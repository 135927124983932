import React from 'react';
import { bindActionCreators } from 'redux';
import { BasePage } from 'digitalbank-layout';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import * as PATHS from 'routes/paths';
import TransferForm from 'routes/Transfer/TransferForm';
import validate from 'routes/Transfer/transfer.validate';
import initialValues from 'routes/Transfer/transfer.initialValues';
import dataSelector from 'redux-helpers/dataSelector';
import { change as changeDataAction } from 'redux-helpers/data.actions';
import { OVERFOERE_FORM_NAME } from 'routes/Transfer/transfer.constants';
import { TYPE as TRANSACTION_TYPE } from 'digitalbank-mockup-data/lib/Transactions';
import { addTransaction } from 'services/transactions.service';
import i18n from 'i18n';
import TransferStyled from '../TransferStyled';

const Overfoere = ({
  history,
  handleSubmit,
  formValues,
  submitting,
  transactions,
  changeData,
}) => (
  <TransferStyled>
    <BasePage
      heading={i18n(t => t.ROUTE_TITLE_TRANSFER)}
      className="p-transfer"
      asideContent={
        <section className="published-content">
          <div className="published-content__item published-content__frame">
            <h2>Tips</h2>
            <p>
              Skal du betale inn på kredittkortet ditt? Gå til mine kort og velg
              Betale inn på kredittkort og få riktig kontonummer og KID-nummer
              for ditt kort. Innbetalingen vil være registrer på kortet innen 1
              til 2 virkedager.
            </p>
          </div>
        </section>
      }
    >
      <TransferForm
        formName={OVERFOERE_FORM_NAME}
        formValues={formValues}
        isSubmitting={submitting}
        handleSubmit={handleSubmit(
          data =>
            new Promise((resolve) => {
              setTimeout(() => {
                addTransaction('transactions', transactions, changeData, {
                  ...data,
                  type: TRANSACTION_TYPE.OVERFOERING,
                });
                resolve();
                history.push(PATHS.TRANSFER_RECEIPT);
              }, 500);
            }),
        )}
      />
    </BasePage>
  </TransferStyled>
);

Overfoere.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeData: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  submitting: PropTypes.bool.isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(OVERFOERE_FORM_NAME)(state) || initialValues,
  transactions: dataSelector(state, 'transactions'),
});

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(changeDataAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    destroyOnUnmount: false,
    form: OVERFOERE_FORM_NAME,
    initialValues,
    validate,
  })(Overfoere),
);
