export const getHeatmapLocations = (t) => {
  if (!t || t.length <= 0) return [];
  const transactionsWithCoordinates = t.filter(
    x => x.place !== undefined && x.place.coordinates !== undefined,
  );
  return transactionsWithCoordinates.map((transaction) => {
    const coordinates = transaction.place.coordinates.split(',');
    return {
      lat: Number(coordinates[0]),
      lng: Number(coordinates[1]),
      weight: Math.abs(Number(transaction.amount)),
    };
  });
};
