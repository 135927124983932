import styled from 'styled-components';
import { breakpoints, spacing } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { formMaxWidthValue } from 'digitalbank-core-style/lib/sizes';
import { rem } from 'polished';

export default styled.div`
  .p-bankid-order {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__description {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__main {
      ${horizontalLayoutSpacing};
    }

    &__info-alert {
      margin-bottom: ${rem(spacing.layoutSpacing.xs)};
    }

    &__form {
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
      max-width: ${rem(formMaxWidthValue)};
    }

    &__submit-btn {
      margin-bottom: ${rem(spacing.layoutSpacing.xs)};
      @media (max-width: ${breakpoints.tablet}px) {
        width: 100%;
      }
      @media (min-width: ${breakpoints.tablet}px) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    &__confirmation {
      ul {
        margin: 0 0 ${rem(spacing.layoutSpacing.md)};
        padding: 0 0 0 ${rem(17)};
      }

      li {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
`;
