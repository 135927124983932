import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavListLinkButtonStyled } from 'styled/Buttons';
import Category from './Category';

const CategoriesStyled = styled.div`
  ${NavListLinkButtonStyled} {
    border-bottom: 1px solid #ddd;
  }
`;

export const Categories = ({ setCategory, categoryData }) => {
  if (
    !categoryData ||
    !categoryData.children ||
    categoryData.children.length <= 0
  ) {
    return null;
  }
  return (
    <CategoriesStyled>
      {categoryData.children.map(subCategory => (
        <Category
          key={`category-${subCategory.name}`}
          category={subCategory}
          setCategory={() => setCategory(subCategory.name)}
          isCategoryIconVisible={categoryData.type === 'mainCategory'}
        />
      ))}
    </CategoriesStyled>
  );
};

Categories.propTypes = {
  setCategory: PropTypes.func.isRequired,
  categoryData: PropTypes.shape({}).isRequired,
};
