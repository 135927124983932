import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <path
      d="M12.8994583,20.4583333 L9,20.4583333 L9,18.5416667 L12.8994583,18.5416667 C12.8601667,18.856 12.8333333,19.175125 12.8333333,19.5 C12.8333333,19.824875 12.8601667,20.144 12.8994583,20.4583333 Z M15.8070417,13.451 L13.0460833,10.6900417 L11.691,12.045125 L14.4519583,14.8060833 C14.8458333,14.299125 15.3000833,13.844875 15.8070417,13.451 L15.8070417,13.451 Z M26.549,14.8060833 L29.3099583,12.045125 L27.954875,10.6900417 L25.1939167,13.451 C25.6999167,13.844875 26.1541667,14.3000833 26.549,14.8060833 L26.549,14.8060833 Z M20.5,11.8333333 C20.824875,11.8333333 21.144,11.8601667 21.4583333,11.8994583 L21.4583333,8 L19.5416667,8 L19.5416667,11.8994583 C19.856,11.8601667 20.175125,11.8333333 20.5,11.8333333 L20.5,11.8333333 Z M20.5,27.1666667 C20.175125,27.1666667 19.856,27.1398333 19.5416667,27.1005417 L19.5416667,31 L21.4583333,31 L21.4583333,27.1005417 C21.144,27.1398333 20.824875,27.1666667 20.5,27.1666667 Z M28.1005417,18.5416667 C28.1398333,18.856 28.1666667,19.175125 28.1666667,19.5 C28.1666667,19.824875 28.1398333,20.144 28.1005417,20.4583333 L32,20.4583333 L32,18.5416667 L28.1005417,18.5416667 Z M25.1939167,25.549 L27.9539167,28.309 L29.3099583,26.9539167 L26.5499583,24.1939167 C26.155125,24.6999167 25.700875,25.1541667 25.1939167,25.549 Z M14.451,24.1929583 L11.691,26.9529583 L13.0460833,28.3080417 L15.8060833,25.5480417 C15.3000833,25.1541667 14.844875,24.6999167 14.451,24.1929583 Z M14.75,19.5 C14.75,22.6759167 17.3240833,25.25 20.5,25.25 C23.6759167,25.25 26.25,22.6759167 26.25,19.5 C26.25,16.3240833 23.6759167,13.75 20.5,13.75 C17.3240833,13.75 14.75,16.3240833 14.75,19.5 Z"
      transform="translate(-9 -8)"
    />
  </svg>
);
