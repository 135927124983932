import React from 'react';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import i18n from 'i18n';
import ScheduledPaymentNavListLinkContainer from 'containers/ScheduledPaymentNavListLinkContainer';

export default () => (
  <NavList
    items={[
      <NavListLink
        component={Link}
        to={PATHS.PAYMENT_GLOBAL}
        title={{ text: i18n(t => t.ROUTE_TITLE_NEW_FOREIGN_PAYMENT) }}
        key="paymentGlobal"
      />,
      <NavListLink
        component={Link}
        to={PATHS.PAY}
        title={{ text: i18n(t => t.ROUTE_TITLE_PAY) }}
        key="ny-betaling"
      />,
      <ScheduledPaymentNavListLinkContainer />,
      <NavListLink
        component={Link}
        to={PATHS.SEARCH}
        title={{ text: i18n(t => t.ROUTE_TITLE_LAST_TRANSACTIONS) }}
        key="sistetransaksjoner"
      />,
    ]}
  />
);
