export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const UPDATE_WINDOW = 'UPDATE_WINDOW';
export const TOGGLE_DATA_CONSENT_INFO_ELEMENT =
  'TOGGLE_DATA_CONSENT_INFO_ELEMENT';
export const TOGGLE_PERSONAL_DATA_CONSENT_HELP =
  'TOGGLE_PERSONAL_DATA_CONSENT_HELP';

// PERIODIC CONTROL - CONTACT INFO
export const TOGGLE_EDIT_MODE_ON_CONTACT_INFO =
  'TOGGLE_EDIT_MODE_ON_CONTACT_INFO';
export const CLOSE_EDIT_MODE_ON_CONTACT_INFO =
  'CLOSE_EDIT_MODE_ON_CONTACT_INFO';
export const OPEN_EDIT_MODE_ON_CONTACT_INFO = 'OPEN_EDIT_MODE_ON_CONTACT_INFO';
export const TOGGLE_CONTACT_INFO_HELP = 'TOGGLE_CONTACT_INFO_HELP';
export const CLOSE_CONTACT_INFO_HELP = 'CLOSE_CONTACT_INFO_HELP';
export const TOGGLE_FOREIGNER_FORM = 'TOGGLE_FOREIGNER_FORM';

// PERIODIC CONTROL - REGIONAL BLOCKING
export const TOGGLE_EDIT_MODE_ON_REGIONAL_BLOCKING =
  'TOGGLE_EDIT_MODE_ON_REGIONAL_BLOCKING';
export const CLOSE_EDIT_MODE_ON_REGIONAL_BLOCKING =
  'CLOSE_EDIT_MODE_ON_REGIONAL_BLOCKING';
export const OPEN_EDIT_MODE_ON_REGIONAL_BLOCKING =
  'OPEN_EDIT_MODE_ON_REGIONAL_BLOCKING';
export const TOGGLE_REGIONAL_BLOCKING_HELP = 'TOGGLE_REGIONAL_BLOCKING_HELP';
export const CLOSE_REGIONAL_BLOCKING_HELP = 'CLOSE_CONTACT_INFO_HELP';
export const OPEN_REGIONAL_BLOCKING_LOCATION =
  'OPEN_REGIONAL_BLOCKING_LOCATION';
export const CLOSE_REGIONAL_BLOCKING_LOCATION =
  'CLOSE_REGIONAL_BLOCKING_LOCATION';
export const TOGGLE_REGIONAL_BLOCKING_LOCATION =
  'TOGGLE_REGIONAL_BLOCKING_LOCATION';
export const TOGGLE_LOCATION_INFO = 'TOGGLE_LOCATION_INFO';

export const ADD_MESSAGE = 'MESSAGE/ADD';
export const READ_MESSAGE = 'MESSAGE/READ';

export const ECONOMY_GET_TRANSACTIONS = 'ECONOMY/GET_TRANSACTIONS';
export const ECONOMY_GET_CATEGORIES = 'ECONOMY/GET_CATEGORIES';

export const ECONOMY_GET_TRANSACTIONS_START = 'ECONOMY/GET_TRANSACTIONS/START';
export const ECONOMY_GET_TRANSACTIONS_SUCCESSFUL =
  'ECONOMY/GET_TRANSACTIONS/SUCCESSFUL';
export const ECONOMY_GET_TRANSACTIONS_FAILED =
  'ECONOMY/GET_TRANSACTIONS/FAILED';

export const ECONOMY_GET_CATEGORIES_START = 'ECONOMY/GET_CATEGORIES/START';
export const ECONOMY_GET_CATEGORIES_SUCCESSFUL =
  'ECONOMY/GET_CATEGORIES/SUCCESSFUL';
export const ECONOMY_GET_CATEGORIES_FAILED = 'ECONOMY/GET_CATEGORIES/FAILED';

export const ECONOMY_GET_TAGS_START = 'ECONOMY/GET_TAGS/START';
export const ECONOMY_GET_TAGS_SUCCESSFUL = 'ECONOMY/GET_TAGS/SUCCESSFUL';
export const ECONOMY_GET_TAGS_FAILED = 'ECONOMY/GET_TAGS/FAILED';

export const TAG_GET_TAGS_START = 'TAG/GET_TAGS/START';
export const TAG_GET_TAGS_SUCCESSFUL = 'TAG/GET_TAGS/SUCCESSFUL';
export const TAG_GET_TAGS_FAILED = 'TAG/GET_TAGS/FAILED';

export const TAG_ADD = 'TAG/ADD';
export const TAG_EDIT = 'TAG/EDIT';
export const TAG_DELETE = 'TAG/DELETE';
