import _ from 'lodash';

export const categorizeRecipients = (recipients) => {
  // Categorize recipients
  const categories = [];
  recipients.forEach((recipient) => {
    // Check first name letter
    const firstChar = recipient.name[0];
    // create new list if none with same char exist
    const existingCategory = _.find(categories, c => c.name === firstChar);
    // check if existingCategory is defined
    if (existingCategory) {
      existingCategory.recipients.push({ ...recipient });
    } else {
      // create new category with first recipient
      categories.push({
        name: firstChar,
        recipients: [{ ...recipient }],
      });
    }
  });
  return categories;
};

const recipientMatchSearch = (searchValue, recipient) =>
  recipient.name.toLowerCase().indexOf(searchValue) > -1 ||
  recipient.account.indexOf(searchValue.replace(/\.|\s/g, '')) > -1;

export const filterRecipients = (recipients, searchValue, group) => {
  const value = searchValue ? searchValue.toLowerCase() : undefined;
  return recipients.map((recipient) => {
    let display = false;

    if (group) {
      const isInGroup = recipient.group === group;
      // We want to display recipient when searchValue is undefined
      if (
        (!value && isInGroup) ||
        (value && isInGroup && recipientMatchSearch(value, recipient))
      ) {
        display = true;
      }
    } else if (!value || (value && recipientMatchSearch(value, recipient))) {
      display = true;
    }
    return {
      ...recipient,
      display,
    };
  });
};

export const orderRecipientsByName = recipients =>
  recipients.sort((a, b) => a.name.localeCompare(b.name, 'nb-NO'));
