import React from 'react';

export default () => (
  <section className="published-content">
    <div className="published-content__item published-content__frame">
      <h2>Oppgjørstider</h2>
      <div>
        <p>Belastning av konto:</p>
        <p>Hverdager 00:25, 10:00, 12:00 og 13:50.</p>
        <p>Godskriving av konto:</p>
        <p>Hverdager 05:30, 09:30, 11:30, 13:30 og 15:30.</p>
        <p>Tidspunktene er omtrentlige.</p>
      </div>
    </div>
  </section>
);
