import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 19"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <path
      d="M29.6633981,18.272825 L27.5767507,16.4308989 L27.5767507,12.040353 C27.5767507,11.6626959 27.2838679,11.3421397 26.9254569,11.3421397 L25.355628,11.3421397 C24.9960773,11.3421397 24.7054738,11.6626959 24.7054738,12.040353 L24.7054738,13.8924555 L21.1413069,10.7479518 C20.4638017,10.1531985 19.4797384,10.1531985 18.8016635,10.7479518 L10.280142,18.272825 C9.72172739,18.7759908 10.0539271,19.7472705 10.7849945,19.7472705 L12.566793,19.7472705 L12.566793,26.2889916 C12.566793,27.5582132 13.5377507,28.5990315 14.7474592,28.5990315 L25.1966507,28.5990315 C26.4057894,28.5990315 27.3767471,27.5582132 27.3767471,26.2889916 L27.3767471,19.7472705 L29.1585457,19.7472705 C29.889613,19.7472705 30.2212429,18.7759908 29.6633981,18.272825 L29.6633981,18.272825 Z M22.8176904,23.9099783 C22.8176904,24.3566087 22.4849209,24.7110862 22.0592721,24.7110862 L17.884268,24.7110862 C17.4586193,24.7110862 17.1258497,24.3566087 17.1258497,23.9099783 L17.1258497,20.5596854 C17.1258497,20.1136204 17.4586193,19.7472705 17.884268,19.7472705 L22.0592721,19.7472705 C22.4849209,19.7472705 22.8176904,20.1136204 22.8176904,20.5596854 L22.8176904,23.9099783 Z"
      transform="translate(-10 -10)"
    />
  </svg>
);
