import styled from 'styled-components';
import { breakpoints, spacing, colors } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-account {
    &__heading,
    &__aside {
      ${horizontalLayoutSpacing};
    }

    &__sub-heading {
      ${horizontalLayoutSpacing({ tablet: false })};
    }

    &__main {
      @media (min-width: ${breakpoints.tablet}px) {
        ${horizontalLayoutSpacing};
      }
    }

    &__top {
      ${horizontalLayoutSpacing({ tablet: false })};
    }

    &__aside {
      margin-top: ${rem(spacing.layoutSpacing.sm)};
    }

    &__nav-links {
      .published-content__frame {
        margin-top: ${rem(spacing.layoutSpacing.sm)};
        background-color: transparent;
        padding-top: 0;

        @media (max-width: ${breakpoints.tablet}px) {
          padding-bottom: 0;
        }
      }
    }

    &__footer {
      &-content {
        @media (min-width: ${breakpoints.tablet}px) {
          width: 70%;
        }

        .published-content {
          ${horizontalLayoutSpacing({ type: 'margin' })};

          &:first-of-type {
            @media (min-width: ${breakpoints.tablet}px) {
              margin-bottom: ${rem(spacing.layoutSpacing.sm)};
            }
          }
        }
      }
    }

    &__open-account-btn {
      margin-left: ${rem(spacing.layoutSpacing.sm)};
      background: none;
      border: 0;
      padding: ${rem(13)} 0 ${rem(15)};
      color: ${colors.doveGray};

      &:focus,
      &:active,
      &:hover {
        color: ${colors.doveGray};
      }

      .button__icon {
        width: ${rem(20)};
        height: ${rem(20)};
        margin-right: 5px;
      }
    }

    &__account-list-section {
      margin-bottom: ${rem(20)};
    }

    .nav-list {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${rem(20)};
    }

    .info-card {
      @media (min-width: ${breakpoints.tablet}px) {
        border-top: 0;
      }
    }

    .published-section {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    .customer-settings {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }
  }
`;
