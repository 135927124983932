import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isPristine, destroy } from 'redux-form';
import moment from 'moment';
import Alert from 'digitalbank-alert';
import * as PATHS from 'routes/paths';
import { ReceiptPage } from 'digitalbank-layout';
import { getFrekvensString } from 'utils/frekvens.util';
import i18n from 'i18n';
import { bindActionCreators } from 'redux';
import { resetAccountPicker } from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import wizardPage from 'components/wizardPage';
import FasteOppdragNavList from 'components/FasteOppdragNavList';
import { FAST_OVERFOERING_FORM_NAME } from 'routes/Transfer/transfer.constants';
import TransferStyled from '../TransferStyled';

class FastOverfoeringReceipt extends Component {
  componentWillUnmount() {
    this.props.onDestroy(FAST_OVERFOERING_FORM_NAME);
    this.props.onResetAccountPicker(`${FAST_OVERFOERING_FORM_NAME}FraKonto`);
    this.props.onResetAccountPicker(`${FAST_OVERFOERING_FORM_NAME}TilKonto`);
  }
  render() {
    const { values } = this.props;

    if (!values.tilKonto || !values.fraKonto) {
      return (
        <Alert warning small hideIcon>
          Vi fikk dessverre ikke hentet kontoene du spesifiserte i overføringen.
        </Alert>
      );
    }
    const { fraKonto, tilKonto } = values;
    const dueDate = moment(values.dato, 'L');
    const dueDatePrint = dueDate.format('LL');
    const fraKontoNavn = fraKonto.visningsnavn || fraKonto.accountName;
    const tilKontoNavn = tilKonto.visningsnavn || tilKonto.accountName;
    const amount = i18n(i => i.NUMBER_TWO_DIGITS, {
      number: Number(values.kroner) + Number(values.oere / 100),
    });

    return (
      <TransferStyled>
        <ReceiptPage
          className="p-transfer"
          receiptTitle="Fast overføring er startet"
          receiptDescription={
            <div>
              <strong>{amount}</strong>
              {` vil bli overført til ${tilKontoNavn} fra ${fraKontoNavn} `}
              <strong>
                {getFrekvensString(values.frekvens).toLowerCase()}
              </strong>
              {' fra '}
              <strong>{dueDatePrint}</strong>
            </div>
          }
          asideContent={
            <section className="published-content">
              <div className="published-content__item published-content__frame">
                <h2>Tips</h2>
                <p>
                  Skal du betale inn på kredittkortet ditt? Gå til mine kort og
                  velg Betale inn på kredittkort og få riktig kontonummer og
                  KID-nummer for ditt kort. Innbetalingen vil være registrer på
                  kortet innen 1 til 2 virkedager.
                </p>
              </div>
            </section>
          }
        >
          <FasteOppdragNavList />
        </ReceiptPage>
      </TransferStyled>
    );
  }
}

FastOverfoeringReceipt.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  values: getFormValues(FAST_OVERFOERING_FORM_NAME)(state),
});

const mapDispatchToProps = dispatch => ({
  onDestroy: bindActionCreators(destroy, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

export default wizardPage({
  redirectTest: state => isPristine(FAST_OVERFOERING_FORM_NAME)(state),
  redirectPath: PATHS.TRANSFER,
})(connect(mapStateToProps, mapDispatchToProps)(FastOverfoeringReceipt));
