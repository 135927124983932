/* eslint-disable no-console */
import HttpClient, { logResponse } from 'spv-http';

// https://github.spvdev.no/sfo/digitalbank-betalingsavtaler-web/blob/develop/betalingsavtaler-web/src/infrastructure/http/httpClient.js
const middlewares = [logResponse({ logger: console })];
export const httpClient = new HttpClient(middlewares);
// export const root = 'http://10.200.6.189:5000';
export const root = 'https://min-okonomi.spv-test-01.ase.spvest.no';
// export const root = 'http://localhost:5000';
export const config = {
  apiEndpoints: {
    transactions: `${root}/transaction/get`,
    categories: `${root}/transaction/categories`,
    logo: `${root}/logo`,
    tag: `${root}/tag`,
  },
  routes: {
    logo: `${root}/logo/id`,
  },
};

export const getTransactions = params =>
  httpClient.get(config.apiEndpoints.transactions, params);

export const getCategories = params =>
  httpClient.get(config.apiEndpoints.categories, params);

export const getTags = params =>
  httpClient.get(config.apiEndpoints.tag, params);
