import {
  TOGGLE_PRIVATE_ACCOUNT,
  TOGGLE_SHARED_ACCOUNT,
  TOGGLE_CREDIT_ACCOUNT,
  SELECT_DUE_DATE_REGISTER_DISPLAY_FILTER,
  TOGGLE_HIDE_PRIVATE_TRANSFER,
  SELECT_CREDIT_DISPLAY_FILTER,
  SELECT_FIRST_CUSTOM_KEY_NUMBER,
  SELECT_SECOND_CUSTOM_KEY_NUMBER,
} from './KeyNumbers.Constants';

export const onTogglePrivateAccount = accountId => ({
  type: TOGGLE_PRIVATE_ACCOUNT,
  accountId,
});

export const onToggleSharedAccount = accountId => ({
  type: TOGGLE_SHARED_ACCOUNT,
  accountId,
});

export const onToggleCreditAccount = accountId => ({
  type: TOGGLE_CREDIT_ACCOUNT,
  accountId,
});

export const onSelectDueDateRegisterFilter = filter => ({
  type: SELECT_DUE_DATE_REGISTER_DISPLAY_FILTER,
  filter,
});

export const onToggleHidePrivateTranfers = () => ({
  type: TOGGLE_HIDE_PRIVATE_TRANSFER,
});

export const onSelectCreditDisplayFilter = filter => ({
  type: SELECT_CREDIT_DISPLAY_FILTER,
  filter,
});

export const selectFirstCustomKeyNumber = alias => ({
  type: SELECT_FIRST_CUSTOM_KEY_NUMBER,
  alias,
});

export const selectSecondCustomKeyNumber = alias => ({
  type: SELECT_SECOND_CUSTOM_KEY_NUMBER,
  alias,
});
