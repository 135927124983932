import React from 'react';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import NavList, { NavListLink, POSITIVE } from 'digitalbank-nav-list';
import {
  UserPlus,
  CommentO,
  EnvelopeO,
  LineChart,
  Handing,
  CogO,
} from 'digitalbank-icons';

const HomeOldNavList = () => (
  <NavList
    items={[
      <NavListLink
        key="vennebetaling"
        component={Link}
        to={PATHS.PAY}
        title={{ text: 'Vennebetaling' }}
        Icon={UserPlus}
      />,
      <NavListLink
        key="kontakt"
        component={Link}
        to={PATHS.HOME}
        title={{ text: 'Kontakt oss' }}
        description={{ text: 'Chat er tilgjengelig', variant: POSITIVE }}
        Icon={CommentO}
      />,
      <NavListLink
        key="postkasse"
        component={Link}
        to={PATHS.HOME}
        title={{ text: 'Postkasse' }}
        Icon={EnvelopeO}
      />,
      <NavListLink
        key="fond-og-aksjer"
        component={Link}
        to={PATHS.HOME}
        title={{ text: 'Mine fond og aksjer' }}
        Icon={LineChart}
      />,
      <NavListLink
        key="laan"
        component={Link}
        to={PATHS.HOME}
        title={{ text: 'Mine lån' }}
        Icon={Handing}
      />,
      <NavListLink
        key="innstillinger"
        component={Link}
        to={PATHS.SETTINGS}
        title={{ text: 'Innstillinger' }}
        Icon={CogO}
      />,
    ]}
  />
);

export default HomeOldNavList;
