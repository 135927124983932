/* eslint-disable import/no-unresolved, import/extensions */

import React from 'react';
import { CommentO, Clock, EnvelopeO, Phone } from 'digitalbank-icons';
import {
  CallToActionWithImage,
  // CallToActionWithIllustration,
  Accordion,
} from 'digitalbank-widgets';

const images = {
  small: {
    defaultSrc: '/images/Trampoline-310x150.png',
    highResolutionSrc: '/images/Trampoline-310x150@2x.png',
  },
  medium: {
    defaultSrc: '/images/Trampoline-250x235.png',
    highResolutionSrc: '/images/Trampoline-250x235@2x.png',
  },
  large: {
    defaultSrc: '/images/Trampoline-450x235.png',
    highResolutionSrc: '/images/Trampoline-450x235@2x.png',
  },
  alt: 'Barn som hopper på trampoline',
};

const callToActionWithImageProps = {
  title: 'Opprett barnekonto, få 200,-',
  description:
    '<p>Opprett en ny barnekonto på Min famlie, så setter vi inn 200 kr</p>',
  link: {
    title: 'Gå til min familie',
    url: 'https://www.spv.no/',
    variant: 'primary',
    size: 'medium',
  },
  images,
  theme: {
    backgroundColor: 'white',
  },
  // symbol: {
  //   text: 'Min familie',
  // },
  placement: 'aside',
};

const accordionProps = {
  faq: {
    symbol: {
      text: 'FAQ',
    },
    header: {
      text: 'Ofte stilte spørsmål',
      description: 'om hvem, hva og hvor',
    },
  },
  items: [
    {
      header: {
        heading: {
          description: 'Hvem fungerer dette for?',
        },
      },
      content: '<p>Dette er veldig <strong>bra</strong>!</p>',
    },
    {
      header: {
        heading: {
          description: 'Hva fungerer egentlig?',
        },
      },
      content: '<p>Dette er veldig <strong>bra</strong>!</p>',
    },
    {
      header: {
        heading: {
          description: 'Hvor fungerer dette?',
        },
      },
      content: '<p>Dette er veldig <strong>bra</strong>!</p>',
    },
  ],
};

// const callToActionWithIllustrationProps = {
//   title: 'Opprett barnekonto, få 200,-',
//   description:
//     'Opprett en ny barnekonto på Min famlie, så setter vi inn 200 kr',
//   link: {
//     title: 'Gå til min familie',
//     url: 'https://www.spv.no/',
//     variant: 'primary',
//     size: 'medium',
//   },
//   illustration: {
//     src: '/images/min-familie.svg',
//     alt: 'Min familie illustrasjon',
//   },
//   theme: {
//     backgroundColor: 'white',
//   },
//   placement: 'aside',
// };

export default () => (
  <section className="p-home__aside--customer-settings-align">
    <div style={{ marginBottom: '20px' }}>
      <div className="published-section">
        <Accordion {...accordionProps} />
      </div>
      <div className="published-section">
        <CallToActionWithImage {...callToActionWithImageProps} />
      </div>
    </div>
    {/* <div style={{ marginBottom: '20px' }}>
      <CallToActionWithIllustration {...callToActionWithIllustrationProps} />
    </div> */}
    <section className="contact-card">
      <h1 className="contact-card__heading">Kontakt</h1>
      <button
        className="button button--transparent contact-card__chat-button"
        type="button"
      >
        <div className="button__content">
          <CommentO className="button__icon button__icon--left" />
          <div>
            <div>Chat med oss</div>
            <div className="contact-card__chat-button-status">Chat er åpen</div>
          </div>
        </div>
      </button>
      <button
        className="button button--transparent button--with-icon"
        type="button"
      >
        <div className="button__content">
          <EnvelopeO className="button__icon button__icon--left" />
          <div className="button__text">
            Postkasse
            <i className="badge badge--new button__badge button__badge--right">
              1
            </i>
          </div>
        </div>
      </button>
      <button
        className="button button--transparent button--with-icon"
        type="button"
      >
        <div className="button__content">
          <Phone className="button__icon button__icon--left" />
          Ring oss
        </div>
      </button>
    </section>
    <section className="my-advisor-card">
      <div className="my-advisor-card__image-wrapper">
        <img
          className="my-advisor-card__image"
          alt="Bilde av Jan-Tore Smith"
          src="https://firebasestorage.googleapis.com/v0/b/firebase-digitalbank.appspot.com/o/images%2Fmockup%2Fadvisor.png?alt=media&token=895dd01c-04d2-4420-bdc3-2358e7624185"
        />
      </div>
      <div className="my-advisor-card__info">
        <h1 className="my-advisor-card__heading">Min rådgiver</h1>
        <p className="my-advisor-card__name">Jan-Tore Smith</p>
        <p className="my-advisor-card__location">Jonsvoll</p>
        <div className="my-advisor-card__actions">
          <button
            className="button button--transparent button--with-icon"
            type="button"
          >
            <div className="button__content">
              <Clock className="button__icon button__icon--left" />
              Bestill rådgivning
            </div>
          </button>
        </div>
      </div>
    </section>
  </section>
);
