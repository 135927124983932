import React from 'react';
import PropTypes from 'prop-types';
import LegendWithSaveStatus from 'components/Form/LegendWithSaveStatus';

const forfallOptions = [
  {
    label: 'Alle forfall',
    value: 'ALLE_FORFALL',
  },
  {
    label: 'Neste 7 dager',
    value: 'NEXT_X_DAYS',
  },
  {
    label: 'Fram til fast dag i måneden',
    value: 'DAY_IN_MONTH',
  },
];

const MySettingsForfallFieldset = ({ forfall, updateFilter }) => {
  const numberOfExistingForfallSaveInstances = forfall.savingInstances.length;
  const forfallStatus =
    numberOfExistingForfallSaveInstances > 0
      ? forfall.savingInstances[numberOfExistingForfallSaveInstances - 1]
      : '';

  return (
    <fieldset className="fieldset home-settings__presentation-fieldset">
      <LegendWithSaveStatus text="Forfall:" status={forfallStatus} />
      <div className="form-group" role="radiogroup">
        {forfallOptions.map((option, i) => (
          <span key={`forfall-option-${option.value}`}>
            <input
              checked={forfall.value === option.value}
              type="radio"
              className="radio"
              id={`forfall-option-${i}`}
              name="forfall-options"
              value={option.value}
              onChange={() =>
                updateFilter(option.value, numberOfExistingForfallSaveInstances)
              }
            />
            <label className="radio__label" htmlFor={`forfall-option-${i}`}>
              {option.label}
            </label>
          </span>
        ))}
      </div>
      {forfall.value === 'DAY_IN_MONTH' && (
        <div
          className="form-group"
          style={{ paddingLeft: '30px', maxWidth: '130px' }}
        >
          <label htmlFor="days" className="label label--hidden">
            Velg dag:
          </label>
          <select name="days" id="days" className="form-control select">
            <optgroup>
              {[...Array(31)].map((x, i) => (
                <option key={`day-option-${i + 1}`} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </optgroup>
          </select>
        </div>
      )}
    </fieldset>
  );
};

MySettingsForfallFieldset.propTypes = {
  forfall: PropTypes.shape({}).isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default MySettingsForfallFieldset;
