// eslint-disable max-len

const template = {
  SAMTYKKE_VALIDATION__MANDATORY_FIELD: {
    'nb-NO': 'Du må velge ett av alternativene',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
};

export default template;
