import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const AmountIncreaserItem = ({ amount, onAmountClick, disabled }) => {
  const handleClick = (ev) => {
    ev.preventDefault();
    onAmountClick(amount);
  };

  return (
    <button
      type="button"
      className={classNames('amount-increaser__item', {
        'amount-increaser__item--disabled': disabled,
      })}
      tabIndex="-1"
      aria-hidden="true"
      onClick={handleClick}
      disabled={disabled}
    >
      <span>
        <span className="amount-increaser__plus">+</span>
        {amount}
      </span>
    </button>
  );
};

AmountIncreaserItem.defaultProps = {
  disabled: false,
};

AmountIncreaserItem.propTypes = {
  amount: PropTypes.number.isRequired,
  onAmountClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AmountIncreaserItem;
