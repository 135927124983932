import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavListLink, TASK } from 'digitalbank-nav-list';
import { NavListLinkButtonStyled } from 'styled/Buttons';
import CategoryIcon from '../CategoryIcon';

const CircleStyled = styled.div`
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: ${props => props.categoryColor};
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 17px;
  }

  /* Need this because of div wrapping Twemoji */
  /* div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    width: 18px;
  } */
`;

export const Category = ({ category, setCategory, isCategoryIconVisible }) => {
  const sumTransactionsInCategory = category.sum;
  if (sumTransactionsInCategory === 0) return null;
  return (
    <NavListLink
      component={NavListLinkButtonStyled}
      onClick={setCategory}
      variant={TASK}
      title={{ text: category.displayName }}
      description={{
        text: `${category.numberOfTransactions} transaksjoner`,
      }}
      Icon={props => (
        <CircleStyled {...props} categoryColor={category.color}>
          {isCategoryIconVisible && (
            <CategoryIcon categoryAlias={category.name} />
          )}
        </CircleStyled>
      )}
      info={{
        text: sumTransactionsInCategory.toLocaleString('nb-NO', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      }}
    />
  );
};

Category.propTypes = {
  category: PropTypes.shape({}).isRequired,
  setCategory: PropTypes.func.isRequired,
  isCategoryIconVisible: PropTypes.bool.isRequired,
};

export default Category;
