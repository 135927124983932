/* eslint-disable no-param-reassign */
// Classname reference
const CLASSES = {
  MASONRY: 'masonry',
  PANEL: 'masonry-panel',
  PAD: 'masonry-pad',
};

export default class MasonryClass {
  constructor(el, config, getLayoutSize) {
    this.container = el;
    this.config = config;
    this.getLayoutSize = getLayoutSize;
    this.panels = el.querySelectorAll(`.${CLASSES.PANEL}`);
    this.state = {
      heights: [],
    };
  }
  /**
   * Reset the layout by removing padding elements, resetting heights
   * reference and removing the container inline style
   */
  reset() {
    const { container } = this;
    this.state.heights = [];
    const fillers = container.querySelectorAll(`.${CLASSES.PAD}`);
    if (fillers.length) {
      for (let f = 0; f < fillers.length; f += 1) {
        fillers[f].parentNode.removeChild(fillers[f]);
      }
    }
    container.style.height = 'auto';
  }
  /**
   * Iterate through panels and work out the height of the layout
   */
  populateHeights() {
    const { panels, state } = this;
    const { heights } = state;
    for (let p = 0; p < panels.length; p += 1) {
      const panel = panels[p];
      const { order: cssOrder, msFlexOrder } = window.getComputedStyle(panel);
      const order = cssOrder || msFlexOrder;
      if (!heights[order - 1]) heights[order - 1] = 0;
      heights[order - 1] += Math.ceil(panel.getBoundingClientRect().height);
    }
  }
  /**
   * Set the layout height based on referencing the content cumulative height
   * This probably doesn't need its own function but felt right to be nice
   * and neat
   */
  setLayout() {
    const { container, state } = this;
    const { heights } = state;
    this.state.maxHeight = Math.max(...heights);
    container.style.height = `${this.state.maxHeight}px`;
  }
  /**
   * JavaScript method for setting order of each panel based on panels.
   * length and desired number of columns
   */
  setOrder() {
    const { panels, config } = this;
    const currentLayoutSize = this.getLayoutSize();
    panels.forEach((panel, i) => {
      let order = (i + 1) % config[currentLayoutSize].columns;

      if (order === 0) order = config[currentLayoutSize].columns;
      panel.style.width = `${100 / config[currentLayoutSize].columns}%`;
      panel.style.order = order;
    });
  }
  /**
   * Pad out layout "columns" with padding elements that make heights equal
   */
  pad() {
    const { container } = this;
    const { heights, maxHeight } = this.state;
    heights.forEach((height, i) => {
      if (height < maxHeight && height > 0) {
        const pad = document.createElement('div');
        pad.className = CLASSES.PAD;
        pad.style.height = `${maxHeight - height}px`;
        pad.style.order = i + 1;
        pad.style.msFlexOrder = i + 1;
        container.appendChild(pad);
      }
    });
  }
  /**
   * Resets and lays out elements
   */
  layout() {
    this.reset();
    this.setOrder();
    this.populateHeights();
    this.setLayout();
    this.pad();
  }
}
