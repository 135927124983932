import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openEditModeOnContactInfo, toggleForeignerForm } from 'actions';
import { Check } from 'digitalbank-icons';
import FakeProgressButton from 'components/FakeProgressButton';
import { BasePage } from 'digitalbank-layout';
import ContactInfoFormContainer from './ContactInfoFormContainer';
import ContactInfoPreview from './ContactInfoPreview';
import ContactInfoSettingsStyled from './ContactInfoSettingsStyled';

const ContactInfoSettings = ({
  contactInfo,
  onOpenEditModeOnContactInfo,
  onToggleForeignerForm,
}) => (
  <ContactInfoSettingsStyled>
    <BasePage
      className="p-contact-info"
      heading="Har vi riktige opplysninger om deg?"
      headingIsAlwaysVisible
      asideContent={
        <section className="published-content">
          <div className="published-content__item published-content__frame">
            <h2>Hold oss oppdatert</h2>
            <div>
              <p>
                For å beskytte din sikkerhet, er det viktig at vi har oppdatert
                informasjon om deg.
              </p>
              <p>
                Dersom du flytter, endrer telefonnummer eller e-postadresse, er
                det viktig at du oppdaterer denne informasjonen her.
              </p>
            </div>
          </div>
        </section>
      }
    >
      {!contactInfo.editMode && (
        <ContactInfoPreview openEditMode={onOpenEditModeOnContactInfo} />
      )}
      {contactInfo.editMode && (
        <ContactInfoFormContainer
          isForeigner={contactInfo.isForeigner}
          toggleForeignerForm={onToggleForeignerForm}
          action={
            <FakeProgressButton
              type="submit"
              defaultProps={{
                text: 'Lagre',
              }}
              loadingProps={{
                text: 'Lagrer...',
                icon: <i className="button__spinner" />,
              }}
              successProps={{
                text: 'Lagret',
                icon: <Check />,
              }}
            />
          }
        />
      )}
    </BasePage>
  </ContactInfoSettingsStyled>
);

ContactInfoSettings.propTypes = {
  contactInfo: PropTypes.shape({}).isRequired,
  onToggleForeignerForm: PropTypes.func.isRequired,
  onOpenEditModeOnContactInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  contactInfo: state.contactInfo,
});

const mapDispatchToProps = dispatch => ({
  onToggleForeignerForm: bindActionCreators(toggleForeignerForm, dispatch),
  onOpenEditModeOnContactInfo: bindActionCreators(
    openEditModeOnContactInfo,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ContactInfoSettings,
);
