import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { change } from 'redux-helpers/data.actions';
import dataSelector from 'redux-helpers/dataSelector';
import {
  EFakturaOneClick,
  ONECLICK,
  EFAKTURA_ONE_CLICK,
  ACCOUNT_PICKER_ID,
} from 'digitalbank-efaktura-oneclick';
import { STATUS as PAYMENT_STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import * as PATHS from 'routes/paths';
import {
  updateTransactionStatus,
  updateUnapprovedfakturaTransactionsWithFromAccount,
  setApprovedTransactionsToActive,
} from 'services/transactions.service';
import { setEfakturaOneClickStatus } from 'actions/userSettings.actions';
import { initEfaktura } from 'actions/efaktura.actions';

const updateTransactions = ({
  transactions,
  changeData,
  accounts,
  eFakturaOneClick,
  onInitEfaktura,
}) => {
  if (transactions.status.received) {
    setApprovedTransactionsToActive(transactions, changeData).then(
      newTransactions =>
        onInitEfaktura(
          accounts,
          newTransactions,
          eFakturaOneClick.efakturainnstilling,
        ),
    );
  }
};

class EfakturaOneClickContainer extends Component {
  componentDidMount() {
    this.props.onInitEfaktura(
      this.props.accounts,
      this.props.transactions.data,
      this.props.eFakturaOneClick.efakturainnstilling,
    );
  }
  componentWillUnmount() {
    updateTransactions(this.props);
  }
  render() {
    const { accountPickers, transactions, changeData } = this.props;
    if (!transactions.status.received) {
      return null;
    }

    return (
      <EFakturaOneClick
        logger={console}
        editOneClickSettingsUrl={PATHS.SETTINGS}
        linkElement={Link}
        onUpdateInnstillinger={status =>
          new Promise((resolve) => {
            setTimeout(() => {
              this.props.onSetEfakturaOneClickStatus(status);
              if (status === ONECLICK.PAA) {
                updateUnapprovedfakturaTransactionsWithFromAccount(
                  transactions,
                  changeData,
                  accountPickers[ACCOUNT_PICKER_ID].selectedAccount
                    .accountNumber,
                );
              }
              resolve();
            }, 500);
          })
        }
        onConfirmEfaktura={transaction =>
          new Promise((resolve) => {
            setTimeout(() => {
              updateTransactionStatus(
                'transactions',
                transactions,
                changeData,
                transaction.efakturareferanse,
                PAYMENT_STATUS.APPROVED,
              );
              resolve();
            }, 250);
          })
        }
        forfallsmappeUrl={PATHS.SCHEDULED_PAYMENTS}
      />
    );
  }
}

EfakturaOneClickContainer.propTypes = {
  accountPickers: PropTypes.shape({}).isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }).isRequired,
  }).isRequired,
  changeData: PropTypes.func.isRequired,
  onSetEfakturaOneClickStatus: PropTypes.func.isRequired,
  eFakturaOneClick: PropTypes.shape({
    efakturainnstilling: PropTypes.shape({
      oneClick: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onInitEfaktura: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
  onSetEfakturaOneClickStatus: bindActionCreators(
    setEfakturaOneClickStatus,
    dispatch,
  ),
  onInitEfaktura: bindActionCreators(initEfaktura, dispatch),
});

const mapStateToProps = state => ({
  accountPickers: state.accountPickers,
  transactions: dataSelector(state, 'transactions'),
  accounts: state.accounts,
  eFakturaOneClick: state[EFAKTURA_ONE_CLICK],
});

export default connect(mapStateToProps, mapDispatchToProps)(
  EfakturaOneClickContainer,
);
