import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isPristine, destroy } from 'redux-form';
import moment from 'moment';
import Alert from 'digitalbank-alert';
import * as PATHS from 'routes/paths';
import { ReceiptPage } from 'digitalbank-layout';
import { concatKronerAndOere } from 'utils/format.util';
import i18n from 'i18n';
import { bindActionCreators } from 'redux';
import { resetAccountPicker } from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import wizardPage from 'components/wizardPage';
import OverfoereNavList from 'routes/Transfer/Overfoere/OverfoereNavList';
import { OVERFOERE_FORM_NAME } from '../transfer.constants';
import TransferStyled from '../TransferStyled';

class OverfoereReceipt extends Component {
  componentWillUnmount() {
    this.props.onDestroy(OVERFOERE_FORM_NAME);
    this.props.onResetAccountPicker(`${OVERFOERE_FORM_NAME}FraKonto`);
    this.props.onResetAccountPicker(`${OVERFOERE_FORM_NAME}TilKonto`);
  }
  render() {
    const { values } = this.props;

    if (!values.tilKonto || !values.fraKonto) {
      return (
        <Alert warning small hideIcon>
          Vi fikk dessverre ikke hentet kontoene du spesifiserte i overføringen.
        </Alert>
      );
    }
    const {
      fraKonto, tilKonto, kroner, oere,
    } = values;
    const dato = moment(values.dato, 'L');
    const datoIsToday = dato.isSame(moment(), 'day');
    const fraKontoNavn = fraKonto.visningsnavn || fraKonto.accountName;
    const tilKontoNavn = tilKonto.visningsnavn || tilKonto.accountName;
    const amount = concatKronerAndOere(kroner, oere);
    const fraKontoAvailableAmount = i18n(i => i.NUMBER_TWO_DIGITS, {
      number: Number(fraKonto.availableAmount - amount),
    });
    const tilKontoAvailableAmount = i18n(i => i.NUMBER_TWO_DIGITS, {
      number: Number(tilKonto.availableAmount + amount),
    });

    const title = datoIsToday
      ? 'Overføringen ble gjennomført'
      : 'Overføringen ble gjennomført';
    return (
      <TransferStyled>
        <ReceiptPage
          className="p-transfer"
          receiptDescription={
            <div>
              <p style={{ marginBottom: '0px' }}>
                {fraKontoNavn} har nå disp. kr
                <strong> {fraKontoAvailableAmount}</strong>
              </p>
              <p>
                {tilKontoNavn} har nå disp. kr
                <strong> {tilKontoAvailableAmount}</strong>
              </p>
            </div>
          }
          receiptTitle={title}
          asideContent={
            <section className="published-content">
              <div className="published-content__item published-content__frame">
                <h2>Tips</h2>
                <p>
                  Skal du betale inn på kredittkortet ditt? Gå til mine kort og
                  velg Betale inn på kredittkort og få riktig kontonummer og
                  KID-nummer for ditt kort. Innbetalingen vil være registrer på
                  kortet innen 1 til 2 virkedager.
                </p>
              </div>
            </section>
          }
        >
          <OverfoereNavList />
        </ReceiptPage>
      </TransferStyled>
    );
  }
}

OverfoereReceipt.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  values: getFormValues(OVERFOERE_FORM_NAME)(state),
});

const mapDispatchToProps = dispatch => ({
  onDestroy: bindActionCreators(destroy, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

export default wizardPage({
  redirectTest: state => isPristine(OVERFOERE_FORM_NAME)(state),
  redirectPath: PATHS.TRANSFER,
})(connect(mapStateToProps, mapDispatchToProps)(OverfoereReceipt));
