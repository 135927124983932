import * as TagService from 'services/tag.service';
import {
  TAG_GET_TAGS_START,
  TAG_GET_TAGS_SUCCESSFUL,
  TAG_GET_TAGS_FAILED,
  TAG_ADD,
  TAG_EDIT,
  TAG_DELETE,
} from './actionTypes';

const getTagsStart = () => ({
  type: TAG_GET_TAGS_START,
});

const getTagsSuccessful = data => ({
  type: TAG_GET_TAGS_SUCCESSFUL,
  data,
});

const getTagsFailed = error => ({
  type: TAG_GET_TAGS_FAILED,
  error,
});

export const getTags = params => (dispatch) => {
  dispatch(getTagsStart());
  return TagService.getTags(params)
    .then((data) => {
      // const parsedData = JSON.parse(data);
      dispatch(getTagsSuccessful(data));
    })
    .catch((error) => {
      dispatch(getTagsFailed(error));
      throw error;
    });
};

export const addTag = data => ({
  type: TAG_ADD,
  data,
});

export const editTag = data => ({
  type: TAG_EDIT,
  data,
});

export const deleteTag = data => ({
  type: TAG_DELETE,
  data,
});
