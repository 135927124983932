import React from 'react';
import { BasePage } from 'digitalbank-layout';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once

const list = [
  { dato: '13.01.2017', interestRate: '1,72' },
  { dato: '01.06.2016', interestRate: '1,60' },
  { dato: '01.12.2015', interestRate: '1,96' },
  { dato: '05.08.2015', interestRate: '2,16' },
  { dato: '08.03.2015', interestRate: '2,36' },
  { dato: '10.12.2014', interestRate: '2,64' },
  { dato: '12.06.2014', interestRate: '2,80' },
  { dato: '23.10.2013', interestRate: '2,96' },
  { dato: '26.04.2013', interestRate: '3,12' },
  // And so on...
];

const InterestRateHistory = () => (
  <BasePage heading="Rentehistorikk">
    <AutoSizer disableHeight>
      {({ width }) => (
        <Table
          width={width}
          height={list.length * 30 + 20} // eslint-disable-line
          headerHeight={20}
          rowHeight={30}
          rowCount={list.length}
          rowGetter={({ index }) => list[index]}
        >
          <Column label="Dato" dataKey="dato" width={100} disableSort />
          <Column
            label="Rentesats (%)"
            dataKey="interestRate"
            width={200}
            disableSort
          />
        </Table>
      )}
    </AutoSizer>
  </BasePage>
);

InterestRateHistory.propTypes = {};

export default InterestRateHistory;
