import styled from 'styled-components';
import { breakpoints, spacing, colors } from 'digitalbank-core-style';
import {
  horizontalLayoutSpacing,
  verticalLayoutSpacing,
} from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-bankid {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__main {
      ${horizontalLayoutSpacing({ phone: false })};
    }

    &__aside {
      ${horizontalLayoutSpacing};
      ${verticalLayoutSpacing};
    }

    &__status {
      ${horizontalLayoutSpacing({ tablet: false })};
      font-size: rem(14);
      color: ${colors.boulder};
    }

    &__description {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__nav-list {
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
    }

    &__tips-article {
      ${horizontalLayoutSpacing({ type: 'margin', tablet: false })};
      ${horizontalLayoutSpacing({ phone: false })};
      ${verticalLayoutSpacing({ phone: false })};
      display: flex;
      align-items: center;
      background: #fff;

      @media (max-width: ${breakpoints.tablet - 1}px) {
        flex-flow: column;
      }

      @media (min-width: ${breakpoints.tablet}px) {
        align-items: flex-start;
      }

      &-icon-container {
        @media (max-width: ${breakpoints.tablet - 1}px) {
          display: inline-block;
          width: 100%;
          padding: ${rem(spacing.layoutSpacing.xs)}; 0;
          border-bottom: 1px solid ${colors.alto};
        }
      }

      &-icon {
        width: 60px;
        min-width: 50px;
        display: flex;
        @media (max-width: ${breakpoints.tablet - 1}px) {
          margin: 0 auto;
        }
        @media (min-width: ${breakpoints.tablet}px) {
          margin-right: 20px;
        }
      }

      &-content {
        ${horizontalLayoutSpacing({ tablet: false })};
        @media (max-width: ${breakpoints.tablet - 1}px) {
          padding-top: ${rem(spacing.layoutSpacing.xs)};
          padding-bottom: ${rem(spacing.layoutSpacing.xs)};
        }
      }

      &-description {
        font-size: rem(14);
      }
    }
  }
`;
