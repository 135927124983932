import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({
  children, visible, trianglePosition, height,
}) => {
  // TODO: Dynamic height like the accordion component
  const tooltipStyle = {
    transform: 'translate(0, -100%)',
    transition: 'transform 300ms ease-in-out',
  };

  const containerStyle = {
    height: '0px',
    transition: 'all 300ms ease-in-out',
    overflowY: 'hidden',
  };
  let triangleModifierClass = '';
  if (trianglePosition) {
    switch (trianglePosition) {
      case 'left':
        triangleModifierClass = 'tooltip--triangle-left';
        break;
      case 'right':
        triangleModifierClass = 'tooltip--triangle-right';
        break;
      case 'center':
        triangleModifierClass = 'tooltip--triangle-center';
        break;
      default:
        triangleModifierClass = 'tooltip--triangle-center';
    }
  }
  if (visible) {
    containerStyle.height = height;
    containerStyle.marginBottom = '20px';
    tooltipStyle.transform = 'translate(0, 0%)';
  }
  return (
    <div style={containerStyle}>
      <div
        className={'tooltip '.concat(triangleModifierClass)}
        style={tooltipStyle}
      >
        <p className="tooltip__content tooltip__content--preview">{children}</p>
      </div>
    </div>
  );
};

Tooltip.defaultProps = {
  trianglePosition: 'center',
  height: '',
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  trianglePosition: PropTypes.string,
  height: PropTypes.string,
};

export default Tooltip;
