import styled from 'styled-components';

export const LogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 1px solid #eaeaea;
  overflow: hidden;

  img {
    max-height: 100%;
  }
`;
