import React from 'react';

const BankIDMobile = props => (
  <i {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 20 29"
    >
      <title>BankID Mobil</title>
      <g fill="none" fillRule="evenodd">
        <g>
          <path
            fill="#4A4A4A"
            d="M0 2.70615136C0 1.21158523 1.1908767 0 2.66711886 0H16.3703143c1.4730091 0 2.6671189 1.2138588 2.6671189 2.70615136V25.9247807c0 1.4945661-1.1908768 2.7061513-2.6671189 2.7061513H2.66711886C1.1941098 28.630932 0 27.4170732 0 25.9247807V2.70615136z"
          />
          <path
            fill="#FFF"
            d="M1.52299465 3.63783213h15.9914439v18.7968534H1.52299465zM6.85347594 1.1598885h5.33048128v1H6.85347594z"
          />
          <ellipse
            cx="9.51871658"
            cy="25.6498151"
            fill="#FFF"
            rx="1.55216159"
            ry="1.57613544"
          />
        </g>
        <path
          fill="#4B78B0"
          d="M10.9327519 11.0640979c.0873676 0 .158493.0717847.158493.1600347v.7664726c0 .0882501-.0711254.1600347-.158493.1600347H8.10462257c-.08736757 0-.15849298-.0717846-.15849298-.1600347v-.7664726c0-.08825.0711254-.1600347.15849297-.1600347h2.82812933zm0 2.090283c.0873676 0 .158493.0717846.158493.1600347v.7664726c0 .0882501-.0711254.1600347-.158493.1600347H8.10462257c-.08736757 0-.15849298-.0717846-.15849298-.1600347v-.7664726c0-.0882501.0711254-.1600347.15849297-.1600347h2.82812933zm4.1387016 2.0902237c.0873676 0 .158493.0717846.158493.1600347v.7664726c0 .0882501-.0711254.1600347-.158493.1600347h-2.8281293c-.0873676 0-.158493-.0717846-.158493-.1600347v-.7664726c0-.0882501.0711254-.1600347.158493-.1600347h2.8281293zm-8.27740315 0c.08736757 0 .158493.0717846.158493.1600347v.7664726c0 .0882501-.07112543.1600347-.158493.1600347H3.96597962c-.0874262 0-.158493-.0717846-.158493-.1600347v-.7664726c0-.0882501.0710668-.1600347.158493-.1600347h2.82807073zm8.27740315-4.1805067c.0873676 0 .158493.0717847.158493.1600347v.7664726c0 .0882501-.0711254.1600347-.158493.1600347h-2.8281293c-.0876021 0-.1586689-.0717846-.1586689-.1600347v-.7664133c0-.0883093.0710668-.160094.158493-.160094h2.8283052zm-8.27740315 2.090283c.08736757 0 .158493.0717846.158493.1600347v.7664726c0 .0882501-.07112543.1600347-.158493.1600347H3.96597962c-.0874262 0-.158493-.0717846-.158493-.1600347v-.7664726c0-.0882501.0710668-.1600347.158493-.1600347h2.82807073zm0-4.1805067c.08736757 0 .158493.07178464.158493.1600347v.76647263c0 .08825007-.07112543.16003467-.158493.16003467H3.96597962c-.0874262 0-.158493-.0717846-.158493-.16003467V9.1339089c0-.08825006.0710668-.1600347.158493-.1600347h2.82807073zm8.27740315 0c.0873676 0 .158493.07178464.158493.1600347v.76647263c0 .08825007-.0711254.16003467-.158493.16003467h-2.8281293c-.0873676 0-.158493-.0717846-.158493-.16003467V9.1339089c0-.08825006.0711254-.1600347.158493-.1600347h2.8281293z"
        />
      </g>
    </svg>
  </i>
);

BankIDMobile.propTypes = {};

export default BankIDMobile;
