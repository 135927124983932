import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  MinusCircle,
  CheckCircle,
  InfoCircle,
  ExclamationCircle,
  Times,
} from 'digitalbank-icons';

const getIcon = (type) => {
  switch (type) {
    case 'success':
      return CheckCircle;
    case 'info':
      return InfoCircle;
    case 'warning':
      return ExclamationCircle;
    case 'danger':
      return MinusCircle;
    default:
      return ExclamationCircle;
  }
};

const Toast = ({
  type,
  loading,
  children,
  className,
  icon,
  hideIcon,
  onCloseClick,
}) => {
  const Icon = icon || getIcon(type);
  return (
    <div
      role="alert"
      className={classNames(className, 'toast', {
        'toast--loading': loading,
        'toast--success': type === 'success',
        'toast--info': type === 'info',
        'toast--warning': type === 'warning',
        'toast--danger': type === 'danger',
        'toast--has-close-button': onCloseClick,
      })}
    >
      {!hideIcon && !loading && <Icon className="toast__icon" />}
      {children}
      {onCloseClick && (
        <button className="toast__close" onClick={onCloseClick}>
          <Times className="toast__close-button-icon" />
        </button>
      )}
    </div>
  );
};

Toast.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.func,
  hideIcon: PropTypes.bool,
  onCloseClick: PropTypes.func,
};

Toast.defaultProps = {
  type: null,
  loading: false,
  children: null,
  className: '',
  icon: null,
  onCloseClick: undefined,
  hideIcon: false,
};

export default Toast;
