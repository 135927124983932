import _ from 'lodash';
import { STATUS } from 'digitalbank-mockup-data/lib/Betalingsavtaler/Status';

const getBetalingsavtaleTilbyderById = (tilbydere, tilbyderId) =>
  _.find(tilbydere, x => x.id === tilbyderId);

const getBetalingsavtaleByTilbyderId = (avtaler, id) =>
  _.find(avtaler, x => x.tilbyderId === id);

const isAvtaleActive = avtale => avtale.status === STATUS.ACTIVE;
const isAvtaleNotActive = avtale => avtale.status === STATUS.NOT_ACTIVE;
const isAvtaleDeleted = avtale => avtale.status === STATUS.DELETED;
const isAvtaleStopped = avtale => avtale.status === STATUS.STOPPED;

const isEfakturaActive = (betalingsavtale) => {
  if (betalingsavtale.efaktura && isAvtaleActive(betalingsavtale.efaktura)) {
    return true;
  }
  return false;
};

const isAvtalegiroActive = (betalingsavtale) => {
  if (
    betalingsavtale.avtalegiro &&
    isAvtaleActive(betalingsavtale.avtalegiro)
  ) {
    return true;
  }
  return false;
};

const isEfakturaPresentable = efaktura => efaktura && isAvtaleActive(efaktura);
const isAvtalegiroPresentable = avtalegiro =>
  avtalegiro && (isAvtaleActive(avtalegiro) || isAvtaleStopped(avtalegiro));

const filterPresentableBetalingsavtale = (
  betalingsavtale,
  automaticEfakturaIsActive,
) => {
  if (automaticEfakturaIsActive) {
    return isAvtaleActive(betalingsavtale.avtalegiro);
  }
  return (
    isEfakturaPresentable(betalingsavtale.efaktura) ||
    isAvtalegiroPresentable(betalingsavtale.avtalegiro)
  );
};

const filterSuggestedBetalingsavtaleTilbydere = (
  currentBetalingsavtaler,
  tilbydere,
  betalingsavtaleForslag,
  automaticEfakturaIsActive = false,
) =>
  _.filter(betalingsavtaleForslag, (forslag) => {
    const tilbyder = getBetalingsavtaleTilbyderById(
      tilbydere,
      forslag.tilbyderId,
    );
    const existingBetalingsavtale = getBetalingsavtaleByTilbyderId(
      currentBetalingsavtaler,
      tilbyder.id,
    );
    if (automaticEfakturaIsActive) {
      if (!tilbyder.hasAvtalegiro) {
        return false;
      }
      if (existingBetalingsavtale) {
        return !isAvtalegiroPresentable(existingBetalingsavtale.avtalegiro);
      }
      return true;
    }

    if (!existingBetalingsavtale) {
      return true;
    }
    if (tilbyder.hasEfaktura && tilbyder.hasAvtalegiro) {
      return !(
        isEfakturaPresentable(existingBetalingsavtale.efaktura) &&
        isAvtalegiroPresentable(existingBetalingsavtale.avtalegiro)
      );
    }
    if (tilbyder.hasEfaktura) {
      return !isEfakturaPresentable(existingBetalingsavtale);
    }
    if (tilbyder.hasAvtalegiro) {
      return !isAvtalegiroPresentable(existingBetalingsavtale.avtalegiro);
    }
    return false;
  });

const getSuggestedBetalingsavtaleData = (
  betalingsavtaleForslag,
  currentBetalingsavtaler,
  betalingsavtaleTilbydere,
) => {
  const existingBetalingsavtale = getBetalingsavtaleByTilbyderId(
    currentBetalingsavtaler,
    betalingsavtaleForslag.tilbyderId,
  );
  const tilbyder = getBetalingsavtaleTilbyderById(
    betalingsavtaleTilbydere,
    betalingsavtaleForslag.tilbyderId,
  );
  return {
    ...betalingsavtaleForslag,
    tilbyder,
    suggestEfaktura: existingBetalingsavtale
      ? !isEfakturaPresentable(existingBetalingsavtale.efaktura)
      : true,
    suggestAvtalegiro: existingBetalingsavtale
      ? !isAvtalegiroPresentable(existingBetalingsavtale.avtalegiro)
      : true,
  };
};

const getBetalingsavtaleData = (betalingsavtale, betalingsavtaleTilbydere) => ({
  ...betalingsavtale,
  tilbyder: getBetalingsavtaleTilbyderById(
    betalingsavtaleTilbydere,
    betalingsavtale.tilbyderId,
  ),
});

const filterPresentableBetalingsavtaler = (
  betalingsavtaler,
  automaticEfakturaIsActive,
) =>
  _.filter(betalingsavtaler, x =>
    filterPresentableBetalingsavtale(x, automaticEfakturaIsActive),
  );

const getToastDeleteText = (avtale) => {
  const efakturaIsActive = isEfakturaActive(avtale);
  if (efakturaIsActive && isAvtalegiroActive(avtale)) {
    return 'AvtaleGiro og eFaktura ble slettet';
  }
  if (efakturaIsActive) {
    return 'eFaktura ble slettet';
  }
  return 'AvtaleGiro ble slettet';
};

export {
  getSuggestedBetalingsavtaleData,
  getBetalingsavtaleData,
  getToastDeleteText,
  filterSuggestedBetalingsavtaleTilbydere,
  getBetalingsavtaleTilbyderById,
  getBetalingsavtaleByTilbyderId,
  isEfakturaActive,
  isAvtalegiroActive,
  isAvtaleActive,
  isAvtaleNotActive,
  isAvtaleStopped,
  isAvtaleDeleted,
  filterPresentableBetalingsavtaler,
};

export default filterSuggestedBetalingsavtaleTilbydere;
