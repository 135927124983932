import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from 'digitalbank-buttons';
import Alert from 'digitalbank-alert';
import { Pencil } from 'digitalbank-icons';
import historyShape from 'shapes/history.shape';
import {
  closeRegionalBlockingLocation,
  openRegionalBlockingLocation,
  openEditModeOnRegionalBlocking,
  closeEditModeOnRegionalBlocking,
  toggleRegionalBlockingLocation,
  toggleLocationInfo,
} from 'actions';
import { RegionBlockingMap } from 'components';
import RegionalBlockingOpenLocations from 'components/RegionalBlockingOpenLocations';
import FakeProgressButton from 'components/FakeProgressButton';
import RegionalBlockingLocationOption from 'routes/RegionalBlocking/RegionalBlockingLocationOption';
import { RegionalBlockingRegions } from 'data';
import RegionalBlockingMapContainerStyled from './RegionalBlockingMapContainerStyled';
import RegionalBlockingPeriodicControlStyled from './RegionalBlockingPeriodicControlStyled';

const locationIsOpen = (openLocationIds, id) =>
  openLocationIds.indexOf(id) > -1;

const MapInfo = ({
  openLocationIds, toggleLocation, isEditMode, children,
}) => (
  <section
    className={classNames('regional-blocking-map-container__map-info', {
      'regional-blocking-map-container__map-info--is-not-editable': !isEditMode,
      'regional-blocking-map-container__map-info--is-editable': isEditMode,
    })}
  >
    <RegionBlockingMap
      openLocationIds={openLocationIds}
      toggleLocation={toggleLocation}
      className="regional-blocking-map-container__svg"
    />
    <div>
      {openLocationIds &&
        openLocationIds.length > 0 && (
          <RegionalBlockingOpenLocations openLocationIds={openLocationIds} />
        )}
      <div className="regional-blocking-map-container__map-info__map-info-children">
        {children}
      </div>
    </div>
  </section>
);

MapInfo.defaultProps = {
  openLocationIds: null,
  toggleLocation: undefined,
  children: null,
  isEditMode: false,
};

MapInfo.propTypes = {
  openLocationIds: PropTypes.arrayOf(PropTypes.number),
  toggleLocation: PropTypes.func,
  isEditMode: PropTypes.bool,
  children: PropTypes.node,
};

const RegionalBlockingPeriodicControl = ({
  periodicControlRegionalBlocking,
  onToggleRegionalBlockingLocation,
  onOpenEditModeOnRegionalBlocking,
  onCloseEditModeOnRegionalBlocking,
  onToggleLocationInfo,
  onCloseRegionalBlockingLocation,
  onOpenRegionalBlockingLocation,
  history,
}) => {
  const {
    visibleLocationInfoIds,
    openLocations,
    editMode,
  } = periodicControlRegionalBlocking;
  const openLocationIds = openLocations;

  const toggleLocation = (openIds, id) => {
    if (locationIsOpen(openIds, id)) {
      onCloseRegionalBlockingLocation(id);
    } else {
      onOpenRegionalBlockingLocation(id);
    }
  };
  const locationOptions = RegionalBlockingRegions.map(
    (location, locationIndex) => (
      <RegionalBlockingLocationOption
        key={`location-option-${location.name}`}
        location={location}
        toggleLocationInfo={() => onToggleLocationInfo(locationIndex)}
        toggleLocation={() => toggleLocation(openLocationIds, location.id)}
        isOpen={locationIsOpen(openLocationIds, location.id)}
        infoIsOpen={visibleLocationInfoIds.indexOf(locationIndex) > -1}
      />
    ),
  );
  const toggleLocationFunc = editMode
    ? id => onToggleRegionalBlockingLocation(id)
    : undefined;
  return (
    <RegionalBlockingPeriodicControlStyled>
      <div className="p-regional-blocking-periodic">
        <div className="p-regional-blocking-periodic__container">
          <h1>Hvor skal kortene dine kunne brukes?</h1>
          <p>Kortene vil alltid være åpne i Norge.</p>
          <Alert
            small
            type="info"
            className="p-regional-blocking-periodic__info-alert"
          >
            <p>
              <strong>Vi har forenklet regionssperren.</strong> Fra nå av vil
              innstillingen gjelde for alle kort.
            </p>
          </Alert>
          {editMode && (
            <RegionalBlockingMapContainerStyled>
              <div className="regional-blocking-map-container">
                <ul className="regional-blocking-map-container__map-list">
                  {locationOptions}
                </ul>
                <MapInfo
                  openLocationIds={openLocationIds}
                  toggleLocation={toggleLocationFunc}
                  isEditMode
                />
              </div>
              <FakeProgressButton
                className="p-regional-blocking-periodic__save-button"
                primary
                shouldReset={false}
                onClick={() => {
                  setTimeout(() => {
                    history.push('/');
                    onCloseEditModeOnRegionalBlocking();
                  }, 250);
                }}
              />
            </RegionalBlockingMapContainerStyled>
          )}
          {!editMode && (
            <section>
              <MapInfo openLocationIds={openLocationIds}>
                <Button
                  variant="edit"
                  size="medium"
                  leftIcon={<Pencil />}
                  onClick={onOpenEditModeOnRegionalBlocking}
                >
                  Endre regionsperre
                </Button>
              </MapInfo>
              <Button
                variant="primary"
                to="/"
                component={Link}
                className="p-regional-blocking-periodic__save-button"
              >
                Innstillingene er riktig
              </Button>
            </section>
          )}
          <p className="regional-blocking-bottom-text">
            Du kan alltid endre regionsperre senere, under <i>Kort</i>
          </p>
        </div>
      </div>
    </RegionalBlockingPeriodicControlStyled>
  );
};

RegionalBlockingPeriodicControl.propTypes = {
  periodicControlRegionalBlocking: PropTypes.shape({
    visibleLocationInfoIds: PropTypes.arrayOf(PropTypes.number),
    openLocations: PropTypes.arrayOf(PropTypes.number),
    editMode: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleRegionalBlockingLocation: PropTypes.func.isRequired,
  onOpenEditModeOnRegionalBlocking: PropTypes.func.isRequired,
  onCloseEditModeOnRegionalBlocking: PropTypes.func.isRequired,
  onToggleLocationInfo: PropTypes.func.isRequired,
  onOpenRegionalBlockingLocation: PropTypes.func.isRequired,
  onCloseRegionalBlockingLocation: PropTypes.func.isRequired,
  history: historyShape.isRequired,
};

const mapStateToProps = state => ({
  periodicControlRegionalBlocking: state.periodicControlRegionalBlocking,
});

const mapDispatchToProps = dispatch => ({
  onOpenRegionalBlockingLocation: bindActionCreators(
    openRegionalBlockingLocation,
    dispatch,
  ),
  onCloseRegionalBlockingLocation: bindActionCreators(
    closeRegionalBlockingLocation,
    dispatch,
  ),
  onCloseEditModeOnRegionalBlocking: bindActionCreators(
    closeEditModeOnRegionalBlocking,
    dispatch,
  ),
  onOpenEditModeOnRegionalBlocking: bindActionCreators(
    openEditModeOnRegionalBlocking,
    dispatch,
  ),
  onToggleRegionalBlockingLocation: bindActionCreators(
    toggleRegionalBlockingLocation,
    dispatch,
  ),
  onToggleLocationInfo: bindActionCreators(toggleLocationInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  RegionalBlockingPeriodicControl,
);
