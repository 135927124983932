import _ from 'lodash';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';
import { BSU, KREDITT } from 'digitalbank-mockup-data/lib/AccountTypes';
import SelectedAccounts from 'digitalbank-mockup-data/lib/SelectedAccounts';
import {
  NUDGE_MODE_TEXTANDGRAPH,
  NUDGE_MODE_TEXTONLY,
  SUMMER_ALLE_FORFALL,
} from 'digitalbank-account-list/lib/accountList.constants';
import * as PATHS from 'routes/paths';

export const getAccount = id => _.find(Accounts, x => x.id === id);
export const getAccountFromAccountNumber = (accounts, accountNumber) =>
  _.find(accounts, x => x.accountNumber === accountNumber);
export const getAccounts = () => Accounts;
export const getSelectedAccounts = () =>
  SelectedAccounts.map((account, i) => ({
    ...account,
    orderIndex: i,
  }));
export const getAccountBelopType = (account) => {
  if (account.type === BSU) return 'Saldo';
  if (account.availableAmount < 0) {
    return 'Saldo';
  }
  return 'Disponibelt';
};

const getUpsaleText = (account) => {
  if (account.type === KREDITT) {
    return 'Søk om inntil 100 000 i kreditt';
  }
  if (account.type === BSU) {
    return '0 av 25 000 spart i år';
  }
  return '';
};

const getNudgeMode = (account) => {
  if (account.type === BSU || account.type === KREDITT) {
    return NUDGE_MODE_TEXTANDGRAPH;
  }
  return NUDGE_MODE_TEXTONLY;
};

const getNudgeData = account => ({
  mode: getNudgeMode(account),
  upsaletext: getUpsaleText(account),
  upsaletextlinetwo: '',
});

export const mapAccountsToAccountListComponent = accounts =>
  accounts.map(account => ({
    ...account,
    id: account.accountNumber || account.id,
    accountName: account.visningsnavn
      ? account.visningsnavn
      : account.accountName,
    beloepType: getAccountBelopType(account),
    visningsBeloep: account.availableAmount,
    disponibelt: account.availableAmount,
    saldo: account.availableAmount,
    isInOvertrekk: false,
    nudgeData: getNudgeData(account),
    debetkortIcon: account.cardId
      ? {
        tekst: 'Debetkort',
      }
      : undefined,
    disposisjonsrettIcon: account.userIds
      ? {
        tekst: 'Disposisjonsrett',
      }
      : undefined,
    reservedAmount: {
      beloep: account.reservedAmount,
      isEstimatedValutakurs: false,
      forfallsFilter: {
        type: SUMMER_ALLE_FORFALL,
        date: 'date',
      },
    },
    sisteBevegelserUrl: PATHS.SEARCH,
    actions: [
      {
        tekst: 'Betale regning',
        url: PATHS.PAY,
      },
      {
        tekst: 'Overføre',
        url: PATHS.TRANSFER,
      },
      {
        tekst: 'Siste bevegelser',
        url: PATHS.SEARCH,
      },
      {
        tekst: 'Forfallsmappe',
        url: PATHS.SCHEDULED_PAYMENTS,
      },
      {
        tekst: 'Gi andre tilgang',
        url: '/',
      },
      {
        tekst: 'Kontodetaljer',
        url: '/',
      },
    ],
  }));
