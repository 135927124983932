import React from 'react';
import { BasePage } from 'digitalbank-layout';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import * as PATHS from 'routes/paths';
import i18n from 'i18n';
import PaymentForm from '../PaymentForm';
import initialValues from '../payment.initialValues';
import validate from '../payment.validate';
import { BETALE_EN_FORM_NAME } from '../payment.constants';
import PaymentAsideContent from '../PaymentAsideContent';
import PaymentStyled from '../PaymentStyled';

const BetaleEn = ({ history, handleSubmit, submitting }) => (
  <PaymentStyled>
    <BasePage
      heading={i18n(t => t.ROUTE_TITLE_PAY)}
      className="p-payment"
      asideContent={<PaymentAsideContent />}
    >
      <PaymentForm
        formName={BETALE_EN_FORM_NAME}
        isSubmitting={submitting}
        handleSubmit={handleSubmit(
          () =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                history.push(PATHS.PAYMENT_SUMMARY);
              }, 500);
            }),
        )}
      />
    </BasePage>
  </PaymentStyled>
);

BetaleEn.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

export default reduxForm({
  destroyOnUnmount: false,
  form: BETALE_EN_FORM_NAME,
  initialValues,
  validate,
})(BetaleEn);
