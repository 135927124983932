import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, Fields } from 'redux-form';
import postalCodes from 'norway-postal-codes';
import _ from 'lodash';
import { Home, Phone, EnvelopeO, Plus } from 'digitalbank-icons';
import { InputField, TextareaField, Legend } from 'digitalbank-form';
import PhoneField from 'digitalbank-form/lib/es/components/PhoneField/PhoneField';
import CountrySelectField from 'digitalbank-form/lib/es/components/CountrySelectField/CountrySelectField';
import i18n from 'i18n';
import Button from 'digitalbank-buttons';
import {
  all as allCountries,
  findCountries,
  findCountryPhoneCode,
} from 'spv-country-data';
import { updateInformation } from 'actions/user.actions';

class ContactInfoFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displaySecondPhone: false,
      displaySecondEmail: false,
    };
    this.displaySecondEmail = this.displaySecondEmail.bind(this);
    this.displaySecondPhone = this.displaySecondPhone.bind(this);
  }
  displaySecondEmail() {
    this.setState({
      ...this.state,
      displaySecondEmail: true,
    });
  }
  displaySecondPhone() {
    this.setState({
      ...this.state,
      displaySecondPhone: true,
    });
  }
  render() {
    const { isForeigner, toggleForeignerForm } = this.props;
    const { displaySecondPhone, displaySecondEmail } = this.state;

    const foreignerLinkText = isForeigner
      ? 'Addresse i Norge?'
      : 'Addresse i utlandet?';
    let location = postalCodes[this.props.zipCode];
    if (this.props.zipCode && this.props.zipCode.length === 4 && !location) {
      location = 'Ukjent postnummer';
    } else if (location) {
      location = _.capitalize(location);
    }

    const KONTAKTINFORMASJON_MOBILTELEFON = i18n(
      t => t.KONTAKTINFORMASJON_MOBILTELEFON,
    );

    const KONTAKTINFORMASJON_TELEFON = i18n(t => t.KONTAKTINFORMASJON_TELEFON);

    const KONTAKTINFORMASJON_ANNENTELEFON_SHORT = i18n(
      t => t.KONTAKTINFORMASJON_ANNENTELEFON_SHORT,
    );
    return (
      <form
        className="p-contact-info__main-content contact-form"
        onSubmit={this.props.handleSubmit((values) => {
          this.props.onUpdateInformation(values);
        })}
      >
        <fieldset className="fieldset">
          <Legend
            text="Adresse"
            icon={<Home className="legend__icon" />}
            tooltipText="Adressen er den sist lagrede adressen, enten fra folkeregistreret eller endringer du har gjort selv i nettbanken."
            tooltipButtonAriaLabel="Trykk for å vise mer informasjon om adressefeltet."
          />
          {!isForeigner && (
            <span>
              <div className="connected-form-groups">
                <Field
                  name="address"
                  component={InputField}
                  type="text"
                  label="Postaddresse"
                />
                <Field
                  name="addressInfo"
                  component={InputField}
                  transformableLabel={false}
                  type="text"
                  label="Addresselinje 2"
                />
              </div>
              <div className="contact-form__location">
                <Field
                  name="zipCode"
                  component={InputField}
                  type="tel"
                  label="Postnummer"
                  className="contact-form__location-code"
                  maxLength="4"
                />
                {location && (
                  <div
                    className="form-group contact-form__location-name"
                    aria-live="polite"
                    role="status"
                  >
                    {location}
                  </div>
                )}
              </div>
            </span>
          )}
          {isForeigner && (
            <span>
              <Field
                name="foreignerAddress"
                component={TextareaField}
                type="text"
                label="Postaddresse utland"
              />
              <Field
                component={CountrySelectField}
                name="foreignerCountry"
                defaultValueText="Velg land"
                label="Land"
                transformableLabel={false}
                countriesShortList={findCountries('GB, PL, US')}
                countries={allCountries}
                ariaLabel=""
                displayCountryPhoneCode={false}
              />
              <p className="contact-form__foreigner-text">
                Utlandsadresser krever manuell behandling og det vil derfor ta
                1-2 virkedager før endringen er lagret.
              </p>
            </span>
          )}
          <Button
            variant="link"
            onClick={(e) => {
              e.preventDefault();
              toggleForeignerForm();
            }}
          >
            {foreignerLinkText}
          </Button>
        </fieldset>
        <fieldset className="fieldset">
          <Legend
            text={KONTAKTINFORMASJON_TELEFON}
            icon={<Phone className="legend__icon" />}
          />
          <Fields
            names={['phone', 'phoneCountryCode']}
            component={PhoneField}
            countriesShortList={findCountries('NO, GB, SE')}
            countries={allCountries}
            numberField={{
              name: 'phone',
              label: KONTAKTINFORMASJON_MOBILTELEFON,
              ariaLabel: 'Mobiltelefon',
            }}
            countryCodeField={{
              name: 'phoneCountryCode',
              label: 'Land',
              ariaLabel: 'Land',
              findCountryPhoneCode,
            }}
          />
          {displaySecondPhone && (
            <Fields
              names={['otherPhone', 'otherPhoneCountryCode']}
              component={PhoneField}
              countriesShortList={findCountries('NO, GB, SE')}
              countries={allCountries}
              numberField={{
                name: 'otherPhone',
                label: KONTAKTINFORMASJON_ANNENTELEFON_SHORT,
                ariaLabel: KONTAKTINFORMASJON_ANNENTELEFON_SHORT,
              }}
              countryCodeField={{
                name: 'otherPhoneCountryCode',
                label: 'Land',
                ariaLabel: 'Land',
                findCountryPhoneCode,
              }}
            />
          )}
          {!displaySecondPhone && (
            <Button
              variant="neutral"
              onClick={this.displaySecondPhone}
              leftIcon={<Plus />}
            >
              Legg til telefonnummer
            </Button>
          )}
        </fieldset>
        <fieldset className="fieldset">
          <Legend text="E-post" icon={<EnvelopeO className="legend__icon" />} />
          <Field
            name="email"
            component={InputField}
            type="email"
            label="E-post privat"
          />
          {displaySecondEmail && (
            <Field
              name="otherEmail"
              component={InputField}
              type="email"
              label="E-post arbeid"
            />
          )}
          {!displaySecondEmail && (
            <Button
              variant="neutral"
              onClick={this.displaySecondEmail}
              leftIcon={<Plus />}
            >
              Legg til e-post arbeid
            </Button>
          )}
        </fieldset>
        {this.props.action}
      </form>
    );
  }
}

ContactInfoFormContainer.defaultProps = {
  zipCode: '',
};

ContactInfoFormContainer.propTypes = {
  zipCode: PropTypes.string,
  isForeigner: PropTypes.bool.isRequired,
  toggleForeignerForm: PropTypes.func.isRequired,
  action: PropTypes.element.isRequired,
  onUpdateInformation: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const validate = () => {
  const errors = {};
  return errors;
};

const form = reduxForm({
  form: 'contactForm',
  validate,
})(ContactInfoFormContainer);

const mapStateToProps = (state) => {
  const selector = formValueSelector('contactForm');
  return {
    zipCode: selector(state, 'zipCode'),
    initialValues: state.user.information,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateInformation: values => dispatch(updateInformation(values)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(form);
