import firebaseApi from 'utils/firebase';

export const initUser = user => ({
  type: 'INIT_USER',
  user,
});

export const removeUser = () => ({
  type: 'REMOVE_USER',
});

export function signOut() {
  return dispatch =>
    firebaseApi
      .authSignOut()
      .then(() => {
        dispatch(removeUser());
      })
      .catch((error) => {
        // @TODO better error handling
        throw error;
      });
}
