// eslint-disable max-len

const template = {
  SAMTYKKE_HEADING: {
    'nb-NO': 'Samtykke til behandling av personopplysninger',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_UNDERAGE_ALERT_TITLE: {
    'nb-NO': 'Vi kan ikke hente inn samtykke.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_UNDERAGE_ALERT_TEXT: {
    'nb-NO': 'Du må være over 18 år.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_SAVE: {
    'nb-NO': 'LAGRE',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_SAVE_ARIA: {
    'nb-NO': 'unknown',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_SAVE_ERRORS_ARIA: {
    'nb-NO':
      'Skjemaet inneholder feil. Vennligst rett opp i disse og prøv igjen.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_FETCH_ERROR_TITLE: {
    'nb-NO': 'Feil ved henting av data',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_UPDATE_ERROR_TITLE: {
    'nb-NO': 'Vi klarte ikke å lagre endringene dine. Prøv igjen senere.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_ERROR_MESSAGE: {
    'nb-NO':
      'Det er dessverre ikke mulig å benytte denne tjenesten akkurat nå. Prøv igjen litt senere.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_UPDATE_SUCCESSFUL: {
    'nb-NO': 'Endringene ble lagret ',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_TOGGLE_MODAL_BUTTON_TITLE: {
    'nb-NO': 'Se fullstendige vilkår.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_MODAL_CARD_CANCEL_BUTTON_TITLE: {
    'nb-NO': 'Lukk',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_MODAL_TITLE: {
    'nb-NO': 'Samtykke til behandling av personopplysninger',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_MODAL_CLOSE_TITLE: {
    'nb-NO': 'Gå videre for å se fullstendige vilkår. Klikk for å lukke',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_PERIODIC_ACCEPT: {
    'nb-NO': 'Ja takk',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_PERIODIC_POSTPONE: {
    'nb-NO': 'Ikke nå',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_PERIODIC_ERROR: {
    'nb-NO': 'Ikke nå',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_PERIODIC_COULD_NOT_SAVE_CHANGES: {
    'nb-NO': 'Vi klarte ikke å lagre opplysningene dine.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_PERIODIC_YOU_WILL_BE_ASKED_AGAIN: {
    'nb-NO': 'Du vil bli spurt igjen neste gang du logger inn.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SAMTYKKE_PERIODIC_NEXT: {
    'nb-NO': 'Gå videre',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
};

export default template;
