import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ConfirmationButtonRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayConfirmation: false,
    };

    this.toggleConfirmation = this.toggleConfirmation.bind(this);
  }

  toggleConfirmation() {
    this.setState({
      displayConfirmation: !this.state.displayConfirmation,
    });
  }

  render() {
    const {
      disabled, Button, ConfirmButton, CancelButton,
    } = this.props;
    const { displayConfirmation } = this.state;

    return (
      <div className="action-list__delete-row">
        <Button onClick={this.toggleConfirmation} />
        <div
          className={classNames('action-list__delete-actions', {
            'action-list__delete-actions--visible': displayConfirmation,
            'action-list__delete-actions--disabled': disabled,
          })}
        >
          {!disabled && <ConfirmButton />}
          {!disabled && <CancelButton onClick={this.toggleConfirmation} />}
        </div>
      </div>
    );
  }
}

ConfirmationButtonRow.propTypes = {
  disabled: PropTypes.bool,
  Button: PropTypes.func.isRequired,
  ConfirmButton: PropTypes.func.isRequired,
  CancelButton: PropTypes.func.isRequired,
};

ConfirmationButtonRow.defaultProps = {
  disabled: false,
};

export default ConfirmationButtonRow;
