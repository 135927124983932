import React from 'react';
import PropTypes from 'prop-types';
// import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
// import { Link } from 'react-router-dom';
// import * as PATHS from 'routes/paths';
import { RadioGroupField } from 'digitalbank-form';
import SettingsCard from './SettingsCard';

const options = [
  {
    value: 'nb-NO',
    label: 'Bokmål',
  },
  {
    value: 'nn-NO',
    label: 'Nynorsk',
  },
  {
    value: 'en-GB',
    label: 'Engelsk',
  },
];

const SettingsCardForLanguage = ({ locale, setLocale }) => (
  <SettingsCard heading="Språk">
    <div className="settings-card__container">
      <RadioGroupField
        options={options}
        meta={{}}
        input={{
          value: locale,
          onChange: (e) => {
            setLocale(e.target.value);
          },
        }}
      />
    </div>
    {/* <NavList
      items={[
        <NavListLink
          key="language"
          title={{ text: 'Norsk bokmål' }}
          info={{
            text: 'Endre',
          }}
          variant={TASK}
          component={Link}
          to={PATHS.SETTINGS}
        />,
      ]}
    /> */}
  </SettingsCard>
);

SettingsCardForLanguage.propTypes = {
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

export default SettingsCardForLanguage;
