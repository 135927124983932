import React, { Component } from 'react';
import ReactHighcharts from 'react-highcharts';
import { getLoanData, getCurrentPeriod } from 'utils/loan.util';
import loanData from './testLoanData';
import getConfig from './annuityLoanGraph.config';

export default class AnnuityLoanGraph extends Component {
  static setInitialTooltip(chart, index) {
    chart.series[0].data[index - 1].setState('hover');
    chart.tooltip.refresh(chart.series[0].data[index - 1]);
  }
  constructor(props) {
    super(props);
    const loanGraphData = getLoanData(
      loanData.startDate,
      loanData.nominellRente,
      loanData.yearsOfLoan,
      loanData.amount,
    );
    this.currentPeriod = getCurrentPeriod(loanData.startDate);
    this.data = loanGraphData.map(x => ({
      x: new Date(x.date).valueOf(),
      y: Math.round(x.remainingLoanValue),
    }));
  }
  componentDidMount() {
    const chart = this.chart.getChart();
    AnnuityLoanGraph.setInitialTooltip(chart, this.currentPeriod);
  }
  render() {
    return (
      <ReactHighcharts
        config={getConfig(this.data, this.currentPeriod)}
        isPureConfig
        ref={(chart) => {
          this.chart = chart;
        }}
      />
    );
  }
}
