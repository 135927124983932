import React from 'react';
import { Field } from 'redux-form';
import { InputField } from 'digitalbank-form';
import classNames from 'classnames';
import { showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb } from 'digitalbank-form/lib/es/utils/showErrorCallbacks.util';
import DatePickerInput from './DatePickerInput';

export default (fields) => {
  const {
    kroner,
    oere,
    kronerLabel = '',
    oereLabel = '',
    datoLabel = '',
    disabled = false,
    isSkeleton = false,
  } = fields;
  const isDirty =
    kroner.meta.active ||
    kroner.input.value !== '' ||
    (oere.meta.active || oere.input.value !== '');

  return (
    <div
      className={classNames('amount-and-date-row', {
        skeleton: isSkeleton,
      })}
    >
      <div className="amount-and-date-row__amount-container">
        <div className="amount-and-date-row__integers">
          <Field
            component={InputField}
            name="kroner"
            disabled={disabled}
            type="tel"
            label={kronerLabel}
            active={isDirty}
            pattern="[0-9]*"
            displayError={false}
            autoComplete="off"
          />
        </div>
        <div className="amount-and-date-row__decimals">
          <Field
            component={InputField}
            name="oere"
            disabled={disabled}
            type="tel"
            label={oereLabel}
            active={isDirty}
            pattern="[0-9]*"
            displayError={false}
            autoComplete="off"
          />
        </div>
        <div
          className={classNames('form-error', {
            'form-error--active': showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb(
              fields.kroner.meta,
            ),
          })}
          role="alert"
        >
          {showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb(
            fields.kroner.meta,
          ) && <span>{fields.kroner.meta.error}</span>}
        </div>
      </div>
      <Field
        component={DatePickerInput}
        name="dato"
        disabled={disabled}
        id="dato"
        label={datoLabel}
        type="text"
        className="amount-and-date-row__date"
        autoComplete="off"
      />
    </div>
  );
};
