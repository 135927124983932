import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import historyShape from 'shapes/history.shape';
import AvtalegiroFormContainer from './AvtalegiroFormContainer';
import BetalingsavtaleStyled from '../BetalingsavtaleStyled';

const AvtalegiroCreatePage = ({ params, history }) => (
  <BetalingsavtaleStyled>
    <BasePage heading="Opprett AvtaleGiro" className="p-betalingsavtale">
      <AvtalegiroFormContainer
        history={history}
        tilbyderId={params ? params.tilbyderId : undefined}
        submitButtonText="Opprett AvtaleGiro"
      />
    </BasePage>
  </BetalingsavtaleStyled>
);

AvtalegiroCreatePage.defaultProps = {
  params: undefined,
};

AvtalegiroCreatePage.propTypes = {
  history: historyShape.isRequired,
  params: PropTypes.shape({
    tilbyderId: PropTypes.string,
  }),
};

export default AvtalegiroCreatePage;
