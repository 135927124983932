/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BasePage } from 'digitalbank-layout';
import styled from 'styled-components';
import _ from 'lodash';
import { UlWithoutStyle } from 'styled';
// import { getTags } from 'services/economy.service';
// import { customerId } from 'actions/economy.actions';
import {
  breakpoints,
  spacing,
  colors,
  typography,
  layout,
} from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
// import i18n from 'i18n';
import { getTags } from 'actions/tag.actions';
import Spinner from 'digitalbank-spinner';
import Tag from './components/Tag';
import Fade from './components/Fade';

const NavListStyled = styled.div`
  padding: 0 ${layout.horizontalSpacing.phone}px;
  margin-bottom: ${spacing.layoutSpacing.sm}px;
`;

const TagItem = styled.li``;

const TagsStyled = styled(UlWithoutStyle)`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${TagItem} {
    width: 100%;
    /* @media (min-width: ${breakpoints.tablet}px) {
      width: 50%;
    } */
  }
`;

const TagsPageStyled = styled.div`
  h1 {
    padding: 0 ${layout.horizontalSpacing.phone}px;
  }

  ${TagItem} {
    padding: 0.75rem;
  }
`;

const TagSection = styled.section`
  margin-bottom: ${spacing.layoutSpacing.sm}px;
`;

const TagSectionHeading = styled.h2`
  ${horizontalLayoutSpacing()};
  font-family: ${typography.fontFamily.primary};
  color: ${colors.doveGray};
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
`;

class TagsPage extends Component {
  componentDidMount() {
    // if (!this.props.tags.status.received) {
    this.props.getTags();
    // }
  }

  render() {
    const { tags } = this.props;
    let activeTravelTags;
    let inactiveTravelTags;
    let activeDefaultTags;
    let otherTags;

    const hasTags = tags && tags.data && tags.data.length > 0;

    if (hasTags) {
      activeTravelTags = _.orderBy(
        tags.data.filter(x => x.isActive && x.isTravelTag),
        ['isActive', 'name'],
        ['desc', 'asc'],
      );

      inactiveTravelTags = _.orderBy(
        tags.data.filter(x => !x.isActive && x.isTravelTag),
        ['isActive', 'name'],
        ['desc', 'asc'],
      );
      activeDefaultTags = _.orderBy(
        tags.data.filter(x => x.isActive && !x.isTravelTag),
        ['isActive', 'name'],
        ['desc', 'asc'],
      );
      otherTags = _.orderBy(
        tags.data.filter(x => !x.isActive && !x.isTravelTag),
        ['isActive', 'name'],
        ['desc', 'asc'],
      );
    }
    return (
      <TagsPageStyled>
        <BasePage heading="Tagger">
          <NavListStyled>
            <NavList
              items={[
                <NavListLink
                  component={Link}
                  to={PATHS.CREATE_AUTOMATIC_TAG}
                  title={{
                    text: 'Tagg betalinger automatisk',
                  }}
                  key="automatic-tag"
                />,
                <NavListLink
                  component={Link}
                  to={PATHS.CREATE_AUTOMATIC_TAG_TRAVEL}
                  title={{
                    text: 'Tagg betalinger på reise', // i18n(t => t.ROUTE_TITLE_CREATE_AUTOMATIC_TAG_TRAVEL),
                  }}
                  key="automatic-tag-travel"
                />,
                <NavListLink
                  component={Link}
                  to={PATHS.CREATE_AUTOMATIC_TAG_TRAVEL_WITH_WORK}
                  title={{
                    text: 'Tagg betalinger på jobbreise', // i18n(t => t.ROUTE_TITLE_CREATE_AUTOMATIC_TAG_TRAVEL_WORK),
                  }}
                  key="automatic-tag-travel-with-work"
                />,
              ]}
            />
          </NavListStyled>
          {tags &&
            tags.status.requested &&
            !tags.status.received && <Spinner centered large verticalSpacing />}

          <Fade in={hasTags} appear>
            {activeTravelTags &&
              activeTravelTags.length > 0 && (
                <TagSection>
                  <TagSectionHeading>
                    Min {activeTravelTags.length > 1 ? 'reiser' : 'reise'}
                  </TagSectionHeading>
                  <TagsStyled>
                    {activeTravelTags.map(tag => (
                      <TagItem key={`tag-${tag.id}`}>
                        <Tag tag={tag} />
                      </TagItem>
                    ))}
                  </TagsStyled>
                </TagSection>
              )}
            {inactiveTravelTags &&
              inactiveTravelTags.length > 0 && (
                <TagSection>
                  <TagSectionHeading>Tidligere reiser</TagSectionHeading>
                  <TagsStyled>
                    {inactiveTravelTags.map(tag => (
                      <TagItem key={`tag-${tag.id}`}>
                        <Tag tag={tag} />
                      </TagItem>
                    ))}
                  </TagsStyled>
                </TagSection>
              )}
            {activeDefaultTags &&
              activeDefaultTags.length > 0 && (
                <TagSection>
                  <TagSectionHeading>Aktive tagger</TagSectionHeading>
                  <TagsStyled>
                    {activeDefaultTags.map(tag => (
                      <TagItem key={`tag-${tag.id}`}>
                        <Tag tag={tag} />
                      </TagItem>
                    ))}
                  </TagsStyled>
                </TagSection>
              )}
            {otherTags &&
              otherTags.length > 0 && (
                <TagSection>
                  <TagSectionHeading>Andre tagger</TagSectionHeading>
                  <TagsStyled>
                    {otherTags.map(tag => (
                      <TagItem key={`tag-${tag.id}`}>
                        <Tag tag={tag} />
                      </TagItem>
                    ))}
                  </TagsStyled>
                </TagSection>
              )}
          </Fade>
        </BasePage>
      </TagsPageStyled>
    );
  }
}

TagsPage.propTypes = {
  tags: PropTypes.shape({}),
  getTags: PropTypes.func.isRequired,
};

TagsPage.defaultProps = {
  tags: null,
};

const mapStateToProps = ({ tags }) => ({ tags });
const mapDispatchToProps = dispatch => ({
  getTags: () => dispatch(getTags()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TagsPage);
