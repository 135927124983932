import { AvtaleadministrasjonFilters } from 'routes/Avtaleadministrasjon/avtaleadministrasjon.constants';

export const HOME = '/';
export const HOME_ALT = '/min-side';
export const HOME_SETTINGS = '/home-settings';
export const LOGIN = '/login';
export const LOGIN_SUCCESS = '/loginSuccess';

export const PAYMENT_GLOBAL = '/betale/betale-utland';
export const PAYMENT_GLOBAL_SECOND = '/betale/betale-utland/betalingsinfo';
export const PAYMENT_GLOBAL_SUMMARY = '/betale/betale-utland/godkjenn';
export const PAYMENT_GLOBAL_REAUTH = '/betale/betale-utland/reauth';
export const PAYMENT_GLOBAL_RECEIPT = '/betale/betale-utland/kvittering';

export const PAY = '/betale/betale-en';
export const PAYMENT_SUMMARY = '/betale/godkjenn';
export const PAYMENT_REAUTH = '/betale/reauth';
export const PAYMENT_RECEIPT = '/betale/kvittering';
export const PAY_NAVIGATION = '/betale';

export const EDIT_BETALE_EN = '/betale/endre';
export const EDIT_BETALE_EN_RECEIPT = '/betale/endre-kvittering';

export const FASTE_OPPDRAG = '/betale/fasteoppdrag';

export const FAST_BETALING = '/betale/fastbetaling';
export const FAST_BETALING_REAUTH = '/betale/fastbetaling/reauth';
export const FAST_BETALING_RECEIPT = '/betale/fastbetaling/kvittering';

export const FAST_BETALING_EDIT = '/betale/fastbetaling/endre';
export const FAST_BETALING_EDIT_REAUTH = '/betale/fastbetaling/endre/reauth';
export const FAST_BETALING_EDIT_RECEIPT =
  '/betale/fastbetaling/endre/kvittering';

export const SCHEDULED_PAYMENTS = '/betale/forfallsmappe';

export const TRANSFER = '/betale/overfoere';
export const TRANSFER_RECEIPT = '/betale/overfoere/kvittering';
export const TRANSFER_EDIT = '/betale/overfoere/endre';
export const TRANSFER_EDIT_RECEIPT = '/betale/overfoere/endre-kvittering';

export const FAST_OVERFOERING = '/betale/fastoverfoering';
export const FAST_OVERFOERING_RECEIPT = '/betale/fastoverfoering/kvittering';
export const FAST_OVERFOERING_EDIT = '/betale/fastoverfoering/edit';
export const FAST_OVERFOERING_EDIT_RECEIPT =
  '/betale/fastoverfoering/edit/kvittering';

export const ACCOUNT = '/konto';
export const CARD = '/kort';
export const LOANS = '/mine-laan';
export const LOAN = '/laan';
export const LOAN_INTEREST_RATE_HISTORY = '/laan/rentehistorikk';
export const SAVING = '/sparing';
export const ESURANCE = '/forsikring';
export const INBOX = '/inboks';
export const SETTINGS = '/innstillinger';

export const CONTACT_INFO = '/kontaktinformasjon';
export const CONTACT_INFO_PERIODIC_CONTROL =
  '/periodisk-kontroll/kontaktinformasjon';

export const REGIONAL_BLOCKING = '/kort/regionsperre';

export const DATA_CONSENT = '/samtykke';
export const DATA_CONSENT_PERIODIC_CONTROL = '/periodisk-kontroll/samtykke';

export const PERIODIC_CONTROL_REGIONAL_BLOCKING_ISOLATED =
  '/periodisk-kontroll/regionsperre';
export const NOT_FOUND = '*';

export const TRANSACTIONS = '/transaksjoner';
export const CONTACT = '/kontakt-oss';

export const RECIPIENTS = '/mottakere';

export const SEARCH = '/soek';

export const EFAKTURA_CREATE = '/efaktura/opprett';
export const EFAKTURA_UPDATE = '/efaktura/endre';

export const AVTALEGIRO_CREATE = '/avtalegiro/opprett';
export const AVTALEGIRO_UPDATE = '/avtalegiro/endre';

export const EFAKTURA_AND_AVTALEGIRO_CREATE = '/efaktura-avtalegiro/opprett';
export const EFAKTURA_AND_AVTALEGIRO_UPDATE = '/efaktura-avtalegiro/endre';

export const EFAKTURA_AND_AVTALEGIRO = '/efaktura-avtalegiro';
export const EFAKTURA_AND_AVTALEGIRO_SUGGESTIONS = `/efaktura-avtalegiro/${
  AvtaleadministrasjonFilters.FORSLAG
}`;

export const EFAKTURA_FRA_ALLE_INFO = '/efaktura-fra-alle/info';
export const EFAKTURA_FRA_ALLE_ACTIVATION_INFO =
  '/efaktura-fra-alle/oppsett/info';
export const EFAKTURA_FRA_ALLE_ACTIVATION_SETUP =
  '/efaktura-fra-alle/oppsett/innstillinger';
export const EFAKTURA_FRA_ALLE_ACTIVATION_RECEIPT =
  '/efaktura-fra-alle/oppsett/kvittering';

export const EFAKTURA_FRA_ALLE_SETTINGS = '/efaktura-fra-alle/innstillinger';
export const EFAKTURA_FRA_ALLE_SPERREDE_AVSENDERE =
  '/efaktura-fra-alle/sperrede-avsendere';

export const BANKID = '/bankid';
export const BANKID_ORDER = '/bankid/bestill';
export const BANKID_ORDER_RECEIPT = '/bankid/bestill/kvittering';

export const BANKID_MOBILE = '/bankid-mobil';
export const BANKID_MOBILE_ORDER = '/bankid-mobil/bestill';
export const BANKID_MOBILE_ORDER_RECEIPT = '/bankid-mobil/bestill/kvittering';

export const BANKID_NEW_KODEBRIKKE_ORDER = '/bankid-kodebrikke/bestill';
export const BANKID_NEW_KODEBRIKKE_ORDER_RECEIPT =
  '/bankid-kodebrikke/bestill/kvittering';

export const ECONOMY = '/okonomi';
export const ECONOMY_TRANSACTION = '/okonomi/transaksjon';
export const ECONOMY_MERCHANTS = '/okonomi/butikker';

export const DEMO = '/test';

export const TAGS = '/mine-tagger';
export const TAG = '/mine-tagger/tag';
export const CREATE_AUTOMATIC_TAG = '/mine-tagger/automatisk-tagg';

export const TRAVEL = '/mine-tagger/min-reise';
export const TRAVEL_SUMMARY = '/mine-tagger/min-reise/reiseregning';
export const CREATE_AUTOMATIC_TAG_TRAVEL = '/mine-tagger/min-reise/privat';
export const CREATE_AUTOMATIC_TAG_TRAVEL_WITH_WORK =
  '/mine-tagger/min-reise/jobb';
