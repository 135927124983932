import React from 'react';
import PropTypes from 'prop-types';
import { Bars } from 'digitalbank-icons';
import i18n from 'i18n';
import * as PATHS from 'routes/paths';
import TabbarItem from './TabbarItem';

const Tabbar = ({
  toggleSidebar,
  notifications,
  isMenuItemHidden,
  displayBadges,
  isLoggedIn,
}) =>
  (isLoggedIn ? (
    <nav className="tab-bar tab-bar--responsive">
      <ul className="tab-bar__items">
        <TabbarItem
          path={PATHS.HOME}
          label={i18n(t => t.ROUTE_TITLE_HOME)}
          icon="home"
        />
        <TabbarItem
          path={PATHS.PAY_NAVIGATION}
          label={i18n(t => t.ROUTE_TITLE_PAY)}
          icon="exchange"
          notificationCount={
            displayBadges && notifications && notifications.scheduledPayments
              ? notifications.scheduledPayments.count
              : 0
          }
        />
        <TabbarItem
          path={PATHS.ACCOUNT}
          label={i18n(t => t.ROUTE_TITLE_ACCOUNT)}
          icon="book"
        />
        <TabbarItem
          path={PATHS.CARD}
          label={i18n(t => t.ROUTE_TITLE_CARD)}
          icon="credit-card-o"
        />
        {isMenuItemHidden && (
          <TabbarItem
            path={PATHS.DEMO}
            label={i18n(t => t.ROUTE_TITLE_CONTACT)}
            icon="spv-symbol"
          />
        )}
        {!isMenuItemHidden && (
          <li className="tab-bar__item">
            <button
              onClick={toggleSidebar}
              className="tab-bar__link"
              type="button"
            >
              <Bars className="tab-bar__icon" />
              <span className="tab-bar__label">{i18n(t => t.MENU)}</span>
            </button>
          </li>
        )}
      </ul>
    </nav>
  ) : null);

Tabbar.defaultProps = {
  notifications: undefined,
  isMenuItemHidden: false,
  displayBadges: true,
};

Tabbar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  notifications: PropTypes.shape({}),
  isMenuItemHidden: PropTypes.bool,
  displayBadges: PropTypes.bool,
};

export default Tabbar;
