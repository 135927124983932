/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from '@vx/text';
import EconomyGraph from './EconomyGraph';
import { getGraphSumHeadingPeriodText } from '../utils';
import BubbleContent from './BubbleContent';
import CategoryIcon from './CategoryIcon';

const EconomyGraphInstanceStyle = styled.div`
  /* margin-bottom: 40px; */
`;

const GraphSumHeading = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const GraphSumHeadingAmount = styled.div`
  font-size: 40px;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  margin-bottom: 0;
`;

const GraphSumHeadingPeriod = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: #777;
`;

class EconomyGraphInstance extends Component {
  shouldComponentUpdate(nextProps) {
    if (!nextProps.isLoading) {
      return true;
    }
    return false;
  }
  render() {
    const {
      hasCategory,
      setCategory,
      categoryData,
      dateFilter,
      mainCategory,
    } = this.props;
    const sumValue =
      categoryData.sum < 0 ? categoryData.sum * -1 : categoryData.sum;
    const graphSum = (
      <GraphSumHeading>
        <GraphSumHeadingAmount>
          {sumValue.toLocaleString('nb-NO', {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </GraphSumHeadingAmount>
        <GraphSumHeadingPeriod>
          {getGraphSumHeadingPeriodText(dateFilter, mainCategory)}
        </GraphSumHeadingPeriod>
      </GraphSumHeading>
    );

    return (
      <EconomyGraphInstanceStyle>
        {!hasCategory && (
          <EconomyGraph
            categoryData={categoryData}
            setCategory={setCategory}
            ContentComponent={({
              node: {
                data: { category, sum },
              },
              ...other
            }) => {
              if (category) {
                return (
                  <BubbleContent
                    onClick={() => setCategory(category.name)}
                    Icon={props => (
                      <CategoryIcon categoryAlias={category.name} {...props} />
                    )}
                    label={`${sum.toLocaleString('nb-NO', {
                      style: 'decimal',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    })}`}
                    color="#fff"
                    {...other}
                  />
                );
              }

              return null;
            }}
          />
        )}
        {hasCategory && (
          <EconomyGraph
            categoryData={categoryData}
            setCategory={setCategory}
            ContentComponent={({
              node: {
                data: { category, name, sum },
                // ...otherNode
              },
              style,
              // ...other
            }) => {
              const size = Math.floor(style.r) * 2;
              if (category) {
                return (
                  <Text
                    width={size}
                    scaleToFit
                    fill="#fff"
                    fontFamily="Oswald-Regular, Oswald"
                    textAnchor="middle"
                    verticalAnchor="middle"
                    onClick={() => setCategory(category.name)}
                  >
                    {name}
                  </Text>
                );
              }

              return null;
            }}
          />
        )}
        {graphSum}
      </EconomyGraphInstanceStyle>
    );
  }
}

EconomyGraphInstance.propTypes = {
  hasCategory: PropTypes.bool.isRequired,
  setCategory: PropTypes.func.isRequired,
  categoryData: PropTypes.shape({}).isRequired,
  dateFilter: PropTypes.string.isRequired,
  mainCategory: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default EconomyGraphInstance;
