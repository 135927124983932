import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isPristine, destroy } from 'redux-form';
import { bindActionCreators } from 'redux';
import { resetAccountPicker } from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import moment from 'moment';
import i18n from 'i18n';
import * as PATHS from 'routes/paths';
import { ReceiptPage } from 'digitalbank-layout';
import wizardPage from 'components/wizardPage';
import { isKidNumber } from 'utils/validation.util';
import { formatAccountNumber } from 'utils/format.util';
import BetaleEnNavList from './BetaleEnNavList';
import { BETALE_EN_FORM_NAME } from '../payment.constants';
import PaymentStyled from '../PaymentStyled';

class BetaleEnReceipt extends Component {
  componentWillUnmount() {
    this.props.onDestroy(BETALE_EN_FORM_NAME);
    this.props.onResetAccountPicker(`${BETALE_EN_FORM_NAME}FraKonto`);
  }
  render() {
    const { values } = this.props;
    const date = moment(values.dato, 'L');
    const dueDateIsToday = date.isSame(moment(), 'day');
    const title = dueDateIsToday
      ? 'Betalingen ble gjennomført'
      : 'Betalingen ble lagt til forfall';

    const dueDate = moment(values.dato, 'L');
    const dueDatePrint = dueDate.format('LL');
    const toAccount =
      values.tilKontonavn || formatAccountNumber(values.tilKonto);
    const amount = i18n(i => i.NUMBER_TWO_DIGITS, {
      number: Number(values.kroner) + Number(values.oere / 100),
    });

    const melding = !isKidNumber(values.kidMelding) ? values.kidMelding : '';
    const kid = isKidNumber(values.kidMelding) ? values.kidMelding : '';

    return (
      <PaymentStyled>
        <ReceiptPage
          className="p-payment"
          receiptTitle={title}
          receiptDescription={
            <div>
              <p style={{ marginBottom: '0px' }}>
                <strong>{amount}</strong>
                {` til ${toAccount} fra ${values.fraKonto.visningsnavn ||
                  values.fraKonto.accountName}`}
                .
              </p>
              <p style={{ marginBottom: '0px' }}>
                {dueDateIsToday ? 'Betalt' : 'Forfall'} {dueDatePrint}.
              </p>
              {kid && (
                <p
                  style={{
                    fontSize: '14px',
                    color: '#82877f',
                    marginBottom: '0px',
                  }}
                >
                  KID: {kid}
                </p>
              )}
              {melding && (
                <p
                  style={{
                    fontSize: '14px',
                    color: '#82877f',
                    marginBottom: '0px',
                  }}
                >
                  Melding: {melding}
                </p>
              )}
            </div>
          }
          asideContent={
            <section className="published-content">
              <div className="published-content__item published-content__frame">
                <h2>Visste du?</h2>
                <p>
                  At du kan vennebetale i mobilbanken? De du betaler til trenger
                  ikke være kunder i Sparebanken Vest, og alt du trenger er
                  mobiltelefonnummeret til den du skal betale til.
                </p>
              </div>
            </section>
          }
        >
          <BetaleEnNavList />
        </ReceiptPage>
      </PaymentStyled>
    );
  }
}

BetaleEnReceipt.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onDestroy: bindActionCreators(destroy, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

const mapStateToProps = state => ({
  values: getFormValues(BETALE_EN_FORM_NAME)(state),
});

export default wizardPage({
  redirectTest: state => isPristine(BETALE_EN_FORM_NAME)(state),
  redirectPath: PATHS.PAY,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(BetaleEnReceipt),
);
