import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import * as PATH from 'routes/paths';
import { RadioGroupField } from 'digitalbank-form';
import Button from 'digitalbank-buttons';
import { BasePage } from 'digitalbank-layout';
import Accordion, { AccordionItemHeader } from 'digitalbank-accordion';
import FakeProgressButton from 'components/FakeProgressButton';
import DataConsentStyle from './DataConsentStyled';

const options = [
  {
    label: 'Ja takk',
    value: 'Accepted',
  },
  {
    label: 'Nei, det ønsker jeg ikke',
    value: 'Rejected',
  },
];

/* eslint-disable max-len */
const DataConsent = ({ isPeriodicControl }) => (
  <DataConsentStyle>
    <BasePage
      className="p-personal-data-consent"
      heading="Samtykke"
      asideContent={
        <section className="published-content">
          <div className="published-content__item published-content__frame">
            <h2>Vi deler ikke informasjonen din</h2>
            <div>
              <p>
                Dine opplysninger er trygt lagret og vil ikke bli distribuert
                videre til andre.
              </p>
            </div>
          </div>
        </section>
      }
    >
      <div>
        <section className="p-personal-data-consent__info">
          <p>
            Vi ønsker å tilby deg en mest mulig relevant kundeopplevelse både i
            nettbanken og når du møter en av våre rådgivere.
          </p>
          <p>
            Vi ønsker derfor ditt samtykke til at vi bruker opplysningene vi
            allerede har registrert om deg til å tilpasse vår rådgivning og
            informasjon både i digitale løsninger (som nettbank) og i møte med
            våre rådgivere.
          </p>
          <p>Samtykket er frivillig og kan når som helst trekkes tilbake.</p>
        </section>
        <Accordion
          items={[
            {
              id: '1',
              header: (
                <AccordionItemHeader
                  heading={{ description: 'Hva betyr dette?' }}
                  hasFixedHeight={false}
                />
              ),
              content: (
                <article style={{ padding: '0 1.125rem', fontSize: '1rem' }}>
                  <p>
                    Konsesjonen vår fra Datatilsynet gjør det mulig for oss å gi
                    deg tilpasset informasjon, rådgivning, anbefalinger og
                    tilbud innenfor samme produktkategori som vi henter
                    opplysninger fra. Våre produktkategorier er:
                  </p>
                  <ul>
                    <li>Betalingstjenester</li>
                    <li>Spare- og innskuddsprodukter</li>
                    <li>Lån og andre kreditter</li>
                    <li>Forsikringsformidling</li>
                  </ul>
                  <p />
                  <p>
                    Samtidig vet vi at for eksempel informasjon om lån og
                    månedlige utgifter er relevant for hvordan du bør spare. For
                    å kunne gi gode råd på tvers av produktkategoriene, ber vi
                    om ditt samtykke til å sammenstille alle opplysningene vi
                    har om deg. Fordi banken formidler en del produkter på vegne
                    av våre produktleverandører, som Frende Forsikring, Brage
                    Finans, Eiendomsmegler Vest, Vipps, Norne Securities samt
                    våre fondsleverandører, omfatter samtykket bruk av
                    opplysninger vi behandler på vegne av disse.
                  </p>
                  <p>
                    Mer informasjon om vår behandling av personopplysninger
                    finner du på{' '}
                    <a
                      className="samtykke-external-link"
                      href="https://www.spv.no/personvern"
                    >
                      spv.no/personvern
                    </a>
                    .
                  </p>
                </article>
              ),
            },
            {
              id: '2',
              header: (
                <AccordionItemHeader
                  heading={{
                    description: 'Vi utleverer ikke informasjonen din',
                  }}
                  hasFixedHeight={false}
                />
              ),
              content: (
                <article style={{ padding: '0 1.125rem', fontSize: '1rem' }}>
                  <p>
                    Konsesjonen vår fra Datatilsynet gjør det mulig for oss å gi
                    deg tilpasset informasjon, rådgivning, anbefalinger og
                    tilbud innenfor samme produktkategori som vi henter
                    opplysninger fra. Våre produktkategorier er:
                  </p>
                  <ul>
                    <li>Betalingstjenester</li>
                    <li>Spare- og innskuddsprodukter</li>
                    <li>Lån og andre kreditter</li>
                    <li>Forsikringsformidling</li>
                  </ul>
                  <p />
                  <p>
                    Samtidig vet vi at for eksempel informasjon om lån og
                    månedlige utgifter er relevant for hvordan du bør spare. For
                    å kunne gi gode råd på tvers av produktkategoriene, ber vi
                    om ditt samtykke til å sammenstille alle opplysningene vi
                    har om deg. Fordi banken formidler en del produkter på vegne
                    av våre produktleverandører, som Frende Forsikring, Brage
                    Finans, Eiendomsmegler Vest, Vipps, Norne Securities samt
                    våre fondsleverandører, omfatter samtykket bruk av
                    opplysninger vi behandler på vegne av disse.
                  </p>
                  <p>
                    Mer informasjon om vår behandling av personopplysninger
                    finner du på{' '}
                    <a
                      className="samtykke-external-link"
                      href="https://www.spv.no/personvern"
                    >
                      spv.no/personvern
                    </a>
                    .
                  </p>
                </article>
              ),
            },
          ]}
        />
      </div>
      {isPeriodicControl && (
        <div className="p-personal-data-consent__actions">
          <div className="p-personal-data-consent__action">
            <Button
              component={Link}
              to={PATH.HOME}
              variant="primary"
              className="p-personal-data-consent-periodic__save-button"
            >
              Ja takk
            </Button>
            <Button
              component={Link}
              to={PATH.HOME}
              variant="cancel"
              className="p-personal-data-consent-periodic__save-button"
            >
              Ikke nå
            </Button>
          </div>
        </div>
      )}
      {!isPeriodicControl && (
        <div>
          <div className="p-personal-data-consent__form">
            <Field
              className="radio-group"
              component={RadioGroupField}
              name="dataConsentStatus"
              options={options}
            />
          </div>
          <div className="p-personal-data-consent__actions">
            <div className="p-personal-data-consent__action">
              <FakeProgressButton className="p-personal-data-consent__save-button" />
            </div>
          </div>
        </div>
      )}
    </BasePage>
  </DataConsentStyle>
);

DataConsent.propTypes = {
  isPeriodicControl: PropTypes.bool,
};

DataConsent.defaultProps = {
  isPeriodicControl: false,
};

const validate = () => {
  const errors = {};
  return errors;
};

const form = reduxForm({
  destroyOnUnmount: false,
  form: 'dataConsentForm',
  fields: ['dataConsentStatus'],
  validate,
})(DataConsent);

export default form;
