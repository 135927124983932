import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getIcon } from 'digitalbank-icons';

const TabBarLink = ({
  path, label, icon, notificationCount,
}) => {
  const Icon = getIcon(icon);
  return (
    <li className="tab-bar__item">
      <NavLink
        exact
        to={path}
        className="tab-bar__link"
        activeClassName="tab-bar__link--active"
        style={{ position: 'relative' }}
      >
        <Icon className="tab-bar__icon" />
        <span className="tab-bar__label">{label}</span>
        {notificationCount > 0 && (
          <div className="badge badge--new tab-bar__badge">
            {notificationCount}
          </div>
        )}
      </NavLink>
    </li>
  );
};

TabBarLink.defaultProps = {
  notificationCount: 0,
};

TabBarLink.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  notificationCount: PropTypes.number,
};

export default TabBarLink;
