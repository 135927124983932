import moment from 'moment';

export default {
  amount: 180000,
  type: 'Annuitetslån', // Annuitets
  startDate: moment()
    .subtract(10, 'years')
    .format(), // åpningsdato
  endDate: moment()
    .add(20, 'years')
    .format(), // innfrielsesdato
  yearsOfLoan: 30,
  nominellRente: 2.2,
  effektivRente: 2.7,
  sikkerhet: 'Pant i fast eiendom',
  medlåntaker: undefined,
  fraKonto: '42334626365',
  beskrivelse: 'Boliglån',
  konto: '36464626326',
  terminomkostninger: 50,
};
