import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { change } from 'redux-helpers/data.actions';
// import * as PATHS from 'routes/paths';
import { BasePage } from 'digitalbank-layout';
import {
  getBetalingsavtaleByTilbyderId,
  // isAvtalegiroActive,
  // getToastDeleteText,
} from 'utils/betalingsavtaler.util';
import { connect } from 'react-redux';
import historyShape from 'shapes/history.shape';
import dataSelector from 'redux-helpers/dataSelector';
// import ActionList from 'components/ActionList';
// import { PlayO, PauseO } from 'digitalbank-icons';
import { registerMessage } from 'actions/messages.actions';
import AvtalegiroFormContainer from './AvtalegiroFormContainer';
// import {
//   deleteAvtalegiroAvtale,
//   startAvtalegiroAvtale,
//   stopAvtalegiroAvtale,
// } from '../avtaleadministrasjon.service';
import BetalingsavtaleStyled from '../BetalingsavtaleStyled';

const AvtalegiroUpdatepage = ({
  params,
  betalingsavtaler,
  // changeData,
  // onRegisterMessage,
  history,
}) => {
  if (betalingsavtaler.status.received) {
    const betalingsavtale = getBetalingsavtaleByTilbyderId(
      betalingsavtaler.data,
      params.tilbyderId,
    );
    if (!betalingsavtale) {
      return <p>Fant ikke betalingsavtale med ID: {params.tilbyderId}</p>;
    }
    // const isActive = isAvtalegiroActive(betalingsavtale);
    // const startStopAction = {
    //   id: 'start-stop-avtalegiro',
    //   title: isActive ? 'Stopp' : 'Start',
    //   Icon: isActive ? PauseO : PlayO,
    //   button: {
    //     onClick: isActive
    //       ? () =>
    //           stopAvtalegiroAvtale(
    //             betalingsavtaler,
    //             changeData,
    //             betalingsavtale.id,
    //           )
    //       : () =>
    //           startAvtalegiroAvtale(
    //             betalingsavtaler,
    //             changeData,
    //             betalingsavtale.id,
    //           ),
    //   },
    // };
    return (
      <BetalingsavtaleStyled>
        <BasePage heading="Endre AvtaleGiro" className="p-betalingsavtale">
          <AvtalegiroFormContainer
            history={history}
            tilbyderId={params ? params.tilbyderId : undefined}
            submitButtonText="Lagre"
            initialValues={betalingsavtale.avtalegiro.values}
          />
          <div className="p-betalingsavtale__action-list-wrapper">
            {/* <ActionList
            className="action-list action-list--is-not-responsive"
            actions={[startStopAction]}
            deleteErrorMessage={'Error'}
            onDeleteClick={() => {
              onRegisterMessage({
                text: getToastDeleteText(betalingsavtale),
              });
              deleteAvtalegiroAvtale(betalingsavtaler, changeData, betalingsavtale.id);
              history.push(PATH.EFAKTURA_AND_AVTALEGIRO);
            }}
            onCloseAlertClick={() => console.log('onCloseAlertClick')}
          /> */}
          </div>
        </BasePage>
      </BetalingsavtaleStyled>
    );
  }
  return <p>Loading</p>;
};

AvtalegiroUpdatepage.defaultProps = {
  params: undefined,
};

AvtalegiroUpdatepage.propTypes = {
  history: historyShape.isRequired,
  params: PropTypes.shape({
    tilbyderId: PropTypes.string,
  }),
  betalingsavtaler: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  // changeData: PropTypes.func.isRequired,
  // onRegisterMessage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
  onRegisterMessage: bindActionCreators(registerMessage, dispatch),
});

const mapStateToProps = state => ({
  betalingsavtaler: dataSelector(state, 'betalingsavtaler'),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  AvtalegiroUpdatepage,
);
