import React from 'react';
import PropTypes from 'prop-types';
import LegendWithSaveStatus from 'components/Form/LegendWithSaveStatus';

const MySettingsBalanceCarouselFieldset = ({
  balanceCarouselImage,
  updateValue,
}) => {
  const numberOfExistingForfallPresentationSaveInstances =
    balanceCarouselImage.savingInstances.length;
  const balanceCarouselImageStatus =
    numberOfExistingForfallPresentationSaveInstances > 0
      ? balanceCarouselImage.savingInstances[
        numberOfExistingForfallPresentationSaveInstances - 1
      ]
      : '';

  const toggleBalanceCarouselImage = (newValue) => {
    updateValue(newValue, numberOfExistingForfallPresentationSaveInstances);
  };

  return (
    <fieldset className="fieldset home-settings__presentation-fieldset home-settings__presentation-fieldset--is-only-visible-small-screen">
      <LegendWithSaveStatus
        text="Bildebakgrunn:"
        status={balanceCarouselImageStatus}
      />
      <input
        type="checkbox"
        className="checkbox"
        id="display-balance-carousel-checkbox"
        name="display-balance-carousel-checkbox"
        value={balanceCarouselImage.value}
        checked={balanceCarouselImage.value}
        onChange={() => toggleBalanceCarouselImage(!balanceCarouselImage.value)}
      />
      <label
        className="checkbox__label"
        htmlFor="display-balance-carousel-checkbox"
      >
        Vis bildebakgrunn
      </label>
    </fieldset>
  );
};

MySettingsBalanceCarouselFieldset.propTypes = {
  balanceCarouselImage: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    savingInstances: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  updateValue: PropTypes.func.isRequired,
};

export default MySettingsBalanceCarouselFieldset;
