import React from 'react';
import { bindActionCreators } from 'redux';
import { BasePage } from 'digitalbank-layout';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import * as PATHS from 'routes/paths';
import { LinkGroup } from 'digitalbank-button-group';
import TransferForm from 'routes/Transfer/TransferForm';
import validate from 'routes/Transfer/transfer.validate';
import initialValues from 'routes/Transfer/FastOverfoering/fastOverfoering.initialValues';
import dataSelector from 'redux-helpers/dataSelector';
import { change as changeDataAction } from 'redux-helpers/data.actions';
import { FAST_OVERFOERING_FORM_NAME } from 'routes/Transfer/transfer.constants';
import { TYPE as TRANSACTION_TYPE } from 'digitalbank-mockup-data/lib/Transactions';
import { addTransaction } from 'services/transactions.service';
import i18n from 'i18n';
import shortid from 'shortid';
import FastOverfoeringStyled from '../FastOverfoeringStyled';

const FastOverfoering = ({
  history,
  handleSubmit,
  formValues,
  submitting,
  transactions,
  recurringTransactions,
  changeData,
}) => (
  <FastOverfoeringStyled>
    <BasePage
      heading={i18n(x => x.ROUTE_TITLE_FAST_OVERFOERING)}
      className="p-fast-overfoering"
    >
      <LinkGroup
        items={[
          {
            text: 'Fast overføring',
            href: PATHS.FAST_OVERFOERING,
            active: true,
          },
          {
            text: 'Fast betaling',
            href: PATHS.FAST_BETALING,
          },
        ]}
      />
      <TransferForm
        formName={FAST_OVERFOERING_FORM_NAME}
        formValues={formValues}
        isSubmitting={submitting}
        handleSubmit={handleSubmit(
          data =>
            new Promise((resolve) => {
              setTimeout(() => {
                const newTransactionId = shortid.generate();
                addTransaction(
                  'transactions',
                  transactions,
                  changeData,
                  {
                    ...data,
                    type: TRANSACTION_TYPE.FAST_OVERFOERING,
                  },
                  null,
                  newTransactionId,
                );
                addTransaction(
                  'recurringTransactions',
                  recurringTransactions,
                  changeData,
                  {
                    ...data,
                    type: TRANSACTION_TYPE.FAST_OVERFOERING,
                  },
                  null,
                  newTransactionId,
                );
                resolve();
                history.push(PATHS.FAST_OVERFOERING_RECEIPT);
              }, 500);
            }),
        )}
        submitButtonText="Start fast overføring"
        isFastOverfoering
      />
    </BasePage>
  </FastOverfoeringStyled>
);

FastOverfoering.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeData: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  submitting: PropTypes.bool.isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  recurringTransactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues(FAST_OVERFOERING_FORM_NAME)(state) || initialValues,
  transactions: dataSelector(state, 'transactions'),
  recurringTransactions: dataSelector(state, 'recurringTransactions'),
});

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(changeDataAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    destroyOnUnmount: false,
    form: FAST_OVERFOERING_FORM_NAME,
    initialValues,
    validate,
  })(FastOverfoering),
);
