/* eslint-disable import/default, global-require */

import 'digitalbank-core-style/lib/style.css';
import 'moment/locale/nb';

import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { loadI18n, I18nStore } from 'spv-i18n';
import initializeI18nWithExistingInBetaling from 'digitalbank-betaling-web/lib/es/i18n/i18nBootstrapper';
import initializeI18nWithExistingInHistorikk from 'digitalbank-historikk-web/lib/es/i18n/i18nBootstrapper';
import betalingLogger from 'digitalbank-betaling-web/lib/es/infrastructure/logger';
import historikkLogger from 'digitalbank-historikk-web/lib/es/infrastructure/logger';
import templates from 'i18n/i18ntemplates';
import moment from 'moment';
import configureStore from 'store/configureStore';
import * as firebase from 'firebase';
import { initUser } from 'actions/authActions';
import ReactGA from 'react-ga';
import Root from './components/Root';
// import createBrowserHistory from 'history/createBrowserHistory';

// import 'react-fastclick';

// auto prefill promise for browsers that does not support promise (phantomjs)
require('es6-promise/auto');

// Tell webpack to load favicon.ico
require('./favicon.ico');

// reset default logger from digitalbank-betaling-web
betalingLogger.loggers = [];
historikkLogger.loggers = [];

// Create an enhanced history that syncs navigation events with the store
// const history = createBrowserHistory();

// eslint-disable-next-line
// const unlisten = history.listen(location => {
//   // location is an object like window.location
//   ReactGA.set({ page: location.pathname });
//   ReactGA.pageview(location.pathname);
// });

const store = configureStore();

const initAuth = () =>
  new Promise((resolve) => {
    const config = {
      apiKey: 'AIzaSyAzPgGH7VAS569MQ1M7UYrG6kE0H2AJmIE',
      authDomain: 'digitalbank.firebaseapp.com',
      databaseURL: 'https://digitalbank.firebaseio.com',
      storageBucket: 'firebase-digitalbank.appspot.com',
      messagingSenderId: '330491862553',
    };
    firebase.initializeApp(config);
    const defaultAuth = firebase.auth();
    defaultAuth.onAuthStateChanged((user) => {
      if (user) {
        // 1. Check if user already exist in store
        const userInStore = store.getState().auth.user;
        // 2. Initialize user if it does not already existing
        if (!userInStore) {
          const mappedUser = {
            name: 'Ola',
            displayName: user.displayName || 'Ola Nordmann',
            email: user.email,
          };
          store.dispatch(initUser(mappedUser));
        }
        resolve('Authenticated');
      } else {
        resolve('Not Authenticated');
      }
    });
  });

const runApp = () => {
  ReactGA.initialize('UA-97987672-1');
  initAuth()
    .then(() =>
      loadI18n(templates, {
        hasVerboseLogging: false,
        locales: ['nb-NO', 'en-GB'],
      }),
    )
    .then((ret) => {
      I18nStore.set(ret);
      initializeI18nWithExistingInBetaling(ret);
      initializeI18nWithExistingInHistorikk(ret);
    })
    .then(() => {
      moment.locale('nb-NO');
    })
    .then(() => {
      render(
        <AppContainer>
          <Root store={store} />
        </AppContainer>,
        document.getElementById('app'),
      );

      if (module.hot) {
        module.hot.accept('./components/Root', () => {
          const NewRoot = require('./components/Root').default;
          render(
            <AppContainer>
              <NewRoot store={store} />
            </AppContainer>,
            document.getElementById('app'),
          );
        });
      }
    });
};

runApp();
