/* eslint-disable no-script-url */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-helpers/data.actions';
import dataSelector from 'redux-helpers/dataSelector';
import { Search, CommentO } from 'digitalbank-icons';
import { PromoArticle } from 'components';
import AccountList from 'digitalbank-account-list/lib/components/list/AccountList';
import { mapAccountsToAccountListComponent } from 'services/account.service';
import {
  filterApprovedAndUnapprovedEfakturaTransactions,
  filterUnapprovedEfakturaTransactions,
  filterStoppedTransactions,
} from 'utils/transactions.util';
import { signOut } from 'actions/authActions';
import _ from 'lodash';
import { BasePage } from 'digitalbank-layout';
import i18n from 'i18n';
import { EFAKTURA_ONE_CLICK } from 'digitalbank-efaktura-oneclick';
import HomeAlerts from 'routes/Home/HomeAlerts';
import HomeAside from 'routes/Home/HomeAside';
import HomeTopContent from 'routes/Home/HomeTopContent';
import EfakturaOneClickContainer from 'containers/EfakturaOneClickContainer';
import CustomerSettingsContainer from 'containers/CustomerSettingsContainer';
import { Link } from 'react-router-dom';
import HomeStyled from './HomeStyled';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAccountId: '',
      displaySettings: false,
    };
    this.toggleSettings = this.toggleSettings.bind(this);
    this.setActiveAccountId = this.setActiveAccountId.bind(this);
  }
  setActiveAccountId(id) {
    const newId = id === this.state.activeAccountId ? '' : id;
    this.setState({
      ...this.state,
      activeAccountId: newId,
    });
  }
  toggleSettings() {
    this.setState({
      ...this.state,
      displaySettings: !this.state.displaySettings,
    });
  }
  render() {
    const { transactions, userSettings } = this.props;

    let approvedAndUnapprovedEfakturaTransactions;
    let unapprovedEfakturaTransactions;
    let stoppedTransactions;
    if (transactions && transactions.data) {
      approvedAndUnapprovedEfakturaTransactions = filterApprovedAndUnapprovedEfakturaTransactions(
        transactions.data,
      );
      unapprovedEfakturaTransactions = filterUnapprovedEfakturaTransactions(
        transactions.data,
      );
      stoppedTransactions = filterStoppedTransactions(transactions.data);
    }
    const selectedAccounts = _.filter(
      this.props.selectedAccounts,
      x => x.isSelected,
    ).map(selectedAccount => ({
      ..._.find(this.props.accounts, x => x.id === selectedAccount.accountId),
      orderIndex: selectedAccount.orderIndex,
    }));
    const activeAccounts = _.filter(selectedAccounts, x => x.active);
    const nudgeAccounts = _.filter(selectedAccounts, x => !x.active);
    const selectedAccountsOrdered = _.orderBy(
      activeAccounts,
      ['orderIndex'],
      ['asc'],
    );
    const visibleAccounts = [...selectedAccountsOrdered, ...nudgeAccounts];
    const visSumForfall =
      userSettings.forfallPresentation &&
      userSettings.forfallPresentation.value === 'SUM_FORFALL';
    const visTrukketKreditt =
      userSettings.kredittkortPresentation &&
      userSettings.kredittkortPresentation.value === 'TRUKKET';
    const efakturaStatus = this.props.eFakturaOneClick.efakturainnstilling
      .oneClick;
    return (
      <HomeStyled>
        <BasePage
          className="p-home"
          asideContent={<HomeAside />}
          topContent={
            <HomeTopContent
              userSettings={userSettings}
              selectedAccounts={selectedAccountsOrdered}
              nudgeAccounts={nudgeAccounts}
            />
          }
        >
          {/* TODO: Don't render notifications block if no no transactions or alerts exist .... */}
          {userSettings.homePage.displayEfakturaOneClick && (
            <div className="p-home__notifications">
              <EfakturaOneClickContainer />
              <HomeAlerts
                className="p-home__alerts"
                efakturaStatus={efakturaStatus}
                stoppedTransactions={stoppedTransactions}
                approvedAndUnapprovedEfakturaTransactions={
                  approvedAndUnapprovedEfakturaTransactions
                }
                unapprovedEfakturaTransactions={unapprovedEfakturaTransactions}
              />
            </div>
          )}

          <CustomerSettingsContainer
            heading="Min oversikt"
            minOversiktSectionIsEnabled
          />
          {selectedAccounts.length > 0 && (
            <section className="p-home__account-list-section p-home__account-list-section--is-responsive">
              <h3 className="account-list-section-heading sr-only">
                Mine kontoer
              </h3>
              <AccountList
                accounts={mapAccountsToAccountListComponent(visibleAccounts)}
                setActiveAccountId={this.setActiveAccountId}
                activeAccountId={this.state.activeAccountId}
                i18nTranslate={(template, format) => i18n(template, format)}
                visSumForfall={visSumForfall}
                visTrukketKreditt={visTrukketKreditt}
              />
            </section>
          )}
          {userSettings.homePage.displayActions && (
            <div className="p-home__actions">
              <form action="javascript:void(0);">
                <div className="search-input">
                  <input
                    type="search"
                    className="search-input__field"
                    name="search"
                    placeholder="Søk i transaksjoner"
                  />
                  <button type="submit" className="search-input__button">
                    <Search className="search-input__button-icon" />
                  </button>
                </div>
              </form>
              <PromoArticle heading="Bli varslet" icon="umbrella">
                <p>
                  Du kan få melding på SMS når du får lønn eller andre innskudd
                  på konto.
                  <Link to="/">Aktiver SMS-varsling</Link>
                </p>
              </PromoArticle>

              <button className="contact-button">
                <span className="contact-button__content">
                  <CommentO className="contact-button__icon" />
                  <div className="contact-button__text">Kontakt oss</div>
                  <div className="contact-button__status">Chat er åpen</div>
                </span>
              </button>
            </div>
          )}
        </BasePage>
      </HomeStyled>
    );
  }
}

Home.propTypes = {
  eFakturaOneClick: PropTypes.shape({
    efakturainnstilling: PropTypes.shape({
      oneClick: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userSettings: PropTypes.shape({
    efakturaOneClickStatus: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  keyNumbers: state.keyNumbers,
  transactions: dataSelector(state, 'transactions'),
  selectedAccounts: state.selectedAccounts,
  accounts: state.accounts,
  userSettings: state.user.settings,
  eFakturaOneClick: state[EFAKTURA_ONE_CLICK],
});

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
  onSignOut: bindActionCreators(signOut, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
