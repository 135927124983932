import styled from 'styled-components';
import { breakpoints } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-efaktura-fra-alle-receipt {
    &__main {
      @media (max-width: ${breakpoints.tablet}px) {
        margin-bottom: ${rem(100)};
      }
    }

    &__aside {
      ${horizontalLayoutSpacing({ phone: false })};
    }

    .nav-list {
      ${horizontalLayoutSpacing};
      padding-top: ${rem(20)};
      margin-bottom: ${rem(20)};
    }
  }
`;
