import React from 'react';
import PropTypes from 'prop-types';

const MasonryPanel = ({ children }) => (
  <div className="masonry-panel" style={{ overflow: 'hidden' }}>
    {children}
  </div>
);

MasonryPanel.defaultProps = {
  children: null,
};

MasonryPanel.propTypes = {
  children: PropTypes.node,
};

export default MasonryPanel;
