import React from 'react';
import Accordion, { AccordionItemHeader, themes } from 'digitalbank-accordion';

export default () => (
  <section className="faq">
    <div className="faq-header">
      <div className="faq-symbol faq-header__symbol">RÅD</div>
      <h3 className="faq-header__heading">Ofte stilte spørsmål</h3>
      <p className="faq-header__description">om eFaktura fra alle</p>
    </div>
    <Accordion
      theme={themes.MEDIUM_LIGHT}
      items={[
        {
          id: 'faq-1',
          header: (
            <AccordionItemHeader
              heading={{ description: 'Hvordan fungerer eFaktura fra alle?' }}
              hasFixedHeight={false}
            />
          ),
          content: (
            <div className="accordion-item-content-container">
              <p>
                Med eFaktura fra alle kan godkjente bedrifter sende deg eFaktura
                direkte, uten at du trenger å opprette avtale på forhånd. Du vil
                fortsatt godkjenne regningene du mottar før de blir lagt til
                betaling.
              </p>
              <p>
                For å få dette til må tjenesten ha dine kontaktopplysninger,
                slik at bedriftene kan sjekke om du er eFaktura-mottaker.
              </p>
              <p>
                Du vil fortsatt kunne legge inn avtaler manuelt, f.eks. hvis du
                skal betale regninger som står i ektefellens navn.
              </p>
            </div>
          ),
        },
        {
          id: 'faq-2',
          header: (
            <AccordionItemHeader
              heading={{ description: 'Hvordan sperrer jeg avsendere?' }}
              hasFixedHeight={false}
            />
          ),
          content: (
            <div className="accordion-item-content-container">
              <p>
                Hvis du mottar eFaktura fra en uønsket avsender, kan du sperre
                avsenderen:
              </p>
              <ol>
                <li>
                  Åpne eFaktura-detaljene ved å trykke knappen med blyantsymbol
                </li>
                <li>Velg Sperr avsender nederst i skjemaet</li>
                <li>Trykk bekreft, og avsenderen er sperret</li>
              </ol>
            </div>
          ),
        },
        {
          id: 'faq-3',
          header: (
            <AccordionItemHeader
              heading={{
                description: 'Hvorfor får jeg fortsatt avtaleforslag?',
              }}
              hasFixedHeight={false}
            />
          ),
          content: (
            <p className="accordion-item-content-container">
              Hvis du får forslag til en ny eFaktura-avtale selv om du har
              aktivert <i>eFaktura fra alle</i> så er det fordi bedriften ikke
              er lagt inn i tjenesten ennå. Du kan gjerne opprette avtale med
              denne bedriften, så slipper du å få regninger i posten.
            </p>
          ),
        },
        {
          id: 'faq-4',
          header: (
            <AccordionItemHeader
              heading={{ description: 'Kan jeg avslutte eFaktura fra alle?' }}
              hasFixedHeight={false}
            />
          ),
          content: (
            <p className="accordion-item-content-container">
              Ja, du kan avslutte tjenesten når du vil -{' '}
              <a href="/kontakt">kontakt oss</a>, så deaktiverer vi eFaktura fra
              alle. Vær oppmerksom på at du da vil kunne få papirfaktura fra de
              bedriftene du ikke har inngått avtale med manuelt.
            </p>
          ),
        },
        {
          id: 'faq-5',
          header: (
            <AccordionItemHeader
              heading={{
                description:
                  'Kan jeg opprette eFaktura-avtale manuelt selv om jeg har eFaktura fra alle?',
              }}
              hasFixedHeight={false}
            />
          ),
          content: (
            <p className="accordion-item-content-container">
              Ja, du kan opprette eFaktura-avtaler manuelt hvis du trenger det,
              f.eks. hvis du trenger å betale regninger som står i en annens
              navn. Når du betaler regningen vil du få forslag om å opprette
              eFaktura-avtale.
            </p>
          ),
        },
      ]}
    />
  </section>
);
