/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronLeft, ChevronRight } from 'digitalbank-icons';

const DatePickerNavbar = ({
  className,
  showPreviousButton,
  showNextButton,
  onPreviousClick,
  onNextClick,
  dir,
}) => {
  const buttonBaseClass = 'DayPicker-NavButton DayPicker-NavButton';
  const previousButton = showPreviousButton && (
    <span
      role="button"
      key="previous"
      className={`${buttonBaseClass}--prev`}
      onClick={(e) => {
        e.preventDefault();
        onPreviousClick();
      }}
    >
      <ChevronLeft className="DayPicker-NavButton-Icon" />
    </span>
  );

  const nextButton = showNextButton && (
    <span
      role="button"
      key="right"
      className={`${buttonBaseClass}--next`}
      onClick={(e) => {
        e.preventDefault();
        onNextClick();
      }}
    >
      <ChevronRight className="DayPicker-NavButton-Icon" />
    </span>
  );

  return (
    <div className={className}>
      {dir === 'rtl'
        ? [nextButton, previousButton]
        : [previousButton, nextButton]}
    </div>
  );
};

DatePickerNavbar.propTypes = {
  className: PropTypes.string,
  // nextMonth: PropTypes.instanceOf(Date).isRequired,
  // previousMonth: PropTypes.instanceOf(Date).isRequired,
  // locale: PropTypes.string.isRequired,
  showPreviousButton: PropTypes.bool,
  showNextButton: PropTypes.bool,
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  dir: PropTypes.string,
};

DatePickerNavbar.defaultProps = {
  className: 'DayPicker-NavBar',
  dir: 'ltr',
  showPreviousButton: true,
  showNextButton: true,
  onPreviousClick: undefined,
  onNextClick: undefined,
};

export default DatePickerNavbar;
