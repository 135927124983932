export default [
  {
    id: 'AP-QAoLg4djztKdbBpWZnVtvl_jdDCijyDyhmmcomleZ_6LifA2',
    kundereferanse: null,
    mottakernavn: 'BKK Kundetjenester AS',
    kundeEpost: null,
    tilbyderId: 'NOR987011653-1',
    brandId: '001',
  },
  {
    id: 'AEzFIw2_9IrDE4Jrvqdku-huy8YqvmLk3jPZiVabPXnMCsYeOg2',
    kundereferanse: null,
    mottakernavn: 'Fjordkraft AS',
    kundeEpost: null,
    tilbyderId: 'NOR976944682-1',
    brandId: '001',
  },
  {
    id: 'AKfKO0PFeHjnfnKSdmJHjPARpvj2H9VLzhZp2yJYx8Di1cmxJA212333',
    kundereferanse: null,
    mottakernavn: 'Bergen Kommune',
    kundeEpost: null,
    tilbyderId: 'NOR13371733043-1',
    brandId: '001',
  },
  {
    kundereferanse: '980874168',
    epost: 'ashild@priv.no',
    kundeEpost: null,
    beskrivelse: 'Test e-post og slett forslag',
    mottakernavn: 'Get ',
    id:
      'AAKoLHr5j4rG-PG5YMyLu2RJVuKXPoVPiWJ0C4041PGpIhQ-1n11TUNoYT9LFn-E3pcJmY-FL-WP1skhiOvWJyK64yDjw-8TpjWBjM0CvEpDzZMhvKAIFiAK9mEcUQGp9g5yqcpvxghhodL6VMf1vhCPDyu0mNBZ-Iypmta-Z3LSzh0HfQ2',
    status: 'Venter',
  },
  {
    kundereferanse: '452884018',
    epost: 'ashild@priv.no',
    kundeEpost: null,
    beskrivelse: null,
    mottakernavn: 'Chess',
    id:
      'AAKoLHr5j4rG-PG5YMyLu2RJVuKXPoVPiWJ0C4041PGpIhQ-1n11TUNoYT9LFn-E3o0BQlQfNqVf7aAbd2F9qWa64yDjw-8TpjWBjM0CvEpDd1k4J9qKvuQAdNsEfyTkGw5yqcpvxghhodL6VMf1vhBceJj130ej2qMIweQxnTRYMMDXFQ2',
    status: 'Venter',
  },
];

export const efakturaavtalerForslag = [
  {
    id: 'AAWphO9IF8aFOSetVbodAWNXGMxTgsqLQf1GFVPKRgPDKNx8Dw2',
    kundereferanse: null,
    mottakernavn: 'Askøy Bompengeselskap AS',
    kundeEpost: null,
    tilbyderId: 'NOR813009862-1',
    brandId: '001',
  },
  {
    id: 'AEitwcoKwMpImO6Ekq8An0YRMOiMzQAuopQ-wppq-pdEjslbAQ2',
    kundereferanse: null,
    mottakernavn: 'Chess',
    kundeEpost: null,
    tilbyderId: 'NOR981161408-1',
    brandId: '001',
  },
  {
    id: 'AEwwnLbzeing9uA4fNWAsUPikm7Kv9tSGGeDz3ogXELHtHAONQ2',
    kundereferanse: null,
    mottakernavn: 'Fortum Markets AS',
    kundeEpost: null,
    tilbyderId: 'NOR981219082-1',
    brandId: '001',
  },
  {
    id: 'AB5Eeej4GuUL7TlCoWmjd1Wd7EfKfmoIVnH_aRWR9QTPaB4goA2',
    kundereferanse: null,
    mottakernavn: 'Telenor AS',
    kundeEpost: null,
    tilbyderId: 'NOR345678929-1123',
    brandId: '001',
  },
  {
    id: 'ADjTrlO1bzPhy5sUDRuh4JCv-lY4rlFALxsEffQGZLUTRNuNKg2',
    kundereferanse: null,
    mottakernavn: 'NRK Lisensavdelinga',
    kundeEpost: null,
    tilbyderId: 'NOR976390512-1',
    brandId: '001',
  },
  {
    id: 'AKfKO0PFeHjnfnKSdmJHjPARpvj2H9VLzhZp2yJYx8Di1cmxJA2',
    kundereferanse: null,
    mottakernavn: 'Skatteetaten',
    kundeEpost: null,
    tilbyderId: 'NOR991733043-1',
    brandId: '001',
  },
  {
    id: 'AKfKO0PFeHjnfnKSdmJHjPARpvj2H9VLzhZp2yJYx8Di1cmxJA215555',
    kundereferanse: null,
    mottakernavn: 'Lånekassen',
    kundeEpost: null,
    tilbyderId: 'NOR13371735555-1',
    brandId: '001',
  },
];
