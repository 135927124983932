import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, isPristine } from 'redux-form';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { formatAccountNumber } from 'utils/format.util';
import * as PATHS from 'routes/paths';
import ReauthWrapper from 'components/Reauth/ReauthWrapper';
import wizardPage from 'components/wizardPage';
import { BETALE_EN_FORM_NAME } from '../payment.constants';

const BetaleEnReauth = ({ values: { kroner, oere, tilKonto }, history }) => {
  const amount = i18n(i => i.NUMBER_TWO_DIGITS, {
    number: Number(kroner) + Number(oere / 100),
  });
  const account = formatAccountNumber(tilKonto);
  return (
    <ReauthWrapper
      description={`Godkjenn betaling av ${amount} til ${account}`}
      onSubmit={() =>
        setTimeout(() => history.push(PATHS.PAYMENT_RECEIPT), 1500)
      }
      goBack={history.goBack}
    />
  );
};

BetaleEnReauth.propTypes = {
  values: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  values: getFormValues(BETALE_EN_FORM_NAME)(state),
});

export default wizardPage({
  redirectTest: state => isPristine(BETALE_EN_FORM_NAME)(state),
  redirectPath: PATHS.PAY,
})(connect(mapStateToProps)(BetaleEnReauth));
