import React from 'react';
import { Field } from 'redux-form';
import { InputField } from 'digitalbank-form';
import { showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb } from 'digitalbank-form/lib/es/utils/showErrorCallbacks.util';
import i18n from 'i18n';

export default fields => (
  <div>
    <div className="connected-form-groups" data-locale={fields.locale}>
      <Field
        name="recipientAddress1"
        spellCheck={false}
        autoComplete="address-line1"
        autoCorrect="off"
        label={i18n(t => t.PAYMENT_GLOBAL_LABEL_RECIPIENT_ADDRESS)}
        displayError={false}
        component={InputField}
        type="text"
        className="form-group--with-help-button-indent"
      />
      <Field
        name="recipientAddress2"
        spellCheck={false}
        autoComplete="address-line2"
        autoCorrect="off"
        label={i18n(t => t.PAYMENT_GLOBAL_LABEL_RECIPIENT_ADDRESS_LINE_2)}
        displayError={false}
        transformableLabel={false}
        component={InputField}
        type="text"
        className="form-group--with-help-button-indent"
      />

      {showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb(
        fields.recipientAddress1.meta,
      ) && (
        <div className="form-error" role="alert">
          {fields.recipientAddress1.meta.error}
        </div>
      )}
    </div>
    <div className="postal-information-row">
      <Field
        name="postnummer"
        label="Postnr"
        component={InputField}
        autoCorrect="off"
        autoComplete="off"
        displayError={false}
        type="text"
        className="postal-information-row__postalcode"
      />
      <Field
        name="poststed"
        label="Poststed"
        component={InputField}
        autoCorrect="off"
        autoComplete="off"
        displayError={false}
        type="text"
        className="postal-information-row__postalcity"
      />
    </div>
  </div>
);
