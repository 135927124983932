import React from 'react';
import { ReceiptPage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import EfakturaFraAlleFaq from './EfakturaFraAlleFaq';
import EfakturaFraAlleReceiptStyled from './EfakturaFraAlleReceiptStyled';

const EfakturaFraAlleReceiptPage = () => (
  <EfakturaFraAlleReceiptStyled>
    <ReceiptPage
      className="p-efaktura-fra-alle-receipt"
      receiptTitle="eFaktura fra alle er aktivert"
      asideContent={<EfakturaFraAlleFaq />}
      receiptDescription={
        <div>
          <p>
            <b>Du vil motta nye eFakturaer automatisk.</b>
          </p>
          <p>
            I en overgangsperiode vil du fortsatt få forslag til nye
            eFaktura-avtaler fra bedrifter som ikke har åpnet for denne
            tjenesten enda.
          </p>
        </div>
      }
    >
      <NavList
        items={[
          <NavListLink
            component={Link}
            to={PATHS.EFAKTURA_FRA_ALLE_SETTINGS}
            title={{ text: 'Innstillinger for eFaktura fra alle' }}
            key="jtta-innstillinger"
          />,
          <NavListLink
            component={Link}
            to={PATHS.EFAKTURA_AND_AVTALEGIRO}
            title={{ text: 'AvtaleGiro og eFaktura' }}
            key="avtalegiro-efaktura"
          />,
          <NavListLink
            component={Link}
            to={PATHS.EFAKTURA_AND_AVTALEGIRO_SUGGESTIONS}
            title={{
              text: (
                <span>
                  {'Forslag til nye avtaler'}
                  <div
                    style={{
                      display: 'inline-block',
                      marginLeft: '10px',
                    }}
                  >
                    <div className="badge badge--info">8</div>
                  </div>
                </span>
              ),
            }}
            key="avtalegiro-efaktura-forslag"
          />,
        ]}
      />
    </ReceiptPage>
  </EfakturaFraAlleReceiptStyled>
);

EfakturaFraAlleReceiptPage.propTypes = {};

export default EfakturaFraAlleReceiptPage;
