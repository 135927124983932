import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 18"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <path
      d="M31,16.7142755 C31,16.0853117 30.4792,15.5681131 29.845,15.5681131 L28.4644,15.5681131 L27.4618,12.9918136 C27.2128,12.3525857 26.4658,11.6762929 25.7974,11.4875467 C25.7974,11.4875467 24.0706,11 20.5,11 C16.93,11 15.2038,11.4875467 15.2038,11.4875467 C14.5348,11.6762929 13.7878,12.3525857 13.5394,12.9958052 L12.5362,15.5681131 L11.1556,15.5681131 C10.5214,15.5681131 10,16.0853117 10,16.7137053 C10,17.3420988 10.5214,17.8558761 11.1556,17.8558761 L11.6494,17.8558761 L11.2906,18.7688144 C11.0422,19.4080423 10.84,20.4949008 10.84,21.1786067 L10.84,26.5781152 C10.84,27.3804289 11.5012,28.0344828 12.3106,28.0344828 C13.1194,28.0344828 13.7806,27.3804289 13.7806,26.5781152 L13.7806,25.7501411 L27.22,25.7501411 L27.22,26.5781152 C27.22,27.3804289 27.8812,28.0344828 28.6906,28.0344828 C29.4994,28.0344828 30.1606,27.3804289 30.1606,26.5781152 L30.1606,21.1786067 C30.1606,20.4949008 29.9584,19.4080423 29.7094,18.7688144 L29.3512,17.8558761 L29.845,17.8558761 C30.4792,17.8558761 31,17.3420988 31,16.7142755 M13.714,17.050141 L14.896,13.8830832 C14.974,13.6686767 15.2308,13.4913352 15.46,13.4913352 L25.5406,13.4913352 C25.7698,13.4913352 26.0266,13.6686767 26.104,13.8830832 L27.2866,17.050141 C27.3646,17.2645475 27.2392,17.4384677 27.0106,17.4384677 L13.99,17.4384677 C13.7608,17.4384677 13.6366,17.2645475 13.714,17.050141 M13.675,22.2169956 C13.0372,22.2169956 12.5206,21.7037886 12.5206,21.0742546 C12.5206,20.4430099 13.0372,19.9332242 13.675,19.9332242 C14.3128,19.9332242 14.83,20.4430099 14.83,21.0742546 C14.83,21.7037886 14.3128,22.2169956 13.675,22.2169956 M27.3256,22.2169956 C26.6878,22.2169956 26.1706,21.7077802 26.1706,21.0742546 C26.1706,20.4430099 26.6878,19.9332242 27.3256,19.9332242 C27.9628,19.9332242 28.4806,20.4430099 28.4806,21.0742546 C28.4806,21.7077802 27.9628,22.2169956 27.3256,22.2169956"
      transform="translate(-10 -11)"
    />
  </svg>
);
