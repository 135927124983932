import styled from 'styled-components';
import {
  breakpoints,
  colors,
  utilityColors,
  spacing,
} from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { formMaxWidthValue } from 'digitalbank-core-style/lib/sizes';
import { topSpacing } from 'digitalbank-core-style/lib/layout';
import { rem } from 'polished';

export default styled.div`
  .p-betalingsavtaler {
    margin: ${rem(20)} 0 0;

    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__top {
      display: flex;
      justify-content: center;
      margin-bottom: ${spacing.layoutSpacing.sm}px;

      @media (min-width: ${breakpoints.tablet}px) {
        justify-content: flex-start;
      }
    }

    &__main {
      @media (min-width: ${breakpoints.tablet}px) {
        ${horizontalLayoutSpacing};
      }
    }

    &__ingen-avtaler-text {
      text-align: center;
    }

    &__efaktura-fra-alle-info-alert {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${spacing.layoutSpacing.sm}px;
    }

    &__tilbyder-icon {
      width: 16px;
      height: 16px;
      color: ${colors.boulder};
      display: inline-block;
      vertical-align: text-top;

      &--large {
        width: 20px;
        height: 20px;
      }
    }

    &__publisert-innhold {
      &-hoyre,
      &-under {
        .published-content {
          ${horizontalLayoutSpacing};
          padding-bottom: ${spacing.layoutSpacing.sm};
        }
      }

      &-under {
        @media (min-width: ${breakpoints.tablet}px) {
          width: 70%;
        }
      }
    }

    &__jtta-infotext {
      ${horizontalLayoutSpacing};
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${formMaxWidthValue}px;
      }
    }

    &__opprett-avtale {
      display: block;
      ${horizontalLayoutSpacing};
      padding-bottom: ${spacing.layoutSpacing.sm};
    }

    .betalingsavtaler {
      &__ny-avtale {
        @media (max-width: ${breakpoints.tablet - 1}px) {
          ${horizontalLayoutSpacing};
        }
      }

      &__retry-button {
        margin-top: ${rem(30)};
     }

      &__ingen-avtaler {
        width: 80%;
        text-align: center;
      }

      &__partial-error {
        &-alert {
          margin-bottom: ${rem(5)};
        }

        &-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }

        &-text {
          flex-direction: column;
          align-self: center;
          width: 70%;
        }

        &-retry-link {
          width: ${rem(100)};
        }
      }

      &__partial-errors {
        margin-bottom: ${rem(20)};
      }
    }

    .efaktura-fra-alle-card {
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
      @media (max-width: ${breakpoints.tablet - 1}px) {
        margin-top: -${rem(topSpacing.phone)};
      }
    }

    .accordion {
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
    }

    .detail-section {
       ${horizontalLayoutSpacing};
        padding-bottom: ${spacing.layoutSpacing.sm};
    }

    .accordion-item-header {
      &--is-negative {
        .p-betalingsavtaler__tilbyder-icon,
        .accordion-item-header__info,
        .accordion-item-header__heading-info {
          color: ${utilityColors.negative};
        }
      }

      &--is-muted {
        .p-betalingsavtaler__tilbyder-icon,
        .accordion-item-header__info,
        .accordion-item-header__heading-info {
          color: ${colors.dustyGray};
        }
      }

      &__info {
        color: ${colors.boulder};
        font-size: ${rem(14)};
      }
        &--desktop {
          display: none;
          padding-right: ${rem(20)};
          @media (min-width: ${breakpoints.tablet}px) {
            display: inline;
          }
        }
      }

      &__heading-info {
        @media (min-width: ${breakpoints.tablet}px) {
          display: none;
        }
      }
    }

    &__jtta-nudge {
      margin-bottom: ${rem(30)};
    }
  }
`;
