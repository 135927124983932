/* eslint-disable react/no-danger */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'digitalbank-buttons';
import LoadingButton from 'components/LoadingButton';
import i18n from 'i18n';

class Go3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
    };
    this.setCode = this.setCode.bind(this);
  }
  componentDidMount() {
    this.codeInput.focus();
  }
  setCode(e) {
    const code = e.target.value;
    this.setState({
      code,
    });
  }
  render() {
    const { code } = this.state;
    const { labelText, goBack } = this.props;
    return (
      <div className="reauth">
        <p
          className="reauth__description"
          dangerouslySetInnerHTML={{
            __html: this.props.description,
          }}
        />
        <div className="form-group reauth__fields">
          <label htmlFor="code" className="label reauth__label">
            {labelText}
          </label>
          <input
            type="tel"
            pattern="[0-9]*"
            className="form-control"
            onChange={this.setCode}
            name="code"
            id="code"
            value={code}
            ref={(input) => {
              this.codeInput = input;
            }}
            autoComplete="off"
          />
        </div>
        <LoadingButton
          variant="primary"
          className="reauth__submit-button"
          onClick={this.props.onSubmit}
          timeout={1500}
        >
          {i18n(t => t.GODKJENN)}
        </LoadingButton>
        <Button
          variant="link"
          className="reauth__cancel-button"
          onClick={goBack}
        >
          {i18n(t => t.CANCEL)}
        </Button>
      </div>
    );
  }
}

Go3.propTypes = {
  description: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default Go3;
