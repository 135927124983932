import React, { Component } from 'react';
import { BasePage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';

// import { ProductTable } from 'components';
// import Accounts from 'digitalbank-mockup-data/lib/Accounts';
// import { Cards } from 'data';
// import { formatAccountNumber } from 'utils/format.util';
import * as PATHS from 'routes/paths';
import CardStyled from './CardStyled';

class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayProductActionsAlias: '',
    };
    this.toggleActions = this.toggleActions.bind(this);
  }
  toggleActions(productAlias) {
    if (this.state.displayProductActionsAlias === productAlias) {
      this.setState({
        ...this.state,
        displayProductActionsAlias: '',
      });
    } else {
      this.setState({
        ...this.state,
        displayProductActionsAlias: productAlias,
      });
    }
  }
  render() {
    // Get cards to display in product table
    // const cardProducts = Cards.map((card, i) => {
    //   const account = Accounts.find(x => x.id === card.accountId);
    //   return {
    //     heading1: card.name,
    //     description1: formatAccountNumber(account.accountNumber),
    //     heading2: (account.availableAmount + account.reservedAmount).toLocaleString('nb-NO', {
    //       style: 'decimal',
    //       minimumFractionDigits: 2,
    //       maximumFractionDigits: 2,
    //     }),
    //     description2: 'Disponibelt',
    //     inactive: !account.active,
    //     buttonText: 'Bestill nå',
    //     toggleActions: () => this.toggleActions(`card-product-${i}`),
    //     displayActions: this.state.displayProductActionsAlias === `card-product-${i}`,
    //     uniqueKey: `card-product-${i}`,
    //   };
    // });
    return (
      <CardStyled>
        <BasePage className="p-card" heading="Kort">
          <NavList
            style={{ marginBottom: '20px' }}
            items={[
              <NavListLink
                key="regionsperre"
                to={PATHS.REGIONAL_BLOCKING}
                component={Link}
                title={{ text: 'Regionsperre' }}
              />,
            ]}
          />
          {/* <ProductTable products={cardProducts} /> */}
        </BasePage>
      </CardStyled>
    );
  }
}

export default Card;
