/* eslint-disable react/no-danger, max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  Field,
  Fields,
  isPristine,
} from 'redux-form';
import { Link } from 'react-router-dom';
import fx from 'money';
import _ from 'lodash';
import accounting from 'accounting';
import {
  InputField,
  TextareaField,
  SelectField,
  Legend,
} from 'digitalbank-form';
import Button from 'digitalbank-buttons';
import classNames from 'classnames';
import Collapse from 'react-css-collapse';
import * as PATHS from 'routes/paths';
import i18n from 'i18n';
import AccountPickerField from 'digitalbank-form/lib/es/components/AccountPickerField';
import {
  receiveAccountList,
  requestAccountList,
} from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import { isAccountPickerFetchingAccounts } from 'utils/account.util';
import { QuestionMark, ChevronDown } from 'digitalbank-icons';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';
import * as ACCOUNT_TYPES from 'digitalbank-mockup-data/lib/AccountTypes';
import { GlobalPaymentInfo } from 'data';
import AmountAndDateRow from 'components/Form/AmountAndDateRow';
import wizardPage from 'components/wizardPage';
import historyShape from 'shapes/history.shape';

import {
  getAvailableCurrenciesInCountry,
  getNumber,
  getAccountCurrency,
  getCurrencyRate,
} from '../paymentGlobal.utils';
import { secondPageValidate } from '../paymentGlobal.validation';
import fixer from '../fixer';
import PaymentGlobalStyled from '../PaymentGlobalStyled';

class SecondPage extends Component {
  constructor(props) {
    super(props);
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this);
    this.currencies = getAvailableCurrenciesInCountry(props.country);
    this.state = {
      displayAdvancedOptions: false,
      fxIsReady: true,
      help: {
        paymentCurrency: false,
        paymentPurpose: false,
        paymentType: false,
        sendEmailConfirmationToReceiver: false,
        agreedRateWithBank: false,
        expensesArePaidBy: false,
      },
    };

    fx.rates = { ...fixer.rates, ...{ EUR: 1 } };
    // window.fetch('https://api.fixer.io/latest', {
    //   method: 'GET',
    // }).then(response => response.json())
    // .then((response) => {
    //   fx.rates = Object.assign(response.rates, { EUR: 1 });
    //   this.setState({
    //     ...this.state,
    //     fxIsReady: true,
    //   });
    // }).catch((err) => {
    //   console.log(err); // eslint-disable-line
    // });
    this.fraKontoAccountPickerKey = 'globalPaymentFormFraKonto';
  }
  componentDidMount() {
    this.getAccounts();
  }
  getAccounts() {
    this.props.onRequestAccounts(this.fraKontoAccountPickerKey);
    setTimeout(() => {
      const accountResult = {
        kontoer: _.filter(
          Accounts,
          x =>
            x.active &&
            x.availableAmount > 0 &&
            x.type !== ACCOUNT_TYPES.KREDITT,
        ),
      };
      this.props.onReceiveAccounts(
        this.fraKontoAccountPickerKey,
        accountResult,
      );
    }, 1200);
  }
  toggleAdvancedOptions() {
    this.setState({
      ...this.state,
      displayAdvancedOptions: !this.state.displayAdvancedOptions,
    });
  }
  render() {
    const { displayAdvancedOptions, fxIsReady } = this.state;
    const {
      expressPayment,
      kroner,
      oere,
      paymentCurrency,
      sendEmailConfirmationToReceiver,
      agreedRateWithBank,
      fraKonto,
      locale,
      handleSubmit,
      accountPickers,
      history,
    } = this.props;

    const amount = getNumber(kroner, oere);
    const accountCurrency = !fraKonto
      ? 'NOK'
      : getAccountCurrency(fraKonto.accountNumber, this.accounts);
    const selectedCurrency = paymentCurrency !== '' ? paymentCurrency : 'NOK';
    const amountInAccountCurrency =
      amount > 0 && fxIsReady
        ? fx.convert(amount, { from: selectedCurrency, to: accountCurrency })
        : 0;
    const formattedAmountInAccountCurrency =
      amountInAccountCurrency > 0 && fxIsReady
        ? accounting.formatNumber(amountInAccountCurrency, 2, ' ', ',')
        : 0;
    const amountInNok =
      amount > 0 && fxIsReady
        ? fx.convert(amount, { from: selectedCurrency, to: 'NOK' })
        : 0;
    const selectedCurrencyRate = fxIsReady
      ? getCurrencyRate(selectedCurrency, accountCurrency, fx, accounting)
      : 0;
    const displayCurrencyCalculation =
      amount > 0 &&
      selectedCurrency &&
      accountCurrency &&
      selectedCurrency !== accountCurrency;

    const isFetchingFromAccounts = isAccountPickerFetchingAccounts(
      accountPickers,
      this.fraKontoAccountPickerKey,
    );
    return (
      <PaymentGlobalStyled>
        <form
          className="payment-global-form"
          onSubmit={handleSubmit(() =>
            history.push(PATHS.PAYMENT_GLOBAL_SUMMARY),
          )}
        >
          <fieldset className="fieldset payment-global-form__default-fieldset">
            <Legend text={i18n(t => t.PAYMENT_GLOBAL_LEGEND_INFO)} />
            <Field
              name="fraKonto"
              i18nTranslate={(template, format) => i18n(template, format)}
              accountpickerId={this.fraKontoAccountPickerKey}
              component={AccountPickerField}
              isFetchingAccounts={isFetchingFromAccounts}
              type="text"
              label={i18n(x => x.FROM_ACCOUNT)}
              defaultChoiceMessage="Velg fra-konto"
            />
            <Fields
              names={['kroner', 'oere', 'dato']}
              component={AmountAndDateRow}
              kronerLabel={i18n(x => x.PAYMENT_GLOBAL_LABEL_AMOUNT)}
              oereLabel=""
            />
            <Field
              component={SelectField}
              name="paymentCurrency"
              label={i18n(x => x.CURRENCY)}
              rightAddon={() => (
                <button
                  type="button"
                  className="form-control__help-button"
                  onClick={() =>
                    this.setState({
                      help: {
                        ...this.state.help,
                        paymentCurrency: !this.state.help.paymentCurrency,
                      },
                    })
                  }
                >
                  <QuestionMark
                    className="form-control__help-button-icon"
                    active={this.state.help.paymentCurrency}
                  />
                </button>
              )}
            >
              {this.currencies &&
                this.currencies.map(c => (
                  <option value={c} key={`payment-currency-${c}`}>
                    {i18n(t => t[c])}
                  </option>
                ))}
              {!this.currencies && (
                <option disabled default value="NOK">
                  No currencies available
                </option>
              )}
            </Field>
            <Collapse
              isOpen={this.state.help.paymentCurrency}
              className="input-help-animation"
            >
              <div
                className={classNames('input-help input-help--with-triangle', {
                  'input-help--is-active': this.state.help.paymentCurrency,
                })}
              >
                <div
                  className="input-help__content"
                  dangerouslySetInnerHTML={{
                    __html: i18n(t => t.INPUT_HELP_TEXT_PAYMENT_CURRENCY),
                  }}
                />
              </div>
            </Collapse>
            {displayCurrencyCalculation && (
              <div className="p-payment-global__alert-container">
                <div
                  role="alert"
                  className="
                    alert
                    alert--info
                    alert--small
                    "
                >
                  <p>
                    {i18n(t => t.PAYMENT_GLOBAL_ESTIMATED_AMOUNT)}
                    <b>
                      {' '}
                      {formattedAmountInAccountCurrency} {accountCurrency}
                    </b>
                    {` (${i18n(x => x.EXCHANGE)} ${selectedCurrencyRate})`}
                  </p>
                </div>
              </div>
            )}
            <Field
              name="message"
              label={i18n(t => t.PAYMENT_GLOBAL_LABEL_MESSAGE)}
              component={TextareaField}
              className="form-group--with-help-button-indent"
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Field
                name="expressPayment"
                id="expressPayment"
                component="input"
                type="checkbox"
                className="checkbox"
              />
              <label className="checkbox__label" htmlFor="expressPayment">
                {i18n(t => t.PAYMENT_GLOBAL_LABEL_EXPRESS_PAYMENT)}
              </label>
              <button
                type="button"
                className={classNames('checkbox__help-button', {
                  'checkbox__help-button--is-active': this.state.help
                    .expressPayment,
                })}
                onClick={() => {
                  this.setState({
                    help: {
                      ...this.state.help,
                      expressPayment: !this.state.help.expressPayment,
                    },
                  });
                }}
              >
                <QuestionMark
                  className="checkbox__help-button-icon"
                  active={this.state.help.expressPayment}
                />
              </button>
            </div>
            <Collapse
              isOpen={this.state.help.expressPayment}
              className="input-help-animation"
            >
              <div
                className={classNames(
                  'input-help input-help--with-help-button-indent',
                  {
                    'input-help--is-active': this.state.help.expressPayment,
                  },
                )}
              >
                <div
                  className="input-help__content"
                  dangerouslySetInnerHTML={{
                    __html: i18n(t => t.INPUT_HELP_HASTEBETALING),
                  }}
                />
              </div>
            </Collapse>
            {expressPayment && (
              <div className="p-payment-global__alert-container">
                <div
                  role="alert"
                  className="
                    alert
                    alert--info
                    alert--small
                    "
                >
                  <div>
                    <p style={{ margin: '0' }}>
                      <b>{i18n(x => x.EXPRESS_PAYMENT)}</b>
                    </p>
                    {amountInNok < 1000 && (
                      <p>{i18n(x => x.EXPRESS_PAYMENT_COSTS)} kr 150,-</p>
                    )}
                    {amountInNok >= 1000 && (
                      <p>{i18n(x => x.EXPRESS_PAYMENT_COSTS)} kr 300,-</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </fieldset>
          {amountInNok >= 100000 && (
            <fieldset className="fieldset p-payment-global__default-fieldset">
              <legend className="legend">
                {i18n(t => t.PAYMENT_GLOBAL_LEGEND_STATUATORY_REPORTING)}
              </legend>
              <Field
                name="paymentPurpose"
                label={i18n(t => t.PAYMENT_GLOBAL_LABEL_PAYMENT_PURPOSE)}
                component={InputField}
                type="text"
                autocomplete
                rightAddon={() => (
                  <button
                    type="button"
                    className="form-control__help-button"
                    onClick={() =>
                      this.setState({
                        help: {
                          ...this.state.help,
                          paymentPurpose: !this.state.help.paymentPurpose,
                        },
                      })
                    }
                  >
                    <QuestionMark
                      className="form-control__help-button-icon"
                      active={this.state.help.paymentPurpose}
                    />
                  </button>
                )}
              />
              <Collapse
                isOpen={this.state.help.paymentPurpose}
                className="input-help-animation"
              >
                <div
                  className={classNames(
                    'input-help input-help--with-triangle',
                    {
                      'input-help--is-active': this.state.help.paymentPurpose,
                    },
                  )}
                >
                  <div
                    className="input-help__content"
                    dangerouslySetInnerHTML={{
                      __html: i18n(t => t.INPUT_HELP_TEXT_PAYMENT_PURPOSE),
                    }}
                  />
                </div>
              </Collapse>
              <Field
                component={SelectField}
                name="paymentType"
                label={i18n(t => t.PAYMENT_GLOBAL_LABEL_PAYMENT_TYPE)}
                rightAddon={() => (
                  <button
                    type="button"
                    className="form-control__help-button"
                    onClick={() =>
                      this.setState({
                        help: {
                          ...this.state.help,
                          paymentType: !this.state.help.paymentType,
                        },
                      })
                    }
                  >
                    <QuestionMark
                      className="form-control__help-button-icon"
                      active={this.state.help.paymentType}
                    />
                  </button>
                )}
              >
                <option defaultValue hidden />
                {GlobalPaymentInfo.PaymentTypes &&
                  GlobalPaymentInfo.PaymentTypes.map(t => (
                    <option value={t[locale]} key={`payment-type-${t[locale]}`}>
                      {t[locale]}
                    </option>
                  ))}
              </Field>
              <Collapse
                isOpen={this.state.help.paymentType}
                className="input-help-animation"
              >
                <div
                  className={classNames(
                    'input-help input-help--with-triangle',
                    {
                      'input-help--is-active': this.state.help.paymentType,
                    },
                  )}
                >
                  <div
                    className="input-help__content"
                    dangerouslySetInnerHTML={{
                      __html: i18n(t => t.INPUT_HELP_TEXT_PAYMENT_TYPE),
                    }}
                  />
                </div>
              </Collapse>
            </fieldset>
          )}
          <section className="p-payment-global__details-section">
            <Button
              className="p-payment-global__details-button"
              variant="collapse"
              justifyContent="space-between"
              rightIcon={<ChevronDown />}
              onClick={this.toggleAdvancedOptions}
            >
              {i18n(x => x.ADVANCED_OPTIONS)}
            </Button>
            <Collapse
              isOpen={displayAdvancedOptions}
              className="collapse-transition"
            >
              <fieldset
                className="fieldset payment-global-form__advanced-fieldset"
                style={{
                  visibility: displayAdvancedOptions ? 'visible' : 'hidden',
                }}
              >
                <Field
                  component={SelectField}
                  name="expensesArePaidBy"
                  label={i18n(
                    t => t.PAYMENT_GLOBAL_LABEL_EXPENSES_ARE_PAYED_BY,
                  )}
                  rightAddon={() => (
                    <button
                      type="button"
                      className="form-control__help-button"
                      onClick={() =>
                        this.setState({
                          help: {
                            ...this.state.help,
                            expensesArePaidBy: !this.state.help
                              .expensesArePaidBy,
                          },
                        })
                      }
                    >
                      <QuestionMark
                        className="form-control__help-button-icon"
                        active={this.state.help.expensesArePaidBy}
                      />
                    </button>
                  )}
                >
                  <option value="shared">
                    {i18n(t => t.PAYMENT_GLOBAL_LABEL_CHARGES_SHARED)}
                  </option>
                  <option value="sender">
                    {i18n(t => t.PAYMENT_GLOBAL_LABEL_CHARGES_PAYED_BY_SENDER)}
                  </option>
                  <option value="recipient">
                    {i18n(
                      t => t.PAYMENT_GLOBAL_LABEL_CHARGES_PAYED_BY_RECIPIENT,
                    )}
                  </option>
                </Field>
                <Collapse
                  isOpen={this.state.help.expensesArePaidBy}
                  className="input-help-animation"
                >
                  <div
                    className={classNames(
                      'input-help input-help--with-triangle',
                      {
                        'input-help--is-active': this.state.help
                          .expensesArePaidBy,
                      },
                    )}
                  >
                    <div
                      className="input-help__content"
                      dangerouslySetInnerHTML={{
                        __html: i18n(
                          t => t.INPUT_HELP_TEXT_EXPENSES_ARE_PAID_BY,
                        ),
                      }}
                    />
                  </div>
                </Collapse>
                <Field
                  name="sendEmailConfirmationToReceiver"
                  id="sendEmailConfirmationToReceiver"
                  component="input"
                  type="checkbox"
                  className="checkbox"
                />
                <label
                  className="checkbox__label"
                  htmlFor="sendEmailConfirmationToReceiver"
                >
                  {i18n(
                    t =>
                      t.PAYMENT_GLOBAL_LABEL_SEND_EMAIL_CONFIRMATION_TO_RECEIVER,
                  )}
                </label>
                {sendEmailConfirmationToReceiver && (
                  <div style={{ marginLeft: '30px' }}>
                    <Field
                      name="receiverEmail"
                      label={i18n(t => t.PAYMENT_GLOBAL_LABEL_RECEIVER_EMAIL)}
                      component={InputField}
                      type="email"
                      autocomplete
                      rightAddon={() => (
                        <button
                          type="button"
                          className="form-control__help-button"
                          onClick={() =>
                            this.setState({
                              help: {
                                ...this.state.help,
                                receiverEmail: !this.state.help.receiverEmail,
                              },
                            })
                          }
                        >
                          <QuestionMark
                            className="form-control__help-button-icon"
                            active={this.state.help.receiverEmail}
                          />
                        </button>
                      )}
                    />
                    <Collapse
                      isOpen={this.state.help.receiverEmail}
                      className="input-help-animation"
                    >
                      <div
                        className={classNames(
                          'input-help input-help--with-triangle',
                          {
                            'input-help--is-active': this.state.help
                              .receiverEmail,
                          },
                        )}
                      >
                        <div
                          className="input-help__content"
                          dangerouslySetInnerHTML={{
                            __html: i18n(t => t.INPUT_HELP_TEXT_RECEIVER_EMAIL),
                          }}
                        />
                      </div>
                    </Collapse>
                    <div className="p-payment-global__alert-container">
                      <div
                        role="alert"
                        className="
                        alert
                        alert--info
                        alert--small
                        "
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: i18n(t => t.RECEIVER_EMAIL_INFO),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <Field
                  name="agreedRateWithBank"
                  id="agreedRateWithBank"
                  component="input"
                  type="checkbox"
                  className="checkbox"
                />
                <label className="checkbox__label" htmlFor="agreedRateWithBank">
                  {i18n(t => t.PAYMENT_GLOBAL_LABEL_AGREED_RATE_WITH_BANK)}
                </label>
                {agreedRateWithBank && (
                  <div style={{ marginLeft: '30px' }}>
                    <Field
                      name="rateWithBank"
                      label={i18n(
                        t => t.PAYMENT_GLOBAL_LABEL_AGREED_RATE_WITH_BANK,
                      )}
                      component={InputField}
                      type="text"
                      autocomplete
                      rightAddon={() => (
                        <button
                          type="button"
                          className="form-control__help-button"
                          onClick={() =>
                            this.setState({
                              help: {
                                ...this.state.help,
                                rateWithBank: !this.state.help.rateWithBank,
                              },
                            })
                          }
                        >
                          <QuestionMark
                            className="form-control__help-button-icon"
                            active={this.state.help.rateWithBank}
                          />
                        </button>
                      )}
                    />
                    <Collapse
                      isOpen={this.state.help.rateWithBank}
                      className="input-help-animation"
                    >
                      <div
                        className={classNames(
                          'input-help input-help--with-triangle',
                          {
                            'input-help--is-active': this.state.help
                              .rateWithBank,
                          },
                        )}
                      >
                        <div
                          className="input-help__content"
                          dangerouslySetInnerHTML={{
                            __html: i18n(t => t.INPUT_HELP_TEXT_RATE_WITH_BANK),
                          }}
                        />
                      </div>
                    </Collapse>
                    <Field
                      name="contactPerson"
                      label={i18n(t => t.PAYMENT_GLOBAL_LABEL_CONTACT_PERSON)}
                      component={InputField}
                      type="text"
                      autocomplete
                      className="form-group--with-help-button-indent"
                    />
                  </div>
                )}
              </fieldset>
            </Collapse>
          </section>
          <div className="payment-global-form__actions">
            <Button
              variant="primary"
              className="payment-global-form__next-button"
              type="submit"
              onClick={this.toggleAuth}
            >
              {i18n(x => x.NEXT)}
            </Button>
            <Button
              variant="cancel"
              className="payment-global-form__back-button"
              to={PATHS.PAYMENT_GLOBAL}
              component={Link}
            >
              {i18n(x => x.BACK)}
            </Button>
          </div>
        </form>
      </PaymentGlobalStyled>
    );
  }
}

SecondPage.defaultProps = {
  kroner: undefined,
  oere: undefined,
  fraKonto: undefined,
};

SecondPage.propTypes = {
  history: historyShape.isRequired,
  country: PropTypes.string.isRequired,
  kroner: PropTypes.string,
  oere: PropTypes.string,
  paymentCurrency: PropTypes.string.isRequired,
  expressPayment: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  fraKonto: PropTypes.shape({}),
  sendEmailConfirmationToReceiver: PropTypes.bool.isRequired,
  agreedRateWithBank: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  locale: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onReceiveAccounts: PropTypes.func.isRequired,
  onRequestAccounts: PropTypes.func.isRequired,
  accountPickers: PropTypes.shape({
    globalPaymentFormFraKonto: PropTypes.shape({
      isFetchingAccounts: PropTypes.bool,
    }),
  }).isRequired,
};

const validate = values => secondPageValidate(values, fx, i18n);
const form = reduxForm({
  destroyOnUnmount: false,
  form: 'globalPaymentForm',
  validate,
})(SecondPage);

const mapStateToProps = (state) => {
  const selector = formValueSelector('globalPaymentForm');
  return {
    country: selector(state, 'country'),
    kroner: selector(state, 'kroner'),
    oere: selector(state, 'oere'),
    paymentCurrency: selector(state, 'paymentCurrency'),
    expressPayment: selector(state, 'expressPayment'),
    fraKonto: selector(state, 'fraKonto'),
    sendEmailConfirmationToReceiver: selector(
      state,
      'sendEmailConfirmationToReceiver',
    ),
    agreedRateWithBank: selector(state, 'agreedRateWithBank'),
    locale: state.locale,
    accountPickers: state.accountPickers,
  };
};

const mapDispatchToProps = dispatch => ({
  onReceiveAccounts: bindActionCreators(receiveAccountList, dispatch),
  onRequestAccounts: bindActionCreators(requestAccountList, dispatch),
});

export default wizardPage({
  redirectTest: state => isPristine('globalPaymentForm')(state),
  redirectPath: PATHS.PAYMENT_GLOBAL,
})(connect(mapStateToProps, mapDispatchToProps)(form));
