import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as PATH from 'routes/paths';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { signOut } from 'actions/authActions';
// import Hammer from 'hammerjs';
import Header from './Header';
import Navigation from './Navigation';
import Tabbar from './Tabbar';
import Sidebar from './Sidebar';

class MenuContainer extends Component {
  constructor(props) {
    super(props);
    this.sidebar = React.createRef();
    this.overlay = React.createRef();
  }
  // componentDidMount() {
  //   const hammertimeSidebar = new Hammer(this.sidebar.current);
  //   hammertimeSidebar.on('swiperight', () => {
  //     // console.log('swipe');
  //     // alert('swipe');
  //     this.props.toggleSidebar();
  //   });
  //   const hammertimeOverlay = new Hammer(this.overlay.current);
  //   hammertimeOverlay.on('swiperight', () => {
  //     // console.log('swipe');
  //     // alert('swipe');
  //     this.props.toggleSidebar();
  //   });
  // }
  render() {
    const {
      isLoggedIn,
      title,
      history,
      handleLogout,
      isApp,
      displaySidebar,
    } = this.props;
    return (
      <div>
        <div
          className={classNames('menu', {
            'menu--is-ios menu--has-safe-area-support': isApp,
          })}
        >
          <div className="menu__header">
            <Header
              handleLogout={handleLogout}
              history={history}
              isApp={this.isApp}
              isLoggedIn={isLoggedIn}
              logoLinkPath={PATH.HOME}
              logoutLinkPath={PATH.LOGIN}
              title={title}
            />
          </div>
          <div
            className={classNames('menu__tab-bar', {
              'menu__tab-bar--hidden': displaySidebar,
            })}
          >
            <Tabbar
              toggleSidebar={this.props.toggleSidebar}
              isLoggedIn={isLoggedIn}
            />
          </div>
          {isLoggedIn && <Navigation />}
          <div
            ref={this.sidebar}
            className={classNames('menu__sidebar', {
              'menu__sidebar--active': displaySidebar,
            })}
          >
            {isLoggedIn && (
              <Sidebar
                toggleSidebar={this.props.toggleSidebar}
                disableOnClickOutside={!displaySidebar}
              />
            )}
          </div>
        </div>
        <button
          type="button"
          onClick={this.props.toggleSidebar}
          ref={this.overlay}
          className={classNames('menu__overlay', {
            'menu__overlay--visible': displaySidebar,
          })}
          tabIndex={displaySidebar ? '0' : '-1'}
        />
      </div>
    );
  }
}

MenuContainer.defaultProps = {
  title: '',
};

MenuContainer.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  isApp: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  displaySidebar: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

const mapDispatchToProps = dispatch => ({
  handleLogout: bindActionCreators(signOut, dispatch),
});

const mapStateToProps = state => ({
  title: state.title,
  isLoggedIn: !!state.auth.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MenuContainer),
);
