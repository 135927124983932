/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Alert from 'digitalbank-alert';
import * as PATHS from 'routes/paths';
import i18n from 'i18n';
import { ChevronRight } from 'digitalbank-icons';
import { Modal, ModalCard } from 'digitalbank-modal';
import Button from 'digitalbank-buttons';
import { toggleDisplaySakTilSignering } from 'actions/userSettings.actions';

class HomeAlerts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySigneringModal: false,
    };
    this.toggleSigneringModal = this.toggleSigneringModal.bind(this);
  }
  toggleSigneringModal() {
    this.setState({
      displaySigneringModal: !this.state.displaySigneringModal,
    });
  }
  render() {
    const {
      className,
      stoppedTransactions,
      unapprovedEfakturaTransactions,
      efakturaStatus,
      displaySakTilSignering,
    } = this.props;

    return (
      <div className={className}>
        {/* {efakturaStatus === EFAKTURA_ONECLICK_STATUS.IKKE_SATT &&
          unapprovedEfakturaTransactions &&
          unapprovedEfakturaTransactions.length > 0 &&
          <Link to={PATHS.SCHEDULED_PAYMENTS} className="alert-action">
            <Alert small hideIcon type="info">
              <i className="new-invoices__counter badge badge--new">
                {unapprovedEfakturaTransactions.length}
              </i>
              {unapprovedEfakturaTransactions.length > 1
                ? i18n(i => i.NUMBER_OF_NEW_INVOICE_PLURAL, {
                  antall: unapprovedEfakturaTransactions.length,
                })
                : i18n(i => i.NUMBER_OF_NEW_INVOICE_SINGLE)}
              <div className="alert__close">
                <ChevronRight className="alert__close-button-icon" />
              </div>
            </Alert>
          </Link>} */}
        {stoppedTransactions &&
          stoppedTransactions.length > 0 && (
            <Link to={PATHS.SCHEDULED_PAYMENTS} className="alert-action">
              <Alert small hideIcon type="warning">
                <i className="new-invoices__counter badge badge--new">
                  {stoppedTransactions.length}
                </i>
                {stoppedTransactions.length > 1
                  ? i18n(i => i.NUMBER_OF_STOPPED_PAYMENTS_PLURAL, {
                      antall: stoppedTransactions.length,
                    })
                  : i18n(i => i.NUMBER_OF_STOPPED_PAYMENTS_SINGLE)}
                <div className="alert__close">
                  <ChevronRight className="alert__close-button-icon" />
                </div>
              </Alert>
            </Link>
          )}
        {displaySakTilSignering && (
          <button className="alert-action" onClick={this.toggleSigneringModal}>
            <Alert small hideIcon type="info">
              <i className="new-invoices__counter badge badge--new">1</i>1 sak
              venter på signering
              <div className="alert__close">
                <ChevronRight className="alert__close-button-icon" />
              </div>
            </Alert>
          </button>
        )}
        <Modal
          visible={this.state.displaySigneringModal}
          toggle={this.toggleSigneringModal}
          hasCenteredContent
        >
          <ModalCard
            toggle={this.toggleSigneringModal}
            className="p-home__modal-card"
            heading="Sak venter på signering"
            hasCloseButton
          >
            <div className="modal-card__actions">
              <Button
                variant="primary"
                onClick={() => {
                  this.props.onToggleDisplaySakTilSignering();
                  this.toggleSigneringModal();
                }}
              >
                Signer sak
              </Button>
              <Button variant="cancel" onClick={this.toggleSigneringModal}>
                Lukk
              </Button>
            </div>
          </ModalCard>
        </Modal>
      </div>
    );
  }
}

HomeAlerts.propTypes = {
  className: PropTypes.string,
  stoppedTransactions: PropTypes.arrayOf(PropTypes.shape({})),
  unapprovedEfakturaTransactions: PropTypes.arrayOf(PropTypes.shape({})),
  efakturaStatus: PropTypes.string.isRequired,
  onToggleDisplaySakTilSignering: PropTypes.func.isRequired,
  displaySakTilSignering: PropTypes.bool.isRequired,
};

HomeAlerts.defaultProps = {
  className: null,
  stoppedTransactions: null,
  unapprovedEfakturaTransactions: null,
};

const mapStateToProps = state => ({
  displaySakTilSignering: state.user.settings.displaySakTilSignering,
});

const mapDispatchToProps = dispatch => ({
  onToggleDisplaySakTilSignering: bindActionCreators(
    toggleDisplaySakTilSignering,
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HomeAlerts);
