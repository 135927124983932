import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, destroy } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { change } from 'redux-helpers/data.actions';
import dataSelector from 'redux-helpers/dataSelector';
import { BasePage } from 'digitalbank-layout';
import * as PATHS from 'routes/paths';
import historyShape from 'shapes/history.shape';
import _ from 'lodash';
import Alert from 'digitalbank-alert';
import ActionList, {
  DeleteButton,
  StopStartButton,
} from 'digitalbank-action-list';
import { CheckCircle } from 'digitalbank-icons';
import i18n from 'i18n';
import {
  deleteTransaction,
  getTransactionFromId,
  updateTransactionWithFormValues,
  updateTransactionStatus,
} from 'services/transactions.service';
import {
  STATUS as TRANSACTION_STATUS,
  TYPE as TRANSACTION_TYPE,
} from 'digitalbank-mockup-data/lib/Transactions';
import TransferForm from '../TransferForm';
import validate from '../transfer.validate';
import { OVERFOERE_EDIT_FORM_NAME } from '../transfer.constants';
import {
  load as loadTransaction,
  unload as unloadTransaction,
} from './editEnOverfoering.actions';
import initialValues from '../FastOverfoering/fastOverfoering.initialValues';
import TransferStyled from '../TransferStyled';

const init = (transactions, transactionId, history, load) => {
  const isTransactionFound =
    _.filter(transactions.data, x => x.id === transactionId).length > 0;
  if (!isTransactionFound) {
    history.push(PATHS.SCHEDULED_PAYMENTS);
  } else {
    load(transactions, transactionId);
  }
};

class EditEnOverfoering extends Component {
  componentDidMount() {
    this.props.onDestroy(OVERFOERE_EDIT_FORM_NAME);
    if (this.props.transactions.status.received) {
      const {
        transactions, match, history, load,
      } = this.props;
      init(transactions, match.params.id, history, load);
    }
  }
  componentWillUpdate(nextProps) {
    if (
      !this.props.transactions.status.received &&
      nextProps.transactions.status.received
    ) {
      const { match, history, load } = this.props;
      const { transactions } = nextProps;
      init(transactions, match.params.id, history, load);
    }
  }
  componentWillUnmount() {
    this.props.unload();
  }
  render() {
    const {
      history,
      handleSubmit,
      formValues,
      transactions,
      changeData,
      submitting,
    } = this.props;
    const { id } = this.props.match.params;
    const transaction = getTransactionFromId(transactions.data, id);
    const isDeletePending =
      transaction.status === TRANSACTION_STATUS.SHOULD_BE_DELETED;
    const isDeleted = transaction.status === TRANSACTION_STATUS.DELETED;
    const isFastOverfoering =
      transaction.type === TRANSACTION_TYPE.FAST_OVERFOERING;
    const actions = [
      <StopStartButton
        key="start"
        startText="Start"
        stopText="Stop"
        isStopped={transaction.status === TRANSACTION_STATUS.STOPPED}
        onStopClick={() =>
          updateTransactionStatus(
            'transactions',
            transactions,
            changeData,
            id,
            TRANSACTION_STATUS.STOPPED,
          )
        }
        onStartClick={() =>
          updateTransactionStatus(
            'transactions',
            transactions,
            changeData,
            id,
            TRANSACTION_STATUS.ACTIVE,
          )
        }
      />,
      <DeleteButton
        key="delete"
        value="Slett"
        i18nTranslate={(template, format) => i18n(template, format)}
        isDeletePending={isDeletePending}
        onDeleteConfirmed={() =>
          deleteTransaction('transactions', transactions, changeData, id)
        }
        onDeleteErrorClose={() => console.log('onDeleteErrorClose click')} // eslint-disable-line
        showDeleteError={false}
        deleteError="Error"
      />,
    ];
    return (
      <TransferStyled>
        <BasePage heading="Endre overføring" className="p-transfer">
          {isFastOverfoering &&
            this.props.initialValues && (
              <div className="p-transfer__info-alert">
                <Alert type="info" small hideIcon>
                  <p>
                    Denne overføringen inngår i et fast oppdrag.{' '}
                    <Link
                      to={`${PATHS.FAST_OVERFOERING_EDIT}/${
                        this.props.initialValues.id
                      }`}
                    >
                      Endre alle (inkludert denne)
                    </Link>
                  </p>
                </Alert>
              </div>
            )}
          <TransferForm
            isDisabled={isDeleted}
            isSubmitting={submitting}
            formName={OVERFOERE_EDIT_FORM_NAME}
            formValues={formValues}
            submitButtonText="Bekreft"
            isEditing
            transactionId={id}
            initialValues={this.props.initialValues}
            handleSubmit={handleSubmit(
              data =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    updateTransactionWithFormValues(
                      'transactions',
                      transactions,
                      changeData,
                      id,
                      data,
                    );
                    resolve();
                    history.push(`${PATHS.TRANSFER_EDIT_RECEIPT}/${id}`);
                  }, 500);
                }),
            )}
          >
            {isDeleted && (
              <Alert
                type="info"
                icon={CheckCircle}
                title="Overføringen ble slettet"
              >
                <Link to={PATHS.SCHEDULED_PAYMENTS}>Se andre forfall</Link>
              </Alert>
            )}
          </TransferForm>
          {!isDeleted && (
            <ActionList actions={actions} orientation="VERTICAL" />
          )}
        </BasePage>
      </TransferStyled>
    );
  }
}

EditEnOverfoering.defaultProps = {
  initialValues: null,
};

EditEnOverfoering.propTypes = {
  history: historyShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  load: PropTypes.func.isRequired,
  unload: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  initialValues: PropTypes.shape({
    id: PropTypes.string,
  }),
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  changeData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  load: bindActionCreators(loadTransaction, dispatch),
  unload: bindActionCreators(unloadTransaction, dispatch),
  onDestroy: bindActionCreators(destroy, dispatch),
  changeData: bindActionCreators(change, dispatch),
});

const mapStateToProps = state => ({
  initialValues: state.editEnOverfoering.data,
  transactions: dataSelector(state, 'transactions'),
  accounts: state.accounts,
  formValues: getFormValues(OVERFOERE_EDIT_FORM_NAME)(state) || initialValues,
});

const form = reduxForm({
  destroyOnUnmount: false,
  form: OVERFOERE_EDIT_FORM_NAME,
  validate,
})(EditEnOverfoering);

export default connect(mapStateToProps, mapDispatchToProps)(form);
