/* eslint-disable no-undef */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from 'digitalbank-spinner';
import styled from 'styled-components';
import moment from 'moment';
import {
  getTransactions,
  getCategories,
  customerId,
} from 'actions/economy.actions';
import * as EconomyService from 'services/economy.service';
import { ParagraphCentered, DefaultLayoutContainer } from 'styled';
import Transactions from './components/Transactions/Transactions';
import { EconomyGraphStyled } from './components/EconomyGraph';
import { getCategoryData, getHeatmapLocations } from './utils';
import { Categories } from './components/Categories/Categories';
import EconomyGraphInstance from './components/EconomyGraphInstance';
import Search from './components/Search';
import Heatmap from './components/Heatmap';
import EconomyDateFilter, {
  EconomyDateFilterStyled,
} from './components/EconomyDateFilter';
import EconomyHeading from './components/EconomyHeading';
import TransactionModal from './components/Transactions/TransactionModal';

const SectionStyled = styled.section`
  margin-bottom: 20px;
`;

const SubheadingStyled = styled.h4`
  padding: 0 14px;
`;

const EconomyPageStyled = styled.div`
  ${EconomyGraphStyled} {
    margin-bottom: 20px;
  }

  ${EconomyDateFilterStyled} {
    padding: 0 14px;
    justify-content: center;
    margin-bottom: 15px;
  }
`;

const initialCategories = {
  mainCategory: 'Utgifter',
  category: '',
  subCategory: '',
};

class Economy extends Component {
  constructor(props) {
    super(props);
    this.dateFormat = 'YYYY-MM-DD';
    this.state = {
      ...initialCategories,
      dateFilter: '1',
      fromDate: moment()
        .subtract(1, 'months')
        .startOf('month')
        .format(this.dateFormat),
      toDate: moment()
        .subtract(1, 'months')
        .endOf('month')
        .format(this.dateFormat),
      searchInputValue: '',
      searchResult: null,
      modal: {
        isVisible: false,
        isOpened: false,
        transaction: null,
      },
    };
    this.setDate = this.setDate.bind(this);
    this.setCategory = this.setCategory.bind(this);
    this.search = this.search.bind(this);
    this.debouncedSearch = _.debounce(this.search, 400, {
      leading: false,
      trailing: true,
    });
    this.setTransaction = this.setTransaction.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    const { mainCategory, category, subCategory } = this.state;
    this.props.dispatchGetTransactions({
      mainCategory,
      category,
      subCategory,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
    this.props.dispatchGetCategories();
  }

  setCategory({ mainCategory, category, subCategory }) {
    const { fromDate, toDate } = this.state;
    this.setState({
      mainCategory,
      category,
      subCategory,
    });

    this.props.dispatchGetTransactions({
      mainCategory,
      category,
      subCategory,
      fromDate,
      toDate,
    });
  }
  setDate(dateFilter) {
    let fromDateObj;
    let toDateObj;
    if (dateFilter === '1') {
      // TODO: remove subtract when using real data
      fromDateObj = moment()
        .subtract(1, 'months')
        .startOf('month');
      toDateObj = moment()
        .subtract(1, 'months')
        .endOf('month');
    } else if (dateFilter === '2') {
      fromDateObj = moment()
        .subtract(3, 'months')
        .startOf('month');
      toDateObj = moment().endOf('month');
    } else if (dateFilter === '3') {
      fromDateObj = moment()
        .subtract(6, 'months')
        .startOf('month');
      toDateObj = moment().endOf('month');
    } else if (dateFilter === '4') {
      fromDateObj = moment().startOf('year');
      toDateObj = moment().endOf('year');
    }

    const fromDate = fromDateObj.format(this.dateFormat);
    const toDate = toDateObj.format(this.dateFormat);

    this.setState({
      dateFilter,
      fromDate,
      toDate,
    });

    this.props.dispatchGetTransactions({
      mainCategory: this.state.mainCategory,
      category: this.state.category,
      subCategory: this.state.subCategory,
      fromDate,
      toDate,
    });
  }
  setTransaction(newTransaction) {
    this.setState({
      modal: {
        transaction: newTransaction,
        isOpened: true,
        isVisible: !this.state.modal.isVisible,
      },
    });
  }
  closeModal() {
    this.setState({
      modal: {
        ...this.state.modal,
        isVisible: !this.state.modal.isVisible,
      },
    });
    setTimeout(() => {
      this.setState({
        modal: {
          ...this.state.modal,
          transaction: null,
        },
      });
    }, 600);
  }
  search(value) {
    this.setState({ searchInputValue: value });
    if (value !== '') {
      EconomyService.getTransactions({
        customerId,
        search: value,
      })
        .then((data) => {
          const parsedData = JSON.parse(data);
          this.setState({
            searchResult: parsedData,
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          throw error;
        });
    }
  }
  resetCategories() {
    const { fromDate, toDate } = this.state;
    this.setState({
      ...initialCategories,
    });
    this.props.dispatchGetTransactions({
      ...initialCategories,
      fromDate,
      toDate,
    });
  }
  render() {
    const {
      economy: { categories, transactions },
    } = this.props;
    const {
      dateFilter,
      category,
      subCategory,
      mainCategory,
      searchResult,
      searchInputValue,
    } = this.state;

    const isSubCategory = !!subCategory;
    const isCategory = !!category;
    const categoryName = subCategory || category || mainCategory;
    const isLoading =
      (transactions.status.requested &&
        !transactions.status.received &&
        !transactions.status.error) ||
      (categories.status.requested &&
        !categories.status.received &&
        !categories.status.error);
    const hasError = transactions.status.error || categories.status.error;
    const hasData =
      categories.data &&
      categories.data.length > 0 &&
      transactions.data &&
      transactions.data.length > 0;
    const categoryData = hasData
      ? getCategoryData({
        categories: categories.data,
        transactions: transactions.data,
        categoryName,
      })
      : undefined;

    const heatMapData = hasData
      ? getHeatmapLocations(transactions.data).map(coordinate => ({
        location: new google.maps.LatLng(coordinate.lat, coordinate.lng),
        weight: coordinate.weight,
      }))
      : undefined;
    return (
      <DefaultLayoutContainer>
        <EconomyPageStyled>
          <SectionStyled>
            <EconomyDateFilter
              activeDateFilter={dateFilter}
              setDate={newDateFilter => this.setDate(newDateFilter)}
            />
          </SectionStyled>
          <EconomyHeading
            mainCategory={mainCategory}
            category={category}
            subCategory={subCategory}
            setCategory={this.setCategory}
          />
          {isLoading && !hasData && <Spinner centered large verticalSpacing />}
          {!isLoading &&
            hasError && (
              <SectionStyled>
                {(!categories.data || categories.data.length <= 0) && (
                  <ParagraphCentered>
                    Dette gikk ikke helt som forventet. Vi fikk ikke hentet
                    kategorier.
                  </ParagraphCentered>
                )}
                {(!transactions.data || transactions.data.length <= 0) && (
                  <ParagraphCentered>
                    Du har ingen transaksjoner i valgt tidsrom
                  </ParagraphCentered>
                )}
              </SectionStyled>
            )}
          {hasData &&
            !isSubCategory && (
              <div>
                <EconomyGraphInstance
                  setCategory={(newCategory) => {
                    if (categoryData.type === 'mainCategory') {
                      return this.setCategory({
                        mainCategory,
                        category: newCategory,
                        subCategory,
                      });
                    }
                    return this.setCategory({
                      mainCategory,
                      category,
                      subCategory: newCategory,
                    });
                  }}
                  hasCategory={isCategory || isSubCategory}
                  categoryName={categoryName}
                  categoryData={categoryData}
                  dateFilter={dateFilter}
                  mainCategory={mainCategory}
                  isLoading={isLoading}
                />
                <SectionStyled>
                  <SubheadingStyled>Bruk per kategori</SubheadingStyled>
                  <Categories
                    setCategory={(newCategory) => {
                      if (categoryData.type === 'mainCategory') {
                        return this.setCategory({
                          mainCategory,
                          category: newCategory,
                          subCategory,
                        });
                      }
                      return this.setCategory({
                        mainCategory,
                        category,
                        subCategory: newCategory,
                      });
                    }}
                    categoryData={categoryData}
                  />
                </SectionStyled>
              </div>
            )}
          {hasData && (
            <div>
              <SectionStyled>
                <SubheadingStyled>Siste transaksjoner</SubheadingStyled>
                <Transactions
                  id="economy"
                  transactions={transactions.data.slice(0, 20)}
                  onClick={transaction => this.setTransaction(transaction)}
                />
                <TransactionModal
                  toggle={this.closeModal}
                  visible={this.state.modal.isVisible}
                  isOpened={this.state.modal.isOpened}
                  transaction={this.state.modal.transaction}
                />
              </SectionStyled>
              {heatMapData.length > 0 && (
                <SectionStyled>
                  <SubheadingStyled>Kart</SubheadingStyled>
                  <Heatmap data={heatMapData} />
                </SectionStyled>
              )}
            </div>
          )}
          <Search
            searchInputValue={searchInputValue}
            searchResult={searchResult}
            onSearchInputValueChange={(e) => {
              this.setState({ searchInputValue: e.target.value });
              this.debouncedSearch(e.target.value);
            }}
            onClick={this.setTransaction}
          />
        </EconomyPageStyled>
      </DefaultLayoutContainer>
    );
  }
}

Economy.propTypes = {
  economy: PropTypes.shape({}).isRequired,
  dispatchGetTransactions: PropTypes.func.isRequired,
  dispatchGetCategories: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatchGetTransactions: params => dispatch(getTransactions(params)),
  dispatchGetCategories: () => dispatch(getCategories()),
});

const mapStateToProps = state => ({
  economy: state.economy,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Economy);
