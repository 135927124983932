import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NavListLinkButtonStyled } from 'styled/Buttons';
import Transaction from './Transaction';

const TransactionsStyled = styled.div`
  ${NavListLinkButtonStyled} {
    border-bottom: 1px solid #ddd;
  }
`;
const Transactions = ({ transactions, id, onClick }) => (
  <TransactionsStyled>
    {transactions.map(transaction => (
      <Transaction
        key={`${id}-${transaction.transactionId}`}
        transaction={transaction}
        onClick={() => onClick(transaction)}
      />
    ))}
  </TransactionsStyled>
);

Transactions.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Transactions;
