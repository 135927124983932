import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PauseO, PlayO } from 'digitalbank-icons';
import { colors } from 'digitalbank-core-style';
import { ButtonResetStyle } from 'styled/Buttons';

export const PlayButtonStyled = styled(ButtonResetStyle)`
  width: 100%;
  color: ${props => (props.isActive ? colors.doveGray : colors.doveGray)};
  svg {
    width: 44px;
    height: 44px;
  }
`;

const PlayButtonText = styled.div`
  font-size: 18px;
  font-family: 'Oswald';
  text-transform: uppercase;
`;

class PlayButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: this.props.isInitiallyActive,
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick() {
    this.props.onClick();
    this.setState(state => ({
      isActive: !state.isActive,
    }));
  }

  render() {
    const text = {
      ...PlayButton.defaultProps.text,
      ...this.props.text,
    };
    return (
      <PlayButtonStyled onClick={this.onClick}>
        <Fragment>
          {this.state.isActive ? <PauseO /> : <PlayO />}
          <PlayButtonText>
            {this.state.isActive ? text.stop : text.start}
          </PlayButtonText>
        </Fragment>
      </PlayButtonStyled>
    );
  }
}

PlayButton.defaultProps = {
  isInitiallyActive: false,
  text: {
    start: 'Start',
    stop: 'Pause / Stoppe',
  },
};

PlayButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isInitiallyActive: PropTypes.bool,
  text: PropTypes.shape({
    start: PropTypes.string,
    stop: PropTypes.string,
  }),
};

export default PlayButton;
