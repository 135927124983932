const TOGGLE_RECIPIENTS = 'SEARCH/RECIPIENTS/TOGGLE';
const TOGGLE_SHARE_DROPDOWN = 'SEARCH/SHARE_DROPDOWN/TOGGLE';
const TOGGLE_FILTER = 'SEARCH/FILTER/TOGGLE';

const TOGGLE_AMOUNT_FIELDS = 'SEARCH/AMOUNT_FIELDS/TOGGLE';
const TOGGLE_DATE_FIELDS = 'SEARCH/DATE_FIELDS/TOGGLE';

export {
  TOGGLE_RECIPIENTS,
  TOGGLE_SHARE_DROPDOWN,
  TOGGLE_FILTER,
  TOGGLE_AMOUNT_FIELDS,
  TOGGLE_DATE_FIELDS,
};
