import {
  TOGGLE_EDIT_MODE_ON_REGIONAL_BLOCKING,
  CLOSE_EDIT_MODE_ON_REGIONAL_BLOCKING,
  OPEN_EDIT_MODE_ON_REGIONAL_BLOCKING,
  TOGGLE_REGIONAL_BLOCKING_HELP,
  CLOSE_REGIONAL_BLOCKING_HELP,
  OPEN_REGIONAL_BLOCKING_LOCATION,
  CLOSE_REGIONAL_BLOCKING_LOCATION,
  TOGGLE_REGIONAL_BLOCKING_LOCATION,
  TOGGLE_LOCATION_INFO,
} from '../actions/actionTypes';

const initialState = {
  editMode: false,
  displayHelp: false,
  openLocations: [0, 1],
  visibleLocationInfoIds: [],
};

const numberExistInArray = (number, array) => {
  const index = array.indexOf(number);
  return index > -1;
};

const getNewArrayWithoutNumber = (number, array) => {
  const index = array.indexOf(number);
  return [...array.slice(0, index), ...array.slice(index + 1)];
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_EDIT_MODE_ON_REGIONAL_BLOCKING:
      return {
        ...state,
        editMode: !state.editMode,
      };
    case CLOSE_EDIT_MODE_ON_REGIONAL_BLOCKING:
      return {
        ...state,
        editMode: false,
      };
    case OPEN_EDIT_MODE_ON_REGIONAL_BLOCKING:
      return {
        ...state,
        editMode: true,
      };
    case TOGGLE_REGIONAL_BLOCKING_HELP:
      return {
        ...state,
        displayHelp: !state.displayHelp,
      };
    case CLOSE_REGIONAL_BLOCKING_HELP:
      return {
        ...state,
        displayHelp: false,
      };
    case OPEN_REGIONAL_BLOCKING_LOCATION:
      return {
        ...state,
        openLocations: [...state.openLocations, action.id],
      };
    case CLOSE_REGIONAL_BLOCKING_LOCATION:
      return {
        ...state,
        openLocations: getNewArrayWithoutNumber(action.id, state.openLocations),
      };
    case TOGGLE_REGIONAL_BLOCKING_LOCATION:
      if (numberExistInArray(action.id, state.openLocations)) {
        return {
          ...state,
          openLocations: getNewArrayWithoutNumber(
            action.id,
            state.openLocations,
          ),
        };
      }
      return {
        ...state,
        openLocations: [...state.openLocations, action.id],
      };
    case TOGGLE_LOCATION_INFO:
      if (numberExistInArray(action.index, state.visibleLocationInfoIds)) {
        return {
          ...state,
          visibleLocationInfoIds: getNewArrayWithoutNumber(
            action.index,
            state.visibleLocationInfoIds,
          ),
        };
      }
      return {
        ...state,
        visibleLocationInfoIds: [...state.visibleLocationInfoIds, action.index],
      };
    default:
      return state;
  }
}
