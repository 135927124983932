import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isPristine, destroy } from 'redux-form';
import { bindActionCreators } from 'redux';
import { resetAccountPicker } from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import * as PATHS from 'routes/paths';
import { ReceiptPage } from 'digitalbank-layout';
import wizardPage from 'components/wizardPage';
import moment from 'moment';
import { formatAccountNumber } from 'utils/format.util';
import i18n from 'i18n';
import { isKidNumber } from 'utils/validation.util';
import { BETALE_EN_EDIT_FORM_NAME } from '../payment.constants';
import EditBetaleEnNavList from './EditBetaleEnNavList';
import PaymentAsideContent from '../PaymentAsideContent';
import PaymentStyled from '../PaymentStyled';

class EditBetaleEnReceipt extends Component {
  componentWillUnmount() {
    this.props.onDestroy(BETALE_EN_EDIT_FORM_NAME);
    this.props.onResetAccountPicker(`${BETALE_EN_EDIT_FORM_NAME}FraKonto`);
  }
  render() {
    const {
      values: {
        kroner,
        oere,
        kidMelding,
        tilKontonavn,
        fraKonto,
        tilKonto,
        dato,
      },
    } = this.props;

    const dueDate = moment(dato, 'L');
    const dueDatePrint = dueDate.format('LL');
    const toAccount = tilKontonavn || formatAccountNumber(tilKonto);
    const amount = i18n(i => i.NUMBER_TWO_DIGITS, {
      number: Number(kroner) + Number(oere / 100),
    });
    const melding = !isKidNumber(kidMelding) ? kidMelding : '';
    const kid = isKidNumber(kidMelding) ? kidMelding : '';
    return (
      <PaymentStyled>
        <ReceiptPage
          className="p-payment"
          receiptTitle="Betaling er endret"
          receiptDescription={
            <div>
              <p style={{ marginBottom: '0px' }}>
                <strong>{amount}</strong>
                {` vil bli betalt til ${toAccount} fra ${fraKonto.visningsnavn ||
                  fraKonto.accountName} `}
                <strong>{dueDatePrint}.</strong>
              </p>
              {kid && (
                <p
                  style={{
                    fontSize: '14px',
                    color: '#82877f',
                    marginBottom: '0px',
                  }}
                >
                  KID: {kid}
                </p>
              )}
              {melding && (
                <p
                  style={{
                    fontSize: '14px',
                    color: '#82877f',
                    marginBottom: '0px',
                  }}
                >
                  Melding: {melding}
                </p>
              )}
            </div>
          }
          asideContent={<PaymentAsideContent />}
        >
          <EditBetaleEnNavList />
        </ReceiptPage>
      </PaymentStyled>
    );
  }
}

EditBetaleEnReceipt.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onDestroy: bindActionCreators(destroy, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

const mapStateToProps = state => ({
  values: getFormValues(BETALE_EN_EDIT_FORM_NAME)(state),
});

export default wizardPage({
  redirectTest: state => isPristine(BETALE_EN_EDIT_FORM_NAME)(state),
  redirectPath: PATHS.FASTE_OPPDRAG,
})(connect(mapStateToProps, mapDispatchToProps)(EditBetaleEnReceipt));
