import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import RenderSelectField from 'digitalbank-form/lib/es/components/base/fields/SelectField';

const OppdragFrekvensSelect = ({ frekvenser, label, disabled }) => (
  <Field
    name="frekvens"
    component={RenderSelectField}
    label={label}
    disabled={disabled}
  >
    {frekvenser.map(frekvens => (
      <option value={frekvens.value} key={frekvens.value}>
        {frekvens.text}
      </option>
    ))}
  </Field>
);

OppdragFrekvensSelect.propTypes = {
  frekvenser: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

OppdragFrekvensSelect.defaultProps = {
  disabled: false,
};

export default OppdragFrekvensSelect;
