export { default as Breakpoints } from './breakpoints';
export { default as KeyCodes } from './keyCodes';
export { default as FirebaseApi } from './firebase';

export const chunkString = (str, length) =>
  str.match(new RegExp('.{1,' + length + '}', 'g')); // eslint-disable-line

export const categorizeRecipients = (recipients) => {
  // Categorize recipients
  const categories = [];
  recipients.forEach((recipient) => {
    // Check first name letter
    const firstChar = recipient.name[0];
    // create new list if none with same char exist
    const existingCategory = categories.find(c => c.name === firstChar);
    // check if existingCategory is defined
    if (existingCategory) {
      existingCategory.recipients.push({ ...recipient });
    } else {
      // create new category with first recipient
      categories.push({
        name: firstChar,
        recipients: [{ ...recipient }],
      });
    }
  });
  return categories;
};

export const formatAmount = amount =>
  amount.toLocaleString('nb-NO', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
