/* eslint-disable no-script-url */

import React, { Component } from 'react';
import styled from 'styled-components';
import { config } from 'services/economy.service';
import Accordion, { AccordionItemHeader } from 'digitalbank-accordion';
import _ from 'lodash';
import { DefaultLayoutContainer } from 'styled';
import { LogoStyled } from './styled-components';
import MerchantForm from './MerchantForm';

const MerchantsPageStyled = styled.div``;

const AccordionItemDescriptionStyled = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;

  ${LogoStyled} {
    margin-right: 15px;
  }
`;

const AccordionItemContentStyled = styled.div`
  padding: 20px;
`;

export default class Merchants extends Component {
  static uploadLogo(file, id) {
    const url = `${config.apiEndpoints.logo}/upload/${id}`;
    const formData = new FormData();
    formData.append('files', file);
    formData.append('id', id);
    return fetch(url, {
      method: 'POST',
      body: formData,
    });
  }

  static uploadLogoWithoutId(file, name, website) {
    const url = `${config.apiEndpoints.logo}/upload/`;
    const formData = new FormData();
    formData.append('files', file);
    formData.append('name', name);
    formData.append('website', website);
    return fetch(url, {
      method: 'POST',
      body: formData,
    });
  }
  constructor(props) {
    super(props);
    this.state = {
      merchants: undefined,
    };
    this.onChange = this.onChange.bind(this);
    this.getMerchants = this.getMerchants.bind(this);
  }
  componentDidMount() {
    this.getMerchants();
  }
  onChange(e, id) {
    Merchants.uploadLogo(e.target.files[0], id).then(() => {
      this.getMerchants();
    });
  }
  getMerchants() {
    fetch(config.apiEndpoints.logo)
      .then(res => res.json())
      .then((data) => {
        this.setState({ merchants: data });
      });
  }
  render() {
    const { merchants } = this.state;
    let items;
    if (merchants && merchants.length > 0) {
      const merchantsSortedByHasLogo = _.orderBy(
        merchants,
        ['hasLogo', 'name'],
        ['asc', 'asc'],
      );
      items = merchantsSortedByHasLogo.map(merchant => ({
        id: merchant.id.toString(),
        header: (
          <AccordionItemHeader
            heading={{
              description: (
                <AccordionItemDescriptionStyled>
                  {merchant.hasLogo && (
                    <LogoStyled>
                      <img
                        src={`${config.routes.logo}/${merchant.id}?${
                          merchant.updated
                            ? new Date(merchant.updated).getTime()
                            : ''
                        }`}
                        alt={`Logo for ${merchant.name}`}
                      />
                    </LogoStyled>
                  )}
                  {merchant.name}
                </AccordionItemDescriptionStyled>
              ),
              info: merchant.website || 'Ingen nettside registrert',
            }}
          />
        ),
        content: (
          <AccordionItemContentStyled>
            <h4>Oppdatere informasjon</h4>
            <MerchantForm
              merchant={merchant}
              upload={e => this.onChange(e, merchant.id)}
              getMerchants={this.getMerchants}
            />
          </AccordionItemContentStyled>
        ),
      }));
    }

    return (
      <DefaultLayoutContainer>
        <MerchantsPageStyled>
          {items && <Accordion items={items} />}
        </MerchantsPageStyled>
      </DefaultLayoutContainer>
    );
  }
}
