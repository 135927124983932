import { UPDATE_WINDOW } from '../actions/actionTypes';

const initialState = {
  width: 0,
  height: 0,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_WINDOW:
      return {
        ...state,
        width: action.windowSize.width,
        height: action.windowSize.height,
      };
    default:
      return state;
  }
}
