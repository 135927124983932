import styled from 'styled-components';
import { breakpoints, colors } from 'digitalbank-core-style';
import {
  horizontalLayoutSpacing,
  verticalLayoutSpacing,
} from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';
import { clearButton } from 'styled/Buttons';

export default styled.div`
  .p-search {
    &__container {
      @media print {
        padding: 0 $default-spacing;
      }
    }

    &__main,
    &__heading {
      /* @include spacing(padding, (left, right), (first, second)); */
      ${horizontalLayoutSpacing({ phone: false })};
    }

    &__main {
      /* result overlay */
      position: relative;
    }

    &__aside {
      /* @include spacing(padding, (left, right)); */
      ${horizontalLayoutSpacing};
    }

    &__retry-button {
      margin-top: ${rem(50)};
    }

    &__detail-heading,
    &__detail-section {
      margin-bottom: ${rem(15)};
    }
  }

  .search-filter-details {
    display: none;
    @media print {
      display: block;
      margin: 0 0 $vertical-margin-small 0;
    }
  }

  .search-form {
    @media print {
      display: none;
    }

    &__top-filter {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: 20px;
      @media (min-width: ${`${breakpoints.tablet}px`}) {
        display: flex;
      }
    }

    &__search-field {
      flex: 1;
      @media (max-width: ${`${breakpoints.tablet - 1}`}) {
        margin-bottom: ${rem(12)};
      }

      @media (min-width: ${`${breakpoints.tablet}px`}) {
        padding-right: 15px;
      }
    }

    &__account-field {
      /* reset default form-group margin */
      margin-bottom: 0;
      /* make sure disp. shows correctly */
      min-width: 250px;
    }
  }

  .transactions {
    position: relative;
    
    @media screen {
      margin-bottom: 5rem;
    }

    @media print {
      max-width: 400px;
    }

    &--has-no-result {
      /*Minimum height to assure pending state displays nice */
      min-height: 150px;
      border-bottom: 1px solid ${colors.alto};
      border-top: 1px solid ${colors.alto};
    }

    &--is-pending {
      &::after,
      &::before {
        visibility: visible;
      }

      &::before {
        opacity: 0.65;
      }

      &::after {
        opacity: 1;
      }
    }

    .load-more {
        ${clearButton}
        ${horizontalLayoutSpacing}
        ${verticalLayoutSpacing}

        @media (min-width: ${breakpoints.tablet}px) {
          font-size: ${rem(18)};
        }

        color: ${colors.boulder};
        background: #f8f8f8;
        border-left: 3px solid transparent;
        border-top: 1px solid ${colors.alto};
        border-bottom: 1px solid ${colors.alto};
        border-right: 0;
        text-align: left;
        width: 100%;
      }

      &__spinner {
        margin-top: ${rem(50)};
      }

    .accordion-item-header {
      &--is-muted {
        font-style: italic;
        color: ${colors.boulder};
      }
    }
  }

  .transaction {
    &__amount {
      &--is-positive {
        color: ${colors.limeade};
        font-weight: 500;
      }
    }

    &__content {
      ${horizontalLayoutSpacing}
      padding-top: ${rem(10)};
      padding-bottom: ${rem(10)};

      .detail-section {
        padding-bottom: ${rem(10)};
      }
    }
  }

  .sum-transactions {
    ${horizontalLayoutSpacing}
    ${verticalLayoutSpacing}
    color: ${colors.boulder};
    text-align: right;
    @media print {
      padding-left: 0;
      padding-right: 0;
      font-size: ${rem(12)};
    }

    &__text {
      &--bolder {
        font-weight: 500;
      }
    }
  }
`;
