/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField } from 'digitalbank-form';
import classNames from 'classnames';
import Collapse from 'react-css-collapse';
import { QuestionMark } from 'digitalbank-icons';

const MaksBeloep = ({
  state, setState, changeFieldValue, formAlias,
}) => (
  <div>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        name="displayMaksBeloep"
        id="displayMaksBeloep"
        type="checkbox"
        className="checkbox"
        checked={state.displayMaksBeloep}
        onChange={() => {
          setState({ displayMaksBeloep: !state.displayMaksBeloep });
          changeFieldValue(formAlias, 'maksBeloep', 0);
        }}
      />
      <label className="checkbox__label" htmlFor="displayMaksBeloep">
        Sett et maksbeløp pr. betaling
      </label>
      <button
        type="button"
        className={classNames('checkbox__help-button', {
          'checkbox__help-button--is-active': state.help.maksBeloep,
        })}
        onClick={() => {
          setState({
            help: { ...state.help, maksBeloep: !state.help.maksBeloep },
          });
        }}
      >
        <QuestionMark
          className="checkbox__help-button-icon"
          active={state.help.maksBeloep}
        />
      </button>
    </div>
    <Collapse isOpen={state.help.maksBeloep} className="input-help-animation">
      <div
        className={classNames(
          'input-help input-help--with-help-button-indent',
          {
            'input-help--is-active': state.help.maksBeloep,
          },
        )}
      >
        <div
          className="input-help__content"
          dangerouslySetInnerHTML={{
            __html:
              '<p>Maksbeløpet er den høyeste summen som kan trekkes på en AvtaleGiro. Det er smart å sette maksbeløpet til dobbelt så mye som den egentlige summen på regningen for å unngå at den stoppes.</p>',
          }}
        />
      </div>
    </Collapse>
    {state.displayMaksBeloep && (
      <div style={{ marginLeft: '30px' }}>
        <Field
          name="maksBeloep"
          label="Maksbeløp"
          component={InputField}
          type="number"
          className="form-group--with-help-button-indent"
        />
      </div>
    )}
  </div>
);

MaksBeloep.propTypes = {
  state: PropTypes.shape({}).isRequired,
  setState: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  formAlias: PropTypes.string.isRequired,
};

export default MaksBeloep;
