import React from 'react';
import PropTypes from 'prop-types';
import Encap from 'components/Reauth/Encap';
import Go3 from 'components/Reauth/Go3';
import i18n from 'i18n';
import MobileDetect from 'mobile-detect';
import ReauthStyled from './ReuthStyled';

const ReauthWrapper = ({ description, onSubmit, goBack }) => {
  const mobileDetect = new MobileDetect(window.navigator.userAgent);
  const isMobile = mobileDetect.mobile();
  return (
    <ReauthStyled>
      {isMobile && (
        <Encap
          description={description}
          codeLength={4}
          onSubmit={onSubmit}
          labelText={i18n(i => i.REAUT_ENCAP_CODE_LABEL)}
          goBack={goBack}
        />
      )}
      {!isMobile && (
        <Go3
          description={description}
          onSubmit={onSubmit}
          labelText={i18n(i => i.REAUT_GO3_CODE_LABEL)}
          goBack={goBack}
        />
      )}
    </ReauthStyled>
  );
};

ReauthWrapper.propTypes = {
  description: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
};

export default ReauthWrapper;
