import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CheckCircle } from 'digitalbank-icons';
import Spinner from 'digitalbank-spinner';

const LoadingStatusIndicator = ({ text }) => (
  <span className="status-indicator status-indicator--positive">
    <Spinner className="status-indicator__icon" />
    <span>{text}</span>
  </span>
);

LoadingStatusIndicator.propTypes = {
  text: PropTypes.string.isRequired,
};

const FinishedStatusIndicator = ({ status, text }) => (
  <span
    className={classNames('status-indicator status-indicator--positive', {
      'status-indicator--finished': status === 'FINISHED',
    })}
  >
    <CheckCircle className="status-indicator__icon" />
    <span>{text}</span>
  </span>
);

FinishedStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const StatusIndicator = ({ status, progressText, successText }) => {
  if (status === 'IN_PROGRESS') {
    return <LoadingStatusIndicator text={progressText} />;
  } else if (status === 'SUCCESSFUL' || status === 'FINISHED') {
    return <FinishedStatusIndicator status={status} text={successText} />;
  }
  return <span className="status-indicator" />;
};

StatusIndicator.defaultProps = {
  progressText: 'Lagrer...',
  successText: 'Lagret!',
};

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  progressText: PropTypes.string,
  successText: PropTypes.string,
};

export default StatusIndicator;
