import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  closeRegionalBlockingLocation,
  openRegionalBlockingLocation,
  toggleRegionalBlockingLocation,
  toggleLocationInfo,
} from 'actions';
import { RegionBlockingMap } from 'components';
// import RegionalBlockingOpenLocations from 'components/RegionalBlockingOpenLocations';
import RegionalBlockingLocationOption from 'routes/RegionalBlocking/RegionalBlockingLocationOption';
import { RegionalBlockingRegions } from 'data';
import RegionalBlockingMapContainerStyled from './RegionalBlockingMapContainerStyled';

const locationIsOpen = (openLocationIds, id) =>
  openLocationIds.indexOf(id) > -1;

const MapInfo = ({ openLocationIds, toggleLocation, isEditMode }) => (
  <section
    className={classNames('regional-blocking-map-container__map-info', {
      'regional-blocking-map-container__map-info--is-editable': isEditMode,
      'regional-blocking-map-container__map-info--is-not-editable': !isEditMode,
    })}
  >
    <RegionBlockingMap
      openLocationIds={openLocationIds}
      toggleLocation={toggleLocation}
      className="regional-blocking-map-container__svg"
    />
  </section>
);

MapInfo.defaultProps = {
  openLocationIds: null,
  toggleLocation: undefined,
  isEditMode: false,
};

MapInfo.propTypes = {
  openLocationIds: PropTypes.arrayOf(PropTypes.number),
  toggleLocation: PropTypes.func,
  isEditMode: PropTypes.bool,
};

const RegionalBlockingMapContainer = ({
  periodicControlRegionalBlocking,
  onToggleRegionalBlockingLocation,
  onToggleLocationInfo,
  onCloseRegionalBlockingLocation,
  onOpenRegionalBlockingLocation,
}) => {
  const {
    visibleLocationInfoIds,
    openLocations,
  } = periodicControlRegionalBlocking;
  const openLocationIds = openLocations;

  const toggleLocation = (openIds, id) => {
    if (locationIsOpen(openIds, id)) {
      onCloseRegionalBlockingLocation(id);
    } else {
      onOpenRegionalBlockingLocation(id);
    }
  };
  const locationOptions = RegionalBlockingRegions.map(
    (location, locationIndex) => (
      <RegionalBlockingLocationOption
        key={`location-option-${location.name}`}
        location={location}
        toggleLocationInfo={() => onToggleLocationInfo(locationIndex)}
        toggleLocation={() => toggleLocation(openLocationIds, location.id)}
        isOpen={locationIsOpen(openLocationIds, location.id)}
        infoIsOpen={visibleLocationInfoIds.indexOf(locationIndex) > -1}
      />
    ),
  );
  return (
    <RegionalBlockingMapContainerStyled>
      <div className="regional-blocking-map-container">
        <ul className="regional-blocking-map-container__map-list">
          {locationOptions}
        </ul>
        <MapInfo
          openLocationIds={openLocationIds}
          toggleLocation={id => onToggleRegionalBlockingLocation(id)}
          isEditMode
        />
      </div>
    </RegionalBlockingMapContainerStyled>
  );
};

RegionalBlockingMapContainer.propTypes = {
  periodicControlRegionalBlocking: PropTypes.shape({
    visibleLocationInfoIds: PropTypes.arrayOf(PropTypes.number),
    openLocations: PropTypes.arrayOf(PropTypes.number),
    editMode: PropTypes.bool.isRequired,
  }).isRequired,
  onToggleRegionalBlockingLocation: PropTypes.func.isRequired,
  onToggleLocationInfo: PropTypes.func.isRequired,
  onOpenRegionalBlockingLocation: PropTypes.func.isRequired,
  onCloseRegionalBlockingLocation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  periodicControlRegionalBlocking: state.periodicControlRegionalBlocking,
});

const mapDispatchToProps = dispatch => ({
  onOpenRegionalBlockingLocation: bindActionCreators(
    openRegionalBlockingLocation,
    dispatch,
  ),
  onCloseRegionalBlockingLocation: bindActionCreators(
    closeRegionalBlockingLocation,
    dispatch,
  ),
  onToggleRegionalBlockingLocation: bindActionCreators(
    toggleRegionalBlockingLocation,
    dispatch,
  ),
  onToggleLocationInfo: bindActionCreators(toggleLocationInfo, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  RegionalBlockingMapContainer,
);
