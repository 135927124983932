import React from 'react';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import ScheduledPaymentNavListLinkContainer from 'containers/ScheduledPaymentNavListLinkContainer';

export default () => (
  <NavList
    items={[
      <NavListLink
        component={Link}
        to={PATHS.TRANSFER}
        title={{ text: 'Ny overføring' }}
        key="overfoering"
      />,
      <ScheduledPaymentNavListLinkContainer />,
      <NavListLink
        component={Link}
        to={PATHS.PAY}
        title={{ text: 'Betale en regning' }}
        key="payment"
      />,
    ]}
  />
);
