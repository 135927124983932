import _ from 'lodash';
import shortid from 'shortid';
import { STATUS } from 'digitalbank-mockup-data/lib/Betalingsavtaler/Status';

const getIndexOfCurrentBetalingsavtale = (
  currentBetalingsavtaler,
  tilbyderId,
) =>
  _.findIndex(currentBetalingsavtaler.data, x => x.tilbyderId === tilbyderId);

const createNewBetalingsavtaleWithEfaktura = (tilbyderId, values) => ({
  id: shortid.generate(),
  tilbyderId,
  efaktura: {
    status: STATUS.ACTIVE,
    values: {
      ...values,
    },
  },
});

const createNewBetalingsavtaleWithAvtalegiro = (tilbyderId, values) => ({
  id: shortid.generate(),
  tilbyderId,
  avtalegiro: {
    status: STATUS.ACTIVE,
    values: {
      ...values,
    },
  },
});

const createNewBetalingsavtale = (betalingsavtaleType, tilbyderId, values) => {
  if (betalingsavtaleType === 'EFAKTURA') {
    return createNewBetalingsavtaleWithEfaktura(tilbyderId, values);
  }
  if (betalingsavtaleType === 'AVTALEGIRO') {
    return createNewBetalingsavtaleWithAvtalegiro(tilbyderId, values);
  }
  return undefined;
};

const updateExistingBetalingsavtale = (
  betalingsavtaleType,
  betalingsavtale,
  values,
) => {
  if (betalingsavtaleType === 'EFAKTURA') {
    const newValues =
      betalingsavtale.efaktura && betalingsavtale.efaktura.values
        ? {
          ...betalingsavtale.efaktura.values,
          ...values,
        }
        : { ...values };

    return {
      ...betalingsavtale,
      efaktura: {
        ...betalingsavtale.efaktura,
        status: STATUS.ACTIVE,
        values: newValues,
      },
    };
  }
  if (betalingsavtaleType === 'AVTALEGIRO') {
    const newValues =
      betalingsavtale.avtalegiro && betalingsavtale.avtalegiro.values
        ? {
          ...betalingsavtale.avtalegiro.values,
          ...values,
        }
        : { ...values };

    return {
      ...betalingsavtale,
      avtalegiro: {
        ...betalingsavtale.avtalegiro,
        status: STATUS.ACTIVE,
        values: newValues,
      },
    };
  }
  return undefined;
};

const updateBetalingsavtaler = (
  currentBetalingsavtaler,
  betalingsavtaleType,
  tilbyderId,
  values,
) => {
  // If betalingsavtale already exist with tilbyderId
  const indexOfCurrentBetalingsavtale = getIndexOfCurrentBetalingsavtale(
    currentBetalingsavtaler,
    tilbyderId,
  );
  if (indexOfCurrentBetalingsavtale === -1) {
    return {
      ...currentBetalingsavtaler,
      data: [
        ...currentBetalingsavtaler.data,
        createNewBetalingsavtale(betalingsavtaleType, tilbyderId, values),
      ],
    };
  }
  // Update existing betalingsavtale
  const existingBetalingsavtale =
    currentBetalingsavtaler.data[indexOfCurrentBetalingsavtale];
  return {
    ...currentBetalingsavtaler,
    data: [
      ...currentBetalingsavtaler.data.slice(0, indexOfCurrentBetalingsavtale),
      updateExistingBetalingsavtale(
        betalingsavtaleType,
        existingBetalingsavtale,
        values,
      ),
      ...currentBetalingsavtaler.data.slice(indexOfCurrentBetalingsavtale + 1),
    ],
  };
};

const createEfakturaAndAvtalegiro = (
  currentBetalingsavtaler,
  change,
  tilbyderId,
  values,
) => {
  const updatedBetalingsavtalerWithEfaktura = updateBetalingsavtaler(
    currentBetalingsavtaler,
    'EFAKTURA',
    tilbyderId,
    values,
  );
  const updatedBetalignsavtalerWithAvtalegiro = updateBetalingsavtaler(
    updatedBetalingsavtalerWithEfaktura,
    'AVTALEGIRO',
    tilbyderId,
    values,
  );
  change('betalingsavtaler', updatedBetalignsavtalerWithAvtalegiro);
};

const createEfaktura = (
  currentBetalingsavtaler,
  change,
  tilbyderId,
  values,
) => {
  change(
    'betalingsavtaler',
    updateBetalingsavtaler(
      currentBetalingsavtaler,
      'EFAKTURA',
      tilbyderId,
      values,
    ),
  );
};

const createAvtalegiro = (
  currentBetalingsavtaler,
  change,
  tilbyderId,
  values,
) => {
  change(
    'betalingsavtaler',
    updateBetalingsavtaler(
      currentBetalingsavtaler,
      'AVTALEGIRO',
      tilbyderId,
      values,
    ),
  );
};

const getAvtaleWithNewStatus = (avtale, status, betalingsavtaleType) => ({
  ...avtale,
  [betalingsavtaleType]: {
    ...avtale.avtalegiro,
    status,
  },
});

const setStatusOnAvtaleInBetalingsavtale = (
  currentBetalingsavtaler,
  changeData,
  tilbyderId,
  type,
  status,
) => {
  const indexOfCurrentBetalingsavtale = getIndexOfCurrentBetalingsavtale(
    currentBetalingsavtaler,
    tilbyderId,
  );
  const existingBetalingsavtale =
    currentBetalingsavtaler.data[indexOfCurrentBetalingsavtale];
  changeData('betalingsavtaler', {
    ...currentBetalingsavtaler,
    data: [
      ...currentBetalingsavtaler.data.slice(0, indexOfCurrentBetalingsavtale),
      {
        ...existingBetalingsavtale,
        [type]: {
          ...existingBetalingsavtale[type],
          status,
        },
      },
      ...currentBetalingsavtaler.data.slice(indexOfCurrentBetalingsavtale + 1),
    ],
  });
};

const deleteEfakturaAvtale = (
  currentBetalingsavtaler,
  changeData,
  tilbyderId,
) =>
  setStatusOnAvtaleInBetalingsavtale(
    currentBetalingsavtaler,
    changeData,
    tilbyderId,
    'efaktura',
    STATUS.DELETED,
  );
const deleteAvtalegiroAvtale = (
  currentBetalingsavtaler,
  changeData,
  tilbyderId,
) =>
  setStatusOnAvtaleInBetalingsavtale(
    currentBetalingsavtaler,
    changeData,
    tilbyderId,
    'avtalegiro',
    STATUS.DELETED,
  );

const stopAvtalegiroAvtale = (
  currentBetalingsavtaler,
  changeData,
  tilbyderId,
) =>
  setStatusOnAvtaleInBetalingsavtale(
    currentBetalingsavtaler,
    changeData,
    tilbyderId,
    'avtalegiro',
    STATUS.STOPPED,
  );
const startAvtalegiroAvtale = (
  currentBetalingsavtaler,
  changeData,
  tilbyderId,
) =>
  setStatusOnAvtaleInBetalingsavtale(
    currentBetalingsavtaler,
    changeData,
    tilbyderId,
    'avtalegiro',
    STATUS.ACTIVE,
  );

const deleteBetalingsavtale = (
  currentBetalingsavtaler,
  changeData,
  tilbyderId,
) => {
  const indexOfCurrentBetalingsavtale = getIndexOfCurrentBetalingsavtale(
    currentBetalingsavtaler,
    tilbyderId,
  );
  const existingBetalingsavtale =
    currentBetalingsavtaler.data[indexOfCurrentBetalingsavtale];
  changeData('betalingsavtaler', {
    ...currentBetalingsavtaler,
    data: [
      ...currentBetalingsavtaler.data.slice(0, indexOfCurrentBetalingsavtale),
      {
        ...existingBetalingsavtale,
        efaktura: {
          ...existingBetalingsavtale.efaktura,
          status: STATUS.DELETED,
        },
        avtalegiro: {
          ...existingBetalingsavtale.avtalegiro,
          status: STATUS.DELETED,
        },
      },
      ...currentBetalingsavtaler.data.slice(indexOfCurrentBetalingsavtale + 1),
    ],
  });
};

export {
  createEfaktura,
  createAvtalegiro,
  createEfakturaAndAvtalegiro,
  getIndexOfCurrentBetalingsavtale,
  updateBetalingsavtaler,
  deleteBetalingsavtale,
  deleteEfakturaAvtale,
  deleteAvtalegiroAvtale,
  startAvtalegiroAvtale,
  stopAvtalegiroAvtale,
  setStatusOnAvtaleInBetalingsavtale,
  getAvtaleWithNewStatus,
};
