export const TOGGLE_PRIVATE_ACCOUNT = 'KEY_NUMBERS/TOGGLE_PRIVATE_ACCOUNT';
export const TOGGLE_SHARED_ACCOUNT = 'KEY_NUMBERS/TOGGLE_SHARED_ACCOUNT';
export const TOGGLE_CREDIT_ACCOUNT = 'KEY_NUMBERS/TOGGLE_CREDIT_ACCOUNT';
export const SELECT_DUE_DATE_REGISTER_DISPLAY_FILTER =
  'KEY_NUMBERS/SELECT_DUE_DATE_REGISTER_DISPLAY_FILTER';
export const TOGGLE_HIDE_PRIVATE_TRANSFER =
  'KEY_NUMBERS/TOGGLE_HIDE_PRIVATE_TRANSFER';
export const SELECT_CREDIT_DISPLAY_FILTER =
  'KEY_NUMBERS/SELECT_CREDIT_DISPLAY_FILTER';
export const SELECT_FIRST_CUSTOM_KEY_NUMBER =
  'KEY_NUMBER/SELECT_FIRST_CUSTOM_KEY_NUMBER';
export const SELECT_SECOND_CUSTOM_KEY_NUMBER =
  'KEY_NUMBER/SELECT_SECOND_CUSTOM_KEY_NUMBER';
