const Accounts = [
  'AL47212110090000000235698741',
  'AU47212110090000000235698741',
  'BR9700360305000010009795493P',
  'SE1750000000052221116968',
  'PL60105012271000009145917150',
  'ES7200495740412816648176',
  '1255459395',
];

const Countries = [
  {
    country: 'Albania',
    countryCode: 'AL',
    bankCodeRequired: true,
    ibanCharacters: 28,
    currencies: ['EUR', 'USD', 'NOK'],
  },
  {
    country: 'Australia',
    countryCode: 'AU',
    bankCodeRequired: true,
    ibanDisplayFilter: 'HIDDEN',
    currencies: ['AUD', 'EUR', 'USD', 'NOK'],
  },
  {
    country: 'Brazil',
    countryCode: 'BR',
    bankCodeRequired: false,
    ibanCharacters: 28,
    currencies: ['EUR', 'USD', 'NOK'],
  },
  {
    country: 'Sweden',
    countryCode: 'SE',
    bankCodeRequired: true,
    ibanCharacters: 24,
    currencies: ['SEK', 'EUR', 'DKK', 'USD', 'GBP', 'CHF', 'NOK'],
  },
  {
    country: 'United States of America',
    countryCode: 'US',
    bankCodeRequired: false,
    ibanCharacters: 24,
    currencies: ['USD', 'EUR', 'NOK'],
  },
  {
    country: 'Poland',
    countryCode: 'PL',
    bankCodeRequired: true,
    ibanCharacters: 28,
    currencies: ['PLN', 'EUR', 'CHF', 'SEK', 'DKK', 'GBP', 'NOK'],
  },
  {
    country: 'Spain',
    countryCode: 'ES',
    bankCodeRequired: true,
    ibanCharacters: 24,
    currencies: ['EUR', 'USD', 'GBP', 'NOK'],
  },
];

const Banks = [
  {
    name: 'National bank of Albania',
    code: 'ALIBABA',
    bic: 'BICCODE',
    country: 'AL',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
    ibanExample: Accounts[0],
  },
  {
    name: 'National bank of Australia',
    code: 'KIWI',
    bic: 'BICCODE',
    country: 'AU',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
    ibanExample: Accounts[1],
  },
  {
    name: 'National bank of Brazil',
    code: 'VUVUZELA',
    bic: 'BICCODE',
    country: 'BR',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
    ibanExample: Accounts[2],
  },
  {
    name: 'Skandinaviska Enskilda Banken',
    code: 'ESSESESSXXX',
    bic: 'BICCODE',
    country: 'SE',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
    ibanExample: Accounts[3],
  },
  {
    name: 'National bank of USA',
    code: 'BANKCODE',
    bic: 'BICCODE',
    country: 'US',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
  },
  {
    name: 'ING BANK SLASKI SA',
    bic: 'INGBPLPWXXX',
    code: 'INGBPLPWXXX',
    country: 'PL',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
    ibanExample: Accounts[4],
  },
  {
    name: 'BANCO SANTANDER S.A.',
    bic: 'BSCHESMMXXX',
    code: 'BSCHESMMXXX',
    country: 'ES',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
    ibanExample: Accounts[5],
  },
  {
    name: 'Citibank N.A.',
    code: 'CITIUS33',
    country: 'US',
    address1: 'Bank address 1',
    address2: 'Bank address 2',
    address3: 'Bank address 3',
    ibanExample: Accounts[6],
  },
];

const PaymentTypes = [
  {
    'nb-NO': 'Kjøp/salg av varer',
    'nn-NO': 'Kjøp/salg av varer',
    'en-GB': 'Purchase / sale of goods',
  },
  {
    'nb-NO': 'Leie',
    'nn-NO': 'Leie',
    'en-GB': 'Rent',
  },
  {
    'nb-NO': 'Annet kjøp/salg av tjenester',
    'nn-NO': 'Annet kjøp/salg av tjenester',
    'en-GB': 'Other purchases / sales of services',
  },
  {
    'nb-NO': 'Renter',
    'nn-NO': 'Renter',
    'en-GB': 'Interest',
  },
  {
    'nb-NO': 'Utbytte',
    'nn-NO': 'Utbytte',
    'en-GB': 'Dividend',
  },
  {
    'nb-NO': 'Annen kapitalavkastning',
    'nn-NO': 'Annen kapitalavkastning',
    'en-GB': 'Other capital',
  },
  {
    'nb-NO': 'Kjøp/salg av fast eiendom og aktiverte rettigheter i utlandet',
    'nn-NO': 'Kjøp/salg av fast eiendom og aktiverte rettigheter i utlandet',
    'en-GB': 'Purchase / sale of real estate and rights abroad',
  },
  {
    'nb-NO': 'Direkteinvesteringer i aksjer mm.',
    'nn-NO': 'Direkteinvesteringer i aksjer mm.',
    'en-GB': 'Direct investment in shares and more',
  },
  {
    'nb-NO': 'Direkteinvesteringer i kapital m.m.',
    'nn-NO': 'Direkteinvesteringer i kapital m.m.',
    'en-GB': 'Direct investment in capital etc.',
  },
  {
    'nb-NO': 'Porteføljeinvesteringer i obligasjoner og sertifikater',
    'nn-NO': 'Porteføljeinvesteringer i obligasjoner og sertifikater',
    'en-GB': 'Portfolio investment in bonds and notes',
  },
  {
    'nb-NO': 'Porteføljeinvesteringer i aksjer og verdipapirfondsandeler',
    'nn-NO': 'Porteføljeinvesteringer i aksjer og verdipapirfondsandeler',
    'en-GB': 'Portfolio investments in shares and mutual funds shares',
  },
  {
    'nb-NO': 'Porteføljeinvesteringer i derivater',
    'nn-NO': 'Porteføljeinvesteringer i derivater',
    'en-GB': 'Portfolio investments in derivatives',
  },
  {
    'nb-NO': 'Livsforsikring/pensjon',
    'nn-NO': 'Livsforsikring/pensjon',
    'en-GB': 'Life insurance / pension',
  },
  {
    'nb-NO': 'Andre finansinvesteringer',
    'nn-NO': 'Andre finansinvesteringer',
    'en-GB': 'Other financial investments',
  },
  {
    'nb-NO': 'Lønn',
    'nn-NO': 'Lønn',
    'en-GB': 'Salary',
  },
  {
    'nb-NO': 'Arv, gave, egne midler m.m',
    'nn-NO': 'Arv, gave, egne midler m.m',
    'en-GB': 'Inheritance, gift, own funds statement, and more',
  },
];

export default {
  Banks,
  Accounts,
  Countries,
  PaymentTypes,
};
