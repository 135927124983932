const template = {
  KALENDAR: {
    'nb-NO': 'Kalender',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SIGN_OUT: {
    'nb-NO': 'Logg ut',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  CLOSE: {
    'nb-NO': 'Lukk',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SOMETHING_ELSE: {
    'nb-NO': 'noe annet',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  FETCHING_KONTOER: {
    'nb-NO': 'Henter kontoer...',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  NY_OVERFOERING: {
    'nb-NO': 'Ny overføring',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  BETALE_EN: {
    'nb-NO': 'Betale en regning',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OPPRETT_KONTO: {
    'nb-NO': 'Åpne konto',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  FORFALLSMAPPE: {
    'nb-NO': 'Forfallsmappe',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  MINE_KORT: {
    'nb-NO': 'Mine kort',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  SISTE_BEVEGELSER: {
    'nb-NO': 'Siste bevegelser',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OVERFOERE_FROM_KREDITTKORT: {
    'nb-NO':
      'Tjenesten koster det samme som for kontantuttak: kr 30 + 1% av overført beløp',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OVERFOERE_FROM_KREDITTKORT_BUSINESS: {
    'nb-NO':
      'Tjenesten koster det samme som for kontantuttak: kr 30 + 2% av overført beløp',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OVERFOERE_FROM_GEBYRBEGRENSNING_START: {
    'nb-NO':
      'Høyrentekonto har {antall, number, noDecimals} gebyrfrie uttak per kalenderår. ' +
      'Fra uttak nr. {antallPlussEn, number, noDecimals}, belastes 1% av beløpet. Du har ',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OVERFOERE_FROM_GEBYRBEGRENSNING_BOLD: {
    'nb-NO': '{antall, number, noDecimals} gebyrfrie uttak ',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OVERFOERE_FROM_GEBYRBEGRENSNING_END: {
    'nb-NO': 'igjen',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OVERFOERE_FROM_GEBYRBEGRENSNING_OFFLINE: {
    'nb-NO':
      'Høyrentekonto har 8 gebyrfrie uttak pr år. Fra uttak nr 9 belastes 1% av beløpet',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  OVERFOERE_OVERSKRIFT_TEXT: {
    'nb-NO': 'Overføre',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  FATALERROR_HEADER: {
    'nb-NO': 'Siden kan ikke åpnes',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  FATALERROR_CONTENT: {
    'nb-NO':
      'Det har oppstått en teknisk feil og siden kan ikke åpnes. Prøv igjen senere.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KVITTERING_NO_DATA_TITLE: {
    'nb-NO': 'Kan ikke vise kvittering',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KVITTERING_NO_DATA_DESCRIPTION: {
    'nb-NO':
      'Du har navigert tilbake til kvittering, men vi har ingen data å vise.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  TIL_KONTO_INPUT_LABEL: {
    'nb-NO': 'Til konto',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  FRA_KONTO_INPUT_LABEL: {
    'nb-NO': 'Fra konto',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  FRA_KONTO_DEFAULTCHOICE: {
    'nb-NO': 'Velg fra-konto',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
};

export default template;
