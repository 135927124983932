import React from 'react';
import { BasePage } from 'digitalbank-layout';
import Button from 'digitalbank-buttons';
import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import BankIDMobile from 'components/Icons/BankIDMobile';
import BankID from 'components/Icons/BankID';
import BankIDStyled from './BankIDStyled';

const BankIDPage = () => (
  <BankIDStyled>
    <BasePage
      heading="BankID"
      className="p-bankid"
      HeadingIcon={BankID}
      headingIsAlwaysVisible
      asideContent={
        <div className="aside-card aside-card--light">
          <h3 className="aside-card__heading">Innlogging</h3>
          <div className="aside-card__content">
            <p>Se hvordan du bruker de ulike alternativene for innlogging.</p>
          </div>
        </div>
      }
    >
      <div className="p-bankid__status">
        <p>
          <b className="u-color--positive">AKTIV</b>
          <i> Sist brukt i dag</i>
        </p>
        <p>
          <i>Utstedt av Sparebanken Vest</i>
        </p>
      </div>
      <p className="p-bankid__description">
        BankID er Innlogging og signering med kodebrikke og passord
      </p>
      <NavList
        className="p-bankid__nav-list"
        items={[
          <NavListLink
            component={Link}
            variant={TASK}
            to={PATHS.HOME}
            title={{ text: 'Endre passord' }}
            key="endre-passord"
          />,
          <NavListLink
            component={Link}
            variant={TASK}
            to={PATHS.BANKID_NEW_KODEBRIKKE_ORDER}
            title={{ text: 'Ny kodebrikke' }}
            key="ny-kodebrikke"
          />,
          <NavListLink
            component={Link}
            variant={TASK}
            to={PATHS.HOME}
            title={{ text: 'Sperre' }}
            key="sperre"
          />,
        ]}
      />
      <article className="p-bankid__tips-article">
        <div className="p-bankid__tips-article-icon-container">
          <BankIDMobile className="p-bankid__tips-article-icon" />
        </div>
        <div className="p-bankid__tips-article-content">
          <h4 className="p-bankid__tips-article-heading">
            Tips: Enklere innlogging med BankID på mobil
          </h4>
          <p className="p-bankid__tips-article-description">
            Med BankID på mobil trenger du ikke kodebrikke, du bruker mobilen
            din og en selvvalgt kode.
          </p>
          <Button
            className="p-bankid__tips-article-button"
            variant="navigation"
            size="medium"
            component={Link}
            to={PATHS.BANKID_MOBILE_ORDER}
          >
            Kom i gang
          </Button>
        </div>
      </article>
    </BasePage>
  </BankIDStyled>
);

export default BankIDPage;
