import Apple from './Apple';
import Box from './Box';
import Car from './Car';
import Cutlery from './Cutlery';
import Heart from './Heart';
import House from './House';
import ShoppingCart from './ShoppingCart';
import Suitcase from './Suitecase';
import Sun from './Sun';
import Education from './Education';
import Vacation from './Vacation';
import Insurance from './Insurance';
import Media from './Media';

export {
  Apple,
  Box,
  Car,
  Cutlery,
  Heart,
  House,
  ShoppingCart,
  Suitcase,
  Sun,
  Education,
  Vacation,
  Insurance,
  Media,
};
