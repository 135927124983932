import moment from 'moment';

export default {
  tilKonto: '',
  country: '',
  bankCode: '',
  bankName: '',
  extraBankCode: '',
  additionalBankInfo: '',
  recipientName: '',
  recipientAddress1: '',
  recipientAddress2: '',
  recipientAddress3: '',
  recipientCountry: '',
  bankAddress1: '',
  bankAddress2: '',
  bankAddress3: '',
  fraKonto: undefined,
  dato: moment()
    .format('DD.MM.YYYY')
    .toString(),
  kroner: '',
  oere: '',
  paymentCurrency: '',
  message: '',
  expressPayment: false,
  paymentPurpose: '',
  paymentType: '',
  expensesArePaidBy: 'shared',
  sendEmailConfirmationToReceiver: false,
  receiverEmail: '',
  agreedRateWithBank: false,
  rateWithBank: '',
  contactPerson: '',
  codeFromDevice: '',
};
