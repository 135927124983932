/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputField } from 'digitalbank-form';
import Transactions from './Transactions/Transactions';

const SearchStyled = styled.div`
  padding: 50px 0;
  margin-bottom: 30px;
  min-height: 600px;
`;

const ContainerStyled = styled.div`
  padding: 0 14px;
`;

const Search = ({
  searchInputValue,
  searchResult,
  onSearchInputValueChange,
  onClick,
}) => (
  <SearchStyled>
    <ContainerStyled>
      <InputField
        label="Søk etter betalinger"
        hiddenLabel
        transformableLabel={false}
        placeholder="Søk etter betalinger"
        meta={{}}
        input={{
          name: 'search',
          type: 'search',
          value: searchInputValue,
          onChange: onSearchInputValueChange,
        }}
      />
    </ContainerStyled>
    {searchResult && (
      <div>
        <ContainerStyled>
          <p>{searchResult.length} treff</p>
        </ContainerStyled>
        <Transactions
          id="economy-search"
          transactions={searchResult.slice(0, 20)}
          onClick={transaction => onClick(transaction)}
        />
      </div>
    )}
  </SearchStyled>
);

Search.defaultProps = {
  searchResult: undefined,
};

Search.propTypes = {
  onClick: PropTypes.func.isRequired,
  onSearchInputValueChange: PropTypes.func.isRequired,
  searchInputValue: PropTypes.string.isRequired,
  searchResult: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Search;
