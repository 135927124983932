import { GlobalPaymentInfo } from './index';

const Recipients = [
  {
    id: 1,
    name: 'Axel Warn',
    account: GlobalPaymentInfo.Accounts[0],
    country: 'AL',
    address1: 'Rr. Dësh1morët e 14 Shkurtit',
    address2: 'address2',
    address3: 'address3',
    bank: GlobalPaymentInfo.Banks[0],
    group: 'utland',
  },
  {
    id: 2,
    name: 'Phillip Romeo',
    account: GlobalPaymentInfo.Accounts[3],
    country: 'SE',
    address1: 'address1',
    address2: 'address2',
    address3: 'address3',
    bank: GlobalPaymentInfo.Banks[3],
    group: 'utland',
  },
  {
    id: 3,
    name: 'Jacob Silver',
    account: GlobalPaymentInfo.Accounts[2],
    country: 'BR',
    address1: 'address1',
    address2: 'address2',
    address3: 'address3',
    bank: GlobalPaymentInfo.Banks[2],
    group: 'utland',
  },
  {
    id: 4,
    name: 'Emma Jensen',
    account: '31464626365',
    group: 'innland',
  },
  {
    id: 5,
    name: 'Nora Olsen',
    account: '32464626365',
    group: 'innland',
  },
  {
    id: 6,
    name: 'William Knutsen',
    account: '33464626361',
    group: 'innland',
  },
  {
    id: 7,
    name: 'Mathias Roland',
    account: '34464626369',
    group: 'innland',
  },
  {
    id: 8,
    name: 'Oliver Twist',
    account: '35464626369',
    group: 'innland',
  },
  {
    id: 9,
    name: 'Emil Lønning',
    account: '37464626371',
    group: 'innland',
  },
  {
    id: 10,
    name: 'Oskar Helleland',
    account: '38464626371',
    group: 'innland',
  },
  {
    id: 11,
    name: 'Lisa Kristiansen',
    account: '39464626372',
    group: 'innland',
  },
  {
    id: 13,
    name: 'Charlotte Hagenes',
    account: GlobalPaymentInfo.Accounts[3],
    country: 'SE',
    address1: 'Folkungagatan 95',
    address2: '116 30 Stockholm',
    address3: '',
    bank: GlobalPaymentInfo.Banks[3],
    group: 'utland',
  },
];

export default Recipients;
