import React from 'react';
import { BasePage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { BasePageStyled, HorizontalLayoutSpacing } from 'styled';

export default () => (
  <BasePageStyled>
    <BasePage heading="Lån">
      <HorizontalLayoutSpacing tablet={false}>
        <NavList
          style={{ marginBottom: '20px' }}
          items={[
            <NavListLink
              to={`${PATHS.LOAN}/1`}
              title={{ text: 'Boliglån' }}
              component={Link}
              key="boligloen"
            />,
            <NavListLink
              to={`${PATHS.LOAN}/2`}
              title={{ text: 'Fleksilån' }}
              component={Link}
              key="fleksiloen"
            />,
          ]}
        />
      </HorizontalLayoutSpacing>
    </BasePage>
  </BasePageStyled>
);
