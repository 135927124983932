const template = {
  ROUTE_TITLE_HOME: {
    'nb-NO': 'Hjem',
    'nn-NO': 'Hjem',
    'en-GB': 'Home',
  },
  ROUTE_TITLE_HOME_ALT: {
    'nb-NO': 'Hjem',
    'nn-NO': 'Hjem',
    'en-GB': 'Home',
  },
  ROUTE_TITLE_LOGIN: {
    'nb-NO': 'Login',
    'nn-NO': 'Login',
    'en-GB': 'Login',
  },
  ROUTE_TITLE_PAYMENT_GLOBAL: {
    'nb-NO': 'Betale utland',
    'nn-NO': 'Betale utland',
    'en-GB': 'Foreign payment',
  },
  ROUTE_TITLE_PAYMENT_GLOBAL_SECOND: {
    'nb-NO': 'Betale utland',
    'nn-NO': 'Betale utland',
    'en-GB': 'Foreign payment',
  },
  ROUTE_TITLE_PAYMENT_GLOBAL_SUMMARY: {
    'nb-NO': 'Godkjenn',
    'nn-NO': 'Godkjenn',
    'en-GB': 'Authorize',
  },
  ROUTE_TITLE_PAYMENT_GLOBAL_REAUTH: {
    'nb-NO': 'Godkjenn',
    'nn-NO': 'Godkjenn',
    'en-GB': 'Authorize',
  },
  ROUTE_TITLE_PAYMENT_GLOBAL_RECEIPT: {
    'nb-NO': 'Betaling godkjent',
    'nn-NO': 'Betaling godkjent',
    'en-GB': 'Payment done',
  },
  ROUTE_TITLE_PAYMENT_AGREEMENTS: {
    'nb-NO': 'AvtaleGiro og eFaktura',
    'nn-NO': 'AvtaleGiro og eFaktura',
    'en-GB': 'AvtaleGiro and eFaktura',
  },
  ROUTE_TITLE_PAY: {
    'nb-NO': 'Betale',
    'nn-NO': 'Betale',
    'en-GB': 'Payment',
  },
  ROUTE_TITLE_UTFOERTE_BETALINGER: {
    'nb-NO': 'Historikk',
    'nn-NO': 'Historikk',
    'en-GB': 'Payment history',
  },
  ROUTE_TITLE_PAY_MULTIPLE: {
    'nb-NO': 'Betale flere',
    'nn-NO': 'Betale flere',
    'en-GB': 'Multiple payments',
  },
  ROUTE_TITLE_FRIENDS_PAYMENT: {
    'nb-NO': 'Vennebetaling',
    'nn-NO': 'Vennebetaling',
    'en-GB': 'Friends payment',
  },
  ROUTE_TITLE_PAYMENT_SUMMARY: {
    'nb-NO': 'Godkjenn',
    'nn-NO': 'Godkjenn',
    'en-GB': 'Summary',
  },
  ROUTE_TITLE_PAYMENT_REAUTH: {
    'nb-NO': 'Godkjenn',
    'nn-NO': 'Godkjenn',
    'en-GB': 'Authenticate',
  },
  ROUTE_TITLE_PAYMENT_RECEIPT: {
    'nb-NO': 'Betaling godkjent',
    'nn-NO': 'Betaling godkjent',
    'en-GB': 'Payment done',
  },
  ROUTE_TITLE_PAYMENT_EDIT: {
    'nb-NO': 'Endre betaling',
    'nn-NO': 'Endre betaling',
    'en-GB': 'Edit payment',
  },
  ROUTE_TITLE_PAYMENT_EDIT_SUMMARY: {
    'nb-NO': 'Endre betaling',
    'nn-NO': 'Endre betaling',
    'en-GB': 'Edit payment',
  },
  ROUTE_TITLE_PAYMENT_EDIT_RECEIPT: {
    'nb-NO': 'Endre betaling',
    'nn-NO': 'Endre betaling',
    'en-GB': 'Edit payment',
  },
  ROUTE_TITLE_FAST_BETALING: {
    'nb-NO': 'Nytt fast oppdrag',
    'nn-NO': 'Nytt fast oppdrag',
    'en-GB': 'New scheduled transaction',
  },
  ROUTE_TITLE_FAST_BETALING_REAUTH: {
    'nb-NO': 'Godkjenn',
    'nn-NO': 'Godkjenn',
    'en-GB': 'Authorize',
  },
  ROUTE_TITLE_FAST_BETALING_RECEIPT: {
    'nb-NO': 'Fast betaling',
    'nn-NO': 'Fast betaling',
    'en-GB': 'Scheduled payment',
  },
  ROUTE_TITLE_EDIT_FAST_BETALING: {
    'nb-NO': 'Endre fast betaling',
    'nn-NO': 'Endre fast betaling',
    'en-GB': 'Edit scheduled payment',
  },
  ROUTE_TITLE_PAY_NAVIGATION: {
    'nb-NO': 'Betale',
    'nn-NO': 'Betale',
    'en-GB': 'Payment',
  },
  ROUTE_TITLE_TRANSFER: {
    'nb-NO': 'Overføre',
    'nn-NO': 'Overføre',
    'en-GB': 'Transfer',
  },
  ROUTE_TITLE_TRANSFER_RECEIPT: {
    'nb-NO': 'Overføre utført',
    'nn-NO': 'Overføre utført',
    'en-GB': 'Transfer done',
  },
  ROUTE_TITLE_EDIT_TRANSFER: {
    'nb-NO': 'Endre overføring',
    'nn-NO': 'Endre overføring',
    'en-GB': 'Edit transfer',
  },
  ROUTE_TITLE_EDIT_TRANSFER_RECEIPT: {
    'nb-NO': 'Endre overføring',
    'nn-NO': 'Endre overføring',
    'en-GB': 'Edit transfer',
  },
  ROUTE_TITLE_FASTE_OPPDRAG: {
    'nb-NO': 'Faste oppdrag',
    'nn-NO': 'Faste oppdrag',
    'en-GB': 'Scheduled transactions',
  },
  ROUTE_TITLE_FAST_OVERFOERING: {
    'nb-NO': 'Nytt fast oppdrag',
    'nn-NO': 'Nytt fast oppdrag',
    'en-GB': 'New scheduled transaction',
  },
  ROUTE_TITLE_FAST_OVERFOERING_RECEIPT: {
    'nb-NO': 'Fast overføring',
    'nn-NO': 'Fast overføring',
    'en-GB': 'Scheduled transfer',
  },
  ROUTE_TITLE_FAST_OVERFOERING_EDIT: {
    'nb-NO': 'Endre fast overføring',
    'nn-NO': 'Endre fast overføring',
    'en-GB': 'Edit scheduled transfer',
  },
  ROUTE_TITLE_FAST_OVERFOERING_EDIT_RECEIPT: {
    'nb-NO': 'Fast overføring',
    'nn-NO': 'Fast overføring',
    'en-GB': 'Scheduled transfer',
  },
  ROUTE_TITLE_ACCOUNT: {
    'nb-NO': 'Konto',
    'nn-NO': 'Konto',
    'en-GB': 'Account',
  },
  ROUTE_TITLE_CARD: {
    'nb-NO': 'Kort',
    'nn-NO': 'Kort',
    'en-GB': 'Card',
  },
  ROUTE_TITLE_LOAN: {
    'nb-NO': 'Lån',
    'nn-NO': 'Lån',
    'en-GB': 'Loan',
  },
  ROUTE_TITLE_LOAN_HISTORY: {
    'nb-NO': 'Detaljer og nedbetalingsplan',
    'nn-NO': 'Detaljer og nedbetalingsplan',
    'en-GB': 'Loan details',
  },
  ROUTE_TITLE_LOAN_INTEREST_RATE_HISTORY: {
    'nb-NO': 'Rentehistorikk',
    'nn-NO': 'Rentehistorikk',
    'en-GB': 'Interes rate history',
  },
  ROUTE_TITLE_SAVING: {
    'nb-NO': 'Sparing',
    'nn-NO': 'Sparing',
    'en-GB': 'Saving',
  },
  ROUTE_TITLE_PENSION: {
    'nb-NO': 'Pensjon',
    'nn-NO': 'Pensjon',
    'en-GB': 'Pension',
  },
  ROUTE_TITLE_STOCK: {
    'nb-NO': 'Aksjer',
    'nn-NO': 'Aksjer',
    'en-GB': 'Stock',
  },
  ROUTE_TITLE_FUND: {
    'nb-NO': 'Fond',
    'nn-NO': 'Fond',
    'en-GB': 'Fund',
  },
  ROUTE_TITLE_SAVINGS_ACCOUNT: {
    'nb-NO': 'Sparekonto',
    'nn-NO': 'Sparekonto',
    'en-GB': 'Savings account',
  },
  ROUTE_TITLE_SAVINGS_AGREEMENTS: {
    'nb-NO': 'Spareavtaler',
    'nn-NO': 'Spareavtaler',
    'en-GB': 'Savings agreements',
  },
  ROUTE_TITLE_ESURANCE: {
    'nb-NO': 'Forsikring',
    'nn-NO': 'Forsikring',
    'en-GB': 'Esurance',
  },
  ROUTE_TITLE_INBOX: {
    'nb-NO': 'Innboks',
    'nn-NO': 'Innboks',
    'en-GB': 'Inbox',
  },
  ROUTE_TITLE_SETTINGS: {
    'nb-NO': 'Innstillinger',
    'nn-NO': 'Innstillinger',
    'en-GB': 'Settings',
  },
  ROUTE_TITLE_CONTACT_INFO: {
    'nb-NO': 'Kontaktinformasjon',
    'nn-NO': 'Kontaktinformasjon',
    'en-GB': 'Contact Info',
  },
  ROUTE_TITLE_REGIONAL_BLOCKING: {
    'nb-NO': 'Regionsperre',
    'nn-NO': 'Regionsperre',
    'en-GB': 'Regional blocking',
  },
  ROUTE_TITLE_DATA_CONSENT: {
    'nb-NO': 'Samtykke',
    'nn-NO': 'Samtykke',
    'en-GB': 'Data consent',
  },
  ROUTE_TITLE_CHANGE_PASSWORD: {
    'nb-NO': 'Endre passord',
    'nn-NO': 'Endre passord',
    'en-GB': 'Change password',
  },
  ROUTE_TITLE_DATA_CONSENT_PERIODIC_CONTROL: {
    'nb-NO': 'Samtykke',
    'nn-NO': 'Samtykke',
    'en-GB': 'Data consent',
  },
  ROUTE_TITLE_INVOICE: {
    'nb-NO': 'efaktura',
    'nn-NO': 'efaktura',
    'en-GB': 'Invoice',
  },
  ROUTE_TITLE_CONTACT_INFO_PERIODIC_CONTROL: {
    'nb-NO': 'Oppdater info',
    'nn-NO': 'Oppdater info',
    'en-GB': 'Oppdater info',
  },
  ROUTE_TITLE_PERIODIC_CONTROL_REGIONAL_BLOCKING_ISOLATED: {
    'nb-NO': 'Regionsperre',
    'nn-NO': 'Regionsperre',
    'en-GB': 'Regional blocking',
  },
  ROUTE_TITLE_NOT_FOUND: {
    'nb-NO': 'Siden finnes ikke',
    'nn-NO': 'Siden finnes ikke',
    'en-GB': 'Not found',
  },
  ROUTE_TITLE_TRANSACTIONS: {
    'nb-NO': 'eFaktura',
    'nn-NO': 'eFaktura',
    'en-GB': 'eFaktura',
  },
  ROUTE_TITLE_CONTACT: {
    'nb-NO': 'Kontakt',
    'nn-NO': 'Kontakt',
    'en-GB': 'Contact',
  },
  ROUTE_TITLE_SCHEDULED_PAYMENTS: {
    'nb-NO': 'Forfallsmappe',
    'nn-NO': 'Forfallsmappe',
    'en-GB': 'Scheduled payments',
  },
  ROUTE_TITLE_RECIPIENTS: {
    'nb-NO': 'Mottakere',
    'nn-NO': 'Mottakere',
    'en-GB': 'Recipients',
  },
  ROUTE_TITLE_SEARCH: {
    'nb-NO': 'Søk',
    'nn-NO': 'Søk',
    'en-GB': 'Search',
  },
  ROUTE_TITLE_LAST_TRANSACTIONS: {
    'nb-NO': 'Siste bevegelser',
    'nn-NO': 'Siste bevegelser',
    'en-GB': 'Newest transactions',
  },
  ROUTE_TITLE_NEW_FOREIGN_PAYMENT: {
    'nb-NO': 'Ny utlandsbetaling',
    'nn-NO': 'Ny utlandsbetaling',
    'en-GB': 'New foreign payment',
  },
  ROUTE_TITLE_MOBILE_SERVICES_AND_NOTIFICATIONS: {
    'nb-NO': 'Mobiltjenester og varslinger',
    'nn-NO': 'Mobiltjenester og varslinger',
    'en-GB': 'Mobile services and notifications',
  },
  ROUTE_TITLE_BANK_ID: {
    'nb-NO': 'BankID',
    'nn-NO': 'BankID',
    'en-GB': 'BankID',
  },
  ROUTE_TITLE_EFAKTURA_AND_AVGALEGIRO: {
    'nb-NO': 'eFaktura og AvtaleGiro',
    'nn-NO': 'eFaktura og AvtaleGiro',
    'en-GB': 'eFaktura and AvtaleGiro',
  },
  ROUTE_TITLE_ISSUES: {
    'nb-NO': 'Saker',
    'nn-NO': 'Saker',
    'en-GB': 'Issues',
  },
  ROUTE_TITLE_AGREEMENTS: {
    'nb-NO': 'Avtaler',
    'nn-NO': 'Avtaler',
    'en-GB': 'Agreements',
  },
  ROUTE_TITLE_EFAKTURA_CREATE: {
    'nb-NO': 'Opprett eFaktura',
    'nn-NO': 'Opprett eFaktura',
    'en-GB': 'Create eFaktura',
  },
  ROUTE_TITLE_EFAKTURA_UPDATE: {
    'nb-NO': 'Endre eFaktura',
    'nn-NO': 'Endre eFaktura',
    'en-GB': 'Update eFaktura',
  },
  ROUTE_TITLE_AVTALEGIRO_CREATE: {
    'nb-NO': 'Opprett AvtaleGiro',
    'nn-NO': 'Opprett AvtaleGiro',
    'en-GB': 'Create AvtaleGiro',
  },
  ROUTE_TITLE_AVTALEGIRO_UPDATE: {
    'nb-NO': 'Endre AvtaleGiro',
    'nn-NO': 'Endre AvtaleGiro',
    'en-GB': 'Update AvtaleGiro',
  },
  ROUTE_TITLE_EFAKTURA_AND_AVTALEGIRO_CREATE: {
    'nb-NO': 'Opprett betalingsavgaler',
    'nn-NO': 'Opprett betalingsavgaler',
    'en-GB': 'Create agrements',
  },
  ROUTE_TITLE_EFAKTURA_AND_AVTALEGIRO_UPDATE: {
    'nb-NO': 'Endre betalingsavtaler',
    'nn-NO': 'Endre betalingsavtaler',
    'en-GB': 'Update agrements',
  },
  ROUTE_TITLE_ECONOMY: {
    'nb-NO': 'Min økonomi',
    'nn-NO': 'Min økonomi',
    'en-GB': 'My economy',
  },
  ROUTE_TITLE_ECONOMY_TRANSACTION: {
    'nb-NO': 'Transaksjonsvisning',
    'nn-NO': 'Transaksjonsvisning',
    'en-GB': 'Transaction details',
  },
  ROUTE_TITLE_TRAVEL: {
    'nb-NO': 'Opprett reise',
    'nn-NO': 'Opprett reise',
    'en-GB': 'Create a trip',
  },
  ROUTE_TITLE_TRAVEL_SUMMARY: {
    'nb-NO': 'Reiseregning',
    'nn-NO': 'Reiseregning',
    'en-GB': 'Travel summary',
  },
  ROUTE_TITLE_TAGS: {
    'nb-NO': 'Mine tagger',
    'nn-NO': 'Mine tagger',
    'en-GB': 'My tags',
  },
  ROUTE_TITLE_TAG: {
    'nb-NO': 'Tagg',
    'nn-NO': 'Tagg',
    'en-GB': 'Tag',
  },
  ROUTE_TITLE_CREATE_AUTOMATIC_TAG: {
    'nb-NO': 'Opprett automatisk tagg',
    'nn-NO': 'Opprett automatisk tagg',
    'en-GB': 'Create automatic tag',
  },
  ROUTE_TITLE_CREATE_AUTOMATIC_TAG_TRAVEL: {
    'nb-NO': 'Min reise',
    'nn-NO': 'Min reise',
    'en-GB': 'My travel',
  },
  ROUTE_TITLE_CREATE_AUTOMATIC_TAG_TRAVEL_WORK: {
    'nb-NO': 'Min jobbreise',
    'nn-NO': 'Min jobbreise',
    'en-GB': 'My travel',
  },
};

// export const CREATE_AUTOMATIC_TAG = '/tagger/automatisk-tagg';
// export const CREATE_AUTOMATIC_TAG_TRAVEL = '/tagger/min-reise';
// export const CREATE_AUTOMATIC_TAG_TRAVEL_WITH_WORK = '/tagger/min-jobbreise';

export default template;
