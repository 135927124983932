const defaultState = {
  data: undefined,
  status: {
    requested: false,
    received: false,
    error: false,
  },
  errors: [],
};

export const requested = (data = defaultState.data) => ({
  ...defaultState,
  data,
  status: {
    ...defaultState.status,
    requested: true,
  },
});

export const received = data => ({
  ...defaultState,
  data,
  status: {
    ...defaultState.status,
    requested: true,
    received: true,
  },
});

export const changed = payload => ({
  data: payload.data,
  status: {
    ...payload.status,
    changed: true,
  },
  errors: payload.errors,
});

export const error = (errors = []) => {
  const errorArray = errors.constructor === Array ? errors : [errors];
  return {
    ...defaultState,
    status: {
      ...defaultState.status,
      requested: true,
      received: true,
      error: true,
    },
    errors: errorArray,
  };
};

export const notRequested = () => ({
  ...defaultState,
});
