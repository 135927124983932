import styled from 'styled-components';
import { breakpoints, colors } from 'digitalbank-core-style';
import { rem } from 'polished';

export default styled.div`
  .scheduled-payments-header {
    padding: ${`${rem(10)} ${rem(10)} ${rem(8)}`};

    @media (min-width: ${breakpoints.tablet}px) {
      padding: ${rem(10)} 0 ${rem(8)};
    }

    &__info {
      overflow: hidden;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    &__heading,
    &__account {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__heading {
      color: ${colors.tundora};
      line-height: ${rem(28)};
      margin-bottom: ${rem(2.5)};
    }

    &__account {
      font-size: ${rem(14)};
      color: ${colors.boulder};
    }

    &__numbers {
      display: flex;

      &-list {
        font-size: ${rem(14)};
        list-style: none;
        margin: 0;
        padding: 0;
        display: table;

        li {
          display: table-row;
        }

        span {
          display: table-cell;
        }

        &-label {
          color: ${colors.boulder};
          min-width: 70px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-amount {
          text-align: right;
          min-width: ${rem(100)};
          /* Set max-width to prevent jumping when the total amount of payments change */

          &--bold {
            font-weight: bold;
          }

          &--danger {
            color: ${colors.guardsmanRed};
          }
        }
      }
    }
  }
`;
