import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from 'digitalbank-buttons';
import NavList, {
  NavListTask,
  NavListLink,
  Variants,
  utilityColors,
} from 'digitalbank-nav-list';
import * as PATHS from 'routes/paths';
import SettingsCard from './SettingsCard';

const EfakturaFraAlleNavListItem = ({ active }) => {
  if (active) {
    return (
      <NavListLink
        to={PATHS.EFAKTURA_FRA_ALLE_SETTINGS}
        component={Link}
        title={{ text: 'eFaktura fra alle' }}
        description={{ text: 'Motta eFaktura automatisk' }}
        variant={Variants.TASK}
        info={{
          text: 'Aktiv',
          variant: utilityColors.POSITIVE,
        }}
      />
    );
  }
  return (
    <NavListTask
      title={{ text: 'eFaktura fra alle' }}
      description={{
        text: 'Motta eFaktura automatisk',
      }}
      aside={
        <Button
          size="small"
          variant="edit"
          component={Link}
          to={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_INFO}
        >
          Aktiver
        </Button>
      }
    />
  );
};

EfakturaFraAlleNavListItem.propTypes = {
  active: PropTypes.bool.isRequired,
};

const EfakturaOneClickNavListItem = ({ active }) => {
  if (active) {
    return (
      <NavListLink
        to="/"
        component={Link}
        title={{ text: 'eFaktura med ett klikk' }}
        description={{ text: 'Enklere godkjenning' }}
        variant={Variants.TASK}
        info={{
          text: 'Aktiv',
          variant: utilityColors.POSITIVE,
        }}
      />
    );
  }
  return (
    <NavListTask
      title={{ text: 'eFaktura med ett klikk' }}
      description={{
        text: 'Enklere godkjenning',
      }}
      aside={
        <Button size="small" variant="edit" component={Link} to={PATHS.HOME}>
          Aktiver
        </Button>
      }
    />
  );
};

EfakturaOneClickNavListItem.propTypes = {
  active: PropTypes.bool.isRequired,
};

const SettingsCardForEFaktura = ({
  eFakturaOneClickIsActive,
  automaticEfakturaIsActive,
}) => (
  <SettingsCard heading="eFaktura">
    <NavList
      items={[
        <EfakturaOneClickNavListItem
          active={eFakturaOneClickIsActive}
          key="efaktura-med-ett-klikk"
        />,
        <EfakturaFraAlleNavListItem
          active={automaticEfakturaIsActive}
          key="efaktura-fra-alle"
        />,
      ]}
    />
  </SettingsCard>
);

SettingsCardForEFaktura.propTypes = {
  eFakturaOneClickIsActive: PropTypes.bool.isRequired,
  automaticEfakturaIsActive: PropTypes.bool.isRequired,
};

export default SettingsCardForEFaktura;
