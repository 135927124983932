import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HiddenInput } from 'styled/Form';

const Label = styled.label`
  cursor: pointer;
  padding: 6px 8px;
  border: 1px solid #bbb;
  border-radius: 3px;
  background-color: transparent;
  font-family: Roboto;
  font-size: 1rem;
  text-transform: none;
  outline: none;
  display: block;

  &:hover,
  &:focus {
    background-color: rgba(0, 0, 0, 0.025);
  }

  &:focus {
    border-color: #0073b2;
  }

  &:active {
    background-color: #c40000;
    border-color: #c30000;
    color: #fff;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
`;

const Input = styled(HiddenInput)`
  &:checked {
    + ${Label} {
      background-color: #c40000;
      border-color: #c30000;
      color: #fff;
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }
`;

const ChoiceCard = ({
  children, id, name, ...other
}) => (
  <div>
    <Input type="radio" name={name} id={id} {...other} />
    <Label htmlFor={id}>{children}</Label>
  </div>
);

ChoiceCard.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ChoiceCard;
