import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'digitalbank-icons';

import KeyNumberOptions from './KeyNumberOptions';

class KeyNumber extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayOptions: false,
    };
    this.toggleOptions = this.toggleOptions.bind(this);
    this.optionClick = this.optionClick.bind(this);
  }
  toggleOptions() {
    this.setState({
      ...this.state,
      displayOptions: !this.state.displayOptions,
    });
  }
  optionClick(onChange) {
    this.setState({
      ...this.state,
      displayOptions: false,
    });
    onChange();
  }
  render() {
    const {
      title, value, children, changeable, onChange, alias,
    } = this.props;
    return (
      <li className="key-number">
        <div className="key-number__title" style={{ position: 'relative' }}>
          {!changeable && <span>{title}</span>}
          {changeable &&
            alias && (
              <span style={{ position: 'relative' }}>
                <button
                  onClick={this.toggleOptions}
                  className="key-number__toggle-options-btn"
                >
                  {title}
                  <ChevronDown className="key-number__toggle-options-btn-icon" />
                </button>
                <KeyNumberOptions
                  currentTitle={title}
                  currentAlias={alias}
                  toggle={this.toggleOptions}
                  active={this.state.displayOptions}
                  onClick={selectedAlias =>
                    this.optionClick(() => onChange(selectedAlias))
                  }
                />
              </span>
            )}
        </div>
        {value !== undefined && (
          <div className="key-number__value">
            {value.toLocaleString('nb-NO', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
        )}
        {!value && <div>{children}</div>}
      </li>
    );
  }
}

KeyNumber.defaultProps = {
  value: undefined,
  changeable: false,
  onChange: undefined,
  alias: '',
  children: undefined,
};

KeyNumber.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  children: PropTypes.node,
  changeable: PropTypes.bool,
  onChange: PropTypes.func,
  alias: PropTypes.string,
};

export default KeyNumber;
