import React, { Component } from 'react';
import { Recipients as initialRecipients } from 'data';
import ButtonGroup from 'digitalbank-button-group';
import { BasePage } from 'digitalbank-layout';
import RecipientTable from 'components/RecipientLists/table/RecipientTable';
import RecipientDropdown from 'components/RecipientLists/dropdown/RecipientDropdown';
import {
  categorizeRecipients,
  filterRecipients,
  orderRecipientsByName,
} from 'utils/recipients.util';
import { Search } from 'digitalbank-icons';
import RecipientStyled from './RecipientsStyled';

class Recipients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      activeRecipientId: -1,
      recipients: initialRecipients,
      recipientCatalog: 'innland',
    };
    this.setRecipientCatalog = this.setRecipientCatalog.bind(this);
    this.deleteRecipient = this.deleteRecipient.bind(this);
    this.toggleRecipient = this.toggleRecipient.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
  }
  setRecipientCatalog(catalog) {
    this.setState({
      ...this.state,
      recipientCatalog: catalog,
    });
  }
  deleteRecipient(recipients, id) {
    const recipientIndex = recipients.findIndex(x => x.id === id);

    this.setState({
      ...this.state,
      recipients: [
        ...recipients.slice(0, recipientIndex),
        ...recipients.slice(recipientIndex + 1),
      ],
    });
  }
  toggleRecipient(id) {
    this.setState({
      ...this.state,
      activeRecipientId: this.state.activeRecipientId === id ? -1 : id,
    });
  }
  handleSearchInputChange(e) {
    this.setState({
      ...this.state,
      searchValue: e.target.value,
    });
  }

  render() {
    const {
      searchValue,
      activeRecipientId,
      recipients,
      recipientCatalog,
    } = this.state;
    const recipientsOrderByFirstName = orderRecipientsByName(recipients);
    const filteredRecipients = filterRecipients(
      recipientsOrderByFirstName,
      searchValue,
      recipientCatalog,
    );
    const categoriezedRecipients = categorizeRecipients(filteredRecipients);
    return (
      <RecipientStyled>
        <BasePage
          className="p-recipients"
          heading="Mottakere"
          asideContent={
            <section className="published-content">
              <h2>Ny mottaker?</h2>
              <p>
                For å lagre en ny mottaker må du utføre en betaling til denne
                mottakeren.
              </p>
              <p>
                Alle mottakere du har betalt til vil legge seg i
                mottakerregisteret. Her kan du enkelt betale ny, se tidligere
                eller slette mottaker.
              </p>
            </section>
          }
        >
          <div className="p-recipients__navigation">
            <div className="p-recipients__btn-group">
              <ButtonGroup
                items={[
                  {
                    text: 'Innland',
                    id: 'innland',
                    active: recipientCatalog === 'innland',
                    onClick: () => this.setRecipientCatalog('innland'),
                  },
                  {
                    text: 'Utland',
                    id: 'utland',
                    active: recipientCatalog === 'utland',
                    onClick: () => this.setRecipientCatalog('utland'),
                  },
                ]}
              />
            </div>
            <div className="p-recipients__search-input">
              <div className="simple-search-input">
                <label
                  htmlFor="simple-search-input"
                  className="simple-search-input__label"
                  aria-hidden
                >
                  <Search className="simple-search-input__label-icon" />
                </label>
                <input
                  type="text"
                  className="simple-search-input__field"
                  placeholder="Søk"
                  name="simple-search-input"
                  id="simple-search-input"
                  aria-label="Søk"
                  value={searchValue}
                  onFocus={this.toggleSearchMode}
                  onChange={this.handleSearchInputChange}
                />
              </div>
            </div>
          </div>
          <div className="recipient-dropdown">
            <RecipientDropdown
              categories={categoriezedRecipients}
              activeRecipientId={activeRecipientId}
              toggleRecipient={this.toggleRecipient}
              deleteRecipient={id => this.deleteRecipient(recipients, id)}
            />
            {searchValue &&
              filteredRecipients.length === 0 && (
                <p style={{ textAlign: 'center', margin: '20px 0' }}>
                  Beklager, vi fant ingen mottakere.
                </p>
              )}
          </div>
          <RecipientTable
            recipients={filteredRecipients}
            deleteRecipient={id => this.deleteRecipient(recipients, id)}
          />
        </BasePage>
      </RecipientStyled>
    );
  }
}

export default Recipients;
