// import Transactions from 'digitalbank-mockup-data/lib/Transactions';
// import _ from 'lodash';

import {
  TOGGLE_SHARE_DROPDOWN,
  TOGGLE_RECIPIENTS,
  TOGGLE_FILTER,
  TOGGLE_AMOUNT_FIELDS,
  TOGGLE_DATE_FIELDS,
} from './search.constants';

const initialState = {
  isDateFieldsVisible: false,
  isAmountFieldsVisible: false,
  isFilterOpen: false,
  isRecipientsVisible: false,
  isShareDropdownOpen: false,
  // transactions: _.orderBy(Transactions, ['date'], ['desc']),
  openTransactionId: '',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_AMOUNT_FIELDS:
      return {
        ...state,
        isAmountFieldsVisible: !state.isAmountFieldsVisible,
      };
    case TOGGLE_DATE_FIELDS:
      return {
        ...state,
        isDateFieldsVisible: !state.isDateFieldsVisible,
      };
    case TOGGLE_SHARE_DROPDOWN:
      return {
        ...state,
        isShareDropdownOpen: !state.isShareDropdownOpen,
      };
    case TOGGLE_RECIPIENTS:
      return {
        ...state,
        isRecipientsVisible: !state.isRecipientsVisible,
      };
    case TOGGLE_FILTER:
      return {
        ...state,
        isFilterOpen: !state.isFilterOpen,
      };
    default:
      return state;
  }
};
