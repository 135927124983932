import React from 'react';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import ScheduledPaymentNavListLinkContainer from 'containers/ScheduledPaymentNavListLinkContainer';
import { HorizontalLayoutSpacing } from 'styled';

export default () => (
  <HorizontalLayoutSpacing>
    <NavList
      items={[
        // <NavListLink
        //   component={Link}
        //   to={`${PATHS.AVTALEGIRO_CREATE}/${'1'}`}
        //   title={{ text: 'Opprett avtalegiro' }}
        //   key="avtalegiro"
        // />,
        <NavListLink
          component={Link}
          to={PATHS.PAY}
          title={{ text: 'Ny betaling' }}
          key="ny-betaling"
        />,
        <ScheduledPaymentNavListLinkContainer />,
        <NavListLink
          component={Link}
          to={PATHS.TRANSFER}
          title={{ text: 'Overføre' }}
          key="transfer"
        />,
      ]}
    />
  </HorizontalLayoutSpacing>
);
