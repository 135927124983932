import _ from 'lodash';
import { KeyNumbersData } from 'data';

import {
  TOGGLE_PRIVATE_ACCOUNT,
  TOGGLE_SHARED_ACCOUNT,
  TOGGLE_CREDIT_ACCOUNT,
  SELECT_DUE_DATE_REGISTER_DISPLAY_FILTER,
  TOGGLE_HIDE_PRIVATE_TRANSFER,
  SELECT_CREDIT_DISPLAY_FILTER,
  SELECT_FIRST_CUSTOM_KEY_NUMBER,
  SELECT_SECOND_CUSTOM_KEY_NUMBER,
} from './KeyNumbers.Constants';

// Get updated accounts with selected property toggled for specified account
export const getUpdatedAccounts = (accounts, id) => {
  const updatedAccounts = accounts.map((account) => {
    if (account.id === id) {
      const updatedAccount = _.clone(account);
      updatedAccount.selected = !updatedAccount.selected;
      return updatedAccount;
    }
    return account;
  });
  return updatedAccounts;
};

export default function reducer(state = KeyNumbersData, action = {}) {
  switch (action.type) {
    case TOGGLE_PRIVATE_ACCOUNT:
      return {
        ...state,
        privateAccounts: getUpdatedAccounts(
          state.privateAccounts,
          action.accountId,
        ),
      };
    case TOGGLE_SHARED_ACCOUNT:
      return {
        ...state,
        sharedAccounts: getUpdatedAccounts(
          state.sharedAccounts,
          action.accountId,
        ),
      };
    case TOGGLE_CREDIT_ACCOUNT:
      return {
        ...state,
        creditAccounts: getUpdatedAccounts(
          state.creditAccounts,
          action.accountId,
        ),
      };
    case SELECT_DUE_DATE_REGISTER_DISPLAY_FILTER: {
      const updatedDueDateRegister = _.clone(state.dueDateRegister);
      updatedDueDateRegister.filter = action.filter;
      return {
        ...state,
        dueDateRegister: updatedDueDateRegister,
      };
    }
    case TOGGLE_HIDE_PRIVATE_TRANSFER: {
      const newDueDateRegister = _.clone(state.dueDateRegister);
      newDueDateRegister.hidePrivateTranfers = !newDueDateRegister.hidePrivateTranfers;
      return {
        ...state,
        dueDateRegister: newDueDateRegister,
      };
    }
    case SELECT_CREDIT_DISPLAY_FILTER:
      return {
        ...state,
        creditCardDisplayFilter: action.filter,
      };
    case SELECT_FIRST_CUSTOM_KEY_NUMBER:
      return {
        ...state,
        firstCustomKeyNumberAlias: action.alias,
      };
    case SELECT_SECOND_CUSTOM_KEY_NUMBER:
      return {
        ...state,
        secondCustomKeyNumberAlias: action.alias,
      };
    default:
      return state;
  }
}
