import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Calendar } from 'digitalbank-icons';
import classNames from 'classnames';
import { InputField } from 'digitalbank-form';
import { KeyCodes } from 'utils';
import { DatePicker } from 'components';

class DatePickerInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayCalendar: false,
    };
    this.onTabCalendar = this.onTabCalendar.bind(this);
    this.closeCalendar = this.closeCalendar.bind(this);
    this.openCalendar = this.openCalendar.bind(this);
    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.onSelectDate = this.onSelectDate.bind(this);
  }
  onSelectDate(date) {
    this.props.input.onChange(
      moment(date)
        .format('DD.MM.YYYY')
        .toString(),
    );
    this.closeCalendar();
  }
  onTabCalendar(e) {
    if (e.keyCode === KeyCodes.TAB && e.shiftKey) {
      this.closeCalendar();
    }
  }
  openCalendar() {
    this.setState({
      ...this.state,
      displayCalendar: true,
    });
  }
  closeCalendar() {
    this.setState({
      ...this.state,
      displayCalendar: false,
    });
  }
  toggleCalendar() {
    this.setState({
      ...this.state,
      displayCalendar: !this.state.displayCalendar,
    });
  }
  render() {
    // convert formatted date to actual date object
    const { value } = this.props.input;

    const selectedDate = value
      ? moment(value, 'DD.MM.YYYY').toDate()
      : new Date();
    const calendar = this.state.displayCalendar ? (
      <DatePicker
        handleClickOutside={() => this.closeCalendar()}
        onSelectDate={date => this.onSelectDate(date)}
        fromMonth={this.props.fromMonth}
        toMonth={this.props.toMonth}
        initialMonth={selectedDate}
        selectedDate={selectedDate}
      />
    ) : (
      ''
    );
    return (
      <div
        className={classNames('calendar-field', {
          'calendar-field--active': this.state.displayCalendar,
        })}
      >
        <InputField
          rightAddon={() => (
            <button
              type="button"
              className="form-control__button ignore-react-onclickoutside"
              onClick={this.toggleCalendar}
            >
              <Calendar className="form-control__button-icon" />
            </button>
          )}
          {...this.props}
        />
        <div className="calendar-field__wrapper">{calendar}</div>
      </div>
    );
  }
}

DatePickerInput.defaultProps = {
  toMonth: moment()
    .add(26, 'M')
    .toDate(),
  fromMonth: moment()
    .subtract(13, 'M')
    .toDate(),
};

DatePickerInput.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  fromMonth: PropTypes.instanceOf(Date),
  toMonth: PropTypes.instanceOf(Date),
};

export default DatePickerInput;
