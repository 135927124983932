import {
  TOGGLE_DATA_CONSENT_INFO_ELEMENT,
  TOGGLE_PERSONAL_DATA_CONSENT_HELP,
} from '../actions/actionTypes';

const initialState = {
  activeInfoElements: [],
  displayHelp: false,
};
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_DATA_CONSENT_INFO_ELEMENT: {
      const index = state.activeInfoElements.indexOf(action.index);
      if (index > -1) {
        return {
          ...state,
          activeInfoElements: [
            ...state.activeInfoElements.slice(0, index),
            ...state.activeInfoElements.slice(index + 1),
          ],
        };
      }

      return {
        ...state,
        activeInfoElements: [...state.activeInfoElements, action.index],
      };
    }
    case TOGGLE_PERSONAL_DATA_CONSENT_HELP:
      return {
        ...state,
        displayHelp: !state.displayHelp,
      };
    default:
      return state;
  }
}
