import React from 'react';
import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { Check } from 'digitalbank-icons';
import classNames from 'classnames';
import SettingsCard from './SettingsCard';

const SettingsCardForDataConcent = () => (
  <SettingsCard
    heading="Samtykke til behandling av personopplysninger"
    description={
      <p>
        Samtykke til lorem ipsum dolor sit amet consectetur adipiscing elit. Les
        mer
      </p>
    }
  >
    <NavList
      items={[
        <NavListLink
          key="dataconcent"
          title={{ text: 'Jeg samtykker' }}
          Icon={({ className }, other) => (
            <Check
              className={classNames(className, 'u-color--positive')}
              {...other}
            />
          )}
          info={{
            text: 'Se / endre',
          }}
          variant={TASK}
          component={Link}
          to={PATHS.SETTINGS}
        />,
      ]}
    />
  </SettingsCard>
);

SettingsCardForDataConcent.propTypes = {};

export default SettingsCardForDataConcent;
