import { START, SUCCESSFUL, FAILED } from './data.constants';

export const change = (name, payload) => ({
  type: 'CHANGE_DATA',
  name,
  payload,
});

export const getDataStart = name => ({
  type: START,
  name,
});

export const getDataSuccessful = (name, payload) => ({
  type: SUCCESSFUL,
  name,
  payload,
});

export const getDataFailed = (name, error) => ({
  type: FAILED,
  name,
  error,
});
