import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getIcon, ChevronDown } from 'digitalbank-icons';
import getLinks from 'services/links.service';
import DropdownList from 'components/DropdownList';
import NavigationLink from './NavigationLink';

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeDropdownIndex: undefined,
    };
    this.resetState = this.resetState.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleClickOutside() {
    // This component is always rendered even when !active
    // To prevent handleClickOutside to trigger toggle we check the active property.
    if (this.state.activeDropdownIndex !== undefined) {
      this.resetState();
    }
  }
  toggleDropdown(event, activeDropdownIndex, index) {
    event.preventDefault();

    const dropdownIsAlreadyActive = activeDropdownIndex === index;
    // Remove dropdown index if it already is active
    if (dropdownIsAlreadyActive) {
      this.setState({
        ...this.state,
        activeDropdownIndex: undefined,
      });
    } else {
      this.setState({
        ...this.state,
        activeDropdownIndex: index,
      });
    }
  }
  resetState() {
    this.setState({
      ...this.state,
      activeDropdownIndex: undefined,
    });
  }
  render() {
    const { activeDropdownIndex } = this.state;
    const links = getLinks(this.props.notifications).filter(
      x => x.visibility.navigation,
    );
    return (
      <nav className="navigation navigation--responsive">
        <ul className="navigation__links">
          {links.map((link, i) => {
            if (link.path && !link.subelements) {
              return (
                <li
                  key={`navigation-link-${link.title}`}
                  className="navigation__item"
                >
                  <NavigationLink
                    path={link.path}
                    title={link.title}
                    icon={link.icon}
                  />
                </li>
              );
            } else if (link.subelements) {
              const Icon = getIcon(link.icon);
              return (
                <li
                  key={`navigation-sublink-${link.title}`}
                  className="navigation__item"
                >
                  <button
                    type="button"
                    className="navigation__link ignore-react-onclickoutside"
                    aria-label={link.title}
                    onClick={e =>
                      this.toggleDropdown(e, activeDropdownIndex, i)
                    }
                  >
                    {link.notification &&
                      link.notification.count > 0 && (
                        <div
                          className="badge badge--new navigation__link-badge"
                          aria-hidden
                        >
                          {link.notification.count}
                        </div>
                      )}
                    <Icon
                      className="navigation__icon"
                      aria-hidden="true"
                      role="presentation"
                    />
                    <span className="navigation__label">
                      {link.title}
                      <ChevronDown
                        className="navigation__label-icon"
                        aria-hidden
                        role="presentation"
                      />
                    </span>
                  </button>
                  <div
                    className={classNames('dropdown navigation-dropdown', {
                      'dropdown--active': activeDropdownIndex === i,
                    })}
                  >
                    <DropdownList
                      linkGroups={link.subelements}
                      onActionClick={this.resetState}
                      handleClickOutside={this.handleClickOutside}
                    />
                  </div>
                </li>
              );
            }
            return '';
          })}
        </ul>
      </nav>
    );
  }
}

Navigation.defaultProps = {
  notifications: undefined,
};

Navigation.propTypes = {
  notifications: PropTypes.shape({}),
};

export default Navigation;
