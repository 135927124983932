import React from 'react';
import PropTypes from 'prop-types';
import { TransitionMotion, spring } from 'react-motion';
import RecipientShape from 'shapes/RecipientShape';
import RecipientRow from './RecipientRow';

const RecipientTable = ({ recipients, deleteRecipient }) => (
  <div className="recipient-table">
    <div className="recipient-table__header">
      <div className="recipient-table__data">Navn</div>
      <div className="recipient-table__header-actions">
        <div className="recipient-table__header-action">Betal</div>
        <div className="recipient-table__header-action">Historikk</div>
        <div className="recipient-table__header-action">Slett</div>
      </div>
    </div>
    <TransitionMotion
      willLeave={() => ({ height: spring(0), opacity: spring(0) })}
      styles={recipients.map(recipient => ({
        key: `recipient-table-${recipient.id}`,
        style: {
          height: 55,
          opacity: 1,
        },
        data: {
          recipient,
        },
      }))}
    >
      {interpolatedStyles => (
        <div>
          {interpolatedStyles.map(config => (
            <RecipientRow
              key={config.key}
              style={{
                ...config.style,
                display: config.data.recipient.display ? 'flex' : 'none',
              }}
              recipient={config.data.recipient}
              deleteRecipient={() => deleteRecipient(config.data.recipient.id)}
            />
          ))}
        </div>
      )}
    </TransitionMotion>
  </div>
);

RecipientTable.propTypes = {
  recipients: PropTypes.arrayOf(RecipientShape).isRequired,
  deleteRecipient: PropTypes.func.isRequired,
};

export default RecipientTable;
