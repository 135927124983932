import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, Fields, change } from 'redux-form';
import { InputField } from 'digitalbank-form';
import classNames from 'classnames';
import AmountAndDateRow from 'components/Form/AmountAndDateRow';
import { Modal } from 'digitalbank-modal';
import RecipientSelect from 'components/RecipientLists/select/RecipientSelect';
import { Recipients } from 'data';
import i18n from 'i18n';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';
import _ from 'lodash';
import Button from 'digitalbank-buttons';
import RecipientField from 'routes/Payment/FormFields/RecipientField';
import AccountPickerField from 'digitalbank-form/lib/es/components/AccountPickerField';
import {
  receiveAccountList,
  requestAccountList,
  selectAccountItem,
  resetAccountPicker,
} from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import { isAccountPickerFetchingAccounts } from 'utils/account.util';
import { getFrekvensList } from 'utils/frekvens.util';
import OppdragFrekvensSelect from './FormFields/OppdragFrekvensSelect';

class PaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayReceivers: false,
      isLoading: false,
      isFetchingData: false,
    };
    this.fraKontoAccountPickerKey = `${this.props.formName}FraKonto`;
    this.initializeAccounts = this.initializeAccounts.bind(this);
    this.toggleReceivers = this.toggleReceivers.bind(this);
    this.updateRecipient = this.updateRecipient.bind(this);
    this.clearToAccountField = this.clearToAccountField.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // setTimeout(() => {
    //   this.setState({ isFetchingData: false });
    // }, 1200);
  }
  componentWillMount() {
    this.initializeAccounts();
    if (this.props.isEditing && this.props.initialValues) {
      this.props.onSelectAccountItem(this.fraKontoAccountPickerKey, {
        accountNumber: this.props.initialValues.fraKonto,
      });
    }
  }
  componentWillUpdate(nextProps) {
    if (
      this.props.isEditing &&
      !this.props.initialValues &&
      nextProps.initialValues
    ) {
      this.props.onSelectAccountItem(this.fraKontoAccountPickerKey, {
        accountNumber: nextProps.initialValues.fraKonto,
      });
    }
  }
  componentWillUnmount() {
    this.props.onResetAccountPicker(this.fraKontoAccountPickerKey);
  }
  initializeAccounts() {
    this.props.onRequestAccounts(this.fraKontoAccountPickerKey);
    setTimeout(() => {
      const accountResult = {
        kontoer: _.filter(Accounts, x => x.active && x.availableAmount > 0),
      };
      this.props.onReceiveAccounts(
        this.fraKontoAccountPickerKey,
        accountResult,
      );
    }, 1000);
  }
  toggleReceivers() {
    this.setState({
      ...this.state,
      displayReceivers: !this.state.displayReceivers,
    });
  }
  updateRecipient(recipient) {
    this.props.changeFieldValue(
      this.props.formName,
      'tilKontonavn',
      recipient.name,
    );
    this.props.changeFieldValue(
      this.props.formName,
      'tilKonto',
      recipient.account,
    );
  }
  clearToAccountField() {
    this.props.changeFieldValue(this.props.formName, 'tilKontonavn', '');
    this.props.changeFieldValue(this.props.formName, 'tilKonto', '');
  }
  handleSubmit() {
    this.setState({
      isLoading: true,
    });
    const promise = this.props.handleSubmit();
    promise.then(() => {
      this.setState({
        isLoading: false,
      });
    });
  }
  render() {
    const {
      children,
      accountPickers,
      isFastBetaling,
      submitButtonText,
      isDisabled,
      isEditing,
      isSubmitting,
    } = this.props;
    const { isFetchingData } = this.state;
    const isFetchingFromAccounts = isAccountPickerFetchingAccounts(
      accountPickers,
      this.fraKontoAccountPickerKey,
    );
    const datoLabel = isFastBetaling ? 'Første forfall' : 'Dato';
    const isSubmitDisabled = isFetchingFromAccounts || this.state.isLoading;
    return (
      <form className="payment-form" onSubmit={this.props.handleSubmit}>
        {isFastBetaling && (
          <OppdragFrekvensSelect
            disabled={isDisabled}
            frekvenser={getFrekvensList()}
            label={i18n(i => i.FREKVENS_TITTEL_BETALING)}
          />
        )}
        <Field
          name="fraKonto"
          disabled={isDisabled}
          accountpickerId={this.fraKontoAccountPickerKey}
          component={AccountPickerField}
          i18nTranslate={(template, format) => i18n(template, format)}
          isFetchingAccounts={isFetchingFromAccounts}
          type="text"
          label={i18n(i => i.FRA_KONTO_INPUT_LABEL)}
          defaultChoiceMessage="Velg fra-konto"
          isSkeleton={isFetchingData}
        />
        <Fields
          names={['tilKonto', 'tilKontonavn']}
          disabled={isEditing || isDisabled}
          component={RecipientField}
          clearToAccountField={this.clearToAccountField}
          toggleReceivers={this.toggleReceivers}
          isSkeleton={isFetchingData}
        />
        <Modal
          toggle={this.toggleReceivers}
          visible={this.state.displayReceivers}
          fullSize
        >
          <RecipientSelect
            header="Velg mottaker"
            closeText="Lukk"
            close={this.toggleReceivers}
            recipients={_.orderBy(
              _.filter(Recipients, x => x.group === 'innland'),
              x => x.name,
            )}
            onSelect={(recipient) => {
              this.updateRecipient(recipient);
              this.toggleReceivers();
            }}
          />
        </Modal>
        <Fields
          names={['kroner', 'oere', 'dato']}
          component={AmountAndDateRow}
          disabled={isDisabled}
          kronerLabel="Kroner"
          oereLabel="Øre"
          datoLabel={datoLabel}
          isSkeleton={isFetchingData}
        />
        <Field
          name="kidMelding"
          component={InputField}
          disabled={isDisabled || isEditing}
          type="text"
          label={isFastBetaling ? 'Melding' : 'KID eller melding'}
          autoComplete="off"
          isSkeleton={isFetchingData}
        />
        {!isDisabled && (
          <Button
            variant="primary"
            className={classNames('payment-form__complete-btn', {
              skeleton: isFetchingData,
            })}
            type="submit"
            disabled={isSubmitDisabled}
            rightIcon={
              isSubmitting ? <div className="button__spinner" /> : null
            }
          >
            {submitButtonText}
          </Button>
        )}
        {children}
      </form>
    );
  }
}

PaymentForm.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isFastBetaling: PropTypes.bool,
  isEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onReceiveAccounts: PropTypes.func.isRequired,
  onRequestAccounts: PropTypes.func.isRequired,
  onSelectAccountItem: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
  accountPickers: PropTypes.shape({
    paymentFormFraKonto: PropTypes.shape({
      isFetchingAccounts: PropTypes.bool,
    }),
  }).isRequired,
  initialValues: PropTypes.shape({
    fraKonto: PropTypes.string,
    id: PropTypes.string,
  }),
};

PaymentForm.defaultProps = {
  children: null,
  isDisabled: false,
  isFastBetaling: false,
  isEditing: false,
  isSubmitting: false,
  initialValues: null,
  submitButtonText: 'Neste',
};

const mapStateToProps = state => ({
  accountPickers: state.accountPickers,
});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: bindActionCreators(change, dispatch),
  onReceiveAccounts: bindActionCreators(receiveAccountList, dispatch),
  onRequestAccounts: bindActionCreators(requestAccountList, dispatch),
  onSelectAccountItem: bindActionCreators(selectAccountItem, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PaymentForm);
