import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isPristine } from 'redux-form';
import * as PATHS from 'routes/paths';
import { BasePage } from 'digitalbank-layout';
import { addTransaction } from 'services/transactions.service';
import { bindActionCreators } from 'redux';
import dataSelector from 'redux-helpers/dataSelector';
import { change } from 'redux-helpers/data.actions';
import Button from 'digitalbank-buttons';
import { TYPE } from 'digitalbank-mockup-data/lib/Transactions';
import PaymentSummaryList from 'routes/Payment/PaymentSummaryList';
import { isKidNumber } from 'utils/validation.util';
import wizardPage from 'components/wizardPage';
import { BETALE_EN_FORM_NAME } from '../payment.constants';
import PaymentStyled from '../PaymentStyled';

const BetaleEnSummary = ({
  transactions, changeData, values, history,
}) => {
  const submitRoute = isKidNumber(values.kidMelding)
    ? PATHS.PAYMENT_RECEIPT
    : PATHS.PAYMENT_REAUTH;
  const handleSubmit = () => {
    addTransaction('transactions', transactions, changeData, {
      ...values,
      type: TYPE.BETALING,
    });
    history.push(submitRoute);
  };
  return (
    <PaymentStyled>
      <BasePage heading="Godkjenn betaling" className="p-payment">
        <div className="payment-summary">
          <PaymentSummaryList
            toAccountNumber={values.tilKonto}
            fromAccountNumber={values.fraKonto.accountNumber}
            fromAccountName={
              values.fraKonto.visningsnavn || values.fraKonto.accountName
            }
            recipientName={values.tilKontonavn}
            kroner={values.kroner}
            oere={values.oere}
            dueDate={values.dato}
            kidOrMessage={values.kidMelding}
          />
          <Button
            variant="primary"
            className="payment-form__submit-btn"
            onClick={handleSubmit}
            type="submit"
          >
            Betal
          </Button>
        </div>
      </BasePage>
    </PaymentStyled>
  );
};

BetaleEnSummary.propTypes = {
  history: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({}).isRequired,
  changeData: PropTypes.func.isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
});

const mapStateToProps = state => ({
  values: getFormValues(BETALE_EN_FORM_NAME)(state),
  transactions: dataSelector(state, 'transactions'),
});

export default wizardPage({
  redirectTest: state => isPristine(BETALE_EN_FORM_NAME)(state),
  redirectPath: PATHS.PAY,
})(connect(mapStateToProps, mapDispatchToProps)(BetaleEnSummary));
