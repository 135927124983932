import React from 'react';
import { ErrorPage } from 'digitalbank-layout';

export default () => (
  <ErrorPage
    className="p-error"
    errorTitle="Siden finnes ikke"
    errorDescription="Siden finnes ikke. Dette kan skyldes at adressen er feil stavet i adressefeltet eller lenken
      du klikket er feil satt opp."
  />
);
