import React from 'react';
import PropTypes from 'prop-types';
import { updateValue } from 'actions/userSettings.actions';
import { connect } from 'react-redux';
import MySettingsForfallFieldset from 'containers/MySettings/MySettingsForfallFieldset';
import MySettingsForfallPresentationFieldset from 'containers/MySettings/MySettingsForfallPresentationFieldset';
import MySettingsKredittkortPresentationFieldset from 'containers/MySettings/MySettingsKredittkortPresentationFieldset';
import MySettingsBalanceCarouselFieldset from 'containers/MySettings/MySettingsBalanceCarouselFieldset';

const MySettingsContainer = ({ userSettings, onUpdateValue }) => {
  const {
    forfall,
    forfallPresentation,
    kredittkortPresentation,
    balanceCarouselImage,
  } = userSettings;

  return (
    <form className="home-settings__presentation-form">
      <MySettingsForfallFieldset
        forfall={forfall}
        updateFilter={(value, index) => onUpdateValue('FORFALL', value, index)}
      />
      <MySettingsForfallPresentationFieldset
        forfallPresentation={forfallPresentation}
        updateFilter={(value, index) =>
          onUpdateValue('FORFALL_PRESENTATION', value, index)
        }
      />
      <MySettingsKredittkortPresentationFieldset
        kredittkortPresentation={kredittkortPresentation}
        updateFilter={(value, index) =>
          onUpdateValue('KREDITTKORT_PRESENTATION', value, index)
        }
      />
      <MySettingsBalanceCarouselFieldset
        balanceCarouselImage={balanceCarouselImage}
        updateValue={(value, index) =>
          onUpdateValue('BILDE_I_SALDO_MODUL', value, index)
        }
      />
    </form>
  );
};

MySettingsContainer.propTypes = {
  userSettings: PropTypes.shape({}).isRequired,
  onUpdateValue: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    userSettings: state.user.settings,
  }),
  {
    onUpdateValue: updateValue,
  },
)(MySettingsContainer);
