const REMOVE_SPERRET_EFAKTURA_AVSENDER = 'USER_SPERREDE_AVSENDERE/REMOVE';
const ADD_SPERRET_EFAKTURA_AVSENDER = 'USER_SPERREDE_AVSENDERE/ADD';

const removeSperretEfakturaAvsender = id => ({
  type: REMOVE_SPERRET_EFAKTURA_AVSENDER,
  id,
});

const addSperretEfakturaAvsender = id => ({
  type: ADD_SPERRET_EFAKTURA_AVSENDER,
  id,
});

export {
  removeSperretEfakturaAvsender,
  addSperretEfakturaAvsender,
  REMOVE_SPERRET_EFAKTURA_AVSENDER,
  ADD_SPERRET_EFAKTURA_AVSENDER,
};
