/*  eslint-disable max-len */

import React, { Component } from 'react';
import Button from 'digitalbank-buttons';
import { Link } from 'react-router-dom';
// import Alert from 'digitalbank-alert';
import * as PATHS from 'routes/paths';
import { Times } from 'digitalbank-icons';

class EfakturaFraAlleCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeclined: false,
    };
  }
  render() {
    const { isDeclined } = this.state;
    return (
      <div className="efaktura-fra-alle-card">
        {!isDeclined && (
          <div>
            <article className="efaktura-fra-alle-card__article">
              <div className="efaktura-fra-alle-card__article-content">
                <h3>eFaktura fra alle</h3>
                <p>
                  Motta eFaktura automatisk, uten egne avtaler for hver enkelt
                  bedrift.{' '}
                  <Link to={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_INFO}>
                    Les mer
                  </Link>
                </p>
              </div>
              <div className="efaktura-fra-alle-card__actions">
                <Button
                  variant="primary"
                  component={Link}
                  to={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_SETUP}
                >
                  Aktiver
                </Button>
              </div>
            </article>
            <button
              type="button"
              onClick={() => this.setState({ isDeclined: true })}
              className="efaktura-fra-alle-card__close-button"
            >
              <Times className="alert__close-button-icon" />
            </button>
          </div>
        )}
        {/* {isDeclined && (
          <Alert small type="info">
            <p>
              Du kan alltid aktivere <b>eFaktura fra alle</b> nederst på siden
              om du skulle ønske det.
            </p>
          </Alert>
        )} */}
      </div>
    );
  }
}

EfakturaFraAlleCard.defaultProps = {};
EfakturaFraAlleCard.propTypes = {};

export default EfakturaFraAlleCard;
