import PropTypes from 'prop-types';

export default {
  history: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  load: PropTypes.func.isRequired,
  unload: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  initialValues: PropTypes.shape({}),
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  changeData: PropTypes.func.isRequired,
  automaticEfakturaIsActive: PropTypes.bool.isRequired,
  onRemoveSperretEfakturaAvsender: PropTypes.func.isRequired,
  onAddSperretEfakturaAvsender: PropTypes.func.isRequired,
};
