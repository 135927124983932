import styled from 'styled-components';

export const HiddenInput = styled.input`
  position: absolute;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
`;
