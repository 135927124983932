import moment from 'moment';

export default {
  kroner: '',
  oere: '',
  fraKonto: '',
  tilKonto: '',
  dato: moment()
    .format('DD.MM.YYYY')
    .toString(),
  melding: '',
};
