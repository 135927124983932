import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { reduxForm, getFormValues, destroy } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { change } from 'redux-helpers/data.actions';
import dataSelector from 'redux-helpers/dataSelector';
import { BasePage } from 'digitalbank-layout';
import * as PATHS from 'routes/paths';
import historyShape from 'shapes/history.shape';
import _ from 'lodash';
import Alert from 'digitalbank-alert';
import ActionList, { ActionLink, DeleteButton } from 'digitalbank-action-list';
import { Clock, CheckCircle } from 'digitalbank-icons';
import i18n from 'i18n';
import {
  deleteTransaction,
  getTransactionFromId,
  updateTransactionWithFormValues,
} from 'services/transactions.service';
import { STATUS as TRANSACTION_STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import TransferForm from '../TransferForm';
import validate from '../transfer.validate';
import { FAST_OVERFOERING_EDIT_FORM_NAME } from '../transfer.constants';
import {
  load as loadTransaction,
  unload as unloadTransaction,
} from './editFastOverfoering.actions';
import initialValues from '../FastOverfoering/fastOverfoering.initialValues';

const init = (transactions, transactionId, history, load) => {
  const isTransactionFound =
    _.filter(transactions.data, x => x.id === transactionId).length > 0;
  if (!isTransactionFound) {
    history.push(PATHS.FASTE_OPPDRAG);
  } else {
    load(transactions, transactionId);
  }
};

class EditFastOverfoering extends Component {
  componentDidMount() {
    this.props.onDestroy(FAST_OVERFOERING_EDIT_FORM_NAME);
    if (this.props.transactions.status.received) {
      const {
        transactions, match, history, load,
      } = this.props;
      init(transactions, match.params.id, history, load);
    }
  }
  componentWillUpdate(nextProps) {
    if (
      !this.props.transactions.status.received &&
      nextProps.transactions.status.received
    ) {
      const { match, history, load } = this.props;
      const { transactions } = nextProps;
      init(transactions, match.params.id, history, load);
    }
  }
  componentWillUnmount() {
    this.props.unload();
  }
  render() {
    const {
      history,
      handleSubmit,
      formValues,
      transactions,
      recurringTransactions,
      changeData,
      submitting,
    } = this.props;
    const { id } = this.props.match.params;
    const transaction = getTransactionFromId(recurringTransactions.data, id);
    const isDeletePending =
      transaction &&
      transaction.status === TRANSACTION_STATUS.SHOULD_BE_DELETED;
    const isDeleted =
      transaction && transaction.status === TRANSACTION_STATUS.DELETED;
    const actions = [
      <ActionLink
        href="/"
        title="Historikk"
        key="history"
        RouterLink={Link}
        Icon={Clock}
      />,
      <DeleteButton
        key="delete"
        value="Slett"
        i18nTranslate={(template, format) => i18n(template, format)}
        isDeletePending={isDeletePending}
        onDeleteConfirmed={() => {
          deleteTransaction('transactions', transactions, changeData, id);
          deleteTransaction(
            'recurringTransactions',
            recurringTransactions,
            changeData,
            id,
          );
        }}
        onDeleteErrorClose={() => console.log('onDeleteErrorClose click')} // eslint-disable-line
        showDeleteError={false}
        deleteError="Error"
      />,
    ];
    return (
      <BasePage
        heading={i18n(t => t.ROUTE_TITLE_FAST_OVERFOERING_EDIT)}
        className="p-fast-overfoering"
      >
        <TransferForm
          isDisabled={isDeleted}
          isSubmitting={submitting}
          formName={FAST_OVERFOERING_EDIT_FORM_NAME}
          formValues={formValues}
          submitButtonText="Bekreft"
          isEditing
          transactionId={id}
          isFastOverfoering
          initialValues={this.props.initialValues}
          handleSubmit={handleSubmit(
            data =>
              new Promise((resolve) => {
                setTimeout(() => {
                  // NOTE: Edit transaction in both recurringTransactions and transactions
                  updateTransactionWithFormValues(
                    'recurringTransactions',
                    recurringTransactions,
                    changeData,
                    id,
                    data,
                  );
                  updateTransactionWithFormValues(
                    'transactions',
                    transactions,
                    changeData,
                    id,
                    data,
                  );
                  resolve();
                  history.push(`${PATHS.FAST_OVERFOERING_EDIT_RECEIPT}/${id}`);
                }, 500);
              }),
          )}
        >
          {isDeleted && (
            <Alert
              type="info"
              icon={CheckCircle}
              title="Det faste oppdraget ble slettet"
            >
              <Link to={PATHS.FASTE_OPPDRAG}>Se faste oppdrag</Link>
            </Alert>
          )}
        </TransferForm>
        {!isDeleted && <ActionList actions={actions} orientation="VERTICAL" />}
      </BasePage>
    );
  }
}

EditFastOverfoering.defaultProps = {
  initialValues: null,
};

EditFastOverfoering.propTypes = {
  history: historyShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  formValues: PropTypes.shape({}).isRequired,
  load: PropTypes.func.isRequired,
  unload: PropTypes.func.isRequired,
  onDestroy: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  initialValues: PropTypes.shape({}),
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  recurringTransactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  changeData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  load: bindActionCreators(loadTransaction, dispatch),
  unload: bindActionCreators(unloadTransaction, dispatch),
  onDestroy: bindActionCreators(destroy, dispatch),
  changeData: bindActionCreators(change, dispatch),
});

const mapStateToProps = state => ({
  initialValues: state.editFastOverfoering.data,
  transactions: dataSelector(state, 'transactions'),
  recurringTransactions: dataSelector(state, 'recurringTransactions'),
  accounts: state.accounts,
  formValues:
    getFormValues(FAST_OVERFOERING_EDIT_FORM_NAME)(state) || initialValues,
});

const form = reduxForm({
  destroyOnUnmount: false,
  form: FAST_OVERFOERING_EDIT_FORM_NAME,
  validate,
})(EditFastOverfoering);

export default connect(mapStateToProps, mapDispatchToProps)(form);
