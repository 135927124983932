const template = {
  DATE_FORMAT: {
    'nb-NO': 'DD.MM.YYYY',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  DATE_DISPLAY_FORMAT: {
    'nb-NO': 'dd.mm.åååå',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  PARSE_DATE_FORMAT: {
    'nb-NO': 'D.M.YYYY',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MISSING_DATE: {
    'nb-NO': 'Forfallsdato mangler',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  INVALID_DATE_FORMAT: {
    'nb-NO': 'Dato er ikke på korrekt format; {format}',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  INVALID_DATE_BEFORE: {
    'nb-NO': 'Datoen må være etter {date, date, standard}',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  INVALID_DATE_AFTER: {
    'nb-NO': 'Datoen må være før {date, date, standard}',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_LONG_0: {
    'nb-NO': 'Søndag',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_LONG_1: {
    'nb-NO': 'Mandag',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_LONG_2: {
    'nb-NO': 'Tirsdag',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_LONG_3: {
    'nb-NO': 'Onsdag',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_LONG_4: {
    'nb-NO': 'Torsdag',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_LONG_5: {
    'nb-NO': 'Fredag',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_LONG_6: {
    'nb-NO': 'Lørdag',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_SHORT_0: {
    'nb-NO': 'Søn',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_SHORT_1: {
    'nb-NO': 'Man',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_SHORT_2: {
    'nb-NO': 'Tir',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_SHORT_3: {
    'nb-NO': 'Ons',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_SHORT_4: {
    'nb-NO': 'Tor',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_SHORT_5: {
    'nb-NO': 'Fre',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  WEEKDAY_SHORT_6: {
    'nb-NO': 'Lør',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_0: {
    'nb-NO': 'Januar',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_1: {
    'nb-NO': 'Februar',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_2: {
    'nb-NO': 'Mars',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_3: {
    'nb-NO': 'April',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_4: {
    'nb-NO': 'Mai',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_5: {
    'nb-NO': 'Juni',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_6: {
    'nb-NO': 'Juli',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_7: {
    'nb-NO': 'August',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_8: {
    'nb-NO': 'September',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_9: {
    'nb-NO': 'Oktober',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_10: {
    'nb-NO': 'November',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
  MONTH_11: {
    'nb-NO': 'Desember',
    'nn-NO': 'Unknown',
    'en-GB': 'Unknown',
  },
};

export default template;
