import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Times } from 'digitalbank-icons';

const buttonSize = 32;
const receiptWidth = 180;

const ReceiptImageStyled = styled.img`
  border-radius: 5px;
  border: 2px solid #ddd;
  image-orientation: from-image;
`;

const DeleteButton = styled.button`
  width: ${buttonSize}px;
  height: ${buttonSize}px;
  background: #c40000;
  apperance: none;
  cursor: pointer;
  color: #fff;
  border-radius: 50%;
  border: none;
  outline: none;

  &:hover {
    background: #ab0000;
  }

  &:focus {
    background: #910000;
  }
`;

const DeleteButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
    width: ${buttonSize / 2.6}px;
    height: ${buttonSize / 2.6}px;
  }
`;

const ReceiptStyled = styled.div`
  position: relative;
  display: inline-block;
  width: ${receiptWidth}px;

  ${DeleteButton} {
    position: absolute;
    right: -${buttonSize / 2}px;
    top: -${buttonSize / 2}px;
  }
`;

const Receipt = ({ imageId, deleteReceipt }) => (
  <ReceiptStyled>
    <ReceiptImageStyled
      src={`https://min-okonomi.spv-test-01.ase.spvest.no/ocr/image/${imageId}/file`}
      alt="Kvittering"
    />
    <DeleteButton
      variant="edit"
      size="small"
      onClick={deleteReceipt}
      style={{ display: 'block' }}
    >
      <DeleteButtonContent>
        <Times />
      </DeleteButtonContent>
    </DeleteButton>
  </ReceiptStyled>
);

Receipt.propTypes = {
  imageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  deleteReceipt: PropTypes.func.isRequired,
};
export default Receipt;
