/* eslint-disable no-console, no-script-url */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { root } from 'services/economy.service';
import Spinner from 'digitalbank-spinner';
import { HiddenButton } from 'styled/Buttons';
import { HiddenInput } from 'styled/Form';
import Receipt from './Receipt';
import UploadIcon from './UploadIcon';

const LabelIconStyled = styled.div`
  margin-right: 8px;
  display: inline-block;
  color: #777;
`;

const LabelStyled = styled.label`
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0;
  padding: .5rem 1.25rem;
  font-size: 1rem;
  text-transform: none;
  cursor: pointer;
  background-color: #fff;
  color: #444;
  border: 1px solid #bbb;
  display: inline-block;
  width: 100%;

  &:hover {
    background: #e6e6e6;
  }
  &:focus {
    background: #d9d9d9;
  }
}
`;

const FormStyled = styled.form`
  margin-bottom: 40px;
`;

const InputStyled = styled(HiddenInput)``;
const SubmitButtonStyled = styled(HiddenButton)``;
const ResetButtonStyled = styled(HiddenButton)``;

const getFileInputLabel = (newFile, status, hasFile) => {
  const isSuccessful = status === 'success';
  if (isSuccessful && hasFile) return 'Legg til ny kvittering';
  else if (newFile) return `Laster opp ${newFile.name}`;
  else if (hasFile) return 'Legg til ny kvittering';
  return 'Legg til kvittering';
};

class TransactionForm extends Component {
  static uploadImage(file, transactionId) {
    const url = `${root}/ocr/upload/${transactionId}`;
    const formData = new FormData();
    formData.append('files', file);
    formData.append('transactionId', transactionId);
    return fetch(url, {
      method: 'POST',
      body: formData,
    });
  }
  static getImage(transactionId) {
    return fetch(`${root}/ocr/transaction/${transactionId}`);
  }
  static deleteImage(imageId) {
    return fetch(`${root}/ocr/image/${imageId}/file`, { method: 'DELETE' });
  }
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imageData: { id: this.props.transaction.imageId },
      fileUploadStatus: '',
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getImage = this.getImage.bind(this);
    this.deleteReceipt = this.deleteReceipt.bind(this);
    this.fileInput = React.createRef();
  }
  onFormSubmit(e) {
    e.preventDefault(); // Prevent default form submit
    TransactionForm.uploadImage(
      this.state.file,
      this.props.transaction.transactionId,
    )
      .then(() => {
        this.getImage();
      })
      .catch(error => console.error('Error:', error));
  }
  onChange(e) {
    const file = e.target.files[0];

    this.setState({ file, fileUploadStatus: 'loading' });
    TransactionForm.uploadImage(file, this.props.transaction.transactionId)
      .then(() => {
        this.getImage();
      })
      .catch((error) => {
        this.setState({ fileUploadStatus: 'error' });
        console.error('Error:', error);
      });
  }
  getImage() {
    TransactionForm.getImage(this.props.transaction.transactionId)
      .then(response => response.json())
      .then((imageData) => {
        this.setState({
          imageData: imageData[0],
          fileUploadStatus: 'success',
        });
        // Reset file value
        this.resetButton.click();
      });
  }
  deleteReceipt(imageId) {
    TransactionForm.deleteImage(imageId).then(() => {
      this.setState({
        imageData: undefined,
        file: null,
      });
    });
  }
  render() {
    if (!this.props.visible) return null;

    const { file, imageData, fileUploadStatus } = this.state;
    const hasFile = !!imageData && !!imageData.id;
    const label = getFileInputLabel(file, fileUploadStatus, hasFile);
    const id = this.props.transaction.transactionId;

    return (
      <div>
        <FormStyled
          id={`transaction-form-${id}`}
          action="javascript:void(0);"
          onSubmit={this.onFormSubmit}
        >
          {!hasFile && (
            <div>
              <LabelStyled htmlFor={`transaction-form-file-${id}`}>
                <LabelIconStyled>
                  {fileUploadStatus === 'loading' && <Spinner small />}
                  {fileUploadStatus !== 'loading' && <UploadIcon />}
                </LabelIconStyled>
                {label}
              </LabelStyled>
              <InputStyled
                type="file"
                id={`transaction-form-file-${id}`}
                name={`transaction-form-file-${id}`}
                onChange={this.onChange}
                onUpdate={() => console.log('update')}
              />
            </div>
          )}
          <ResetButtonStyled
            type="reset"
            innerRef={(el) => {
              this.resetButton = el;
            }}
          >
            Reset
          </ResetButtonStyled>
          <SubmitButtonStyled type="submit">{label}</SubmitButtonStyled>
        </FormStyled>
        {hasFile && (
          <Receipt
            imageId={imageData.id}
            deleteReceipt={() => this.deleteReceipt(imageData.id)}
          />
        )}
      </div>
    );
  }
}

TransactionForm.propTypes = {
  transaction: PropTypes.shape({
    transactionId: PropTypes.number.isRequired,
    imageId: PropTypes.string,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
};

export default TransactionForm;
