import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import AmountIncreaserItem from './AmountIncreaserItem';

const AmountIncreaser = ({
  classNames, onAmountClick, amounts, disabled,
}) => (
  <div
    className={classnames(classNames, 'amount-increaser', {
      'amount-increaser--disabled': disabled,
    })}
  >
    {amounts.map(amount => (
      <AmountIncreaserItem
        key={amount}
        amount={amount}
        onAmountClick={onAmountClick}
        disabled={disabled}
      />
    ))}
  </div>
);

AmountIncreaser.defaultProps = {
  classNames: '',
  disabled: false,
};

AmountIncreaser.propTypes = {
  onAmountClick: PropTypes.func.isRequired,
  amounts: PropTypes.arrayOf(PropTypes.number).isRequired,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
};

AmountIncreaser.defaultProps = {
  classNames: '',
  disabled: false,
};

export default AmountIncreaser;
