import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 533.709 538.994"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M323.193 226.645c39.244 24.41 75.644 47.053 115.706 71.978 7.687-5.443 20.518-14.485 33.308-23.596 16.733-11.923 36.27-11.452 49.046 3.779 3.513 4.191 2.568 15.766-.705 21.426-23.705 40.994-48.427 81.404-73.095 121.833-4.729 7.745-9.06 19.278-21.177 13.509-12.203-5.8-28.746-9.521-27.842-28.026.891-18.185 3.495-36.292 4.924-50.249-40.704-19.793-79.74-38.778-119.825-58.269-16.168 17.561-22.275 40.532-27.606 64.119-8.975 39.719-18.474 79.324-28.171 118.881-5.593 22.809-12.452 26.109-34.167 17.908-28.122-10.606-31.047-14.689-31.318-45.384-.605-68.198-1.514-136.4-1.325-204.593.045-15.865-4.177-25.531-19.237-32.95-30.238-14.884-60.119-30.866-88.548-48.915-13.988-8.884-26.951-21.77-35.867-35.727-13.768-21.535-1.913-41.939 23.343-40.623 17.786.931 36.644 4.67 52.618 12.229 32.58 15.413 63.735 33.905 95.022 51.921 8.735 5.028 15.083 4.992 23.944.068 64.671-35.921 129.717-71.172 194.611-106.705 25.712-14.075 46.608-10.335 65.331 12.008 10.309 12.302 2.247 20.797-6.506 28.579-35.89 31.91-72.438 63.093-107.682 95.687-13.098 12.108-23.298 27.345-34.782 41.112z"
    />
  </svg>
);
