/* eslint-disable no-mixed-operators */
/* eslint-disable no-script-url */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { InputField, CheckboxField, Legend } from 'digitalbank-form';
import { reduxForm, Field, getFormValues } from 'redux-form';
import { Button } from 'digitalbank-buttons';
import Alert, { AlertTypes } from 'digitalbank-alert';
import styled from 'styled-components';
import { breakpoints, spacing } from 'digitalbank-core-style';
import { addTag, editTag } from 'services/tag.service';
import * as PATHS from 'routes/paths';
// import Map from './Map';

const getPlaceholder = ({ isTravelTag, isTravelWithWork }) => {
  if (isTravelTag && isTravelWithWork) return 'Eksempel Konferanse i Olso';
  if (isTravelTag) return 'Eksempel Sommerferie 2019';
  return 'Eksempel Festival 2019';
};

export const Form = styled.form`
  /* Need this to position map */
  position: relative;
  .button {
    @media (max-width: ${breakpoints.tablet - 1}px) {
      width: 100%;
    }
  }
`;

// const MapStyled = styled.div`
//   position: absolute;
//   top: 25%;
//   left: 0;
//   right: 0;
// `;

const AlertStyled = styled.div`
  margin-bottom: ${spacing.layoutSpacing.xs}px;
`;

const AutomaticTagForm = ({
  isEditing,
  isTravelTag,
  isTravelWithWork,
  tag,
  formValues,
  onSubmitSuccessCallback,
  handleSubmit,
  history,
}) => {
  const name = {
    label: isTravelTag ? 'Navn på reisen' : 'Navn på tagg',
    placeholder: getPlaceholder({ isTravelTag, isTravelWithWork }),
  };
  const submitText = isEditing ? 'Lagre' : 'Start tagging av betalinger';

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        if (isEditing) {
          editTag({
            id: tag.id,
            data: { ...formData, isActive: tag.isActive },
          }).then(() => {
            if (onSubmitSuccessCallback) {
              onSubmitSuccessCallback();
            }
            // history.push(PATHS.TAGS);
          });
        } else {
          addTag({
            ...formData,
            isAutomatic: true,
            isActive: true,
            isTravelTag,
            isTravelWithWork,
          }).then(() => {
            // TODO: Do not push if error (duplicate control)
            history.push(PATHS.TAGS);
          });
        }
      })}
      action="javascript:void(0);"
    >
      {/* {isTravelTag && (
        <MapStyled>
          <Map />
        </MapStyled>
      )} */}
      {/* {!isEditing && (
        <Fragment>
          {isTravelTag && (
            <p>
              Nå kan du tagge alle utgifter automatisk mens du er på reise. Du
              kan enkelt legge til eller trekke fra utgifter senere.
            </p>
          )}
          {!isTravelTag && (
            <p>
              Nå kan du tagge alle betalinger automatisk. Du kan enkelt fjerne
              eller legge til tagg på betalinger senere.
            </p>
          )}
        </Fragment>
      )} */}
      <fieldset className="fieldset">
        <legend className="legend-screen-reader">Detaljer</legend>
        <Field
          component={InputField}
          name="name"
          id="name"
          label={name.label}
          placeholder={name.placeholder}
          transformableLabel={false}
          autoComplete="off"
          required
        />
        <Field
          component={InputField}
          name="budget"
          id="budget"
          label="Budsjett"
          type="number"
          // placeholder={name.placeholder}
          // transformableLabel={false}
          autoComplete="off"
        />
        {isTravelWithWork && (
          <Fragment>
            <Field
              component={CheckboxField}
              name="diet"
              id="diet"
              label="Jeg reiser på diett"
            />
            {formValues &&
              formValues.diet && (
                <Field
                  component={InputField}
                  name="country"
                  id="country"
                  label="Destinasjon / Land"
                  placeholder="Eksempel England"
                  transformableLabel={false}
                  autoComplete="off"
                />
              )}
          </Fragment>
        )}
      </fieldset>
      <fieldset className="fieldset">
        <Legend text="Varslinger om kortbruk" headingSelector="h4" />
        <Field
          component={CheckboxField}
          name="notificationOnCardUse"
          id="notificationOnCardUse"
          label="Send varsel når jeg bruker kortet"
        />
        <Field
          component={CheckboxField}
          name="notificationWithDailySummaryOfCardUse"
          id="notificationWithDailySummaryOfCardUse"
          label="Send meg en daglig oppsummering"
        />
      </fieldset>
      <fieldset className="fieldset">
        <Legend text="Andre varsler" headingSelector="h4" />
        <Field
          component={CheckboxField}
          name="notificationWithReminderToTakePictureOfReceipt"
          id="notificationWithReminderToTakePictureOfReceipt"
          label="Send meg påminnelse om å ta bilde av kvittering når jeg handler"
        />
        {isTravelWithWork && (
          <AlertStyled>
            <Alert type={AlertTypes.INFO} small hideIcon>
              Tips: Du kan spare masse tid ved å ta bilde av kvitteringer
              underveis.
            </Alert>
          </AlertStyled>
        )}
        <Field
          component={CheckboxField}
          name="notificationWhenUsingWrongValuta"
          id="notificationWhenUsingWrongValuta"
          label="Send meg varsel hvis jeg bruker feil valuta"
        />
      </fieldset>
      <Button variant="primary" type="submit">
        {submitText}
      </Button>
    </Form>
  );
};

AutomaticTagForm.defaultProps = {
  isEditing: false,
  isTravelTag: false,
  isTravelWithWork: false,
  formValues: undefined,
  initialValues: null,
  tag: null,
  onSubmitSuccessCallback: null,
};

AutomaticTagForm.propTypes = {
  isEditing: PropTypes.bool,
  isTravelTag: PropTypes.bool,
  isTravelWithWork: PropTypes.bool,
  formValues: PropTypes.shape({}),
  initialValues: PropTypes.shape({}),
  tag: PropTypes.shape({}),
  onSubmitSuccessCallback: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
};

const form = reduxForm({
  form: 'automaticTagForm',
  destroyOnUnmount: true,
  initialValues: {
    diet: false,
    notificationOnCardUse: false,
    notificationWithDailySummaryOfCardUse: false,
    notificationWithReminderToTakePictureOfReceipt: false,
    notificationWhenUsingWrongValuta: false,
  },
})(AutomaticTagForm);

const mapStateToProps = state => ({
  formValues: getFormValues('automaticTagForm')(state),
});

export default connect(
  mapStateToProps,
  null,
)(form);
