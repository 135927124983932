import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import moment from 'moment';
import DatePickerInput from 'components/Form/DatePickerInput';
import Legend from 'digitalbank-form/lib/es/components/base/Legend';
import _ from 'lodash';
import shortid from 'shortid';
import OptionButton from './OptionButton';

const options = [
  {
    value: _.capitalize(
      moment()
        .startOf('month')
        .format('MMMM'),
    ),
    fromDate: moment()
      .startOf('month')
      .format('DD.MM.YYYY'),
    toDate: moment()
      .endOf('month')
      .format('DD.MM.YYYY'),
  },
  {
    value: _.capitalize(
      moment()
        .subtract(1, 'months')
        .startOf('month')
        .format('MMMM'),
    ),
    fromDate: moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('DD.MM.YYYY'),
    toDate: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('DD.MM.YYYY'),
  },
  {
    value: 'I år',
    fromDate: moment()
      .startOf('year')
      .format('DD.MM.YYYY'),
    toDate: moment()
      .endOf('year')
      .format('DD.MM.YYYY'),
  },
  {
    value: 'I fjor',
    fromDate: moment()
      .subtract(1, 'year')
      .startOf('year')
      .format('DD.MM.YYYY'),
    toDate: moment()
      .subtract(1, 'year')
      .endOf('year')
      .format('DD.MM.YYYY'),
  },
  {
    value: 'Siste 90 dager',
    fromDate: moment()
      .subtract(90, 'days')
      .startOf('day')
      .format('DD.MM.YYYY'),
    toDate: moment()
      .endOf('day')
      .format('DD.MM.YYYY'),
  },
];

const SearchFilterDateOptions = ({
  onChange,
  toggleFields,
  isFieldsVisible,
  getTransactions,
  fromDate,
  toDate,
}) => (
  <fieldset className="fieldset search-filter__group">
    <div className="search-filter__legend search-filter__legend--with-spacing">
      <Legend text="Periode" headingSelector="h5" />
    </div>
    <div className="search-filter__options">
      {options.map(option => (
        <OptionButton
          key={shortid.generate()}
          value={option.value}
          isActive={option.fromDate === fromDate && option.toDate === toDate}
          onClick={() => {
            if (option.fromDate === fromDate && option.toDate === toDate) {
              onChange('searchForm', 'fromDate', '');
              onChange('searchForm', 'toDate', '');
            } else {
              onChange('searchForm', 'fromDate', option.fromDate);
              onChange('searchForm', 'toDate', option.toDate);
            }
            getTransactions(option.fromDate, option.toDate);
          }}
        />
      ))}
    </div>
    {isFieldsVisible && (
      <div className="search-filter__calendar-fields">
        <Field
          name="fromDate"
          id="fromDate"
          label="Fra dato"
          transformableLabel={false}
          component={DatePickerInput}
          fromMonth={moment()
            .subtract(10, 'years')
            .toDate()}
          toMonth={moment().toDate()}
          type="text"
          onChange={date => getTransactions(date, toDate)}
        />
        <Field
          name="toDate"
          id="toDate"
          label="Til dato"
          transformableLabel={false}
          component={DatePickerInput}
          fromMonth={moment()
            .subtract(10, 'years')
            .toDate()}
          toMonth={moment().toDate()}
          type="text"
          onChange={date => getTransactions(fromDate, date)}
        />
      </div>
    )}
    <div className="search-filter__container">
      <button className="search-filter__fieldset-btn" onClick={toggleFields}>
        {!isFieldsVisible && <span>Velg datoer</span>}
        {isFieldsVisible && <span>Skjul datovelger</span>}
      </button>
    </div>
  </fieldset>
);

SearchFilterDateOptions.defaultProps = {
  fromDate: undefined,
  toDate: undefined,
};

SearchFilterDateOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  toggleFields: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  isFieldsVisible: PropTypes.bool.isRequired,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
};

export default SearchFilterDateOptions;
