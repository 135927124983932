import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField } from 'digitalbank-form';

class EmailField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayField: false,
    };
  }
  componentDidMount() {
    if (this.props.initialValues && this.props.initialValues.email) {
      this.displayField();
    }
  }
  displayField() {
    this.setState({ displayField: true });
  }
  render() {
    return (
      <div>
        <input
          name="displayEmailField"
          id="displayEmailField"
          type="checkbox"
          className="checkbox"
          checked={this.state.displayField}
          onChange={() => {
            this.setState({ displayField: !this.state.displayField });
            this.props.changeFieldValue(this.props.formAlias, 'email', '');
          }}
        />
        <label className="checkbox__label" htmlFor="displayEmailField">
          Varsling på e-post
        </label>
        {this.state.displayField && (
          <div style={{ marginLeft: '30px' }}>
            <Field
              name="email"
              label="Varsling på e-post (valgfritt)"
              component={InputField}
              type="text"
              className="form-group--with-help-button-indent"
            />
            <input
              name="useEmailForEveryAvtaleGiro"
              id="useEmailForEveryAvtaleGiro"
              type="checkbox"
              className="checkbox"
            />
            <label
              className="checkbox__label"
              htmlFor="useEmailForEveryAvtaleGiro"
            >
              Bruk denne e-postadressen på alle mine AvtaleGiro-avtaler
            </label>
          </div>
        )}
      </div>
    );
  }
}

EmailField.propTypes = {
  initialValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  formAlias: PropTypes.string.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};

EmailField.defaultProps = {
  initialValues: null,
};

export default EmailField;
