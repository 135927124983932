export default [
  'toAccount',
  'country',
  'bankCode',
  'additionalBankInfo',
  'recipientName',
  'recipientAddress1',
  'recipientAddress2',
  'recipientAddress3',
  'recipientCountry',
  'bankAddress1',
  'bankAddress2',
  'bankAddress3',
  'fromAccount',
  'dueDate',
  'kroner',
  'oere',
  'currency',
  'paymentCurrency',
  'message',
  'expressPayment',
  'paymentPurpose',
  'paymentType',
  'expensesArePaidBy',
  'sendEmailConfirmationToReceiver',
  'receiverEmail',
  'agreedRateWithBank',
  'rateWithBank',
  'contactPerson',
  'codeFromDevice',
];
