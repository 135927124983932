import React from 'react';
import PropTypes from 'prop-types';

const KeyNumberLegend = props => (
  // Because of issues with the legend element we have to use a heading element
  // and a seperate visually hidden legend element.
  // Notice that we hide the legend using css and ignoring the heading using aria-hidden.
  // Reference to issue: https://bugzilla.mozilla.org/show_bug.cgi?id=292736
  <div>
    <h3 aria-hidden="true" className="key-number-legend">
      <div className="key-number-legend__text">{props.text}</div>
      <div className="key-number-legend__line" />
    </h3>
    <legend className="key-number-legend-screen-reader">{props.text}</legend>
  </div>
);

KeyNumberLegend.propTypes = {
  text: PropTypes.string.isRequired,
};

export default KeyNumberLegend;
