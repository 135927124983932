import { getTransactionFromId } from 'services/transactions.service';
import { mapTransactionToFormValues } from 'utils/transactions.util';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case 'EDIT_EN_BETALING/LOAD': {
      const transaction = getTransactionFromId(
        action.transactions.data,
        action.transactionId,
      );
      const formValues = mapTransactionToFormValues(transaction);
      return {
        ...state,
        data: {
          // used to detect if we need to destroy form or not when entering first edit route
          transactionId: action.transactionId,
          ...formValues,
        },
      };
    }

    case 'EDIT_EN_BETALING/UNLOAD': {
      return {};
    }
    default:
      return state;
  }
}
