import styled from 'styled-components';
import { colors } from 'digitalbank-core-style';
import { rem } from 'polished';

export default styled.li`
  margin: 5px 0;
  .regional-blocking-option {
    &-content {
      display: flex;
      align-items: center;
    }

    &-info {
      padding: ${`${rem(12)} ${rem(5)}`};
      justify-content: flex-start;
      text-align: left;

      &:hover,
      &:focus,
      &:active {
        color: ${colors.baseFontColor};

        .button__icon {
          color: ${colors.guardsmanRed};
        }
      }
    }

    &-switch {
      margin-left: auto;
      /* // provide space between option-text and switch */
      padding-left: 10px;
    }

    &-countries-list {
      list-style: none;
      margin: 0 0 30px 0;
      padding: 0;
      columns: 100px 3;

      &-item {
        margin-bottom: 6px;
        font-size: ${rem(14)};
        line-height: 1.2;
      }
    }

    &__country-modal {
      .modal-card {
        max-width: 650px;
      }
    }
  }
`;
