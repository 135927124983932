const KeyNumbersData = {
  privateAccounts: [
    {
      id: '1',
      selected: true,
    },
    {
      id: '2',
      selected: true,
    },
    {
      id: '3',
      selected: true,
    },
  ],
  sharedAccounts: [
    {
      id: '8',
      selected: false,
    },
    {
      id: '9',
      selected: false,
    },
  ],
  creditAccounts: [
    // {
    //   id: '5',
    //   selected: false,
    // },
  ],
  foundAccounts: [],
  dueDateRegister: {
    filter: 'DISPLAY_NEXT_SEVEN_DAYS',
    hidePrivateTranfers: true,
  },
  creditCardDisplayFilter: 'DISPLAY_AVAILABLE_AMOUNT',
  firstCustomKeyNumberAlias: 'CREDIT',
  secondCustomKeyNumberAlias: '',
};

export default KeyNumbersData;
