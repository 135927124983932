import React from 'react';
import PropTypes from 'prop-types';
// import Twemoji from 'react-twemoji';

import {
  Apple,
  Box,
  Car,
  Cutlery,
  Heart,
  House,
  ShoppingCart,
  Education,
  Vacation,
  Insurance,
  Media,
  // Suitcase,
  // Sun,
} from './Icons';

const CategoryIcon = ({ categoryAlias, ...other }) => {
  let Icon = null;
  if (categoryAlias === 'Hjem og bolig') {
    Icon = House;
  } else if (categoryAlias === 'Mat og drikke') {
    Icon = Cutlery;
  } else if (categoryAlias === 'Bil og transport') {
    Icon = Car;
  } else if (categoryAlias === 'Levekostnader') {
    Icon = ShoppingCart;
  } else if (categoryAlias === 'Diverse') {
    Icon = Box;
  } else if (categoryAlias === 'Treningssenter') {
    Icon = Heart;
  } else if (categoryAlias === 'Mat og drikke') {
    Icon = Apple;
  } else if (categoryAlias === 'Utdanning') {
    Icon = Education;
  } else if (categoryAlias === 'Ferie og reise') {
    Icon = Vacation;
  } else if (categoryAlias === 'Forsikring') {
    Icon = Insurance;
  } else if (categoryAlias === 'Medier og kommunikasjon') {
    Icon = Media;
  } else {
    Icon = Box;
  }
  return <Icon {...other} />;
};

CategoryIcon.propTypes = {
  categoryAlias: PropTypes.string.isRequired,
};

// const CategoryTwemoji = ({ categoryAlias, ...other }) => {
//   let emoji = '';
//   if (categoryAlias === 'Hjem og bolig') {
//     emoji = '🏠';
//   } else if (categoryAlias === 'Mat og drikke') {
//     emoji = '🍎';
//   } else if (categoryAlias === 'Bil og transport') {
//     emoji = '🚗';
//   } else {
//     emoji = '🛒';
//   }
//   // return <span>{emoji}</span>;
//   return (
//     <Twemoji
//       options={{
//         ext: '.svg',
//         base: 'https://twemoji.maxcdn.com/2/',
//         size: 'svg',
//       }}
//       {...other}
//     >
//       {emoji}
//     </Twemoji>
//   );
// };

// CategoryTwemoji.propTypes = CategoryIcon.propTypes;

export { CategoryIcon };

export default CategoryIcon;
