import styled from 'styled-components';
import { breakpoints, spacing, feedbackColors } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { formMaxWidthValue } from 'digitalbank-core-style/lib/sizes';
import { rem } from 'polished';

export default styled.div`
  /* Page used in both efaktura/opprettt og efaktura/endre */

  .p-betalingsavtale {
    &__main {
      padding-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__heading,
    .nav-list {
      ${horizontalLayoutSpacing};
    }

    &__top-button-group {
      ${horizontalLayoutSpacing};
      margin-top: ${rem(spacing.layoutSpacing.xs)};

      @media (max-width: ${breakpoints.tablet - 1}px) {
        display: flex;
        justify-content: center;
      }
    }

    .betalingsavtale-form {
      margin-bottom: ${rem(30)};
    }

    .betalingsavtale-form,
    .action-list {
      ${horizontalLayoutSpacing};
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${rem(formMaxWidthValue)};
      }
    }

    &__complete-btn {
      margin-top: ${rem(20)};
      width: 100%;
    }

    &__detail-section {
      ${horizontalLayoutSpacing};
      margin-bottom: ${rem(30)};

      &--no-indent {
        margin-bottom: ${rem(30)};
      }

      &__heading {
        ${horizontalLayoutSpacing};
        font-weight: 400;
        font-size: 1.1em;
        margin-bottom: ${rem(5)};
      }
    }

    &__delete-alert {
      ${horizontalLayoutSpacing({ type: 'margin' })};
    }

    &__avtale-slettet-alert {
      ${horizontalLayoutSpacing({ type: 'margin' })};

      &__heading {
        color: ${feedbackColors.info.icon};
      }
    }

    &__avtale-stoppet-alert {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__publisert-innhold {
      &-hoyre,
      &-under {
        .published-content {
          margin-bottom: ${rem(spacing.layoutSpacing.sm)};
          @media (min-width: ${breakpoints.tablet}px) {
            max-width: ${rem(formMaxWidthValue)};
          }
        }
      }
    }
  }
`;
