import styled from 'styled-components';
import {
  breakpoints,
  spacing,
  colors,
  typography,
} from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

const triangleBorderWidthValue = 14;
const triangleBorderWidthBefore = `${triangleBorderWidthValue + 1}px`;
const triangleBorderWidthAfter = `${triangleBorderWidthValue}px`;
const triangleBackgroundColor = '#fff';
const triangleBorderColor = colors.alto;
const triangleTopPositionValue = 20;

export default styled.div`
  .p-bankid-mobile-order {
    &__heading,
    &__main {
      ${horizontalLayoutSpacing};
    }

    &__description {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__info-alert {
      margin-bottom: ${rem(spacing.layoutSpacing.xs)};
    }

    &__info {
      color: ${colors.doveGray};
      font-style: italic;
    }

    &__detail-section,
    &__open-edit-btn {
      ${horizontalLayoutSpacing({ type: 'margin', tablet: false })};
    }

    &__detail-section {
      margin-bottom: ${rem(spacing.layoutSpacing.xs)};
    }

    &__open-edit-btn {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__submit-btn {
      margin-bottom: ${rem(spacing.layoutSpacing.xs)};
      @media (max-width: ${breakpoints.tablet}px) {
        width: 100%;
      }
      @media (min-width: ${breakpoints.tablet}px) {
        margin-right: 20px;
        margin-bottom: 0;
      }
    }

    &__confirmation {
      ${horizontalLayoutSpacing({ type: 'margin', tablet: false })};
      padding-top: ${rem(spacing.layoutSpacing.sm)};

      ul {
        margin: 0 0 ${rem(spacing.layoutSpacing.sm)};
      }

      li {
        margin-bottom: 10px;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &__mobile-test {
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
    }

    &__mobile-field {
      .input-group {
        align-items: center;
      }

      .input-group-addon {
        display: flex;
        height: 100%;
      }

      .status-indicator {
        margin-left: 30px;
      }

      .button {
        padding: 0 rem(16);
        margin-left: 5px;
        @media (min-width: 375px) {
          padding: 0 rem(30);
          margin-left: 10px;
        }
      }
    }

    &__activation-tooltip {
      background-color: ${triangleBackgroundColor};
      border: 1px solid ${triangleBorderColor};
      padding: ${rem(14)};
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
      }

      &::before {
        top: ${triangleTopPositionValue - 1}px;
        margin-left: -${triangleBorderWidthBefore};
        border-top: ${triangleBorderWidthBefore} solid transparent;
        border-bottom: ${triangleBorderWidthBefore} solid transparent;
        border-right: ${triangleBorderWidthBefore} solid ${triangleBorderColor};
      }

      &::after {
        top: ${triangleTopPositionValue}px;
        margin-left: -${triangleBorderWidthAfter};
        border-top: ${triangleBorderWidthAfter} solid transparent;
        border-bottom: ${triangleBorderWidthAfter} solid transparent;
        border-right: ${triangleBorderWidthAfter} solid
          ${triangleBackgroundColor};
      }
    }

    &__activation-container {
      display: flex;
      align-items: start;
    }

    &__activation-text {
      margin-bottom: 5px;
    }

    &__activation-number {
      font-family: ${typography.fontFamily.secondary};
      font-size: rem(24);
      letter-spacing: rem(8);
      margin-bottom: 10px;

      @media (min-width: ${breakpoints.tablet}px) {
        font-size: rem(30);
      }
    }

    &__activation-description {
      margin-bottom: 0;
    }

    &__mobile-spinner {
      margin-right: 24px;
    }
  }
`;
