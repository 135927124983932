import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import dataReducer from 'redux-helpers/data.reducer';
import search from 'routes/Search/search.reducer';
import accountPickersReducer from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.reducer';
import editBetaleEn from 'routes/Payment/EditBetaleEn/editBetaleEn.reducer';
import editFastBetaling from 'routes/Payment/EditFastBetaling/editFastBetaling.reducer';
import editEnOverfoering from 'routes/Transfer/EditEnOverfoering/editEnOverfoering.reducer';
import editFastOverfoering from 'routes/Transfer/EditFastOverfoering/editFastOverfoering.reducer';
import forfallsmappeReducer from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/forfallsmappe.reducer';
import betalingsavtalerReducer from 'digitalbank-betaling-web/lib/es/features/betalingsavtaler/betalingsavtaler.reducer';
import {
  eFakturaOneClickReducer,
  EFAKTURA_ONE_CLICK,
} from 'digitalbank-efaktura-oneclick';
import auth from './auth';
import visibilityFilter from './visibilityFilter';
import displaySidebar from './displaySidebar';
import contactInfo from './contactInfo';
import periodicControlRegionalBlocking from './periodicControlRegionalBlocking';
import windowProperties from './windowProperties';
import personalDataConsent from './personalDataConsent';
import keyNumbers from '../components/KeyNumbers/KeyNumbers.Reducer';
import context from './context';
import locale from './locale';
import selectedAccounts from './selectedAccounts';
import user from './user.reducer';
import accounts from './accounts.reducer';
import editAccountNamesStatus from './editAccountNamesStatus.reducer';
import messages from './messages.reducer';
import title from './title.reducer';
import economy from './economy.reducer';
import tag from './tag.reducer';

const rootReducer = combineReducers({
  [EFAKTURA_ONE_CLICK]: eFakturaOneClickReducer,
  editBetaleEn,
  editFastBetaling,
  editEnOverfoering,
  editFastOverfoering,
  forfallsmappe: forfallsmappeReducer,
  betalingsavtaler: betalingsavtalerReducer,
  title,
  auth,
  windowProperties,
  visibilityFilter,
  displaySidebar,
  contactInfo,
  periodicControlRegionalBlocking,
  personalDataConsent,
  keyNumbers,
  context,
  locale,
  accounts,
  selectedAccounts,
  user,
  editAccountNamesStatus,
  search,
  messages,
  accountPickers: accountPickersReducer,
  data: dataReducer,
  form: formReducer.plugin({}),
  economy,
  tags: tag,
});

export default rootReducer;
