import { getAccounts } from 'services/account.service';
import _ from 'lodash';

const initialState = getAccounts();

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case 'ACCOUNTS/SET_DISPLAY_NAME': {
      const indexOfAccount = _.findIndex(state, x => x.id === action.accountId);
      const newUpdatedAccount = {
        ...state[indexOfAccount],
        visningsnavn: action.value,
      };
      return [
        ...state.slice(0, indexOfAccount),
        newUpdatedAccount,
        ...state.slice(indexOfAccount + 1),
      ];
    }
    default:
      return state;
  }
};
