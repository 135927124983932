import styled from 'styled-components';
import { breakpoints, spacing, colors } from 'digitalbank-core-style';
import { rem } from 'polished';

export default styled.div`
  .regional-blocking-map-container {
    margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    $border-color: ${colors.silver};
    padding: 10px;
    background: #fff;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    @media (min-width: ${breakpoints.tablet}px) {
      display: flex;
      align-items: flex-start;
      flex-flow: row;
      padding: 20px;
    }

    &__element {
      @include disable-highlighting;
      fill: #ccc;

      &--selected {
        fill: ${colors.oliveDrab};
      }

      &--clickable {
        cursor: pointer;
      }
    }

    &__map-list {
      list-style: none;
      padding: 0;
      margin: 0;

      @media (max-width: ${breakpoints.tablet - 1}px) {
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid ${colors.gallery};
      }

      @media (min-width: ${breakpoints.tablet}px) {
        width: 45%;
      }
    }

    &__map-info {
      background-color: #fff;
      padding: 20px;
      align-self: center;
      display: flex;

      .regional-blocking-map-container__svg {
        max-width: 500px;
        max-height: 300px;
      }

      &--is-editable {
        @media (min-width: ${breakpoints.tablet}px) {
          width: 45%;
        }
      }

      &--is-not-editable {
        flex-direction: column;
        width: 100%;

        @media (max-width: ${breakpoints.tablet - 1}px) {
          align-items: center;

          .regional-blocking-map-container__svg {
            max-width: 240px;
          }
        }

        @media (min-width: ${breakpoints.tablet}px) {
          flex-direction: row-reverse;
          justify-content: space-between;

          .regional-blocking-map-container__svg {
            width: 60%;
          }
        }

        .regional-blocking-open-locations {
          margin-bottom: ${rem(spacing.layoutSpacing.sm)};

          @media (min-width: ${breakpoints.tablet}px) {
            text-align: left;
          }
        }
      }
    }
  }
`;
