import React from 'react';
import DetailSection from 'digitalbank-detail-section';
import PropTypes from 'prop-types';

const getItemsToFirstSection = (tag) => {
  const items = [
    { label: 'Navn', value: 'Hanne Opedal' },
    { label: 'Start', value: '24.01.2018' },
    { label: 'Slutt', value: '13.02.2018' },
  ];

  if (tag.diet) {
    items.push({
      label: 'Reiser på diett',
      value: 'Ja',
    });
    if (tag.country) {
      items.push({
        label: 'Destinasjon',
        value: tag.country,
      });
    }
  }

  return items;
};

const TagDetails = ({ tag }) => (
  <DetailSection items={getItemsToFirstSection(tag)} />
);

TagDetails.propTypes = {
  tag: PropTypes.shape({}).isRequired,
};

export default TagDetails;
