import React from 'react';
import { BasePage } from 'digitalbank-layout';
import EfakturaFraAlleFaq from './EfakturaFraAlleFaq';
import EfakturaFraAlleStyled from './EfakturaFraAlleStyled';

const EfakturaFraAlleInfoPage = () => (
  <EfakturaFraAlleStyled>
    <BasePage
      heading="eFaktura fra alle"
      className="p-efaktura-fra-alle-info"
      aside={<EfakturaFraAlleFaq />}
    >
      <article
        className="p-efaktura-fra-alle-info__article"
        style={{ marginBottom: '80px' }}
      >
        <p className="short-description">
          Med eFaktura fra alle mottar du regninger automatisk i nettbanken fra
          godkjente fakturaavsendere. Da slipper du å inngå avtale for hver
          enkelt fakturaavsender. Regningene må fortsatt godkjennes av deg.
        </p>
        <p>
          Du kan fortsatt legge inn avtaler manuelt, f.eks. for regninger som er
          registrert i en annens navn.
        </p>
      </article>
    </BasePage>
  </EfakturaFraAlleStyled>
);

EfakturaFraAlleInfoPage.propTypes = {};

export default EfakturaFraAlleInfoPage;
