import styled from 'styled-components';
import { breakpoints, layout, sizes, colors } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-contact-info-periodic {
    margin: ${rem(layout.topSpacing.phone)} auto 0;
    ${horizontalLayoutSpacing};

    @media (min-width: ${breakpoints.tablet}px) {
      max-width: ${rem(sizes.formMaxWidthValue)};
      margin: ${rem(layout.topSpacing.tablet)} auto 0;
    }

    &__container {
      display: flex;
      flex-direction: column;
      max-width: ${rem(layout.maxWidth)};
    }

    &__main {
      width: 100%;
    }

    &__bottom-text {
      margin: ${rem(15)} ${rem(30)} 0;
      text-align: center;
      font-size: ${rem(13)};
      color: ${colors.boulder};
    }

    &__save-button {
      margin-top: ${rem(20)};
      width: 100%;
    }

    .periodic-control-error {
      .modal__backdrop {
        cursor: default;
      }
    }
  }
`;
