import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonGroup from 'digitalbank-button-group';
import AccountSelectionContainer from 'containers/AccountSelectionContainer';
import MySettingsContainer from 'containers/MySettings/MySettingsContainer';
import EditAccountNamesListContainer from 'containers/EditAccountNamesListContainer';
import { filterActiveAccounts } from 'utils/account.util';
import _ from 'lodash';

const HomeSettingsFilters = {
  MIN_OVERSIKT: 'min-oversikt',
  INNSTILLINGER: 'innstillinger',
  KONTONAVN: 'kontonavn',
};

class HomeSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionFilter: this.props.minOversiktSectionIsEnabled
        ? HomeSettingsFilters.MIN_OVERSIKT
        : HomeSettingsFilters.INNSTILLINGER,
    };
    this.setSectionFilter = this.setSectionFilter.bind(this);
  }
  setSectionFilter({ id }) {
    this.setState({
      ...this.state,
      sectionFilter: id,
    });
  }
  render() {
    const { sectionFilter } = this.state;
    const { selectedAccounts, minOversiktSectionIsEnabled } = this.props;
    const displayMinOversiktSection =
      sectionFilter === HomeSettingsFilters.MIN_OVERSIKT;
    const displayInnstillingerSection =
      sectionFilter === HomeSettingsFilters.INNSTILLINGER;
    const displayKontonavnSection =
      sectionFilter === HomeSettingsFilters.KONTONAVN;
    const activeAccounts = filterActiveAccounts(this.props.accounts);
    const activeAccountsWithOrderIndex = activeAccounts.map(account => ({
      ...account,
      orderIndex: selectedAccounts.filter(x => x.accountId === account.id)[0]
        .orderIndex,
    }));
    const orderedAccounts = _.sortBy(
      activeAccountsWithOrderIndex,
      'orderIndex',
    );
    let items = [
      {
        id: HomeSettingsFilters.INNSTILLINGER,
        text: 'Innstillinger',
        onClick: this.setSectionFilter,
        active: displayInnstillingerSection,
      },
      {
        id: HomeSettingsFilters.KONTONAVN,
        text: 'Kontonavn',
        onClick: this.setSectionFilter,
        active: displayKontonavnSection,
      },
    ];

    if (minOversiktSectionIsEnabled) {
      items = [
        {
          id: HomeSettingsFilters.MIN_OVERSIKT,
          text: 'Min oversikt',
          onClick: this.setSectionFilter,
          active: displayMinOversiktSection,
        },
        ...items,
      ];
    }

    return (
      <div className="customer-settings__edit">
        <ButtonGroup
          className="customer-settings__edit-button-group"
          items={items}
        />
        {minOversiktSectionIsEnabled &&
          displayMinOversiktSection && (
            <AccountSelectionContainer
              accounts={activeAccounts}
              selectedAccounts={this.props.selectedAccounts}
            />
          )}
        {displayInnstillingerSection && <MySettingsContainer />}
        {displayKontonavnSection && (
          <EditAccountNamesListContainer accounts={orderedAccounts} />
        )}
      </div>
    );
  }
}

HomeSettings.defaultProps = {
  minOversiktSectionIsEnabled: false,
};

HomeSettings.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  minOversiktSectionIsEnabled: PropTypes.bool,
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  selectedAccounts: state.selectedAccounts,
});

export default connect(mapStateToProps, null)(HomeSettings);
