import React from 'react';
import PropTypes from 'prop-types';
import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { RegionBlockingMap } from 'components';
import RegionalBlockingOpenLocations from 'components/RegionalBlockingOpenLocations';
import SettingsCard from './SettingsCard';

const SettingsCardForRegionalBlocking = ({ openLocations }) => (
  <SettingsCard
    heading="Regionsperre"
    className="settings-card-regional-blocking"
    description={
      <p>
        Unngå at kortene dine brukes i land du ikke oppholder deg i, for å unngå
        kortsvindel. Les mer
      </p>
    }
    aside={
      <RegionBlockingMap
        openLocationIds={openLocations}
        className="settings-card__regional-blocking-map"
      />
    }
  >
    <div className="settings-card__container">
      <RegionBlockingMap
        openLocationIds={openLocations}
        className="settings-card__regional-blocking-map"
      />
      <RegionalBlockingOpenLocations openLocationIds={openLocations} />
    </div>
    <NavList
      items={[
        <NavListLink
          key="regionspere"
          title={{ text: 'Endre regionsperre' }}
          component={Link}
          to={PATHS.REGIONAL_BLOCKING}
          variant={TASK}
        />,
      ]}
    />
  </SettingsCard>
);

SettingsCardForRegionalBlocking.defaultProps = {
  openLocations: null,
};

SettingsCardForRegionalBlocking.propTypes = {
  openLocations: PropTypes.arrayOf(PropTypes.number),
};

export default SettingsCardForRegionalBlocking;
