/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Button from 'digitalbank-buttons';
import { BasePage } from 'digitalbank-layout';
import _ from 'lodash';
import NavList, { NavListTask, TASK, NEGATIVE } from 'digitalbank-nav-list';
import betalingsavtaler from 'data/betalingsavtaler';
import {
  removeSperretEfakturaAvsender,
  addSperretEfakturaAvsender,
} from './efakturaFraAlle.actions';

const getSperredeAvsendere = (sperredeAvsendereIds, avsendere) => {
  let ret = [];
  sperredeAvsendereIds.forEach((avsenderId) => {
    const avsender = _.find(avsendere, x => x.tilbyderId === avsenderId);
    if (avsender) {
      ret = [...ret, avsender];
    } else {
      console.warn(`Avsender with ${avsenderId} does not exist`);
    }
  });
  return ret;
};

class EfakturaFraAlleSperredeAvsenderePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tilbydereOpened: [],
    };
    this.sperredeAvsendere = getSperredeAvsendere(
      this.props.sperredeAvsendere,
      betalingsavtaler,
    );
    this.openTilbyder = this.openTilbyder.bind(this);
    this.closeTilbyder = this.closeTilbyder.bind(this);
    this.tilbyderIsOpen = this.tilbyderIsOpen.bind(this);
  }
  openTilbyder(id) {
    this.setState({
      tilbydereOpened: [...this.state.tilbydereOpened, id],
    });
    this.props.onRemoveSperretEfakturaAvsender(id);
  }
  closeTilbyder(id) {
    const oldArray = this.state.tilbydereOpened;
    const index = oldArray.indexOf(id);
    this.setState({
      tilbydereOpened: [
        ...oldArray.slice(0, index),
        ...oldArray.slice(index + 1),
      ],
    });
    this.props.onAddSperretEfakturaAvsender(id);
  }
  tilbyderIsOpen(id) {
    return this.state.tilbydereOpened.indexOf(id) > -1;
  }
  render() {
    const items = this.sperredeAvsendere.map((tilbyder) => {
      if (this.tilbyderIsOpen(tilbyder.tilbyderId)) {
        return (
          <NavListTask
            variant={TASK}
            key={tilbyder.mottakernavn.split(' ').join()}
            title={{ text: tilbyder.mottakernavn }}
            description={{
              text: (
                <span>
                  Sperren er fjernet.{' '}
                  <Button
                    variant="link"
                    onClick={() => this.closeTilbyder(tilbyder.tilbyderId)}
                  >
                    Angre
                  </Button>
                </span>
              ),
            }}
            isDeleted
          />
        );
      }
      return (
        <NavListTask
          variant={TASK}
          key={tilbyder.mottakernavn.split(' ').join()}
          title={{ text: tilbyder.mottakernavn }}
          description={{ text: 'Sperret', variant: NEGATIVE }}
          aside={
            <Button
              size="small"
              variant="edit"
              onClick={() => this.openTilbyder(tilbyder.tilbyderId)}
            >
              Fjern sperre
            </Button>
          }
        />
      );
    });
    return (
      <BasePage
        heading="Sperrede avsendere"
        className="p-efaktura-fra-alle-sperrede-avsendere"
      >
        {this.sperredeAvsendere.length === 0 && (
          <div className="p-efaktura-fra-alle-sperrede-avsendere__info-card">
            Ingen sperrede eFaktura-avsendere
          </div>
        )}
        <NavList items={items} />
        <article>
          <h3>Slik sperre du eFaktura-avsendere</h3>
          <p>
            Hvis du mottar eFaktura fra en uønsket avsender, kan du sperre
            avsenderen:
          </p>
          <ol>
            <li>
              Åpne eFaktura-detaljene ved å trykke knappen med blyantsymbol
            </li>
            <li>Velg Sperr avsender nederst i skjemaet</li>
            <li>Trykk bekreft, og avsenderen er sperret</li>
          </ol>
        </article>
      </BasePage>
    );
  }
}

EfakturaFraAlleSperredeAvsenderePage.propTypes = {
  onRemoveSperretEfakturaAvsender: PropTypes.func.isRequired,
  onAddSperretEfakturaAvsender: PropTypes.func.isRequired,
  sperredeAvsendere: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  sperredeAvsendere: state.user.sperredeAvsendere,
});

const mapDispatchToProps = dispatch => ({
  onRemoveSperretEfakturaAvsender: id =>
    dispatch(removeSperretEfakturaAvsender(id)),
  onAddSperretEfakturaAvsender: id => dispatch(addSperretEfakturaAvsender(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  EfakturaFraAlleSperredeAvsenderePage,
);
