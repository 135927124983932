import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormError } from 'digitalbank-form';
import { showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb } from 'digitalbank-form/lib/es/utils/showErrorCallbacks.util';

const RadioButtonGroupField = (props) => {
  const {
    options, meta, input, className, showErrorCallback,
  } = props;
  return (
    <div>
      <div
        className={classNames('radio-btn-group', className)}
        role="radiogroup"
      >
        {options.map(option => (
          <div
            key={`btn-group-item-${option.value}`}
            className="radio-btn-group__item"
          >
            <input
              {...input}
              id={option.value}
              value={option.value}
              checked={input.value === option.value}
              aria-checked={input.value === option.value}
              aria-label={option.ariaLabel}
              type="radio"
              onBlur={null}
              className="radio-btn-group__input"
            />
            <label
              id={`label-${option.value}`}
              htmlFor={option.value}
              title={option.label}
              className={classNames('radio-btn-group__label', {
                'radio-btn-group__label--active': input.value === option.value,
              })}
            >
              {option.label}
            </label>
          </div>
        ))}
        <FormError
          meta={meta}
          showErrorCallback={
            showErrorCallback ||
            showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb
          }
        />
      </div>
    </div>
  );
};

RadioButtonGroupField.defaultProps = {
  className: null,
  showErrorCallback: null,
};

RadioButtonGroupField.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  meta: PropTypes.shape({}).isRequired,
  input: PropTypes.shape({}).isRequired,
  showErrorCallback: PropTypes.func,
};

export default RadioButtonGroupField;
