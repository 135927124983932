import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, getFormValues, isPristine } from 'redux-form';
import dataSelector from 'redux-helpers/dataSelector';
import { change } from 'redux-helpers/data.actions';
import { Link } from 'react-router-dom';
import i18n from 'i18n';
import fx from 'money';
import accounting from 'accounting';
import { ChevronDown } from 'digitalbank-icons';
import Collapse from 'react-css-collapse';
import Button from 'digitalbank-buttons';
import * as PATHS from 'routes/paths';
import { addTransaction } from 'services/transactions.service';
import { TYPE } from 'digitalbank-mockup-data/lib/Transactions';
import { formatAccountNumber } from 'utils/format.util';
import wizardPage from 'components/wizardPage';
import historyShape from 'shapes/history.shape';
import {
  getNumber,
  getCurrencyRate,
  getBankBasedOnAccountNumber,
  getCountry,
  getExpensesArePaidByDisplayValue,
  getIbanDisplayFilter,
  getToAccountLabel,
} from '../paymentGlobal.utils';
import { formValuesShape } from '../paymentGlobal.shapes';
import fixer from '../fixer';
import PaymentGlobalStyled from '../PaymentGlobalStyled';

const KeyValueRow = ({ heading, data, style }) => (
  <li className="key-value-list__row" style={style}>
    <h2 className="key-value-list__key">{heading}</h2>
    <div className="key-value-list__value">{data}</div>
  </li>
);

KeyValueRow.defaultProps = {
  style: undefined,
};

KeyValueRow.propTypes = {
  heading: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

class Summary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fxIsReady: true,
      displayDetails: false,
    };
    fx.rates = { ...fixer.rates, ...{ EUR: 1 } };
    // window.fetch('https://api.fixer.io/latest', {
    //   method: 'get',
    // }).then(response => response.json())
    // .then((data) => {
    //   fx.rates = Object.assign(data.rates, { EUR: 1 });
    //   this.setState({
    //     ...this.state,
    //     fxIsReady: true,
    //   });
    // }).catch((err) => {
    //   console.log(err); // eslint-disable-line
    // });
    this.toggleDetails = this.toggleDetails.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  toggleDetails() {
    this.setState({
      ...this.state,
      displayDetails: !this.state.displayDetails,
    });
  }
  handleSubmit(estimatedAmount) {
    addTransaction(
      'transactions',
      this.props.transactions,
      this.props.changeData,
      {
        ...this.props.formValues,
        type: TYPE.UTLANDSBETALING,
      },
      estimatedAmount,
    );
    this.props.history.push(PATHS.PAYMENT_GLOBAL_REAUTH);
  }
  render() {
    const { fxIsReady } = this.state;
    const {
      fraKonto,
      tilKonto,
      kroner,
      oere,
      paymentCurrency,
      country,
      recipientName,
      message,
      dato,
      recipientAddress1,
      recipientAddress2,
      recipientAddress3,
      bankCode,
      extraBankCode,
      expensesArePaidBy,
      expressPayment,
      paymentPurpose,
      paymentType,
      sendEmailConfirmationToReceiver,
      receiverEmail,
      agreedRateWithBank,
      rateWithBank,
      contactPerson,
    } = this.props.formValues;
    const bank = getBankBasedOnAccountNumber(tilKonto);
    const accountCurrency = fraKonto.currency;
    const amount = getNumber(kroner, oere);
    const selectedCurrency = paymentCurrency !== '' ? paymentCurrency : 'NOK';
    const amountInAccountCurrency =
      amount > 0 && fxIsReady
        ? fx.convert(amount, { from: selectedCurrency, to: accountCurrency })
        : 0;
    const formattedAmountInAccountCurrency =
      amountInAccountCurrency > 0 && fxIsReady
        ? accounting.formatNumber(amountInAccountCurrency, 2, ' ', ',')
        : 0;
    const selectedCurrencyRate = fxIsReady
      ? getCurrencyRate(selectedCurrency, accountCurrency, fx, accounting)
      : 0;
    const ibanDisplayFilter = getIbanDisplayFilter(country);
    const recipientCountry = getCountry(country);
    const bankCountry = bank && bank.country ? getCountry(bank.country) : null;
    const estimatedAmount = {
      verdi: amountInAccountCurrency * -1,
      valutakode: accountCurrency,
    };
    return (
      <PaymentGlobalStyled>
        <div className="payment-global-form">
          <div className="p-payment-global__summary">
            <div>
              <h3 className="p-payment-global__summary-heading">
                <span className="p-payment-global__summary-heading-element">
                  {recipientName}
                </span>
                <span className="p-payment-global__summary-heading-element">
                  {amount} {selectedCurrency}
                </span>
              </h3>
              <p className="p-payment-global__summary-sub-heading">
                <span className="p-payment-global__summary-sub-heading-element">
                  {i18n(t => t.PAYMENT_GLOBAL_ESTIMATED_AMOUNT)}
                  {` (${i18n(t => t.EXCHANGE).toLowerCase()}`}
                  {` ${selectedCurrencyRate})`}
                </span>
                <span className="p-payment-global__summary-sub-heading-element">
                  {formattedAmountInAccountCurrency} {accountCurrency}
                </span>
              </p>
            </div>
            <ul className="key-value-list">
              {dato && (
                <KeyValueRow
                  heading={i18n(t => t.PAYMENT_GLOBAL_LABEL_DATE)}
                  data={dato}
                />
              )}
              {fraKonto && (
                <KeyValueRow
                  heading={i18n(t => t.FROM)}
                  data={`${fraKonto.visningsnavn ||
                    fraKonto.accountName} - ${formatAccountNumber(
                    fraKonto.accountNumber,
                  )}`}
                />
              )}
              {tilKonto && (
                <KeyValueRow
                  heading={i18n(t => t.TO)}
                  data={formatAccountNumber(tilKonto)}
                />
              )}
              {message && (
                <KeyValueRow heading={i18n(t => t.MESSAGE)} data={message} />
              )}
            </ul>
          </div>
          <Button
            className="p-payment-global__summary-details-button"
            variant="collapse"
            justifyContent="space-between"
            rightIcon={<ChevronDown />}
            onClick={this.toggleDetails}
          >
            {!this.state.displayDetails && (
              <span>{i18n(t => t.DISPLAY_DETAILS)}</span>
            )}
            {this.state.displayDetails && (
              <span>{i18n(t => t.HIDE_DETAILS)}</span>
            )}
          </Button>

          <Collapse
            isOpen={this.state.displayDetails}
            className="input-help-animation"
          >
            <div className="p-payment-global__details">
              <div className="p-payment-global__detail-group">
                <h3 className="p-payment-global__details-header">
                  {i18n(t => t.RECIPIENT)}
                </h3>
                <ul className="key-value-list" style={{ marginBottom: '10px' }}>
                  {recipientAddress1 && (
                    <KeyValueRow
                      heading={i18n(t => t.ADDRESS)}
                      data={recipientAddress1}
                    />
                  )}
                  {recipientAddress2 && (
                    <KeyValueRow heading="" data={recipientAddress2} />
                  )}
                  {recipientAddress3 && (
                    <KeyValueRow heading="" data={recipientAddress3} />
                  )}
                  {recipientCountry && (
                    <KeyValueRow heading="" data={recipientCountry.country} />
                  )}
                </ul>
                <ul className="key-value-list">
                  {tilKonto && (
                    <KeyValueRow
                      heading={getToAccountLabel(ibanDisplayFilter, i18n)}
                      data={tilKonto}
                    />
                  )}
                  {bankCode && (
                    <KeyValueRow heading="SWIFT/BIC" data={bankCode} />
                  )}
                  {extraBankCode && (
                    <KeyValueRow
                      heading={i18n(t => t.PAYMENT_GLOBAL_LABEL_BANK_CODE)}
                      data={extraBankCode}
                    />
                  )}
                  {bank && (
                    <KeyValueRow
                      heading={i18n(t => t.PAYENT_GLOBAL_LEGEND_RECIPIENT_BANK)}
                      data={bank.name}
                    />
                  )}
                  {bank && (
                    <KeyValueRow heading="" data={bankCountry.country} />
                  )}
                </ul>
              </div>
              <div className="p-payment-global__detail-group">
                <h3 className="p-payment-global__details-header">
                  {i18n(t => t.PAYMENT_GLOBAL_LEGEND_INFO)}
                </h3>
                <ul className="key-value-list">
                  {expensesArePaidBy && (
                    <KeyValueRow
                      heading={i18n(t => t.FEES)}
                      data={getExpensesArePaidByDisplayValue(
                        expensesArePaidBy,
                        i18n,
                      )}
                    />
                  )}
                  <KeyValueRow
                    heading={i18n(t => t.PAYMENT_GLOBAL_LABEL_EXPRESS_PAYMENT)}
                    data={expressPayment ? i18n(t => t.YES) : i18n(t => t.NO)}
                  />
                  {paymentPurpose && (
                    <KeyValueRow
                      heading={i18n(
                        t => t.PAYMENT_GLOBAL_LABEL_PAYMENT_PURPOSE,
                      )}
                      data={paymentPurpose}
                    />
                  )}
                  {paymentType && (
                    <KeyValueRow
                      heading={i18n(t => t.PAYMENT_GLOBAL_LABEL_PAYMENT_TYPE)}
                      data={paymentType}
                    />
                  )}
                  {sendEmailConfirmationToReceiver &&
                    receiverEmail && (
                      <KeyValueRow
                        heading={i18n(t => t.CONFIRMATION)}
                        data={receiverEmail}
                      />
                    )}
                  {agreedRateWithBank &&
                    rateWithBank && (
                      <KeyValueRow
                        heading={i18n(
                          t => t.PAYMENT_GLOBAL_LABEL_AGREED_RATE_WITH_BANK,
                        )}
                        data={rateWithBank}
                      />
                    )}
                  {contactPerson && (
                    <KeyValueRow
                      heading={i18n(t => t.PAYMENT_GLOBAL_LABEL_CONTACT_PERSON)}
                      data={contactPerson}
                    />
                  )}
                </ul>
              </div>
            </div>
          </Collapse>
          <div className="payment-global-form__summary-actions">
            <Button
              variant="primary"
              className="payment-global-form__submit-button"
              onClick={() => this.handleSubmit(estimatedAmount)}
              type="button"
            >
              {i18n(t => t.BETAL)}
            </Button>
            <Button
              variant="cancel"
              className="payment-global-form__back-button"
              to={PATHS.PAYMENT_GLOBAL_SECOND}
              component={Link}
            >
              {i18n(x => x.BACK)}
            </Button>
          </div>
        </div>
      </PaymentGlobalStyled>
    );
  }
}

Summary.propTypes = {
  history: historyShape.isRequired,
  formValues: PropTypes.shape(formValuesShape).isRequired,
  changeData: PropTypes.func.isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
};

const validate = () => {
  const errors = {};
  return errors;
};

const form = reduxForm({
  destroyOnUnmount: false,
  form: 'globalPaymentForm',
  validate,
})(Summary);

const mapStateToProps = state => ({
  formValues: getFormValues('globalPaymentForm')(state),
  transactions: dataSelector(state, 'transactions'),
});

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
});

export default wizardPage({
  redirectTest: state => isPristine('globalPaymentForm')(state),
  redirectPath: PATHS.PAYMENT_GLOBAL,
})(connect(mapStateToProps, mapDispatchToProps)(form));
