import React from 'react';
import PropTypes from 'prop-types';
import { formatAccountNumber } from 'utils/format.util';
import i18n from 'i18n';
import moment from 'moment';
import { isKidNumber } from 'utils/validation.util';

const PaymentSummaryList = ({
  fromAccountNumber,
  fromAccountName,
  toAccountNumber,
  recipientName,
  kroner,
  oere,
  dueDate,
  kidOrMessage,
}) => {
  const formattedToAccountNumber = formatAccountNumber(toAccountNumber);
  const formattedFromAccountNumber = formatAccountNumber(fromAccountNumber);
  const heading = recipientName || formattedToAccountNumber;
  const amount = i18n(i => i.NUMBER_TWO_DIGITS, {
    number: Number(kroner) + Number(oere / 100),
  });
  const description =
    heading === formattedToAccountNumber ? null : formattedToAccountNumber;
  const melding = !isKidNumber(kidOrMessage) ? kidOrMessage : '';
  const kid = isKidNumber(kidOrMessage) ? kidOrMessage : '';

  return (
    <div className="payment-summary-list">
      <div className="payment-summary-list__item">
        <div className="payment-summary-list__group">
          <div className="payment-summary-list__heading">
            {heading}
            <br />
            <span className="payment-summary-list__account">{description}</span>
          </div>
          <div className="payment-summary-list__details">
            <div className="text-poster payment-summary-list__amount">
              {amount}
            </div>
          </div>
        </div>
      </div>
      <div className="payment-summary-list__item">
        <div className="payment-summary-item">
          <div className="payment-summary-item__label">{i18n(i => i.FRA)}</div>
          <div className="payment-summary-item__value">
            {`${fromAccountName} - ${formattedFromAccountNumber}`}
          </div>
        </div>
        <div className="payment-summary-item">
          <div className="payment-summary-item__label">
            {i18n(i => i.FORFALL)}
          </div>
          <div className="payment-summary-item__value">
            {moment(dueDate, 'L').format(i18n(i => i.DATE_FORMAT))}
          </div>
        </div>
        {kid && (
          <div className="payment-summary-item">
            <div className="payment-summary-item__label">KID</div>
            <div className="payment-summary-item__value">{kid}</div>
          </div>
        )}
        {melding && (
          <div className="payment-summary-item">
            <div className="payment-summary-item__label">Melding</div>
            <div className="payment-summary-item__value">{melding}</div>
          </div>
        )}
      </div>
    </div>
  );
};

PaymentSummaryList.propTypes = {
  fromAccountNumber: PropTypes.string.isRequired,
  fromAccountName: PropTypes.string.isRequired,
  toAccountNumber: PropTypes.string.isRequired,
  recipientName: PropTypes.string.isRequired,
  kroner: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  oere: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  dueDate: PropTypes.string.isRequired,
  kidOrMessage: PropTypes.string.isRequired,
};

export default PaymentSummaryList;
