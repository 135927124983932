import {
  getAvtalegiroAvtalerStart,
  getAvtalegiroAvtalerSuccessful,
  getEfakturaAvtalerStart,
  getEfakturaAvtalerSuccessful,
  getKombinertAvtalerStart,
  getKombinertAvtalerSuccessful,
  getAvtalegiroAvtalerForslagStart,
  getAvtalegiroAvtalerForslagSuccessful,
  getEfakturaAvtalerForslagStart,
  getEfakturaAvtalerForslagSuccessful,
  getKombinertAvtalerForslagStart,
  getKombinertAvtalerForslagSuccessful,
} from 'digitalbank-betaling-web/lib/es/features/betalingsavtaler/betalingsavtaler.actions';
import {
  getAvtalegiroavtaler as getAvtalegiroavtalerData,
  getEfakturaavtaler as getEfakturaavtalerData,
  getKombinertAvtaler as getKombinertAvtalerData,
  getAvtalegiroavtalerForslag as getAvtalegiroavtalerForslagData,
  getEfakturaavtalerForslag as getEfakturaavtalerForslagData,
  getKombinertAvtalerForslag as getKombinertAvtalerForslagData,
} from 'services/betalingsavtaler.service';
import { TOGGLE_AUTOMATIC_EFAKTURA } from './avtaleadministrasjon.constants';

export const getAvtalegiroavtaler = () => (dispatch) => {
  dispatch(getAvtalegiroAvtalerStart());
  return getAvtalegiroavtalerData().then(avtaler =>
    dispatch(getAvtalegiroAvtalerSuccessful(avtaler)),
  );
};

export const getEfakturaavtaler = () => (dispatch) => {
  dispatch(getEfakturaAvtalerStart());
  return getEfakturaavtalerData().then(avtaler =>
    dispatch(getEfakturaAvtalerSuccessful(avtaler)),
  );
};

export const getKombinertAvtaler = () => (dispatch) => {
  dispatch(getKombinertAvtalerStart());
  return getKombinertAvtalerData().then(avtaler =>
    dispatch(getKombinertAvtalerSuccessful(avtaler)),
  );
};

export const getAvtalegiroavtalerForslag = () => (dispatch) => {
  dispatch(getAvtalegiroAvtalerForslagStart());
  return getAvtalegiroavtalerForslagData().then(avtaler =>
    dispatch(getAvtalegiroAvtalerForslagSuccessful(avtaler)),
  );
};

export const getEfakturaavtalerForslag = () => (dispatch) => {
  dispatch(getEfakturaAvtalerForslagStart());
  return getEfakturaavtalerForslagData().then(avtaler =>
    dispatch(getEfakturaAvtalerForslagSuccessful(avtaler)),
  );
};

export const getKombinertAvtalerForslag = () => (dispatch) => {
  dispatch(getKombinertAvtalerForslagStart());
  return getKombinertAvtalerForslagData().then(avtaler =>
    dispatch(getKombinertAvtalerForslagSuccessful(avtaler)),
  );
};

const toggleAutomaticEfaktura = () => ({
  type: TOGGLE_AUTOMATIC_EFAKTURA,
});

export { toggleAutomaticEfaktura };
