/* eslint-disable no-script-url */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Fields, reduxForm, formValueSelector } from 'redux-form';
import { InputField } from 'digitalbank-form';
import Button from 'digitalbank-buttons';
import postalCodes from 'norway-postal-codes';
import PhoneField from 'digitalbank-form/lib/es/components/PhoneField/PhoneField';
import {
  all as allCountries,
  findCountries,
  findCountryPhoneCode,
} from 'spv-country-data';
import {
  asYouType as FormatAsYouType,
  format as formatNumber,
} from 'libphonenumber-js';

const EfakturaFraAlleKontaktinfoFormContainer = ({
  handleSubmit,
  onSubmitSuccess,
  postnummer,
  phoneCountryCode,
}) => {
  const location = postalCodes[postnummer];
  return (
    <form
      action="javascript:void(0);"
      onSubmit={handleSubmit(onSubmitSuccess)}
      className="efaktura-fra-alle-form"
    >
      <fieldset className="fieldset">
        <Field
          name="adresse"
          component={InputField}
          label="Adresse"
          autoComplete="address-line1"
        />
        <div className="contact-form__location">
          <Field
            name="postnummer"
            component={InputField}
            type="tel"
            size="10"
            hasAutoWidth
            label="Postnummer"
            autoComplete="postal-code"
            maxLength="4"
            rightAddon={() => (
              <div
                className="contact-form__location-name"
                aria-live="polite"
                role="status"
              >
                {location && location}
              </div>
            )}
          />
        </div>
        <Field
          name="epost"
          component={InputField}
          label="E-post"
          transformableLabel={false}
          placeholder="Skriv e-postadresse"
          autoComplete="email"
        />
      </fieldset>
      <fieldset className="fieldset">
        <Fields
          names={['phone', 'phoneCountryCode']}
          component={PhoneField}
          countriesShortList={findCountries('NO, GB, SE')}
          countries={allCountries}
          numberField={{
            name: 'phone',
            label: 'Mobilnummer',
            ariaLabel: 'Mobilnummer',
            normalize: value =>
              value && new FormatAsYouType(phoneCountryCode).input(value),
            autoComplete: 'tel-national',
          }}
          countryCodeField={{
            name: 'phoneCountryCode',
            label: 'Land',
            ariaLabel: 'Land',
            autoComplete: 'country-name',
            findCountryPhoneCode,
          }}
        />
      </fieldset>
      {/* <FakeProgressButton type="submit" /> */}

      <Button variant="primary" style={{ width: '100%' }} type="submit">
        Ferdig
      </Button>
    </form>
  );
};

EfakturaFraAlleKontaktinfoFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  postnummer: PropTypes.string,
  phoneCountryCode: PropTypes.string,
};

EfakturaFraAlleKontaktinfoFormContainer.defaultProps = {
  postnummer: undefined,
  phoneCountryCode: undefined,
};

const form = reduxForm({
  form: 'efakturaFraAlleKontaktinfo',
  destroyOnUnmount: false,
})(EfakturaFraAlleKontaktinfoFormContainer);

const mapStateToProps = (state) => {
  const selector = formValueSelector('efakturaFraAlleKontaktinfo');
  const { efakturaFraAlle } = state.user;
  return {
    postnummer: selector(state, 'postnummer'),
    phoneCountryCode: selector(state, 'phoneCountryCode'),
    initialValues: {
      adresse: efakturaFraAlle.address,
      postnummer: efakturaFraAlle.zipCode,
      phone: formatNumber(
        {
          country: efakturaFraAlle.phoneCountryCode,
          phone: efakturaFraAlle.phone,
        },
        'National',
      ),
      phoneCountryCode: efakturaFraAlle.phoneCountryCode,
      epost: efakturaFraAlle.email,
      mailNotification: efakturaFraAlle.mailNotification,
    },
  };
};

export default connect(mapStateToProps)(form);
