import React from 'react';
import PropTypes from 'prop-types';
import LegendWithSaveStatus from 'components/Form/LegendWithSaveStatus';

const forfallPresentationOptions = [
  {
    label: 'Vis disponibelt beløp',
    value: 'DISPONIBELT',
  },
  {
    label: 'Vis trukket kreditt',
    value: 'TRUKKET',
  },
];

const MySettingsKredittkortPresentationFieldset = ({
  kredittkortPresentation,
  updateFilter,
}) => {
  const numberOfExistingKredittkortPresentationSaveInstances =
    kredittkortPresentation.savingInstances.length;
  const kredittkortPresentationStatus =
    numberOfExistingKredittkortPresentationSaveInstances > 0
      ? kredittkortPresentation.savingInstances[
        numberOfExistingKredittkortPresentationSaveInstances - 1
      ]
      : '';

  return (
    <fieldset className="fieldset home-settings__presentation-fieldset">
      <LegendWithSaveStatus
        text="Kredittkort-visning:"
        status={kredittkortPresentationStatus}
      />
      <div className="form-group" role="radiogroup">
        {forfallPresentationOptions.map((option, i) => (
          <span key={`kredittkort-presentation-${option.value}`}>
            <input
              checked={kredittkortPresentation.value === option.value}
              type="radio"
              className="radio"
              id={`kredittkort-presentation-${i}`}
              name="kredittkort-presentations"
              value={`kredittkort-presentation-${i}`}
              onChange={() =>
                updateFilter(
                  option.value,
                  numberOfExistingKredittkortPresentationSaveInstances,
                )
              }
            />
            <label
              className="radio__label"
              htmlFor={`kredittkort-presentation-${i}`}
            >
              {option.label}
            </label>
          </span>
        ))}
      </div>
    </fieldset>
  );
};

MySettingsKredittkortPresentationFieldset.propTypes = {
  kredittkortPresentation: PropTypes.shape({}).isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default MySettingsKredittkortPresentationFieldset;
