import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DetailSectionListItem from 'digitalbank-detail-section/lib/components/DetailSection/DetailSectionListItem';
import DetailSectionList from 'digitalbank-detail-section/lib/components/DetailSection/DetailSectionList';
import { formatNumber, formatAccountNumber } from 'utils/format.util';
import { getCurrentPeriod } from 'utils/loan.util';
import LoanHistoryDetails from './LoanHistoryDetails';

const getLoanTypeLabel = (loanType) => {
  if (loanType === 'ANNUITY') {
    return 'Annuitetslån';
  }
  if (loanType === 'FLEXIBLE') {
    return 'Fleksilån';
  }
  return 'Ukjent låntype';
};

const LoanDetailSection = ({
  loanData,
  loanGraphData,
  durationInYears,
  restgjeld,
  loanType,
}) => {
  const currentPeriod = getCurrentPeriod(loanData.startDate);
  const isAnnuityLoan = loanType === 'ANNUITY';
  const isFlexible = loanType === 'FLEXIBLE';
  const loanTypeLabel = getLoanTypeLabel(loanType);
  return (
    <section>
      <DetailSectionList>
        <DetailSectionListItem
          label={loanTypeLabel}
          value={`${durationInYears} år`}
        />
        <DetailSectionListItem label="Medlåntaker" value="Henrik Johannesen" />
        {isFlexible && (
          <DetailSectionListItem label="Ramme" value={formatNumber(1000000)} />
        )}
        {isFlexible && (
          <DetailSectionListItem
            label="Benyttet"
            value={formatNumber(400000)}
          />
        )}
        {isFlexible && (
          <DetailSectionListItem
            label="Disponibelt"
            value={formatNumber(600000)}
          />
        )}
        {isAnnuityLoan && (
          <DetailSectionListItem
            label="Opprinnelig lån"
            value={formatNumber(loanData.amount)}
          />
        )}
        {isAnnuityLoan && (
          <DetailSectionListItem
            label="Restgjeld"
            value={formatNumber(restgjeld)}
          />
        )}
      </DetailSectionList>
      <DetailSectionList>
        <DetailSectionListItem
          label="Nominell rente"
          value={`${loanData.nominellRente}%`}
        />
        <DetailSectionListItem
          label="Effektiv rente"
          value={`${loanData.effektivRente}%`}
        />
        {isAnnuityLoan && (
          <DetailSectionListItem label="Terminbeløp" value="733,00" />
        )}
        {isAnnuityLoan && (
          <DetailSectionListItem
            label="Neste forfall"
            value={moment(loanGraphData[currentPeriod].date).format('L')}
          />
        )}
      </DetailSectionList>
      <LoanHistoryDetails style={{ paddingBottom: '20px' }}>
        <DetailSectionList>
          <DetailSectionListItem
            label="Åpningsdato"
            value={moment(loanData.startDate).format('L')}
          />
          <DetailSectionListItem
            label="Innfris dato"
            value={moment(loanData.endDate).format('L')}
          />
          {loanData.terminomkostninger && (
            <DetailSectionListItem
              label="Terminomkostninger"
              value={formatNumber(loanData.terminomkostninger)}
            />
          )}
          <DetailSectionListItem label="Terminlengde" value="30 dager" />
          <DetailSectionListItem
            label="Fra konto"
            value={formatAccountNumber(loanData.fraKonto)}
          />
          {loanData.sikkerhet && (
            <DetailSectionListItem
              label="Sikkerhet"
              value={loanData.sikkerhet}
            />
          )}
        </DetailSectionList>
        <DetailSectionList>
          <DetailSectionListItem label="Avdrag hittil i år" value="56 382,00" />
          <DetailSectionListItem label="Renter hittil i år" value="53 188,09" />
          <DetailSectionListItem
            label="Omkostninger hittil i år"
            value="350,00"
          />
        </DetailSectionList>
      </LoanHistoryDetails>
    </section>
  );
};
LoanDetailSection.propTypes = {
  loanData: PropTypes.shape({}).isRequired,
  loanGraphData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  restgjeld: PropTypes.string.isRequired,
  durationInYears: PropTypes.number.isRequired,
  loanType: PropTypes.oneOf(['ANNUITY', 'FLEXIBLE']).isRequired,
};

export default LoanDetailSection;
