const AvtaleadministrasjonFilters = {
  BETALINGSAVTALER: 'betalingsavtaler',
  FORSLAG: 'forslag',
};

const TOGGLE_AUTOMATIC_EFAKTURA = 'AUTOMATIC_EFAKTURA/TOGGLE';

export default TOGGLE_AUTOMATIC_EFAKTURA;

export { TOGGLE_AUTOMATIC_EFAKTURA, AvtaleadministrasjonFilters };
