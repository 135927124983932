import i18n from 'i18n';

const validFrequencies = [0, 1, 2, 3, 4, 5, 6, 7];

export const getFrekvensString = (frekvens) => {
  switch (parseInt(frekvens, 10)) {
    case 0:
      return i18n(i => i.FREKVENS_UKENTLIG);
    case 1:
      return i18n(i => i.FREKVENS_ANNENHVERUKE);
    case 2:
      return i18n(i => i.FREKVENS_MANEDLIG);
    case 3:
      return i18n(i => i.FREKVENS_ANNENHVERMANED);
    case 4:
      return i18n(i => i.FREKVENS_HVERTREDJEMANED);
    case 5:
      return i18n(i => i.FREKVENS_HVERFJERDEMANED);
    case 6:
      return i18n(i => i.FREKVENS_HALVARLIG);
    case 7:
      return i18n(i => i.FREKVENS_ARLIG);
    case 8:
      return i18n(i => i.FREKVENS_UDEFINERT);
    default:
      return i18n(i => i.FREKVENS_UKJENT);
  }
};

export const getFrekvensList = () =>
  validFrequencies.map(frekvens => ({
    value: frekvens,
    text: getFrekvensString(frekvens),
  }));
