/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import BetalingHeader from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/Betalingsliste/BetalingHeader';
import BetalingList from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/Betalingsliste/BetalingList';
import i18n from 'i18n';
import { formatAmount } from 'utils';
import { formatAccountNumber } from 'utils/format.util';
import { STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import * as PATH from 'routes/paths';
import forfallsmappeUtils from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/forfallsmappe.util';
import ScheduledPaymentsHeaderStyled from './ScheduledPaymentsHeaderStyled';
import ScheduledPaymentsDropdownStyled from './ScheduledPaymentsDropdownStyled';

const ScheduledPaymentsCollection = ({
  account,
  deletePayment,
  setPaymentStatus,
}) => {
  const totalBeloep = forfallsmappeUtils.sumForfallForKonto(account);
  return (
    <section className="p-scheduled-payments__collection">
      <ScheduledPaymentsHeaderStyled>
        <BetalingHeader
          kontonavn={account.accountName}
          kontonummer={formatAccountNumber(account.accountNumber)}
          disponibeltBeloep={account.availableAmount}
          disponibeltBeloepFormatted={formatAmount(account.availableAmount)}
          totaltBeloep={totalBeloep}
          totaltBeloepFormatted={formatAmount(totalBeloep)}
          kontoHarGlobaleBetalinger={_.filter(
            account.betalinger,
            x => x.betalingsstatus === 'SCHEDULED',
          ).some(x => x.betalingstype === 'Utlandsbetaling')}
        />
      </ScheduledPaymentsHeaderStyled>
      <ScheduledPaymentsDropdownStyled>
        <BetalingList
          betalinger={_.orderBy(account.betalinger, ['dato'], ['asc'])}
          onDeleteBetaling={id => deletePayment(id)}
          onUpdateBetalingsstatus={(id, status) => setPaymentStatus(id, status)}
          i18nTranslate={template => i18n(template)}
          onStartBetaling={betaling =>
            setPaymentStatus(betaling.transaksjonId, STATUS.ACTIVE)
          }
          onStopBetaling={betaling =>
            setPaymentStatus(betaling.transaksjonId, STATUS.STOPPED)
          }
          onResetDeleteBetalingStatus={() =>
            console.log('reset betaling status')
          }
          onResetStopStartBetalingStatus={() =>
            console.log('reset stopstart betaling status')
          }
          onShowFaktura={() => console.log('onShowFaktura')}
          onShowFakturaReset={() => console.log('onShowFakturaReset')}
          onResetStopStartBetalingsStatus={() =>
            console.log('onResetStopStartBetalingsStatus')
          }
          resetDeleteBetalingFailed={() =>
            console.log('resetDeleteBetalingFailed')
          }
          endreBetalingUrl={`${PATH.EDIT_BETALE_EN.slice(1)}`}
          endreOverfoeringUrl={`${PATH.TRANSFER_EDIT.slice(1)}`}
          routerLink={Link}
        />
      </ScheduledPaymentsDropdownStyled>
    </section>
  );
};

ScheduledPaymentsCollection.propTypes = {
  account: PropTypes.shape({}).isRequired,
  deletePayment: PropTypes.func.isRequired,
  setPaymentStatus: PropTypes.func.isRequired,
};

export default ScheduledPaymentsCollection;
