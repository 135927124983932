import {
  TOGGLE_EDIT_MODE_ON_CONTACT_INFO,
  CLOSE_EDIT_MODE_ON_CONTACT_INFO,
  OPEN_EDIT_MODE_ON_CONTACT_INFO,
  TOGGLE_CONTACT_INFO_HELP,
  CLOSE_CONTACT_INFO_HELP,
  TOGGLE_FOREIGNER_FORM,
} from '../actions/actionTypes';

const initialState = {
  editMode: false,
  displayHelp: false,
  isForeigner: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_EDIT_MODE_ON_CONTACT_INFO:
      return {
        ...state,
        editMode: !state.editMode,
      };
    case CLOSE_EDIT_MODE_ON_CONTACT_INFO:
      return initialState;
    case OPEN_EDIT_MODE_ON_CONTACT_INFO:
      return {
        ...state,
        editMode: true,
      };
    case TOGGLE_CONTACT_INFO_HELP:
      return {
        ...state,
        displayHelp: !state.displayHelp,
      };
    case CLOSE_CONTACT_INFO_HELP:
      return {
        ...state,
        displayHelp: false,
      };
    case TOGGLE_FOREIGNER_FORM:
      return {
        ...state,
        isForeigner: !state.isForeigner,
      };
    default:
      return state;
  }
}
