/* eslint-disable no-unused-vars, no-continue */
import _ from 'lodash';
import shortid from 'shortid';
import moment from 'moment';
import Fuse from 'fuse.js';
import { TYPE, STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import {
  OVERFOERING_MELLOM_EGNE_KONTOER,
  UTLANDSBETALING,
} from 'digitalbank-betaling-web/lib/es/common/betalingstyper';
import { getAccountFromAccountNumber } from 'services/account.service';
import {
  concatKronerAndOere,
  formatAccountNumber,
  getKronerFromBeloep,
  getOereFromBeloep,
} from 'utils/format.util';

import {
  BEKREFTE_BETALING,
  GODKJENNE_BETALING,
  NEDPRIORITERE_BETALING,
  OPPDATERE_BETALING,
  PRIORITERE_BETALING,
  REAKTIVERE_BETALING,
  SE_BETALING,
  SKJULE_BETALING,
  SLETTE_BETALING,
  STOPPE_BETALING,
  TILBAKETREKKE_GODKJENNING,
  VERIFISERE_BETALING,
} from 'digitalbank-betaling-web/lib/es/common/betalingstillatelser';

const getTilKontoVisningsnavn = (accounts, transaction) => {
  const tilKonto = getAccountFromAccountNumber(accounts, transaction.tilKonto);
  if (tilKonto) {
    return tilKonto.visningsnavn || tilKonto.accountName || '';
  }
  return '';
};

const getFraKontoVisningsnavn = (accounts, transaction) => {
  const fraKonto = getAccountFromAccountNumber(accounts, transaction.fraKonto);
  if (fraKonto) {
    return fraKonto.visningsnavn || fraKonto.accountName;
  }
  return '';
};

const getTransactionHeadingDescription = (
  { type, description, fraKonto },
  direction,
  account,
  tilKontoVisningsnavn,
  fraKontoVisningsnavn,
) => {
  if (type === TYPE.OVERFOERING) {
    // if (account.accountNumber === fraKonto || (!account.accountNumber && direction === 'INN')) {
    //   return `Overføring til ${tilKontoVisningsnavn}`;
    // }
    return `Overføring fra ${fraKontoVisningsnavn}`;
  }

  return description;
};

const filter = (
  initialTransactions,
  inputValue,
  account,
  direction,
  type,
  mottakernavn,
  fromDate,
  toDate,
  fromAmount,
  toAmount,
  accounts,
) => {
  let transactions = initialTransactions;
  // First we try to search using the input value
  if (inputValue) {
    const options = {
      shouldSort: true,
      threshold: 0.35,
      minMatchCharLength: 1,
      keys: [
        {
          name: 'description',
          weight: 0.3,
        },
        {
          name: 'mottakernavn',
          weight: 0.3,
        },
        {
          name: 'melding',
          weight: 0.2,
        },
        {
          name: 'type',
          weight: 0.1,
        },
        {
          name: 'kid',
          weight: 0.1,
        },
      ],
    };
    const fuse = new Fuse(transactions, options);
    transactions = fuse.search(inputValue);
  }
  const filteredTransactions = [];
  for (let i = 0; i < transactions.length; i += 1) {
    const transaction = transactions[i];
    const isInnTransaksjon = transaction.beloep.verdi > 0;
    const isUtTransaksjon = transaction.beloep.verdi < 0;

    if (account && !account.isAllAccountsOption) {
      if (
        account.accountNumber !== transaction.fraKonto &&
        account.accountNumber !== transaction.tilKonto
      ) {
        continue;
      }
      if (direction === 'OUT') {
        if (account.accountNumber !== transaction.fraKonto) {
          continue;
        }
      }
      if (direction === 'INN') {
        if (account.accountNumber !== transaction.tilKonto) {
          continue;
        }
      }
    } else {
      if (direction === 'OUT' && !isUtTransaksjon) {
        continue;
      }
      if (
        direction === 'INN' &&
        (!isInnTransaksjon && transaction.type !== TYPE.OVERFOERING)
      ) {
        continue;
      }
    }
    if (mottakernavn && mottakernavn !== transaction.mottakernavn) {
      continue;
    }
    if (
      fromDate &&
      moment(transaction.dato).isBefore(
        moment(fromDate, 'DD.MM.YYYY').startOf('day'),
      )
    ) {
      continue;
    }
    if (
      toDate &&
      moment(transaction.dato).isAfter(
        moment(toDate, 'DD.MM.YYYY').endOf('day'),
      )
    ) {
      continue;
    }
    if (
      fromAmount &&
      Math.abs(transaction.beloep.verdi) < Math.abs(fromAmount)
    ) {
      continue;
    }
    if (toAmount && Math.abs(transaction.beloep.verdi) > Math.abs(toAmount)) {
      continue;
    }
    filteredTransactions.push(transaction);
  }

  return filteredTransactions.map((transaction) => {
    const tilKontoVisningsnavn = getTilKontoVisningsnavn(accounts, transaction);
    const fraKontoVisningsnavn = getFraKontoVisningsnavn(accounts, transaction);
    return {
      ...transaction,
      tilKontoVisningsnavn,
      fraKontoVisningsnavn,
      description: getTransactionHeadingDescription(
        transaction,
        direction,
        account,
        tilKontoVisningsnavn,
        fraKontoVisningsnavn,
      ),
    };
  });
};

export const mapTransactionsToBetalingsList = transactions =>
  transactions.map(transaction => ({
    ...transaction,
    beloep: {
      ...transaction.beloep,
      verdi: transaction.beloep.verdi * -1,
    },
    estimertBeloep: transaction.estimertBeloep
      ? {
        ...transaction.estimertBeloep,
        verdi: transaction.estimertBeloep.verdi * -1,
      }
      : null,
    transaksjonId: transaction.id,
    kredittkonto: transaction.tilKonto,
    mottakernavn:
      transaction.tilKontonavn || formatAccountNumber(transaction.tilKonto),
    debetkonto: transaction.fraKonto,
    forfallsdato: transaction.dato,
    betalingstype:
      transaction.type === TYPE.OVERFOERING
        ? OVERFOERING_MELLOM_EGNE_KONTOER
        : transaction.type,
    betalingsstatus: transaction.status,
    tillatteOperasjoner: [
      BEKREFTE_BETALING,
      GODKJENNE_BETALING,
      NEDPRIORITERE_BETALING,
      OPPDATERE_BETALING,
      PRIORITERE_BETALING,
      REAKTIVERE_BETALING,
      SE_BETALING,
      SKJULE_BETALING,
      SLETTE_BETALING,
      STOPPE_BETALING,
    ],
    efakturaReferanse: null,
  }));

export default filter;

export const filterEfakturaTransactions = transactions =>
  _.filter(transactions, x => x.type === TYPE.EFAKTURA);

export const filterStoppedTransactions = transactions =>
  _.filter(transactions, x => x.status === STATUS.STOPPED);

export const filterUnapprovedEfakturaTransactions = transactions =>
  _.filter(
    filterEfakturaTransactions(transactions),
    x => x.status === STATUS.UNAPPROVED,
  );

export const filterApprovedAndUnapprovedEfakturaTransactions = transactions =>
  _.filter(
    filterEfakturaTransactions(transactions),
    x => x.status === STATUS.UNAPPROVED || x.status === STATUS.APPROVED,
  );

export const mapTransactionToFormValues = ({
  beloep,
  dato,
  kid,
  melding,
  ...other
}) => ({
  kroner: getKronerFromBeloep(beloep.verdi * -1),
  oere: getOereFromBeloep(beloep.verdi * -1),
  dato: moment(dato, moment.ISO_8601).format('DD.MM.YYYY'),
  kidMelding: kid || melding,
  kid,
  melding,
  // endreFastBetaling: 'endreKunDenneBetalingen',
  ...other,
});

export { filter };
