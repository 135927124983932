import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import { CheckboxField } from 'digitalbank-form';
import DetailSection from 'digitalbank-detail-section';
import Button from 'digitalbank-buttons';
import { Modal, ModalCard } from 'digitalbank-modal';
import postalCodes from 'norway-postal-codes';
import { format } from 'libphonenumber-js';
import EfakturaFraAlleKontaktinfoFormContainer from './EfakturaFraAlleKontaktinfoFormContainer';
import TextElementWithTooltip from './TextElementWithTooltip';

const MailNotificationForm = () => (
  <form className="p-efaktura-fra-alle-settings__form">
    <fieldset className="fieldset">
      <Field
        component={CheckboxField}
        name="mailNotification"
        id="mailNotification"
        label="Send varsel på e-post når det kommer nye eFakturaer"
      />
    </fieldset>
  </form>
);

const MailNotificationFormContainer = reduxForm({
  form: 'efakturaFraAlleKontaktinfo',
  destroyOnUnmount: false,
})(MailNotificationForm);

class EfakturaFraAlleSettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }
  render() {
    const { isModalVisible } = this.state;
    const {
      adresse, postnummer, epost, phone, phoneCountryCode,
    } = this.props;
    const location = postalCodes[postnummer];
    return (
      <div className="p-efaktura-fra-alle-settings__section">
        <TextElementWithTooltip
          textElement={<h4 style={{ margin: '0' }}>Kontaktinfo</h4>}
          tooltipContent="Bedrifter du handler hos kan bruke kundeopplysningene de har om deg til å sjekke om du kan motta eFaktura automatisk."
        />
        <div className="p-efaktura-fra-alle-settings__section-contact-info">
          <DetailSection
            items={[
              {
                label: 'Navn',
                value: 'Kari Nordmann',
              },
              {
                label: 'Adresse',
                value: `${adresse}, ${postnummer || ''} ${location || ''}`,
              },
              {
                label: 'E-post',
                value: epost || '',
              },
              {
                label: 'Mobil',
                value:
                  phone && phoneCountryCode
                    ? format(
                        { country: phoneCountryCode, phone },
                        'International',
                      )
                    : '',
              },
            ]}
          />
          <Button
            variant="link"
            onClick={() => this.setState({ isModalVisible: true })}
          >
            Endre
          </Button>
          <Modal
            visible={isModalVisible}
            toggle={() => this.setState({ isModalVisible: !isModalVisible })}
          >
            <ModalCard
              toggle={() => this.setState({ isModalVisible: !isModalVisible })}
              heading="Kontaktinfo for eFaktura"
              className="p-efaktura-fra-alle-settings__modal-card"
              hasCloseButton
            >
              <p>
                Opplysningene brukes for å identifisere deg, på tvers av banker.
              </p>
              <EfakturaFraAlleKontaktinfoFormContainer
                onSubmitSuccess={() => {
                  this.setState({ isModalVisible: false });
                }}
              />
            </ModalCard>
          </Modal>
        </div>
        <MailNotificationFormContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const selector = formValueSelector('efakturaFraAlleKontaktinfo');
  return {
    adresse: selector(state, 'adresse'),
    postnummer: selector(state, 'postnummer'),
    phone: selector(state, 'phone'),
    phoneCountryCode: selector(state, 'phoneCountryCode'),
    epost: selector(state, 'epost'),
  };
};

EfakturaFraAlleSettingsContainer.defaultProps = {
  adresse: '',
  postnummer: '',
  phone: '',
  phoneCountryCode: '',
  epost: '',
};

EfakturaFraAlleSettingsContainer.propTypes = {
  adresse: PropTypes.string,
  postnummer: PropTypes.string,
  phone: PropTypes.string,
  phoneCountryCode: PropTypes.string,
  epost: PropTypes.string,
};

export default connect(mapStateToProps)(EfakturaFraAlleSettingsContainer);
