const template = {
  USD: {
    'nb-NO': 'Amerikanske dollar (USD)',
    'nn-NO': 'Amerikanske dollar (USD)',
    'en-GB': 'USA (USD)',
  },
  AED: {
    'nb-NO': 'Arab. Emiratiske dirham (AED)',
    'nn-NO': 'Arab. Emiratiske dirham (AED)',
    'en-GB': 'United Arab Emirates (AED)',
  },
  AUD: {
    'nb-NO': 'Australske dollar (AUD)',
    'nn-NO': 'Australske dollar (AUD)',
    'en-GB': 'Australia (AUD)',
  },
  BSD: {
    'nb-NO': 'BSD (Bahamas)',
    'nn-NO': 'BSD (Bahamas)',
    'en-GB': 'BSD (Bahamas)',
  },
  BHD: {
    'nb-NO': 'Bahrain dinarer (BHD)',
    'nn-NO': 'Bahrain dinarer (BHD)',
    'en-GB': 'Bahrain (BHD)',
  },
  BWP: {
    'nb-NO': 'Botswana pula (BWP)',
    'nn-NO': 'Botswana pula (BWP)',
    'en-GB': 'Botswana (BWP)',
  },
  BRL: {
    'nb-NO': 'Brasil real (BRL)',
    'nn-NO': 'Brasil real (BRL)',
    'en-GB': 'Brazil (BRL)',
  },
  GBP: {
    'nb-NO': 'Britiske pund (GBP)',
    'nn-NO': 'Britiske pund (GBP)',
    'en-GB': 'United Kingdom (GBP)',
  },
  BGN: {
    'nb-NO': 'Bulgarske lev (BGN)',
    'nn-NO': 'Bulgarske lev (BGN)',
    'en-GB': 'Bulgaria (BGN)',
  },
  CLP: {
    'nb-NO': 'Chilenske pesos (CLP)',
    'nn-NO': 'Chilenske pesos (CLP)',
    'en-GB': 'Chile (CLP)',
  },
  DKK: {
    'nb-NO': 'Danske kroner (DKK)',
    'nn-NO': 'Danske kroner (DKK)',
    'en-GB': 'Denmark (DKK)',
  },
  DOP: {
    'nb-NO': 'Dominikansk peso (DOP)',
    'nn-NO': 'Dominikansk peso (DOP)',
    'en-GB': 'Dominican Republic (DOP)',
  },
  EGP: {
    'nb-NO': 'Egyptiske pund (EGP)',
    'nn-NO': 'Egyptiske pund (EGP)',
    'en-GB': 'Egypt (EGP)',
  },
  ETB: {
    'nb-NO': 'Etiopiske birr (ETB)',
    'nn-NO': 'Etiopiske birr (ETB)',
    'en-GB': 'Etiopia (ETB)',
  },
  EUR: {
    'nb-NO': 'Euro (EUR)',
    'nn-NO': 'Euro (EUR)',
    'en-GB': 'Euro (EUR)',
  },
  PHP: {
    'nb-NO': 'Filippinske peso (PHP)',
    'nn-NO': 'Filippinske peso (PHP)',
    'en-GB': 'The Phillipines (PHP)',
  },
  GMD: {
    'nb-NO': 'Gambiske dalasi (GMD)',
    'nn-NO': 'Gambiske dalasi (GMD)',
    'en-GB': 'Gambia (GMD)',
  },
  HKD: {
    'nb-NO': 'Hong Kong dollar (HKD)',
    'nn-NO': 'Hong Kong dollar (HKD)',
    'en-GB': 'Hong Kong(HKD)',
  },
  INR: {
    'nb-NO': 'Indiske rupi (INR)',
    'nn-NO': 'Indiske rupi (INR)',
    'en-GB': 'India (INR)',
  },
  IDR: {
    'nb-NO': 'Indonesiske rupies (IDR)',
    'nn-NO': 'Indonesiske rupies (IDR)',
    'en-GB': 'Indonesia (IDR)',
  },
  ISK: {
    'nb-NO': 'Islandske kroner (ISK)',
    'nn-NO': 'Islandske kroner (ISK)',
    'en-GB': 'Iceland (ISK)',
  },
  ILS: {
    'nb-NO': 'Israelske shekel (ILS)',
    'nn-NO': 'Israelske shekel (ILS)',
    'en-GB': 'Israel (ILS)',
  },
  JPY: {
    'nb-NO': 'Japanske yen (JPY)',
    'nn-NO': 'Japanske yen (JPY)',
    'en-GB': 'Japan (JPY)',
  },
  JOD: {
    'nb-NO': 'Jordanske dinar (JOD)',
    'nn-NO': 'Jordanske dinar (JOD)',
    'en-GB': 'Jordan (JOD)',
  },
  CAD: {
    'nb-NO': 'Kanadiske dollar (CAD)',
    'nn-NO': 'Kanadiske dollar (CAD)',
    'en-GB': 'Canada (CAD)',
  },
  KES: {
    'nb-NO': 'Kenyanske shilling (KES)',
    'nn-NO': 'Kenyanske shilling (KES)',
    'en-GB': 'Kenya (KES)',
  },
  CNH: {
    'nb-NO': 'Kinesiske offshore yuan (CNH)',
    'nn-NO': 'Kinesiske offshore yuan (CNH)',
    'en-GB': 'China Offshore (CNH)',
  },
  CNY: {
    'nb-NO': 'Kinesiske yuan (CNY)',
    'nn-NO': 'Kinesiske yuan (CNY)',
    'en-GB': 'China (CNY)',
  },
  KRW: {
    'nb-NO': 'Koreanske won (KRW)',
    'nn-NO': 'Koreanske won (KRW)',
    'en-GB': 'South Korea (KRW)',
  },
  HRK: {
    'nb-NO': 'Kroatiske kuna (HRK)',
    'nn-NO': 'Kroatiske kuna (HRK)',
    'en-GB': 'Croatia (HRK)',
  },
  KWD: {
    'nb-NO': 'Kuwait dinarer (KWD)',
    'nn-NO': 'Kuwait dinarer (KWD)',
    'en-GB': 'Kuwait (KWD)',
  },
  MKD: {
    'nb-NO': 'Makedonske denar (MKD)',
    'nn-NO': 'Makedonske denar (MKD)',
    'en-GB': 'Macedonia (MKD)',
  },
  MYR: {
    'nb-NO': 'Malaysiske ringgit (MYR)',
    'nn-NO': 'Malaysiske ringgit (MYR)',
    'en-GB': 'Malaysia (MYR)',
  },
  MVR: {
    'nb-NO': 'Maldivene rufiyaa (MVR)',
    'nn-NO': 'Maldivene rufiyaa (MVR)',
    'en-GB': 'Maldives (MVR)',
  },
  MAD: {
    'nb-NO': 'Marokkanske dirham (MAD)',
    'nn-NO': 'Marokkanske dirham (MAD)',
    'en-GB': 'Marocco (MAD)',
  },
  MUR: {
    'nb-NO': 'Mauritius rupee (MUR)',
    'nn-NO': 'Mauritius rupee (MUR)',
    'en-GB': 'Mauritius (MUR)',
  },
  MXN: {
    'nb-NO': 'Mexikanske pesos (MXN)',
    'nn-NO': 'Mexikanske pesos (MXN)',
    'en-GB': 'Mexico (MXN)',
  },
  NZD: {
    'nb-NO': 'New Zealand dollar (NZD)',
    'nn-NO': 'New Zealand dollar (NZD)',
    'en-GB': 'New Zealand (NZD)',
  },
  NOK: {
    'nb-NO': 'Norske kroner (NOK)',
    'nn-NO': 'Norske kroner (NOK)',
    'en-GB': 'Norway (NOK)',
  },
  OMR: {
    'nb-NO': 'Omanske rial (OMR)',
    'nn-NO': 'Omanske rial (OMR)',
    'en-GB': 'Oman (OMR)',
  },
  PKR: {
    'nb-NO': 'Pakistanske rupi (PKR)',
    'nn-NO': 'Pakistanske rupi (PKR)',
    'en-GB': 'Pakistan (PKR)',
  },
  PLN: {
    'nb-NO': 'Polske zloty (PLN)',
    'nn-NO': 'Polske zloty (PLN)',
    'en-GB': 'Poland (PLN)',
  },
  QAR: {
    'nb-NO': 'Qatar riyal (QAR)',
    'nn-NO': 'Qatar riyal (QAR)',
    'en-GB': 'Qatar (QAR)',
  },
  RON: {
    'nb-NO': 'Rumenske leu (RON)',
    'nn-NO': 'Rumenske leu (RON)',
    'en-GB': 'Romania(RON)',
  },
  RUB: {
    'nb-NO': 'Russiske rubel (RUB)',
    'nn-NO': 'Russiske rubel (RUB)',
    'en-GB': 'Russia (RUB)',
  },
  SAR: {
    'nb-NO': 'Saudi Arabiske riyal (SAR)',
    'nn-NO': 'Saudi Arabiske riyal (SAR)',
    'en-GB': 'Saudi Arabia (SAR)',
  },
  RSD: {
    'nb-NO': 'Serbiske dinar (RSD)',
    'nn-NO': 'Serbiske dinar (RSD)',
    'en-GB': 'Serbia (RSD)',
  },
  SGD: {
    'nb-NO': 'Singapore dollar (SGD)',
    'nn-NO': 'Singapore dollar (SGD)',
    'en-GB': 'Singapore (SGD)',
  },
  LKR: {
    'nb-NO': 'Sri Lanka rupi (LKR)',
    'nn-NO': 'Sri Lanka rupi (LKR)',
    'en-GB': 'Sri Lanka (LKR)',
  },
  CHF: {
    'nb-NO': 'Sveitsiske franc (CHF)',
    'nn-NO': 'Sveitsiske franc (CHF)',
    'en-GB': 'Switzerland (CHF)',
  },
  SEK: {
    'nb-NO': 'Svenske kroner (SEK)',
    'nn-NO': 'Svenske kroner (SEK)',
    'en-GB': 'Sweden (SEK)',
  },
  ZAR: {
    'nb-NO': 'Sør Afrikanske rand (ZAR)',
    'nn-NO': 'Sør Afrikanske rand (ZAR)',
    'en-GB': 'South Africa(ZAR)',
  },
  TWD: {
    'nb-NO': 'Taiwanske dollar (TWD)',
    'nn-NO': 'Taiwanske dollar (TWD)',
    'en-GB': 'Taiwan (TWD)',
  },
  TZS: {
    'nb-NO': 'Tanzania shilling (TZS)',
    'nn-NO': 'Tanzania shilling (TZS)',
    'en-GB': 'Tanzania (TZS)',
  },
  THB: {
    'nb-NO': 'Thailandske baht (THB)',
    'nn-NO': 'Thailandske baht (THB)',
    'en-GB': 'Thailand (THB)',
  },
  CZK: {
    'nb-NO': 'Tsjekkiske koruna (CZK)',
    'nn-NO': 'Tsjekkiske koruna (CZK)',
    'en-GB': 'Czech Republic (CZK)',
  },
  TND: {
    'nb-NO': 'Tunisiske dinarer (TND)',
    'nn-NO': 'Tunisiske dinarer (TND)',
    'en-GB': 'Tunisia (TND)',
  },
  TRY: {
    'nb-NO': 'Tyrkiske lire (TRY)',
    'nn-NO': 'Tyrkiske lire (TRY)',
    'en-GB': 'Tyrkey (TRY)',
  },
  HUF: {
    'nb-NO': 'Ungarske forinter (HUF)',
    'nn-NO': 'Ungarske forinter (HUF)',
    'en-GB': 'Hungary (HUF)',
  },
  VND: {
    'nb-NO': 'Vietnamesiske dong (VND)',
    'nn-NO': 'Vietnamesiske dong (VND)',
    'en-GB': 'Vietnam (VND)',
  },
};

export default template;
