import * as EconomyService from 'services/economy.service';
import {
  ECONOMY_GET_TRANSACTIONS_START,
  ECONOMY_GET_TRANSACTIONS_SUCCESSFUL,
  ECONOMY_GET_TRANSACTIONS_FAILED,
  ECONOMY_GET_CATEGORIES_START,
  ECONOMY_GET_CATEGORIES_SUCCESSFUL,
  ECONOMY_GET_CATEGORIES_FAILED,
  ECONOMY_GET_TAGS_START,
  ECONOMY_GET_TAGS_SUCCESSFUL,
  ECONOMY_GET_TAGS_FAILED,
} from './actionTypes';

export const customerId = '21049443258';
const initialParams = {
  customerId,
};

export const getTransactionsStart = () => ({
  type: ECONOMY_GET_TRANSACTIONS_START,
});

export const getTransactionsSuccessful = data => ({
  type: ECONOMY_GET_TRANSACTIONS_SUCCESSFUL,
  data,
});

export const getTransactionsFailed = error => ({
  type: ECONOMY_GET_TRANSACTIONS_FAILED,
  error,
});

export const getTransactions = params => (dispatch) => {
  dispatch(getTransactionsStart());
  return EconomyService.getTransactions({ ...initialParams, ...params })
    .then((data) => {
      const parsedData = JSON.parse(data);
      dispatch(getTransactionsSuccessful(parsedData));
    })
    .catch((error) => {
      dispatch(getTransactionsFailed(error));
      throw error;
    });
};

export const getTagsStart = () => ({
  type: ECONOMY_GET_TAGS_START,
});

export const getTagsSuccessful = data => ({
  type: ECONOMY_GET_TAGS_SUCCESSFUL,
  data,
});

export const getTagsFailed = error => ({
  type: ECONOMY_GET_TAGS_FAILED,
  error,
});

export const getTags = params => (dispatch) => {
  dispatch(getTagsStart());
  return EconomyService.getTags({ ...initialParams, ...params })
    .then((data) => {
      const parsedData = JSON.parse(data);
      dispatch(getTagsSuccessful(parsedData));
    })
    .catch((error) => {
      dispatch(getTagsFailed(error));
      throw error;
    });
};

export const getCategoriesStart = () => ({
  type: ECONOMY_GET_CATEGORIES_START,
});

export const getCategoriesSuccessful = data => ({
  type: ECONOMY_GET_CATEGORIES_SUCCESSFUL,
  data,
});

export const getCategoriesFailed = error => ({
  type: ECONOMY_GET_CATEGORIES_FAILED,
  error,
});

export const getCategories = () => (dispatch) => {
  dispatch(getCategoriesStart());
  return EconomyService.getCategories()
    .then((data) => {
      dispatch(getCategoriesSuccessful(JSON.parse(data)));
    })
    .catch((error) => {
      dispatch(getCategoriesFailed(error));
      throw error;
    });
};
