import {
  getIbanDisplayFilter,
  getToAccountLabel,
  getAvailableCurrenciesInCountry,
  getNumber,
} from './paymentGlobal.utils';

export const firstPageValidate = (values, i18n) => {
  const errors = {};
  // const isExtraBankCodeMandatory = this.isExtraBankCodeMandatory(country);
  const ibanDisplayFilter = getIbanDisplayFilter(values.country);
  // const countryShouldDisplayBankInfoOptions = this.countryShouldDisplayBankInfoOptions(country)
  const {
    country,
    tilKonto,
    recipientName,
    recipientAddress1,
    bankName,
  } = values;
  if (!country) {
    errors.country = i18n(
      t => t.PAYMENT_GLOBAL_ERROR_RECIPIENT_BANK_IS_REQUIRED,
    );
  }
  if (!tilKonto) {
    const label = getToAccountLabel(ibanDisplayFilter, i18n);
    errors.tilKonto = `${label} ${i18n(t => t.IS_REQUIRED)}`;
  }
  if (!recipientName) {
    errors.recipientName = i18n(
      t => t.PAYMENT_GLOBAL_ERROR_RECIPIENT_NAME_IS_REQUIRED,
    );
  }
  if (!recipientAddress1) {
    errors.recipientAddress1 = i18n(
      t => t.PAYMENT_GLOBAL_ERROR_RECIPIENT_ADDRESS_IS_REQUIRED,
    );
  }

  if (!bankName) {
    errors.bankName = i18n(t => t.PAYMENT_GLOBAL_ERROR_BANK_NAME_NOT_FOUND);
  }

  return errors;
};

export const secondPageValidate = (values, fx, i18n) => {
  const errors = {};
  const {
    country, kroner, oere, currency, fraKonto,
  } = values;
  const amount = getNumber(kroner, oere);

  const currencies = getAvailableCurrenciesInCountry(country);
  const selectedCurrency = currency !== '' ? currency : currencies[0];
  const amountInNok =
    amount > 0 && fx.rates
      ? fx.convert(amount, { from: selectedCurrency, to: 'NOK' })
      : 0;

  if (!kroner || kroner <= 0) {
    errors.kroner = i18n(t => t.PAYMENT_GLOBAL_ERROR_KRONER_IS_EMPTY);
  }

  if (!fraKonto || (fraKonto && !fraKonto.accountNumber)) {
    errors.fraKonto = i18n(t => t.PAYMENT_GLOBAL_ERROR_FRA_KONTO_IS_MISSING);
  }

  if (fraKonto && fraKonto.availableAmount < amountInNok) {
    errors.fraKonto = i18n(t => t.PAYMENT_GLOBAL_ERROR_NOT_ENOUGH_MONEY);
  }

  if (amountInNok >= 100000) {
    if (!values.paymentPurpose) {
      errors.paymentPurpose = i18n(
        t => t.PAYMENT_GLOBAL_ERROR_PAYMENT_PURPOSE_IS_REQUIRED,
      );
    }
    if (!values.paymentType) {
      errors.paymentType = i18n(
        t => t.PAYMENT_GLOBAL_ERROR_PAYMENT_TYPE_IS_REQUIRED,
      );
    }
  }
  return errors;
};
