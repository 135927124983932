import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLocale } from 'actions';
import { EFAKTURA_ONE_CLICK, ONECLICK } from 'digitalbank-efaktura-oneclick';
import Masonry from './Masonry/Masonry';
import SettingsCardForSecurity from './SettingsCardForSecurity';
import SettingsCardForRegionalBlocking from './SettingsCardForRegionalBlocking';
import SettingsCardForContactInfo from './SettingsCardForContactInfo';
import SettingsCardForMyFamily from './SettingsCardForMyFamily';
import SettingsCardForMobile from './SettingsCardForMobile';
import SettingsCardForEFaktura from './SettingsCardForEFaktura';
import SettingsCardForLanguage from './SettingsCardForLanguage';
import SettingsCardForDataConcent from './SettingsCardForDataConcent';
import SettingsStyled from './SettingsStyled';

const Settings = ({
  user,
  periodicControlRegionalBlocking,
  locale,
  onSetLocale,
  eFakturaOneClickIsActive,
}) => (
  <SettingsStyled>
    <div className="p-settings">
      <div className="p-settings__container">
        <Masonry>
          <SettingsCardForContactInfo info={user.information} />
          <SettingsCardForRegionalBlocking
            openLocations={periodicControlRegionalBlocking.openLocations}
          />
          <SettingsCardForMyFamily />
          <SettingsCardForEFaktura
            eFakturaOneClickIsActive={eFakturaOneClickIsActive}
            automaticEfakturaIsActive={user.settings.automaticEfakturaIsActive}
          />
          <SettingsCardForMobile />
          <SettingsCardForSecurity />
          <SettingsCardForLanguage locale={locale} setLocale={onSetLocale} />
          <SettingsCardForDataConcent />
        </Masonry>
      </div>
    </div>
  </SettingsStyled>
);

Settings.propTypes = {
  user: PropTypes.shape({}).isRequired,
  periodicControlRegionalBlocking: PropTypes.shape({}).isRequired,
  onSetLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  eFakturaOneClickIsActive: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  user,
  periodicControlRegionalBlocking,
  locale,
  ...other
}) => ({
  user,
  periodicControlRegionalBlocking,
  locale,
  eFakturaOneClickIsActive: other[EFAKTURA_ONE_CLICK].oneClick === ONECLICK.PAA,
});

const mapDispatchToProps = dispatch => ({
  onSetLocale: locale => dispatch(setLocale(locale)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
