import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const OptionButton = ({ value, onClick, isActive }) => (
  <button
    className={classNames('search-filter__option-btn', {
      'search-filter__option-btn--active': isActive,
    })}
    type="button"
    onClick={onClick}
  >
    <div className="search-filter__option-btn-content">{value}</div>
  </button>
);

OptionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default OptionButton;
