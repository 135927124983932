import { getSelectedAccounts } from 'services/account.service';
import _ from 'lodash';

const updateOrderIndex = elements =>
  elements.map((element, i) => ({
    ...element,
    orderIndex: i,
  }));

export default function reducer(state = getSelectedAccounts(), action = {}) {
  switch (action.type) {
    case 'SELECTED_ACCOUNTS/TOGGLE_ACCOUNT': {
      // check if account alread exist in selected accounts
      const index = _.findIndex(state, x => x.accountId === action.accountId);

      // if the selected element already is active
      const currentElement = state[index];

      // get number of selected elements
      const numberOfSelectedElements = _.filter(state, x => x.isSelected)
        .length;

      // remove element from array
      const arrayWithoutElement = [
        ...state.slice(0, index),
        ...state.slice(index + 1),
      ];

      // now we want to place the element inside the new array
      // we want to place the element between the selected and disabled elements
      if (currentElement.isSelected) {
        return updateOrderIndex([
          ...arrayWithoutElement.slice(0, numberOfSelectedElements - 1),
          {
            ...currentElement,
            isSelected: !currentElement.isSelected, // toggle isSelected property
          },
          ...arrayWithoutElement.slice(numberOfSelectedElements - 1),
        ]);
      }
      return updateOrderIndex([
        ...arrayWithoutElement.slice(0, numberOfSelectedElements),
        {
          ...currentElement,
          isSelected: !currentElement.isSelected, // toggle isSelected property
        },
        ...arrayWithoutElement.slice(numberOfSelectedElements),
      ]);
    }
    case 'SELECTED_ACCOUNTS/MOVE_ACCOUNT_UP': {
      // get index of account
      const indexOfAccountInSelectedAccounts = _.findIndex(
        state,
        x => x.accountId === action.accountId,
      );

      // check if element is the first element
      if (indexOfAccountInSelectedAccounts === 0) {
        return state;
      }
      const accountToMoveDown = {
        ...state[indexOfAccountInSelectedAccounts - 1],
      };
      const accountToMoveUp = { ...state[indexOfAccountInSelectedAccounts] };
      const newSelectedAccounts = [
        ...state.slice(0, indexOfAccountInSelectedAccounts - 1),
        accountToMoveUp,
        accountToMoveDown,
        ...state.slice(indexOfAccountInSelectedAccounts + 1),
      ];
      return updateOrderIndex(newSelectedAccounts);
    }
    // return moveAccountUp(state, action.account);
    case 'SELECTED_ACCOUNTS/MOVE_ACCOUNT_DOWN': {
      // get index of account
      const indexOfAccountInSelectedAccounts = _.findIndex(
        state,
        x => x.accountId === action.accountId,
      );

      // check if element is the last element
      if (indexOfAccountInSelectedAccounts === state.length - 1) {
        return state;
      }
      const accountToMoveDown = { ...state[indexOfAccountInSelectedAccounts] };
      const accountToMoveUp = {
        ...state[indexOfAccountInSelectedAccounts + 1],
      };
      const newSelectedAccounts = [
        ...state.slice(0, indexOfAccountInSelectedAccounts),
        accountToMoveUp,
        accountToMoveDown,
        ...state.slice(indexOfAccountInSelectedAccounts + 2),
      ];
      return updateOrderIndex(newSelectedAccounts);
    }
    default:
      return state;
  }
}
