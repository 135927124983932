import styled from 'styled-components';
import { breakpoints, layout } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

const borderColor = '#d5d5d5';

export default styled.div`
  .p-home {
    &__main,
    &__aside {
      ${horizontalLayoutSpacing}
    }

    &__container {
      @media (max-width: ${breakpoints.tablet - 1}px) {
        margin: 0;
      }
    }
    
    /* informasjon om nye efaktura, signering, stoppede betalinger osv.
    contact and search container */
    &__alerts,
    &__actions {
      margin-bottom: 20px;
    }

    &__notifications {
      @media (min-width: ${breakpoints.tablet}px) {
        margin-bottom: ${rem(40)};
      }
    }

    &__alerts {
      .alert-action ~ .alert-action {
        margin-top: ${rem(5)};
      }
    }

    &__footer {
      ${horizontalLayoutSpacing}

      @media screen and (min-width: ${breakpoints.tablet}px) {
        width: 64%;
      }
      @media screen and (min-width: ${breakpoints.desktop}px) {
        width: 70%;
      }

      &-content {
        @media (min-width: ${breakpoints.tablet}px) {
          width: 100%;
        }
      }
    }


    .stopped-payments,
    .new-invoices {
      &__counter {
        margin-right: ${rem(15)};
      }
    }

    .my-advisor-card {
      display: none;
      @media (min-width: ${breakpoints.tablet}px) {
        display: block;
      }
    }

    .published-content--demo {
      display: none;
      @media (min-width: ${breakpoints.tablet}px) {
        display: flex;
      }
    }

    .info-card {
      
      border-top: 1px solid ${borderColor};
      border-bottom: 1px solid ${borderColor};

      @media (min-width: ${breakpoints.tablet}px) {
        border-top: 0;
      }
    }

    .search-input {
      padding-right: 50px;
    }

    .search-input__button {
      width: 50px;
    }
    
    .contact-button,
    .promo-article,
    .search-input,
    .shortcuts {
      @media (min-width: ${breakpoints.tablet}px) {
        display: none;
      }
    }

    .customer-settings {
      display: none;
      @media (min-width: ${breakpoints.tablet}px) {
        display: block;
      }
    }

    .key-numbers,
    .key-numbers__settings {
      ${horizontalLayoutSpacing}
      max-width: ${layout.maxWidth};
      margin: 0 auto;
    }

    .key-numbers-wrapper {
      /* TODO: Display this component if it is selected both on small and larger devices */
      display: none;

      @media (min-width: ${breakpoints.tablet}px) {
        display: block;
        margin-bottom: 50px;
      }
    }

    .invoice-preview-article,
    .invoice-preview-list {
      margin-bottom: 20px;
    }
    
    .contact-card,
    .published-section {
      margin-bottom: 20px;
    }

    .balance-carousel {
      /* margin-top: -100px;
      padding-top: 70px; */
      
      /* margin-top: calc(-50px - constant(safe-area-inset-top));
      padding-top: calc(50px + constant(safe-area-inset-top));
      
      margin-top: calc(-50px - env(safe-area-inset-top));
      padding-top: calc(50px + env(safe-area-inset-top)); */
      /* padding-top: 0;
      margin-top: 0; */
      /* margin-top: -($header-height + $ios-status-bar-height);
      padding-top: $header-height + $ios-status-bar-height; */
      /* padding-top: 70px;
      margin-top: -100px;
      display: block; */
      
      @media (min-width: ${breakpoints.tablet}px) {
        display: none;
      }
    }
  }
`;
