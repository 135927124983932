import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isApp, isWebApp } from 'utils/detect.util';
import Hammer from 'hammerjs';
import MenuContainer from './MenuContainer';
import FooterContainer from './FooterContainer';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySidebar: false,
    };
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.hammertime = this.hammertime.bind(this);
    this.isApp = isApp() || isWebApp();
    this.app = React.createRef();
  }
  componentDidMount() {
    this.hammertime(false);
  }
  hammertime() {
    if (this.hammer) {
      // console.log('stop and destroy');
      this.hammer.stop();
      this.hammer.destroy();
    }
    this.hammer = new Hammer(this.app.current);
    this.hammer.on('swipeleft', () => {
      this.toggleSidebar();
    });
    this.hammer.on('swiperight', () => {
      if (this.state.displaySidebar) {
        this.toggleSidebar(); // Hide sidebar
      }
      // else {
      //   window.history.go(-1);
      // }
    });
  }
  toggleSidebar() {
    if (!this.state.displaySidebar) {
      document.body.classList.add('body--no-scroll');
    } else {
      document.body.classList.remove('body--no-scroll');
    }
    this.setState({ displaySidebar: !this.state.displaySidebar });
    // if (!this.state.displaySidebar) {
    //   this.hammertime(false);
    // }
  }
  render() {
    return (
      <div
        className={classNames('app', {
          'app--ios-app app--has-safe-area-support': this.isApp,
        })}
        ref={this.app}
      >
        <MenuContainer
          isApp={this.isApp}
          toggleSidebar={this.toggleSidebar}
          displaySidebar={this.state.displaySidebar}
        />
        <div className="app__main">{this.props.children}</div>
        <FooterContainer />
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element.isRequired,
};

export default App;
// const mapDispatchToProps = dispatch => ({
//   handleLogout: bindActionCreators(signOut, dispatch),
// });

// const mapStateToProps = state => ({
//   title: state.title,
//   isLoggedIn: !!state.auth.user,
// });

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

// import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import _ from 'lodash';
// import classNames from 'classnames';
// import Helmet from 'react-helmet';
// import { toggleSidebar, updateWindowSize } from 'actions';
// import { readMessage } from 'actions/messages.actions';
// import { signOut } from 'actions/authActions';
// import dataSelector from 'redux-helpers/dataSelector';
// // import { withRouter } from 'react-router-dom';
// import {
//   getAvtalegiroavtaler,
//   getEfakturaavtaler,
//   getKombinertAvtaler,
//   getAvtalegiroavtalerForslag,
//   getEfakturaavtalerForslag,
//   getKombinertAvtalerForslag,
// } from 'routes/Avtaleadministrasjon/avtaleadministrasjon.actions';
// import {
//   getTransactions,
//   getRecurringTransactions,
// } from 'actions/transactions.actions';
// import { initEfaktura } from 'actions/efaktura.actions';
// import * as PATH from 'routes/paths';
// import SpvMobileDetect from 'spv-mobile-detect';
// import { isApp, isWebApp } from 'utils/detect.util';
// import { getNotifications } from 'utils/notifications.util';
// import { Footer } from 'components';
// // import historyShape from 'shapes/history.shape';
// import AppToast from './AppToast';
// import Header from './Header';
// import Navigation from './Navigation';
// import Tabbar from './Tabbar';
// import Sidebar from './Sidebar';

// class App extends Component {
//   constructor(props) {
//     console.log(props);
//     super(props);
//     this.handleResizeWithDebounce = _.debounce(
//       () => {
//         props.onUpdateWindowSize({
//           width: window.innerWidth,
//           height: window.innerHeight,
//         });
//       },
//       60,
//       { leading: true, trailing: true },
//     );
//     this.isApp = isApp() || isWebApp();
//     const mobileDetect = new SpvMobileDetect();
//     this.isAndroid = mobileDetect.isAndroid();
//     if (this.isAndroid) {
//       // Initialize windowProperties values
//       props.onUpdateWindowSize({
//         width: window.innerWidth,
//         height: window.innerHeight,
//       });
//       window.addEventListener('resize', this.handleResizeWithDebounce);
//     }
//   }
//   componentDidMount() {
//     // Initialize data if it does not exist
//     if (!this.props.recurringTransactions.status.received) {
//       this.props.dispatchGetRecurringTransactions();
//     }
//     if (!this.props.transactions.status.received) {
//       // this.props.dispatchGetTransactions();
//       this.props.dispatchGetTransactions().then((transactions) => {
//         this.props.onInitEfaktura(this.props.accounts, transactions);
//       });
//     }
//     if (this.props.transactions.status.received) {
//       this.props.onInitEfaktura(
//         this.props.accounts,
//         this.props.transactions.data,
//       );
//     }
//     this.props.onGetAvtalegiroavtaler();
//     this.props.onGetEfakturaavtaler();
//     this.props.onGetKombinertAvtaler();
//     this.props.onGetAvtalegiroavtalerForslag();
//     this.props.onGetEfakturaavtalerForslag();
//     this.props.onGetKombinertAvtalerForslag();

//     // if (!this.props.betalingsavtaleTilbydere.status.received) {
//     //   this.props.dispatchGetBetalingsavtaleTilbydere();
//     // }
//     // if (!this.props.betalingsavtaler.status.received) {
//     //   this.props.dispatchGetBetalingsavtaler();
//     // }
//     // if (!this.props.betalingsavtaleForslag.status.received) {
//     //   this.props.dispatchGetBetalingsavtaleForslag();
//     // }
//   }
//   componentWillUnmount() {
//     if (this.isAndroid) {
//       document.removeEventListener('resize', this.handleResizeWithDebounce);
//     }
//   }
//   toggleSidebar = () => {
//     if (!this.props.displaySidebar) {
//       document.body.classList.add('body--no-scroll');
//     } else {
//       document.body.classList.remove('body--no-scroll');
//     }
//     this.props.onToggleSidebar();
//   };
//   render() {
//     const {
//       user,
//       userSettings,
//       displaySidebar,
//       title,
//       windowProperties,
//       messages,
//       // history,
//       transactions,
//     } = this.props;
//     const notifications = getNotifications(transactions);
//     // const currentPath = this.props.location.pathname;
//     // const isPeriodicControl =
//     //   currentPath === PATH.PERIODIC_CONTROL_REGIONAL_BLOCKING_ISOLATED ||
//     //   currentPath === PATH.CONTACT_INFO_PERIODIC_CONTROL ||
//     //   currentPath === PATH.DATA_CONSENT_PERIODIC_CONTROL;
//     const hideNavigation = false;
//     // currentPath === PATH.PAYMENT_REAUTH ||
//     // currentPath === PATH.PAYMENT_GLOBAL_REAUTH ||
//     // isPeriodicControl;
//     const currentMessage = _.find(messages, x => !x.isRead);
//     const isTabbarHidden = this.isAndroid && windowProperties.height < 450;
//     const isHomePage = true; // currentPath === PATH.HOME;
//     const hideGoBack = true; // isHomePage || isPeriodicControl;
//     return (
//       <div
//         className={classNames('app', {
//           'app--ios-app app--has-safe-area-support': this.isApp,
//         })}
//       >
//         <Helmet>
//           <title>{title}</title>
//         </Helmet>
//         <div
//           className={classNames('menu', {
//             'menu--is-ios menu--has-safe-area-support': isApp,
//           })}
//         >
//           <div className="menu__header">
//             <Header
//               title={!isHomePage && title ? title : ''}
//               logoutLinkPath={PATH.HOME}
//               logoLinkPath={PATH.HOME}
//               hideGoBack={hideGoBack}
//               handleLogout={this.props.onSignOut}
//               hideLinks={hideNavigation}
//               transparent={
//                 isHomePage &&
//                 windowProperties.scrollY <= 0 &&
//                 userSettings.homePage.displayBalanceCarousel
//               }
//               isApp={this.isApp}
//               notifications={notifications}
//             />
//           </div>
//           <div
//             className={classNames('menu__tab-bar', {
//               'menu__tab-bar--hidden': displaySidebar,
//             })}
//           >
//             {user &&
//               !hideNavigation &&
//               !isTabbarHidden && (
//                 <Tabbar
//                   toggleSidebar={this.toggleSidebar}
//                   notifications={notifications}
//                   isMenuItemHidden={userSettings.homePage.displayOldNavList}
//                   displayBadges={userSettings.homePage.displayBadgesInTabbar}
//                 />
//               )}
//           </div>
//           {user &&
//             !hideNavigation && <Navigation notifications={notifications} />}
//         </div>
//         <div className="app__main">{this.props.children}</div>
//         <div className="app__footer">
//           <Footer kontaktOssUrl="/kontakt" logoUrl="/test" />
//         </div>
//         <AppToast
//           message={currentMessage}
//           readMessage={() => this.props.onReadMessage(currentMessage.id)}
//         />
//         <button
//           type="button"
//           onClick={this.toggleSidebar}
//           className={classNames('app__overlay', {
//             'app__overlay--visible': displaySidebar,
//           })}
//           tabIndex={displaySidebar ? '0' : '-1'}
//         />
//         <div
//           className={classNames('app__sidebar', {
//             'app__sidebar--active': displaySidebar,
//           })}
//         >
//           {user && (
//             <Sidebar
//               toggleSidebar={this.toggleSidebar}
//               // currentPath={currentPath}
//               currentPath="/"
//               handleLogout={this.props.onSignOut}
//               logoutPath={PATH.HOME}
//               notifications={notifications}
//               disableOnClickOutside={!displaySidebar}
//             />
//           )}
//         </div>
//       </div>
//     );
//   }
// }

// App.defaultProps = {
//   user: undefined,
//   displaySidebar: false,
// };

// App.propTypes = {
//   title: PropTypes.string.isRequired,
//   // history: historyShape.isRequired,
//   children: PropTypes.element.isRequired,
//   onToggleSidebar: PropTypes.func.isRequired,
//   onSignOut: PropTypes.func.isRequired,
//   // location: PropTypes.shape({
//   //   pathname: PropTypes.string.isRequired,
//   // }).isRequired, // react router value
//   messages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   windowProperties: PropTypes.shape({}).isRequired,
//   user: PropTypes.shape({}),
//   userSettings: PropTypes.shape({}).isRequired,
//   displaySidebar: PropTypes.bool,
//   dispatchGetTransactions: PropTypes.func.isRequired,
//   dispatchGetRecurringTransactions: PropTypes.func.isRequired,
//   onGetAvtalegiroavtaler: PropTypes.func.isRequired,
//   onGetEfakturaavtaler: PropTypes.func.isRequired,
//   onGetKombinertAvtaler: PropTypes.func.isRequired,
//   onGetAvtalegiroavtalerForslag: PropTypes.func.isRequired,
//   onGetEfakturaavtalerForslag: PropTypes.func.isRequired,
//   onGetKombinertAvtalerForslag: PropTypes.func.isRequired,
//   accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
//   transactions: PropTypes.shape({
//     data: PropTypes.arrayOf(PropTypes.shape({})),
//     status: PropTypes.shape({
//       received: PropTypes.bool,
//     }),
//   }).isRequired,
//   recurringTransactions: PropTypes.shape({
//     data: PropTypes.arrayOf(PropTypes.shape({})),
//     status: PropTypes.shape({
//       received: PropTypes.bool,
//     }),
//   }).isRequired,
//   onReadMessage: PropTypes.func.isRequired,
//   onUpdateWindowSize: PropTypes.func.isRequired,
//   onInitEfaktura: PropTypes.func.isRequired,
// };

// const mapStateToProps = state => ({
//   title: state.title,
//   user: state.auth.user,
//   userSettings: state.user.settings,
//   displaySidebar: state.displaySidebar,
//   locale: state.locale,
//   // context: state.context,
//   messages: state.messages,
//   windowProperties: state.windowProperties,
//   transactions: dataSelector(state, 'transactions'),
//   accounts: state.accounts,
//   recurringTransactions: dataSelector(state, 'recurringTransactions'),
//   betalingsavtaler: dataSelector(state, 'betalingsavtaler'),
//   betalingsavtaleTilbydere: dataSelector(state, 'betalingsavtaleTilbydere'),
//   betalingsavtaleForslag: dataSelector(state, 'betalingsavtaleForslag'),
// });

// const mapDispatchToProps = dispatch => ({
//   onSignOut: bindActionCreators(signOut, dispatch),
//   onToggleSidebar: bindActionCreators(toggleSidebar, dispatch),
//   dispatchGetTransactions: bindActionCreators(getTransactions, dispatch),
//   dispatchGetRecurringTransactions: bindActionCreators(
//     getRecurringTransactions,
//     dispatch,
//   ),
//   onGetAvtalegiroavtaler: () => dispatch(getAvtalegiroavtaler()),
//   onGetEfakturaavtaler: () => dispatch(getEfakturaavtaler()),
//   onGetKombinertAvtaler: () => dispatch(getKombinertAvtaler()),
//   onGetAvtalegiroavtalerForslag: () => dispatch(getAvtalegiroavtalerForslag()),
//   onGetEfakturaavtalerForslag: () => dispatch(getEfakturaavtalerForslag()),
//   onGetKombinertAvtalerForslag: () => dispatch(getKombinertAvtalerForslag()),
//   onReadMessage: bindActionCreators(readMessage, dispatch),
//   onUpdateWindowSize: bindActionCreators(updateWindowSize, dispatch),
//   onInitEfaktura: bindActionCreators(initEfaktura, dispatch),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(App);
