const fixer = {
  base: 'EUR',
  date: '2017-06-21',
  rates: {
    AUD: 1.4738,
    BGN: 1.9558,
    BRL: 3.7028,
    CAD: 1.4803,
    CHF: 1.0857,
    CNY: 7.6091,
    CZK: 26.266,
    DKK: 7.4403,
    GBP: 0.8781,
    HKD: 8.6954,
    HRK: 7.4165,
    HUF: 309.13,
    IDR: 14834.0,
    ILS: 3.947,
    INR: 71.9,
    JPY: 124.21,
    KRW: 1273.1,
    MXN: 20.256,
    MYR: 4.7793,
    NOK: 9.5158,
    NZD: 1.5402,
    PHP: 56.053,
    PLN: 4.2368,
    RON: 4.59,
    RUB: 66.242,
    SEK: 9.7808,
    SGD: 1.5487,
    THB: 37.9,
    TRY: 3.9311,
    USD: 1.1147,
    ZAR: 14.526,
  },
};

export default fixer;
