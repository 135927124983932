/* eslint-disable no-console */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavListLink, TASK } from 'digitalbank-nav-list';
import { config } from 'services/economy.service';
import styled from 'styled-components';
// import { InfoCircleO } from 'digitalbank-icons';
import { NavListLinkButtonStyled } from 'styled/Buttons';
import { LogoStyled } from '../../styled-components';
import { getCategoryColor, getTransactionDescription } from '../../utils';
import CategoryIcon from '../CategoryIcon';

const CircleStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: ${props => props.background};

  svg {
    display: flex;
    width: 18px;
    height: 18px;
    fill: #fff;
  }
`;

const TransactionIcon = ({ src, transaction, ...other }) => {
  if (src) {
    return (
      <LogoStyled {...other}>
        <img src={src} alt={`Logo for ${transaction.text}`} />
      </LogoStyled>
    );
  }
  return (
    <CircleStyled {...other}>
      <CategoryIcon categoryAlias={transaction.category} />
    </CircleStyled>
  );
};

TransactionIcon.defaultProps = {
  src: undefined,
};

TransactionIcon.propTypes = {
  src: PropTypes.string,
  transaction: PropTypes.shape({}).isRequired,
};

class Transaction extends Component {
  shouldComponentUpdate() {
    return false;
  }
  render() {
    const { transaction, onClick } = this.props;
    const logoSrc =
      transaction.place && transaction.place.logoId > 0
        ? `${config.routes.logo}/${transaction.place.logoId}`
        : undefined;
    return (
      <div>
        <NavListLink
          component={NavListLinkButtonStyled}
          onClick={onClick}
          variant={TASK}
          title={{
            text: getTransactionDescription(transaction),
          }}
          description={{ text: transaction.category }}
          Icon={props => (
            <TransactionIcon
              {...props}
              src={logoSrc}
              transaction={transaction}
              background={getCategoryColor(transaction.category)}
            />
          )}
          info={{
            text: Number(transaction.amount).toLocaleString('nb-NO', {
              style: 'decimal',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
          }}
        />
      </div>
    );
  }
}

Transaction.defaultProps = {
  transaction: null,
};

Transaction.propTypes = {
  transaction: PropTypes.shape({
    text: PropTypes.string,
    transactionId: PropTypes.number,
    place: PropTypes.shape({
      placeName: PropTypes.string,
      logoId: PropTypes.number,
    }),
  }),
  onClick: PropTypes.func.isRequired,
};

export default Transaction;
