import React from 'react';
import { BasePage } from 'digitalbank-layout';
import { BasePageStyled } from 'styled';

export default () => (
  <BasePageStyled>
    <BasePage heading="Sparing">
      <span />
    </BasePage>
  </BasePageStyled>
);
