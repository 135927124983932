import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-css-collapse';
import HomeSettings from 'routes/Home/HomeSettings';
import classNames from 'classnames';
import { ChevronDown } from 'digitalbank-icons';

class CustomerSettingsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySettings: false,
    };
  }
  render() {
    return (
      <div className="customer-settings">
        <div className="customer-settings__heading">
          <h1>{this.props.heading}</h1>
          <button
            className={classNames(
              'customer-settings__heading-button-collapse button--with-icon',
              {
                'customer-settings__heading-button-collapse--active': this.state
                  .displaySettings,
              },
            )}
            onClick={() =>
              this.setState({
                displaySettings: !this.state.displaySettings,
              })
            }
          >
            <div className="button__content">
              {this.state.displaySettings && 'Lukk visning'}
              {!this.state.displaySettings && 'Endre visning'}
              <ChevronDown className="button__icon" />
            </div>
          </button>
        </div>
        <div className="customer-settings-collapse">
          <Collapse
            isOpen={this.state.displaySettings}
            className="collapse-transition"
          >
            <HomeSettings
              minOversiktSectionIsEnabled={
                this.props.minOversiktSectionIsEnabled
              }
            />
          </Collapse>
        </div>
      </div>
    );
  }
}
CustomerSettingsContainer.defaultProps = {
  minOversiktSectionIsEnabled: false,
};

CustomerSettingsContainer.propTypes = {
  heading: PropTypes.string.isRequired,
  minOversiktSectionIsEnabled: PropTypes.bool,
};

export default CustomerSettingsContainer;
