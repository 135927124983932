import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 19"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="M0.520662832,8.74552904 L7.09466283,8.7560118 L8.19066283,5.89028766 C8.24599617,5.74483939 8.39199617,5.65442559 8.54999617,5.66883939 C8.7073295,5.68325318 8.83466283,5.79856353 8.86199617,5.9512187 L9.5833295,9.98904628 L10.3873295,7.93835663 C10.4333295,7.82108077 10.5406628,7.73787387 10.6673295,7.72149456 C10.7959962,7.70511525 10.9206628,7.75752904 10.9959962,7.85908077 L11.3379962,8.3190118 L12.4226628,4.14556353 C12.4639962,3.98439111 12.6173295,3.8730118 12.7853295,3.88808077 C12.9539962,3.89987387 13.0899962,4.02828766 13.1079962,4.19339111 L13.9953295,12.2572532 L14.5179962,9.06394284 C14.5453295,8.89687387 14.6913295,8.7750118 14.8626628,8.7750118 L14.8633295,8.7750118 L21.4039962,8.78418422 C21.6286628,8.22008077 21.7739962,7.69987387 21.8419962,7.24125318 C23.0066628,-0.60050544 13.4553295,-1.63043647 10.9699962,2.03263249 C8.4853295,-1.63043647 -1.0666705,-0.60050544 0.0979961654,7.24125318 C0.164662832,7.68873594 0.304662832,8.19649456 0.520662832,8.74552904" />
      <path d="M15.3393333,9.30534483 L14.4513333,14.7327931 C14.424,14.8998621 14.2773333,15.0217241 14.1066667,15.0217241 L14.0973333,15.0217241 C13.9226667,15.0171379 13.778,14.8861034 13.7586667,14.7157586 L12.8,6 L12.0106667,9.03803448 C11.9773333,9.16710345 11.8713333,9.26537931 11.7386667,9.29027586 C11.6046667,9.31648276 11.4693333,9.26341379 11.39,9.15531034 L10.9886667,8.61544828 L9.96266667,11.2335172 C9.90533333,11.3776552 9.75733333,11.4661034 9.602,11.4523448 C9.446,11.4372759 9.31866667,11.3219655 9.292,11.1699655 L8.574,7.1517931 L7.84266667,9.06358621 C7.792,9.19724138 7.66133333,9.28634483 7.516,9.28634483 L7.51533333,9.28634483 L1,9.27651724 C2.354,12.0996552 5.56333333,15.7882759 11.1493333,18.5701379 C16.7106667,15.8007241 19.9153333,12.133069 21.2793333,9.31386207 L15.3393333,9.30534483 Z" />
    </g>
  </svg>
);
