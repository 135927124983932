import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-css-collapse';
import classNames from 'classnames';
import { ChevronDown } from 'digitalbank-icons';
import { formatAccountNumber } from 'utils/format.util';
import { getAccount } from 'services/account.service';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import * as KeyNumberActions from 'components/KeyNumbers/KeyNumbers.Actions';
import KeyNumberLegend from './KeyNumberLegend';
import KeyNumber from './KeyNumber';

class KeyNumbers extends Component {
  static getCreditCardKeyNumberFieldset(
    accounts,
    filter,
    onSelectCreditDisplayFilter,
  ) {
    // Check if there exists any credit accounts
    if (!accounts || accounts.length === 0) {
      // We return empty fieldset because no credit account is registered on this user.
      return <fieldset className="fieldset key-number__fieldset" />;
    }
    return (
      <fieldset className="fieldset key-number__fieldset">
        <KeyNumberLegend text="Kredittkort" />
        <input
          checked={filter === 'DISPLAY_AVAILABLE_AMOUNT'}
          type="radio"
          className="radio"
          id="DISPLAY_AVAILABLE_AMOUNT"
          name="creditCardDisplayFilter-options"
          value="DISPLAY_AVAILABLE_AMOUNT"
          onChange={() =>
            onSelectCreditDisplayFilter('DISPLAY_AVAILABLE_AMOUNT')
          }
        />
        <label className="radio__label" htmlFor="DISPLAY_AVAILABLE_AMOUNT">
          Disponibelt beløp
        </label>

        <input
          checked={filter === 'DISPLAY_WITHDRAWN_AMOUNT'}
          type="radio"
          className="radio"
          id="DISPLAY_WITHDRAWN_AMOUNT"
          name="creditCardDisplayFilter-options"
          value="DISPLAY_WITHDRAWN_AMOUNT"
          onChange={() =>
            onSelectCreditDisplayFilter('DISPLAY_WITHDRAWN_AMOUNT')
          }
        />
        <label className="radio__label" htmlFor="DISPLAY_WITHDRAWN_AMOUNT">
          Trukket kreditt
        </label>
      </fieldset>
    );
  }
  static getCheckboxes(accounts, onChange) {
    return accounts.map((a) => {
      const { account, selected } = a;
      return (
        <div key={`account-checkbox-${account.id}`}>
          <input
            type="checkbox"
            className="checkbox"
            id={`account-checkbox-${account.id}`}
            name="account-checkboxes"
            value={account.id}
            checked={selected}
            onChange={() => onChange(account.id)}
          />
          <label
            className="checkbox__label"
            htmlFor={`account-checkbox-${account.id}`}
          >
            <span className="checkbox__label-text">{account.accountName}</span>
            <span className="checkbox__label-info">
              {account.accountNumber &&
                formatAccountNumber(account.accountNumber)}
            </span>
          </label>
        </div>
      );
    });
  }
  static getKeyNumber(accounts, title, btnText, onChange, alias, changeable) {
    // Check if there exists any credit accounts
    if (!accounts || accounts.length === 0) {
      // We display nudging
      return (
        <KeyNumber
          title={title}
          alias={alias}
          changeable={changeable}
          onChange={keyNumberAlias => onChange(keyNumberAlias)}
        >
          <button className="button button--simple button--small">
            {btnText}
          </button>
        </KeyNumber>
      );
    }
    // Get total fond value
    let value = 0;
    accounts.forEach((a) => {
      // get account from id
      const account = getAccount(a.id);
      value += account.availableAmount;
    });
    return (
      <KeyNumber
        title={title}
        value={value}
        alias={alias}
        changeable={changeable}
        onChange={keyNumberAlias => onChange(keyNumberAlias)}
      />
    );
  }
  static getCustomKeyNumber(
    alias,
    fallbackAlias,
    creditAccounts,
    foundAccounts,
    onChange,
    changeable,
  ) {
    const keyNumberAlias =
      alias !== undefined && alias !== '' ? alias : fallbackAlias;
    switch (keyNumberAlias) {
      case 'CREDIT':
        return KeyNumbers.getKeyNumber(
          creditAccounts,
          'Kredittkort',
          'Bestill kredittkort',
          onChange,
          'CREDIT',
          changeable,
        );
      case 'FOUND':
        return KeyNumbers.getKeyNumber(
          foundAccounts,
          'Fond',
          'Opprett fond',
          onChange,
          'FOUND',
          changeable,
        );
      case 'STOCK':
        return KeyNumbers.getKeyNumber(
          [],
          'Aksjer',
          'Start sparing',
          onChange,
          'STOCK',
          changeable,
        );
      case 'LOAN':
        return KeyNumbers.getKeyNumber(
          [],
          'Mine lån',
          'Søk lån',
          onChange,
          'LOAN',
          changeable,
        );
      default:
        return ''; // If no alias or fallback alias is defined
    }
  }
  static getCustomKeyNumberFieldset(
    alias,
    creditAccounts,
    foundAccounts,
    onSelectCreditDisplayFilter,
    creditCardDisplayFilter,
  ) {
    // TODO: Add founds case if it should have any options
    switch (alias) {
      case 'CREDIT':
        return KeyNumbers.getCreditCardKeyNumberFieldset(
          creditAccounts,
          creditCardDisplayFilter,
          onSelectCreditDisplayFilter,
        );
      default:
        return <fieldset className="fieldset key-number__fieldset" />;
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      settingsIsOpened: false,
      settingsIsAnimated: false,
    };
    this.toggleSettings = this.toggleSettings.bind(this);
  }
  toggleSettings() {
    this.setState({
      ...this.state,
      settingsIsOpened: !this.state.settingsIsOpened,
    });
  }
  render() {
    const {
      onTogglePrivateAccount,
      onToggleSharedAccount,
      onToggleCreditAccount,
      onSelectDueDateRegisterFilter,
      onToggleHidePrivateTranfers,
      onSelectCreditDisplayFilter,
      selectFirstCustomKeyNumber,
      selectSecondCustomKeyNumber,
    } = this.props;

    const {
      privateAccounts,
      sharedAccounts,
      creditAccounts,
      foundAccounts,
      dueDateRegister,
      creditCardDisplayFilter,
      firstCustomKeyNumberAlias,
      secondCustomKeyNumberAlias,
    } = this.props.keyNumbers;

    const privateAccountsMapped = privateAccounts.map(a => ({
      account: getAccount(a.id),
      selected: a.selected,
    }));

    const privateAccountOptions = KeyNumbers.getCheckboxes(
      privateAccountsMapped,
      onTogglePrivateAccount,
    );

    const sharedAccountsMapped = sharedAccounts.map(a => ({
      account: getAccount(a.id),
      selected: a.selected,
    }));

    const sharedAccountOptions = KeyNumbers.getCheckboxes(
      sharedAccountsMapped,
      onToggleSharedAccount,
    );

    const creditAccountsMapped = creditAccounts.map(a => ({
      account: getAccount(a.id),
      selected: a.selected,
    }));

    const creditAccountOptions = KeyNumbers.getCheckboxes(
      creditAccountsMapped,
      onToggleCreditAccount,
    );

    const { settingsIsAnimated, settingsIsOpened } = this.state;
    const customKeyNumberIsChangeable = settingsIsOpened && settingsIsAnimated;
    const firstCustomKeyNumber = KeyNumbers.getCustomKeyNumber(
      firstCustomKeyNumberAlias,
      'CREDIT',
      creditAccounts,
      foundAccounts,
      selectFirstCustomKeyNumber,
      customKeyNumberIsChangeable,
    );

    const firstCustomKeyNumberFieldset = KeyNumbers.getCustomKeyNumberFieldset(
      firstCustomKeyNumberAlias,
      creditAccounts,
      foundAccounts,
      onSelectCreditDisplayFilter,
      creditCardDisplayFilter,
    );
    const secondCustomKeyNumber = KeyNumbers.getCustomKeyNumber(
      secondCustomKeyNumberAlias,
      'FOUND',
      creditAccounts,
      foundAccounts,
      selectSecondCustomKeyNumber,
      customKeyNumberIsChangeable,
    );
    const secondCustomKeyNumberFieldset = KeyNumbers.getCustomKeyNumberFieldset(
      secondCustomKeyNumberAlias,
      creditAccounts,
      foundAccounts,
      onSelectCreditDisplayFilter,
    );

    let availableAmount = 0;
    let reservedAmountOnPrivateAccounts = 0;

    const totalAccounts = _.filter(privateAccounts, x => x.selected)
      .concat(_.filter(sharedAccounts, x => x.selected))
      .concat(_.filter(creditAccounts, x => x.selected));

    totalAccounts.forEach((a) => {
      const currentAccount = getAccount(a.id);
      availableAmount += currentAccount.availableAmount;
      reservedAmountOnPrivateAccounts += currentAccount.reservedAmount;
    });

    return (
      <div
        className={classNames('key-numbers-wrapper', {
          'key-numbers-wrapper--animated':
            this.state.settingsIsOpened && this.state.settingsIsAnimated,
        })}
      >
        <ul className="key-numbers">
          <KeyNumber title="Disponibelt" value={availableAmount} />
          <KeyNumber
            title="Forfall"
            value={reservedAmountOnPrivateAccounts * -1}
          />
          {firstCustomKeyNumber}
          {secondCustomKeyNumber}
        </ul>
        <button
          onClick={this.toggleSettings}
          className={classNames('key-numbers__settings-btn', {
            'key-numbers__settings-btn--active': this.state.settingsIsOpened,
          })}
        >
          <ChevronDown className="key-numbers__settings-btn-icon" />
        </button>
        <Collapse
          isOpen={this.state.settingsIsOpened}
          className="collapse-transition"
          onRest={() =>
            this.setState({ ...this.state, settingsIsAnimated: true })
          }
        >
          <form className="key-numbers__settings">
            <fieldset className="fieldset key-number__fieldset">
              <KeyNumberLegend text="Egne kontoer" />
              {privateAccountOptions}
              {sharedAccountOptions &&
                sharedAccountOptions.length > 0 && (
                  <fieldset className="fieldset">
                    <KeyNumberLegend text="Kontoer jeg disponerer" />
                    {sharedAccountOptions}
                  </fieldset>
                )}
              {creditAccountOptions &&
                creditAccountOptions.length > 0 && (
                  <fieldset className="fieldset">
                    <KeyNumberLegend text="Kreditt?" />
                    {creditAccountOptions}
                  </fieldset>
                )}
            </fieldset>
            <fieldset className="fieldset key-number__fieldset">
              <KeyNumberLegend text="Vis forfall" />
              <input
                checked={dueDateRegister.filter === 'DISPLAY_NEXT_SEVEN_DAYS'}
                type="radio"
                className="radio"
                id="DISPLAY_NEXT_SEVEN_DAYS"
                name="duaDateRegister-options"
                value="DISPLAY_NEXT_SEVEN_DAYS"
                onChange={() =>
                  onSelectDueDateRegisterFilter('DISPLAY_NEXT_SEVEN_DAYS')
                }
              />
              <label className="radio__label" htmlFor="DISPLAY_NEXT_SEVEN_DAYS">
                Neste 7 dager
              </label>

              <input
                checked={dueDateRegister.filter === 'DISPLAY_ALL'}
                type="radio"
                className="radio"
                id="DISPLAY_ALL"
                name="duaDateRegister-options"
                value="DISPLAY_ALL"
                onChange={() => onSelectDueDateRegisterFilter('DISPLAY_ALL')}
              />
              <label className="radio__label" htmlFor="DISPLAY_ALL">
                Alle varslende trekk
              </label>

              <input
                checked={dueDateRegister.filter === 'DISPLAY_UNTIL_NEXT_PAYDAY'}
                type="radio"
                className="radio"
                id="DISPLAY_UNTIL_NEXT_PAYDAY"
                name="duaDateRegister-options"
                value="DISPLAY_UNTIL_NEXT_PAYDAY"
                onChange={() =>
                  onSelectDueDateRegisterFilter('DISPLAY_UNTIL_NEXT_PAYDAY')
                }
              />
              <label
                className="radio__label"
                htmlFor="DISPLAY_UNTIL_NEXT_PAYDAY"
              >
                Frem til lønningsdato
              </label>
              <fieldset className="fieldset">
                <KeyNumberLegend text="Skjul overføringer" />
                <input
                  type="checkbox"
                  className="checkbox"
                  id="hidePrivateTranfers"
                  name="account-checkboxes"
                  value="hidePrivateTranfers"
                  checked={dueDateRegister.hidePrivateTranfers}
                  onChange={onToggleHidePrivateTranfers}
                />
                <label
                  className="checkbox__label"
                  htmlFor="hidePrivateTranfers"
                >
                  Skjul forfall/overføringer mellom egne konto
                </label>
              </fieldset>
            </fieldset>
            {firstCustomKeyNumberFieldset}
            {secondCustomKeyNumberFieldset}
          </form>
        </Collapse>
      </div>
    );
  }
}

KeyNumbers.propTypes = {
  keyNumbers: PropTypes.shape({
    privateAccounts: PropTypes.array,
    sharedAccounts: PropTypes.array,
    creditAccounts: PropTypes.array,
    foundAccounts: PropTypes.array,
    dueDateRegister: PropTypes.shape({}).isRequired,
    creditCardDisplayFilter: PropTypes.string.isRequired,
    firstCustomKeyNumberAlias: PropTypes.string,
    secondCustomKeyNumberAlias: PropTypes.string,
  }).isRequired,
  onTogglePrivateAccount: PropTypes.func.isRequired,
  onToggleSharedAccount: PropTypes.func.isRequired,
  onToggleCreditAccount: PropTypes.func.isRequired,
  onSelectDueDateRegisterFilter: PropTypes.func.isRequired,
  onToggleHidePrivateTranfers: PropTypes.func.isRequired,
  onSelectCreditDisplayFilter: PropTypes.func.isRequired,
  selectFirstCustomKeyNumber: PropTypes.func.isRequired,
  selectSecondCustomKeyNumber: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  keyNumbers: state.keyNumbers,
});

const mapDispatchToProps = dispatch => ({
  onTogglePrivateAccount: bindActionCreators(
    KeyNumberActions.onTogglePrivateAccount,
    dispatch,
  ),
  onToggleSharedAccount: bindActionCreators(
    KeyNumberActions.onToggleSharedAccount,
    dispatch,
  ),
  onToggleCreditAccount: bindActionCreators(
    KeyNumberActions.onToggleCreditAccount,
    dispatch,
  ),
  onSelectDueDateRegisterFilter: bindActionCreators(
    KeyNumberActions.onSelectDueDateRegisterFilter,
    dispatch,
  ),
  onToggleHidePrivateTranfers: bindActionCreators(
    KeyNumberActions.onToggleHidePrivateTranfers,
    dispatch,
  ),
  onSelectCreditDisplayFilter: bindActionCreators(
    KeyNumberActions.onSelectCreditDisplayFilter,
    dispatch,
  ),
  selectFirstCustomKeyNumber: bindActionCreators(
    KeyNumberActions.selectFirstCustomKeyNumber,
    dispatch,
  ),
  selectSecondCustomKeyNumber: bindActionCreators(
    KeyNumberActions.selectSecondCustomKeyNumber,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(KeyNumbers);
