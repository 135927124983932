import React from 'react';
import PropTypes from 'prop-types';
import AmountIncreaser from 'components/AmountIncreaser/AmountIncreaser';

const AmountIncreaserField = ({ onAmountClick, disabled }) => {
  const amounts = [50, 100, 500, 1000];

  const onAmountClicked = (amount) => {
    if (document.activeElement && document.activeElement.type !== 'button') {
      document.activeElement.blur();
    }
    onAmountClick(amount);
  };

  return (
    <div className="form-group form-group__amount-increaser form-group--small">
      <AmountIncreaser
        onAmountClick={onAmountClicked}
        amounts={amounts}
        classNames="transfer-form__amount-increaser"
        disabled={disabled}
      />
    </div>
  );
};

AmountIncreaserField.defaultProps = {
  disabled: false,
};

AmountIncreaserField.propTypes = {
  onAmountClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AmountIncreaserField;
