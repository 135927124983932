import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isPristine, destroy } from 'redux-form';
import { bindActionCreators } from 'redux';
import i18n from 'i18n';
import moment from 'moment';
import fx from 'money';
import accounting from 'accounting';
import Alert from 'digitalbank-alert';
import { resetAccountPicker } from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import wizardPage from 'components/wizardPage';
import PaymentGlobalNavList from './PaymentGlobalNavList';
import { getNumber } from '../paymentGlobal.utils';
import * as PATHS from '../../paths';
import { formValuesShape } from '../paymentGlobal.shapes';
import fixer from '../fixer';
import PaymentGlobalStyled from '../PaymentGlobalStyled';

class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fxIsReady: true,
    };
    fx.rates = { ...fixer.rates, ...{ EUR: 1 } };
    // window.fetch('https://api.fixer.io/latest', {
    //   method: 'get',
    // }).then(response => response.json())
    // .then((data) => {
    //   fx.rates = Object.assign(data.rates, { EUR: 1 });
    //   this.setState({
    //     ...this.state,
    //     fxIsReady: true,
    //   });
    // }).catch((err) => {
    //   console.log(err); // eslint-disable-line
    // });
  }
  componentWillUnmount() {
    this.props.onDestroy('globalPaymentForm');
    this.props.onResetAccountPicker('globalPaymentFormFraKonto');
  }
  render() {
    const { fxIsReady } = this.state;
    const {
      fraKonto,
      kroner,
      oere,
      paymentCurrency,
      recipientName,
    } = this.props.formValues;
    const dueDate = moment(this.props.formValues.dato, 'L');
    const dueDatePrint = dueDate.format('D. MMMM');
    const dueDateIsToday = dueDate.isSame(moment(), 'day');
    const title = dueDateIsToday
      ? i18n(t => t.PAYMENT_GLOBAL_DONE)
      : i18n(t => t.PAYMENT_GLOBAL_DONE_ALT);
    const amount = getNumber(kroner, oere);
    const selectedCurrency = paymentCurrency !== '' ? paymentCurrency : 'NOK';
    const amountInAccountCurrency =
      amount > 0 && fxIsReady
        ? fx.convert(amount, { from: selectedCurrency, to: fraKonto.currency })
        : 0;
    const formattedAmountInAccountCurrency =
      amountInAccountCurrency > 0 && fxIsReady
        ? accounting.formatNumber(amountInAccountCurrency, 2, ' ', ',')
        : 0;

    return (
      <PaymentGlobalStyled>
        <div className="p-payment-global__receipt">
          <Alert type="success" large title={title}>
            <span>
              <b>
                {`${accounting.formatNumber(
                  amount,
                  2,
                  ' ',
                  ',',
                )} ${selectedCurrency} `}
              </b>
              {`(${i18n(
                t => t.PAYMENT_GLOBAL_ESTIMATED_AMOUNT_TO,
              ).toLowerCase()}`}
              {` ${formattedAmountInAccountCurrency} ${fraKonto.currency})`}
              {` ${i18n(t => t.PAYMENT_GLOBAL_RECEIPT_TO)} ${recipientName}`}
              {` ${i18n(t => t.FROM).toLowerCase()} ${fraKonto.accountName}`}
              <b>{` ${dueDatePrint}`}.</b>
            </span>
          </Alert>
          <PaymentGlobalNavList />
        </div>
      </PaymentGlobalStyled>
    );
  }
}

Receipt.propTypes = {
  formValues: PropTypes.shape(formValuesShape).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues('globalPaymentForm')(state),
});
const mapDispatchToProps = dispatch => ({
  onDestroy: bindActionCreators(destroy, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

export default wizardPage({
  redirectTest: state => isPristine('globalPaymentForm')(state),
  redirectPath: PATHS.PAYMENT_GLOBAL,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Receipt),
);
