import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 19 22"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="M10.032,6.24972549 C10.449525,5.61647059 11.11025,4.71015686 11.944825,3.8405098 L11.945775,3.8405098 C12.652575,3.10243137 14.036725,2.15513725 14.036725,2.15513725 C14.244775,2.01235294 14.22245,1.8134902 13.9859,1.71341176 L12.31295,1.00337255 C12.0764,0.902862745 11.754825,0.976196078 11.598075,1.16556863 C11.598075,1.16556863 10.3607,2.77760784 8.90435,6.20917647 C4.40135,4.35945098 0.121125,6.95027451 0.121125,11.4555294 C0.121125,16.1613725 4.1078,23.3096471 9.49905,21.1519216 C15.1772,23.3834118 18.879825,16.1618039 18.879825,11.4555294 C18.879825,6.91145098 15.032325,4.24729412 10.032,6.24972549 Z" />
      <path d="M9.023575,4.72568627 C9.283875,4.70584314 9.513775,4.49662745 9.534675,4.26066667 C9.534675,4.26066667 9.701875,2.42043137 8.2897,1.13666667 C6.87325,-0.147529412 4.8507,0.00560784314 4.8507,0.00560784314 C4.5904,0.0254509804 4.3605,0.234666667 4.339125,0.470627451 C4.339125,0.470627451 4.169075,2.31172549 5.58315,3.59592157 C6.996275,4.87882353 9.023575,4.72568627 9.023575,4.72568627 Z" />
    </g>
  </svg>
);
