import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import { BasePageStyled } from 'styled';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import styled from 'styled-components';
import { Umbrella, Plane } from 'digitalbank-icons';
import i18n from 'i18n';

const CartLinkIcon = styled.div`
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #c40000;
  border-radius: 50%;
  /* border: 1px solid #dedede; */

  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    background: #c40000;
    transition: transform 250ms ease-in-out;
    border-radius: 50%;
    z-index: 1;
  }

  svg {
    height: 56%;
    max-height: 24px;
    position: relative;
    z-index: 2;
  }
`;

const CardLinkTitle = styled.h3`
  font-size: 1.275rem;
  margin-bottom: 0;
  width: 100%;
  text-align: center;

  position: relative;
  z-index: 2;
  transition: color 250ms ease-in-out;
`;

const CardLinkStyled = styled.div`
  a {
    /* 
    border: 1px solid #dedede; */
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 24px;
    text-decoration: none;
    overflow: hidden;

    &:hover,
    &:focus {
      ${CartLinkIcon} {
        &::after {
          transform: scale(12);
        }
      }
      ${CardLinkTitle} {
        color: #fff;
      }
    }
  }

  ${CartLinkIcon} {
    margin-bottom: 8px;
  }
`;

const CardLink = ({ to, title, Icon }) => (
  <CardLinkStyled>
    <Link to={to}>
      <CartLinkIcon>
        <Icon />
      </CartLinkIcon>
      <CardLinkTitle>{title}</CardLinkTitle>
    </Link>
  </CardLinkStyled>
);

CardLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
};

const TravelPageCards = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;

  ${CardLinkStyled} {
    margin-bottom: 24px;
  }
`;

const TravelPageStyled = styled(BasePageStyled)``;

const Page = () => (
  <TravelPageStyled>
    <BasePage heading={i18n(t => t.ROUTE_TITLE_TRAVEL)}>
      <p>
        Nå kan du tagge alle utgifter automatisk mens du er på reise. Du kan
        enkelt legge til eller trekke fra utgifter senere.
      </p>
      <TravelPageCards>
        <CardLink
          to={PATHS.CREATE_AUTOMATIC_TAG_TRAVEL}
          title="Privat reise"
          Icon={Umbrella}
        />
        <CardLink
          to={PATHS.CREATE_AUTOMATIC_TAG_TRAVEL_WITH_WORK}
          title="Jobbreise"
          Icon={Plane}
        />
      </TravelPageCards>
    </BasePage>
  </TravelPageStyled>
);

export default Page;
