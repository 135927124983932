import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from 'i18n';
import { categorizeRecipients, filterRecipients } from 'utils/recipients.util';
import RecipientShape from 'shapes/RecipientShape';
import { formatAccountNumber } from 'utils/format.util';
import _ from 'lodash';
import { Search } from 'digitalbank-icons';
import { isApp, isWebApp } from 'utils/detect.util';

const RecipientOption = ({ recipient, onSelect }) => {
  const names = recipient.name.split(' ');
  const numberOfNames = names.length;
  const firstName =
    numberOfNames > 1 ? names.slice(0, numberOfNames - 1).join(' ') : names[0];
  const lastName = numberOfNames > 1 ? names[numberOfNames - 1] : '';
  return (
    <li className="recipient-select__option">
      <button
        type="button"
        onClick={() => onSelect(recipient)}
        className="recipient-select__option-button"
      >
        <div className="recipient-select__option-heading">
          <span className="recipient-select__option-heading-first">
            {firstName}
          </span>
          <span className="recipient-select__option-heading-last">{` ${lastName}`}</span>
        </div>
        <div className="recipient-select__option-info">
          {formatAccountNumber(recipient.account)}
        </div>
      </button>
    </li>
  );
};

RecipientOption.propTypes = {
  recipient: RecipientShape.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const RecipientList = ({ recipients, onSelect }) => (
  <ul className="recipient-select__category-list">
    {recipients.map(recipient => (
      <RecipientOption
        key={`recipient-${recipient.name}`}
        recipient={recipient}
        onSelect={onSelect}
      />
    ))}
  </ul>
);

RecipientList.defaultProps = {
  recipients: [],
};

RecipientList.propTypes = {
  recipients: PropTypes.arrayOf(RecipientShape),
  onSelect: PropTypes.func.isRequired,
};

const RecipientCategories = ({ categories, onSelect }) => {
  const numberOfCategoriesWithRecipients = _.filter(categories, x =>
    x.recipients.some(y => y.display),
  ).length;
  return (
    <ul className="recipient-select__categories">
      {categories.length > 0 &&
        categories.map(category => (
          <li
            key={`recipient-category-${category.name}`}
            className="recipient-select__category"
          >
            {category.recipients.some(x => x.display) &&
              numberOfCategoriesWithRecipients > 1 && (
                <h3 className="recipient-select__category-heading">
                  {category.name}
                </h3>
              )}
            <RecipientList
              recipients={_.filter(category.recipients, x => x.display)}
              onSelect={onSelect}
            />
          </li>
        ))}
    </ul>
  );
};

RecipientCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

class RecipientSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
    };
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
  }
  handleSearchInputChange(e) {
    this.setState({
      ...this.state,
      searchValue: e.target.value,
    });
  }
  render() {
    const {
      header, onSelect, closeText, recipients, close,
    } = this.props;

    const { searchValue } = this.state;
    const filteredRecipients = filterRecipients(recipients, searchValue);
    const categoriezedRecipients = categorizeRecipients(filteredRecipients);

    return (
      <div className="recipient-select">
        <div className="recipient-select__content">
          <div
            className={classNames('recipient-select__header', {
              'recipient-select__header--ios': isApp() || isWebApp(),
            })}
          >
            <div className="recipient-select__header-left">
              <button
                type="button"
                onClick={close}
                className="link recipient-select__header-button"
              >
                {closeText}
              </button>
            </div>
            <div className="recipient-select__header-center">{header}</div>
            <div className="recipient-select__header-right" />
          </div>
          <div className="recipient-select__search-input">
            <div className="simple-search-input">
              <label
                htmlFor="simple-search-input"
                className="simple-search-input__label"
                aria-hidden
              >
                <Search className="simple-search-input__label-icon" />
              </label>
              <input
                type="text"
                className="simple-search-input__field"
                placeholder={i18n(t => t.SEARCH)}
                name="simple-search-input"
                id="simple-search-input"
                aria-label={i18n(t => t.SEARCH)}
                value={searchValue}
                onFocus={this.toggleSearchMode}
                onChange={this.handleSearchInputChange}
              />
            </div>
          </div>
          <div className="recipient-select__data-view">
            <RecipientCategories
              categories={categoriezedRecipients}
              onSelect={onSelect}
            />
            {searchValue &&
              filteredRecipients.length === 0 && (
                <p style={{ textAlign: 'center', margin: '20px 0' }}>
                  Beklager, vi fant ingen mottakere.
                </p>
              )}
          </div>
        </div>
      </div>
    );
  }
}

RecipientSelect.defaultProps = {
  recipients: [],
};

RecipientSelect.propTypes = {
  header: PropTypes.string.isRequired,
  closeText: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  recipients: PropTypes.arrayOf(RecipientShape),
};

export default RecipientSelect;
