/* eslint-disable max-len */
import PropTypes from 'prop-types';

const formValuesShape = {
  fraKonto: PropTypes.shape({}),
  tilKonto: PropTypes.string,
  kroner: PropTypes.string,
  oere: PropTypes.string,
  paymentCurrency: PropTypes.string,
  country: PropTypes.string,
  recipientName: PropTypes.string,
  message: PropTypes.string,
  dato: PropTypes.string,
  recipientAddress1: PropTypes.string,
  recipientAddress2: PropTypes.string,
  recipientAddress3: PropTypes.string,
  bankCode: PropTypes.string,
  extraBankCode: PropTypes.string,
  expensesArePaidBy: PropTypes.string,
  expressPayment: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  paymentPurpose: PropTypes.string,
  paymentType: PropTypes.string,
  sendEmailConfirmationToReceiver: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  receiverEmail: PropTypes.string,
  agreedRateWithBank: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
    .isRequired,
  rateWithBank: PropTypes.string,
  contactPerson: PropTypes.string,
};

export default formValuesShape;

export { formValuesShape };
