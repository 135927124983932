import React from 'react';
import Button from 'digitalbank-buttons';
import NavList, {
  NavListLink,
  NavListTask,
  TASK,
  POSITIVE,
} from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { Lock, Mobile } from 'digitalbank-icons';
import BankIDMobile from 'components/Icons/BankIDMobile';
import BankID from 'components/Icons/BankID';
import Go3 from 'components/Icons/Go3';
import SettingsCard from './SettingsCard';

const SettingsCardForSecurity = () => (
  <SettingsCard heading="Sikkerhet">
    <NavList
      items={[
        <NavListTask
          key="bankid-mobil"
          Icon={BankIDMobile}
          title={{ text: 'BankID på  mobil' }}
          description={{
            text: 'Ikke aktivert',
          }}
          aside={
            <Button size="small" variant="edit">
              Aktiver
            </Button>
          }
        />,
        <NavListLink
          key="bankid"
          Icon={BankID}
          title={{ text: 'BankID' }}
          description={{
            text: 'Sist brukt i dag',
          }}
          info={{
            text: 'Aktiv',
            variant: POSITIVE,
          }}
          variant={TASK}
          component={Link}
          to={PATHS.BANKID}
        />,
        <NavListLink
          key="alternativ-innlogging"
          Icon={Lock}
          title={{ text: 'Alternativ innlogging' }}
          description={{
            text: 'Sist brukt for 5 mnd siden',
          }}
          info={{
            text: 'Aktiv',
            variant: POSITIVE,
          }}
          variant={TASK}
          component={Link}
          to={PATHS.SETTINGS}
        />,
        <NavListLink
          key="innlogging-mobilbank"
          Icon={Mobile}
          title={{ text: 'Innlogging mobilbank' }}
          description={{
            text: 'Sist brukt for 9 dager siden',
          }}
          info={{
            text: 'Aktiv',
            variant: POSITIVE,
          }}
          variant={TASK}
          component={Link}
          to={PATHS.SETTINGS}
        />,
        <NavListTask
          key="bankid-ungdom"
          Icon={BankID}
          title={{ text: 'BankID ungdom' }}
          description={{
            text: 'For ungdom 13-18 år',
          }}
          aside={
            <Button
              size="small"
              variant="edit"
              component={Link}
              to={PATHS.SETTINGS}
            >
              Bestill
            </Button>
          }
        />,
        <NavListLink
          key="bestille-kodebrikke"
          Icon={Go3}
          title={{ text: 'Bestille ny kodebrikke' }}
          description={{
            text: 'Alternativ innlogging og BankID',
          }}
          variant={TASK}
          component={Link}
          to={PATHS.BANKID_NEW_KODEBRIKKE_ORDER}
        />,
      ]}
    />
  </SettingsCard>
);

SettingsCardForSecurity.propTypes = {};

export default SettingsCardForSecurity;
