import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'digitalbank-buttons';
import Collapse from 'react-css-collapse';

class ReadMoreCollapse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const { isOpen } = this.state;
    const { button } = this.props;
    return (
      <article className={this.props.className}>
        {this.props.children}
        <Collapse isOpen={isOpen} className="collapse-transition">
          {this.props.collapseContent}
        </Collapse>
        <Button
          variant="link"
          onClick={() => this.setState({ isOpen: !isOpen })}
        >
          {isOpen && button.open}
          {!isOpen && button.closed}
        </Button>
      </article>
    );
  }
}

ReadMoreCollapse.defaultProps = {
  children: null,
  collapseContent: null,
  button: {
    open: 'Skjul informasjon',
    closed: 'Mer informasjon',
  },
  className: null,
};
ReadMoreCollapse.propTypes = {
  children: PropTypes.node,
  collapseContent: PropTypes.node,
  button: PropTypes.shape({
    open: PropTypes.string,
    closed: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default ReadMoreCollapse;
