import React from 'react';
import PropTypes from 'prop-types';
import { RegionalBlockingRegions } from 'data';
import _ from 'lodash';

const getLocationById = (locationOptions, id) =>
  _.find(locationOptions, location => location.id === id);

const RegionalBlockingOpenLocations = ({ openLocationIds }) => {
  if (openLocationIds === undefined) return null;
  const indexOfDanmarkOgSverige = openLocationIds.indexOf(1);
  const danmarkOgSverigeExist = indexOfDanmarkOgSverige > -1;
  const openLocations = openLocationIds.map((locationId, locationIndex) => {
    const location = getLocationById(RegionalBlockingRegions, locationId);
    const isLastOption = openLocationIds.length === locationIndex + 1;
    const isSecondLastOption = openLocationIds.length === locationIndex + 2;
    const isDanmarkOgSverige = locationIndex === 1;
    const nextOptionIsDanmarkOgSverige =
      danmarkOgSverigeExist && indexOfDanmarkOgSverige === locationIndex + 1;
    let ret = '';
    if (isDanmarkOgSverige) {
      const locations = location.name.replace(/ /g, '').split('og');
      if (isSecondLastOption) {
        ret = `${locations[0]}, ${locations[1]} og `;
      } else if (isLastOption) {
        ret = `${location.name}.`;
      } else {
        ret = `${locations[0]}, ${locations[1]}, `;
      }
    } else if (isSecondLastOption && !nextOptionIsDanmarkOgSverige) {
      ret = `${location.name} og `;
    } else if (isLastOption) {
      ret = `${location.name}.`;
    } else {
      ret = `${location.name}, `;
    }
    return <span key={`open-localtion-${location.name}`}>{ret}</span>;
  });

  return (
    <div className="regional-blocking-open-locations">
      <p className="regional-blocking-open-locations__heading">
        Kortene dine er åpne i
      </p>
      <p className="regional-blocking-open-locations__locations">
        {openLocations}
      </p>
    </div>
  );
};

RegionalBlockingOpenLocations.defaultProps = {
  openLocationIds: undefined,
};

RegionalBlockingOpenLocations.propTypes = {
  openLocationIds: PropTypes.arrayOf(PropTypes.number),
};

export default RegionalBlockingOpenLocations;
