import React from 'react';
import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import SettingsCard from './SettingsCard';
import MyFamilyIcon from './MyFamilyIcon';

const SettingsCardForMyFamily = () => (
  <SettingsCard
    heading="Min familie"
    className="settings-card-my-family"
    aside={<MyFamilyIcon className="settings-card-my-family__icon" />}
    description={
      <p>
        På Min familie får du oversikt over kontoene og kortene til de du er
        registrert som forelder til eller verge for.
      </p>
    }
  >
    <NavList
      items={[
        <NavListLink
          key="min-familie"
          title={{ text: 'Min familie' }}
          info={{
            text: 'Olav, Jeanette',
          }}
          component={Link}
          to={PATHS.SETTINGS}
          variant={TASK}
        />,
      ]}
    />
  </SettingsCard>
);

SettingsCardForMyFamily.propTypes = {};

export default SettingsCardForMyFamily;
