import shortid from 'shortid';

import { ADD_MESSAGE, READ_MESSAGE } from './actionTypes';

const addMessage = message => ({
  type: ADD_MESSAGE,
  message,
});

const readMessage = id => ({
  type: READ_MESSAGE,
  id,
});

const registerMessage = newMessage => (dispatch) => {
  const id = shortid.generate();
  const message = {
    id,
    ...newMessage,
    isRead: false,
    timeout: newMessage.timeout || 2500,
    type: newMessage.type || 'success',
  };
  dispatch(addMessage(message));
};

export default addMessage;

export { addMessage, registerMessage, readMessage };
