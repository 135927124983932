import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { getIcon } from 'digitalbank-icons';

const NavigationLink = ({
  path, title, icon, handleClick,
}) => {
  const LinkIcon = getIcon(icon);

  return (
    <NavLink
      exact
      to={path}
      className="navigation__link"
      activeClassName="navigation__link--active"
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      <LinkIcon className="navigation__icon" aria-hidden role="presentation" />
      <span className="navigation__label">{title}</span>
    </NavLink>
  );
};

NavigationLink.defaultProps = {
  handleClick: undefined,
};

NavigationLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
};

export default NavigationLink;
