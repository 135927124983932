export const config = {
  sm: {
    width: 430,
    columns: 1,
  },
  md: {
    width: 768,
    columns: 2,
  },
  lg: {
    width: 992,
    columns: 3,
  },
  xl: {
    width: 1200,
    columns: 3,
  },
};

export const getLayoutSize = () => {
  const n = window.innerWidth;
  if (n < config.md.width) return 'sm';
  if (n < config.lg.width) return 'md';
  if (n < config.xl.width) return 'lg';
  return 'xl';
};
