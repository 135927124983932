import React from 'react';
import PropTypes from 'prop-types';
import { UlWithoutStyle } from 'styled';
import styled from 'styled-components';
import moment from 'moment';
import ChoiceCard from './ChoiceCard';

const filters = [
  {
    id: '1',
    text: `I ${moment()
      .subtract(1, 'months')
      .format('MMM')}`,
  },
  {
    id: '2',
    text: 'Siste 3 mnd',
  },
  {
    id: '3',
    text: 'Siste 6 mnd',
  },
  {
    id: '4',
    text: 'I år',
  },
];

export const EconomyDateFilterStyled = styled(UlWithoutStyle)`
  display: flex;
  flex-flow: wrap;

  li {
    margin: 5px;
  }
`;

const EconomyDateFilter = ({ activeDateFilter, setDate }) => (
  <EconomyDateFilterStyled>
    {filters.map(filter => (
      <li key={`datefilter-${filter.id}`}>
        <ChoiceCard
          name="datefilter"
          id={`datefilter-${filter.id}`}
          onChange={() => setDate(filter.id)}
          checked={activeDateFilter === filter.id}
        >
          {filter.text}
        </ChoiceCard>
      </li>
    ))}
  </EconomyDateFilterStyled>
);

EconomyDateFilter.propTypes = {
  activeDateFilter: PropTypes.string.isRequired,
  setDate: PropTypes.func.isRequired,
};
export default EconomyDateFilter;
