export default {
  chart: {
    title: {
      text: '',
    },
  },
  title: {
    text: '',
  },
  tooltip: {
    xDateFormat: '%d.%m.%Y',
    shadow: false,
    backgroundColor: '#333',
    borderWidth: 0,
    borderRadius: 0,
    style: {
      color: '#fff',
    },
  },
  xAxis: {
    lineColor: '#D4D4D4',
    lineWidth: 1,
    tickColor: 'transparent',
    marker: {
      radius: 2,
    },
    labels: {
      style: {
        fontSize: '14px',
      },
    },
  },
  yAxis: {
    labels: {
      enabled: false,
    },
    title: {
      text: '',
    },
    lineColor: '#D4D4D4',
    lineWidth: 1,
    gridLineColor: 'transparent',
  },
};
