/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import { getTag, editTag } from 'services/tag.service';
// import i18n from 'i18n';
import styled from 'styled-components';
import { spacing, breakpoints, layout } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import transactions from 'data/transactions';
import categories from 'data/categories';
import { getCategoryData } from 'routes/Economy/utils';
import { Categories } from 'routes/Economy/components/Categories/Categories';
import Transactions from 'routes/Economy/components/Transactions/Transactions';
import TransactionModal from 'routes/Economy/components/Transactions/TransactionModal';
import { Modal, ModalCard } from 'digitalbank-modal';
import { Transition } from 'react-transition-group';
// import { HorizontalLayoutSpacing } from 'styled';
// // import TagDetails from './TagDetails';
import TagHeader, { TagHeading, TagDescription } from './TagHeader';
import TagActionList from './TagActionList';
import CreateAutomaticTagFormContainer, {
  Form,
} from '../AutomaticTag/Wizard/CreateAutomaticTagFormContainer';
import TagBudgetGraph from '../components/TagBudgetGraph';
import Map from '../components/Map';
import Fade from '../components/Fade';
import PlayButton, { PlayButtonStyled } from '../components/PlayButton';

const tagGraphAnimationDuration = 250;

const SectionStyled = styled.section`
  ${horizontalLayoutSpacing({ phone: false })}
  margin-bottom: ${spacing.layoutSpacing.md}px;
`;

const SubheadingStyled = styled.h4`
  padding: 0 ${layout.horizontalSpacing.phone}px;
`;

const TagBudgetGraphWrapper = styled.div``;

const MapStyled = styled.div`
  position: absolute;
  top: 50px;
  left: ${layout.horizontalSpacing.phone}px;
  right: ${layout.horizontalSpacing.phone}px;
  z-index: 1;

  @media (min-width: ${breakpoints.tablet}px) {
    top: 0;
    left: ${layout.horizontalSpacing.tablet}px;
    right: ${layout.horizontalSpacing.tablet}px;
  }
`;

const TagPageStyled = styled.div`
  .p-general {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__main {
      /* We need this to position the map */
      position: relative;
    }
  } 
  
  ${TagBudgetGraphWrapper} {
    ${horizontalLayoutSpacing}
    margin-bottom: ${spacing.layoutSpacing.md}px;
  }

  ${PlayButtonStyled} {
    position: relative;
    z-index: 2;
    display: block;
    margin: 0 auto ${spacing.layoutSpacing.lg}px;
  }

  ${Form} {
    /* ${horizontalLayoutSpacing}; */
    margin-bottom: ${spacing.layoutSpacing.md}px;
  }

  .action-list {
    margin-bottom: ${spacing.layoutSpacing.md}px;
    ${horizontalLayoutSpacing({ phone: false })}
  }

  .detail-section {
    margin-bottom: ${spacing.layoutSpacing.xs}px;
    ${horizontalLayoutSpacing};
  }
`;

const TagBudgetGraphDataValue = styled.div`
  font-size: 28px;
  font-weight: 300;
  font-family: 'Oswald';
  line-height: 1.2;
`;

const TagBudgetGraphDataDescription = styled.div`
  font-size: 14px;
  color: #777;
  line-height: 1.2;
`;
const TagBudgetGraphData = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.componentSpacing.xs}px;

  ${TagBudgetGraphDataDescription} {
    :last-of-type {
      text-align: right;
    }
  }
`;

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: null,
      isDeletePending: false,
      isEditing: false,
      showTransactions: false,
      modal: {
        isVisible: false,
        isOpened: false,
        transaction: null,
      },
    };
    this.getTag = this.getTag.bind(this);
    this.setTransaction = this.setTransaction.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  componentDidMount() {
    this.getTag();
    /* eslint-disable-next-line */
    this.categoryData = getCategoryData({
      categories,
      transactions: transactions.slice(0, 12),
      categoryName: 'Utgifter',
    });
  }
  getTag() {
    getTag(this.props.match.params.id)
      .then((tag) => {
        this.setState({ tag, isEditing: false });
      })
      .catch((error) => {
        /* eslint-disable-next-line */
        console.error(error);
      });
  }
  setTransaction(newTransaction) {
    this.setState({
      modal: {
        transaction: newTransaction,
        isOpened: true,
        isVisible: !this.state.modal.isVisible,
      },
    });
  }
  closeModal() {
    this.setState({
      modal: {
        ...this.state.modal,
        isVisible: !this.state.modal.isVisible,
      },
    });
    setTimeout(() => {
      this.setState({
        modal: {
          ...this.state.modal,
          transaction: null,
        },
      });
    }, 600);
  }
  render() {
    const { history } = this.props;
    const {
      tag, isDeletePending, isEditing, showTransactions,
    } = this.state;
    const isLoading = !tag;
    const hasBudget = tag && tag.budget && tag.budget > 0;
    const tagBudgetPercentage = hasBudget
      ? Math.round((tag.amount / tag.budget) * 100)
      : 0;
    return (
      <TagPageStyled>
        <BasePage heading={tag ? tag.name : ''}>
          {isLoading && <span />}
          {!isLoading && (
            <Fade in={!isLoading} appear duration={200}>
              <TagHeader>
                <TagHeading>{tag.name}</TagHeading>
                {tag.diet &&
                  tag.country && (
                    <TagDescription>
                      Jobbreise med diett i {tag.country}
                    </TagDescription>
                  )}
              </TagHeader>
              {hasBudget && (
                <TagBudgetGraphWrapper>
                  <TagBudgetGraphData>
                    <div>
                      <TagBudgetGraphDataValue>
                        {tag.amount.toLocaleString('nb-NO', {
                          style: 'decimal',
                          maximumFractionDigits: 0,
                        })}
                      </TagBudgetGraphDataValue>
                      <TagBudgetGraphDataDescription>
                        Bruk hittil
                      </TagBudgetGraphDataDescription>
                    </div>
                    <div>
                      <TagBudgetGraphDataValue>
                        {Number(tag.budget).toLocaleString('nb-NO', {
                          style: 'decimal',
                          maximumFractionDigits: 0,
                        })}
                      </TagBudgetGraphDataValue>
                      <TagBudgetGraphDataDescription>
                        Budsjett
                      </TagBudgetGraphDataDescription>
                    </div>
                  </TagBudgetGraphData>
                  <Transition
                    in={hasBudget}
                    timeout={tagGraphAnimationDuration}
                    appear
                  >
                    {state => (
                      <TagBudgetGraph
                        animationDuration={tagGraphAnimationDuration}
                        percentage={
                          tagBudgetPercentage > 100 ? 100 : tagBudgetPercentage
                        }
                        state={state}
                        height="8px"
                        isActive={tag.isActive}
                      />
                    )}
                  </Transition>
                </TagBudgetGraphWrapper>
              )}
              <PlayButton
                onClick={() => {
                  editTag({
                    id: tag.id,
                    data: { isActive: !tag.isActive },
                  }).then(() => this.getTag());
                }}
                text={{
                  start: tag.isTravelTag ? 'Start' : 'Start automatisk tagging',
                }}
                isInitiallyActive={tag.isActive}
              />
              {!showTransactions && (
                <Fade appear in={!showTransactions}>
                  <SectionStyled>
                    <SubheadingStyled>Bruk</SubheadingStyled>
                    <Categories
                      setCategory={() => {
                        this.setState({
                          showTransactions: true,
                        });
                      }}
                      categoryData={this.categoryData}
                    />
                  </SectionStyled>
                </Fade>)}
              {showTransactions && (
                <Fade appear in={showTransactions}>
                  <SectionStyled>
                    <SubheadingStyled>Transaksjoner</SubheadingStyled>
                    <Transactions
                      id="tag-transactions"
                      transactions={transactions.slice(0, 12)}
                      onClick={transaction => this.setTransaction(transaction)}
                    />
                    <TransactionModal
                      toggle={this.closeModal}
                      visible={this.state.modal.isVisible}
                      isOpened={this.state.modal.isOpened}
                      transaction={this.state.modal.transaction}
                    />
                  </SectionStyled>
                </Fade>
              )}
              {/* <TagDetails tag={tag} /> */}
              <Modal
                visible={isEditing}
                toggle={() => this.setState({ isEditing: !isEditing })}
              >
                <ModalCard
                  toggle={() => this.setState({ isEditing: !isEditing })}
                >
                  <CreateAutomaticTagFormContainer
                    isEditing
                    isTravelTag={tag.isTravelTag}
                    isTravelWithWork={tag.isTravelWithWork}
                    history={history}
                    initialValues={tag}
                    tag={tag}
                    onSubmitSuccessCallback={() => {
                      this.getTag();
                    }}
                  />
                  {/* <ModalCloseButton
                    toggle={() => this.setState({ isEditing: !isEditing })}
                  /> */}
                </ModalCard>
              </Modal>
              <TagActionList
                isEditing={isEditing}
                isDeletePending={isDeletePending}
                getTag={this.getTag}
                history={history}
                tag={tag}
                toggleEditMode={() => this.setState({ isEditing: !isEditing })}
                setDeletePending={() =>
                  this.setState({ isDeletePending: true })
                }
              />
              {hasBudget &&
                tag.isTravelTag && (
                  <MapStyled>
                    <Map />
                  </MapStyled>
                )}
            </Fade>
          )}
        </BasePage>
      </TagPageStyled>
    );
  }
}

Page.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Page;
