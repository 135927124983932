/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import i18n from 'i18n';
import { InputField } from 'digitalbank-form';
import { QuestionMark } from 'digitalbank-icons';
import classNames from 'classnames';
import Collapse from 'react-css-collapse';

const getBankNameFromBankCode = (code) => {
  if (
    code === '221172610' ||
    code === 'FW221172610' ||
    code === 'FW/221172610' ||
    code === 'FW+221172610'
  ) {
    return 'Citibank N.A.';
  }
  return i18n(i => i.PAYMENT_GLOBAL_LABEL_BANK_NAME);
};

const ExtraBankCodeFields = ({ help, extraBankCode, toggleHelp }) => (
  <div>
    <div className="connected-form-groups">
      <Field
        name="extraBankCode"
        autoCapitalize="characters"
        label={i18n(i => i.PAYMENT_GLOBAL_LABEL_BANK_CODE)}
        component={InputField}
        type="text"
        rightAddon={() => (
          <button
            type="button"
            className="form-control__help-button"
            onClick={toggleHelp}
          >
            <QuestionMark
              className="form-control__help-button-icon"
              active={help.extraBankCode}
            />
          </button>
        )}
      />
      <div className="form-group form-group--with-help-button-indent">
        <div className="form-control form-control--disabled">
          {getBankNameFromBankCode(extraBankCode)}
        </div>
      </div>
    </div>
    <Collapse isOpen={help.extraBankCode} className="input-help-animation">
      <div
        className={classNames('input-help input-help--with-triangle', {
          'input-help--is-active': help.extraBankCode,
        })}
      >
        <div
          className="input-help__content"
          dangerouslySetInnerHTML={{
            __html: i18n(t => t.INPUT_HELP_TEXT_EXTRA_BANK_CODE),
          }}
        />
      </div>
    </Collapse>
  </div>
);

ExtraBankCodeFields.propTypes = {
  extraBankCode: PropTypes.string.isRequired,
  help: PropTypes.shape({}).isRequired,
  toggleHelp: PropTypes.func.isRequired,
};

export default ExtraBankCodeFields;
