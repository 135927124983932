import { combineReducers } from 'redux';
import DataState from 'spv-data-state';
import {
  ECONOMY_GET_TRANSACTIONS_START,
  ECONOMY_GET_TRANSACTIONS_SUCCESSFUL,
  ECONOMY_GET_TRANSACTIONS_FAILED,
  ECONOMY_GET_CATEGORIES_START,
  ECONOMY_GET_CATEGORIES_SUCCESSFUL,
  ECONOMY_GET_CATEGORIES_FAILED,
} from 'actions/actionTypes';

const initialValues = DataState.notRequested();

const transactionsReducer = (state = initialValues, action) => {
  switch (action.type) {
    case ECONOMY_GET_TRANSACTIONS_START:
      return DataState.requested(state);
    case ECONOMY_GET_TRANSACTIONS_SUCCESSFUL:
      return DataState.received(action.data, state);
    case ECONOMY_GET_TRANSACTIONS_FAILED:
      return DataState.error(action.error, state);
    default:
      return state;
  }
};

const categoriesReducer = (state = initialValues, action) => {
  switch (action.type) {
    case ECONOMY_GET_CATEGORIES_START:
      return DataState.requested(state);
    case ECONOMY_GET_CATEGORIES_SUCCESSFUL:
      return DataState.received(action.data, state);
    case ECONOMY_GET_CATEGORIES_FAILED:
      return DataState.error(action.error, state);
    default:
      return state;
  }
};

export default combineReducers({
  transactions: transactionsReducer,
  categories: categoriesReducer,
});
