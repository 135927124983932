import React from 'react';

const MobileWithSpinner = () => (
  <div className="mobile-with-spinner">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84 130"
      fill="currentColor"
    >
      <path d="M0 12.3C0 5.5 5.3 0 11.8 0L72.2 0C78.7 0 84 5.5 84 12.3L84 117.7C84 124.5 78.7 130 72.2 130L11.8 130C5.3 130 0 124.5 0 117.7L0 12.3ZM7 16L7 101 77 101 77 16 7 16ZM32 5C30.9 5 30 5.9 30 7 30 8.1 30.9 9 32 9L52 9C53.1 9 54 8.1 54 7 54 5.9 53.1 5 52 5L32 5ZM42 123C45.9 123 49 119.9 49 116 49 112.1 45.9 109 42 109 38.1 109 35 112.1 35 116 35 119.9 38.1 123 42 123Z" />
    </svg>
  </div>
);

MobileWithSpinner.propTypes = {};

export default MobileWithSpinner;
