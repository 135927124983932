/* eslint-disable react/no-danger, max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  reduxForm,
  Field,
  getFormValues,
  change as reduxFormChange,
} from 'redux-form';
// import AccountPickerInput from 'components/Form/AccountPickerInput';
import * as PATH from 'routes/paths';
import dataSelector from 'redux-helpers/dataSelector';
import { getBetalingsavtaleTilbyderById } from 'utils/betalingsavtaler.util';
import { change } from 'redux-helpers/data.actions';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';
import _ from 'lodash';
import { DetailSection, DetailSectionListItem } from 'components';
import { registerMessage } from 'actions/messages.actions';
import { InputField } from 'digitalbank-form';
import historyShape from 'shapes/history.shape';
import {
  createAvtalegiro,
  getIndexOfCurrentBetalingsavtale,
} from '../avtaleadministrasjon.service';
import KidField from '../Fields/KidField';
import MaksBeloep from '../Fields/MaksBeloep';

class AvtalegiroFormContainer extends Component {
  constructor(props) {
    super(props);
    this.accounts = _.filter(Accounts, x => x.active);
    // this.state = {
    //   displayMaksBeloep: false,
    //   help: {
    //     maksBeloep: false,
    //     kid: false,
    //   },
    // };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.displayMaksBeloep = this.displayMaksBeloep.bind(this);
  }
  componentDidMount() {
    if (this.props.initialValues && this.props.initialValues.maksBeloep) {
      this.displayMaksBeloep();
    }
  }
  // displayMaksBeloep() {
  //   this.setState({ displayMaksBeloep: true });
  // }
  handleSubmit() {
    createAvtalegiro(
      this.props.betalingsavtaler,
      this.props.changeData,
      this.props.tilbyderId,
      this.props.formValues,
    );
    const existInBetalingsAvtaler =
      getIndexOfCurrentBetalingsavtale(
        this.props.betalingsavtaler,
        this.props.tilbyderId,
      ) > -1;
    this.props.onRegisterMessage({
      text: existInBetalingsAvtaler
        ? 'AvtaleGiro oppdatert'
        : 'AvtaleGiro opprettet',
    });
    this.props.history.push(PATH.EFAKTURA_AND_AVTALEGIRO);
  }
  render() {
    const {
      submitButtonText,
      initialValues,
      tilbyderId,
      betalingsavtaleTilbydere,
    } = this.props;
    const tilbyder = tilbyderId
      ? getBetalingsavtaleTilbyderById(
        betalingsavtaleTilbydere.data,
        tilbyderId,
      )
      : undefined;
    return (
      <form
        onSubmit={this.props.handleSubmit(() => this.handleSubmit())}
        className="betalingsavtale-form"
      >
        {tilbyder && (
          <DetailSection
            heading={tilbyder.name}
            className="avtalegiro-form__details"
          >
            {tilbyder.accountNumber && (
              <DetailSectionListItem
                label="Kontonr"
                value={tilbyder.accountNumber}
              />
            )}
            {initialValues &&
              initialValues.kid && (
                <DetailSectionListItem
                  label="KID-nr."
                  value={initialValues.kid}
                />
              )}
          </DetailSection>
        )}
        {(!initialValues || !initialValues.kid) && (
          <KidField state={this.state} setState={ret => this.setState(ret)} />
        )}
        <Field
          name="fraKonto"
          component={InputField}
          accounts={this.accounts}
          type="text"
          label="Fra konto"
          defaultChoiceMessage="Velg fra-konto"
          className="form-group--with-help-button-indent"
        />
        <MaksBeloep
          state={this.state}
          setState={el => this.setState(el)}
          changeFieldValue={this.props.changeFieldValue}
          formAlias="avtalegiroForm"
        />
        <div className="avtalegiro-form__submit-wrapper">
          <button
            type="submit"
            className="button button--primary avtalegiro-form__submit-button"
          >
            {submitButtonText}
          </button>
        </div>
      </form>
    );
  }
}

AvtalegiroFormContainer.defaultProps = {
  initialValues: null,
  tilbyderId: undefined,
  formValues: null,
  handleSubmit: undefined,
};

AvtalegiroFormContainer.propTypes = {
  history: historyShape.isRequired,
  initialValues: PropTypes.shape({
    maksBeloep: PropTypes.number,
  }),
  tilbyderId: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  betalingsavtaleTilbydere: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  betalingsavtaler: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  changeData: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  onRegisterMessage: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.efakturaReference) {
    errors.efakturaReference = 'eFaktura-referanse er nødvendig';
  }
  return errors;
};

const form = reduxForm({
  destroyOnUnmount: true,
  form: 'avtalegiroForm',
  validate,
})(AvtalegiroFormContainer);

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
  changeFieldValue: bindActionCreators(reduxFormChange, dispatch),
  onRegisterMessage: bindActionCreators(registerMessage, dispatch),
});

const mapStateToProps = state => ({
  betalingsavtaleTilbydere: dataSelector(state, 'betalingsavtaleTilbydere'),
  betalingsavtaler: dataSelector(state, 'betalingsavtaler'),
  formValues: getFormValues('avtalegiroForm')(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(form);
