import {
  TOGGLE_SHARE_DROPDOWN,
  TOGGLE_RECIPIENTS,
  TOGGLE_FILTER,
  TOGGLE_AMOUNT_FIELDS,
  TOGGLE_DATE_FIELDS,
} from './search.constants';

const toggleAmountFields = () => ({
  type: TOGGLE_AMOUNT_FIELDS,
});

const toggleDateFields = () => ({
  type: TOGGLE_DATE_FIELDS,
});

const toggleShareDropdown = () => ({
  type: TOGGLE_SHARE_DROPDOWN,
});

const toggleRecipients = () => ({
  type: TOGGLE_RECIPIENTS,
});

const toggleFilter = () => ({
  type: TOGGLE_FILTER,
});

export {
  toggleShareDropdown,
  toggleRecipients,
  toggleFilter,
  toggleAmountFields,
  toggleDateFields,
};
