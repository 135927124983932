/* eslint-disable no-mixed-operators */
import moment from 'moment';

const commonProps = {
  isActive: false,
  diet: false,
  notificationOnCardUse: false,
  notificationWithDailySummaryOfCardUse: false,
  notificationWithReminderToTakePictureOfReceipt: false,
  notificationWhenUsingWrongValuta: false,
  numberOfTransactions: Math.floor(Math.random() * (10 - 1 + 1)) + 1,
  amount: Math.floor(Math.random() * (500 - 1 + 1)),
};

const tags = [
  {
    id: '1',
    name: 'Sommer 2017',
    inserted: '2017-01-12T14:38:16.203',
    ...commonProps,
  },
  {
    id: '2',
    name: 'Sykkel',
    inserted: '2017-02-13T14:38:16.203',
    ...commonProps,
  },
  {
    id: '3',
    name: 'Oppussing av bad',
    inserted: '2017-06-17T14:38:16.203',
    ...commonProps,
  },
];

const get = data =>
  new Promise((resolve) => {
    setTimeout(() => {
      const tagsFromLocalStorage = localStorage.getItem('tags');
      if (tagsFromLocalStorage) {
        resolve(JSON.parse(tagsFromLocalStorage));
      } else {
        localStorage.setItem('tags', JSON.stringify(data));
        resolve(data);
      }
    }, 300);
  });

export const getTags = () => get(tags);

export const getTag = (id) => {
  const currentTags = JSON.parse(localStorage.getItem('tags'));
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const tag = currentTags.filter(x => x.id === id)[0];
      if (tag) {
        resolve(tag);
      } else {
        reject(new Error(`Tag with ${id} not found`));
      }
    }, 200);
  });
};

export const addTag = (data) => {
  const currentTags = JSON.parse(localStorage.getItem('tags'));
  return new Promise((resolve) => {
    setTimeout(() => {
      // TODO: Get the newest id and add 1
      const tag = {
        id:
          !currentTags || currentTags.length === 0
            ? '0'
            : `${Number(currentTags[currentTags.length - 1].id) + 1}`,
        ...data,
        amount: Math.floor(Math.random() * (data.budget || 500 - 1 + 1)),
        numberOfTransactions: Math.floor(Math.random() * (10 - 1 + 1)) + 1,
        inserted: moment().toJSON(),
      };
      if (!currentTags || currentTags.length === 0) {
        localStorage.setItem('tags', JSON.stringify([tag]));
      } else {
        localStorage.setItem('tags', JSON.stringify([...currentTags, tag]));
      }
      resolve();
    }, 200);
  });
};

export const editTag = ({ id, data }) => {
  const currentTags = JSON.parse(localStorage.getItem('tags'));
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const indexOfOldTag = currentTags.findIndex(el => el.id === id);
      if (indexOfOldTag > -1) {
        const updatedTag = {
          ...currentTags[indexOfOldTag],
          ...data,
          updated: moment().toJSON(),
        };
        const newTags = [
          ...currentTags.slice(0, indexOfOldTag),
          updatedTag,
          ...currentTags.slice(indexOfOldTag + 1),
        ];
        localStorage.setItem('tags', JSON.stringify(newTags));
        resolve();
      } else {
        reject(new Error(`Tag with ${id} not found`));
      }
    }, 200);
  });
};

export const deleteTag = (id) => {
  const currentTags = JSON.parse(localStorage.getItem('tags'));
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const indexOfOldTag = currentTags.findIndex(el => el.id === id);
      if (indexOfOldTag > -1) {
        const newTags = [
          ...currentTags.slice(0, indexOfOldTag),
          ...currentTags.slice(indexOfOldTag + 1),
        ];
        localStorage.setItem('tags', JSON.stringify([...newTags]));
        resolve();
      } else {
        reject(new Error(`Tag with ${id} not found`));
      }
    }, 300);
  });
};
