import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import dataSelector from 'redux-helpers/dataSelector';
import { change } from 'redux-helpers/data.actions';
import { bindActionCreators } from 'redux';
import { Links } from 'data';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';
import { BasePage } from 'digitalbank-layout';
import {
  BRUKSKONTO,
  SPAREKONTO,
} from 'digitalbank-mockup-data/lib/AccountTypes';
import * as PATHS from 'routes/paths';
import { STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import {
  updateTransactionStatus,
  deleteTransaction,
} from 'services/transactions.service';
import _ from 'lodash';
import FilterContainer from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/Filters/FilterContainer';
import IngenBetalinger from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/IngenBetalinger/IngenBetalinger';
import SumForfallFooter from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/SumForfallFooter';
import { filterForfallskontoerByDate } from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/reducers/helpers/filter.helpers';
import {
  receiveAccountList,
  requestAccountList,
} from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import {
  mapTransactionsToBetalingsList,
  filterUnapprovedEfakturaTransactions,
  filterApprovedAndUnapprovedEfakturaTransactions,
} from 'utils/transactions.util';
import EfakturaOneClickContainer from 'containers/EfakturaOneClickContainer';
import { ONECLICK } from 'digitalbank-efaktura-oneclick';
import ScheduledPaymentCollection from './ScheduledPaymentCollection';
import ScheduledPaymentsStyled from './ScheduledPaymentsStyled';
import ScheduledPaymentsFilterStyled from './ScheduledPaymentsFilterStyled';

const mergeAndMapAccountAndTransactions = (account, transactions) => ({
  kontonummer: account.accountNumber,
  kontonavn: account.accountName,
  disponibeltBeloep: account.availableAmount,
  betalinger: mapTransactionsToBetalingsList(
    _.filter(transactions, x => x.fraKonto === account.accountNumber),
  ),
  ...account,
});

const getForfallskontoer = (
  accounts,
  transactions,
  selectedKonto,
  selectedDateOption,
  selectedDay,
) => {
  let forfallskontoer;
  if (selectedKonto && !selectedKonto.isAllAccountsOption) {
    forfallskontoer = [
      mergeAndMapAccountAndTransactions(selectedKonto, transactions),
    ];
  } else {
    forfallskontoer = accounts.map(account =>
      mergeAndMapAccountAndTransactions(account, transactions),
    );
  }
  return filterForfallskontoerByDate(forfallskontoer, {
    dateFilterOptionId: selectedDateOption.name,
    day: selectedDay,
    account: selectedKonto,
  });
};

const filterTransactions = transactions =>
  _.filter(
    transactions,
    x =>
      x.status === STATUS.ACTIVE ||
      x.status === STATUS.STOPPED ||
      x.status === STATUS.APPROVED,
  );

class Forfallsmappe extends Component {
  constructor(props) {
    super(props);
    this.accounts = _.filter(
      Accounts,
      x => x.active && (x.type === BRUKSKONTO || x.type === SPAREKONTO),
    );
    this.paymentLink = _.find(Links, x => x.path === PATHS.PAY);
  }
  componentDidMount() {
    this.getAccounts();
  }
  getAccounts() {
    this.props.onRequestAccounts('fromAccountsPicker');
    setTimeout(() => {
      const accountResult = {
        kontoer: this.accounts,
      };
      this.props.onReceiveAccounts('fromAccountsPicker', accountResult);
    }, 600);
  }
  render() {
    const {
      transactions,
      changeData,
      forfallsmappe,
      userSettings,
    } = this.props;
    const {
      selectedKonto,
      selectedDateOption,
      selectedDay,
    } = forfallsmappe.filter;
    const shouldDisplayAllAccounts =
      selectedKonto === null || selectedKonto.isAllAccountsOption;
    const betalinger = transactions.status.received
      ? filterTransactions(this.props.transactions.data)
      : [];
    const forfallskontoer = getForfallskontoer(
      this.accounts,
      betalinger,
      selectedKonto,
      selectedDateOption,
      selectedDay,
    );
    const unapprovedEfakturaTransactions = filterUnapprovedEfakturaTransactions(
      transactions.data,
    );
    // eslint-disable-next-line
    const approvedAndUnapprovedEfakturaTransactions = filterApprovedAndUnapprovedEfakturaTransactions(
      transactions.data,
    );
    const efakturaStatus =
      userSettings.efakturaOneClickStatus === ONECLICK.PAA
        ? ONECLICK.PAA
        : ONECLICK.AV;
    return (
      <ScheduledPaymentsStyled>
        <BasePage className="p-scheduled-payments">
          {approvedAndUnapprovedEfakturaTransactions.length > 0 && (
            <div className="p-scheduled-payments__efaktura-container">
              <h3>
                Nye eFaktura (
                {unapprovedEfakturaTransactions.length > 0
                  ? unapprovedEfakturaTransactions.length
                  : 0}
                )
              </h3>
              <EfakturaOneClickContainer status={efakturaStatus} />
            </div>
          )}
          <h1 className="p-scheduled-payments__heading">Forfallsmappe</h1>
          <ScheduledPaymentsFilterStyled>
            <FilterContainer />
          </ScheduledPaymentsFilterStyled>
          {forfallskontoer &&
            forfallskontoer.map((account) => {
              if (
                !shouldDisplayAllAccounts ||
                (shouldDisplayAllAccounts &&
                  (account.betalinger && account.betalinger.length > 0))
              ) {
                return (
                  <div key={`collection-${account.id}`}>
                    <ScheduledPaymentCollection
                      account={account}
                      deletePayment={id =>
                        deleteTransaction(
                          'transactions',
                          transactions,
                          changeData,
                          id,
                        )
                      }
                      setPaymentStatus={(id, status) =>
                        updateTransactionStatus(
                          'transactions',
                          transactions,
                          changeData,
                          id,
                          status,
                        )
                      }
                    />
                    {account.betalinger.length === 0 && (
                      <section className="p-scheduled-payments__collection p-scheduled-payments__collection--empty">
                        <IngenBetalinger />
                      </section>
                    )}
                  </div>
                );
              }
              return null;
            })}
          <SumForfallFooter forfallskontoer={forfallskontoer} />
        </BasePage>
      </ScheduledPaymentsStyled>
    );
  }
}

Forfallsmappe.defaultProps = {
  forfallsmappe: null,
};

Forfallsmappe.propTypes = {
  forfallsmappe: PropTypes.shape({}),
  changeData: PropTypes.func.isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  onReceiveAccounts: PropTypes.func.isRequired,
  onRequestAccounts: PropTypes.func.isRequired,
  userSettings: PropTypes.shape({
    efakturaOneClickStatus: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
  onReceiveAccounts: bindActionCreators(receiveAccountList, dispatch),
  onRequestAccounts: bindActionCreators(requestAccountList, dispatch),
});

const mapStateToProps = state => ({
  transactions: dataSelector(state, 'transactions'),
  forfallsmappe: state.forfallsmappe,
  userSettings: state.user.settings,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Forfallsmappe);
