import {
  startInstance,
  updateInstance,
} from './editAccountNamesStatus.actions';

export const toggleAccount = accountId => ({
  type: 'SELECTED_ACCOUNTS/TOGGLE_ACCOUNT',
  accountId,
});

export const moveAccountUp = accountId => ({
  type: 'SELECTED_ACCOUNTS/MOVE_ACCOUNT_UP',
  accountId,
});

export const moveAccountDown = accountId => ({
  type: 'SELECTED_ACCOUNTS/MOVE_ACCOUNT_DOWN',
  accountId,
});

export const setDisplayName = (accountId, value) => ({
  type: 'ACCOUNTS/SET_DISPLAY_NAME',
  accountId,
  value,
});

export const updateDisplayName = (accountId, value, index) => (dispatch) => {
  dispatch(setDisplayName(accountId, value));
  dispatch(startInstance(accountId));
  dispatch(() => {
    setTimeout(() => {
      dispatch(updateInstance(accountId, index, 'SUCCESSFUL'));
    }, 800);
  });
  dispatch(() => {
    setTimeout(() => {
      dispatch(updateInstance(accountId, index, 'FINISHED'));
    }, 2400);
  });
};
