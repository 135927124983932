import { TOGGLE_SIDEBAR } from '../actions/actionTypes';

export default function reducer(state = false, action = {}) {
  switch (action.type) {
    case TOGGLE_SIDEBAR: {
      const isToggled = !state;
      return isToggled;
    }
    default:
      return state;
  }
}
