import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-helpers/data.actions';
import { bindActionCreators } from 'redux';
import { BasePage } from 'digitalbank-layout';
import FasteOppdragNavList from 'routes/FasteOppdrag/FasteOppdragNavList';
import dataSelector from 'redux-helpers/dataSelector';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';
import _ from 'lodash';
import { deleteTransaction } from 'services/transactions.service';
import { STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import FasteOppdragCollection from './FastOppdragCollection';
import FasteOppdragStyled from './FasteOppdragStyled';

const accounts = _.filter(Accounts, x => x.active);

const FasteOppdrag = ({ transactions, recurringTransactions, changeData }) => (
  <FasteOppdragStyled>
    <BasePage heading="Faste oppdrag" className="p-faste-oppdrag">
      <FasteOppdragNavList />
      {recurringTransactions.status.received &&
        accounts.map(account => (
          <FasteOppdragCollection
            key={account.accountNumber}
            account={account}
            transactions={_.orderBy(
              _.filter(
                recurringTransactions.data,
                x => x.status !== STATUS.DELETED,
              ),
              ['dato'],
              ['asc'],
            )}
            deleteTransaction={(id) => {
              // NOTE: Delete both transaction and recurringTransaction
              deleteTransaction(
                'recurringTransactions',
                recurringTransactions,
                changeData,
                id,
              );
              deleteTransaction('transactions', transactions, changeData, id);
            }}
          />
        ))}
    </BasePage>
  </FasteOppdragStyled>
);

FasteOppdrag.propTypes = {
  transactions: PropTypes.shape({}).isRequired,
  recurringTransactions: PropTypes.shape({}).isRequired,
  changeData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  recurringTransactions: dataSelector(state, 'recurringTransactions'),
  transactions: dataSelector(state, 'transactions'),
});

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FasteOppdrag);
