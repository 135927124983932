const initialState = {
  user: undefined,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case 'INIT_USER': {
      return {
        ...state,
        user: action.user,
      };
    }

    case 'REMOVE_USER':
      return {
        ...state,
        user: undefined,
      };
    default:
      return state;
  }
}
