export default [
  {
    id:
      'AOW--wdWf26aWupiIzO8VMF8A8tEU8Y1YoKRXSVnUW_nJb8Qp5Y8M3fxRTr1mclXwU62NPi2Ch7KqyaMS9565LqixLdYr26hgYaUNnPPjKExZwU5VA2',
    mottakernavn: 'FRENDE SKADE AS',
    debetkonto: '34501253415',
    kredittkonto: '36242483787',
    kid: '9904718815',
    maanedligBeloep: 500000000000.0,
    status: 'Ingen',
    sisteBetalingsdato: '2016-09-15T00:00:00.0000000+02:00',
    notat: 'FRENDE SKADE AS',
    kredittkontoProtected:
      'APjU4StSOfTOr0O15dGJ735dAgWusB6vcM60VSXUF6VKuk5LsPq31oy6Ks_Ii0yPVjWfA6I1',
  },
  {
    id:
      'AOW--wdWf26aWupiIzO8VMFCfYE-_UbTA59m8R0reimgGceoslOcCa4tA5OR1hCF_qbZgScOKLz09i3_c9xmcn0iCHcTbsjzn2abSO8VxIaBTs_TdA2',
    mottakernavn: 'FRENDE LIVSFORSIKRING AS',
    debetkonto: '36372729923',
    kredittkonto: '36242458871',
    kid: '00202973900000006',
    maanedligBeloep: 500000000000000.0,
    status: 'Ingen',
    sisteBetalingsdato: null,
    notat: 'Hoi! Livsforsikring for Sara',
    kredittkontoProtected:
      'APjU4StSOfTOr0O15dGJ7371DFiYhMRwCrHgOUDrUdAuxIIm1VeeKTn1Y_OUPaPD-3ZpbZQ1',
  },
  {
    id:
      'ALd7ifpnRkvaxf-Y51rPuuNvkx8PHusPQVnGitvP1QQYSNAwYzMuH6dz2qtQg2N9LRmvsnh2yhx1LGX9jNpox1mLYIBtqCjwWJ4wJttOiddjRSGiMw2',
    mottakernavn: 'TELIASONERA NORGE AS,NETCOM',
    debetkonto: '36245365351',
    kredittkonto: '70580671290',
    kid: '134164946',
    maanedligBeloep: null,
    status: 'Ingen',
    sisteBetalingsdato: null,
    notat: 'Barnebidrag',
  },
  {
    id:
      'ALd7ifpnRkvaxf-Y51rPuuNvkx8PHusPQVnGitvP1QQYZ49WP40n0WVqPcKwpCe4qJaXAFEKOCfOE3kWz8bvt7Pz3usg47EhMtSH1aD5tFHW35veoQ2',
    mottakernavn: 'GET AS',
    debetkonto: '36311532884',
    kredittkonto: '63500514347',
    kid: '9808741680312014699',
    maanedligBeloep: 3004.0,
    status: 'Ingen',
    sisteBetalingsdato: null,
    notat: 'Tony T sin GET!L',
  },
  {
    id:
      'ALd7ifpnRkvaxf-Y51rPuuNvkx8PHusPQVnGitvP1QQYZ49WP40n0WVqPcKwpCe4qLpEpX67JW6ca10Ls2uXioFGnxHR7ZCa1qokesRpmEp8dTW5EQ2',
    mottakernavn: 'SKAGEN VEKST',
    debetkonto: '36311532884',
    kredittkonto: '96860500555',
    kid: '110000265378',
    maanedligBeloep: null,
    status: 'Ingen',
    sisteBetalingsdato: null,
    notat: 'Barnebidrag',
  },
];

export const avtalegiroAvtalerForslag = [
  {
    id:
      'AGpzQFdjB7yruYktOBSyNKC0jWJJ7eYNrDtd-W6b68ocwhHtcy_5Sjcy3F7b5iVRRHi_8rucVVzv2RZu0Eq24y6GU_o77VsuFWG22lSdpjVeXNCz5A2',
    mottakernavn: 'SKAGEN M2 VERDIPAPIRFOND',
    kredittkonto: '96861122856',
    kid: '110009440295',
  },
  {
    id:
      'AGpzQFdjB7yruYktOBSyNKAISXbLvSPinNLwGcoOXSgGwhHtcy_5Sjcy3F7b5iVRRHi_8rucVVzv2RZu0Eq24y54zLFeJA2r6XoQlmjyhjTDDsv7Aw2',
    mottakernavn: 'SKAGEN GLOBAL',
    kredittkonto: '96860507770',
    kid: '110010225644',
  },
  {
    id:
      'AGpzQFdjB7yruYktOBSyNKD9A4O34M_DHPuW57toWTO8whHtcy_5Sjcy3F7b5iVRRHi_8rucVVzv2RZu0Eq24y5zbnQYuPK9yD6XzVtrvzb23kqzmQ2',
    mottakernavn: 'BKK KUNDETJENESTER AS',
    kredittkonto: '52010661641',
    kid: '0083041319811',
  },
];
