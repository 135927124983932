import React from 'react';
import { BasePage } from 'digitalbank-layout';
import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import BankIDMobile from 'components/Icons/BankIDMobile';
import BankIDStyled from './BankIDStyled';

const BankIDPage = () => (
  <BankIDStyled>
    <BasePage
      heading="BankID på mobil"
      className="p-bankid"
      HeadingIcon={BankIDMobile}
      headingIsAlwaysVisible
      asideContent={
        <div className="aside-card aside-card--light">
          <img
            src="images/login-help.png"
            alt="login help video"
            className="aside-card__image"
          />
          <h3 className="aside-card__heading">Innlogging</h3>
          <div className="aside-card__content">
            <p>Se hvordan du bruker de ulike alternativene for innlogging.</p>
          </div>
        </div>
      }
    >
      <div className="p-bankid__status">
        <p>
          <b className="u-color--positive">AKTIV</b>
          <i> Sist brukt i dag</i>
        </p>
        <p>
          <i>Utstedt av Sparebanken Vest</i>
        </p>
      </div>
      {/* <p className="p-bankid__description">
      BankID er Innlogging og signering med kodebrikke og passord
    </p> */}
      <NavList
        className="p-bankid__nav-list"
        items={[
          <NavListLink
            component={Link}
            variant={TASK}
            to={PATHS.HOME}
            title={{ text: 'Endre passord' }}
            key="endre-passord"
          />,
          <NavListLink
            component={Link}
            variant={TASK}
            to={PATHS.HOME}
            title={{ text: 'Sperre' }}
            key="sperre"
          />,
        ]}
      />
    </BasePage>
  </BankIDStyled>
);

export default BankIDPage;
