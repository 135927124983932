import _ from 'lodash';
import { KREDITT } from 'digitalbank-mockup-data/lib/AccountTypes';

export const isAccountNumberCreditCard = accountNumber =>
  /^\d{6}\*{6}\d{4}$/.test(accountNumber);
export const isAccountNumberInternational = accountNumber =>
  accountNumber.length > 17;
export const filterActiveAccounts = accounts =>
  _.filter(accounts, x => x.active);
export const filterEfakturaAccounts = accounts =>
  _.filter(accounts, x => x.active && x.type !== KREDITT);

export const isAccountPickerFetchingAccounts = (accountPickers, account) =>
  !accountPickers ||
  !accountPickers[account] ||
  accountPickers[account].isFetchingAccounts;
