import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormValues, isPristine, destroy } from 'redux-form';
import { bindActionCreators } from 'redux';
import { resetAccountPicker } from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import * as PATHS from 'routes/paths';
import { ReceiptPage } from 'digitalbank-layout';
import wizardPage from 'components/wizardPage';
import moment from 'moment';
import { formatAccountNumber } from 'utils/format.util';
import i18n from 'i18n';
import { getFrekvensString } from 'utils/frekvens.util';
import { isKidNumber } from 'utils/validation.util';
import FasteOppdragNavList from 'components/FasteOppdragNavList';
import { FAST_BETALING_FORM_NAME } from '../payment.constants';
import FastBetalingStyled from '../FastBetalingStyled';

class FastBetalingReceipt extends Component {
  componentWillUnmount() {
    this.props.onDestroy(FAST_BETALING_FORM_NAME);
    this.props.onResetAccountPicker(`${FAST_BETALING_FORM_NAME}FraKonto`);
  }
  render() {
    const { values } = this.props;

    const dueDate = moment(values.dato, 'L');
    const dueDatePrint = dueDate.format('LL');
    const toAccount =
      values.tilKontonavn || formatAccountNumber(values.tilKonto);
    const amount = i18n(i => i.NUMBER_TWO_DIGITS, {
      number: Number(values.kroner) + Number(values.oere / 100),
    });
    const melding = !isKidNumber(values.kidMelding) ? values.kidMelding : '';
    const kid = isKidNumber(values.kidMelding) ? values.kidMelding : '';
    return (
      <FastBetalingStyled>
        <ReceiptPage
          className="p-fast-betaling"
          receiptTitle="Fast betaling er startet"
          receiptDescription={
            <div>
              <p style={{ marginBottom: '0px' }}>
                <strong>{amount}</strong>
                {` vil bli betalt til ${toAccount} fra ${values.fraKonto
                  .visningsnavn || values.fraKonto.accountName} `}
                <strong>
                  {getFrekvensString(values.frekvens).toLowerCase()}
                </strong>
                {' fra '}
                <strong>{dueDatePrint}.</strong>
              </p>
              {kid && (
                <p
                  style={{
                    fontSize: '14px',
                    color: '#82877f',
                    marginBottom: '0px',
                  }}
                >
                  KID: {kid}
                </p>
              )}
              {melding && (
                <p
                  style={{
                    fontSize: '14px',
                    color: '#82877f',
                    marginBottom: '0px',
                  }}
                >
                  Melding: {melding}
                </p>
              )}
            </div>
          }
          asideContent={
            <section className="published-content">
              <div className="published-content__item published-content__frame">
                <h2>Visste du?</h2>
                <p>
                  At du kan vennebetale i mobilbanken? De du betaler til trenger
                  ikke være kunder i Sparebanken Vest, og alt du trenger er
                  mobiltelefonnummeret til den du skal betale til.
                </p>
              </div>
            </section>
          }
        >
          <FasteOppdragNavList />
        </ReceiptPage>
      </FastBetalingStyled>
    );
  }
}

FastBetalingReceipt.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onDestroy: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onDestroy: bindActionCreators(destroy, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

const mapStateToProps = state => ({
  values: getFormValues(FAST_BETALING_FORM_NAME)(state),
});

export default wizardPage({
  redirectTest: state => isPristine(FAST_BETALING_FORM_NAME)(state),
  redirectPath: PATHS.FAST_BETALING,
})(connect(mapStateToProps, mapDispatchToProps)(FastBetalingReceipt));
