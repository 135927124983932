import i18nAccountList from 'digitalbank-account-list/lib/i18n/accountlist.i18n';
import i18nAccountPicker from 'digitalbank-account-picker/lib/es/i18n/accountPicker.i18n';
import i18nActionList from 'digitalbank-action-list/lib/es/components/ActionList/actionList.i18n';
import i18nForfallsmappe from 'digitalbank-betaling-web/lib/es/features/forfallsmappe/forfallsmappe.i18n';
import i18nBetalingsavtaler from 'digitalbank-betaling-web/lib/es/features/betalingsavtaler/betalingsavtaler.i18n';
import i18nBetalingCommon from 'digitalbank-betaling-web/lib/es/i18n/common.i18n';
import i18nSearchFeilside from 'digitalbank-historikk-web/lib/es/features/search/Feilside/searchFeilside.i18n';
import i18nSearch from 'digitalbank-historikk-web/lib/es/features/search/search.i18n';
import i18nSearchFormValidation from 'digitalbank-historikk-web/lib/es/features/search/searchForm.validation.i18n';
import i18nSearchDetails from 'digitalbank-historikk-web/lib/es/features/search/Details/details.i18n';
import i18nOversikt from 'i18n/oversikt/oversikt.i18n';
import general from './general';
import currencies from './currencies';
import routes from './routes';
import kontaktinformasjon from './innstillinger/kontaktinformasjon.i18n';
import kontaktinformasjonValidation from './innstillinger/kontaktinformasjon.validation.i18n';
import samtykke from './innstillinger/samtykke.i18n';
import samtykkeValidation from './innstillinger/samtykke.validation.i18n';
import betalingCommon from './betaling/common.i18n';
import betaleEn from './betaling/betaleEn.i18n';
import fasteOppdrag from './betaling/fasteOppdrag.i18n';
import datePicker from './datePicker.i18n';
import paymentGlobal from './paymentGlobal.i18n';
import actionList from './actionList.i18n';
import i18nReauth from './reauth.i18n';

export default {
  ...general,
  ...currencies,
  ...routes,
  ...datePicker,
  ...kontaktinformasjon,
  ...kontaktinformasjonValidation,
  ...samtykke,
  ...samtykkeValidation,
  ...betaleEn,
  ...betalingCommon,
  ...fasteOppdrag,
  ...i18nForfallsmappe,
  ...i18nBetalingsavtaler,
  ...i18nBetalingCommon,
  ...paymentGlobal,
  ...actionList,
  ...i18nAccountList,
  ...i18nAccountPicker,
  ...i18nReauth,
  ...i18nActionList,
  ...i18nOversikt,
  ...i18nSearchFeilside,
  ...i18nSearch,
  ...i18nSearchFormValidation,
  ...i18nSearchDetails,
};
