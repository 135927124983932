import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';

class Transactions extends Component {
  constructor(props) {
    super(props);
    this.account = Accounts.find(
      x => x.id === Number(this.props.match.params.accountId).valueOf(),
    );
  }

  render() {
    const { account } = this;
    return (
      <div className="container">
        <Helmet title="Siste transaksjoner" />
        <h1>{account.accountName}</h1>
      </div>
    );
  }
}

Transactions.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string,
    }),
  }).isRequired,
};

export default Transactions;
