import React from 'react';
import { BasePage } from 'digitalbank-layout';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import * as PATHS from 'routes/paths';
import { LinkGroup } from 'digitalbank-button-group';
import i18n from 'i18n';
import PaymentForm from '../PaymentForm';
import initialValues from './fastBetaling.initialValues';
import validate from '../payment.validate';
import { FAST_BETALING_FORM_NAME } from '../payment.constants';
import FastBetalingStyled from '../FastBetalingStyled';

const FastBetaling = ({ history, handleSubmit }) => (
  <FastBetalingStyled>
    <BasePage
      heading={i18n(x => x.ROUTE_TITLE_FAST_BETALING)}
      className="p-fast-betaling"
    >
      <LinkGroup
        items={[
          {
            text: 'Fast overføring',
            href: PATHS.FAST_OVERFOERING,
          },
          {
            text: 'Fast betaling',
            href: PATHS.FAST_BETALING,
            active: true,
          },
        ]}
      />
      <PaymentForm
        formName={FAST_BETALING_FORM_NAME}
        isFastBetaling
        handleSubmit={handleSubmit(
          () =>
            new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                history.push(PATHS.FAST_BETALING_REAUTH);
              }, 500);
            }),
        )}
      />
    </BasePage>
  </FastBetalingStyled>
);

FastBetaling.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  destroyOnUnmount: false,
  form: FAST_BETALING_FORM_NAME,
  initialValues,
  validate,
})(FastBetaling);
