/* eslint-disable no-mixed-operators */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { Button } from 'digitalbank-buttons';
import { config } from 'services/economy.service';
import TransactionForm from './TransactionForm';
import TransactionCardMap from './TransactionCardMap';
import TransactionTags, { TransactionTagsStyle } from './TransactionTags';

const logoHeight = 78;
const contentPaddingTop = 20;

const TransactionCardStyle = styled.article`
  ${TransactionTagsStyle} {
    margin-bottom: 32px;
  }
  /* Remove ugly google elements. Not really allowed? */
  .gm-style a img,
  .gm-style-cc {
    display: none !important;
  }
`;

const TransactionCardLogoContainerStyled = styled.div`
  border: 2px solid #eeeeee;
  background-color: #ffffff;
  height: ${logoHeight}px;
  width: ${logoHeight}px;
  display: flex;
  padding: 4px;

  img {
    align-self: center;
  }
`;

const TransactionCardContentStyled = styled.article`
  background: #fff;
  width: 100%;
  padding: ${contentPaddingTop}px 20px 50px;
  min-height: 200px;

  ${TransactionCardLogoContainerStyled} {
    margin-top: ${(logoHeight / 2 + contentPaddingTop) * -1}px;
    margin-bottom: 17px;
    position: relative; /* We need this to overlay map */
  }
`;

const TransactionHeader = styled.div`
  display: flex;
  align-items: top;
  justify-content: space-between;
`;

const TransactionCardHeading = styled.h1`
  font-size: 18px;
  font-family: 'Roboto';
`;
const TransactionDate = styled.p`
  font-size: 12px;
  color: #777;
`;

const TransactionAmount = styled.div`
  font-size: 18px;
  font-family: 'Oswald';
`;

const TransactionMapPlaceholder = styled.div`
  height: 200px;
  background: #ddd;
`;

const TransactionCard = ({
  transaction, isOpened, toggle, visible,
}) => {
  const cordinates =
    transaction.place && transaction.place.coordinates
      ? transaction.place.coordinates.split(',')
      : undefined;
  const logoSrc =
    transaction.place && transaction.place.logoId > 0
      ? `${config.routes.logo}/${transaction.place.logoId}`
      : undefined;

  let map = <TransactionMapPlaceholder />;
  if (cordinates) {
    const lat = Number(cordinates[0]);
    const lng = Number(cordinates[1]);
    map = (
      <TransactionCardMap
        containerElement={<div style={{ height: '200px' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        lat={lat}
        lng={lng}
      />
    );
  }
  return (
    <TransactionCardStyle>
      {isOpened && visible && map}
      <TransactionCardContentStyled>
        {isOpened &&
          logoSrc && (
            <TransactionCardLogoContainerStyled>
              <img src={logoSrc} alt={`Logo for ${transaction.placeName}`} />
            </TransactionCardLogoContainerStyled>
          )}
        {isOpened && (
          <TransactionHeader>
            <div>
              <TransactionCardHeading>
                {transaction.placeName}
              </TransactionCardHeading>
              <TransactionDate>
                {_.capitalize(moment(transaction.date).format('dddd MM. MMM'))}
              </TransactionDate>
            </div>
            <TransactionAmount>
              {Number(transaction.amount).toLocaleString('nb-NO', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </TransactionAmount>
          </TransactionHeader>
        )}
        {isOpened && (
          <p style={{ marginBottom: '30px' }}>
            <b>Transaksjonstekst:</b>
            <br /> {transaction.alfareferanse}
          </p>
        )}
        <TransactionForm transaction={transaction} visible={isOpened} />
        <TransactionTags transaction={transaction} visible={visible} />
        <Button variant="alternative" onClick={toggle}>
          Lukk
        </Button>
      </TransactionCardContentStyled>
    </TransactionCardStyle>
  );
};

TransactionCard.propTypes = {
  transaction: PropTypes.shape({}).isRequired,
  isOpened: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default TransactionCard;
