/* eslint-disable no-mixed-operators, no-console */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ResponsiveBubble } from '@nivo/circle-packing';
import { mapCategoryDataToGraph } from '../utils';

export const EconomyGraphStyled = styled.div`
  height: 380px;
  font-family: 'Oswald';
  font-size: 14px;
  font-weight: normal;
`;

const EconomyGraph = ({ categoryData, ContentComponent, setCategory }) => {
  if (
    !categoryData ||
    !categoryData.children ||
    categoryData.children.length <= 0
  ) {
    return null;
  }
  const root = mapCategoryDataToGraph(categoryData);
  return (
    <EconomyGraphStyled>
      <ResponsiveBubble
        root={root}
        identity="name"
        value="sum"
        colorBy={d => `${d.color}`}
        isZoomable={false}
        hasTooltip={false}
        padding={4}
        labelTextColor="#fff"
        onClick={(node) => {
          if (node && node.data && node.data.category) {
            setCategory(node.data.category.name);
          }
        }}
        motionStiffness={90}
        motionDamping={12}
        ContentComponent={ContentComponent}
        label={(e) => {
          if (e.data.percentageOfTotal < 6) return '';
          // console.log(e.data);
          // const sumFormatted = e.data.sum.toLocaleString('nb-NO', {
          //   style: 'decimal',
          //   minimumFractionDigits: 0,
          //   maximumFractionDigits: 0,
          // });
          return `${e.data.displayName}`;
        }}
      />
    </EconomyGraphStyled>
  );
};

EconomyGraph.defaultProps = {
  ContentComponent: null,
};

EconomyGraph.propTypes = {
  categoryData: PropTypes.shape({}).isRequired,
  setCategory: PropTypes.func.isRequired,
  ContentComponent: PropTypes.func,
};
export default EconomyGraph;
