import DataState from 'spv-data-state';
import {
  TAG_GET_TAGS_START,
  TAG_GET_TAGS_SUCCESSFUL,
  TAG_GET_TAGS_FAILED,
} from 'actions/actionTypes';

const initialValues = DataState.notRequested();

export default (state = initialValues, action) => {
  switch (action.type) {
    case TAG_GET_TAGS_START:
      return DataState.requested(state);
    case TAG_GET_TAGS_SUCCESSFUL:
      return DataState.received(action.data, state);
    case TAG_GET_TAGS_FAILED:
      return DataState.error(action.error, state);
    default:
      return state;
  }
};
