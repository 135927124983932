import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { setTitle } from 'actions';

export default (WrappedComponent, title) => {
  class RouteComponent extends Component {
    componentWillMount() {
      this.props.onSetTitle(title);
    }
    componentDidMount() {
      window.scrollTo(0, 0);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  RouteComponent.propTypes = {
    onSetTitle: PropTypes.func.isRequired,
  };

  RouteComponent.displayName = `RouteComponent(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  const mapDispatchToProps = dispatch => ({
    onSetTitle: bindActionCreators(setTitle, dispatch),
  });

  return connect(null, mapDispatchToProps)(RouteComponent);
};
