import React from 'react';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import ScheduledPaymentNavListLinkContainer from 'containers/ScheduledPaymentNavListLinkContainer';

export default () => (
  <NavList
    items={[
      <NavListLink
        component={Link}
        to={PATHS.FASTE_OPPDRAG}
        title={{ text: 'Se faste oppdrag' }}
        key="fasteoppdrag"
      />,
      <NavListLink
        component={Link}
        to={PATHS.FAST_BETALING}
        title={{ text: 'Nytt fast oppdrag' }}
        key="nyttfastoppdrag"
      />,
      <ScheduledPaymentNavListLinkContainer />,
      <NavListLink
        component={Link}
        to={PATHS.SEARCH}
        title={{ text: 'Siste bevegelser' }}
        key="sistebevegelser"
      />,
    ]}
  />
);
