import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { all as allCountries } from 'spv-country-data';
import { InfoCircle } from 'digitalbank-icons';
import Button from 'digitalbank-buttons';
import { Switch } from 'digitalbank-form';
import { Modal, ModalCard } from 'digitalbank-modal';
import RegionalBlockingLocationOptionStyled from './RegionalBlockingLocationOptionStyled';

const RegionalBlockingLocationOption = ({
  location,
  toggleLocationInfo,
  toggleLocation,
  isOpen,
  infoIsOpen,
}) => {
  // Get countries in locationIndex
  const countries = [];
  if (location.countries) {
    location.countries.forEach((countryCode) => {
      // Get country name
      const country = _.find(allCountries, x => x.kode === countryCode);
      if (country) {
        countries.push(country.tittel);
      }
    });
  }
  let locationOptionElement = '';
  if (location.mandatory) {
    locationOptionElement = (
      <Switch
        onText="Åpen"
        offText="Sperret"
        isOn={isOpen}
        isDisabled
        handleClick={() => {}}
      />
    );
  } else {
    locationOptionElement = (
      <Switch
        onText="Åpen"
        offText="Sperret"
        isOn={isOpen}
        handleClick={toggleLocation}
      />
    );
  }
  const optionHasMultipleCountries = countries.length > 0;
  return (
    <RegionalBlockingLocationOptionStyled key={`location-${location.name}`}>
      <div className="regional-blocking-option-content">
        {optionHasMultipleCountries && (
          <Button
            variant="transparent"
            className="regional-blocking-option-info"
            onClick={toggleLocationInfo}
            rightIcon={<InfoCircle />}
          >
            {location.name}
          </Button>
        )}
        {!optionHasMultipleCountries && (
          <span className="regional-blocking-option-info">{location.name}</span>
        )}
        <span className="regional-blocking-option-switch">
          {locationOptionElement}
        </span>
      </div>
      {optionHasMultipleCountries && (
        <Modal toggle={toggleLocationInfo} visible={infoIsOpen}>
          <ModalCard
            className="modal-card--large"
            toggle={toggleLocationInfo}
            visible={infoIsOpen}
            heading={location.name}
            hasCloseButton
          >
            <div>
              <ul className="regional-blocking-option-countries-list">
                {countries.sort().map(country => (
                  <li
                    key={`country-info-name${country}`}
                    className="regional-blocking-option-countries-list-item"
                  >
                    {country}
                  </li>
                ))}
              </ul>
              <div className="modal-card__actions">
                {isOpen && (
                  <Button
                    variant="danger"
                    size="medium"
                    onClick={() => {
                      toggleLocation();
                    }}
                  >
                    Steng for region
                  </Button>
                )}
                {!isOpen && (
                  <Button
                    variant="primary"
                    size="medium"
                    onClick={() => {
                      toggleLocation();
                    }}
                  >
                    Åpne for region
                  </Button>
                )}
                <Button
                  size="medium"
                  variant="cancel"
                  onClick={toggleLocationInfo}
                >
                  Lukk
                </Button>
              </div>
            </div>
          </ModalCard>
        </Modal>
      )}
    </RegionalBlockingLocationOptionStyled>
  );
};

RegionalBlockingLocationOption.propTypes = {
  location: PropTypes.shape({}).isRequired,
  toggleLocationInfo: PropTypes.func.isRequired,
  toggleLocation: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  infoIsOpen: PropTypes.bool.isRequired,
};

export default RegionalBlockingLocationOption;
