/* eslint-disable react/no-danger */

import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import { connect } from 'react-redux';
import i18n from 'i18n';
import PaymentGlobalStyled from './PaymentGlobalStyled';

const PaymentGlobal = ({
  children,
  displayHeading = true,
  displayPublishedContent = true,
}) => (
  <PaymentGlobalStyled>
    <BasePage
      className="p-payment-global"
      heading={displayHeading ? i18n(t => t.ROUTE_TITLE_PAYMENT_GLOBAL) : null}
      asideContent={
        <div>
          {displayPublishedContent && (
            <section>
              <section className="published-content">
                <div
                  className="published-content__item published-content__frame"
                  dangerouslySetInnerHTML={{
                    __html: i18n(
                      t => t.PAYMENT_GLOBAL_PUBLISHED_CONTENT_ADVICE,
                    ),
                  }}
                />
              </section>
              <section className="published-content">
                <div
                  className="published-content__item published-content__frame"
                  dangerouslySetInnerHTML={{
                    __html: i18n(
                      t => t.PAYMENT_GLOBAL_PUBLISHED_CONTENT_FALSE_EMAIL,
                    ),
                  }}
                />
              </section>
            </section>
          )}
        </div>
      }
    >
      {children}
    </BasePage>
  </PaymentGlobalStyled>
);

PaymentGlobal.defaultProps = {
  displayHeading: true,
  displayPublishedContent: true,
};

PaymentGlobal.propTypes = {
  children: PropTypes.element.isRequired,
  displayHeading: PropTypes.bool,
  displayPublishedContent: PropTypes.bool,
};

const mapStateToProps = state => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(PaymentGlobal);
