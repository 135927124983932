import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Footer } from 'components';

const FooterContainer = ({ isLoggedIn }) => (
  <div className="app__footer">
    {isLoggedIn && <Footer kontaktOssUrl="/kontakt" logoUrl="/test" />}
  </div>
);

FooterContainer.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: !!state.auth.user,
});

export default connect(mapStateToProps)(FooterContainer);
