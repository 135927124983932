import {
  filterUnapprovedEfakturaTransactions,
  filterStoppedTransactions,
} from 'utils/transactions.util';

const getNotifications = (transactions) => {
  if (!transactions || !transactions.data) return undefined;

  const unapprovedEfakturaTransactions = filterUnapprovedEfakturaTransactions(
    transactions.data,
  );
  const stoppedTransactions = filterStoppedTransactions(transactions.data);

  let scheduledPaymentsCount = 0;
  if (stoppedTransactions) {
    scheduledPaymentsCount += stoppedTransactions.length;
  }
  if (unapprovedEfakturaTransactions) {
    scheduledPaymentsCount += unapprovedEfakturaTransactions.length;
  }

  if (scheduledPaymentsCount === 0) {
    return undefined;
  }

  return {
    scheduledPayments: {
      count: scheduledPaymentsCount,
    },
  };
};

export default getNotifications;
export { getNotifications };
