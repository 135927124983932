import {
  TOGGLE_EDIT_MODE_ON_CONTACT_INFO,
  CLOSE_EDIT_MODE_ON_CONTACT_INFO,
  OPEN_EDIT_MODE_ON_CONTACT_INFO,
  TOGGLE_CONTACT_INFO_HELP,
  TOGGLE_FOREIGNER_FORM,
} from './actionTypes';

export const toggleEditModeOnContactInfo = () => ({
  type: TOGGLE_EDIT_MODE_ON_CONTACT_INFO,
});

export const closeEditModeOnContactInfo = () => ({
  type: CLOSE_EDIT_MODE_ON_CONTACT_INFO,
});

export const openEditModeOnContactInfo = () => ({
  type: OPEN_EDIT_MODE_ON_CONTACT_INFO,
});

export const toggleContactInfoHelp = () => ({
  type: TOGGLE_CONTACT_INFO_HELP,
});

export const toggleForeignerForm = () => ({
  type: TOGGLE_FOREIGNER_FORM,
});
