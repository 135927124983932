import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Phone, Home, EnvelopeO } from 'digitalbank-icons';
import classNames from 'classnames';
import postalCodes from 'norway-postal-codes';

const ContactInfoCard = ({ active, info }) => {
  let location = info && info.zipCode ? postalCodes[info.zipCode] : undefined;
  if (info.zipCode && info.zipCode.length === 4 && !location) {
    location = 'Ukjent postnummer';
  } else if (location) {
    location = `${info.zipCode} ${_.capitalize(location)}`;
  }
  return (
    <ul
      className={classNames('contact-info-card', {
        'contact-info-card--active': active,
      })}
    >
      <li className="contact-info-card__element">
        <Home className="contact-info-card__icon" />
        <div className="contact-info-card__data">
          <div className="contact-info-card__heading">Postadresse</div>
          {info.address && (
            <p className="contact-info-card__data-value">{info.address}</p>
          )}
          {info.zipCode && (
            <p className="contact-info-card__data-value">{location}</p>
          )}
        </div>
      </li>
      <li className="contact-info-card__element">
        <Phone className="contact-info-card__icon" />
        <div className="contact-info-card__data">
          <div className="contact-info-card__heading">Mobiltelefon</div>
          {info.phone && (
            <p className="contact-info-card__data-value">{info.phone}</p>
          )}
        </div>
      </li>
      <li className="contact-info-card__element">
        <EnvelopeO className="contact-info-card__icon" />
        <div className="contact-info-card__data">
          <div className="contact-info-card__heading">E-post</div>
          {info.email && (
            <p className="contact-info-card__data-value">{info.email}</p>
          )}
        </div>
      </li>
    </ul>
  );
};

ContactInfoCard.defaultProps = {
  active: false,
  info: undefined,
};

ContactInfoCard.propTypes = {
  active: PropTypes.bool,
  info: PropTypes.shape({}),
};

export default ContactInfoCard;
