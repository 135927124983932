import { filterEfakturaAccounts } from 'utils/account.util';
import { filterApprovedAndUnapprovedEfakturaTransactions } from 'utils/transactions.util';
import { initializeEFakturaOneClick } from 'digitalbank-efaktura-oneclick/lib/eFakturaOneClick.actions';
import * as PATHS from 'routes/paths';

const getKontoOptions = accounts =>
  filterEfakturaAccounts(accounts).map(konto => ({
    visningsnavn: konto.visningsnavn || konto.accountName,
    kontonummer: konto.accountNumber,
    disponibelt: konto.availableAmount,
    isDisabled: false,
  }));

const getEfakturaer = transactions =>
  filterApprovedAndUnapprovedEfakturaTransactions(transactions).map(
    transaction => ({
      efakturareferanse: transaction.id,
      mottakernavn: transaction.tilKontonavn,
      beloep: transaction.beloep.verdi * -1,
      forfallsdato: transaction.dato,
      endreBetalingUrl: `${PATHS.EDIT_BETALE_EN}/${transaction.id}`,
    }),
  );

const getOneClickEfakturaOptions = (
  accounts,
  transactions,
  efakturainnstilling,
) => ({
  efakturainnstilling: {
    kontonummer: undefined,
    kontonavn: undefined,
    oneClick: 'IkkeSatt',
    kontoOptions: getKontoOptions(accounts),
    ...efakturainnstilling,
  },
  efakturaer: getEfakturaer(transactions),
});

export const initEfaktura = (
  accounts,
  transactions,
  efakturainnstilling,
) => (dispatch) => {
  dispatch(
    initializeEFakturaOneClick(
      getOneClickEfakturaOptions(accounts, transactions, efakturainnstilling),
    ),
  );
};
