export default [
  {
    name: 'Inntekter',
    type: 'mainCategory',
    subCategories: [
      {
        name: 'Barnetrygd',
        type: 'category',
        displayName: 'Barnetrygd',
      },
      {
        name: 'Bostøtte',
        type: 'category',
        displayName: 'Bostøtte',
      },
      {
        name: 'Innskudd',
        type: 'category',
        displayName: 'Innskudd',
      },
      {
        name: 'Inntekter uten kategori',
        type: 'category',
        displayName: 'Inntekter uten kategori',
      },
      {
        name: 'Kortomsetning',
        type: 'category',
        displayName: 'Kortomsetning',
      },
      {
        name: 'Lønn',
        type: 'category',
        displayName: 'Lønn',
      },
      {
        name: 'Overføring fra eksternt kredittkort',
        type: 'category',
        displayName: 'Overføring fra eksternt kredittkort',
      },
      {
        name: 'Pensjon',
        type: 'category',
        displayName: 'Pensjon',
      },
      {
        name: 'Renteinntekter',
        type: 'category',
        displayName: 'Renteinntekter',
      },
      {
        name: 'Sosialstønad',
        type: 'category',
        displayName: 'Sosialstønad',
      },
      {
        name: 'Studiestøtte',
        type: 'category',
        displayName: 'Studiestøtte',
      },
      {
        name: 'Tilbakebetalt skatt',
        type: 'category',
        displayName: 'Tilbakebetalt skatt',
      },
      {
        name: 'Trygder',
        type: 'category',
        displayName: 'Trygder',
      },
      {
        name: 'Utbetaling av kreditt/forbrukslån',
        type: 'category',
        displayName: 'Utbetaling av kreditt/forbrukslån',
      },
      {
        name: 'Utbytte',
        type: 'category',
        displayName: 'Utbytte',
      },
    ],
    displayName: 'Inntekter',
  },
  {
    name: 'Utgifter',
    type: 'mainCategory',
    subCategories: [
      {
        name: 'Barnehage',
        type: 'category',
        displayName: 'Barnehage',
      },
      {
        name: 'Bil og transport',
        type: 'category',
        subCategories: [
          {
            name: 'Bil',
            type: 'subCategory',
            displayName: 'Bil',
          },
          {
            name: 'Bilavgifter',
            type: 'subCategory',
            displayName: 'Avgifter',
          },
          {
            name: 'Billån/leasing',
            type: 'subCategory',
            displayName: 'Lån',
          },
          {
            name: 'Bompenger',
            type: 'subCategory',
            displayName: 'Bompenger',
          },
          {
            name: 'Buss',
            type: 'subCategory',
            displayName: 'Buss',
          },
          {
            name: 'Drivstoff',
            type: 'subCategory',
            displayName: 'Drivstoff',
          },
          {
            name: 'Ferge',
            type: 'subCategory',
            displayName: 'Ferge',
          },
          {
            name: 'Kjøreskole',
            type: 'subCategory',
            displayName: 'Kjøreskole',
          },
          {
            name: 'Parkering',
            type: 'subCategory',
            displayName: 'Parkering',
          },
          {
            name: 'Taxi',
            type: 'subCategory',
            displayName: 'Taxi',
          },
          {
            name: 'Tog',
            type: 'subCategory',
            displayName: 'Tog',
          },
        ],
        displayName: 'Bil og transport',
      },
      {
        name: 'Diverse',
        type: 'category',
        subCategories: [
          {
            name: 'Forbrukslån',
            type: 'subCategory',
            displayName: 'Forbrukslån',
          },
          {
            name: 'Gaver',
            type: 'subCategory',
            displayName: 'Gaver',
          },
          {
            name: 'Gebyr',
            type: 'subCategory',
            displayName: 'Gebyr',
          },
          {
            name: 'Inkasso',
            type: 'subCategory',
            displayName: 'Inkasso',
          },
          {
            name: 'Kontingent',
            type: 'subCategory',
            displayName: 'Kontingent',
          },
          {
            name: 'Leasing/avbetaling',
            type: 'subCategory',
            displayName: 'Leasing/avbetaling',
          },
          {
            name: 'Minibankuttak',
            type: 'subCategory',
            displayName: 'Minibankuttak',
          },
          {
            name: 'Postkontor',
            type: 'subCategory',
            displayName: 'Postkontor',
          },
        ],
        displayName: 'Diverse',
      },
      {
        name: 'Ferie og reise',
        type: 'category',
        subCategories: [
          {
            name: 'Bilutleie',
            type: 'subCategory',
            displayName: 'Bilutleie',
          },
          {
            name: 'Fly',
            type: 'subCategory',
            displayName: 'Fly',
          },
          {
            name: 'Hotell',
            type: 'subCategory',
            displayName: 'Hotell',
          },
          {
            name: 'Reise',
            type: 'subCategory',
            displayName: 'Reise',
          },
        ],
        displayName: 'Ferie og reise',
      },
      {
        name: 'Forsikring',
        type: 'category',
        subCategories: [
          {
            name: 'Livsforsikring',
            type: 'subCategory',
            displayName: 'Liv',
          },
          {
            name: 'Skadeforsikring',
            type: 'subCategory',
            displayName: 'Skade',
          },
        ],
        displayName: 'Forsikring',
      },
      {
        name: 'Hjem og bolig',
        type: 'category',
        subCategories: [
          {
            name: 'Alarm',
            type: 'subCategory',
            displayName: 'Alarm',
          },
          {
            name: 'Boliglån',
            type: 'subCategory',
            displayName: 'Boliglån',
          },
          {
            name: 'Byggevarer',
            type: 'subCategory',
            displayName: 'Byggevarer',
          },
          {
            name: 'Elektrisitet',
            type: 'subCategory',
            displayName: 'Elektrisitet',
          },
          {
            name: 'Hagesenter',
            type: 'subCategory',
            displayName: 'Hagesenter',
          },
          {
            name: 'Husleie',
            type: 'subCategory',
            displayName: 'Husleie',
          },
          {
            name: 'Interiør',
            type: 'subCategory',
            displayName: 'Interiør',
          },
          {
            name: 'Jernvare',
            type: 'subCategory',
            displayName: 'Jernvare',
          },
          {
            name: 'Kommunale avgifter',
            type: 'subCategory',
            displayName: 'Kommunalt',
          },
          {
            name: 'Offentlige avgifter',
            type: 'subCategory',
            displayName: 'Offentlig',
          },
          {
            name: 'Renovasjon',
            type: 'subCategory',
            displayName: 'Renovasjon',
          },
        ],
        displayName: 'Hjem',
      },
      {
        name: 'Kredittkort',
        type: 'category',
        displayName: 'Kredittkort',
      },
      {
        name: 'Levekostnader',
        type: 'category',
        subCategories: [
          {
            name: 'Apotek',
            type: 'subCategory',
            displayName: 'Apotek',
          },
          {
            name: 'Barnebidrag',
            type: 'subCategory',
            displayName: 'Barnebidrag',
          },
          {
            name: 'Barneklær',
            type: 'subCategory',
            displayName: 'Barneklær',
          },
          {
            name: 'Blomster',
            type: 'subCategory',
            displayName: 'Blomster',
          },
          {
            name: 'Bokhandel',
            type: 'subCategory',
            displayName: 'Bokhandel',
          },
          {
            name: 'Diverse netthandel',
            type: 'subCategory',
            displayName: 'Diverse netthandel',
          },
          {
            name: 'Dyrebutikk',
            type: 'subCategory',
            displayName: 'Dyrebutikk',
          },
          {
            name: 'Dyreklinikk',
            type: 'subCategory',
            displayName: 'Dyreklinikk',
          },
          {
            name: 'Elektronikk',
            type: 'subCategory',
            displayName: 'Elektronikk',
          },
          {
            name: 'Fotobutikk',
            type: 'subCategory',
            displayName: 'Fotobutikk',
          },
          {
            name: 'Frisør',
            type: 'subCategory',
            displayName: 'Frisør',
          },
          {
            name: 'Fritidsaktiviteter',
            type: 'subCategory',
            displayName: 'Fritidsaktiviteter',
          },
          {
            name: 'Gullsmed',
            type: 'subCategory',
            displayName: 'Gullsmed',
          },
          {
            name: 'Helse',
            type: 'subCategory',
            displayName: 'Helse',
          },
          {
            name: 'Helsekost',
            type: 'subCategory',
            displayName: 'Helsekost',
          },
          {
            name: 'Hobbybutikk',
            type: 'subCategory',
            displayName: 'Hobbybutikk',
          },
          {
            name: 'Kafe/kantine',
            type: 'subCategory',
            displayName: 'Kafe/kantine',
          },
          {
            name: 'Kafe/restaurant',
            type: 'subCategory',
            displayName: 'Kafe/restaurant',
          },
          {
            name: 'Kafe/utested',
            type: 'subCategory',
            displayName: 'Kafe/utested',
          },
          {
            name: 'Klær',
            type: 'subCategory',
            displayName: 'Klær',
          },
          {
            name: 'Klær og sko',
            type: 'subCategory',
            displayName: 'Klær og sko',
          },
          {
            name: 'Konserter, teater og underholdning',
            type: 'subCategory',
            displayName: 'Konserter, teater og underholdning',
          },
          {
            name: 'Kvinnebutikk',
            type: 'subCategory',
            displayName: 'Kvinnebutikk',
          },
          {
            name: 'Lege',
            type: 'subCategory',
            displayName: 'Lege',
          },
          {
            name: 'Leketøy',
            type: 'subCategory',
            displayName: 'Leketøy',
          },
          {
            name: 'Nips',
            type: 'subCategory',
            displayName: 'Nips',
          },
          {
            name: 'Optiker',
            type: 'subCategory',
            displayName: 'Optiker',
          },
          {
            name: 'Parfymeri',
            type: 'subCategory',
            displayName: 'Parfymeri',
          },
          {
            name: 'Renseri',
            type: 'subCategory',
            displayName: 'Renseri',
          },
          {
            name: 'Sko',
            type: 'subCategory',
            displayName: 'Sko',
          },
          {
            name: 'Solsenter',
            type: 'subCategory',
            displayName: 'Solsenter',
          },
          {
            name: 'Spill/musikk',
            type: 'subCategory',
            displayName: 'Spill/musikk',
          },
          {
            name: 'Sport & spill',
            type: 'subCategory',
            displayName: 'Sport & spill',
          },
          {
            name: 'Sportsutstyr',
            type: 'subCategory',
            displayName: 'Sportsutstyr',
          },
          {
            name: 'Taxfree',
            type: 'subCategory',
            displayName: 'Taxfree',
          },
          {
            name: 'Vesker',
            type: 'subCategory',
            displayName: 'Vesker',
          },
        ],
        displayName: 'Levekostnader',
      },
      {
        name: 'Mat og drikke',
        type: 'category',
        subCategories: [
          {
            name: 'Alkohol',
            type: 'subCategory',
            displayName: 'Alkohol',
          },
          {
            name: 'Bakervarer',
            type: 'subCategory',
            displayName: 'Bakervarer',
          },
          {
            name: 'Dagligvarer',
            type: 'subCategory',
            displayName: 'Dagligvarer',
          },
          {
            name: 'Hurtigmat',
            type: 'subCategory',
            displayName: 'Hurtigmat',
          },
          {
            name: 'Kiosk',
            type: 'subCategory',
            displayName: 'Kiosk',
          },
          {
            name: 'Spesialforretning',
            type: 'subCategory',
            displayName: 'Spesialforretning',
          },
        ],
        displayName: 'Mat og drikke',
      },
      {
        name: 'Medier og kommunikasjon',
        type: 'category',
        subCategories: [
          {
            name: 'Aviser',
            type: 'subCategory',
            displayName: 'Aviser',
          },
          {
            name: 'Bredbånd',
            type: 'subCategory',
            displayName: 'Bredbånd',
          },
          {
            name: 'Bøker',
            type: 'subCategory',
            displayName: 'Bøker',
          },
          {
            name: 'Fasttelefoni',
            type: 'subCategory',
            displayName: 'Fasttelefoni',
          },
          {
            name: 'Internett-tjenester',
            type: 'subCategory',
            displayName: 'Internett-tjenester',
          },
          {
            name: 'Kabel-TV/Satellitt',
            type: 'subCategory',
            displayName: 'Kabel-TV/Satellitt',
          },
          {
            name: 'Kino',
            type: 'subCategory',
            displayName: 'Kino',
          },
          {
            name: 'Mobiltelefoni',
            type: 'subCategory',
            displayName: 'Mobiltelefoni',
          },
          {
            name: 'Musikk',
            type: 'subCategory',
            displayName: 'Musikk',
          },
          {
            name: 'NRK-avgift',
            type: 'subCategory',
            displayName: 'NRK-avgift',
          },
          {
            name: 'Online-spill',
            type: 'subCategory',
            displayName: 'Online-spill',
          },
          {
            name: 'Spill og programvare',
            type: 'subCategory',
            displayName: 'Spill og programvare',
          },
          {
            name: 'Strømmetjenester',
            type: 'subCategory',
            displayName: 'Strømmetjenester',
          },
          {
            name: 'Tidsskrifter',
            type: 'subCategory',
            displayName: 'Tidsskrifter',
          },
        ],
        displayName: 'Media',
      },
      {
        name: 'Renter',
        type: 'category',
        displayName: 'Renter',
      },
      {
        name: 'Skatt',
        type: 'category',
        displayName: 'Skatt',
      },
      {
        name: 'Tannlege',
        type: 'category',
        displayName: 'Tannlege',
      },
      {
        name: 'Treningssenter',
        type: 'category',
        displayName: 'Treningssenter',
      },
      {
        name: 'Utdanning',
        type: 'category',
        subCategories: [
          {
            name: 'Semesteravgift',
            type: 'subCategory',
            displayName: 'Semesteravgift',
          },
          {
            name: 'Studielån',
            type: 'subCategory',
            displayName: 'Studielån',
          },
        ],
        displayName: 'Utdanning',
      },
    ],
    displayName: 'Utgifter',
  },
];
