import styled from 'styled-components';
import { breakpoints, colors, spacing, sizes } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-payment {
    &__heading,
    &__aside,
    &__info-alert {
      ${horizontalLayoutSpacing};
    }

    &__info-alert {
      margin-bottom: ${spacing.layoutSpacing.xs}px;
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${sizes.formMaxWidthValue}px;
      }

      + .p-fast-betaling__info-alert {
        margin-top: ${spacing.layoutSpacing.sm}px;
      }
    }

    &__no-payment {
      display: flex;
      flex-direction: column;

      &-link-container {
        align-self: center;
        margin-top: ${rem(30)};
      }

      &-link {
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        color: ${colors.tundora};

        &:hover,
        &:focus {
          text-decoration: none;
          color: ${colors.tundora};
        }
      }

      &-link-text {
        color: ${colors.tundora};
        font-size: inherit;
        display: flex;
      }

      &-link-icon {
        align-self: center;
        margin-right: ${rem(20)};

        svg {
          display: block;
          width: 15px;
          height: 15px;
        }
      }
    }

    &__alert-container {
      ${horizontalLayoutSpacing};
      margin-bottom: ${rem(10)};
      padding-top: ${rem(15)};

      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${sizes.formMaxWidthValue}px;
      }
    }

    &__scheduled-payment-alert {
      font-size: ${rem(14)};
      text-align: left;

      &--center-content {
        text-align: center;
      }

      &--large-text {
        font-size: ${rem(15)};
      }

      &__text-container {
        display: flex;
        justify-content: center;
      }
    }

    .nav-list {
      padding-top: ${rem(20)};
      margin-bottom: ${rem(20)};
    }

    .payment-action-list,
    .payment-form,
    .payment-summary {
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${sizes.formMaxWidthValue}px;
      }
    }

    .payment-action-list,
    .p-payment__footer-content,
    .payment-summary {
      ${horizontalLayoutSpacing};
      padding-bottom: 20px;
    }
  }
`;
