import React from 'react';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import ScheduledPaymentNavListLinkContainer from 'containers/ScheduledPaymentNavListLinkContainer';
import { HorizontalLayoutSpacing } from 'styled';

export default () => (
  <HorizontalLayoutSpacing>
    <NavList
      items={[
        <NavListLink
          component={Link}
          to={PATHS.PAY}
          title={{ text: 'Ny betaling' }}
          key="betaling"
        />,
        <ScheduledPaymentNavListLinkContainer />,
        <NavListLink
          component={Link}
          to={PATHS.TRANSFER}
          title={{ text: 'Overføre' }}
          key="overfoere"
        />,
      ]}
    />
  </HorizontalLayoutSpacing>
);
