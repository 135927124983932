import React from 'react';
import Alert from 'digitalbank-alert';
import { Plane } from 'digitalbank-icons';
import { BasePage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import RegionalBlockingMapContainer from './RegionalBlockingMapContainer';
import RegionalBlockingStyled from './RegionalBlockingStyled';

export default () => (
  <RegionalBlockingStyled>
    <BasePage
      className="p-regional-blocking"
      heading="Hvor skal kortene dine være åpne?"
      asideContent={
        <NavList
          items={[
            <NavListLink
              to={PATHS.HOME}
              title={{ text: 'Reiseforsikring' }}
              component={Link}
              key="reiseforsikring"
            />,
            <NavListLink
              to={PATHS.HOME}
              title={{ text: 'Mine kort' }}
              component={Link}
              key="mine-kort"
            />,
            <NavListLink
              to={PATHS.SETTINGS}
              title={{ text: 'Innstillinger' }}
              component={Link}
              key="innstillinger"
            />,
          ]}
        />
      }
    >
      <p>Kortene vil alltid være åpne i Norge.</p>
      <Alert
        type="info"
        small
        icon={Plane}
        className="p-regional-blocking__nudge-alert"
      >
        <div>
          <p>
            <strong>Vi har forenklet regionssperren. </strong>Fra nå av vil
            innstillingen gjelde for alle kort.
          </p>
          {/* <p>
          <strong>Ut og reise?</strong> Husk å{' '}
          <Link to="/">bestille reiseforsikring</Link>
        </p> */}
        </div>
      </Alert>
      <RegionalBlockingMapContainer />
      {/* <RegionalBlockingContainer editMode /> */}
    </BasePage>
  </RegionalBlockingStyled>
);
