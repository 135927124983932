import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Legend from 'digitalbank-form/lib/es/components/base/Legend';
import { InputField } from 'digitalbank-form';
import Rheostat from 'rheostat';
import { formatNumber } from 'utils/format.util';
import geometric from 'rheostat/lib/algorithms/geometric';

class SearchFilterAmountOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      min: '0',
      max: '30000',
    };
  }
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.values.fromAmount !== this.props.values.fromAmount &&
        nextProps.values.fromAmount !== this.state.min) ||
      (nextProps.values.toAmount !== this.props.values.toAmount &&
        nextProps.values.toAmount !== this.state.max)
    ) {
      this.setState({
        min: nextProps.values.fromAmount,
        max: !nextProps.values.toAmount ? '30000' : nextProps.values.toAmount,
      });
    }
  }
  render() {
    const {
      onChange,
      toggleFields,
      getTransactions,
      isFieldsVisible,
      values,
    } = this.props;
    const { min, max } = this.state;
    const maxValue = 30000;
    const maxFormatted = formatNumber(max, 0);
    const minFormatted = formatNumber(min, 0);
    const maxText =
      max === maxValue.toString()
        ? `Over ${maxFormatted} kr`
        : `${maxFormatted} kr`;
    const amountText = `${minFormatted} - ${maxText}`;
    const rehostatToAmount =
      !values.toAmount || values.toAmount > maxValue
        ? maxValue
        : values.toAmount;
    const isInitialValues = values.fromAmount === '' && values.toAmount === '';

    return (
      <fieldset className="fieldset search-filter__group">
        <div className="search-filter__legend search-filter__legend--with-spacing">
          <Legend text="Beløp" headingSelector="h5" />
          <p>{amountText}</p>
        </div>
        <div className="search-filter__container">
          {!isFieldsVisible && (
            <Rheostat
              algorithm={geometric}
              min={0}
              max={30000}
              values={[Number(values.fromAmount), Number(rehostatToAmount)]}
              onValuesUpdated={(e) => {
                this.setState({
                  min: e.values[0].toString(),
                  max: e.values[1].toString(),
                });
              }}
              onChange={(e) => {
                const rheostatFromValue = e.values[0];
                const rheostatToValue = e.values[1];
                const isInitialRheostatValues =
                  rheostatFromValue === 0 && rheostatToValue === maxValue;
                if (!isInitialValues && isInitialRheostatValues) {
                  onChange('searchForm', 'fromAmount', '');
                  onChange('searchForm', 'toAmount', '');
                  getTransactions({
                    ...this.props.values,
                    fromAmount: '',
                    toAmount: '',
                  });
                } else if (!isInitialRheostatValues) {
                  onChange(
                    'searchForm',
                    'fromAmount',
                    rheostatFromValue.toString(),
                  );
                  onChange(
                    'searchForm',
                    'toAmount',
                    rheostatToValue.toString(),
                  );
                  getTransactions({
                    ...this.props.values,
                    fromAmount: rheostatFromValue.toString(),
                    toAmount: rheostatToValue.toString(),
                  });
                }
              }}
            />
          )}
        </div>
        {isFieldsVisible && (
          <div className="search-filter__amount-fields">
            <Field
              className="amount-field"
              name="fromAmount"
              id="fromAmount"
              label="Fra beløp"
              transformableLabel={false}
              component={InputField}
              type="tel"
              onChange={(e) => {
                getTransactions({ ...values, fromAmount: e.target.value });
              }}
            />
            <Field
              className="amount-field"
              name="toAmount"
              id="toAmount"
              label="Til beløp"
              transformableLabel={false}
              component={InputField}
              type="tel"
              onChange={e =>
                getTransactions({ ...values, toAmount: e.target.value })
              }
            />
          </div>
        )}
        <div className="search-filter__container">
          <button
            className="search-filter__fieldset-btn"
            onClick={() => {
              if (!isInitialValues) {
                onChange('searchForm', 'toAmount', '');
                onChange('searchForm', 'fromAmount', '');
                getTransactions({ ...values, fromAmount: '', toAmount: '' });
              }
              toggleFields();
            }}
          >
            {!isFieldsVisible && <span>Skriv beløp</span>}
            {isFieldsVisible && <span>Skjul beløp</span>}
          </button>
        </div>
      </fieldset>
    );
  }
}

SearchFilterAmountOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  toggleFields: PropTypes.func.isRequired,
  isFieldsVisible: PropTypes.bool.isRequired,
  getTransactions: PropTypes.func.isRequired,
  values: PropTypes.shape({
    fromAmount: PropTypes.string,
    toAmount: PropTypes.string,
  }).isRequired,
};

export default SearchFilterAmountOptions;
