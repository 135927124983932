import styled from 'styled-components';
import { breakpoints, colors, layout, spacing } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { fontFamily } from 'digitalbank-core-style/lib/typography';
import {
  formMaxWidthValue,
  formControlAddonButtonWidthValue,
} from 'digitalbank-core-style/lib/sizes';
import { rem } from 'polished';

export default styled.div`
  .p-payment-global {
    &__heading,
    &__main,
    &__aside {
      ${horizontalLayoutSpacing};
    }

    &__footer {
      ${horizontalLayoutSpacing};
    }

    &__aside {
      ${`${spacing.layoutSpacing.sm}px`};
    }

    &__receipt {
      @media (max-width: ${breakpoints.tablet}px) {
        margin-top: ${rem(-20)};
        margin-left: ${rem(layout.horizontalSpacing.phone * -1)};
        margin-right: ${rem(layout.horizontalSpacing.phone * -1)};
      }
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${formMaxWidthValue}px;
      }
    }

    &__summary,
    &__details {
      display: inline-block;
      width: 100%;
      @media (max-width: ${breakpoints.tablet - 1}px) {
        padding-left: ${rem(layout.horizontalSpacing.phone)};
        padding-right: ${rem(layout.horizontalSpacing.phone)};
      }
    }

    &__summary {
      padding-bottom: ${rem(20)};

      &-details-button,
      &-submit-button {
        display: inline-block;
        width: 100%;
      }

      &-submit-button {
        margin-top: ${rem(20)};
      }

      &-details-button {
        margin-bottom: ${rem(spacing.layoutSpacing.xs)};
      }

      &-heading,
      &-sub-heading {
        display: table;
        width: 100%;

        &-element {
          display: table-cell;

          &:first-of-type {
            width: 100%;
          }

          &:nth-of-type(2) {
            text-align: right;
            min-width: ${rem(150)};
          }
        }
      }
    }

    &__details {
      margin: ${rem(spacing.layoutSpacing.xs)} 0;
      padding-bottom: ${rem(spacing.layoutSpacing.xs)};
      &__chevron {
        /* @include accordion-item-chevron; */
      }

      &-section {
        margin-bottom: ${rem(20)};
        @media (min-width: ${breakpoints.tablet}px) {
          padding-right: ${rem(formControlAddonButtonWidthValue)};
        }
      }

      &-header {
        margin-top: 0;
      }

      &-button {
        display: inline-block;
        width: 100%;
        font-family: ${fontFamily.secondary};
        font-size: ${rem(20)};
        color: $dove-gray;
      }
    }

    &__detail-group {
      ~ .p-payment-global__detail-group {
        padding-top: ${rem(spacing.layoutSpacing.xs)};
        margin-top: ${rem(spacing.layoutSpacing.xs)};
        border-top: 1px solid $alto;
      }
    }

    &__alert-container {
      margin: 0 0 15px;
      /* Used to create indent when using input help button */
      padding-right: ${rem(formControlAddonButtonWidthValue)};
    }

    .nav-list {
      margin-top: 20px;
      @media (max-width: ${breakpoints.tablet}px) {
        padding: 0 ${rem(layout.horizontalSpacing.phone)};
      }
    }

    .modal-card {
      margin: 40px auto;
      width: calc(100% - 40px);
    }

    .deactivated-payment-component {
      ${horizontalLayoutSpacing};

      &__text-content {
        margin-bottom: ${spacing.layoutSpacing.xs}px;
      }

      &__map-wrapper {
        position: relative;
      }

      &__map {
        fill: ${colors.lightGray};
      }

      &__activate-button {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;

        &::before {
          content: ' ';
          display: block;
          height: 40%;
        }

        .spinner {
          &::after {
            border-right-color: #fff;
            border-bottom-color: #fff;
          }
        }
      }

      &__info-link {
        display: block;
        text-align: center;
      }
    }
  }

  .payment-global-form {
    @media (max-width: ${breakpoints.tablet}px - 1) {
      padding-top: ${rem(16)};
    }
    @media (min-width: ${breakpoints.tablet}px) {
      max-width: ${formMaxWidthValue}px;
    }

    &__language-toggler {
      position: absolute;
      top: 0;
      right: ${rem(layout.horizontalSpacing.phone)};
      @media (min-width: ${breakpoints.tablet}px) {
        right: 0;
      }
    }

    &__default-fieldset {
      margin: 0 0 ${rem(20)};
    }

    &__advanced-fieldset {
      margin: ${rem(20)} 0;

      &-checkbox-form-groups {
        padding-left: ${rem(layout.horizontalSpacing.phone)}px;
      }
    }

    &__actions,
    &__summary-actions {
      display: flex;
      flex-direction: column;
      margin-bottom: ${rem(20)};

      @media (min-width: ${breakpoints.tablet}px) {
        flex-direction: row-reverse;
        justify-content: flex-end;
      }
    }

    &__actions {
      margin-right: ${rem(formControlAddonButtonWidthValue)};

      @media (max-width: ${breakpoints.tablet}px - 1) {
        margin-right: ${rem(layout.horizontalSpacing.phone)};
        margin-left: ${rem(layout.horizontalSpacing.phone)};
      }
    }

    &__submit-button-wrapper {
      ${horizontalLayoutSpacing({ tablet: false })} margin-top: 20px;
    }

    &__next-button {
      .spinner {
        &::after {
          border-right-color: #fff;
          border-bottom-color: #fff;
        }
      }
    }

    &__next-button,
    &__back-button,
    &__submit-button {
      width: 100%;
      margin-bottom: ${rem(10)};
      @media (min-width: ${breakpoints.tablet}px) {
        /* Cant use auto because we need space for spinner */
        width: 8rem;
      }
    }

    &__back-button {
      @media (min-width: ${breakpoints.tablet}px) {
        margin-right: ${rem(12)};
      }
    }

    &__info-alert {
      margin-bottom: ${rem(20)};
      @media (max-width: ${breakpoints.tablet}px - 1) {
        margin-right: ${rem(layout.horizontalSpacing.phone)};
        margin-left: ${rem(layout.horizontalSpacing.phone)};
      }
    }

    &__submission-alert {
      margin-bottom: ${rem(20)};
      margin-right: ${rem(formControlAddonButtonWidthValue)};
      @media (max-width: ${breakpoints.tablet}px - 1) {
        margin-right: ${rem(layout.horizontalSpacing.phone)};
        margin-left: ${rem(layout.horizontalSpacing.phone)};
      }
    }

    .fieldset {
      /* We need this to position the language toggler. */
      position: relative;
      display: inline-block;
      width: 100%;

      @media (max-width: ${breakpoints.tablet}px - 1) {
        padding-right: ${rem(layout.horizontalSpacing.phone)};
        padding-left: ${rem(layout.horizontalSpacing.phone)};
      }
    }

    .select-wrapper {
      width: 100%;
    }

    .account-picker {
      /* Used to create indent when using input help button */
      padding-right: ${rem(formControlAddonButtonWidthValue)};
    }

    .amount-and-date-row {
      padding-right: ${rem(formControlAddonButtonWidthValue)};
    }

    .postal-information-row {
      padding-right: ${rem(formControlAddonButtonWidthValue)};

      &__postalcode {
        width: 38%;
        margin-right: 4%;
      }

      &__postalcity {
        width: 58%;
      }
    }

    .bankinfo-input,
    .recipient-field {
      .form-control.input {
        text-transform: uppercase;
      }
    }

    .hastebetaling {
      .tooltip {
        margin-top: 0.78rem;
      }
    }
  }

  .key-value-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    width: 100%;

    &__row {
      display: table-row;
    }

    &__key,
    &__value {
      display: table-cell;
      width: 50%;
      font-size: ${rem(14)};
      padding-bottom: 3px;
    }

    &__key {
      font-weight: 700;
      margin: 0;
    }

    &__value {
      color: ${colors.boulder};
    }
  }
`;
