/* eslint-disable no-mixed-operators */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'digitalbank-modal';
import styled from 'styled-components';
import TransactionCard from './TransactionCard';

const TransactionModalContentStyled = styled.div`
  position: relative;
  margin: 43px 14px;
  background: #fff;
`;

const TransactionModal = ({
  visible, isOpened, toggle, transaction,
}) => (
  <Modal visible={visible} toggle={toggle} fullSize>
    <TransactionModalContentStyled>
      {transaction && (
        <TransactionCard
          transaction={transaction}
          isOpened={isOpened}
          toggle={toggle}
          visible={visible}
        />
      )}
    </TransactionModalContentStyled>
  </Modal>
);
TransactionModal.defaultProps = {
  transaction: null,
};

TransactionModal.propTypes = {
  transaction: PropTypes.shape({}),
  visible: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpened: PropTypes.bool.isRequired,
};

export default TransactionModal;
