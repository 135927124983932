// eslint-disable max-len

import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import TransitionRoutes from 'components/TransitionRoutes';
import { isApp, isWebApp } from 'utils/detect.util';
import Animate from 'react-router-animate';

import PrivateRoute from 'components/PrivateRoute';
import routeComponent from 'routes/routeComponent';
import i18n from 'i18n';
import headings from 'i18n/routes';
import * as PATH from 'routes/paths';

import Login from 'routes/Login/Login';
import Home from 'routes/Home/Home';
import HomeSettings from 'routes/Home/HomeSettings';
import NotFound from 'routes/NotFound/NotFound';
import PaymentGlobal from 'routes/PaymentGlobal/PaymentGlobal';
import PaymentGlobalFirstPage from 'routes/PaymentGlobal/Wizard/FirstPage';
import PaymentGlobalSecondPage from 'routes/PaymentGlobal/Wizard/SecondPage';
import PaymentGlobalSummary from 'routes/PaymentGlobal/Wizard/Summary';
import PaymentGlobalReauth from 'routes/PaymentGlobal/Wizard/Reauth';
import PaymentGlobalReceipt from 'routes/PaymentGlobal/Wizard/Receipt';

import PayNavigation from 'routes/PayNavigation/PayNavigation';
import BetaleEn from 'routes/Payment/BetaleEn/BetaleEn';
import BetaleEnSummary from 'routes/Payment/BetaleEn/BetaleEnSummary';
import BetaleEnReauth from 'routes/Payment/BetaleEn/BetaleEnReauth';
import BetaleEnReceipt from 'routes/Payment/BetaleEn/BetaleEnReceipt';

import EditBetaleEn from 'routes/Payment/EditBetaleEn/EditBetaleEn';
import EditBetaleEnReceipt from 'routes/Payment/EditBetaleEn/EditBetaleEnReceipt';

import FasteOppdrag from 'routes/FasteOppdrag/FasteOppdrag';

import FastBetaling from 'routes/Payment/FastBetaling/FastBetaling';
import FastBetalingReauth from 'routes/Payment/FastBetaling/FastBetalingReauth';
import FastBetalingReceipt from 'routes/Payment/FastBetaling/FastBetalingReceipt';

import EditFastBetaling from 'routes/Payment/EditFastBetaling/EditFastBetaling';
import EditFastBetalingReauth from 'routes/Payment/EditFastBetaling/EditFastBetalingReauth';
import EditFastBetalingReceipt from 'routes/Payment/EditFastBetaling/EditFastBetalingReceipt';

import Overfoere from 'routes/Transfer/Overfoere/Overfoere';
import OverfoereReceipt from 'routes/Transfer/Overfoere/OverfoereReceipt';
import EditEnOverfoering from 'routes/Transfer/EditEnOverfoering/EditEnOverfoering';
import EditEnOverfoeringReceipt from 'routes/Transfer/EditEnOverfoering/EditEnOverfoeringReceipt';

import FastOverfoering from 'routes/Transfer/FastOverfoering/FastOverfoering';
import FastOverfoeringReceipt from 'routes/Transfer/FastOverfoering/FastOverfoeringReceipt';

import EditFastOverfoering from 'routes/Transfer/EditFastOverfoering/EditFastOverfoering';
import EditFastOverfoeringReceipt from 'routes/Transfer/EditFastOverfoering/EditFastOverfoeringReceipt';

import ContactInfo from 'routes/ContactInfo/ContactInfoSettings';
import ContactInfoPeriodicControl from 'routes/ContactInfo/ContactInfoPeriodicControl';

import RegionalBlocking from 'routes/RegionalBlocking/RegionalBlocking';
import RegionalBlockingPeriodicControl from 'routes/RegionalBlocking/RegionalBlockingPeriodicControl';

import DataConsent from 'routes/DataConsent/DataConsent';

import Transactions from 'routes/Transactions/Transactions';

import Account from 'routes/Account/Account';
import Card from 'routes/Card/Card';
import Loans from 'routes/Loan/Loans';
import Loan from 'routes/Loan/Loan';
import InterestRateHistory from 'routes/Loan/InterestRateHistory';
import Saving from 'routes/Saving/Saving';
import Esurance from 'routes/Esurance/Esurance';
import Settings from 'routes/Settings/Settings';
import Recipients from 'routes/Recipients/Recipients';
import ScheduledPayments from 'routes/Forfallsmappe/ScheduledPayments';

import Search from 'routes/Search/Search';

import Avtaleadministrasjon from 'routes/Avtaleadministrasjon/Avtaleadministrasjon';
import { AvtaleadministrasjonFilters } from 'routes/Avtaleadministrasjon/avtaleadministrasjon.constants';
import EfakturaCreatePage from 'routes/Avtaleadministrasjon/Efaktura/EfakturaCreatePage';
import EfakturaUpdatePage from 'routes/Avtaleadministrasjon/Efaktura/EfakturaUpdatePage';
import AvtalegiroCreatePage from 'routes/Avtaleadministrasjon/Avtalegiro/AvtalegiroCreatePage';
import AvtalegiroUpdatePage from 'routes/Avtaleadministrasjon/Avtalegiro/AvtalegiroUpdatePage';
import EfakturaAndAvtalegiroCreatePage from 'routes/Avtaleadministrasjon/EfakturaAndAvtalegiro/CreatePage';
import EfakturaAndAvtalegiroUpdatePage from 'routes/Avtaleadministrasjon/EfakturaAndAvtalegiro/UpdatePage';

import EfakturaFraAlleInfoPage from 'routes/Avtaleadministrasjon/EfakturaFraAlle/EfakturaFraAlleInfoPage';
import EfakturaFraAlleSetupInfoPage from 'routes/Avtaleadministrasjon/EfakturaFraAlle/EfakturaFraAlleSetupInfoPage';
import EfakturaFraAlleSetupPage from 'routes/Avtaleadministrasjon/EfakturaFraAlle/EfakturaFraAlleSetupPage';
import EfakturaFraAlleReceiptPage from 'routes/Avtaleadministrasjon/EfakturaFraAlle/EfakturaFraAlleReceiptPage';
import EfakturaFraAlleSettingsPage from 'routes/Avtaleadministrasjon/EfakturaFraAlle/EfakturaFraAlleSettingsPage';
import EfakturaFraAlleSperredeAvsenderePage from 'routes/Avtaleadministrasjon/EfakturaFraAlle/EfakturaFraAlleSperredeAvsenderePage';

import BankIDPage from 'routes/BankID/BankIDPage';
import BankIDOrderPage from 'routes/BankID/BankIDOrderPage';
import BankIDOrderReceipt from 'routes/BankID/BankIDOrderReceipt';

import BankIDMobilePage from 'routes/BankID/BankIDMobilePage';
import BankIDMobilOrderPage from 'routes/BankID/BankIDMobilOrderPage';
import BankIDMobilOrderReceipt from 'routes/BankID/BankIDMobilOrderReceipt';

import BankIDOrderNewKodebrikkePage from 'routes/BankID/BankIDOrderNewKodebrikkePage';
import BankIDOrderNewKodebrikkePageReceipt from 'routes/BankID/BankIDOrderNewKodebrikkePageReceipt';

import TestPage from 'routes/Test/TestPage';
import Economy from 'routes/Economy';
import Merchants from 'routes/Economy/Merchants';

import TagsPage from 'routes/Tags';
import TravelPage from 'routes/Tags/TravelPage';
import TagPage from 'routes/Tags/Tag';
import TravelSummaryPage from 'routes/Tags/TravelSummaryPage';

import CreateAutomaticTagPage from 'routes/Tags/AutomaticTag/Wizard/Default';
import CreateAutomaticTagPageTravel from 'routes/Tags/AutomaticTag/Wizard/Travel';
import CreateAutomaticTagPageTravelWithWork from 'routes/Tags/AutomaticTag/Wizard/TravelWithWork';

// const iosStatusBarHeight = 20;
const headerHeight = 50;
const tabBarHeight = 64;
const animateCustomStyle = () => {
  if (isApp() || isWebApp()) {
    return `
      background-color: #f3f3f3;
      box-shadow: 0 0 24px 0 #bbb;
      top: calc(${headerHeight}px + constant(safe-area-inset-top));
      top: calc(${headerHeight}px + env(safe-area-inset-top));
      min-height: calc(100vh - ${headerHeight}px - ${tabBarHeight}px - constant(safe-area-inset-bottom));
      min-height: calc(100vh - ${headerHeight}px - ${tabBarHeight}px - env(safe-area-inset-bottom));
    `;
  }
  return `
    background-color: #f3f3f3;
    box-shadow: 0 0 24px 0 #bbb;
    top: ${headerHeight}px;
    min-height: calc(100vh - ${headerHeight}px - ${tabBarHeight}px);
  `;
};

const Routes = () => (
  <Route
    render={({ location, history }) => (
      <Animate
        location={location}
        hasAnimation={window.innerWidth <= 768}
        customStyle={animateCustomStyle()}
      >
        <Switch location={location}>
          <Route
            exact
            path={PATH.LOGIN}
            component={routeComponent(Login, i18n(x => x.ROUTE_TITLE_LOGIN))}
          />
          <PrivateRoute
            exact
            path={PATH.HOME}
            component={routeComponent(Home, '')}
          />
          <PrivateRoute
            exact
            path={PATH.HOME_SETTINGS}
            component={routeComponent(HomeSettings, 'Innstillinger')}
          />
          <PrivateRoute
            exact
            path={PATH.PAY_NAVIGATION}
            component={routeComponent(
              PayNavigation,
              i18n(x => x.ROUTE_TITLE_PAY_NAVIGATION),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAY}
            component={routeComponent(BetaleEn, i18n(x => x.ROUTE_TITLE_PAY))}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_SUMMARY}
            component={routeComponent(
              BetaleEnSummary,
              i18n(x => x.ROUTE_TITLE_PAYMENT_SUMMARY),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_REAUTH}
            component={routeComponent(
              BetaleEnReauth,
              i18n(x => x.ROUTE_TITLE_PAYMENT_REAUTH),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_RECEIPT}
            component={routeComponent(
              BetaleEnReceipt,
              i18n(x => x.ROUTE_TITLE_PAYMENT_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_GLOBAL}
            component={routeComponent(
              () => (
                <PaymentGlobal heading={headings.PAYMENT_GLOBAL}>
                  <PaymentGlobalFirstPage history={history} />
                </PaymentGlobal>
              ),
              i18n(x => x.ROUTE_TITLE_PAYMENT_GLOBAL),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_GLOBAL_SECOND}
            component={routeComponent(
              () => (
                <PaymentGlobal
                  heading={headings.PAYMENT_GLOBAL_SECOND}
                  displayPublishedContent={false}
                >
                  <PaymentGlobalSecondPage history={history} />
                </PaymentGlobal>
              ),
              i18n(x => x.ROUTE_TITLE_PAYMENT_GLOBAL_SECOND),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_GLOBAL_SUMMARY}
            component={routeComponent(
              () => (
                <PaymentGlobal
                  heading={headings.PAYMENT_GLOBAL_SUMMARY}
                  displayHeading={false}
                  displayPublishedContent={false}
                >
                  <PaymentGlobalSummary history={history} />
                </PaymentGlobal>
              ),
              i18n(x => x.ROUTE_TITLE_PAYMENT_GLOBAL_SUMMARY),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_GLOBAL_REAUTH}
            component={routeComponent(
              () => (
                <PaymentGlobalReauth history={history} />
              ),
              i18n(x => x.ROUTE_TITLE_PAYMENT_GLOBAL_REAUTH),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PAYMENT_GLOBAL_RECEIPT}
            component={routeComponent(
              () => (
                <PaymentGlobal
                  heading={headings.PAYMENT_GLOBAL_RECEIPT}
                  displayHeading={false}
                  displayPublishedContent={false}
                >
                  <PaymentGlobalReceipt history={history} />
                </PaymentGlobal>
              ),
              i18n(x => x.ROUTE_TITLE_PAYMENT_GLOBAL_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.EDIT_BETALE_EN}/:id`}
            component={routeComponent(
              EditBetaleEn,
              i18n(x => x.ROUTE_TITLE_PAYMENT_EDIT),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.EDIT_BETALE_EN_RECEIPT}/:id`}
            component={routeComponent(
              EditBetaleEnReceipt,
              i18n(x => x.ROUTE_TITLE_PAYMENT_EDIT_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.FASTE_OPPDRAG}
            component={routeComponent(
              FasteOppdrag,
              i18n(x => x.ROUTE_TITLE_FASTE_OPPDRAG),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.FAST_BETALING}
            component={routeComponent(
              FastBetaling,
              i18n(x => x.ROUTE_TITLE_FAST_BETALING),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.FAST_BETALING_REAUTH}
            component={routeComponent(
              FastBetalingReauth,
              i18n(x => x.ROUTE_TITLE_FAST_BETALING_REAUTH),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.FAST_BETALING_RECEIPT}
            component={routeComponent(
              FastBetalingReceipt,
              i18n(x => x.ROUTE_TITLE_FAST_BETALING_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.FAST_BETALING_EDIT}/:id`}
            component={routeComponent(
              EditFastBetaling,
              i18n(x => x.ROUTE_TITLE_EDIT_FAST_BETALING),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.FAST_BETALING_EDIT_REAUTH}/:id`}
            component={routeComponent(
              EditFastBetalingReauth,
              i18n(x => x.ROUTE_TITLE_FAST_BETALING_REAUTH),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.FAST_BETALING_EDIT_RECEIPT}/:id`}
            component={routeComponent(
              EditFastBetalingReceipt,
              i18n(x => x.ROUTE_TITLE_FAST_BETALING_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.TRANSACTIONS}/:accountId`}
            component={routeComponent(
              Transactions,
              i18n(x => x.ROUTE_TITLE_TRANSACTIONS),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.TRANSFER}
            component={routeComponent(
              Overfoere,
              i18n(x => x.ROUTE_TITLE_TRANSFER),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.TRANSFER_RECEIPT}
            component={routeComponent(
              OverfoereReceipt,
              i18n(x => x.ROUTE_TITLE_TRANSFER_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.TRANSFER_EDIT}/:id`}
            component={routeComponent(
              EditEnOverfoering,
              i18n(x => x.ROUTE_TITLE_EDIT_TRANSFER),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.TRANSFER_EDIT_RECEIPT}/:id`}
            component={routeComponent(
              EditEnOverfoeringReceipt,
              i18n(x => x.ROUTE_TITLE_EDIT_TRANSFER_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.FAST_OVERFOERING}
            component={routeComponent(
              FastOverfoering,
              i18n(x => x.ROUTE_TITLE_FAST_OVERFOERING),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.FAST_OVERFOERING_RECEIPT}
            component={routeComponent(
              FastOverfoeringReceipt,
              i18n(x => x.ROUTE_TITLE_FAST_OVERFOERING_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.FAST_OVERFOERING_EDIT}/:id`}
            component={routeComponent(
              EditFastOverfoering,
              i18n(x => x.ROUTE_TITLE_FAST_OVERFOERING_EDIT),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.FAST_OVERFOERING_EDIT_RECEIPT}/:id`}
            component={routeComponent(
              EditFastOverfoeringReceipt,
              i18n(x => x.ROUTE_TITLE_FAST_OVERFOERING_EDIT_RECEIPT),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.ACCOUNT}
            component={routeComponent(
              Account,
              i18n(x => x.ROUTE_TITLE_ACCOUNT),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.CARD}
            component={routeComponent(Card, i18n(x => x.ROUTE_TITLE_CARD))}
          />
          <PrivateRoute
            exact
            path={PATH.LOANS}
            component={routeComponent(Loans, i18n(x => x.ROUTE_TITLE_LOAN))}
          />
          <PrivateRoute
            exact
            path={`${PATH.LOAN}/:id`}
            component={routeComponent(Loan, i18n(x => x.ROUTE_TITLE_LOAN))}
          />
          <PrivateRoute
            exact
            path={PATH.LOAN_INTEREST_RATE_HISTORY}
            component={routeComponent(
              InterestRateHistory,
              i18n(x => x.ROUTE_TITLE_LOAN_INTEREST_RATE_HISTORY),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.SAVING}
            component={routeComponent(Saving, i18n(x => x.ROUTE_TITLE_SAVING))}
          />
          <PrivateRoute
            exact
            path={PATH.ESURANCE}
            component={routeComponent(
              Esurance,
              i18n(x => x.ROUTE_TITLE_ESURANCE),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.CONTACT_INFO}
            component={routeComponent(
              ContactInfo,
              i18n(x => x.ROUTE_TITLE_CONTACT_INFO),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.CONTACT_INFO_PERIODIC_CONTROL}
            component={routeComponent(
              ContactInfoPeriodicControl,
              i18n(x => x.ROUTE_TITLE_CONTACT_INFO_PERIODIC_CONTROL),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.REGIONAL_BLOCKING}
            component={routeComponent(
              RegionalBlocking,
              i18n(x => x.ROUTE_TITLE_REGIONAL_BLOCKING),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.PERIODIC_CONTROL_REGIONAL_BLOCKING_ISOLATED}
            component={routeComponent(
              RegionalBlockingPeriodicControl,
              i18n(
                x => x.ROUTE_TITLE_PERIODIC_CONTROL_REGIONAL_BLOCKING_ISOLATED,
              ),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.DATA_CONSENT}
            component={routeComponent(
              DataConsent,
              i18n(x => x.ROUTE_TITLE_DATA_CONSENT),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.DATA_CONSENT_PERIODIC_CONTROL}
            component={routeComponent(
              () => (
                <DataConsent isPeriodicControl />
              ),
              i18n(x => x.ROUTE_TITLE_DATA_CONSENT_PERIODIC_CONTROL),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.SETTINGS}
            component={routeComponent(
              Settings,
              i18n(x => x.ROUTE_TITLE_SETTINGS),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.RECIPIENTS}
            component={routeComponent(
              Recipients,
              i18n(x => x.ROUTE_TITLE_RECIPIENTS),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.SCHEDULED_PAYMENTS}
            component={routeComponent(
              ScheduledPayments,
              i18n(x => x.ROUTE_TITLE_SCHEDULED_PAYMENTS),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.SEARCH}
            component={routeComponent(Search, i18n(x => x.ROUTE_TITLE_SEARCH))}
          />
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_AND_AVTALEGIRO}
            component={routeComponent(
              Avtaleadministrasjon,
              i18n(x => x.ROUTE_TITLE_PAYMENT_AGREEMENTS),
            )}
          />
          ,
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_AND_AVTALEGIRO_SUGGESTIONS}
            component={routeComponent(
              () => (
                <Avtaleadministrasjon
                  filter={AvtaleadministrasjonFilters.FORSLAG}
                />
              ),
              i18n(x => x.ROUTE_TITLE_PAYMENT_AGREEMENTS),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.EFAKTURA_CREATE}/:tilbyderId`}
            component={routeComponent(
              EfakturaCreatePage,
              i18n(x => x.ROUTE_TITLE_EFAKTURA_CREATE),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.EFAKTURA_UPDATE}/:tilbyderId`}
            component={routeComponent(
              EfakturaUpdatePage,
              i18n(x => x.ROUTE_TITLE_EFAKTURA_UPDATE),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.AVTALEGIRO_CREATE}/:tilbyderId`}
            component={routeComponent(
              AvtalegiroCreatePage,
              i18n(x => x.ROUTE_TITLE_AVTALEGIRO_CREATE),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.AVTALEGIRO_UPDATE}/:tilbyderId`}
            component={routeComponent(
              AvtalegiroUpdatePage,
              i18n(x => x.ROUTE_TITLE_AVTALEGIRO_UPDATE),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.EFAKTURA_AND_AVTALEGIRO_CREATE}/:tilbyderId`}
            component={routeComponent(
              EfakturaAndAvtalegiroCreatePage,
              i18n(x => x.ROUTE_TITLE_EFAKTURA_AND_AVTALEGIRO_CREATE),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.EFAKTURA_AND_AVTALEGIRO_UPDATE}/:tilbyderId`}
            component={routeComponent(
              EfakturaAndAvtalegiroUpdatePage,
              i18n(x => x.ROUTE_TITLE_EFAKTURA_AND_AVTALEGIRO_UPDATE),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_FRA_ALLE_INFO}
            component={routeComponent(
              EfakturaFraAlleInfoPage,
              'eFaktura fra alle',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_FRA_ALLE_ACTIVATION_INFO}
            component={routeComponent(
              EfakturaFraAlleSetupInfoPage,
              'eFaktura fra alle',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_FRA_ALLE_ACTIVATION_SETUP}
            component={routeComponent(
              EfakturaFraAlleSetupPage,
              'eFaktura fra alle',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_FRA_ALLE_ACTIVATION_RECEIPT}
            component={routeComponent(
              EfakturaFraAlleReceiptPage,
              'eFaktura fra alle',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_FRA_ALLE_SETTINGS}
            component={routeComponent(
              EfakturaFraAlleSettingsPage,
              'eFaktura fra alle',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.EFAKTURA_FRA_ALLE_SPERREDE_AVSENDERE}
            component={routeComponent(
              EfakturaFraAlleSperredeAvsenderePage,
              'Sperrede avsendere',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID}
            component={routeComponent(
              () => (
                <BankIDPage />
              ),
              'BankID',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_ORDER}
            component={routeComponent(
              () => (
                <BankIDOrderPage history={history} isIdentifiedWithKodebrikke />
              ),
              'Bestill BankID',
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.BANKID_ORDER}/uten-kodebrikke`}
            component={routeComponent(
              () => (
                <BankIDOrderPage history={history} />
              ),
              'Bestill BankID',
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.BANKID_ORDER}/uten-kodebrikke/new`}
            component={routeComponent(
              () => (
                <BankIDOrderPage hasReauth history={history} />
              ),
              'Bestill BankID',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_ORDER_RECEIPT}
            component={routeComponent(BankIDOrderReceipt, 'BankID er bestilt')}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_MOBILE}
            component={routeComponent(
              () => (
                <BankIDMobilePage />
              ),
              'BankID på mobil',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_MOBILE_ORDER}
            component={routeComponent(
              () => (
                <BankIDMobilOrderPage
                  isIdentifiedWithKodebrikke
                  history={history}
                />
              ),
              'Bestill BankID på mobil',
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.BANKID_MOBILE_ORDER}/uten-kodebrikke`}
            component={routeComponent(
              () => (
                <BankIDMobilOrderPage
                  isIdentifiedWithKodebrikke={false}
                  history={history}
                />
              ),
              'Bestill BankID på mobil',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_MOBILE_ORDER_RECEIPT}
            component={routeComponent(
              BankIDMobilOrderReceipt,
              'BankID på mobil er bestilt',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_MOBILE_ORDER_RECEIPT}
            component={routeComponent(
              BankIDMobilOrderReceipt,
              'BankID på mobil er bestilt',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_NEW_KODEBRIKKE_ORDER}
            component={routeComponent(
              BankIDOrderNewKodebrikkePage,
              'Bestill ny kodebrikke',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.BANKID_NEW_KODEBRIKKE_ORDER_RECEIPT}
            component={routeComponent(
              BankIDOrderNewKodebrikkePageReceipt,
              'Ny kodebrikke bestilt',
            )}
          />
          <PrivateRoute
            exact
            path={PATH.ECONOMY}
            component={routeComponent(
              Economy,
              i18n(x => x.ROUTE_TITLE_ECONOMY),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.ECONOMY_MERCHANTS}
            component={routeComponent(
              Merchants,
              i18n(x => x.ROUTE_TITLE_ECONOMY),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.DEMO}
            component={routeComponent(TestPage, 'Demo')}
          />
          <PrivateRoute
            exact
            path={PATH.TRAVEL}
            component={routeComponent(
              TravelPage,
              i18n(t => t.ROUTE_TITLE_TRAVEL),
            )}
          />
          <PrivateRoute
            exact
            path={`${PATH.TRAVEL_SUMMARY}/:id`}
            component={routeComponent(
              TravelSummaryPage,
              i18n(t => t.ROUTE_TITLE_TRAVEL_SUMMARY),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.TAGS}
            component={routeComponent(TagsPage, i18n(t => t.ROUTE_TITLE_TAGS))}
          />
          <PrivateRoute
            exact
            path={`${PATH.TAG}/:id`}
            component={routeComponent(TagPage, i18n(t => t.ROUTE_TITLE_TAG))}
          />
          <PrivateRoute
            exact
            path={PATH.CREATE_AUTOMATIC_TAG}
            component={routeComponent(
              CreateAutomaticTagPage,
              i18n(t => t.ROUTE_TITLE_CREATE_AUTOMATIC_TAG),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.CREATE_AUTOMATIC_TAG_TRAVEL}
            component={routeComponent(
              CreateAutomaticTagPageTravel,
              i18n(t => t.ROUTE_TITLE_CREATE_AUTOMATIC_TAG_TRAVEL),
            )}
          />
          <PrivateRoute
            exact
            path={PATH.CREATE_AUTOMATIC_TAG_TRAVEL_WITH_WORK}
            component={routeComponent(
              CreateAutomaticTagPageTravelWithWork,
              i18n(t => t.ROUTE_TITLE_CREATE_AUTOMATIC_TAG_TRAVEL_WORK),
            )}
          />
          <Route
            component={routeComponent(
              NotFound,
              i18n(x => x.ROUTE_TITLE_NOT_FOUND),
            )}
          />
        </Switch>
      </Animate>
    )}
  />
);

export default Routes;
