import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AccountListWrapper from 'digitalbank-account-list/lib/components/AccountListWrapper';
import { mapAccountsToAccountListComponent } from 'services/account.service';
import { BasePage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATH from 'routes/paths';
import i18n from 'i18n';
import _ from 'lodash';
import { BSU, KREDITT } from 'digitalbank-mockup-data/lib/AccountTypes';
import CustomerSettingsContainer from 'containers/CustomerSettingsContainer';
import Aside from './Aside';
import AccountStyled from './AccountStyled';

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAccountId: '',
    };
    this.setActiveAccountId = this.setActiveAccountId.bind(this);
  }
  setActiveAccountId(id) {
    const newId = id === this.state.activeAccountId ? '' : id;
    this.setState({
      activeAccountId: newId,
    });
  }
  render() {
    const filteredAccounts = _.filter(
      this.props.accounts,
      x => (x.active || x.type === BSU) && x.type !== KREDITT,
    );
    const mappedAccounts = mapAccountsToAccountListComponent(filteredAccounts);
    const activeAccounts = _.filter(mappedAccounts, x => x.active);
    const unactiveAccounts = _.filter(mappedAccounts, x => !x.active);
    const activeSortedAccounts = _.sortBy(activeAccounts, 'accountName');

    const orderdAccounts = [...activeSortedAccounts, ...unactiveAccounts];
    const { userSettings } = this.props;
    const visSumForfall =
      userSettings.forfallPresentation &&
      userSettings.forfallPresentation.value === 'SUM_FORFALL';
    const visTrukketKreditt =
      userSettings.kredittkortPresentation &&
      userSettings.kredittkortPresentation.value === 'TRUKKET';
    return (
      <AccountStyled>
        <BasePage className="p-account" asideContent={<Aside />}>
          <CustomerSettingsContainer
            heading="Mine kontoer"
            accounts={this.props.accounts}
            selectedAccounts={this.props.selectedAccounts}
          />
          <div className="p-account__account-list-section p-account__account-list-section--is-responsive">
            <AccountListWrapper
              accounts={orderdAccounts}
              setActiveAccountId={this.setActiveAccountId}
              activeAccountId={this.state.activeAccountId}
              i18nTranslate={(template, format) => i18n(template, format)}
              visSumForfall={visSumForfall}
              visTrukketKreditt={visTrukketKreditt}
              disableClickoutsideSmallScreens
            />
          </div>
          <NavList
            items={[
              <NavListLink
                title={{ text: 'Søk i transaksjoner' }}
                to={PATH.SEARCH}
                component={Link}
                key="transaksjonsøk"
              />,
            ]}
          />
        </BasePage>
      </AccountStyled>
    );
  }
}

Account.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  userSettings: PropTypes.shape({}).isRequired,
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const mapStateToProps = state => ({
  accounts: state.accounts,
  userSettings: state.user.settings,
  selectedAccounts: state.selectedAccounts,
});

export default connect(mapStateToProps, null)(Account);
