const Loans = [
  {
    id: 1,
    name: 'Boliglån',
    accountId: 10,
  },
  {
    id: 2,
    name: 'Billån',
    accountId: 11,
  },
  {
    id: 3,
    name: 'Flexilån',
    accountId: 12,
  },
];

export default Loans;
