/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MasonryClass from './masonryClass';
import MasonryPanel from './MasonryPanel';
import { config, getLayoutSize } from './masonryDefaultProps';

class Masonry extends Component {
  constructor(props) {
    super(props);
    this.layout = undefined;
  }
  componentDidMount() {
    this.myMasonry = new MasonryClass(
      this.masonryDOMElement,
      this.props.config,
      this.props.getLayoutSize,
    );
    this.myMasonry.layout();
    this.layout = _.debounce(() => this.myMasonry.layout(), 20);
    window.addEventListener('resize', this.layout);
  }
  componentDidUpdate() {
    this.layout();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.layout);
  }
  render() {
    return (
      <div
        className="masonry"
        style={{
          display: 'flex',
          flexFlow: 'column wrap',
          maxWidth: '100%',
        }}
        ref={(el) => {
          this.masonryDOMElement = el;
        }}
      >
        {this.props.children &&
          this.props.children.map((child, i) => (
            <MasonryPanel key={`masonry-panel-${i}`}>{child}</MasonryPanel>
          ))}
      </div>
    );
  }
}

Masonry.defaultProps = {
  children: null,
  config,
  getLayoutSize,
};

Masonry.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({}),
  getLayoutSize: PropTypes.func,
};

export default Masonry;
