/* eslint-disable no-alert */
import React from 'react';
import Button from 'digitalbank-buttons';
import { Lock } from 'digitalbank-icons';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';

const BankIDWithoutKodebrikkeArticle = () => (
  <article className="p-bankid-order">
    <section style={{ marginBottom: '40px' }}>
      <h3>
        For å bestille BankID må du være innlogget med kodebrikke fra
        Sparebanken Vest
      </h3>
      <p>
        Når du bestiller BankID må vi vite at du tidligere har identifisert deg
        med pass i en av våre filialer. Derfor må du være innlogget med{' '}
        <i>Alternativ innlogging</i> og kodebrikke fra Sparebanken Vest for å
        kunne bestille.
      </p>
      <Button
        variant="edit"
        size="medium"
        onClick={() => window.alert('demo')}
        leftIcon={<Lock />}
      >
        Logg inn på nytt
      </Button>
    </section>
    <section style={{ marginBottom: '40px' }}>
      <h4>Har du ikke kodebrikke fra Sparebanken Vest?</h4>
      <p>
        Hvis du ikke har kodebrikke som er utstedt av oss må du identifisere deg
        med pass i en av våre filialer for å bestille.
      </p>
    </section>
    <section style={{ marginBottom: '40px' }}>
      <h4>Mistet kodebrikken?</h4>
      <p>
        <Link to={PATHS.BANKID_NEW_KODEBRIKKE_ORDER}>
          Bestill ny kodebrikke
        </Link>
      </p>
    </section>
  </article>
);

BankIDWithoutKodebrikkeArticle.propTypes = {};

export default BankIDWithoutKodebrikkeArticle;
