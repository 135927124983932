/* eslint-disable max-len */

const template = {
  PAYMENT_GLOBAL_DONE: {
    'nb-NO': 'Betalingen ble gjennomført',
    'nn-NO': 'Betalingen ble gjennomført',
    'en-GB': 'Payment is completed',
  },
  PAYMENT_GLOBAL_RECEIPT_TO: {
    'nb-NO': 'vil bli betalt til',
    'nn-NO': 'vil bli betalt til',
    'en-GB': 'is paid to',
  },
  PAYMENT_GLOBAL_DONE_ALT: {
    'nb-NO': 'Betalingen ble lagt til forfall',
    'nn-NO': 'Betalingen ble lagt til forfall',
    'en-GB': 'Payment is scheduled',
  },
  PAYMENT_GLOBAL_HEADING: {
    'nb-NO': 'Betale utland',
    'nn-NO': 'Betale utland',
    'en-GB': 'Global payment',
  },
  PAYENT_GLOBAL_LEGEND_RECIPIENT_BANK: {
    'nb-NO': 'Mottakers bank',
    'nn-NO': 'Mottakers bank',
    'en-GB': 'Recipient bank',
  },
  PAYMENT_GLOBAL_LEGEND_RECIPIENT: {
    'nb-NO': 'Mottaker',
    'nn-NO': 'Mottaker',
    'en-GB': 'Recipient',
  },
  PAYMENT_GLOBAL_LABEL_AMOUNT: {
    'nb-NO': 'Beløp',
    'nn-NO': 'Beløp',
    'en-GB': 'Amount',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_NAME: {
    'nb-NO': 'Mottakers navn',
    'nn-NO': 'Mottakers navn',
    'en-GB': 'Recipient name',
  },
  PAYMENT_GLOBAL_LEGEND_INFO: {
    'nb-NO': 'Betalingsinformasjon',
    'nn-NO': 'Betalingsinformasjon',
    'en-GB': 'Payment info',
  },
  PAYMENT_GLOBAL_LABEL_BANK_CODE: {
    'nb-NO': 'Bankkode',
    'nn-NO': 'Bankkode',
    'en-GB': 'Bank code',
  },
  PAYMENT_GLOBAL_LABEL_BANK_NAME: {
    'nb-NO': 'Mottakerbankens navn',
    'nn-NO': 'Mottakarbankens navn',
    'en-GB': 'Bank name',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_BANK_ADDRESS: {
    'nb-NO': 'Bankens adresse',
    'nn-NO': 'Bankens adresse',
    'en-GB': 'Bank address',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_BANK_ADDRESS_LINE_2: {
    'nb-NO': 'Adresselinje 2',
    'nn-NO': 'Adresselinje 2',
    'en-GB': 'Address line 2',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_BANK_ADDRESS_LINE_3: {
    'nb-NO': 'Adresselinje 3',
    'nn-NO': 'Adresselinje 3',
    'en-GB': 'Address line 3',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_ADDRESS: {
    'nb-NO': 'Mottaker adresse',
    'nn-NO': 'Mottakar adresse',
    'en-GB': 'Recipient address',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_ADDRESS_LINE_2: {
    'nb-NO': 'Adresselinje 2',
    'nn-NO': 'Adresselinje 2',
    'en-GB': 'Address line 2',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_ADDRESS_LINE_3: {
    'nb-NO': 'Adresselinje 3',
    'nn-NO': 'Adresselinje 3',
    'en-GB': 'Address line 3',
  },
  PAYMENT_GLOBAL_LABEL_RECIPIENT_COUNTRY: {
    'nb-NO': 'Mottakers land',
    'nn-NO': 'Mottakars land',
    'en-GB': 'Recipient country',
  },
  PAYMENT_GLOBAL_LABEL_DUE_DATE: {
    'nb-NO': 'Dato',
    'nn-NO': 'Dato',
    'en-GB': 'Due date',
  },
  PAYMENT_GLOBAL_ESTIMATED_AMOUNT: {
    'nb-NO': 'Beregnet beløp',
    'nn-NO': 'Beregnet beløp',
    'en-GB': 'Estimated amount',
  },
  PAYMENT_GLOBAL_ESTIMATED_AMOUNT_TO: {
    'nb-NO': 'Beregnet til',
    'nn-NO': 'Beregnet til',
    'en-GB': 'Estimated to',
  },
  PAYMENT_GLOBAL_LABEL_MESSAGE: {
    'nb-NO': 'Melding til mottaker',
    'nn-NO': 'Melding til mottakar',
    'en-GB': 'Message to recipient',
  },
  PAYMENT_GLOBAL_LABEL_EXPRESS_PAYMENT: {
    'nb-NO': 'Hastebetaling',
    'nn-NO': 'Hastebetaling',
    'en-GB': 'Urgent payment',
  },
  PAYMENT_GLOBAL_LABEL_EXPENSES_ARE_PAYED_BY: {
    'nb-NO': 'Omkostninger',
    'nn-NO': 'Omkostningar',
    'en-GB': 'Charges',
  },
  PAYMENT_GLOBAL_LABEL_CHARGES_SHARED: {
    'nb-NO': 'Omkostningene deles (anbefalt)',
    'nn-NO': 'Omkostningene deles (anbefalt)',
    'en-GB': 'Shared between sender and recipient (recommended)',
  },
  PAYMENT_GLOBAL_LABEL_CHARGES_PAYED_BY_SENDER: {
    'nb-NO': 'Betalt av sender',
    'nn-NO': 'Betalt av sendar',
    'en-GB': 'Paid by sender',
  },
  PAYMENT_GLOBAL_LABEL_CHARGES_PAYED_BY_RECIPIENT: {
    'nb-NO': 'Betalt av mottaker',
    'nn-NO': 'Betalt av mottakar',
    'en-GB': 'Paid by recipient',
  },
  PAYMENT_GLOBAL_LEGEND_STATUATORY_REPORTING: {
    'nb-NO': 'Myndighetsrapportering',
    'nn-NO': 'Rapportering til valutaregisteret',
    'en-GB': 'Statuatory reporting',
  },
  PAYMENT_GLOBAL_LABEL_PAYMENT_PURPOSE: {
    'nb-NO': 'Beløpet gjelder',
    'nn-NO': 'Beløpet gjeld',
    'en-GB': 'Payment purpose',
  },
  PAYMENT_GLOBAL_LABEL_PAYMENT_TYPE: {
    'nb-NO': 'Type betaling',
    'nn-NO': 'Type betaling',
    'en-GB': 'Payment type',
  },
  PAYMENT_GLOBAL_INPUT_PAYMENT_TYPE_DEFAULT: {
    'nb-NO': 'Velg',
    'nn-NO': 'Velg',
    'en-GB': 'Select',
  },
  PAYMENT_GLOBAL_LABEL_SEND_EMAIL_CONFIRMATION_TO_RECEIVER: {
    'nb-NO': 'Send bekreftelse til mottaker',
    'nn-NO': 'Send stadfestning til mottakar',
    'en-GB': 'Send confirmation to recipient',
  },
  PAYMENT_GLOBAL_LABEL_RECEIVER_EMAIL: {
    'nb-NO': 'E-post eller Faks',
    'nn-NO': 'E-post eller Faks',
    'en-GB': 'Email or fax',
  },
  PAYMENT_GLOBAL_LABEL_AGREED_RATE_WITH_BANK: {
    'nb-NO': 'Avtalt kurs',
    'nn-NO': 'Avtalt kurs',
    'en-GB': 'Agreed exchange rate',
  },
  PAYMENT_GLOBAL_LABEL_CONTACT_PERSON: {
    'nb-NO': 'Kontaktperson',
    'nn-NO': 'Kontaktperson',
    'en-GB': 'Contact person',
  },
  PAYMENT_GLOBAL_LABEL_TO_ACCOUNT_IBAN_OR_ACCOUNT_NUMBER: {
    'nb-NO': 'IBAN eller kontonummer',
    'nn-NO': 'IBAN eller kontonummer',
    'en-GB': 'IBAN or account number',
  },
  PAYMENT_GLOBAL_LABEL_TO_ACCOUNT_IBAN: {
    'nb-NO': 'IBAN',
    'nn-NO': 'IBAN',
    'en-GB': 'IBAN',
  },
  PAYMENT_GLOBAL_LABEL_TO_ACCOUNT_ACCOUNT_NUMBER: {
    'nb-NO': 'Kontonummer',
    'nn-NO': 'Kontonummer',
    'en-GB': 'Account number',
  },
  PAYMENT_GLOBAL_ERROR_BANK_NAME_NOT_FOUND: {
    'nb-NO': 'Vi finner ikke bank. Vennligst sjekk IBAN eller kontonummer',
    'nn-NO': 'Vi finner ikke bank. Vennligst sjekk IBAN eller kontonummer',
    'en-GB':
      'We where not able to find the bank. Please check the IBAN or account number',
  },
  PAYMENT_GLOBAL_ERROR_KRONER_IS_EMPTY: {
    'nb-NO': 'Mangler beløp',
    'nn-NO': 'Mangler beløp',
    'en-GB': 'Amount is missing',
  },
  PAYMENT_GLOBAL_ERROR_RECIPIENT_BANK_IS_REQUIRED: {
    'nb-NO': 'Mottakerbankens land er påkrevd',
    'nn-NO': 'Mottakerbankens land er påkrevd',
    'en-GB': 'Recipient bank country is required',
  },
  PAYMENT_GLOBAL_ERROR_RECIPIENT_NAME_IS_REQUIRED: {
    'nb-NO': 'Mottaker navn er påkrevd',
    'nn-NO': 'Mottaker navn er påkrevd',
    'en-GB': 'Recipient name is required',
  },
  PAYMENT_GLOBAL_ERROR_RECIPIENT_ADDRESS_IS_REQUIRED: {
    'nb-NO': 'Mottaker adresse er påkrevd',
    'nn-NO': 'Mottaker adresse er påkrevd',
    'en-GB': 'Recipient address is required',
  },
  PAYMENT_GLOBAL_ERROR_PAYMENT_PURPOSE_IS_REQUIRED: {
    'nb-NO': 'Du er pålagt å dokumentere hva beløpet gjelder.',
    'nn-NO': 'Du er pålagt å dokumentere hva beløpet gjelder.',
    'en-GB': 'You need to document the purpose of this payment',
  },
  PAYMENT_GLOBAL_ERROR_PAYMENT_TYPE_IS_REQUIRED: {
    'nb-NO': 'Du er pålagt å dokumentere betalingsart',
    'nn-NO': 'Du er pålagt å dokumentere betalingsart',
    'en-GB': 'You need to document the payment type',
  },
  PAYMENT_GLOBAL_ERROR_FRA_KONTO_IS_MISSING: {
    'nb-NO': 'Kontonummer mangler',
    'nn-NO': 'Kontonummer mangler',
    'en-GB': 'From account is missing',
  },
  PAYMENT_GLOBAL_ERROR_NOT_ENOUGH_MONEY: {
    'nb-NO': 'Ikke nok penger',
    'nn-NO': 'Ikke nok penger',
    'en-GB': 'Not enough money',
  },
  PAYMENT_GLOBAL_LABEL_CODE_FROM_DEVICE: {
    'nb-NO': 'Kode fra kodebrikke',
    'nn-NO': 'Kode fra kodebrikke',
    'en-GB': 'Code from you device',
  },
  PAYMENT_GLOBAL_LABEL_DATE: {
    'nb-NO': 'Forfall',
    'nn-NO': 'Forfall',
    'en-GB': 'Date',
  },
  PAYMENT_GLOBAL_FINISH_TRANSACTION: {
    'nb-NO': 'Utfør betaling',
    'nn-NO': 'Utfør betaling',
    'en-GB': 'Finish transaction',
  },
  INPUT_HELP_TEXT_TO_ACCOUNT_IBAN_OR_ACCOUNT: {
    'nb-NO':
      '<p>Skal du betale til et land i EU/EØS er IBAN obligatorisk. Til resten av verden brukes kontonummer og BIC eller bankkode.</p> <p>Mottakere du har betalt til vil lagre seg i mottakerregisteret, og enkelt hentes frem ved klikk på ikonet ved neste betaling.</p>',
    'nn-NO':
      '<p>Skal du betale til et land i EU/EØS er IBAN obligatorisk. Til resten av verden brukes kontonummer og BIC eller bankkode.</p> <p>Mottakere du har betalt til vil lagre seg i mottakerregisteret, og enkelt hentes frem ved klikk på ikonet ved neste betaling.</p>',
    'en-GB':
      '<p>When you are transferring money to an EU / EEA country, IBAN (International Bank Account Number) is mandatory. For the rest of the world, account numbers and BIC- or bank codes are used.</p><p>Previous recipients will be stored in the recipient register, and can easily be selected by clicking on the icon within the next payment.</p>',
  },
  INPUT_HELP_TEXT_TO_ACCOUNT_IBAN: {
    'nb-NO':
      '<p>IBAN (International Bank Account Number) er obligatorisk ved overførsel til land i EU/EØS. IBAN finner du som regel på faktura eller brevark fra betalingsmottaker. Vennligst kontakt mottaker vedrørende manglende IBAN. En bankkunde får IBAN oppgitt fra banken sin.</p>',
    'nn-NO':
      '<p>IBAN (International Bank Account Number) er obligatorisk ved overførsel til land i EU/EØS. IBAN finner du som regel på faktura eller brevark fra betalingsmottaker. Vennligst kontakt mottaker vedrørende manglende IBAN. En bankkunde får IBAN oppgitt fra banken sin.</p>',
    'en-GB':
      '<p>IBAN (International Bank Account Number) is mandatory when transferring money to EU/EEA countries. The IBAN number is usually found on the recipient’s invoice or stationary.</p><p>Please contact the recipient if you can not find an IBAN number. Any bank customers can get their IBAN number by contacting their bank.</p>',
  },
  INPUT_HELP_TEXT_TO_ACCOUNT_ACCOUNT: {
    'nb-NO':
      '<p>Oppgi kontonummer til mottaker. Kontonummer har ulik lengde i ulike land. Kontakt mottaker om kontonummer mangler.</p>',
    'nn-NO':
      '<p>Oppgi kontonummer til mottaker. Kontonummer har ulik lengde i ulike land. Kontakt mottaker om kontonummer mangler.</p>',
    'en-GB':
      '<p>Enter the account number of the recipient. Account number has different lengths in different countries. Contact the recipient if the account number is missing.</p>',
  },
  INPUT_HELP_TEXT_EXTRA_BANK_CODE: {
    'nb-NO':
      '<p>Bankkode benyttes til å identifisere mottakers bank i nasjonale systemer. Til USA må du registrere FW + Routing Number/ABA (Eksempel: FW999999999)</p>',
    'nn-NO':
      '<p>Bankkode benyttes til å identifisere mottakers bank i nasjonale systemer. Til USA må du registrere FW + Routing Number/ABA (Eksempel: FW999999999)</p>',
    'en-GB':
      "<p>The bank code consists of digits and is used to identify the beneficiar's bank in national clearing systems. The bank code is not to be filled in if an IBAN number is used. For payments to the USA, you must register the FW + Routing Number/ABA (e.g.: FW999999999).</p>",
  },
  INPUT_HELP_TEXT_EXTRA_BANK_CODE_SWIFT_BIC: {
    'nb-NO':
      '<p>BIC (Bank Identifier Code) benyttes til å identifisere banker i SWIFT-systemet (Society for World Wide Interbank Financial Telecommunication). BIC består av 8 eller 11 tegn og er obligatorisk ved betaling til en rekke land. </p>',
    'nn-NO':
      '<p>BIC (Bank Identifier Code) benyttes til å identifisere banker i SWIFT-systemet (Society for World Wide Interbank Financial Telecommunication). BIC består av 8 eller 11 tegn og er obligatorisk ved betaling til en rekke land. </p>',
    'en-GB':
      "<p>BIC (Bank Identifier Code) is used to identify banks in the SWIFT system (Society for Worldwide Interbank Financial Telecommunication). BIC consists of eight or eleven characters and is mandatory for payments to many countries and always when an IBAN number is used. Only the SWIFT/BIC of the beneficiary's bank is to be stated.</p>",
  },
  INPUT_HELP_TEXT_PAYMENT_CURRENCY: {
    'nb-NO':
      '<p>Kun tillatte betalingsvalutaer til mottakerland vil være tilgjengelig. Vær oppmerksom på at valutakursen som benyttes ved beregning kun er veiledende.</p>',
    'nn-NO':
      '<p>Kun tillatte betalingsvalutaer til mottakerland vil være tilgjengelig. Vær oppmerksom på at valutakursen som benyttes ved beregning kun er veiledende.</p>',
    'en-GB':
      '<p>Only allowed payment currencies to the recipient country will be available. Please note that the exchange rate used for calculation is for guidance only.</p>',
  },
  INPUT_HELP_TEXT_PAYMENT_PURPOSE: {
    'nb-NO':
      '<p>Rapportering kreves i alle utlandsbetalinger over 100 000 NOK. Gi en kort beskrivelse av hva beløpet gjelder.</p>',
    'nn-NO':
      '<p>Rapportering kreves i alle utlandsbetalinger over 100 000 NOK. Gi en kort beskrivelse av hva beløpet gjelder.</p>',
    'en-GB':
      '<p>Reporting is required in all international payments over NOK 100,000. Give a brief description of what the amount applies.</p>',
  },
  INPUT_HELP_TEXT_PAYMENT_TYPE: {
    'nb-NO':
      '<p>Valgene i listen er iht. myndighetskrav, og sendes til Toll- og Avgifts Direktoratet.</p> <p>Sender du underhold/støtte til personer i utlandet, benytter du «Arv, gave med mer».</p>',
    'nn-NO':
      '<p>Valgene i listen er iht. myndighetskrav, og sendes til Toll- og Avgifts Direktoratet.</p> <p>Sender du underhold/støtte til personer i utlandet, benytter du «Arv, gave med mer».</p>',
    'en-GB':
      '<p>The choices in the list are in accordance with authority requirements, and sent to the Customs and Excise Directorate.</p> <p>If you send support / support to people abroad, you use "Heritage, gift and more".</p>',
  },
  INPUT_HELP_TEXT_RECEIVER_EMAIL: {
    'nb-NO':
      '<p>Ved å registrere e-postadressen / faksnummeret her, vil Sparebanken Vest sende en bekreftelse til mottakeren, deg selv eller andre.</p>',
    'nn-NO':
      '<p>Ved å registrere e-postadressen / faksnummeret her, vil Sparebanken Vest sende en bekreftelse til mottakeren, deg selv eller andre.</p>',
    'en-GB':
      '<p>By submitting your email address / fax number here, Sparebanken Vest will send a confirmation in English to the recipient, yourself or others.</p>',
  },
  INPUT_HELP_TEXT_RATE_WITH_BANK: {
    'nb-NO':
      '<p>Skal du overføre større beløp til utlandet, kan du avtale kurs på forhånd med din rådgiver. Dette vil gi deg forutsigbarhet på kostnad/transaksjon.</p>',
    'nn-NO':
      '<p>Skal du overføre større beløp til utlandet, kan du avtale kurs på forhånd med din rådgiver. Dette vil gi deg forutsigbarhet på kostnad/transaksjon.</p>',
    'en-GB':
      '<p>If you are going to transfer larger sums to a foreign country, you have the opportunity to set the exchange rate in advance with your bank advisor. This will give you predictability on cost / transaction.</p>',
  },
  INPUT_HELP_TEXT_EXPENSES_ARE_PAID_BY: {
    'nb-NO':
      '<p>Ved utlandsbetalinger er det normalt kostnader både i avsenderbank og mottakerbank.</p><p>Hvis det ikke er presisert i avtalen med betalingsmottaker, anbefaler vi at du velger alternativet «Omkostningene deles». Sparebanken Vest overstyrer i noen tilfeller disse instruksjonene pga myndighetsregulering og ellers i tilfeller når det er til fordel for transaksjonen.</p>',
    'nn-NO':
      '<p>Ved utlandsbetalinger er det normalt kostnader både i avsenderbank og mottakerbank.</p><p>Hvis det ikke er presisert i avtalen med betalingsmottaker, anbefaler vi at du velger alternativet «Omkostningene deles». Sparebanken Vest overstyrer i noen tilfeller disse instruksjonene pga myndighetsregulering og ellers i tilfeller når det er til fordel for transaksjonen.</p>',
    'en-GB':
      '<p>When performing foreign payments, there are costs associated. This applies to both sender- and receiver bank.</p><p>If it is not specified otherwise in the agreement with recipient,  we recommend that you select the "Sharing costs" option. In some cases, Sparebanken Vest override these instructions due to governmental regulation and in cases where it is beneficial to the transaction.</p>',
  },
  INPUT_HELP_HASTEBETALING: {
    'nb-NO':
      '<p>Hastebetaling:</p><p>0-2 bankdager (varierer per valutasort)</p><p>Normalbetaling: </p><p>1-5 bankdager til EØS-land,</p><p>2-8 bankdager til resten av verden. </p><p>For enkelte asiatiiske, afrikanske og sør-amerikanske land kan overføringstiden være lenger - maks 15 bankdager.</p><p>Mottakers konto krediteres normalt innen 1 bankdag etter at mottakerbanken har fått beløpet.</p>',
    'nn-NO':
      '<p>Hastebetaling:</p><p>0-2 bankdager (varierer per valutasort)</p><p>Normalbetaling: </p><p>1-5 bankdager til EØS-land,</p><p>2-8 bankdager til resten av verden. </p><p>For enkelte asiatiiske, afrikanske og sør-amerikanske land kan overføringstiden være lenger - maks 15 bankdager.</p><p>Mottakers konto krediteres normalt innen 1 bankdag etter at mottakerbanken har fått beløpet.</p>',
    'en-GB':
      '<p>Express payment:</p><p>0-2 banking days (varies per currency)</p><p>Normal Payment: </p><p>1-5 banking days to the EEA country,</p><p>2-8 banking days to the rest of the world. </p><p>For some Asian, African and South American countries, the transfer period may be longer - max 15 banking days.</p><p>Recipient account is normally credited within 1 banking day after the recipient bank has received the amount.</p>',
  },
  EXPRESS_PAYMENT_COSTS: {
    'nb-NO': 'Tjenesten koster',
    'nn-NO': 'Tjenesten koster',
    'en-GB': 'Service price',
  },
  RECEIVER_EMAIL_INFO: {
    'nb-NO':
      '<p><b>Bekreftelse</b></p><p>Banken sender bekreftelse til mottaker for deg. Kostnad kr 100,-</p>',
    'nn-NO':
      '<p><b>Bekreftelse</b></p><p>Banken sender bekreftelse til mottaker for deg. Kostnad kr 100,-</p>',
    'en-GB':
      '<p><b>Confirmation</b></p><p>We send a confirmation email to the recipient. Cost 100 NOK</p>',
  },
  EXPRESS_PAYMENT: {
    'nb-NO': 'Hastebetaling',
    'nn-NO': 'Hastebetaling',
    'en-GB': 'Express payment',
  },
  PAYMENT_GLOBAL_PUBLISHED_CONTENT_ADVICE: {
    'nb-NO':
      '<h2>Tips</h2><p>For store beløp kan det være lurt å avtale kurs på forhånd med din rådgiver. Da har du full forutsigbarhet på kostnadene.</p><p>Har du avtalt kurs på forhånd, må du merke betalingen med dette under avanserte innstillinger på neste side.</p>',
    'nn-NO':
      '<h2>Tips</h2><p>For store beløp kan det være lurt å avtale kurs på forhånd med din rådgiver. Da har du full forutsigbarhet på kostnadene.</p><p>Har du avtalt kurs på forhånd, må du merke betalingen med dette under avanserte innstillinger på neste side.</p>',
    'en-GB':
      '<h2>Tips</h2><p>For large amounts, you may want to arrange courses in advance with your adviser. Then you have full predictability on the costs.</p><p>If you have agreed on a course in advance, please note the payment with this under advanced settings on the next page.</p>',
  },
  PAYMENT_GLOBAL_PUBLISHED_CONTENT_FALSE_EMAIL: {
    'nb-NO':
      '<h2>Har du mottatt falsk e-post?</h2><p>Når du mottar betalingsinformasjon på e-post anbefaler vi deg å dobbeltsjekke pr. telefon at opplysningene stemmer.</p><p>Vil du vite mer om hvordan du unngår å bli svindlet? <span className="link">Klikk her.</span></p>',
    'nn-NO':
      '<h2>Har du mottatt falsk e-post?</h2><p>Når du mottar betalingsinformasjon på e-post anbefaler vi deg å dobbeltsjekke pr. telefon at opplysningene stemmer.</p><p>Vil du vite mer om hvordan du unngår å bli svindlet? <span className="link">Klikk her.</span></p>',
    'en-GB':
      '<h2>Have you received fraudulent email?</h2><p>When you receive payment information via e-mail, we recommend you to double check that the information is correct pr. telephone.</p><p>Do you want to know more about how to avoid being victim of fraud? <span className="link">Click here</span> (opens in new window)</p>',
  },
};

export default template;
