import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <g transform="translate(.16 .254)">
      <path d="M4.00112 3.37741576L3.95463273 3.37741576 3.48728727 1.21773105C3.44129455.989467706 3.26721455.801370874 3.04170182.740141437L.815258182.0999264416C.476494545.00293901299.1224.20132239.02448.53636987-.0724509091.877295377.127345455 1.23340578.460669091 1.32941354L2.32312727 1.86333423 4.47242182 11.8628361C4.53522909 12.1577171 4.79634909 12.3658971 5.09406545 12.3658971L13.9479127 12.3913686C14.2970618 12.3913686 14.5838982 12.1067742 14.5838982 11.7560519 14.5838982 11.4053297 14.3020073 11.115837 13.9479127 11.115837L5.61185455 11.0903655 5.3448 9.85010203 14.52208 9.85010203C14.8148509 9.85010203 15.0522327 9.61204197 15.0522327 9.317161L15.9394473 4.6171894 4.00112 3.37741576zM6.34051782 13.3315099C5.42659782 13.3315099 4.68230691 14.0731208 4.68230691 14.9935217 4.68230691 15.9129429 5.42659782 16.6545539 6.34051782 16.6545539 7.25789964 16.6545539 8.00268509 15.9129429 8.00268509 14.9935217 8.00268509 14.0731208 7.25789964 13.3315099 6.34051782 13.3315099M12.5781207 13.3315099C11.6597498 13.3315099 10.9159535 14.0731208 10.9159535 14.9935217 10.9159535 15.9129429 11.6597498 16.6545539 12.5781207 16.6545539 13.4910516 16.6545539 14.2353425 15.9129429 14.2353425 14.9935217 14.2353425 14.0731208 13.4910516 13.3315099 12.5781207 13.3315099" />
    </g>
  </svg>
);
