import styled from 'styled-components';
import { breakpoints, layout, spacing, sizes } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-contact-info {
    &__heading,
    &__main,
    &__footer,
    &__aside {
      ${horizontalLayoutSpacing};
    }

    &__main {
      @media (min-width: ${breakpoints.tablet}) {
        padding-right: ${rem(layout.horizontalSpacing.tablet)};
        max-width: ${rem(sizes.formMaxWidthValue)};
      }
    }

    &__aside {
      @media (max-width: ${breakpoints.tablet}) {
        margin-top: ${rem(spacing.layoutSpacing.sm)};
      }
    }

    &__footer {
      margin-top: ${rem(spacing.layoutSpacing.sm)};

      &-content {
        @media (min-width: ${breakpoints.tablet}) {
          width: 70%;
          max-width: ${rem(sizes.formMaxWidthValue)};
        }
      }
    }

    &__edit-button {
      margin-top: ${rem(20)};
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__info-heading {
      margin-top: ${rem(10)};
    }

    &__alert {
      margin-bottom: 50px;

      @media (min-width: ${breakpoints.tablet}) {
        margin-top: 20px;
      }
    }

    &__age-alert {
      margin-bottom: ${rem(20)};
    }
  }
`;
