/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TransitionMotion, spring, presets } from 'react-motion';
import classNames from 'classnames';
import { Exchange, Clock, ChevronDown } from 'digitalbank-icons';
import { formatAccountNumber } from 'utils/format.util';
import RecipientShape from 'shapes/RecipientShape';
import _ from 'lodash';
import i18n from 'i18n';
import * as PATH from 'routes/paths';
import ActionList, { ActionLink, DeleteButton } from 'digitalbank-action-list';

const RecipientOption = ({
  recipient,
  displayActions,
  toggleActions,
  deleteRecipient,
  className,
  style,
}) => {
  const names = recipient.name.split(' ');
  const numberOfNames = names.length;
  const firstName =
    numberOfNames > 1 ? names.slice(0, numberOfNames - 1).join(' ') : names[0];
  const lastName = numberOfNames > 1 ? names[numberOfNames - 1] : '';

  const actions = [
    <ActionLink
      href={PATH.PAY}
      title="Betale"
      key="betale"
      RouterLink={Link}
      Icon={Exchange}
    />,
    <ActionLink
      href={PATH.SEARCH}
      title="Historikk"
      key="historikk"
      RouterLink={Link}
      Icon={Clock}
    />,
    <DeleteButton
      key="delete"
      value="Slett"
      i18nTranslate={(template, format) => i18n(template, format)}
      isDeletePending={false}
      onDeleteConfirmed={deleteRecipient}
      onDeleteErrorClose={() => console.log('onDeleteErrorClose click')}
      showDeleteError={false}
      deleteError="Error"
    />,
  ];
  return (
    <li
      className={classNames('recipient-dropdown__option ', className)}
      style={style}
    >
      <button
        type="button"
        className={classNames('recipient-dropdown__option-button', {
          'recipient-dropdown__option-button--active': displayActions,
        })}
        onClick={toggleActions}
      >
        <div className="recipient-dropdown__option-button-content">
          <div>
            <div className="recipient-dropdown__option-heading">
              <span className="recipient-dropdown__option-heading-first">
                {firstName}
              </span>
              <span className="recipient-dropdown__option-heading-last">{` ${lastName}`}</span>
            </div>
            <div className="recipient-dropdown__option-info">
              {formatAccountNumber(recipient.account)}
            </div>
          </div>
          <ChevronDown
            className={classNames('recipient-dropdown__chevron', {
              'recipient-dropdown__chevron--active': displayActions,
            })}
          />
        </div>
      </button>
      <div className="recipient-dropdown__collapse">
        <ActionList actions={actions} />
      </div>
    </li>
  );
};

RecipientOption.defaultProps = {
  displayActions: false,
  className: '',
};

RecipientOption.propTypes = {
  recipient: RecipientShape.isRequired,
  displayActions: PropTypes.bool,
  toggleActions: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
  style: PropTypes.shape({
    display: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
  }).isRequired,
  className: PropTypes.string,
};

const RecipientList = ({
  recipients,
  activeRecipientId,
  toggleRecipient,
  deleteRecipient,
  name,
}) => (
  <TransitionMotion
    willLeave={() => ({ height: spring(0) })}
    styles={recipients.map(recipient => ({
      key: `recipient-${name}-${recipient.id}`,
      style: {
        height:
          activeRecipientId === recipient.id
            ? spring(59 + 144, {
                ...presets.noWobble,
                precision: 0.5,
              })
            : spring(59, { ...presets.noWobble, precision: 0.5 }),
      },
      data: {
        recipient,
      },
    }))}
  >
    {interpolatedStyles => (
      <ul className="recipient-dropdown__category-list">
        {interpolatedStyles.map(config => (
          <RecipientOption
            key={config.key}
            style={{
              ...config.style,
              display: config.data.recipient.display ? 'block' : 'none',
            }}
            className={classNames({
              'recipient-dropdown__option--hidden': !config.data.recipient
                .display,
              'recipient-dropdown__option--visible':
                config.data.recipient.display,
            })}
            recipient={config.data.recipient}
            displayActions={activeRecipientId === config.data.recipient.id}
            toggleActions={() => toggleRecipient(config.data.recipient.id)}
            deleteRecipient={() => deleteRecipient(config.data.recipient.id)}
          />
        ))}
      </ul>
    )}
  </TransitionMotion>
);

RecipientList.propTypes = {
  recipients: PropTypes.arrayOf(RecipientShape).isRequired,
  activeRecipientId: PropTypes.number.isRequired,
  toggleRecipient: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const RecipientDropdown = ({
  categories,
  activeRecipientId,
  toggleRecipient,
  deleteRecipient,
}) => {
  const numberOfCategoriesWithRecipients = _.filter(categories, x =>
    x.recipients.some(y => y.display),
  ).length;
  return (
    <ul className="recipient-dropdown__categories">
      {categories.length > 0 &&
        categories.map(category => (
          <li
            key={`recipient-category-${category.name}`}
            className="recipient-dropdown__category"
          >
            {category.recipients.some(x => x.display) &&
              numberOfCategoriesWithRecipients > 1 && (
                <h3 className="recipient-dropdown__category-heading">
                  {category.name}
                </h3>
              )}
            <RecipientList
              name="category-recipients"
              recipients={category.recipients}
              activeRecipientId={activeRecipientId}
              toggleRecipient={toggleRecipient}
              deleteRecipient={deleteRecipient}
            />
          </li>
        ))}
    </ul>
  );
};

RecipientDropdown.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      recipients: PropTypes.arrayOf(RecipientShape).isRequired,
    }),
  ).isRequired,
  activeRecipientId: PropTypes.number.isRequired,
  toggleRecipient: PropTypes.func.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
};

export default RecipientDropdown;
