import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Footer = ({ kontaktOssUrl, logoUrl }) => (
  <footer className="footer">
    <div className="footer__container">
      <div>
        Sparebanken Vest 2018 - Trenger du hjelp?{' '}
        <Link to={kontaktOssUrl}>Kontakt oss</Link> 07 - 23 alle dager
      </div>
      <div className="footer__logo">
        <Link to={logoUrl}>
          <img
            src="https://static.spv.no/static/assets/images/1.0.0/header__logo.svg"
            alt="Sparebanken Vest"
          />
        </Link>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  kontaktOssUrl: PropTypes.string,
  logoUrl: PropTypes.string,
};

Footer.defaultProps = {
  kontaktOssUrl: 'https://www1.spv.no/Nettbank/Kontakt-oss',
  logoUrl: 'https://www3.spv.no/wps/myportal/3625/nettbank',
};

export default Footer;
