import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'digitalbank-buttons';
import { openEditModeOnContactInfo, toggleForeignerForm } from 'actions';
import i18n from 'i18n';
import ContactInfoFormContainer from './ContactInfoFormContainer';
import ContactInfoPreview from './ContactInfoPreview';
import ContactInfoPeriodicControlStyled from './ContactInfoPeriodicControlStyled';

const ContactInfoPeriodicControl = ({
  contactInfo,
  onOpenEditModeOnContactInfo,
  onToggleForeignerForm,
}) => (
  <ContactInfoPeriodicControlStyled>
    <div className="p-contact-info-periodic">
      <div className="p-contact-info-periodic__container">
        <h1 className="p-contact-info-periodic__heading p-contact-info__heading--always-visible">
          {i18n(t => t.KONTAKTINFORMASJON_HEADING)}?
        </h1>
        <div className="p-contact-info__content-periodic">
          <main role="main" className="p-contact-info-periodic__main">
            {!contactInfo.editMode && (
              <div>
                <ContactInfoPreview
                  openEditMode={onOpenEditModeOnContactInfo}
                />
                <Button
                  component={Link}
                  to="/"
                  variant="primary"
                  style={{ marginTop: '20px', width: '100%' }}
                >
                  Opplysningene er riktige
                </Button>
              </div>
            )}
            {contactInfo.editMode && (
              <ContactInfoFormContainer
                isForeigner={contactInfo.isForeigner}
                toggleForeignerForm={onToggleForeignerForm}
                action={
                  <Button
                    component={Link}
                    to="/"
                    variant="primary"
                    style={{ marginTop: '20px', width: '100%' }}
                  >
                    Lagre
                  </Button>
                }
              />
            )}
            <p className="p-contact-info-periodic__bottom-text">
              {`${i18n(t => t.KONTAKTINFORMASJON_BOTTOM_INFO)}
            ${i18n(t => t.KONTAKTINFORMASJON_INNSTILLINGER)} >
            ${i18n(t => t.KONTAKTINFORMASJON_KONTAKTINFORMASJON)}`}
            </p>
          </main>
        </div>
      </div>
    </div>
  </ContactInfoPeriodicControlStyled>
);

ContactInfoPeriodicControl.propTypes = {
  contactInfo: PropTypes.shape({}).isRequired,
  onToggleForeignerForm: PropTypes.func.isRequired,
  onOpenEditModeOnContactInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  contactInfo: state.contactInfo,
});

const mapDispatchToProps = dispatch => ({
  onToggleForeignerForm: bindActionCreators(toggleForeignerForm, dispatch),
  onOpenEditModeOnContactInfo: bindActionCreators(
    openEditModeOnContactInfo,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(
  ContactInfoPeriodicControl,
);
