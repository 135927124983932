// eslint-disable no-console

import { GlobalPaymentInfo } from 'data';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';

export const getCountry = countryCode =>
  GlobalPaymentInfo.Countries.find(c => c.countryCode === countryCode);

export const getBankBasedOnAccountNumber = account =>
  GlobalPaymentInfo.Banks.find(b => b.ibanExample === account);

export const getCountryDefaultCurrency = (countryCode) => {
  const country = getCountry(countryCode);
  // If country does not exist in our registry we
  // return EUR as default currency (demo purpose)

  if (!country) {
    // eslint-disable-next-line
    console.warn(
      `Cound not find country default currency with countryCode: ${countryCode}, we use EUR as a fallback`,
    );
    return 'EUR';
  }
  const defaultCurrency = country.currencies[0];
  return defaultCurrency !== undefined ? defaultCurrency : 'EUR';
};

export const getAvailableCurrenciesInCountry = (countryCode) => {
  if (!countryCode) return undefined;
  const country = getCountry(countryCode);
  // If country does not exist in our register we
  // return a default array of currencies (demo purpose)
  if (!country) return ['EUR', 'USD', 'NOK'];
  return country.currencies;
};

export const getIbanDisplayFilter = (countryCode) => {
  const mandatoryCountries = ['NO', 'SE', 'PL', 'DK', 'GB', 'DE', 'ES'];
  const notMandatoryCountries = ['JP', 'CA', 'AU', 'ZA', 'US'];
  if (mandatoryCountries.indexOf(countryCode) > -1) {
    return 'MANDATORY';
  }
  if (notMandatoryCountries.indexOf(countryCode) > -1) {
    return 'NOT_MANDATORY';
  }
  return 'OPTIONAL';
};

export const getNumber = (integers, decimals) => {
  const i = integers !== undefined ? integers : 0;
  const d = decimals !== undefined ? decimals : 0;
  return Number(i) + Number(d / 100);
};

// Every country except US
export const countryShouldDisplayBankIdentifierCodeField = countryCode =>
  ['US'].indexOf(countryCode) === -1;

// Canada, Australia & South Africa
export const countryShouldDisplayBankCodeField = countryCode =>
  ['CA', 'AU', 'ZA', 'US'].indexOf(countryCode) > -1;

// Japan
export const countryShouldDisplayBankAddressFields = countryCode =>
  ['JP'].indexOf(countryCode) > -1;

export const getAccount = (accountNumber, accounts = Accounts) =>
  accounts.find(x => x.accountNumber === accountNumber);

export const getAccountCurrency = (accountNumber, accounts = Accounts) =>
  getAccount(accountNumber, accounts).currency;

export const getCurrencyRate = (fromCurrency, toCurrency, fx, accounting) => {
  const value = fx.convert(1, { from: fromCurrency, to: toCurrency });
  return value > 0 ? accounting.format(value, 4, ' ', '.') : 0;
};

export const getToAccountLabel = (filter, i18n) => {
  if (filter === 'MANDATORY') {
    return i18n(t => t.PAYMENT_GLOBAL_LABEL_TO_ACCOUNT_IBAN);
  }
  if (filter === 'NOT_MANDATORY') {
    return i18n(t => t.PAYMENT_GLOBAL_LABEL_TO_ACCOUNT_ACCOUNT_NUMBER);
  }
  return i18n(t => t.PAYMENT_GLOBAL_LABEL_TO_ACCOUNT_IBAN_OR_ACCOUNT_NUMBER);
};

export const getToAccountHelpText = (filter, i18n) => {
  if (filter === 'MANDATORY') {
    return i18n(t => t.INPUT_HELP_TEXT_TO_ACCOUNT_IBAN);
  }
  if (filter === 'NOT_MANDATORY') {
    return i18n(t => t.INPUT_HELP_TEXT_TO_ACCOUNT_ACCOUNT);
  }
  return i18n(t => t.INPUT_HELP_TEXT_TO_ACCOUNT_IBAN_OR_ACCOUNT);
};

export const getExpensesArePaidByDisplayValue = (value, i18n) => {
  switch (value) {
    case 'shared':
      return i18n(t => t.PAYMENT_GLOBAL_LABEL_CHARGES_SHARED);
    case 'sender':
      return i18n(t => t.PAYMENT_GLOBAL_LABEL_CHARGES_PAYED_BY_SENDER);
    case 'receiver':
      return i18n(t => t.PAYMENT_GLOBAL_LABEL_CHARGES_PAYED_BY_RECEIVER);
    default:
      return i18n(t => t.PAYMENT_GLOBAL_LABEL_CHARGES_SHARED);
  }
};
