/* eslint-disable no-script-url */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { InputField } from 'digitalbank-form';
import { Button } from 'digitalbank-buttons';
import { config } from 'services/economy.service';
import { HiddenInput } from 'styled/Form';

const InputStyled = styled(HiddenInput)``;
const LabelStyled = styled.label`
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 0;
  padding: .5rem 1.25rem;
  font-size: 1rem;
  text-transform: none;
  cursor: pointer;
  background-color: #fff;
  color: #444;
  border: 1px solid #bbb;
  display: inline-block;
  width: 100%;

  &:hover {
    background: #e6e6e6;
  }
  &:focus {
    background: #d9d9d9;
  }
}
`;

const MerchantFormStyled = styled.form`
  margin-bottom: 20px;
`;

class MerchantForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        name: this.props.merchant.name,
      },
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  onFormSubmit(e) {
    e.preventDefault(); // Prevent default form submit
    // console.log('name', this.state.values.name);
    const url = `${config.apiEndpoints.logo}/id/${this.props.merchant.id}`;
    const formData = new FormData();
    formData.append('merchantName', this.state.values.name);
    return fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: this.state.values.name,
    }).then(() => {
      this.props.getMerchants();
    });
  }
  render() {
    const { upload, merchant } = this.props;
    const { id } = merchant;
    return (
      <MerchantFormStyled
        id={`merchant-form-${id}`}
        action="javascript:void(0);"
        onSubmit={this.onFormSubmit}
      >
        <LabelStyled htmlFor={`merchant-logo-${id}`}>Last opp logo</LabelStyled>
        <InputStyled
          type="file"
          id={`merchant-logo-${id}`}
          name="file"
          onChange={e => upload(e)}
          // eslint-disable-next-line
          onUpdate={() => console.log('update')}
        />
        <div style={{ marginBottom: '20px' }}>
          <InputField
            id={`merchant-name-${id}`}
            label="Navn"
            meta={{}}
            input={{
              value: this.state.values.name,
              onChange: e =>
                this.setState({
                  values: {
                    name: e.target.value,
                  },
                }),
            }}
          />
        </div>
        <Button variant="primary" type="submit">
          Lagre
        </Button>
      </MerchantFormStyled>
    );
  }
}

MerchantForm.propTypes = {
  merchant: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  upload: PropTypes.func.isRequired,
  getMerchants: PropTypes.func.isRequired,
};

export default MerchantForm;
