import {
  TOGGLE_EDIT_MODE_ON_REGIONAL_BLOCKING,
  CLOSE_EDIT_MODE_ON_REGIONAL_BLOCKING,
  OPEN_EDIT_MODE_ON_REGIONAL_BLOCKING,
  TOGGLE_REGIONAL_BLOCKING_HELP,
  OPEN_REGIONAL_BLOCKING_LOCATION,
  CLOSE_REGIONAL_BLOCKING_LOCATION,
  TOGGLE_REGIONAL_BLOCKING_LOCATION,
  TOGGLE_LOCATION_INFO,
} from './actionTypes';

export const toggleLocationInfo = index => ({
  type: TOGGLE_LOCATION_INFO,
  index,
});

export const toggleEditModeOnRegionalBlocking = () => ({
  type: TOGGLE_EDIT_MODE_ON_REGIONAL_BLOCKING,
});

export const closeEditModeOnRegionalBlocking = () => ({
  type: CLOSE_EDIT_MODE_ON_REGIONAL_BLOCKING,
});

export const openEditModeOnRegionalBlocking = () => ({
  type: OPEN_EDIT_MODE_ON_REGIONAL_BLOCKING,
});

export const toggleRegionalBlockingHelp = () => ({
  type: TOGGLE_REGIONAL_BLOCKING_HELP,
});

export const openRegionalBlockingLocation = id => ({
  type: OPEN_REGIONAL_BLOCKING_LOCATION,
  id,
});

export const closeRegionalBlockingLocation = id => ({
  type: CLOSE_REGIONAL_BLOCKING_LOCATION,
  id,
});

export const toggleRegionalBlockingLocation = id => ({
  type: TOGGLE_REGIONAL_BLOCKING_LOCATION,
  id,
});
