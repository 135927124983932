import React, { Component } from 'react';
import * as firebase from 'firebase';
import Alert from 'digitalbank-alert';
import historyShape from 'shapes/history.shape';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BasePage } from 'digitalbank-layout';
import { bindActionCreators } from 'redux';
import i18n from 'i18n';
import { initUser } from 'actions/authActions';
import { DefaultLayoutContainer, BasePageStyled } from 'styled';

class Login extends Component {
  constructor(props) {
    super(props);
    this.email = 'halseth.torleif@gmail.com';
    this.state = {
      error: '',
    };
    this.signIn = this.signIn.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  signIn() {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.email, `passord${this.pwInput.value}`)
      .then((user) => {
        // Initialize user
        this.props.initializeUser({
          name: 'Ola',
          displayName: user.displayName || 'Ola Nordmann',
          email: user.email,
        });
        const { location } = this.props;
        const fromPath =
          location && location.state && location.state.from
            ? location.state.from
            : '/';
        this.props.history.replace(fromPath);
      })
      .catch((error) => {
        // Handle Errors here.
        if (error.code === 'auth/wrong-password') {
          this.setState({ error: 'Feil passord' });
        } else {
          this.setState({ error: error.message });
        }
      });
  }
  handleSubmit(e) {
    e.preventDefault();
    this.signIn();
  }
  render() {
    return (
      <DefaultLayoutContainer>
        <BasePageStyled>
          <BasePage heading={i18n(t => t.ROUTE_TITLE_LOGIN)}>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  pattern="[0-9]*"
                  placeholder="PIN-kode"
                  className="form-control form-control--secure"
                  ref={(input) => {
                    this.pwInput = input;
                  }}
                  name="password"
                  autoComplete="off"
                />
              </div>
              {this.state.error && (
                <Alert type="warning" small>
                  <p>{this.state.error}</p>
                </Alert>
              )}
              <button
                className="button button--primary"
                onClick={this.handleSubmit}
                style={{ marginTop: '20px' }}
                type="submit"
              >
                Logg Inn
              </button>
            </form>
          </BasePage>
        </BasePageStyled>
      </DefaultLayoutContainer>
    );
  }
}

Login.propTypes = {
  history: historyShape.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.string,
    }),
  }).isRequired,
  initializeUser: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  initializeUser: bindActionCreators(initUser, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(Login);
