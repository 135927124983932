import React from 'react';
import PropTypes from 'prop-types';
import { Times } from 'digitalbank-icons';

const TagButton = ({ value, disabled, ...other }) => (
  <button
    className="search-filter__tag-btn"
    type="button"
    disabled={disabled}
    {...other}
  >
    <div className="search-filter__tag-btn-content">
      {value}
      {!disabled && (
        <Times className="search-filter__tag-btn-icon search-filter__tag-btn-icon--times" />
      )}
    </div>
  </button>
);

TagButton.defaultProps = {
  disabled: false,
};

TagButton.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default TagButton;
