import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { BasePage } from 'digitalbank-layout';
import { BasePageStyled } from 'styled';
import { Switch } from 'digitalbank-form';
import {
  toggleBalanceCarousel,
  toggleEfakturaOneClickFromHomepage,
  toggleActionsFromHomepage,
  toggleOldNavListFromHomepage,
  toggleEfakturaBannerFromHomepage,
  toggleBadgeInTabbar,
} from 'actions/userSettings.actions';
import i18n from 'i18n';

const SwitchContainer = ({ children }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '10px',
    }}
  >
    {children}
  </div>
);

SwitchContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const sectionStyle = {
  margin: '0 0 40px',
};

const TestPage = ({
  onToggleBalanceCarousel,
  onToggleEfakturaOneClickFromHomepage,
  onToggleActionsFromHomepage,
  onToggleOldNavListFromHomepage,
  onToggleEfakturaBannerFromHomepage,
  onToggleBadgeInTabbar,
  user,
}) => (
  <BasePageStyled>
    <BasePage heading="Demo">
      <section style={sectionStyle}>
        <h2>eFaktura fra alle</h2>
        <NavList
          items={[
            <NavListLink
              component={Link}
              to={PATHS.EFAKTURA_AND_AVTALEGIRO}
              title={{ text: 'AvtaleGiro og eFaktura' }}
              key="avtalegiro-og-efaktura"
            />,
            <NavListLink
              component={Link}
              to={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_INFO}
              title={{ text: 'Oppsett info' }}
              key="avtalegiro-og-efaktura-oppsett-info"
            />,
            <NavListLink
              component={Link}
              to={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_SETUP}
              title={{ text: 'Oppsett innstillinger' }}
              key="avtalegiro-og-efaktura-oppsett-innstillinger"
            />,
            <NavListLink
              component={Link}
              to={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_RECEIPT}
              title={{ text: 'Oppsett kvittering' }}
              key="avtalegiro-og-efaktura-kvittering"
            />,
            // <NavListLink
            //   component={Link}
            //   to={PATHS.EFAKTURA_FRA_ALLE_INFO}
            //   title={{ text: 'Info' }}
            //   key="avtalegiro-og-efaktura-info"
            // />,
            <NavListLink
              component={Link}
              to={PATHS.EFAKTURA_FRA_ALLE_SETTINGS}
              title={{ text: 'Innstillinger' }}
              key="avtalegiro-og-efaktura-innstillinger"
            />,
          ]}
        />
      </section>
      <section style={sectionStyle}>
        <h2>BankID</h2>
        <NavList
          items={[
            <NavListLink
              component={Link}
              to={PATHS.BANKID}
              title={{ text: 'BankID' }}
              key="bankid"
            />,
            <NavListLink
              component={Link}
              to={PATHS.BANKID_MOBILE}
              title={{ text: 'BankID mobil' }}
              key="bankid-mobil"
            />,
            <NavListLink
              component={Link}
              to={PATHS.BANKID_ORDER}
              title={{ text: 'Bestill BankID (innlogget med kodebrikke)' }}
              key="bankid-bestilling-med-kodebrikke"
            />,
            <NavListLink
              component={Link}
              to={`${PATHS.BANKID_ORDER}/uten-kodebrikke`}
              title={{
                text: 'Bestill BankID (ikke innlogget med kodebrikke)',
              }}
              key="bankid-uten-kodebrikke"
            />,
            <NavListLink
              component={Link}
              to={`${PATHS.BANKID_ORDER}/uten-kodebrikke/new`}
              title={{
                text:
                  'Bestill BankID (fremtidig: ikke innlogget med kodebrikke som krever reauth under bestilling)',
              }}
              key="bankid-uten-kodebrikke-new"
            />,
            <NavListLink
              component={Link}
              to={PATHS.BANKID_MOBILE_ORDER}
              title={{
                text: 'Bestill BankID på mobil (innlogget med kodebrikke)',
              }}
              key="bankid-mobile-bestilling-med-kodebrikke"
            />,
            <NavListLink
              component={Link}
              to={`${PATHS.BANKID_MOBILE_ORDER}/uten-kodebrikke`}
              title={{
                text:
                  'Bestill BankID på mobil (fremtidig: ikke innlogget med kodebrikke som krever reauth under bestilling)',
              }}
              key="bankid-mobil-uten-kodebrikke"
            />,
            <NavListLink
              component={Link}
              to={PATHS.BANKID_NEW_KODEBRIKKE_ORDER}
              title={{ text: 'Bestill kodebrikke' }}
              key="bankid-kodebrikke"
            />,
          ]}
        />
      </section>
      <section style={sectionStyle}>
        <h2>Regionsperre</h2>
        <NavList
          items={[
            <NavListLink
              component={Link}
              to={PATHS.REGIONAL_BLOCKING}
              title={{ text: 'Regionsperre' }}
              key="regionsperre"
            />,
            <NavListLink
              component={Link}
              to={PATHS.PERIODIC_CONTROL_REGIONAL_BLOCKING_ISOLATED}
              title={{ text: 'Regionsperre - Periodisk kontroll' }}
              key="regionsperre-periodisk"
            />,
          ]}
        />
      </section>
      <section style={sectionStyle}>
        <h2>Kontaktopplysninger</h2>
        <NavList
          items={[
            <NavListLink
              component={Link}
              to={PATHS.CONTACT_INFO}
              title={{ text: 'Kontaktopplysninger' }}
              key="kontaktopplysninger"
            />,
            <NavListLink
              component={Link}
              to={PATHS.CONTACT_INFO_PERIODIC_CONTROL}
              title={{ text: 'Kontaktopplysninger - Periodisk kontroll' }}
              key="kontaktopplysninger-periodisk"
            />,
          ]}
        />
      </section>
      <section style={sectionStyle}>
        <h2>Samtykke</h2>
        <NavList
          items={[
            <NavListLink
              component={Link}
              to={PATHS.DATA_CONSENT}
              title={{ text: 'Samtykke' }}
              key="samtykke"
            />,
            <NavListLink
              component={Link}
              to={PATHS.DATA_CONSENT_PERIODIC_CONTROL}
              title={{ text: 'Samtykke - Periodisk kontroll' }}
              key="samtykke-periodisk"
            />,
          ]}
        />
      </section>
      <section style={sectionStyle}>
        <h2>Min oversikt - Innstillinger</h2>
        <SwitchContainer>
          <div>Saldomodul</div>
          <Switch
            isOn={user.settings.homePage.displayBalanceCarousel}
            onText="På"
            offText="Av"
            handleClick={onToggleBalanceCarousel}
          />
        </SwitchContainer>
        <SwitchContainer>
          <div>eFaktura med ett klikk</div>
          <Switch
            isOn={user.settings.homePage.displayEfakturaOneClick}
            onText="På"
            offText="Av"
            handleClick={onToggleEfakturaOneClickFromHomepage}
          />
        </SwitchContainer>
        <SwitchContainer>
          <div>Nye widgets</div>
          <Switch
            isOn={user.settings.homePage.displayActions}
            onText="På"
            offText="Av"
            handleClick={onToggleActionsFromHomepage}
          />
        </SwitchContainer>
        <SwitchContainer>
          <div>Gammel navigasjon</div>
          <Switch
            isOn={user.settings.homePage.displayOldNavList}
            onText="På"
            offText="Av"
            handleClick={onToggleOldNavListFromHomepage}
          />
        </SwitchContainer>
        <SwitchContainer>
          <div>eFaktura fra alle banner / reklame</div>
          <Switch
            isOn={user.settings.homePage.displayEfakturaFraAlleBanner}
            onText="På"
            offText="Av"
            handleClick={onToggleEfakturaBannerFromHomepage}
          />
        </SwitchContainer>
        <SwitchContainer>
          <div>Hvis badge i Tabbar</div>
          <Switch
            isOn={user.settings.homePage.displayBadgesInTabbar}
            onText="På"
            offText="Av"
            handleClick={onToggleBadgeInTabbar}
          />
        </SwitchContainer>
      </section>
      <section style={sectionStyle}>
        <h2>Min økonomi</h2>
        <NavList
          items={[
            <NavListLink
              component={Link}
              to={PATHS.ECONOMY}
              title={{ text: 'Min økonomi' }}
              key="okonomi"
            />,
          ]}
        />
      </section>
      <section style={sectionStyle}>
        <h2>Mine tagger</h2>
        <NavList
          items={[
            <NavListLink
              component={Link}
              to={PATHS.TAGS}
              title={{ text: i18n(t => t.ROUTE_TITLE_TAGS) }}
              key="tags"
            />,
            <NavListLink
              component={Link}
              to={PATHS.TRAVEL}
              title={{ text: i18n(t => t.ROUTE_TITLE_TRAVEL) }}
              key="tags-travel"
            />,
          ]}
        />
      </section>
    </BasePage>
  </BasePageStyled>
);

TestPage.propTypes = {
  onToggleBalanceCarousel: PropTypes.func.isRequired,
  onToggleActionsFromHomepage: PropTypes.func.isRequired,
  onToggleEfakturaOneClickFromHomepage: PropTypes.func.isRequired,
  onToggleOldNavListFromHomepage: PropTypes.func.isRequired,
  onToggleEfakturaBannerFromHomepage: PropTypes.func.isRequired,
  onToggleBadgeInTabbar: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  onToggleBalanceCarousel: () => dispatch(toggleBalanceCarousel()),
  onToggleActionsFromHomepage: () => dispatch(toggleActionsFromHomepage()),
  onToggleOldNavListFromHomepage: () =>
    dispatch(toggleOldNavListFromHomepage()),
  onToggleEfakturaOneClickFromHomepage: () =>
    dispatch(toggleEfakturaOneClickFromHomepage()),
  onToggleEfakturaBannerFromHomepage: () =>
    dispatch(toggleEfakturaBannerFromHomepage()),
  onToggleBadgeInTabbar: () => dispatch(toggleBadgeInTabbar()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TestPage);
