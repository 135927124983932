/* eslint-disable no-console */
import React, { Component } from 'react';
import { reduxForm, destroy } from 'redux-form';
import { change } from 'redux-helpers/data.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dataSelector from 'redux-helpers/dataSelector';
import { BasePage } from 'digitalbank-layout';
import Button from 'digitalbank-buttons';
import * as PATHS from 'routes/paths';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { CheckCircle } from 'digitalbank-icons';
import i18n from 'i18n';
import {
  deleteTransaction,
  getTransactionFromId,
  updateTransactionWithFormValues,
  updateTransactionStatus,
} from 'services/transactions.service';
import {
  STATUS as TRANSACTION_STATUS,
  TYPE as TRANSACTION_TYPE,
} from 'digitalbank-mockup-data/lib/Transactions';
import Alert from 'digitalbank-alert';
import {
  removeSperretEfakturaAvsender,
  addSperretEfakturaAvsender,
} from 'routes/Avtaleadministrasjon/EfakturaFraAlle/efakturaFraAlle.actions';
import PaymentForm from '../PaymentForm';
import validate from '../payment.validate';
import { BETALE_EN_EDIT_FORM_NAME } from '../payment.constants';
import {
  load as loadTransaction,
  unload as unloadTransaction,
} from './editBetaleEn.actions';
import propTypes from '../editBetaling.propTypes';
import EditBetaleEnActions from './EditBetaleEnActions';
import PaymentStyled from '../PaymentStyled';

const init = (transactions, transactionId, history, load) => {
  const isTransactionFound =
    _.filter(transactions.data, x => x.id === transactionId).length > 0;
  if (!isTransactionFound) {
    history.push(PATHS.HOME);
  } else {
    load(transactions, transactionId);
  }
};

const isAvsenderSperret = (transaction, sperredeAvsendere) => {
  if (transaction.tilbyderId) {
    return sperredeAvsendere.indexOf(transaction.tilbyderId) > -1;
  }
  return false;
};

class EditBetaleEn extends Component {
  componentDidMount() {
    this.props.onDestroy(BETALE_EN_EDIT_FORM_NAME);
    if (this.props.transactions.status.received) {
      const {
        transactions, match, history, load,
      } = this.props;
      init(transactions, match.params.id, history, load);
    }
  }
  componentWillUpdate(nextProps) {
    if (
      !this.props.transactions.status.received &&
      nextProps.transactions.status.received
    ) {
      const { match, history, load } = this.props;
      const { transactions } = nextProps;
      init(transactions, match.params.id, history, load);
    }
  }
  componentWillUnmount() {
    this.props.unload();
  }
  render() {
    const {
      history,
      handleSubmit,
      transactions,
      changeData,
      submitting,
      automaticEfakturaIsActive,
    } = this.props;
    const { id } = this.props.match.params;
    const transaction = getTransactionFromId(transactions.data, id);
    const isDeletePending =
      transaction.status === TRANSACTION_STATUS.SHOULD_BE_DELETED;
    const isStopped = transaction.status === TRANSACTION_STATUS.STOPPED;
    const isDeleted = transaction.status === TRANSACTION_STATUS.DELETED;
    const isEfaktura = transaction.type === TRANSACTION_TYPE.EFAKTURA;
    const isFastBetaling = transaction.type === TRANSACTION_TYPE.FAST_BETALING;
    const isSperret = isAvsenderSperret(
      transaction,
      this.props.sperredeAvsendere,
    );

    return (
      <PaymentStyled>
        <BasePage heading={i18n(t => t.ROUTE_TITLE_PAY)} className="p-payment">
          {isFastBetaling &&
            this.props.initialValues && (
              <div className="p-payment__info-alert">
                <Alert type="info" small hideIcon>
                  <p>
                    Denne betalingen inngår i et fast oppdrag.{' '}
                    <Link
                      to={`${PATHS.FAST_BETALING_EDIT}/${
                        this.props.initialValues.id
                      }`}
                    >
                      Endre alle (inkludert denne)
                    </Link>
                  </p>
                </Alert>
              </div>
            )}
          <PaymentForm
            isDisabled={isDeleted}
            isEditing
            isSubmitting={submitting}
            formName={BETALE_EN_EDIT_FORM_NAME}
            submitButtonText="Bekreft"
            initialValues={this.props.initialValues}
            handleSubmit={handleSubmit(
              values =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    // INFO: We want to set status to active when you submit changes.
                    const newValues = {
                      ...values,
                      status: TRANSACTION_STATUS.ACTIVE,
                    };
                    updateTransactionWithFormValues(
                      'transactions',
                      transactions,
                      changeData,
                      id,
                      newValues,
                    );
                    history.push(`${PATHS.EDIT_BETALE_EN_RECEIPT}/${id}`);
                    resolve(values);
                  }, 1000);
                }),
            )}
          />
          {isDeleted &&
            isSperret && (
              <div className="p-payment__info-alert">
                <Alert type="info" icon={CheckCircle} small>
                  <p>
                    eFakturaen er slettet, og du vil nå ikke motta flere
                    eFakturaer fra {transaction.tilKontonavn}.
                    <Button
                      variant="link"
                      onClick={() => {
                        this.props.onRemoveSperretEfakturaAvsender(
                          transaction.tilbyderId,
                        );
                        updateTransactionStatus(
                          'transactions',
                          transactions,
                          changeData,
                          id,
                          TRANSACTION_STATUS.UNAPPROVED,
                        );
                      }}
                    >
                      Angre
                    </Button>
                  </p>
                </Alert>
              </div>
            )}
          {isDeleted &&
            !isSperret && (
              <div className="p-payment__info-alert">
                <Alert
                  type="info"
                  icon={CheckCircle}
                  small
                  // title="Betalingen ble slettet"
                >
                  <p>
                    Betalingen ble slettet.{' '}
                    <Button
                      variant="link"
                      onClick={() => {
                        this.props.onRemoveSperretEfakturaAvsender(
                          transaction.tilbyderId,
                        );
                        updateTransactionStatus(
                          'transactions',
                          transactions,
                          changeData,
                          id,
                          TRANSACTION_STATUS.UNAPPROVED,
                        );
                      }}
                    >
                      Angre
                    </Button>
                  </p>
                  {/* <Link to={PATHS.SCHEDULED_PAYMENTS}>Se andre forfall</Link> */}
                </Alert>
              </div>
            )}
          <EditBetaleEnActions
            transaction={transaction}
            automaticEfakturaIsActive={automaticEfakturaIsActive}
            isEfaktura={isEfaktura}
            isDeleted={isDeleted}
            isDeletePending={isDeletePending}
            isStopped={isStopped}
            isSperret={isSperret}
            start={() =>
              updateTransactionStatus(
                'transactions',
                transactions,
                changeData,
                id,
                TRANSACTION_STATUS.ACTIVE,
              )
            }
            stop={() =>
              updateTransactionStatus(
                'transactions',
                transactions,
                changeData,
                id,
                TRANSACTION_STATUS.STOPPED,
              )
            }
            remove={() =>
              deleteTransaction('transactions', transactions, changeData, id)
            }
            block={() =>
              this.props.onAddSperretEfakturaAvsender(transaction.tilbyderId)
            }
          />
        </BasePage>
      </PaymentStyled>
    );
  }
}

EditBetaleEn.defaultProps = {
  initialValues: null,
};

EditBetaleEn.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  load: bindActionCreators(loadTransaction, dispatch),
  unload: bindActionCreators(unloadTransaction, dispatch),
  onDestroy: bindActionCreators(destroy, dispatch),
  changeData: bindActionCreators(change, dispatch),
  onRemoveSperretEfakturaAvsender: id =>
    dispatch(removeSperretEfakturaAvsender(id)),
  onAddSperretEfakturaAvsender: id => dispatch(addSperretEfakturaAvsender(id)),
});

const mapStateToProps = state => ({
  initialValues: state.editBetaleEn.data,
  transactions: dataSelector(state, 'transactions'),
  accounts: state.accounts,
  sperredeAvsendere: state.user.sperredeAvsendere,
  automaticEfakturaIsActive: state.user.settings.automaticEfakturaIsActive,
});

const form = reduxForm({
  destroyOnUnmount: false,
  form: BETALE_EN_EDIT_FORM_NAME,
  validate,
})(EditBetaleEn);

export default connect(mapStateToProps, mapDispatchToProps)(form);
