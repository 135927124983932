import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 965.726 965.726"
    fill="currentColor"
    {...props}
  >
    <path d="M766.601 628.012v-158.1l-253.101 50.9c-10 2-20.3 3-30.6 3s-20.5-1-30.6-3l-253.101-50.9v158.1c0 40.9 127 74.101 283.7 74.101s283.702-33.101 283.702-74.101z" />
    <path d="M945.7 354.312l-438.1-88.2c-8.101-1.6-16.4-2.5-24.7-2.5s-16.5.8-24.7 2.5l-438.1 88.2c-26.801 5.4-26.801 43.6 0 49l179.1 36 259 52.1c8.101 1.6 16.4 2.5 24.7 2.5s16.5-.8 24.7-2.5l259-52.1 80.6-16.2v187.399a45.375 45.375 0 0 0-15.5 34.2c0 25.101 20.4 45.5 45.5 45.5 25.101 0 45.5-20.399 45.5-45.5 0-13.7-6-25.899-15.5-34.2v-199.5l38.5-7.699c26.7-5.399 26.7-43.6 0-49z" />
  </svg>
);
