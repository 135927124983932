import _ from 'lodash';
import { ADD_MESSAGE, READ_MESSAGE } from 'actions/actionTypes';

export default (state = [], action = {}) => {
  switch (action.type) {
    case ADD_MESSAGE: {
      return [
        ...state,
        {
          ...action.message,
        },
      ];
    }
    case READ_MESSAGE: {
      const index = _.findIndex(state, x => x.id === action.id);
      const message = {
        ...state[index],
        isRead: true,
      };
      return [...state.slice(0, index), message, ...state.slice(index + 1)];
    }
    default:
      return state;
  }
};
