/* eslint-disable no-alert, no-script-url, no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { InputField } from 'digitalbank-form';
import { BasePage } from 'digitalbank-layout';
import DetailSection from 'digitalbank-detail-section';
import Button from 'digitalbank-buttons';
import { Modal, ModalCard } from 'digitalbank-modal';
import postalCodes from 'norway-postal-codes';
import * as PATHS from 'routes/paths';
import LoadingButton from 'components/LoadingButton';
import historyShape from 'shapes/history.shape';
import { BasePageStyled } from 'styled';

class BankIDOrderNewKodebrikkePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
    };
  }
  render() {
    const { isModalVisible } = this.state;
    const { adresse, postnummer, handleSubmit } = this.props;
    const location = postalCodes[postnummer];

    return (
      <BasePageStyled>
        <BasePage heading="Bestill ny kodebrikke">
          <section className="p-bankid-order__box">
            <h4>Kodebrikken sendes til din registrerte adresse</h4>
            <p style={{ marginBottom: '20px' }}>
              Straumegaten 52 <br /> 5151 Straumsgrend
            </p>
            {/* <DetailSection
            className="p-bankid-order__detail-section"
            items={[
              {
                label: 'Bestiller',
                value: 'Karianne Lie Johansen',
              },
              {
                label: 'Adresse',
                value: `${adresse}, ${postnummer || ''} ${location || ''}`,
              },
            ]}
          /> */}
            {/* <Button
            link
            onClick={() => this.setState({ isModalVisible: true })}
            className="p-bankid-order__open-edit-btn"
          >
            Endre postadresse
          </Button>
          <Modal
            visible={isModalVisible}
            toggle={() => this.setState({ isModalVisible: !isModalVisible })}
          >
            <ModalCard
              toggle={() => this.setState({ isModalVisible: !isModalVisible })}
              heading="Endre postadresse"
              className="p-bankid-order__modal-card"
            >
              <form
                action="javascript:void(0);"
                onSubmit={handleSubmit(() => {
                  this.setState({ isModalVisible: false });
                })}
                className="efaktura-fra-alle-form"
              >
                <fieldset className="fieldset">
                  <Field
                    name="adresse"
                    component={InputField}
                    label="Adresse"
                  />
                  <div className="contact-form__location">
                    <Field
                      name="postnummer"
                      component={InputField}
                      type="tel"
                      size="10"
                      hasAutoWidth
                      label="Postnummer"
                      maxLength="4"
                      rightAddon={() => (
                        <div
                          className="contact-form__location-name"
                          aria-live="polite"
                          role="status"
                        >
                          {location && location}
                        </div>
                      )}
                    />
                  </div>
                </fieldset>
                <Button variant="primary" style={{ width: '100%' }} type="submit">
                  Lagre
                </Button>
              </form>
            </ModalCard>
          </Modal> */}
            <div className="p-bankid-order__actions">
              <LoadingButton
                variant="primary"
                onClick={() =>
                  this.props.history.push(
                    PATHS.BANKID_NEW_KODEBRIKKE_ORDER_RECEIPT,
                  )
                }
                className="p-bankid-order__submit-btn"
              >
                Bestill
              </LoadingButton>
              {/* <Button
              link
              onClick={this.props.history.goBack}
              className="p-bankid-order__cancel-btn"
            >
              Avbryt
            </Button> */}
            </div>
          </section>
        </BasePage>
      </BasePageStyled>
    );
  }
}

BankIDOrderNewKodebrikkePage.defaultProps = {
  adresse: '',
  postnummer: '',
};

BankIDOrderNewKodebrikkePage.propTypes = {
  adresse: PropTypes.string,
  postnummer: PropTypes.string,
  history: historyShape.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const form = reduxForm({
  form: 'bankIDOrderNewKodebrikkeForm',
  destroyOnUnmount: false,
})(BankIDOrderNewKodebrikkePage);

const mapStateToProps = (state) => {
  const selector = formValueSelector('bankIDOrderNewKodebrikkeForm');
  const { bankIDInformation } = state.user;
  return {
    adresse: selector(state, 'adresse'),
    postnummer: selector(state, 'postnummer'),
    initialValues: {
      adresse: bankIDInformation.address,
      postnummer: bankIDInformation.zipCode,
    },
  };
};

export default connect(mapStateToProps)(form);
