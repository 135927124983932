/* eslint-disable no-mixed-operators */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { spacing, colors } from 'digitalbank-core-style';
// import { baseFontColor } from 'digitalbank-core-style/lib/colors';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { Transition } from 'react-transition-group';
import TagBudgetGraph from './TagBudgetGraph';

const tagGraphAnimationDuration = 250;
const TagName = styled.h4`
  margin: 0;
  font-size: 1rem;
`;

const TagAmount = styled.p`
  margin: 0;
  font-size: 1rem;
`;

const TagTransactionNumber = styled.div`
  color: ${colors.doveGray};
  font-size: 0.875rem;
`;

const TagBudget = styled.div`
  color: ${colors.doveGray};
  font-size: 0.875rem;
`;

const TagHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${spacing.componentSpacing.xs}px;
`;

const TagFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TagStyled = styled.div`
  a {
    display: block;
    /* background-color: ${props => (props.isActive ? '#e5f2dd' : 'white')}; */
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: ${colors.tundora};
    transition: transform 125ms ease-in-out, box-shadow 125ms ease-in-out;

    /*Need this to position the Graph*/
    position: relative;
    overflow: hidden;

    &:hover,
    &:focus {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      transform: scale(1.02);
    }

    &:active {
      transform: scale(1);
      box-shadow: none;
    }
  }

  p {
    margin: 0;
  }

  ${TagBudgetGraph} {
    padding-top: 20px;
  }

  ${TagName}, ${TagTransactionNumber}, ${TagAmount}, ${TagBudget} {
    line-height: 1;
    z-index: 200;
    position: relative;
  }
`;

const Tag = ({ tag }) => {
  // const amount = Math.floor(Math.random() * (tag.budget || 500 - 1 + 1));
  const hasBudget = tag.budget && tag.budget > 0;
  const hasBudgetAndIsActive = hasBudget && tag.isActive;
  const leftToUse = hasBudget ? tag.budget - tag.amount : 0;
  const tagBudgetPercentage = hasBudget
    ? Math.round((tag.amount / tag.budget) * 100)
    : 0;

  return (
    <TagStyled isActive={tag.isActive} hasBudget={hasBudgetAndIsActive}>
      <Link to={`${PATHS.TAG}/${tag.id}`}>
        <TagHeader>
          <TagName>{tag.name}</TagName>
          <TagAmount>
            {!hasBudgetAndIsActive &&
              tag.amount.toLocaleString('nb-NO', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            {hasBudgetAndIsActive &&
              `${leftToUse.toLocaleString('nb-NO', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} igjen`}
          </TagAmount>
        </TagHeader>
        <TagFooter>
          <TagTransactionNumber>
            {tag.numberOfTransactions} transaksjoner
          </TagTransactionNumber>
          {hasBudgetAndIsActive && (
            <TagBudget>
              {`av ${Number(tag.budget).toLocaleString('nb-NO', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </TagBudget>
          )}
        </TagFooter>
        {hasBudgetAndIsActive && (
          <Transition in={hasBudget} timeout={tagGraphAnimationDuration} appear>
            {state => (
              <TagBudgetGraph
                animationDuration={tagGraphAnimationDuration}
                percentage={
                  tagBudgetPercentage > 100 ? 100 : tagBudgetPercentage
                }
                state={state}
                isActive={tag.isActive}
              />
            )}
          </Transition>
        )}
      </Link>
    </TagStyled>
  );
};

Tag.propTypes = {
  tag: PropTypes.shape({}).isRequired,
};

export default Tag;
