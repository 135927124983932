import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import LocaleUtils from 'react-day-picker/moment';
import moment from 'moment';
import onClickOutside from 'react-onclickoutside';
import { KeyCodes } from 'utils';
import DatePickerCaption from './DatePickerCaption';
import DatePickerDate from './DatePickerDate';
import DatePickerNavbar from './DatePickerNavbar';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.state = {
      date: this.props.selectedDate,
    };
  }
  componentDidMount() {
    this.dayPickerComponent.dayPicker.focus();
  }
  handleClickOutside() {
    this.props.handleClickOutside();
  }
  handleYearMonthChange(date) {
    this.setState({ date });
  }
  render() {
    const {
      toMonth, fromMonth, selectedDate, onSelectDate,
    } = this.props;

    const isDisabledDate = (day) => {
      const calendarDay = moment(day);
      return (
        calendarDay.isBefore(fromMonth, 'day') ||
        calendarDay.isAfter(toMonth, 'day')
      );
    };

    const isSelectedDate = (day) => {
      const calendarDay = moment(day);
      return calendarDay.isSame(selectedDate, 'day');
    };

    const onDayClick = (day, { disabled }) => {
      if (!disabled) {
        onSelectDate(moment(day));
      }
    };

    const onKeypress = (e) => {
      if (e.keyCode === KeyCodes.ESCAPE) {
        onSelectDate(moment(selectedDate));
      } else if (e.keyCode === KeyCodes.TAB) {
        if (
          (e.target.id === 'DayPicker' && e.shiftKey) ||
          (e.target.classList.contains('DayPicker-Day') && !e.shiftKey)
        ) {
          setTimeout(() => {
            // Timeout to fix IE tab order quirk
            onSelectDate(moment(selectedDate));
          }, 0);
        }
      }
    };

    return (
      <DayPicker
        enableOutsideDays
        ref={(c) => {
          this.dayPickerComponent = c;
        }}
        month={this.state.date}
        fromMonth={fromMonth}
        toMonth={toMonth}
        modifiers={{ disabled: isDisabledDate, selected: isSelectedDate }}
        onDayClick={onDayClick}
        onKeyDown={onKeypress}
        navbarElement={<DatePickerNavbar />}
        captionElement={
          <DatePickerCaption
            onChange={this.handleYearMonthChange}
            toMonth={toMonth}
            fromMonth={fromMonth}
            localUtils={LocaleUtils}
          />
        }
        renderDay={DatePickerDate}
        id="DayPicker"
        localeUtils={LocaleUtils}
        locale="nb-NO"
      />
    );
  }
}

DatePicker.propTypes = {
  selectedDate: PropTypes.shape({}).isRequired,
  fromMonth: PropTypes.instanceOf(Date).isRequired,
  toMonth: PropTypes.instanceOf(Date).isRequired,
  handleClickOutside: PropTypes.func.isRequired,
  onSelectDate: PropTypes.func.isRequired,
};

export default onClickOutside(DatePicker);
