import styled from 'styled-components';
import { breakpoints, spacing } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-efaktura-fra-alle-info {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__main {
      ${horizontalLayoutSpacing({ phone: false })};

      @media (max-width: ${breakpoints.tablet}px) {
        margin-bottom: rem(100);
      }
    }

    &__aside {
      ${horizontalLayoutSpacing({ phone: false })};
    }

    &__article {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
    }

    &__continue-btn-container {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
    }

    &__continue-btn {
      @media (max-width: ${breakpoints.tablet}px) {
        width: 100%;
      }
    }
  }
`;
