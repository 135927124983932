import styled from 'styled-components';
import { colors } from 'digitalbank-core-style';

const TagBudgetGraph = styled.div`
  position: relative;
  width: 100%;
  height: ${props => props.height};
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: ${props => props.height};
    border-radius: 8px;
    background-color: ${colors.alto};
  }

  &::before {
    right: 0;
    background-color: ${colors.alto};
    z-index: 100;
  }

  &::after {
    background-color: ${props =>
    (props.isActive ? colors.guardsmanRed : colors.doveGray)};
    z-index: 200;
    /* 100 % minus padding */
    width: calc(${props => props.percentage}% - 2rem);
    transition: transform ${props => props.animationDuration}ms ease-in-out;
    transform: ${props =>
    (props.state === 'entered'
      ? 'translateX(0%)'
      : 'translateX(calc(-100% - 1rem))')};
  }
`;

TagBudgetGraph.defaultProps = {
  height: '6px',
};

export default TagBudgetGraph;
