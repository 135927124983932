export default [
  {
    avtalegiroavtale: {
      id:
        'ALd7ifpnRkvaxf-Y51rPuuNvkx8PHusPQVnGitvP1QQYZ49WP40n0WVqPcKwpCe4qJaXAFEKOCfOE3kWz8bvt7Pz3usg47EhMtSH1aD5tFHW35veoQ2',
      mottakernavn: 'GET AS',
      debetkonto: '36311532884',
      kredittkonto: '63500514347',
      kid: '9808741680312014699',
      maanedligBeloep: 3004.0,
      status: 'Ingen',
      sisteBetalingsdato: null,
      notat: 'Tony T sin GET!L',
    },
    efakturaavtale: {
      kundereferanse: '980874168',
      epost: 'ashild@priv.no',
      kundeEpost: null,
      beskrivelse: 'Test e-post og slett forslag',
      mottakernavn: 'Get ',
      id:
        'AAKoLHr5j4rG-PG5YMyLu2RJVuKXPoVPiWJ0C4041PGpIhQ-1n11TUNoYT9LFn-E3pcJmY-FL-WP1skhiOvWJyK64yDjw-8TpjWBjM0CvEpDzZMhvKAIFiAK9mEcUQGp9g5yqcpvxghhodL6VMf1vhCPDyu0mNBZ-Iypmta-Z3LSzh0HfQ2',
      status: 'Venter',
    },
  },
];

export const kombinertavtalerForslag = [
  {
    avtalegiroavtaleforslag: {
      id:
        'AGpzQFdjB7yruYktOBSyNKD9A4O34M_DHPuW57toWTO8whHtcy_5Sjcy3F7b5iVRRHi_8rucVVzv2RZu0Eq24y5zbnQYuPK9yD6XzVtrvzb23kqzmQ2',
      mottakernavn: 'BKK Kundetjenester AS',
      kredittkonto: '52010661641',
      kid: '0083041319811',
    },
    efakturaavtaleforslag: {
      id: 'AP-QAoLg4djztKdbBpWZnVtvl_jdDCijyDyhmmcomleZ_6LifA2',
      kundereferanse: null,
      mottakernavn: 'BKK Kundetjenester AS',
      kundeEpost: null,
      tilbyderId: 'NOR987011653-1',
      brandId: '001',
    },
  },
];
