/* eslint-disable max-len */

import _ from 'lodash';
import moment from 'moment';
import shortid from 'shortid';
import { isKidNumber } from 'utils/validation.util';

import Transactions, {
  STATUS,
  TYPE,
} from 'digitalbank-mockup-data/lib/Transactions';
import { concatKronerAndOere } from 'utils/format.util';

const getInitialTransactions = () => Transactions;

const getInitialRecurringTransactions = () =>
  _.filter(
    Transactions,
    x =>
      x.status !== STATUS.DELETED &&
      (x.type === TYPE.FAST_BETALING || x.type === TYPE.FAST_OVERFOERING),
  );

export const getTransactionFromId = (transactions, id) =>
  _.find(transactions, x => x.id === id);

const getIndexOfTransaction = (transactions, id) =>
  _.findIndex(transactions, x => x.id === id);

const getMelding = ({ melding, kidMelding }) => {
  if (kidMelding && isKidNumber(kidMelding)) return '';
  return kidMelding || melding;
};

const getDescription = ({ tilKontonavn, recipientName, melding }) =>
  tilKontonavn || recipientName || melding;

const getTilKontonavn = ({ tilKontonavn, tilKonto, recipientName }) => {
  if (tilKontonavn) return tilKontonavn; // regular payment
  if (recipientName) return recipientName; // foreign payment
  if (tilKonto) return tilKonto.visningsnavn || tilKonto.accountName; // transfer
  return '';
};

// transfer => object, payment => string, global-payment => string
const getTilkonto = ({ tilKonto }) => tilKonto.accountNumber || tilKonto;

export const updateTransactionStatus = (
  dataName,
  transactions,
  changeData,
  id,
  status,
) => {
  const indexOfTransaction = getIndexOfTransaction(transactions.data, id);
  changeData(dataName, {
    ...transactions,
    data: [
      ...transactions.data.slice(0, indexOfTransaction),
      {
        ...transactions.data[indexOfTransaction],
        status,
      },
      ...transactions.data.slice(indexOfTransaction + 1),
    ],
  });
};

export const updateTransactionWithFormValues = (
  dataName,
  transactions,
  changeData,
  id,
  values,
) => {
  const indexOfTransaction = getIndexOfTransaction(transactions.data, id);
  const transaction = transactions.data[indexOfTransaction];
  const {
    tilKontonavn,
    tilKonto,
    melding,
    kid,
    dato,
    fraKonto,
    kroner,
    oere,
    ...other
  } = values;
  changeData(dataName, {
    ...transactions,
    data: [
      ...transactions.data.slice(0, indexOfTransaction),
      {
        ...transaction,
        ...other,
        beloep: {
          ...transaction.beloep,
          verdi: concatKronerAndOere(kroner, oere) * -1,
        },
        tilKontonavn: getTilKontonavn(values),
        tilKonto: getTilkonto(values),
        melding: getMelding(values),
        kid,
        dato: moment(dato, 'L').toISOString(),
        fraKonto: fraKonto.accountNumber,
        status: STATUS.ACTIVE,
      },
      ...transactions.data.slice(indexOfTransaction + 1),
    ],
  });
};

export const addTransaction = (
  dataName,
  transactions,
  changeData,
  values,
  estimatedAmount = null,
  id = shortid.generate(),
) => {
  changeData(dataName, {
    ...transactions,
    data: [
      ...transactions.data,
      {
        id,
        beloep: {
          verdi: concatKronerAndOere(values.kroner, values.oere) * -1,
          valutakode: values.paymentCurrency ? values.paymentCurrency : 'NOK',
        },
        estimertBeloep: estimatedAmount,
        description: getDescription(values), // used in search result
        tilKontonavn: getTilKontonavn(values),
        tilKonto: getTilkonto(values),
        melding: getMelding(values),
        kid:
          values.kid || (values.kidMeldingisKidNumber && values.kidMelding)
            ? values.kidMelding
            : '',
        kidMelding: values.kidMelding,
        dato: moment(values.dato, 'L').toISOString(),
        status: STATUS.ACTIVE,
        fraKonto: values.fraKonto.accountNumber,
        type: values.type || TYPE.ANNET,
        frekvens: values.frekvens,
      },
    ],
  });
};

export const deleteTransaction = (dataName, transactions, changeData, id) =>
  new Promise((resolve) => {
    setTimeout(() => {
      updateTransactionStatus(
        dataName,
        transactions,
        changeData,
        id,
        STATUS.SHOULD_BE_DELETED,
      );
    }, 250);
    setTimeout(() => {
      updateTransactionStatus(
        dataName,
        transactions,
        changeData,
        id,
        STATUS.DELETED,
      );
      resolve();
    }, 1000);
  });

export const setApprovedTransactionsToActive = (transactions, changeData) =>
  new Promise((resolve) => {
    const newTransactions = transactions.data.map((transaction) => {
      if (transaction.status === STATUS.APPROVED) {
        return {
          ...transaction,
          status: STATUS.ACTIVE,
        };
      }
      return transaction;
    });
    changeData('transactions', {
      ...transactions,
      data: newTransactions,
    });
    resolve(newTransactions);
  });

export const updateUnapprovedfakturaTransactionsWithFromAccount = (
  transactions,
  changeData,
  fromAccountNumber,
) => {
  changeData('transactions', {
    ...transactions,
    data: transactions.data.map((transaction) => {
      if (
        transaction.type === TYPE.EFAKTURA &&
        transaction.status === STATUS.UNAPPROVED
      ) {
        return {
          ...transaction,
          fraKonto: fromAccountNumber,
        };
      }
      return transaction;
    }),
  });
};
export { getInitialTransactions, getInitialRecurringTransactions };

export default getInitialTransactions;
