import React from 'react';
import { Link } from 'react-router-dom';
import { Exchange, Transfer, History } from 'digitalbank-icons';
import * as PATHS from '../routes/paths';

const Shortcuts = () => (
  <nav className="shortcuts">
    <ul className="shortcuts__links">
      <li className="shortcuts__link">
        <Link to={PATHS.PAY} className="shortcuts__anchor">
          <Exchange className="shortcuts__icon" />
          <span className="shortcuts__label">Betale</span>
        </Link>
      </li>
      <li className="shortcuts__link">
        <Link to={PATHS.TRANSFER} className="shortcuts__anchor">
          <Transfer className="shortcuts__icon" />
          <span className="shortcuts__label">Overføre</span>
        </Link>
      </li>
      <li className="shortcuts__link">
        <Link to={PATHS.SEARCH} className="shortcuts__anchor">
          <History className="shortcuts__icon" />
          <span className="shortcuts__label">Historikk</span>
        </Link>
      </li>
    </ul>
  </nav>
);

export default Shortcuts;
