import React from 'react';
import PropTypes from 'prop-types';
import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import ContactInfoCard from 'routes/ContactInfo/ContactInfoCard';
import SettingsCard from './SettingsCard';

const SettingsCardForContactInfo = ({ info }) => (
  <SettingsCard heading="Kontaktinfo" className="settings-card-contact-info">
    <ContactInfoCard info={info} />
    <NavList
      items={[
        <NavListLink
          key="kontaktinformasjon"
          title={{ text: 'Endre kontaktinformasjon' }}
          component={Link}
          to={PATHS.CONTACT_INFO}
          variant={TASK}
        />,
      ]}
    />
  </SettingsCard>
);

SettingsCardForContactInfo.propTypes = {
  info: PropTypes.shape({}).isRequired,
};

export default SettingsCardForContactInfo;
