import Regions from './Regions';

export default [
  {
    id: 0,
    name: 'Norge',
    mandatory: true,
  },
  {
    id: 1,
    name: 'Danmark og Sverige',
    mandatory: false,
  },
  {
    id: 2,
    name: 'Europa',
    countries: Regions.northernEurope.countries
      .concat(Regions.southernEurope.countries)
      .concat(Regions.easternEurope.countries)
      .concat(Regions.westernEurope.countries),
    mandatory: false,
  },
  {
    id: 3,
    name: 'Afrika',
    countries: Regions.centralAfrica.countries
      .concat(Regions.northAfrica.countries)
      .concat(Regions.southernAfrica.countries)
      .concat(Regions.eastAfrica.countries)
      .concat(Regions.westAfrica.countries),
    mandatory: false,
  },
  {
    id: 4,
    name: 'Asia',
    mandatory: false,
    countries: Regions.centralAsia.countries
      .concat(Regions.southernAsia.countries)
      .concat(Regions.southeastAsia.countries)
      .concat(Regions.eastAsia.countries)
      .concat(Regions.westernAsia.countries),
  },
  {
    id: 5,
    name: 'Nord- og Mellom-Amerika',
    mandatory: false,
    countries: Regions.northernAmerica.countries.concat(
      Regions.centralAmerica.countries,
    ),
  },
  {
    id: 6,
    name: 'Sør-Amerika',
    mandatory: false,
    countries: Regions.southAmerica.countries,
  },
  {
    id: 7,
    name: 'Oseania',
    mandatory: false,
    countries: Regions.australia.countries
      .concat(Regions.melanesia.countries)
      .concat(Regions.micronesia.countries)
      .concat(Regions.polynesia.countries),
  },
];
