/* eslint-disable no-script-url */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonResetStyle } from 'styled/Buttons';
import { config, httpClient } from 'services/economy.service';
import { customerId } from 'actions/economy.actions';
import { InputField } from 'digitalbank-form';
import { Times } from 'digitalbank-icons';

export const getTags = transactionId =>
  httpClient.get(`${config.apiEndpoints.tag}/${transactionId}`);

export const deleteTag = (tagId, transactionId) =>
  httpClient.delete(
    `${config.apiEndpoints.tag}/${tagId}/transaction/${transactionId}`,
  );

const Tags = styled.div`
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  margin-bottom: 18px;
`;

const Tag = styled.div`
  background-color: #c0e6ff;
  color: #325c80;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  margin: 0.125rem;
  border-radius: 1rem;
`;

const TagButton = styled(ButtonResetStyle)`
  background-color: #dfe3e6;
  color: #394b54;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  line-height: 1;
  margin: 0.125rem;
  border-radius: 1rem;

  i {
    display: flex;
    width: 0.5rem;
    margin-left: 0.5rem;
  }
`;

export const TransactionTagsStyle = styled.div`
  /* background: red; */
`;

export const EditTagButton = styled.button`
  font-size: 0.75rem;
  margin: 0.125rem 0.125rem 0.125rem 1rem;
`;

export default class TransactionTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: null,
      tagInputValue: '',
      isEditMode: false,
    };
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.getTags = this.getTags.bind(this);
    this.deleteTag = this.deleteTag.bind(this);
  }
  componentDidMount() {
    this.getTags(this.props.transaction.transactionId);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.transaction.transactionId !==
      prevProps.transaction.transactionId
    ) {
      this.getTags(this.props.transaction.transactionId);
    }
  }
  onFormSubmit(e) {
    const { transactionId } = this.props.transaction;
    e.preventDefault(); // Prevent default form submit
    const url = `${config.apiEndpoints.tag}/${transactionId}`;
    const data = {
      tagName: this.state.tagInputValue,
      customerId,
    };
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    }).then(() => {
      this.getTags(transactionId, true);
    });
  }
  getTags(transactionId, shouldResetTagInputValue = false) {
    getTags(transactionId)
      .then((data) => {
        const parsedData = JSON.parse(data);
        // TODO: Sort data
        const tags = parsedData.sort(
          (a, b) => new Date(a.inserted) - new Date(b.inserted),
        );
        this.setState({
          tags,
          tagInputValue: shouldResetTagInputValue
            ? ''
            : this.state.tagInputValue,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        throw error;
      });
  }
  deleteTag(tagId, transactionId) {
    deleteTag(tagId, transactionId)
      .then(() => this.getTags(transactionId))
      .catch((error) => {
        // eslint-disable-next-line
        console.log(error);
        throw error;
      });
  }
  render() {
    const { tags, isEditMode } = this.state;
    const { transaction } = this.props;
    return (
      <TransactionTagsStyle>
        {this.props.visible &&
          tags &&
          tags.length > 0 && (
            <div>
              <h4>Tags</h4>
              <Tags>
                {tags.map(tag => (
                  <div key={`tag-${tag.id}`}>
                    {isEditMode && (
                      <TagButton
                        onClick={() =>
                          this.deleteTag(tag.id, transaction.transactionId)
                        }
                      >
                        {tag.tagName}
                        <Times />
                      </TagButton>
                    )}
                    {!isEditMode && <Tag>{tag.tagName}</Tag>}
                  </div>
                ))}
                <EditTagButton
                  className="button button--link"
                  onClick={() => {
                    this.setState({
                      isEditMode: !isEditMode,
                    });
                  }}
                >
                  {isEditMode ? 'Ferdig' : 'Endre'}
                </EditTagButton>
              </Tags>
            </div>
          )}
        <form action="javascript:void(0);" onSubmit={this.onFormSubmit}>
          <InputField
            label="Tagg"
            transformableLabel={false}
            placeholder="Legg til ny tagg"
            meta={{}}
            input={{
              value: this.state.tagInputValue,
              onChange: e =>
                this.setState({
                  tagInputValue: e.target.value,
                }),
              name: 'tag',
            }}
          />
        </form>
      </TransactionTagsStyle>
    );
  }
}

TransactionTags.propTypes = {
  transaction: PropTypes.shape({
    transactionId: PropTypes.number.isRequired,
  }).isRequired,
  visible: PropTypes.bool.isRequired,
};
