import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DetailSectionListItem from 'digitalbank-detail-section/lib/components/DetailSection/DetailSectionListItem';
import DetailSectionList from 'digitalbank-detail-section/lib/components/DetailSection/DetailSectionList';
import { formatNumber, formatAccountNumber } from 'utils/format.util';

const getTransactionType = (direction) => {
  if (direction === 'INN') return 'Inn på konto';
  if (direction === 'OUT') return 'Ut av konto';
  return undefined;
};

const SearchFilterDetails = ({ values }) => {
  const transactionDirection = getTransactionType(values.transactionDirection);
  return (
    <DetailSectionList className="search-filter-details">
      {values.inputValue && (
        <DetailSectionListItem label="Søk" value={values.inputValue} />
      )}
      {values.account && (
        <DetailSectionListItem
          label={values.account.accountName}
          value={` ${formatAccountNumber(
            values.account.accountNumber,
          )} - Disponibelt: ${formatNumber(values.account.availableAmount)}`}
        />
      )}
      {transactionDirection && (
        <DetailSectionListItem
          label="Transaksjoner"
          value={transactionDirection}
        />
      )}
      {values.fromAmount &&
        values.toAmount && (
          <DetailSectionListItem
            label="Beløp"
            value={`${formatNumber(values.fromAmount)} - ${formatNumber(
              values.toAmount,
            )}`}
          />
        )}
      {values.fromDate &&
        values.toDate && (
          <DetailSectionListItem
            label="Tidsperiode"
            value={`Fra ${moment(values.fromDate, 'DD.MM.YYYY').format(
              'DD.MM.YY',
            )} til ${moment(values.toDate, 'DD.MM.YYYY').format('DD.MM.YY')}`}
          />
        )}
    </DetailSectionList>
  );
};

SearchFilterDetails.propTypes = {
  values: PropTypes.shape({}).isRequired,
};
export default SearchFilterDetails;
