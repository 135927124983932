/* eslint-disable no-mixed-operators, no-undef */

import React from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap } from 'react-google-maps';
import HeatmapLayer from 'react-google-maps/lib/components/visualization/HeatmapLayer';
import googleMapStyles from '../utils/googleMapStyles';

const Map = withGoogleMap(({ data, center }) => (
  <GoogleMap
    zoom={6}
    center={center}
    clickableIcons={false}
    options={{
      disableDefaultUI: true,
      styles: googleMapStyles.silver,
    }}
  >
    <HeatmapLayer
      data={data}
      options={{
        radius: 20,
      }}
    />
  </GoogleMap>
));

Map.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  center: PropTypes.shape({}).isRequired,
};

const Heatmap = ({ data }) => {
  const bound = new google.maps.LatLngBounds();
  data.forEach(x => bound.extend(x.location));
  const center = bound.getCenter();
  return (
    <Map
      data={data}
      center={center}
      containerElement={<div style={{ height: '500px' }} />}
      mapElement={<div style={{ height: '100%' }} />}
    />
  );
};

Heatmap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Heatmap;
