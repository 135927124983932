import React from 'react';
import { ReceiptPage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import styled from 'styled-components';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

const BankIDOrderReceiptStyled = styled.div`
  .nav-list {
    ${horizontalLayoutSpacing};
    padding-top: ${rem(20)};
    margin-bottom: ${rem(20)};
  }
`;
const BankIDOrderReceipt = () => (
  <BankIDOrderReceiptStyled>
    <ReceiptPage
      className="p-bankid-mobile-order-receipt"
      receiptType="success"
      receiptTitle="BankID er bestilt"
      receiptDescription={
        <p>
          Du vil straks motta en e-post til{' '}
          <b>karianne.lie.johannesen@gmail.com</b> med midlertidig passord.
          BankID aktiveres ved første gangs pålogging. Du benytter den vanlige
          kodebrikken fra Sparebanken Vest.
        </p>
      }
    >
      <NavList
        items={[
          <NavListLink
            component={Link}
            to={PATHS.SETTINGS}
            title={{ text: 'Innstillinger' }}
            key="innstillinger"
          />,
        ]}
      />
    </ReceiptPage>
  </BankIDOrderReceiptStyled>
);

BankIDOrderReceipt.propTypes = {};

export default BankIDOrderReceipt;
