import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropdownList from 'components/DropdownList';
import onClickOutside from 'react-onclickoutside';

class ShareDropdown extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleClickOutside() {
    if (this.props.isVisible) {
      this.props.toggle();
    }
  }
  render() {
    return (
      <div
        className="dropdown"
        style={{ display: this.props.isVisible ? 'block' : 'none' }}
      >
        <DropdownList
          handleClickOutside={this.handleClickOutside}
          linkGroups={[
            {
              elements: [
                {
                  title: 'Lagre  som PDF',
                  path: '#',
                },
                {
                  title: 'Lagre som excel',
                  path: '#',
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}

ShareDropdown.defaultProps = {
  isVisible: false,
};

ShareDropdown.propTypes = {
  toggle: PropTypes.func.isRequired,
  isVisible: PropTypes.bool,
};

export default onClickOutside(ShareDropdown);
