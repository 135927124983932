import configureStoreProd from './configureStore.prod';
import configureStoreDev from './configureStore.dev';

const configureStore = () => {
  if (process.env.NODE_ENV === 'production') {
    return configureStoreProd;
  }
  return configureStoreDev;
};

export default configureStore();
