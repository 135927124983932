import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 21 21"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="M6.6507 13.1097664C6.56151429 13.1172971 6.47254286 13.1211701 6.38335714 13.1211701 6.13937143 13.1211701 5.90207143 13.085625 5.6697 13.0316189L.292885714 18.4306414C.0289714286 18.69525.0289714286 19.1250615.292885714 19.3897131L1.68445714 20.787332C1.94837143 21.0519836 2.37595714 21.0519836 2.63914286 20.787332L9.10135714 14.2998381 7.90375714 13.0023996 6.6507 13.1097664zM20.8005 4.46030533C20.5481571 4.20705738 20.1397714 4.20705738 19.8874714 4.46030533L16.4030571 7.95891393C16.0733571 8.2896209 15.5393143 8.2896209 15.2096143 7.95891393 14.8813714 7.62846516 14.8813714 7.09266393 15.2096143 6.76230123L18.6768 3.2816373C18.9387429 3.0183627 18.9387429 2.59156352 18.6768 2.32768648 18.4136143 2.06475615 17.9887286 2.06475615 17.7267857 2.32768648L14.2610143 5.80800615C13.9313143 6.13905738 13.3978714 6.13905738 13.0681714 5.80800615 12.7391571 5.47768648 12.7391571 4.94149795 13.0681714 4.61143648L16.5518571 1.11317213C16.8048857.859536885 16.8048857.448702869 16.5518571.194723361 16.2995143-.058954918 15.8904-.058954918 15.6373714.194723361L11.7177429 4.13063115C10.7753571 5.07748156 10.4776714 6.40654918 10.7827714 7.61589959L13.4103429 10.2544119C14.6146714 10.5596434 15.9389571 10.2606086 16.8802714 9.31453279L20.8004571 5.37884016C21.0535286 5.12520492 21.0535286 4.71394057 20.8005 4.46030533z" />
      <path d="M1.76112857,0.550432377 C1.62681429,0.41552459 1.44518571,0.34155123 1.25867143,0.34155123 C1.21898571,0.34155123 1.17908571,0.34495082 1.14012857,0.351491803 C0.9132,0.39004918 0.7185,0.536317623 0.618471429,0.744510246 C-0.529671429,3.12736475 -0.0498428571,5.97863115 1.81392857,7.85012705 L5.14825714,11.1976906 C5.47722857,11.5283975 5.92204286,11.711459 6.38335714,11.711459 C6.43268571,11.711459 6.48214286,11.7093934 6.53147143,11.7053053 L8.46737143,11.5394139 L16.9089857,20.6834078 C17.0400429,20.8255451 17.2236429,20.908082 17.4170571,20.9119119 L17.4294,20.9119119 C17.6184857,20.9119119 17.7995143,20.8369057 17.9338714,20.7029877 L19.3796143,19.2510184 C19.5132,19.1171865 19.5878571,18.9355451 19.5878571,18.7462439 C19.5878571,18.5569857 19.5132,18.3752152 19.3801714,18.2413832 L1.76112857,0.550432377 Z" />
    </g>
  </svg>
);
