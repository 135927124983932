import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRight } from 'digitalbank-icons';
import { Link } from 'react-router-dom';

const Banner = ({ path, heading, description }) => (
  <Link className="banner" to={path}>
    <div className="banner__container">
      <div>
        <div className="banner__heading">{heading}</div>
        <div className="banner__description">{description}</div>
      </div>
      <ChevronRight className="banner__chevron" />
    </div>
  </Link>
);

Banner.propTypes = {
  path: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
export default Banner;
