import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getIcon } from 'digitalbank-icons';

const PromoArticle = ({
  className, icon, heading, children,
}) => {
  let renderIcon = '';

  if (icon) {
    const Icon = getIcon(icon);
    renderIcon = (
      <div className="promo-article__icon-container">
        <Icon className="promo-article__icon" />
      </div>
    );
  }
  return (
    <article
      className={classNames(
        'promo-article',
        { [`${className}`]: className },
        { 'promo-article--with-icon': icon },
      )}
    >
      {renderIcon && renderIcon}
      <div className="promo-article__body">
        <h4 className="promo-article__heading">{heading}</h4>
        <div className="promo-article__content">{children}</div>
      </div>
    </article>
  );
};

PromoArticle.defaultProps = {
  className: '',
  icon: '',
  heading: '',
  children: undefined,
};

PromoArticle.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  heading: PropTypes.string,
  children: PropTypes.element,
};

export default PromoArticle;
