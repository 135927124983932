import React from 'react';
import PropTypes from 'prop-types';
import { isApp, isWebApp } from 'utils/detect.util';
import * as PATHS from 'routes/paths';
import {
  // KeyNumbers,
  // PromoArticle,
  Shortcuts,
  BalanceCarousel,
  Banner,
} from 'components';
import HomeOldNavList from './HomeOldNavList';

const HomeTopContent = ({ selectedAccounts, nudgeAccounts, userSettings }) => {
  const balanceCarouselAccounts = [...selectedAccounts, ...nudgeAccounts];
  return (
    <section>
      {userSettings &&
        userSettings.homePage.displayBalanceCarousel &&
        balanceCarouselAccounts &&
        balanceCarouselAccounts.length > 0 && (
          <BalanceCarousel
            accounts={balanceCarouselAccounts}
            shouldDisplayBackgroundImage={
              userSettings.balanceCarouselImage.value
            }
            isApp
            // isApp() || isWebApp()
          />
        )}
      {/* <KeyNumbers /> */}
      {userSettings &&
        userSettings.homePage.displayEfakturaFraAlleBanner &&
        !userSettings.automaticEfakturaIsActive && (
          <Banner
            path={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_INFO}
            heading="eFaktura fra alle"
            description="Motta eFaktura automatisk, uten egne avtaler for hver enkelt bedrift"
          />
        )}
      {userSettings &&
        userSettings.homePage.displayEfakturaFraAlleBanner &&
        userSettings.automaticEfakturaIsActive && (
          <Banner
            path={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_INFO}
            heading="Vinn 10 000 kr i fond!"
            description="Alle med spareavtale i fond er med i den månedlige trekningen!"
          />
        )}
      {/* <PromoArticle
        heading="Visa Gull til sommerferien"
        className="promo-article--soft"
      >
        <p>
          Med Visa Sølv får du en rekke fordeler, rabatter og en ekstra trygghet
          på reisen. <Link to="/">Les mer</Link>
        </p>
      </PromoArticle> */}
      <Shortcuts />
      {userSettings &&
        userSettings.homePage.displayOldNavList && <HomeOldNavList />}
    </section>
  );
};

HomeTopContent.propTypes = {
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  nudgeAccounts: PropTypes.arrayOf(PropTypes.shape({})),
  userSettings: PropTypes.shape({}),
};

HomeTopContent.defaultProps = {
  selectedAccounts: null,
  nudgeAccounts: null,
  userSettings: null,
};

export default HomeTopContent;
