import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import i18n from 'i18n';
import { getIcon } from 'digitalbank-icons';
import getLinks from 'services/links.service';
import * as PATH from 'routes/paths';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signOut } from 'actions/authActions';
import SidebarDropdown from './SidebarDropdown';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDropdownId: undefined,
    };
    this.updateOpenDropdownId = this.updateOpenDropdownId.bind(this);
    this.resetOpenDropdownId = this.resetOpenDropdownId.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  resetOpenDropdownId() {
    this.setState({
      openDropdownId: undefined,
    });
  }
  handleClickOutside() {
    this.resetOpenDropdownId();
  }
  updateOpenDropdownId(id) {
    if (this.state.openDropdownId === id) {
      this.setState({
        openDropdownId: undefined,
      });
    } else {
      this.setState({
        openDropdownId: id,
      });
    }
  }
  render() {
    const { toggleSidebar, currentPath, handleLogout } = this.props;
    const { openDropdownId } = this.state;
    const Tabs = [
      {
        label: i18n(t => t.LOG_OUT),
        path: PATH.LOGIN,
        onClick: () => {
          toggleSidebar();
          handleLogout();
        },
        icon: {
          name: 'lock',
          position: 'left',
        },
      },
      {
        label: i18n(t => t.ROUTE_TITLE_CONTACT),
        path: PATH.CONTACT,
        onClick: () => toggleSidebar(),
        icon: {
          name: 'comment-o',
          position: 'right',
        },
      },
    ];
    return (
      <nav aria-label="navigation" className="sidebar">
        <div className="sidebar__outer">
          <ul className="sidebar__links">
            {getLinks(this.props.notifications).map((link, i) => {
              const Icon = getIcon(link.icon);
              return (
                <li key={`sidebar-list-element-${link.title}`}>
                  {link.path &&
                    !link.subelements && (
                      <NavLink
                        to={link.path}
                        className="sidebar__link"
                        activeClassName="sidebar__link "
                        exact
                        onClick={toggleSidebar}
                      >
                        <div className="sidebar__link-content">
                          <div className="sidebar__icon-cell">
                            <Icon className="sidebar__icon" />
                          </div>
                          <span className="sidebar__label">{link.title}</span>
                        </div>
                      </NavLink>
                    )}
                  {link.subelements && (
                    <SidebarDropdown
                      link={link}
                      closeSidebar={() => {
                        toggleSidebar();
                        this.resetOpenDropdownId();
                      }}
                      currentPath={currentPath}
                      toggle={() => this.updateOpenDropdownId(i)}
                      isOpened={openDropdownId === i}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
        <nav className="sidebar__tab-bar">
          {Tabs.map((tab) => {
            const iconPosition =
              tab.icon.position === 'left' ? 'left' : 'right';
            const iconStyle = {
              padding: iconPosition === 'left' ? '0 10px 0 0' : '0 0 0 10px',
            };
            const btnStyle = {
              textAlign: iconPosition === 'left' ? 'left' : 'right',
            };
            const Icon = getIcon(tab.icon.name);
            const icon = (
              <i className="sidebar__tab-icon-cell">
                <Icon className="sidebar__tab-icon" style={iconStyle} />
              </i>
            );
            return (
              <Link
                to={tab.path}
                onClick={tab.onClick}
                className="sidebar__tab-button"
                key={`sidebar-tab-button-${tab.label}`}
                style={btnStyle}
              >
                {iconPosition === 'left' && icon}
                <span className="sidebar__tab-label">{tab.label}</span>
                {iconPosition === 'right' && icon}
              </Link>
            );
          })}
        </nav>
      </nav>
    );
  }
}

Sidebar.defaultProps = {
  currentPath: '',
  notifications: undefined,
};

Sidebar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  currentPath: PropTypes.string,
  notifications: PropTypes.shape({}),
};

const mapDispatchToProps = dispatch => ({
  handleLogout: bindActionCreators(signOut, dispatch),
});

export default connect(mapDispatchToProps)(Sidebar);
