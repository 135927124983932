import React from 'react';
import ReactHighcharts from 'react-highcharts';
import graphConfig from 'routes/Loan/loanGraf.util';
import { formatNumber } from 'utils/format.util';

const seriesStyle = {
  showInLegend: false,
  marker: {
    fillColor: '#5B8601',
  },
};

const config = {
  ...graphConfig,
  chart: {
    ...graphConfig.chart,
    height: '36%',
  },
  tooltip: {
    ...graphConfig.tooltip,
  },
  plotOptions: {
    area: {
      marker: {
        enabled: false,
        symbol: 'circle',
        radius: 2,
        states: {
          hover: {
            enabled: true,
          },
        },
      },
    },
  },
  xAxis: {
    ...graphConfig.xAxis,
    type: 'datetime',
    min: new Date('2002').valueOf(),
    max: new Date().valueOf(),
    showLastLabel: true,
    labels: {
      ...graphConfig.xAxis.labels,
      formatter() {
        if (this.isLast) {
          return 'I dag';
        }
        return this.axis.defaultLabelFormatter.call(this);
      },
    },
  },
  yAxis: {
    ...graphConfig.yAxis,
    max: 1000000,
  },
  series: [
    {
      name: 'Ramme',
      type: 'area',
      color: '#000',
      fillColor: '#E5F2DD',
      data: [
        {
          x: new Date('2002').valueOf(),
          y: 1000000,
        },
        {
          x: new Date().valueOf(),
          y: 1000000,
        },
      ],
      ...seriesStyle,
      dashStyle: 'ShortDash',
      lineColor: '#5B8601',
      lineWidth: 3,
      tooltip: {
        ...graphConfig.tooltip,
        pointFormatter() {
          return `<b>Ramme</b>: ${formatNumber(this.y)}`;
        },
      },
    },
    {
      name: 'Bruk av ramme',
      type: 'area',
      fillColor: '#A9CF90',
      step: 'left',
      data: [
        {
          x: new Date('2002').valueOf(),
          y: 800000,
          deposit: 200000,
        },
        {
          x: new Date('2004').valueOf(),
          y: 500000,
          deposit: 300000,
        },
        {
          x: new Date('2005').valueOf(),
          y: 600000,
          withdrawal: 100000,
        },
        {
          x: new Date('2009').valueOf(),
          y: 850000,
          withdrawal: 250000,
        },
        {
          x: new Date('2012').valueOf(),
          y: 500000,
          deposit: 350000,
        },
        {
          x: new Date('2013').valueOf(),
          y: 600000,
          withdrawal: 100000,
        },
        {
          x: new Date('2015').valueOf(),
          y: 750000,
          withdrawal: 150000,
        },
        {
          x: new Date('2016').valueOf(),
          y: 400000,
          deposit: 350000,
        },
        {
          x: new Date().valueOf(),
          y: 400000,
        },
      ],
      ...seriesStyle,
      lineColor: 'transparent',
      tooltip: {
        ...graphConfig.tooltip,
        pointFormatter() {
          let withdrawal = '';
          if (this.withdrawal) {
            withdrawal = `<b>Uttak:</b> ${formatNumber(this.withdrawal)} <br/>`;
          }
          let deposit = '';
          if (this.deposit) {
            deposit = `<b>Innbetaling:</b> ${formatNumber(this.deposit)} <br/>`;
          }
          return `${withdrawal} ${deposit} <b>Benyttet ramme</b>: ${formatNumber(
            this.y,
          )}`;
        },
      },
    },
  ],
};

export default () => <ReactHighcharts config={config} isPureConfig />;
