import styled from 'styled-components';
import { spacing } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-regional-blocking {
    &__heading,
    &__main,
    &__aside {
      ${horizontalLayoutSpacing};
    }

    .regional-blocking-section,
    .published-section {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }
  }
`;
