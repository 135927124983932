import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 20"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <path
      d="M11.0032727,14.8198214 L20,10 L28.9918182,14.8696429 L20.0040909,19.5785714 L11.0032727,14.8198214 L11.0032727,14.8198214 Z M20.8181818,20.9872321 L20.8181818,29.2857143 L29,24.9769643 L29,16.6792857 L20.8181818,20.9872321 L20.8181818,20.9872321 Z M19.1818182,20.9872321 L11,16.6391071 L11,24.9769643 L19.1818182,29.2857143 L19.1818182,20.9872321 Z"
      transform="translate(-11 -10)"
    />
  </svg>
);
