import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 611.998 611.998"
    fill="currentColor"
    {...props}
  >
    <path d="M611.998 327.252c-14.873-10.688-37.447-17.51-62.734-17.51-39.152 0-71.82 16.348-79.42 38.09h-3.262c-7.414-21.883-40.182-38.375-79.504-38.375-25.328 0-47.891 6.865-62.756 17.584v184.033c0 45.803-37.262 83.062-83.063 83.062-45.802 0-83.063-37.26-83.063-83.062 0-10.121 8.202-18.322 18.323-18.322s18.323 8.201 18.323 18.322c0 25.594 20.822 46.416 46.417 46.416 25.594 0 46.416-20.822 46.416-46.416V327.34c-14.869-10.725-37.443-17.598-62.783-17.598-39.153 0-71.821 16.348-79.42 38.09h-3.261c-7.415-21.883-40.183-38.375-79.504-38.375-25.271 0-47.834 6.812-62.706 17.488C11.951 196.459 134.73 92.737 287.676 85.013V71.316c0-10.122 8.202-18.323 18.323-18.323s18.323 8.202 18.323 18.323v13.699c153.053 7.741 275.889 111.615 287.676 242.237zM44.264 52.993s-26.561 52.432-26.561 67.101c0 14.668 11.892 26.561 26.561 26.561s26.561-11.892 26.561-26.561c0-14.67-26.561-67.101-26.561-67.101zm75.197-35.132S99.536 57.194 99.536 68.199s8.921 19.925 19.925 19.925 19.925-8.921 19.925-19.925-19.925-50.338-19.925-50.338z" />
  </svg>
);
