import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { ChevronUp } from 'digitalbank-icons';

class KeyNumberOptions extends Component {
  handleClickOutside() {
    // This component is always rendered even when !active
    // To prevent handleClickOutside to trigger toggle we check the active property.

    if (this.props.active) {
      this.props.toggle();
    }
  }
  render() {
    const {
      currentTitle, currentAlias, onClick, active, toggle,
    } = this.props;

    const options = [
      {
        alias: 'CREDIT',
        label: 'Kreditt',
      },
      {
        alias: 'FOUND',
        label: 'Fond',
      },
      {
        alias: 'STOCK',
        label: 'Aksjer',
      },
      {
        alias: 'LOAN',
        label: 'Mine lån',
      },
    ];
    return (
      <ul
        className={classNames('key-number__options dropdown-list', {
          'key-number__options--active': active,
        })}
      >
        <li>
          <button
            onClick={toggle}
            className="key-number__option-btn key-number__option-btn--current dropdown-list__action"
            disabled={!active}
          >
            {currentTitle}
            <ChevronUp className="key-number__option-btn-icon" />
          </button>
        </li>
        {/* // TODO Add some style to the active alias instead of hiding it. */}
        {options.filter(x => x.alias !== currentAlias).map(option => (
          <li key={`key-number-option-${option.alias}`}>
            <button
              onClick={() => onClick(option.alias)}
              className="key-number__option-btn dropdown-list__action"
              disabled={!active}
            >
              {option.label}
            </button>
          </li>
        ))}
      </ul>
    );
  }
}

KeyNumberOptions.propTypes = {
  currentTitle: PropTypes.string.isRequired,
  currentAlias: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default onClickOutside(KeyNumberOptions);
