export const activateBankID = () => ({ type: 'BANKID/ACTIVATE' });
export const activateBankIDMobile = () => ({ type: 'BANKID_MOBILE/ACTIVATE' });

export const setEfakturaOneClickStatus = status => ({
  type: 'USER_SETTINGS/VALUE_EFAKTURA_ONECLICK_STATUS/SET',
  status,
});

const createNewSavingInstance = (key, index) => ({
  type: `USER_SETTINGS/VALUE_${key}/NEW_SAVING_INSTANCE`,
  index,
});

const updateSavingInstanceStatus = (key, index, status) => ({
  type: `USER_SETTINGS/VALUE_${key}/UPDATE_SAVING_INSTANCE`,
  index,
  status,
});

export const setValue = (key, value) => ({
  type: `USER_SETTINGS/VALUE_${key}/SET`,
  value,
});

export const toggleDisplaySakTilSignering = () => ({
  type: 'USER_SETTINGS/VALUE_DISPLAY_SAK_TIL_SIGNERING/TOGGLE',
});

export const toggleBalanceCarousel = () => ({
  type: 'HOME_PAGE/TOGGLE_BALANCE_CAROUSEL',
});

export const toggleEfakturaOneClickFromHomepage = () => ({
  type: 'HOME_PAGE/TOGGLE_EFAKTURA_ONE_CLICK',
});

export const toggleActionsFromHomepage = () => ({
  type: 'HOME_PAGE/TOGGLE_ACTIONS_FROM_HOMEPAGE',
});
export const toggleOldNavListFromHomepage = () => ({
  type: 'HOME_PAGE/TOGGLE_OLD_NAVLIST_FROM_HOMEPAGE',
});

export const toggleEfakturaBannerFromHomepage = () => ({
  type: 'HOME_PAGE/TOGGLE_EFAKTURA_FRA_ALLE_BANNER',
});

export const toggleBadgeInTabbar = () => ({
  type: 'HOME_PAGE/TOGGLE_BADGE_IN_TABBAR',
});

export const updateValue = (key, value, index) => (dispatch) => {
  dispatch(setValue(key, value));

  dispatch(createNewSavingInstance(key, index));
  dispatch(() => {
    setTimeout(() => {
      dispatch(updateSavingInstanceStatus(key, index, 'SUCCESSFUL'));
    }, 800);
  });
  dispatch(() => {
    setTimeout(() => {
      dispatch(updateSavingInstanceStatus(key, index, 'FINISHED'));
    }, 2400);
  });
};
