const template = {
  GAA_TIL_FASTE_OPPDRAG: {
    'nb-NO': 'Gå til faste oppdrag',
  },
  NYTT_FAST_OPPDRAG: {
    'nb-NO': 'Nytt fast oppdrag',
  },
  NO_STANDING_ORDERS: {
    'nb-NO': 'Ingen faste oppdrag',
  },
  FASTE_OPPDRAG_ERROR_TITLE: {
    'nb-NO': 'Feil ved henting av faste oppdrag',
  },
  FASTE_OPPDRAG_ERROR_MESSAGE: {
    'nb-NO':
      'Det har oppstått en teknisk feil, og faste oppdrag kunne ikke hentes. Prøv igjen senere.',
  },
  FAST_OPPDRAG_TIL_KONTO: {
    'nb-NO': 'Til konto: {konto}',
  },
  FAST_OPPDRAG_KID: {
    'nb-NO': 'KID: {kid}',
  },
  FAST_OPPDRAG_MELDING: {
    'nb-NO': 'Melding: {melding}',
  },
  FAST_OPPDRAG_NESTE_OVERFOERING: {
    'nb-NO': '{frekvens}, neste overføring {dato}',
  },
  ENDRE_FAST_OPPDRAG_DATO_PLACEHOLDER: {
    'nb-NO': 'Neste forfall',
  },
  FAST_OPPDRAG_DATO_PLACEHOLDER: {
    'nb-NO': 'Første forfall',
  },
  FAST_OPPDRAG_NESTE_BETALING: {
    'nb-NO': '{frekvens}, neste betaling {dato}',
  },
  FREKVENS_TITTEL_OVERFOERING: {
    'nb-NO': 'Gjenta overføringen',
  },
  FREKVENS_TITTEL_BETALING: {
    'nb-NO': 'Gjenta betalingen',
  },
  FREKVENS_UKENTLIG: {
    'nb-NO': 'Hver uke',
  },
  FREKVENS_ANNENHVERUKE: {
    'nb-NO': 'Annenhver uke',
  },
  FREKVENS_MANEDLIG: {
    'nb-NO': 'Hver måned',
  },
  FREKVENS_ANNENHVERMANED: {
    'nb-NO': 'Annenhver måned',
  },
  FREKVENS_HVERTREDJEMANED: {
    'nb-NO': 'Hver tredje måned',
  },
  FREKVENS_HVERFJERDEMANED: {
    'nb-NO': 'Hver fjerde måned',
  },
  FREKVENS_HALVARLIG: {
    'nb-NO': 'Hvert halvår',
  },
  FREKVENS_ARLIG: {
    'nb-NO': 'Hvert år',
  },
  FREKVENS_UDEFINERT: {
    'nb-NO': 'Udefinert',
  },
  FREKVENS_UKJENT: {
    'nb-NO': 'Udefinert',
  },
  FAST_OVERFOERING: {
    'nb-NO': 'Fast overføring',
  },
  FAST_BETALING: {
    'nb-NO': 'Fast betaling',
  },
  SE_FASTE_OPPDRAG: {
    'nb-NO': 'Se faste oppdrag',
  },
  DAGENS_DATO_FEIL: {
    'nb-NO': 'Første forfall må være frem i tid',
  },
  ERROR_FASTE_OPPDRAG_DELETE: {
    'nb-NO': 'Kunne ikke slette det faste oppdraget.',
  },
  ERROR_GET_FAST_BETALING_TITLE: {
    'nb-NO': 'Feil ved henting av fast betaling',
  },
  ERROR_GET_FAST_BETALING_TEXT: {
    'nb-NO':
      'Kunne ikke hente fast betaling, venligst prøv igjen eller velg et annet oppdrag fra faste oppdragslisten.',
  },
  ERROR_GET_FAST_OVERFOERING_TITLE: {
    'nb-NO': 'Feil ved henting av fast overføring',
  },
  ERROR_GET_FAST_OVERFOERING_TEXT: {
    'nb-NO':
      'Kunne ikke hente fast overføring, venligst prøv igjen eller velg et annet oppdrag fra faste oppdragslisten.',
  },
  FAST_OPPDRAG_SLETTET: {
    'nb-NO': 'Det faste oppdraget ble slettet',
  },
};

export default template;
