import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BasePage } from 'digitalbank-layout';
import LoadingButton from 'components/LoadingButton';
import { toggleAutomaticEfaktura } from 'routes/Avtaleadministrasjon/avtaleadministrasjon.actions';
import { registerMessage } from 'actions/messages.actions';
import historyShape from 'shapes/history.shape';
import * as PATHS from 'routes/paths';
import EfakturaFraAlleSettingsContainer from './EfakturaFraAlleSettingsContainer';
import EfakturaFraAlleFaq from './EfakturaFraAlleFaq';
import EfakturaFraAlleSettingsStyled from './EfakturaFraAlleSettingsStyled';

const EfakturaFraAlleSetupPage = ({
  onToggleAutomaticEfaktura,
  // onRegisterMessage,
  history,
}) => (
  <EfakturaFraAlleSettingsStyled>
    <BasePage
      heading="eFaktura fra alle"
      className="p-efaktura-fra-alle-settings"
      asideContent={<EfakturaFraAlleFaq />}
    >
      <div className="p-efaktura-fra-alle-settings__description">
        <div className="short-description">
          Motta eFaktura automatisk, uten egne avtaler for hver enkelt bedrift.
        </div>
      </div>
      <EfakturaFraAlleSettingsContainer />
      <div className="p-efaktura-fra-alle-settings__submit-btn-container">
        <LoadingButton
          variant="primary"
          onClick={() => {
            onToggleAutomaticEfaktura();
            // onRegisterMessage({
            //   text: 'eFaktura fra alle er aktivert',
            //   timeout: 3500,
            // });
            history.push(PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_RECEIPT);
          }}
        >
          Aktiver efaktura fra alle
        </LoadingButton>
      </div>
    </BasePage>
  </EfakturaFraAlleSettingsStyled>
);

EfakturaFraAlleSetupPage.propTypes = {
  onToggleAutomaticEfaktura: PropTypes.func.isRequired,
  // onRegisterMessage: PropTypes.func.isRequired,
  history: historyShape.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onToggleAutomaticEfaktura: bindActionCreators(
    toggleAutomaticEfaktura,
    dispatch,
  ),
  onRegisterMessage: bindActionCreators(registerMessage, dispatch),
});

export default connect(null, mapDispatchToProps)(EfakturaFraAlleSetupPage);
