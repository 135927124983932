import styled from 'styled-components';
import { spacing, layout, breakpoints } from 'digitalbank-core-style';

export const TagHeading = styled.h2`
  font-weight: 300;
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: 0.5rem;

  @media (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

export const TagDescription = styled.p`
  margin-bottom: 0;

  @media (max-width: ${breakpoints.tablet - 1}px) {
    text-align: center;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    padding: 0 ${layout.horizontalSpacing.tablet}px;
  }
`;

const TagHeader = styled.div`
  margin-bottom: ${spacing.layoutSpacing.sm}px;
  @media (max-width: ${breakpoints.tablet - 1}px) {
    padding-top: 20px;
  }
`;

export default TagHeader;
