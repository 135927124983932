import React from 'react';
import PropTypes from 'prop-types';
import StatusIndicator from 'components/StatusIndicator';

const LegendWithSaveStatus = ({ status, text }) => (
  <div className="legend">
    <h5 aria-hidden="true" className="legend-cell">
      <div className="legend__text">
        <span className="legend__text-static">{text}</span>
        <StatusIndicator status={status} />
      </div>
    </h5>
    <legend className="legend-screen-reader">{text}</legend>
  </div>
);

LegendWithSaveStatus.defaultProps = {
  status: '',
};

LegendWithSaveStatus.propTypes = {
  text: PropTypes.string.isRequired,
  status: PropTypes.string,
};

export default LegendWithSaveStatus;
