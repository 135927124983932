import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const withRedirect = (WrappedComponent, redirectPath) => {
  class WithRedirect extends Component {
    componentWillMount() {
      if (this.props.componentShouldRedirect) {
        this.props.history.replace(redirectPath);
      }
    }
    render() {
      if (this.props.componentShouldRedirect) return null;
      return <WrappedComponent {...this.props} />;
    }
  }
  WithRedirect.displayName = `WithRedirect(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  WithRedirect.propTypes = {
    componentShouldRedirect: PropTypes.bool.isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  return withRouter(WithRedirect);
};

// function that returns another function that takes the component as parameter (hoc)
const wizardPage = ({ redirectPath, redirectTest }) => {
  const mapStateToProps = state => ({
    componentShouldRedirect: redirectTest(state),
  });
  return WrapperComponent =>
    connect(mapStateToProps)(withRedirect(WrapperComponent, redirectPath));
};

export default wizardPage;
