import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ProgressButton, STATE } from 'digitalbank-buttons';

class FakeProgressButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: '',
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick(handleClick, timeout, shouldReset) {
    this.setState({ state: STATE.LOADING });
    setTimeout(() => {
      this.setState({ state: STATE.SUCCESS });
      if (handleClick) {
        handleClick();
      }
    }, timeout);

    if (shouldReset) {
      setTimeout(() => {
        this.setState({
          state: '',
        });
      }, timeout + 1000);
    }
  }
  render() {
    const {
      timeout, onClick, shouldReset, ...other
    } = this.props;
    return (
      <ProgressButton
        state={this.state.state}
        onClick={() => this.onClick(onClick, timeout, shouldReset)}
        {...other}
      />
    );
  }
}

FakeProgressButton.defaultProps = {
  timeout: 1500,
  onClick: null,
  shouldReset: true,
};

FakeProgressButton.propTypes = {
  onClick: PropTypes.func,
  timeout: PropTypes.number,
  shouldReset: PropTypes.bool,
};

export default FakeProgressButton;
