import React from 'react';
import { ReceiptPage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import styled from 'styled-components';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

const BankIDOrderNewKodebrikkePageReceiptStyled = styled.div`
  .nav-list {
    ${horizontalLayoutSpacing};
    padding-top: ${rem(20)};
    margin-bottom: ${rem(20)};
  }
`;
const BankIDOrderNewKodebrikkePageReceipt = () => (
  <BankIDOrderNewKodebrikkePageReceiptStyled>
    <ReceiptPage
      receiptType="success"
      receiptTitle="Ny kodebrikke bestilt"
      receiptDescription={
        <p>Du vil motta kodebrikken i posten innen 3-4 virkedager.</p>
      }
    >
      <NavList
        items={[
          <NavListLink
            component={Link}
            to={PATHS.SETTINGS}
            title={{ text: 'Innstillinger' }}
            key="innstillinger"
          />,
        ]}
      />
    </ReceiptPage>
  </BankIDOrderNewKodebrikkePageReceiptStyled>
);

BankIDOrderNewKodebrikkePageReceipt.propTypes = {};

export default BankIDOrderNewKodebrikkePageReceipt;
