import React from 'react';
import PropTypes from 'prop-types';
import getLinks from 'services/links.service';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import _ from 'lodash';
import { connect } from 'react-redux';
import dataSelector from 'redux-helpers/dataSelector';
import { getNotifications } from 'utils/notifications.util';
import { BasePage } from 'digitalbank-layout';
import { HorizontalLayoutSpacing } from 'styled';

const PayNavigation = ({ transactions }) => {
  const notifications = getNotifications(transactions);

  const links = _.find(getLinks(notifications), x => x.path === PATHS.PAY)
    .subelements[0].elements;

  const items = links.map((link) => {
    const title =
      link.notification && link.notification.count > 0 ? (
        <span>
          {link.title}
          <div
            style={{
              display: 'inline-block',
              marginLeft: '10px',
            }}
          >
            <div className="badge badge--new">{link.notification.count}</div>
          </div>
        </span>
      ) : (
        link.title
      );
    return (
      <NavListLink
        key={link.title}
        component={Link}
        to={link.path}
        title={{ text: title }}
      />
    );
  });

  return (
    <BasePage>
      <HorizontalLayoutSpacing>
        <NavList items={items} />
      </HorizontalLayoutSpacing>
    </BasePage>
  );
};

const mapStateToProps = state => ({
  transactions: dataSelector(state, 'transactions'),
});

PayNavigation.propTypes = {
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
};

export default connect(mapStateToProps)(PayNavigation);
