// eslint-disable max-len

const template = {
  VALIDATION__MANDATORY_FIELD: {
    'nb-NO': 'må spesifiseres',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_FOREIGN_ADDRESS_TOO_LONG: {
    'nb-NO': 'Adressen kan ikke være lenger enn 150 tegn',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ADDRESS_CONTENT_INVALID: {
    'nb-NO': 'Adressen kan ikke inneholde spesialtegn',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ADDRESS_TOO_SHORT: {
    'nb-NO': 'Adressen kan ikke være tom',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ADDRESS_TOO_MANY_LINES: {
    'nb-NO': 'Adressen er for lang. Fjern 1 linje',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ADDRESS_TOO_MANY_LINES_PLURAL: {
    'nb-NO': 'Adressen er for lang. Fjern {numLinesOverLimit} linjer',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ADDRESS_TOO_LONG: {
    'nb-NO': 'Adressen er for lang. Fjern {numCharsOverLimit} tegn',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ADDRESS_FOREIGN_COUNTRY_CODE: {
    'nb-NO': 'Land må spesifiseres for utenlandsadresser',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ZIP: {
    'nb-NO': 'Postnummeret er ikke gyldig',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ZIP_TOO_SHORT: {
    'nb-NO': 'Postnummeret kan ikke være tomt',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_ZIP_PLACE: {
    'nb-NO': 'Postnummer/sted er ikke gyldig',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_EMAIL: {
    'nb-NO': 'Epost er ikke en gyldig epostadresse',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_SPECIFY_MAIN_EMAIL: {
    'nb-NO': 'Vennligst spesifiser epost som hovedadresse',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_PHONENUMBER_LENGTH: {
    'nb-NO': 'Telefonnummeret må bestå av 0-15 siffer',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_PHONENUMBER_DIGITS: {
    'nb-NO': 'Telefonnummeret kan bare bestå av tall',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_PHONENUMBER_DIGITS_NO: {
    'nb-NO': 'Norsk telefonnummer må bestå av 8 siffer',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_PHONENUMBER_MOBILE: {
    'nb-NO': 'Norsk mobilnummer må være 8 siffer og starte med 4 eller 9',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  VALIDATION_ERROR_COUNTRY_CODE_MISSING: {
    'nb-NO': 'Landkode må spesifiseres',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
};

export default template;
