import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import moment from 'moment';
import { formatNumber, formatAccountNumber } from 'utils/format.util';
import { getLoanTableData, getLoanData } from 'utils/loan.util';
import HistoryTable from './HistoryTable';
import AnnuityLoanNavList from './AnnuityLoanNavList';
import FlexibleLoanNavList from './FlexibleLoanNavList';
import loanData from './testLoanData';
import AnnuityLoanGraph from './AnnuityLoanGraph';
import FlexibleLoanGraph from './FlexibleLoanGraph';
import LoanDetailSection from './LoanDetailSection';
import LoanStyled from './LoanStyled';

const loanTableData = getLoanTableData(
  loanData.startDate,
  loanData.nominellRente,
  loanData.yearsOfLoan,
  loanData.amount,
);
const startDateObj = moment(loanData.startDate);
const endDateObj = moment(loanData.endDate);
const durationInYears = moment.duration(endDateObj.diff(startDateObj));

const list = [
  ['Dato', 'Å betale', 'Renter', 'Avdrag', 'Omkostninger', 'Restgjeld'],
  ...loanTableData,
];

const loanGraphData = getLoanData(
  loanData.startDate,
  loanData.nominellRente,
  loanData.yearsOfLoan,
  loanData.amount,
);
const getAside = (id) => {
  if (id === '1') {
    return <AnnuityLoanNavList />;
  }
  if (id === '2') {
    return <FlexibleLoanNavList />;
  }
  return null;
};

const Loan = ({
  match: {
    params: { id },
  },
}) => {
  const isAnnuityLoan = id === '1';
  const isFlexibleLoan = id === '2';
  const heading = isAnnuityLoan ? 'Boliglån' : 'Fleksilån';
  const loanType = isAnnuityLoan ? 'ANNUITY' : 'FLEXIBLE';
  return (
    <LoanStyled>
      <BasePage
        heading={heading}
        className="p-loan-details"
        headingIsAlwaysVisible
        asideContent={getAside(id)}
      >
        <p className="p-loan-details__heading-info">
          {formatAccountNumber(loanData.konto)}
        </p>
        <LoanDetailSection
          durationInYears={durationInYears.years()}
          loanData={loanData}
          loanGraphData={loanGraphData}
          restgjeld={loanTableData[0][5]}
          loanType={loanType}
        />
        {isFlexibleLoan && (
          <div className="p-loan-details__graph-container">
            <div className="p-loan-details__graph-header">
              <h4 className="p-loan-details__graph-header-heading">
                Ramme: {formatNumber(1000000)}
              </h4>
              <h4 className="p-loan-details__graph-header-heading">
                Disponibelt: {formatNumber(600000)}
              </h4>
            </div>
            <FlexibleLoanGraph />
          </div>
        )}
        {isAnnuityLoan && (
          <div>
            <div className="p-loan-details__graph-container">
              <div className="p-loan-details__graph-header">
                <h4 className="p-loan-details__graph-header-heading">
                  Opprinnelig lån: {formatNumber(loanData.amount)}
                </h4>
              </div>
              <AnnuityLoanGraph />
            </div>
            <div className="p-loan-details__table">
              <header className="p-loan-details__table-header">
                <h3 className="p-loan-details__table-heading">
                  Nedbetalingsplan
                </h3>
              </header>
            </div>
            <HistoryTable list={list} />
          </div>
        )}
      </BasePage>
    </LoanStyled>
  );
};

Loan.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Loan;
