import React from 'react';
import PropTypes from 'prop-types';
import { ArrowUp, ArrowDown } from 'digitalbank-icons';
import { formatAccountNumber } from 'utils/format.util';

const AccountSelectionElement = ({
  account,
  isSelected,
  toggle,
  moveUp,
  moveDown,
  isFirst,
  isLast,
}) => {
  const { accountName, visningsnavn, accountNumber } = account;
  const displayName = visningsnavn || accountName;
  return (
    <li className="account-selection__element">
      <div className="account-selection__element-input">
        <input
          type="checkbox"
          className="account-selection__element-checkbox"
          id={`account-selection-element-${account.id}`}
          name="checkboxes"
          value={accountName}
          checked={isSelected}
          onChange={toggle}
        />
        <label
          className="account-selection__element-label"
          htmlFor={`account-selection-element-${account.id}`}
        >
          <div className="account-selection__element-info">
            <p className="account-selection__element-name">{displayName}</p>
            {!account.active && (
              <p className="account-selection__element-number">
                {account.nudging.text}
              </p>
            )}
            {account.active && (
              <p className="account-selection__element-number">
                {formatAccountNumber(accountNumber)}
              </p>
            )}
          </div>
        </label>
      </div>
      {!account.active && (
        <div className="account-selection__element-actions">
          <button className="button button--simple button--small">
            {account.nudging.btnText}
          </button>
        </div>
      )}
      {account.active &&
        isSelected && (
          <div className="account-selection__element-actions">
            <button
              disabled={isFirst}
              onClick={moveUp}
              className={`account-selection__element-btn ${
                isFirst ? 'account-selection__element-btn--disabled' : ''
              }`}
            >
              <ArrowUp className="account-selection__element-btn-icon" />
            </button>
            <button
              disabled={isLast}
              onClick={moveDown}
              className={`account-selection__element-btn ${
                isLast ? 'account-selection__element-btn--disabled' : ''
              }`}
            >
              <ArrowDown className="account-selection__element-btn-icon" />
            </button>
          </div>
        )}
    </li>
  );
};

AccountSelectionElement.defaultProps = {
  isFirst: false,
  isLast: false,
};

AccountSelectionElement.propTypes = {
  account: PropTypes.shape({}).isRequired,
  isSelected: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  moveUp: PropTypes.func.isRequired,
  moveDown: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
};

export default AccountSelectionElement;
