import { formatNumber } from 'utils/format.util';
import graphConfig from 'routes/Loan/loanGraf.util';
import { getPreviousPaymentDate } from 'utils/loan.util';
import moment from 'moment';
import loanData from './testLoanData';

const seriesStyle = {
  showInLegend: false,
  marker: {
    fillColor: '#5B8601',
  },
};

export default (data, currentPeriod) => ({
  ...graphConfig,
  chart: {
    ...graphConfig.chart,
    height: '30%',
  },
  tooltip: {
    ...graphConfig.tooltip,
    pointFormatter() {
      return `<b>Restgjeld</b>: ${formatNumber(
        this.y,
      )}<br/> <b>Nedbetalt</b>: ${formatNumber(loanData.amount - this.y)}`;
    },
  },
  xAxis: {
    ...graphConfig.xAxis,
    type: 'datetime',
    min: new Date(loanData.startDate).valueOf(),
    max: new Date(loanData.endDate).valueOf(),
  },
  yAxis: {
    ...graphConfig.yAxis,
    max: loanData.amount,
    tickInterval: 10000,
  },
  plotLines: [
    {
      color: '#D4D4D4',
      width: 2,
      value: getPreviousPaymentDate(moment().format(), 15).valueOf(),
      dashStyle: 'LongDash',
    },
  ],
  series: [
    {
      data: [
        {
          x: new Date(loanData.startDate).valueOf(),
          y: loanData.amount,
        },
        ...data.slice(0, currentPeriod),
      ],
      ...seriesStyle,
      lineColor: '#5B8601',
    },
    {
      data: [...data.slice(currentPeriod + 1, data.length - 1)],
      ...seriesStyle,
      dashStyle: 'LongDash',
      lineColor: '#ABC17D',
    },
  ],
});
