/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

const DropdownListLink = ({ link, onClick }) => (
  <Link
    className="dropdown-list__action ignore-react-onclickoutside"
    onClick={onClick}
    to={link.path}
  >
    {link.title}
    {link.info && (
      <span className="dropdown-list__action-info">{link.info}</span>
    )}
    {link.notification &&
      link.notification.count > 0 && (
        <div style={{ display: 'inline-block', marginLeft: '10px' }}>
          <div className="badge badge--new">{link.notification.count}</div>
        </div>
      )}
  </Link>
);

DropdownListLink.propTypes = {
  link: PropTypes.shape({}).isRequired,
  onClick: PropTypes.func,
};

DropdownListLink.defaultProps = {
  onClick: undefined,
};

const DropdownList = ({ onActionClick, className, linkGroups }) => (
  <ul className={classNames('dropdown-list', className)}>
    {linkGroups.map((linkGroup, i) => (
      <li key={`dropdown-list-element-${i}`}>
        {linkGroup.title && (
          <h5 className="dropdown-list__heading">
            <span className="dropdown-list__heading-text">
              {linkGroup.title}
            </span>
            <span className="dropdown-list__heading-line" />
          </h5>
        )}
        <ul className="dropdown-list">
          {linkGroup.elements.map(link => (
            <li key={`dropdown-sublist-element${link.title}`}>
              <DropdownListLink link={link} onClick={onActionClick} />
            </li>
          ))}
        </ul>
      </li>
    ))}
  </ul>
);

DropdownList.propTypes = {
  linkGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onActionClick: PropTypes.func,
  className: PropTypes.string,
  handleClickOutside: PropTypes.func.isRequired, // eslint-disable-line
};

DropdownList.defaultProps = {
  className: null,
  onActionClick: undefined,
};

export default onClickOutside(DropdownList);
