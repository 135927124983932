import React from 'react';
import NavList, { NavListLink, TASK, POSITIVE } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import SettingsCard from './SettingsCard';

const SettingsCardForMobile = () => (
  <SettingsCard heading="Mobile">
    <NavList
      items={[
        <NavListLink
          key="mobilbank"
          title={{ text: 'Mobilbank' }}
          info={{
            text: 'Aktiv',
            variant: POSITIVE,
          }}
          variant={TASK}
          component={Link}
          to={PATHS.SETTINGS}
        />,
        <NavListLink
          key="sms-bank"
          title={{ text: 'SMS-bank' }}
          info={{
            text: 'Aktiv',
            variant: POSITIVE,
          }}
          variant={TASK}
          component={Link}
          to={PATHS.SETTINGS}
        />,
        <NavListLink
          key="varslinger"
          title={{ text: 'Varslinger' }}
          info={{
            text: 'Ingen',
          }}
          variant={TASK}
          component={Link}
          to={PATHS.SETTINGS}
        />,
      ]}
    />
  </SettingsCard>
);

SettingsCardForMobile.propTypes = {};

export default SettingsCardForMobile;
