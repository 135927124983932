import styled from 'styled-components';

export const clearButton = () => `
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  apperance: none;
`;

export const ButtonResetStyle = styled.button`
  ${clearButton};
`;

export const NavListLinkButtonStyled = styled(ButtonResetStyle)`
  text-align: left;
  background: white;
  display: block;
  width: 100%;
`;

export const HiddenButton = styled.button`
  position: absolute;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
`;
