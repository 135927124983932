import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 17"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <g>
      <path d="M7.5,3.4 L5.83333333,3.4 L5.83333333,1.7 C5.83333333,0.7616 6.58,0 7.5,0 L12.5,0 C13.42,0 14.1666667,0.7616 14.1666667,1.7 L14.1666667,3.4 L12.5,3.4 L12.5,2.125 C12.5,1.8904 12.3133333,1.7 12.0833333,1.7 L7.91666667,1.7 C7.68666667,1.7 7.5,1.8904 7.5,2.125 L7.5,3.4 Z" />
      <rect width="3" height="13" y="4" />
      <rect width="3" height="13" x="17" y="4" />
      <rect width="12" height="13" x="4" y="4" />
    </g>
  </svg>
);
