import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Cog } from 'digitalbank-icons';
import classNames from 'classnames';
import * as PATHS from 'routes/paths';

const BalanceCarouselArrow = ({ onClick, Icon, className }) => (
  <button
    type="button"
    data-role="none"
    onClick={onClick}
    className={className}
  >
    <Icon className="balance-carousel__nav-icon" />
  </button>
);

BalanceCarouselArrow.defaultProps = {
  onClick: undefined,
  className: '',
};

BalanceCarouselArrow.propTypes = {
  Icon: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const getBalanceCarouselItemName = (account) => {
  if (!account.active) {
    return account.carousel.name || '';
  }
  return account.visningsnavn || account.accountName;
};

const getBalanceCarouselItemValue = (account) => {
  if (!account.active) {
    return account.carousel.value || '';
  }
  return account.availableAmount.toLocaleString('nb-NO', {
    style: 'decimal',
    maximumFractionDigits: 0,
  });
};

const BalanceCarouselItem = ({ account }) => (
  <div className="balance-carousel__item">
    <div className="balance-carousel__item-content">
      <div className="balance-carousel__item-name">
        {getBalanceCarouselItemName(account)}
      </div>
      <div className="balance-carousel__item-value">
        {getBalanceCarouselItemValue(account)}
      </div>
      <div className="balance-carousel__item-info">
        {account.carousel && account.carousel.info}
      </div>
    </div>
  </div>
);

BalanceCarouselItem.propTypes = {
  account: PropTypes.shape({}).isRequired,
};

const BalanceCarousel = ({ accounts, shouldDisplayBackgroundImage, isApp }) => {
  const balanceCarouselStyle = shouldDisplayBackgroundImage
    ? {
      backgroundSize: 'cover',
      backgroundImage:
          'url("https://cdn-spv.no/static/assets/images/1.0.1/locations/bergen-min.png")',
    }
    : null;

  const settings = {
    dots: accounts.length > 1,
    arrows: accounts.length > 1,
    infinite: accounts.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <BalanceCarouselArrow Icon={ChevronLeft} />,
    nextArrow: <BalanceCarouselArrow Icon={ChevronRight} />,
  };

  return (
    <div
      className={classNames('balance-carousel', {
        'balance-carousel--without-image': !shouldDisplayBackgroundImage,
        'balance-carousel--ios-app': isApp,
      })}
      style={balanceCarouselStyle}
    >
      <Slider {...settings}>
        {accounts.map(account => (
          <div key={`balance-carousel-item-${account.id}`}>
            <BalanceCarouselItem account={account} />
          </div>
        ))}
      </Slider>
      <Link className="balance-carousel__btn" to={PATHS.HOME_SETTINGS}>
        <Cog className="balance-carousel__btn-icon" />
      </Link>
    </div>
  );
};

BalanceCarousel.defaultProps = {
  isApp: false,
};

BalanceCarousel.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  shouldDisplayBackgroundImage: PropTypes.bool.isRequired,
  isApp: PropTypes.bool,
};

export default BalanceCarousel;
