/* eslint-disable react/prefer-stateless-function */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from 'routes';
import App from 'routes/App/App';
import Init from 'containers/Init';
import { Provider } from 'react-redux';

export default class Root extends Component {
  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <Init>
          <Router>
            <App>
              <Routes />
            </App>
          </Router>
        </Init>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.shape({}).isRequired,
};
