import * as PATHS from 'routes/paths';
import i18n from 'i18n';

const getNotification = (notifications, alias) => {
  if (notifications && notifications[alias]) {
    return notifications[alias];
  }
  return undefined;
};

export const inboxLinks = () => ({
  title: i18n(t => t.ROUTE_TITLE_INBOX),
  path: PATHS.INBOX,
  icon: 'envelope-o',
  visibility: {
    navigation: false,
    sidebar: true,
  },
  subelements: [
    {
      title: '',
      elements: [
        {
          title: 'Min postkasse',
          path: PATHS.INBOX,
        },
        {
          title: 'Mine saker',
          path: PATHS.INBOX,
        },
      ],
    },
    {
      title: 'Arkiv',
      elements: [
        {
          title: 'Søke i meldingsarkiv',
          path: PATHS.INBOX,
        },
        {
          title: 'Årsoppgaver',
          path: PATHS.INBOX,
        },
        {
          title: 'Kontoutskrift?',
          path: PATHS.INBOX,
        },
      ],
    },
  ],
});

export const settingsLinks = () => ({
  title: i18n(t => t.ROUTE_TITLE_SETTINGS),
  path: PATHS.SETTINGS,
  icon: 'cog-o',
  visibility: {
    navigation: false,
    sidebar: true,
  },
  subelements: [
    {
      title: '',
      elements: [
        {
          title: i18n(t => t.MY_INFORMATION),
          path: PATHS.SETTINGS,
        },
        {
          title: 'Kontaktinformasjon',
          path: PATHS.CONTACT_INFO,
        },
        {
          title: 'Min familie',
          path: PATHS.HOME,
        },
      ],
    },
    {
      title: 'Sikkerhet',
      elements: [
        {
          title: 'BankID',
          path: PATHS.BANKID,
        },
        {
          title: 'BankID for ungdom',
          path: PATHS.BANKID,
        },
        {
          title: 'BankID på mobil',
          path: PATHS.BANKID_MOBILE,
        },
        {
          title: 'Alternativ innlogging',
          path: PATHS.HOME,
        },
      ],
    },
    {
      title: 'Mobil',
      elements: [
        {
          title: 'Mobilbank',
          path: PATHS.HOME,
        },
        {
          title: 'SMS-bank',
          path: PATHS.HOME,
        },
      ],
    },
    {
      title: 'Innstillinger',
      elements: [
        {
          title: 'Kontoinnstillinger',
          path: PATHS.HOME,
        },
        {
          title: 'eFaktura med ett klikk',
          path: PATHS.HOME,
        },
        {
          title: 'Varslinger',
          path: PATHS.HOME,
        },
        {
          title: 'Samtykke',
          path: PATHS.DATA_CONSENT,
        },
      ],
    },
  ],
});

export default notifications => [
  {
    title: i18n(t => t.ROUTE_TITLE_HOME),
    icon: 'home',
    path: PATHS.HOME,
    visibility: {
      navigation: true,
      sidebar: true,
    },
  },
  {
    title: i18n(t => t.ROUTE_TITLE_PAY),
    icon: 'exchange',
    path: PATHS.PAY,
    notification: getNotification(notifications, 'scheduledPayments'),
    visibility: {
      navigation: true,
      sidebar: true,
    },
    subelements: [
      {
        title: '',
        elements: [
          {
            title: i18n(t => t.ROUTE_TITLE_PAY),
            info: 'regning',
            path: PATHS.PAY,
          },
          {
            title: i18n(t => t.ROUTE_TITLE_TRANSFER),
            info: 'mellom kontoer',
            path: PATHS.TRANSFER,
          },
          {
            title: i18n(t => t.ROUTE_TITLE_PAYMENT_GLOBAL),
            path: PATHS.PAYMENT_GLOBAL,
          },
          {
            title: i18n(t => t.ROUTE_TITLE_FASTE_OPPDRAG),
            info: '',
            path: PATHS.FASTE_OPPDRAG,
          },
          {
            title: i18n(t => t.ROUTE_TITLE_SCHEDULED_PAYMENTS),
            path: PATHS.SCHEDULED_PAYMENTS,
            // notification: getNotification(notifications, 'scheduledPayments'),
          },
          {
            title: i18n(t => t.ROUTE_TITLE_UTFOERTE_BETALINGER),
            path: PATHS.SEARCH,
          },
          {
            title: i18n(t => t.ROUTE_TITLE_PAYMENT_AGREEMENTS),
            path: PATHS.EFAKTURA_AND_AVTALEGIRO,
          },
          {
            title: i18n(t => t.ROUTE_TITLE_RECIPIENTS),
            path: PATHS.RECIPIENTS,
          },
        ],
      },
    ],
  },
  {
    title: i18n(t => t.ROUTE_TITLE_ACCOUNT),
    path: PATHS.ACCOUNT,
    icon: 'book',
    visibility: {
      navigation: true,
      sidebar: true,
    },
  },
  {
    title: i18n(t => t.ROUTE_TITLE_CARD),
    path: PATHS.CARD,
    icon: 'credit-card-o',
    visibility: {
      navigation: true,
      sidebar: true,
    },
  },
  {
    title: i18n(t => t.ROUTE_TITLE_LOAN),
    icon: 'handing',
    visibility: {
      navigation: true,
      sidebar: true,
    },
    subelements: [
      {
        title: '',
        elements: [
          {
            title: 'Mine lån',
            path: PATHS.LOANS,
          },
        ],
      },
      {
        title: 'Lån',
        elements: [
          {
            title: 'Nytt boliglån',
            path: PATHS.LOANS,
          },
          {
            title: 'Øke boliglån',
            path: PATHS.LOANS,
          },
          {
            title: 'Forbrukslån',
            path: PATHS.LOANS,
          },
          {
            title: 'Billån',
            path: PATHS.LOANS,
          },
          {
            title: 'Båtlån',
            path: PATHS.LOANS,
          },
        ],
      },
    ],
  },
  {
    title: i18n(t => t.ROUTE_TITLE_SAVING),
    path: PATHS.SAVING,
    icon: 'line-chart',
    visibility: {
      navigation: true,
      sidebar: true,
    },
    subelements: [
      {
        title: '',
        elements: [
          {
            title: 'Min sparing',
            path: PATHS.SAVING,
          },
          {
            title: 'Spareavtale i bank',
            path: PATHS.SAVING,
          },
        ],
      },
      {
        title: 'Fond',
        elements: [
          {
            title: 'Våre fond',
            path: PATHS.SAVING,
          },
          {
            title: 'Spareavtale i fond',
            path: PATHS.SAVING,
          },
          {
            title: 'Fond i gave',
            path: PATHS.SAVING,
          },
          {
            title: 'Fondshandel',
            path: PATHS.SAVING,
          },
          {
            title: 'Opprett fondshandelskonto',
            path: PATHS.SAVING,
          },
        ],
      },
      {
        title: 'Aksjehandel',
        elements: [
          {
            title: 'Aksjehandel',
            path: PATHS.SAVING,
          },
          {
            title: 'VPS Investorer',
            path: PATHS.SAVING,
          },
        ],
      },
      {
        title: 'Pensjon',
        elements: [
          {
            title: 'Pensjon',
            path: PATHS.SAVING,
          },
          {
            title: 'Min innskuddspensjon',
            path: PATHS.SAVING,
          },
        ],
      },
    ],
  },
  {
    title: i18n(t => t.ROUTE_TITLE_ESURANCE),
    path: PATHS.ESURANCE,
    icon: 'umbrella',
    visibility: {
      navigation: true,
      sidebar: true,
    },
  },
  { ...inboxLinks() },
  { ...settingsLinks() },
  {
    title: 'Demo',
    path: '/test',
    icon: 'user',
    visibility: {
      navigation: false,
      sidebar: true,
    },
  },
];
