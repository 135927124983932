import * as firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAzPgGH7VAS569MQ1M7UYrG6kE0H2AJmIE',
  authDomain: 'digitalbank.firebaseapp.com',
  databaseURL: 'https://digitalbank.firebaseio.com',
  storageBucket: 'firebase-digitalbank.appspot.com',
};

class FirebaseApi {
  static initAuth() {
    firebase.initializeApp(config);
    return new Promise((resolve, reject) => {
      const unsub = firebase.auth().onAuthStateChanged(
        (user) => {
          unsub();
          resolve(user);
        },
        error => reject(error),
      );
    });
  }

  static createUserWithEmailAndPassword(user) {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user.password);
  }

  static signInWithEmailAndPassword(user) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user.password);
  }

  static authSignOut() {
    return firebase.auth().signOut();
  }

  static databasePush(path, value) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(path)
        .push(value, (error) => {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        });
    });
  }

  static getValueByKeyOnce(path, key) {
    return firebase
      .database()
      .ref(path)
      .orderByKey()
      .equalTo(key)
      .once('value');
  }

  static getChildAddedByKeyOnce(path, key) {
    return firebase
      .database()
      .ref(path)
      .orderByKey()
      .equalTo(key)
      .once('child_added');
  }

  static databaseSet(path, value) {
    return firebase
      .database()
      .ref(path)
      .set(value);
  }
}

export default FirebaseApi;
