import styled from 'styled-components';
import { spacing, colors } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-loan-details {
    &__main,
    &__aside,
    &__footer {
      ${horizontalLayoutSpacing};
    }

    &__heading {
      ${horizontalLayoutSpacing};
      margin: 0 0 0.2em;
    }

    &__heading-info {
      font-size: ${rem(14)};
      color: ${colors.boulder};
    }

    &__graph-header {
      display: flex;
      justify-content: space-between;
      margin: 0 10px;

      &-heading {
        color: ${colors.boulder};
      }
    }

    &__detail-container {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__graph-container {
      ${horizontalLayoutSpacing({ phone: false })};
      padding-top: ${rem(20)};
      padding-bottom: ${rem(20)};
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
      background-color: #fff;
    }

    &__nedbetalingsplan {
      .table {
        min-width: 480px;
      }
    }

    &__table {
      &-header {
        padding: ${rem(10)} 0;

        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-heading {
        margin-bottom: 0;
      }
    }

    &__avtaler {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};

      &-avtale {
        margin-bottom: 0;
      }
    }

    &__gebyr-advarsel {
      color: ${colors.boulder};
      font-style: italic;
    }

    &__rentesatser {
      margin-bottom: ${rem(spacing.layoutSpacing.sm)};
    }

    &__footer {
      margin-top: ${rem(spacing.layoutSpacing.sm)};
      margin-right: ${rem(spacing.layoutSpacing.sm)};
    }

    .detail-section {
      &__list {
        margin-bottom: 20px;
      }

      &--no-margin {
        .detail-section__list {
          margin-bottom: 0;
        }
      }
    }

    .react-css-collapse-transition {
      transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .inline-tooltip {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      height: ${rem(17)};
      width: ${rem(27)};
      padding-left: ${rem(10)};

      &--content {
        position: absolute;
        width: ${rem(165)};
        transform: translateX(-50%);

        .detail-section__list {
          margin-bottom: 0;
        }

        .tooltip__content {
          &::after {
            /* flytter pil til center av tooltip */
            right: ${rem(62)};
          }
        }
      }
    }
  }
`;
