import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ActionList, {
  EditButton,
  DeleteButton,
  ActionLink,
} from 'digitalbank-action-list';
import i18n from 'i18n';
import * as PATHS from 'routes/paths';
import { deleteTag } from 'services/tag.service';
import { Paper } from 'digitalbank-icons';

const TagActionList = ({
  // isEditing,
  isDeletePending,
  history,
  tag,
  toggleEditMode,
  setDeletePending,
}) => (
  <ActionList
    actions={[
      <EditButton
        key="edit"
        onClick={toggleEditMode}
        value="Endre detaljer og varslinger"
      />,
      <ActionLink
        key="generate"
        RouterLink={Link}
        href={`${PATHS.TRAVEL_SUMMARY}/${tag.id}`}
        title={tag.isTravelTag ? 'Lag reiseregning' : 'Lag totaloversikt'}
        Icon={Paper}
      />,
      <DeleteButton
        key="delete"
        value="Slett"
        i18nTranslate={(template, format) => i18n(template, format)}
        isDeletePending={isDeletePending}
        onDeleteConfirmed={() => {
          deleteTag(tag.id).then(() => {
            history.push(PATHS.TAGS);
          });
          setDeletePending();
        }}
        onDeleteErrorClose={() =>
          /* eslint-disable-next-line */
          console.log('onDeleteErrorClose click')
        } // eslint-disable-line
        showDeleteError={false}
        deleteError="Error"
      />,
    ]}
    orientation="VERTICAL"
    // hasIndentDesktop
    // hasIndentMobile
  />
);

TagActionList.defaultProps = {
  // isEditing: false,
  isDeletePending: false,
};

TagActionList.propTypes = {
  // isEditing: PropTypes.bool,
  isDeletePending: PropTypes.bool,
  toggleEditMode: PropTypes.func.isRequired,
  setDeletePending: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  tag: PropTypes.shape({}).isRequired,
};

export default TagActionList;
