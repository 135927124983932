// eslint-disable max-len

const template = {
  KONTAKTINFORMASJON_UNDERAGE_ALERT_TITLE: {
    'nb-NO': 'Kan ikke endres',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_UNDERAGE_ALERT_TEXT: {
    'nb-NO': 'Du må være over 18 år for å endre kontaktinformasjon.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ROUTE_LEAVE_TEXT: {
    'nb-NO': 'Du har ulagrede endringer. Er du sikker?',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ROUTE_LEAVE_TEXT_CONFIRM: {
    'nb-NO': 'Forlat siden',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ROUTE_LEAVE_TEXT_CANCEL: {
    'nb-NO': 'Bli værende på siden',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_HEADING: {
    'nb-NO': 'Har vi riktige opplysninger om deg',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_SHORT_DESCRIPTION: {
    'nb-NO': 'Kontroller at følgende opplysninger er korrekte',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_CARD_HEADING: {
    'nb-NO': 'Kontaktinformasjon',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_LAND: {
    'nb-NO': 'Land',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_VELG_LAND: {
    'nb-NO': 'Velg land',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_VELG_LAND_LANDKODE_ARIA: {
    'nb-NO':
      'Velg land i nedtrekksliste for å sette riktig landkode for telefonnummeret til høyre',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_QUERY_ADRESSE_NORGE: {
    'nb-NO': 'Adresse i Norge?',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_QUERY_ADRESSE_UTLAND: {
    'nb-NO': 'Adresse i utlandet?',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ADRESSE: {
    'nb-NO': 'Adresse',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_POSTADRESSE: {
    'nb-NO': 'Postadresse',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_POSTADRESSE_UTLAND: {
    'nb-NO': 'Postadresse utland',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_POSTADRESSE_UTLAND_HELP_TEXT: {
    'nb-NO':
      'Utlandsadresser krever manuell behandling og det vil derfor ta' +
      ' 1-2 virkedager før endringen er lagret.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ADRESSELINJE2: {
    'nb-NO': 'Adresselinje 2',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_POSTNUMMER: {
    'nb-NO': 'Postnummer',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_TELEFON: {
    'nb-NO': 'Telefon',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_MOBILTELEFON: {
    'nb-NO': 'Mobiltelefon',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ANNENTELEFON: {
    'nb-NO': 'Annen telefon',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ANNENTELEFON_SHORT: {
    'nb-NO': 'Annen tlf',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_EPOST: {
    'nb-NO': 'E-post',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_EPOST_PRIVAT: {
    'nb-NO': 'E-post privat',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_EPOST_ARBEID: {
    'nb-NO': 'E-post arbeid',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ADD_ANNENTELEFON: {
    'nb-NO': 'Legg til telefonnummer',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ADD_EPOST_ARBEID: {
    'nb-NO': 'Legg til e-post arbeid',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_EDIT_BUTTON: {
    'nb-NO': 'Endre kontaktinformasjon',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_CONFIRM_BUTTON: {
    'nb-NO': 'Opplysningene er riktige',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_BOTTOM_INFO: {
    'nb-NO': 'Du kan alltid endre innstillingene dine senere ved å gå til',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_INNSTILLINGER: {
    'nb-NO': 'Innstillinger',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_KONTAKTINFORMASJON: {
    'nb-NO': 'Kontaktinformasjon',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_TOOLTIP: {
    'nb-NO':
      'Adressen er den sist lagrede adressen, enten fra folkeregistreret eller endringer du har gjort selv i nettbanken.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_TOOLTIP_ADDRESS_ARIA: {
    'nb-NO': 'Trykk for å vise mer informasjon om adressefeltet.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_SAVE: {
    'nb-NO': 'LAGRE',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_SAVE_ERRORS_ARIA: {
    'nb-NO':
      'Skjemaet inneholder feil. Vennligst rett opp i disse og prøv igjen.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_FETCH_ERROR_TITLE: {
    'nb-NO': 'Feil ved henting av data',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_SAVE_ARIA: {
    'nb-NO':
      'Våre kontaktopplysninger om deg vil bli oppdatert med informasjonen i skjemaet. Ved flytting til eller fra utlandet vil operasjonen kreve manuell behandling og kan derfor ta opptil 2 virkedager.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_UPDATE_ERROR_TITLE: {
    'nb-NO': 'Vi klarte ikke å lagre endringene dine. Prøv igjen senere.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_ERROR_MESSAGE: {
    'nb-NO':
      'Det er dessverre ikke mulig å benytte denne tjenesten akkurat nå. Prøv igjen litt senere.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_DATA_MISSING: {
    'nb-NO': 'Ikke registrert ',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_UPDATE_SUCCESSFUL: {
    'nb-NO': 'Endringene ble lagret ',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_UPDATE_SUCCESSFUL_BATCH: {
    'nb-NO': 'Endringene ble lagret. Endringene er synlig neste virkedag ',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_PERIODIC_COULD_NOT_SAVE_CHANGES: {
    'nb-NO': 'Vi klarte ikke å lagre opplysningene dine.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_PERIODIC_YOU_WILL_BE_ASKED_AGAIN: {
    'nb-NO': 'Du vil bli spurt igjen neste gang du logger inn.',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
  KONTAKTINFORMASJON_PERIODIC_NEXT: {
    'nb-NO': 'Gå videre',
    'nn-NO': 'unknown',
    'en-GB': 'unknown',
  },
};

export default template;
