import React from 'react';
import { BasePage } from 'digitalbank-layout';
import SearchFormContainer from './SearchFormContainer';
import SearchStyled from './SearchStyled';

const Payment = () => (
  <SearchStyled>
    <BasePage
      heading="Historikk"
      className="p-search"
      asideContent={
        <section className="published-content">
          <div className="published-content__item published-content__frame">
            <h2>Søketips</h2>
            <p>
              Som søketekst kan du f.eks. bruke mottakers navn, type betaling,
              beløp eller dato.
            </p>
            <h2>Filter</h2>
            <p>
              Her kan du tilpasse listen til å vise f.eks. kun kjøp, eller
              betalinger til en spesifikk mottaker.
            </p>
          </div>
        </section>
      }
    >
      <SearchFormContainer />
    </BasePage>
  </SearchStyled>
);

export default Payment;
