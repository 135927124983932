const Cards = [
  {
    id: 1,
    name: 'Bankkort',
    accountId: '1',
  },
  {
    id: 2,
    name: 'Kredittkort',
    accountId: '5',
  },
];

export default Cards;
