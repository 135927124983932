import styled from 'styled-components';
import { breakpoints } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-recipients {
    &__heading,
    &__aside {
      ${horizontalLayoutSpacing};
    }

    &__main {
      ${horizontalLayoutSpacing({ phone: false })};
    }

    &__aside {
      margin-top: ${rem(24)};

      @media (min-width: ${breakpoints.tablet}px) {
        /* // Align with table on desktop */
        margin-top: ${rem(75)};
      }
    }

    &__footer {
      &-content {
        @media (min-width: ${breakpoints.tablet}px) {
          width: 70%;
        }
      }

      .published-content {
        @include spacing(margin, (left, right));

        &:first-of-type {
          @include spacing(margin, (top));
        }
      }
    }

    &__navigation {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 ${rem(10)} ${rem(10)};

      @media (min-width: ${breakpoints.tablet}px) {
        flex-direction: row;
        justify-content: space-between;
        margin: 0 0 ${rem(30)};
      }
    }

    &__search-input {
      @media (max-width: ${breakpoints.tablet + 1}px) {
        width: 100%;
      }
      @media (min-width: ${breakpoints.tablet}px) {
        width: 300px;
        max-width: 300px;
        margin-left: 20px;
      }
    }

    &__btn-group {
      justify-content: center;
      @media (max-width: ${breakpoints.tablet + 1}px) {
        margin: 10px 0;
      }

      .btn-group {
        @media (min-width: ${breakpoints.tablet}px) {
          font-size: ${rem(16)};
        }
      }

      .btn-group__item {
        padding: 8px 45px;
      }
    }

    .p-recipients__list--small {
      .recipient__category {
        &:last-child {
          .recipient-list__option {
            &:last-child {
              border-bottom: 1px solid $silver;
            }
          }
        }
      }
    }

    &__list {
      &--small {
        @media (min-width: ${breakpoints.tablet}px) {
          display: none;
        }
      }

      &--large {
        @media (max-width: ${breakpoints.tablet - 1}px) {
          display: none;
        }

        .recipient-table__row {
          &:first-child {
            box-shadow: 0 -1px 0 0 $alto;
          }
        }
      }
    }

    .recipient-dropdown {
      @media (min-width: ${breakpoints.tablet}px) {
        display: none;
      }
    }

    .recipient-table {
      display: none;
      @media (min-width: ${breakpoints.tablet}px) {
        display: block;
      }
    }
  }
`;
