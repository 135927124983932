import isString from 'lodash/isString';
import moment from 'moment';
import {
  isAccountNumberCreditCard,
  isAccountNumberInternational,
} from './account.util';

const formatAccountNumber = (number) => {
  if (!number) return '';
  if (isAccountNumberCreditCard(number)) {
    return `${number.slice(0, 4)} ${number.slice(4, 8)} ${number.slice(
      8,
      12,
    )} ${number.slice(12)}`;
  }
  if (isAccountNumberInternational(number)) {
    return number.match(new RegExp('.{1,4}', 'g')).join(' ');
  }
  return `${number.slice(0, 4)}.${number.slice(4, 6)}.${number.slice(6)}`;
};

export const isValidBeloep = (beloep) => {
  if (
    typeof beloep === 'number' &&
    beloep > 0 &&
    !beloep.toString().includes('e') // We don't want crazy big numbers that will mess up formatting
  ) {
    const values = beloep.toString().split('.');
    if (values.length === 1) {
      return true; // Integer number
    }
    if (values.length === 2) {
      const oere = values[1];
      return oere.length <= 2;
    }
  }

  return false;
};
export const getKronerFromBeloep = (beloep) => {
  if (isValidBeloep(beloep)) {
    return beloep.toString().split('.')[0];
  }
  return '';
};

export const getOereFromBeloep = (beloep) => {
  if (isValidBeloep(beloep)) {
    const values = beloep.toString().split('.');
    if (values.length === 2) {
      const oere = values[1];
      if (oere.length === 1) {
        return `${values[1]}0`;
      }
      if (oere.length === 2) {
        return oere;
      }
    }
  }
  return '';
};

export const stripWhitespace = (number) => {
  if (!isString(number)) {
    throw new Error('Provided number must be a string');
  }
  return number.replace(/\s/g, '');
};

export const ensureNumberIsWithoutWhitespace = (number) => {
  const numberString = isString(number) ? number : number.toString();
  const numberWithoutWhitespace = stripWhitespace(numberString);
  if (Number.isNaN(Number(numberWithoutWhitespace))) {
    return null;
  }
  return numberWithoutWhitespace;
};

export const getAmountAsNumber = (amount) => {
  const amountWithoutWhitespace = ensureNumberIsWithoutWhitespace(amount);
  return amountWithoutWhitespace === null
    ? amount
    : Number(amountWithoutWhitespace, 10);
};

export const prependLeadingZero = (number) => {
  const numberString = number.toString();
  if (numberString.length > 1) {
    return numberString;
  }
  return number > 9 ? numberString : `0${number}`;
};

export const concatKronerAndOere = (kroner, oere) => {
  const kronerNumber = getAmountAsNumber(kroner);

  if (oere === '0') {
    return kronerNumber;
  }

  const oereFormatted = prependLeadingZero(oere);

  const amount = `${kronerNumber}.${oereFormatted}`;
  return getAmountAsNumber(amount);
};

export const constructSimpleDate = (date) => {
  const dateFormatted = moment(date).locale('nb-NO');
  const isCurrentYear = dateFormatted.year() === moment().year();
  const dateDag = dateFormatted.format('DD');
  const dateMaaned = dateFormatted.format('MMM').replace('.', '');
  const dateAar = !isCurrentYear ? dateFormatted.format('YYYY') : '';
  return `${dateDag}. ${dateMaaned} ${dateAar}`;
};

export const formatNumber = (number, decimals = 2) => {
  if (!number) return '';
  let n = number;
  if (typeof number === 'string') {
    n = Number(number);
  }
  return n.toLocaleString('nb-NO', {
    style: 'decimal',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
};

export { formatAccountNumber };
export default formatAccountNumber;
