/* eslint-disable no-alert,  no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { BasePage } from 'digitalbank-layout';
import DetailSection from 'digitalbank-detail-section';
import Button from 'digitalbank-buttons';
import { Modal, ModalCard } from 'digitalbank-modal';
// import postalCodes from 'norway-postal-codes';
import Alert from 'digitalbank-alert';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import LoadingButton from 'components/LoadingButton';
import historyShape from 'shapes/history.shape';
import { activateBankID } from 'actions/userSettings.actions';
import { InputField } from 'digitalbank-form';
import BankIDOrderFormContainer from './BankIDOrderFormContainer';
import BankIDOrderWithoutKodebrikkeArticle from './BankIDOrderWithoutKodebrikkeArticle';
import BankIDOrderPageStyled from './BankIDOrderPageStyled';

class BankIDOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      epost: 'karianne.lie.johansen@yahoo.com',
    };
  }
  render() {
    const { isModalVisible } = this.state;
    const {
      // adresse,
      // postnummer,
      epost,
      phone,
      phoneCountryCode,
      isIdentifiedWithKodebrikke,
      hasReauth,
    } = this.props;
    // const location = postalCodes[postnummer];

    return (
      <BankIDOrderPageStyled>
        <BasePage
          heading="Bestill BankID"
          className="p-bankid-order"
          headingHasLargeMargin={!isIdentifiedWithKodebrikke}
        >
          {!isIdentifiedWithKodebrikke &&
            !hasReauth && <BankIDOrderWithoutKodebrikkeArticle />}
          <p className="p-bankid-order__description">
            BankID er en felles standard for elektronisk identifisering som kan
            benyttes i alle norske banker, og mange andre steder.
          </p>
          {!isIdentifiedWithKodebrikke &&
            hasReauth && (
              <Alert type="info" small className="p-bankid-order__info-alert">
                <div>
                  <p style={{ margin: '0' }}>
                    <b>
                      Bestillingen krever at du identifiserer deg med kodebrikke
                      utstedt av oss.
                    </b>
                  </p>
                  <p>
                    Mangler du kodebrikke fra Sparebanken Vest?{' '}
                    <Link to={PATHS.BANKID_NEW_KODEBRIKKE_ORDER}>
                      Bestill her
                    </Link>.
                  </p>
                </div>
              </Alert>
            )}
          {(isIdentifiedWithKodebrikke || hasReauth) && (
            <section>
              <h4>
                BankID brukes sammen med kodebrikken din fra Sparebanken Vest
              </h4>
              <p>
                Midlertidig BankID-passord og informasjon om BankID sendes på
                e-post:
              </p>
              <div className="p-bankid-order__form">
                <InputField
                  name="epost"
                  label="E-post"
                  meta={{}}
                  input={{
                    value: this.state.epost,
                    onChange: e => this.setState({ epost: e.target.value }),
                  }}
                  transformableLabel={false}
                  placeholder="Skriv e-postadresse"
                />
              </div>
              <section className="p-bankid-order__confirmation">
                <h4>Ved å gå videre bekrefter jeg at:</h4>
                <ul className="p-bankid-order__confirmation-list">
                  <li>
                    Jeg forstår at BankID bekrefter min identitet på linje med
                    min håndskrevne signatur
                  </li>
                  <li>
                    Jeg forstår at passord eller kodebrikke aldri må deles med
                    andre
                  </li>
                  <li>
                    Jeg er gjort kjent med{' '}
                    <a href="/">Avtalevilkårene for PersonBankID</a>
                  </li>
                </ul>
              </section>
              <div className="p-bankid-order__actions">
                <LoadingButton
                  primary
                  onClick={() => {
                    this.props.onActivateBankID();
                    this.props.history.push(PATHS.BANKID_ORDER_RECEIPT);
                  }}
                  className="p-bankid-order__submit-btn"
                >
                  Bekreft og bestill
                </LoadingButton>
              </div>
            </section>
          )}
        </BasePage>
      </BankIDOrderPageStyled>
    );
  }
}

BankIDOrderPage.defaultProps = {
  // adresse: '',
  // postnummer: '',
  phone: '',
  phoneCountryCode: '',
  epost: '',
  hasReauth: false,
  isIdentifiedWithKodebrikke: false,
};

BankIDOrderPage.propTypes = {
  isIdentifiedWithKodebrikke: PropTypes.bool,
  // adresse: PropTypes.string,
  // postnummer: PropTypes.string,
  phone: PropTypes.string,
  phoneCountryCode: PropTypes.string,
  epost: PropTypes.string,
  history: historyShape.isRequired,
  onActivateBankID: PropTypes.func.isRequired,
  hasReauth: PropTypes.bool,
};

const mapDispatchToProps = dispatch => ({
  onActivateBankID: () => dispatch(activateBankID()),
});

const mapStateToProps = (state) => {
  const selector = formValueSelector('bankIDOrderForm');
  return {
    // adresse: selector(state, 'adresse'),
    // postnummer: selector(state, 'postnummer'),
    phone: selector(state, 'phone'),
    phoneCountryCode: selector(state, 'phoneCountryCode'),
    epost: selector(state, 'epost'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BankIDOrderPage);
