import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatNumber } from 'utils/format.util';
import TagButton from './TagButton';

const getAmountText = (maxAmount, fromAmount, toAmount) => {
  const toAmountFormatted = formatNumber(toAmount, 0);
  const fromAmountFormatted = formatNumber(fromAmount, 0);
  const maxText =
    toAmount === maxAmount
      ? `Over ${toAmountFormatted} kr`
      : `${toAmountFormatted} kr`;
  return `${fromAmountFormatted} - ${maxText}`;
};

const SearchFilterTags = ({ onChange, values, getTransactions }) => {
  const {
    transactionType,
    recipient,
    fromDate,
    toDate,
    fromAmount,
    toAmount,
  } = values;
  return (
    <div className="search-filter__tags">
      {recipient && (
        <TagButton
          value={recipient}
          onClick={() => {
            onChange('searchForm', 'recipient', '');
            getTransactions({ ...values, recipient: '' });
          }}
        />
      )}
      {transactionType && (
        <TagButton
          value={transactionType}
          onClick={() => {
            onChange('searchForm', 'transactionType', '');
            getTransactions({ ...values, transactionType: '' });
          }}
        />
      )}
      {fromDate &&
        toDate && (
          <TagButton
            value={`Fra ${moment(fromDate, 'DD.MM.YYYY').format(
              'DD.MM.YY',
            )} til ${moment(toDate, 'DD.MM.YYYY').format('DD.MM.YY')}`}
            onClick={() => {
              onChange('searchForm', 'fromDate', '');
              onChange('searchForm', 'toDate', '');
              getTransactions({
                ...values,
                fromDate: '',
                toDate: '',
              });
            }}
          />
        )}
      {!toDate &&
        fromDate && (
          <TagButton
            value={`Fra dato: ${moment(fromDate, 'DD.MM.YYYY').format(
              'DD.MM.YY',
            )}`}
            onClick={() => {
              onChange('searchForm', 'fromDate', '');
              getTransactions({ ...values, fromDate: '' });
            }}
          />
        )}
      {!fromDate &&
        toDate && (
          <TagButton
            value={`Til dato: ${moment(toDate, 'DD.MM.YYYY').format(
              'DD.MM.YY',
            )}`}
            onClick={() => {
              onChange('searchForm', 'toDate', '');
              getTransactions({ ...values, toDate: '' });
            }}
            textBefore="Til dato"
          />
        )}
      {fromAmount &&
        toAmount &&
        (fromAmount > 0 || toAmount !== '30000') && (
          <TagButton
            value={getAmountText('30000', fromAmount, toAmount)}
            onClick={() => {
              onChange('searchForm', 'fromAmount', '');
              onChange('searchForm', 'toAmount', '');
              getTransactions({
                ...values,
                fromAmount: '',
                toAmount: '',
              });
            }}
          />
        )}
      {!toAmount &&
        fromAmount && (
          <TagButton
            value={`Beløp fra ${formatNumber(fromAmount, 0)} kr`}
            onClick={() => {
              onChange('searchForm', 'fromAmount', '');
              getTransactions({
                ...values,
                fromAmount: '',
              });
            }}
          />
        )}
      {!fromAmount &&
        toAmount && (
          <TagButton
            value={`Beløp opp til ${formatNumber(toAmount, 0)} kr`}
            onClick={() => {
              onChange('searchForm', 'toAmount', '');
              getTransactions({
                ...values,
                toAmount: '',
              });
            }}
          />
        )}
    </div>
  );
};

SearchFilterTags.propTypes = {
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
};

export default SearchFilterTags;
