import React from 'react';
import PropTypes from 'prop-types';
import { formatAccountNumber } from 'utils/format.util';
import styled from 'styled-components';
import { breakpoints, colors } from 'digitalbank-core-style';
import { rem } from 'polished';

const FasteOppdragHeaderStyled = styled.div`
  .faste-oppdrag-header {
    padding: ${rem(20)} ${rem(20)} 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (min-width: ${breakpoints.tablet}px) {
      padding: ${rem(20)} 0 ${rem(6)};
    }

    &__info {
      overflow: hidden;
    }

    &__heading,
    &__account {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__heading {
      color: $tundora;
      line-height: ${rem(28)};
    }

    &__account {
      font-size: ${rem(14)};
      color: ${colors.boulder};
    }
  }
`;

const FasteOppdragHeader = ({ accountName, accountNumber }) => (
  <FasteOppdragHeaderStyled>
    <header className="faste-oppdrag-header">
      <div className="faste-oppdrag-header__info">
        <h3 className="faste-oppdrag-header__heading">{accountName}</h3>
        <p className="faste-oppdrag-header__account" aria-label="kontonummer">
          {formatAccountNumber(accountNumber)}
        </p>
      </div>
    </header>
  </FasteOppdragHeaderStyled>
);

FasteOppdragHeader.propTypes = {
  accountName: PropTypes.string.isRequired,
  accountNumber: PropTypes.string.isRequired,
};

export default FasteOppdragHeader;
