import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { AccountSelectionElement } from 'components';
import {
  toggleAccount as toggle,
  moveAccountUp as moveUp,
  moveAccountDown as moveDown,
} from 'actions';

const AccountSelectionContainer = ({
  accounts,
  selectedAccounts,
  toggleAccount,
  moveAccountUp,
  moveAccountDown,
}) => {
  const enabledAccounts = [];
  const disabledAccounts = [];

  accounts.forEach((account) => {
    // check if account exist in selected accounts
    const indexOfSelectedAccount = _.findIndex(
      selectedAccounts,
      x => x.accountId === account.id,
    );
    const selectedAccount = selectedAccounts[indexOfSelectedAccount];

    const mappedAccount = {
      ...account,
      orderIndex: selectedAccount.orderIndex,
      isSelected: selectedAccount.isSelected,
    };

    if (mappedAccount.isSelected) {
      enabledAccounts.push(mappedAccount);
    } else {
      disabledAccounts.push(mappedAccount);
    }
  });

  const orderedEnabledAccounts = _.orderBy(
    enabledAccounts,
    ['orderIndex'],
    ['asc'],
  );
  const orderedDisabledAccounts = _.orderBy(
    disabledAccounts,
    ['orderIndex'],
    ['asc'],
  );

  return (
    <section className="p-settings__section page">
      <p>
        Velg hva du ønsker å se i min oversikt. Endre rekkefølge ved å klikke på
        pilene til høyre. Selv om du skjuler noe fra min oversikt, vil du finne
        de igjen andre steder i digitalbanken.
      </p>
      <ul className="account-selection">
        {orderedEnabledAccounts.map((account, index) => (
          <AccountSelectionElement
            key={`account-selection-${account.id}`}
            account={account}
            isSelected
            isFirst={index === 0}
            isLast={index + 1 === enabledAccounts.length}
            toggle={() => toggleAccount(account.id)}
            moveUp={() => moveAccountUp(account.id)}
            moveDown={() => moveAccountDown(account.id)}
          />
        ))}
      </ul>
      {orderedDisabledAccounts.length > 0 && (
        <ul
          className="account-selection"
          style={{
            borderTop:
              enabledAccounts.length === 0 ? 'none' : '3px solid #c40000',
          }}
        >
          {orderedDisabledAccounts.map((account, index) => (
            <AccountSelectionElement
              key={`account-selection-${account.id}`}
              account={account}
              isSelected={false}
              isFirst={index === 0}
              isLast={index + 1 === orderedDisabledAccounts.length}
              toggle={() => toggleAccount(account.id)}
              moveUp={() => moveAccountUp(account.id)}
              moveDown={() => moveAccountDown(account.id)}
            />
          ))}
        </ul>
      )}
    </section>
  );
};

AccountSelectionContainer.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedAccounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggleAccount: PropTypes.func.isRequired,
  moveAccountUp: PropTypes.func.isRequired,
  moveAccountDown: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  toggleAccount: bindActionCreators(toggle, dispatch),
  moveAccountUp: bindActionCreators(moveUp, dispatch),
  moveAccountDown: bindActionCreators(moveDown, dispatch),
});

export default connect(null, mapDispatchToProps)(AccountSelectionContainer);
