export const startInstance = accountId => ({
  type: 'EDIT_ACCOUNT_NAMES/START_INSTANCE',
  accountId,
});

export const updateInstance = (accountId, index, status) => ({
  type: 'EDIT_ACCOUNT_NAMES/UPDATE_INSTANCE',
  accountId,
  index,
  status,
});
