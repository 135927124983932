/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import { getTag } from 'services/tag.service';
import styled from 'styled-components';
import { spacing } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import transactions from 'data/transactions';
import categories from 'data/categories';
import { getCategoryData } from 'routes/Economy/utils';
import { HorizontalLayoutSpacing } from 'styled';
import Button from 'digitalbank-buttons';
import Alert from 'digitalbank-alert';
import TagDetails from './Tag/TagDetails';
// import Map from './components/Map';
import Fade from './components/Fade';
import CategorySummary, {
  CategorySummaryStyled,
} from './components/CategorySummary';

const PrintButton = styled.button`
  @media print {
    display: none;
  }
`;

const Receipts = styled.div`
  ${horizontalLayoutSpacing};
`;

const ReceiptImage = styled.img`
  max-width: 300px;
`;

const ReceiptSection = styled.section`
  margin-bottom: 24px;
`;

const SectionStyled = styled.section`
  ${horizontalLayoutSpacing({ phone: false })}
  margin-bottom: ${spacing.layoutSpacing.md}px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  /* font-size: 18px; */
  font-weight: bold;
  ${horizontalLayoutSpacing({ tablet: false, desktop: false })};
`;

const DetailSection = styled.div`
  margin-bottom: ${spacing.layoutSpacing.xs}px;
  ${horizontalLayoutSpacing};
`;

const TagPageStyled = styled.div`
  .p-general {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__main {
      /* We need this to position the map */
      position: relative;
    }
  }

  ${CategorySummaryStyled} {
    margin-bottom: 24px;
  }

  ${PrintButton} {
    margin-bottom: 24px;
  }

  .alert,
  .button {
    @media print {
      display: none;
    }
  }
`;

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: null,
    };
    this.getTag = this.getTag.bind(this);
  }
  componentDidMount() {
    this.getTag();
    /* eslint-disable-next-line */
    this.categoryData = getCategoryData({
      categories,
      transactions: transactions.slice(0, 12),
      categoryName: 'Utgifter',
    });
  }
  getTag() {
    getTag(this.props.match.params.id)
      .then((tag) => {
        this.setState({ tag });
      })
      .catch((error) => {
        /* eslint-disable-next-line */
        console.error(error);
      });
  }
  render() {
    const { tag } = this.state;
    const isLoading = !tag;
    // console.log(this.categoryData);
    return (
      <TagPageStyled>
        <BasePage heading={tag ? tag.name : ''}>
          {isLoading && <span />}
          {!isLoading && (
            <Fade in={!isLoading} appear duration={200}>
              <DetailSection>
                <TagDetails tag={tag} />
                <Button variant="link">Legg til flere detaljer</Button>
              </DetailSection>
              <SectionStyled>
                {/* <SubheadingStyled>Bruk</SubheadingStyled> */}
                {this.categoryData.children
                  .filter(x => x.sum < 0)
                  .map(category => (
                    <CategorySummary
                      category={category}
                      key={`category-${category.name}`}
                    />
                  ))}
                <Footer>
                  <div>Totalt</div>
                  <div>
                    {Number(this.categoryData.sum).toLocaleString('nb-NO', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </Footer>

                {/* {JSON.stringify(this.categoryData.category)} */}
              </SectionStyled>
              {/* {hasBudget &&
                tag.isTravelTag && (
                  <MapStyled>
                    <Map />
                  </MapStyled>
                )} */}
              <Receipts>
                <h2>Vedlegg</h2>
                <ReceiptSection>
                  <h4>Vedlegg 1</h4>
                  <ReceiptImage
                    src="/images/receipts/receipt-1.jpg"
                    alt="Vedlegg 1"
                  />
                </ReceiptSection>
                <ReceiptSection>
                  <h4>Vedlegg 2</h4>
                  <ReceiptImage
                    src="/images/receipts/receipt-2.jpg"
                    alt="Vedlegg 2"
                  />
                </ReceiptSection>
              </Receipts>
              <HorizontalLayoutSpacing>
                <PrintButton type="button" onClick={() => window.print()}>
                  Lagre som PDF
                </PrintButton>
                <Alert type="info" title="Enda enklere?">
                  Ønsker du å sende reiseregningen direkte til ditt
                  regnskapssystem? Vi har gjort det mulig for alle
                  regnskapsystem å koble seg mot din reiseregning. Gi ditt
                  regnskapsystem et lite hint om denne muligheten i dag og
                  kontakt oss gjerne på utvikler@spv.no i dag for mer
                  informasjon.
                </Alert>
              </HorizontalLayoutSpacing>
            </Fade>
          )}
        </BasePage>
      </TagPageStyled>
    );
  }
}

Page.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

export default Page;
