// TODO: Fix issue
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'digitalbank-buttons';
import { Pencil } from 'digitalbank-icons';
import ContactInfoCard from './ContactInfoCard';

const ContactInfoPreview = ({ openEditMode, user }) => (
  <div>
    <div onClick={openEditMode}>
      <ContactInfoCard active info={user.information} />
    </div>
    <Button
      variant="edit"
      size="medium"
      className="p-contact-info__edit-button"
      style={{ marginTop: '20px', width: '100%' }}
      onClick={openEditMode}
      leftIcon={<Pencil />}
    >
      Endre Kontaktinformasjon
    </Button>
  </div>
);

ContactInfoPreview.propTypes = {
  openEditMode: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default connect(({ user }) => ({ user }))(ContactInfoPreview);
