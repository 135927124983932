const template = {
  BETALE_EN_OVERSKRIFT_TEXT: {
    'nb-NO': 'Betale en regning',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALE_EN_GODKJENNING_OVERSKRIFT_TEXT: {
    'nb-NO': 'Godkjenn betaling',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  KID_ELLER_MELDING: {
    'nb-NO': 'KID eller melding',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALE_EN_NESTE_BUTTON: {
    'nb-NO': 'Neste',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  NY_BETALING: {
    'nb-NO': 'Ny betaling',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  NO_ACCOUNT_TO_PAY_FROM: {
    'nb-NO': 'Du har ingen kontoer å betale fra',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  NO_ACCOUNT_TO_PAY_FROM_HELPTEXT: {
    'nb-NO': 'Du kan opprette kontoer i nettbanken.',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  GODKJENN: {
    'nb-NO': 'Godkjenn',
    'nn-NO': 'Godkjenn',
    'en-GB': 'Approve',
  },
  BETAL: {
    'nb-NO': 'Betal',
    'nn-NO': 'Betal',
    'en-GB': 'Pay',
  },
  BETALING_ERROR_TITLE: {
    'nb-NO': 'Feil ved betaling',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_ERROR_MESSAGE: {
    'nb-NO':
      'Det har oppstått en teknisk feil, ' +
      'og betalingen kan ikke gjennomføres. Prøv igjen senere.',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_STENGESYSTEM_TITLE: {
    'nb-NO': 'Betaling ikke tilgjengelig',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_STENGESYSTEM_MESSAGE: {
    'nb-NO': 'Det er dessverre ikke mulig å benytte tjenesten akkurat nå.',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_UTFORT_TITTEL: {
    'nb-NO': 'Betalingen er gjennomført',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_FORFALL_TITTEL: {
    'nb-NO': 'Betalingen er lagt til forfall',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_UTFORT_TEXT: {
    'nb-NO': ' til {kredittkonto} fra {debetkonto}.',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_FORFALL_TEXT: {
    'nb-NO': 'Forfall {date}.',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_INGEN_REAUTENTISERING_TITLE: {
    'nb-NO': 'Hvorfor spurte vi ikke etter kode?',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
  BETALING_INGEN_REAUTENTISERING_TEXT: {
    'nb-NO':
      'Bacon ipsum dolor amet kevin strip steak meatloaf ribeye tail pork belly leberkas. Alcatra hamburger turducken flank pig spare ribs. Pancetta beef shankle meatball, hamburger rump pork ground round tail sausage leberkas. Ball tip flank corned beef, shank tongue turkey jerky. Capicola pork loin ground round, ribeye boudin short ribs ball tip brisket tenderloin jerky venison meatloaf kevin sirloin. Pork loin ribeye cupim, pig short ribs salami frankfurter spare ribs. Turducken sirloin porchetta tail flank.',
    'nn-NO': 'unknkown',
    'en-GB': 'unknkown',
  },
};

export default template;
