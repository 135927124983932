import { combineReducers } from 'redux';
import { ONECLICK as EFAKTURA_ONECLICK_STATUS } from 'digitalbank-efaktura-oneclick';
import automaticEfakturaIsActive from 'routes/Avtaleadministrasjon/avtaleadministrasjon.reducer';

const initialForfall = {
  value: 'ALLE_FORFALL',
  savingInstances: [],
};

const initialForfallPresentation = {
  value: 'SUM_FORFALL', // REST_ETTER_FORFALL, 'SUM_FORFALL',
  savingInstances: [],
};

const initalKredittkortPresentation = {
  value: 'DISPONIBELT', // DISPONIBELT, TRUKKET,
  savingInstances: [],
};

const initialBalanceCarouselImage = {
  value: true,
  savingInstances: [],
};

const createUserSettingReducer = (initialState, alias) => (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case `USER_SETTINGS/VALUE_${alias}/SET`:
      return {
        ...state,
        value: action.value,
      };
    case `USER_SETTINGS/VALUE_${alias}/NEW_SAVING_INSTANCE`: {
      return {
        ...state,
        savingInstances: [...state.savingInstances, 'IN_PROGRESS'],
      };
    }
    case `USER_SETTINGS/VALUE_${alias}/UPDATE_SAVING_INSTANCE`: {
      const savingInstances = [...state.savingInstances];
      savingInstances[action.index] = action.status;
      return {
        ...state,
        savingInstances,
      };
    }
    default:
      return state;
  }
};

const displaySakTilSignering = (state = true, action) => {
  switch (action.type) {
    case 'USER_SETTINGS/VALUE_DISPLAY_SAK_TIL_SIGNERING/TOGGLE':
      return !state;
    default:
      return state;
  }
};

const efakturaOneClickStatus = (
  state = EFAKTURA_ONECLICK_STATUS.IKKE_SATT,
  action,
) => {
  switch (action.type) {
    case 'USER_SETTINGS/VALUE_EFAKTURA_ONECLICK_STATUS/SET':
      return action.status;
    default:
      return state;
  }
};

const bankIDIsActive = (state = false, action = {}) => {
  switch (action.type) {
    case 'BANKID/ACTIVATE':
      return true;
    default:
      return state;
  }
};

const bankIDMobileIsActive = (state = false, action = {}) => {
  switch (action.type) {
    case 'BANKID_MOBILE/ACTIVATE':
      return true;
    default:
      return state;
  }
};

const getHomePageInitialState = () => {
  const intialHomePageState = {
    displayEfakturaOneClick: true,
    displayActions: true,
    displayOldNavList: false,
    displayEfakturaFraAlleBanner: false,
    displayBalanceCarousel: true,
    displayBadgesInTabbar: true,
  };
  const balanceCarouselJson = window.localStorage.getItem('homePage');

  return balanceCarouselJson
    ? JSON.parse(balanceCarouselJson)
    : intialHomePageState;
};

const homePage = (state = getHomePageInitialState(), action = {}) => {
  switch (action.type) {
    case 'HOME_PAGE/TOGGLE_EFAKTURA_ONE_CLICK': {
      const newState = {
        ...state,
        displayEfakturaOneClick: !state.displayEfakturaOneClick,
      };
      window.localStorage.setItem('homePage', JSON.stringify(newState));
      return newState;
    }
    case 'HOME_PAGE/TOGGLE_ACTIONS_FROM_HOMEPAGE': {
      const newState = {
        ...state,
        displayActions: !state.displayActions,
      };
      window.localStorage.setItem('homePage', JSON.stringify(newState));
      return newState;
    }
    case 'HOME_PAGE/TOGGLE_OLD_NAVLIST_FROM_HOMEPAGE': {
      const newState = {
        ...state,
        displayOldNavList: !state.displayOldNavList,
      };
      window.localStorage.setItem('homePage', JSON.stringify(newState));
      return newState;
    }
    case 'HOME_PAGE/TOGGLE_EFAKTURA_FRA_ALLE_BANNER': {
      const newState = {
        ...state,
        displayEfakturaFraAlleBanner: !state.displayEfakturaFraAlleBanner,
      };
      window.localStorage.setItem('homePage', JSON.stringify(newState));
      return newState;
    }
    case 'HOME_PAGE/TOGGLE_BALANCE_CAROUSEL': {
      const newState = {
        ...state,
        displayBalanceCarousel: !state.displayBalanceCarousel,
      };
      window.localStorage.setItem('homePage', JSON.stringify(newState));
      return newState;
    }
    case 'HOME_PAGE/TOGGLE_BADGE_IN_TABBAR': {
      const newState = {
        ...state,
        displayBadgesInTabbar: !state.displayBadgesInTabbar,
      };
      window.localStorage.setItem('homePage', JSON.stringify(newState));
      return newState;
    }
    default:
      return state;
  }
};

export const forfall = createUserSettingReducer(initialForfall, 'FORFALL');
export const forfallPresentation = createUserSettingReducer(
  initialForfallPresentation,
  'FORFALL_PRESENTATION',
);
export const kredittkortPresentation = createUserSettingReducer(
  initalKredittkortPresentation,
  'KREDITTKORT_PRESENTATION',
);
export const balanceCarouselImage = createUserSettingReducer(
  initialBalanceCarouselImage,
  'BILDE_I_SALDO_MODUL',
);

export default combineReducers({
  homePage,
  forfall,
  forfallPresentation,
  kredittkortPresentation,
  balanceCarouselImage,
  efakturaOneClickStatus,
  automaticEfakturaIsActive,
  displaySakTilSignering,
  bankIDMobileIsActive,
  bankIDIsActive,
});
