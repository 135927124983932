// Action creators - return an action
import createI18n, { I18nStore } from 'spv-i18n';
import moment from 'moment';
import templates from 'i18n/i18ntemplates';

import {
  SET_VISIBILITY_FILTER,
  TOGGLE_TODO,
  TOGGLE_SIDEBAR,
  UPDATE_WINDOW,
  TOGGLE_DATA_CONSENT_INFO_ELEMENT,
  TOGGLE_PERSONAL_DATA_CONSENT_HELP,
} from './actionTypes';

export {
  toggleEditModeOnContactInfo,
  closeEditModeOnContactInfo,
  openEditModeOnContactInfo,
  toggleContactInfoHelp,
  toggleForeignerForm,
} from './periodicControlContactInfo';

export {
  toggleEditModeOnRegionalBlocking,
  closeEditModeOnRegionalBlocking,
  openEditModeOnRegionalBlocking,
  toggleRegionalBlockingHelp,
  openRegionalBlockingLocation,
  closeRegionalBlockingLocation,
  toggleRegionalBlockingLocation,
  toggleLocationInfo,
} from './periodicControlRegionalBlocking';

export { getTransactions } from './transactions.actions';

export {
  toggleAccount,
  moveAccountUp,
  moveAccountDown,
} from './accounts.actions';

export const togglePersonalDataConsentInfoElement = index => ({
  type: TOGGLE_DATA_CONSENT_INFO_ELEMENT,
  index,
});
export const togglePersonalDataConsentHelp = () => ({
  type: TOGGLE_PERSONAL_DATA_CONSENT_HELP,
});

export const updateWindowSize = windowSize => ({
  type: UPDATE_WINDOW,
  windowSize,
});

export const setVisibilityFilter = filter => ({
  type: SET_VISIBILITY_FILTER,
  filter,
});

export const toggleTodo = id => ({
  type: TOGGLE_TODO,
  id,
});

export const toggleSidebar = () => ({
  type: TOGGLE_SIDEBAR,
});

export const togglePeriodicControlTest = () => ({
  type: 'TOGGLE_PERIODIC_CONTROL_TEST',
});

export const updateLocale = locale => ({
  type: 'SET_LOCALE',
  locale,
});

export const setTitle = title => ({
  type: 'SET_TITLE',
  title,
});

export const setLocale = locale => (dispatch) => {
  const i18n = createI18n(templates, {
    hasVerboseLogging: false,
    locales: ['nb-NO', 'en-GB'],
    defaultLocale: locale,
  });
  I18nStore.set(i18n);
  moment.locale(locale);
  dispatch(updateLocale(locale));
};
