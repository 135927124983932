/* eslint-disable no-alert */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { BasePage } from 'digitalbank-layout';
import Button from 'digitalbank-buttons';
import Alert from 'digitalbank-alert';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import historyShape from 'shapes/history.shape';
import { InputField } from 'digitalbank-form';
import { asYouType as FormatAsYouType } from 'libphonenumber-js';
import StatusIndicator from 'components/StatusIndicator';
import BankIDSigning from 'components/BankIDSigning';
import MobileWithSpinner from 'components/MobileWithSpinner';
import { activateBankIDMobile } from 'actions/userSettings.actions';
import BankIDMobilOrderPageStyled from './BankIDMobilOrderPageStyled';

const getRightAddon = (status) => {
  if (!status) {
    return (
      <Button variant="primary" type="submit">
        Test mobil
      </Button>
    );
  }
  return (
    <StatusIndicator
      status={status}
      progressText="Tester..."
      successText="Testen var vellykket"
    />
  );
};

class BankIDMobilOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isPhoneValid: false,
      phoneTestStatus: undefined,
      hasSuccessfullyTestetPhoneOnce: false,
      wizardStep: 'INITIAL',
    };
    this.testPhone = this.testPhone.bind(this);
    this.bankIDSigning = this.bankIDSigning.bind(this);
  }
  testPhone() {
    this.setState({
      phoneTestStatus: 'IN_PROGRESS',
    });

    return new Promise((resolve) => {
      setTimeout(() => {
        this.setState({
          phoneTestStatus: 'SUCCESSFUL',
          hasSuccessfullyTestetPhoneOnce: true,
        });
        resolve();
      }, 2500);
    });
  }
  bankIDSigning() {
    this.setState({ wizardStep: 'ACTIVATING' });
    setTimeout(() => {
      this.props.onActivateBankIDMobile();
      this.props.history.push(PATHS.BANKID_MOBILE_ORDER_RECEIPT);
    }, 5000);
  }
  render() {
    // const { isPhoneValid } = this.state;
    const { isIdentifiedWithKodebrikke } = this.props;
    const { wizardStep } = this.state;
    return (
      <BankIDMobilOrderPageStyled>
        <BasePage
          heading="Bestill BankID på mobil"
          className="p-bankid-mobile-order"
        >
          <p className="p-bankid-mobile-order__description">
            BankID er en felles standard for elektronisk identifisering som kan
            benyttes i alle norske banker, og mange andre steder.
          </p>
          {wizardStep === 'INITIAL' &&
            !isIdentifiedWithKodebrikke && (
              <Alert
                type="info"
                small
                className="p-bankid-mobile-order__info-alert"
              >
                <div>
                  <p style={{ margin: '0' }}>
                    <b>
                      Bestillingen krever at du identifiserer deg med kodebrikke
                      utstedt av oss.
                    </b>
                  </p>
                  <p>
                    Mangler du kodebrikke fra Sparebanken Vest?{' '}
                    <Link to={PATHS.BANKID_NEW_KODEBRIKKE_ORDER}>
                      Bestill her
                    </Link>.
                  </p>
                </div>
              </Alert>
            )}
          <section>
            {wizardStep === 'BANK_ID_SIGNING' && (
              <BankIDSigning onClick={this.bankIDSigning} />
            )}
            {wizardStep === 'ACTIVATING' && (
              <div className="p-bankid-mobile-order__activation-container">
                <div className="p-bankid-mobile-order__mobile-spinner">
                  <MobileWithSpinner />
                </div>
                <div className="p-bankid-mobile-order__activation-tooltip">
                  <p className="p-bankid-mobile-order__activation-text">
                    <b>Din aktiveringskode:</b>
                  </p>
                  <p className="p-bankid-mobile-order__activation-number">
                    {Math.floor(Math.random() * 100000000)}
                  </p>
                  <p className="p-bankid-mobile-order__activation-description">
                    Følg anvsiningene på mobiltelefonen din
                  </p>
                </div>
                {/* <Button
                link
                onClick={this.props.history.goBack}
                className="p-bankid-mobile-order__activation-cancel-button"
              >
                Avbryt
              </Button> */}
              </div>
            )}
            {wizardStep === 'INITIAL' && (
              <div>
                <form
                  className="p-bankid-mobile-order__mobile-test"
                  onSubmit={this.props.handleSubmit(this.testPhone)}
                >
                  <Field
                    name="phone"
                    label="Mobil"
                    ariaLabel="Mobil"
                    disabled={this.state.phoneTestStatus === 'IN_PROGRESS'}
                    onChange={() =>
                      this.setState({ phoneTestStatus: undefined })
                    }
                    showErrorCallback={meta =>
                      !!meta.error &&
                      (meta.submitFailed || (meta.dirty && meta.touched))
                    }
                    className="p-bankid-mobile-order__mobile-field"
                    component={InputField}
                    normalize={value =>
                      value && new FormatAsYouType('NO').input(value)
                    }
                    size="12"
                    type="tel"
                    hasAutoWidth
                    leftAddon={() => (
                      <div className="form-control-addon form-control-addon--left">
                        + 47
                      </div>
                    )}
                    rightAddon={() => getRightAddon(this.state.phoneTestStatus)}
                  />
                  <p className="p-bankid-mobile-order__info">
                    Merk: Mobilen eller nettbrettet du bestiller BankID til, kan
                    ikke brukes til selve bestillingen.
                  </p>
                </form>
                {this.state.hasSuccessfullyTestetPhoneOnce && (
                  <div>
                    <section className="p-bankid-order__confirmation">
                      <h4>Ved å gå videre bekrefter jeg at:</h4>
                      <ul className="p-bankid-mobile__confirmation-list">
                        <li>
                          Jeg forstår at BankID bekrefter min identitet på linje
                          med min håndskrevne signatur
                        </li>
                        <li>
                          Jeg forstår at passord eller kodebrikke aldri må deles
                          med andre
                        </li>
                        <li>
                          Jeg er gjort kjent med Avtalevilkårene for
                          PersonBankID
                        </li>
                      </ul>
                    </section>
                    <Button
                      primary
                      disabled={this.state.phoneTestStatus !== 'SUCCESSFUL'}
                      onClick={() => {
                        this.setState({ wizardStep: 'BANK_ID_SIGNING' });
                      }}
                      className="p-bankid-mobile-order__submit-btn"
                    >
                      Bekreft og bestill
                    </Button>
                  </div>
                )}
              </div>
            )}
          </section>
        </BasePage>
      </BankIDMobilOrderPageStyled>
    );
  }
}

BankIDMobilOrderPage.defaultProps = {};
BankIDMobilOrderPage.propTypes = {
  history: historyShape.isRequired,
  isIdentifiedWithKodebrikke: PropTypes.bool.isRequired,
  onActivateBankIDMobile: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onActivateBankIDMobile: () => dispatch(activateBankIDMobile()),
});

export default reduxForm({
  form: 'bankIDMobileOrderForm',
  destroyOnUnmount: false,
  validate: (values) => {
    const errors = {};
    if (!values.phone) {
      errors.phone =
        'Vi trenger ditt mobilnummer for å aktivere BankID på mobil.';
    }
    return errors;
  },
})(connect(null, mapDispatchToProps)(BankIDMobilOrderPage));
