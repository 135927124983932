import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BasePage } from 'digitalbank-layout';
import NavList, { NavListLink, TASK } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import { BlockO } from 'digitalbank-icons';
import * as PATHS from 'routes/paths';
import ReadMoreCollapse from 'components/ReadMoreCollapse';
import EfakturaFraAlleFaq from './EfakturaFraAlleFaq';
import EfakturaFraAlleSettingsContainer from './EfakturaFraAlleSettingsContainer';
import EfakturaFraAlleSettingsStyled from './EfakturaFraAlleSettingsStyled';

const EfakturaFraAlleSettingsPage = ({ sperredeAvsendere }) => (
  <EfakturaFraAlleSettingsStyled>
    <BasePage
      heading="eFaktura fra alle"
      className="p-efaktura-fra-alle-settings"
      asideContent={<EfakturaFraAlleFaq />}
    >
      <div className="p-efaktura-fra-alle-settings__description">
        <div className="short-description">
          <p>
            <i>
              <b>eFaktura fra alle</b>
            </i>{' '}
            gjør eFaktura enklere. Du slipper å få regningen i posten, og du
            slipper å opprette eFaktura-avtale for hver enkelt bedrift.
          </p>
        </div>
        <ReadMoreCollapse
          button={{
            closed: 'Slik virker eFaktura fra alle',
            open: 'Vis mindre',
          }}
          collapseContent={
            <div>
              <ul style={{ margin: '0 0 30px' }}>
                <li>Du kan fortsatt legge inn avtaler manuelt.</li>
                <li>
                  Bedrifter bruker e-postadresse eller mobilnummer for å sjekke
                  om du kan motta eFaktura automatisk.
                </li>
                <li>
                  I en periode vil du fortsatt kunne se forslag til nye avtaler,
                  fra bedrifter som ikke har åpnet for tjenesten ennå.
                </li>
                <li>Du kan sperre enkeltavsendere om nødvendig.</li>
              </ul>
            </div>
          }
        />
      </div>
      <EfakturaFraAlleSettingsContainer />
      <section>
        <h3 className="p-efaktura-fra-alle-settings__section-heading">
          Sperringer
        </h3>
        <NavList
          items={[
            <NavListLink
              variant={TASK}
              key="sperrede-tilbydere"
              to={PATHS.EFAKTURA_FRA_ALLE_SPERREDE_AVSENDERE}
              component={Link}
              title={{ text: 'Sperrede avsendere' }}
              info={{ text: `${sperredeAvsendere.length || 'Ingen'}` }}
              Icon={({ className, ...other }) => (
                <BlockO
                  {...other}
                  className={`p-efaktura-fra-alle-settings__block-icon ${className}`}
                />
              )}
            />,
          ]}
        />
      </section>
    </BasePage>
  </EfakturaFraAlleSettingsStyled>
);

EfakturaFraAlleSettingsPage.propTypes = {
  sperredeAvsendere: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = state => ({
  sperredeAvsendere: state.user.sperredeAvsendere,
});

export default connect(mapStateToProps)(EfakturaFraAlleSettingsPage);
