import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Collapse from 'react-css-collapse';
import { getIcon, ChevronDown } from 'digitalbank-icons';
import DropdownList from 'components/DropdownList';

const SidebarDropdown = (props) => {
  const {
    link, closeSidebar, currentPath, toggle, isOpened,
  } = props;

  const linkClassName = classNames('sidebar__dropdown-button', {
    'sidebar__dropdown-button--active':
      (link.path && link.path) === currentPath || isOpened,
    'sidebar__dropdown-button--is-open': isOpened,
  });
  const Icon = getIcon(link.icon);

  return (
    <div>
      <button type="button" className={linkClassName} onClick={toggle}>
        <div className="sidebar__dropdown-button-content">
          <Icon className="sidebar__icon" />
          <span className="sidebar__label">
            {link.title}
            {link.notification &&
              link.notification.count > 0 && (
                <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                  <div className="badge badge--new">
                    {link.notification.count}
                  </div>
                </div>
              )}
          </span>
          <ChevronDown className="sidebar__chevron" />
        </div>
      </button>
      <Collapse isOpen={isOpened} className="collapse-transition">
        <DropdownList
          linkGroups={link.subelements}
          onActionClick={closeSidebar}
          className="sidebar__dropdown"
          handleClickOutside={() => {}}
        />
      </Collapse>
    </div>
  );
};

SidebarDropdown.defaultProps = {
  isOpened: false,
};

SidebarDropdown.propTypes = {
  link: PropTypes.shape({}).isRequired,
  closeSidebar: PropTypes.func.isRequired,
  currentPath: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpened: PropTypes.bool,
};

export default SidebarDropdown;
