/* eslint-disable no-console, no-alert */
import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { Paper, MinusCircle, MinusCircleO } from 'digitalbank-icons';
import ActionList, {
  ActionButton,
  DeleteButton,
  StopStartButton,
} from 'digitalbank-action-list';
import ConfirmationButtonRow from 'components/ConfirmationButtonRow';
import classNames from 'classnames';

const EditBetaleEnActions = ({
  transaction,
  automaticEfakturaIsActive,
  isEfaktura,
  isDeleted,
  isDeletePending,
  isStopped,
  isSperret,
  start,
  stop,
  remove,
  block,
}) => {
  let actions = [];
  if (isEfaktura) {
    actions = [
      ...actions,
      <ActionButton
        onClick={() => window.alert('Beklager, denne er ikke tilgjengelig.')}
        value="Se faktura"
        key="faktura"
        Icon={Paper}
      />,
    ];
  }
  if (!isEfaktura) {
    actions = [
      ...actions,
      <StopStartButton
        key="start"
        startText="Start"
        stopText="Stop"
        isStopped={isStopped}
        onStopClick={stop}
        onStartClick={start}
      />,
    ];
  }
  if (!isDeleted) {
    actions = [
      ...actions,
      <DeleteButton
        key="delete"
        value="Slett"
        i18nTranslate={(template, format) => i18n(template, format)}
        isDeletePending={isDeletePending}
        onDeleteConfirmed={remove}
        onDeleteErrorClose={() => console.log('onDeleteErrorClose click')}
        showDeleteError={false}
        deleteError="Error"
      />,
    ];
  }

  if (isEfaktura && automaticEfakturaIsActive && !isSperret) {
    actions = [
      ...actions,
      <ConfirmationButtonRow
        Button={props => (
          <ActionButton
            {...props}
            value={`Sperre efaktura fra ${transaction.tilKontonavn}`}
            key="sperre-efaktura"
            Icon={MinusCircleO}
          />
        )}
        ConfirmButton={() => (
          <button
            type="button"
            className="action-list__button action-list__button--delete"
            onClick={() => {
              if (isDeleted) {
                block();
              } else {
                remove().then(() => {
                  block();
                });
              }
            }}
          >
            <span className="action-list__button-content">
              <MinusCircle
                className={classNames('action-list__button-icon', {
                  'action-list__button-icon--loading': isDeletePending,
                })}
              />
              <span>Bekreft sperring</span>
            </span>
          </button>
        )}
        CancelButton={props => (
          <button
            type="button"
            className="action-list__button action-list__button--cancel"
            {...props}
          >
            Avbryt
          </button>
        )}
      />,
    ];
  }
  return (
    <div className="payment-action-list">
      <ActionList actions={actions} orientation="VERTICAL" />
    </div>
  );
};

EditBetaleEnActions.propTypes = {
  transaction: PropTypes.shape({}).isRequired,
  automaticEfakturaIsActive: PropTypes.bool.isRequired,
  isEfaktura: PropTypes.bool.isRequired,
  isDeleted: PropTypes.bool.isRequired,
  isDeletePending: PropTypes.bool.isRequired,
  isStopped: PropTypes.bool.isRequired,
  isSperret: PropTypes.bool.isRequired,
  start: PropTypes.func.isRequired,
  stop: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  block: PropTypes.func.isRequired,
};

export default EditBetaleEnActions;
