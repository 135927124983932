import { START, SUCCESSFUL, FAILED } from 'redux-helpers/data.constants';

import { requested, received, changed, error } from 'redux-helpers/data.util';

const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case START: {
      // check if data alredy exist
      const obj = state[action.name];
      if (obj) {
        return {
          ...state,
          [action.name]: requested(obj.data),
        };
      }
      return {
        ...state,
        [action.name]: requested(),
      };
    }
    case 'GET_DATA_NEW_START':
      return {
        ...state,
        [action.name]: requested(state[action.name].data),
      };
    case SUCCESSFUL:
      return {
        ...state,
        [action.name]: received(action.payload),
      };
    case 'CHANGE_DATA':
      return {
        ...state,
        [action.name]: changed(action.payload),
      };
    case FAILED:
      return {
        ...state,
        [action.name]: error(action.payload),
      };
    default:
      return state;
  }
};

export default dataReducer;
