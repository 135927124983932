/* eslint-disable no-script-url */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import _ from 'lodash';
import classNames from 'classnames';
import i18n from 'i18n';
import RadioButtonGroupField from 'components/Form/RadioButtonGroupField';
import Collapse from 'react-css-collapse';
import { filter } from 'utils/transactions.util';
import { STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import dataSelector from 'redux-helpers/dataSelector';
import { PaperArrowRight, ChevronDown, Times, Search } from 'digitalbank-icons';
import { InputField } from 'digitalbank-form';
import AccountPickerField from 'digitalbank-form/lib/es/components/AccountPickerField';
import { HiddenButton } from 'styled/Buttons';
import {
  receiveAccountList,
  requestAccountList,
} from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import {
  isAccountPickerFetchingAccounts,
  filterActiveAccounts,
} from 'utils/account.util';
import {
  toggleAmountFields,
  toggleDateFields,
  toggleRecipients,
  toggleFilter,
  toggleShareDropdown,
} from './search.actions';

import SearchResultCollapse from './SearchResultCollapse';
import SearchFilterAmountOptions from './SearchFilterAmountOptions';
import SearchFilterDateOptions from './SearchFilterDateOptions';
import SearchFilterTags from './SearchFilterTags';
import ShareDropdown from './ShareDropdown';
import SearchFilterDetails from './SearchFilterDetails';

class SearchFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      noResultInputValue: '',
      result: [],
      status: {
        requested: false,
        received: false,
      },
    };
    this.accountPickerKey = 'searchFormAccount';
    this.getTransactions = this.getTransactions.bind(this);
    this.debouncedSearch = _.debounce(
      (transactions, values) => {
        this.getTransactions(transactions, values, this.props.accounts, input =>
          this.setState({
            noResultInputValue: input,
          }),
        );
      },
      420,
      { leading: false, trailing: true },
    );
  }
  componentDidMount() {
    if (this.props.transactions.status.received) {
      this.getTransactions(this.props.transactions.data, {
        ...this.props.searchFormValues,
      });
    }
    this.getAccounts();
  }
  componentWillReceiveProps(nextProps) {
    if (
      !this.props.transactions.status.received &&
      nextProps.transactions.status.received
    ) {
      this.getTransactions(nextProps.transactions.data, {
        ...this.props.searchFormValues,
      });
    }
  }
  getAccounts() {
    this.props.onRequestAccounts(this.accountPickerKey);
    setTimeout(() => {
      const accountResult = {
        kontoer: filterActiveAccounts(this.props.accounts),
      };
      this.props.onReceiveAccounts(this.accountPickerKey, accountResult);
    }, 0);
  }
  getTransactions(
    transactions,
    {
      inputValue = '',
      account = undefined,
      transactionDirection = 'ALL',
      transactionType = '',
      recipient = '',
      fromDate = '',
      toDate = '',
      fromAmount = '',
      toAmount = '',
    },
    accounts = this.props.accounts,
    cb,
  ) {
    this.setState({
      status: {
        received: false,
        requested: true,
      },
    });
    const filteredTransactions = filter(
      _.filter(transactions, x => x.status === STATUS.DONE),
      inputValue,
      account,
      transactionDirection,
      transactionType,
      recipient,
      fromDate,
      toDate,
      fromAmount,
      toAmount,
      accounts,
    );

    setTimeout(() => {
      this.setState({
        result: filteredTransactions,
        status: {
          received: true,
          requested: true,
        },
      });
    }, 500);

    if (cb) {
      cb(inputValue);
    }
  }
  render() {
    const {
      onToggleDateFields,
      onToggleAmountFields,
      onToggleFilter,
      onToggleShareDropdown,
      changeFieldValue,
      searchState,
      searchFormValues,
      accountPickers,
    } = this.props;
    const transactions = this.props.transactions.data;
    const {
      isAmountFieldsVisible,
      isDateFieldsVisible,
      isFilterOpen,
      isShareDropdownOpen,
    } = searchState;

    const {
      transactionType,
      fromDate,
      toDate,
      fromAmount,
      toAmount,
    } = searchFormValues;
    const hasTags =
      (transactionType && transactionType !== 'Alle') ||
      fromDate ||
      toDate ||
      ((fromAmount && !toAmount) ||
        (!fromAmount && toAmount) ||
        ((fromAmount && fromAmount > 0) || (toAmount && toAmount !== '30000')));
    const isPending =
      this.state.status.requested && !this.state.status.received;
    const isFetchingAccounts = isAccountPickerFetchingAccounts(
      accountPickers,
      this.accountPickerKey,
    );

    return (
      <article>
        <SearchFilterDetails values={searchFormValues} />
        <form className="search-form" action="javascript:void(0);">
          <div className="search-form__top-filter">
            <div className="search-form__search-field">
              <Field
                component={InputField}
                name="inputValue"
                id="test"
                label="Søketekst"
                hiddenLabel
                ariaLabel="Søk etter f.eks. navn eller type"
                placeholder="Søk etter f.eks. navn eller type"
                leftInlineElement={({ meta: { active } }) => (
                  <label
                    htmlFor="inputValue"
                    className="form-control__button form-control__button--inline"
                    aria-hidden
                  >
                    {(!isPending || !active) && (
                      <Search className="form-control__button-icon form-control__button-icon-search" />
                    )}
                    {isPending &&
                      active && (
                        <div className="form-control__button-icon form-control__button-icon-spinner" />
                      )}
                  </label>
                )}
                rightInlineElement={({ meta: { dirty } }) => (
                  <button
                    tabIndex="-1"
                    type="button"
                    className="form-control__button form-control__button--inline"
                    onClick={() => {
                      changeFieldValue('searchForm', 'inputValue', '');
                      this.getTransactions(transactions, {
                        ...searchFormValues,
                        inputValue: '',
                      });
                    }}
                    aria-label="Fjern søketekst"
                    style={{
                      zIndex: dirty ? '1' : '-1',
                      opacity: dirty ? '1' : '0',
                    }}
                  >
                    <Times className="form-control__button-icon-clear" />
                  </button>
                )}
                onChange={(e) => {
                  this.debouncedSearch(transactions, {
                    ...searchFormValues,
                    inputValue: e.target.value,
                  });
                }}
              />
              <HiddenButton type="submit" />
            </div>
            <div className="search-form__account-field">
              <Field
                name="account"
                component={AccountPickerField}
                enableAllAccountsOption
                allAccountsOptionLabel="Alle kort og kontoer"
                i18nTranslate={(template, format) => i18n(template, format)}
                accountpickerId={this.accountPickerKey}
                isFetchingAccounts={isFetchingAccounts}
                type="text"
                label="Velg konto"
                hiddenLabel
                defaultChoiceMessage="Velg konto"
                onFieldChange={account =>
                  this.getTransactions(transactions, {
                    ...searchFormValues,
                    account,
                  })
                }
              />
            </div>
          </div>
          <div
            className={classNames('search-filter', {
              'search-filter--is-open': isFilterOpen,
              'search-filter--has-open-share-dropdown': isShareDropdownOpen,
            })}
          >
            <div className="search-filter__header">
              <div className="search-filter__btn-group">
                <Field
                  name="transactionDirection"
                  component={RadioButtonGroupField}
                  options={[
                    {
                      label: 'Alle',
                      ariaLabel: 'Alle betalinger',
                      value: 'ALL',
                    },
                    {
                      label: 'Inn',
                      ariaLabel: 'Innbetalinger',
                      value: 'INN',
                    },
                    {
                      label: 'Ut',
                      ariaLabel: 'Utbetalinger',
                      value: 'OUT',
                    },
                  ]}
                  onChange={(e) => {
                    this.getTransactions(transactions, {
                      ...searchFormValues,
                      transactionDirection: e.target.value,
                    });
                  }}
                />
              </div>
              <div style={{ position: 'relative' }}>
                <button
                  className={classNames(
                    'ignore-react-onclickoutside search-filter__share-btn',
                    {
                      'search-filter__share-btn--active': isShareDropdownOpen,
                    },
                  )}
                  type="button"
                  onClick={onToggleShareDropdown}
                >
                  <div className="search-filter__share-btn-content">
                    <PaperArrowRight className="search-filter__share-btn-icon" />
                    <ChevronDown className="search-filter__filter-btn-icon search-filter__filter-btn-icon--chevron" />
                  </div>
                </button>
                <ShareDropdown
                  isVisible={isShareDropdownOpen}
                  toggle={onToggleShareDropdown}
                />
              </div>
              <button
                className={classNames('search-filter__filter-btn', {
                  'search-filter__filter-btn--active': isFilterOpen,
                })}
                onClick={onToggleFilter}
                type="button"
              >
                <div className="search-filter__filter-btn-content">
                  Filter
                  <ChevronDown className="search-filter__filter-btn-icon search-filter__filter-btn-icon--chevron" />
                </div>
              </button>
            </div>
            <Collapse isOpen={isFilterOpen} className="search-filter-animation">
              <div className="search-filter__content">
                <SearchFilterDateOptions
                  onChange={changeFieldValue}
                  toggleFields={onToggleDateFields}
                  isFieldsVisible={isDateFieldsVisible}
                  getTransactions={(fDate, tDate) =>
                    this.getTransactions(transactions, {
                      ...searchFormValues,
                      fromDate: fDate,
                      toDate: tDate,
                    })
                  }
                  fromDate={this.props.searchFormValues.fromDate}
                  toDate={this.props.searchFormValues.toDate}
                />
                <SearchFilterAmountOptions
                  onChange={changeFieldValue}
                  toggleFields={onToggleAmountFields}
                  isFieldsVisible={isAmountFieldsVisible}
                  getTransactions={ret =>
                    this.getTransactions(transactions, { ...ret })
                  }
                  values={searchFormValues}
                />
              </div>
            </Collapse>
          </div>
          {hasTags && (
            <SearchFilterTags
              onChange={changeFieldValue}
              values={searchFormValues}
              getTransactions={ret =>
                this.getTransactions(transactions, { ...ret })
              }
            />
          )}
        </form>
        <SearchResultCollapse
          transactions={_.orderBy(this.state.result, ['dato'], ['desc'])}
          isPending={isPending}
          isFilterOpen={isFilterOpen}
          onChange={changeFieldValue}
          getTransactions={ret => this.getTransactions(transactions, ret)}
          values={searchFormValues}
          noResultInputValue={this.state.noResultInputValue}
        />
      </article>
    );
  }
}

SearchFormContainer.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
  onToggleAmountFields: PropTypes.func.isRequired,
  onToggleDateFields: PropTypes.func.isRequired,
  // onToggleRecipients: PropTypes.func.isRequired,
  onToggleFilter: PropTypes.func.isRequired,
  onToggleShareDropdown: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  searchState: PropTypes.shape({
    isFilterOpen: PropTypes.bool.isRequired,
    isRecipientsVisible: PropTypes.bool.isRequired,
    isShareDropdownOpen: PropTypes.bool.isRequired,
  }).isRequired,
  searchFormValues: PropTypes.shape({
    inputValue: PropTypes.string,
    account: PropTypes.object,
    transactionDirection: PropTypes.string,
    transactionType: PropTypes.string,
    recipient: PropTypes.string,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    fromAmount: PropTypes.string,
    toAmount: PropTypes.string,
  }).isRequired,
  onReceiveAccounts: PropTypes.func.isRequired,
  onRequestAccounts: PropTypes.func.isRequired,
  accountPickers: PropTypes.shape({
    fromAccountNumber: PropTypes.shape({
      isFetchingAccounts: PropTypes.bool,
    }),
  }).isRequired,
};

const initialValues = {
  inputValue: '',
  account: undefined,
  transactionDirection: 'ALL',
  transactionType: '',
  recipient: '',
  fromDate: '',
  toDate: '',
  fromAmount: '',
  toAmount: '',
};

const form = reduxForm({
  destroyOnUnmount: false,
  form: 'searchForm',
  initialValues,
})(SearchFormContainer);

const mapStateToProps = (state) => {
  const selector = formValueSelector('searchForm');
  return {
    transactions: dataSelector(state, 'transactions'),
    accounts: state.accounts,
    searchState: state.search,
    accountPickers: state.accountPickers,
    searchFormValues: selector(
      state,
      'inputValue',
      'account',
      'transactionDirection',
      'transactionType',
      'recipient',
      'fromDate',
      'toDate',
      'fromAmount',
      'toAmount',
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  onToggleDateFields: bindActionCreators(toggleDateFields, dispatch),
  onToggleAmountFields: bindActionCreators(toggleAmountFields, dispatch),
  onToggleFilter: bindActionCreators(toggleFilter, dispatch),
  onToggleRecipients: bindActionCreators(toggleRecipients, dispatch),
  onToggleShareDropdown: bindActionCreators(toggleShareDropdown, dispatch),
  changeFieldValue: bindActionCreators(change, dispatch),
  onReceiveAccounts: bindActionCreators(receiveAccountList, dispatch),
  onRequestAccounts: bindActionCreators(requestAccountList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form);
