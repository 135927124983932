import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@vx/text';

const getContentSize = (radius) => {
  const width = Math.floor(radius) * 2;
  const hasText = width >= 80;
  if (hasText) return width * 0.5;
  if (width < 60) return width * 0.58;
  return width * 0.4;
};

const BubbleContent = ({
  color, Icon, style, label, ...other
}) => {
  const width = Math.floor(style.r) * 2;
  const hasText = width >= 80;
  const size = getContentSize(style.r);
  // eslint-disable-next-line
  const iconHeigh = hasText ? '60%' : '100%';
  return (
    <svg
      width={size}
      height={size}
      x={-size / 2}
      y={-size / 2}
      style={{ color }}
      {...other}
    >
      {Icon && <Icon height={iconHeigh} />}
      {hasText && (
        <Text
          width={size}
          scaleToFit
          fill={color}
          fontFamily="Oswald-Regular, Oswald"
          dy="100%"
          dx="50%"
          textAnchor="middle"
        >
          {label}
        </Text>
      )}
    </svg>
  );
};

BubbleContent.propTypes = {
  color: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.shape({
    r: PropTypes.number.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    fill: PropTypes.string,
    borderWidth: PropTypes.number.isRequired,
    borderColor: PropTypes.string.isRequired,
    labelTextColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default BubbleContent;
