import React from 'react';

export default props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="100%"
    height="100%"
    fill="currentColor"
    {...props}
  >
    <path d="M405.1 371.9l10.7 4.7 13.2-34.7-7-3.1z" />
    <path d="M406.5 138.1h-301c-42.7 0-77.4 34.6-77.4 77.4v149.1c0 42.7 34.6 77.4 77.4 77.4h13.9l-20.9 49.8c-4.3 10.2 8.4 19 16.4 11.4l64.8-61.2h152.7l64.8 61.2c8 7.6 20.7-1.2 16.4-11.4L392.7 442h13.9c42.7 0 77.4-34.6 77.4-77.4V215.5c-.1-42.8-34.8-77.4-77.5-77.4zm11.5 84.4c7.9 0 14.3 6.4 14.3 14.3s-6.4 14.3-14.3 14.3-14.3-6.4-14.3-14.3 6.4-14.3 14.3-14.3zm0 49.2c7.9 0 14.3 6.4 14.3 14.3s-6.4 14.3-14.3 14.3-14.3-6.4-14.3-14.3 6.4-14.3 14.3-14.3zM140.7 402c-35.2 0-63.8-28.5-63.8-63.8v-96.3c0-35.2 28.5-63.8 63.8-63.8h148.2c35.2 0 63.8 28.5 63.8 63.8v96.3c0 35.2-28.5 63.8-63.8 63.8H140.7zm299.8-34.7c-5.5 12.4-20.1 18-32.5 12.5-12.4-5.5-18-20.1-12.5-32.5 5.5-12.4 20.1-18 32.5-12.5 12.4 5.5 18 20.1 12.5 32.5zM347.5 12.1c1.9-5.4-5.9-8.7-8.4-3.5L282.9 124h24.7l39.9-111.9zM204.5 124h24.7L172.9 8.6c-2.5-5.2-10.3-1.9-8.4 3.5l40 111.9z" />
  </svg>
);
