import {
  getInitialTransactions,
  getInitialRecurringTransactions,
} from 'services/transactions.service';

import {
  getDataStart,
  // getDataFailed,
  getDataSuccessful,
} from 'redux-helpers/data.actions';

export const getTransactions = () => dispatch =>
  new Promise((resolve) => {
    dispatch(getDataStart('transactions'));
    setTimeout(() => {
      const transactions = getInitialTransactions();
      dispatch(getDataSuccessful('transactions', transactions));
      resolve(transactions);
    }, 500);
  });

export const getRecurringTransactions = () => dispatch =>
  new Promise((resolve) => {
    dispatch(getDataStart('recurringTransactions'));
    dispatch(() => {
      setTimeout(() => {
        const recurringTransactions = getInitialRecurringTransactions();
        dispatch(
          getDataSuccessful('recurringTransactions', recurringTransactions),
        );
        resolve(recurringTransactions);
      }, 500);
    });
  });

export default getTransactions;
