import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'digitalbank-buttons';

class LoadingButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
    this.onClick = this.onClick.bind(this);
  }
  onClick(handleClick, timeout) {
    this.setState({ isLoading: true });
    setTimeout(() => {
      handleClick();
    }, timeout);
  }
  render() {
    const { timeout, onClick, ...other } = this.props;

    return (
      <Button
        {...other}
        onClick={() => this.onClick(onClick, timeout)}
        disabled={this.state.isLoading}
        rightIcon={
          this.state.isLoading ? <div className="button__spinner" /> : null
        }
      />
    );
  }
}

LoadingButton.defaultProps = {
  timeout: 2000,
};

LoadingButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

export default LoadingButton;
