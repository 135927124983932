// import Tilbydere from 'digitalbank-mockup-data/lib/Betalingsavtaler/Tilbydere';
// import InitialAvtaler from 'digitalbank-mockup-data/lib/Betalingsavtaler/InitialAvtaler';
//
// const getInitialTilbydere = () => Tilbydere;
// const getInitialAvtaler = () => InitialAvtaler;
//
// export { getInitialAvtaler, getInitialTilbydere };
//
// export default getInitialAvtaler;

import avtalegiroavtaler, { avtalegiroavtalerForslag } from 'data/betalingsavtaler/avtalegiroavtaler.data';
import efakturaavtaler, { efakturaavtalerForslag } from 'data/betalingsavtaler/efakturaavtaler.data';
import kombinertavtaler, { kombinertavtalerForslag } from 'data/betalingsavtaler/kombinertavtaler.data';

const get = data =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(data);
    }, 800);
  });

export const getAvtalegiroavtaler = () => get(avtalegiroavtaler);
export const getAvtalegiroavtalerForslag = () => get(avtalegiroavtalerForslag);
export const getEfakturaavtaler = () => get(efakturaavtaler);
export const getEfakturaavtalerForslag = () => get(efakturaavtalerForslag);
export const getKombinerteAvtaler = () => get(kombinertavtaler);
export const getKombinertAvtaler = () => get(kombinertavtaler);
export const getKombinertAvtalerForslag = () => get(kombinertavtalerForslag);
