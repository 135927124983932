/* eslint-disable no-console */
import moment from 'moment';

// Calculates the payment for a loan based on constant payments and
// a constant interest rate.
// https://support.office.com/en-us/article/PMT-function-0214da64-9a63-4996-bc20-214433fa6441
export const PMT = (
  annualInterest,
  totalNumberOfPaymentsForTheLoan,
  presentValue,
  featureValue = 0, // Optional
  type = 0, // Optional
) => {
  if (annualInterest === 0) {
    return -(presentValue + featureValue) / totalNumberOfPaymentsForTheLoan;
  }

  const pvif = Math.pow(1 + annualInterest, totalNumberOfPaymentsForTheLoan); // eslint-disable-line
  let pmt = annualInterest / (pvif - 1) * -(presentValue * pvif + featureValue); // eslint-disable-line

  if (type === 1) {
    pmt /= 1 + annualInterest;
  }

  return pmt;
};

// Returns the interest payment for a given period for an investment based
// on periodic, constant payments and a constant interest rate.
export const IPMT = (presentValueOfLoan, pmt, annualInterest, period) => {
  const tmp = Math.pow(1 + annualInterest, period); // eslint-disable-line
  return 0 - (presentValueOfLoan * tmp * annualInterest + pmt * (tmp - 1)); // eslint-disable-line
};

// Returns the payment on the principal for a given period for an investment
// based on periodic, constant payments and a constant interest rate.
// https://support.office.com/en-us/article/PPMT-function-c370d9e3-7749-4ca4-beea-b06c6ac95e1b
export const PPMT = (
  interestRate,
  period,
  totalNumberOfPayments,
  presentValue,
  featureValue = 0, // Optional
  type = 0, // Optional. The number 0 (zero) or 1 and indicates when payments are due.
) => {
  if (period < 1 || period >= totalNumberOfPayments + 1) {
    return null;
  }
  const pmt = PMT(
    interestRate,
    totalNumberOfPayments,
    presentValue,
    featureValue,
    type,
  );
  const ipmt = IPMT(presentValue, pmt, interestRate, period - 1);
  return pmt - ipmt;
};

// Stating your interest as an annual percentage rate
// To do this, divide it by 1200 (100 per month)
export const getAnnualInterestRate = interestRate => interestRate / 1200;

export const getLoanDataForPeriod = (
  annualInterest,
  totalNumberOfPaymentsForTheLoan,
  presentValueOfLoan,
  period,
) => {
  const pmt = PMT(
    annualInterest,
    totalNumberOfPaymentsForTheLoan,
    -presentValueOfLoan,
    0,
    0,
  );
  const ipmt = IPMT(-presentValueOfLoan, pmt, annualInterest, period);
  const ppmt = PPMT(
    annualInterest,
    period,
    totalNumberOfPaymentsForTheLoan,
    -presentValueOfLoan,
    0,
    0,
  );
  return {
    pmt,
    ipmt,
    ppmt,
  };
};

export const getPaymentDateInCurrentMonth = (todaysDate, paymentDay) =>
  moment(todaysDate, moment.ISO_8601)
    .startOf('month')
    .add(paymentDay - 1, 'days');

export const getPreviousPaymentDate = (todaysDate, paymentDay) => {
  const paymentDateInCurrentMonth = getPaymentDateInCurrentMonth(
    todaysDate,
    paymentDay,
  );
  const todaysDateObj = moment(todaysDate, moment.ISO_8601);
  if (todaysDateObj.isAfter(paymentDateInCurrentMonth)) {
    return paymentDateInCurrentMonth;
  }
  return getPaymentDateInCurrentMonth(
    todaysDateObj.subtract(1, 'month').format(),
    15,
  );
};

export const getCurrentPeriod = (
  startDate,
  todaysDate = moment().format(),
  paymentDay = 15,
) => {
  const todaysDateObj = moment(todaysDate, moment.ISO_8601);
  const durationAsMonths = moment
    .duration(todaysDateObj.diff(startDate))
    .asMonths();
  const paymentDateObjInCurrentPeriod = getPaymentDateInCurrentMonth(
    todaysDate,
    paymentDay,
  );
  const todayDateIsSameOrBeforeCurretPaymentDate = todaysDateObj.isSameOrBefore(
    paymentDateObjInCurrentPeriod,
  );
  if (todayDateIsSameOrBeforeCurretPaymentDate) {
    return Math.floor(durationAsMonths);
  }
  return Math.ceil(durationAsMonths);
};

export const getLoanData = (
  startDate,
  interestRate,
  yearsOfLoan,
  loanValue,
) => {
  let currentPeriod;
  let loanData = [];
  let currentRemainingLoanValue = loanValue;
  const totalNumberOfPaymentsForTheLoan = yearsOfLoan * 12;
  const annualInterestRate = getAnnualInterestRate(interestRate);
  for (
    currentPeriod = 0;
    currentPeriod < totalNumberOfPaymentsForTheLoan;
    currentPeriod += 1
  ) {
    const loanDataForPeriod = getLoanDataForPeriod(
      annualInterestRate,
      totalNumberOfPaymentsForTheLoan,
      loanValue,
      currentPeriod + 1,
    );
    currentRemainingLoanValue -= loanDataForPeriod.ppmt;
    loanData = [
      ...loanData,
      {
        ...loanDataForPeriod,
        remainingLoanValue: currentRemainingLoanValue,
        period: currentPeriod,
        date: moment(startDate, moment.ISO_8601)
          .add(currentPeriod, 'months')
          .startOf('month')
          .add(14, 'days')
          .format('YYYY-MM-DD'),
      },
    ];
  }
  return loanData;
};

export const getLoanTablePeriodData = (
  loanData,
  periodDateObj,
  periodData,
  periodCost,
  remainingLoanValue,
  numberOfPeriods,
) => {
  const yearData = loanData.slice(
    periodData.period - numberOfPeriods,
    periodData.period + 1,
  );
  const sumPmt = yearData.map(x => x.pmt).reduce((a, b) => a + b);
  const sumTerminkostninger = periodCost * numberOfPeriods;
  const sum = sumPmt + sumTerminkostninger;
  const sumIpmt = yearData.map(x => x.ipmt).reduce((a, b) => a + b);
  const sumPpmt = yearData.map(x => x.ppmt).reduce((a, b) => a + b);

  return [
    `Sum ${periodDateObj.year()}`,
    Math.round(sum).toString(),
    Math.round(sumIpmt).toString(),
    Math.round(sumPpmt).toString(),
    `${sumTerminkostninger}`,
    `${remainingLoanValue}`,
  ];
};

export const isFirstMonthInYear = dateObj => dateObj.month() === 0;
export const isLastMonthInYear = dateObj => dateObj.month() === 11;
export const isStartYear = (startDate, dateObj) =>
  moment(startDate, moment.ISO_8601).year() === dateObj.year();
export const getNumberOfMonthsLeftInYear = dateObj => 12 - dateObj.month();
export const getNumberOfPeriodsLeftInCurrentYear = (
  todayDateObj = moment(),
) => {
  const paymentDateInCurrentPeriod = getPaymentDateInCurrentMonth(
    todayDateObj.format(),
    15,
  );
  const shouldIncludeCurrentMonth = todayDateObj.isSameOrBefore(
    paymentDateInCurrentPeriod,
  );
  const numberOfMonthsLeftInYear = getNumberOfMonthsLeftInYear(todayDateObj);
  return shouldIncludeCurrentMonth
    ? numberOfMonthsLeftInYear
    : numberOfMonthsLeftInYear - 1;
};
export const getLoanTableData = (
  startDate,
  interestRate,
  yearsOfLoan,
  loanValue,
  numberOfDetailedYears = 1,
) => {
  const numberOfDetailedPeriods = numberOfDetailedYears * 12;
  const numberOfPeriodsInCurrentMonth = getNumberOfPeriodsLeftInCurrentYear();
  const terminomkostninger = 50;
  const loanData = getLoanData(startDate, interestRate, yearsOfLoan, loanValue);
  const currentPeriod = getCurrentPeriod(startDate);
  const filteredLoanData = loanData.filter(x => x.period >= currentPeriod);
  let loanTableData = [];
  filteredLoanData.forEach((periodData, i) => {
    const periodDateObj = moment(periodData.date, moment.ISO_8601);
    const remainingLoanValue = Math.round(
      periodData.remainingLoanValue,
    ).toString();

    // should display each period data
    if (i < numberOfPeriodsInCurrentMonth + numberOfDetailedPeriods) {
      loanTableData = [
        ...loanTableData,
        [
          `${periodDateObj.format('DD.MM.YYYY')}`,
          Math.round(periodData.pmt + terminomkostninger).toString(),
          Math.round(periodData.ipmt).toString(),
          Math.round(periodData.ppmt).toString(),
          `${terminomkostninger}`,
          remainingLoanValue,
        ],
      ];
    }
    if (isLastMonthInYear(periodDateObj)) {
      const lastMonthData = getLoanTablePeriodData(
        loanData,
        periodDateObj,
        periodData,
        terminomkostninger,
        remainingLoanValue,
        11,
      );
      loanTableData = [...loanTableData, lastMonthData];
    }
    // is last period
    if (i === filteredLoanData.length - 1) {
      const numberOfPeriodsLeftAfterIteration =
        (filteredLoanData.length - numberOfPeriodsInCurrentMonth) % 13;
      const lastPeriodsData = getLoanTablePeriodData(
        loanData,
        periodDateObj,
        periodData,
        terminomkostninger,
        remainingLoanValue,
        numberOfPeriodsLeftAfterIteration,
      );
      loanTableData = [...loanTableData, lastPeriodsData];

      const sumPmt = loanData.map(x => x.pmt).reduce((a, b) => a + b);
      const sumTerminkostninger = terminomkostninger * loanData.length;
      const sum = sumPmt + sumTerminkostninger;
      const sumIpmt = loanData.map(x => x.ipmt).reduce((a, b) => a + b);
      const sumPpmt = loanData.map(x => x.ppmt).reduce((a, b) => a + b);

      loanTableData = [
        ...loanTableData,
        [
          'Totalt',
          Math.round(sum).toString(),
          Math.round(sumIpmt).toString(),
          Math.round(sumPpmt).toString(),
          `${sumTerminkostninger}`,
          `${remainingLoanValue}`,
        ],
      ];
    }
  });
  return loanTableData;
};
