import React from 'react';
import PropTypes from 'prop-types';
import FasteOppdragHeader from 'routes/FasteOppdrag/FasteOppdragHeader';
import Accordion from 'digitalbank-accordion';
import _ from 'lodash';
import fastOppdragListItem from './fastOppdragListItem';

const FasteOppdragCollection = ({
  account,
  transactions,
  deleteTransaction,
}) => {
  const transactionsFromAccount = _.filter(
    transactions,
    x => x.fraKonto === account.accountNumber,
  );
  if (transactionsFromAccount.length === 0) return null;

  return (
    <div className="p-faste-oppdrag__collection">
      <FasteOppdragHeader
        accountName={account.accountName}
        accountNumber={account.accountNumber}
      />
      <Accordion
        items={transactionsFromAccount.map(transaction =>
          fastOppdragListItem(transaction, () =>
            deleteTransaction(transaction.id),
          ),
        )}
      />
    </div>
  );
};

FasteOppdragCollection.propTypes = {
  account: PropTypes.shape({}).isRequired,
  transactions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  deleteTransaction: PropTypes.func.isRequired,
};

export default FasteOppdragCollection;
