/* eslint-disable no-console */
import React, { Component } from 'react';
import { reduxForm, destroy } from 'redux-form';
import { change } from 'redux-helpers/data.actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import dataSelector from 'redux-helpers/dataSelector';
import { BasePage } from 'digitalbank-layout';
import * as PATHS from 'routes/paths';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import ActionList, { ActionLink, DeleteButton } from 'digitalbank-action-list';
import { Clock, CheckCircle } from 'digitalbank-icons';
import i18n from 'i18n';
import {
  deleteTransaction,
  getTransactionFromId,
} from 'services/transactions.service';
import { STATUS as TRANSACTION_STATUS } from 'digitalbank-mockup-data/lib/Transactions';
import Alert from 'digitalbank-alert';
import {
  removeSperretEfakturaAvsender,
  addSperretEfakturaAvsender,
} from 'routes/Avtaleadministrasjon/EfakturaFraAlle/efakturaFraAlle.actions';
import PaymentForm from '../PaymentForm';
import validate from '../payment.validate';
import { FAST_BETALING_EDIT_FORM_NAME } from '../payment.constants';
import {
  load as loadTransaction,
  unload as unloadTransaction,
} from './editFastBetaling.actions';
import propTypes from '../editBetaling.propTypes';
import FastBetalingStyled from '../FastBetalingStyled';

const init = (transactions, transactionId, history, load) => {
  const isTransactionFound =
    _.filter(transactions.data, x => x.id === transactionId).length > 0;
  if (!isTransactionFound) {
    history.push(PATHS.FASTE_OPPDRAG);
  } else {
    load(transactions, transactionId);
  }
};

class EditFastBetaling extends Component {
  componentDidMount() {
    this.props.onDestroy(FAST_BETALING_EDIT_FORM_NAME);
    if (this.props.recurringTransactions.status.received) {
      const {
        recurringTransactions, match, history, load,
      } = this.props;
      init(recurringTransactions, match.params.id, history, load);
    }
  }
  componentWillUpdate(nextProps) {
    if (
      !this.props.recurringTransactions.status.received &&
      nextProps.recurringTransactions.status.received
    ) {
      const { match, history, load } = this.props;
      const { recurringTransactions } = nextProps;
      init(recurringTransactions, match.params.id, history, load);
    }
  }
  componentWillUnmount() {
    this.props.unload();
  }
  render() {
    const {
      history,
      handleSubmit,
      transactions,
      recurringTransactions,
      changeData,
    } = this.props;
    const transaction = getTransactionFromId(
      recurringTransactions.data,
      this.props.match.params.id,
    );
    const isDeletePending =
      transaction.status === TRANSACTION_STATUS.SHOULD_BE_DELETED;
    const isDeleted = transaction.status === TRANSACTION_STATUS.DELETED;
    const actions = [
      <ActionLink
        href="/"
        title="Historikk"
        key="history"
        RouterLink={Link}
        Icon={Clock}
      />,
      <DeleteButton
        key="delete"
        value="Slett"
        i18nTranslate={(template, format) => i18n(template, format)}
        isDeletePending={isDeletePending}
        onDeleteConfirmed={() => {
          deleteTransaction(
            'transactions',
            transactions,
            changeData,
            this.props.match.params.id,
          );
          deleteTransaction(
            'recurringTransactions',
            recurringTransactions,
            changeData,
            this.props.match.params.id,
          );
        }}
        onDeleteErrorClose={() => console.log('onDeleteErrorClose click')}
        showDeleteError={false}
        deleteError="Error"
      />,
    ];
    return (
      <FastBetalingStyled>
        <BasePage
          heading={i18n(t => t.ROUTE_TITLE_EDIT_FAST_BETALING)}
          className="p-fast-betaling"
        >
          <PaymentForm
            isDisabled={isDeleted}
            formName={FAST_BETALING_EDIT_FORM_NAME}
            submitButtonText="Bekreft"
            isFastBetaling
            isEditing
            initialValues={this.props.initialValues}
            handleSubmit={handleSubmit(
              () =>
                new Promise((resolve) => {
                  setTimeout(() => {
                    resolve();
                    history.push(
                      `${PATHS.FAST_BETALING_EDIT_REAUTH}/${
                        this.props.match.params.id
                      }`,
                    );
                  }, 500);
                }),
            )}
          >
            {isDeleted && (
              <Alert
                type="info"
                icon={CheckCircle}
                title="Det faste oppdraget ble slettet"
              >
                <Link to={PATHS.FASTE_OPPDRAG}>Se faste oppdrag</Link>
              </Alert>
            )}
          </PaymentForm>
          {!isDeleted && (
            <ActionList actions={actions} orientation="VERTICAL" />
          )}
        </BasePage>
      </FastBetalingStyled>
    );
  }
}

EditFastBetaling.defaultProps = {
  initialValues: null,
};

EditFastBetaling.propTypes = propTypes;

const mapDispatchToProps = dispatch => ({
  load: bindActionCreators(loadTransaction, dispatch),
  unload: bindActionCreators(unloadTransaction, dispatch),
  onDestroy: bindActionCreators(destroy, dispatch),
  changeData: bindActionCreators(change, dispatch),
  onRemoveSperretEfakturaAvsender: id =>
    dispatch(removeSperretEfakturaAvsender(id)),
  onAddSperretEfakturaAvsender: id => dispatch(addSperretEfakturaAvsender(id)),
});

const mapStateToProps = state => ({
  initialValues: state.editFastBetaling.data,
  transactions: dataSelector(state, 'transactions'),
  recurringTransactions: dataSelector(state, 'recurringTransactions'),
  accounts: state.accounts,
  automaticEfakturaIsActive: state.user.settings.automaticEfakturaIsActive,
});

const form = reduxForm({
  destroyOnUnmount: false,
  form: FAST_BETALING_EDIT_FORM_NAME,
  validate,
})(EditFastBetaling);

export default connect(mapStateToProps, mapDispatchToProps)(form);
