import styled from 'styled-components';
import { breakpoints, layout } from 'digitalbank-core-style';
import {
  horizontalLayoutSpacing,
  verticalLayoutSpacing,
} from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-settings {
    margin: rem(20) 0 0;

    &__container {
      display: flex;
      flex-direction: column;
      max-width: ${layout.maxWidth}px;
      margin: ${layout.topSpacing.phone}px auto;

      @media print, screen and (min-width: ${breakpoints.tablet}) {
        margin: ${layout.topSpacing.tablet}px auto;
      }
    }

    .settings-card {
      ${horizontalLayoutSpacing({ type: 'margin', phone: false })};
      ${verticalLayoutSpacing({ type: 'margin', phone: false })};
      @media (max-width: ${breakpoints.tablet - 1}) {
        margin-bottom: ${rem(20)};
      }
    }
  }
`;
