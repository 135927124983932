import styled from 'styled-components';
import { breakpoints, layout, spacing, colors } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-regional-blocking-periodic {
    ${horizontalLayoutSpacing};

    &__container {
      display: flex;
      flex-direction: column;
      margin: ${layout.topSpacing.phone}px auto;
    }

    &__info-alert {
      margin-bottom: ${rem(spacing.layoutSpacing.xs)};
    }

    &__save-button {
      @media (max-width: ${breakpoints.tablet - 1}px) {
        width: 100%;
      }
    }

    &__top-text {
      color: ${colors.boulder};
    }

    .regional-blocking-section {
      margin-bottom: ${rem(spacing.layoutSpacing.xs)};
    }

    .regional-blocking-open-locations {
      @media (max-width: ${breakpoints.tablet}px) {
        margin-top: rem(20);
      }
    }

    .regional-blocking-map-container__map-info {
      align-items: center;
    }
  }
`;
