import React from 'react';

const BankID = props => (
  <i {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 14"
      width="100%"
      height="100%"
      fill="currentColor"
    >
      <path d="M12 14C5.4 14 0 10.9 0 7s5.4-7 12-7 12 3.1 12 7-5.4 7-12 7zM9 4c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1h9c.6 0 1-.4 1-1V5c0-.6-.4-1-1-1H9zm-4 .1c0-.6-.2-.7-.7-.5C2.7 4.6 2 5.8 2 7c0 1.9 1.3 2.7 2.1 3.2.6.4.9.3.9-.1v-6z" />
    </svg>
  </i>
);

BankID.propTypes = {};

export default BankID;
