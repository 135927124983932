import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
  getTransactions,
  getRecurringTransactions,
} from 'actions/transactions.actions';
import Spinner from 'digitalbank-spinner';

class Init extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }
  componentDidMount() {
    // Initialize data if it does not exist
    if (!this.state.isLoaded) {
      const p1 = this.props.onGetTransactions();
      const p2 = this.props.onGetRecurringTransactions();
      Promise.all([p1, p2]).then(() => {
        this.setState({ isLoaded: true });
      });
    }
  }
  render() {
    if (!this.state.isLoaded) {
      return <Spinner centered large verticalSpacing />;
    }

    return this.props.children;
  }
}

Init.propTypes = {
  onGetTransactions: PropTypes.func.isRequired,
  onGetRecurringTransactions: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const mapDispatchToProps = dispatch => ({
  onGetTransactions: bindActionCreators(getTransactions, dispatch),
  onGetRecurringTransactions: bindActionCreators(
    getRecurringTransactions,
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(Init);
