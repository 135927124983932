import styled from 'styled-components';

import HorizontalLayoutSpacing from './HorizontalLayoutSpacing';
import VerticalLayoutSpacing from './VerticalLayoutSpacing';
import BasePageStyled from './BasePageStyled';

export { HorizontalLayoutSpacing, VerticalLayoutSpacing, BasePageStyled };

export const ParagraphCentered = styled.p`
  text-align: center;
`;

export const DefaultLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1170px;
  margin: 1.25rem auto;
`;

export const UlWithoutStyle = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
