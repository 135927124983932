import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Exchange, Clock, Trash } from 'digitalbank-icons';
import * as PATHS from 'routes/paths';
import RecipientShape from 'shapes/RecipientShape';
import { formatAccountNumber } from 'utils/format.util';

export default class RecipientRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteStatus: 'DEFAULT',
    };
    this.setDeleteState = this.setDeleteState.bind(this);
  }
  setDeleteState(status) {
    this.setState({
      ...this.props,
      deleteStatus: status,
    });
  }
  render() {
    const { recipient, deleteRecipient, style } = this.props;
    const { deleteStatus } = this.state;
    const names = recipient.name.split(' ');
    const numberOfNames = names.length;
    const firstName =
      numberOfNames > 1
        ? names.slice(0, numberOfNames - 1).join(' ')
        : names[0];
    const lastName = numberOfNames > 1 ? names[numberOfNames - 1] : '';
    const deleteButtonsTabIndex = deleteStatus === 'ACTIVE' ? '0' : '-1';

    return (
      <li className="recipient-table__row" style={style}>
        <div className="recipient-table__data">
          <div className="recipient-table__data-heading">
            <span className="recipient-table__data-heading-first">
              {firstName}
            </span>
            <span className="recipient-table__data-heading-last">{` ${lastName}`}</span>
          </div>
          <div className="recipient-table__data-info">
            {formatAccountNumber(recipient.account)}
          </div>
        </div>
        <div className="recipient-table__actions">
          <button type="button" className="recipient-table__action">
            <Exchange className="recipient-table__action-icon" />
          </button>
          <Link to={PATHS.SEARCH} className="recipient-table__action">
            <Clock className="recipient-table__action-icon" />
          </Link>
          <button
            type="button"
            className="recipient-table__action"
            onClick={() => this.setDeleteState('ACTIVE')}
          >
            <Trash className="recipient-table__action-icon" />
          </button>
          <div
            className={classNames('recipient-table__delete-actions', {
              'recipient-table__delete-actions--visible':
                deleteStatus === 'ACTIVE',
            })}
          >
            <button
              type="button"
              onClick={deleteRecipient}
              className="recipient-table__button
              recipient-table__button--delete"
              tabIndex={deleteButtonsTabIndex}
            >
              <span className="recipient-table__button-content">
                <Trash className="recipient-table__button-icon" />
                <span className="recipient-table__button-text">
                  Bekreft sletting
                </span>
              </span>
            </button>
            <button
              type="button"
              onClick={() => this.setDeleteState('DEFAULT')}
              className="recipient-table__button
              recipient-table__button--cancel"
              tabIndex={deleteButtonsTabIndex}
            >
              Avbryt
            </button>
          </div>
        </div>
      </li>
    );
  }
}

RecipientRow.propTypes = {
  recipient: RecipientShape.isRequired,
  deleteRecipient: PropTypes.func.isRequired,
  style: PropTypes.shape({
    display: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    opacity: PropTypes.number.isRequired,
  }).isRequired,
};
