const mod11OfNumberWithControlDigit = (input) => {
  let controlNumber = 2;
  let sumForMod = 0;
  let i;

  for (i = input.length - 2; i >= 0; i -= 1) {
    sumForMod += input.charAt(i) * controlNumber;
    controlNumber += 1;
    if (controlNumber > 7) {
      controlNumber = 2;
    }
  }
  const result = 11 - sumForMod % 11; // eslint-disable-line

  return result === 11 ? 0 : result;
};

const luhnValue = (number) => {
  let sum = 0;
  let dbl = 0;
  let i;
  for (i = number.length - 2; i >= 0; i -= 2) {
    dbl = (parseInt(number.charAt(i), 10) * 2).toString();
    sum += parseInt(dbl.charAt(0), 10) + parseInt(dbl.charAt(1) || 0, 10);
  }
  for (i = number.length - 3; i >= 0; i -= 2) {
    sum += parseInt(number.charAt(i), 10);
  }
  sum = sum.toString();
  return 10 - parseInt(sum.charAt(sum.length - 1), 10);
};

const isKidNumber = (number) => {
  if (!number) return false;
  const controlDigit = number.charAt(number.length - 1);
  return (
    parseInt(controlDigit, 10) === mod11OfNumberWithControlDigit(number) ||
    parseInt(controlDigit, 10) === luhnValue(number)
  );
};

const isAccountNumber = (number) => {
  if (!number) {
    return false;
  }

  const washedNumber = number.toString().replace(/\./g, '');
  if (washedNumber.length !== 11) {
    return false;
  }

  return (
    parseInt(number.charAt(number.length - 1), 10) ===
    mod11OfNumberWithControlDigit(number)
  );
};

export default isKidNumber;
export { isKidNumber, isAccountNumber };
