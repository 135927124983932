import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import historyShape from 'shapes/history.shape';
// import * as PATHS from 'routes/paths';
import { change } from 'redux-helpers/data.actions';
import { connect } from 'react-redux';
import dataSelector from 'redux-helpers/dataSelector';
// import ActionList from 'components/ActionList';
import { BasePage } from 'digitalbank-layout';
import { registerMessage } from 'actions/messages.actions';
import {
  getBetalingsavtaleByTilbyderId,
  // getToastDeleteText,
} from 'utils/betalingsavtaler.util';
// import { deleteEfakturaAvtale } from '../avtaleadministrasjon.service';
import EfakturaFormContainer from './EfakturaFormContainer';
import BetalingsavtaleStyled from '../BetalingsavtaleStyled';

const EfakturaUpdatepage = ({
  params,
  betalingsavtaler,
  // changeData,
  // onRegisterMessage,
  history,
}) => {
  if (betalingsavtaler.status.received) {
    const betalingsavtale = getBetalingsavtaleByTilbyderId(
      betalingsavtaler.data,
      params.tilbyderId,
    );
    if (!betalingsavtale) {
      return <p>Fant ikke betalingsavtale med ID: {params.tilbyderId}</p>;
    }
    return (
      <BetalingsavtaleStyled>
        <BasePage heading="Endre eFaktura" className="p-betalingsavtale">
          <EfakturaFormContainer
            history={history}
            tilbyderId={params ? params.tilbyderId : undefined}
            submitButtonText="Lagre"
            initialValues={betalingsavtale.efaktura.values}
          />
          <div className="p-betalingsavtale__action-list-wrapper">
            {/* <ActionList
              className="action-list action-list--is-not-responsive"
              actions={[]}
              deleteErrorMessage={'Error'}
              onDeleteClick={() => {
                onRegisterMessage({
                  text: getToastDeleteText(betalingsavtale),
                });
                deleteEfakturaAvtale(betalingsavtaler, changeData, params.tilbyderId);
                history.push(EFAKTURA_AND_AVTALEGIRO);
              }}
              onCloseAlertClick={() => console.log('onCloseAlertClick')}
            /> */}
          </div>
        </BasePage>
      </BetalingsavtaleStyled>
    );
  }
  return <p>Loading</p>;
};

EfakturaUpdatepage.defaultProps = {
  params: undefined,
};

EfakturaUpdatepage.propTypes = {
  history: historyShape.isRequired,
  params: PropTypes.shape({
    tilbyderId: PropTypes.string,
  }),
  betalingsavtaler: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  // changeData: PropTypes.func.isRequired,
  // onRegisterMessage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
  onRegisterMessage: bindActionCreators(registerMessage, dispatch),
});

const mapStateToProps = state => ({
  betalingsavtaler: dataSelector(state, 'betalingsavtaler'),
});

export default connect(mapStateToProps, mapDispatchToProps)(EfakturaUpdatepage);
