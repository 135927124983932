/* eslint-disable no-mixed-operators */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Spinner from 'digitalbank-spinner';
import { TYPE } from 'digitalbank-mockup-data/lib/Transactions';
import { formatNumber } from 'utils/format.util';
import Accordion from 'digitalbank-accordion';
import TransactionItemHeader from 'digitalbank-historikk-web/lib/es/features/search/TransactionItemHeader';
import TransactionItemContent from 'digitalbank-historikk-web/lib/es/features/search/TransactionItemContent';

const getDisplayType = (type) => {
  if (type === TYPE.BETALING_MED_KID || type === TYPE.BETALING_MED_MELDING) {
    return 'Betaling';
  }
  if (type === TYPE.AVTALEGIRO_OG_EFAKTURA) {
    return 'Avtalegiro og eFaktura';
  }
  if (type === TYPE.EFAKTURA) {
    return 'eFaktura';
  }
  return type;
};

const SearchResultCollapse = ({
  transactions,
  isPending,
  isFilterOpen,
  onChange,
  getTransactions,
  values,
  noResultInputValue,
}) => {
  const hasSuggestion =
    values.account ||
    (values.fromDate && values.toDate) ||
    (values.fromAmount && values.toAmount) ||
    values.transactionDirection !== 'ALL';
  const sumOut =
    transactions && transactions.length > 0
      ? transactions.reduce((acc, value) => {
        if (
          value.beloep.verdi < 0 &&
            (!values.account || values.account.accountNumber !== value.tilKonto)
        ) {
          return acc + value.beloep.verdi;
        }
        return acc;
      }, 0)
      : 0;
  const sumIn =
    transactions && transactions.length > 0
      ? transactions.reduce((acc, value) => {
        if (value.beloep.verdi > 0) {
          return acc + value.beloep.verdi;
        } else if (
          value.type === TYPE.OVERFOERING &&
            (!values.account || values.account.accountNumber === value.tilKonto)
        ) {
          return acc + value.beloep.verdi * -1;
        }
        return acc;
      }, 0)
      : 0;
  return (
    <div
      className={classNames('transactions', {
        'transactions--has-no-result':
          transactions && transactions.length === 0,
        'transactions--is-pending': isPending,
        'transactions--is-filter-open': isFilterOpen,
      })}
    >
      {isPending && (
        <div className="transactions__spinner">
          <Spinner large centered />
        </div>
      )}
      {(!transactions || transactions.length === 0) &&
        !isPending && (
          <div className="info-card">
            <p
              className="info-card__heading"
              style={{
                display: 'block',
                margin: '.75rem auto',
                padding: '0',
                maxWidth: '400px',
                width: '100%',
                textAlign: hasSuggestion ? 'left' : 'center',
              }}
            >
              {noResultInputValue && (
                <span>
                  Ditt søk -{' '}
                  <i style={{ textDecoration: 'underline' }}>
                    {noResultInputValue}
                  </i>{' '}
                  - ga ingen treff
                </span>
              )}
              {!noResultInputValue && <span>Ingen Treff</span>}
            </p>
            {hasSuggestion && (
              <p
                style={{
                  display: 'block',
                  margin: '0 auto',
                  padding: '0',
                  maxWidth: '400px',
                  width: '100%',
                }}
              >
                Forslag:
              </p>
            )}
            <ul
              style={{
                display: 'block',
                margin: '0 auto',
                padding: '0 18px',
                maxWidth: '400px',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              {values.account && (
                <li>
                  <button
                    className="search-filter__fieldset-btn"
                    style={{ margin: '0', textAlign: 'left' }}
                    onClick={() => {
                      onChange('searchForm', 'account', '');
                      getTransactions({ ...values, account: '' });
                    }}
                  >
                    Søk i alle kontoer
                  </button>
                </li>
              )}
              {values.fromDate &&
                values.toDate && (
                  <li>
                    <button
                      className="search-filter__fieldset-btn"
                      style={{ margin: '0', textAlign: 'left' }}
                      onClick={() => {
                        onChange('searchForm', 'fromDate', '');
                        onChange('searchForm', 'toDate', '');
                        getTransactions({
                          ...values,
                          fromDate: '',
                          toDate: '',
                        });
                      }}
                    >
                      Søk uten valgt periode
                    </button>
                  </li>
                )}
              {values.fromAmount &&
                values.toAmount && (
                  <li>
                    <button
                      className="search-filter__fieldset-btn"
                      style={{ margin: '0', textAlign: 'left' }}
                      onClick={() => {
                        onChange('searchForm', 'fromAmount', '');
                        onChange('searchForm', 'toAmount', '');
                        getTransactions({
                          ...values,
                          fromAmount: '',
                          toAmount: '',
                        });
                      }}
                    >
                      Søk uten valgt beløp
                    </button>
                  </li>
                )}
              {values.transactionDirection !== 'ALL' && (
                <li>
                  <button
                    className="search-filter__fieldset-btn"
                    style={{ margin: '0', textAlign: 'left' }}
                    onClick={() => {
                      onChange('searchForm', 'transactionDirection', 'ALL');
                      getTransactions({
                        ...values,
                        transactionDirection: 'ALL',
                      });
                    }}
                  >
                    Søk i alle transaksjoner
                  </button>
                </li>
              )}
            </ul>
          </div>
        )}
      {!isPending &&
        transactions && (
          <Accordion
            items={transactions.map(transaction => ({
              id: transaction.id,
              header: (
                <TransactionItemHeader
                  transaction={{
                    ...transaction,
                    beskrivelse: transaction.description,
                    kategori: getDisplayType(transaction.type),
                    isBokfoert: true,
                    bokfoeringsdato: transaction.dato,
                    beloep: {
                      ...transaction.beloep,
                      verdiNok: transaction.beloep
                        ? transaction.beloep.verdi
                        : 0,
                    },
                  }}
                />
              ),
              content: (
                <TransactionItemContent
                  transaction={{
                    ...transaction,
                    beskrivelse: transaction.description,
                    kategori: getDisplayType(transaction.type),
                    isBokfoert: true,
                    bokfoeringsdato: transaction.dato,
                    beloep: {
                      ...transaction.beloep,
                      verdiNok: transaction.beloep
                        ? transaction.beloep.verdi
                        : 0,
                    },
                    fraKontonummer: transaction.fraKonto,
                    tilKontonummer: transaction.tilKonto,
                  }}
                  getDetails={() => {}}
                />
              ),
            }))}
          />
        )}
      {!isPending &&
        transactions.length > 0 && (
          <div className="sum-transactions">
            {transactions.length > 10 && (
              <div>
                <span className="sum-transactions__text sum-transactions__text--bolder">
                  {`Totalt ${transactions.length} transaksjoner`}
                </span>
              </div>
            )}
            {sumOut < 0 && (
              <div>
                <span className="sum-transactions__text sum-transactions__text--bolder">
                  Ut:
                </span>
                <span className="sum-transactions__text">
                  {' '}
                  {formatNumber(sumOut)}
                </span>
              </div>
            )}
            {sumIn > 0 && (
              <div>
                <span className="sum-transactions__text sum-transactions__text--bolder">
                  Inn:
                </span>
                <span className="sum-transactions__text">
                  {' '}
                  {formatNumber(sumIn)}
                </span>
              </div>
            )}
          </div>
        )}
    </div>
  );
};

SearchResultCollapse.defaultProps = {
  transactions: undefined,
};

SearchResultCollapse.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({})),
  isPending: PropTypes.bool.isRequired,
  isFilterOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  getTransactions: PropTypes.func.isRequired,
  values: PropTypes.shape({}).isRequired,
  noResultInputValue: PropTypes.string.isRequired,
};

export default SearchResultCollapse;
