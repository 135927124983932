import React from 'react';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';

export default () => (
  <NavList
    style={{ marginBottom: '20px' }}
    items={[
      <NavListLink
        to={PATHS.SEARCH}
        title={{ text: 'Se siste bevegelser' }}
        component={Link}
        key="siste-bevegelser"
      />,
      <NavListLink
        to={PATHS.SEARCH}
        title={{ text: 'Betal inn på lån' }}
        component={Link}
        key="innbetaling"
      />,
      <NavListLink
        to={PATHS.SEARCH}
        title={{ text: 'Øk terminbeløp' }}
        component={Link}
        key="oek-terminbeloep"
      />,
      <NavListLink
        to={PATHS.SEARCH}
        title={{ text: 'Endre forfallsdato' }}
        component={Link}
        key="forfallsdato"
      />,
      <NavListLink
        to={PATHS.SEARCH}
        title={{ text: 'Endre betalingskonto' }}
        component={Link}
        key="betalingskonto"
      />,
      <NavListLink
        to={PATHS.SEARCH}
        title={{ text: 'Søk om avdragsfrihet' }}
        component={Link}
        key="avdragsfrihet"
      />,
      <NavListLink
        to={PATHS.SEARCH}
        title={{ text: 'Disposisjonsrett' }}
        component={Link}
        key="disposisjonsrett"
      />,
    ]}
  />
);
