import React from 'react';
import PropTypes from 'prop-types';
import { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { connect } from 'react-redux';
import dataSelector from 'redux-helpers/dataSelector';
import { getNotifications } from 'utils/notifications.util';

const getScheduledPaymentsTitle = (notifications, title) => {
  if (
    !notifications ||
    !notifications.scheduledPayments ||
    notifications.scheduledPayments.count === 0
  ) {
    return title;
  }

  return (
    <span>
      {title}
      <div
        style={{
          display: 'inline-block',
          marginLeft: '10px',
        }}
      >
        <div className="badge badge--new">
          {notifications.scheduledPayments.count}
        </div>
      </div>
    </span>
  );
};

const ScheduledPaymentNavListLinkContainer = ({ transactions }) => {
  const notifications = getNotifications(transactions);
  const scheduledPaymentsTitle = getScheduledPaymentsTitle(
    notifications,
    'Forfallsmappe',
  );
  return (
    <NavListLink
      component={Link}
      to={PATHS.SCHEDULED_PAYMENTS}
      title={{ text: scheduledPaymentsTitle }}
      key="forfallsmappe"
    />
  );
};

const mapStateToProps = state => ({
  transactions: dataSelector(state, 'transactions'),
});

ScheduledPaymentNavListLinkContainer.propTypes = {
  transactions: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
    status: PropTypes.shape({
      received: PropTypes.bool,
    }),
  }).isRequired,
};

export default connect(mapStateToProps)(ScheduledPaymentNavListLinkContainer);
