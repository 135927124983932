import styled from 'styled-components';
import { spacing } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-card {
    &__heading,
    &__main,
    &__aside {
      ${horizontalLayoutSpacing};
    }

    &__aside {
      @media (max-width: $first-breakpoint-value - 1) {
        margin-top: ${rem(spacing.layoutSpacing.sm)};
      }
    }
  }
`;
