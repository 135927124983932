import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import { BasePageStyled } from 'styled';
import CreateAutomaticTagFormContainer from '../CreateAutomaticTagFormContainer';

const Page = ({ history }) => (
  <BasePageStyled>
    <BasePage heading="Min reise">
      <CreateAutomaticTagFormContainer isTravelTag history={history} />
    </BasePage>
  </BasePageStyled>
);

Page.propTypes = {
  history: PropTypes.shape({}).isRequired,
};
export default Page;
