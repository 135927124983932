import styled from 'styled-components';
import { breakpoints } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { formMaxWidthValue } from 'digitalbank-core-style/lib/sizes';
import { rem } from 'polished';

export default styled.div`
  .p-fast-betaling {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__footer {
      &-content {
        @media (min-width: ${breakpoints.tablet}px) {
          max-width: $form-max-width;
          width: 70%;
        }

        &--full-width {
          max-width: none;
        }

        .published-content {
          &:first-of-type {
            @include spacing(margin, (top));
          }
        }
      }
    }

    &__aside {
      ${horizontalLayoutSpacing};

      &--summary-align {
        @media (min-width: ${breakpoints.tablet}px) {
          /* Align with summary on desktop */
          margin-top: 0;
        }
      }

      &--form-align {
        @media (min-width: ${breakpoints.tablet}px) {
          /* Align with form on desktop */
          margin-top: ${rem(24)};
        }
      }
    }

    .link-group {
      ${horizontalLayoutSpacing};
      display: flex;
      justify-content: center;

      &__item {
        padding: ${rem(5)} ${rem(20)};
      }

      @media (min-width: ${breakpoints.tablet}px) {
        justify-content: flex-start;
        padding-top: 0;
      }
    }

    .payment-form {
      padding-top: ${rem(15)};
      margin-bottom: ${rem(40)};
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${formMaxWidthValue}px;
      }
    }

    .action-list {
      @media (min-width: ${breakpoints.tablet}px) {
        margin: 0 ${rem(20)} ${rem(40)};
        max-width: 510px;
      }
    }

    .nav-list {
      ${horizontalLayoutSpacing};
      padding-top: ${rem(20)};
      margin-bottom: ${rem(20)};
    }
  }
`;
