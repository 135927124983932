import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import historyShape from 'shapes/history.shape';
import FormContainer from './FormContainer';
import BetalingsavtaleStyled from '../BetalingsavtaleStyled';

const CreatePage = ({ params, history }) => (
  <BetalingsavtaleStyled>
    <BasePage heading="Opprett betalingsavtale" className="p-betalingsavtale">
      <FormContainer
        history={history}
        tilbyderId={params ? params.tilbyderId : undefined}
        submitButtonText="Opprett avtaler"
      />
    </BasePage>
  </BetalingsavtaleStyled>
);

CreatePage.defaultProps = {
  params: undefined,
};

CreatePage.propTypes = {
  history: historyShape.isRequired,
  params: PropTypes.shape({
    tilbyderId: PropTypes.string,
  }),
};

export default CreatePage;
