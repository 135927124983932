import React from 'react';
import { Link } from 'react-router-dom';
import { getFrekvensString } from 'utils/frekvens.util';
import DateTime from 'spv-datetime';
import DetailSectionListItem from 'digitalbank-detail-section/lib/components/DetailSection/DetailSectionListItem';
import DetailSectionList from 'digitalbank-detail-section/lib/components/DetailSection/DetailSectionList';
import {
  TYPE as TRANSACTION_TYPE,
  STATUS as TRANSACTION_STATUS,
} from 'digitalbank-mockup-data/lib/Transactions';
import * as PATH from 'routes/paths';
import ActionList, { ActionLink, DeleteButton } from 'digitalbank-action-list';
import { Pencil, Clock } from 'digitalbank-icons';
import i18n from 'i18n';
import { formatAccountNumber } from 'utils/format.util';
import { AccordionItemHeader } from 'digitalbank-accordion';

export default (
  {
    id, frekvens, beloep, tilKonto, tilKontonavn, melding, dato, type, status,
  },
  onDelete,
) => {
  const beloepPrint = (Number(beloep.verdi) * -1).toLocaleString('nb-NO', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const info = i18n(
    type === TRANSACTION_TYPE.FAST_OVERFOERING
      ? i => i.FAST_OPPDRAG_NESTE_OVERFOERING
      : i => i.FAST_OPPDRAG_NESTE_BETALING,
    {
      frekvens: getFrekvensString(frekvens),
      dato: DateTime.getVerboseDateForBetaling(DateTime.parseIsoDate(dato)),
    },
  );
  const editHref =
    type === TRANSACTION_TYPE.FAST_BETALING
      ? `${PATH.FAST_BETALING_EDIT}/${id}`
      : `${PATH.FAST_OVERFOERING_EDIT}/${id}`;

  const actions = [
    <ActionLink
      href={editHref}
      title="Endre"
      key="edit"
      RouterLink={Link}
      Icon={Pencil}
    />,
    <ActionLink
      href={PATH.SEARCH}
      title="Historikk"
      key="history"
      RouterLink={Link}
      Icon={Clock}
    />,
    <DeleteButton
      key="delete"
      value="Slett"
      i18nTranslate={(template, format) => i18n(template, format)}
      isDeletePending={status === TRANSACTION_STATUS.SHOULD_BE_DELETED}
      onDeleteConfirmed={onDelete}
      // eslint-disable-next-line
      onDeleteErrorClose={() => console.log('onDeleteErrorClose click')}
      showDeleteError={false}
      deleteError="Error"
    />,
  ];

  return {
    id,
    header: (
      <AccordionItemHeader
        heading={{ description: tilKontonavn || tilKonto, info }}
        info={beloepPrint}
      />
    ),
    content: (
      <div>
        <div className="general-list-item-detail-section">
          <div className="detail-section">
            <DetailSectionList>
              {tilKonto && (
                <DetailSectionListItem
                  label="Til konto"
                  value={formatAccountNumber(tilKonto)}
                />
              )}
              {melding && (
                <DetailSectionListItem label="Melding" value={melding} />
              )}
            </DetailSectionList>
          </div>
        </div>
        <ActionList actions={actions} />
      </div>
    ),
  };
};
