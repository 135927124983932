import styled from 'styled-components';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';

export default styled.div`
  .p-general {
    &__heading,
    &__main {
      ${horizontalLayoutSpacing};
    }
  }
`;
