/* eslint-disable no-mixed-operators */
import React from 'react';
import PropTypes from 'prop-types';
import { MultiGrid, AutoSizer } from 'react-virtualized';
import { getNumberOfPeriodsLeftInCurrentYear } from 'utils/loan.util';

const colors = {
  headerBackground: '#eee',
  tableHead: '#444',
  tableCell: '#666',
};
const shadows = {
  insetRight: 'inset 10px 0 10px -8px #ddd',
  insetLeft: 'inset -10px 0 10px -8px #ddd',
};

const border = '1px solid #dbdbdb';

const defaultStyle = {
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  fontFamily: 'Roboto',
  paddingLeft: '10px',
  paddingRight: '10px',
};

const numberOfPeriodsLeftInCurrentYear = getNumberOfPeriodsLeftInCurrentYear();
const numberOfDetailedYears = 1;
const isSummaryRow = rowIndex =>
  (numberOfPeriodsLeftInCurrentYear > 0 &&
    rowIndex === numberOfPeriodsLeftInCurrentYear + 1) ||
  rowIndex === 13 ||
  (rowIndex > numberOfPeriodsLeftInCurrentYear + 1 &&
    (rowIndex - (numberOfPeriodsLeftInCurrentYear + 1)) % 13 === 0) ||
  rowIndex >
    numberOfPeriodsLeftInCurrentYear +
      numberOfDetailedYears * 12 +
      numberOfDetailedYears;

const isFirstRow = rowIndex => rowIndex === 0;
const isFirstColumn = columnIndex => columnIndex === 0;

const cellRenderer = ({
  columnIndex, key, rowIndex, style,
}, list) => {
  const cellStyle = {
    ...style,
    ...defaultStyle,
  };
  if (isSummaryRow(rowIndex)) {
    cellStyle.fontWeight = '600';
    if (
      rowIndex % 2 === 1 ||
      rowIndex <= numberOfPeriodsLeftInCurrentYear + 12 * numberOfDetailedYears
    ) {
      cellStyle.borderTop = border;
      cellStyle.borderBottom = border;
    }
  }
  // else if (rowIndex > 0 && rowIndex % 2 === 0) {
  //   cellStyle.backgroundColor = '#f8f8f8';
  //   cellStyle.zIndex = -1;
  // }
  if (isFirstRow(rowIndex)) {
    cellStyle.color = colors.tableHead;
  } else {
    cellStyle.color = colors.tableCell;
  }
  if (!isFirstColumn(columnIndex)) {
    cellStyle.justifyContent = 'flex-end';
  }
  return (
    <div key={key} style={cellStyle}>
      {list[rowIndex][columnIndex]}
    </div>
  );
};

cellRenderer.propTypes = {
  columnIndex: PropTypes.number.isRequired,
  key: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
  style: PropTypes.shape({}).isRequired,
};

const getGridHeight = (rowHeight, listLength) => {
  if (window.innerWidth >= 768) {
    return listLength * rowHeight;
  }
  return 600;
};

const isScrollable = (width, totalWidth) => width < totalWidth;
const HistoryTable = ({ list }) => (
  <AutoSizer disableHeight>
    {({ width }) => {
      const scrollable = isScrollable(width, 120 * list[0].length);
      const boxShadow = scrollable
        ? `${shadows.insetRight}, ${shadows.insetLeft}`
        : 'none';
      const rowHeight = 36;
      return (
        <MultiGrid
          cellRenderer={e => cellRenderer(e, list)}
          columnCount={list[0].length}
          columnWidth={scrollable ? 120 : width / 6}
          height={getGridHeight(rowHeight, list.length)}
          rowCount={list.length}
          fixedColumnCount={1}
          fixedRowCount={1}
          rowHeight={rowHeight}
          width={width}
          style={{
            border,
          }}
          styleTopLeftGrid={{
            background: colors.headerBackground,
            borderBottom: border,
          }}
          styleTopRightGrid={{
            background: colors.headerBackground,
            borderBottom: border,
            boxShadow,
          }}
          styleBottomRightGrid={{
            boxShadow,
          }}
        />
      );
    }}
  </AutoSizer>
);

HistoryTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default HistoryTable;
