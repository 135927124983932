import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ReceiptPage } from 'digitalbank-layout';
import NavList, { NavListLink } from 'digitalbank-nav-list';
import { Link } from 'react-router-dom';
import * as PATHS from 'routes/paths';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormValues, isPristine, destroy } from 'redux-form';
import wizardPage from 'components/wizardPage';
import styled from 'styled-components';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

const BankIDMobileOrderReceipt = styled.div`
  .nav-list {
    ${horizontalLayoutSpacing};
    padding-top: ${rem(20)};
    margin-bottom: ${rem(20)};
  }
`;

class BankIDMobilOrderReceipt extends Component {
  componentWillUnmount() {
    this.props.onDestroy('bankIDMobileOrderForm');
  }
  render() {
    const { formValues } = this.props;
    return (
      <BankIDMobileOrderReceipt>
        <ReceiptPage
          className="p-bankid-mobile-order-receipt"
          receiptType="success"
          receiptTitle="BankID på mobil er bestilt"
          receiptDescription={
            <p>
              Du vil straks motta en SMS til <b>{formValues.phone}</b> når
              tjenesten er klar til bruk.
            </p>
          }
        >
          <NavList
            items={[
              <NavListLink
                component={Link}
                to={PATHS.SETTINGS}
                title={{ text: 'Innstillinger' }}
                key="innstillinger"
              />,
            ]}
          />
        </ReceiptPage>
      </BankIDMobileOrderReceipt>
    );
  }
}

BankIDMobilOrderReceipt.propTypes = {
  formValues: PropTypes.shape({}).isRequired,
  onDestroy: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  formValues: getFormValues('bankIDMobileOrderForm')(state),
});

const mapDispatchToProps = dispatch => ({
  onDestroy: bindActionCreators(destroy, dispatch),
});

export default wizardPage({
  redirectTest: state => isPristine('bankIDMobileOrderForm')(state),
  redirectPath: PATHS.BANKID_MOBILE_ORDER,
})(connect(mapStateToProps, mapDispatchToProps)(BankIDMobilOrderReceipt));
