/* eslint-disable react/no-danger, max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  reduxForm,
  Field,
  getFormValues,
  change as reduxFormChange,
} from 'redux-form';
import { InputField } from 'digitalbank-form';
import * as PATH from 'routes/paths';
import dataSelector from 'redux-helpers/dataSelector';
import historyShape from 'shapes/history.shape';
import { getBetalingsavtaleTilbyderById } from 'utils/betalingsavtaler.util';
import { change } from 'redux-helpers/data.actions';
import { DetailSection, DetailSectionListItem } from 'components';
import { registerMessage } from 'actions/messages.actions';
import {
  createEfaktura,
  getIndexOfCurrentBetalingsavtale,
} from '../avtaleadministrasjon.service';
import ReferenceField from '../Fields/ReferenceField';
import EmailField from '../Fields/EmailField';

class EfakturaFormContainer extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   help: {
    //     reference: false,
    //   },
    // };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit() {
    createEfaktura(
      this.props.betalingsavtaler,
      this.props.changeData,
      this.props.tilbyderId,
      this.props.formValues,
    );
    const existInBetalingsAvtaler =
      getIndexOfCurrentBetalingsavtale(
        this.props.betalingsavtaler,
        this.props.tilbyderId,
      ) > -1;
    this.props.onRegisterMessage({
      text: existInBetalingsAvtaler
        ? 'eFaktura oppdatert'
        : 'eFaktura opprettet',
    });
    this.props.history.push(PATH.EFAKTURA_AND_AVTALEGIRO);
  }
  render() {
    const {
      submitButtonText,
      initialValues,
      tilbyderId,
      betalingsavtaleTilbydere,
    } = this.props;
    const tilbyder = tilbyderId
      ? getBetalingsavtaleTilbyderById(
        betalingsavtaleTilbydere.data,
        tilbyderId,
      )
      : undefined;
    return (
      <form
        onSubmit={this.props.handleSubmit(() => this.handleSubmit())}
        className="betalingsavtale-form"
      >
        {tilbyder && (
          <DetailSection
            heading={tilbyder.name}
            className="efaktura-form__details"
          >
            {initialValues &&
              initialValues.reference && (
                <DetailSectionListItem
                  label="Referanse"
                  value={initialValues.reference}
                />
              )}
          </DetailSection>
        )}
        {(!initialValues || !initialValues.reference) && (
          <ReferenceField
            state={this.state}
            setState={el => this.setState(el)}
          />
        )}
        <Field
          name="description"
          label="Egen kommentar (valgfritt)"
          component={InputField}
          type="text"
          className="form-group--with-help-button-indent"
        />
        <EmailField
          initialValues={initialValues}
          formAlias="efakturaForm"
          changeFieldValue={this.props.changeFieldValue}
        />
        <div className="efaktura-form__submit-wrapper">
          <button
            type="submit"
            className="button button--primary efaktura-form__submit-button"
          >
            {submitButtonText}
          </button>
        </div>
      </form>
    );
  }
}

EfakturaFormContainer.defaultProps = {
  initialValues: null,
  tilbyderId: undefined,
  formValues: null,
  handleSubmit: undefined,
};

EfakturaFormContainer.propTypes = {
  history: historyShape.isRequired,
  initialValues: PropTypes.shape({}),
  tilbyderId: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  betalingsavtaleTilbydere: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  betalingsavtaler: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  changeData: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  formValues: PropTypes.shape({}),
  handleSubmit: PropTypes.func,
  onRegisterMessage: PropTypes.func.isRequired,
};

const validate = (values) => {
  const errors = {};
  if (!values.efakturaReference) {
    errors.efakturaReference = 'eFaktura-referanse er nødvendig';
  }
  return errors;
};

const form = reduxForm({
  destroyOnUnmount: true,
  form: 'efakturaForm',
  validate,
})(EfakturaFormContainer);

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
  changeFieldValue: bindActionCreators(reduxFormChange, dispatch),
  onRegisterMessage: bindActionCreators(registerMessage, dispatch),
});

const mapStateToProps = state => ({
  betalingsavtaleTilbydere: dataSelector(state, 'betalingsavtaleTilbydere'),
  betalingsavtaler: dataSelector(state, 'betalingsavtaler'),
  formValues: getFormValues('efakturaForm')(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(form);
