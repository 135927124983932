import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChevronLeft } from 'digitalbank-icons';
import { ButtonResetStyle } from 'styled/Buttons';
import { getCategoryColor } from '../utils';
import CategoryIcon from './CategoryIcon';

const EconomyHeadingStyled = styled.h2`
  /* font-size: 18px; */
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.2;
  height: 44px; /*We need this to keep height consistant when changing between main category and other categories */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const EconomyBackIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  color: #777;

  i {
    width: 100%;
    height: 100%;
    display: flex;
  }

  svg {
    display: flex;
  }
`;

const EconomyCategoryIcon = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 5px;
  background: ${props => props.backgroundColor};
  border-radius: 50%;
  padding: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    display: flex;
    width: 14px;
  }
`;

const EconomyCategoryHeadingButtonStyled = styled(ButtonResetStyle)`
  text-transform: inherit;
  padding: 0.5rem 1rem;

  &:hover,
  &:focus {
    background: #ddd;
  }
`;
const EconomyCategoryHeadingButtonContentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EconomyHeading = ({
  mainCategory,
  category,
  subCategory,
  setCategory,
}) => {
  if (subCategory || category) {
    return (
      <EconomyHeadingStyled
        onClick={() => {
          setCategory({
            mainCategory,
            category: subCategory ? category : '',
            subCategory: '',
          });
        }}
      >
        <EconomyCategoryHeadingButtonStyled>
          <EconomyCategoryHeadingButtonContentStyled>
            <EconomyBackIcon>
              <ChevronLeft />
            </EconomyBackIcon>
            <EconomyCategoryIcon
              backgroundColor={getCategoryColor(category || '')}
            >
              <CategoryIcon categoryAlias={category} />
            </EconomyCategoryIcon>
            {subCategory || category}
          </EconomyCategoryHeadingButtonContentStyled>
        </EconomyCategoryHeadingButtonStyled>
      </EconomyHeadingStyled>
    );
  }
  return (
    <EconomyHeadingStyled>
      {mainCategory === 'Utgifter' ? 'Mine utgifter' : mainCategory}
    </EconomyHeadingStyled>
  );
};

EconomyHeading.defaultProps = {
  mainCategory: '',
  category: '',
  subCategory: '',
};
EconomyHeading.propTypes = {
  mainCategory: PropTypes.string,
  category: PropTypes.string,
  subCategory: PropTypes.string,
  setCategory: PropTypes.func.isRequired,
};
export default EconomyHeading;
