import PropTypes from 'prop-types';

const RecipientShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  account: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  // display: PropTypes.bool.isRequired,
});

export default RecipientShape;
