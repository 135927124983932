import { TOGGLE_AUTOMATIC_EFAKTURA } from './avtaleadministrasjon.constants';

export default (state = false, action = {}) => {
  switch (action.type) {
    case TOGGLE_AUTOMATIC_EFAKTURA:
      return !state.automaticEfakturaIsActive;
    default:
      return state;
  }
};
