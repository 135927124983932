import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field, Fields, change as changeFieldAction } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'i18n';
import { InputField } from 'digitalbank-form';
import Accounts from 'digitalbank-mockup-data/lib/Accounts';
import AmountAndDateRow from 'components/Form/AmountAndDateRow';
import AmountIncreaserField from 'components/Form/AmountIncreaserField';
import _ from 'lodash';
import Button from 'digitalbank-buttons';
import AccountPickerField from 'digitalbank-form/lib/es/components/AccountPickerField';
import {
  receiveAccountList,
  requestAccountList,
  selectAccountItem,
  resetAccountPicker,
} from 'digitalbank-account-picker/lib/es/components/AccountPicker/accountPicker.actions';
import { isAccountPickerFetchingAccounts } from 'utils/account.util';
import { getFrekvensList } from 'utils/frekvens.util';
import OppdragFrekvensSelect from 'routes/Payment/FormFields/OppdragFrekvensSelect';

const getDateLabel = (isEditing, isFastOverfoering) => {
  if (!isEditing && isFastOverfoering) return 'Første forfall';
  if (isEditing && isFastOverfoering) return 'Neste forfall';
  return 'Dato';
};

class TransferForm extends Component {
  constructor(props) {
    super(props);
    this.initializeAccounts = this.initializeAccounts.bind(this);
    this.setSelectedAccounts = this.setSelectedAccounts.bind(this);
    this.fraKontoAccountPickerKey = `${this.props.formName}FraKonto`;
    this.tilKontoAccountPickerKey = `${this.props.formName}TilKonto`;
    this.onAmountClicked = this.onAmountClicked.bind(this);
  }
  componentWillMount() {
    this.initializeAccounts();
    if (this.props.initialValues) {
      this.setSelectedAccounts(this.props.initialValues);
    }
  }
  componentWillUpdate(nextProps) {
    if (!this.props.initialValues && nextProps.initialValues) {
      this.setSelectedAccounts(nextProps.initialValues);
    }
  }
  componentWillUnmount() {
    this.props.onResetAccountPicker(this.fraKontoAccountPickerKey);
    this.props.onResetAccountPicker(this.tilKontoAccountPickerKey);
  }
  onAmountClicked(amount) {
    const newNumber = Number(
      amount + Number(this.props.formValues.kroner),
    ).toString();
    this.props.changeFieldValue(this.props.formName, 'kroner', newNumber);
  }
  setSelectedAccounts({ fraKonto, tilKonto }) {
    if (fraKonto) {
      this.props.onSelectAccountItem(this.fraKontoAccountPickerKey, {
        accountNumber: fraKonto,
      });
    }
    if (tilKonto) {
      this.props.onSelectAccountItem(this.tilKontoAccountPickerKey, {
        accountNumber: tilKonto,
      });
    }
  }
  initializeAccounts() {
    this.props.onRequestAccounts(this.fraKontoAccountPickerKey);
    this.props.onRequestAccounts(this.tilKontoAccountPickerKey);
    setTimeout(() => {
      const accountResult = {
        kontoer: _.filter(Accounts, x => x.active && x.availableAmount > 0),
      };
      this.props.onReceiveAccounts(
        this.fraKontoAccountPickerKey,
        accountResult,
      );
      this.props.onReceiveAccounts(
        this.tilKontoAccountPickerKey,
        accountResult,
      );
    }, 1000);
  }
  render() {
    const {
      children,
      accountPickers,
      isFastOverfoering,
      submitButtonText,
      isEditing,
      isDisabled,
      isSubmitting,
    } = this.props;
    const isFetchingToAccounts = isAccountPickerFetchingAccounts(
      accountPickers,
      this.tilKontoAccountPickerKey,
    );
    const isFetchingFromAccounts = isAccountPickerFetchingAccounts(
      accountPickers,
      this.fraKontoAccountPickerKey,
    );
    const datoLabel = getDateLabel(isEditing, isFastOverfoering);
    const isSubmitDisabled = isFetchingToAccounts || isFetchingFromAccounts;
    return (
      <form className="transfer-form" onSubmit={this.props.handleSubmit}>
        {isFastOverfoering && (
          <OppdragFrekvensSelect
            disabled={isDisabled}
            frekvenser={getFrekvensList()}
            label={i18n(i => i.FREKVENS_TITTEL_BETALING)}
          />
        )}
        <Field
          name="fraKonto"
          disabled={isDisabled}
          accountpickerId={this.fraKontoAccountPickerKey}
          component={AccountPickerField}
          i18nTranslate={(template, format) => i18n(template, format)}
          isFetchingAccounts={isFetchingFromAccounts}
          type="text"
          label="Fra konto"
          defaultChoiceMessage="Velg fra-konto"
        />
        <Field
          name="tilKonto"
          disabled={isDisabled}
          accountpickerId={this.tilKontoAccountPickerKey}
          component={AccountPickerField}
          i18nTranslate={(template, format) => i18n(template, format)}
          isFetchingAccounts={isFetchingToAccounts}
          type="text"
          label="Til konto"
          defaultChoiceMessage="Velg til-konto"
        />
        <Field
          name="melding"
          disabled={isDisabled}
          label="Tekst på kontoutskrift"
          component={InputField}
          type="text"
          autoComplete="off"
        />
        <Fields
          names={['kroner', 'oere', 'dato']}
          disabled={isDisabled}
          component={AmountAndDateRow}
          kronerLabel="Kroner"
          oereLabel="Øre"
          datoLabel={datoLabel}
        />
        <AmountIncreaserField
          onAmountClick={this.onAmountClicked}
          disabled={isDisabled}
        />

        {!isDisabled && (
          <Button
            type="submit"
            variant="primary"
            className="payment-form__complete-btn"
            rightIcon={
              isSubmitting ? <div className="button__spinner" /> : null
            }
            disabled={isSubmitDisabled}
          >
            {submitButtonText}
          </Button>
        )}
        {children}
      </form>
    );
  }
}

TransferForm.propTypes = {
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  submitButtonText: PropTypes.string,
  isFastOverfoering: PropTypes.bool,
  isEditing: PropTypes.bool,
  formName: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  onReceiveAccounts: PropTypes.func.isRequired,
  onRequestAccounts: PropTypes.func.isRequired,
  onSelectAccountItem: PropTypes.func.isRequired,
  onResetAccountPicker: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    kroner: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  accountPickers: PropTypes.shape({
    fromAccountNumber: PropTypes.shape({
      isFetchingAccounts: PropTypes.bool,
    }),
    toAccountNumber: PropTypes.shape({
      isFetchingAccounts: PropTypes.bool,
    }),
  }).isRequired,
  initialValues: PropTypes.shape({
    fraKonto: PropTypes.string,
    tilKonto: PropTypes.string,
  }),
};

TransferForm.defaultProps = {
  children: null,
  isDisabled: false,
  isFastOverfoering: false,
  isEditing: false,
  isSubmitting: false,
  submitButtonText: 'Overfør',
  initialValues: null,
};

const mapStateToProps = state => ({
  accountPickers: state.accountPickers,
});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: bindActionCreators(changeFieldAction, dispatch),
  onReceiveAccounts: bindActionCreators(receiveAccountList, dispatch),
  onRequestAccounts: bindActionCreators(requestAccountList, dispatch),
  onSelectAccountItem: bindActionCreators(selectAccountItem, dispatch),
  onResetAccountPicker: bindActionCreators(resetAccountPicker, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferForm);
