import _ from 'lodash';

export default (state = [], action = {}) => {
  switch (action.type) {
    case 'EDIT_ACCOUNT_NAMES/START_INSTANCE': {
      // Check if instance already exist
      const indexOfInstance = _.findIndex(
        state,
        x => x.accountId === action.accountId,
      );
      if (indexOfInstance > -1) {
        const currentSavingInsances = [
          ...state[indexOfInstance].savingInstances,
        ];
        currentSavingInsances[currentSavingInsances.length] = 'IN_PROGRESS';
        const updatedInstance = {
          ...state[indexOfInstance],
          savingInstances: currentSavingInsances,
        };
        return [
          ...state.slice(0, indexOfInstance),
          updatedInstance,
          ...state.slice(indexOfInstance + 1),
        ];
      }
      return [
        ...state,
        {
          accountId: action.accountId,
          savingInstances: ['IN_PROGRESS'],
        },
      ];
    }
    case 'EDIT_ACCOUNT_NAMES/UPDATE_INSTANCE': {
      const indexOfInstance = _.findIndex(
        state,
        x => x.accountId === action.accountId,
      );
      const currentSavingInsances = [...state[indexOfInstance].savingInstances];
      currentSavingInsances[action.index] = action.status;
      const updatedInstance = {
        ...state[indexOfInstance],
        savingInstances: currentSavingInsances,
      };
      return [
        ...state.slice(0, indexOfInstance),
        updatedInstance,
        ...state.slice(indexOfInstance + 1),
      ];
    }
    default:
      return state;
  }
};
