export default [
  {
    "transactionId": 1709390437,
    "accountNumber": "35302464616",
    "date": "2018-09-03",
    "amount": "-183.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "02.09 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1708978455,
    "accountNumber": "35302464616",
    "date": "2018-09-03",
    "amount": "-196.50",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "03.09 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1709354733,
    "accountNumber": "35302464616",
    "date": "2018-09-03",
    "amount": "-44.65",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "01.09 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1709613950,
    "accountNumber": "35302464616",
    "date": "2018-09-03",
    "amount": "-250.11",
    "text": "Shell7- 11 Drotningsvik Godvik",
    "alfareferanse": "02.09 7513 SHELL7- 11 DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 473,
      "placeId": "ChIJ1dk79Uz7PEYR_5XNn5ofWSA",
      "placeName": "Shell",
      "coordinates": "60.3722839,5.1810615",
      "website": "https://find.shell.com/no/fuel/NO_7919-shell-7-eleven-drotningsvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1709693043,
    "accountNumber": "35302464616",
    "date": "2018-09-03",
    "amount": "-226.00",
    "text": "Shell Nygård Lyderhornsve Laksevåg",
    "alfareferanse": "01.09 SHELL NYGÅRD LYDERHORNSVE LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 499,
      "placeId": "ChIJfXhiQYT7PEYRPGY9dKF5xmg",
      "placeName": "Shell",
      "coordinates": "60.3847311,5.2712933",
      "website": "https://find.shell.com/no/fuel/NO_4140-shell-nygard",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1710122820,
    "accountNumber": "35302464616",
    "date": "2018-09-03",
    "amount": "-216.77",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "31.08 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1709066139,
    "accountNumber": "35302464616",
    "date": "2018-09-03",
    "amount": "-176.14",
    "text": "Rema Kleppestø Holmedalen 2 Kleppestø",
    "alfareferanse": "02.09 REMA KLEPPESTØ  HOLMEDALEN 2 KLEPPESTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1478,
      "placeId": "ChIJCQG9iZX8PEYRUbHKT29tcZM",
      "placeName": "REMA 1000",
      "coordinates": "60.4064379,5.2196091",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1708342700,
    "accountNumber": "35302464616",
    "date": "2018-08-31",
    "amount": "-69.00",
    "text": "Funky Frozen Jernbanetorg Oslo",
    "alfareferanse": "30.08 FUNKY FROZEN JERNBANETORG OSLO",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1466,
      "placeId": "ChIJDcV13YluQUYR28Qhn3FCbIo",
      "placeName": "Funky Frozen Yogurt AS",
      "coordinates": "59.9115803,10.748191",
      "website": "http://funkyfrozenyogurt.no/",
      "logoId": 305,
      "merchantName": "Funky Frozen Yogurt AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1708516955,
    "accountNumber": "35302464616",
    "date": "2018-08-31",
    "amount": "-172.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         86 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1467,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1708630404,
    "accountNumber": "35302464616",
    "date": "2018-08-31",
    "amount": "-30.00",
    "text": "Narvesen 773 Ve Vestkanten Loddefjord",
    "alfareferanse": "30.08 NARVESEN 773 VE VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1468,
      "placeId": "ChIJo0lIzQz7PEYRqzT5uxwr47s",
      "placeName": "Narvesen Vestkanten",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1708736991,
    "accountNumber": "35302464616",
    "date": "2018-08-31",
    "amount": "-10.00",
    "text": "Minibankgebyr",
    "alfareferanse": "MINIBANKGEBYR                       1 TRANS(ER) TYPE 718",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1708233579,
    "accountNumber": "35302464616",
    "date": "2018-08-31",
    "amount": "-1358.18",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "31.08 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1708199014,
    "accountNumber": "35302464616",
    "date": "2018-08-31",
    "amount": "-47.36",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "30.08 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707820749,
    "accountNumber": "35302464616",
    "date": "2018-08-30",
    "amount": "-175.00",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "29.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707638360,
    "accountNumber": "35302464616",
    "date": "2018-08-30",
    "amount": "-224.00",
    "text": "Shell7- 11 Florvågvn 1 Kleppestø",
    "alfareferanse": "30.08 7517 SHELL7- 11 FLORVÅGVN 1  KLEPPESTØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1470,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707340601,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-1880.00",
    "text": "Volvat Bergen Lagunevn 9 Rådal",
    "alfareferanse": "28.08 VOLVAT BERGEN LAGUNEVN 9 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1471,
      "placeId": "ChIJwZh66-P5PEYRF9cq6zgroLk",
      "placeName": "Volvat Medisinske Senter",
      "coordinates": "60.2945271,5.3261613",
      "website": "https://www.volvat.no/sentrene/bergen/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707488165,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-59.90",
    "text": "Vitusapotek Lag Fanavegen 98 Rådal",
    "alfareferanse": "28.08 VITUSAPOTEK LAG FANAVEGEN 98 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1472,
      "placeId": "ChIJV5Jb6-P5PEYRTC2emp2z2hI",
      "placeName": "Vitusapotek Laguneparken",
      "coordinates": "60.294546,5.326047",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Laguneparken/",
      "logoId": 307,
      "merchantName": "Vitusapotek Laguneparken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707515264,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-102.10",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "28.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707232454,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-154.40",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "28.08 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707366377,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-362.48",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "29.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707517291,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 27.08 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707519452,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-194.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 27.08 NOK 194.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1707159806,
    "accountNumber": "35302464616",
    "date": "2018-08-29",
    "amount": "-178.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 27.08 NOK 178.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706750133,
    "accountNumber": "35302464616",
    "date": "2018-08-28",
    "amount": "-261.80",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "27.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706643317,
    "accountNumber": "35302464616",
    "date": "2018-08-28",
    "amount": "-48.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "28.08 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706745554,
    "accountNumber": "35302464616",
    "date": "2018-08-28",
    "amount": "-299.00",
    "text": "Bk Bystasj 5022 Strøm Gt. 8 Bergen",
    "alfareferanse": "28.08 BK BYSTASJ 5022 STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 541,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706590622,
    "accountNumber": "35302464616",
    "date": "2018-08-28",
    "amount": "-169.10",
    "text": "Meny Nestun Nestunveien Nesttun",
    "alfareferanse": "28.08 MENY NESTUN NESTUNVEIEN  NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1473,
      "placeId": "ChIJ1wc5mLv5PEYRpIzraBIbWCA",
      "placeName": "MENY Nesttun",
      "coordinates": "60.3200736,5.3534615",
      "website": "https://meny.no/Finn-butikk/MENY-Nesttun/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706781511,
    "accountNumber": "35302464616",
    "date": "2018-08-28",
    "amount": "-250.00",
    "text": "Aleris Røntgen Kaigaten 5 Bergen",
    "alfareferanse": "28.08 ALERIS RØNTGEN  KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1474,
      "placeId": "ChIJGY7au67-PEYR93yYWi_8xRE",
      "placeName": "Aleris Røntgen Bergen",
      "coordinates": "60.389607,5.333252",
      "website": "https://www.aleris.no/rontgen/",
      "logoId": 308,
      "merchantName": "Aleris Røntgen Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705380864,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-84.00",
    "text": "Shell Voss Strandavn 19 Voss",
    "alfareferanse": "25.08 SHELL VOSS STRANDAVN 19 VOSS",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1475,
      "placeId": "ChIJ268gkZbaPUYRIjIPvf3GjVc",
      "placeName": "Shell Voss",
      "coordinates": "60.6302401,6.4275532",
      "website": "http://www.shell.no/",
      "logoId": 309,
      "merchantName": "Shell Voss",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705441358,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-168.00",
    "text": "Espresso House Laguneveien Rådal",
    "alfareferanse": "27.08 ESPRESSO HOUSE  LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1476,
      "placeId": "ChIJJ1_jvOb5PEYRGJPw-iC1LpY",
      "placeName": "ESPRESSO HOUSE - Lagunen",
      "coordinates": "60.2967572,5.3299423",
      "website": "https://no.espressohouse.com/produkter/",
      "logoId": 310,
      "merchantName": "ESPRESSO HOUSE - Lagunen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706048256,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-62.90",
    "text": "Drotningsvik Post I Butik Bergen",
    "alfareferanse": "25.08 DROTNINGSVIK POST I BUTIK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 1477,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705566112,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-443.19",
    "text": "Engelshamn Mari Kjøkkelvikvn Loddefjord",
    "alfareferanse": "26.08 ENGELSHAMN MARI KJØKKELVIKVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1388,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706267152,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-36.80",
    "text": "Rema Kløverhuse Strandgaten Bergen",
    "alfareferanse": "27.08 REMA KLØVERHUSE STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1392,
      "placeId": "ChIJ6fFHqQL8PEYR0neiOKdXozw",
      "placeName": "Rema 1000 Kløverhuset",
      "coordinates": "60.3944251,5.3232854",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706258915,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-1041.20",
    "text": "Morris 7121 Lagunevn 1 Rådal",
    "alfareferanse": "27.08 MORRIS 7121 LAGUNEVN 1 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Vesker",
    "imageId": "",
    "place": {
      "id": 1128,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705450744,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-199.00",
    "text": "Norli Oasen Byd F.Bernadotte Fyllingsdalen",
    "alfareferanse": "27.08 NORLI OASEN BYD F.BERNADOTTE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1237,
      "placeId": "ChIJoTfziMX7PEYRFW2AoN5LeEE",
      "placeName": "Norli Oasis Bydelssenter",
      "coordinates": "60.3490486,5.2914566",
      "website": "http://www.norli.no/",
      "logoId": 141,
      "merchantName": "Norli",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705905957,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-325.20",
    "text": "Ark Vestkanten Vestkanten Loddefjord",
    "alfareferanse": "24.08 ARK VESTKANTEN  VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 705,
      "placeId": "ChIJo0lIzQz7PEYR3UXU8LI2iQ0",
      "placeName": "Ark Beyer Vestkanten",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://www.vestkanten.no/butikker-og-virksomheter/ark/",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706421597,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-472.00",
    "text": "Esso Voss Evangervn.4 Voss",
    "alfareferanse": "25.08 ESSO VOSS EVANGERVN.4  VOSS",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1091,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705318885,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-180.00",
    "text": "Shell7- 11 Ådnavegen 63 Indre Arna",
    "alfareferanse": "25.08 7526 SHELL7- 11 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 540,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705403296,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-500.17",
    "text": "Shell7- 11 Ådnavegen 63 Indre Arna",
    "alfareferanse": "25.08 7526 SHELL7- 11 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 540,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705548702,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-555.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "25.08 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706185580,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-39.80",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "27.08 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706138237,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-759.91",
    "text": "Obs Vestkanten Loddefjord",
    "alfareferanse": "24.08 OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 530,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705926418,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-191.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "24.08 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706202140,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-223.30",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "24.08 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1706130413,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-274.40",
    "text": "Rema Drotningsv Drotningsvik Godvik",
    "alfareferanse": "25.08 REMA DROTNINGSV DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 505,
      "placeId": "ChIJo3cCmkz7PEYRS5O0T1uCot0",
      "placeName": "REMA 1000",
      "coordinates": "60.3719928,5.180246",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705584973,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-604.69",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "25.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705548276,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-342.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "24.08 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705543019,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-232.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "26.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705341049,
    "accountNumber": "35302464616",
    "date": "2018-08-27",
    "amount": "-162.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "27.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1704845906,
    "accountNumber": "35302464616",
    "date": "2018-08-24",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1705173949,
    "accountNumber": "35302464616",
    "date": "2018-08-24",
    "amount": "-84.38",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "23.08 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1704634475,
    "accountNumber": "35302464616",
    "date": "2018-08-23",
    "amount": "-30.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "22.08 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1703989138,
    "accountNumber": "35302464616",
    "date": "2018-08-22",
    "amount": "-160.00",
    "text": "Rema Kløverhuse Strandgaten Bergen",
    "alfareferanse": "22.08 REMA KLØVERHUSE STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1392,
      "placeId": "ChIJ6fFHqQL8PEYR0neiOKdXozw",
      "placeName": "Rema 1000 Kløverhuset",
      "coordinates": "60.3944251,5.3232854",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1703169672,
    "accountNumber": "35302464616",
    "date": "2018-08-21",
    "amount": "-577.00",
    "text": "Iz *Roll Rock Sk",
    "alfareferanse": "*0971 18.08 NOK 577.00 IZ *ROLL   ROCK SK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1459,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1703552259,
    "accountNumber": "35302464616",
    "date": "2018-08-21",
    "amount": "-109.00",
    "text": "Spotify",
    "alfareferanse": "*0971 18.08 NOK 109.00 PAYPAL *SPOTIFY Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1702410727,
    "accountNumber": "35302464616",
    "date": "2018-08-20",
    "amount": "-13.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "20.08 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Fastfood"
  },
  {
    "transactionId": 1699480014,
    "accountNumber": "35302464616",
    "date": "2018-08-14",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.08 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1698604924,
    "accountNumber": "35302464616",
    "date": "2018-08-13",
    "amount": "-656.82",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "10.08 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Ferie, Reise"
  },
  {
    "transactionId": 1698633956,
    "accountNumber": "35302464616",
    "date": "2018-08-13",
    "amount": "-23.70",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "10.08 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1698809006,
    "accountNumber": "35302464616",
    "date": "2018-08-13",
    "amount": "-120.19",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "10.08 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1697718193,
    "accountNumber": "35302464616",
    "date": "2018-08-10",
    "amount": "-131.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "10.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1696826397,
    "accountNumber": "35302464616",
    "date": "2018-08-09",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.08 NOK 65.00 PlaystationNetwork Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1697029375,
    "accountNumber": "35302464616",
    "date": "2018-08-09",
    "amount": "-268.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "08.08 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Pizza"
  },
  {
    "transactionId": 1694905809,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-100.07",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "04.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1695517018,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-444.08",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "04.08 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1695536047,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-57.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "05.08 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1695390859,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-322.60",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "03.08 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1695310953,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-78.10",
    "text": "129 Boots Apotek Loddefjord",
    "alfareferanse": "03.08 129 BOOTS APOTEK LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 698,
      "placeId": "ChIJo0lIzQz7PEYRXzUfwZa7nWA",
      "placeName": "Boots apotek Loddefjord",
      "coordinates": "60.36309,5.233356",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1694724443,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-200.50",
    "text": "Engelshamn Mari Kjøkkelvikvn Loddefjord",
    "alfareferanse": "05.08 ENGELSHAMN MARI KJØKKELVIKVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1388,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1694885286,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-98.00",
    "text": "Shell Dale Dale Industr Dalekvam",
    "alfareferanse": "05.08 SHELL DALE DALE INDUSTR DALEKVAM",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1389,
      "placeId": "ChIJ04goxdixPUYRwb8N4kGWZ4g",
      "placeName": "SHELL DALE",
      "coordinates": "60.5834375,5.7953721",
      "website": "https://find.shell.com/no/fuel/NO_4185-shell-dale",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1695440749,
    "accountNumber": "35302464616",
    "date": "2018-08-06",
    "amount": "-420.90",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "03.08 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "227",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Fest"
  },
  {
    "transactionId": 1694174348,
    "accountNumber": "35302464616",
    "date": "2018-08-03",
    "amount": "-6000.00",
    "text": "Spv Fyllingsdalen Ute Fyllingsdalen",
    "alfareferanse": "02.08 SPV Fyllingsdalen ute   Fyllingsdalen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1390,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 293,
      "merchantName": "Sparebanken Vest Fyllingsdalen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1694510878,
    "accountNumber": "35302464616",
    "date": "2018-08-03",
    "amount": "-65.00",
    "text": "3508 Europark Rosenkrantz",
    "alfareferanse": "*0971 01.08 NOK 65.00 3508 EUROPARK ROSENKRANTZ Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1391,
      "placeId": "ChIJf9tlm6f-PEYRAvC5q3-tyIk",
      "placeName": "Rosenkrantz P-hus",
      "coordinates": "60.3970099,5.3250995",
      "website": "http://www.europark.no/finn-parkering/bergen/rosenkrantz-p-hus/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1694396870,
    "accountNumber": "35302464616",
    "date": "2018-08-03",
    "amount": "-5119.00",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "02.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1694559406,
    "accountNumber": "35302464616",
    "date": "2018-08-03",
    "amount": "-65.70",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "03.08 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1694517599,
    "accountNumber": "35302464616",
    "date": "2018-08-03",
    "amount": "-157.51",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "02.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1694588273,
    "accountNumber": "35302464616",
    "date": "2018-08-03",
    "amount": "-131.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "03.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Drivstoff"
  },
  {
    "transactionId": 1694195331,
    "accountNumber": "35302464616",
    "date": "2018-08-03",
    "amount": "-5106.80",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "02.08 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1693839815,
    "accountNumber": "35302464616",
    "date": "2018-08-02",
    "amount": "-109.00",
    "text": "Akvariet I Berg Nordnesbakke Bergen",
    "alfareferanse": "01.08 AKVARIET I BERG NORDNESBAKKE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 647,
      "placeId": "ChIJ6TU-lBD8PEYR63yf2sh2Ekc",
      "placeName": "Akvariet i Bergen - Det Nasjonale Akvariet",
      "coordinates": "60.3996913,5.303371",
      "website": "http://www.akvariet.no/?utm_source=googlemybusiness&utm_campaign=Google%20Min%20Bedrift&utm_medium=organic",
      "logoId": 102,
      "merchantName": "Akvariet i Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1693925283,
    "accountNumber": "35302464616",
    "date": "2018-08-02",
    "amount": "-238.00",
    "text": "Rema Kløverhuse Strandgaten Bergen",
    "alfareferanse": "01.08 REMA KLØVERHUSE STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1392,
      "placeId": "ChIJ6fFHqQL8PEYR0neiOKdXozw",
      "placeName": "Rema 1000 Kløverhuset",
      "coordinates": "60.3944251,5.3232854",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1693153059,
    "accountNumber": "35302464616",
    "date": "2018-08-01",
    "amount": "-322.99",
    "text": "Rema Kokstad Kokstadvn.15 Kokstad",
    "alfareferanse": "31.07 REMA KOKSTAD KOKSTADVN.15 KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1394,
      "placeId": "ChIJ825K1Z_wPEYRvuoM9P_Jd8U",
      "placeName": "REMA 1000 Kokstad",
      "coordinates": "60.2922252,5.2620446",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1692686179,
    "accountNumber": "35302464616",
    "date": "2018-07-31",
    "amount": "-34.00",
    "text": "Europark Phus 2",
    "alfareferanse": "*0971 27.07 NOK 34.00 EUROPARK PHUS 2 Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1370,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1692761176,
    "accountNumber": "35302464616",
    "date": "2018-07-31",
    "amount": "-162.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         81 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1371,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1692596219,
    "accountNumber": "35302464616",
    "date": "2018-07-31",
    "amount": "-83.34",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "30.07 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1692345292,
    "accountNumber": "35302464616",
    "date": "2018-07-31",
    "amount": "-20.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1692175011,
    "accountNumber": "35302464616",
    "date": "2018-07-31",
    "amount": "-121.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 27.07 NOK 121.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1690947473,
    "accountNumber": "35302464616",
    "date": "2018-07-30",
    "amount": "-945.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1691690739,
    "accountNumber": "35302464616",
    "date": "2018-07-30",
    "amount": "-332.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "28.07 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1691701259,
    "accountNumber": "35302464616",
    "date": "2018-07-30",
    "amount": "-520.80",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "28.07 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Fest"
  },
  {
    "transactionId": 1691077263,
    "accountNumber": "35302464616",
    "date": "2018-07-30",
    "amount": "-8000.00",
    "text": "Nordea Vestkanten Storsenter, Loddefjord 19:46",
    "alfareferanse": "27.07 NORDEA VESTKANTEN STORSENTER, LODDEFJORD 19:46",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1372,
      "placeId": "ChIJo0lIzQz7PEYR2-ImB67rsBM",
      "placeName": "Vestkanten Storsenter",
      "coordinates": "60.363224,5.233616",
      "website": "http://www.vestkanten.no/?utm_source=google&utm_medium=infoboks&utm_campaign=GMB",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1691149536,
    "accountNumber": "35302464616",
    "date": "2018-07-30",
    "amount": "-1650.00",
    "text": "Eikum Hotell 6869 Hafslo Tlf.00",
    "alfareferanse": "29.07 EIKUM HOTELL 6869 HAFSLO  TLF.57680600",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Hotell",
    "imageId": "",
    "place": {
      "id": 1373,
      "placeId": "ChIJNxfo3BjwFUYR8N4yHc0ciyk",
      "placeName": "Eikum Hotel",
      "coordinates": "61.3154857,7.2033595",
      "website": "http://www.eikum.no/",
      "logoId": 289,
      "merchantName": "Eikum Hotel",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1691603142,
    "accountNumber": "35302464616",
    "date": "2018-07-30",
    "amount": "-60.00",
    "text": "Staples, Bergen Kanalvn.107 Minde",
    "alfareferanse": "27.07 STAPLES, BERGEN KANALVN.107  MINDE",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1374,
      "placeId": "ChIJrRU690H5PEYRovo_Yg7Zwbo",
      "placeName": "Staples Bergen, Minde",
      "coordinates": "60.3598067,5.342756",
      "website": "http://www.staples.no/",
      "logoId": 290,
      "merchantName": "Staples Bergen, Minde",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1690673416,
    "accountNumber": "35302464616",
    "date": "2018-07-27",
    "amount": "-1750.00",
    "text": "Sonans As",
    "alfareferanse": "Nettgiro til: SONANS AS Betalt: 27.07.18",
    "mainCategory": "Utgifter",
    "category": "Utdanning",
    "subCategory": "Semesteravgift",
    "imageId": "",
    "place": {
      "id": 1375,
      "placeId": "ChIJhQ_hSpkxbUYRnQQ0C_rJ0LQ",
      "placeName": "Sonans AS",
      "coordinates": "63.4327115,10.4040612",
      "website": "https://sonans.no/",
      "logoId": 291,
      "merchantName": "Sonans AS",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Skole"
  },
  {
    "transactionId": 1690313380,
    "accountNumber": "35302464616",
    "date": "2018-07-26",
    "amount": "-609.54",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "25.07 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1689994574,
    "accountNumber": "35302464616",
    "date": "2018-07-26",
    "amount": "-11.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 24.07 NOK 11.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1690145674,
    "accountNumber": "35302464616",
    "date": "2018-07-26",
    "amount": "-113.93",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "25.07 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1689896280,
    "accountNumber": "35302464616",
    "date": "2018-07-25",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1689878135,
    "accountNumber": "35302464616",
    "date": "2018-07-25",
    "amount": "-77.19",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "25.07 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1689171983,
    "accountNumber": "35302464616",
    "date": "2018-07-24",
    "amount": "-59.00",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "23.07 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1688967739,
    "accountNumber": "35302464616",
    "date": "2018-07-24",
    "amount": "-52.00",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "23.07 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1689081376,
    "accountNumber": "35302464616",
    "date": "2018-07-24",
    "amount": "-389.29",
    "text": "Coop Obs Sartor Sartor Sente Straume",
    "alfareferanse": "23.07 COOP OBS SARTOR SARTOR SENTE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 578,
      "placeId": "ChIJ07Dmae7kPEYRdtQFqnLjn7Y",
      "placeName": "Obs Bygg",
      "coordinates": "60.3589314,5.1249784",
      "website": "https://coop.no/butikker/obs/sartor-2408/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1688890416,
    "accountNumber": "35302464616",
    "date": "2018-07-24",
    "amount": "-55.00",
    "text": "Narvesen 722 Sartor Sent. Straume",
    "alfareferanse": "23.07 NARVESEN 722 SARTOR SENT. STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1396,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1688593322,
    "accountNumber": "35302464616",
    "date": "2018-07-23",
    "amount": "-1000.00",
    "text": "Nokas Coop Askvoll 6980 Askvoll",
    "alfareferanse": "20.07 Nokas Coop Askvoll      6980 Askvoll",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1377,
      "placeId": "ChIJV_DLPWjnF0YRtaxatgY0CAY",
      "placeName": "Coop Marked Askvoll",
      "coordinates": "61.3467682,5.0611554",
      "website": "https://coop.no/butikker/marked/askvoll-1380/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1687745400,
    "accountNumber": "35302464616",
    "date": "2018-07-23",
    "amount": "-13.00",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "21.07 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1688166776,
    "accountNumber": "35302464616",
    "date": "2018-07-23",
    "amount": "-440.55",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "21.07 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1686798010,
    "accountNumber": "35302464616",
    "date": "2018-07-20",
    "amount": "-201.68",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "19.07 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1686072621,
    "accountNumber": "35302464616",
    "date": "2018-07-18",
    "amount": "-26.80",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "18.07 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1685990638,
    "accountNumber": "35302464616",
    "date": "2018-07-18",
    "amount": "-100.00",
    "text": "Br-Leker F2336 Folke Bernad Fyllingsdalen",
    "alfareferanse": "18.07 BR-LEKER F2336  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 588,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1685249373,
    "accountNumber": "35302464616",
    "date": "2018-07-17",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.07 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1685359330,
    "accountNumber": "35302464616",
    "date": "2018-07-17",
    "amount": "-304.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.07 NOK 304.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1684977874,
    "accountNumber": "35302464616",
    "date": "2018-07-17",
    "amount": "-24.80",
    "text": "Bunnpris Lavik Lavik",
    "alfareferanse": "16.07 BUNNPRIS LAVIK   LAVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1378,
      "placeId": "ChIJUZ0ayu95PUYRmvSRpO2vxO0",
      "placeName": "Bunnpris Lavik",
      "coordinates": "61.1033114,5.5115611",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1684547999,
    "accountNumber": "35302464616",
    "date": "2018-07-16",
    "amount": "-376.96",
    "text": "Rema Øyrane Før Firdavegen 2 Førde",
    "alfareferanse": "15.07 REMA ØYRANE FØR FIRDAVEGEN 2 FØRDE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1379,
      "placeId": "ChIJaef07t0zFkYRuxy-4r4DTwQ",
      "placeName": "Rema 1000",
      "coordinates": "61.4575205,5.848639",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1683012273,
    "accountNumber": "35302464616",
    "date": "2018-07-13",
    "amount": "-305.38",
    "text": "Circle K Førde Hafstadveien Førde",
    "alfareferanse": "12.07 CIRCLE K FØRDE  HAFSTADVEIEN FØRDE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1380,
      "placeId": "ChIJbx887tgzFkYRImN_bOdPdlE",
      "placeName": "Circle K Førde",
      "coordinates": "61.4500181,5.8540396",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=52147&countryid=no",
      "logoId": 292,
      "merchantName": "Circle K Førde",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1683278111,
    "accountNumber": "35302464616",
    "date": "2018-07-13",
    "amount": "-645.80",
    "text": "Vinmonopolet Førde Førde",
    "alfareferanse": "12.07 VINMONOPOLET FØRDE FØRDE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1381,
      "placeId": "ChIJG2xCTdkzFkYRQRKcVVsTo00",
      "placeName": "Vinmonopolet Førde",
      "coordinates": "61.4530357,5.8510094",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1683456201,
    "accountNumber": "35302464616",
    "date": "2018-07-13",
    "amount": "-77.30",
    "text": "Spar Matsenter Handelshuset 6800 Frde",
    "alfareferanse": "12.07 SPAR MATSENTER  HANDELSHUSET 6800 FRDE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1382,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1682364413,
    "accountNumber": "35302464616",
    "date": "2018-07-12",
    "amount": "-82.00",
    "text": "Circle K Førde Hafstadveien Førde",
    "alfareferanse": "12.07 CIRCLE K FØRDE  HAFSTADVEIEN FØRDE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1380,
      "placeId": "ChIJbx887tgzFkYRImN_bOdPdlE",
      "placeName": "Circle K Førde",
      "coordinates": "61.4500181,5.8540396",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=52147&countryid=no",
      "logoId": 292,
      "merchantName": "Circle K Førde",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1682520462,
    "accountNumber": "35302464616",
    "date": "2018-07-12",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.07 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1682416428,
    "accountNumber": "35302464616",
    "date": "2018-07-12",
    "amount": "-168.00",
    "text": "Shell Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "12.07 SHELL KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 643,
      "placeId": "ChIJ60FlYp8DPUYRxGs2HOIOWfA",
      "placeName": "Shell",
      "coordinates": "60.5471999,5.2912582",
      "website": "https://find.shell.com/no/fuel/NO_9232-shell-knarvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1682087565,
    "accountNumber": "35302464616",
    "date": "2018-07-11",
    "amount": "-144.80",
    "text": "Nærbutikken Stongfjorden Stongfjorden",
    "alfareferanse": "11.07 NÆRBUTIKKEN STONGFJORDEN STONGFJORDEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1383,
      "placeId": "ChIJ0XKu9s_CF0YRaJbB2gj4b4w",
      "placeName": "Nærbutikken Stongfjorden",
      "coordinates": "61.427125,5.1652612",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Stongfjorden",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1681735373,
    "accountNumber": "35302464616",
    "date": "2018-07-10",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 08.07 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1681204517,
    "accountNumber": "35302464616",
    "date": "2018-07-10",
    "amount": "-24.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "09.07 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680608402,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-94.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "09.07 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680933757,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-4.94",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "09.07 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680282804,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-169.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 05.07 NOK 169.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680777563,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-133.40",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "07.07 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680694298,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-131.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "06.07 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680138081,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-380.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "06.07 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680259820,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-70.82",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "09.07 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1681031027,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-256.34",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "07.07 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680772722,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-21.18",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "08.07 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1680505850,
    "accountNumber": "35302464616",
    "date": "2018-07-09",
    "amount": "-109.80",
    "text": "Brio Arken As Arken Senter Ulset",
    "alfareferanse": "07.07 BRIO ARKEN AS ARKEN SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 931,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1679947045,
    "accountNumber": "35302464616",
    "date": "2018-07-06",
    "amount": "-79.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "06.07 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1679006978,
    "accountNumber": "35302464616",
    "date": "2018-07-05",
    "amount": "-45.66",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "05.07 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1679371873,
    "accountNumber": "35302464616",
    "date": "2018-07-05",
    "amount": "-510.70",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "04.07 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1678537317,
    "accountNumber": "35302464616",
    "date": "2018-07-04",
    "amount": "-177.31",
    "text": "Yx Godvik 0213 Godvik",
    "alfareferanse": "04.07 YX GODVIK 0213   GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 468,
      "placeId": "ChIJ8Sb2U0P7PEYRasC-Yn2YkSg",
      "placeName": "YX Godvik",
      "coordinates": "60.3757182,5.2046342",
      "website": "https://yx.no/_stasjon?id=6f3dbb3e-b77c-4cac-8466-04ed9c58578e",
      "logoId": 43,
      "merchantName": "YX",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1678963820,
    "accountNumber": "35302464616",
    "date": "2018-07-04",
    "amount": "-150.62",
    "text": "Yx Godvik 0213 Godvik",
    "alfareferanse": "03.07 YX GODVIK 0213   GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 468,
      "placeId": "ChIJ8Sb2U0P7PEYRasC-Yn2YkSg",
      "placeName": "YX Godvik",
      "coordinates": "60.3757182,5.2046342",
      "website": "https://yx.no/_stasjon?id=6f3dbb3e-b77c-4cac-8466-04ed9c58578e",
      "logoId": 43,
      "merchantName": "YX",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1678833971,
    "accountNumber": "35302464616",
    "date": "2018-07-04",
    "amount": "-101.91",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "04.07 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1678548640,
    "accountNumber": "35302464616",
    "date": "2018-07-04",
    "amount": "-399.00",
    "text": "Din Sko 1122 Loddefjordv2 Loddefjord",
    "alfareferanse": "04.07 DIN SKO 1122 LODDEFJORDV2 LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1384,
      "placeId": "ChIJo0lIzQz7PEYR0QqVxgsoNMk",
      "placeName": "DinSko & ECCO",
      "coordinates": "60.3632227,5.2336255",
      "website": "https://feetfirst.no/sko/dinsko-ecco-vestkanten-kjopsenter",
      "logoId": 159,
      "merchantName": "DinSko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1678600114,
    "accountNumber": "35302464616",
    "date": "2018-07-04",
    "amount": "-600.50",
    "text": "Carlings 513 Loddefjordvn Loddefjord",
    "alfareferanse": "04.07 CARLINGS 513 LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1385,
      "placeId": "ChIJo0lIzQz7PEYR4bIl5q6D1Vk",
      "placeName": "Carlings AS",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://carlings.com/",
      "logoId": 214,
      "merchantName": "Carlings",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1678647883,
    "accountNumber": "35302464616",
    "date": "2018-07-04",
    "amount": "-149.00",
    "text": "Piam As Stølegt 12B Bergen",
    "alfareferanse": "03.07 PIAM AS STØLEGT 12B  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1386,
      "placeId": "ChIJFxpLUqf-PEYR7Zu3gtj-Dd4",
      "placeName": "Piam AS",
      "coordinates": "60.4000513,5.3253169",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1678426736,
    "accountNumber": "35302464616",
    "date": "2018-07-03",
    "amount": "-19.40",
    "text": "Snoparen Kiosk Vestkanten S Bergen",
    "alfareferanse": "02.07 SNOPAREN KIOSK  VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 667,
      "placeId": "ChIJXQ2K0gz7PEYRqzuwkdW41_0",
      "placeName": "Snoparen Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://www.vestkanten.no/butikker-og-virksomheter/snoparen/",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1676106185,
    "accountNumber": "35302464616",
    "date": "2018-07-02",
    "amount": "-167.00",
    "text": "Shell Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "02.07 SHELL KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 643,
      "placeId": "ChIJ60FlYp8DPUYRxGs2HOIOWfA",
      "placeName": "Shell",
      "coordinates": "60.5471999,5.2912582",
      "website": "https://find.shell.com/no/fuel/NO_9232-shell-knarvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1677599596,
    "accountNumber": "35302464616",
    "date": "2018-07-02",
    "amount": "-89.00",
    "text": "Circle K Servic Nordre Brurå Nyborg",
    "alfareferanse": "29.06 CIRCLE K SERVIC NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 491,
      "placeId": "ChIJd0uwwucBPUYRZlp84b2Kdlc",
      "placeName": "Circle K Haukås",
      "coordinates": "60.4846724,5.3764514",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55564&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1675841123,
    "accountNumber": "35302464616",
    "date": "2018-07-02",
    "amount": "-236.90",
    "text": "Rema Førde Hafstad Førde",
    "alfareferanse": "30.06 REMA FØRDE HAFSTAD FØRDE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1387,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1676912202,
    "accountNumber": "35302464616",
    "date": "2018-06-30",
    "amount": "-1.00",
    "text": "Debetrenter U/Dekning",
    "alfareferanse": "DEBETRENTER U/DEKNING",
    "mainCategory": "Utgifter",
    "category": "Renter",
    "subCategory": "Renter",
    "imageId": "",
    "place": {
      "id": 1367,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1675629959,
    "accountNumber": "35302464616",
    "date": "2018-06-30",
    "amount": "-138.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         69 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 534,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1677703314,
    "accountNumber": "35302464616",
    "date": "2018-06-29",
    "amount": "-945.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1674959993,
    "accountNumber": "35302464616",
    "date": "2018-06-28",
    "amount": "-192.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "27.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1675402390,
    "accountNumber": "35302464616",
    "date": "2018-06-28",
    "amount": "-313.99",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "27.06 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1674949654,
    "accountNumber": "35302464616",
    "date": "2018-06-28",
    "amount": "-78.10",
    "text": "Apotek 1 Lagunen Rådal.",
    "alfareferanse": "27.06 APOTEK 1 LAGUNEN RÅDAL.",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1368,
      "placeId": "ChIJIQ84ueb5PEYRlrLJ0dXH2IE",
      "placeName": "Apotek 1 Lagunen",
      "coordinates": "60.296531,5.330386",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/lagunen-13",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1675446010,
    "accountNumber": "35302464616",
    "date": "2018-06-28",
    "amount": "-71.00",
    "text": "Narvesen 702 Krohnåsvn. 1 Rådal",
    "alfareferanse": "27.06 NARVESEN 702 KROHNÅSVN. 1 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 780,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1674846259,
    "accountNumber": "35302464616",
    "date": "2018-06-27",
    "amount": "-181.59",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "27.06 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1673886653,
    "accountNumber": "35302464616",
    "date": "2018-06-26",
    "amount": "-275.00",
    "text": "Årspris Visa Classic",
    "alfareferanse": "Årspris Visa Classic",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 679,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1672538512,
    "accountNumber": "35302464616",
    "date": "2018-06-25",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1673150153,
    "accountNumber": "35302464616",
    "date": "2018-06-25",
    "amount": "-329.49",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "25.06 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1672867336,
    "accountNumber": "35302464616",
    "date": "2018-06-25",
    "amount": "-168.71",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "24.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1669817540,
    "accountNumber": "35302464616",
    "date": "2018-06-19",
    "amount": "-27.37",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "19.06 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "220",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1668664574,
    "accountNumber": "35302464616",
    "date": "2018-06-18",
    "amount": "-38.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "16.06 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1668642618,
    "accountNumber": "35302464616",
    "date": "2018-06-18",
    "amount": "-165.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "15.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "216",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1669022369,
    "accountNumber": "35302464616",
    "date": "2018-06-18",
    "amount": "-77.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "16.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1668822049,
    "accountNumber": "35302464616",
    "date": "2018-06-18",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.06 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1668188157,
    "accountNumber": "35302464616",
    "date": "2018-06-15",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.06 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1667030076,
    "accountNumber": "35302464616",
    "date": "2018-06-14",
    "amount": "-240.00",
    "text": "Tin Tin Loi Da Bjørndalsstø Loddefjord",
    "alfareferanse": "13.06 TIN TIN LOI DA  BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 478,
      "placeId": "ChIJZxfZC6j7PEYRe0vxGBotL08",
      "placeName": "Tin Tin Loi Da",
      "coordinates": "60.3509164,5.2442406",
      "website": "http://www.tintinloi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1667237353,
    "accountNumber": "35302464616",
    "date": "2018-06-14",
    "amount": "-14.19",
    "text": "Rema Drotningsv Drotningsvik Godvik",
    "alfareferanse": "13.06 REMA DROTNINGSV DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 505,
      "placeId": "ChIJo3cCmkz7PEYRS5O0T1uCot0",
      "placeName": "REMA 1000",
      "coordinates": "60.3719928,5.180246",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1666284074,
    "accountNumber": "35302464616",
    "date": "2018-06-12",
    "amount": "-53.50",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "11.06 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1665676093,
    "accountNumber": "35302464616",
    "date": "2018-06-12",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.06 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1666009015,
    "accountNumber": "35302464616",
    "date": "2018-06-12",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 08.06 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1665025230,
    "accountNumber": "35302464616",
    "date": "2018-06-11",
    "amount": "-820.80",
    "text": "Vinmonopolet Os Os",
    "alfareferanse": "09.06 VINMONOPOLET OS OS",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 507,
      "placeId": "ChIJ58yHGC5fPEYR442LSYYPbtY",
      "placeName": "Vinmonopolet Os",
      "coordinates": "60.1837903,5.4720502",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1665183930,
    "accountNumber": "35302464616",
    "date": "2018-06-11",
    "amount": "-705.98",
    "text": "Bunnpris Vedholmen Lepsøy",
    "alfareferanse": "09.06 BUNNPRIS VEDHOLMEN LEPSØY",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 508,
      "placeId": "ChIJ-eABA3X1PEYRVZaDFqN2sxw",
      "placeName": "Bunnpris Vedholmen",
      "coordinates": "60.1456458,5.3786797",
      "website": "http://bunnpris.no/butikker/bunnpris-vedholmen",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1665319779,
    "accountNumber": "35302464616",
    "date": "2018-06-11",
    "amount": "-150.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "10.06 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1665212652,
    "accountNumber": "35302464616",
    "date": "2018-06-11",
    "amount": "-179.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "10.06 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1665276316,
    "accountNumber": "35302464616",
    "date": "2018-06-11",
    "amount": "-283.39",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "08.06 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1664755790,
    "accountNumber": "35302464616",
    "date": "2018-06-11",
    "amount": "-284.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "08.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1662177183,
    "accountNumber": "35302464616",
    "date": "2018-06-08",
    "amount": "-56.00",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "07.06 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1661906940,
    "accountNumber": "35302464616",
    "date": "2018-06-08",
    "amount": "-24.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "08.06 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1660859450,
    "accountNumber": "35302464616",
    "date": "2018-06-05",
    "amount": "-566.05",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "04.06 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1660838105,
    "accountNumber": "35302464616",
    "date": "2018-06-05",
    "amount": "-141.30",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "04.06 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1660812979,
    "accountNumber": "35302464616",
    "date": "2018-06-05",
    "amount": "-403.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "04.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1659498724,
    "accountNumber": "35302464616",
    "date": "2018-06-04",
    "amount": "-257.05",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "03.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1660179285,
    "accountNumber": "35302464616",
    "date": "2018-06-04",
    "amount": "-55.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "04.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1660131209,
    "accountNumber": "35302464616",
    "date": "2018-06-04",
    "amount": "-75.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "03.06 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1660125957,
    "accountNumber": "35302464616",
    "date": "2018-06-04",
    "amount": "-408.80",
    "text": "Esso Fjøsanger Kanalvn.1 Bergen",
    "alfareferanse": "01.06 ESSO FJØSANGER  KANALVN.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 496,
      "placeId": "ChIJ1S-heU_5PEYRWA7Ve6VYQlI",
      "placeName": "Esso",
      "coordinates": "60.3711804,5.3411141",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1659000965,
    "accountNumber": "35302464616",
    "date": "2018-06-01",
    "amount": "-81.00",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "01.06 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1658449450,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-160.80",
    "text": "Extra Skareveien 1 Mathopen",
    "alfareferanse": "31.05 EXTRA SKAREVEIEN 1 MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 465,
      "placeId": "ChIJM76lv-b6PEYRg29QdF3BMSs",
      "placeName": "EXTRA",
      "coordinates": "60.3336941,5.2118852",
      "website": "https://coop.no/butikker/extra/mathopen-4345/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Mat"
  },
  {
    "transactionId": 1658394344,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-90.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "30.05 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Sommerferie, Bil og sånn"
  },
  {
    "transactionId": 1658430081,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-209.00",
    "text": "China Palace As Torgallmenni Bergen",
    "alfareferanse": "30.05 CHINA PALACE AS TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 464,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1657723103,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-228.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "31.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1658132529,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-84.21",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "31.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1657924043,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 29.05 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1658265830,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-192.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         96 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 461,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1658364453,
    "accountNumber": "35302464616",
    "date": "2018-05-31",
    "amount": "-945.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1657352814,
    "accountNumber": "35302464616",
    "date": "2018-05-30",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 28.05 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1657148703,
    "accountNumber": "35302464616",
    "date": "2018-05-30",
    "amount": "-131.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "29.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1657468234,
    "accountNumber": "35302464616",
    "date": "2018-05-30",
    "amount": "-60.84",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "29.05 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Mat"
  },
  {
    "transactionId": 1656496160,
    "accountNumber": "35302464616",
    "date": "2018-05-29",
    "amount": "-364.29",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "28.05 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1656964844,
    "accountNumber": "35302464616",
    "date": "2018-05-29",
    "amount": "-131.00",
    "text": "Yx Godvik 0213 Godvik",
    "alfareferanse": "29.05 YX GODVIK 0213   GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 468,
      "placeId": "ChIJ8Sb2U0P7PEYRasC-Yn2YkSg",
      "placeName": "YX Godvik",
      "coordinates": "60.3757182,5.2046342",
      "website": "https://yx.no/_stasjon?id=6f3dbb3e-b77c-4cac-8466-04ed9c58578e",
      "logoId": 43,
      "merchantName": "YX",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1656925178,
    "accountNumber": "35302464616",
    "date": "2018-05-29",
    "amount": "-227.13",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "28.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1656995223,
    "accountNumber": "35302464616",
    "date": "2018-05-29",
    "amount": "-200.15",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "28.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1656198827,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-330.06",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "25.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1655584227,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-331.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "26.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1655388847,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-479.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "27.05 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1655578485,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-203.43",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "26.05 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1655474221,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-303.60",
    "text": "1-2-3 Godvik 57 Alvehaugen Godvik",
    "alfareferanse": "26.05 1-2-3 GODVIK 57 ALVEHAUGEN GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 470,
      "placeId": "ChIJ63ueS0_7PEYRWCCYwWA3ypU",
      "placeName": "1-2-3 Godvik",
      "coordinates": "60.3704065,5.1921729",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=57074&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1656021233,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-125.50",
    "text": "Shell7- 11 Drotningsvik Godvik",
    "alfareferanse": "27.05 7513 SHELL7- 11 DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 473,
      "placeId": "ChIJ1dk79Uz7PEYR_5XNn5ofWSA",
      "placeName": "Shell",
      "coordinates": "60.3722839,5.1810615",
      "website": "https://find.shell.com/no/fuel/NO_7919-shell-7-eleven-drotningsvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1655810933,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-98.84",
    "text": "Meny Dolviken Dolvikvn. 36 Søriedgrend",
    "alfareferanse": "26.05 MENY DOLVIKEN DOLVIKVN. 36 SØRIEDGREND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 472,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1656436748,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-187.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "27.05 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1655497645,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-423.63",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "28.05 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1656192549,
    "accountNumber": "35302464616",
    "date": "2018-05-28",
    "amount": "-2018.90",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "26.05 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1654897082,
    "accountNumber": "35302464616",
    "date": "2018-05-25",
    "amount": "-51.00",
    "text": "Esso Nyborg . Bergen",
    "alfareferanse": "25.05 ESSO NYBORG . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 475,
      "placeId": "ChIJeyicmf_9PEYRZunp1cnbohE",
      "placeName": "Esso",
      "coordinates": "60.4742847,5.3370742",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1654684335,
    "accountNumber": "35302464616",
    "date": "2018-05-24",
    "amount": "-269.00",
    "text": "Mathopen Pizzar Skareveien Mathopen",
    "alfareferanse": "23.05 MATHOPEN PIZZAR SKAREVEIEN MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 477,
      "placeId": "ChIJbV6bH-T6PEYRCPvUj7bBEQc",
      "placeName": "Mathopen Pizzeria",
      "coordinates": "60.3336074,5.2130728",
      "website": "http://www.mathopen-pizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1654695608,
    "accountNumber": "35302464616",
    "date": "2018-05-24",
    "amount": "-202.58",
    "text": "Yx Godvik 0213 Godvik",
    "alfareferanse": "23.05 YX GODVIK 0213   GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 468,
      "placeId": "ChIJ8Sb2U0P7PEYRasC-Yn2YkSg",
      "placeName": "YX Godvik",
      "coordinates": "60.3757182,5.2046342",
      "website": "https://yx.no/_stasjon?id=6f3dbb3e-b77c-4cac-8466-04ed9c58578e",
      "logoId": 43,
      "merchantName": "YX",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1654671283,
    "accountNumber": "35302464616",
    "date": "2018-05-24",
    "amount": "-54.00",
    "text": "Yx Godvik 0213 Godvik",
    "alfareferanse": "23.05 YX GODVIK 0213   GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 468,
      "placeId": "ChIJ8Sb2U0P7PEYRasC-Yn2YkSg",
      "placeName": "YX Godvik",
      "coordinates": "60.3757182,5.2046342",
      "website": "https://yx.no/_stasjon?id=6f3dbb3e-b77c-4cac-8466-04ed9c58578e",
      "logoId": 43,
      "merchantName": "YX",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1654242017,
    "accountNumber": "35302464616",
    "date": "2018-05-24",
    "amount": "-163.00",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "23.05 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1654039245,
    "accountNumber": "35302464616",
    "date": "2018-05-23",
    "amount": "-99.07",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "22.05 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652112713,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-61.00",
    "text": "Shell7- 11 Drotningsvik Godvik",
    "alfareferanse": "21.05 7513 SHELL7- 11 DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 473,
      "placeId": "ChIJ1dk79Uz7PEYR_5XNn5ofWSA",
      "placeName": "Shell",
      "coordinates": "60.3722839,5.1810615",
      "website": "https://find.shell.com/no/fuel/NO_7919-shell-7-eleven-drotningsvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652447468,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-149.50",
    "text": "Shell7- 11 Drotningsvik Godvik",
    "alfareferanse": "20.05 7513 SHELL7- 11 DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 473,
      "placeId": "ChIJ1dk79Uz7PEYR_5XNn5ofWSA",
      "placeName": "Shell",
      "coordinates": "60.3722839,5.1810615",
      "website": "https://find.shell.com/no/fuel/NO_7919-shell-7-eleven-drotningsvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652464275,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-77.94",
    "text": "Extra Øvregaten 37 Bergen",
    "alfareferanse": "22.05 EXTRA ØVREGATEN 37 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 479,
      "placeId": "ChIJv63NY6f-PEYRrcyEGTg-ilY",
      "placeName": "Coop Extra",
      "coordinates": "60.398484,5.32445",
      "website": "https://coop.no/butikker/extra/ovregaten-4947/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652641571,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-75.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "20.05 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652248124,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-30.00",
    "text": "Tin Tin Loi Da Bjørndalsstø Loddefjord",
    "alfareferanse": "19.05 TIN TIN LOI DA  BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 478,
      "placeId": "ChIJZxfZC6j7PEYRe0vxGBotL08",
      "placeName": "Tin Tin Loi Da",
      "coordinates": "60.3509164,5.2442406",
      "website": "http://www.tintinloi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1653317971,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-55.00",
    "text": "Circle K Fjell Fjell Fjell",
    "alfareferanse": "21.05 CIRCLE K FJELL  FJELL FJELL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 481,
      "placeId": "ChIJI6MFELvlPEYR9btu3vfsK60",
      "placeName": "Circle K",
      "coordinates": "60.3301493,5.0752831",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=52117&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652960360,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-159.44",
    "text": "Circle K Fjell Fjell Fjell",
    "alfareferanse": "21.05 CIRCLE K FJELL  FJELL FJELL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 481,
      "placeId": "ChIJI6MFELvlPEYR9btu3vfsK60",
      "placeName": "Circle K",
      "coordinates": "60.3301493,5.0752831",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=52117&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1653210684,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1651922482,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-585.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "21.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652972206,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "19.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1652774919,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "22.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1653347095,
    "accountNumber": "35302464616",
    "date": "2018-05-22",
    "amount": "-209.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "18.05 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1651305022,
    "accountNumber": "35302464616",
    "date": "2018-05-18",
    "amount": "-78.10",
    "text": "Vitusapotek Nor Strømgt 8 Bergen",
    "alfareferanse": "17.05 VITUSAPOTEK NOR STRØMGT 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 484,
      "placeId": "ChIJW-e2tK7-PEYRGP_JiUKGoXs",
      "placeName": "Vitusapotek Nordstjernen - Bergen",
      "coordinates": "60.3893095,5.3325248",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Nordstjernen-Bergen/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1651368028,
    "accountNumber": "35302464616",
    "date": "2018-05-18",
    "amount": "-6.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "17.05 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1651774638,
    "accountNumber": "35302464616",
    "date": "2018-05-18",
    "amount": "-107.00",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "17.05 NARVESEN 747 OLE BULLSPL. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1650582980,
    "accountNumber": "35302464616",
    "date": "2018-05-16",
    "amount": "-76.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.05 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1650522719,
    "accountNumber": "35302464616",
    "date": "2018-05-16",
    "amount": "-131.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "16.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1650242968,
    "accountNumber": "35302464616",
    "date": "2018-05-15",
    "amount": "-153.59",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "14.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1649250134,
    "accountNumber": "35302464616",
    "date": "2018-05-15",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "15.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1649840261,
    "accountNumber": "35302464616",
    "date": "2018-05-15",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "14.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1649197711,
    "accountNumber": "35302464616",
    "date": "2018-05-15",
    "amount": "-145.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 12.05 NOK 145.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1649504747,
    "accountNumber": "35302464616",
    "date": "2018-05-15",
    "amount": "-24.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 12.05 NOK 24.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648897524,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-131.00",
    "text": "Circle K Servic Nordre Brurå Nyborg",
    "alfareferanse": "12.05 CIRCLE K SERVIC NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 491,
      "placeId": "ChIJd0uwwucBPUYRZlp84b2Kdlc",
      "placeName": "Circle K Haukås",
      "coordinates": "60.4846724,5.3764514",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55564&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648242167,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-254.33",
    "text": "Circle K Servic Nordre Brurå Nyborg",
    "alfareferanse": "12.05 CIRCLE K SERVIC NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 491,
      "placeId": "ChIJd0uwwucBPUYRZlp84b2Kdlc",
      "placeName": "Circle K Haukås",
      "coordinates": "60.4846724,5.3764514",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55564&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648083482,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-595.70",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "12.05 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648806205,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-145.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 10.05 NOK 145.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648340327,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.05 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648440313,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-709.10",
    "text": "47 Plantasjen Alvehaugen 6 Godvik",
    "alfareferanse": "12.05 47 PLANTASJEN ALVEHAUGEN 6 GODVIK",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Hagesenter",
    "imageId": "",
    "place": {
      "id": 493,
      "placeId": "ChIJC1hM0Uj7PEYRcx6oy2zX0-4",
      "placeName": "Plantasjen",
      "coordinates": "60.3702736,5.1893302",
      "website": "https://www.plantasjen.no/storelocator/bergen-drotningsvik",
      "logoId": 54,
      "merchantName": "Plantasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1649079948,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-340.29",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "12.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648528954,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-64.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "13.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648969254,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-206.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "13.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1648799171,
    "accountNumber": "35302464616",
    "date": "2018-05-14",
    "amount": "-190.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "13.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1647308747,
    "accountNumber": "35302464616",
    "date": "2018-05-11",
    "amount": "-53.40",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "09.05 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1647581647,
    "accountNumber": "35302464616",
    "date": "2018-05-11",
    "amount": "-260.26",
    "text": "Esso Fjøsanger Kanalvn.1 Bergen",
    "alfareferanse": "11.05 ESSO FJØSANGER  KANALVN.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 496,
      "placeId": "ChIJ1S-heU_5PEYRWA7Ve6VYQlI",
      "placeName": "Esso",
      "coordinates": "60.3711804,5.3411141",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1647507023,
    "accountNumber": "35302464616",
    "date": "2018-05-11",
    "amount": "-160.00",
    "text": "Esso Fjøsanger Kanalvn.1 Bergen",
    "alfareferanse": "11.05 ESSO FJØSANGER  KANALVN.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 496,
      "placeId": "ChIJ1S-heU_5PEYRWA7Ve6VYQlI",
      "placeName": "Esso",
      "coordinates": "60.3711804,5.3411141",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1647516274,
    "accountNumber": "35302464616",
    "date": "2018-05-11",
    "amount": "-31.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "11.05 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1647628318,
    "accountNumber": "35302464616",
    "date": "2018-05-11",
    "amount": "-246.90",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "10.05 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1647237536,
    "accountNumber": "35302464616",
    "date": "2018-05-11",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 08.05 NOK 65.00 PlaystationNetwork Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1646774822,
    "accountNumber": "35302464616",
    "date": "2018-05-09",
    "amount": "-103.00",
    "text": "Shell Nygård Lyderhornsve Laksevåg",
    "alfareferanse": "08.05 SHELL NYGÅRD LYDERHORNSVE LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 499,
      "placeId": "ChIJfXhiQYT7PEYRPGY9dKF5xmg",
      "placeName": "Shell",
      "coordinates": "60.3847311,5.2712933",
      "website": "https://find.shell.com/no/fuel/NO_4140-shell-nygard",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1646797602,
    "accountNumber": "35302464616",
    "date": "2018-05-09",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "09.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1646839198,
    "accountNumber": "35302464616",
    "date": "2018-05-09",
    "amount": "-78.17",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "09.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1646379327,
    "accountNumber": "35302464616",
    "date": "2018-05-08",
    "amount": "-274.20",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "07.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1646170121,
    "accountNumber": "35302464616",
    "date": "2018-05-08",
    "amount": "-60.90",
    "text": "Meny Dolviken Dolvikvn. 36 Søriedgrend",
    "alfareferanse": "08.05 MENY DOLVIKEN DOLVIKVN. 36 SØRIEDGREND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 472,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1645940514,
    "accountNumber": "35302464616",
    "date": "2018-05-08",
    "amount": "-203.56",
    "text": "Yx Godvik 0213 Godvik",
    "alfareferanse": "08.05 YX GODVIK 0213   GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 468,
      "placeId": "ChIJ8Sb2U0P7PEYRasC-Yn2YkSg",
      "placeName": "YX Godvik",
      "coordinates": "60.3757182,5.2046342",
      "website": "https://yx.no/_stasjon?id=6f3dbb3e-b77c-4cac-8466-04ed9c58578e",
      "logoId": 43,
      "merchantName": "YX",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1646147071,
    "accountNumber": "35302464616",
    "date": "2018-05-08",
    "amount": "-85.00",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "08.05 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1645100975,
    "accountNumber": "35302464616",
    "date": "2018-05-07",
    "amount": "-104.96",
    "text": "Esso Fjøsanger Kanalvn.1 Bergen",
    "alfareferanse": "06.05 ESSO FJØSANGER  KANALVN.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 496,
      "placeId": "ChIJ1S-heU_5PEYRWA7Ve6VYQlI",
      "placeName": "Esso",
      "coordinates": "60.3711804,5.3411141",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1645646951,
    "accountNumber": "35302464616",
    "date": "2018-05-07",
    "amount": "-50.00",
    "text": "Esso Fjøsanger Kanalvn.1 Bergen",
    "alfareferanse": "06.05 ESSO FJØSANGER  KANALVN.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 496,
      "placeId": "ChIJ1S-heU_5PEYRWA7Ve6VYQlI",
      "placeName": "Esso",
      "coordinates": "60.3711804,5.3411141",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1645145145,
    "accountNumber": "35302464616",
    "date": "2018-05-07",
    "amount": "-27.00",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "06.05 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1645236576,
    "accountNumber": "35302464616",
    "date": "2018-05-07",
    "amount": "-256.00",
    "text": "Shell7- 11 Drotningsvik Godvik",
    "alfareferanse": "06.05 7513 SHELL7- 11 DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 473,
      "placeId": "ChIJ1dk79Uz7PEYR_5XNn5ofWSA",
      "placeName": "Shell",
      "coordinates": "60.3722839,5.1810615",
      "website": "https://find.shell.com/no/fuel/NO_7919-shell-7-eleven-drotningsvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1645123126,
    "accountNumber": "35302464616",
    "date": "2018-05-07",
    "amount": "-34.40",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "07.05 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1644663783,
    "accountNumber": "35302464616",
    "date": "2018-05-04",
    "amount": "-55.78",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "03.05 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1643855060,
    "accountNumber": "35302464616",
    "date": "2018-05-03",
    "amount": "-147.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "02.05 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1643939010,
    "accountNumber": "35302464616",
    "date": "2018-05-03",
    "amount": "-159.38",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "02.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642988207,
    "accountNumber": "35302464616",
    "date": "2018-05-02",
    "amount": "-130.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "01.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1643158858,
    "accountNumber": "35302464616",
    "date": "2018-05-02",
    "amount": "-90.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "01.05 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642927606,
    "accountNumber": "35302464616",
    "date": "2018-05-02",
    "amount": "-208.88",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "30.04 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642180289,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-156.40",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "28.04 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1641774858,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-1500.00",
    "text": "Iz *Empire Tattoo",
    "alfareferanse": "*0971 26.04 NOK 1500.00 IZ *EMPIRE TATTOO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 510,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1641780809,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-142.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         71 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 511,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642372177,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-110.00",
    "text": "7 - Eleven 7134 Torget. 17 Bergen",
    "alfareferanse": "29.04 7 - ELEVEN 7134 TORGET. 17 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 512,
      "placeId": "ChIJW7VB36f-PEYRiPmsO1fOA-Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3953766,5.3262766",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1641653242,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-291.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "29.04 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642728909,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-381.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "29.04 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642498472,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-175.00",
    "text": "Little Ea 1602 Folke Bernad Fyllingsdalen",
    "alfareferanse": "28.04 LITTLE EA 1602  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 513,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642524282,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-100.00",
    "text": "Klubb Kok Christian Mi Bergen",
    "alfareferanse": "29.04 KLUBB KOK CHRISTIAN MI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 514,
      "placeId": "ChIJl5eioAL8PEYR0fIhVxOAOj0",
      "placeName": "club Kok",
      "coordinates": "60.3933996,5.3230142",
      "website": "http://klubbkok.no/",
      "logoId": 60,
      "merchantName": "Klubb Kok",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642324337,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-47.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "28.04 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642125831,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-152.71",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "29.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642470231,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "29.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1642540177,
    "accountNumber": "35302464616",
    "date": "2018-04-30",
    "amount": "-945.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1640922439,
    "accountNumber": "35302464616",
    "date": "2018-04-27",
    "amount": "-129.39",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "26.04 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1641198046,
    "accountNumber": "35302464616",
    "date": "2018-04-27",
    "amount": "-24.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 25.04 NOK 24.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1640334328,
    "accountNumber": "35302464616",
    "date": "2018-04-26",
    "amount": "-151.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "26.04 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1640433019,
    "accountNumber": "35302464616",
    "date": "2018-04-26",
    "amount": "-179.00",
    "text": "Jula Bergen Oas Folke Barnad Fyllingsdalen",
    "alfareferanse": "25.04 JULA BERGEN OAS FOLKE BARNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 516,
      "placeId": "ChIJp13-g8_7PEYRFzYXBSYJXB8",
      "placeName": "Jula",
      "coordinates": "60.3485852,5.2915106",
      "website": "http://www.jula.no/vare-varehus/bergen-fyllingsdalen/",
      "logoId": 61,
      "merchantName": "Jula",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1640400421,
    "accountNumber": "35302464616",
    "date": "2018-04-26",
    "amount": "-77.60",
    "text": "Meny Dolviken Dolvikvn. 36 Søriedgrend",
    "alfareferanse": "26.04 MENY DOLVIKEN DOLVIKVN. 36 SØRIEDGREND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 472,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1640502335,
    "accountNumber": "35302464616",
    "date": "2018-04-26",
    "amount": "-204.22",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "26.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1640749733,
    "accountNumber": "35302464616",
    "date": "2018-04-26",
    "amount": "-1211.65",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.04 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1640572945,
    "accountNumber": "35302464616",
    "date": "2018-04-26",
    "amount": "-325.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "26.04 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1639266789,
    "accountNumber": "35302464616",
    "date": "2018-04-24",
    "amount": "-11.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "23.04 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1639405899,
    "accountNumber": "35302464616",
    "date": "2018-04-24",
    "amount": "-133.00",
    "text": "Solbrød Oasen ( Folke Bernad Fyllingsdalen",
    "alfareferanse": "24.04 SOLBRØD OASEN ( FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 520,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1639220479,
    "accountNumber": "35302464616",
    "date": "2018-04-24",
    "amount": "-203.34",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "24.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1639715131,
    "accountNumber": "35302464616",
    "date": "2018-04-24",
    "amount": "-30.00",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "24.04 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1639687572,
    "accountNumber": "35302464616",
    "date": "2018-04-24",
    "amount": "-28.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "24.04 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1638729167,
    "accountNumber": "35302464616",
    "date": "2018-04-23",
    "amount": "-272.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "22.04 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1638308473,
    "accountNumber": "35302464616",
    "date": "2018-04-23",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1638411745,
    "accountNumber": "35302464616",
    "date": "2018-04-23",
    "amount": "-94.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "22.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1637952086,
    "accountNumber": "35302464616",
    "date": "2018-04-20",
    "amount": "-120.41",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "19.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1637714972,
    "accountNumber": "35302464616",
    "date": "2018-04-20",
    "amount": "-177.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "20.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1636999656,
    "accountNumber": "35302464616",
    "date": "2018-04-19",
    "amount": "-94.48",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "18.04 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1636558008,
    "accountNumber": "35302464616",
    "date": "2018-04-18",
    "amount": "-129.92",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "17.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1635630095,
    "accountNumber": "35302464616",
    "date": "2018-04-17",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.04 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1635364484,
    "accountNumber": "35302464616",
    "date": "2018-04-17",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.04 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1635052457,
    "accountNumber": "35302464616",
    "date": "2018-04-17",
    "amount": "-54.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "17.04 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1635417782,
    "accountNumber": "35302464616",
    "date": "2018-04-17",
    "amount": "-20.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 13.04 NOK 20.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1634749816,
    "accountNumber": "35302464616",
    "date": "2018-04-16",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 12.04 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1634148714,
    "accountNumber": "35302464616",
    "date": "2018-04-16",
    "amount": "-207.35",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "15.04 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1633916321,
    "accountNumber": "35302464616",
    "date": "2018-04-16",
    "amount": "-18.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "13.04 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1634531354,
    "accountNumber": "35302464616",
    "date": "2018-04-16",
    "amount": "-170.00",
    "text": "Tin Tin Loi Da Bjørndalsstø Loddefjord",
    "alfareferanse": "16.04 TIN TIN LOI DA  BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 478,
      "placeId": "ChIJZxfZC6j7PEYRe0vxGBotL08",
      "placeName": "Tin Tin Loi Da",
      "coordinates": "60.3509164,5.2442406",
      "website": "http://www.tintinloi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1634578411,
    "accountNumber": "35302464616",
    "date": "2018-04-16",
    "amount": "-101.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "14.04 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1633289553,
    "accountNumber": "35302464616",
    "date": "2018-04-13",
    "amount": "-145.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 11.04 NOK 145.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1633159745,
    "accountNumber": "35302464616",
    "date": "2018-04-13",
    "amount": "-121.00",
    "text": "Subway Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "13.04 SUBWAY OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 522,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1633494343,
    "accountNumber": "35302464616",
    "date": "2018-04-13",
    "amount": "-11.19",
    "text": "Apotek 1 Oasen Oasen Senter Fyllingsdalen",
    "alfareferanse": "13.04 APOTEK 1 OASEN  OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 523,
      "placeId": "ChIJp13-g8_7PEYR5MFkfv-WtX8",
      "placeName": "Apotek 1",
      "coordinates": "60.349305,5.2902173",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/oasen-895",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1632893149,
    "accountNumber": "35302464616",
    "date": "2018-04-12",
    "amount": "-11.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "11.04 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1632353508,
    "accountNumber": "35302464616",
    "date": "2018-04-12",
    "amount": "-56.79",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "11.04 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1632356360,
    "accountNumber": "35302464616",
    "date": "2018-04-12",
    "amount": "-35.90",
    "text": "Fyllingsdalen Post I Butik Bergen",
    "alfareferanse": "12.04 FYLLINGSDALEN POST I BUTIK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 525,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1632908588,
    "accountNumber": "35302464616",
    "date": "2018-04-12",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.04 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1632481835,
    "accountNumber": "35302464616",
    "date": "2018-04-12",
    "amount": "-204.72",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "11.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1635932401,
    "accountNumber": "35302464616",
    "date": "2018-04-11",
    "amount": "-102.79",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "10.04 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1635838713,
    "accountNumber": "35302464616",
    "date": "2018-04-11",
    "amount": "-46.70",
    "text": "Kiwi 823 Spelha Krokatjønnv. Fyllingsdalen",
    "alfareferanse": "11.04 KIWI 823 SPELHA KROKATJØNNV. FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 526,
      "placeId": "ChIJA6mH5rf7PEYRacLUrZF-r2g",
      "placeName": "Kiwi Spelhaugen",
      "coordinates": "60.3498878,5.2765162",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Spelhaugen/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1600523351,
    "accountNumber": "35302464616",
    "date": "2018-04-10",
    "amount": "-125.00",
    "text": "Klarna * Wish",
    "alfareferanse": "*0971 08.04 NOK 125.00 Klarna * Wish Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 527,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1600330534,
    "accountNumber": "35302464616",
    "date": "2018-04-10",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.04 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1600237814,
    "accountNumber": "35302464616",
    "date": "2018-04-10",
    "amount": "-198.98",
    "text": "Shell Nygård Lyderhornsve Laksevåg",
    "alfareferanse": "09.04 SHELL NYGÅRD LYDERHORNSVE LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 499,
      "placeId": "ChIJfXhiQYT7PEYRPGY9dKF5xmg",
      "placeName": "Shell",
      "coordinates": "60.3847311,5.2712933",
      "website": "https://find.shell.com/no/fuel/NO_4140-shell-nygard",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1600017878,
    "accountNumber": "35302464616",
    "date": "2018-04-09",
    "amount": "-117.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "08.04 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1599845976,
    "accountNumber": "35302464616",
    "date": "2018-04-09",
    "amount": "-127.00",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "08.04 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1598924243,
    "accountNumber": "35302464616",
    "date": "2018-04-06",
    "amount": "-174.00",
    "text": "Fyllingsdalen Post I Butik Bergen",
    "alfareferanse": "05.04 FYLLINGSDALEN POST I BUTIK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 525,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1599010207,
    "accountNumber": "35302464616",
    "date": "2018-04-06",
    "amount": "-30.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "05.04 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1595931003,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-407.90",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "31.03 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1595418506,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-59.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "03.04 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1595939789,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-400.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "01.04 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1597584094,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-361.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "01.04 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1597084646,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-97.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "01.04 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1595435366,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-236.40",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "28.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596482143,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "30.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1595753294,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-168.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "01.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596118053,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-248.00",
    "text": "Norsk Restauran Rosenkrantzg Bergen",
    "alfareferanse": "01.04 NORSK RESTAURAN ROSENKRANTZG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 529,
      "placeId": "ChIJhy_x0qn-PEYRhXxHb2ehoEI",
      "placeName": "BARE Restaurant",
      "coordinates": "60.3938271,5.3262673",
      "website": "http://www.barerestaurant.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596537128,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-282.20",
    "text": "Obs Vestkanten Loddefjord",
    "alfareferanse": "31.03 OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 530,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596851567,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-201.00",
    "text": "Shell 7- E Åsamyrane 16 Nyborg",
    "alfareferanse": "29.03 7522 SHELL 7- E ÅSAMYRANE 16 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 531,
      "placeId": "ChIJ_d3defn9PEYRVwcP3ClZ3V8",
      "placeName": "Shell",
      "coordinates": "60.4700235,5.3267621",
      "website": "https://find.shell.com/no/fuel/NO_7912-shell-7-eleven-nyborg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1597497083,
    "accountNumber": "35302464616",
    "date": "2018-04-03",
    "amount": "-183.00",
    "text": "Norgestaxi As Ensjøveien 2 Oslo",
    "alfareferanse": "01.04 NORGESTAXI AS ENSJØVEIEN 2 OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 532,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596884623,
    "accountNumber": "35302464616",
    "date": "2018-03-31",
    "amount": "-30.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           3 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1595548238,
    "accountNumber": "35302464616",
    "date": "2018-03-31",
    "amount": "-132.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         66 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 534,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596683462,
    "accountNumber": "35302464616",
    "date": "2018-03-28",
    "amount": "-137.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "28.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596057250,
    "accountNumber": "35302464616",
    "date": "2018-03-28",
    "amount": "-945.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1596739614,
    "accountNumber": "35302464616",
    "date": "2018-03-28",
    "amount": "-73.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "27.03 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1594133028,
    "accountNumber": "35302464616",
    "date": "2018-03-26",
    "amount": "-150.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "25.03 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1594728575,
    "accountNumber": "35302464616",
    "date": "2018-03-26",
    "amount": "-116.69",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "24.03 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1593840410,
    "accountNumber": "35302464616",
    "date": "2018-03-26",
    "amount": "-45.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "25.03 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1593895720,
    "accountNumber": "35302464616",
    "date": "2018-03-26",
    "amount": "-71.00",
    "text": "God Servering A Engen 12 Bergen",
    "alfareferanse": "25.03 GOD SERVERING A ENGEN 12 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 536,
      "placeId": "ChIJy85aqyb8PEYRuvuQng3b5Yc",
      "placeName": "Bergen servering AS",
      "coordinates": "60.412205,5.3188574",
      "website": "http://bergenservering.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1594701787,
    "accountNumber": "35302464616",
    "date": "2018-03-26",
    "amount": "-71.00",
    "text": "God Servering A Engen 12 Bergen",
    "alfareferanse": "25.03 GOD SERVERING A ENGEN 12 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 536,
      "placeId": "ChIJy85aqyb8PEYRuvuQng3b5Yc",
      "placeName": "Bergen servering AS",
      "coordinates": "60.412205,5.3188574",
      "website": "http://bergenservering.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1593221827,
    "accountNumber": "35302464616",
    "date": "2018-03-23",
    "amount": "-185.70",
    "text": "37 Plantasjen Gravdalsvn 1 Laksevåg",
    "alfareferanse": "22.03 37 PLANTASJEN GRAVDALSVN 1 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Hagesenter",
    "imageId": "",
    "place": {
      "id": 537,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1593508160,
    "accountNumber": "35302464616",
    "date": "2018-03-23",
    "amount": "-418.00",
    "text": "Kiwi 867 Hetlevikåsen Loddefjord",
    "alfareferanse": "22.03 KIWI 867 HETLEVIKÅSEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 538,
      "placeId": "ChIJ5-aeagX7PEYRCZnQ_dlCtaM",
      "placeName": "KIWI Hetlevikåsen",
      "coordinates": "60.3505026,5.2275957",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Hetlevikasen/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1593109514,
    "accountNumber": "35302464616",
    "date": "2018-03-22",
    "amount": "-747.38",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "21.03 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1593079551,
    "accountNumber": "35302464616",
    "date": "2018-03-22",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "22.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1592653057,
    "accountNumber": "35302464616",
    "date": "2018-03-21",
    "amount": "-99.76",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "20.03 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1591887724,
    "accountNumber": "35302464616",
    "date": "2018-03-20",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1591280065,
    "accountNumber": "35302464616",
    "date": "2018-03-20",
    "amount": "-204.80",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "20.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1591272299,
    "accountNumber": "35302464616",
    "date": "2018-03-20",
    "amount": "-348.90",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "20.03 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1591390216,
    "accountNumber": "35302464616",
    "date": "2018-03-20",
    "amount": "-132.80",
    "text": "Obs Vestkanten Loddefjord",
    "alfareferanse": "20.03 OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 530,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1590156788,
    "accountNumber": "35302464616",
    "date": "2018-03-19",
    "amount": "-376.90",
    "text": "Kiwi 806 Olsvik Olsvikvn. 8A Olsvik",
    "alfareferanse": "17.03 KIWI 806 OLSVIK OLSVIKVN. 8A OLSVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 539,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1590944490,
    "accountNumber": "35302464616",
    "date": "2018-03-19",
    "amount": "-204.10",
    "text": "Shell7- 11 Ådnavegen 63 Indre Arna",
    "alfareferanse": "16.03 7526 SHELL7- 11 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 540,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1590754968,
    "accountNumber": "35302464616",
    "date": "2018-03-19",
    "amount": "-192.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "18.03 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1591128225,
    "accountNumber": "35302464616",
    "date": "2018-03-19",
    "amount": "-277.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "17.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1591192838,
    "accountNumber": "35302464616",
    "date": "2018-03-19",
    "amount": "-692.78",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "16.03 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1589750388,
    "accountNumber": "35302464616",
    "date": "2018-03-16",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.03 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1589838570,
    "accountNumber": "35302464616",
    "date": "2018-03-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.03 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1589804740,
    "accountNumber": "35302464616",
    "date": "2018-03-16",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "15.03 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1589670957,
    "accountNumber": "35302464616",
    "date": "2018-03-16",
    "amount": "-212.10",
    "text": "Vitusapotek Nor Strømgt 8 Bergen",
    "alfareferanse": "15.03 VITUSAPOTEK NOR STRØMGT 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 484,
      "placeId": "ChIJW-e2tK7-PEYRGP_JiUKGoXs",
      "placeName": "Vitusapotek Nordstjernen - Bergen",
      "coordinates": "60.3893095,5.3325248",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Nordstjernen-Bergen/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1590005928,
    "accountNumber": "35302464616",
    "date": "2018-03-16",
    "amount": "-142.00",
    "text": "Bk Bystasj 5022 Strøm Gt. 8 Bergen",
    "alfareferanse": "15.03 BK BYSTASJ 5022 STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 541,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1588891607,
    "accountNumber": "35302464616",
    "date": "2018-03-15",
    "amount": "-61.89",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "14.03 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1588699916,
    "accountNumber": "35302464616",
    "date": "2018-03-14",
    "amount": "-1500.00",
    "text": "Nokas 7-Eleven Christie 5015 Bergen",
    "alfareferanse": "13.03 Nokas 7-Eleven Christie 5015 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 542,
      "placeId": "ChIJxdLM8an-PEYRblSEiJkkpGI",
      "placeName": "7-Eleven Christiesgate",
      "coordinates": "60.3893981,5.323395",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1588142787,
    "accountNumber": "35302464616",
    "date": "2018-03-13",
    "amount": "-60.00",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "13.03 MENY BERGEN STORSENTER.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1587996127,
    "accountNumber": "35302464616",
    "date": "2018-03-13",
    "amount": "-265.90",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "13.03 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1588308248,
    "accountNumber": "35302464616",
    "date": "2018-03-13",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "13.03 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1588221266,
    "accountNumber": "35302464616",
    "date": "2018-03-13",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.03 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1587075861,
    "accountNumber": "35302464616",
    "date": "2018-03-12",
    "amount": "-81.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 08.03 NOK 81.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1586769198,
    "accountNumber": "35302464616",
    "date": "2018-03-12",
    "amount": "-523.90",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "10.03 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1586683842,
    "accountNumber": "35302464616",
    "date": "2018-03-12",
    "amount": "-761.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "10.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1586456576,
    "accountNumber": "35302464616",
    "date": "2018-03-09",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.03 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585811844,
    "accountNumber": "35302464616",
    "date": "2018-03-08",
    "amount": "-84.70",
    "text": "429Europris Fyl Spelhaugen 6 Fyllingsdalen",
    "alfareferanse": "07.03 429EUROPRIS FYL SPELHAUGEN 6 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 544,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585969668,
    "accountNumber": "35302464616",
    "date": "2018-03-08",
    "amount": "-175.00",
    "text": "Fyllingsdalen Post I Butik Bergen",
    "alfareferanse": "07.03 FYLLINGSDALEN POST I BUTIK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 525,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585423229,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-55.90",
    "text": "Obs Vestkanten Loddefjord",
    "alfareferanse": "06.03 OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 530,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585663317,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-11.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "06.03 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585592043,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-220.00",
    "text": "Lazio Pizza C/O Ibrahim Godvik",
    "alfareferanse": "07.03 LAZIO PIZZA C/O IBRAHIM  GODVIK",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 547,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585420772,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-250.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "06.03 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585570085,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-480.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "06.03 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585258621,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-98.80",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "07.03 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585652328,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 04.03 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585608390,
    "accountNumber": "35302464616",
    "date": "2018-03-07",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 02.03 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1585113912,
    "accountNumber": "35302464616",
    "date": "2018-03-06",
    "amount": "-32.00",
    "text": "Shell7- 11 Drotningsvik Godvik",
    "alfareferanse": "05.03 7513 SHELL7- 11 DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 473,
      "placeId": "ChIJ1dk79Uz7PEYR_5XNn5ofWSA",
      "placeName": "Shell",
      "coordinates": "60.3722839,5.1810615",
      "website": "https://find.shell.com/no/fuel/NO_7919-shell-7-eleven-drotningsvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584929810,
    "accountNumber": "35302464616",
    "date": "2018-03-06",
    "amount": "-1000.00",
    "text": "Nordea Vestkanten Storsenter, Loddefjord 23:18",
    "alfareferanse": "05.03 NORDEA VESTKANTEN STORSENTER, LODDEFJORD 23:18",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 549,
      "placeId": "ChIJo0lIzQz7PEYR2-ImB67rsBM",
      "placeName": "Vestkanten Storsenter",
      "coordinates": "60.363224,5.233616",
      "website": "http://www.vestkanten.no/?utm_source=google&utm_medium=infoboks&utm_campaign=GMB",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584211263,
    "accountNumber": "35302464616",
    "date": "2018-03-05",
    "amount": "-84.00",
    "text": "Shell Randbergv Randabergvei Stavanger",
    "alfareferanse": "05.03 SHELL RANDBERGV RANDABERGVEI STAVANGER",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 550,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584454084,
    "accountNumber": "35302464616",
    "date": "2018-03-05",
    "amount": "-1000.00",
    "text": "Nordea Vestkanten Storsenter, Loddefjord 04:07",
    "alfareferanse": "03.03 NORDEA VESTKANTEN STORSENTER, LODDEFJORD 04:07",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 551,
      "placeId": "ChIJo0lIzQz7PEYR2-ImB67rsBM",
      "placeName": "Vestkanten Storsenter",
      "coordinates": "60.363224,5.233616",
      "website": "http://www.vestkanten.no/?utm_source=google&utm_medium=infoboks&utm_campaign=GMB",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584720003,
    "accountNumber": "35302464616",
    "date": "2018-03-05",
    "amount": "-200.09",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "03.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584431544,
    "accountNumber": "35302464616",
    "date": "2018-03-05",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 01.03 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584088024,
    "accountNumber": "35302464616",
    "date": "2018-03-05",
    "amount": "-122.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "04.03 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584097132,
    "accountNumber": "35302464616",
    "date": "2018-03-05",
    "amount": "-294.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "03.03 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1584036788,
    "accountNumber": "35302464616",
    "date": "2018-03-05",
    "amount": "-820.76",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "02.03 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1583877006,
    "accountNumber": "35302464616",
    "date": "2018-03-02",
    "amount": "-190.80",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "01.03 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1583526964,
    "accountNumber": "35302464616",
    "date": "2018-03-02",
    "amount": "-86.30",
    "text": "Kiwi 823 Spelha Krokatjønnv. Fyllingsdalen",
    "alfareferanse": "01.03 KIWI 823 SPELHA KROKATJØNNV. FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 526,
      "placeId": "ChIJA6mH5rf7PEYRacLUrZF-r2g",
      "placeName": "Kiwi Spelhaugen",
      "coordinates": "60.3498878,5.2765162",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Spelhaugen/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1583354457,
    "accountNumber": "35302464616",
    "date": "2018-03-01",
    "amount": "-32.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "28.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582877591,
    "accountNumber": "35302464616",
    "date": "2018-03-01",
    "amount": "-165.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "01.03 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582615800,
    "accountNumber": "35302464616",
    "date": "2018-02-28",
    "amount": "-154.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "28.02 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582518398,
    "accountNumber": "35302464616",
    "date": "2018-02-28",
    "amount": "-209.72",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "27.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582443969,
    "accountNumber": "35302464616",
    "date": "2018-02-28",
    "amount": "-92.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "27.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582684260,
    "accountNumber": "35302464616",
    "date": "2018-02-28",
    "amount": "-945.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582667489,
    "accountNumber": "35302464616",
    "date": "2018-02-28",
    "amount": "-65.99",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "27.02 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582254763,
    "accountNumber": "35302464616",
    "date": "2018-02-28",
    "amount": "-20.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1582825626,
    "accountNumber": "35302464616",
    "date": "2018-02-28",
    "amount": "-122.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         61 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 534,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1581637353,
    "accountNumber": "35302464616",
    "date": "2018-02-27",
    "amount": "-50.00",
    "text": "Vipps",
    "alfareferanse": "*0971 23.02 NOK 50.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1581596593,
    "accountNumber": "35302464616",
    "date": "2018-02-27",
    "amount": "-27.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "26.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580979943,
    "accountNumber": "35302464616",
    "date": "2018-02-26",
    "amount": "-152.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "25.02 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1581464761,
    "accountNumber": "35302464616",
    "date": "2018-02-26",
    "amount": "-22.20",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "23.02 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1581477110,
    "accountNumber": "35302464616",
    "date": "2018-02-26",
    "amount": "-318.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "24.02 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1581125605,
    "accountNumber": "35302464616",
    "date": "2018-02-26",
    "amount": "-84.00",
    "text": "Barbarista Barbarista / Bergen",
    "alfareferanse": "24.02 BARBARISTA BARBARISTA / BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 553,
      "placeId": "ChIJT6yqWqf-PEYRilSaC1yEHkQ",
      "placeName": "BarBarista",
      "coordinates": "60.3973072,5.3260528",
      "website": "https://www.facebook.com/BarBarista.no/?ref=aymt_homepage_panel",
      "logoId": 71,
      "merchantName": "BarBarista",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1581154263,
    "accountNumber": "35302464616",
    "date": "2018-02-26",
    "amount": "-108.00",
    "text": "Barbarista Barbarista / Bergen",
    "alfareferanse": "24.02 BARBARISTA BARBARISTA / BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 553,
      "placeId": "ChIJT6yqWqf-PEYRilSaC1yEHkQ",
      "placeName": "BarBarista",
      "coordinates": "60.3973072,5.3260528",
      "website": "https://www.facebook.com/BarBarista.no/?ref=aymt_homepage_panel",
      "logoId": 71,
      "merchantName": "BarBarista",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580084104,
    "accountNumber": "35302464616",
    "date": "2018-02-23",
    "amount": "-400.00",
    "text": "Nordea Vestkanten Storsenter, Loddefjord 19:19",
    "alfareferanse": "22.02 NORDEA VESTKANTEN STORSENTER, LODDEFJORD 19:19",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 554,
      "placeId": "ChIJo0lIzQz7PEYR2-ImB67rsBM",
      "placeName": "Vestkanten Storsenter",
      "coordinates": "60.363224,5.233616",
      "website": "http://www.vestkanten.no/?utm_source=google&utm_medium=infoboks&utm_campaign=GMB",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580441156,
    "accountNumber": "35302464616",
    "date": "2018-02-23",
    "amount": "-181.50",
    "text": "131 Boots Apotek Fyllingsdalen",
    "alfareferanse": "22.02 131 BOOTS APOTEK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 555,
      "placeId": "ChIJp13-g8_7PEYRukP7E0TM2X8",
      "placeName": "Boots apotek Fyllingsdalen",
      "coordinates": "60.348958,5.2918029",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580295149,
    "accountNumber": "35302464616",
    "date": "2018-02-23",
    "amount": "-115.00",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "22.02 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580330500,
    "accountNumber": "35302464616",
    "date": "2018-02-23",
    "amount": "-72.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "23.02 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580238966,
    "accountNumber": "35302464616",
    "date": "2018-02-23",
    "amount": "-72.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "22.02 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580307194,
    "accountNumber": "35302464616",
    "date": "2018-02-23",
    "amount": "-365.90",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "23.02 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1580151733,
    "accountNumber": "35302464616",
    "date": "2018-02-23",
    "amount": "-206.19",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "22.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1579970800,
    "accountNumber": "35302464616",
    "date": "2018-02-22",
    "amount": "-71.00",
    "text": "Bk Sartor 5037 Sartor Sent Straume",
    "alfareferanse": "22.02 BK SARTOR 5037  SARTOR SENT  STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 556,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1579138500,
    "accountNumber": "35302464616",
    "date": "2018-02-21",
    "amount": "-500.00",
    "text": "Iz *Skin Fuel Empi",
    "alfareferanse": "*0971 19.02 NOK 500.00 IZ *SKIN FUEL EMPI Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Buss",
    "imageId": "",
    "place": {
      "id": 557,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1579501031,
    "accountNumber": "35302464616",
    "date": "2018-02-21",
    "amount": "-62.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 19.02 NOK 62.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1578809179,
    "accountNumber": "35302464616",
    "date": "2018-02-20",
    "amount": "-220.09",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "20.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1578662541,
    "accountNumber": "35302464616",
    "date": "2018-02-20",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1578315886,
    "accountNumber": "35302464616",
    "date": "2018-02-20",
    "amount": "-700.00",
    "text": "Vipps",
    "alfareferanse": "*0971 16.02 NOK 700.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1578276041,
    "accountNumber": "35302464616",
    "date": "2018-02-20",
    "amount": "-150.00",
    "text": "Vipps",
    "alfareferanse": "*0971 16.02 NOK 150.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1578948466,
    "accountNumber": "35302464616",
    "date": "2018-02-20",
    "amount": "-150.00",
    "text": "Vipps",
    "alfareferanse": "*0971 18.02 NOK 150.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1578210181,
    "accountNumber": "35302464616",
    "date": "2018-02-20",
    "amount": "-150.00",
    "text": "Vipps",
    "alfareferanse": "*0971 17.02 NOK 150.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1578854447,
    "accountNumber": "35302464616",
    "date": "2018-02-20",
    "amount": "-204.00",
    "text": "Bk Sartor 5037 Sartor Sent Straume",
    "alfareferanse": "19.02 BK SARTOR 5037  SARTOR SENT  STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 556,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577209912,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-260.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "18.02 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577480669,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-200.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "18.02 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577836469,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-150.00",
    "text": "Upstairs As Vestre Torvg Bergen",
    "alfareferanse": "17.02 UPSTAIRS AS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 562,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577210024,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-27.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "17.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577380449,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-1700.00",
    "text": "Nordea Vestkanten Storsenter, Loddefjord 20:59",
    "alfareferanse": "16.02 NORDEA VESTKANTEN STORSENTER, LODDEFJORD 20:59",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 560,
      "placeId": "ChIJo0lIzQz7PEYR2-ImB67rsBM",
      "placeName": "Vestkanten Storsenter",
      "coordinates": "60.363224,5.233616",
      "website": "http://www.vestkanten.no/?utm_source=google&utm_medium=infoboks&utm_campaign=GMB",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577573808,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-79.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "17.02 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577182322,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-49.50",
    "text": "Apotek 1 Oasen Oasen Senter Fyllingsdalen",
    "alfareferanse": "16.02 APOTEK 1 OASEN  OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 523,
      "placeId": "ChIJp13-g8_7PEYR5MFkfv-WtX8",
      "placeName": "Apotek 1",
      "coordinates": "60.349305,5.2902173",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/oasen-895",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577473847,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-94.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "17.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577229874,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-231.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "18.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577380234,
    "accountNumber": "35302464616",
    "date": "2018-02-19",
    "amount": "-279.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "17.02 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1577039238,
    "accountNumber": "35302464616",
    "date": "2018-02-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.02 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1576091885,
    "accountNumber": "35302464616",
    "date": "2018-02-15",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.02 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1575992516,
    "accountNumber": "35302464616",
    "date": "2018-02-15",
    "amount": "-234.39",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "14.02 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1575882958,
    "accountNumber": "35302464616",
    "date": "2018-02-15",
    "amount": "-92.00",
    "text": "Big Bite Oasen F.Bernadotte Fyllingsdalen",
    "alfareferanse": "14.02 BIG BITE OASEN  F.BERNADOTTE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 563,
      "placeId": "ChIJp13-g8_7PEYRsozFVYYiG7w",
      "placeName": "Big Bite",
      "coordinates": "60.3491563,5.2911056",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1576004294,
    "accountNumber": "35302464616",
    "date": "2018-02-15",
    "amount": "-399.00",
    "text": "H&M No0854 Oasen Kjøpes Fyllingsdalen",
    "alfareferanse": "14.02 H&M NO0854 OASEN KJØPES FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 564,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1576482753,
    "accountNumber": "35302464616",
    "date": "2018-02-15",
    "amount": "-646.90",
    "text": "H&M No0854 Oasen Kjøpes Fyllingsdalen",
    "alfareferanse": "14.02 H&M NO0854 OASEN KJØPES FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 564,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1576275016,
    "accountNumber": "35302464616",
    "date": "2018-02-15",
    "amount": "-502.00",
    "text": "Vita Folkebernado Fyllingsdalen",
    "alfareferanse": "14.02 1606 VITA FOLKEBERNADO FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 565,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1575633636,
    "accountNumber": "35302464616",
    "date": "2018-02-14",
    "amount": "-835.16",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "13.02 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1575291171,
    "accountNumber": "35302464616",
    "date": "2018-02-13",
    "amount": "-82.73",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "13.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1574845719,
    "accountNumber": "35302464616",
    "date": "2018-02-13",
    "amount": "-59.62",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "12.02 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1575127589,
    "accountNumber": "35302464616",
    "date": "2018-02-13",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.02 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573314441,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-92.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "10.02 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573673539,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-173.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "11.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573064369,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-130.00",
    "text": "Jacobs All Berg Småstrandgat Bergen",
    "alfareferanse": "09.02 JACOBS ALL BERG SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 566,
      "placeId": "ChIJu3S-Xaj-PEYRiV0QNnQKXao",
      "placeName": "Jacob Aall Brasserie & Bar Bergen",
      "coordinates": "60.3928282,5.326276",
      "website": "http://www.jacobaall.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573260286,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-248.00",
    "text": "Jacobs All Berg Småstrandgat Bergen",
    "alfareferanse": "10.02 JACOBS ALL BERG SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 566,
      "placeId": "ChIJu3S-Xaj-PEYRiV0QNnQKXao",
      "placeName": "Jacob Aall Brasserie & Bar Bergen",
      "coordinates": "60.3928282,5.326276",
      "website": "http://www.jacobaall.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573980097,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-220.00",
    "text": "Harbour Cafe Torget 9 Bergen",
    "alfareferanse": "10.02 HARBOUR CAFE TORGET 9 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 567,
      "placeId": "ChIJX9mF3Kf-PEYRu_0lGooLSt0",
      "placeName": "Harbour Café",
      "coordinates": "60.3949519,5.3261725",
      "website": "http://harbourcafe.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573162868,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-250.00",
    "text": "Harbour Cafe Torget 9 Bergen",
    "alfareferanse": "10.02 HARBOUR CAFE TORGET 9 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 567,
      "placeId": "ChIJX9mF3Kf-PEYRu_0lGooLSt0",
      "placeName": "Harbour Café",
      "coordinates": "60.3949519,5.3261725",
      "website": "http://harbourcafe.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573436705,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-250.00",
    "text": "Tgi Friday 3514 Nedre Ole Bu Bergen",
    "alfareferanse": "09.02 TGI FRIDAY 3514 NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 568,
      "placeId": "ChIJpQJT0qn-PEYRicdiDvFY3AU",
      "placeName": "TGI Friday's",
      "coordinates": "60.3913922,5.3237916",
      "website": "https://fridays.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1573611004,
    "accountNumber": "35302464616",
    "date": "2018-02-12",
    "amount": "-400.00",
    "text": "Christiania Tax Sandviksvn.1 Bergen",
    "alfareferanse": "10.02 CHRISTIANIA TAX SANDVIKSVN.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 569,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1572955589,
    "accountNumber": "35302464616",
    "date": "2018-02-09",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.02 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571947129,
    "accountNumber": "35302464616",
    "date": "2018-02-07",
    "amount": "-240.59",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "06.02 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571969197,
    "accountNumber": "35302464616",
    "date": "2018-02-07",
    "amount": "-231.63",
    "text": "Just Eat",
    "alfareferanse": "*0971 05.02 NOK 231.63 Just Eat Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 571,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1572010250,
    "accountNumber": "35302464616",
    "date": "2018-02-07",
    "amount": "-61.79",
    "text": "Kiwi 878 Birke Nye Sandviks Bergen",
    "alfareferanse": "07.02 KIWI 878 BIRKE  NYE SANDVIKS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 572,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571553181,
    "accountNumber": "35302464616",
    "date": "2018-02-06",
    "amount": "-297.85",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 03.02 NOK 297.85 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571562307,
    "accountNumber": "35302464616",
    "date": "2018-02-06",
    "amount": "-130.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "05.02 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571733908,
    "accountNumber": "35302464616",
    "date": "2018-02-06",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "05.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570712489,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-212.77",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "02.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571288665,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-138.79",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "03.02 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570842487,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-36.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "05.02 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570801836,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-299.90",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "03.02 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571167657,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-275.43",
    "text": "Esso Frekhaug . Frekhaug",
    "alfareferanse": "04.02 ESSO FREKHAUG . FREKHAUG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 574,
      "placeId": "ChIJX9dhzdoCPUYRZGRavUuecLk",
      "placeName": "Esso",
      "coordinates": "60.5148598,5.2278602",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570704406,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-94.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "04.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570729203,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-134.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "04.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1571288546,
    "accountNumber": "35302464616",
    "date": "2018-02-05",
    "amount": "-188.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "04.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570154820,
    "accountNumber": "35302464616",
    "date": "2018-02-02",
    "amount": "-89.90",
    "text": "Apotek 1 Oasen Oasen Senter Fyllingsdalen",
    "alfareferanse": "01.02 APOTEK 1 OASEN  OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 523,
      "placeId": "ChIJp13-g8_7PEYR5MFkfv-WtX8",
      "placeName": "Apotek 1",
      "coordinates": "60.349305,5.2902173",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/oasen-895",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570027958,
    "accountNumber": "35302464616",
    "date": "2018-02-02",
    "amount": "-213.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "01.02 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570069601,
    "accountNumber": "35302464616",
    "date": "2018-02-02",
    "amount": "-270.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "02.02 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1570109539,
    "accountNumber": "35302464616",
    "date": "2018-02-02",
    "amount": "-127.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "02.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1569678103,
    "accountNumber": "35302464616",
    "date": "2018-02-01",
    "amount": "-4650.00",
    "text": "Vipps",
    "alfareferanse": "*0971 30.01 NOK 4650.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sportsutstyr",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1568739574,
    "accountNumber": "35302464616",
    "date": "2018-01-31",
    "amount": "-104.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         52 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 575,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1569043267,
    "accountNumber": "35302464616",
    "date": "2018-01-31",
    "amount": "-125.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "31.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1569208189,
    "accountNumber": "35302464616",
    "date": "2018-01-31",
    "amount": "-88.24",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "31.01 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1568703828,
    "accountNumber": "35302464616",
    "date": "2018-01-31",
    "amount": "-99.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "30.01 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1568255315,
    "accountNumber": "35302464616",
    "date": "2018-01-30",
    "amount": "-148.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "29.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1568387609,
    "accountNumber": "35302464616",
    "date": "2018-01-30",
    "amount": "-104.59",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "29.01 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1568576148,
    "accountNumber": "35302464616",
    "date": "2018-01-30",
    "amount": "-180.36",
    "text": "Express Sandvik Sandviksveie Bergen",
    "alfareferanse": "30.01 EXPRESS SANDVIK SANDVIKSVEIE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 576,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1568408735,
    "accountNumber": "35302464616",
    "date": "2018-01-30",
    "amount": "-951.45",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 26.01 NOK 951.45 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1567735608,
    "accountNumber": "35302464616",
    "date": "2018-01-29",
    "amount": "-284.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "26.01 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1567444878,
    "accountNumber": "35302464616",
    "date": "2018-01-29",
    "amount": "-102.80",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "26.01 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1567700469,
    "accountNumber": "35302464616",
    "date": "2018-01-29",
    "amount": "-954.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1567884175,
    "accountNumber": "35302464616",
    "date": "2018-01-29",
    "amount": "-300.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "27.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1567150585,
    "accountNumber": "35302464616",
    "date": "2018-01-29",
    "amount": "-193.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "27.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1568010280,
    "accountNumber": "35302464616",
    "date": "2018-01-29",
    "amount": "-253.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "29.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566727627,
    "accountNumber": "35302464616",
    "date": "2018-01-26",
    "amount": "-205.15",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "26.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566906429,
    "accountNumber": "35302464616",
    "date": "2018-01-26",
    "amount": "-128.80",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 23.01 NOK 128.80 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566220402,
    "accountNumber": "35302464616",
    "date": "2018-01-25",
    "amount": "-114.00",
    "text": "Coop Obs Sartor Sartor Sente Straume",
    "alfareferanse": "24.01 COOP OBS SARTOR SARTOR SENTE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 578,
      "placeId": "ChIJ07Dmae7kPEYRdtQFqnLjn7Y",
      "placeName": "Obs Bygg",
      "coordinates": "60.3589314,5.1249784",
      "website": "https://coop.no/butikker/obs/sartor-2408/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566362112,
    "accountNumber": "35302464616",
    "date": "2018-01-25",
    "amount": "-249.00",
    "text": "Solbrød Sartor Satror Sente Straume",
    "alfareferanse": "24.01 SOLBRØD SARTOR  SATROR SENTE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 580,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566420678,
    "accountNumber": "35302464616",
    "date": "2018-01-25",
    "amount": "-89.90",
    "text": "Br-Leker F2302 . Straume",
    "alfareferanse": "24.01 BR-LEKER F2302  . STRAUME",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 581,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566528990,
    "accountNumber": "35302464616",
    "date": "2018-01-25",
    "amount": "-240.70",
    "text": "Glitter Bergen Sartor Sente Straume",
    "alfareferanse": "24.01 GLITTER BERGEN  SARTOR SENTE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 582,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566447721,
    "accountNumber": "35302464616",
    "date": "2018-01-25",
    "amount": "-150.00",
    "text": "Vipps",
    "alfareferanse": "*0971 22.01 NOK 150.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Apotek",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566587737,
    "accountNumber": "35302464616",
    "date": "2018-01-25",
    "amount": "-123.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "25.01 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566243018,
    "accountNumber": "35302464616",
    "date": "2018-01-25",
    "amount": "-39.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 23.01 NOK 39.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566090336,
    "accountNumber": "35302464616",
    "date": "2018-01-24",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 22.01 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1566040376,
    "accountNumber": "35302464616",
    "date": "2018-01-24",
    "amount": "-1000.00",
    "text": "Bergen Bildekk Håkonsvernvn Loddefjord",
    "alfareferanse": "24.01 BERGEN BILDEKK  HÅKONSVERNVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bil",
    "imageId": "",
    "place": {
      "id": 583,
      "placeId": "ChIJySzh8gz7PEYRdCWLWkTT1jw",
      "placeName": "Bergen tires AS",
      "coordinates": "60.3525711,5.2314223",
      "website": "http://www.bergenbildekk.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1565063647,
    "accountNumber": "35302464616",
    "date": "2018-01-23",
    "amount": "-200.00",
    "text": "Iz *Oye Inc",
    "alfareferanse": "*0971 21.01 NOK 200.00 IZ *OYE INC Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 584,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1564840573,
    "accountNumber": "35302464616",
    "date": "2018-01-23",
    "amount": "-400.00",
    "text": "Vipps",
    "alfareferanse": "*0971 20.01 NOK 400.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1565152019,
    "accountNumber": "35302464616",
    "date": "2018-01-23",
    "amount": "-224.25",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 20.01 NOK 224.25 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1565152631,
    "accountNumber": "35302464616",
    "date": "2018-01-23",
    "amount": "-162.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 21.01 NOK 162.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1565261428,
    "accountNumber": "35302464616",
    "date": "2018-01-23",
    "amount": "-203.35",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "23.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1565239014,
    "accountNumber": "35302464616",
    "date": "2018-01-23",
    "amount": "-227.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "23.01 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1564893695,
    "accountNumber": "35302464616",
    "date": "2018-01-23",
    "amount": "-226.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "22.01 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1564063878,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-135.00",
    "text": "Shell Nygård Lyderhornsve Laksevåg",
    "alfareferanse": "21.01 SHELL NYGÅRD LYDERHORNSVE LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 499,
      "placeId": "ChIJfXhiQYT7PEYRPGY9dKF5xmg",
      "placeName": "Shell",
      "coordinates": "60.3847311,5.2712933",
      "website": "https://find.shell.com/no/fuel/NO_4140-shell-nygard",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563998618,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-290.00",
    "text": "Shell Nygård Lyderhornsve Laksevåg",
    "alfareferanse": "21.01 SHELL NYGÅRD LYDERHORNSVE LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 499,
      "placeId": "ChIJfXhiQYT7PEYRPGY9dKF5xmg",
      "placeName": "Shell",
      "coordinates": "60.3847311,5.2712933",
      "website": "https://find.shell.com/no/fuel/NO_4140-shell-nygard",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563961202,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-86.00",
    "text": "7 - Eleven 7134 Torget. 17 Bergen",
    "alfareferanse": "21.01 7 - ELEVEN 7134 TORGET. 17 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 512,
      "placeId": "ChIJW7VB36f-PEYRiPmsO1fOA-Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3953766,5.3262766",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563818331,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-1147.80",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "19.01 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563243960,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-399.00",
    "text": "Sats Elixia",
    "alfareferanse": "SATS ELIXIA",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 482,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563529374,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-69.00",
    "text": "Big Bite Oasen F.Bernadotte Fyllingsdalen",
    "alfareferanse": "20.01 BIG BITE OASEN  F.BERNADOTTE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 563,
      "placeId": "ChIJp13-g8_7PEYRsozFVYYiG7w",
      "placeName": "Big Bite",
      "coordinates": "60.3491563,5.2911056",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563201459,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-170.00",
    "text": "Barbarista Barbarista / Bergen",
    "alfareferanse": "21.01 BARBARISTA BARBARISTA / BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 553,
      "placeId": "ChIJT6yqWqf-PEYRilSaC1yEHkQ",
      "placeName": "BarBarista",
      "coordinates": "60.3973072,5.3260528",
      "website": "https://www.facebook.com/BarBarista.no/?ref=aymt_homepage_panel",
      "logoId": 71,
      "merchantName": "BarBarista",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563949461,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-40.00",
    "text": "Barbarista Barbarista / Bergen",
    "alfareferanse": "21.01 BARBARISTA BARBARISTA / BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 553,
      "placeId": "ChIJT6yqWqf-PEYRilSaC1yEHkQ",
      "placeName": "BarBarista",
      "coordinates": "60.3973072,5.3260528",
      "website": "https://www.facebook.com/BarBarista.no/?ref=aymt_homepage_panel",
      "logoId": 71,
      "merchantName": "BarBarista",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563110523,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-375.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "20.01 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563409300,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-574.00",
    "text": "Normal Bergen, Folke Bernad Fyllingsdalen",
    "alfareferanse": "19.01 NORMAL BERGEN,  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 585,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563660767,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-558.90",
    "text": "Clas Ohl 2881 Loddefjordv. Bergen",
    "alfareferanse": "19.01 CLAS OHL 2881 LODDEFJORDV. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 586,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563848404,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-309.00",
    "text": "Little Ea Folke Bernad Fyllingsdalen",
    "alfareferanse": "19.01 1602 LITTLE EA  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 587,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1563918175,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-129.90",
    "text": "Br-Leker F2336 Folke Bernad Fyllingsdalen",
    "alfareferanse": "19.01 BR-LEKER F2336  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 588,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1564100015,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-200.00",
    "text": "Chagall Scene A Vaskerelven Bergen",
    "alfareferanse": "21.01 CHAGALL SCENE A VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 589,
      "placeId": "ChIJwVdeYAL8PEYRbdFXQpTszg0",
      "placeName": "Chagall",
      "coordinates": "60.3915721,5.3205036",
      "website": "https://m.facebook.com/chagallbergen/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1564234650,
    "accountNumber": "35302464616",
    "date": "2018-01-22",
    "amount": "-80.00",
    "text": "Piam As Stølegt 21 Bergen",
    "alfareferanse": "20.01 PIAM AS STØLEGT 21 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 590,
      "placeId": "ChIJFxpLUqf-PEYR7Zu3gtj-Dd4",
      "placeName": "Piam AS",
      "coordinates": "60.4000513,5.3253169",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1562557160,
    "accountNumber": "35302464616",
    "date": "2018-01-19",
    "amount": "-2000.00",
    "text": "Vipps",
    "alfareferanse": "*0971 17.01 NOK 2000.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1562562408,
    "accountNumber": "35302464616",
    "date": "2018-01-19",
    "amount": "-156.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "18.01 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1562314171,
    "accountNumber": "35302464616",
    "date": "2018-01-18",
    "amount": "-206.54",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "18.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1562005142,
    "accountNumber": "35302464616",
    "date": "2018-01-18",
    "amount": "-120.00",
    "text": "Cafe Sze Chuan Øvregt. 17 Bergen",
    "alfareferanse": "18.01 CAFE SZE CHUAN  ØVREGT. 17 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 591,
      "placeId": "ChIJ6W-8Cqf-PEYRQCdA5FdCURw",
      "placeName": "Cafe Sze Chuan",
      "coordinates": "60.3974295,5.3263219",
      "website": "https://www.facebook.com/Cafe-Sze-Chuan-%E5%B7%9D%E7%B2%A4%E6%A5%BC-806915966103897/?fref=ts",
      "logoId": 71,
      "merchantName": "BarBarista",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1561911131,
    "accountNumber": "35302464616",
    "date": "2018-01-17",
    "amount": "-67.60",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "17.01 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560990309,
    "accountNumber": "35302464616",
    "date": "2018-01-16",
    "amount": "-477.58",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "15.01 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1561021001,
    "accountNumber": "35302464616",
    "date": "2018-01-16",
    "amount": "-1799.00",
    "text": "Iz *Bergen Mobil F",
    "alfareferanse": "*0971 13.01 NOK 1799.00 IZ *BERGEN MOBIL F Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 592,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1561306215,
    "accountNumber": "35302464616",
    "date": "2018-01-16",
    "amount": "-300.00",
    "text": "Vipps",
    "alfareferanse": "*0971 14.01 NOK 300.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1561302584,
    "accountNumber": "35302464616",
    "date": "2018-01-16",
    "amount": "-200.00",
    "text": "Vipps",
    "alfareferanse": "*0971 14.01 NOK 200.00 VIPPS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 552,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1561027112,
    "accountNumber": "35302464616",
    "date": "2018-01-16",
    "amount": "-165.60",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 13.01 NOK 165.60 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1561260067,
    "accountNumber": "35302464616",
    "date": "2018-01-16",
    "amount": "-184.00",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 12.01 NOK 184.00 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1561426509,
    "accountNumber": "35302464616",
    "date": "2018-01-16",
    "amount": "-135.00",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "15.01 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560916238,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-450.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "14.01 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560612291,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-265.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "13.01 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1559635142,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-193.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "12.01 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1559926128,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-255.00",
    "text": "Vinmonopolet Myrdalsvn 2 Nyborg",
    "alfareferanse": "12.01 VINMONOPOLET MYRDALSVN 2  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 594,
      "placeId": "ChIJq3eFEPr9PEYRj57wLlGIuk0",
      "placeName": "Vinmonopolet Åsane Horisont",
      "coordinates": "60.4695732,5.323153",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1559982351,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-99.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "14.01 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560185046,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-125.00",
    "text": "Deli De Luca En Engen 10 Bergen",
    "alfareferanse": "13.01 DELI DE LUCA EN ENGEN 10 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 596,
      "placeId": "ChIJpVeEigL8PEYRge9-UXmATTA",
      "placeName": "Deli de Luca Engen",
      "coordinates": "60.3917507,5.3206748",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560604502,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-154.00",
    "text": "0815Pp Peppes Fjøsangervei",
    "alfareferanse": "13.01 0815PP PEPPES FJØSANGERVEI",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 597,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560630501,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-260.00",
    "text": "Torget 7 Servering As Bergen",
    "alfareferanse": "13.01 TORGET 7 SERVERING AS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 598,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560908152,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-402.00",
    "text": "Pizzabakeren Ve Kjøkkelvikve Loddefjord",
    "alfareferanse": "14.01 PIZZABAKEREN VE KJØKKELVIKVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 599,
      "placeId": "ChIJ9wzRNnD7PEYR1723iIlyWZc",
      "placeName": "Pizzabakeren",
      "coordinates": "60.372567,5.2316609",
      "website": "https://www.pizzabakeren.no/shops.php?reg=Hordaland",
      "logoId": 81,
      "merchantName": "Pizzabakeren",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1559634857,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-204.86",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "13.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560580343,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-160.00",
    "text": "Shell Nygård Lyderhornsve Laksevåg",
    "alfareferanse": "14.01 SHELL NYGÅRD LYDERHORNSVE LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 499,
      "placeId": "ChIJfXhiQYT7PEYRPGY9dKF5xmg",
      "placeName": "Shell",
      "coordinates": "60.3847311,5.2712933",
      "website": "https://find.shell.com/no/fuel/NO_4140-shell-nygard",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1560040597,
    "accountNumber": "35302464616",
    "date": "2018-01-15",
    "amount": "-211.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "14.01 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1559134330,
    "accountNumber": "35302464616",
    "date": "2018-01-12",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.01 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1559558860,
    "accountNumber": "35302464616",
    "date": "2018-01-12",
    "amount": "-317.00",
    "text": "Jasmine Restaur Krokatjønnve Fyllingsdalen",
    "alfareferanse": "11.01 JASMINE RESTAUR KROKATJØNNVE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 600,
      "placeId": "ChIJUV_U6Lf7PEYRe4FzZBpjv5g",
      "placeName": "Krystall Restaurant & Take-Away",
      "coordinates": "60.3496856,5.2770907",
      "website": "http://www.krystallrestaurant.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1558818091,
    "accountNumber": "35302464616",
    "date": "2018-01-11",
    "amount": "-317.91",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "10.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1558510222,
    "accountNumber": "35302464616",
    "date": "2018-01-10",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "09.01 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1558280551,
    "accountNumber": "35302464616",
    "date": "2018-01-10",
    "amount": "-256.00",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "09.01 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1558570871,
    "accountNumber": "35302464616",
    "date": "2018-01-10",
    "amount": "-116.45",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "09.01 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1557783355,
    "accountNumber": "35302464616",
    "date": "2018-01-09",
    "amount": "-329.80",
    "text": "Sandviken Apote Stølegt. 15 Bergen",
    "alfareferanse": "09.01 SANDVIKEN APOTE STØLEGT. 15  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 602,
      "placeId": "ChIJeRjZRaf-PEYR1zNrgkSI-2Q",
      "placeName": "Sandviken pharmacy AS",
      "coordinates": "60.399822,5.325585",
      "website": "https://www.dittapotek.no/dittapotek/NO/Hordaland/Bergen/Sandviken%20apotek",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1558190996,
    "accountNumber": "35302464616",
    "date": "2018-01-09",
    "amount": "-110.40",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 05.01 NOK 110.40 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1558173745,
    "accountNumber": "35302464616",
    "date": "2018-01-09",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.01 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1558216975,
    "accountNumber": "35302464616",
    "date": "2018-01-09",
    "amount": "-48.00",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "09.01 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1557284351,
    "accountNumber": "35302464616",
    "date": "2018-01-08",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "07.01 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1557351816,
    "accountNumber": "35302464616",
    "date": "2018-01-08",
    "amount": "-200.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "07.01 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1557339137,
    "accountNumber": "35302464616",
    "date": "2018-01-08",
    "amount": "-230.00",
    "text": "God Servering A Engen 12 Bergen",
    "alfareferanse": "07.01 GOD SERVERING A ENGEN 12 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 536,
      "placeId": "ChIJy85aqyb8PEYRuvuQng3b5Yc",
      "placeName": "Bergen servering AS",
      "coordinates": "60.412205,5.3188574",
      "website": "http://bergenservering.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1557670125,
    "accountNumber": "35302464616",
    "date": "2018-01-08",
    "amount": "-140.00",
    "text": "Dwell Sparebanksga Bergen",
    "alfareferanse": "06.01 DWELL SPAREBANKSGA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 603,
      "placeId": "ChIJzevIKqb-PEYRXXLKWp-wa60",
      "placeName": "Dwell Bergen",
      "coordinates": "60.394505,5.3277644",
      "website": "http://dwellbergen.no/",
      "logoId": 84,
      "merchantName": "Dwell Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1556748142,
    "accountNumber": "35302464616",
    "date": "2018-01-05",
    "amount": "-268.30",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "04.01 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1556808025,
    "accountNumber": "35302464616",
    "date": "2018-01-05",
    "amount": "-78.80",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "05.01 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1556692642,
    "accountNumber": "35302464616",
    "date": "2018-01-05",
    "amount": "-200.53",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "05.01 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1555258489,
    "accountNumber": "35302464616",
    "date": "2018-01-02",
    "amount": "-1290.61",
    "text": "Intrum Justitia",
    "alfareferanse": "Nettgiro til: INTRUM JUSTITIA Betalt: 02.01.18",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Inkasso",
    "imageId": "",
    "place": {
      "id": 604,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1553581262,
    "accountNumber": "35302464616",
    "date": "2018-01-02",
    "amount": "-75.32",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 28.12 NOK 75.32 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1555043523,
    "accountNumber": "35302464616",
    "date": "2017-12-31",
    "amount": "-158.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         79 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 511,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1553564034,
    "accountNumber": "35302464616",
    "date": "2017-12-29",
    "amount": "-70.00",
    "text": "Brukbar Håkonsgt 27 Bergen",
    "alfareferanse": "29.12 BRUKBAR HÅKONSGT 27  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 605,
      "placeId": "ChIJK1ZcFQL8PEYRvbJOPrKxfps",
      "placeName": "BrukBar",
      "coordinates": "60.3902635,5.3201857",
      "website": "http://www.brukbars.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1554740246,
    "accountNumber": "35302464616",
    "date": "2017-12-29",
    "amount": "-138.00",
    "text": "Brukbar Håkonsgt 27 Bergen",
    "alfareferanse": "29.12 BRUKBAR HÅKONSGT 27  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 605,
      "placeId": "ChIJK1ZcFQL8PEYRvbJOPrKxfps",
      "placeName": "BrukBar",
      "coordinates": "60.3902635,5.3201857",
      "website": "http://www.brukbars.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1554684272,
    "accountNumber": "35302464616",
    "date": "2017-12-29",
    "amount": "-206.88",
    "text": "Express Fjøsang Straumevegen Bønes",
    "alfareferanse": "28.12 EXPRESS FJØSANG STRAUMEVEGEN BØNES",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 606,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1503480673,
    "accountNumber": "35302464616",
    "date": "2017-12-28",
    "amount": "-201.25",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 22.12 NOK 201.25 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1502153908,
    "accountNumber": "35302464616",
    "date": "2017-12-28",
    "amount": "-50.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "27.12 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1500329586,
    "accountNumber": "35302464616",
    "date": "2017-12-28",
    "amount": "-150.29",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "28.12 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1494032390,
    "accountNumber": "35302464616",
    "date": "2017-12-28",
    "amount": "-701.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1497577451,
    "accountNumber": "35302464616",
    "date": "2017-12-28",
    "amount": "-730.00",
    "text": "Mathopen Pizzar Skareveien Mathopen",
    "alfareferanse": "27.12 MATHOPEN PIZZAR SKAREVEIEN MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 477,
      "placeId": "ChIJbV6bH-T6PEYRCPvUj7bBEQc",
      "placeName": "Mathopen Pizzeria",
      "coordinates": "60.3336074,5.2130728",
      "website": "http://www.mathopen-pizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1500071819,
    "accountNumber": "35302464616",
    "date": "2017-12-28",
    "amount": "-174.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "28.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1500733605,
    "accountNumber": "35302464616",
    "date": "2017-12-27",
    "amount": "-124.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "27.12 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1503480672,
    "accountNumber": "35302464616",
    "date": "2017-12-27",
    "amount": "-201.25",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 21.12 NOK 201.25 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1501122398,
    "accountNumber": "35302464616",
    "date": "2017-12-27",
    "amount": "-100.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "27.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1498384367,
    "accountNumber": "35302464616",
    "date": "2017-12-27",
    "amount": "-400.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "27.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1500629113,
    "accountNumber": "35302464616",
    "date": "2017-12-27",
    "amount": "-130.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "27.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1500628661,
    "accountNumber": "35302464616",
    "date": "2017-12-27",
    "amount": "-130.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "27.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1503480671,
    "accountNumber": "35302464616",
    "date": "2017-12-22",
    "amount": "-201.25",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 20.12 NOK 201.25 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1497669794,
    "accountNumber": "35302464616",
    "date": "2017-12-22",
    "amount": "-667.30",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "22.12 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1499976861,
    "accountNumber": "35302464616",
    "date": "2017-12-22",
    "amount": "-181.00",
    "text": "Solbrød Oasen ( Folke Bernad Fyllingsdalen",
    "alfareferanse": "22.12 SOLBRØD OASEN ( FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 520,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1498555961,
    "accountNumber": "35302464616",
    "date": "2017-12-22",
    "amount": "-367.00",
    "text": "Cubus 874 Oasen Senter Fyllingsdalen",
    "alfareferanse": "22.12 CUBUS 874 OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 607,
      "placeId": "ChIJoTfziMX7PEYRab_c3wl4tKY",
      "placeName": "Cubus",
      "coordinates": "60.348195,5.2920946",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/874/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1497461993,
    "accountNumber": "35302464616",
    "date": "2017-12-22",
    "amount": "-829.20",
    "text": "Lampehuset Fyll Folke Bernad Fyllingsdalen",
    "alfareferanse": "22.12 LAMPEHUSET FYLL FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 608,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1496904471,
    "accountNumber": "35302464616",
    "date": "2017-12-22",
    "amount": "-305.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "22.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1498857047,
    "accountNumber": "35302464616",
    "date": "2017-12-20",
    "amount": "-308.00",
    "text": "Kappahl 313 Ves Loddefjordvn Loddefjord",
    "alfareferanse": "19.12 KAPPAHL 313 VES LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 609,
      "placeId": "ChIJo0lIzQz7PEYR3xamyWBuNq8",
      "placeName": "KappAhl",
      "coordinates": "60.3625506,5.2340934",
      "website": "http://www.kappahl.com/en-US/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1500555405,
    "accountNumber": "35302464616",
    "date": "2017-12-20",
    "amount": "-135.80",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "19.12 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1503463332,
    "accountNumber": "35302464616",
    "date": "2017-12-20",
    "amount": "-212.75",
    "text": "Paypal *Easypark As",
    "alfareferanse": "*0971 17.12 NOK 212.75 PAYPAL *EASYPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 573,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1498068816,
    "accountNumber": "35302464616",
    "date": "2017-12-19",
    "amount": "-495.70",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "18.12 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1500273827,
    "accountNumber": "35302464616",
    "date": "2017-12-19",
    "amount": "-155.69",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "18.12 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1498432769,
    "accountNumber": "35302464616",
    "date": "2017-12-19",
    "amount": "-397.53",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "18.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 8042311,
    "accountNumber": "35302464616",
    "date": "2017-12-18",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.12 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 7931775,
    "accountNumber": "35302464616",
    "date": "2017-12-18",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.12 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 9196323,
    "accountNumber": "35302464616",
    "date": "2017-12-18",
    "amount": "-260.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "17.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 8314149,
    "accountNumber": "35302464616",
    "date": "2017-12-18",
    "amount": "-200.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "17.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 8374448,
    "accountNumber": "35302464616",
    "date": "2017-12-18",
    "amount": "-436.00",
    "text": "Taxi 1 As Janaflaten 3 Godvik",
    "alfareferanse": "17.12 TAXI 1 AS JANAFLATEN 3 GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 611,
      "placeId": "ChIJ_2LqPUbhPEYRz28-6W5z7f8",
      "placeName": "Taxi 1",
      "coordinates": "60.369358,5.1867955",
      "website": "http://www.taxi1.no/",
      "logoId": 89,
      "merchantName": "Taxi 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 9484836,
    "accountNumber": "35302464616",
    "date": "2017-12-15",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "14.12 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 10520937,
    "accountNumber": "35302464616",
    "date": "2017-12-14",
    "amount": "-32.40",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "14.12 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 10925115,
    "accountNumber": "35302464616",
    "date": "2017-12-13",
    "amount": "-162.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "12.12 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 11372983,
    "accountNumber": "35302464616",
    "date": "2017-12-12",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 08.12 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 11783483,
    "accountNumber": "35302464616",
    "date": "2017-12-12",
    "amount": "-635.60",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "11.12 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 11719320,
    "accountNumber": "35302464616",
    "date": "2017-12-12",
    "amount": "-119.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.12 NOK 119.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 11349783,
    "accountNumber": "35302464616",
    "date": "2017-12-12",
    "amount": "-20.00",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "12.12 NARVESEN 747 OLE BULLSPL. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 12588665,
    "accountNumber": "35302464616",
    "date": "2017-12-11",
    "amount": "-448.56",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "09.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 12884675,
    "accountNumber": "35302464616",
    "date": "2017-12-11",
    "amount": "-42.00",
    "text": "7 - Eleven 7091 Strandgt. 2 Bergen",
    "alfareferanse": "09.12 7 - ELEVEN 7091 STRANDGT. 2  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 612,
      "placeId": "ChIJXe9d36f-PEYRb0irnzf202s",
      "placeName": "7-Eleven",
      "coordinates": "60.3945016,5.3258814",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 12140355,
    "accountNumber": "35302464616",
    "date": "2017-12-11",
    "amount": "-146.00",
    "text": "7 - Eleven 7091 Strandgt. 2 Bergen",
    "alfareferanse": "09.12 7 - ELEVEN 7091 STRANDGT. 2  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 612,
      "placeId": "ChIJXe9d36f-PEYRb0irnzf202s",
      "placeName": "7-Eleven",
      "coordinates": "60.3945016,5.3258814",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 12488884,
    "accountNumber": "35302464616",
    "date": "2017-12-11",
    "amount": "-210.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "09.12 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 12680778,
    "accountNumber": "35302464616",
    "date": "2017-12-11",
    "amount": "-329.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "09.12 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 12459078,
    "accountNumber": "35302464616",
    "date": "2017-12-11",
    "amount": "-41.70",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "10.12 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 13564274,
    "accountNumber": "35302464616",
    "date": "2017-12-08",
    "amount": "-61.00",
    "text": "Kaffelade Matborsen",
    "alfareferanse": "*0971 06.12 NOK 61.00 KAFFELADE MATBORSEN Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 615,
      "placeId": "ChIJMRBxbaj-PEYRuqQiqYF_Lqo",
      "placeName": "Kaffelade Matbørsen",
      "coordinates": "60.3936999,5.3255431",
      "website": "http://kaffelade.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 13804336,
    "accountNumber": "35302464616",
    "date": "2017-12-07",
    "amount": "-72.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "06.12 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 13821700,
    "accountNumber": "35302464616",
    "date": "2017-12-07",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "06.12 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 13798413,
    "accountNumber": "35302464616",
    "date": "2017-12-07",
    "amount": "-108.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "07.12 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 14520392,
    "accountNumber": "35302464616",
    "date": "2017-12-06",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "05.12 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 14457837,
    "accountNumber": "35302464616",
    "date": "2017-12-06",
    "amount": "-115.40",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "06.12 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 14429374,
    "accountNumber": "35302464616",
    "date": "2017-12-06",
    "amount": "-84.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "05.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 15140417,
    "accountNumber": "35302464616",
    "date": "2017-12-05",
    "amount": "-39.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 01.12 NOK 39.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 15315629,
    "accountNumber": "35302464616",
    "date": "2017-12-04",
    "amount": "-342.46",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "01.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 15890146,
    "accountNumber": "35302464616",
    "date": "2017-12-04",
    "amount": "-74.58",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "04.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 15549578,
    "accountNumber": "35302464616",
    "date": "2017-12-04",
    "amount": "-254.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "01.12 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 16086677,
    "accountNumber": "35302464616",
    "date": "2017-12-04",
    "amount": "-35.00",
    "text": "Mcdonald's Lagunen Lagunevn. 1 Rådal",
    "alfareferanse": "01.12 MCD 059 LAGUNEN LAGUNEVN. 1  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 618,
      "placeId": "ChIJIQ84ueb5PEYRlrLJ0dXH2IE",
      "placeName": "Apotek 1",
      "coordinates": "60.296531,5.330386",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/lagunen-13",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 15877471,
    "accountNumber": "35302464616",
    "date": "2017-12-04",
    "amount": "-79.00",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "02.12 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 16357366,
    "accountNumber": "35302464616",
    "date": "2017-12-01",
    "amount": "-599.00",
    "text": "Br-Leker F2336 Folke Bernad Fyllingsdalen",
    "alfareferanse": "30.11 BR-LEKER F2336  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 588,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 16518974,
    "accountNumber": "35302464616",
    "date": "2017-12-01",
    "amount": "-105.38",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "30.11 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 16659079,
    "accountNumber": "35302464616",
    "date": "2017-12-01",
    "amount": "-180.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "30.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 17309721,
    "accountNumber": "35302464616",
    "date": "2017-11-30",
    "amount": "-114.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         57 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 575,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 17348196,
    "accountNumber": "35302908859",
    "date": "2017-11-30",
    "amount": "-3.00",
    "text": "Nettgiro M/Meld.Forfall I Dag",
    "alfareferanse": "NETTGIRO M/MELD.FORFALL I DAG       1 TRANS(ER) TYPE 203",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 635,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 18093188,
    "accountNumber": "35302464616",
    "date": "2017-11-29",
    "amount": "-528.96",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "28.11 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 17895600,
    "accountNumber": "35302464616",
    "date": "2017-11-29",
    "amount": "-258.12",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "28.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 18558558,
    "accountNumber": "35302464616",
    "date": "2017-11-28",
    "amount": "-710.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 18540377,
    "accountNumber": "35302464616",
    "date": "2017-11-28",
    "amount": "-4802.00",
    "text": "Urban F84 Sund Torgalmennin Bergen",
    "alfareferanse": "28.11 URBAN F84 SUND  TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 636,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 18238217,
    "accountNumber": "35302464616",
    "date": "2017-11-28",
    "amount": "-85.27",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "28.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 19483299,
    "accountNumber": "35302464616",
    "date": "2017-11-27",
    "amount": "-11.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "24.11 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 19213645,
    "accountNumber": "35302464616",
    "date": "2017-11-27",
    "amount": "-45.00",
    "text": "Coop Post I Butikk Bergen",
    "alfareferanse": "25.11 COOP POST I BUTIKK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 637,
      "placeId": "ChIJz0ss26j-PEYRLUffHD0BHiM",
      "placeName": "Marken Post i Butikk",
      "coordinates": "60.3921045,5.3301831",
      "website": "https://www.posten.no/",
      "logoId": 100,
      "merchantName": "Posten",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 18937822,
    "accountNumber": "35302464616",
    "date": "2017-11-27",
    "amount": "-2263.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "25.11 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 19336484,
    "accountNumber": "35302464616",
    "date": "2017-11-27",
    "amount": "-212.80",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "25.11 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 19130162,
    "accountNumber": "35302464616",
    "date": "2017-11-27",
    "amount": "-204.89",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "25.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 19536938,
    "accountNumber": "35302464616",
    "date": "2017-11-27",
    "amount": "-149.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "24.11 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 19969326,
    "accountNumber": "35302464616",
    "date": "2017-11-24",
    "amount": "-679.92",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "23.11 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 20313343,
    "accountNumber": "35302464616",
    "date": "2017-11-24",
    "amount": "-89.00",
    "text": "Hygienisk Rens Og Vask Vestkanten",
    "alfareferanse": "23.11 HYGIENISK RENS  OG VASK VESTKANTEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Renseri",
    "imageId": "",
    "place": {
      "id": 638,
      "placeId": "ChIJo0lIzQz7PEYRsPMdrTYRcVU",
      "placeName": "Hygienic Wash & Cleaning",
      "coordinates": "60.3632227,5.2336255",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 20807286,
    "accountNumber": "35302464616",
    "date": "2017-11-23",
    "amount": "-180.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "22.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 20503442,
    "accountNumber": "35302464616",
    "date": "2017-11-23",
    "amount": "-200.90",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "22.11 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 20519583,
    "accountNumber": "35302464616",
    "date": "2017-11-23",
    "amount": "-39.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 21.11 NOK 39.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 21230618,
    "accountNumber": "35302464616",
    "date": "2017-11-22",
    "amount": "-200.00",
    "text": "Mathopen Pizzar Skareveien Mathopen",
    "alfareferanse": "21.11 MATHOPEN PIZZAR SKAREVEIEN MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 477,
      "placeId": "ChIJbV6bH-T6PEYRCPvUj7bBEQc",
      "placeName": "Mathopen Pizzeria",
      "coordinates": "60.3336074,5.2130728",
      "website": "http://www.mathopen-pizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 21136743,
    "accountNumber": "35302464616",
    "date": "2017-11-22",
    "amount": "-198.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "21.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 21558302,
    "accountNumber": "35302464616",
    "date": "2017-11-21",
    "amount": "-91.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "21.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 21883585,
    "accountNumber": "35302464616",
    "date": "2017-11-21",
    "amount": "-137.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "20.11 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 21455913,
    "accountNumber": "35302464616",
    "date": "2017-11-21",
    "amount": "-267.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "20.11 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 21917886,
    "accountNumber": "35302464616",
    "date": "2017-11-20",
    "amount": "-106.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "19.11 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 22990581,
    "accountNumber": "35302464616",
    "date": "2017-11-20",
    "amount": "-60.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "19.11 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 22436816,
    "accountNumber": "35302464616",
    "date": "2017-11-20",
    "amount": "-304.69",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "19.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 22850876,
    "accountNumber": "35302464616",
    "date": "2017-11-20",
    "amount": "-39.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "18.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 21941858,
    "accountNumber": "35302464616",
    "date": "2017-11-20",
    "amount": "-162.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "17.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 22611625,
    "accountNumber": "35302464616",
    "date": "2017-11-20",
    "amount": "-168.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "18.11 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 22164154,
    "accountNumber": "35302464616",
    "date": "2017-11-20",
    "amount": "-19.00",
    "text": "7 - Eleven 7134 Torget. 17 Bergen",
    "alfareferanse": "18.11 7 - ELEVEN 7134 TORGET. 17 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 512,
      "placeId": "ChIJW7VB36f-PEYRiPmsO1fOA-Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3953766,5.3262766",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 23559724,
    "accountNumber": "35302464616",
    "date": "2017-11-17",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "16.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 23421289,
    "accountNumber": "35302464616",
    "date": "2017-11-17",
    "amount": "-298.65",
    "text": "Nille 298 F.Bernad.V52 Fyllingsdalen",
    "alfareferanse": "17.11 NILLE 298 F.BERNAD.V52 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 639,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 23651607,
    "accountNumber": "35302464616",
    "date": "2017-11-17",
    "amount": "-70.00",
    "text": "Tgr Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "17.11 TGR OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 642,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 23420279,
    "accountNumber": "35302464616",
    "date": "2017-11-17",
    "amount": "-293.18",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "17.11 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 23368735,
    "accountNumber": "35302464616",
    "date": "2017-11-17",
    "amount": "-92.98",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "17.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 24246784,
    "accountNumber": "35302464616",
    "date": "2017-11-16",
    "amount": "-180.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "15.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 24055254,
    "accountNumber": "35302464616",
    "date": "2017-11-16",
    "amount": "-80.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "15.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 23950881,
    "accountNumber": "35302464616",
    "date": "2017-11-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.11 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 24143872,
    "accountNumber": "35302464616",
    "date": "2017-11-16",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.11 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 24537878,
    "accountNumber": "35302464616",
    "date": "2017-11-15",
    "amount": "-112.45",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "15.11 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 25177715,
    "accountNumber": "35302464616",
    "date": "2017-11-14",
    "amount": "-369.65",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "13.11 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 25705131,
    "accountNumber": "35302464616",
    "date": "2017-11-14",
    "amount": "-99.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.11 NOK 99.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 25132002,
    "accountNumber": "35302464616",
    "date": "2017-11-14",
    "amount": "-168.30",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "13.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 25156680,
    "accountNumber": "35302464616",
    "date": "2017-11-14",
    "amount": "-11.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "13.11 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 25190422,
    "accountNumber": "35302464616",
    "date": "2017-11-14",
    "amount": "-79.50",
    "text": "Brio Barn Og Le Postboks 57 Bergen",
    "alfareferanse": "13.11 BRIO BARN OG LE POSTBOKS 57  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 644,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 25778526,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-63.80",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "11.11 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26016965,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-200.01",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "11.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 25805031,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-78.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "13.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26626500,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-31.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "10.11 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26014065,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-303.61",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "10.11 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26256999,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-156.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "10.11 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26208419,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-71.00",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "11.11 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26024422,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-25.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 09.11 NOK 25.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26649418,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-69.50",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "12.11 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26747974,
    "accountNumber": "35302464616",
    "date": "2017-11-13",
    "amount": "-354.99",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "10.11 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26992694,
    "accountNumber": "35302464616",
    "date": "2017-11-10",
    "amount": "-25.70",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "09.11 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26866053,
    "accountNumber": "35302464616",
    "date": "2017-11-10",
    "amount": "-107.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "10.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 26868576,
    "accountNumber": "35302464616",
    "date": "2017-11-10",
    "amount": "-11.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "10.11 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 27526249,
    "accountNumber": "35302464616",
    "date": "2017-11-09",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.11 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 28462707,
    "accountNumber": "35302464616",
    "date": "2017-11-07",
    "amount": "-105.80",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "07.11 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 28256223,
    "accountNumber": "35302464616",
    "date": "2017-11-07",
    "amount": "-740.76",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "06.11 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 28290455,
    "accountNumber": "35302464616",
    "date": "2017-11-07",
    "amount": "-243.54",
    "text": "Yx Godvik 0213 Godvik",
    "alfareferanse": "07.11 YX GODVIK 0213   GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 468,
      "placeId": "ChIJ8Sb2U0P7PEYRasC-Yn2YkSg",
      "placeName": "YX Godvik",
      "coordinates": "60.3757182,5.2046342",
      "website": "https://yx.no/_stasjon?id=6f3dbb3e-b77c-4cac-8466-04ed9c58578e",
      "logoId": 43,
      "merchantName": "YX",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 28451679,
    "accountNumber": "35302464616",
    "date": "2017-11-07",
    "amount": "-19.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "07.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 28400694,
    "accountNumber": "35302464616",
    "date": "2017-11-07",
    "amount": "-178.00",
    "text": "Akvariet I Berg Nordnesbakke Bergen",
    "alfareferanse": "06.11 AKVARIET I BERG NORDNESBAKKE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 647,
      "placeId": "ChIJ6TU-lBD8PEYR63yf2sh2Ekc",
      "placeName": "Akvariet i Bergen - Det Nasjonale Akvariet",
      "coordinates": "60.3996913,5.303371",
      "website": "http://www.akvariet.no/?utm_source=googlemybusiness&utm_campaign=Google%20Min%20Bedrift&utm_medium=organic",
      "logoId": 102,
      "merchantName": "Akvariet i Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 29720918,
    "accountNumber": "35302464616",
    "date": "2017-11-03",
    "amount": "-139.00",
    "text": "Netflix",
    "alfareferanse": "*0971 01.11 NOK 139.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 29750227,
    "accountNumber": "35302464616",
    "date": "2017-11-03",
    "amount": "-43.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "02.11 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 30409626,
    "accountNumber": "35302464616",
    "date": "2017-11-02",
    "amount": "-85.30",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "01.11 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 30334707,
    "accountNumber": "35302464616",
    "date": "2017-11-02",
    "amount": "-141.90",
    "text": "Sandviken Apote Stølegt. 15 Bergen",
    "alfareferanse": "01.11 SANDVIKEN APOTE STØLEGT. 15  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 602,
      "placeId": "ChIJeRjZRaf-PEYR1zNrgkSI-2Q",
      "placeName": "Sandviken pharmacy AS",
      "coordinates": "60.399822,5.325585",
      "website": "https://www.dittapotek.no/dittapotek/NO/Hordaland/Bergen/Sandviken%20apotek",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 30534057,
    "accountNumber": "35302464616",
    "date": "2017-11-01",
    "amount": "-149.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 30.10 NOK 149.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 31314883,
    "accountNumber": "35302464616",
    "date": "2017-10-31",
    "amount": "-1442.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 31167499,
    "accountNumber": "35302464616",
    "date": "2017-10-31",
    "amount": "-114.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         57 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 575,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 31590983,
    "accountNumber": "35302908859",
    "date": "2017-10-31",
    "amount": "-3.00",
    "text": "Nettgiro M/Meld.Forfall I Dag",
    "alfareferanse": "NETTGIRO M/MELD.FORFALL I DAG       1 TRANS(ER) TYPE 203",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 635,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 32551254,
    "accountNumber": "35302464616",
    "date": "2017-10-30",
    "amount": "-80.79",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "28.10 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 32322548,
    "accountNumber": "35302464616",
    "date": "2017-10-30",
    "amount": "-139.00",
    "text": "271 Boots Apotek Spelhaugen",
    "alfareferanse": "30.10 271 BOOTS APOTEK SPELHAUGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 653,
      "placeId": "ChIJH3-H5rf7PEYRgCTeF2gxbJI",
      "placeName": "Boots apotek Spelhaugen",
      "coordinates": "60.3499212,5.276873",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 32497807,
    "accountNumber": "35302464616",
    "date": "2017-10-30",
    "amount": "-180.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "27.10 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 32456265,
    "accountNumber": "35302464616",
    "date": "2017-10-30",
    "amount": "-159.62",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "30.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 32631283,
    "accountNumber": "35302464616",
    "date": "2017-10-30",
    "amount": "-54.30",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "28.10 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33133968,
    "accountNumber": "35302464616",
    "date": "2017-10-27",
    "amount": "-210.80",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "26.10 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33081363,
    "accountNumber": "35302464616",
    "date": "2017-10-27",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "26.10 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33240741,
    "accountNumber": "35302464616",
    "date": "2017-10-27",
    "amount": "-49.00",
    "text": "7 - Eleven 7091 Strandgt. 2 Bergen",
    "alfareferanse": "26.10 7 - ELEVEN 7091 STRANDGT. 2  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 612,
      "placeId": "ChIJXe9d36f-PEYRb0irnzf202s",
      "placeName": "7-Eleven",
      "coordinates": "60.3945016,5.3258814",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33518202,
    "accountNumber": "35302464616",
    "date": "2017-10-26",
    "amount": "-488.23",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "25.10 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33711506,
    "accountNumber": "35302464616",
    "date": "2017-10-26",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "25.10 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33681023,
    "accountNumber": "35302464616",
    "date": "2017-10-26",
    "amount": "-210.79",
    "text": "Kiwi 855 Bjørge Bjørndalsstø Loddefjord",
    "alfareferanse": "25.10 KIWI 855 BJØRGE BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 466,
      "placeId": "ChIJZxfZC6j7PEYRflCturonJ8Y",
      "placeName": "KIWI Bjørgeveien",
      "coordinates": "60.3508946,5.2444852",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bjorgeveien/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33728471,
    "accountNumber": "35302464616",
    "date": "2017-10-26",
    "amount": "-56.00",
    "text": "Deli De Luca Torget 1 Bergen",
    "alfareferanse": "26.10 DELI DE LUCA TORGET 1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1361,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34066851,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-849.95",
    "text": "Vero Moda .",
    "alfareferanse": "24.10 VERO MODA 176244 .",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1273,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34234784,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-138.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "25.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34089176,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-142.50",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "25.10 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34173851,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-322.40",
    "text": "Accessorize Gal Torgalmennin Bergen",
    "alfareferanse": "25.10 ACCESSORIZE GAL TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 654,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34274933,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-73.00",
    "text": "Snoparen Kiosk Vestkanten S Bergen",
    "alfareferanse": "24.10 SNOPAREN KIOSK  VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 667,
      "placeId": "ChIJXQ2K0gz7PEYRqzuwkdW41_0",
      "placeName": "Snoparen Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://www.vestkanten.no/butikker-og-virksomheter/snoparen/",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34081520,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-204.80",
    "text": "Brio Vestkanten Vestkanten S Loddefjord",
    "alfareferanse": "24.10 BRIO VESTKANTEN VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 661,
      "placeId": "ChIJo0lIzQz7PEYRfhUe0vO1UU4",
      "placeName": "Kozmo Vestkanten Loddefjord",
      "coordinates": "60.3630847,5.2328662",
      "website": "http://kozmos.no/din-kozmos-butikk/kontakt-kozmos-vestkanten-loddefjord/",
      "logoId": 103,
      "merchantName": "Kozmo",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 33884527,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-119.00",
    "text": "227Jernia Vestk Loddefjordve Loddefjord",
    "alfareferanse": "24.10 227JERNIA VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 662,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34130182,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-408.00",
    "text": "227Jernia Vestk Loddefjordve Loddefjord",
    "alfareferanse": "24.10 227JERNIA VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 662,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34254197,
    "accountNumber": "35302464616",
    "date": "2017-10-25",
    "amount": "-48.00",
    "text": "Narvesen 773 Vestkanten Loddefjord",
    "alfareferanse": "24.10 NARVESEN 773 VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 665,
      "placeId": "ChIJo0lIzQz7PEYRqzT5uxwr47s",
      "placeName": "Narvesen",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34821239,
    "accountNumber": "35302464616",
    "date": "2017-10-24",
    "amount": "-237.66",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "23.10 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34498877,
    "accountNumber": "35302464616",
    "date": "2017-10-24",
    "amount": "-29.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 21.10 NOK 29.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 34931195,
    "accountNumber": "35302464616",
    "date": "2017-10-23",
    "amount": "-12.90",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "22.10 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 35096311,
    "accountNumber": "35302464616",
    "date": "2017-10-23",
    "amount": "-60.60",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "20.10 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 35062740,
    "accountNumber": "35302464616",
    "date": "2017-10-23",
    "amount": "-154.30",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "21.10 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 35524296,
    "accountNumber": "35302464616",
    "date": "2017-10-23",
    "amount": "-235.00",
    "text": "China Palace As Torgallmenni Bergen",
    "alfareferanse": "20.10 CHINA PALACE AS TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 464,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 35423780,
    "accountNumber": "35302464616",
    "date": "2017-10-23",
    "amount": "-180.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "20.10 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 35842426,
    "accountNumber": "35302464616",
    "date": "2017-10-23",
    "amount": "-249.00",
    "text": "Bikbok J33 Oasen Fyllingsdalen",
    "alfareferanse": "20.10 BIKBOK J33 OASEN FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 668,
      "placeId": "ChIJp13-g8_7PEYR8r_D_kMJzHo",
      "placeName": "BIK BOK",
      "coordinates": "60.34909,5.2903814",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/j33/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36535969,
    "accountNumber": "35302464616",
    "date": "2017-10-20",
    "amount": "-203.97",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "20.10 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36547330,
    "accountNumber": "35302464616",
    "date": "2017-10-20",
    "amount": "-315.58",
    "text": "Nille 5 Vestk Loddefjordve Bergen",
    "alfareferanse": "19.10 NILLE 5 VESTK LODDEFJORDVE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 670,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36175084,
    "accountNumber": "35302464616",
    "date": "2017-10-20",
    "amount": "-172.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "19.10 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36684190,
    "accountNumber": "35302464616",
    "date": "2017-10-20",
    "amount": "-4414.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "19.10 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36444800,
    "accountNumber": "35302464616",
    "date": "2017-10-20",
    "amount": "-238.20",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "19.10 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36194565,
    "accountNumber": "35302464616",
    "date": "2017-10-20",
    "amount": "-167.30",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "19.10 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36379384,
    "accountNumber": "35302464616",
    "date": "2017-10-20",
    "amount": "-122.00",
    "text": "Deli De Luca Torget 1 Bergen",
    "alfareferanse": "20.10 DELI DE LUCA TORGET 1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1361,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 36781444,
    "accountNumber": "35302464616",
    "date": "2017-10-19",
    "amount": "-7.37",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "18.10 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38225233,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-100.00",
    "text": "Zoo-1 Drotnings Drotningsvik Godvik",
    "alfareferanse": "16.10 ZOO-1 DROTNINGS DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 671,
      "placeId": "ChIJcfVohEz7PEYRbSWgH2UhDjU",
      "placeName": "Zoo-1 Drotningsvik",
      "coordinates": "60.3715999,5.1803441",
      "website": "http://www.zoo1.no/butikker/vestlandet/zoo-1-drotningsvik",
      "logoId": 106,
      "merchantName": "Zoo-1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38181904,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-130.00",
    "text": "Arken Zoo Arken Senter Ulset",
    "alfareferanse": "16.10 ARKEN ZOO ARKEN SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 672,
      "placeId": "ChIJAYxFxfr9PEYR5zUdhYyHLaI",
      "placeName": "Buddy Arken Zoo",
      "coordinates": "60.4654634,5.3245503",
      "website": "https://buddy.no/butikker/hordaland/buddy-arken/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38157604,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-134.00",
    "text": "Lazio Pizza C/O Ibrahim Godvik",
    "alfareferanse": "16.10 LAZIO PIZZA C/O IBRAHIM  GODVIK",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 547,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 37943033,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.10 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38216354,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.10 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 37963684,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-98.59",
    "text": "Rema Drotningsv Drotningsvik Godvik",
    "alfareferanse": "16.10 REMA DROTNINGSV DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 505,
      "placeId": "ChIJo3cCmkz7PEYRS5O0T1uCot0",
      "placeName": "REMA 1000",
      "coordinates": "60.3719928,5.180246",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 37661226,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-163.00",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "16.10 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 37847747,
    "accountNumber": "35302464616",
    "date": "2017-10-17",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 13.10 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38987539,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-141.00",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "16.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39206545,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-172.80",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "14.10 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38436129,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-518.37",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "13.10 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39234473,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-158.00",
    "text": "Solbrød Oasen ( Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.10 SOLBRØD OASEN ( FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 520,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39435284,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-81.00",
    "text": "Solbrød Oasen ( Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.10 SOLBRØD OASEN ( FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 520,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38895511,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-115.34",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "16.10 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38570596,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-72.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "14.10 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 38747040,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-257.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "14.10 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39218618,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-56.40",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "14.10 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39445332,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-194.80",
    "text": "Hygienisk Rens Og Vask Vestkanten",
    "alfareferanse": "13.10 HYGIENISK RENS  OG VASK VESTKANTEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Renseri",
    "imageId": "",
    "place": {
      "id": 638,
      "placeId": "ChIJo0lIzQz7PEYRsPMdrTYRcVU",
      "placeName": "Hygienic Wash & Cleaning",
      "coordinates": "60.3632227,5.2336255",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39281552,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-397.90",
    "text": "Clas Ohl 2881 Loddefjordv. Bergen",
    "alfareferanse": "14.10 CLAS OHL 2881 LODDEFJORDV. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 586,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39339461,
    "accountNumber": "35302464616",
    "date": "2017-10-16",
    "amount": "-89.70",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "14.10 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 39895025,
    "accountNumber": "35302464616",
    "date": "2017-10-13",
    "amount": "-60.80",
    "text": "Coop Prix Olsvi Olsvikskjene Olsvik",
    "alfareferanse": "13.10 COOP PRIX OLSVI OLSVIKSKJENE OLSVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 675,
      "placeId": "ChIJCyUcRWD7PEYRPdqSB9LibeE",
      "placeName": "Coop Prix Olsvik",
      "coordinates": "60.385058,5.21795",
      "website": "https://coop.no/butikker/prix/olsvik-4666/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40194586,
    "accountNumber": "35302464616",
    "date": "2017-10-12",
    "amount": "-441.80",
    "text": "91 Kid Interiør Folke Bernad Fyllingsdalen",
    "alfareferanse": "11.10 91 KID INTERIØR FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 676,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40544318,
    "accountNumber": "35302464616",
    "date": "2017-10-12",
    "amount": "-178.90",
    "text": "H&M No0854 Oasen Kjøpes Fyllingsdalen",
    "alfareferanse": "11.10 H&M NO0854 OASEN KJØPES FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 564,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40026345,
    "accountNumber": "35302464616",
    "date": "2017-10-12",
    "amount": "-46.80",
    "text": "Jula Bergen Oas Folke Barnad Fyllingsdalen",
    "alfareferanse": "11.10 JULA BERGEN OAS FOLKE BARNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 516,
      "placeId": "ChIJp13-g8_7PEYRFzYXBSYJXB8",
      "placeName": "Jula",
      "coordinates": "60.3485852,5.2915106",
      "website": "http://www.jula.no/vare-varehus/bergen-fyllingsdalen/",
      "logoId": 61,
      "merchantName": "Jula",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40356984,
    "accountNumber": "35302464616",
    "date": "2017-10-12",
    "amount": "-88.80",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "11.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40558752,
    "accountNumber": "35302464616",
    "date": "2017-10-12",
    "amount": "-99.00",
    "text": "Viaplay Ab",
    "alfareferanse": "*0971 10.10 NOK 99.00 Viaplay AB Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Strømmetjenester",
    "imageId": "",
    "place": {
      "id": 492,
      "placeId": "ChIJ5SqRge53X0YRm-MVHACVJYw",
      "placeName": "Viaplay AB",
      "coordinates": "59.3099519,18.0591427",
      "website": "https://viaplay.se/",
      "logoId": 53,
      "merchantName": "Viaplay",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40084844,
    "accountNumber": "35302464616",
    "date": "2017-10-12",
    "amount": "-238.85",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "11.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40401284,
    "accountNumber": "35302464616",
    "date": "2017-10-12",
    "amount": "-59.90",
    "text": "Coop Prix Øvreg Øvregaten 37 Bergen",
    "alfareferanse": "12.10 COOP PRIX ØVREG ØVREGATEN 37 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1404,
      "placeId": "ChIJv63NY6f-PEYRrcyEGTg-ilY",
      "placeName": "Coop Extra",
      "coordinates": "60.398484,5.32445",
      "website": "https://coop.no/butikker/extra/ovregaten-4947/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40900464,
    "accountNumber": "35302464616",
    "date": "2017-10-11",
    "amount": "-198.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "10.10 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 40694776,
    "accountNumber": "35302464616",
    "date": "2017-10-11",
    "amount": "-172.80",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "10.10 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 41300277,
    "accountNumber": "35302464616",
    "date": "2017-10-10",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.10 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 41773333,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-131.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "07.10 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 41676995,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-151.00",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "08.10 NARVESEN 747 OLE BULLSPL. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42155689,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-140.13",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "07.10 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42247373,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-101.24",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "09.10 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42216802,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-174.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "08.10 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42138163,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-130.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "08.10 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42228462,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-300.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "08.10 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42290258,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-100.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "08.10 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 41723451,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-90.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "08.10 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 41644545,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-254.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "08.10 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 41853563,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-165.60",
    "text": "Extra Damsgård Damgårdsv205 Laksevåg",
    "alfareferanse": "07.10 EXTRA DAMSGÅRD  DAMGÅRDSV205 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 677,
      "placeId": "ChIJZeZcVAj8PEYRapU3njGo5aI",
      "placeName": "Extra Damsgård",
      "coordinates": "60.38652,5.299735",
      "website": "https://coop.no/butikker/extra/damsgard-4582/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42005327,
    "accountNumber": "35302464616",
    "date": "2017-10-09",
    "amount": "-216.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "08.10 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42463012,
    "accountNumber": "35302464616",
    "date": "2017-10-06",
    "amount": "-144.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "05.10 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42616693,
    "accountNumber": "35302464616",
    "date": "2017-10-06",
    "amount": "-116.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "05.10 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42985290,
    "accountNumber": "35302464616",
    "date": "2017-10-05",
    "amount": "-120.70",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "04.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 42982885,
    "accountNumber": "35302464616",
    "date": "2017-10-05",
    "amount": "-130.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "05.10 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 43446002,
    "accountNumber": "35302464616",
    "date": "2017-10-04",
    "amount": "-204.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "03.10 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 43624142,
    "accountNumber": "35302464616",
    "date": "2017-10-04",
    "amount": "-139.00",
    "text": "Netflix",
    "alfareferanse": "*0971 02.10 NOK 139.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 43311350,
    "accountNumber": "35302464616",
    "date": "2017-10-04",
    "amount": "-63.46",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "03.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 43536683,
    "accountNumber": "35302464616",
    "date": "2017-10-04",
    "amount": "-400.00",
    "text": "Klubb Kok Christian Mi Bergen",
    "alfareferanse": "04.10 KLUBB KOK CHRISTIAN MI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 514,
      "placeId": "ChIJl5eioAL8PEYR0fIhVxOAOj0",
      "placeName": "club Kok",
      "coordinates": "60.3933996,5.3230142",
      "website": "http://klubbkok.no/",
      "logoId": 60,
      "merchantName": "Klubb Kok",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 43517514,
    "accountNumber": "35302464616",
    "date": "2017-10-04",
    "amount": "-165.00",
    "text": "Tin Tin Loi Da Bjørndalsstø Loddefjord",
    "alfareferanse": "04.10 TIN TIN LOI DA  BJØRNDALSSTØ LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 478,
      "placeId": "ChIJZxfZC6j7PEYRe0vxGBotL08",
      "placeName": "Tin Tin Loi Da",
      "coordinates": "60.3509164,5.2442406",
      "website": "http://www.tintinloi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 43905896,
    "accountNumber": "35302464616",
    "date": "2017-10-03",
    "amount": "-22.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "02.10 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 46072506,
    "accountNumber": "35302464616",
    "date": "2017-10-02",
    "amount": "-79.90",
    "text": "Brio Vestkanten Vestkanten S Loddefjord",
    "alfareferanse": "02.10 BRIO VESTKANTEN VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 661,
      "placeId": "ChIJo0lIzQz7PEYRfhUe0vO1UU4",
      "placeName": "Kozmo Vestkanten Loddefjord",
      "coordinates": "60.3630847,5.2328662",
      "website": "http://kozmos.no/din-kozmos-butikk/kontakt-kozmos-vestkanten-loddefjord/",
      "logoId": 103,
      "merchantName": "Kozmo",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 45005997,
    "accountNumber": "35302464616",
    "date": "2017-10-02",
    "amount": "-264.80",
    "text": "Brio Vestkanten Vestkanten S Loddefjord",
    "alfareferanse": "02.10 BRIO VESTKANTEN VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 661,
      "placeId": "ChIJo0lIzQz7PEYRfhUe0vO1UU4",
      "placeName": "Kozmo Vestkanten Loddefjord",
      "coordinates": "60.3630847,5.2328662",
      "website": "http://kozmos.no/din-kozmos-butikk/kontakt-kozmos-vestkanten-loddefjord/",
      "logoId": 103,
      "merchantName": "Kozmo",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 45395378,
    "accountNumber": "35302464616",
    "date": "2017-10-02",
    "amount": "-49.10",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "02.10 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 45932773,
    "accountNumber": "35302464616",
    "date": "2017-10-02",
    "amount": "-302.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "30.09 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 45852732,
    "accountNumber": "35302464616",
    "date": "2017-10-02",
    "amount": "-199.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "01.10 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 45165992,
    "accountNumber": "35302464616",
    "date": "2017-10-02",
    "amount": "-286.25",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "01.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 44770282,
    "accountNumber": "35302464616",
    "date": "2017-09-30",
    "amount": "-96.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         48 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 44185537,
    "accountNumber": "35302464616",
    "date": "2017-09-29",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 44309326,
    "accountNumber": "35302464616",
    "date": "2017-09-29",
    "amount": "-126.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "28.09 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 44543490,
    "accountNumber": "35302464616",
    "date": "2017-09-29",
    "amount": "-33.89",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "28.09 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 46593283,
    "accountNumber": "35302464616",
    "date": "2017-09-28",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "27.09 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 47229197,
    "accountNumber": "35302464616",
    "date": "2017-09-26",
    "amount": "-180.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "26.09 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 47255441,
    "accountNumber": "35302464616",
    "date": "2017-09-26",
    "amount": "-239.91",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "25.09 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 47180691,
    "accountNumber": "35302908859",
    "date": "2017-09-26",
    "amount": "-275.00",
    "text": "Årspris Visa Classic",
    "alfareferanse": "Årspris Visa Classic",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 679,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 48555688,
    "accountNumber": "35302464616",
    "date": "2017-09-25",
    "amount": "-324.00",
    "text": "Fersk Kosmetikk Småstrandgt Bergen",
    "alfareferanse": "23.09 FERSK KOSMETIKK SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 680,
      "placeId": "ChIJu3S-Xaj-PEYRdY2KXPeajc8",
      "placeName": "Xhibition Shopping Center",
      "coordinates": "60.392964,5.3260681",
      "website": "https://www.xhibition.no/",
      "logoId": 109,
      "merchantName": "Xhibition Shopping Center",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 47632952,
    "accountNumber": "35302464616",
    "date": "2017-09-25",
    "amount": "-28.00",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "25.09 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 47974094,
    "accountNumber": "35302464616",
    "date": "2017-09-25",
    "amount": "-300.92",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "23.09 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 48401928,
    "accountNumber": "35302464616",
    "date": "2017-09-25",
    "amount": "-240.90",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "23.09 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 48418368,
    "accountNumber": "35302464616",
    "date": "2017-09-25",
    "amount": "-54.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "25.09 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 49019740,
    "accountNumber": "35302464616",
    "date": "2017-09-22",
    "amount": "-199.70",
    "text": "Meny Dolviken Dolvikvn. 36 Søriedgrend",
    "alfareferanse": "21.09 MENY DOLVIKEN DOLVIKVN. 36 SØRIEDGREND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 472,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 49037342,
    "accountNumber": "35302464616",
    "date": "2017-09-22",
    "amount": "-89.60",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "22.09 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 48808380,
    "accountNumber": "35302464616",
    "date": "2017-09-22",
    "amount": "-109.80",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "22.09 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 48748015,
    "accountNumber": "35302464616",
    "date": "2017-09-22",
    "amount": "-142.00",
    "text": "Big Bite Avd320 Loddefjordvn Loddefjord",
    "alfareferanse": "21.09 BIG BITE AVD320 LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1405,
      "placeId": "ChIJXQ2K0gz7PEYRU3MSsqTL4ik",
      "placeName": "Big Bite",
      "coordinates": "60.3625521,5.2340884",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 57412909,
    "accountNumber": "35302464616",
    "date": "2017-09-21",
    "amount": "-448.90",
    "text": "Just Eat",
    "alfareferanse": "*0971 19.09 NOK 448.90 Just Eat Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 571,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 58077683,
    "accountNumber": "35302464616",
    "date": "2017-09-20",
    "amount": "-428.59",
    "text": "Rema 1000 Kjerr Kjerreidvike Fyllingsdalen",
    "alfareferanse": "20.09 REMA 1000 KJERR KJERREIDVIKE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 506,
      "placeId": "ChIJWc0QD6v7PEYR03y6J4MH8_k",
      "placeName": "REMA 1000",
      "coordinates": "60.3419209,5.2468362",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 59311833,
    "accountNumber": "35302464616",
    "date": "2017-09-19",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 15.09 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 58790195,
    "accountNumber": "35302464616",
    "date": "2017-09-19",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 16.09 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 60157963,
    "accountNumber": "35302464616",
    "date": "2017-09-18",
    "amount": "-104.00",
    "text": "Subway Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.09 SUBWAY OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 522,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 60574907,
    "accountNumber": "35302464616",
    "date": "2017-09-15",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.09 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 61405268,
    "accountNumber": "35302464616",
    "date": "2017-09-14",
    "amount": "-127.20",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "13.09 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 61623858,
    "accountNumber": "35302464616",
    "date": "2017-09-13",
    "amount": "-99.00",
    "text": "Www.Viaplay.No",
    "alfareferanse": "*0971 11.09 NOK 99.00 WWW.VIAPLAY.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 681,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 62413703,
    "accountNumber": "35302464616",
    "date": "2017-09-12",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 07.09 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 62434336,
    "accountNumber": "35302464616",
    "date": "2017-09-12",
    "amount": "-96.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 08.09 NOK 96.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 62962037,
    "accountNumber": "35302464616",
    "date": "2017-09-11",
    "amount": "-65.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.09 NOK 65.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 63351645,
    "accountNumber": "35302464616",
    "date": "2017-09-11",
    "amount": "-144.00",
    "text": "Upstairs As Vestre Torvg Bergen",
    "alfareferanse": "09.09 UPSTAIRS AS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 562,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 63368193,
    "accountNumber": "35302464616",
    "date": "2017-09-11",
    "amount": "-115.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "09.09 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 63062361,
    "accountNumber": "35302464616",
    "date": "2017-09-11",
    "amount": "-184.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "09.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 63288198,
    "accountNumber": "35302464616",
    "date": "2017-09-11",
    "amount": "-92.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "09.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 64072543,
    "accountNumber": "35302464616",
    "date": "2017-09-08",
    "amount": "-45.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "08.09 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 64926662,
    "accountNumber": "35302464616",
    "date": "2017-09-05",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 02.09 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 65363621,
    "accountNumber": "35302465310",
    "date": "2017-09-05",
    "amount": "-5975.00",
    "text": "Bergen Bolig Og",
    "alfareferanse": "Nettgiro til: BERGEN BOLIG OG Betalt: 05.09.17",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1406,
      "placeId": "ChIJ2b9ilqj-PEYRf4QCHhL56WU",
      "placeName": "Bergen Housing AS",
      "coordinates": "60.3932937,5.3293907",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 64923070,
    "accountNumber": "35302464616",
    "date": "2017-09-05",
    "amount": "-156.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "05.09 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 66388880,
    "accountNumber": "35302464616",
    "date": "2017-09-04",
    "amount": "-25.20",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "01.09 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 65701156,
    "accountNumber": "35302464616",
    "date": "2017-09-04",
    "amount": "-44.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "03.09 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 66217713,
    "accountNumber": "35302464616",
    "date": "2017-09-04",
    "amount": "-397.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "03.09 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 66691294,
    "accountNumber": "35302464616",
    "date": "2017-09-01",
    "amount": "-82.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "01.09 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 67658637,
    "accountNumber": "35302464616",
    "date": "2017-08-31",
    "amount": "-76.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "31.08 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 67219954,
    "accountNumber": "35302464616",
    "date": "2017-08-31",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 67366533,
    "accountNumber": "35302464616",
    "date": "2017-08-31",
    "amount": "-62.40",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "30.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 67174230,
    "accountNumber": "35302908859",
    "date": "2017-08-31",
    "amount": "-3.00",
    "text": "Nettgiro M/Meld.Forfall I Dag",
    "alfareferanse": "NETTGIRO M/MELD.FORFALL I DAG       1 TRANS(ER) TYPE 203",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 635,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 67668617,
    "accountNumber": "35302908859",
    "date": "2017-08-31",
    "amount": "-3.00",
    "text": "Nettgiro M/Kid Forfall I Dag",
    "alfareferanse": "NETTGIRO M/KID FORFALL I DAG        1 TRANS(ER) TYPE 201",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 684,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 67072731,
    "accountNumber": "35302464616",
    "date": "2017-08-31",
    "amount": "-60.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         30 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 68164875,
    "accountNumber": "35302464616",
    "date": "2017-08-30",
    "amount": "-15.00",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "30.08 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 68181015,
    "accountNumber": "35302464616",
    "date": "2017-08-29",
    "amount": "-45.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "29.08 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 68798327,
    "accountNumber": "35302464616",
    "date": "2017-08-28",
    "amount": "-89.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "27.08 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 69109451,
    "accountNumber": "35302464616",
    "date": "2017-08-28",
    "amount": "-128.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "27.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 68729467,
    "accountNumber": "35302464616",
    "date": "2017-08-28",
    "amount": "-102.00",
    "text": "God Drikke Berg Vaskerelven Bergen",
    "alfareferanse": "27.08 GOD DRIKKE BERG VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 686,
      "placeId": "ChIJ8XhRegL8PEYRSgdZyHSHbM4",
      "placeName": "God Drikke Bergen AS",
      "coordinates": "60.3911614,5.3217653",
      "website": "http://www.bergensentrum.no/",
      "logoId": 110,
      "merchantName": "God Drikke Bergen AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 68732252,
    "accountNumber": "35302464616",
    "date": "2017-08-28",
    "amount": "-50.00",
    "text": "Kaos Nygårdsgt. 2 Bergen",
    "alfareferanse": "27.08 KAOS NYGÅRDSGT. 2 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 687,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 69056999,
    "accountNumber": "35302464616",
    "date": "2017-08-28",
    "amount": "-67.00",
    "text": "Paff Invest As Vaskerelvsmu Bergen",
    "alfareferanse": "27.08 PAFF INVEST AS  VASKERELVSMU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 685,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 69102104,
    "accountNumber": "35302464616",
    "date": "2017-08-28",
    "amount": "-104.00",
    "text": "Paff Invest As Vaskerelvsmu Bergen",
    "alfareferanse": "27.08 PAFF INVEST AS  VASKERELVSMU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 685,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 70412871,
    "accountNumber": "35302464616",
    "date": "2017-08-24",
    "amount": "-250.00",
    "text": "Møllaren Cafe Wolffsgt. 1 Bergen",
    "alfareferanse": "23.08 MØLLAREN CAFE WOLFFSGT. 12 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 688,
      "placeId": "ChIJjTD1zKr-PEYR4732lO0N9iE",
      "placeName": "Møllaren Cafè",
      "coordinates": "60.3831069,5.3237118",
      "website": "http://www.mollarencafe.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 70818870,
    "accountNumber": "35302464616",
    "date": "2017-08-23",
    "amount": "-54.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "23.08 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 70838848,
    "accountNumber": "35302464616",
    "date": "2017-08-23",
    "amount": "-179.00",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "23.08 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 70631523,
    "accountNumber": "35302464616",
    "date": "2017-08-23",
    "amount": "-5994.00",
    "text": "Chica Klinikk Øvregaten 25 Bergen",
    "alfareferanse": "22.08 CHICA KLINIKK ØVREGATEN 25 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Lege",
    "imageId": "",
    "place": {
      "id": 1407,
      "placeId": "ChIJu3S-Xaj-PEYR_axLISO9QYo",
      "placeName": "Chica Clinic",
      "coordinates": "60.3979641,5.3253766",
      "website": "http://www.chica.as/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 70700528,
    "accountNumber": "35302464616",
    "date": "2017-08-23",
    "amount": "-83.30",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "23.08 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 71061977,
    "accountNumber": "35302464616",
    "date": "2017-08-22",
    "amount": "-244.90",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "21.08 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 72561745,
    "accountNumber": "35302464616",
    "date": "2017-08-21",
    "amount": "-92.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "19.08 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 72557603,
    "accountNumber": "35302464616",
    "date": "2017-08-21",
    "amount": "-436.00",
    "text": "Harbour Cafe Torget 9 Bergen",
    "alfareferanse": "19.08 HARBOUR CAFE TORGET 9 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 567,
      "placeId": "ChIJX9mF3Kf-PEYRu_0lGooLSt0",
      "placeName": "Harbour Café",
      "coordinates": "60.3949519,5.3261725",
      "website": "http://harbourcafe.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 71744736,
    "accountNumber": "35302464616",
    "date": "2017-08-21",
    "amount": "-150.00",
    "text": "Harbour Cafe Torget 9 Bergen",
    "alfareferanse": "19.08 HARBOUR CAFE TORGET 9 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 567,
      "placeId": "ChIJX9mF3Kf-PEYRu_0lGooLSt0",
      "placeName": "Harbour Café",
      "coordinates": "60.3949519,5.3261725",
      "website": "http://harbourcafe.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 72140499,
    "accountNumber": "35302464616",
    "date": "2017-08-21",
    "amount": "-124.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "19.08 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 72213683,
    "accountNumber": "35302464616",
    "date": "2017-08-21",
    "amount": "-70.00",
    "text": "Chagall Scene A Vaskerelven Bergen",
    "alfareferanse": "19.08 CHAGALL SCENE A VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 589,
      "placeId": "ChIJwVdeYAL8PEYRbdFXQpTszg0",
      "placeName": "Chagall",
      "coordinates": "60.3915721,5.3205036",
      "website": "https://m.facebook.com/chagallbergen/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 72016968,
    "accountNumber": "35302464616",
    "date": "2017-08-21",
    "amount": "-183.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "19.08 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 73154722,
    "accountNumber": "35302464616",
    "date": "2017-08-18",
    "amount": "-52.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "18.08 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 73201559,
    "accountNumber": "35302464616",
    "date": "2017-08-18",
    "amount": "-1484.96",
    "text": "47 Plantasjen Alvehaugen 6 Godvik",
    "alfareferanse": "17.08 47 PLANTASJEN ALVEHAUGEN 6 GODVIK",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Hagesenter",
    "imageId": "",
    "place": {
      "id": 493,
      "placeId": "ChIJC1hM0Uj7PEYRcx6oy2zX0-4",
      "placeName": "Plantasjen",
      "coordinates": "60.3702736,5.1893302",
      "website": "https://www.plantasjen.no/storelocator/bergen-drotningsvik",
      "logoId": 54,
      "merchantName": "Plantasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 72932369,
    "accountNumber": "35302464616",
    "date": "2017-08-18",
    "amount": "-503.71",
    "text": "Rema Godvik 197 Alvehaugen 2 Godvik",
    "alfareferanse": "17.08 REMA GODVIK 197 ALVEHAUGEN 2 GODVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1276,
      "placeId": "ChIJC1lPrkj7PEYRl9z03Xe9P7M",
      "placeName": "Rema 1000 Godvik",
      "coordinates": "60.3700737,5.1928331",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 73546851,
    "accountNumber": "35302464616",
    "date": "2017-08-17",
    "amount": "-260.00",
    "text": "Bergen Kino As",
    "alfareferanse": "*0971 15.08 NOK 260.00 BERGEN KINO AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 689,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 73693046,
    "accountNumber": "35302464616",
    "date": "2017-08-17",
    "amount": "-108.40",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "17.08 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 74268502,
    "accountNumber": "35302464616",
    "date": "2017-08-16",
    "amount": "-191.90",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "15.08 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 74040807,
    "accountNumber": "35302464616",
    "date": "2017-08-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.08 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 74414384,
    "accountNumber": "35302464616",
    "date": "2017-08-16",
    "amount": "-330.13",
    "text": "Just Eat",
    "alfareferanse": "*0971 14.08 NOK 330.13 Just Eat Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 571,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 74172765,
    "accountNumber": "35302464616",
    "date": "2017-08-16",
    "amount": "-522.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "16.08 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 75197215,
    "accountNumber": "35302464616",
    "date": "2017-08-15",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.08 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 75808635,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-55.60",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "14.08 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 76045961,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-101.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 10.08 NOK 101.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 75892592,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-79.30",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "11.08 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 75947165,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-164.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "12.08 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 76127169,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-49.00",
    "text": "Www.Viaplay.No",
    "alfareferanse": "*0971 10.08 NOK 49.00 WWW.VIAPLAY.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 681,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 75504392,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-279.30",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "11.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 76301667,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-117.50",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "12.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 75906568,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-303.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "13.08 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 75848895,
    "accountNumber": "35302464616",
    "date": "2017-08-14",
    "amount": "-50.00",
    "text": "Ny Milano Pizze Stortveitvei Bergen",
    "alfareferanse": "13.08 NY MILANO PIZZE STORTVEITVEI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 691,
      "placeId": "ChIJPTvOD0D5PEYRI6kz-Db50M4",
      "placeName": "Milano Pizzeria",
      "coordinates": "60.359419,5.3500582",
      "website": "http://www.milano-pizzeria.net/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77364725,
    "accountNumber": "35302464616",
    "date": "2017-08-10",
    "amount": "-362.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "09.08 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77304244,
    "accountNumber": "35302464616",
    "date": "2017-08-10",
    "amount": "-256.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "09.08 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 76971754,
    "accountNumber": "35302464616",
    "date": "2017-08-10",
    "amount": "-141.10",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "09.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77019571,
    "accountNumber": "35302464616",
    "date": "2017-08-10",
    "amount": "-23.40",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "10.08 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77682809,
    "accountNumber": "35302464616",
    "date": "2017-08-09",
    "amount": "-63.40",
    "text": "Meny Støletorge Stølegaten15 Bergen",
    "alfareferanse": "09.08 MENY STØLETORGE STØLEGATEN15 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 503,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77603954,
    "accountNumber": "35302464616",
    "date": "2017-08-09",
    "amount": "-17.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "08.08 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77788830,
    "accountNumber": "35302464616",
    "date": "2017-08-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.08 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 78252508,
    "accountNumber": "35302464616",
    "date": "2017-08-08",
    "amount": "-23.00",
    "text": "7 - Eleven 7075 Ladegårdsgt. Bergen",
    "alfareferanse": "08.08 7 - ELEVEN 7075 LADEGÅRDSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 497,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 78256176,
    "accountNumber": "35302464616",
    "date": "2017-08-08",
    "amount": "-286.00",
    "text": "China Palace As Torgallmenni Bergen",
    "alfareferanse": "07.08 CHINA PALACE AS TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 464,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 78244992,
    "accountNumber": "35302464616",
    "date": "2017-08-08",
    "amount": "-70.30",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "07.08 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77885691,
    "accountNumber": "35302464616",
    "date": "2017-08-08",
    "amount": "-16.40",
    "text": "Coop Prix Øvreg Øvregaten 37 Bergen",
    "alfareferanse": "08.08 COOP PRIX ØVREG ØVREGATEN 37 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1404,
      "placeId": "ChIJv63NY6f-PEYRrcyEGTg-ilY",
      "placeName": "Coop Extra",
      "coordinates": "60.398484,5.32445",
      "website": "https://coop.no/butikker/extra/ovregaten-4947/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 77818121,
    "accountNumber": "35302464616",
    "date": "2017-08-08",
    "amount": "-134.80",
    "text": "Coop Prix Øvreg Øvregaten 37 Bergen",
    "alfareferanse": "08.08 COOP PRIX ØVREG ØVREGATEN 37 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1404,
      "placeId": "ChIJv63NY6f-PEYRrcyEGTg-ilY",
      "placeName": "Coop Extra",
      "coordinates": "60.398484,5.32445",
      "website": "https://coop.no/butikker/extra/ovregaten-4947/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 78662576,
    "accountNumber": "35302464616",
    "date": "2017-08-07",
    "amount": "-244.30",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "06.08 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 78534881,
    "accountNumber": "35302464616",
    "date": "2017-08-07",
    "amount": "-968.70",
    "text": "Rusta Bergen Sa Gjertrudveie Straume",
    "alfareferanse": "05.08 RUSTA BERGEN SA GJERTRUDVEIE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 620,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 79148138,
    "accountNumber": "35302464616",
    "date": "2017-08-07",
    "amount": "-145.00",
    "text": "Brun Og Blid Effect As",
    "alfareferanse": "*0971 03.08 NOK 145.00 Brun og Blid Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 488,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 79173843,
    "accountNumber": "35302464616",
    "date": "2017-08-07",
    "amount": "-668.20",
    "text": "Jula Bergen Oas Folke Barnad Fyllingsdalen",
    "alfareferanse": "05.08 JULA BERGEN OAS FOLKE BARNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 516,
      "placeId": "ChIJp13-g8_7PEYRFzYXBSYJXB8",
      "placeName": "Jula",
      "coordinates": "60.3485852,5.2915106",
      "website": "http://www.jula.no/vare-varehus/bergen-fyllingsdalen/",
      "logoId": 61,
      "merchantName": "Jula",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 79630135,
    "accountNumber": "35302464616",
    "date": "2017-08-03",
    "amount": "-323.39",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "02.08 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 79900497,
    "accountNumber": "35302464616",
    "date": "2017-08-03",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 02.08 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 80593296,
    "accountNumber": "35302464616",
    "date": "2017-08-01",
    "amount": "-113.28",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "31.07 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 80944838,
    "accountNumber": "35302464616",
    "date": "2017-08-01",
    "amount": "-56.90",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "01.08 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 80959548,
    "accountNumber": "35302464616",
    "date": "2017-08-01",
    "amount": "-150.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "01.08 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 81038091,
    "accountNumber": "35302464616",
    "date": "2017-08-01",
    "amount": "-312.00",
    "text": "Kinsarvik Natur Folke Bernad Fyllingsdalen",
    "alfareferanse": "01.08 KINSARVIK NATUR FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 692,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 80955863,
    "accountNumber": "35302464616",
    "date": "2017-08-01",
    "amount": "-97.00",
    "text": "Bergen Kaffebre Kong Christi Bergen",
    "alfareferanse": "31.07 BERGEN KAFFEBRE KONG CHRISTI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 693,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82031059,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-153.00",
    "text": "7 - Eleven 7097 Christiesgt Bergen",
    "alfareferanse": "30.07 7 - ELEVEN 7097 CHRISTIESGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 694,
      "placeId": "ChIJxdLM8an-PEYRblSEiJkkpGI",
      "placeName": "7-Eleven Christiesgate",
      "coordinates": "60.3893981,5.323395",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 81388337,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-2250.00",
    "text": "R I Brønnøysund",
    "alfareferanse": "Nettgiro til: R i Brønnøysund Betalt: 30.07.17",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Offentlige avgifter",
    "imageId": "",
    "place": {
      "id": 695,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 81371096,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-214.00",
    "text": "Leos Lekeland B Kokstaddalen Kokstad",
    "alfareferanse": "30.07 LEOS LEKELAND B KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 696,
      "placeId": "ChIJd5vtSnb6PEYRRUT3bGzuUx8",
      "placeName": "Leo's Lekeland",
      "coordinates": "60.2943127,5.2532398",
      "website": "https://www.leoslekeland.no/bergen/",
      "logoId": 115,
      "merchantName": "Leo's Lekeland",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 81428736,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-125.00",
    "text": "Leos Lekeland B Kokstaddalen Kokstad",
    "alfareferanse": "30.07 LEOS LEKELAND B KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 696,
      "placeId": "ChIJd5vtSnb6PEYRRUT3bGzuUx8",
      "placeName": "Leo's Lekeland",
      "coordinates": "60.2943127,5.2532398",
      "website": "https://www.leoslekeland.no/bergen/",
      "logoId": 115,
      "merchantName": "Leo's Lekeland",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 81310844,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-88.30",
    "text": "129 Boots Apotek Loddefjord",
    "alfareferanse": "28.07 129 BOOTS APOTEK LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 698,
      "placeId": "ChIJo0lIzQz7PEYRXzUfwZa7nWA",
      "placeName": "Boots apotek Loddefjord",
      "coordinates": "60.36309,5.233356",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82228700,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-145.00",
    "text": "Effect As",
    "alfareferanse": "*0971 27.07 NOK 145.00 Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 697,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82083829,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-89.00",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "29.07 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82091413,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-254.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "30.07 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82335552,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-278.00",
    "text": "Vita Folkebernado Fyllingsdalen",
    "alfareferanse": "29.07 1606 VITA FOLKEBERNADO FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 565,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82266082,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-82.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         41 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82282953,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-300.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "28.07 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82414147,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-128.00",
    "text": "Shell Lagunepar Laguneveien Rådal",
    "alfareferanse": "30.07 SHELL LAGUNEPAR LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 640,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82332206,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-255.18",
    "text": "Shell Lagunepar Laguneveien Rådal",
    "alfareferanse": "30.07 SHELL LAGUNEPAR LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 640,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82327116,
    "accountNumber": "35302464616",
    "date": "2017-07-31",
    "amount": "-20.90",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "28.07 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82683136,
    "accountNumber": "35302464616",
    "date": "2017-07-28",
    "amount": "-425.92",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "27.07 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82732121,
    "accountNumber": "35302464616",
    "date": "2017-07-28",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 83156095,
    "accountNumber": "35302464616",
    "date": "2017-07-27",
    "amount": "-36.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "27.07 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 83244926,
    "accountNumber": "35302464616",
    "date": "2017-07-27",
    "amount": "-78.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "27.07 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 82977608,
    "accountNumber": "35302464616",
    "date": "2017-07-27",
    "amount": "-306.80",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "26.07 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84186552,
    "accountNumber": "35302464616",
    "date": "2017-07-25",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 23.07 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84046225,
    "accountNumber": "35302464616",
    "date": "2017-07-25",
    "amount": "-329.92",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "24.07 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84197070,
    "accountNumber": "35302464616",
    "date": "2017-07-25",
    "amount": "-23.00",
    "text": "N. Lie Nielsen Vestkanten Loddefjord",
    "alfareferanse": "24.07 N. LIE NIELSEN  VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 699,
      "placeId": "ChIJo0lIzQz7PEYRNyl36OiDcbA",
      "placeName": "Lie-Nielsen konditori AS",
      "coordinates": "60.3632227,5.2336255",
      "website": "https://www.lienielsen.no/",
      "logoId": 116,
      "merchantName": "Lie-Nielsen konditori",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84029400,
    "accountNumber": "35302464616",
    "date": "2017-07-25",
    "amount": "-797.00",
    "text": "Bik Bok J01 Loddefjordve Bergen",
    "alfareferanse": "24.07 BIK BOK J01 LODDEFJORDVE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1408,
      "placeId": "ChIJM-52F6j-PEYRlPWjCquzOgY",
      "placeName": "BIK BOK",
      "coordinates": "60.3922526,5.3239096",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/640/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84984978,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-395.00",
    "text": "Årstad Pizzeria Bjørnsons Ga Bergen",
    "alfareferanse": "23.07 ÅRSTAD PIZZERIA BJØRNSONS GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 700,
      "placeId": "ChIJOxdIJk_5PEYRWJBPFscXGyI",
      "placeName": "Årstad Pizzeria",
      "coordinates": "60.373177,5.3439855",
      "website": "https://www.just-eat.no/restaurants-arstad-pizza",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84674823,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-149.00",
    "text": "Cubus 878 Vestkanten Loddefjord",
    "alfareferanse": "22.07 CUBUS 878 VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 701,
      "placeId": "ChIJo0lIzQz7PEYRzyoTFXsHUCA",
      "placeName": "Cubus",
      "coordinates": "60.3633973,5.2330705",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/878/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84945950,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-202.93",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "23.07 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85268593,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-67.50",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "23.07 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85317179,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-156.00",
    "text": "Klubb Kok Christian Mi Bergen",
    "alfareferanse": "23.07 KLUBB KOK CHRISTIAN MI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 514,
      "placeId": "ChIJl5eioAL8PEYR0fIhVxOAOj0",
      "placeName": "club Kok",
      "coordinates": "60.3933996,5.3230142",
      "website": "http://klubbkok.no/",
      "logoId": 60,
      "merchantName": "Klubb Kok",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85255670,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-130.00",
    "text": "Klubb Kok Christian Mi Bergen",
    "alfareferanse": "22.07 KLUBB KOK CHRISTIAN MI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 514,
      "placeId": "ChIJl5eioAL8PEYR0fIhVxOAOj0",
      "placeName": "club Kok",
      "coordinates": "60.3933996,5.3230142",
      "website": "http://klubbkok.no/",
      "logoId": 60,
      "merchantName": "Klubb Kok",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85380207,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-150.00",
    "text": "Klubb Kok Christian Mi Bergen",
    "alfareferanse": "23.07 KLUBB KOK CHRISTIAN MI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 514,
      "placeId": "ChIJl5eioAL8PEYR0fIhVxOAOj0",
      "placeName": "club Kok",
      "coordinates": "60.3933996,5.3230142",
      "website": "http://klubbkok.no/",
      "logoId": 60,
      "merchantName": "Klubb Kok",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84576497,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-233.00",
    "text": "Little Ea Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.07 1602 LITTLE EA  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 587,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85124465,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-89.00",
    "text": "Little Ea Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.07 1602 LITTLE EA  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 587,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84714163,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-146.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "22.07 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84968837,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-45.00",
    "text": "7 - Eleven 7091 Strandgt. 2 Bergen",
    "alfareferanse": "23.07 7 - ELEVEN 7091 STRANDGT. 2  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 612,
      "placeId": "ChIJXe9d36f-PEYRb0irnzf202s",
      "placeName": "7-Eleven",
      "coordinates": "60.3945016,5.3258814",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85357946,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-180.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "22.07 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85384407,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-206.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "22.07 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85312746,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-146.20",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "23.07 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 84806188,
    "accountNumber": "35302464616",
    "date": "2017-07-24",
    "amount": "-310.00",
    "text": "Jacobs All Berg Småstrandgat Bergen",
    "alfareferanse": "21.07 JACOBS ALL BERG SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 566,
      "placeId": "ChIJu3S-Xaj-PEYRiV0QNnQKXao",
      "placeName": "Jacob Aall Brasserie & Bar Bergen",
      "coordinates": "60.3928282,5.326276",
      "website": "http://www.jacobaall.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85481038,
    "accountNumber": "35302464616",
    "date": "2017-07-21",
    "amount": "-54.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "21.07 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85478746,
    "accountNumber": "35302464616",
    "date": "2017-07-21",
    "amount": "-239.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 19.07 NOK 239.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85810759,
    "accountNumber": "35302464616",
    "date": "2017-07-21",
    "amount": "-154.60",
    "text": "Asiaten Mat As Drotningsvik Godvik",
    "alfareferanse": "21.07 ASIATEN MAT AS  DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 702,
      "placeId": "ChIJzWQCmkz7PEYRg522m4ayPZU",
      "placeName": "Asian food",
      "coordinates": "60.3718836,5.1803986",
      "website": "http://asiatenmat.no/",
      "logoId": 118,
      "merchantName": "Asian food",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85573934,
    "accountNumber": "35302464616",
    "date": "2017-07-21",
    "amount": "-329.00",
    "text": "Vita Torgalmennin Bergen",
    "alfareferanse": "21.07 2604 VITA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1277,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 85624411,
    "accountNumber": "35302464616",
    "date": "2017-07-21",
    "amount": "-59.80",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "21.07 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 87634466,
    "accountNumber": "35302464616",
    "date": "2017-07-18",
    "amount": "-97.19",
    "text": "Kiwi 866 Hylkje Hylkjebakken Hylkje",
    "alfareferanse": "18.07 KIWI 866 HYLKJE HYLKJEBAKKEN HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1271,
      "placeId": "ChIJ572B_CcCPUYRbNdUl8weD-A",
      "placeName": "Kiwi Hylkje",
      "coordinates": "60.5095691,5.3475542",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Hylkje/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 89090592,
    "accountNumber": "35302464616",
    "date": "2017-07-14",
    "amount": "-160.43",
    "text": "Circle K Fjell Fjell Fjell",
    "alfareferanse": "13.07 CIRCLE K FJELL  FJELL FJELL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 481,
      "placeId": "ChIJI6MFELvlPEYR9btu3vfsK60",
      "placeName": "Circle K",
      "coordinates": "60.3301493,5.0752831",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=52117&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 89875391,
    "accountNumber": "35302464616",
    "date": "2017-07-13",
    "amount": "-89.00",
    "text": "Shell Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "12.07 SHELL KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 643,
      "placeId": "ChIJ60FlYp8DPUYRxGs2HOIOWfA",
      "placeName": "Shell",
      "coordinates": "60.5471999,5.2912582",
      "website": "https://find.shell.com/no/fuel/NO_9232-shell-knarvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90460209,
    "accountNumber": "35302464616",
    "date": "2017-07-12",
    "amount": "-114.00",
    "text": "Hygienisk Rens Og Vask Vestkanten",
    "alfareferanse": "11.07 HYGIENISK RENS  OG VASK VESTKANTEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Renseri",
    "imageId": "",
    "place": {
      "id": 638,
      "placeId": "ChIJo0lIzQz7PEYRsPMdrTYRcVU",
      "placeName": "Hygienic Wash & Cleaning",
      "coordinates": "60.3632227,5.2336255",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90576072,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-300.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "11.07 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90555375,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-20.14",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "10.07 MENY BERGEN STORSENTER.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90611466,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 07.07 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90830141,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-239.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 07.07 NOK 239.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90950561,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-238.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 07.07 NOK 238.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 91061280,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-55.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 07.07 NOK 55.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90717032,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.07 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 90576498,
    "accountNumber": "35302464616",
    "date": "2017-07-11",
    "amount": "-55.35",
    "text": "Extra Håkonsgaten Bergen",
    "alfareferanse": "10.07 EXTRA HÅKONSGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 703,
      "placeId": "ChIJB59LDAL8PEYRP7XlFxrKm7s",
      "placeName": "Extra Håkonsgaten",
      "coordinates": "60.3900835,5.3216343",
      "website": "https://coop.no/butikker/extra/hakonsgaten-4572/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 91143430,
    "accountNumber": "35302464616",
    "date": "2017-07-10",
    "amount": "-44.00",
    "text": "Starbucks Neumannsgate Bergen",
    "alfareferanse": "07.07 6109 STARBUCKS  NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 704,
      "placeId": "ChIJQWMabgL8PEYReOR7bM-sglQ",
      "placeName": "Starbucks Neumann street",
      "coordinates": "60.3907327,5.3214611",
      "website": "http://www.starbucks.no/store/1008256/",
      "logoId": 119,
      "merchantName": "Starbucks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 92044242,
    "accountNumber": "35302464616",
    "date": "2017-07-10",
    "amount": "-280.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 06.07 NOK 280.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 91605501,
    "accountNumber": "35302464616",
    "date": "2017-07-10",
    "amount": "-47.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "07.07 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 91470996,
    "accountNumber": "35302464616",
    "date": "2017-07-10",
    "amount": "-108.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "07.07 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 92508771,
    "accountNumber": "35302464616",
    "date": "2017-07-07",
    "amount": "-156.48",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "06.07 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 92213563,
    "accountNumber": "35302464616",
    "date": "2017-07-07",
    "amount": "-204.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 05.07 NOK 204.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 92603916,
    "accountNumber": "35302464616",
    "date": "2017-07-06",
    "amount": "-55.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 04.07 NOK 55.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 92797943,
    "accountNumber": "35302464616",
    "date": "2017-07-06",
    "amount": "-24.50",
    "text": "Ark Vestkanten Vestkanten Loddefjord",
    "alfareferanse": "05.07 ARK VESTKANTEN  VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 705,
      "placeId": "ChIJo0lIzQz7PEYR3UXU8LI2iQ0",
      "placeName": "Ark Beyer Vestkanten",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://www.vestkanten.no/butikker-og-virksomheter/ark/",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 92620913,
    "accountNumber": "35302464616",
    "date": "2017-07-06",
    "amount": "-145.00",
    "text": "Big Bite Avd320 Loddefjordvn Loddefjord",
    "alfareferanse": "05.07 BIG BITE AVD320 LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1405,
      "placeId": "ChIJXQ2K0gz7PEYRU3MSsqTL4ik",
      "placeName": "Big Bite",
      "coordinates": "60.3625521,5.2340884",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 93201220,
    "accountNumber": "35302464616",
    "date": "2017-07-05",
    "amount": "-299.00",
    "text": "Telenorbutikken Torgallmenni Bergen",
    "alfareferanse": "04.07 TELENORBUTIKKEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 1279,
      "placeId": "ChIJ1dQdua7-PEYRIBw_Mq0MzjI",
      "placeName": "Telenor shop Bergen Storsenter",
      "coordinates": "60.3896095,5.3329286",
      "website": "http://www.telenor.no/telenorbutikken",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 93020442,
    "accountNumber": "35302464616",
    "date": "2017-07-05",
    "amount": "-150.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "04.07 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 93300288,
    "accountNumber": "35302464616",
    "date": "2017-07-05",
    "amount": "-54.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "05.07 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 93721717,
    "accountNumber": "35302464616",
    "date": "2017-07-04",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 01.07 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94202960,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-132.60",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "01.07 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94923688,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-302.92",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "03.07 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94999602,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-196.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "02.07 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94785461,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-190.00",
    "text": "Chagall Scene A Vaskerelven Bergen",
    "alfareferanse": "02.07 CHAGALL SCENE A VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 589,
      "placeId": "ChIJwVdeYAL8PEYRbdFXQpTszg0",
      "placeName": "Chagall",
      "coordinates": "60.3915721,5.3205036",
      "website": "https://m.facebook.com/chagallbergen/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 95109828,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-148.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 29.06 NOK 148.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94774069,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-148.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 29.06 NOK 148.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94502797,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-60.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "01.07 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94320260,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-6.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "02.07 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94224887,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-105.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "01.07 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94187113,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-203.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "01.07 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94502628,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-122.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "02.07 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 94857860,
    "accountNumber": "35302464616",
    "date": "2017-07-03",
    "amount": "-111.00",
    "text": "Jama Mat As Sikthaugen T Fyllingsdalen",
    "alfareferanse": "01.07 JAMA MAT AS SIKTHAUGEN T FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 708,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 95444088,
    "accountNumber": "35302464616",
    "date": "2017-06-30",
    "amount": "-261.00",
    "text": "Dolly Dimple's Lyderhornsve Loddefjord",
    "alfareferanse": "29.06 DOLLY DIMPLE'S  LYDERHORNSVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 709,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 95349819,
    "accountNumber": "35302464616",
    "date": "2017-06-30",
    "amount": "-252.12",
    "text": "Esso Nesstun Fanavn.2 Nesttun",
    "alfareferanse": "29.06 ESSO NESSTUN FANAVN.2 NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 710,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 95857502,
    "accountNumber": "35302464616",
    "date": "2017-06-30",
    "amount": "-126.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         63 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 534,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 96266257,
    "accountNumber": "35302464616",
    "date": "2017-06-29",
    "amount": "-140.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 27.06 NOK 140.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 96268605,
    "accountNumber": "35302464616",
    "date": "2017-06-29",
    "amount": "-307.10",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 27.06 NOK 307.10 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 97012951,
    "accountNumber": "35302464616",
    "date": "2017-06-28",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 96773069,
    "accountNumber": "35302464616",
    "date": "2017-06-28",
    "amount": "-184.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 26.06 NOK 184.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 96595383,
    "accountNumber": "35302464616",
    "date": "2017-06-28",
    "amount": "-121.00",
    "text": "Fruktbar As Christies Ga Bergen",
    "alfareferanse": "28.06 FRUKTBAR AS CHRISTIES GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 621,
      "placeId": "ChIJX3-Y4QH8PEYRyoAjEDzSLfs",
      "placeName": "Godt Brød Christie Café",
      "coordinates": "60.387474,5.3222432",
      "website": "http://www.godtbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 97386481,
    "accountNumber": "35302464616",
    "date": "2017-06-27",
    "amount": "-275.00",
    "text": "Årspris Visa Classic",
    "alfareferanse": "Årspris Visa Classic",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 679,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 97321094,
    "accountNumber": "35302464616",
    "date": "2017-06-27",
    "amount": "-190.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 24.06 NOK 190.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 97299505,
    "accountNumber": "35302464616",
    "date": "2017-06-27",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 23.06 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 97560503,
    "accountNumber": "35302464616",
    "date": "2017-06-27",
    "amount": "-24.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 25.06 NOK 24.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 98445262,
    "accountNumber": "35302464616",
    "date": "2017-06-26",
    "amount": "-299.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "24.06 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 98182956,
    "accountNumber": "35302464616",
    "date": "2017-06-26",
    "amount": "-115.39",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "23.06 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 98555918,
    "accountNumber": "35302464616",
    "date": "2017-06-26",
    "amount": "-208.00",
    "text": "Fruktbar As Christies Ga Bergen",
    "alfareferanse": "24.06 FRUKTBAR AS CHRISTIES GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 621,
      "placeId": "ChIJX3-Y4QH8PEYRyoAjEDzSLfs",
      "placeName": "Godt Brød Christie Café",
      "coordinates": "60.387474,5.3222432",
      "website": "http://www.godtbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99013146,
    "accountNumber": "35302464616",
    "date": "2017-06-23",
    "amount": "-84.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "22.06 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99005643,
    "accountNumber": "35302464616",
    "date": "2017-06-23",
    "amount": "-250.00",
    "text": "Pasta Sentral V.Torggt 5-7 Bergen",
    "alfareferanse": "23.06 PASTA SENTRAL V.TORGGT 5-7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 712,
      "placeId": "ChIJ_-KGbQL8PEYRif8GaK8WHEU",
      "placeName": "Pasta sentralen AS",
      "coordinates": "60.3904726,5.3211308",
      "website": "http://pastasentral.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99155068,
    "accountNumber": "35302464616",
    "date": "2017-06-23",
    "amount": "-260.00",
    "text": "Bergen Kino As",
    "alfareferanse": "*0971 21.06 NOK 260.00 BERGEN KINO AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 689,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99391130,
    "accountNumber": "35302464616",
    "date": "2017-06-22",
    "amount": "-238.00",
    "text": "Bergen Kino Neumannsgate Bergen",
    "alfareferanse": "21.06 BERGEN KINO NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 713,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99811335,
    "accountNumber": "35302464616",
    "date": "2017-06-22",
    "amount": "-339.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "21.06 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99285226,
    "accountNumber": "35302464616",
    "date": "2017-06-22",
    "amount": "-111.00",
    "text": "Fruktbar As Christies Ga Bergen",
    "alfareferanse": "22.06 FRUKTBAR AS CHRISTIES GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 621,
      "placeId": "ChIJX3-Y4QH8PEYRyoAjEDzSLfs",
      "placeName": "Godt Brød Christie Café",
      "coordinates": "60.387474,5.3222432",
      "website": "http://www.godtbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99330318,
    "accountNumber": "35302464616",
    "date": "2017-06-22",
    "amount": "-150.28",
    "text": "Esso Nyborg . Bergen",
    "alfareferanse": "21.06 ESSO NYBORG . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 475,
      "placeId": "ChIJeyicmf_9PEYRZunp1cnbohE",
      "placeName": "Esso",
      "coordinates": "60.4742847,5.3370742",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 99910031,
    "accountNumber": "35302464616",
    "date": "2017-06-21",
    "amount": "-40.72",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "20.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 100157781,
    "accountNumber": "35302464616",
    "date": "2017-06-21",
    "amount": "-344.88",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "20.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 100112737,
    "accountNumber": "35302464616",
    "date": "2017-06-21",
    "amount": "-6561.00",
    "text": "Krav Fra Faktnr. 6",
    "alfareferanse": "Krav fra faktnr. 1647226",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1453,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 100604112,
    "accountNumber": "35302464616",
    "date": "2017-06-20",
    "amount": "-121.00",
    "text": "Fruktbar As Christies Ga Bergen",
    "alfareferanse": "20.06 FRUKTBAR AS CHRISTIES GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 621,
      "placeId": "ChIJX3-Y4QH8PEYRyoAjEDzSLfs",
      "placeName": "Godt Brød Christie Café",
      "coordinates": "60.387474,5.3222432",
      "website": "http://www.godtbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 100667651,
    "accountNumber": "35302464616",
    "date": "2017-06-20",
    "amount": "-55.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 17.06 NOK 55.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 100562550,
    "accountNumber": "35302464616",
    "date": "2017-06-20",
    "amount": "-235.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 18.06 NOK 235.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 101985776,
    "accountNumber": "35302464616",
    "date": "2017-06-19",
    "amount": "-95.00",
    "text": "Chagall Scene A Vaskerelven Bergen",
    "alfareferanse": "17.06 CHAGALL SCENE A VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 589,
      "placeId": "ChIJwVdeYAL8PEYRbdFXQpTszg0",
      "placeName": "Chagall",
      "coordinates": "60.3915721,5.3205036",
      "website": "https://m.facebook.com/chagallbergen/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 102055142,
    "accountNumber": "35302464616",
    "date": "2017-06-19",
    "amount": "-190.00",
    "text": "Chagall Scene A Vaskerelven Bergen",
    "alfareferanse": "17.06 CHAGALL SCENE A VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 589,
      "placeId": "ChIJwVdeYAL8PEYRbdFXQpTszg0",
      "placeName": "Chagall",
      "coordinates": "60.3915721,5.3205036",
      "website": "https://m.facebook.com/chagallbergen/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 101647289,
    "accountNumber": "35302464616",
    "date": "2017-06-19",
    "amount": "-105.30",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "19.06 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 101914436,
    "accountNumber": "35302464616",
    "date": "2017-06-19",
    "amount": "-439.00",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "17.06 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 101883624,
    "accountNumber": "35302464616",
    "date": "2017-06-19",
    "amount": "-328.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "17.06 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 102512387,
    "accountNumber": "35302464616",
    "date": "2017-06-16",
    "amount": "-187.00",
    "text": "Sib Kaffebar Bi Bi Marinehol Bergen",
    "alfareferanse": "16.06 SIB KAFFEBAR BI BI MARINEHOL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 716,
      "placeId": "ChIJ-4MS11T5PEYRzpgH0S1MMi8",
      "placeName": "SiB Kafé Slippen BI Bergen",
      "coordinates": "60.3822395,5.3253919",
      "website": "http://www.sib.no/no/mat-og-drikke/kafeer-og-apningstider/sib-kafe-bi-bergen",
      "logoId": 122,
      "merchantName": "SiB Kafé",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 102654457,
    "accountNumber": "35302464616",
    "date": "2017-06-16",
    "amount": "-239.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.06 NOK 239.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 102714633,
    "accountNumber": "35302464616",
    "date": "2017-06-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.06 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 104336182,
    "accountNumber": "35302464616",
    "date": "2017-06-13",
    "amount": "-55.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 10.06 NOK 55.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 104446643,
    "accountNumber": "35302464616",
    "date": "2017-06-13",
    "amount": "-110.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 11.06 NOK 110.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 104332827,
    "accountNumber": "35302464616",
    "date": "2017-06-13",
    "amount": "-288.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 11.06 NOK 288.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 104314064,
    "accountNumber": "35302464616",
    "date": "2017-06-13",
    "amount": "-55.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 10.06 NOK 55.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 104667507,
    "accountNumber": "35302464616",
    "date": "2017-06-13",
    "amount": "-55.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 11.06 NOK 55.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 104572889,
    "accountNumber": "35302464616",
    "date": "2017-06-13",
    "amount": "-114.00",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "13.06 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 104296205,
    "accountNumber": "35302464616",
    "date": "2017-06-13",
    "amount": "-20.10",
    "text": "129 Boots Apotek Loddefjord",
    "alfareferanse": "13.06 129 BOOTS APOTEK LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 698,
      "placeId": "ChIJo0lIzQz7PEYRXzUfwZa7nWA",
      "placeName": "Boots apotek Loddefjord",
      "coordinates": "60.36309,5.233356",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 105401507,
    "accountNumber": "35302464616",
    "date": "2017-06-12",
    "amount": "-319.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "10.06 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 105169083,
    "accountNumber": "35302464616",
    "date": "2017-06-12",
    "amount": "-125.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "10.06 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 105478010,
    "accountNumber": "35302464616",
    "date": "2017-06-12",
    "amount": "-35.58",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "11.06 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 105177017,
    "accountNumber": "35302464616",
    "date": "2017-06-12",
    "amount": "-219.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "11.06 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 105197770,
    "accountNumber": "35302464616",
    "date": "2017-06-12",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "10.06 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 106302636,
    "accountNumber": "35302464616",
    "date": "2017-06-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.06 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 106156728,
    "accountNumber": "35302464616",
    "date": "2017-06-09",
    "amount": "-250.11",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "08.06 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 106718485,
    "accountNumber": "35302464616",
    "date": "2017-06-08",
    "amount": "-54.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "08.06 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 106807051,
    "accountNumber": "35302464616",
    "date": "2017-06-08",
    "amount": "-48.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "08.06 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 107186930,
    "accountNumber": "35302464616",
    "date": "2017-06-07",
    "amount": "-13.90",
    "text": "Rema Dreggen Dreggsallmen Bergen",
    "alfareferanse": "07.06 REMA DREGGEN DREGGSALLMEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 717,
      "placeId": "ChIJdaSkvR38PEYRfq7m0M0uvWY",
      "placeName": "Rema 1000 Dreggen",
      "coordinates": "60.3986011,5.3219006",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108205236,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-165.90",
    "text": "Vinmonopolet Valkendorfsg Bergen",
    "alfareferanse": "03.06 VINMONOPOLET VALKENDORFSG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 718,
      "placeId": "ChIJu_x9vq7-PEYRDJzRB3tvIqY",
      "placeName": "Vinmonopolet Bergen Storsenter",
      "coordinates": "60.3894163,5.3332038",
      "website": "http://www.vinmonopolet.no/butikker?butikk_id=121",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 107709818,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-145.14",
    "text": "Paypal *Hairheaven",
    "alfareferanse": "*0971 31.05 NOK 145.14 PAYPAL *HAIRHEAVEN Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 719,
      "placeId": "ChIJA5hX4DwVdkgRYp06RNRFBCo",
      "placeName": "Hair Heaven",
      "coordinates": "51.6526767,-0.3581291",
      "website": "https://www.hairheaven.co.uk/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 107955187,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-124.00",
    "text": "Deli De Luca Torget 1 Bergen",
    "alfareferanse": "04.06 DELI DE LUCA TORGET 1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1361,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108332711,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 01.06 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108371197,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-56.90",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "04.06 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 107908698,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-36.40",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "04.06 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 107980908,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-130.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "04.06 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108498260,
    "accountNumber": "35302464616",
    "date": "2017-06-06",
    "amount": "-130.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "04.06 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108665206,
    "accountNumber": "35302464616",
    "date": "2017-06-02",
    "amount": "-53.40",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "01.06 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108878022,
    "accountNumber": "35302464616",
    "date": "2017-06-02",
    "amount": "-317.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "02.06 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108879714,
    "accountNumber": "35302464616",
    "date": "2017-06-02",
    "amount": "-219.00",
    "text": "Vita T.Almenning Bergen",
    "alfareferanse": "02.06 1603 VITA T.ALMENNING  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1280,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108900505,
    "accountNumber": "35302464616",
    "date": "2017-06-02",
    "amount": "-388.00",
    "text": "Vita Torgalmennin Bergen",
    "alfareferanse": "02.06 2604 VITA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1277,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 108809838,
    "accountNumber": "35302464616",
    "date": "2017-06-02",
    "amount": "-150.00",
    "text": "Vita Torgalmennin Bergen",
    "alfareferanse": "01.06 2604 VITA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1277,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 109978380,
    "accountNumber": "35302464616",
    "date": "2017-05-31",
    "amount": "-183.29",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "30.05 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 109571818,
    "accountNumber": "35302464616",
    "date": "2017-05-31",
    "amount": "-106.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         53 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 575,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 110058029,
    "accountNumber": "35302464616",
    "date": "2017-05-31",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 110218551,
    "accountNumber": "35302464616",
    "date": "2017-05-31",
    "amount": "-1054.80",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "31.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 109569431,
    "accountNumber": "35302464616",
    "date": "2017-05-31",
    "amount": "-24.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 26.05 NOK 24.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 110500614,
    "accountNumber": "35302464616",
    "date": "2017-05-30",
    "amount": "-10.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "30.05 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 110501875,
    "accountNumber": "35302464616",
    "date": "2017-05-30",
    "amount": "-188.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "30.05 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 110774504,
    "accountNumber": "35302464616",
    "date": "2017-05-30",
    "amount": "-143.00",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "30.05 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 111594200,
    "accountNumber": "35302464616",
    "date": "2017-05-29",
    "amount": "-191.50",
    "text": "7 - Eleven 7092 Engen 16 Bergen",
    "alfareferanse": "28.05 7 - ELEVEN 7092 ENGEN 16 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1410,
      "placeId": "ChIJfYZ5XwL8PEYRpNxbn_gNe6Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3916858,5.3200612",
      "website": "http://7-eleven.no/butikker",
      "logoId": 207,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 111753137,
    "accountNumber": "35302464616",
    "date": "2017-05-29",
    "amount": "-269.20",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "27.05 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 111936338,
    "accountNumber": "35302464616",
    "date": "2017-05-26",
    "amount": "-158.00",
    "text": "Godteland Sartor Stors Straume",
    "alfareferanse": "24.05 GODTELAND SARTOR STORS STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 721,
      "placeId": "ChIJD53HT-7kPEYRVLr8h6Mw-2E",
      "placeName": "Godteland Sartor",
      "coordinates": "60.3594025,5.1242705",
      "website": "http://www.sartorstorsenter.no/butikker-og-virksomheter/godteland/",
      "logoId": 124,
      "merchantName": "Godteland Sartor",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 113823341,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-100.00",
    "text": "Skipperstuen Torggt. Bergen",
    "alfareferanse": "21.05 SKIPPERSTUEN TORGGT. 9 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 722,
      "placeId": "ChIJ0ecsfwL8PEYRk_osjEw_tZo",
      "placeName": "Skipperstuen",
      "coordinates": "60.3912703,5.3222627",
      "website": "https://skipperstuenpub.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114339882,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-280.00",
    "text": "Vinmonopolet Valkendorfsg Bergen",
    "alfareferanse": "19.05 VINMONOPOLET VALKENDORFSG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 718,
      "placeId": "ChIJu_x9vq7-PEYRDJzRB3tvIqY",
      "placeName": "Vinmonopolet Bergen Storsenter",
      "coordinates": "60.3894163,5.3332038",
      "website": "http://www.vinmonopolet.no/butikker?butikk_id=121",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114839433,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-135.00",
    "text": "Logehaven Øvre Ole Bul Bergen",
    "alfareferanse": "19.05 LOGEHAVEN ØVRE OLE BUL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1411,
      "placeId": "ChIJM-sihgL8PEYRNkenudeVD9E",
      "placeName": "LogeHaven",
      "coordinates": "60.391945,5.321493",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114876377,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-100.00",
    "text": "Logehaven Øvre Ole Bul Bergen",
    "alfareferanse": "19.05 LOGEHAVEN ØVRE OLE BUL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1411,
      "placeId": "ChIJM-sihgL8PEYRNkenudeVD9E",
      "placeName": "LogeHaven",
      "coordinates": "60.391945,5.321493",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114867626,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-4988.00",
    "text": "Krav Fra Faktnr. 6",
    "alfareferanse": "Krav fra faktnr. 1635246",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1453,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114125836,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-235.70",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "21.05 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114657995,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-371.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "20.05 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 113922729,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-282.95",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "19.05 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114307421,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-258.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "20.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114419863,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-258.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "21.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114485917,
    "accountNumber": "35302464616",
    "date": "2017-05-22",
    "amount": "-125.00",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "21.05 NARVESEN 747 OLE BULLSPL. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115188335,
    "accountNumber": "35302464616",
    "date": "2017-05-19",
    "amount": "-223.50",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "18.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115097207,
    "accountNumber": "35302464616",
    "date": "2017-05-19",
    "amount": "-198.00",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "18.05 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115145495,
    "accountNumber": "35302464616",
    "date": "2017-05-19",
    "amount": "-898.00",
    "text": "Moods Of Norway Torgallmenni Bergen",
    "alfareferanse": "19.05 MOODS OF NORWAY TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 723,
      "placeId": "ChIJz_7uOqj-PEYRHzkQ-e3yeR8",
      "placeName": "Moods Of Norway Bergen",
      "coordinates": "60.3924903,5.3241874",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 114983813,
    "accountNumber": "35302464616",
    "date": "2017-05-19",
    "amount": "-501.30",
    "text": "Cubus 879 Sundt City Bergen",
    "alfareferanse": "19.05 CUBUS 879 SUNDT CITY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 724,
      "placeId": "ChIJg4UbMKj-PEYRyOGpKSaozac",
      "placeName": "Cubus",
      "coordinates": "60.3922867,5.3239234",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/879/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115968583,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-15.80",
    "text": "Rema Danmarkspl Fjøsangervei Bergen",
    "alfareferanse": "18.05 REMA DANMARKSPL FJØSANGERVEI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 725,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115712650,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-214.00",
    "text": "Musikkhuset As Christiesgt. Bergen",
    "alfareferanse": "18.05 MUSIKKHUSET AS  CHRISTIESGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 726,
      "placeId": "ChIJzeH57an-PEYRUmWf2RueF0k",
      "placeName": "Musikkhuset AS",
      "coordinates": "60.389573,5.3240131",
      "website": "http://garage.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115545467,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-117.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "16.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115860424,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-64.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "18.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115597514,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-432.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "18.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 116079393,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-260.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "18.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115699497,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-260.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "18.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115513775,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-185.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "18.05 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115696070,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-29.00",
    "text": "Fruktbar As Christies Ga Bergen",
    "alfareferanse": "18.05 FRUKTBAR AS CHRISTIES GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 621,
      "placeId": "ChIJX3-Y4QH8PEYRyoAjEDzSLfs",
      "placeName": "Godt Brød Christie Café",
      "coordinates": "60.387474,5.3222432",
      "website": "http://www.godtbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115522931,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-170.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "18.05 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115727932,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-213.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "18.05 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 115942879,
    "accountNumber": "35302464616",
    "date": "2017-05-18",
    "amount": "-275.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "16.05 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 116336454,
    "accountNumber": "35302464616",
    "date": "2017-05-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.05 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 116359829,
    "accountNumber": "35302464616",
    "date": "2017-05-16",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.05 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 116497495,
    "accountNumber": "35302464616",
    "date": "2017-05-16",
    "amount": "-607.70",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "15.05 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 116779881,
    "accountNumber": "35302464616",
    "date": "2017-05-16",
    "amount": "-88.00",
    "text": "Fruktbar As Christies Ga Bergen",
    "alfareferanse": "16.05 FRUKTBAR AS CHRISTIES GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 621,
      "placeId": "ChIJX3-Y4QH8PEYRyoAjEDzSLfs",
      "placeName": "Godt Brød Christie Café",
      "coordinates": "60.387474,5.3222432",
      "website": "http://www.godtbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 116217618,
    "accountNumber": "35302464616",
    "date": "2017-05-16",
    "amount": "-98.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "15.05 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 116635316,
    "accountNumber": "35302464616",
    "date": "2017-05-16",
    "amount": "-374.00",
    "text": "Brio Vestk. Trå Vestkanten S Loddefjord",
    "alfareferanse": "15.05 BRIO VESTK. TRÅ VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 727,
      "placeId": "ChIJo0lIzQz7PEYRfhUe0vO1UU4",
      "placeName": "Kozmo Vestkanten Loddefjord",
      "coordinates": "60.3630847,5.2328662",
      "website": "http://kozmos.no/din-kozmos-butikk/kontakt-kozmos-vestkanten-loddefjord/",
      "logoId": 103,
      "merchantName": "Kozmo",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 118121138,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-148.00",
    "text": "Mcdonald's Brygges Bryggesporen Bergen",
    "alfareferanse": "12.05 MCD 057 BRYGGES BRYGGESPOREN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 728,
      "placeId": "ChIJP1mWxaf-PEYRnQwhU4NL1qk",
      "placeName": "McDonald's",
      "coordinates": "60.3954113,5.3266711",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 118198254,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-138.00",
    "text": "Mcdonald's Brygges Bryggesporen Bergen",
    "alfareferanse": "13.05 MCD 057 BRYGGES BRYGGESPOREN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 728,
      "placeId": "ChIJP1mWxaf-PEYRnQwhU4NL1qk",
      "placeName": "McDonald's",
      "coordinates": "60.3954113,5.3266711",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 117545515,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-105.00",
    "text": "Los Tacos Vetrl Vetrlidsallm Bergen",
    "alfareferanse": "13.05 LOS TACOS VETRL VETRLIDSALLM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 729,
      "placeId": "ChIJRzkYzqn-PEYRprWKuxCk4-U",
      "placeName": "Los Tacos Sentrum",
      "coordinates": "60.3912,5.323645",
      "website": "http://lostacos.no/",
      "logoId": 127,
      "merchantName": "Los Tacos",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 117978337,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-229.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "13.05 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 117135270,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-303.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "13.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 117635114,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-800.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "13.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 117455238,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-100.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "13.05 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 117368281,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-1361.06",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "12.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 117637568,
    "accountNumber": "35302464616",
    "date": "2017-05-15",
    "amount": "-218.00",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "13.05 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 118478292,
    "accountNumber": "35302464616",
    "date": "2017-05-12",
    "amount": "-45.00",
    "text": "Fløibanen As Vetrlidsalme Bergen",
    "alfareferanse": "12.05 FLØIBANEN AS VETRLIDSALME BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 730,
      "placeId": "ChIJjS2isqT-PEYRCidepLOTHdk",
      "placeName": "Fløibanen",
      "coordinates": "60.3964198,5.3285645",
      "website": "http://www.floibanen.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119165677,
    "accountNumber": "35302464616",
    "date": "2017-05-11",
    "amount": "-207.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "10.05 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119106573,
    "accountNumber": "35302464616",
    "date": "2017-05-11",
    "amount": "-189.00",
    "text": "Lerøy Mat Galle Torgalm.8 Bergen",
    "alfareferanse": "10.05 LERØY MAT GALLE TORGALM.8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1281,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119278888,
    "accountNumber": "35302464616",
    "date": "2017-05-11",
    "amount": "-101.19",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "11.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119265232,
    "accountNumber": "35302464616",
    "date": "2017-05-11",
    "amount": "-27.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119466160,
    "accountNumber": "35302464616",
    "date": "2017-05-10",
    "amount": "-84.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "09.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119534738,
    "accountNumber": "35302464616",
    "date": "2017-05-10",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.05 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119539905,
    "accountNumber": "35302464616",
    "date": "2017-05-10",
    "amount": "-498.00",
    "text": "Bik Bok 640 Torgalmening Bergen",
    "alfareferanse": "10.05 BIK BOK 640 TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1283,
      "placeId": "ChIJM-52F6j-PEYRlPWjCquzOgY",
      "placeName": "BIK BOK",
      "coordinates": "60.3922526,5.3239096",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/640/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 119874986,
    "accountNumber": "35302464616",
    "date": "2017-05-10",
    "amount": "-199.70",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "10.05 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120316320,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-359.00",
    "text": "Vita T.Almenning Bergen",
    "alfareferanse": "05.05 1603 VITA T.ALMENNING  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1280,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120318604,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-49.00",
    "text": "Vita Torgalmennin Bergen",
    "alfareferanse": "06.05 2604 VITA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1277,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120633344,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-151.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "06.05 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120632572,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-200.00",
    "text": "Nintendo Of Europe",
    "alfareferanse": "*0971 04.05 NOK 200.00 NINTENDO OF EUROPE Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 731,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121033459,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-500.00",
    "text": "Nintendo Of Europe",
    "alfareferanse": "*0971 04.05 NOK 500.00 NINTENDO OF EUROPE Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 731,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121011964,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-83.00",
    "text": "Biblioteket Bar Vesterlidalm Bergen",
    "alfareferanse": "07.05 BIBLIOTEKET BAR VESTERLIDALM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 732,
      "placeId": "ChIJZ88Wtqf-PEYReiCCV4-OZ2Q",
      "placeName": "The Library Bar",
      "coordinates": "60.395987,5.327239",
      "website": "http://www.biblioteketbar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121106758,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-158.00",
    "text": "Kremmerhuset 27 Torgalmennin Bergen",
    "alfareferanse": "05.05 KREMMERHUSET 27 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 733,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120945691,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-38.28",
    "text": "Søstrene Grene Torgallmenni Bergen",
    "alfareferanse": "05.05 SØSTRENE GRENE  TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 734,
      "placeId": "ChIJE9vOQqj-PEYRTFD5oJlvttk",
      "placeName": "Søstrene Grene",
      "coordinates": "60.392964,5.3260681",
      "website": "https://sostrenegrene.com/",
      "logoId": 130,
      "merchantName": "Søstrene Grene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121119626,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-311.74",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "08.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120242121,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-180.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "07.05 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120692270,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-300.41",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "07.05 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120665122,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-130.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "07.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 120401239,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-124.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "06.05 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121090113,
    "accountNumber": "35302464616",
    "date": "2017-05-08",
    "amount": "-33.40",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "06.05 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121353033,
    "accountNumber": "35302464616",
    "date": "2017-05-05",
    "amount": "-148.00",
    "text": "Deli De Luca En Engen 10 Bergen",
    "alfareferanse": "05.05 DELI DE LUCA EN ENGEN 10 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 596,
      "placeId": "ChIJpVeEigL8PEYRge9-UXmATTA",
      "placeName": "Deli de Luca Engen",
      "coordinates": "60.3917507,5.3206748",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121536227,
    "accountNumber": "35302464616",
    "date": "2017-05-05",
    "amount": "-17.00",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "05.05 NARVESEN 747 OLE BULLSPL. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121458565,
    "accountNumber": "35302464616",
    "date": "2017-05-05",
    "amount": "-3070.00",
    "text": "Skatteetaten -",
    "alfareferanse": "Nettgiro til: SKATTEETATEN - Betalt: 05.05.17",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bilavgifter",
    "imageId": "",
    "place": {
      "id": 735,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121182829,
    "accountNumber": "35302464616",
    "date": "2017-05-05",
    "amount": "-48.30",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "05.05 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122052691,
    "accountNumber": "35302464616",
    "date": "2017-05-04",
    "amount": "-520.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "03.05 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121683589,
    "accountNumber": "35302464616",
    "date": "2017-05-04",
    "amount": "-50.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "03.05 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121996276,
    "accountNumber": "35302464616",
    "date": "2017-05-04",
    "amount": "-80.10",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "03.05 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 121766762,
    "accountNumber": "35302464616",
    "date": "2017-05-04",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 02.05 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122022688,
    "accountNumber": "35302464616",
    "date": "2017-05-04",
    "amount": "-64.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "04.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122200018,
    "accountNumber": "35302464616",
    "date": "2017-05-03",
    "amount": "-272.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122544202,
    "accountNumber": "35302464616",
    "date": "2017-05-03",
    "amount": "-109.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 29.04 NOK 109.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122427265,
    "accountNumber": "35302464616",
    "date": "2017-05-03",
    "amount": "-275.55",
    "text": "Rema Dreggen Dreggsallmen Bergen",
    "alfareferanse": "03.05 REMA DREGGEN DREGGSALLMEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 717,
      "placeId": "ChIJdaSkvR38PEYRfq7m0M0uvWY",
      "placeName": "Rema 1000 Dreggen",
      "coordinates": "60.3986011,5.3219006",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122204870,
    "accountNumber": "35302464616",
    "date": "2017-05-03",
    "amount": "-118.00",
    "text": "Sørensen Tobakk Torgallmenni Bergen",
    "alfareferanse": "02.05 SØRENSEN TOBAKK TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 736,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124298612,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-560.00",
    "text": "Espos Nr.2 Konsertbet. Drammen",
    "alfareferanse": "29.04 ESPOS NR.2 KONSERTBET.  DRAMMEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Konserter, teater og underholdning",
    "imageId": "",
    "place": {
      "id": 737,
      "placeId": "ChIJm84cmmU7QUYRl5X4JT77WOg",
      "placeName": "Espos Norge AS",
      "coordinates": "59.7090882,10.2375251",
      "website": "http://www.espos.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124204321,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-510.00",
    "text": "Espos Nr.2 Konsertbet. Drammen",
    "alfareferanse": "29.04 ESPOS NR.2 KONSERTBET.  DRAMMEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Konserter, teater og underholdning",
    "imageId": "",
    "place": {
      "id": 737,
      "placeId": "ChIJm84cmmU7QUYRl5X4JT77WOg",
      "placeName": "Espos Norge AS",
      "coordinates": "59.7090882,10.2375251",
      "website": "http://www.espos.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124722245,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-215.00",
    "text": "Musikkhuset As Christiesgt. Bergen",
    "alfareferanse": "29.04 MUSIKKHUSET AS  CHRISTIESGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 726,
      "placeId": "ChIJzeH57an-PEYRUmWf2RueF0k",
      "placeName": "Musikkhuset AS",
      "coordinates": "60.389573,5.3240131",
      "website": "http://garage.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123948867,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-30.00",
    "text": "Musikkhuset As Christiesgt. Bergen",
    "alfareferanse": "30.04 MUSIKKHUSET AS  CHRISTIESGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 726,
      "placeId": "ChIJzeH57an-PEYRUmWf2RueF0k",
      "placeName": "Musikkhuset AS",
      "coordinates": "60.389573,5.3240131",
      "website": "http://garage.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124202786,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-27.20",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "28.04 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124428388,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-45.00",
    "text": "7 - Eleven 7097 Christiesgt Bergen",
    "alfareferanse": "29.04 7 - ELEVEN 7097 CHRISTIESGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 694,
      "placeId": "ChIJxdLM8an-PEYRblSEiJkkpGI",
      "placeName": "7-Eleven Christiesgate",
      "coordinates": "60.3893981,5.323395",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123586722,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-124.00",
    "text": "7 - Eleven 7097 Christiesgt Bergen",
    "alfareferanse": "30.04 7 - ELEVEN 7097 CHRISTIESGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 694,
      "placeId": "ChIJxdLM8an-PEYRblSEiJkkpGI",
      "placeName": "7-Eleven Christiesgate",
      "coordinates": "60.3893981,5.323395",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123452479,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-126.80",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "28.04 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123691073,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-99.90",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "28.04 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124127497,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-408.70",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "02.05 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124178978,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "3332.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "Fra: GJENSIDIGE FORSIKRING ASA Betalt: 02.05.17",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124580484,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-8.50",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "28.04 NARVESEN 747 OLE BULLSPL. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123753075,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-57.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123505637,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-188.29",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "30.04 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124198449,
    "accountNumber": "35302464616",
    "date": "2017-05-02",
    "amount": "-370.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "30.04 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123340115,
    "accountNumber": "35302464616",
    "date": "2017-04-30",
    "amount": "-10.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123339194,
    "accountNumber": "35302464616",
    "date": "2017-04-30",
    "amount": "-68.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         34 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122773658,
    "accountNumber": "35302464616",
    "date": "2017-04-28",
    "amount": "-156.10",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "28.04 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122760807,
    "accountNumber": "35302464616",
    "date": "2017-04-28",
    "amount": "-305.30",
    "text": "Nespresso Bouti Strandgaten Bergen",
    "alfareferanse": "28.04 NESPRESSO BOUTI STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 738,
      "placeId": "ChIJJQ2lA6j-PEYRkm-DbV9hQQY",
      "placeName": "Nespresso Boutique Bergen",
      "coordinates": "60.3940462,5.3237397",
      "website": "http://www.nespresso.com/",
      "logoId": 133,
      "merchantName": "Nespresso Boutique",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123097711,
    "accountNumber": "35302464616",
    "date": "2017-04-28",
    "amount": "-120.30",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "27.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 123084242,
    "accountNumber": "35302464616",
    "date": "2017-04-28",
    "amount": "-167.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "28.04 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 122886202,
    "accountNumber": "35302464616",
    "date": "2017-04-28",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124886488,
    "accountNumber": "35302464616",
    "date": "2017-04-27",
    "amount": "-273.10",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "26.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 124939453,
    "accountNumber": "35302464616",
    "date": "2017-04-27",
    "amount": "-55.00",
    "text": "Effect As",
    "alfareferanse": "*0971 25.04 NOK 55.00 Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 697,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 125326107,
    "accountNumber": "35302464616",
    "date": "2017-04-26",
    "amount": "-275.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "26.04 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 125387005,
    "accountNumber": "35302464616",
    "date": "2017-04-26",
    "amount": "-126.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "25.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 125873683,
    "accountNumber": "35302464616",
    "date": "2017-04-25",
    "amount": "-49.00",
    "text": "Www.Viaplay.No",
    "alfareferanse": "*0971 21.04 NOK 49.00 WWW.VIAPLAY.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 681,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126096472,
    "accountNumber": "35302464616",
    "date": "2017-04-25",
    "amount": "-879.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 21.04 NOK 879.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 125855450,
    "accountNumber": "35302464616",
    "date": "2017-04-25",
    "amount": "-79.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 21.04 NOK 79.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 125980479,
    "accountNumber": "35302464616",
    "date": "2017-04-25",
    "amount": "-250.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "24.04 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 125940799,
    "accountNumber": "35302464616",
    "date": "2017-04-25",
    "amount": "-548.00",
    "text": "Cubus 879 Sundt City Bergen",
    "alfareferanse": "24.04 CUBUS 879 SUNDT CITY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 724,
      "placeId": "ChIJg4UbMKj-PEYRyOGpKSaozac",
      "placeName": "Cubus",
      "coordinates": "60.3922867,5.3239234",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/879/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126664661,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-263.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "23.04 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126732375,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-74.00",
    "text": "Effect As",
    "alfareferanse": "*0971 20.04 NOK 74.00 Effect AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Treningssenter",
    "subCategory": "Treningssenter",
    "imageId": "",
    "place": {
      "id": 697,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126700694,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-568.00",
    "text": "Årstad Pizzeria Bjørnsons Ga Bergen",
    "alfareferanse": "22.04 ÅRSTAD PIZZERIA BJØRNSONS GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 700,
      "placeId": "ChIJOxdIJk_5PEYRWJBPFscXGyI",
      "placeName": "Årstad Pizzeria",
      "coordinates": "60.373177,5.3439855",
      "website": "https://www.just-eat.no/restaurants-arstad-pizza",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126474876,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-344.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "21.04 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126397661,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "23.04 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126669607,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-300.17",
    "text": "Circle K Servic Nordre Brurå Nyborg",
    "alfareferanse": "21.04 CIRCLE K SERVIC NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 491,
      "placeId": "ChIJd0uwwucBPUYRZlp84b2Kdlc",
      "placeName": "Circle K Haukås",
      "coordinates": "60.4846724,5.3764514",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55564&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126591599,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-303.70",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "22.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 126513472,
    "accountNumber": "35302464616",
    "date": "2017-04-24",
    "amount": "-140.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "21.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127313065,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-363.14",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "20.04 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127499219,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-35.00",
    "text": "Kinsarvik Frukt Olav Kyrres Bergen",
    "alfareferanse": "20.04 KINSARVIK FRUKT OLAV KYRRES  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 622,
      "placeId": "ChIJ8Yn4CQL8PEYRxln6jym_U2g",
      "placeName": "Kinsarvik Fruit Sales Naturkostladen",
      "coordinates": "60.3898921,5.3222186",
      "website": "http://www.kinsarvik.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127122782,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-330.00",
    "text": "Årstad Pizzeria Bjørnsons Ga Bergen",
    "alfareferanse": "20.04 ÅRSTAD PIZZERIA BJØRNSONS GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 700,
      "placeId": "ChIJOxdIJk_5PEYRWJBPFscXGyI",
      "placeName": "Årstad Pizzeria",
      "coordinates": "60.373177,5.3439855",
      "website": "https://www.just-eat.no/restaurants-arstad-pizza",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127264857,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-230.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "21.04 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127213033,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-1000.00",
    "text": "Atm Galleriet Be Dnb Bank Asa 7183",
    "alfareferanse": "20.04 ATM GALLERIET BE DNB BANK ASA      7183",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 739,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127237258,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-314.00",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "21.04 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127560267,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-94.80",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "20.04 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127524351,
    "accountNumber": "35302464616",
    "date": "2017-04-21",
    "amount": "-4988.00",
    "text": "Krav Fra Faktnr. 2",
    "alfareferanse": "Krav fra faktnr. 1607162",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1454,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 128390212,
    "accountNumber": "35302464616",
    "date": "2017-04-20",
    "amount": "-255.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "19.04 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 127708454,
    "accountNumber": "35302464616",
    "date": "2017-04-20",
    "amount": "-253.28",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "19.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 128768712,
    "accountNumber": "35302464616",
    "date": "2017-04-19",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.04 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 129043685,
    "accountNumber": "35302464616",
    "date": "2017-04-19",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.04 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 128751514,
    "accountNumber": "35302464616",
    "date": "2017-04-19",
    "amount": "-93.70",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "18.04 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 129581955,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-171.30",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "17.04 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 130512502,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-79.44",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "18.04 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 130159170,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-63.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "14.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 129871856,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-169.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "16.04 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 130227569,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-222.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "14.04 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 130394460,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-139.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "16.04 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 129666440,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-36.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "15.04 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 129752860,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-150.00",
    "text": "Lille Vaskerelven Bergen",
    "alfareferanse": "16.04 LILLE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 740,
      "placeId": "ChIJqzY-egL8PEYRudQQ9OqCRXU",
      "placeName": "Lille Bar & Lounge",
      "coordinates": "60.3911359,5.322035",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 129507232,
    "accountNumber": "35302464616",
    "date": "2017-04-18",
    "amount": "-144.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "15.04 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131138095,
    "accountNumber": "35302464616",
    "date": "2017-04-12",
    "amount": "-244.20",
    "text": "Nille 458 Småstrandgt Bergen",
    "alfareferanse": "12.04 NILLE 458 SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 741,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131295831,
    "accountNumber": "35302464616",
    "date": "2017-04-12",
    "amount": "-109.80",
    "text": "H&M No0839 Xhibition Sm Bergen",
    "alfareferanse": "12.04 H&M NO0839 XHIBITION SM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 742,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131115518,
    "accountNumber": "35302464616",
    "date": "2017-04-12",
    "amount": "-199.00",
    "text": "H&M No0839 Xhibition Sm Bergen",
    "alfareferanse": "12.04 H&M NO0839 XHIBITION SM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 742,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131252880,
    "accountNumber": "35302464616",
    "date": "2017-04-12",
    "amount": "-59.86",
    "text": "Søstrene Grene Småstrandgat Bergen",
    "alfareferanse": "12.04 SØSTRENE GRENE  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 743,
      "placeId": "ChIJE9vOQqj-PEYRTFD5oJlvttk",
      "placeName": "Søstrene Grene",
      "coordinates": "60.392964,5.3260681",
      "website": "https://sostrenegrene.com/",
      "logoId": 130,
      "merchantName": "Søstrene Grene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131320569,
    "accountNumber": "35302464616",
    "date": "2017-04-12",
    "amount": "-283.09",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "12.04 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131295402,
    "accountNumber": "35302464616",
    "date": "2017-04-12",
    "amount": "-349.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 10.04 NOK 349.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 130877007,
    "accountNumber": "35302464616",
    "date": "2017-04-12",
    "amount": "-184.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "12.04 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131631235,
    "accountNumber": "35302464616",
    "date": "2017-04-11",
    "amount": "-106.60",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "10.04 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131741508,
    "accountNumber": "35302464616",
    "date": "2017-04-11",
    "amount": "-64.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "11.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 131782799,
    "accountNumber": "35302464616",
    "date": "2017-04-11",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.04 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132587775,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-36.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "07.04 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132342041,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-25.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132218258,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-165.30",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "09.04 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132124386,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-615.80",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "08.04 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132547269,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-62.53",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "08.04 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132827115,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-303.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "08.04 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132704368,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-276.90",
    "text": "Extra Strømgt. 5 Bergen",
    "alfareferanse": "07.04 EXTRA STRØMGT. 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 744,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132577504,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-109.00",
    "text": "Godteland Byst. Strømgaten 8 Bergen.",
    "alfareferanse": "07.04 GODTELAND BYST. STRØMGATEN 8 BERGEN.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 745,
      "placeId": "ChIJ37Vivq7-PEYR7k-d9e2avQM",
      "placeName": "Godteland Bystasjonen",
      "coordinates": "60.3891794,5.332768",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/godteland/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132521918,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-299.00",
    "text": "Cafe Marino As Åsane Senter Ulset",
    "alfareferanse": "08.04 CAFE MARINO AS  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 746,
      "placeId": "ChIJMc6UmPr9PEYRNc9JWl46Xe0",
      "placeName": "Grand Caffé Venezia avd. Åsane Senter",
      "coordinates": "60.4663844,5.3225102",
      "website": "http://veneziapizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 132426146,
    "accountNumber": "35302464616",
    "date": "2017-04-10",
    "amount": "-200.00",
    "text": "Møllaren Cafe Wolffsgt. 1 Bergen",
    "alfareferanse": "09.04 MØLLAREN CAFE WOLFFSGT. 12 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 688,
      "placeId": "ChIJjTD1zKr-PEYR4732lO0N9iE",
      "placeName": "Møllaren Cafè",
      "coordinates": "60.3831069,5.3237118",
      "website": "http://www.mollarencafe.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 133019883,
    "accountNumber": "35302464616",
    "date": "2017-04-07",
    "amount": "-49.40",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "07.04 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 133069350,
    "accountNumber": "35302464616",
    "date": "2017-04-07",
    "amount": "-344.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 05.04 NOK 344.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 133548706,
    "accountNumber": "35302464616",
    "date": "2017-04-06",
    "amount": "-123.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "06.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 133993381,
    "accountNumber": "35302464616",
    "date": "2017-04-05",
    "amount": "-173.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "04.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 134594015,
    "accountNumber": "35302464616",
    "date": "2017-04-04",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 01.04 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 134617781,
    "accountNumber": "35302464616",
    "date": "2017-04-04",
    "amount": "-441.00",
    "text": "Dolly Dimple's Torgalmennin Bergen",
    "alfareferanse": "03.04 DOLLY DIMPLE'S  TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 747,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 135017575,
    "accountNumber": "35302464616",
    "date": "2017-04-03",
    "amount": "-196.40",
    "text": "Bunnpris Ulveda Ulvedalen 5 Flaktveit",
    "alfareferanse": "02.04 BUNNPRIS ULVEDA ULVEDALEN 5  FLAKTVEIT",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 748,
      "placeId": "ChIJeSlWRhr-PEYRPn7QA7VLd5E",
      "placeName": "Bunnpris Ulvedalen",
      "coordinates": "60.4648761,5.3621029",
      "website": "http://bunnpris.no/butikker/bunnpris-ulvedalen",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 135553982,
    "accountNumber": "35302464616",
    "date": "2017-04-03",
    "amount": "-99.40",
    "text": "H&M No0879 Myrdalsvegen Nyborg",
    "alfareferanse": "01.04 H&M NO0879 MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 749,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 134899975,
    "accountNumber": "35302464616",
    "date": "2017-04-03",
    "amount": "-199.00",
    "text": "H&M No0879 Myrdalsvegen Nyborg",
    "alfareferanse": "01.04 H&M NO0879 MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 749,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 134879274,
    "accountNumber": "35302464616",
    "date": "2017-04-03",
    "amount": "-345.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "02.04 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 135680815,
    "accountNumber": "35302464616",
    "date": "2017-04-03",
    "amount": "-20.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 29.03 NOK 20.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 135687941,
    "accountNumber": "35302464616",
    "date": "2017-04-03",
    "amount": "-416.79",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "02.04 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 134634899,
    "accountNumber": "35302464616",
    "date": "2017-04-03",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "01.04 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 136018714,
    "accountNumber": "35302464616",
    "date": "2017-03-31",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 135856176,
    "accountNumber": "35302464616",
    "date": "2017-03-31",
    "amount": "-86.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         43 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 135995389,
    "accountNumber": "35302464616",
    "date": "2017-03-31",
    "amount": "-1120.00",
    "text": "Tannlege Nygård Dreggallmeni Bergen",
    "alfareferanse": "31.03 TANNLEGE NYGÅRD DREGGALLMENI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Tannlege",
    "subCategory": "Tannlege",
    "imageId": "",
    "place": {
      "id": 1285,
      "placeId": "ChIJy_2sFKz-PEYRvQffQxADJdg",
      "placeName": "Fylkestannlegen i Hordaland",
      "coordinates": "60.3869619,5.3322157",
      "website": "http://www.hordaland.no/nn-NO/om-oss/om-fylkeskommunen/meir-om-tannhelse/",
      "logoId": 263,
      "merchantName": "Fylkestannlegen i Hordaland",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 136963802,
    "accountNumber": "35302464616",
    "date": "2017-03-29",
    "amount": "-0.93",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "28.03 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 137610178,
    "accountNumber": "35302464616",
    "date": "2017-03-28",
    "amount": "-38.52",
    "text": "Søstrene Grene Torgallmenni Bergen",
    "alfareferanse": "28.03 SØSTRENE GRENE  TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 734,
      "placeId": "ChIJE9vOQqj-PEYRTFD5oJlvttk",
      "placeName": "Søstrene Grene",
      "coordinates": "60.392964,5.3260681",
      "website": "https://sostrenegrene.com/",
      "logoId": 130,
      "merchantName": "Søstrene Grene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 138200390,
    "accountNumber": "35302464616",
    "date": "2017-03-27",
    "amount": "-124.05",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "25.03 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 138290504,
    "accountNumber": "35302464616",
    "date": "2017-03-27",
    "amount": "-259.00",
    "text": "Godteland Byst. Strømgaten 8 Bergen.",
    "alfareferanse": "24.03 GODTELAND BYST. STRØMGATEN 8 BERGEN.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 745,
      "placeId": "ChIJ37Vivq7-PEYR7k-d9e2avQM",
      "placeName": "Godteland Bystasjonen",
      "coordinates": "60.3891794,5.332768",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/godteland/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 138729873,
    "accountNumber": "35302464616",
    "date": "2017-03-27",
    "amount": "-292.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "24.03 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 138039339,
    "accountNumber": "35302464616",
    "date": "2017-03-27",
    "amount": "-217.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "25.03 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 138627689,
    "accountNumber": "35302464616",
    "date": "2017-03-27",
    "amount": "-362.17",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "27.03 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139053666,
    "accountNumber": "35302464616",
    "date": "2017-03-24",
    "amount": "-93.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 22.03 NOK 93.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139031379,
    "accountNumber": "35302464616",
    "date": "2017-03-24",
    "amount": "-93.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 22.03 NOK 93.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139495793,
    "accountNumber": "35302464616",
    "date": "2017-03-23",
    "amount": "-599.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 21.03 NOK 599.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139421601,
    "accountNumber": "35302464616",
    "date": "2017-03-23",
    "amount": "-399.99",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 21.03 NOK 399.99 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139242319,
    "accountNumber": "35302464616",
    "date": "2017-03-23",
    "amount": "-76.88",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "23.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139721404,
    "accountNumber": "35302464616",
    "date": "2017-03-22",
    "amount": "-45.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 20.03 NOK 45.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139602098,
    "accountNumber": "35302464616",
    "date": "2017-03-22",
    "amount": "-2149.44",
    "text": "Bkk As",
    "alfareferanse": "Nettgiro til: BKK AS Betalt: 22.03.17",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Elektrisitet",
    "imageId": "",
    "place": {
      "id": 751,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139999398,
    "accountNumber": "35302464616",
    "date": "2017-03-22",
    "amount": "-2400.00",
    "text": "Bergen Kommune",
    "alfareferanse": "Nettgiro til: BERGEN KOMMUNE Betalt: 22.03.17",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 773,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 139859779,
    "accountNumber": "35302464616",
    "date": "2017-03-22",
    "amount": "-517.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 22.03.17",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140059245,
    "accountNumber": "35302464616",
    "date": "2017-03-21",
    "amount": "-4988.00",
    "text": "Krav Fra Faktnr. 3",
    "alfareferanse": "Krav fra faktnr. 1600253",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1455,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140106135,
    "accountNumber": "35302464616",
    "date": "2017-03-21",
    "amount": "-20.70",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "20.03 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 141774151,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-24.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "18.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 141733575,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-186.50",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "20.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140957026,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-200.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "18.03 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140693900,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-350.12",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "17.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140685833,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-251.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "17.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140566832,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-659.33",
    "text": "Vita Excl Loddefjordvn Loddefjord",
    "alfareferanse": "17.03 3616 VITA EXCL  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1397,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 141648134,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-178.00",
    "text": "Vita T.Almenning Bergen",
    "alfareferanse": "18.03 1603 VITA T.ALMENNING  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1280,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 141681797,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-40.00",
    "text": "Haraldsplass Pa Ulriksdal 8 Bergen",
    "alfareferanse": "17.03 HARALDSPLASS PA ULRIKSDAL 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 752,
      "placeId": "ChIJNcAvjjT5PEYR5s9AYQbx4Do",
      "placeName": "Haraldsplass Diakonale Sykehus",
      "coordinates": "60.3790047,5.3623255",
      "website": "http://www.haraldsplass.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140753325,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-267.00",
    "text": "Vita Vestk Loddefjordve Loddefjord",
    "alfareferanse": "17.03 1611 VITA VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 753,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 140873803,
    "accountNumber": "35302464616",
    "date": "2017-03-20",
    "amount": "-279.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "19.03 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 142223025,
    "accountNumber": "35302464616",
    "date": "2017-03-17",
    "amount": "-101.00",
    "text": "Extra Strømgt. 5 Bergen",
    "alfareferanse": "17.03 EXTRA STRØMGT. 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 744,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 142432354,
    "accountNumber": "35302464616",
    "date": "2017-03-17",
    "amount": "-178.00",
    "text": "Vita Strømgt. 8 Bergen",
    "alfareferanse": "17.03 1610 VITA STRØMGT. 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 754,
      "placeId": "ChIJJ_9Dt67-PEYRlZpJ6_lJ0WE",
      "placeName": "Vita Bergen Storsenter",
      "coordinates": "60.3890421,5.3322011",
      "website": "https://www.vita.no/butikker/vita-bergen-storsenter",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 142265635,
    "accountNumber": "35302464616",
    "date": "2017-03-17",
    "amount": "-24.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "17.03 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 142249822,
    "accountNumber": "35302464616",
    "date": "2017-03-17",
    "amount": "-242.43",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "16.03 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 142439490,
    "accountNumber": "35302464616",
    "date": "2017-03-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.03 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 142866799,
    "accountNumber": "35302464616",
    "date": "2017-03-16",
    "amount": "-39.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "16.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 143573514,
    "accountNumber": "35302464616",
    "date": "2017-03-15",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.03 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 143561370,
    "accountNumber": "35302464616",
    "date": "2017-03-15",
    "amount": "-45.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 13.03 NOK 45.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144995895,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-60.00",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "12.03 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144467452,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-155.70",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "11.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144560382,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-55.44",
    "text": "Deli De Luca En Engen 10 Bergen",
    "alfareferanse": "10.03 DELI DE LUCA EN ENGEN 10 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 596,
      "placeId": "ChIJpVeEigL8PEYRge9-UXmATTA",
      "placeName": "Deli de Luca Engen",
      "coordinates": "60.3917507,5.3206748",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144721756,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-73.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "12.03 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144413231,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-22.90",
    "text": "Kiwi Vaskerelve Vaskerelven Bergen",
    "alfareferanse": "10.03 KIWI VASKERELVE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 755,
      "placeId": "ChIJB8N4YQL8PEYRE5MD7Pzmxls",
      "placeName": "KIWI Vaskerelven",
      "coordinates": "60.3916346,5.3209891",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Vaskerelven-/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144697588,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-114.40",
    "text": "Kiwi Vaskerelve Vaskerelven Bergen",
    "alfareferanse": "10.03 KIWI VASKERELVE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 755,
      "placeId": "ChIJB8N4YQL8PEYRE5MD7Pzmxls",
      "placeName": "KIWI Vaskerelven",
      "coordinates": "60.3916346,5.3209891",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Vaskerelven-/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144267214,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-212.00",
    "text": "Snedig As Magnus Barfo Bergen",
    "alfareferanse": "12.03 SNEDIG AS MAGNUS BARFO BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 756,
      "placeId": "ChIJmfn00Kn-PEYR9X4VvWhHERA",
      "placeName": "Snedig design AS",
      "coordinates": "60.3909694,5.3233177",
      "website": "http://snedig.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 144314605,
    "accountNumber": "35302464616",
    "date": "2017-03-13",
    "amount": "-250.00",
    "text": "Felix Pub Torget 3 Bergen",
    "alfareferanse": "12.03 FELIX PUB TORGET 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1413,
      "placeId": "ChIJ8zua3qf-PEYRltBuEukw3D8",
      "placeName": "Felix",
      "coordinates": "60.3947594,5.325929",
      "website": "http://www.felixbergen.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 145880715,
    "accountNumber": "35302464616",
    "date": "2017-03-09",
    "amount": "-186.00",
    "text": "Paypal *Makeupmekka",
    "alfareferanse": "*0971 07.03 NOK 186.00 PAYPAL *MAKEUPMEKKA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 757,
      "placeId": "ChIJOYDfs4luQUYRnN7mCciKceQ",
      "placeName": "Makeup Mekka AS",
      "coordinates": "59.925237,10.748984",
      "website": "http://www.makeupmekka.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 145837985,
    "accountNumber": "35302464616",
    "date": "2017-03-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.03 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 146624155,
    "accountNumber": "35302464616",
    "date": "2017-03-07",
    "amount": "-197.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "06.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 146768984,
    "accountNumber": "35302464616",
    "date": "2017-03-07",
    "amount": "-149.00",
    "text": "Vita Torgalmennin Bergen",
    "alfareferanse": "06.03 2604 VITA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1277,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147425145,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-299.00",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "04.03 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147224315,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-154.00",
    "text": "Starbucks Vetrlidsallm Bergen",
    "alfareferanse": "05.03 6107 STARBUCKS  VETRLIDSALLM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 758,
      "placeId": "ChIJZ88Wtqf-PEYRSzJdXH7MFJA",
      "placeName": "Starbucks",
      "coordinates": "60.3957719,5.326411",
      "website": "http://www.starbucks.no/store/1006292/no/kjottbasaren/vetrlidsallmenningen-2-bryggen-bergen-12-5014",
      "logoId": 119,
      "merchantName": "Starbucks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147399598,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-163.16",
    "text": "Søstrene Grene Torgallmenni Bergen",
    "alfareferanse": "03.03 SØSTRENE GRENE  TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 734,
      "placeId": "ChIJE9vOQqj-PEYRTFD5oJlvttk",
      "placeName": "Søstrene Grene",
      "coordinates": "60.392964,5.3260681",
      "website": "https://sostrenegrene.com/",
      "logoId": 130,
      "merchantName": "Søstrene Grene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147676935,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-240.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "03.03 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147164570,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-99.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "04.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147411597,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-145.40",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "04.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147349560,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-29.90",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "03.03 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147289075,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 02.03 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147195285,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-306.11",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "04.03 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147205597,
    "accountNumber": "35302464616",
    "date": "2017-03-06",
    "amount": "-10.00",
    "text": "7 - Eleven 7134 Torget. 17 Bergen",
    "alfareferanse": "05.03 7 - ELEVEN 7134 TORGET. 17 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 512,
      "placeId": "ChIJW7VB36f-PEYRiPmsO1fOA-Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3953766,5.3262766",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 147845625,
    "accountNumber": "35302464616",
    "date": "2017-03-03",
    "amount": "-34.30",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "03.03 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 148282310,
    "accountNumber": "35302464616",
    "date": "2017-03-02",
    "amount": "-89.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 148976829,
    "accountNumber": "35302464616",
    "date": "2017-03-01",
    "amount": "-183.70",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "01.03 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 148565387,
    "accountNumber": "35302464616",
    "date": "2017-03-01",
    "amount": "-461.19",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "28.02 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 148771618,
    "accountNumber": "35302464616",
    "date": "2017-03-01",
    "amount": "-186.60",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "28.02 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 149578253,
    "accountNumber": "35302464616",
    "date": "2017-02-28",
    "amount": "-87.34",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "27.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 149346885,
    "accountNumber": "35302464616",
    "date": "2017-02-28",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 149641569,
    "accountNumber": "35302464616",
    "date": "2017-02-28",
    "amount": "-54.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         27 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150439130,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-265.00",
    "text": "Inside Rock Cafe Bergen",
    "alfareferanse": "26.02 INSIDE ROCK CAFE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 761,
      "placeId": "ChIJFXBwfAL8PEYRScMQLJUMq6E",
      "placeName": "Inside Live & Rock cafe",
      "coordinates": "60.391402,5.3218394",
      "website": "http://insiderockcafe.no/",
      "logoId": 140,
      "merchantName": "Inside Live & Rock cafe",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150530840,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-259.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "26.02 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150299159,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-203.89",
    "text": "Uno-X Fjoesange R 0727 Bergen",
    "alfareferanse": "25.02 UNO-X FJOESANGE R 0727 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 760,
      "placeId": "ChIJ2-aGK275PEYRIRChH9mFokc",
      "placeName": "Uno-X Fjøsanger",
      "coordinates": "60.3476207,5.33622",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150630704,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-36.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "27.02 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150190370,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-163.00",
    "text": "Big Bite Avd320 Loddefjordvn Loddefjord",
    "alfareferanse": "25.02 BIG BITE AVD320 LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1405,
      "placeId": "ChIJXQ2K0gz7PEYRU3MSsqTL4ik",
      "placeName": "Big Bite",
      "coordinates": "60.3625521,5.2340884",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150790455,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-77.00",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "26.02 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 149984200,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-326.40",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "25.02 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150233219,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-119.00",
    "text": "Vita Folkebernado Fyllingsdalen",
    "alfareferanse": "25.02 1606 VITA FOLKEBERNADO FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 565,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150173541,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-888.80",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "25.02 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150508243,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-74.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "24.02 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 150612667,
    "accountNumber": "35302464616",
    "date": "2017-02-27",
    "amount": "-563.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "24.02 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 151305639,
    "accountNumber": "35302464616",
    "date": "2017-02-24",
    "amount": "-20.90",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "24.02 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 151150549,
    "accountNumber": "35302464616",
    "date": "2017-02-24",
    "amount": "-587.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 24.02.17",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 151689236,
    "accountNumber": "35302464616",
    "date": "2017-02-23",
    "amount": "-74.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "23.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152187454,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-104.27",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "20.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152321834,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-713.41",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "21.02 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152286532,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-336.65",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 19.02 NOK 336.65 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152436828,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-92.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 19.02 NOK 92.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152288981,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 19.02 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152421877,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-899.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 19.02 NOK 899.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152265879,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-619.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 19.02 NOK 619.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152364925,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-4988.00",
    "text": "Krav Fra Faktnr. 6",
    "alfareferanse": "Krav fra faktnr. 1593076",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1453,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152377719,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-799.00",
    "text": "Biltema Avd 240 Janaflaten Godvik",
    "alfareferanse": "21.02 BILTEMA AVD 240 JANAFLATEN GODVIK",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1286,
      "placeId": "ChIJ00n-HEn7PEYRsBd5mLj4VKE",
      "placeName": "Biltema",
      "coordinates": "60.3688061,5.189063",
      "website": "http://www.biltema.no/",
      "logoId": 264,
      "merchantName": "Biltema",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 152536806,
    "accountNumber": "35302464616",
    "date": "2017-02-21",
    "amount": "-229.00",
    "text": "Paypal *Makeupmekka",
    "alfareferanse": "*0971 19.02 NOK 229.00 PAYPAL *MAKEUPMEKKA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 757,
      "placeId": "ChIJOYDfs4luQUYRnN7mCciKceQ",
      "placeName": "Makeup Mekka AS",
      "coordinates": "59.925237,10.748984",
      "website": "http://www.makeupmekka.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 153600971,
    "accountNumber": "35302464616",
    "date": "2017-02-20",
    "amount": "-552.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "18.02 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 153497115,
    "accountNumber": "35302464616",
    "date": "2017-02-20",
    "amount": "-342.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "19.02 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 153778880,
    "accountNumber": "35302464616",
    "date": "2017-02-20",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "19.02 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 153885136,
    "accountNumber": "35302464616",
    "date": "2017-02-20",
    "amount": "-206.17",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "18.02 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 154368821,
    "accountNumber": "35302464616",
    "date": "2017-02-17",
    "amount": "-152.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "17.02 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 153939453,
    "accountNumber": "35302464616",
    "date": "2017-02-17",
    "amount": "-93.35",
    "text": "Søstrene Grene Småstrandgat Bergen",
    "alfareferanse": "17.02 SØSTRENE GRENE  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 743,
      "placeId": "ChIJE9vOQqj-PEYRTFD5oJlvttk",
      "placeName": "Søstrene Grene",
      "coordinates": "60.392964,5.3260681",
      "website": "https://sostrenegrene.com/",
      "logoId": 130,
      "merchantName": "Søstrene Grene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 154546793,
    "accountNumber": "35302464616",
    "date": "2017-02-16",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.02 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 154904159,
    "accountNumber": "35302464616",
    "date": "2017-02-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.02 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 156135050,
    "accountNumber": "35302464616",
    "date": "2017-02-14",
    "amount": "-153.50",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "13.02 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 156120503,
    "accountNumber": "35302464616",
    "date": "2017-02-14",
    "amount": "-295.55",
    "text": "Nille 5 Vestk Loddefjordve Bergen",
    "alfareferanse": "13.02 NILLE 5 VESTK LODDEFJORDVE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 670,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 155851452,
    "accountNumber": "35302464616",
    "date": "2017-02-14",
    "amount": "-22.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "13.02 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 155793706,
    "accountNumber": "35302464616",
    "date": "2017-02-14",
    "amount": "-39.00",
    "text": "Norli Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "13.02 NORLI VESTKANT  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 762,
      "placeId": "ChIJtRG8xQz7PEYRfAYt5JYO8RM",
      "placeName": "Norli",
      "coordinates": "60.3624188,5.2337002",
      "website": "http://www.norli.no/",
      "logoId": 141,
      "merchantName": "Norli",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 156097665,
    "accountNumber": "35302464616",
    "date": "2017-02-14",
    "amount": "-191.60",
    "text": "Clas Ohl 881 Loddefjordv. Bergen",
    "alfareferanse": "13.02 CLAS OHL 881 LODDEFJORDV. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 763,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157241820,
    "accountNumber": "35302464616",
    "date": "2017-02-10",
    "amount": "-299.00",
    "text": "Paypal *Makeupmekka",
    "alfareferanse": "*0971 07.02 NOK 299.00 PAYPAL *MAKEUPMEKKA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 757,
      "placeId": "ChIJOYDfs4luQUYRnN7mCciKceQ",
      "placeName": "Makeup Mekka AS",
      "coordinates": "59.925237,10.748984",
      "website": "http://www.makeupmekka.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157543954,
    "accountNumber": "35302464616",
    "date": "2017-02-10",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 08.02 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157960851,
    "accountNumber": "35302464616",
    "date": "2017-02-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.02 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157822462,
    "accountNumber": "35302464616",
    "date": "2017-02-09",
    "amount": "-234.60",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "08.02 MENY BERGEN STORSENTER.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157832565,
    "accountNumber": "35302464616",
    "date": "2017-02-09",
    "amount": "-136.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "08.02 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157912718,
    "accountNumber": "35302464616",
    "date": "2017-02-09",
    "amount": "-7.50",
    "text": "Gc Reiber Eiendom Plus",
    "alfareferanse": "*0971 07.02 NOK 7.50 GC Reiber Eiendom Plus Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 764,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157711026,
    "accountNumber": "35302464616",
    "date": "2017-02-09",
    "amount": "-65.00",
    "text": "Tgr Norge As Strøm Gaten Bergen",
    "alfareferanse": "08.02 TGR NORGE AS STRØM GATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 765,
      "placeId": "ChIJWXX6OUuYP0YRSXPklBnAo8E",
      "placeName": "Flying Tiger Copenhagen",
      "coordinates": "60.3892604,5.3325568",
      "website": "http://flyingtiger.com/",
      "logoId": 142,
      "merchantName": "Flying Tiger",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 157988699,
    "accountNumber": "35302464616",
    "date": "2017-02-09",
    "amount": "-149.00",
    "text": "Clas Ohl 825 Strømgaten 8 Bergen",
    "alfareferanse": "08.02 CLAS OHL 825 STRØMGATEN 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 766,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 158177862,
    "accountNumber": "35302464616",
    "date": "2017-02-08",
    "amount": "76.00",
    "text": "Klarna Wish.Com",
    "alfareferanse": "*0971 12.11 NOK 76.00 KLARNA WISH.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 767,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 158522324,
    "accountNumber": "35302464616",
    "date": "2017-02-07",
    "amount": "73.00",
    "text": "Klarna Wish.Com",
    "alfareferanse": "*0971 12.11 NOK 73.00 KLARNA WISH.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 767,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 158755545,
    "accountNumber": "35302464616",
    "date": "2017-02-07",
    "amount": "-150.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "07.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 159373027,
    "accountNumber": "35302464616",
    "date": "2017-02-06",
    "amount": "-9.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "04.02 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 159381784,
    "accountNumber": "35302464616",
    "date": "2017-02-06",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 02.02 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 158942000,
    "accountNumber": "35302464616",
    "date": "2017-02-06",
    "amount": "-413.00",
    "text": "Biologen As . Herdla",
    "alfareferanse": "04.02 BIOLOGEN AS . HERDLA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 768,
      "placeId": "ChIJWRTFu0UfPUYRsoRbPKkLEgE",
      "placeName": "Biologen",
      "coordinates": "60.569479,4.957696",
      "website": "http://biologenherdla.no/",
      "logoId": 143,
      "merchantName": "Biologen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 160048938,
    "accountNumber": "35302464616",
    "date": "2017-02-03",
    "amount": "-259.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "02.02 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 159885419,
    "accountNumber": "35302464616",
    "date": "2017-02-03",
    "amount": "-90.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "02.02 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 160172433,
    "accountNumber": "35302464616",
    "date": "2017-02-02",
    "amount": "-358.70",
    "text": "Coop Obs Sartor Sartor Sente Straume",
    "alfareferanse": "01.02 COOP OBS SARTOR SARTOR SENTE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 578,
      "placeId": "ChIJ07Dmae7kPEYRdtQFqnLjn7Y",
      "placeName": "Obs Bygg",
      "coordinates": "60.3589314,5.1249784",
      "website": "https://coop.no/butikker/obs/sartor-2408/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 160487841,
    "accountNumber": "35302464616",
    "date": "2017-02-02",
    "amount": "-438.40",
    "text": "Rusta Bergen Sa Gjertrudveie Straume",
    "alfareferanse": "01.02 RUSTA BERGEN SA GJERTRUDVEIE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 620,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 160272748,
    "accountNumber": "35302464616",
    "date": "2017-02-02",
    "amount": "-97.00",
    "text": "Malinka As Jonsvollsgt Bergen",
    "alfareferanse": "02.02 MALINKA AS JONSVOLLSGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 769,
      "placeId": "ChIJeSClHAP8PEYRntTGqrMpemg",
      "placeName": "Malinka",
      "coordinates": "60.3926674,5.3179451",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 160371290,
    "accountNumber": "35302464616",
    "date": "2017-02-02",
    "amount": "-100.00",
    "text": "Vitusapotek Sartorvegen Straume",
    "alfareferanse": "01.02 VITUSAPOTEK SARTORVEGEN  STRAUME",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 770,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 160709233,
    "accountNumber": "35302464616",
    "date": "2017-02-01",
    "amount": "-129.10",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 30.01 NOK 129.10 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 161423483,
    "accountNumber": "35302464616",
    "date": "2017-01-31",
    "amount": "-932.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 161601479,
    "accountNumber": "35302464616",
    "date": "2017-01-31",
    "amount": "-84.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         42 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 163043747,
    "accountNumber": "35302464616",
    "date": "2017-01-27",
    "amount": "-168.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "26.01 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 162844010,
    "accountNumber": "35302464616",
    "date": "2017-01-27",
    "amount": "-70.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "26.01 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 163942550,
    "accountNumber": "35302464616",
    "date": "2017-01-25",
    "amount": "-159.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 23.01 NOK 159.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 164041369,
    "accountNumber": "35302464616",
    "date": "2017-01-25",
    "amount": "-304.00",
    "text": "Minde Pizzeria Conrad Mohrs Bergen",
    "alfareferanse": "24.01 MINDE PIZZERIA  CONRAD MOHRS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 771,
      "placeId": "ChIJiWlcF2r5PEYRg4KMipEnYxA",
      "placeName": "Minde Pizzeria",
      "coordinates": "60.3577426,5.3430312",
      "website": "http://www.mindepizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 163798368,
    "accountNumber": "35302464616",
    "date": "2017-01-25",
    "amount": "-517.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 25.01.17",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 164249396,
    "accountNumber": "35302464616",
    "date": "2017-01-24",
    "amount": "-549.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 22.01 NOK 549.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 164371272,
    "accountNumber": "35302464616",
    "date": "2017-01-24",
    "amount": "-210.70",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "23.01 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165090134,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-54.20",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "23.01 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165268297,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-74.00",
    "text": "Narvesen 773 Vestkanten Loddefjord",
    "alfareferanse": "21.01 NARVESEN 773 VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 665,
      "placeId": "ChIJo0lIzQz7PEYRqzT5uxwr47s",
      "placeName": "Narvesen",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 164680966,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-315.21",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "22.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 164941721,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-418.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "22.01 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165282753,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-254.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "21.01 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165033685,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-4988.00",
    "text": "Krav Fra Faktnr. 1",
    "alfareferanse": "Krav fra faktnr. 1585421",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1456,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 164588517,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-260.00",
    "text": "Biljarden Kong Oscarsg 5017 Bergen",
    "alfareferanse": "22.01 BILJARDEN KONG OSCARSG 5017 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 772,
      "placeId": "ChIJ6YS4NK_-PEYROKn2WHph1Yc",
      "placeName": "King Oscar",
      "coordinates": "60.3920634,5.3311497",
      "website": "http://kongoscar.no/",
      "logoId": 145,
      "merchantName": "King Oscar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 164942021,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-130.00",
    "text": "Biljarden Kong Oscarsg 5017 Bergen",
    "alfareferanse": "22.01 BILJARDEN KONG OSCARSG 5017 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 772,
      "placeId": "ChIJ6YS4NK_-PEYROKn2WHph1Yc",
      "placeName": "King Oscar",
      "coordinates": "60.3920634,5.3311497",
      "website": "http://kongoscar.no/",
      "logoId": 145,
      "merchantName": "King Oscar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165124362,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-400.00",
    "text": "Biljarden Kong Oscarsg 5017 Bergen",
    "alfareferanse": "22.01 BILJARDEN KONG OSCARSG 5017 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 772,
      "placeId": "ChIJ6YS4NK_-PEYROKn2WHph1Yc",
      "placeName": "King Oscar",
      "coordinates": "60.3920634,5.3311497",
      "website": "http://kongoscar.no/",
      "logoId": 145,
      "merchantName": "King Oscar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165135775,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-455.00",
    "text": "Biljarden Kong Oscarsg 5017 Bergen",
    "alfareferanse": "22.01 BILJARDEN KONG OSCARSG 5017 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 772,
      "placeId": "ChIJ6YS4NK_-PEYROKn2WHph1Yc",
      "placeName": "King Oscar",
      "coordinates": "60.3920634,5.3311497",
      "website": "http://kongoscar.no/",
      "logoId": 145,
      "merchantName": "King Oscar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165202834,
    "accountNumber": "35302464616",
    "date": "2017-01-23",
    "amount": "-494.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "20.01 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 165600962,
    "accountNumber": "35302464616",
    "date": "2017-01-20",
    "amount": "-99.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 18.01 NOK 99.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 166717289,
    "accountNumber": "35302464616",
    "date": "2017-01-18",
    "amount": "-35.00",
    "text": "Bergen Kommune",
    "alfareferanse": "*0971 16.01 NOK 35.00 Bergen Kommune Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 773,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 167166781,
    "accountNumber": "35302464616",
    "date": "2017-01-17",
    "amount": "-238.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.01 NOK 238.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 167421763,
    "accountNumber": "35302464616",
    "date": "2017-01-17",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.01 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 168094588,
    "accountNumber": "35302464616",
    "date": "2017-01-16",
    "amount": "-202.75",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "13.01 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169210500,
    "accountNumber": "35302464616",
    "date": "2017-01-13",
    "amount": "-448.23",
    "text": "Viasat As",
    "alfareferanse": "Nettgiro til: Viasat AS Betalt: 13.01.17",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kabel-TV/Satellitt",
    "imageId": "",
    "place": {
      "id": 774,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169173559,
    "accountNumber": "35302464616",
    "date": "2017-01-13",
    "amount": "-420.00",
    "text": "Viasat As",
    "alfareferanse": "Nettgiro til: Viasat AS Betalt: 13.01.17",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kabel-TV/Satellitt",
    "imageId": "",
    "place": {
      "id": 774,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 168810934,
    "accountNumber": "35302464616",
    "date": "2017-01-13",
    "amount": "-100.00",
    "text": "Jondalstunnelen",
    "alfareferanse": "Nettgiro til: Jondalstunnelen Betalt: 13.01.17",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 775,
      "placeId": "ChIJyYZ4Qy87PEYRA8KmsjUZHlE",
      "placeName": "Jondalstunnelen",
      "coordinates": "60.1968197,6.2606728",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169000301,
    "accountNumber": "35302464616",
    "date": "2017-01-13",
    "amount": "-517.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 13.01.17",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169668351,
    "accountNumber": "35302464616",
    "date": "2017-01-12",
    "amount": "-136.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "11.01 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169322055,
    "accountNumber": "35302464616",
    "date": "2017-01-12",
    "amount": "-262.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "11.01 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169685418,
    "accountNumber": "35302464616",
    "date": "2017-01-12",
    "amount": "-375.08",
    "text": "Søstrene Grene Torgallmenni Bergen",
    "alfareferanse": "11.01 SØSTRENE GRENE  TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 734,
      "placeId": "ChIJE9vOQqj-PEYRTFD5oJlvttk",
      "placeName": "Søstrene Grene",
      "coordinates": "60.392964,5.3260681",
      "website": "https://sostrenegrene.com/",
      "logoId": 130,
      "merchantName": "Søstrene Grene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169413016,
    "accountNumber": "35302464616",
    "date": "2017-01-12",
    "amount": "-83.40",
    "text": "Kremmerhuset 27 Torgalmennin Bergen",
    "alfareferanse": "11.01 KREMMERHUSET 27 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 733,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169556207,
    "accountNumber": "35302464616",
    "date": "2017-01-12",
    "amount": "-429.25",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "11.01 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 170052706,
    "accountNumber": "35302464616",
    "date": "2017-01-11",
    "amount": "-42.00",
    "text": "Fruktbar As Christies Ga Bergen",
    "alfareferanse": "11.01 FRUKTBAR AS CHRISTIES GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 621,
      "placeId": "ChIJX3-Y4QH8PEYRyoAjEDzSLfs",
      "placeName": "Godt Brød Christie Café",
      "coordinates": "60.387474,5.3222432",
      "website": "http://www.godtbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 169806457,
    "accountNumber": "35302464616",
    "date": "2017-01-11",
    "amount": "-157.45",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 09.01 NOK 157.45 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 170086703,
    "accountNumber": "35302464616",
    "date": "2017-01-11",
    "amount": "-210.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 09.01 NOK 210.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 170409893,
    "accountNumber": "35302464616",
    "date": "2017-01-10",
    "amount": "-157.45",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 08.01 NOK 157.45 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 170480981,
    "accountNumber": "35302464616",
    "date": "2017-01-10",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.01 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 170507111,
    "accountNumber": "35302464616",
    "date": "2017-01-10",
    "amount": "-229.85",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "09.01 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 170214945,
    "accountNumber": "35302464616",
    "date": "2017-01-10",
    "amount": "-86.00",
    "text": "Paypal *Makeupmekka",
    "alfareferanse": "*0971 06.01 NOK 86.00 PAYPAL *MAKEUPMEKKA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 757,
      "placeId": "ChIJOYDfs4luQUYRnN7mCciKceQ",
      "placeName": "Makeup Mekka AS",
      "coordinates": "59.925237,10.748984",
      "website": "http://www.makeupmekka.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 171113760,
    "accountNumber": "35302464616",
    "date": "2017-01-09",
    "amount": "-108.50",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "07.01 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 170819934,
    "accountNumber": "35302464616",
    "date": "2017-01-09",
    "amount": "-438.40",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "08.01 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 171327445,
    "accountNumber": "35302464616",
    "date": "2017-01-06",
    "amount": "-202.99",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "05.01 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172166182,
    "accountNumber": "35302464616",
    "date": "2017-01-04",
    "amount": "-101.61",
    "text": "Payments.Lucastel.Com",
    "alfareferanse": "*0971 02.01 EUR 10.97 payments.lucastel.com Kurs: 9.2625",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sport & spill",
    "imageId": "",
    "place": {
      "id": 776,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172224763,
    "accountNumber": "35302464616",
    "date": "2017-01-04",
    "amount": "-75.80",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "04.01 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172269805,
    "accountNumber": "35302464616",
    "date": "2017-01-04",
    "amount": "-112.90",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "04.01 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172328986,
    "accountNumber": "35302464616",
    "date": "2017-01-04",
    "amount": "-202.33",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "03.01 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172493741,
    "accountNumber": "35302464616",
    "date": "2017-01-03",
    "amount": "-109.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 30.12 NOK 109.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172397550,
    "accountNumber": "35302464616",
    "date": "2017-01-03",
    "amount": "-38.20",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "02.01 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172458366,
    "accountNumber": "35302464616",
    "date": "2017-01-03",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*0971 01.01 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172637425,
    "accountNumber": "35302464616",
    "date": "2017-01-03",
    "amount": "-206.38",
    "text": "Paydohreseller.Com",
    "alfareferanse": "*0971 31.12 NOK 206.38 PAYDOHRESELLER.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sport & spill",
    "imageId": "",
    "place": {
      "id": 777,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 172645449,
    "accountNumber": "35302464616",
    "date": "2017-01-03",
    "amount": "-208.30",
    "text": "129 Boots Apotek Loddefjord",
    "alfareferanse": "03.01 129 BOOTS APOTEK LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 698,
      "placeId": "ChIJo0lIzQz7PEYRXzUfwZa7nWA",
      "placeName": "Boots apotek Loddefjord",
      "coordinates": "60.36309,5.233356",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 173346481,
    "accountNumber": "35302464616",
    "date": "2016-12-31",
    "amount": "-50.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         25 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 173439948,
    "accountNumber": "35302464616",
    "date": "2016-12-31",
    "amount": "-2.00",
    "text": "Debetrenter U/Dekning",
    "alfareferanse": "DEBETRENTER U/DEKNING",
    "mainCategory": "Utgifter",
    "category": "Renter",
    "subCategory": "Renter",
    "imageId": "",
    "place": {
      "id": 1367,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 173521695,
    "accountNumber": "35302464616",
    "date": "2016-12-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 173097894,
    "accountNumber": "35302464616",
    "date": "2016-12-30",
    "amount": "-116.79",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "29.12 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 173090159,
    "accountNumber": "35302464616",
    "date": "2016-12-30",
    "amount": "-829.00",
    "text": "Microsoft *Store",
    "alfareferanse": "*0971 27.12 NOK 829.00 MICROSOFT   *STORE Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 778,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 175120062,
    "accountNumber": "35302464616",
    "date": "2016-12-29",
    "amount": "-195.49",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "28.12 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 175732000,
    "accountNumber": "35302464616",
    "date": "2016-12-28",
    "amount": "-935.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 175809726,
    "accountNumber": "35302464616",
    "date": "2016-12-28",
    "amount": "-230.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "28.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 176157265,
    "accountNumber": "35302464616",
    "date": "2016-12-27",
    "amount": "-278.52",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "26.12 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 176317459,
    "accountNumber": "35302464616",
    "date": "2016-12-27",
    "amount": "-76.00",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "26.12 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 176021127,
    "accountNumber": "35302464616",
    "date": "2016-12-27",
    "amount": "-99.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "25.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 175946369,
    "accountNumber": "35302464616",
    "date": "2016-12-27",
    "amount": "-570.00",
    "text": "Mammamia Pizza Hellevn. 277 Bergen",
    "alfareferanse": "26.12 MAMMAMIA PIZZA  HELLEVN. 277 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1287,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 177476575,
    "accountNumber": "35302464616",
    "date": "2016-12-22",
    "amount": "-105.00",
    "text": "Narvesen 702 Krohnåsvn. 1 Rådal",
    "alfareferanse": "21.12 NARVESEN 702 KROHNÅSVN. 1 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 780,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 177304962,
    "accountNumber": "35302464616",
    "date": "2016-12-22",
    "amount": "-299.90",
    "text": "Gamestop Lagune Lagunevn. 1 5239 Rådal",
    "alfareferanse": "21.12 GAMESTOP LAGUNE LAGUNEVN. 1  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 781,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 177327647,
    "accountNumber": "35302464616",
    "date": "2016-12-22",
    "amount": "-190.00",
    "text": "Gamestop Lagune Lagunevn. 1 5239 Rådal",
    "alfareferanse": "21.12 GAMESTOP LAGUNE LAGUNEVN. 1  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 781,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 177500741,
    "accountNumber": "35302464616",
    "date": "2016-12-22",
    "amount": "-258.00",
    "text": "Coop Obs! Lagunevn. 1 5239 Rådal",
    "alfareferanse": "21.12 COOP OBS! LAGUNEVN. 1  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 779,
      "placeId": "ChIJX1fNweb5PEYR87iliMuzS2Q",
      "placeName": "Obs Bygg",
      "coordinates": "60.2952833,5.3288162",
      "website": "https://coop.no/butikker/obs-bygg/lagunen-2268/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 177745713,
    "accountNumber": "35302464616",
    "date": "2016-12-22",
    "amount": "-238.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "21.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 177901574,
    "accountNumber": "35302464616",
    "date": "2016-12-21",
    "amount": "-219.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 19.12 NOK 219.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 178084644,
    "accountNumber": "35302464616",
    "date": "2016-12-21",
    "amount": "-270.70",
    "text": "Vinmonopolet Ra Radøyvegen 1 Manger",
    "alfareferanse": "20.12 VINMONOPOLET RA RADØYVEGEN 1 MANGER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1288,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 177973152,
    "accountNumber": "35302464616",
    "date": "2016-12-21",
    "amount": "-4363.00",
    "text": "Krav Fra Faktnr. 1",
    "alfareferanse": "Krav fra faktnr. 1578361",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1456,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 178972779,
    "accountNumber": "35302464616",
    "date": "2016-12-20",
    "amount": "-107.90",
    "text": "Kiwi 823 Spelha Krokatjønnv. Fyllingsdalen",
    "alfareferanse": "19.12 KIWI 823 SPELHA KROKATJØNNV. FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 526,
      "placeId": "ChIJA6mH5rf7PEYRacLUrZF-r2g",
      "placeName": "Kiwi Spelhaugen",
      "coordinates": "60.3498878,5.2765162",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Spelhaugen/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 179357476,
    "accountNumber": "35302464616",
    "date": "2016-12-19",
    "amount": "-200.02",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "16.12 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 180340419,
    "accountNumber": "35302464616",
    "date": "2016-12-19",
    "amount": "-310.12",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "18.12 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 179360352,
    "accountNumber": "35302464616",
    "date": "2016-12-19",
    "amount": "-800.00",
    "text": "Egon Lagunen Lagunevn.1 Rådal",
    "alfareferanse": "16.12 EGON LAGUNEN LAGUNEVN.1 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1414,
      "placeId": "ChIJX1fNweb5PEYRR4pVpo0nI0M",
      "placeName": "Egon Lagunen",
      "coordinates": "60.2962118,5.3300405",
      "website": "http://www.egon.no/restauranter/lagunen",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 179899834,
    "accountNumber": "35302464616",
    "date": "2016-12-19",
    "amount": "-399.00",
    "text": "Platekompaniet Laguneveien Rådal",
    "alfareferanse": "16.12 PLATEKOMPANIET  LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Spill/musikk",
    "imageId": "",
    "place": {
      "id": 1289,
      "placeId": "ChIJX1fNweb5PEYRwcWows4dqbU",
      "placeName": "Platekompaniet AS",
      "coordinates": "60.2968658,5.3311507",
      "website": "https://www.platekompaniet.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 180656479,
    "accountNumber": "35302464616",
    "date": "2016-12-16",
    "amount": "-144.60",
    "text": "Coop Obs! Lagunevn. 1 5239 Rådal",
    "alfareferanse": "15.12 COOP OBS! LAGUNEVN. 1  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 779,
      "placeId": "ChIJX1fNweb5PEYR87iliMuzS2Q",
      "placeName": "Obs Bygg",
      "coordinates": "60.2952833,5.3288162",
      "website": "https://coop.no/butikker/obs-bygg/lagunen-2268/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 180806056,
    "accountNumber": "35302464616",
    "date": "2016-12-16",
    "amount": "-55.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.12 NOK 55.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 181063537,
    "accountNumber": "35302464616",
    "date": "2016-12-16",
    "amount": "-184.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.12 NOK 184.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 180516804,
    "accountNumber": "35302464616",
    "date": "2016-12-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.12 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 180722316,
    "accountNumber": "35302464616",
    "date": "2016-12-16",
    "amount": "-215.00",
    "text": "Mcdonald's Lagunen Lagunevn. 1 Rådal",
    "alfareferanse": "15.12 MCD 059 LAGUNEN LAGUNEVN. 1  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 618,
      "placeId": "ChIJIQ84ueb5PEYRlrLJ0dXH2IE",
      "placeName": "Apotek 1",
      "coordinates": "60.296531,5.330386",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/lagunen-13",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 181413123,
    "accountNumber": "35302464616",
    "date": "2016-12-15",
    "amount": "-469.50",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.12 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 181195909,
    "accountNumber": "35302464616",
    "date": "2016-12-15",
    "amount": "-189.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 13.12 NOK 189.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 182934699,
    "accountNumber": "35302464616",
    "date": "2016-12-13",
    "amount": "-20.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 08.12 NOK 20.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183656090,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-400.04",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "10.12 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183876787,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-194.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "09.12 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183382172,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-164.62",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "10.12 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183727197,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-645.00",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "11.12 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183714383,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-313.48",
    "text": "Hygienisk Rens Og Vask Vestkanten",
    "alfareferanse": "10.12 HYGIENISK RENS  OG VASK VESTKANTEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Renseri",
    "imageId": "",
    "place": {
      "id": 638,
      "placeId": "ChIJo0lIzQz7PEYRsPMdrTYRcVU",
      "placeName": "Hygienic Wash & Cleaning",
      "coordinates": "60.3632227,5.2336255",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183191669,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-162.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "11.12 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183743704,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-218.00",
    "text": "Spar Kjøp As Strandgt 17 Bergen",
    "alfareferanse": "09.12 SPAR KJØP AS STRANDGT 17  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 782,
      "placeId": "ChIJ2aaKsgL8PEYRUsgYWLRaAPg",
      "placeName": "Spar Kjøp Bergen Sentrum",
      "coordinates": "60.3930955,5.3287018",
      "website": "https://www.sparkjop.no/",
      "logoId": 146,
      "merchantName": "Spar Kjøp",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183506595,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-39.00",
    "text": "Waynes Xhibithi Småstrandgat Bergen",
    "alfareferanse": "09.12 WAYNES XHIBITHI SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 783,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183654049,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-200.00",
    "text": "Nokas Narvesen Ole Bull 5012 Bergen",
    "alfareferanse": "11.12 Nokas Narvesen Ole Bull 5012 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 784,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183895523,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-91.00",
    "text": "Nye Kristiansan",
    "alfareferanse": "Nettgiro til: Nye Kristiansan Betalt: 12.12.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 785,
      "placeId": "ChIJtxAPtEMCOEYREL4ZXdhX1cw",
      "placeName": "Nye Veier",
      "coordinates": "58.1464506,8.0109966",
      "website": "http://www.nyeveier.no/",
      "logoId": 147,
      "merchantName": "Nye Veier",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 183999831,
    "accountNumber": "35302464616",
    "date": "2016-12-12",
    "amount": "-596.00",
    "text": "Bergen Bompenge",
    "alfareferanse": "Nettgiro til: Bergen Bompenge Betalt: 12.12.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 1290,
      "placeId": "ChIJheVQtUX5PEYRoLGjxDF1E90",
      "placeName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "coordinates": "60.3677117,5.3417854",
      "website": "http://www.bomringenbergen.no/",
      "logoId": 266,
      "merchantName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 184811220,
    "accountNumber": "35302464616",
    "date": "2016-12-09",
    "amount": "-408.80",
    "text": "27 Plantasjen Åsamyrane 32 Nyborg",
    "alfareferanse": "08.12 27 PLANTASJEN ÅSAMYRANE 32 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Hagesenter",
    "imageId": "",
    "place": {
      "id": 641,
      "placeId": "ChIJB01zzP8BPUYRoT3m9BfwoII",
      "placeName": "Plantasjen",
      "coordinates": "60.4767896,5.3452213",
      "website": "https://www.plantasjen.no/storelocator/bergen-asane",
      "logoId": 54,
      "merchantName": "Plantasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 184798664,
    "accountNumber": "35302464616",
    "date": "2016-12-09",
    "amount": "-249.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.12 NOK 249.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 184410434,
    "accountNumber": "35302464616",
    "date": "2016-12-09",
    "amount": "-129.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.12 NOK 129.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 184604495,
    "accountNumber": "35302464616",
    "date": "2016-12-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.12 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 184878757,
    "accountNumber": "35302464616",
    "date": "2016-12-08",
    "amount": "-49.00",
    "text": "Www.Viaplay.No",
    "alfareferanse": "*0971 06.12 NOK 49.00 WWW.VIAPLAY.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 681,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 185104642,
    "accountNumber": "35302464616",
    "date": "2016-12-08",
    "amount": "-138.00",
    "text": "Paypal *Makeupmekka",
    "alfareferanse": "*0971 05.12 NOK 138.00 PAYPAL *MAKEUPMEKKA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 757,
      "placeId": "ChIJOYDfs4luQUYRnN7mCciKceQ",
      "placeName": "Makeup Mekka AS",
      "coordinates": "59.925237,10.748984",
      "website": "http://www.makeupmekka.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 185774396,
    "accountNumber": "35302464616",
    "date": "2016-12-06",
    "amount": "-225.49",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "05.12 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 186449777,
    "accountNumber": "35302464616",
    "date": "2016-12-05",
    "amount": "-133.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "05.12 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 186653647,
    "accountNumber": "35302464616",
    "date": "2016-12-05",
    "amount": "-143.00",
    "text": "Deli De Luca En Engen 10 Bergen",
    "alfareferanse": "03.12 DELI DE LUCA EN ENGEN 10 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 596,
      "placeId": "ChIJpVeEigL8PEYRge9-UXmATTA",
      "placeName": "Deli de Luca Engen",
      "coordinates": "60.3917507,5.3206748",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 186280869,
    "accountNumber": "35302464616",
    "date": "2016-12-05",
    "amount": "-254.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "04.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 186815352,
    "accountNumber": "35302464616",
    "date": "2016-12-05",
    "amount": "-254.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "04.12 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 187417684,
    "accountNumber": "35302464616",
    "date": "2016-12-02",
    "amount": "-433.00",
    "text": "Dolly Dimples S Sandviksv.44 Bergen",
    "alfareferanse": "01.12 DOLLY DIMPLES S SANDVIKSV.44 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 786,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 187765554,
    "accountNumber": "35302464616",
    "date": "2016-12-01",
    "amount": "-3825.59",
    "text": "Bkk Kundetjenes",
    "alfareferanse": "Nettgiro til: BKK Kundetjenes Betalt: 01.12.16",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Elektrisitet",
    "imageId": "",
    "place": {
      "id": 787,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 187607470,
    "accountNumber": "35302464616",
    "date": "2016-12-01",
    "amount": "-43.00",
    "text": "Kvam B As",
    "alfareferanse": "Nettgiro til: Kvam B AS Betalt: 01.12.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 788,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 187802263,
    "accountNumber": "35302464616",
    "date": "2016-12-01",
    "amount": "-50.00",
    "text": "Fyrkløvern Ab",
    "alfareferanse": "Nettgiro til: Fyrkløvern AB Betalt: 01.12.16",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1415,
      "placeId": "ChIJAYzJS4hSUkYRe3EciGnSezA",
      "placeName": "Fyrkløvern Ab",
      "coordinates": "55.7240867,12.5779806",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 187569668,
    "accountNumber": "35302464616",
    "date": "2016-12-01",
    "amount": "-77.00",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "30.11 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 187586496,
    "accountNumber": "35302464616",
    "date": "2016-12-01",
    "amount": "-577.00",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "30.11 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 188573642,
    "accountNumber": "35302464616",
    "date": "2016-11-30",
    "amount": "-84.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         42 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 188838811,
    "accountNumber": "35302464616",
    "date": "2016-11-30",
    "amount": "-814.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 188623861,
    "accountNumber": "35302464616",
    "date": "2016-11-30",
    "amount": "-110.00",
    "text": "Qpark As",
    "alfareferanse": "*0971 28.11 NOK 110.00 QPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 789,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 188902163,
    "accountNumber": "35302464616",
    "date": "2016-11-30",
    "amount": "-2.00",
    "text": "Sms-Bank Forespørsel Forfall",
    "alfareferanse": "SMS-BANK FORESPØRSEL FORFALL        1 TRANS(ER) TYPE 469 106",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 791,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 188935233,
    "accountNumber": "35302464616",
    "date": "2016-11-29",
    "amount": "-403.00",
    "text": "Paypal *Makeupmekka",
    "alfareferanse": "*0971 26.11 NOK 403.00 PAYPAL *MAKEUPMEKKA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 757,
      "placeId": "ChIJOYDfs4luQUYRnN7mCciKceQ",
      "placeName": "Makeup Mekka AS",
      "coordinates": "59.925237,10.748984",
      "website": "http://www.makeupmekka.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189364814,
    "accountNumber": "35302464616",
    "date": "2016-11-29",
    "amount": "-22.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 25.11 NOK 22.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189551826,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-712.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 24.11 NOK 712.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190217848,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-749.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 24.11 NOK 749.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189440864,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-36.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "28.11 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189588468,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-236.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.11 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189715828,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-91.00",
    "text": "Tgr Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.11 TGR OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 642,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189898091,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-73.79",
    "text": "Nille 298 F.Bernad.V52 Fyllingsdalen",
    "alfareferanse": "25.11 NILLE 298 F.BERNAD.V52 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 639,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190120308,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-259.29",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.11 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189804015,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-319.80",
    "text": "Br-Leker F2336 Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.11 BR-LEKER F2336  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 588,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190076259,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-175.00",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "26.11 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190180316,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-111.75",
    "text": "Kitchn Fyllings Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.11 KITCHN FYLLINGS FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 792,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 189771396,
    "accountNumber": "35302464616",
    "date": "2016-11-28",
    "amount": "-397.00",
    "text": "Mammamia Pizza Hellevn. 277 Bergen",
    "alfareferanse": "26.11 MAMMAMIA PIZZA  HELLEVN. 277 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1287,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190898017,
    "accountNumber": "35302464616",
    "date": "2016-11-25",
    "amount": "-35.00",
    "text": "Bergen Kommune",
    "alfareferanse": "*0971 23.11 NOK 35.00 Bergen Kommune Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 773,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190741065,
    "accountNumber": "35302464616",
    "date": "2016-11-25",
    "amount": "-303.40",
    "text": "Shell Lagunepar Laguneveien Rådal",
    "alfareferanse": "24.11 SHELL LAGUNEPAR LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 640,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190549389,
    "accountNumber": "35302464616",
    "date": "2016-11-25",
    "amount": "-261.40",
    "text": "Extra Skareveien 1 Mathopen",
    "alfareferanse": "24.11 EXTRA SKAREVEIEN 1 MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 465,
      "placeId": "ChIJM76lv-b6PEYRg29QdF3BMSs",
      "placeName": "EXTRA",
      "coordinates": "60.3336941,5.2118852",
      "website": "https://coop.no/butikker/extra/mathopen-4345/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 190966193,
    "accountNumber": "35302464616",
    "date": "2016-11-24",
    "amount": "90.00",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*1018 22.11 NOK 90.00 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 191641976,
    "accountNumber": "35302464616",
    "date": "2016-11-23",
    "amount": "-35.00",
    "text": "Bergen Kommune",
    "alfareferanse": "*0971 21.11 NOK 35.00 Bergen Kommune Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 773,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 191982348,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-27.00",
    "text": "A & Jc Matglede C. Sundts Gt Bergen",
    "alfareferanse": "21.11 A & JC MATGLEDE C. SUNDTS GT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 794,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 191961154,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-99.98",
    "text": "Søstrene Grene Torgallmenni Bergen",
    "alfareferanse": "21.11 SØSTRENE GRENE  TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 734,
      "placeId": "ChIJE9vOQqj-PEYRTFD5oJlvttk",
      "placeName": "Søstrene Grene",
      "coordinates": "60.392964,5.3260681",
      "website": "https://sostrenegrene.com/",
      "logoId": 130,
      "merchantName": "Søstrene Grene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 191771281,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-21.90",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "21.11 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 191879705,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-135.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "21.11 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192183194,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-298.00",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "21.11 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192125485,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-42.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "21.11 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192221212,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 18.11 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 191995753,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 20.11 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192243655,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 19.11 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 191966382,
    "accountNumber": "35302464616",
    "date": "2016-11-22",
    "amount": "-135.65",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "21.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192645257,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-376.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "19.11 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192814499,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-200.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "19.11 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193081850,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-31.90",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "20.11 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192370710,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-100.06",
    "text": "Shell 7-Eleven Åsmyrane.167 Nyborg",
    "alfareferanse": "18.11 SHELL 7-ELEVEN  ÅSMYRANE.167 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 645,
      "placeId": "ChIJ1aFVCcI-TUYRSERG0ggU8Q4",
      "placeName": "7-Eleven",
      "coordinates": "55.3037836,10.7819461",
      "website": "http://www.7-eleven.dk/",
      "logoId": 101,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192946533,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-1733.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "18.11 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193150019,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 17.11 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192599052,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-93.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 17.11 NOK 93.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192630031,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "20.11 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192370114,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-316.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "19.11 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193204423,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-270.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "18.11 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193106884,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-20.00",
    "text": "Nord-Jæren Bomp",
    "alfareferanse": "Nettgiro til: NORD-JÆREN BOMP Betalt: 21.11.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 1291,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192610473,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-517.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 21.11.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193330197,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-4363.00",
    "text": "Krav Fra Faktnr. 1",
    "alfareferanse": "Krav fra faktnr. 1572151",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1456,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192749699,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-259.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "20.11 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193102049,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-374.50",
    "text": "7 - Eleven 7097 Christiesgt Bergen",
    "alfareferanse": "19.11 7 - ELEVEN 7097 CHRISTIESGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 694,
      "placeId": "ChIJxdLM8an-PEYRblSEiJkkpGI",
      "placeName": "7-Eleven Christiesgate",
      "coordinates": "60.3893981,5.323395",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192645589,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-289.99",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "19.11 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192318563,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-150.50",
    "text": "Apotek 1,Arken Åsane Senter Ulset",
    "alfareferanse": "18.11 APOTEK 1,ARKEN  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 795,
      "placeId": "ChIJRcs1kfr9PEYRb1B_Au3QQX4",
      "placeName": "Apotek 1",
      "coordinates": "60.4657271,5.3244879",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/arken-bergen-697",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192878308,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-14.00",
    "text": "Haugalandspakke",
    "alfareferanse": "Nettgiro til: HAUGALANDSPAKKE Betalt: 21.11.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 796,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 192894380,
    "accountNumber": "35302464616",
    "date": "2016-11-21",
    "amount": "-35.00",
    "text": "Bergen Kommune",
    "alfareferanse": "*0971 17.11 NOK 35.00 Bergen Kommune Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 773,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193551003,
    "accountNumber": "35302464616",
    "date": "2016-11-18",
    "amount": "-549.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 16.11 NOK 549.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 193874505,
    "accountNumber": "35302464616",
    "date": "2016-11-18",
    "amount": "-169.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 16.11 NOK 169.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 194068909,
    "accountNumber": "35302464616",
    "date": "2016-11-17",
    "amount": "-259.32",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "17.11 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 194233008,
    "accountNumber": "35302464616",
    "date": "2016-11-17",
    "amount": "-30.00",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "17.11 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 194470209,
    "accountNumber": "35302464616",
    "date": "2016-11-17",
    "amount": "-502.00",
    "text": "Dolly Dimples S Sandviksv.44 Bergen",
    "alfareferanse": "16.11 DOLLY DIMPLES S SANDVIKSV.44 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 786,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 194520695,
    "accountNumber": "35302464616",
    "date": "2016-11-16",
    "amount": "-417.00",
    "text": "Body Shop Galle Torgalmennin Bergen",
    "alfareferanse": "16.11 BODY SHOP GALLE TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 798,
      "placeId": "ChIJr9Wraqj-PEYR-i8U2vM4KiA",
      "placeName": "The Body Shop",
      "coordinates": "60.3920905,5.3238146",
      "website": "https://www.thebodyshop.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 194636419,
    "accountNumber": "35302464616",
    "date": "2016-11-16",
    "amount": "-35.00",
    "text": "Bergen Kommune",
    "alfareferanse": "*0971 14.11 NOK 35.00 Bergen Kommune Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 773,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 194592785,
    "accountNumber": "35302464616",
    "date": "2016-11-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.11 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 195294103,
    "accountNumber": "35302464616",
    "date": "2016-11-15",
    "amount": "-151.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 13.11 NOK 151.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 195333370,
    "accountNumber": "35302464616",
    "date": "2016-11-15",
    "amount": "-33.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "14.11 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 195588998,
    "accountNumber": "35302464616",
    "date": "2016-11-15",
    "amount": "-520.93",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "14.11 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 195421362,
    "accountNumber": "35302464616",
    "date": "2016-11-15",
    "amount": "-45.00",
    "text": "Narvesen 773 Vestkanten Loddefjord",
    "alfareferanse": "14.11 NARVESEN 773 VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 665,
      "placeId": "ChIJo0lIzQz7PEYRqzT5uxwr47s",
      "placeName": "Narvesen",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 195572231,
    "accountNumber": "35302464616",
    "date": "2016-11-15",
    "amount": "-350.00",
    "text": "Klarna Wish.Com",
    "alfareferanse": "*0971 12.11 NOK 350.00 KLARNA WISH.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 767,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 195370955,
    "accountNumber": "35302464616",
    "date": "2016-11-15",
    "amount": "-398.00",
    "text": "Europris Vestka Lyderhornv. Loddefjord",
    "alfareferanse": "14.11 EUROPRIS VESTKA LYDERHORNV.  LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 799,
      "placeId": "ChIJVRxLtAz7PEYRPf1x7TyPAw8",
      "placeName": "Europris Vestkanten",
      "coordinates": "60.3632301,5.2353441",
      "website": "https://www.europris.no/vaare-butikker/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 195073217,
    "accountNumber": "35302464616",
    "date": "2016-11-15",
    "amount": "-522.00",
    "text": "Dolly Dimple's Lyderhornsve Loddefjord",
    "alfareferanse": "14.11 DOLLY DIMPLE'S  LYDERHORNSVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 709,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 196306833,
    "accountNumber": "35302464616",
    "date": "2016-11-14",
    "amount": "-1000.00",
    "text": "Egon Åsane Myrdalsvegen Nyborg",
    "alfareferanse": "11.11 EGON ÅSANE MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 800,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 196507134,
    "accountNumber": "35302464616",
    "date": "2016-11-14",
    "amount": "-200.02",
    "text": "Shell 7-Eleven Åsmyrane.167 Nyborg",
    "alfareferanse": "11.11 SHELL 7-ELEVEN  ÅSMYRANE.167 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 645,
      "placeId": "ChIJ1aFVCcI-TUYRSERG0ggU8Q4",
      "placeName": "7-Eleven",
      "coordinates": "55.3037836,10.7819461",
      "website": "http://www.7-eleven.dk/",
      "logoId": 101,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 196149967,
    "accountNumber": "35302464616",
    "date": "2016-11-14",
    "amount": "-72.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "14.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 197752314,
    "accountNumber": "35302464616",
    "date": "2016-11-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.11 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 197835882,
    "accountNumber": "35302464616",
    "date": "2016-11-09",
    "amount": "-407.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "08.11 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 197916249,
    "accountNumber": "35302464616",
    "date": "2016-11-09",
    "amount": "-279.00",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "08.11 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 198354140,
    "accountNumber": "35302464616",
    "date": "2016-11-08",
    "amount": "-25.00",
    "text": "Lister Bompenge",
    "alfareferanse": "Nettgiro til: Lister Bompenge Betalt: 08.11.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 801,
      "placeId": "ChIJw70_pqN_N0YRWFrTOQrxWPg",
      "placeName": "Lister Bompengeselskap AS",
      "coordinates": "58.1386106,7.068916",
      "website": "http://www.listerbompengeselskap.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 198357607,
    "accountNumber": "35302464616",
    "date": "2016-11-08",
    "amount": "-86.00",
    "text": "Kvam B As",
    "alfareferanse": "Nettgiro til: Kvam B AS Betalt: 08.11.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 788,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 198851985,
    "accountNumber": "35302464616",
    "date": "2016-11-07",
    "amount": "-324.89",
    "text": "Klarna Wish.Com",
    "alfareferanse": "*0971 03.11 NOK 324.89 KLARNA WISH.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 767,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 198845780,
    "accountNumber": "35302464616",
    "date": "2016-11-07",
    "amount": "-194.73",
    "text": "Uno-X Fjoesange R 0727 Bergen",
    "alfareferanse": "05.11 UNO-X FJOESANGE R 0727 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 760,
      "placeId": "ChIJ2-aGK275PEYRIRChH9mFokc",
      "placeName": "Uno-X Fjøsanger",
      "coordinates": "60.3476207,5.33622",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 199329382,
    "accountNumber": "35302464616",
    "date": "2016-11-07",
    "amount": "-153.30",
    "text": "Eurospar Isdals . Isdalstø",
    "alfareferanse": "05.11 EUROSPAR ISDALS . ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 802,
      "placeId": "ChIJPX-a4HEDPUYRFpkyPmCiS2Y",
      "placeName": "Eurospar Isdalstø",
      "coordinates": "60.5559052,5.2701894",
      "website": "https://spar.no/Finn-butikk/#?q=7080003294531",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 199500392,
    "accountNumber": "35302464616",
    "date": "2016-11-04",
    "amount": "-328.70",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*0971 02.11 NOK 328.70 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 199429667,
    "accountNumber": "35302464616",
    "date": "2016-11-04",
    "amount": "-73.70",
    "text": "Extra Skareveien 1 Mathopen",
    "alfareferanse": "04.11 EXTRA SKAREVEIEN 1 MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 465,
      "placeId": "ChIJM76lv-b6PEYRg29QdF3BMSs",
      "placeName": "EXTRA",
      "coordinates": "60.3336941,5.2118852",
      "website": "https://coop.no/butikker/extra/mathopen-4345/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 199654165,
    "accountNumber": "35302464616",
    "date": "2016-11-04",
    "amount": "-302.98",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "04.11 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200101226,
    "accountNumber": "35302464616",
    "date": "2016-11-03",
    "amount": "-347.00",
    "text": "Klarna Wish.Com",
    "alfareferanse": "*0971 01.11 NOK 347.00 KLARNA WISH.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 767,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200092506,
    "accountNumber": "35302464616",
    "date": "2016-11-03",
    "amount": "-324.10",
    "text": "Klarna Wish.Com",
    "alfareferanse": "*0971 01.11 NOK 324.10 KLARNA WISH.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 767,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200105543,
    "accountNumber": "35302464616",
    "date": "2016-11-03",
    "amount": "-308.00",
    "text": "Klarna Wish.Com",
    "alfareferanse": "*0971 01.11 NOK 308.00 KLARNA WISH.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 767,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 199983588,
    "accountNumber": "35302464616",
    "date": "2016-11-03",
    "amount": "-42.00",
    "text": "Panduro Småstrandgt Bergen",
    "alfareferanse": "03.11 PANDURO SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 803,
      "placeId": "ChIJt8K0XKj-PEYRW-QBe-h4eXE",
      "placeName": "Panduro hobby Xhibition",
      "coordinates": "60.3927195,5.3262175",
      "website": "http://www.pandurohobby.no/",
      "logoId": 154,
      "merchantName": "Panduro hobby",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 199822686,
    "accountNumber": "35302464616",
    "date": "2016-11-03",
    "amount": "-380.00",
    "text": "Årstad Pizzeria Bjørnsons Ga Bergen",
    "alfareferanse": "02.11 ÅRSTAD PIZZERIA BJØRNSONS GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 700,
      "placeId": "ChIJOxdIJk_5PEYRWJBPFscXGyI",
      "placeName": "Årstad Pizzeria",
      "coordinates": "60.373177,5.3439855",
      "website": "https://www.just-eat.no/restaurants-arstad-pizza",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200522409,
    "accountNumber": "35302464616",
    "date": "2016-11-02",
    "amount": "-107.90",
    "text": "Kiwi 826 Allehelgensg Bergen",
    "alfareferanse": "01.11 KIWI 826 ALLEHELGENSG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 804,
      "placeId": "ChIJo87wi6j-PEYRuAcLPuiYQIs",
      "placeName": "KIWI Allehelgensgate",
      "coordinates": "60.3934049,5.3277785",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Allehelgensgate/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200296983,
    "accountNumber": "35302464616",
    "date": "2016-11-02",
    "amount": "-298.00",
    "text": "Spar Kjøp As Strandgt 17 Bergen",
    "alfareferanse": "01.11 SPAR KJØP AS STRANDGT 17  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 782,
      "placeId": "ChIJ2aaKsgL8PEYRUsgYWLRaAPg",
      "placeName": "Spar Kjøp Bergen Sentrum",
      "coordinates": "60.3930955,5.3287018",
      "website": "https://www.sparkjop.no/",
      "logoId": 146,
      "merchantName": "Spar Kjøp",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200504138,
    "accountNumber": "35302464616",
    "date": "2016-11-02",
    "amount": "-78.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "01.11 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200448113,
    "accountNumber": "35302464616",
    "date": "2016-11-02",
    "amount": "-15.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "01.11 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200386225,
    "accountNumber": "35302464616",
    "date": "2016-11-02",
    "amount": "-141.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "01.11 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200775806,
    "accountNumber": "35302464616",
    "date": "2016-11-01",
    "amount": "-399.10",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "31.10 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 200767115,
    "accountNumber": "35302464616",
    "date": "2016-11-01",
    "amount": "-173.17",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "31.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201843630,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-90.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "31.10 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201513401,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-1333.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201436990,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-100.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         50 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 575,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 202020280,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201298290,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-44.80",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "28.10 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201876313,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-200.00",
    "text": "Nokas 7-Eleven Christie 5015 Bergen",
    "alfareferanse": "30.10 Nokas 7-Eleven Christie 5015 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 542,
      "placeId": "ChIJxdLM8an-PEYRblSEiJkkpGI",
      "placeName": "7-Eleven Christiesgate",
      "coordinates": "60.3893981,5.323395",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201267258,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-150.00",
    "text": "Upstairs As Vestre Torvg Bergen",
    "alfareferanse": "30.10 UPSTAIRS AS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 562,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201810432,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-30.00",
    "text": "Fincken - Det L Nygårdsgt. 2 Bergen",
    "alfareferanse": "30.10 FINCKEN - DET L NYGÅRDSGT. 2 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 805,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201282905,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-410.00",
    "text": "Dolly Dimple's Lyderhornsve Loddefjord",
    "alfareferanse": "30.10 DOLLY DIMPLE'S  LYDERHORNSVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 709,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 201779097,
    "accountNumber": "35302464616",
    "date": "2016-10-31",
    "amount": "-220.00",
    "text": "Musikkhuset As Christiesgt. Bergen",
    "alfareferanse": "30.10 MUSIKKHUSET AS  CHRISTIESGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 726,
      "placeId": "ChIJzeH57an-PEYRUmWf2RueF0k",
      "placeName": "Musikkhuset AS",
      "coordinates": "60.389573,5.3240131",
      "website": "http://garage.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 202631941,
    "accountNumber": "35302464616",
    "date": "2016-10-28",
    "amount": "-217.70",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "27.10 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 203473611,
    "accountNumber": "35302464616",
    "date": "2016-10-26",
    "amount": "-155.15",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "26.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 203589655,
    "accountNumber": "35302464616",
    "date": "2016-10-26",
    "amount": "-199.20",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 24.10 NOK 199.20 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204845784,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-150.08",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "21.10 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204266735,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-149.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "21.10 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204494601,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-160.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "24.10 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204791974,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-159.00",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "21.10 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204155756,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-327.60",
    "text": "Bunnpris Ulveda Ulvedalen 5 Flaktveit",
    "alfareferanse": "23.10 BUNNPRIS ULVEDA ULVEDALEN 5  FLAKTVEIT",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 748,
      "placeId": "ChIJeSlWRhr-PEYRPn7QA7VLd5E",
      "placeName": "Bunnpris Ulvedalen",
      "coordinates": "60.4648761,5.3621029",
      "website": "http://bunnpris.no/butikker/bunnpris-ulvedalen",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204929484,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-148.90",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "22.10 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204217401,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-59.90",
    "text": "H&M No0849 Åsane Senter Ulset",
    "alfareferanse": "22.10 H&M NO0849 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 807,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204648602,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-109.00",
    "text": "Panduro Åsane Senter Ulset",
    "alfareferanse": "22.10 PANDURO ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 808,
      "placeId": "ChIJ-9E2lvr9PEYRfXMAw8OkAks",
      "placeName": "Panduro hobby",
      "coordinates": "60.4656398,5.3223496",
      "website": "http://panduro.com/nb-no/",
      "logoId": 155,
      "merchantName": "Panduro hobby",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204492715,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-700.00",
    "text": "Coop Obs! Lagunevn. 1 5239 Rådal",
    "alfareferanse": "21.10 COOP OBS! LAGUNEVN. 1  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 779,
      "placeId": "ChIJX1fNweb5PEYR87iliMuzS2Q",
      "placeName": "Obs Bygg",
      "coordinates": "60.2952833,5.3288162",
      "website": "https://coop.no/butikker/obs-bygg/lagunen-2268/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 204141748,
    "accountNumber": "35302464616",
    "date": "2016-10-24",
    "amount": "-516.00",
    "text": "Mammamia Pizza Hellevn. 277 Bergen",
    "alfareferanse": "23.10 MAMMAMIA PIZZA  HELLEVN. 277 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1287,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 205327293,
    "accountNumber": "35302464616",
    "date": "2016-10-21",
    "amount": "-129.00",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "20.10 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 205366203,
    "accountNumber": "35302464616",
    "date": "2016-10-21",
    "amount": "-577.90",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "20.10 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 205241813,
    "accountNumber": "35302464616",
    "date": "2016-10-21",
    "amount": "-4363.00",
    "text": "Krav Fra Faktnr. 8",
    "alfareferanse": "Krav fra faktnr. 1564998",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1457,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 205147529,
    "accountNumber": "35302464616",
    "date": "2016-10-21",
    "amount": "-178.00",
    "text": "Panduro Småstrandgt Bergen",
    "alfareferanse": "20.10 PANDURO SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 803,
      "placeId": "ChIJt8K0XKj-PEYRW-QBe-h4eXE",
      "placeName": "Panduro hobby Xhibition",
      "coordinates": "60.3927195,5.3262175",
      "website": "http://www.pandurohobby.no/",
      "logoId": 154,
      "merchantName": "Panduro hobby",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 205512603,
    "accountNumber": "35302464616",
    "date": "2016-10-21",
    "amount": "-293.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "20.10 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 205375794,
    "accountNumber": "35302464616",
    "date": "2016-10-21",
    "amount": "-414.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 19.10 NOK 414.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 205717007,
    "accountNumber": "35302464616",
    "date": "2016-10-20",
    "amount": "-549.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 18.10 NOK 549.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 206522942,
    "accountNumber": "35302464616",
    "date": "2016-10-19",
    "amount": "-119.00",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "19.10 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 206555580,
    "accountNumber": "35302464616",
    "date": "2016-10-19",
    "amount": "-649.90",
    "text": "Gamestop Bergen Åsane Senter Ulset",
    "alfareferanse": "18.10 GAMESTOP BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 809,
      "placeId": "ChIJw0qn3Pr9PEYRdKClmC31R9I",
      "placeName": "GameStop",
      "coordinates": "60.4654149,5.3243373",
      "website": "https://www.gamestop.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 206583991,
    "accountNumber": "35302464616",
    "date": "2016-10-19",
    "amount": "-1000.00",
    "text": "Gamestop Bergen Åsane Senter Ulset",
    "alfareferanse": "18.10 GAMESTOP BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 809,
      "placeId": "ChIJw0qn3Pr9PEYRdKClmC31R9I",
      "placeName": "GameStop",
      "coordinates": "60.4654149,5.3243373",
      "website": "https://www.gamestop.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 206374650,
    "accountNumber": "35302464616",
    "date": "2016-10-19",
    "amount": "-41.93",
    "text": "Glitter Bergen Starvhusgt 4 Bergen",
    "alfareferanse": "19.10 GLITTER BERGEN  STARVHUSGT 4 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 810,
      "placeId": "ChIJ__-_XKj-PEYR-7d3oVnZ4vU",
      "placeName": "Glitter",
      "coordinates": "60.3927285,5.3263497",
      "website": "https://www.glitter.no/storelocator/Glitter-Bergen-Xhibition",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 206395469,
    "accountNumber": "35302464616",
    "date": "2016-10-19",
    "amount": "-200.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "19.10 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 206523237,
    "accountNumber": "35302464616",
    "date": "2016-10-19",
    "amount": "-1399.00",
    "text": "Dna Torg Torgalm. Bergen",
    "alfareferanse": "19.10 805703 DNA TORG TORGALM.5 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1292,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 207082739,
    "accountNumber": "35302464616",
    "date": "2016-10-18",
    "amount": "-4790.00",
    "text": "Komplett.No",
    "alfareferanse": "*0971 14.10 NOK 4790.00 KOMPLETT.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 811,
      "placeId": "ChIJ80nmyznHRkYR2WyOgi0pn-I",
      "placeName": "Komplett.no Pick-up-Point",
      "coordinates": "59.1757884,10.2189126",
      "website": "https://www.komplett.no/",
      "logoId": 158,
      "merchantName": "Komplett.no Pick-up-Point",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 206820286,
    "accountNumber": "35302464616",
    "date": "2016-10-18",
    "amount": "-17.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "17.10 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 207235891,
    "accountNumber": "35302464616",
    "date": "2016-10-18",
    "amount": "-157.22",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "18.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 207185263,
    "accountNumber": "35302464616",
    "date": "2016-10-18",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.10 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 207213266,
    "accountNumber": "35302464616",
    "date": "2016-10-18",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 14.10 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 207231348,
    "accountNumber": "35302464616",
    "date": "2016-10-18",
    "amount": "-65.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "18.10 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 207703670,
    "accountNumber": "35302464616",
    "date": "2016-10-17",
    "amount": "-151.37",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "14.10 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 208232207,
    "accountNumber": "35302464616",
    "date": "2016-10-17",
    "amount": "-20.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*0971 12.10 NOK 20.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 207742465,
    "accountNumber": "35302464616",
    "date": "2016-10-17",
    "amount": "-266.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "16.10 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 209231116,
    "accountNumber": "35302464616",
    "date": "2016-10-13",
    "amount": "-239.90",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "12.10 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 209208608,
    "accountNumber": "35302464616",
    "date": "2016-10-13",
    "amount": "-47.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "12.10 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 209245528,
    "accountNumber": "35302464616",
    "date": "2016-10-13",
    "amount": "-300.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "12.10 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 209063678,
    "accountNumber": "35302464616",
    "date": "2016-10-13",
    "amount": "-536.00",
    "text": "Hygienisk Rens Og Vask Vestkanten",
    "alfareferanse": "12.10 HYGIENISK RENS  OG VASK VESTKANTEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Renseri",
    "imageId": "",
    "place": {
      "id": 638,
      "placeId": "ChIJo0lIzQz7PEYRsPMdrTYRcVU",
      "placeName": "Hygienic Wash & Cleaning",
      "coordinates": "60.3632227,5.2336255",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 208940247,
    "accountNumber": "35302464616",
    "date": "2016-10-13",
    "amount": "-552.60",
    "text": "Coop Obs Vestkanten Loddefjord",
    "alfareferanse": "12.10 COOP OBS VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 601,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 209109939,
    "accountNumber": "35302464616",
    "date": "2016-10-13",
    "amount": "-290.00",
    "text": "Vita Vestk Loddefjordve Loddefjord",
    "alfareferanse": "12.10 1611 VITA VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 753,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 208995549,
    "accountNumber": "35302464616",
    "date": "2016-10-13",
    "amount": "-242.60",
    "text": "Clas Ohl 881 Loddefjordv. Bergen",
    "alfareferanse": "12.10 CLAS OHL 881 LODDEFJORDV. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 763,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 209722457,
    "accountNumber": "35302464616",
    "date": "2016-10-12",
    "amount": "-153.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 10.10 NOK 153.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 210012302,
    "accountNumber": "35302464616",
    "date": "2016-10-11",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 07.10 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 210882097,
    "accountNumber": "35302464616",
    "date": "2016-10-10",
    "amount": "-178.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "10.10 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 210853125,
    "accountNumber": "35302464616",
    "date": "2016-10-10",
    "amount": "-202.89",
    "text": "Shell Lagunepar Laguneveien Rådal",
    "alfareferanse": "08.10 SHELL LAGUNEPAR LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 640,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 211179679,
    "accountNumber": "35302464616",
    "date": "2016-10-10",
    "amount": "-175.50",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "09.10 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 210952712,
    "accountNumber": "35302464616",
    "date": "2016-10-10",
    "amount": "-299.00",
    "text": "Din Sko 1121 Strandgaten Bergen",
    "alfareferanse": "10.10 DIN SKO 1121 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 812,
      "placeId": "ChIJA0JqAqj-PEYRMJqjp6BhiAE",
      "placeName": "DinSko",
      "coordinates": "60.3941063,5.3233139",
      "website": "https://feetfirst.no/sko/dinsko-strandgaten-9",
      "logoId": 159,
      "merchantName": "DinSko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 211240019,
    "accountNumber": "35302464616",
    "date": "2016-10-10",
    "amount": "-337.00",
    "text": "Spar Kjøp As Strandgt 17 Bergen",
    "alfareferanse": "10.10 SPAR KJØP AS STRANDGT 17  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 782,
      "placeId": "ChIJ2aaKsgL8PEYRUsgYWLRaAPg",
      "placeName": "Spar Kjøp Bergen Sentrum",
      "coordinates": "60.3930955,5.3287018",
      "website": "https://www.sparkjop.no/",
      "logoId": 146,
      "merchantName": "Spar Kjøp",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 210965039,
    "accountNumber": "35302464616",
    "date": "2016-10-10",
    "amount": "-387.00",
    "text": "Coop Obs! Lagunevn. 1 5239 Rådal",
    "alfareferanse": "08.10 COOP OBS! LAGUNEVN. 1  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 779,
      "placeId": "ChIJX1fNweb5PEYR87iliMuzS2Q",
      "placeName": "Obs Bygg",
      "coordinates": "60.2952833,5.3288162",
      "website": "https://coop.no/butikker/obs-bygg/lagunen-2268/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 210415685,
    "accountNumber": "35302464616",
    "date": "2016-10-10",
    "amount": "-95.70",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "10.10 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 211444260,
    "accountNumber": "35302464616",
    "date": "2016-10-07",
    "amount": "-232.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "06.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 211796244,
    "accountNumber": "35302464616",
    "date": "2016-10-06",
    "amount": "-207.89",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "05.10 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 212179157,
    "accountNumber": "35302464616",
    "date": "2016-10-05",
    "amount": "-296.10",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 03.10 NOK 296.10 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 212440153,
    "accountNumber": "35302464616",
    "date": "2016-10-04",
    "amount": "-499.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 30.09 NOK 499.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 212543727,
    "accountNumber": "35302464616",
    "date": "2016-10-04",
    "amount": "-219.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 30.09 NOK 219.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 212498572,
    "accountNumber": "35302464616",
    "date": "2016-10-04",
    "amount": "-549.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*0971 30.09 NOK 549.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 212764685,
    "accountNumber": "35302464616",
    "date": "2016-10-04",
    "amount": "-109.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*0971 02.10 NOK 109.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 213647050,
    "accountNumber": "35302464616",
    "date": "2016-10-03",
    "amount": "-28.90",
    "text": "Apotek 1 Oasen Oasen Senter Fyllingsdalen",
    "alfareferanse": "03.10 APOTEK 1 OASEN  OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 523,
      "placeId": "ChIJp13-g8_7PEYR5MFkfv-WtX8",
      "placeName": "Apotek 1",
      "coordinates": "60.349305,5.2902173",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/oasen-895",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 213889136,
    "accountNumber": "35302464616",
    "date": "2016-10-03",
    "amount": "-1438.00",
    "text": "Outland As Bergen Fortu Bergen",
    "alfareferanse": "01.10 OUTLAND AS BERGEN FORTU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 813,
      "placeId": "ChIJHY8rsAL8PEYR3nKlQkN1i3w",
      "placeName": "Outland Bergen",
      "coordinates": "60.394017,5.3221861",
      "website": "https://www.outland.no/?utm_source=google&utm_medium=GMB",
      "logoId": 160,
      "merchantName": "Outland",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 214121635,
    "accountNumber": "35302464616",
    "date": "2016-09-30",
    "amount": "-60.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         30 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 214075080,
    "accountNumber": "35302464616",
    "date": "2016-09-30",
    "amount": "-1333.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 217026145,
    "accountNumber": "35302464616",
    "date": "2016-09-23",
    "amount": "-2400.00",
    "text": "Bergen Kommune",
    "alfareferanse": "Nettgiro til: BERGEN KOMMUNE Betalt: 23.09.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 773,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 217218330,
    "accountNumber": "35302464616",
    "date": "2016-09-23",
    "amount": "-945.00",
    "text": "Bkk Kundetjenes",
    "alfareferanse": "Nettgiro til: BKK KUNDETJENES Betalt: 23.09.16",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Elektrisitet",
    "imageId": "",
    "place": {
      "id": 787,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 217357366,
    "accountNumber": "35302464616",
    "date": "2016-09-23",
    "amount": "-676.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 23.09.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 217930918,
    "accountNumber": "35302464616",
    "date": "2016-09-21",
    "amount": "-4363.00",
    "text": "Krav Fra Faktnr. 5",
    "alfareferanse": "Krav fra faktnr. 1558655",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1458,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 219679717,
    "accountNumber": "35302464616",
    "date": "2016-09-19",
    "amount": "-100.00",
    "text": "Go3",
    "alfareferanse": "Go3",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1479,
      "search": "Go3",
      "logoId": 0,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 220223043,
    "accountNumber": "35302464616",
    "date": "2016-09-16",
    "amount": "-308.38",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "16.09 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 220355421,
    "accountNumber": "35302464616",
    "date": "2016-09-16",
    "amount": "-117.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "16.09 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 220328043,
    "accountNumber": "35302464616",
    "date": "2016-09-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.09 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 221105135,
    "accountNumber": "35302464616",
    "date": "2016-09-15",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 13.09 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 221213345,
    "accountNumber": "35302464616",
    "date": "2016-09-15",
    "amount": "-549.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 13.09 NOK 549.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 220762151,
    "accountNumber": "35302464616",
    "date": "2016-09-15",
    "amount": "-100.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "15.09 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 221718561,
    "accountNumber": "35302464616",
    "date": "2016-09-14",
    "amount": "-158.50",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "13.09 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222004931,
    "accountNumber": "35302464616",
    "date": "2016-09-13",
    "amount": "-162.00",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "12.09 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222518397,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-86.00",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "11.09 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222331836,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-199.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 08.09 NOK 199.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222267596,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-243.20",
    "text": "Vitusapotek Nor Strømgt 8 Bergen",
    "alfareferanse": "11.09 VITUSAPOTEK NOR STRØMGT 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 484,
      "placeId": "ChIJW-e2tK7-PEYRGP_JiUKGoXs",
      "placeName": "Vitusapotek Nordstjernen - Bergen",
      "coordinates": "60.3893095,5.3325248",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Nordstjernen-Bergen/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222796884,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-6.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "11.09 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222895832,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-28.80",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "12.09 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222962544,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-173.00",
    "text": "7-Eleven 7150 Krinkelkroke Bergen",
    "alfareferanse": "10.09 7-ELEVEN 7150 KRINKELKROKE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1356,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222800412,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-33.00",
    "text": "Bergen Kino, Ki Neumannsgt. Bergen",
    "alfareferanse": "10.09 BERGEN KINO, KI NEUMANNSGT.  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 814,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222637836,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-149.00",
    "text": "Narvesen 768 Bystasjonen Bergen",
    "alfareferanse": "11.09 NARVESEN 768 BYSTASJONEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 815,
      "placeId": "ChIJ1zU0267-PEYRm0ZIjjZwpbo",
      "placeName": "Narvesen",
      "coordinates": "60.3893065,5.332543",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 222288977,
    "accountNumber": "35302464616",
    "date": "2016-09-12",
    "amount": "-102.00",
    "text": "Aft Invest As Vaskerelvsma Bergen",
    "alfareferanse": "11.09 AFT INVEST AS VASKERELVSMA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 816,
      "placeId": "ChIJbx7g2wL8PEYRAQlxrVs5IJs",
      "placeName": "Bergen Eiendom Invest AS",
      "coordinates": "60.3935248,5.3192109",
      "website": "http://www.bergeneiendom.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223296340,
    "accountNumber": "35302464616",
    "date": "2016-09-09",
    "amount": "-179.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 07.09 NOK 179.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223573626,
    "accountNumber": "35302464616",
    "date": "2016-09-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 07.09 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223546828,
    "accountNumber": "35302464616",
    "date": "2016-09-09",
    "amount": "-179.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 07.09 NOK 179.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 224025921,
    "accountNumber": "35302464616",
    "date": "2016-09-08",
    "amount": "-61.80",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 06.09 NOK 61.80 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223861913,
    "accountNumber": "35302464616",
    "date": "2016-09-08",
    "amount": "-260.80",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "07.09 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223924595,
    "accountNumber": "35302464616",
    "date": "2016-09-08",
    "amount": "-328.80",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "07.09 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223876445,
    "accountNumber": "35302464616",
    "date": "2016-09-08",
    "amount": "-144.50",
    "text": "Nille 5 Vestk Loddefjordve Bergen",
    "alfareferanse": "07.09 NILLE 5 VESTK LODDEFJORDVE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 670,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223862751,
    "accountNumber": "35302464616",
    "date": "2016-09-08",
    "amount": "-391.38",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "07.09 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 224072894,
    "accountNumber": "35302464616",
    "date": "2016-09-08",
    "amount": "-87.00",
    "text": "N. Lie Nielsen Vestkanten Loddefjord",
    "alfareferanse": "07.09 N. LIE NIELSEN  VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 699,
      "placeId": "ChIJo0lIzQz7PEYRNyl36OiDcbA",
      "placeName": "Lie-Nielsen konditori AS",
      "coordinates": "60.3632227,5.2336255",
      "website": "https://www.lienielsen.no/",
      "logoId": 116,
      "merchantName": "Lie-Nielsen konditori",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 223912053,
    "accountNumber": "35302464616",
    "date": "2016-09-08",
    "amount": "-70.00",
    "text": "Big Bite Avd320 Loddefjordvn Loddefjord",
    "alfareferanse": "07.09 BIG BITE AVD320 LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1405,
      "placeId": "ChIJXQ2K0gz7PEYRU3MSsqTL4ik",
      "placeName": "Big Bite",
      "coordinates": "60.3625521,5.2340884",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 224210899,
    "accountNumber": "35302464616",
    "date": "2016-09-07",
    "amount": "-17.40",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "06.09 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 224337726,
    "accountNumber": "35302464616",
    "date": "2016-09-07",
    "amount": "-36.00",
    "text": "Billettautomat Vestre Strøm Bergen",
    "alfareferanse": "06.09 BILLETTAUTOMAT  VESTRE STRØM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 818,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 224163156,
    "accountNumber": "35302464616",
    "date": "2016-09-07",
    "amount": "-36.00",
    "text": "Billettautomat Vestre Strøm Bergen",
    "alfareferanse": "06.09 BILLETTAUTOMAT  VESTRE STRØM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Buss",
    "imageId": "",
    "place": {
      "id": 818,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 224122294,
    "accountNumber": "35302464616",
    "date": "2016-09-07",
    "amount": "457.00",
    "text": "Telenor Norge As",
    "alfareferanse": "Fra: TELENOR NORGE AS Betalt: 07.09.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 819,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 162,
      "merchantName": "Telenor",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 224528719,
    "accountNumber": "35302464616",
    "date": "2016-09-06",
    "amount": "-179.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 04.09 NOK 179.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 225403440,
    "accountNumber": "35302464616",
    "date": "2016-09-05",
    "amount": "-200.00",
    "text": "Brun Og Blid Vestkanten Loddefjord",
    "alfareferanse": "04.09 BRUN OG BLID VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 624,
      "placeId": "ChIJIVtrzAz7PEYRiHBsK8sy-Mc",
      "placeName": "BRUN OG BLID",
      "coordinates": "60.3629793,5.2338904",
      "website": "https://brunogblid.no/",
      "logoId": 95,
      "merchantName": "Brun og blid",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 225875208,
    "accountNumber": "35302464616",
    "date": "2016-09-02",
    "amount": "-441.00",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*1018 31.08 NOK 441.00 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 225876169,
    "accountNumber": "35302464616",
    "date": "2016-09-02",
    "amount": "-470.00",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*1018 31.08 NOK 470.00 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 226049140,
    "accountNumber": "35302464616",
    "date": "2016-09-02",
    "amount": "-463.15",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*1018 31.08 NOK 463.15 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 227364991,
    "accountNumber": "35302464616",
    "date": "2016-08-31",
    "amount": "-976.85",
    "text": "Bkk Kundetjenes",
    "alfareferanse": "Nettgiro til: BKK KUNDETJENES Betalt: 31.08.16",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Elektrisitet",
    "imageId": "",
    "place": {
      "id": 787,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 226888598,
    "accountNumber": "35302464616",
    "date": "2016-08-31",
    "amount": "-457.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 31.08.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 226868810,
    "accountNumber": "35302464616",
    "date": "2016-08-31",
    "amount": "-1014.90",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 31.08.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 226897645,
    "accountNumber": "35302464616",
    "date": "2016-08-31",
    "amount": "-96.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         48 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 226896771,
    "accountNumber": "35302464616",
    "date": "2016-08-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 227072024,
    "accountNumber": "35302464616",
    "date": "2016-08-31",
    "amount": "-1333.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 227419030,
    "accountNumber": "35302464616",
    "date": "2016-08-30",
    "amount": "-102.43",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "29.08 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 227849624,
    "accountNumber": "35302464616",
    "date": "2016-08-30",
    "amount": "-159.39",
    "text": "Rema Helleveien Søndre Øyjor Bergen",
    "alfareferanse": "29.08 REMA HELLEVEIEN SØNDRE ØYJOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 820,
      "placeId": "ChIJQR5c_jP8PEYRFWJK-GSOfmY",
      "placeName": "REMA 1000",
      "coordinates": "60.4245179,5.3034795",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 228609981,
    "accountNumber": "35302464616",
    "date": "2016-08-29",
    "amount": "-40.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 25.08 NOK 40.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 228574405,
    "accountNumber": "35302464616",
    "date": "2016-08-29",
    "amount": "-248.95",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "26.08 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 227997346,
    "accountNumber": "35302464616",
    "date": "2016-08-29",
    "amount": "-118.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "27.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 228485077,
    "accountNumber": "35302464616",
    "date": "2016-08-29",
    "amount": "-39.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "27.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 228399967,
    "accountNumber": "35302464616",
    "date": "2016-08-29",
    "amount": "-150.00",
    "text": "Extra Leker Ber Gullgruven S Nyborg",
    "alfareferanse": "29.08 EXTRA LEKER BER GULLGRUVEN S NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 625,
      "placeId": "ChIJSd2_2P79PEYRuLOBMrqjqso",
      "placeName": "Extra Leker Bergen Åsane",
      "coordinates": "60.4726717,5.3335366",
      "website": "http://www.extra-leker.no/",
      "logoId": 96,
      "merchantName": "Extra Leker",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 228574589,
    "accountNumber": "35302464616",
    "date": "2016-08-29",
    "amount": "-98.60",
    "text": "Vitusapotek Gul Gullgruven Nyborg",
    "alfareferanse": "29.08 VITUSAPOTEK GUL GULLGRUVEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 626,
      "placeId": "ChIJSd2_2P79PEYRJYcKHKagnQ8",
      "placeName": "Vitusapotek Gullgruven",
      "coordinates": "60.4726354,5.3322667",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Gullgruven/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 228950703,
    "accountNumber": "35302464616",
    "date": "2016-08-26",
    "amount": "-109.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "25.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 229504009,
    "accountNumber": "35302464616",
    "date": "2016-08-25",
    "amount": "-109.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "24.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 229497960,
    "accountNumber": "35302464616",
    "date": "2016-08-25",
    "amount": "-187.20",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "24.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 229694643,
    "accountNumber": "35302464616",
    "date": "2016-08-24",
    "amount": "-150.14",
    "text": "Shell7- 11 Ådnavegen 63 Indre Arna",
    "alfareferanse": "23.08 7526 SHELL7- 11 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 540,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 229946955,
    "accountNumber": "35302464616",
    "date": "2016-08-24",
    "amount": "-123.00",
    "text": "Shell7- 11 Ådnavegen 63 Indre Arna",
    "alfareferanse": "23.08 7526 SHELL7- 11 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 540,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 229700739,
    "accountNumber": "35302464616",
    "date": "2016-08-24",
    "amount": "-131.00",
    "text": "M/F Etne 4 Boks 839 Sen Stavanger",
    "alfareferanse": "23.08 M/F ETNE 4 BOKS 839 SEN STAVANGER",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 821,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230148584,
    "accountNumber": "35302464616",
    "date": "2016-08-23",
    "amount": "-299.00",
    "text": "Mcdonald's Sørland Barstølv. 35 Kristiansand",
    "alfareferanse": "22.08 MCD 021 SØRLAND BARSTØLV. 35 KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 822,
      "placeId": "ChIJj0xa1h8BOEYRS8A84NOry3E",
      "placeName": "McDonald's",
      "coordinates": "58.1788273,8.1346817",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230455074,
    "accountNumber": "35302464616",
    "date": "2016-08-23",
    "amount": "-23.00",
    "text": "Cafe Opus Barstølvn 33 Kristiansand",
    "alfareferanse": "22.08 2437 CAFE OPUS  BARSTØLVN 33 KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 823,
      "placeId": "ChIJi1SeoR8BOEYR7u-jUat954o",
      "placeName": "Café Opus",
      "coordinates": "58.1795033,8.1339203",
      "website": "http://www.cafeopus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230478691,
    "accountNumber": "35302464616",
    "date": "2016-08-23",
    "amount": "-11.00",
    "text": "Geheb Conditori Stemmane 4A Kristiansand",
    "alfareferanse": "22.08 GEHEB CONDITORI STEMMANE 4A  KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 824,
      "placeId": "ChIJ7UEmUhwBOEYRxtPAMKSzkcc",
      "placeName": "Geheb Conditori AS",
      "coordinates": "58.1732944,8.1294798",
      "website": "http://www.geheb.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230304788,
    "accountNumber": "35302464616",
    "date": "2016-08-23",
    "amount": "-71.39",
    "text": "Rema Søgne Nygårdsjorde Søgne",
    "alfareferanse": "22.08 REMA SØGNE NYGÅRDSJORDE SØGNE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 825,
      "placeId": "ChIJI7A0NFv2N0YRnSUiVfCknVM",
      "placeName": "REMA 1000",
      "coordinates": "58.0978552,7.8110461",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230238684,
    "accountNumber": "35302464616",
    "date": "2016-08-23",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*1018 20.08 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230113666,
    "accountNumber": "35302464616",
    "date": "2016-08-23",
    "amount": "-400.01",
    "text": "Esso Søgne Sentrumsvn. Søgne",
    "alfareferanse": "23.08 ESSO SØGNE SENTRUMSVN.  SØGNE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 627,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230820492,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-327.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 18.08 NOK 327.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231102170,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-214.00",
    "text": "Arsvågen Bod 2 Fjord 1 Bokn",
    "alfareferanse": "19.08 ARSVÅGEN BOD 2  FJORD 1 BOKN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 826,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230825085,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-83.50",
    "text": "Shell7- 11 Rosselandvn Brennåsen",
    "alfareferanse": "21.08 7535 SHELL7- 11 ROSSELANDVN  BRENNÅSEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 827,
      "placeId": "ChIJQzteTHkdOEYRY-EX2UJQHcQ",
      "placeName": "Shell",
      "coordinates": "58.135496,7.8562315",
      "website": "https://find.shell.com/no/fuel/NO_7963-shell-7-eleven-rosseland",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230761021,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-646.00",
    "text": "H&M No0733 Slottet Shop Kristiansand",
    "alfareferanse": "20.08 H&M NO0733 SLOTTET SHOP KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 828,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231532424,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-79.90",
    "text": "H&M No0733 Slottet Shop Kristiansand",
    "alfareferanse": "20.08 H&M NO0733 SLOTTET SHOP KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 828,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231519046,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-1128.41",
    "text": "Coop Extra Marie Førelv Søgne",
    "alfareferanse": "20.08 COOP EXTRA MARIE FØRELV SØGNE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 829,
      "placeId": "ChIJ_1QbzkT2N0YRE1JXhltHp40",
      "placeName": "Extra Tangvall",
      "coordinates": "58.0984644,7.8155564",
      "website": "https://coop.no/butikker/extra/tangvall-2450/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231563782,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-108.90",
    "text": "Coop Extra Marie Førelv Søgne",
    "alfareferanse": "20.08 COOP EXTRA MARIE FØRELV SØGNE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 829,
      "placeId": "ChIJ_1QbzkT2N0YRE1JXhltHp40",
      "placeName": "Extra Tangvall",
      "coordinates": "58.0984644,7.8155564",
      "website": "https://coop.no/butikker/extra/tangvall-2450/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231658735,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-649.80",
    "text": "Gamestop Markensg. 22 Kristiansand",
    "alfareferanse": "20.08 GAMESTOP MARKENSG. 22 KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 830,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231648889,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-275.00",
    "text": "Mcdonald's Markens Markensgt 11 Kristiansand",
    "alfareferanse": "20.08 MCD 010 MARKENS MARKENSGT 11 KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 831,
      "placeId": "ChIJAb7QAl4COEYRrDWShvZoSLw",
      "placeName": "McDonald's Markensgate",
      "coordinates": "58.1443163,7.9953446",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231455371,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-210.50",
    "text": "Coop Mega Ole Nielsens Ålgård",
    "alfareferanse": "19.08 COOP MEGA OLE NIELSENS ÅLGÅRD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 832,
      "placeId": "ChIJIWjVlmY6OkYRpwP-Py_D9u8",
      "placeName": "Coop Mega Ålgård AMFI Ålgård",
      "coordinates": "58.7702475,5.8460276",
      "website": "https://coop.no/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231422978,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-200.00",
    "text": "Nordea Dyreparken Kr.Sand 15:31",
    "alfareferanse": "21.08 NORDEA DYREPARKEN KR.SAND 15:31",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 833,
      "placeId": "ChIJlbqbn54COEYRRGf3XXL6ntg",
      "placeName": "Kristiansand Zoo and Amusement Park",
      "coordinates": "58.1856575,8.144123",
      "website": "http://www.dyreparken.no/",
      "logoId": 165,
      "merchantName": "Kristiansand Zoo and Amusement Park",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231474251,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-126.00",
    "text": "Mf Stavangerfjo Strandav 1 Florø",
    "alfareferanse": "19.08 MF STAVANGERFJO STRANDAV 1 FLORØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 834,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 230991314,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-28.00",
    "text": "Mf Fanafjord, Strandav 1 Florø",
    "alfareferanse": "19.08 MF FANAFJORD, STRANDAV 1 FLORØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 835,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231453602,
    "accountNumber": "35302464616",
    "date": "2016-08-22",
    "amount": "-27.00",
    "text": "Scandic Fb Ø.Strandgt. Kristiansand",
    "alfareferanse": "20.08 SCANDIC FB Ø.STRANDGT.  KRISTIANSAND",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Hotell",
    "imageId": "",
    "place": {
      "id": 836,
      "placeId": "ChIJhZul21wCOEYRcxO3OHKZ7UI",
      "placeName": "Scandic Kristiansand Bystranda",
      "coordinates": "58.1471441,8.0051935",
      "website": "https://www.scandichotels.no/hotell/norge/kristiansand/scandic-kristiansand-bystranda",
      "logoId": 166,
      "merchantName": "Scandic",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231876669,
    "accountNumber": "35302464616",
    "date": "2016-08-19",
    "amount": "-452.00",
    "text": "Lyssand Bil Industriveie Os",
    "alfareferanse": "19.08 LYSSAND BIL INDUSTRIVEIE OS",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bil",
    "imageId": "",
    "place": {
      "id": 837,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 232001767,
    "accountNumber": "35302464616",
    "date": "2016-08-19",
    "amount": "-287.00",
    "text": "Halhjem Bod 1 Fjord Os",
    "alfareferanse": "19.08 HALHJEM BOD 1 FJORD 1 5208 OS",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 838,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 231847316,
    "accountNumber": "35302464616",
    "date": "2016-08-19",
    "amount": "-624.93",
    "text": "Uno-X Os 0313 Os",
    "alfareferanse": "19.08 UNO-X OS 0313  OS",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 839,
      "placeId": "ChIJP2MaL7pYPEYRcVR_LdEJRkM",
      "placeName": "Uno-X Os",
      "coordinates": "60.205824,5.44916",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 232036892,
    "accountNumber": "35302464616",
    "date": "2016-08-19",
    "amount": "-459.00",
    "text": "Årstad Pizzeria Bjørnsons Ga Bergen",
    "alfareferanse": "18.08 ÅRSTAD PIZZERIA BJØRNSONS GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 700,
      "placeId": "ChIJOxdIJk_5PEYRWJBPFscXGyI",
      "placeName": "Årstad Pizzeria",
      "coordinates": "60.373177,5.3439855",
      "website": "https://www.just-eat.no/restaurants-arstad-pizza",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 232315286,
    "accountNumber": "35302464616",
    "date": "2016-08-18",
    "amount": "-568.00",
    "text": "Outland As Bergen Fortu Bergen",
    "alfareferanse": "17.08 OUTLAND AS BERGEN FORTU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 813,
      "placeId": "ChIJHY8rsAL8PEYR3nKlQkN1i3w",
      "placeName": "Outland Bergen",
      "coordinates": "60.394017,5.3221861",
      "website": "https://www.outland.no/?utm_source=google&utm_medium=GMB",
      "logoId": 160,
      "merchantName": "Outland",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 232431003,
    "accountNumber": "35302464616",
    "date": "2016-08-18",
    "amount": "-346.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "17.08 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 232707709,
    "accountNumber": "35302464616",
    "date": "2016-08-18",
    "amount": "-254.70",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "18.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 233121454,
    "accountNumber": "35302464616",
    "date": "2016-08-17",
    "amount": "-340.00",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*1018 15.08 NOK 340.00 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 233013837,
    "accountNumber": "35302464616",
    "date": "2016-08-17",
    "amount": "-383.00",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*1018 15.08 NOK 383.00 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 233253395,
    "accountNumber": "35302464616",
    "date": "2016-08-16",
    "amount": "-79.64",
    "text": "Rema Fjøsanger Straumenveie Bergen",
    "alfareferanse": "16.08 REMA FJØSANGER  STRAUMENVEIE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 840,
      "placeId": "ChIJGUyy7275PEYRu5QkMcUITww",
      "placeName": "REMA 1000",
      "coordinates": "60.3474007,5.3355204",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 233663701,
    "accountNumber": "35302464616",
    "date": "2016-08-16",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 13.08 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 233300109,
    "accountNumber": "35302464616",
    "date": "2016-08-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.08 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 233504706,
    "accountNumber": "35302464616",
    "date": "2016-08-16",
    "amount": "-132.36",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "16.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 235457300,
    "accountNumber": "35302464616",
    "date": "2016-08-12",
    "amount": "-164.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 10.08 NOK 164.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 235226592,
    "accountNumber": "35302464616",
    "date": "2016-08-12",
    "amount": "-8.58",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "11.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 235231238,
    "accountNumber": "35302464616",
    "date": "2016-08-12",
    "amount": "-176.08",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "11.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 235523966,
    "accountNumber": "35302464616",
    "date": "2016-08-11",
    "amount": "-119.00",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "10.08 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 236053910,
    "accountNumber": "35302464616",
    "date": "2016-08-10",
    "amount": "-200.52",
    "text": "1-2-3 Godvik 57 Alvehaugen Godvik",
    "alfareferanse": "10.08 1-2-3 GODVIK 57 ALVEHAUGEN GODVIK",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 470,
      "placeId": "ChIJ63ueS0_7PEYRWCCYwWA3ypU",
      "placeName": "1-2-3 Godvik",
      "coordinates": "60.3704065,5.1921729",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=57074&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 236034128,
    "accountNumber": "35302464616",
    "date": "2016-08-10",
    "amount": "-4.30",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "09.08 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 235942768,
    "accountNumber": "35302464616",
    "date": "2016-08-10",
    "amount": "-129.00",
    "text": "Mekk Drotningsv Drotningsvik Godvik",
    "alfareferanse": "10.08 MEKK DROTNINGSV DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 841,
      "placeId": "ChIJAUqyj0z7PEYRk1BJq9MUlzg",
      "placeName": "Mekk Drotningsvik",
      "coordinates": "60.3720512,5.1802764",
      "website": "http://www.mekk.no/butikker/mekk-drotningsvik/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 236613753,
    "accountNumber": "35302464616",
    "date": "2016-08-09",
    "amount": "-892.10",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 05.08 NOK 892.10 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 236529987,
    "accountNumber": "35302464616",
    "date": "2016-08-09",
    "amount": "-55.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 07.08 NOK 55.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 237448623,
    "accountNumber": "35302464616",
    "date": "2016-08-08",
    "amount": "-361.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "06.08 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 237200455,
    "accountNumber": "35302464616",
    "date": "2016-08-08",
    "amount": "-630.60",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "06.08 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 236984821,
    "accountNumber": "35302464616",
    "date": "2016-08-08",
    "amount": "-358.00",
    "text": "Dolly Dimple's Lyderhornsve Loddefjord",
    "alfareferanse": "07.08 DOLLY DIMPLE'S  LYDERHORNSVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 709,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 237616057,
    "accountNumber": "35302464616",
    "date": "2016-08-05",
    "amount": "-20.00",
    "text": "Bergen Kino Kiosk .",
    "alfareferanse": "05.08 BERGEN KINO KIOSK .",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 842,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 237803338,
    "accountNumber": "35302464616",
    "date": "2016-08-05",
    "amount": "-146.00",
    "text": "7 - Eleven 7092 Engen 16 Bergen",
    "alfareferanse": "04.08 7 - ELEVEN 7092 ENGEN 16 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1410,
      "placeId": "ChIJfYZ5XwL8PEYRpNxbn_gNe6Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3916858,5.3200612",
      "website": "http://7-eleven.no/butikker",
      "logoId": 207,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 238103553,
    "accountNumber": "35302464616",
    "date": "2016-08-04",
    "amount": "-650.10",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "03.08 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 238228421,
    "accountNumber": "35302464616",
    "date": "2016-08-04",
    "amount": "-147.00",
    "text": "Bergen Kino As",
    "alfareferanse": "*1018 02.08 NOK 147.00 BERGEN KINO AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 689,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 238048366,
    "accountNumber": "35302464616",
    "date": "2016-08-04",
    "amount": "-201.99",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "03.08 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 238645962,
    "accountNumber": "35302464616",
    "date": "2016-08-03",
    "amount": "-218.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 01.08 NOK 218.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 238614485,
    "accountNumber": "35302464616",
    "date": "2016-08-03",
    "amount": "-486.08",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "02.08 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 238854204,
    "accountNumber": "35302464616",
    "date": "2016-08-02",
    "amount": "-48.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "01.08 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 238803725,
    "accountNumber": "35302464616",
    "date": "2016-08-02",
    "amount": "-159.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "01.08 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 239152388,
    "accountNumber": "35302464616",
    "date": "2016-08-02",
    "amount": "-20.00",
    "text": "Jernia Minde Stømgt.8 Bergen",
    "alfareferanse": "01.08 JERNIA MINDE STØMGT.8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 843,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 239754993,
    "accountNumber": "35302464616",
    "date": "2016-07-31",
    "amount": "-54.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         27 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 239214720,
    "accountNumber": "35302464616",
    "date": "2016-07-29",
    "amount": "-1333.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 241591759,
    "accountNumber": "35302464616",
    "date": "2016-07-27",
    "amount": "-248.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "26.07 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 241521174,
    "accountNumber": "35302464616",
    "date": "2016-07-27",
    "amount": "-143.70",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "27.07 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 241472732,
    "accountNumber": "35302464616",
    "date": "2016-07-27",
    "amount": "-119.00",
    "text": "Coop Obs Åsane Myrdalsveien Nyborg",
    "alfareferanse": "26.07 COOP OBS ÅSANE  MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 844,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 241947825,
    "accountNumber": "35302464616",
    "date": "2016-07-26",
    "amount": "-382.00",
    "text": "Klarna/Wish.Com",
    "alfareferanse": "*1018 22.07 NOK 382.00 Klarna/Wish.com Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 793,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 241944594,
    "accountNumber": "35302464616",
    "date": "2016-07-26",
    "amount": "-168.89",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "25.07 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 242718920,
    "accountNumber": "35302464616",
    "date": "2016-07-25",
    "amount": "-201.84",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "22.07 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 242367820,
    "accountNumber": "35302464616",
    "date": "2016-07-25",
    "amount": "-250.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "22.07 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 242567994,
    "accountNumber": "35302464616",
    "date": "2016-07-25",
    "amount": "-218.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 21.07 NOK 218.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 242936851,
    "accountNumber": "35302464616",
    "date": "2016-07-25",
    "amount": "-98.50",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "24.07 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 242731042,
    "accountNumber": "35302465310",
    "date": "2016-07-25",
    "amount": "-457.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 24.07.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243022936,
    "accountNumber": "35302464616",
    "date": "2016-07-25",
    "amount": "-2976.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 24.07.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243173925,
    "accountNumber": "35302465310",
    "date": "2016-07-25",
    "amount": "-497.00",
    "text": "Telenor Norge A",
    "alfareferanse": "Nettgiro til: TELENOR NORGE A Betalt: 24.07.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Fasttelefoni",
    "imageId": "",
    "place": {
      "id": 1412,
      "placeId": "ChIJE0MuGXO4RkYRn6LQ_d4Xno8",
      "placeName": "Telenor Norway AS avd Stokke",
      "coordinates": "59.2244744,10.295381",
      "website": "https://www.telenor.no/",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243606962,
    "accountNumber": "35302464616",
    "date": "2016-07-22",
    "amount": "-711.50",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "21.07 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243905742,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-210.00",
    "text": "Elite Foto Promenaden Knarvik Sente",
    "alfareferanse": "21.07 ELITE FOTO PROMENADEN KNARVIK SENTE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fotobutikk",
    "imageId": "",
    "place": {
      "id": 845,
      "placeId": "ChIJWZy8_58DPUYR1ERRYTjpk0g",
      "placeName": "Elite Foto Knarvik",
      "coordinates": "60.5479918,5.2870355",
      "website": "http://elitefoto.no/",
      "logoId": 168,
      "merchantName": "Elite Foto",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244030069,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-369.00",
    "text": "Nintendo Of Europe",
    "alfareferanse": "*1018 19.07 NOK 369.00 NINTENDO OF EUROPE Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 731,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243682807,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-70.00",
    "text": "Starbucks Neumannsgate Bergen",
    "alfareferanse": "20.07 6109 STARBUCKS  NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 704,
      "placeId": "ChIJQWMabgL8PEYReOR7bM-sglQ",
      "placeName": "Starbucks Neumann street",
      "coordinates": "60.3907327,5.3214611",
      "website": "http://www.starbucks.no/store/1008256/",
      "logoId": 119,
      "merchantName": "Starbucks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243855065,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-4363.00",
    "text": "Krav Fra Faktnr. 3",
    "alfareferanse": "Krav fra faktnr. 1545213",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1455,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243871155,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-307.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "20.07 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243688793,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-129.00",
    "text": "Netflix",
    "alfareferanse": "*1018 19.07 NOK 129.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243930387,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-90.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "20.07 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 243695391,
    "accountNumber": "35302464616",
    "date": "2016-07-21",
    "amount": "-75.00",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "20.07 NARVESEN 747 OLE BULLSPL. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244731709,
    "accountNumber": "35302464616",
    "date": "2016-07-20",
    "amount": "-941.20",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "19.07 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244258497,
    "accountNumber": "35302464616",
    "date": "2016-07-20",
    "amount": "-171.80",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "19.07 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244375175,
    "accountNumber": "35302464616",
    "date": "2016-07-20",
    "amount": "-55.80",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "19.07 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244555462,
    "accountNumber": "35302464616",
    "date": "2016-07-20",
    "amount": "-150.90",
    "text": "37 Kid Interiør Liamyrane 1 Nyborg",
    "alfareferanse": "19.07 37 KID INTERIØR LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1293,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244321362,
    "accountNumber": "35302464616",
    "date": "2016-07-20",
    "amount": "-307.00",
    "text": "Mammamia Pizza Hellevn. 277 Bergen",
    "alfareferanse": "19.07 MAMMAMIA PIZZA  HELLEVN. 277 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1287,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244968044,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-550.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "18.07 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245100871,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-95.80",
    "text": "Nille 96 Sane Sane Senter Ulset",
    "alfareferanse": "19.07 NILLE 96 SANE SANE SENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 846,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245119767,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-109.00",
    "text": "Hjelle Bakeri Åsane Senter Bergen.",
    "alfareferanse": "19.07 HJELLE BAKERI ÅSANE SENTER BERGEN.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 847,
      "placeId": "ChIJMc6UmPr9PEYRSrWtdRTXFc0",
      "placeName": "Hjelle Bakeri",
      "coordinates": "60.4657162,5.3227725",
      "website": "http://www.hjellebakeri.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244967028,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-305.00",
    "text": "Kremmerhuset Åsane Storse Ulset",
    "alfareferanse": "19.07 KREMMERHUSET ÅSANE STORSE ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 848,
      "placeId": "ChIJGQBJkfr9PEYRRl07hrNUGCc",
      "placeName": "Kremmerhuset",
      "coordinates": "60.465909,5.3224399",
      "website": "http://www.kremmerhuset.no/",
      "logoId": 169,
      "merchantName": "Kremmerhuset",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245098435,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-54.90",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "19.07 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245319240,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-129.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 17.07 NOK 129.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245046748,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-73.65",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 14.07 NOK 73.65 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 244957873,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-150.22",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "18.07 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245016485,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-81.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "18.07 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245186519,
    "accountNumber": "35302464616",
    "date": "2016-07-19",
    "amount": "-218.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 17.07 NOK 218.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 246311823,
    "accountNumber": "35302464616",
    "date": "2016-07-18",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.07 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245697933,
    "accountNumber": "35302464616",
    "date": "2016-07-18",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.07 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245760434,
    "accountNumber": "35302464616",
    "date": "2016-07-18",
    "amount": "-149.00",
    "text": "H&M No0849 Åsane Senter Ulset",
    "alfareferanse": "16.07 H&M NO0849 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 807,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 245885506,
    "accountNumber": "35302464616",
    "date": "2016-07-18",
    "amount": "-268.00",
    "text": "Gina Tricot Ber Sane Center Ulset",
    "alfareferanse": "16.07 GINA TRICOT BER SANE CENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 849,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 247486531,
    "accountNumber": "35302464616",
    "date": "2016-07-13",
    "amount": "-7799.00",
    "text": "Musikkmagazinet Engen 2 .Bergen",
    "alfareferanse": "13.07 MUSIKKMAGAZINET ENGEN 2 .BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 850,
      "placeId": "ChIJcVr5iwL8PEYRQ133jDa78XM",
      "placeName": "Musik-Magazin AS",
      "coordinates": "60.392092,5.321087",
      "website": "http://www.musik-magazinet.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 247663481,
    "accountNumber": "35302464616",
    "date": "2016-07-13",
    "amount": "-72.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "13.07 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 247621111,
    "accountNumber": "35302464616",
    "date": "2016-07-13",
    "amount": "-72.90",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "13.07 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 248003406,
    "accountNumber": "35302464616",
    "date": "2016-07-12",
    "amount": "-195.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 10.07 NOK 195.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 248007693,
    "accountNumber": "35302464616",
    "date": "2016-07-12",
    "amount": "-323.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 08.07 NOK 323.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 248829164,
    "accountNumber": "35302464616",
    "date": "2016-07-11",
    "amount": "-499.00",
    "text": "Mani Vestkanten Loddefjordvn Bergen",
    "alfareferanse": "09.07 MANI VESTKANTEN LODDEFJORDVN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 851,
      "placeId": "ChIJXQ2K0gz7PEYRNoL9-5yclDM",
      "placeName": "Mani Vestkanten Storsenter",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://skomani.no/",
      "logoId": 171,
      "merchantName": "Mani",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 248891375,
    "accountNumber": "35302464616",
    "date": "2016-07-11",
    "amount": "-1246.64",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "08.07 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 248622257,
    "accountNumber": "35302464616",
    "date": "2016-07-11",
    "amount": "-389.00",
    "text": "129 Boots Apotek Loddefjord",
    "alfareferanse": "09.07 129 BOOTS APOTEK LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 698,
      "placeId": "ChIJo0lIzQz7PEYRXzUfwZa7nWA",
      "placeName": "Boots apotek Loddefjord",
      "coordinates": "60.36309,5.233356",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 249468779,
    "accountNumber": "35302464616",
    "date": "2016-07-08",
    "amount": "-159.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 06.07 NOK 159.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 249694830,
    "accountNumber": "35302464616",
    "date": "2016-07-08",
    "amount": "-37.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "08.07 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 250887033,
    "accountNumber": "35302464616",
    "date": "2016-07-05",
    "amount": "-699.29",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "04.07 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 251584981,
    "accountNumber": "35302464616",
    "date": "2016-07-04",
    "amount": "-296.05",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "04.07 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 252371364,
    "accountNumber": "35302464616",
    "date": "2016-07-01",
    "amount": "-206.52",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "01.07 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 252494144,
    "accountNumber": "35302464616",
    "date": "2016-07-01",
    "amount": "-297.00",
    "text": "Lindex 959 Øyra Ådnavegen 63 Indre Arna",
    "alfareferanse": "01.07 LINDEX 959 ØYRA ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1399,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 252230946,
    "accountNumber": "35302464616",
    "date": "2016-07-01",
    "amount": "-25.00",
    "text": "Hjelle Konditor Ådnavegen 63 Indre Arna",
    "alfareferanse": "01.07 HJELLE KONDITOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1417,
      "placeId": "ChIJC6INGX__PEYRC5dDUQWEsnU",
      "placeName": "Hjelle Bakeri",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.hjellebakeri.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 252987211,
    "accountNumber": "35302464616",
    "date": "2016-06-30",
    "amount": "-44.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         22 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 252725875,
    "accountNumber": "35302464616",
    "date": "2016-06-30",
    "amount": "-437.00",
    "text": "Peppes Pizza As Åsmyrene 82 Ulset",
    "alfareferanse": "29.06 PEPPES PIZZA AS ÅSMYRENE 82  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 852,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 253535203,
    "accountNumber": "35302464616",
    "date": "2016-06-30",
    "amount": "-39.00",
    "text": "Circle K Servic Nordre Brurå Nyborg",
    "alfareferanse": "29.06 CIRCLE K SERVIC NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 491,
      "placeId": "ChIJd0uwwucBPUYRZlp84b2Kdlc",
      "placeName": "Circle K Haukås",
      "coordinates": "60.4846724,5.3764514",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55564&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 253927353,
    "accountNumber": "35302464616",
    "date": "2016-06-29",
    "amount": "-378.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 27.06 NOK 378.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254524494,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-1333.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254425016,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-179.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 26.06 NOK 179.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254087838,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-308.10",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 24.06 NOK 308.10 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254327423,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-200.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "27.06 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254266466,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-782.50",
    "text": "Kicks 674 Loddefjordve Loddefjord",
    "alfareferanse": "27.06 KICKS 674 LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 854,
      "placeId": "ChIJXQ2K0gz7PEYRp_GUvflkmqA",
      "placeName": "KICKS",
      "coordinates": "60.363224,5.233616",
      "website": "https://www.kicks.no/butikker/loddefjord-vestkanten/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254130398,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-99.00",
    "text": "Vestkanten Zoo Vestkanten S Loddefjord",
    "alfareferanse": "27.06 VESTKANTEN ZOO  VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 853,
      "placeId": "ChIJo0lIzQz7PEYRevVlTjxQU9Y",
      "placeName": "Buddy Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://www.buddy.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254154079,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-607.92",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "27.06 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254064294,
    "accountNumber": "35302464616",
    "date": "2016-06-28",
    "amount": "-459.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "27.06 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254620207,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-916.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "26.06 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255235386,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-293.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "25.06 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254559022,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-375.90",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "26.06 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255099125,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-58.00",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "26.06 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254999141,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-117.00",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "26.06 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254788968,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-200.06",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "26.06 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255349472,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-332.60",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "27.06 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 254789845,
    "accountNumber": "35302464616",
    "date": "2016-06-27",
    "amount": "-250.00",
    "text": "Årspris Visa Classic",
    "alfareferanse": "Årspris Visa Classic",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 679,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255554003,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-360.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "23.06 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255882202,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-214.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 22.06 NOK 214.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255524354,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-159.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 22.06 NOK 159.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255873678,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-378.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 22.06 NOK 378.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255537821,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-259.00",
    "text": "Best Alversund Alversund Alversund",
    "alfareferanse": "23.06 BEST ALVERSUND  ALVERSUND ALVERSUND",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 856,
      "placeId": "ChIJXW0CS1YDPUYROp0_SulXTR0",
      "placeName": "Gabben Bensinstasjon AS",
      "coordinates": "60.5710295,5.2329067",
      "website": "https://beststasjon.no/",
      "logoId": 174,
      "merchantName": "Gabben Bensinstasjon AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255549527,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-164.60",
    "text": "Coop Extra Manger Manger",
    "alfareferanse": "23.06 COOP EXTRA MANGER MANGER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1294,
      "placeId": "ChIJsadSoLsZPUYREhWa8fTB9Zc",
      "placeName": "Extra Manger",
      "coordinates": "60.6406076,5.0421694",
      "website": "https://coop.no/butikker/extra/manger-1395/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255843791,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-70.00",
    "text": "Yx Manger 0094 Manger",
    "alfareferanse": "23.06 YX MANGER 0094   MANGER",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1295,
      "placeId": "ChIJu6v2bL0ZPUYRpzi_-e9V7xo",
      "placeName": "YX Manger",
      "coordinates": "60.6396786,5.0539356",
      "website": "http://www.unox.no/web/yxportal.nsf/hovedside/bil?Opendocument",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255738996,
    "accountNumber": "35302464616",
    "date": "2016-06-24",
    "amount": "-194.90",
    "text": "Apotek 1 Radøyv. 1680 Manger",
    "alfareferanse": "23.06 APOTEK 1 RADØYV. 1680 MANGER",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1296,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256204813,
    "accountNumber": "35302464616",
    "date": "2016-06-23",
    "amount": "-861.20",
    "text": "Et Hårstudio Ei Vollane 1 Eidsvåg I Åsa",
    "alfareferanse": "22.06 ET HÅRSTUDIO EI VOLLANE 1 EIDSVÅG I ÅSA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 857,
      "placeId": "ChIJvbQMnNH9PEYRychR6rmZ_p8",
      "placeName": "ET Hårstudio Eidsvåg",
      "coordinates": "60.436588,5.3190309",
      "website": "http://www.ethaarstudio.no/",
      "logoId": 175,
      "merchantName": "ET Haarstudio",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 255980831,
    "accountNumber": "35302464616",
    "date": "2016-06-23",
    "amount": "-345.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "22.06 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256189534,
    "accountNumber": "35302464616",
    "date": "2016-06-23",
    "amount": "-329.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 21.06 NOK 329.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256086718,
    "accountNumber": "35302464616",
    "date": "2016-06-23",
    "amount": "-159.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 21.06 NOK 159.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256118864,
    "accountNumber": "35302464616",
    "date": "2016-06-23",
    "amount": "-183.19",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "22.06 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256211162,
    "accountNumber": "35302464616",
    "date": "2016-06-23",
    "amount": "-200.74",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "22.06 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256866395,
    "accountNumber": "35302464616",
    "date": "2016-06-22",
    "amount": "-329.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 20.06 NOK 329.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256550600,
    "accountNumber": "35302464616",
    "date": "2016-06-22",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "22.06 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256524600,
    "accountNumber": "35302464616",
    "date": "2016-06-22",
    "amount": "-176.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 20.06 NOK 176.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256528642,
    "accountNumber": "35302464616",
    "date": "2016-06-22",
    "amount": "-270.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "22.06 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 256549525,
    "accountNumber": "35302464616",
    "date": "2016-06-22",
    "amount": "-755.00",
    "text": "Kicks 676 Strømgaten 8 Bergen",
    "alfareferanse": "22.06 KICKS 676 STRØMGATEN 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 858,
      "placeId": "ChIJJ_9Dt67-PEYRu-Al530qmuM",
      "placeName": "KICKS",
      "coordinates": "60.3891768,5.332768",
      "website": "https://www.kicks.no/butikker/bergen-bergen-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 257470972,
    "accountNumber": "35302464616",
    "date": "2016-06-21",
    "amount": "-251.00",
    "text": "Bergen Kino As",
    "alfareferanse": "*1018 17.06 NOK 251.00 BERGEN KINO AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 689,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 257217536,
    "accountNumber": "35302464616",
    "date": "2016-06-21",
    "amount": "-385.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "20.06 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 257233994,
    "accountNumber": "35302464616",
    "date": "2016-06-21",
    "amount": "-4363.00",
    "text": "Krav Fra Faktnr. 5",
    "alfareferanse": "Krav fra faktnr. 1538365",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1458,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 257126421,
    "accountNumber": "35302464616",
    "date": "2016-06-21",
    "amount": "-988.87",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "21.06 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 257508591,
    "accountNumber": "35302464616",
    "date": "2016-06-21",
    "amount": "-331.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "21.06 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 257331270,
    "accountNumber": "35302464616",
    "date": "2016-06-21",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 19.06 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 258491985,
    "accountNumber": "35302464616",
    "date": "2016-06-20",
    "amount": "-325.30",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "19.06 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 258060060,
    "accountNumber": "35302464616",
    "date": "2016-06-20",
    "amount": "-355.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "20.06 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 257993166,
    "accountNumber": "35302464616",
    "date": "2016-06-20",
    "amount": "-390.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "18.06 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 258096954,
    "accountNumber": "35302464616",
    "date": "2016-06-20",
    "amount": "-1593.30",
    "text": "H&M No0879 Myrdalsvegen Nyborg",
    "alfareferanse": "18.06 H&M NO0879 MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 749,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 258535751,
    "accountNumber": "35302464616",
    "date": "2016-06-20",
    "amount": "-750.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "18.06 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 258525421,
    "accountNumber": "35302464616",
    "date": "2016-06-20",
    "amount": "-400.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "19.06 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 258252089,
    "accountNumber": "35302464616",
    "date": "2016-06-20",
    "amount": "-531.17",
    "text": "Rema Helleveien Søndre Øyjor Bergen",
    "alfareferanse": "18.06 REMA HELLEVEIEN SØNDRE ØYJOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 820,
      "placeId": "ChIJQR5c_jP8PEYRFWJK-GSOfmY",
      "placeName": "REMA 1000",
      "coordinates": "60.4245179,5.3034795",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 258920379,
    "accountNumber": "35302464616",
    "date": "2016-06-17",
    "amount": "-178.00",
    "text": "Kremen Konditor Kaien Norheimsund",
    "alfareferanse": "16.06 KREMEN KONDITOR KAIEN NORHEIMSUND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 859,
      "placeId": "ChIJjzUMT2s0PEYR14Y7CvWSERk",
      "placeName": "Kremen Konditori",
      "coordinates": "60.371341,6.1458029",
      "website": "http://www.kremen-konditori.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259288447,
    "accountNumber": "35302464616",
    "date": "2016-06-17",
    "amount": "-118.80",
    "text": "308 Boots Apotek Norheimsund",
    "alfareferanse": "16.06 308 BOOTS APOTEK NORHEIMSUND",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 860,
      "placeId": "ChIJf_XKTms0PEYR-cCkE0O_LZE",
      "placeName": "Boots apotek Norheimsund",
      "coordinates": "60.372369,6.146178",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259185004,
    "accountNumber": "35302464616",
    "date": "2016-06-17",
    "amount": "-607.00",
    "text": "Ai-Interiør Da Grova 39 Norheimsund",
    "alfareferanse": "16.06 AI-INTERIØR DA  GROVA 39 NORHEIMSUND",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 861,
      "placeId": "ChIJkfd2TGs0PEYRKTgiWJLZPXQ",
      "placeName": "Ai-interiør DA",
      "coordinates": "60.3710071,6.145757",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259401732,
    "accountNumber": "35302464616",
    "date": "2016-06-17",
    "amount": "-258.40",
    "text": "Coop Prix Avd. 101 Norheimsund",
    "alfareferanse": "16.06 COOP PRIX AVD. 101 NORHEIMSUND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 862,
      "placeId": "ChIJv8-haWs0PEYR6cYJJdqNy_I",
      "placeName": "Coop Prix Norheimsund",
      "coordinates": "60.3723,6.14595",
      "website": "https://coop.no/butikker/prix/norheimsund-1445/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259208159,
    "accountNumber": "35302464616",
    "date": "2016-06-17",
    "amount": "-30.00",
    "text": "Safa Utsalget Grova 22 Norheimsund",
    "alfareferanse": "16.06 SAFA UTSALGET GROVA 22 NORHEIMSUND",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 863,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259367959,
    "accountNumber": "35302464616",
    "date": "2016-06-17",
    "amount": "-539.00",
    "text": "Paypal *Makeupmekka",
    "alfareferanse": "*1018 14.06 NOK 539.00 PAYPAL *MAKEUPMEKKA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 757,
      "placeId": "ChIJOYDfs4luQUYRnN7mCciKceQ",
      "placeName": "Makeup Mekka AS",
      "coordinates": "59.925237,10.748984",
      "website": "http://www.makeupmekka.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259512444,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-269.80",
    "text": "Toys\"R\"Us F3305 Laguneveien Rådal",
    "alfareferanse": "15.06 TOYS\"R\"US F3305 LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 864,
      "placeId": "ChIJobCusub5PEYR4voyp-JXkl4",
      "placeName": "TOYS\"R\"US",
      "coordinates": "60.2969763,5.3307453",
      "website": "https://www.toysrus.no/store/3305",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259746593,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-1377.01",
    "text": "Kiwi 814 Tertnesveien Tertnes",
    "alfareferanse": "15.06 KIWI 814 TERTNESVEIEN TERTNES",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 865,
      "placeId": "ChIJb5hcu5X9PEYRDKGffqthGuI",
      "placeName": "KIWI Tertnes",
      "coordinates": "60.4557967,5.2952747",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Tertnes/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259640103,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-596.80",
    "text": "H&M No0779 Lagunen Stor Rådal",
    "alfareferanse": "15.06 H&M NO0779 LAGUNEN STOR RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 866,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259451416,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-149.00",
    "text": "Platekompaniet Laguneveien Rådal",
    "alfareferanse": "15.06 PLATEKOMPANIET  LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Spill/musikk",
    "imageId": "",
    "place": {
      "id": 1289,
      "placeId": "ChIJX1fNweb5PEYRwcWows4dqbU",
      "placeName": "Platekompaniet AS",
      "coordinates": "60.2968658,5.3311507",
      "website": "https://www.platekompaniet.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259872495,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.06 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259668218,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-92.00",
    "text": "Shell 7-Eleven Botnane 1 Ulset",
    "alfareferanse": "15.06 SHELL 7-ELEVEN  BOTNANE 1 ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 649,
      "placeId": "ChIJpQQxkev9PEYRV0wegIY0Zks",
      "placeName": "Shell",
      "coordinates": "60.4572686,5.3071216",
      "website": "https://find.shell.com/no/fuel/NO_7937-shell-7-eleven-tertnes",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259903211,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-302.88",
    "text": "Shell 7-Eleven Botnane 1 Ulset",
    "alfareferanse": "15.06 SHELL 7-ELEVEN  BOTNANE 1 ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 649,
      "placeId": "ChIJpQQxkev9PEYRV0wegIY0Zks",
      "placeName": "Shell",
      "coordinates": "60.4572686,5.3071216",
      "website": "https://find.shell.com/no/fuel/NO_7937-shell-7-eleven-tertnes",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 259527178,
    "accountNumber": "35302464616",
    "date": "2016-06-16",
    "amount": "-213.00",
    "text": "Shell 7-Eleven Botnane 1 Ulset",
    "alfareferanse": "15.06 SHELL 7-ELEVEN  BOTNANE 1 ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 649,
      "placeId": "ChIJpQQxkev9PEYRV0wegIY0Zks",
      "placeName": "Shell",
      "coordinates": "60.4572686,5.3071216",
      "website": "https://find.shell.com/no/fuel/NO_7937-shell-7-eleven-tertnes",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 260053108,
    "accountNumber": "35302464616",
    "date": "2016-06-15",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 13.06 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 260471877,
    "accountNumber": "35302464616",
    "date": "2016-06-15",
    "amount": "-346.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "15.06 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 260951854,
    "accountNumber": "35302464616",
    "date": "2016-06-14",
    "amount": "-250.78",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "14.06 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261129772,
    "accountNumber": "35302464616",
    "date": "2016-06-14",
    "amount": "-812.55",
    "text": "Coop Prix Strømgt. 5 Bergen",
    "alfareferanse": "13.06 COOP PRIX STRØMGT. 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 867,
      "placeId": "ChIJzUss26j-PEYRQZVuKLINXA0",
      "placeName": "Coop Prix Marken",
      "coordinates": "60.392113,5.330169",
      "website": "https://coop.no/butikker/prix/marken-4574/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 260789304,
    "accountNumber": "35302464616",
    "date": "2016-06-14",
    "amount": "-501.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "14.06 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 260667613,
    "accountNumber": "35302464616",
    "date": "2016-06-14",
    "amount": "-364.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "13.06 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262172132,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-198.00",
    "text": "Los Tacos Vetrl Vetrlidsallm Bergen",
    "alfareferanse": "12.06 LOS TACOS VETRL VETRLIDSALLM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 729,
      "placeId": "ChIJRzkYzqn-PEYRprWKuxCk4-U",
      "placeName": "Los Tacos Sentrum",
      "coordinates": "60.3912,5.323645",
      "website": "http://lostacos.no/",
      "logoId": 127,
      "merchantName": "Los Tacos",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261466382,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-950.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "12.06 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261761845,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-341.00",
    "text": "Peppes Pizza As Åsmyrene 82 Ulset",
    "alfareferanse": "10.06 PEPPES PIZZA AS ÅSMYRENE 82  ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 852,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261979314,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-299.00",
    "text": "Kicks 675 Åsane Senter Ulset",
    "alfareferanse": "11.06 KICKS 675 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 868,
      "placeId": "ChIJ1RP5sfr9PEYR9RNWaC99jzQ",
      "placeName": "KICKS",
      "coordinates": "60.4654946,5.3214737",
      "website": "https://www.kicks.no/butikker/ulset-asane-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262017981,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-200.90",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "11.06 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261500198,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-199.00",
    "text": "Cubus 877 Arken Ulset",
    "alfareferanse": "11.06 CUBUS 877 ARKEN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 870,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261535849,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-99.95",
    "text": "Gamestop Bergen Åsane Senter Ulset",
    "alfareferanse": "11.06 GAMESTOP BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 809,
      "placeId": "ChIJw0qn3Pr9PEYRdKClmC31R9I",
      "placeName": "GameStop",
      "coordinates": "60.4654149,5.3243373",
      "website": "https://www.gamestop.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261174849,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-50.00",
    "text": "Bryggen Pianoba Bryggen Bergen",
    "alfareferanse": "12.06 BRYGGEN PIANOBA BRYGGEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1418,
      "placeId": "ChIJhbLjnR38PEYRCui64uRFigE",
      "placeName": "Bryggen Pianobar AS",
      "coordinates": "60.3975921,5.3223474",
      "website": "http://bryggenpianobar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262024202,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-550.00",
    "text": "Bryggen Pianoba Bryggen Bergen",
    "alfareferanse": "12.06 BRYGGEN PIANOBA BRYGGEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1418,
      "placeId": "ChIJhbLjnR38PEYRCui64uRFigE",
      "placeName": "Bryggen Pianobar AS",
      "coordinates": "60.3975921,5.3223474",
      "website": "http://bryggenpianobar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261478396,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-530.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "12.06 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262217235,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-121.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "12.06 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262173571,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-1721.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "11.06 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 261352096,
    "accountNumber": "35302464616",
    "date": "2016-06-13",
    "amount": "-37.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "11.06 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 263145441,
    "accountNumber": "35302464616",
    "date": "2016-06-09",
    "amount": "-107.20",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "09.06 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262919208,
    "accountNumber": "35302464616",
    "date": "2016-06-09",
    "amount": "-183.50",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "09.06 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262942522,
    "accountNumber": "35302464616",
    "date": "2016-06-09",
    "amount": "-203.49",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "09.06 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 262830003,
    "accountNumber": "35302464616",
    "date": "2016-06-09",
    "amount": "-86.00",
    "text": "Solbrød Oasen Kokstaddalen Kokstad",
    "alfareferanse": "09.06 SOLBRØD OASEN KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 871,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 263362372,
    "accountNumber": "35302464616",
    "date": "2016-06-08",
    "amount": "-112.00",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "07.06 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 264400930,
    "accountNumber": "35302464616",
    "date": "2016-06-06",
    "amount": "-241.10",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "03.06 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 265196457,
    "accountNumber": "35302464616",
    "date": "2016-06-03",
    "amount": "-119.00",
    "text": "Lindex 230 Vestkanten Loddefjord",
    "alfareferanse": "03.06 LINDEX 230 VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 873,
      "placeId": "ChIJo0lIzQz7PEYRXYkYdEChozE",
      "placeName": "Lindex",
      "coordinates": "60.3619119,5.2341155",
      "website": "http://www.lindex.com/",
      "logoId": 178,
      "merchantName": "Lindex",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 264944369,
    "accountNumber": "35302464616",
    "date": "2016-06-03",
    "amount": "-139.00",
    "text": "Oliviers & Co Markeveien 4 Bergen",
    "alfareferanse": "03.06 OLIVIERS & CO MARKEVEIEN 4 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 874,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 265127043,
    "accountNumber": "35302464616",
    "date": "2016-06-03",
    "amount": "-200.00",
    "text": "Mani Vestkanten Loddefjordvn Bergen",
    "alfareferanse": "02.06 MANI VESTKANTEN LODDEFJORDVN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 851,
      "placeId": "ChIJXQ2K0gz7PEYRNoL9-5yclDM",
      "placeName": "Mani Vestkanten Storsenter",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://skomani.no/",
      "logoId": 171,
      "merchantName": "Mani",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 265213219,
    "accountNumber": "35302464616",
    "date": "2016-06-03",
    "amount": "-87.35",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "02.06 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 265182452,
    "accountNumber": "35302464616",
    "date": "2016-06-03",
    "amount": "-54.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "03.06 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 266368114,
    "accountNumber": "35302464616",
    "date": "2016-05-31",
    "amount": "-54.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "31.05 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 266680445,
    "accountNumber": "35302464616",
    "date": "2016-05-31",
    "amount": "-1335.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 266737879,
    "accountNumber": "35302464616",
    "date": "2016-05-31",
    "amount": "-283.51",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "31.05 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 266241473,
    "accountNumber": "35302464616",
    "date": "2016-05-31",
    "amount": "-77.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "31.05 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 266327019,
    "accountNumber": "35302464616",
    "date": "2016-05-31",
    "amount": "-100.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         50 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 575,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 266616592,
    "accountNumber": "35302464616",
    "date": "2016-05-31",
    "amount": "-309.00",
    "text": "Vita Folkebernado Fyllingsdalen",
    "alfareferanse": "31.05 1606 VITA FOLKEBERNADO FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 565,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 266833797,
    "accountNumber": "35302464616",
    "date": "2016-05-31",
    "amount": "-575.00",
    "text": "H&M No0854 Oasen Kjøpes Fyllingsdalen",
    "alfareferanse": "31.05 H&M NO0854 OASEN KJØPES FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 564,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 267351220,
    "accountNumber": "35302464616",
    "date": "2016-05-30",
    "amount": "-59.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "29.05 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 267071627,
    "accountNumber": "35302464616",
    "date": "2016-05-30",
    "amount": "-222.69",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "28.05 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 267075534,
    "accountNumber": "35302464616",
    "date": "2016-05-30",
    "amount": "-437.00",
    "text": "Peppes Pizza As Åsmyrene 82 Ulset",
    "alfareferanse": "27.05 PEPPES PIZZA AS ÅSMYRENE 82  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 852,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 268404968,
    "accountNumber": "35302464616",
    "date": "2016-05-27",
    "amount": "-274.19",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "26.05 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 268080266,
    "accountNumber": "35302464616",
    "date": "2016-05-27",
    "amount": "-69.10",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "27.05 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 268733574,
    "accountNumber": "35302464616",
    "date": "2016-05-26",
    "amount": "-521.59",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "25.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 268781251,
    "accountNumber": "35302464616",
    "date": "2016-05-26",
    "amount": "-240.69",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "25.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 268568781,
    "accountNumber": "35302464616",
    "date": "2016-05-26",
    "amount": "-108.60",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "25.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 268884884,
    "accountNumber": "35302464616",
    "date": "2016-05-25",
    "amount": "-213.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "25.05 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 269193888,
    "accountNumber": "35302464616",
    "date": "2016-05-25",
    "amount": "-236.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "24.05 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 269395666,
    "accountNumber": "35302464616",
    "date": "2016-05-24",
    "amount": "-152.82",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "23.05 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 269372320,
    "accountNumber": "35302464616",
    "date": "2016-05-24",
    "amount": "-230.00",
    "text": "Little Ea Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.05 1602 LITTLE EA  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 587,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 269343576,
    "accountNumber": "35302464616",
    "date": "2016-05-24",
    "amount": "-291.30",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.05 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270280677,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-144.00",
    "text": "Upstairs As Vestre Torvg Bergen",
    "alfareferanse": "21.05 UPSTAIRS AS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 562,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270267206,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-197.00",
    "text": "Burger Kin Strandgt. 5 Bergen",
    "alfareferanse": "21.05 5003 BURGER KIN STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 613,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270612103,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 19.05 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270510149,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-106.00",
    "text": "Circle K Viken Vikensgt. 6 Bergen",
    "alfareferanse": "22.05 CIRCLE K VIKEN  VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 471,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270380354,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-101.28",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "21.05 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270590942,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-121.00",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "21.05 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 269832989,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-366.00",
    "text": "Pasta Sentral V.Torggt 5-7 Bergen",
    "alfareferanse": "22.05 PASTA SENTRAL V.TORGGT 5-7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 712,
      "placeId": "ChIJ_-KGbQL8PEYRif8GaK8WHEU",
      "placeName": "Pasta sentralen AS",
      "coordinates": "60.3904726,5.3211308",
      "website": "http://pastasentral.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270363364,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-206.00",
    "text": "Bergen Kino Kiosk .",
    "alfareferanse": "21.05 BERGEN KINO KIOSK .",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 842,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270133300,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-440.55",
    "text": "Bunnpris Ulveda Ulvedalen To Flaktveit",
    "alfareferanse": "22.05 BUNNPRIS ULVEDA ULVEDALEN TO FLAKTVEIT",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 875,
      "placeId": "ChIJeSlWRhr-PEYRPn7QA7VLd5E",
      "placeName": "Bunnpris Ulvedalen",
      "coordinates": "60.4648761,5.3621029",
      "website": "http://bunnpris.no/butikker/bunnpris-ulvedalen",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270468812,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-120.00",
    "text": "7-Eleven 7150 Krinkelkroke Bergen",
    "alfareferanse": "22.05 7-ELEVEN 7150 KRINKELKROKE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1356,
      "placeId": "ChIJcVAsfxD5PEYRU9pcirM5uS0",
      "placeName": "Shell",
      "coordinates": "60.3487916,5.3665606",
      "website": "https://find.shell.com/no/fuel/NO_7924-shell-7-eleven-natlandsveien",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270099361,
    "accountNumber": "35302464616",
    "date": "2016-05-23",
    "amount": "-4363.00",
    "text": "Krav Fra Faktnr. 1",
    "alfareferanse": "Krav fra faktnr. 1531611",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1456,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270674538,
    "accountNumber": "35302464616",
    "date": "2016-05-20",
    "amount": "-180.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "20.05 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 270868075,
    "accountNumber": "35302464616",
    "date": "2016-05-20",
    "amount": "-112.00",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "19.05 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 271577891,
    "accountNumber": "35302464616",
    "date": "2016-05-19",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.05 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 271490728,
    "accountNumber": "35302464616",
    "date": "2016-05-19",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.05 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 271677103,
    "accountNumber": "35302464616",
    "date": "2016-05-19",
    "amount": "-300.00",
    "text": "Meny Sletten Vilhelm Bjer Bergen",
    "alfareferanse": "18.05 MENY SLETTEN VILHELM BJER BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 876,
      "placeId": "ChIJ____PxH5PEYRvxRcXwKn5QM",
      "placeName": "MENY Sletten",
      "coordinates": "60.3566014,5.3590732",
      "website": "https://meny.no/Finn-butikk/MENY-Sletten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 271477375,
    "accountNumber": "35302464616",
    "date": "2016-05-19",
    "amount": "-202.69",
    "text": "Rema Wergeland Fageråsveeie Bergen",
    "alfareferanse": "18.05 REMA WERGELAND  FAGERÅSVEEIE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 877,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 272064039,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-777.00",
    "text": "Egon Bristol Torgalm. 11 Bergen",
    "alfareferanse": "13.05 EGON BRISTOL TORGALM. 11  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 878,
      "placeId": "ChIJuYPZJ6j-PEYRzic3O2MvbPw",
      "placeName": "Egon",
      "coordinates": "60.3925896,5.3228722",
      "website": "http://www.egon.no/restauranter/bristol",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 272648167,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-90.80",
    "text": "Nille 79 Bergen Strømsgata 8 Bergen",
    "alfareferanse": "14.05 NILLE 79 BERGEN STRØMSGATA 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 879,
      "placeId": "ChIJVbzau67-PEYRvWYbvefhI_4",
      "placeName": "Nille Bergen Storsenter",
      "coordinates": "60.3894344,5.3324956",
      "website": "http://nille.no/",
      "logoId": 179,
      "merchantName": "Nille",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273214787,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-505.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "14.05 CUBUS 875 BYSTASJONEN  5015 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 272565274,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-288.97",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "13.05 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 272744593,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-286.00",
    "text": "Bk Bystasj Strøm Gt. 8 Bergen",
    "alfareferanse": "14.05 5022 BK BYSTASJ STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 707,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 272385853,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-360.00",
    "text": "Toscana Pizzeri Øvre Fylling Laksevåg",
    "alfareferanse": "15.05 TOSCANA PIZZERI ØVRE FYLLING LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 881,
      "placeId": "ChIJ8YhaQvr7PEYRUW6QnzyushU",
      "placeName": "Toscana Pizzeria",
      "coordinates": "60.37594,5.3037526",
      "website": "http://www.toscana-pizzeria.net/",
      "logoId": 180,
      "merchantName": "Toscana Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273284994,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-390.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "16.05 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 272739584,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-203.90",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "15.05 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273010361,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-359.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "15.05 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 272868534,
    "accountNumber": "35302464616",
    "date": "2016-05-18",
    "amount": "-121.00",
    "text": "Circle K Hellev Helleveien 3 Bergen",
    "alfareferanse": "15.05 CIRCLE K HELLEV HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 500,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273705487,
    "accountNumber": "35302464616",
    "date": "2016-05-13",
    "amount": "-530.70",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "13.05 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273607174,
    "accountNumber": "35302464616",
    "date": "2016-05-13",
    "amount": "-150.12",
    "text": "Circle K Ulset Hesthaugveie Ulset",
    "alfareferanse": "12.05 CIRCLE K ULSET  HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 617,
      "placeId": "ChIJn9_77u_9PEYR4V7-t5ICpgo",
      "placeName": "Circle K Ulset",
      "coordinates": "60.4635337,5.3189254",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=55342&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 274086147,
    "accountNumber": "35302464616",
    "date": "2016-05-13",
    "amount": "-1260.90",
    "text": "Telia Norge As.",
    "alfareferanse": "Nettgiro til: TELIA NORGE AS. Betalt: 12.05.16",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 882,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273971270,
    "accountNumber": "35302464616",
    "date": "2016-05-13",
    "amount": "-451.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "12.05 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273747000,
    "accountNumber": "35302465310",
    "date": "2016-05-13",
    "amount": "-1750.00",
    "text": "Bergen Bompenge",
    "alfareferanse": "Nettgiro til: BERGEN BOMPENGE Betalt: 13.05.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 1290,
      "placeId": "ChIJheVQtUX5PEYRoLGjxDF1E90",
      "placeName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "coordinates": "60.3677117,5.3417854",
      "website": "http://www.bomringenbergen.no/",
      "logoId": 266,
      "merchantName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 273860827,
    "accountNumber": "35302465310",
    "date": "2016-05-13",
    "amount": "-1825.00",
    "text": "Bergen Bompenge",
    "alfareferanse": "Nettgiro til: BERGEN BOMPENGE Betalt: 13.05.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 1290,
      "placeId": "ChIJheVQtUX5PEYRoLGjxDF1E90",
      "placeName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "coordinates": "60.3677117,5.3417854",
      "website": "http://www.bomringenbergen.no/",
      "logoId": 266,
      "merchantName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 274163759,
    "accountNumber": "35302464616",
    "date": "2016-05-12",
    "amount": "-115.00",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "12.05 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 274503340,
    "accountNumber": "35302464616",
    "date": "2016-05-12",
    "amount": "-641.07",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "11.05 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 274804248,
    "accountNumber": "35302464616",
    "date": "2016-05-11",
    "amount": "-639.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 09.05 NOK 639.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 274990370,
    "accountNumber": "35302464616",
    "date": "2016-05-11",
    "amount": "-98.19",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "10.05 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 275219293,
    "accountNumber": "35302464616",
    "date": "2016-05-10",
    "amount": "-164.00",
    "text": "Big Bite 314 Liamyrene 1 Bergen",
    "alfareferanse": "10.05 BIG BITE 314 LIAMYRENE 1  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1297,
      "placeId": "ChIJ______gBPUYRzEg7MslSoFM",
      "placeName": "Big Bite",
      "coordinates": "60.4728392,5.3324414",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 275354098,
    "accountNumber": "35302464616",
    "date": "2016-05-10",
    "amount": "-260.80",
    "text": "Vitusapotek Gul Gullgruven Nyborg",
    "alfareferanse": "10.05 VITUSAPOTEK GUL GULLGRUVEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 626,
      "placeId": "ChIJSd2_2P79PEYRJYcKHKagnQ8",
      "placeName": "Vitusapotek Gullgruven",
      "coordinates": "60.4726354,5.3322667",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Gullgruven/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 275841669,
    "accountNumber": "35302464616",
    "date": "2016-05-09",
    "amount": "-169.71",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "07.05 MENY BERGEN STORSENTER.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 275889279,
    "accountNumber": "35302464616",
    "date": "2016-05-09",
    "amount": "-140.10",
    "text": "Shell Nygård Lyderhornsve Laksevåg",
    "alfareferanse": "07.05 SHELL NYGÅRD LYDERHORNSVE LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 499,
      "placeId": "ChIJfXhiQYT7PEYRPGY9dKF5xmg",
      "placeName": "Shell",
      "coordinates": "60.3847311,5.2712933",
      "website": "https://find.shell.com/no/fuel/NO_4140-shell-nygard",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 276267364,
    "accountNumber": "35302464616",
    "date": "2016-05-09",
    "amount": "-150.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "07.05 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 276384616,
    "accountNumber": "35302464616",
    "date": "2016-05-09",
    "amount": "-98.00",
    "text": "Vita Strømgt. 8 Bergen",
    "alfareferanse": "07.05 1610 VITA STRØMGT. 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 754,
      "placeId": "ChIJJ_9Dt67-PEYRlZpJ6_lJ0WE",
      "placeName": "Vita Bergen Storsenter",
      "coordinates": "60.3890421,5.3322011",
      "website": "https://www.vita.no/butikker/vita-bergen-storsenter",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 275879527,
    "accountNumber": "35302464616",
    "date": "2016-05-09",
    "amount": "-139.96",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "07.05 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 276035525,
    "accountNumber": "35302464616",
    "date": "2016-05-09",
    "amount": "-587.50",
    "text": "Lindex Avd. 264 Strømgt 8 Bergen",
    "alfareferanse": "07.05 LINDEX AVD. 264 STRØMGT 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 883,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 275927600,
    "accountNumber": "35302464616",
    "date": "2016-05-09",
    "amount": "-74.50",
    "text": "Kappahl 365 Strømgt. 8 Bergen",
    "alfareferanse": "07.05 KAPPAHL 365 STRØMGT. 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 884,
      "placeId": "ChIJD-EaOKn-PEYRmHiMlxyVdxQ",
      "placeName": "KappAhl",
      "coordinates": "60.3894431,5.332499",
      "website": "http://www.kappahl.com/en-US/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 276868029,
    "accountNumber": "35302464616",
    "date": "2016-05-06",
    "amount": "-310.00",
    "text": "Bowling 1 Vestk Lyderhornsv Loddefjord",
    "alfareferanse": "05.05 BOWLING 1 VESTK LYDERHORNSV  LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 885,
      "placeId": "ChIJo0lIzQz7PEYRvUK53njcWhU",
      "placeName": "Bowling 1 Vestkanten",
      "coordinates": "60.362372,5.2354532",
      "website": "http://www.bowling1vestkanten.no/",
      "logoId": 181,
      "merchantName": "Bowling 1 Vestkanten",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 276848830,
    "accountNumber": "35302464616",
    "date": "2016-05-06",
    "amount": "-216.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "05.05 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 277408844,
    "accountNumber": "35302464616",
    "date": "2016-05-04",
    "amount": "-801.30",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "03.05 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 277249927,
    "accountNumber": "35302464616",
    "date": "2016-05-04",
    "amount": "-172.99",
    "text": "Rema Ulset Hesthaugveie Ulset",
    "alfareferanse": "03.05 REMA ULSET HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 886,
      "placeId": "ChIJ1W7y6u_9PEYRs8mtvGJdhN8",
      "placeName": "REMA 1000",
      "coordinates": "60.4625489,5.3196278",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 277731070,
    "accountNumber": "35302464616",
    "date": "2016-05-03",
    "amount": "-342.00",
    "text": "O Learys Bar922 Osl Lufthavn Gardermoen",
    "alfareferanse": "02.05 O LEARYS BAR922 OSL LUFTHAVN GARDERMOEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 887,
      "placeId": "ChIJ7UnrN5CDQUYR4nKnlEgN6ik",
      "placeName": "O'Learys",
      "coordinates": "60.19755,11.1004151",
      "website": "https://olearys.no/oslo-airport-gardemoen-international/",
      "logoId": 182,
      "merchantName": "O'Learys",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 277911051,
    "accountNumber": "35302464616",
    "date": "2016-05-03",
    "amount": "-66.40",
    "text": "Narvesen 163 Flyplassvn. Gardermoen",
    "alfareferanse": "02.05 NARVESEN 163 FLYPLASSVN.  GARDERMOEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 888,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1245559430,
    "accountNumber": "35302464616",
    "date": "2016-05-02",
    "amount": "-147.00",
    "text": "Narvesen 870 Jernbanetorg Oslo",
    "alfareferanse": "01.05 NARVESEN 870 JERNBANETORG OSLO",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 889,
      "placeId": "ChIJOT0lHIpuQUYRzGKJrRg1s-g",
      "placeName": "Narvesen",
      "coordinates": "59.910789,10.7515024",
      "website": "http://www.narvesen.no/",
      "logoId": 183,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1236682882,
    "accountNumber": "35302464616",
    "date": "2016-04-30",
    "amount": "-78.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         39 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233277354,
    "accountNumber": "35302464616",
    "date": "2016-04-29",
    "amount": "-126.00",
    "text": "Bergen Kino Kiosk .",
    "alfareferanse": "28.04 BERGEN KINO KIOSK .",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 842,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1227989406,
    "accountNumber": "35302464616",
    "date": "2016-04-29",
    "amount": "-1502.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230898608,
    "accountNumber": "35302464616",
    "date": "2016-04-28",
    "amount": "-758.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "27.04 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235183072,
    "accountNumber": "35302464616",
    "date": "2016-04-28",
    "amount": "-20.50",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "27.04 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231163934,
    "accountNumber": "35302464616",
    "date": "2016-04-27",
    "amount": "-534.00",
    "text": "Peppes Pizza Av Fjøsangervei",
    "alfareferanse": "26.04 PEPPES PIZZA AV FJØSANGERVEI",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 891,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234407286,
    "accountNumber": "35302464616",
    "date": "2016-04-27",
    "amount": "-58.00",
    "text": "Hjelle Konditor Liamyrane 1 Nyborg",
    "alfareferanse": "26.04 HJELLE KONDITOR LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1298,
      "placeId": "ChIJhfKtJ__9PEYR4UHpqAzw6qU",
      "placeName": "Hjelle konditori Gullgruven",
      "coordinates": "60.4729884,5.3326372",
      "website": "http://www.hjellebakeri.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234940064,
    "accountNumber": "35302464616",
    "date": "2016-04-27",
    "amount": "-32.00",
    "text": "Hjelle Konditor Liamyrane 1 Nyborg",
    "alfareferanse": "26.04 HJELLE KONDITOR LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1298,
      "placeId": "ChIJhfKtJ__9PEYR4UHpqAzw6qU",
      "placeName": "Hjelle konditori Gullgruven",
      "coordinates": "60.4729884,5.3326372",
      "website": "http://www.hjellebakeri.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233245486,
    "accountNumber": "35302464616",
    "date": "2016-04-27",
    "amount": "-129.00",
    "text": "Big Bite 314 Liamyrene 1 Bergen",
    "alfareferanse": "26.04 BIG BITE 314 LIAMYRENE 1  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1297,
      "placeId": "ChIJ______gBPUYRzEg7MslSoFM",
      "placeName": "Big Bite",
      "coordinates": "60.4728392,5.3324414",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233944692,
    "accountNumber": "35302464616",
    "date": "2016-04-27",
    "amount": "-83.90",
    "text": "Vitusapotek Gul Gullgruven Nyborg",
    "alfareferanse": "26.04 VITUSAPOTEK GUL GULLGRUVEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 626,
      "placeId": "ChIJSd2_2P79PEYRJYcKHKagnQ8",
      "placeName": "Vitusapotek Gullgruven",
      "coordinates": "60.4726354,5.3322667",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Gullgruven/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235379521,
    "accountNumber": "35302464616",
    "date": "2016-04-26",
    "amount": "-2606.00",
    "text": "Norwegian Ai7 1.00",
    "alfareferanse": "*1018 21.04 NOK 2606.00 NORWEGIAN AI3287195359317 Kurs: 1.00",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 892,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231725173,
    "accountNumber": "35302464616",
    "date": "2016-04-26",
    "amount": "-334.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "25.04 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234117687,
    "accountNumber": "35302464616",
    "date": "2016-04-26",
    "amount": "-73.90",
    "text": "881 Kiwi Nøstet Nøstegt 31 Bergen",
    "alfareferanse": "25.04 881 KIWI NØSTET NØSTEGT 31 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 720,
      "placeId": "ChIJx_cQhQP8PEYROEzk2xxGDKc",
      "placeName": "Kiwi Nøstet",
      "coordinates": "60.3913415,5.3145786",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Nostet/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231565010,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-378.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "22.04 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1232258815,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-235.80",
    "text": "Nille 79 Bergen Strømsgata 8 Bergen",
    "alfareferanse": "23.04 NILLE 79 BERGEN STRØMSGATA 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 879,
      "placeId": "ChIJVbzau67-PEYRvWYbvefhI_4",
      "placeName": "Nille Bergen Storsenter",
      "coordinates": "60.3894344,5.3324956",
      "website": "http://nille.no/",
      "logoId": 179,
      "merchantName": "Nille",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234181964,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-70.00",
    "text": "Narvesen 768 Bystasjonen Bergen",
    "alfareferanse": "23.04 NARVESEN 768 BYSTASJONEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 815,
      "placeId": "ChIJ1zU0267-PEYRm0ZIjjZwpbo",
      "placeName": "Narvesen",
      "coordinates": "60.3893065,5.332543",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231129671,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-554.00",
    "text": "Roll & Rock Skostredet 1 Bergen",
    "alfareferanse": "24.04 ROLL & ROCK SKOSTREDET 1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 893,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231459100,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-404.30",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "23.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233279874,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-125.70",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "24.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231304627,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-467.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "24.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230517251,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-151.53",
    "text": "Esso Nyborg . Bergen",
    "alfareferanse": "25.04 ESSO NYBORG . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 475,
      "placeId": "ChIJeyicmf_9PEYRZunp1cnbohE",
      "placeName": "Esso",
      "coordinates": "60.4742847,5.3370742",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233020673,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-149.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "24.04 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234614369,
    "accountNumber": "35302464616",
    "date": "2016-04-25",
    "amount": "-48.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "23.04 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235565330,
    "accountNumber": "35302464616",
    "date": "2016-04-22",
    "amount": "-289.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 20.04 NOK 289.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235729875,
    "accountNumber": "35302464616",
    "date": "2016-04-22",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 20.04 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231815939,
    "accountNumber": "35302464616",
    "date": "2016-04-22",
    "amount": "-313.00",
    "text": "Valeri Kebab Nygårds Gate Bergen",
    "alfareferanse": "21.04 VALERI KEBAB NYGÅRDS GATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 690,
      "placeId": "ChIJA9qplKv-PEYRYDdSIrfVrmU",
      "placeName": "Valeri Pizza and Kebab",
      "coordinates": "60.38705,5.329059",
      "website": "http://www.valeripizza.no/",
      "logoId": 113,
      "merchantName": "Valeri Pizza and Kebab",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235532791,
    "accountNumber": "35302464616",
    "date": "2016-04-21",
    "amount": "-344.00",
    "text": "Toscana Pizzeria",
    "alfareferanse": "*1018 19.04 NOK 344.00 TOSCANA PIZZERIA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 894,
      "placeId": "ChIJZeL-1C8VkFQRgKRoHIj9x1s",
      "placeName": "Toscana Pizzeria",
      "coordinates": "47.6244393,-122.3255402",
      "website": "https://toscanapizzeriatogo.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230501062,
    "accountNumber": "35302464616",
    "date": "2016-04-21",
    "amount": "-201.43",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "20.04 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230981995,
    "accountNumber": "35302464616",
    "date": "2016-04-21",
    "amount": "-667.58",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "20.04 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234165948,
    "accountNumber": "35302464616",
    "date": "2016-04-20",
    "amount": "-70.20",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "20.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233493919,
    "accountNumber": "35302464616",
    "date": "2016-04-20",
    "amount": "-109.90",
    "text": "Spildes Eftf. A Thormøhlensg 5006 Bergen",
    "alfareferanse": "20.04 SPILDES EFTF. A THORMØHLENSG 5006 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 545,
      "placeId": "ChIJwcBKyar-PEYReOLtqLtEUs4",
      "placeName": "convenience store Spilde",
      "coordinates": "60.383363,5.323603",
      "website": "https://narbutikken.no/Finn-butikk/Narbutikken-Spilde--Co",
      "logoId": 68,
      "merchantName": "Narbutikken",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1229221747,
    "accountNumber": "35302464616",
    "date": "2016-04-20",
    "amount": "-1100.00",
    "text": "Statens Innkrev",
    "alfareferanse": "Nettgiro til: Statens Innkrev Betalt: 20.04.16",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Offentlige avgifter",
    "imageId": "",
    "place": {
      "id": 896,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1229260179,
    "accountNumber": "35302464616",
    "date": "2016-04-20",
    "amount": "-750.00",
    "text": "Bergen Parkerin",
    "alfareferanse": "Nettgiro til: BERGEN PARKERIN Betalt: 20.04.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1419,
      "placeId": "ChIJOVqbla7-PEYRZOAnHkSKO6A",
      "placeName": "Bergen Parking AS",
      "coordinates": "60.3870826,5.3313938",
      "website": "http://www.bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1229219439,
    "accountNumber": "35302464616",
    "date": "2016-04-20",
    "amount": "-1125.00",
    "text": "Bergen Parkerin",
    "alfareferanse": "Nettgiro til: BERGEN PARKERIN Betalt: 20.04.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1419,
      "placeId": "ChIJOVqbla7-PEYRZOAnHkSKO6A",
      "placeName": "Bergen Parking AS",
      "coordinates": "60.3870826,5.3313938",
      "website": "http://www.bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1229116702,
    "accountNumber": "35302464616",
    "date": "2016-04-20",
    "amount": "-4363.00",
    "text": "Bergen Bolig Og",
    "alfareferanse": "Nettgiro til: BERGEN BOLIG OG Betalt: 20.04.16",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1406,
      "placeId": "ChIJ2b9ilqj-PEYRf4QCHhL56WU",
      "placeName": "Bergen Housing AS",
      "coordinates": "60.3932937,5.3293907",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230558255,
    "accountNumber": "35302464616",
    "date": "2016-04-19",
    "amount": "-4105.00",
    "text": "Synsam Åsane Senter Ulset",
    "alfareferanse": "19.04 12853 SYNSAM ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Optiker",
    "imageId": "",
    "place": {
      "id": 897,
      "placeId": "ChIJ-9E2lvr9PEYRol8HQWzsynQ",
      "placeName": "Synsam Åsane Storsenter",
      "coordinates": "60.4652874,5.3227278",
      "website": "https://www.synsam.no/optiker/synsam-%C3%A5sane-storsenter/202",
      "logoId": 185,
      "merchantName": "Synsam",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233864040,
    "accountNumber": "35302464616",
    "date": "2016-04-19",
    "amount": "-88.00",
    "text": "Hella Servering Åsane Senter Ulset",
    "alfareferanse": "19.04 HELLA SERVERING ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 898,
      "placeId": "ChIJE8vqqPr9PEYR18CWVdK7RGU",
      "placeName": "Hella servering V/Berit Hella",
      "coordinates": "60.4650789,5.3212323",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231647264,
    "accountNumber": "35302464616",
    "date": "2016-04-19",
    "amount": "-352.00",
    "text": "Cafe Marino As Åsane Senter Ulset",
    "alfareferanse": "19.04 CAFE MARINO AS  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 746,
      "placeId": "ChIJMc6UmPr9PEYRNc9JWl46Xe0",
      "placeName": "Grand Caffé Venezia avd. Åsane Senter",
      "coordinates": "60.4663844,5.3225102",
      "website": "http://veneziapizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1232197652,
    "accountNumber": "35302464616",
    "date": "2016-04-19",
    "amount": "-245.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "18.04 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235738872,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-109.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.04 NOK 109.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1236121451,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.04 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231706994,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-339.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "16.04 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1232128610,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-253.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "17.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234317191,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-62.00",
    "text": "Starbucks Neumannsgate Bergen",
    "alfareferanse": "17.04 6109 STARBUCKS  NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 704,
      "placeId": "ChIJQWMabgL8PEYReOR7bM-sglQ",
      "placeName": "Starbucks Neumann street",
      "coordinates": "60.3907327,5.3214611",
      "website": "http://www.starbucks.no/store/1008256/",
      "logoId": 119,
      "merchantName": "Starbucks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231748611,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-328.00",
    "text": "Egon Bristol Torgalm. 11 Bergen",
    "alfareferanse": "17.04 EGON BRISTOL TORGALM. 11  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 878,
      "placeId": "ChIJuYPZJ6j-PEYRzic3O2MvbPw",
      "placeName": "Egon",
      "coordinates": "60.3925896,5.3228722",
      "website": "http://www.egon.no/restauranter/bristol",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235094567,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-25.00",
    "text": "Egon Bristol Torgalm. 11 Bergen",
    "alfareferanse": "17.04 EGON BRISTOL TORGALM. 11  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 878,
      "placeId": "ChIJuYPZJ6j-PEYRzic3O2MvbPw",
      "placeName": "Egon",
      "coordinates": "60.3925896,5.3228722",
      "website": "http://www.egon.no/restauranter/bristol",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234327063,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-61.20",
    "text": "Coop Extra Skareveien 1 Mathopen",
    "alfareferanse": "16.04 COOP EXTRA SKAREVEIEN 1 MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 899,
      "placeId": "ChIJM76lv-b6PEYRg29QdF3BMSs",
      "placeName": "EXTRA",
      "coordinates": "60.3336941,5.2118852",
      "website": "https://coop.no/butikker/extra/mathopen-4345/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231721737,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-335.00",
    "text": "Dolly Dimple's Åsane Senter Ulset",
    "alfareferanse": "15.04 DOLLY DIMPLE'S  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 900,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230507029,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-200.12",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "16.04 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234288291,
    "accountNumber": "35302464616",
    "date": "2016-04-18",
    "amount": "-64.00",
    "text": "Coop Prix Ulset Hesthaugvn.3 Ulset",
    "alfareferanse": "15.04 COOP PRIX ULSET HESTHAUGVN.3 ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 902,
      "placeId": "ChIJ77gwue_9PEYRR2t-PGEtnKM",
      "placeName": "Coop Prix Ulset",
      "coordinates": "60.463101,5.3160849",
      "website": "https://coop.no/butikker/prix/ulset-4579/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230506063,
    "accountNumber": "35302464616",
    "date": "2016-04-15",
    "amount": "-200.18",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "15.04 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1227560328,
    "accountNumber": "35302464616",
    "date": "2016-04-15",
    "amount": "-464.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "14.04 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233682496,
    "accountNumber": "35302464616",
    "date": "2016-04-15",
    "amount": "-99.00",
    "text": "H&M No0747 Galleriet Ol Bergen",
    "alfareferanse": "15.04 H&M NO0747 GALLERIET OL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1278,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231467533,
    "accountNumber": "35302464616",
    "date": "2016-04-15",
    "amount": "-400.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "15.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1232213713,
    "accountNumber": "35302464616",
    "date": "2016-04-14",
    "amount": "-241.69",
    "text": "Rema Danmarkspl Fjøsangervei Bergen",
    "alfareferanse": "13.04 REMA DANMARKSPL FJØSANGERVEI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 725,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1232223174,
    "accountNumber": "35302464616",
    "date": "2016-04-13",
    "amount": "-240.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "12.04 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1235956187,
    "accountNumber": "35302464616",
    "date": "2016-04-13",
    "amount": "-49.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 11.04 NOK 49.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1231394700,
    "accountNumber": "35302464616",
    "date": "2016-04-12",
    "amount": "-429.59",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "11.04 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1229163263,
    "accountNumber": "35302464616",
    "date": "2016-04-11",
    "amount": "-2111.00",
    "text": "Bergen Bolig Og",
    "alfareferanse": "Nettgiro til: BERGEN BOLIG OG Betalt: 09.04.16",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Husleie",
    "imageId": "",
    "place": {
      "id": 1406,
      "placeId": "ChIJ2b9ilqj-PEYRf4QCHhL56WU",
      "placeName": "Bergen Housing AS",
      "coordinates": "60.3932937,5.3293907",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233659401,
    "accountNumber": "35302464616",
    "date": "2016-04-11",
    "amount": "-99.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "08.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1232351397,
    "accountNumber": "35302464616",
    "date": "2016-04-08",
    "amount": "-221.60",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "07.04 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230770664,
    "accountNumber": "35302464616",
    "date": "2016-04-08",
    "amount": "-985.65",
    "text": "132 Kid Interiø Vestkanten S Loddefjord",
    "alfareferanse": "07.04 132 KID INTERIØ VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 903,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1230957071,
    "accountNumber": "35302464616",
    "date": "2016-04-06",
    "amount": "-695.00",
    "text": "Synsam Åsane Senter Ulset",
    "alfareferanse": "05.04 12853 SYNSAM ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Optiker",
    "imageId": "",
    "place": {
      "id": 897,
      "placeId": "ChIJ-9E2lvr9PEYRol8HQWzsynQ",
      "placeName": "Synsam Åsane Storsenter",
      "coordinates": "60.4652874,5.3227278",
      "website": "https://www.synsam.no/optiker/synsam-%C3%A5sane-storsenter/202",
      "logoId": 185,
      "merchantName": "Synsam",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1234813373,
    "accountNumber": "35302464616",
    "date": "2016-04-05",
    "amount": "-38.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "05.04 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1229298142,
    "accountNumber": "35302465310",
    "date": "2016-04-04",
    "amount": "-500.00",
    "text": "Bergen Parkerin",
    "alfareferanse": "Nettgiro til: BERGEN PARKERIN Betalt: 04.04.16",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1419,
      "placeId": "ChIJOVqbla7-PEYRZOAnHkSKO6A",
      "placeName": "Bergen Parking AS",
      "coordinates": "60.3870826,5.3313938",
      "website": "http://www.bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1227536834,
    "accountNumber": "35302464616",
    "date": "2016-04-04",
    "amount": "-175.00",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "01.04 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1233078689,
    "accountNumber": "35302464616",
    "date": "2016-04-04",
    "amount": "-143.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "01.04 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1216177575,
    "accountNumber": "35302464616",
    "date": "2016-03-31",
    "amount": "-1504.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1225606622,
    "accountNumber": "35302464616",
    "date": "2016-03-31",
    "amount": "-58.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         29 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220290602,
    "accountNumber": "35302464616",
    "date": "2016-03-30",
    "amount": "-329.09",
    "text": "Rema Helleveien Søndre Øyjor Bergen",
    "alfareferanse": "29.03 REMA HELLEVEIEN SØNDRE ØYJOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 820,
      "placeId": "ChIJQR5c_jP8PEYRFWJK-GSOfmY",
      "placeName": "REMA 1000",
      "coordinates": "60.4245179,5.3034795",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220312709,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-324.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "24.03 BURGER KING. STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220936847,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-221.00",
    "text": "Daniel Rullekeb Gravdalsv.11 Laksevåg",
    "alfareferanse": "25.03 DANIEL RULLEKEB GRAVDALSV.11 LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 469,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222164820,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-108.60",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "23.03 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219340924,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-820.02",
    "text": "Rema Lynghaugpa Dag Hammersk Fyllingsdalen",
    "alfareferanse": "23.03 REMA LYNGHAUGPA DAG HAMMERSK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 494,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1223447534,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-40.00",
    "text": "Vitusapotek Nor Strømgt 8 Bergen",
    "alfareferanse": "24.03 VITUSAPOTEK NOR STRØMGT 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 484,
      "placeId": "ChIJW-e2tK7-PEYRGP_JiUKGoXs",
      "placeName": "Vitusapotek Nordstjernen - Bergen",
      "coordinates": "60.3893095,5.3325248",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Nordstjernen-Bergen/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1223794971,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-24.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "24.03 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1224012764,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-5.40",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "28.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1218961718,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-250.28",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "23.03 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219902620,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-437.00",
    "text": "Little Ea Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.03 1602 LITTLE EA  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 587,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222678863,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-79.90",
    "text": "H&M No0854 Oasen Kjøpes Fyllingsdalen",
    "alfareferanse": "23.03 H&M NO0854 OASEN KJØPES FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 564,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222205976,
    "accountNumber": "35302464616",
    "date": "2016-03-29",
    "amount": "-105.78",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.03 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1223351359,
    "accountNumber": "35302464616",
    "date": "2016-03-23",
    "amount": "-45.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "22.03 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219320776,
    "accountNumber": "35302464616",
    "date": "2016-03-23",
    "amount": "-851.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "22.03 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222371316,
    "accountNumber": "35302464616",
    "date": "2016-03-22",
    "amount": "-97.90",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "22.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221526787,
    "accountNumber": "35302464616",
    "date": "2016-03-22",
    "amount": "-158.70",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "21.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222612988,
    "accountNumber": "35302464616",
    "date": "2016-03-22",
    "amount": "-83.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "21.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1224451273,
    "accountNumber": "35302464616",
    "date": "2016-03-22",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 20.03 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221691282,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-144.80",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "20.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1215610149,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-618.00",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "19.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220564859,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-278.00",
    "text": "Bunnpris Welhavensgt. Bergen",
    "alfareferanse": "19.03 BUNNPRIS WELHAVENSGT. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 682,
      "placeId": "ChIJIQfmg6r-PEYRR6chbdWUJLo",
      "placeName": "Bunnpris - Welhavensgate",
      "coordinates": "60.3853932,5.3220494",
      "website": "http://bunnpris.no/",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220612768,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-269.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "19.03 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1215704151,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-243.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "18.03 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221901702,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-127.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "19.03 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219051963,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-2404.73",
    "text": "Expert Åsane Åsane Senter Ulset",
    "alfareferanse": "18.03 EXPERT ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 905,
      "placeId": "ChIJ5yCEZ_r9PEYR7tt-P3NRymY",
      "placeName": "POWER Åsane",
      "coordinates": "60.4685242,5.3247385",
      "website": "https://www.power.no/butikk/power-aasane-senter/s-1101/",
      "logoId": 186,
      "merchantName": "POWER",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220285972,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-330.00",
    "text": "Årstad Pizzeria Børnsons Gt Bergen",
    "alfareferanse": "20.03 ÅRSTAD PIZZERIA BØRNSONS GT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 906,
      "placeId": "ChIJOxdIJk_5PEYRWJBPFscXGyI",
      "placeName": "Årstad Pizzeria",
      "coordinates": "60.373177,5.3439855",
      "website": "https://www.just-eat.no/restaurants-arstad-pizza",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219002649,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-82.75",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "20.03 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1215580674,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-1121.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "19.03 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221086351,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-200.19",
    "text": "Rema Helleveien Søndre Øyjor Bergen",
    "alfareferanse": "19.03 REMA HELLEVEIEN SØNDRE ØYJOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 820,
      "placeId": "ChIJQR5c_jP8PEYRFWJK-GSOfmY",
      "placeName": "REMA 1000",
      "coordinates": "60.4245179,5.3034795",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219684823,
    "accountNumber": "35302464616",
    "date": "2016-03-21",
    "amount": "-528.80",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "19.03 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220368065,
    "accountNumber": "35302464616",
    "date": "2016-03-18",
    "amount": "-312.79",
    "text": "Rema Helleveien Søndre Øyjor Bergen",
    "alfareferanse": "17.03 REMA HELLEVEIEN SØNDRE ØYJOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 820,
      "placeId": "ChIJQR5c_jP8PEYRFWJK-GSOfmY",
      "placeName": "REMA 1000",
      "coordinates": "60.4245179,5.3034795",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220212515,
    "accountNumber": "35302464616",
    "date": "2016-03-18",
    "amount": "-349.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "17.03 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221260403,
    "accountNumber": "35302464616",
    "date": "2016-03-18",
    "amount": "-186.50",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "17.03 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1218989830,
    "accountNumber": "35302464616",
    "date": "2016-03-18",
    "amount": "-150.76",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "17.03 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221901470,
    "accountNumber": "35302464616",
    "date": "2016-03-18",
    "amount": "-127.00",
    "text": "Burger King 500 Strandgt. 5",
    "alfareferanse": "18.03 BURGER KING 500 STRANDGT. 5",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 907,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222745748,
    "accountNumber": "35302464616",
    "date": "2016-03-18",
    "amount": "-76.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "18.03 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219029791,
    "accountNumber": "35302464616",
    "date": "2016-03-18",
    "amount": "-3612.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "17.03 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221890661,
    "accountNumber": "35302464616",
    "date": "2016-03-17",
    "amount": "-128.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "17.03 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222817228,
    "accountNumber": "35302464616",
    "date": "2016-03-17",
    "amount": "-72.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "17.03 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1218941980,
    "accountNumber": "35302464616",
    "date": "2016-03-17",
    "amount": "-302.68",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "17.03 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1224892613,
    "accountNumber": "35302464616",
    "date": "2016-03-17",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.03 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1224429295,
    "accountNumber": "35302464616",
    "date": "2016-03-17",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 13.03 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1224796354,
    "accountNumber": "35302464616",
    "date": "2016-03-17",
    "amount": "-30.00",
    "text": "Samferdselsetat",
    "alfareferanse": "*1018 11.03 NOK 30.00 SAMFERDSELSETAT Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 908,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1224730551,
    "accountNumber": "35302464616",
    "date": "2016-03-17",
    "amount": "-41.00",
    "text": "Samferdselsetat",
    "alfareferanse": "*1018 11.03 NOK 41.00 SAMFERDSELSETAT Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 908,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1223527725,
    "accountNumber": "35302464616",
    "date": "2016-03-16",
    "amount": "-36.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "16.03 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220222389,
    "accountNumber": "35302464616",
    "date": "2016-03-16",
    "amount": "-347.02",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "15.03 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220612253,
    "accountNumber": "35302464616",
    "date": "2016-03-16",
    "amount": "-269.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "16.03 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1223803720,
    "accountNumber": "35302464616",
    "date": "2016-03-16",
    "amount": "-23.50",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "16.03 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221610234,
    "accountNumber": "35302464616",
    "date": "2016-03-15",
    "amount": "-150.00",
    "text": "Apotek 1 Horiso Myrdalsv.2 Nyborg",
    "alfareferanse": "14.03 APOTEK 1 HORISO MYRDALSV.2 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 909,
      "placeId": "ChIJjfYFde8-TUYReHYol1hFewc",
      "placeName": "Nyborg Apotek",
      "coordinates": "55.3115103,10.7898857",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219409031,
    "accountNumber": "35302464616",
    "date": "2016-03-15",
    "amount": "-740.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "14.03 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220426978,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-300.00",
    "text": "Et Hårstudio Ei Vollane 1 Eidsvåg I Åsa",
    "alfareferanse": "12.03 ET HÅRSTUDIO EI VOLLANE 1 EIDSVÅG I ÅSA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 857,
      "placeId": "ChIJvbQMnNH9PEYRychR6rmZ_p8",
      "placeName": "ET Hårstudio Eidsvåg",
      "coordinates": "60.436588,5.3190309",
      "website": "http://www.ethaarstudio.no/",
      "logoId": 175,
      "merchantName": "ET Haarstudio",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220218342,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-348.00",
    "text": "Gina Tricot Ber Sane Center Ulset",
    "alfareferanse": "12.03 GINA TRICOT BER SANE CENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 849,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219580764,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-595.70",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "12.03 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221133978,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-199.00",
    "text": "H&M No0849 Åsane Senter Ulset",
    "alfareferanse": "12.03 H&M NO0849 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 807,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221609354,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-150.00",
    "text": "Fincken - Det L Nygårdsgt. 2 Bergen",
    "alfareferanse": "13.03 FINCKEN - DET L NYGÅRDSGT. 2 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 805,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221174377,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-196.00",
    "text": "Trg Norge As Åsene Storse Ulset",
    "alfareferanse": "12.03 TRG NORGE AS ÅSENE STORSE ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 910,
      "placeId": "ChIJLc6svPr9PEYRfqz1Eoow7K8",
      "placeName": "H&M",
      "coordinates": "60.4661421,5.3227179",
      "website": "http://www.hm.com/no/",
      "logoId": 187,
      "merchantName": "H&M",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221028546,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-209.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "12.03 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1218958275,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-257.40",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "13.03 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222605838,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-84.00",
    "text": "Hella Servering Åsane Senter Ulset",
    "alfareferanse": "12.03 HELLA SERVERING ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 898,
      "placeId": "ChIJE8vqqPr9PEYR18CWVdK7RGU",
      "placeName": "Hella servering V/Berit Hella",
      "coordinates": "60.4650789,5.3212323",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219904510,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-436.00",
    "text": "Bryggen Pianoba Bryggen Bergen",
    "alfareferanse": "13.03 BRYGGEN PIANOBA BRYGGEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1418,
      "placeId": "ChIJhbLjnR38PEYRCui64uRFigE",
      "placeName": "Bryggen Pianobar AS",
      "coordinates": "60.3975921,5.3223474",
      "website": "http://bryggenpianobar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220646849,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-262.00",
    "text": "Christiania Tax Sandviksvn.1 Bergen",
    "alfareferanse": "13.03 CHRISTIANIA TAX SANDVIKSVN.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 569,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1221924307,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-125.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "13.03 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222162938,
    "accountNumber": "35302464616",
    "date": "2016-03-14",
    "amount": "-108.62",
    "text": "Rema Kjøkkelvik Nedbergeveie Loddefjord",
    "alfareferanse": "14.03 REMA KJØKKELVIK NEDBERGEVEIE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 467,
      "placeId": "ChIJPVpZaWP7PEYRABRW0d026zk",
      "placeName": "REMA 1000",
      "coordinates": "60.381785,5.2293013",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1218967570,
    "accountNumber": "35302464616",
    "date": "2016-03-11",
    "amount": "-222.52",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "10.03 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222848817,
    "accountNumber": "35302464616",
    "date": "2016-03-11",
    "amount": "-70.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "11.03 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219691064,
    "accountNumber": "35302464616",
    "date": "2016-03-10",
    "amount": "-525.00",
    "text": "Kicks 674 Loddefjordve Loddefjord",
    "alfareferanse": "10.03 KICKS 674 LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 854,
      "placeId": "ChIJXQ2K0gz7PEYRp_GUvflkmqA",
      "placeName": "KICKS",
      "coordinates": "60.363224,5.233616",
      "website": "https://www.kicks.no/butikker/loddefjord-vestkanten/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1223988907,
    "accountNumber": "35302464616",
    "date": "2016-03-10",
    "amount": "-10.00",
    "text": "129 Boots Apotek Loddefjord",
    "alfareferanse": "10.03 129 BOOTS APOTEK LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 698,
      "placeId": "ChIJo0lIzQz7PEYRXzUfwZa7nWA",
      "placeName": "Boots apotek Loddefjord",
      "coordinates": "60.36309,5.233356",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220377906,
    "accountNumber": "35302464616",
    "date": "2016-03-10",
    "amount": "-310.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "09.03 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222864693,
    "accountNumber": "35302464616",
    "date": "2016-03-10",
    "amount": "-69.90",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "10.03 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219634966,
    "accountNumber": "35302464616",
    "date": "2016-03-09",
    "amount": "-557.30",
    "text": "H&M No0849 Åsane Senter Ulset",
    "alfareferanse": "09.03 H&M NO0849 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 807,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222847434,
    "accountNumber": "35302464616",
    "date": "2016-03-09",
    "amount": "-70.00",
    "text": "Cubus 82B Myrdalsvegen Nyborg",
    "alfareferanse": "09.03 CUBUS 82B MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 915,
      "placeId": "ChIJ43aFEPr9PEYReAOxpK159oM",
      "placeName": "Cubus",
      "coordinates": "60.4699647,5.322702",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/82B/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220548166,
    "accountNumber": "35302464616",
    "date": "2016-03-09",
    "amount": "-280.00",
    "text": "Ark Åsane Stors Åsane Senter Ulset",
    "alfareferanse": "09.03 ARK ÅSANE STORS ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 913,
      "placeId": "ChIJ1RP5sfr9PEYRHPmEGDjt_bg",
      "placeName": "Ark Beyer Åsane",
      "coordinates": "60.4649722,5.3217089",
      "website": "http://www.ark.no/",
      "logoId": 188,
      "merchantName": "Ark Beyer",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1222847167,
    "accountNumber": "35302464616",
    "date": "2016-03-09",
    "amount": "-70.00",
    "text": "Big Bite 322 Myrdalsvegen 5130 Nyborg",
    "alfareferanse": "09.03 BIG BITE 322 MYRDALSVEGEN 5130 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 914,
      "placeId": "ChIJq3eFEPr9PEYReCP0kl8cLHk",
      "placeName": "Big Bite",
      "coordinates": "60.4699227,5.3225459",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1220091437,
    "accountNumber": "35302464616",
    "date": "2016-03-08",
    "amount": "-380.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "07.03 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219886567,
    "accountNumber": "35302464616",
    "date": "2016-03-07",
    "amount": "-443.39",
    "text": "Bunnpris Ulveda Ulvedalen To Flaktveit",
    "alfareferanse": "06.03 BUNNPRIS ULVEDA ULVEDALEN TO FLAKTVEIT",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 875,
      "placeId": "ChIJeSlWRhr-PEYRPn7QA7VLd5E",
      "placeName": "Bunnpris Ulvedalen",
      "coordinates": "60.4648761,5.3621029",
      "website": "http://bunnpris.no/butikker/bunnpris-ulvedalen",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1219737667,
    "accountNumber": "35302464616",
    "date": "2016-03-07",
    "amount": "-500.00",
    "text": "Akvariet I Berg Nordnesbakke Bergen",
    "alfareferanse": "05.03 AKVARIET I BERG NORDNESBAKKE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 647,
      "placeId": "ChIJ6TU-lBD8PEYR63yf2sh2Ekc",
      "placeName": "Akvariet i Bergen - Det Nasjonale Akvariet",
      "coordinates": "60.3996913,5.303371",
      "website": "http://www.akvariet.no/?utm_source=googlemybusiness&utm_campaign=Google%20Min%20Bedrift&utm_medium=organic",
      "logoId": 102,
      "merchantName": "Akvariet i Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207763771,
    "accountNumber": "35302464616",
    "date": "2016-03-01",
    "amount": "-749.00",
    "text": "Elkjøp Gullgruv Liamyrane 1 Bergen",
    "alfareferanse": "01.03 ELKJØP GULLGRUV LIAMYRANE 1  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 628,
      "placeId": "ChIJF11QJf_9PEYRj19E_h1tbc0",
      "placeName": "Elkjøp Megastore Gullgruven",
      "coordinates": "60.4726214,5.3329832",
      "website": "http://www.elkjop.no/",
      "logoId": 97,
      "merchantName": "Elkjøp ",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207592282,
    "accountNumber": "35302464616",
    "date": "2016-03-01",
    "amount": "-1083.98",
    "text": "Rema Drotningsv Drotningsvik Godvik",
    "alfareferanse": "29.02 REMA DROTNINGSV DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 505,
      "placeId": "ChIJo3cCmkz7PEYRS5O0T1uCot0",
      "placeName": "REMA 1000",
      "coordinates": "60.3719928,5.180246",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1212752798,
    "accountNumber": "35302464616",
    "date": "2016-03-01",
    "amount": "-80.00",
    "text": "Nintendo Of Europe",
    "alfareferanse": "*1018 27.02 NOK 80.00 NINTENDO OF EUROPE Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Spill og programvare",
    "imageId": "",
    "place": {
      "id": 731,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1213704824,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-52.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         26 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208955904,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-261.90",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "26.02 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211834681,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-31.00",
    "text": "Narvesen 671 Ø.Flesland Bergen",
    "alfareferanse": "26.02 NARVESEN 671 Ø.FLESLAND BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 916,
      "placeId": "ChIJbfWDa37wPEYRe40WpHT0OSk",
      "placeName": "Narvesen",
      "coordinates": "60.2891313,5.2280343",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207877063,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-630.00",
    "text": "Estee Lauder Co Oslo City, S Oslo",
    "alfareferanse": "27.02 ESTEE LAUDER CO OSLO CITY, S OSLO",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 917,
      "placeId": "ChIJoa0NMGJuQUYR2D9lu0kifDw",
      "placeName": "Oslo City",
      "coordinates": "59.9126918,10.752917",
      "website": "https://oslo-city.steenstrom.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209412370,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-199.80",
    "text": "Glitter Karl Johansg Oslo",
    "alfareferanse": "27.02 GLITTER KARL JOHANSG OSLO",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 918,
      "placeId": "ChIJGVdp5oluQUYRpdlXhKeURa0",
      "placeName": "Glitter",
      "coordinates": "59.9116701,10.748316",
      "website": "https://www.glitter.no/storelocator/Glitter-Oslo-Arkaden",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209438900,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-199.00",
    "text": "Bik Bok 603 Arkaden Oslo",
    "alfareferanse": "27.02 BIK BOK 603 ARKADEN OSLO",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 919,
      "placeId": "ChIJqfE4nWJuQUYRT_qNYpTusP4",
      "placeName": "BIK BOK",
      "coordinates": "59.9116546,10.7478762",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/603/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209569991,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-184.00",
    "text": "Nsb Osl Tvm Edv.Munchsv. Gardermoen",
    "alfareferanse": "26.02 NSB OSL TVM EDV.MUNCHSV. GARDERMOEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 920,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211462990,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-50.00",
    "text": "Starbucks Landside 973 Gardermoen",
    "alfareferanse": "26.02 STARBUCKS LANDSIDE 973 GARDERMOEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 921,
      "placeId": "ChIJUZgGipCDQUYR6TAQPkoeeNk",
      "placeName": "Starbucks",
      "coordinates": "60.19386,11.098711",
      "website": "http://www.starbucks.no/store/1016217/",
      "logoId": 119,
      "merchantName": "Starbucks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209410784,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-199.90",
    "text": "Vinmonopolet Os Oslo City Oslo",
    "alfareferanse": "27.02 VINMONOPOLET OS OSLO CITY OSLO",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 922,
      "placeId": "ChIJF7zQe9JtQUYRjl1rLM1PoDg",
      "placeName": "Vinmonopolet Oslo, Frogner",
      "coordinates": "59.9188589,10.7073206",
      "website": "https://www.vinmonopolet.no/store/111",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209569997,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-184.00",
    "text": "Nsb Oslo Tvm Jernbanet.1 Oslo",
    "alfareferanse": "28.02 NSB OSLO TVM JERNBANET.1  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 923,
      "placeId": "ChIJOT0lHIpuQUYRV5KiJTS3la4",
      "placeName": "NSB AS Oslo S",
      "coordinates": "59.910789,10.7515024",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1210601248,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-99.00",
    "text": "Zara Karl Johan Karl Johans Oslo",
    "alfareferanse": "27.02 ZARA KARL JOHAN KARL JOHANS  OSLO",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 924,
      "placeId": "ChIJNYVbUH1uQUYRLJkPCCTe0io",
      "placeName": "Zara Karl Johan",
      "coordinates": "59.9133081,10.7410041",
      "website": "http://www.zara.com/no",
      "logoId": 190,
      "merchantName": "Zara",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209481385,
    "accountNumber": "35302464616",
    "date": "2016-02-29",
    "amount": "-194.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "29.02 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1203595566,
    "accountNumber": "35302464616",
    "date": "2016-02-26",
    "amount": "6935.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "Fra: GJENSIDIGE FORSIKRING ASA Betalt: 26.02.16",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211458826,
    "accountNumber": "35302464616",
    "date": "2016-02-26",
    "amount": "-50.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "25.02 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211194009,
    "accountNumber": "35302464616",
    "date": "2016-02-26",
    "amount": "-64.00",
    "text": "Big Bite 320 Vestkanten S Loddefjord",
    "alfareferanse": "25.02 BIG BITE 320 VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 925,
      "placeId": "ChIJXQ2K0gz7PEYRU3MSsqTL4ik",
      "placeName": "Big Bite",
      "coordinates": "60.3625521,5.2340884",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207401010,
    "accountNumber": "35302464616",
    "date": "2016-02-26",
    "amount": "-153.36",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "26.02 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211834256,
    "accountNumber": "35302464616",
    "date": "2016-02-26",
    "amount": "-31.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "26.02 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208780853,
    "accountNumber": "35302464616",
    "date": "2016-02-26",
    "amount": "-298.00",
    "text": "Cubus 878 Vestkanten Loddefjord",
    "alfareferanse": "25.02 CUBUS 878 VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 701,
      "placeId": "ChIJo0lIzQz7PEYRzyoTFXsHUCA",
      "placeName": "Cubus",
      "coordinates": "60.3633973,5.2330705",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/878/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209272286,
    "accountNumber": "35302464616",
    "date": "2016-02-25",
    "amount": "-216.70",
    "text": "129 Boots Apotek Loddefjord",
    "alfareferanse": "24.02 129 BOOTS APOTEK LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 698,
      "placeId": "ChIJo0lIzQz7PEYRXzUfwZa7nWA",
      "placeName": "Boots apotek Loddefjord",
      "coordinates": "60.36309,5.233356",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208855188,
    "accountNumber": "35302464616",
    "date": "2016-02-25",
    "amount": "-281.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "24.02 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208340727,
    "accountNumber": "35302464616",
    "date": "2016-02-25",
    "amount": "-399.95",
    "text": "Vero Moda .",
    "alfareferanse": "24.02 VERO MODA 176244 .",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1273,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209329461,
    "accountNumber": "35302464616",
    "date": "2016-02-25",
    "amount": "-208.50",
    "text": "Kappahl 313 Ves Loddefjordvn Loddefjord",
    "alfareferanse": "24.02 KAPPAHL 313 VES LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 609,
      "placeId": "ChIJo0lIzQz7PEYR3xamyWBuNq8",
      "placeName": "KappAhl",
      "coordinates": "60.3625506,5.2340934",
      "website": "http://www.kappahl.com/en-US/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211150079,
    "accountNumber": "35302464616",
    "date": "2016-02-25",
    "amount": "-66.40",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "24.02 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208416951,
    "accountNumber": "35302464616",
    "date": "2016-02-24",
    "amount": "-380.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "23.02 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209084077,
    "accountNumber": "35302464616",
    "date": "2016-02-24",
    "amount": "-243.30",
    "text": "Coop Prix Ulset Hesthaugvn.3 Ulset",
    "alfareferanse": "23.02 COOP PRIX ULSET HESTHAUGVN.3 ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 902,
      "placeId": "ChIJ77gwue_9PEYRR2t-PGEtnKM",
      "placeName": "Coop Prix Ulset",
      "coordinates": "60.463101,5.3160849",
      "website": "https://coop.no/butikker/prix/ulset-4579/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1212619437,
    "accountNumber": "35302464616",
    "date": "2016-02-23",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 20.02 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209838467,
    "accountNumber": "35302464616",
    "date": "2016-02-22",
    "amount": "-155.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "22.02 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209465898,
    "accountNumber": "35302464616",
    "date": "2016-02-22",
    "amount": "-196.00",
    "text": "Vita Åsane Senter Ulset",
    "alfareferanse": "20.02 1601 VITA ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 926,
      "placeId": "ChIJm0qxovr9PEYRLWqUX5supVw",
      "placeName": "Vita",
      "coordinates": "60.464841,5.321676",
      "website": "https://www.vita.no/butikker/vita-sane-storsenter",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1213037660,
    "accountNumber": "35302464616",
    "date": "2016-02-22",
    "amount": "-10.65",
    "text": "Coop Hordaland Sa",
    "alfareferanse": "*1018 18.02 NOK 10.65 Coop Hordaland SA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 927,
      "placeId": "ChIJd5vtSnb6PEYR2WrdDdtTIYs",
      "placeName": "Coop Hordaland Sa",
      "coordinates": "60.2943127,5.2532398",
      "website": "https://coop.no/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208290708,
    "accountNumber": "35302464616",
    "date": "2016-02-22",
    "amount": "-415.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "21.02 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209143819,
    "accountNumber": "35302464616",
    "date": "2016-02-22",
    "amount": "-235.00",
    "text": "Kicks 675 Åsane Senter Ulset",
    "alfareferanse": "20.02 KICKS 675 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 868,
      "placeId": "ChIJ1RP5sfr9PEYR9RNWaC99jzQ",
      "placeName": "KICKS",
      "coordinates": "60.4654946,5.3214737",
      "website": "https://www.kicks.no/butikker/ulset-asane-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208193388,
    "accountNumber": "35302464616",
    "date": "2016-02-22",
    "amount": "-450.59",
    "text": "Rema Helleveien Søndre Øyjor Bergen",
    "alfareferanse": "20.02 REMA HELLEVEIEN SØNDRE ØYJOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 820,
      "placeId": "ChIJQR5c_jP8PEYRFWJK-GSOfmY",
      "placeName": "REMA 1000",
      "coordinates": "60.4245179,5.3034795",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211018845,
    "accountNumber": "35302464616",
    "date": "2016-02-19",
    "amount": "-74.00",
    "text": "Big Bite 322 Myrdalsvegen 5130 Nyborg",
    "alfareferanse": "18.02 BIG BITE 322 MYRDALSVEGEN 5130 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 914,
      "placeId": "ChIJq3eFEPr9PEYReCP0kl8cLHk",
      "placeName": "Big Bite",
      "coordinates": "60.4699227,5.3225459",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207487434,
    "accountNumber": "35302464616",
    "date": "2016-02-19",
    "amount": "-1730.28",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "18.02 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1212530867,
    "accountNumber": "35302464616",
    "date": "2016-02-16",
    "amount": "-190.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.02 NOK 190.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1213045811,
    "accountNumber": "35302464616",
    "date": "2016-02-16",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.02 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1210541758,
    "accountNumber": "35302464616",
    "date": "2016-02-16",
    "amount": "-100.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "15.02 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1210540496,
    "accountNumber": "35302464616",
    "date": "2016-02-15",
    "amount": "-100.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "13.02 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208218871,
    "accountNumber": "35302464616",
    "date": "2016-02-15",
    "amount": "-443.79",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "13.02 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209987953,
    "accountNumber": "35302464616",
    "date": "2016-02-15",
    "amount": "-142.15",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "13.02 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207384781,
    "accountNumber": "35302464616",
    "date": "2016-02-15",
    "amount": "-207.18",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "15.02 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211318446,
    "accountNumber": "35302464616",
    "date": "2016-02-15",
    "amount": "-57.00",
    "text": "Solbrød Åsane Kokstaddalen Kokstad",
    "alfareferanse": "15.02 SOLBRØD ÅSANE KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 928,
      "placeId": "ChIJjRGEuPr9PEYRLCZbrNaYAuk",
      "placeName": "Solbrød Åsane senter",
      "coordinates": "60.4650338,5.3218943",
      "website": "http://www.solbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211318085,
    "accountNumber": "35302464616",
    "date": "2016-02-12",
    "amount": "-57.00",
    "text": "Subway Åsane Åsane Senter Ulset",
    "alfareferanse": "11.02 SUBWAY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 929,
      "placeId": "ChIJD2Huq_r9PEYRijLXhtSf7eg",
      "placeName": "Subway Åsane Storsenter",
      "coordinates": "60.4647506,5.3215871",
      "website": "http://www.subway.no/",
      "logoId": 192,
      "merchantName": "Subway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207832504,
    "accountNumber": "35302464616",
    "date": "2016-02-12",
    "amount": "-672.75",
    "text": "Lindex 202 Åsane Senter Ulset",
    "alfareferanse": "11.02 LINDEX 202 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 930,
      "placeId": "ChIJyeou6vr9PEYR1YOo7_IoIVw",
      "placeName": "Lindex",
      "coordinates": "60.4659334,5.32293",
      "website": "http://www.lindex.com/",
      "logoId": 178,
      "merchantName": "Lindex",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209391044,
    "accountNumber": "35302464616",
    "date": "2016-02-12",
    "amount": "-200.00",
    "text": "Brun Og Blid Åsane Senter Ulset",
    "alfareferanse": "11.02 BRUN OG BLID ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 629,
      "placeId": "ChIJDcFBlfr9PEYRHtNO42IKqO8",
      "placeName": "Baker Brun Arken",
      "coordinates": "60.4658371,5.324458",
      "website": "http://www.bakerbrun.no/hvor-er-vi/arken-asane-senter/",
      "logoId": 98,
      "merchantName": "Baker Brun",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1212008561,
    "accountNumber": "35302464616",
    "date": "2016-02-12",
    "amount": "-23.00",
    "text": "Coop Post I Butikk Bergen",
    "alfareferanse": "12.02 COOP POST I BUTIKK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 637,
      "placeId": "ChIJz0ss26j-PEYRLUffHD0BHiM",
      "placeName": "Marken Post i Butikk",
      "coordinates": "60.3921045,5.3301831",
      "website": "https://www.posten.no/",
      "logoId": 100,
      "merchantName": "Posten",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207695418,
    "accountNumber": "35302464616",
    "date": "2016-02-11",
    "amount": "-846.00",
    "text": "Kicks 675 Åsane Senter Ulset",
    "alfareferanse": "11.02 KICKS 675 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 868,
      "placeId": "ChIJ1RP5sfr9PEYR9RNWaC99jzQ",
      "placeName": "KICKS",
      "coordinates": "60.4654946,5.3214737",
      "website": "https://www.kicks.no/butikker/ulset-asane-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207400495,
    "accountNumber": "35302464616",
    "date": "2016-02-10",
    "amount": "-156.64",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "09.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211248286,
    "accountNumber": "35302464616",
    "date": "2016-02-10",
    "amount": "-60.00",
    "text": "Mcdonald's Vestk. Loddefjordvn Loddefjord",
    "alfareferanse": "09.02 MCD 069 VESTK.  LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 474,
      "placeId": "ChIJo0lIzQz7PEYRPl_V9aDSk8Y",
      "placeName": "McDonald's",
      "coordinates": "60.36168,5.2341495",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1212580296,
    "accountNumber": "35302464616",
    "date": "2016-02-09",
    "amount": "-144.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 05.02 NOK 144.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208693865,
    "accountNumber": "35302464616",
    "date": "2016-02-08",
    "amount": "-310.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "05.02 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1209464630,
    "accountNumber": "35302464616",
    "date": "2016-02-08",
    "amount": "-196.00",
    "text": "Vita Myrdalsvegen Nyborg",
    "alfareferanse": "06.02 1619 VITA MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 932,
      "placeId": "ChIJq3eFEPr9PEYRepatAa7SSew",
      "placeName": "Vita Exclusive",
      "coordinates": "60.4699227,5.3225459",
      "website": "https://www.vita.no/butikker/vita-exclusive-horisont",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207666161,
    "accountNumber": "35302464616",
    "date": "2016-02-08",
    "amount": "-899.00",
    "text": "Brio Arken As Arken Senter Ulset",
    "alfareferanse": "05.02 BRIO ARKEN AS ARKEN SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 931,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207403500,
    "accountNumber": "35302464616",
    "date": "2016-02-08",
    "amount": "-150.08",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "07.02 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1211240144,
    "accountNumber": "35302464616",
    "date": "2016-02-05",
    "amount": "-60.30",
    "text": "Europris Vestka Lyderhornv. Loddefjord",
    "alfareferanse": "04.02 EUROPRIS VESTKA LYDERHORNV.  LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 799,
      "placeId": "ChIJVRxLtAz7PEYRPf1x7TyPAw8",
      "placeName": "Europris Vestkanten",
      "coordinates": "60.3632301,5.2353441",
      "website": "https://www.europris.no/vaare-butikker/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207409091,
    "accountNumber": "35302464616",
    "date": "2016-02-05",
    "amount": "-103.29",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "05.02 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207759162,
    "accountNumber": "35302464616",
    "date": "2016-02-05",
    "amount": "-751.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "04.02 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1207661059,
    "accountNumber": "35302464616",
    "date": "2016-02-04",
    "amount": "-906.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "03.02 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1212716137,
    "accountNumber": "35302464616",
    "date": "2016-02-04",
    "amount": "-95.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 02.02 NOK 95.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1208366504,
    "accountNumber": "35302464616",
    "date": "2016-02-04",
    "amount": "-396.33",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "03.02 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1210850955,
    "accountNumber": "35302464616",
    "date": "2016-02-02",
    "amount": "-83.90",
    "text": "Apotek 1,Arken Åsane Senter Ulset",
    "alfareferanse": "01.02 APOTEK 1,ARKEN  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 795,
      "placeId": "ChIJRcs1kfr9PEYRb1B_Au3QQX4",
      "placeName": "Apotek 1",
      "coordinates": "60.4657271,5.3244879",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/arken-bergen-697",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1204297784,
    "accountNumber": "35302464616",
    "date": "2016-02-02",
    "amount": "-79.00",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "01.02 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1192746925,
    "accountNumber": "35302464616",
    "date": "2016-02-01",
    "amount": "-2640.20",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "29.01 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197350038,
    "accountNumber": "35302464616",
    "date": "2016-02-01",
    "amount": "-334.40",
    "text": "Coop Prix Strømgt. 5 Bergen",
    "alfareferanse": "30.01 COOP PRIX STRØMGT. 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 867,
      "placeId": "ChIJzUss26j-PEYRQZVuKLINXA0",
      "placeName": "Coop Prix Marken",
      "coordinates": "60.392113,5.330169",
      "website": "https://coop.no/butikker/prix/marken-4574/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1199936502,
    "accountNumber": "35302464616",
    "date": "2016-02-01",
    "amount": "-62.40",
    "text": "Bunnpris Ulveda Ulvedalen To Flaktveit",
    "alfareferanse": "31.01 BUNNPRIS ULVEDA ULVEDALEN TO FLAKTVEIT",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 875,
      "placeId": "ChIJeSlWRhr-PEYRPn7QA7VLd5E",
      "placeName": "Bunnpris Ulvedalen",
      "coordinates": "60.4648761,5.3621029",
      "website": "http://bunnpris.no/butikker/bunnpris-ulvedalen",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1202353269,
    "accountNumber": "35302464616",
    "date": "2016-01-31",
    "amount": "-42.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         21 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197606000,
    "accountNumber": "35302464616",
    "date": "2016-01-29",
    "amount": "-283.70",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "28.01 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197071194,
    "accountNumber": "35302464616",
    "date": "2016-01-29",
    "amount": "-408.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "28.01 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1200808442,
    "accountNumber": "35302464616",
    "date": "2016-01-28",
    "amount": "-17.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "28.01 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198986282,
    "accountNumber": "35302464616",
    "date": "2016-01-28",
    "amount": "-120.50",
    "text": "Shell 7-Eleven Åsmyrane.167 Nyborg",
    "alfareferanse": "27.01 SHELL 7-ELEVEN  ÅSMYRANE.167 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 645,
      "placeId": "ChIJ1aFVCcI-TUYRSERG0ggU8Q4",
      "placeName": "7-Eleven",
      "coordinates": "55.3037836,10.7819461",
      "website": "http://www.7-eleven.dk/",
      "logoId": 101,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1199783290,
    "accountNumber": "35302464616",
    "date": "2016-01-27",
    "amount": "-71.59",
    "text": "Rema Helleveien Søndre Øyjor Bergen",
    "alfareferanse": "26.01 REMA HELLEVEIEN SØNDRE ØYJOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 820,
      "placeId": "ChIJQR5c_jP8PEYRFWJK-GSOfmY",
      "placeName": "REMA 1000",
      "coordinates": "60.4245179,5.3034795",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196170502,
    "accountNumber": "35302464616",
    "date": "2016-01-27",
    "amount": "-100.20",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "26.01 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197250440,
    "accountNumber": "35302464616",
    "date": "2016-01-26",
    "amount": "-358.50",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "25.01 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1201126298,
    "accountNumber": "35302464616",
    "date": "2016-01-26",
    "amount": "-268.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 24.01 NOK 268.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1201295128,
    "accountNumber": "35302464616",
    "date": "2016-01-26",
    "amount": "-104.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 24.01 NOK 104.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1200323658,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-42.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "22.01 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1200857577,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-12.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "24.01 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196543405,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-722.10",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "22.01 MENY BERGEN STORSENTER.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196549015,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-715.50",
    "text": "Lindex Avd. 264 Strømgt 8 Bergen",
    "alfareferanse": "22.01 LINDEX AVD. 264 STRØMGT 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 883,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198586563,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-155.40",
    "text": "Coop Prix Ulset Hesthaugvn.3 Ulset",
    "alfareferanse": "23.01 COOP PRIX ULSET HESTHAUGVN.3 ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 902,
      "placeId": "ChIJ77gwue_9PEYRR2t-PGEtnKM",
      "placeName": "Coop Prix Ulset",
      "coordinates": "60.463101,5.3160849",
      "website": "https://coop.no/butikker/prix/ulset-4579/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1199379540,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-96.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "23.01 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197655266,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-274.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "24.01 BURGER KING. STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197715500,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-262.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "22.01 BURGER KING. STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196949098,
    "accountNumber": "35302464616",
    "date": "2016-01-25",
    "amount": "-454.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "23.01 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1192793410,
    "accountNumber": "35302464616",
    "date": "2016-01-22",
    "amount": "-530.00",
    "text": "Europris Vestka Lyderhornv. Loddefjord",
    "alfareferanse": "21.01 EUROPRIS VESTKA LYDERHORNV.  LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 799,
      "placeId": "ChIJVRxLtAz7PEYRPf1x7TyPAw8",
      "placeName": "Europris Vestkanten",
      "coordinates": "60.3632301,5.2353441",
      "website": "https://www.europris.no/vaare-butikker/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1201280334,
    "accountNumber": "35302464616",
    "date": "2016-01-22",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 20.01 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198998578,
    "accountNumber": "35302464616",
    "date": "2016-01-22",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "22.01 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1199638106,
    "accountNumber": "35302464616",
    "date": "2016-01-21",
    "amount": "-80.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "20.01 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196964855,
    "accountNumber": "35302464616",
    "date": "2016-01-21",
    "amount": "-449.48",
    "text": "Nille 5 Vestk Loddefjordve Bergen",
    "alfareferanse": "20.01 NILLE 5 VESTK LODDEFJORDVE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 670,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198333605,
    "accountNumber": "35302464616",
    "date": "2016-01-21",
    "amount": "-182.40",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "20.01 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1192862313,
    "accountNumber": "35302464616",
    "date": "2016-01-21",
    "amount": "-10.00",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "20.01 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1200040482,
    "accountNumber": "35302464616",
    "date": "2016-01-21",
    "amount": "-57.00",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "20.01 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196070891,
    "accountNumber": "35302464616",
    "date": "2016-01-21",
    "amount": "-502.45",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "20.01 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197733189,
    "accountNumber": "35302464616",
    "date": "2016-01-21",
    "amount": "-259.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "20.01 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196558482,
    "accountNumber": "35302464616",
    "date": "2016-01-18",
    "amount": "-702.40",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "16.01 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1200097402,
    "accountNumber": "35302464616",
    "date": "2016-01-18",
    "amount": "-53.70",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "15.01 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1200718969,
    "accountNumber": "35302464616",
    "date": "2016-01-18",
    "amount": "-22.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "16.01 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1201659719,
    "accountNumber": "35302464616",
    "date": "2016-01-18",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 14.01 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197967109,
    "accountNumber": "35302464616",
    "date": "2016-01-15",
    "amount": "-225.76",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "14.01 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196863856,
    "accountNumber": "35302464616",
    "date": "2016-01-14",
    "amount": "-497.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "13.01 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1197479512,
    "accountNumber": "35302464616",
    "date": "2016-01-14",
    "amount": "-304.25",
    "text": "Obs Vestkant Loddefjordvn Loddefjord",
    "alfareferanse": "13.01 OBS VESTKANT LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 817,
      "placeId": "ChIJGeodyAz7PEYRN_zlNFbdjcs",
      "placeName": "Coop Obs! Vestkanten",
      "coordinates": "60.3610513,5.2337214",
      "website": "https://coop.no/butikker/obs/vestkanten-2511/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198486014,
    "accountNumber": "35302464616",
    "date": "2016-01-13",
    "amount": "-166.20",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "12.01 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198391898,
    "accountNumber": "35302464616",
    "date": "2016-01-12",
    "amount": "-176.59",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "11.01 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1199129198,
    "accountNumber": "35302464616",
    "date": "2016-01-11",
    "amount": "-110.00",
    "text": "God Servering A Engen 12 Bergen",
    "alfareferanse": "08.01 GOD SERVERING A ENGEN 12 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 536,
      "placeId": "ChIJy85aqyb8PEYRuvuQng3b5Yc",
      "placeName": "Bergen servering AS",
      "coordinates": "60.412205,5.3188574",
      "website": "http://bergenservering.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196670105,
    "accountNumber": "35302464616",
    "date": "2016-01-11",
    "amount": "-600.70",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "10.01 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196161066,
    "accountNumber": "35302464616",
    "date": "2016-01-08",
    "amount": "-152.08",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "07.01 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198915932,
    "accountNumber": "35302464616",
    "date": "2016-01-07",
    "amount": "-127.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "07.01 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1198879076,
    "accountNumber": "35302464616",
    "date": "2016-01-04",
    "amount": "-129.90",
    "text": "Apotek 1 Horiso Myrdalsv.2 Nyborg",
    "alfareferanse": "02.01 APOTEK 1 HORISO MYRDALSV.2 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 909,
      "placeId": "ChIJjfYFde8-TUYReHYol1hFewc",
      "placeName": "Nyborg Apotek",
      "coordinates": "55.3115103,10.7898857",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1196145255,
    "accountNumber": "35302464616",
    "date": "2016-01-04",
    "amount": "-203.25",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "02.01 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1190875263,
    "accountNumber": "35302464616",
    "date": "2015-12-31",
    "amount": "-40.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         20 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186603416,
    "accountNumber": "35302464616",
    "date": "2015-12-29",
    "amount": "-125.39",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "28.12 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186922321,
    "accountNumber": "35302464616",
    "date": "2015-12-29",
    "amount": "-104.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "29.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186868566,
    "accountNumber": "35302464616",
    "date": "2015-12-28",
    "amount": "-107.80",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "23.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1187417748,
    "accountNumber": "35302464616",
    "date": "2015-12-28",
    "amount": "-79.50",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "24.12 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1187034251,
    "accountNumber": "35302464616",
    "date": "2015-12-28",
    "amount": "-99.40",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "24.12 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186071970,
    "accountNumber": "35302464616",
    "date": "2015-12-28",
    "amount": "-167.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.12 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184399074,
    "accountNumber": "35302464616",
    "date": "2015-12-28",
    "amount": "-392.80",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "23.12 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1182895203,
    "accountNumber": "35302464616",
    "date": "2015-12-28",
    "amount": "-100.07",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "24.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184971672,
    "accountNumber": "35302464616",
    "date": "2015-12-28",
    "amount": "-290.00",
    "text": "Lazio Pizzeria Drotningsvik Godvik",
    "alfareferanse": "23.12 LAZIO PIZZERIA  DROTNINGSVIK GODVIK",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 933,
      "placeId": "ChIJAUqyj0z7PEYR5RdxPQNE5EI",
      "placeName": "Lazio pizzeria AS under liquidation",
      "coordinates": "60.3720512,5.1802764",
      "website": "http://www.laziopizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185670249,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-200.00",
    "text": "Jack & Jones Vestkanten Loddefjord",
    "alfareferanse": "22.12 JACK & JONES  VESTKANTEN  LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 934,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1189142372,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-120.00",
    "text": "Mcdonald's Raadalen",
    "alfareferanse": "*1018 17.12 NOK 120.00 MCD 008 RAADALEN Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 935,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1187456182,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-78.00",
    "text": "Ark, Vestkanten Loddefjordvn Loddefjord",
    "alfareferanse": "22.12 ARK, VESTKANTEN LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 936,
      "placeId": "ChIJo0lIzQz7PEYR3UXU8LI2iQ0",
      "placeName": "Ark Beyer Vestkanten",
      "coordinates": "60.3632227,5.2336255",
      "website": "http://www.vestkanten.no/butikker-og-virksomheter/ark/",
      "logoId": 70,
      "merchantName": "Vestkanten Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183100476,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-1393.00",
    "text": "Cubus 878 Vestkanten Loddefjord",
    "alfareferanse": "22.12 CUBUS 878 VESTKANTEN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 701,
      "placeId": "ChIJo0lIzQz7PEYRzyoTFXsHUCA",
      "placeName": "Cubus",
      "coordinates": "60.3633973,5.2330705",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/878/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185792342,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-194.00",
    "text": "Vestkanten Zoo Vestkanten S Loddefjord",
    "alfareferanse": "22.12 VESTKANTEN ZOO  VESTKANTEN S LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 853,
      "placeId": "ChIJo0lIzQz7PEYRevVlTjxQU9Y",
      "placeName": "Buddy Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://www.buddy.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1182871207,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-203.29",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "21.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1187592066,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-70.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "21.12 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186677507,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "22.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1189144566,
    "accountNumber": "35302464616",
    "date": "2015-12-22",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.12 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184206483,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-431.80",
    "text": "Vinmonopolet Myrdalsvn 2 Nyborg",
    "alfareferanse": "19.12 VINMONOPOLET MYRDALSVN 2  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 594,
      "placeId": "ChIJq3eFEPr9PEYRj57wLlGIuk0",
      "placeName": "Vinmonopolet Åsane Horisont",
      "coordinates": "60.4695732,5.323153",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185116822,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-266.00",
    "text": "Bergen Taxi As Kokstadveien Kokstad",
    "alfareferanse": "20.12 BERGEN TAXI AS  KOKSTADVEIEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 509,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186625179,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-124.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "20.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186533223,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-130.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "18.12 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185472363,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-221.80",
    "text": "Coop Prix Strømgt. 5 Bergen",
    "alfareferanse": "18.12 COOP PRIX STRØMGT. 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 867,
      "placeId": "ChIJzUss26j-PEYRQZVuKLINXA0",
      "placeName": "Coop Prix Marken",
      "coordinates": "60.392113,5.330169",
      "website": "https://coop.no/butikker/prix/marken-4574/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184201348,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-434.00",
    "text": "Bella Italia Hesthaugvn Ulset",
    "alfareferanse": "19.12 BELLA ITALIA HESTHAUGVN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 715,
      "placeId": "ChIJC716yO_9PEYRzl4gJHCV63o",
      "placeName": "Bella Italia Pizzeria AS",
      "coordinates": "60.4626399,5.316614",
      "website": "http://www.bellaitaliapizzeria.no/",
      "logoId": 121,
      "merchantName": "Bella Italia Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185645915,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-200.30",
    "text": "Spar Øyjordsveien Sandviken",
    "alfareferanse": "19.12 SPAR ØYJORDSVEIEN SANDVIKEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 937,
      "placeId": "ChIJLzdr5zP8PEYRDhlsTDbAwFc",
      "placeName": "SPAR Øyjorden",
      "coordinates": "60.4248386,5.3052227",
      "website": "https://spar.no/Finn-butikk/SPAR-Oyjorden/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184955388,
    "accountNumber": "35302464616",
    "date": "2015-12-21",
    "amount": "-293.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "20.12 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1182871513,
    "accountNumber": "35302464616",
    "date": "2015-12-18",
    "amount": "-202.99",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "17.12 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1189526298,
    "accountNumber": "35302464616",
    "date": "2015-12-17",
    "amount": "-10.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 15.12 NOK 10.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1182927632,
    "accountNumber": "35302464616",
    "date": "2015-12-15",
    "amount": "-3699.00",
    "text": "Telenorbutikken Loddefjordvn Loddefjord",
    "alfareferanse": "14.12 TELENORBUTIKKEN LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 1420,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186406625,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-140.00",
    "text": "Vita Excl Laguneveien Rådal",
    "alfareferanse": "13.12 3619 VITA EXCL  LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1398,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184918045,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-298.25",
    "text": "Lindex 076 Lagunen Rådal",
    "alfareferanse": "13.12 LINDEX 076 LAGUNEN RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1400,
      "placeId": "ChIJX1fNweb5PEYR2OfDVqm5Wdw",
      "placeName": "Lindex AS",
      "coordinates": "60.2968658,5.3311507",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1182846429,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-300.60",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "14.12 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185356563,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-237.00",
    "text": "Vita Excl Åsane Storse Ulset",
    "alfareferanse": "12.12 3617 VITA EXCL  ÅSANE STORSE ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 938,
      "placeId": "ChIJ17u-vfr9PEYRz4AWjziMZDg",
      "placeName": "Vita Exclusive Åsane Storsenter",
      "coordinates": "60.465738,5.322469",
      "website": "https://www.vita.no/butikker/vita-exclusive-arken",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184489703,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-370.00",
    "text": "Egon Lagunen Krohnåsvn. 1 Rådal",
    "alfareferanse": "13.12 EGON LAGUNEN KROHNÅSVN. 1 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 939,
      "placeId": "ChIJX1fNweb5PEYRR4pVpo0nI0M",
      "placeName": "Egon Lagunen",
      "coordinates": "60.2962118,5.3300405",
      "website": "http://www.egon.no/restauranter/lagunen",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183163184,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-1199.00",
    "text": "Session Lagunen Laguneveien Rådal",
    "alfareferanse": "13.12 SESSION LAGUNEN LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 940,
      "placeId": "ChIJX1fNweb5PEYRdo9fCOu0wtE",
      "placeName": "Lagunen Storsenter",
      "coordinates": "60.2968658,5.3311507",
      "website": "http://www.lagunen.no/",
      "logoId": 194,
      "merchantName": "Lagunen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183889646,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-531.00",
    "text": "Accessorize Lag Laguneveien Rådal",
    "alfareferanse": "13.12 ACCESSORIZE LAG LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 941,
      "placeId": "ChIJX1fNweb5PEYRHo0lGmOVCiw",
      "placeName": "Accessorize",
      "coordinates": "60.2968658,5.3311507",
      "website": "https://uk.accessorize.com/",
      "logoId": 195,
      "merchantName": "Accessorize",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185777721,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-195.59",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "11.12 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183923778,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-517.00",
    "text": "H&M No0779 Lagunen Stor Rådal",
    "alfareferanse": "13.12 H&M NO0779 LAGUNEN STOR RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 866,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184221265,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-427.90",
    "text": "Toys\"R\"Us F3305 Laguneveien Rådal",
    "alfareferanse": "13.12 TOYS\"R\"US F3305 LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 864,
      "placeId": "ChIJobCusub5PEYR4voyp-JXkl4",
      "placeName": "TOYS\"R\"US",
      "coordinates": "60.2969763,5.3307453",
      "website": "https://www.toysrus.no/store/3305",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186539056,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-129.90",
    "text": "Apotek 1,Arken Åsane Senter Ulset",
    "alfareferanse": "12.12 APOTEK 1,ARKEN  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 795,
      "placeId": "ChIJRcs1kfr9PEYRb1B_Au3QQX4",
      "placeName": "Apotek 1",
      "coordinates": "60.4657271,5.3244879",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/arken-bergen-697",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1187308198,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-84.80",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "11.12 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184769746,
    "accountNumber": "35302464616",
    "date": "2015-12-14",
    "amount": "-315.57",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "12.12 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185137698,
    "accountNumber": "35302464616",
    "date": "2015-12-11",
    "amount": "-262.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "10.12 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183514411,
    "accountNumber": "35302464616",
    "date": "2015-12-11",
    "amount": "-735.50",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "10.12 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1188690406,
    "accountNumber": "35302464616",
    "date": "2015-12-10",
    "amount": "-10.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "09.12 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1187229219,
    "accountNumber": "35302464616",
    "date": "2015-12-10",
    "amount": "-89.30",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "10.12 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183005701,
    "accountNumber": "35302464616",
    "date": "2015-12-10",
    "amount": "-1899.00",
    "text": "Mani Vestkanten Loddefjordvn Bergen",
    "alfareferanse": "10.12 MANI VESTKANTEN LODDEFJORDVN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 851,
      "placeId": "ChIJXQ2K0gz7PEYRNoL9-5yclDM",
      "placeName": "Mani Vestkanten Storsenter",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://skomani.no/",
      "logoId": 171,
      "merchantName": "Mani",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185127479,
    "accountNumber": "35302464616",
    "date": "2015-12-10",
    "amount": "-264.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "09.12 BURGER KING. STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1182873964,
    "accountNumber": "35302464616",
    "date": "2015-12-09",
    "amount": "-200.98",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "09.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186754930,
    "accountNumber": "35302464616",
    "date": "2015-12-08",
    "amount": "-115.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "08.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183680244,
    "accountNumber": "35302464616",
    "date": "2015-12-07",
    "amount": "-626.00",
    "text": "Cubus 876 Lagunen Bergen",
    "alfareferanse": "06.12 CUBUS 876 LAGUNEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 942,
      "placeId": "ChIJX1fNweb5PEYR-H1Q7_7TAlQ",
      "placeName": "Cubus",
      "coordinates": "60.2970287,5.3312498",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/876/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186583516,
    "accountNumber": "35302464616",
    "date": "2015-12-07",
    "amount": "-127.10",
    "text": "Coop Hordaland Kronåsv. 12 5239 Rådal",
    "alfareferanse": "06.12 COOP HORDALAND  KRONÅSV. 12  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 943,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1184632450,
    "accountNumber": "35302464616",
    "date": "2015-12-07",
    "amount": "-343.00",
    "text": "Coop Prix Strømgt. 5 Bergen",
    "alfareferanse": "04.12 COOP PRIX STRØMGT. 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 867,
      "placeId": "ChIJzUss26j-PEYRQZVuKLINXA0",
      "placeName": "Coop Prix Marken",
      "coordinates": "60.392113,5.330169",
      "website": "https://coop.no/butikker/prix/marken-4574/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185746853,
    "accountNumber": "35302464616",
    "date": "2015-12-07",
    "amount": "-198.00",
    "text": "Los Tacos Vetrl Vetrlidsallm Bergen",
    "alfareferanse": "06.12 LOS TACOS VETRL VETRLIDSALLM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 729,
      "placeId": "ChIJRzkYzqn-PEYRprWKuxCk4-U",
      "placeName": "Los Tacos Sentrum",
      "coordinates": "60.3912,5.323645",
      "website": "http://lostacos.no/",
      "logoId": 127,
      "merchantName": "Los Tacos",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185614383,
    "accountNumber": "35302464616",
    "date": "2015-12-07",
    "amount": "-204.78",
    "text": "Yx Nkp-Gaarden 0628 Bergen",
    "alfareferanse": "06.12 YX NKP-GAARDEN  0628 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1421,
      "placeId": "ChIJy_2sFKz-PEYRyCfAPhl19gA",
      "placeName": "YX NKP Gården",
      "coordinates": "60.3869619,5.3322157",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1188763990,
    "accountNumber": "35302464616",
    "date": "2015-12-04",
    "amount": "-1590.00",
    "text": "Billettservice.No",
    "alfareferanse": "*1018 02.12 NOK 1590.00 BILLETTSERVICE.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Konserter, teater og underholdning",
    "imageId": "",
    "place": {
      "id": 944,
      "placeId": "ChIJ-zFcNn1uQUYRO3L1zr4bUeE",
      "placeName": "Ticketmaster Norway",
      "coordinates": "59.9135488,10.747248",
      "website": "http://www.ticketmaster.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1186108644,
    "accountNumber": "35302464616",
    "date": "2015-12-04",
    "amount": "-163.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "03.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185196089,
    "accountNumber": "35302464616",
    "date": "2015-12-03",
    "amount": "-253.87",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "02.12 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1185423092,
    "accountNumber": "35302464616",
    "date": "2015-12-02",
    "amount": "-228.20",
    "text": "Meny Gullgruven Liamyrane 1 Nyborg",
    "alfareferanse": "01.12 MENY GULLGRUVEN LIAMYRANE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 623,
      "placeId": "ChIJsXGL2P79PEYRJHz7nINxv20",
      "placeName": "Meny Gullgruven",
      "coordinates": "60.4725054,5.3324672",
      "website": "http://www.meny.no/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1183279375,
    "accountNumber": "35302464616",
    "date": "2015-12-02",
    "amount": "-994.00",
    "text": "Kicks 676 Strømgaten 8 Bergen",
    "alfareferanse": "01.12 KICKS 676 STRØMGATEN 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 858,
      "placeId": "ChIJJ_9Dt67-PEYRu-Al530qmuM",
      "placeName": "KICKS",
      "coordinates": "60.3891768,5.332768",
      "website": "https://www.kicks.no/butikker/bergen-bergen-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175367268,
    "accountNumber": "35302464616",
    "date": "2015-12-01",
    "amount": "-43.10",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "30.11 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1173835195,
    "accountNumber": "35302464616",
    "date": "2015-12-01",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "01.12 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175443498,
    "accountNumber": "35302464616",
    "date": "2015-11-30",
    "amount": "-40.00",
    "text": "Vitusapotek Nor Strømgt 8 Bergen",
    "alfareferanse": "29.11 VITUSAPOTEK NOR STRØMGT 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 484,
      "placeId": "ChIJW-e2tK7-PEYRGP_JiUKGoXs",
      "placeName": "Vitusapotek Nordstjernen - Bergen",
      "coordinates": "60.3893095,5.3325248",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Nordstjernen-Bergen/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1167618847,
    "accountNumber": "35302464616",
    "date": "2015-11-30",
    "amount": "-1099.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1177638698,
    "accountNumber": "35302464616",
    "date": "2015-11-30",
    "amount": "-48.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         24 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1174695653,
    "accountNumber": "35302464616",
    "date": "2015-11-30",
    "amount": "-75.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "29.11 BURGER KING. STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1170447223,
    "accountNumber": "35302464616",
    "date": "2015-11-30",
    "amount": "-206.48",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "29.11 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175528142,
    "accountNumber": "35302464616",
    "date": "2015-11-30",
    "amount": "-36.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "30.11 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1176429214,
    "accountNumber": "35302464616",
    "date": "2015-11-25",
    "amount": "-169.00",
    "text": "Playstationnetwork",
    "alfareferanse": "*1018 23.11 NOK 169.00 PLAYSTATIONNETWORK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 495,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1170448730,
    "accountNumber": "35302464616",
    "date": "2015-11-25",
    "amount": "-203.72",
    "text": "Shell Varden Vardeveien 2 Fyllingsdalen",
    "alfareferanse": "25.11 SHELL VARDEN VARDEVEIEN 2 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 570,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1173831151,
    "accountNumber": "35302464616",
    "date": "2015-11-24",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "24.11 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1173488071,
    "accountNumber": "35302464616",
    "date": "2015-11-24",
    "amount": "-146.29",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "23.11 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1176494274,
    "accountNumber": "35302464616",
    "date": "2015-11-20",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.11 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1174409361,
    "accountNumber": "35302464616",
    "date": "2015-11-17",
    "amount": "-89.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "16.11 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1174116276,
    "accountNumber": "35302464616",
    "date": "2015-11-16",
    "amount": "-102.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "16.11 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1170447512,
    "accountNumber": "35302464616",
    "date": "2015-11-16",
    "amount": "-205.69",
    "text": "Shell Lagunepar Laguneveien Rådal",
    "alfareferanse": "16.11 SHELL LAGUNEPAR LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 640,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1176852830,
    "accountNumber": "35302464616",
    "date": "2015-11-13",
    "amount": "-20.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*1018 11.11 NOK 20.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175090210,
    "accountNumber": "35302464616",
    "date": "2015-11-12",
    "amount": "-55.00",
    "text": "Statoil Leirvik Vabakken Stord",
    "alfareferanse": "12.11 STATOIL LEIRVIK VABAKKEN STORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 945,
      "placeId": "ChIJ29yxdSh5PEYRNjJ9_CTUmkg",
      "placeName": "Circle K Leirvik",
      "coordinates": "59.7751378,5.486292",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=54474&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1170449066,
    "accountNumber": "35302464616",
    "date": "2015-11-12",
    "amount": "-203.35",
    "text": "Statoil Leirvik Vabakken Stord",
    "alfareferanse": "12.11 STATOIL LEIRVIK VABAKKEN STORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 945,
      "placeId": "ChIJ29yxdSh5PEYRNjJ9_CTUmkg",
      "placeName": "Circle K Leirvik",
      "coordinates": "59.7751378,5.486292",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=54474&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1171431945,
    "accountNumber": "35302464616",
    "date": "2015-11-11",
    "amount": "-467.50",
    "text": "H&M No0763 Amanda Stors Haugesund",
    "alfareferanse": "10.11 H&M NO0763 AMANDA STORS HAUGESUND",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 946,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1173639442,
    "accountNumber": "35302464616",
    "date": "2015-11-11",
    "amount": "-134.00",
    "text": "Bunnpris Marked Skåregt. 92 Haugesund",
    "alfareferanse": "10.11 BUNNPRIS MARKED SKÅREGT. 92  HAUGESUND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 947,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1173758579,
    "accountNumber": "35302464616",
    "date": "2015-11-11",
    "amount": "-125.00",
    "text": "Dolly Dimples O Oasen City Karmsund",
    "alfareferanse": "11.11 DOLLY DIMPLES O OASEN CITY KARMSUND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 948,
      "placeId": "ChIJBbf48JyfO0YRl5VUHBdHH1A",
      "placeName": "Dolly Dimple's Oasen",
      "coordinates": "59.3785958,5.3059964",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1171161902,
    "accountNumber": "35302464616",
    "date": "2015-11-11",
    "amount": "-589.85",
    "text": "Name It Longhammervn Haugesund",
    "alfareferanse": "10.11 NAME IT LONGHAMMERVN HAUGESUND",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Barneklær",
    "imageId": "",
    "place": {
      "id": 949,
      "placeId": "ChIJj032sg-fO0YRbvmIkhC8HHY",
      "placeName": "name it",
      "coordinates": "59.3947609,5.3329119",
      "website": "https://www.nameit.com/no/no/home",
      "logoId": 197,
      "merchantName": "name it",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1172922466,
    "accountNumber": "35302464616",
    "date": "2015-11-11",
    "amount": "-198.00",
    "text": "Cubus 870 . .",
    "alfareferanse": "10.11 CUBUS 870 . .",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 950,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175121463,
    "accountNumber": "35302464616",
    "date": "2015-11-11",
    "amount": "-53.80",
    "text": "Meny Oasen 5542 Karmsund",
    "alfareferanse": "11.11 MENY OASEN 5542 KARMSUND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 951,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1172455048,
    "accountNumber": "35302464616",
    "date": "2015-11-10",
    "amount": "-249.03",
    "text": "Coop Obs Storma Longhammervn Haugesund",
    "alfareferanse": "10.11 COOP OBS STORMA LONGHAMMERVN HAUGESUND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 952,
      "placeId": "ChIJo032sg-fO0YRffXf2qN0F6A",
      "placeName": "Coop Obs",
      "coordinates": "59.3953811,5.3329597",
      "website": "https://coop.no/butikker/obs/haugesund-2235/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1172921602,
    "accountNumber": "35302464616",
    "date": "2015-11-09",
    "amount": "-198.00",
    "text": "Kicks 617 Strandgaten Bergen",
    "alfareferanse": "07.11 KICKS 617 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 953,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1170391918,
    "accountNumber": "35302464616",
    "date": "2015-11-09",
    "amount": "-418.92",
    "text": "Shell 7-Eleven Åsmyrane.167 Nyborg",
    "alfareferanse": "08.11 SHELL 7-ELEVEN  ÅSMYRANE.167 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 645,
      "placeId": "ChIJ1aFVCcI-TUYRSERG0ggU8Q4",
      "placeName": "7-Eleven",
      "coordinates": "55.3037836,10.7819461",
      "website": "http://www.7-eleven.dk/",
      "logoId": 101,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1171072449,
    "accountNumber": "35302464616",
    "date": "2015-11-06",
    "amount": "-640.00",
    "text": "Rema Godvik 197 Alvehaugen 2 Godvik",
    "alfareferanse": "05.11 REMA GODVIK 197 ALVEHAUGEN 2 GODVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1276,
      "placeId": "ChIJC1lPrkj7PEYRl9z03Xe9P7M",
      "placeName": "Rema 1000 Godvik",
      "coordinates": "60.3700737,5.1928331",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1173360897,
    "accountNumber": "35302464616",
    "date": "2015-11-05",
    "amount": "-155.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "04.11 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1170421378,
    "accountNumber": "35302464616",
    "date": "2015-11-04",
    "amount": "-300.75",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "04.11 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175301474,
    "accountNumber": "35302464616",
    "date": "2015-11-04",
    "amount": "-46.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "04.11 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1174265743,
    "accountNumber": "35302464616",
    "date": "2015-11-04",
    "amount": "-96.59",
    "text": "Rema Øyrane Tor Ådnavegen 63 Indre Arna",
    "alfareferanse": "04.11 REMA ØYRANE TOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1300,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1174206018,
    "accountNumber": "35302464616",
    "date": "2015-11-04",
    "amount": "-99.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "04.11 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175465420,
    "accountNumber": "35302464616",
    "date": "2015-11-04",
    "amount": "-39.00",
    "text": "128 Boots Apotek Arna",
    "alfareferanse": "04.11 128 BOOTS APOTEK ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 954,
      "placeId": "ChIJC6INGX__PEYRFpsj0c6UoTA",
      "placeName": "Boots apotek Arna",
      "coordinates": "60.4211979,5.4654294",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1172432531,
    "accountNumber": "35302464616",
    "date": "2015-11-02",
    "amount": "-250.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "31.10 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1170448823,
    "accountNumber": "35302464616",
    "date": "2015-11-02",
    "amount": "-203.61",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "31.10 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1174352400,
    "accountNumber": "35302464616",
    "date": "2015-11-02",
    "amount": "-91.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "31.10 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1175950893,
    "accountNumber": "35302464616",
    "date": "2015-11-02",
    "amount": "-17.50",
    "text": "Vita Folkebernado Fyllingsdalen",
    "alfareferanse": "31.10 1606 VITA FOLKEBERNADO FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 565,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1173326030,
    "accountNumber": "35302464616",
    "date": "2015-11-02",
    "amount": "-158.50",
    "text": "H&M No0854 Oasen Kjøpes Fyllingsdalen",
    "alfareferanse": "31.10 H&M NO0854 OASEN KJØPES FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 564,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1174500274,
    "accountNumber": "35302464616",
    "date": "2015-11-02",
    "amount": "-83.90",
    "text": "131 Boots Apotek Fyllingsdalen",
    "alfareferanse": "30.10 131 BOOTS APOTEK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 555,
      "placeId": "ChIJp13-g8_7PEYRukP7E0TM2X8",
      "placeName": "Boots apotek Fyllingsdalen",
      "coordinates": "60.348958,5.2918029",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1172937444,
    "accountNumber": "35302464616",
    "date": "2015-11-02",
    "amount": "-196.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "31.10 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1164909987,
    "accountNumber": "35302464616",
    "date": "2015-10-31",
    "amount": "-96.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         48 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161509172,
    "accountNumber": "35302464616",
    "date": "2015-10-30",
    "amount": "-120.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "29.10 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1159399081,
    "accountNumber": "35302464616",
    "date": "2015-10-30",
    "amount": "-487.00",
    "text": "Peppes Pizza 26 Olav Kyrres Bergen",
    "alfareferanse": "29.10 PEPPES PIZZA 26 OLAV KYRRES  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 956,
      "placeId": "ChIJMRfeSaj-PEYRBm0FKEqpfzE",
      "placeName": "Peppes Pizza - Ole Bulls Plass",
      "coordinates": "60.3917406,5.3243031",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1159330993,
    "accountNumber": "35302464616",
    "date": "2015-10-30",
    "amount": "-511.58",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "29.10 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162340469,
    "accountNumber": "35302464616",
    "date": "2015-10-30",
    "amount": "-72.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "29.10 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161056446,
    "accountNumber": "35302464616",
    "date": "2015-10-29",
    "amount": "-160.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "28.10 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1156098779,
    "accountNumber": "35302464616",
    "date": "2015-10-28",
    "amount": "-1276.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158584183,
    "accountNumber": "35302464616",
    "date": "2015-10-28",
    "amount": "-307.02",
    "text": "Shell 7-Eleven Åsmyrane.167 Nyborg",
    "alfareferanse": "28.10 SHELL 7-ELEVEN  ÅSMYRANE.167 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 645,
      "placeId": "ChIJ1aFVCcI-TUYRSERG0ggU8Q4",
      "placeName": "7-Eleven",
      "coordinates": "55.3037836,10.7819461",
      "website": "http://www.7-eleven.dk/",
      "logoId": 101,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1159861184,
    "accountNumber": "35302464616",
    "date": "2015-10-28",
    "amount": "-336.50",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "28.10 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161056095,
    "accountNumber": "35302464616",
    "date": "2015-10-27",
    "amount": "-160.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "27.10 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1163054081,
    "accountNumber": "35302464616",
    "date": "2015-10-27",
    "amount": "-36.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "27.10 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158641584,
    "accountNumber": "35302464616",
    "date": "2015-10-27",
    "amount": "-100.04",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "27.10 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161525291,
    "accountNumber": "35302464616",
    "date": "2015-10-26",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "23.10 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162082208,
    "accountNumber": "35302464616",
    "date": "2015-10-26",
    "amount": "-86.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "24.10 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1160738426,
    "accountNumber": "35302464616",
    "date": "2015-10-26",
    "amount": "-196.00",
    "text": "Vita Folkebernado Fyllingsdalen",
    "alfareferanse": "23.10 1606 VITA FOLKEBERNADO FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 565,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158627205,
    "accountNumber": "35302464616",
    "date": "2015-10-23",
    "amount": "-176.63",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "22.10 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161312919,
    "accountNumber": "35302464616",
    "date": "2015-10-23",
    "amount": "-138.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "22.10 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161781430,
    "accountNumber": "35302464616",
    "date": "2015-10-21",
    "amount": "-102.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "20.10 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162932589,
    "accountNumber": "35302464616",
    "date": "2015-10-21",
    "amount": "-41.70",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "20.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162556818,
    "accountNumber": "35302464616",
    "date": "2015-10-20",
    "amount": "-60.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "19.10 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1163930747,
    "accountNumber": "35302464616",
    "date": "2015-10-20",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.10 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1159028271,
    "accountNumber": "35302464616",
    "date": "2015-10-20",
    "amount": "-749.00",
    "text": "Elkjop Fyllings Strandgaten Bergen",
    "alfareferanse": "19.10 ELKJOP FYLLINGS STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 958,
      "placeId": "ChIJVUe9qwL8PEYRayIsMwt_IGE",
      "placeName": "Elkjøp Phonehouse Kløverhuset",
      "coordinates": "60.3940527,5.3233263",
      "website": "http://www.elkjop.no/",
      "logoId": 97,
      "merchantName": "Elkjøp ",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158629730,
    "accountNumber": "35302464616",
    "date": "2015-10-19",
    "amount": "-154.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "18.10 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1160360965,
    "accountNumber": "35302464616",
    "date": "2015-10-19",
    "amount": "-243.00",
    "text": "Fincken - Det L Nygårdsgt. 2 Bergen",
    "alfareferanse": "17.10 FINCKEN - DET L NYGÅRDSGT. 2 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 805,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1159782599,
    "accountNumber": "35302464616",
    "date": "2015-10-14",
    "amount": "-354.60",
    "text": "Spar Øyjordsveien Sandviken",
    "alfareferanse": "13.10 SPAR ØYJORDSVEIEN SANDVIKEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 937,
      "placeId": "ChIJLzdr5zP8PEYRDhlsTDbAwFc",
      "placeName": "SPAR Øyjorden",
      "coordinates": "60.4248386,5.3052227",
      "website": "https://spar.no/Finn-butikk/SPAR-Oyjorden/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158630649,
    "accountNumber": "35302464616",
    "date": "2015-10-13",
    "amount": "-151.45",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "12.10 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162036544,
    "accountNumber": "35302464616",
    "date": "2015-10-12",
    "amount": "-89.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "09.10 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1160217894,
    "accountNumber": "35302464616",
    "date": "2015-10-12",
    "amount": "-264.90",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "09.10 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162747235,
    "accountNumber": "35302464616",
    "date": "2015-10-12",
    "amount": "-50.00",
    "text": "Starbucks Vetrlidsallm Bergen",
    "alfareferanse": "10.10 6107 STARBUCKS  VETRLIDSALLM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 758,
      "placeId": "ChIJZ88Wtqf-PEYRSzJdXH7MFJA",
      "placeName": "Starbucks",
      "coordinates": "60.3957719,5.326411",
      "website": "http://www.starbucks.no/store/1006292/no/kjottbasaren/vetrlidsallmenningen-2-bryggen-bergen-12-5014",
      "logoId": 119,
      "merchantName": "Starbucks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161814599,
    "accountNumber": "35302464616",
    "date": "2015-10-12",
    "amount": "-100.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "11.10 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161517385,
    "accountNumber": "35302464616",
    "date": "2015-10-09",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "09.10 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158614468,
    "accountNumber": "35302464616",
    "date": "2015-10-07",
    "amount": "-203.30",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "07.10 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1164237304,
    "accountNumber": "35302464616",
    "date": "2015-10-07",
    "amount": "-25.00",
    "text": "Samferdselsetat",
    "alfareferanse": "*1018 05.10 NOK 25.00 SAMFERDSELSETAT Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 908,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161320518,
    "accountNumber": "35302464616",
    "date": "2015-10-07",
    "amount": "-137.00",
    "text": "Burger King5003 Strandgt. 5 Bergen",
    "alfareferanse": "07.10 BURGER KING5003 STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 959,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162674323,
    "accountNumber": "35302464616",
    "date": "2015-10-07",
    "amount": "-54.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "07.10 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158629948,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-153.21",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "03.10 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1162790180,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-49.00",
    "text": "Baker Brun As Damsgårdsvei Bergen",
    "alfareferanse": "02.10 BAKER BRUN AS DAMSGÅRDSVEI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 631,
      "placeId": "ChIJUT-MYv_7PEYRbDxdWnAc-DE",
      "placeName": "Baker Brun Produksjon AS",
      "coordinates": "60.383095,5.3148769",
      "website": "http://www.bakerbrun.no/",
      "logoId": 98,
      "merchantName": "Baker Brun",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161436146,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "04.10 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1163156965,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-30.56",
    "text": "131 Boots Apotek Fyllingsdalen",
    "alfareferanse": "03.10 131 BOOTS APOTEK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 555,
      "placeId": "ChIJp13-g8_7PEYRukP7E0TM2X8",
      "placeName": "Boots apotek Fyllingsdalen",
      "coordinates": "60.348958,5.2918029",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1163039592,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-36.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "03.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1160731433,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-196.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "03.10 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1161388847,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-130.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "03.10 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1160561879,
    "accountNumber": "35302464616",
    "date": "2015-10-05",
    "amount": "-213.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "04.10 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1158632585,
    "accountNumber": "35302464616",
    "date": "2015-10-02",
    "amount": "-150.08",
    "text": "Shell Lagunepar Laguneveien Rådal",
    "alfareferanse": "01.10 SHELL LAGUNEPAR LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 640,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151221910,
    "accountNumber": "35302464616",
    "date": "2015-10-01",
    "amount": "-54.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "30.09 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1146999771,
    "accountNumber": "35302464616",
    "date": "2015-09-30",
    "amount": "-200.62",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "29.09 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1153632789,
    "accountNumber": "35302464616",
    "date": "2015-09-30",
    "amount": "-72.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         36 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151320540,
    "accountNumber": "35302464616",
    "date": "2015-09-30",
    "amount": "-50.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "30.09 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151719613,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-32.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "27.09 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1144374143,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-1276.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151177739,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-56.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "27.09 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150702460,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-80.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "25.09 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149623026,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-150.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "26.09 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150541854,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-89.00",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "25.09 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147842279,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-465.80",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "25.09 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150324583,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-100.00",
    "text": "Hjelle Konditor Ådnavegen 63 Indre Arna",
    "alfareferanse": "25.09 HJELLE KONDITOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1417,
      "placeId": "ChIJC6INGX__PEYRC5dDUQWEsnU",
      "placeName": "Hjelle Bakeri",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.hjellebakeri.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148770712,
    "accountNumber": "35302464616",
    "date": "2015-09-28",
    "amount": "-246.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "25.09 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149333297,
    "accountNumber": "35302464616",
    "date": "2015-09-25",
    "amount": "-178.95",
    "text": "Coop Extra Skareveien 1 Mathopen",
    "alfareferanse": "24.09 COOP EXTRA SKAREVEIEN 1 MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 899,
      "placeId": "ChIJM76lv-b6PEYRg29QdF3BMSs",
      "placeName": "EXTRA",
      "coordinates": "60.3336941,5.2118852",
      "website": "https://coop.no/butikker/extra/mathopen-4345/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149747677,
    "accountNumber": "35302464616",
    "date": "2015-09-24",
    "amount": "-140.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "23.09 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1146965150,
    "accountNumber": "35302464616",
    "date": "2015-09-24",
    "amount": "-309.13",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "24.09 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1143865851,
    "accountNumber": "35302464616",
    "date": "2015-09-23",
    "amount": "-125.00",
    "text": "Akvariet Server Nordnesbakke Bergen",
    "alfareferanse": "23.09 AKVARIET SERVER NORDNESBAKKE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Fritidsaktiviteter",
    "imageId": "",
    "place": {
      "id": 961,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147454474,
    "accountNumber": "35302464616",
    "date": "2015-09-22",
    "amount": "-707.38",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "21.09 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1152559322,
    "accountNumber": "35302464616",
    "date": "2015-09-22",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.09 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148702277,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-254.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "20.09 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148893743,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-228.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "20.09 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149218361,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-191.00",
    "text": "Little Ea Folke Bernad Fyllingsdalen",
    "alfareferanse": "19.09 1602 LITTLE EA  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 587,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149390496,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-172.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "20.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148702325,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-254.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "20.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149382757,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-173.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "19.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148583894,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-275.59",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "19.09 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1146995897,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-203.60",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "19.09 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1143848626,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-100.01",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "20.09 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150539227,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-89.00",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "18.09 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150894995,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-70.00",
    "text": "Vaagen Pub As Strandkaien Bergen",
    "alfareferanse": "19.09 VAAGEN PUB AS STRANDKAIEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 962,
      "placeId": "ChIJVT2hC6j-PEYRQ3-HDkn1avU",
      "placeName": "Femte i andre Bar -",
      "coordinates": "60.3942558,5.3243799",
      "website": "http://www.femteiandre.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147593615,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-598.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "18.09 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149605826,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-150.10",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "18.09 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149967506,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-122.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "18.09 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148035092,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-399.00",
    "text": "Lindex 959 Øyra Ådnavegen 63 Indre Arna",
    "alfareferanse": "18.09 LINDEX 959 ØYRA ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1399,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150283824,
    "accountNumber": "35302464616",
    "date": "2015-09-21",
    "amount": "-100.90",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "18.09 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147336479,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-864.50",
    "text": "H&M No0879 Myrdalsvegen Nyborg",
    "alfareferanse": "17.09 H&M NO0879 MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 749,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147579173,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-600.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "17.09 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148266043,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-337.90",
    "text": "Clas Ohl 835 Horisont Nyborg",
    "alfareferanse": "17.09 CLAS OHL 835 HORISONT NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 963,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148845053,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-235.00",
    "text": "Kicks 694 Myrdalsvg.2 Nyborg",
    "alfareferanse": "17.09 KICKS 694 MYRDALSVG.2  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 964,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147397085,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-778.90",
    "text": "Jula Bergen As Åsmyrane 320 Nyborg",
    "alfareferanse": "17.09 JULA BERGEN AS  ÅSMYRANE 320 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 965,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1146999194,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-200.84",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "17.09 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148981430,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-215.70",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "17.09 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150234574,
    "accountNumber": "35302464616",
    "date": "2015-09-18",
    "amount": "-104.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "17.09 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147012891,
    "accountNumber": "35302464616",
    "date": "2015-09-16",
    "amount": "-151.33",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "15.09 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148561567,
    "accountNumber": "35302464616",
    "date": "2015-09-16",
    "amount": "-279.50",
    "text": "Spar Øyjordsveien Sandviken",
    "alfareferanse": "15.09 SPAR ØYJORDSVEIEN SANDVIKEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 937,
      "placeId": "ChIJLzdr5zP8PEYRDhlsTDbAwFc",
      "placeName": "SPAR Øyjorden",
      "coordinates": "60.4248386,5.3052227",
      "website": "https://spar.no/Finn-butikk/SPAR-Oyjorden/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147578821,
    "accountNumber": "35302464616",
    "date": "2015-09-16",
    "amount": "-600.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "15.09 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150626752,
    "accountNumber": "35302464616",
    "date": "2015-09-15",
    "amount": "-83.90",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "14.09 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150355908,
    "accountNumber": "35302464616",
    "date": "2015-09-15",
    "amount": "-99.00",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "14.09 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149907614,
    "accountNumber": "35302464616",
    "date": "2015-09-14",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "12.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150141938,
    "accountNumber": "35302464616",
    "date": "2015-09-14",
    "amount": "-110.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "12.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148613809,
    "accountNumber": "35302464616",
    "date": "2015-09-14",
    "amount": "-269.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "11.09 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150249455,
    "accountNumber": "35302464616",
    "date": "2015-09-14",
    "amount": "-103.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "11.09 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150380562,
    "accountNumber": "35302464616",
    "date": "2015-09-14",
    "amount": "-98.00",
    "text": "Mcdonald's Brygges Bryggesporen Bergen",
    "alfareferanse": "12.09 MCD 057 BRYGGES BRYGGESPOREN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 728,
      "placeId": "ChIJP1mWxaf-PEYRnQwhU4NL1qk",
      "placeName": "McDonald's",
      "coordinates": "60.3954113,5.3266711",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149844200,
    "accountNumber": "35302464616",
    "date": "2015-09-14",
    "amount": "-131.40",
    "text": "Jama Mat As Sikthaugen T Fyllingsdalen",
    "alfareferanse": "11.09 JAMA MAT AS SIKTHAUGEN T FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 708,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148753406,
    "accountNumber": "35302464616",
    "date": "2015-09-14",
    "amount": "-248.86",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "13.09 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1146985310,
    "accountNumber": "35302464616",
    "date": "2015-09-11",
    "amount": "-252.30",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "10.09 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149907202,
    "accountNumber": "35302464616",
    "date": "2015-09-11",
    "amount": "-127.00",
    "text": "Kicks 673 Folke Bernad Bergen",
    "alfareferanse": "10.09 KICKS 673 FOLKE BERNAD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 966,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151527407,
    "accountNumber": "35302464616",
    "date": "2015-09-11",
    "amount": "-40.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "10.09 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149279187,
    "accountNumber": "35302464616",
    "date": "2015-09-11",
    "amount": "-184.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "10.09 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147338960,
    "accountNumber": "35302464616",
    "date": "2015-09-11",
    "amount": "-859.35",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "10.09 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151727893,
    "accountNumber": "35302464616",
    "date": "2015-09-11",
    "amount": "-31.28",
    "text": "131 Boots Apotek Fyllingsdalen",
    "alfareferanse": "10.09 131 BOOTS APOTEK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 555,
      "placeId": "ChIJp13-g8_7PEYRukP7E0TM2X8",
      "placeName": "Boots apotek Fyllingsdalen",
      "coordinates": "60.348958,5.2918029",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148972272,
    "accountNumber": "35302464616",
    "date": "2015-09-10",
    "amount": "-217.10",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "10.09 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149989822,
    "accountNumber": "35302464616",
    "date": "2015-09-09",
    "amount": "-120.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "08.09 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147015157,
    "accountNumber": "35302464616",
    "date": "2015-09-09",
    "amount": "-150.01",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "08.09 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1152731802,
    "accountNumber": "35302464616",
    "date": "2015-09-08",
    "amount": "-60.00",
    "text": "Mcdonald's 018 Torgalmen",
    "alfareferanse": "*1018 03.09 NOK 60.00 MCDONALD'S 018 TORGALMEN Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 967,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147602951,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-592.00",
    "text": "Raah Frisør Gyldenprisvn Bergen",
    "alfareferanse": "05.09 RAAH FRISØR GYLDENPRISVN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 968,
      "placeId": "ChIJURLaNP77PEYRPuhDqorm67s",
      "placeName": "Raah Frisør Jensen",
      "coordinates": "60.3792153,5.3259668",
      "website": "http://raahfrisor.no/",
      "logoId": 199,
      "merchantName": "Raah Frisør Jensen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1143790218,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-423.40",
    "text": "Jama Mat As Sikthaugen T Fyllingsdalen",
    "alfareferanse": "04.09 JAMA MAT AS SIKTHAUGEN T FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 708,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149826570,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-133.19",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "05.09 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1150419125,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-95.00",
    "text": "Trekroneren Flaktveitvn Flaktveit",
    "alfareferanse": "05.09 TREKRONEREN FLAKTVEITVN  FLAKTVEIT",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1424,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151564349,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-39.00",
    "text": "Kaffelade Torgallmenni Bergen",
    "alfareferanse": "05.09 KAFFELADE TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1302,
      "placeId": "ChIJtRJ8aqj-PEYRY5N8KmKDaWI",
      "placeName": "Kaffelade",
      "coordinates": "60.3930613,5.3252111",
      "website": "http://www.kaffelade.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151915791,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-24.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "06.09 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1149906813,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "05.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147735329,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-508.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "05.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148701552,
    "accountNumber": "35302464616",
    "date": "2015-09-07",
    "amount": "-254.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "05.09 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1147391221,
    "accountNumber": "35302464616",
    "date": "2015-09-04",
    "amount": "-788.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "03.09 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148928802,
    "accountNumber": "35302464616",
    "date": "2015-09-04",
    "amount": "-223.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "03.09 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151418205,
    "accountNumber": "35302464616",
    "date": "2015-09-04",
    "amount": "-45.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "03.09 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148780310,
    "accountNumber": "35302464616",
    "date": "2015-09-04",
    "amount": "-244.30",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "03.09 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1148957164,
    "accountNumber": "35302464616",
    "date": "2015-09-03",
    "amount": "-219.00",
    "text": "Barillo Pizzeri Helleveien 9 Bergen",
    "alfareferanse": "02.09 BARILLO PIZZERI HELLEVEIEN 9 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1425,
      "placeId": "ChIJtcDcATT8PEYRKHtdKTdmzV0",
      "placeName": "Barillo Pizzeria",
      "coordinates": "60.4245102,5.3027593",
      "website": "https://www.just-eat.no/restaurants-Barillo_Pizzeria",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151620081,
    "accountNumber": "35302464616",
    "date": "2015-09-03",
    "amount": "-36.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "03.09 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1151744169,
    "accountNumber": "35302464616",
    "date": "2015-09-02",
    "amount": "-30.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "01.09 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137993633,
    "accountNumber": "35302464616",
    "date": "2015-09-01",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "01.09 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139145576,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-60.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "30.08 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136335057,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-298.00",
    "text": "Jasmine Restaur Krokatjønnve Fyllingsdalen",
    "alfareferanse": "28.08 JASMINE RESTAUR KROKATJØNNVE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 600,
      "placeId": "ChIJUV_U6Lf7PEYRe4FzZBpjv5g",
      "placeName": "Krystall Restaurant & Take-Away",
      "coordinates": "60.3496856,5.2770907",
      "website": "http://www.krystallrestaurant.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134613377,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-353.11",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "31.08 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1141952472,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138895660,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-72.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "30.08 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134952553,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-1012.20",
    "text": "Kicks 675 Åsane Senter Ulset",
    "alfareferanse": "29.08 KICKS 675 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 868,
      "placeId": "ChIJ1RP5sfr9PEYR9RNWaC99jzQ",
      "placeName": "KICKS",
      "coordinates": "60.4654946,5.3214737",
      "website": "https://www.kicks.no/butikker/ulset-asane-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1141791515,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-50.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         25 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1135442612,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-540.00",
    "text": "Egon Kjøttbasar Vetrlidsallm Bergen.",
    "alfareferanse": "30.08 EGON KJØTTBASAR VETRLIDSALLM BERGEN.",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 969,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137621120,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-149.00",
    "text": "Pizza Venezia Folke Bernad Fyllingsdalen",
    "alfareferanse": "29.08 PIZZA VENEZIA FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 970,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137172943,
    "accountNumber": "35302464616",
    "date": "2015-08-31",
    "amount": "-189.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "29.08 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134613444,
    "accountNumber": "35302464616",
    "date": "2015-08-28",
    "amount": "-352.92",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "28.08 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137935916,
    "accountNumber": "35302464616",
    "date": "2015-08-28",
    "amount": "-124.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "27.08 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137991277,
    "accountNumber": "35302464616",
    "date": "2015-08-28",
    "amount": "-120.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "28.08 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1132011128,
    "accountNumber": "35302464616",
    "date": "2015-08-28",
    "amount": "-1276.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1135125057,
    "accountNumber": "35302464616",
    "date": "2015-08-27",
    "amount": "-761.10",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "26.08 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136418406,
    "accountNumber": "35302464616",
    "date": "2015-08-27",
    "amount": "-281.00",
    "text": "Egon Lagunen Krohnåsvn. 1 Rådal",
    "alfareferanse": "26.08 EGON LAGUNEN KROHNÅSVN. 1 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 939,
      "placeId": "ChIJX1fNweb5PEYRR4pVpo0nI0M",
      "placeName": "Egon Lagunen",
      "coordinates": "60.2962118,5.3300405",
      "website": "http://www.egon.no/restauranter/lagunen",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140135933,
    "accountNumber": "35302464616",
    "date": "2015-08-27",
    "amount": "-14.00",
    "text": "Coop Hordaland Kronåsv. 12 5239 Rådal",
    "alfareferanse": "26.08 COOP HORDALAND  KRONÅSV. 12  5239 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 943,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134969648,
    "accountNumber": "35302464616",
    "date": "2015-08-27",
    "amount": "-995.00",
    "text": "Cubus 876 Lagunen Bergen",
    "alfareferanse": "26.08 CUBUS 876 LAGUNEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 942,
      "placeId": "ChIJX1fNweb5PEYR-H1Q7_7TAlQ",
      "placeName": "Cubus",
      "coordinates": "60.2970287,5.3312498",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/876/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134925779,
    "accountNumber": "35302464616",
    "date": "2015-08-27",
    "amount": "-1082.00",
    "text": "H&M No0779 Lagunen Stor Rådal",
    "alfareferanse": "26.08 H&M NO0779 LAGUNEN STOR RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 866,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140172362,
    "accountNumber": "35302464616",
    "date": "2015-08-27",
    "amount": "-10.00",
    "text": "Time Park Lagun Laguneveien Rådal",
    "alfareferanse": "26.08 TIME PARK LAGUN LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1303,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1131898011,
    "accountNumber": "35302464616",
    "date": "2015-08-26",
    "amount": "-500.00",
    "text": "Sr-Bank Åsane 5116 Ulset",
    "alfareferanse": "25.08 SR-Bank Åsane           5116 Ulset",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 971,
      "placeId": "ChIJTUU73uT9PEYRaidsYZKnSo0",
      "placeName": "SpareBank 1 SR-Bank, Åsane",
      "coordinates": "60.46288,5.3251721",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/asane.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139140894,
    "accountNumber": "35302464616",
    "date": "2015-08-26",
    "amount": "-60.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "25.08 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134613991,
    "accountNumber": "35302464616",
    "date": "2015-08-25",
    "amount": "-351.23",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "25.08 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136786633,
    "accountNumber": "35302464616",
    "date": "2015-08-24",
    "amount": "-229.00",
    "text": "Brio Barn Og Le Postboks 57 Bergen",
    "alfareferanse": "22.08 BRIO BARN OG LE POSTBOKS 57  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 644,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137210619,
    "accountNumber": "35302464616",
    "date": "2015-08-24",
    "amount": "-184.59",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "23.08 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137760109,
    "accountNumber": "35302464616",
    "date": "2015-08-24",
    "amount": "-137.60",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "21.08 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136743685,
    "accountNumber": "35302464616",
    "date": "2015-08-24",
    "amount": "-235.00",
    "text": "Pizza Wood As Rollandslia Ulset",
    "alfareferanse": "21.08 PIZZA WOOD AS ROLLANDSLIA  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 973,
      "placeId": "ChIJRQFgZOP9PEYRN5nXOAMpC8Q",
      "placeName": "Pizzawood og Pasta",
      "coordinates": "60.46227,5.329469",
      "website": "https://www.just-eat.no/restaurants-pizzawood/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1135150319,
    "accountNumber": "35302464616",
    "date": "2015-08-24",
    "amount": "-738.70",
    "text": "Vinmonopolet Lagunen Bergen",
    "alfareferanse": "22.08 VINMONOPOLET LAGUNEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1304,
      "placeId": "ChIJX1fNweb5PEYRtuI3d_2g9v0",
      "placeName": "Vinmonopolet Bergen, Lagunen",
      "coordinates": "60.2968658,5.3311507",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139270699,
    "accountNumber": "35302464616",
    "date": "2015-08-24",
    "amount": "-54.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "24.08 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138054501,
    "accountNumber": "35302464616",
    "date": "2015-08-21",
    "amount": "-116.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "20.08 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134625353,
    "accountNumber": "35302464616",
    "date": "2015-08-21",
    "amount": "-302.76",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "20.08 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136555371,
    "accountNumber": "35302464616",
    "date": "2015-08-21",
    "amount": "-258.87",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "20.08 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138647531,
    "accountNumber": "35302464616",
    "date": "2015-08-21",
    "amount": "-84.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "20.08 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140168938,
    "accountNumber": "35302464616",
    "date": "2015-08-21",
    "amount": "-10.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "20.08 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139355214,
    "accountNumber": "35302464616",
    "date": "2015-08-20",
    "amount": "-50.00",
    "text": "Hjelle Bakeri Gullgruven Nyborg Åsane",
    "alfareferanse": "20.08 HJELLE BAKERI GULLGRUVEN NYBORG ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 632,
      "placeId": "ChIJhfKtJ__9PEYR4UHpqAzw6qU",
      "placeName": "Hjelle konditori Gullgruven",
      "coordinates": "60.4729884,5.3326372",
      "website": "http://www.hjellebakeri.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140650864,
    "accountNumber": "35302464616",
    "date": "2015-08-20",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.08 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136381687,
    "accountNumber": "35302464616",
    "date": "2015-08-20",
    "amount": "-288.90",
    "text": "429Europris Fyl Spelhaugen 6 Fyllingsdalen",
    "alfareferanse": "19.08 429EUROPRIS FYL SPELHAUGEN 6 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 544,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1131408532,
    "accountNumber": "35302464616",
    "date": "2015-08-20",
    "amount": "-320.80",
    "text": "Bunnpris Steinsvikveg Rådal",
    "alfareferanse": "19.08 BUNNPRIS STEINSVIKVEG RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 974,
      "placeId": "ChIJsexCCfj5PEYRP3Q1geZGH8s",
      "placeName": "Bunnpris Steinsviken",
      "coordinates": "60.301339,5.3035414",
      "website": "https://bpsteinsviken.business.site/",
      "logoId": 201,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139860290,
    "accountNumber": "35302464616",
    "date": "2015-08-20",
    "amount": "-29.00",
    "text": "Hööks Hestespor Liamyrene 1 Nyborg",
    "alfareferanse": "20.08 HÖÖKS HESTESPOR LIAMYRENE 1  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sportsutstyr",
    "imageId": "",
    "place": {
      "id": 975,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140659154,
    "accountNumber": "35302464616",
    "date": "2015-08-19",
    "amount": "-111.00",
    "text": "Mcdonald's Raadalen",
    "alfareferanse": "*1018 16.08 NOK 111.00 MCD 008 RAADALEN Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 935,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137933924,
    "accountNumber": "35302464616",
    "date": "2015-08-19",
    "amount": "-124.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "18.08 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138646850,
    "accountNumber": "35302464616",
    "date": "2015-08-18",
    "amount": "-84.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "18.08 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134921680,
    "accountNumber": "35302464616",
    "date": "2015-08-18",
    "amount": "-1095.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "17.08 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134976809,
    "accountNumber": "35302464616",
    "date": "2015-08-18",
    "amount": "-979.97",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "17.08 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139438583,
    "accountNumber": "35302464616",
    "date": "2015-08-18",
    "amount": "-47.00",
    "text": "Pasta Sentral V Loddefjordve Loddefjord",
    "alfareferanse": "17.08 PASTA SENTRAL V LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 673,
      "placeId": "ChIJXQ2K0gz7PEYRTxvse2_bJEQ",
      "placeName": "Pasta Sentral AS - Vestkanten",
      "coordinates": "60.3630902,5.2333567",
      "website": "http://pastasentral.no/restauranter/pasta-sentral-vestkanten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134655416,
    "accountNumber": "35302464616",
    "date": "2015-08-18",
    "amount": "-205.62",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "17.08 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1135333232,
    "accountNumber": "35302464616",
    "date": "2015-08-18",
    "amount": "-599.00",
    "text": "Bianco As Loddefjordvn Bergen",
    "alfareferanse": "17.08 BIANCO AS LODDEFJORDVN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 976,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137088709,
    "accountNumber": "35302464616",
    "date": "2015-08-18",
    "amount": "-198.00",
    "text": "Bik Bok J01 Loddefjordve Bergen",
    "alfareferanse": "17.08 BIK BOK J01 LODDEFJORDVE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1408,
      "placeId": "ChIJM-52F6j-PEYRlPWjCquzOgY",
      "placeName": "BIK BOK",
      "coordinates": "60.3922526,5.3239096",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/640/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138525198,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-90.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "14.08 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138896928,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-71.90",
    "text": "Rimi Gågt C. Sundts Ga Bergen",
    "alfareferanse": "14.08 4985 RIMI GÅGT  C. SUNDTS GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 977,
      "placeId": "ChIJk7UL2Bz8PEYRKPcTFDXyUN0",
      "placeName": "EXTRA",
      "coordinates": "60.3952631,5.3186143",
      "website": "https://coop.no/butikker/extra/gagaten-4570/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1135879822,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-391.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "15.08 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138124271,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-111.30",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "15.08 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136911413,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-212.90",
    "text": "Rema Eidsvåg Ervikveien 1 Øvre Ervik",
    "alfareferanse": "14.08 REMA EIDSVÅG ERVIKVEIEN 1 ØVRE ERVIK",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 714,
      "placeId": "ChIJNxfbJdH9PEYRbQ8A8XqEKe0",
      "placeName": "Rema 1000 Eidsvåg",
      "coordinates": "60.4380188,5.3226697",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139398438,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-49.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "15.08 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136493795,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-269.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "15.08 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137843274,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-130.00",
    "text": "Harbour Cafe Torget 9 Bergen",
    "alfareferanse": "15.08 HARBOUR CAFE TORGET 9 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 567,
      "placeId": "ChIJX9mF3Kf-PEYRu_0lGooLSt0",
      "placeName": "Harbour Café",
      "coordinates": "60.3949519,5.3261725",
      "website": "http://harbourcafe.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140181649,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-8.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "15.08 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137542214,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-153.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "15.08 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139813364,
    "accountNumber": "35302464616",
    "date": "2015-08-17",
    "amount": "-30.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.08 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138425384,
    "accountNumber": "35302464616",
    "date": "2015-08-14",
    "amount": "-96.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "14.08 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134622368,
    "accountNumber": "35302464616",
    "date": "2015-08-14",
    "amount": "-309.31",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "14.08 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1135615043,
    "accountNumber": "35302464616",
    "date": "2015-08-14",
    "amount": "-470.00",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "14.08 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136911394,
    "accountNumber": "35302464616",
    "date": "2015-08-14",
    "amount": "-212.90",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "13.08 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136241804,
    "accountNumber": "35302464616",
    "date": "2015-08-13",
    "amount": "-308.00",
    "text": "Peppes Pizza Av Fjøsangervei",
    "alfareferanse": "12.08 PEPPES PIZZA AV FJØSANGERVEI",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 891,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137982643,
    "accountNumber": "35302464616",
    "date": "2015-08-13",
    "amount": "-120.00",
    "text": "Solbrød Åsane Kokstaddalen Kokstad",
    "alfareferanse": "13.08 SOLBRØD ÅSANE KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 928,
      "placeId": "ChIJjRGEuPr9PEYRLCZbrNaYAuk",
      "placeName": "Solbrød Åsane senter",
      "coordinates": "60.4650338,5.3218943",
      "website": "http://www.solbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139649918,
    "accountNumber": "35302464616",
    "date": "2015-08-13",
    "amount": "-37.90",
    "text": "Spar Øyjordsveien Sandviken",
    "alfareferanse": "12.08 SPAR ØYJORDSVEIEN SANDVIKEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 937,
      "placeId": "ChIJLzdr5zP8PEYRDhlsTDbAwFc",
      "placeName": "SPAR Øyjorden",
      "coordinates": "60.4248386,5.3052227",
      "website": "https://spar.no/Finn-butikk/SPAR-Oyjorden/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138052584,
    "accountNumber": "35302464616",
    "date": "2015-08-12",
    "amount": "-116.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "12.08 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1138880999,
    "accountNumber": "35302464616",
    "date": "2015-08-12",
    "amount": "-72.80",
    "text": "Rimi Skareveien 1 Mathopen",
    "alfareferanse": "11.08 4987 RIMI SKAREVEIEN 1 MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 979,
      "placeId": "ChIJM76lv-b6PEYRg29QdF3BMSs",
      "placeName": "EXTRA",
      "coordinates": "60.3336941,5.2118852",
      "website": "https://coop.no/butikker/extra/mathopen-4345/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137916179,
    "accountNumber": "35302464616",
    "date": "2015-08-12",
    "amount": "-125.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "11.08 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1136794434,
    "accountNumber": "35302464616",
    "date": "2015-08-12",
    "amount": "-228.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "12.08 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139421416,
    "accountNumber": "35302464616",
    "date": "2015-08-12",
    "amount": "-48.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "12.08 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134634560,
    "accountNumber": "35302464616",
    "date": "2015-08-12",
    "amount": "-300.02",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "12.08 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140104316,
    "accountNumber": "35302464616",
    "date": "2015-08-11",
    "amount": "-16.50",
    "text": "Hylkje Post I B Hylkjebak.1 Hylkje",
    "alfareferanse": "11.08 HYLKJE POST I B HYLKJEBAK.1  HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 980,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137145410,
    "accountNumber": "35302464616",
    "date": "2015-08-11",
    "amount": "-191.39",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "11.08 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1134621812,
    "accountNumber": "35302464616",
    "date": "2015-08-10",
    "amount": "-311.71",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "10.08 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1137880571,
    "accountNumber": "35302464616",
    "date": "2015-08-06",
    "amount": "-128.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "06.08 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1140026315,
    "accountNumber": "35302464616",
    "date": "2015-08-03",
    "amount": "-20.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "03.08 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1139098407,
    "accountNumber": "35302464616",
    "date": "2015-08-03",
    "amount": "-61.70",
    "text": "Rimi Gågt C. Sundts Ga Bergen",
    "alfareferanse": "03.08 4985 RIMI GÅGT  C. SUNDTS GA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 977,
      "placeId": "ChIJk7UL2Bz8PEYRKPcTFDXyUN0",
      "placeName": "EXTRA",
      "coordinates": "60.3952631,5.3186143",
      "website": "https://coop.no/butikker/extra/gagaten-4570/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126111075,
    "accountNumber": "35302464616",
    "date": "2015-07-31",
    "amount": "-98.00",
    "text": "Solbrød Oasen Kokstaddalen Kokstad",
    "alfareferanse": "30.07 SOLBRØD OASEN KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 871,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1129292740,
    "accountNumber": "35302464616",
    "date": "2015-07-31",
    "amount": "-68.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         34 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122344506,
    "accountNumber": "35302464616",
    "date": "2015-07-30",
    "amount": "-302.90",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "30.07 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1119925446,
    "accountNumber": "35302464616",
    "date": "2015-07-28",
    "amount": "-1276.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122351342,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-300.10",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "26.07 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125534208,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-135.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "26.07 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124456034,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-243.46",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "24.07 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124808959,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-199.95",
    "text": "Blaze As Øyrane Torg Tlf 50",
    "alfareferanse": "24.07 BLAZE AS ØYRANE TORG  TLF 55950450",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 981,
      "placeId": "ChIJT48wG3__PEYRGqErJ2soRGQ",
      "placeName": "Blace",
      "coordinates": "60.4212725,5.4656685",
      "website": "http://www.oyrane-torg.no/2011/klaer-sko-parfyme/blace/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126206661,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-90.40",
    "text": "128 Boots Apotek Arna",
    "alfareferanse": "24.07 128 BOOTS APOTEK ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 954,
      "placeId": "ChIJC6INGX__PEYRFpsj0c6UoTA",
      "placeName": "Boots apotek Arna",
      "coordinates": "60.4211979,5.4654294",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125448628,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-141.50",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "25.07 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123390168,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-466.80",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "24.07 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122811226,
    "accountNumber": "35302464616",
    "date": "2015-07-27",
    "amount": "-822.70",
    "text": "Lindex 959 Øyra Ådnavegen 63 Indre Arna",
    "alfareferanse": "24.07 LINDEX 959 ØYRA ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1399,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123435765,
    "accountNumber": "35302464616",
    "date": "2015-07-24",
    "amount": "-450.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "24.07 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124839895,
    "accountNumber": "35302464616",
    "date": "2015-07-24",
    "amount": "-199.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "24.07 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124258408,
    "accountNumber": "35302464616",
    "date": "2015-07-23",
    "amount": "-270.20",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "22.07 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125826452,
    "accountNumber": "35302464616",
    "date": "2015-07-23",
    "amount": "-113.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "22.07 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126398580,
    "accountNumber": "35302464616",
    "date": "2015-07-23",
    "amount": "-80.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "22.07 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126901519,
    "accountNumber": "35302464616",
    "date": "2015-07-23",
    "amount": "-52.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "22.07 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123989768,
    "accountNumber": "35302464616",
    "date": "2015-07-23",
    "amount": "-314.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "22.07 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122351082,
    "accountNumber": "35302464616",
    "date": "2015-07-23",
    "amount": "-300.11",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "22.07 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123545549,
    "accountNumber": "35302464616",
    "date": "2015-07-22",
    "amount": "-414.50",
    "text": "Cubus 874 Oasen Senter Fyllingsdalen",
    "alfareferanse": "21.07 CUBUS 874 OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 607,
      "placeId": "ChIJoTfziMX7PEYRab_c3wl4tKY",
      "placeName": "Cubus",
      "coordinates": "60.348195,5.2920946",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/874/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123059201,
    "accountNumber": "35302464616",
    "date": "2015-07-22",
    "amount": "-613.80",
    "text": "131 Boots Apotek Fyllingsdalen",
    "alfareferanse": "21.07 131 BOOTS APOTEK FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 555,
      "placeId": "ChIJp13-g8_7PEYRukP7E0TM2X8",
      "placeName": "Boots apotek Fyllingsdalen",
      "coordinates": "60.348958,5.2918029",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1119403147,
    "accountNumber": "35302464616",
    "date": "2015-07-21",
    "amount": "-14.90",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "21.07 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122343258,
    "accountNumber": "35302464616",
    "date": "2015-07-21",
    "amount": "-304.65",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "20.07 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1128208085,
    "accountNumber": "35302464616",
    "date": "2015-07-21",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.07 NOK 119.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126113630,
    "accountNumber": "35302464616",
    "date": "2015-07-21",
    "amount": "-97.70",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "21.07 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126698299,
    "accountNumber": "35302464616",
    "date": "2015-07-21",
    "amount": "-64.00",
    "text": "Big Bite 321 F.Bernad.Vei Fyllingsdalen",
    "alfareferanse": "21.07 BIG BITE 321 F.BERNAD.VEI FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 982,
      "placeId": "ChIJp13-g8_7PEYRsozFVYYiG7w",
      "placeName": "Big Bite",
      "coordinates": "60.3491563,5.2911056",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123173112,
    "accountNumber": "35302464616",
    "date": "2015-07-21",
    "amount": "-553.59",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "20.07 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124893717,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-194.00",
    "text": "Cafe Marino As Åsane Senter Ulset",
    "alfareferanse": "17.07 CAFE MARINO AS  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 746,
      "placeId": "ChIJMc6UmPr9PEYRNc9JWl46Xe0",
      "placeName": "Grand Caffé Venezia avd. Åsane Senter",
      "coordinates": "60.4663844,5.3225102",
      "website": "http://veneziapizzeria.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125441299,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-142.30",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "17.07 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123876555,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-338.00",
    "text": "H&M No0849 Åsane Senter Ulset",
    "alfareferanse": "17.07 H&M NO0849 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 807,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123142205,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-571.00",
    "text": "Spar Kjøp Åsane Liamyrene 6 Nyborg",
    "alfareferanse": "17.07 SPAR KJØP ÅSANE LIAMYRENE 6  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 983,
      "placeId": "ChIJ1X5vDf79PEYR1kJCNSChc48",
      "placeName": "Spar Kjøp Åsane",
      "coordinates": "60.470281,5.337713",
      "website": "https://www.sparkjop.no/",
      "logoId": 146,
      "merchantName": "Spar Kjøp",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125598413,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-129.20",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "17.07 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125958009,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-103.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "17.07 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126122025,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-96.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "18.07 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126326678,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-84.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "18.07 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124870955,
    "accountNumber": "35302464616",
    "date": "2015-07-20",
    "amount": "-196.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "18.07 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126393332,
    "accountNumber": "35302464616",
    "date": "2015-07-15",
    "amount": "-80.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "14.07 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1128624233,
    "accountNumber": "35302464616",
    "date": "2015-07-15",
    "amount": "-10.65",
    "text": "Coop Hordaland Sa",
    "alfareferanse": "*1018 13.07 NOK 10.65 Coop Hordaland SA Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 927,
      "placeId": "ChIJd5vtSnb6PEYR2WrdDdtTIYs",
      "placeName": "Coop Hordaland Sa",
      "coordinates": "60.2943127,5.2532398",
      "website": "https://coop.no/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122388021,
    "accountNumber": "35302464616",
    "date": "2015-07-15",
    "amount": "-152.80",
    "text": "Uno-X Fjoesange R 0727 Bergen",
    "alfareferanse": "15.07 UNO-X FJOESANGE R 0727 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 760,
      "placeId": "ChIJ2-aGK275PEYRIRChH9mFokc",
      "placeName": "Uno-X Fjøsanger",
      "coordinates": "60.3476207,5.33622",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124214327,
    "accountNumber": "35302464616",
    "date": "2015-07-14",
    "amount": "-278.40",
    "text": "Gina Tricot Ber Sane Center Ulset",
    "alfareferanse": "14.07 GINA TRICOT BER SANE CENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 849,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126599191,
    "accountNumber": "35302464616",
    "date": "2015-07-14",
    "amount": "-69.90",
    "text": "Brio Arken As Arken Senter Ulset",
    "alfareferanse": "13.07 BRIO ARKEN AS ARKEN SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 931,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1127585122,
    "accountNumber": "35302464616",
    "date": "2015-07-14",
    "amount": "-10.98",
    "text": "Søstrene Grene Myrdalsveien Nyborg",
    "alfareferanse": "13.07 SØSTRENE GRENE  MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 984,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125364862,
    "accountNumber": "35302464616",
    "date": "2015-07-14",
    "amount": "-149.00",
    "text": "Mango Norge Åsane Senter Ulset",
    "alfareferanse": "14.07 MANGO NORGE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 985,
      "placeId": "ChIJGQBJkfr9PEYRw1bmEKKokmE",
      "placeName": "Mango",
      "coordinates": "60.4662392,5.322624",
      "website": "http://shop.mango.com/preHome.faces",
      "logoId": 203,
      "merchantName": "Mango",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124762861,
    "accountNumber": "35302464616",
    "date": "2015-07-14",
    "amount": "-202.00",
    "text": "Nille 469 Myrdalsvegen Nyborg",
    "alfareferanse": "13.07 NILLE 469 MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 986,
      "placeId": "ChIJX8rrA_r9PEYRQGb-BbOyaZ8",
      "placeName": "Nille Horisont Åsane",
      "coordinates": "60.4699227,5.3225459",
      "website": "http://nille.no/",
      "logoId": 179,
      "merchantName": "Nille",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122378444,
    "accountNumber": "35302464616",
    "date": "2015-07-14",
    "amount": "-200.09",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "13.07 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1128185191,
    "accountNumber": "35302464616",
    "date": "2015-07-14",
    "amount": "-129.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 11.07 NOK 129.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125951862,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-104.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "11.07 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125582652,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-130.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "10.07 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124273152,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-268.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "11.07 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125223651,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-159.50",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "12.07 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125906153,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-107.39",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "12.07 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124273147,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-268.90",
    "text": "Vinmonopolet Fyllingsdale Fyllingsdalen",
    "alfareferanse": "10.07 VINMONOPOLET FYLLINGSDALE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 517,
      "placeId": "ChIJYfhOfsX7PEYR63C-grUTDDo",
      "placeName": "Vinmonopolet Bergen, Fyllingsdalen",
      "coordinates": "60.3494513,5.291745",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124644532,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-218.00",
    "text": "Cubus 81D Prestegardsk Os",
    "alfareferanse": "10.07 CUBUS 81D PRESTEGARDSK  OS",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 987,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125404441,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-146.00",
    "text": "Peppes 331 Kaien Os",
    "alfareferanse": "11.07 PEPPES 331 KAIEN OS",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 988,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1127458709,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-22.50",
    "text": "Spar Tysnes As Triangel Var Tysnes",
    "alfareferanse": "11.07 SPAR TYSNES AS  TRIANGEL VAR TYSNES",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 989,
      "placeId": "ChIJWYOBcyxhPEYRCUac46svwjI",
      "placeName": "SPAR Tysnes",
      "coordinates": "60.0438827,5.5222033",
      "website": "https://spar.no/Finn-butikk/SPAR-Tysnes",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1126461334,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-77.00",
    "text": "M/F Fosen 2 Pirterminal Trondheim",
    "alfareferanse": "11.07 M/F FOSEN 2 PIRTERMINAL  TRONDHEIM",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 990,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125222881,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-159.60",
    "text": "Coop Extra Os Prestegardss Os",
    "alfareferanse": "10.07 COOP EXTRA OS PRESTEGARDSS OS",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 991,
      "placeId": "ChIJbWWV7bdYPEYROcG_h2sK1pI",
      "placeName": "Extra Os",
      "coordinates": "60.2022749,5.4569175",
      "website": "https://coop.no/butikker/extra/os-2494/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124194260,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-281.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "13.07 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124639898,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-218.70",
    "text": "Jama Mat As Sikthaugen T Fyllingsdalen",
    "alfareferanse": "11.07 JAMA MAT AS SIKTHAUGEN T FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 708,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125382103,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-148.30",
    "text": "Jama Mat As Sikthaugen T Fyllingsdalen",
    "alfareferanse": "10.07 JAMA MAT AS SIKTHAUGEN T FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 708,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125621927,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-128.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "12.07 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1124644325,
    "accountNumber": "35302464616",
    "date": "2015-07-13",
    "amount": "-218.00",
    "text": "Bryggen Pianoba Bryggen Bergen",
    "alfareferanse": "12.07 BRYGGEN PIANOBA BRYGGEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1418,
      "placeId": "ChIJhbLjnR38PEYRCui64uRFigE",
      "placeName": "Bryggen Pianobar AS",
      "coordinates": "60.3975921,5.3223474",
      "website": "http://bryggenpianobar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123380972,
    "accountNumber": "35302464616",
    "date": "2015-07-09",
    "amount": "-470.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "08.07 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1127493868,
    "accountNumber": "35302464616",
    "date": "2015-07-07",
    "amount": "-20.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "07.07 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1122396363,
    "accountNumber": "35302464616",
    "date": "2015-07-06",
    "amount": "-103.02",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "05.07 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123717517,
    "accountNumber": "35302464616",
    "date": "2015-07-06",
    "amount": "-374.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "04.07 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1119350502,
    "accountNumber": "35302464616",
    "date": "2015-07-03",
    "amount": "-421.00",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "02.07 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1123863406,
    "accountNumber": "35302464616",
    "date": "2015-07-03",
    "amount": "-340.49",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "02.07 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1125690602,
    "accountNumber": "35302464616",
    "date": "2015-07-03",
    "amount": "-122.00",
    "text": "Mf Raunefjord F Strandav 1 Florø",
    "alfareferanse": "02.07 MF RAUNEFJORD F STRANDAV 1 FLORØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 992,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117443402,
    "accountNumber": "35302464616",
    "date": "2015-06-30",
    "amount": "-42.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         21 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117227126,
    "accountNumber": "35302464616",
    "date": "2015-06-30",
    "amount": "-1.00",
    "text": "Debetrenter U/Dekning",
    "alfareferanse": "DEBETRENTER U/DEKNING",
    "mainCategory": "Utgifter",
    "category": "Renter",
    "subCategory": "Renter",
    "imageId": "",
    "place": {
      "id": 1367,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117595569,
    "accountNumber": "35302464616",
    "date": "2015-06-30",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1107641409,
    "accountNumber": "35302464616",
    "date": "2015-06-30",
    "amount": "-1276.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1113818772,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-116.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "26.06 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114011092,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-104.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "28.06 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115303989,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-40.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "26.06 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1111272322,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-508.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "27.06 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112368014,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-254.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "28.06 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1113674190,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "27.06 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114371475,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-86.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "28.06 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112367973,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-254.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "28.06 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115479913,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-31.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "27.06 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1111869142,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-340.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "27.06 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1110385745,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-203.74",
    "text": "Shell Shell Lag Unepark Eraadal",
    "alfareferanse": "27.06 Shell Shell Lag unepark eRaadal",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 652,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114539725,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-78.00",
    "text": "Burger King 500 Strandgt. 5 Bergen",
    "alfareferanse": "27.06 BURGER KING 500 STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 994,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115729933,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-20.00",
    "text": "Kokobello Kokstadvn.10 Kokstad",
    "alfareferanse": "27.06 KOKOBELLO KOKSTADVN.10 KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 995,
      "placeId": "ChIJGe3kJZ7wPEYRx6e45lqH8Vs",
      "placeName": "JaFs Kokkobello",
      "coordinates": "60.2919682,5.2601268",
      "website": "http://www.jafskokkobello.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114751014,
    "accountNumber": "35302464616",
    "date": "2015-06-29",
    "amount": "-67.00",
    "text": "Vaagen Pub As Strandkaien Bergen",
    "alfareferanse": "27.06 VAAGEN PUB AS STRANDKAIEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 962,
      "placeId": "ChIJVT2hC6j-PEYRQ3-HDkn1avU",
      "placeName": "Femte i andre Bar -",
      "coordinates": "60.3942558,5.3243799",
      "website": "http://www.femteiandre.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115157704,
    "accountNumber": "35302464616",
    "date": "2015-06-26",
    "amount": "-47.26",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "26.06 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1110581136,
    "accountNumber": "35302464616",
    "date": "2015-06-26",
    "amount": "-1409.00",
    "text": "Hylkje Post I B Hylkjebak.1 Hylkje",
    "alfareferanse": "26.06 HYLKJE POST I B HYLKJEBAK.1  HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 980,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114157679,
    "accountNumber": "35302464616",
    "date": "2015-06-26",
    "amount": "-98.70",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "25.06 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115727446,
    "accountNumber": "35302464616",
    "date": "2015-06-25",
    "amount": "-20.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "24.06 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117378354,
    "accountNumber": "35302464616",
    "date": "2015-06-25",
    "amount": "-250.00",
    "text": "Årspris Visa Classic",
    "alfareferanse": "Årspris Visa Classic",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 679,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115691746,
    "accountNumber": "35302464616",
    "date": "2015-06-25",
    "amount": "-20.90",
    "text": "Meny Vestkanten Loddefjordvn Loddefjord.",
    "alfareferanse": "24.06 MENY VESTKANTEN LODDEFJORDVN LODDEFJORD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 610,
      "placeId": "ChIJ7aQ5zQz7PEYRkIHit713VfE",
      "placeName": "Meny Vestkanten",
      "coordinates": "60.3634048,5.2335239",
      "website": "https://meny.no/Finn-butikk/Meny-Vestkanten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1110593387,
    "accountNumber": "35302464616",
    "date": "2015-06-25",
    "amount": "-1353.00",
    "text": "H&M No0757 Vestkanten S Bergen",
    "alfareferanse": "24.06 H&M NO0757 VESTKANTEN S BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 515,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115835175,
    "accountNumber": "35302464616",
    "date": "2015-06-25",
    "amount": "-10.00",
    "text": "Vero Moda .",
    "alfareferanse": "24.06 VERO MODA 176244 .",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1273,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1111195479,
    "accountNumber": "35302464616",
    "date": "2015-06-24",
    "amount": "-548.00",
    "text": "Bikbok J33 Oasen Fyllingsdalen",
    "alfareferanse": "23.06 BIKBOK J33 OASEN FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 668,
      "placeId": "ChIJp13-g8_7PEYR8r_D_kMJzHo",
      "placeName": "BIK BOK",
      "coordinates": "60.34909,5.2903814",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/j33/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1110353598,
    "accountNumber": "35302464616",
    "date": "2015-06-23",
    "amount": "-304.66",
    "text": "Statoil Nesttun Nesttunveien Nesttun",
    "alfareferanse": "23.06 STATOIL NESTTUN NESTTUNVEIEN NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 996,
      "placeId": "ChIJJ35YiLv5PEYRwxhNE9wXjL8",
      "placeName": "Circle K Nesttun",
      "coordinates": "60.320153,5.351467",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=52114&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114629390,
    "accountNumber": "35302464616",
    "date": "2015-06-23",
    "amount": "-73.00",
    "text": "Statoil Nesttun Nesttunveien Nesttun",
    "alfareferanse": "23.06 STATOIL NESTTUN NESTTUNVEIEN NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 996,
      "placeId": "ChIJJ35YiLv5PEYRwxhNE9wXjL8",
      "placeName": "Circle K Nesttun",
      "coordinates": "60.320153,5.351467",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=52114&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114929618,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-58.00",
    "text": "Vitusapotek Kna Kvernhusmyra Isdalstø",
    "alfareferanse": "19.06 VITUSAPOTEK KNA KVERNHUSMYRA ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 997,
      "placeId": "ChIJhy2R9Z8DPUYRitZ8-3_BEUk",
      "placeName": "Vitusapotek Knarvik",
      "coordinates": "60.5474554,5.287272",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Knarvik",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1113599489,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-132.00",
    "text": "Big Bite 310 Knarvik Sent Isdalstø",
    "alfareferanse": "19.06 BIG BITE 310 KNARVIK SENT ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 998,
      "placeId": "ChIJPX-a4HEDPUYRc_27UOQjUj0",
      "placeName": "Big Bite",
      "coordinates": "60.5475727,5.2877836",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112851727,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-199.00",
    "text": "Pizza Venezia Folke Bernad Fyllingsdalen",
    "alfareferanse": "21.06 PIZZA VENEZIA FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 970,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1108857183,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-2227.57",
    "text": "Bkk Kundetjenes",
    "alfareferanse": "Nettgiro til: BKK KUNDETJENES Betalt: 20.06.15",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Elektrisitet",
    "imageId": "",
    "place": {
      "id": 787,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116280371,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.06 NOK 119.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112761247,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-204.52",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "20.06 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112335438,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-259.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "20.06 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112759910,
    "accountNumber": "35302464616",
    "date": "2015-06-22",
    "amount": "-204.80",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "20.06 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1111932176,
    "accountNumber": "35302464616",
    "date": "2015-06-19",
    "amount": "-325.90",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "19.06 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114168002,
    "accountNumber": "35302464616",
    "date": "2015-06-19",
    "amount": "-98.00",
    "text": "Vita Åsane Senter Ulset",
    "alfareferanse": "19.06 1601 VITA ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 926,
      "placeId": "ChIJm0qxovr9PEYRLWqUX5supVw",
      "placeName": "Vita",
      "coordinates": "60.464841,5.321676",
      "website": "https://www.vita.no/butikker/vita-sane-storsenter",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1111603608,
    "accountNumber": "35302464616",
    "date": "2015-06-18",
    "amount": "-399.99",
    "text": "Teknikmagasinet Sane Senter Ulset",
    "alfareferanse": "18.06 TEKNIKMAGASINET SANE SENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 999,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1110373465,
    "accountNumber": "35302464616",
    "date": "2015-06-17",
    "amount": "-254.54",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "16.06 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112767434,
    "accountNumber": "35302464616",
    "date": "2015-06-15",
    "amount": "-203.97",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "15.06 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1110385638,
    "accountNumber": "35302464616",
    "date": "2015-06-12",
    "amount": "-203.87",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "11.06 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116604398,
    "accountNumber": "35302464616",
    "date": "2015-06-12",
    "amount": "-25.00",
    "text": "Thomas Cook Airlines Onbo",
    "alfareferanse": "*1018 08.06 NOK 25.00 THOMAS COOK AIRLINES ONBO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 1000,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116735157,
    "accountNumber": "35302464616",
    "date": "2015-06-10",
    "amount": "-297.57",
    "text": "Alanya Ata Kzlarpnar",
    "alfareferanse": "*1018 08.06 NOK 297.57 ALANYA ATA KZLARPNAR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1002,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117464233,
    "accountNumber": "35302464616",
    "date": "2015-06-10",
    "amount": "-35.90",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116718897,
    "accountNumber": "35302464616",
    "date": "2015-06-10",
    "amount": "-1188.13",
    "text": "Alanya Sube Atm",
    "alfareferanse": "*1018 05.06 TRY 400.00 ALANYA SUBE ATM Kurs: 2.9703",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1004,
      "placeId": "ChIJV7nvBniY3BQR0nCs-w3qqFE",
      "placeName": "Hsbc-alanya Şubesi",
      "coordinates": "36.544972,31.993089",
      "website": "http://hsbc.com.tr/",
      "logoId": 205,
      "merchantName": "Hsbc-alanya Subesi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116735281,
    "accountNumber": "35302464616",
    "date": "2015-06-10",
    "amount": "-290.10",
    "text": "Alanya Ata Kzlarpnar",
    "alfareferanse": "*1018 08.06 NOK 290.10 ALANYA ATA KZLARPNAR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1002,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117486388,
    "accountNumber": "35302464616",
    "date": "2015-06-10",
    "amount": "-31.40",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117486383,
    "accountNumber": "35302464616",
    "date": "2015-06-10",
    "amount": "-31.40",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116253002,
    "accountNumber": "35302464616",
    "date": "2015-06-10",
    "amount": "-138.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 08.06 NOK 138.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1107553821,
    "accountNumber": "35302464616",
    "date": "2015-06-09",
    "amount": "-200.00",
    "text": "Nordea Flesland Lobby-3 Autom.Omr. 08:07",
    "alfareferanse": "09.06 NORDEA FLESLAND LOBBY-3 AUTOM.OMR. 08:07",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1005,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1114475510,
    "accountNumber": "35302464616",
    "date": "2015-06-09",
    "amount": "-80.00",
    "text": "Narvesen 163 Flyplassvn. Gardermoen",
    "alfareferanse": "09.06 NARVESEN 163 FLYPLASSVN.  GARDERMOEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 888,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116729308,
    "accountNumber": "35302464616",
    "date": "2015-06-08",
    "amount": "-601.68",
    "text": "Alanya Ata Kzlarpnar",
    "alfareferanse": "*1018 04.06 NOK 601.68 ALANYA ATA KZLARPNAR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1002,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117477758,
    "accountNumber": "35302464616",
    "date": "2015-06-08",
    "amount": "-33.00",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117486108,
    "accountNumber": "35302464616",
    "date": "2015-06-05",
    "amount": "-31.50",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116734958,
    "accountNumber": "35302464616",
    "date": "2015-06-05",
    "amount": "-315.37",
    "text": "Akbank/Alanya Carsi 1",
    "alfareferanse": "*1018 03.06 NOK 315.37 AKBANK/ALANYA CARSI 1 Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1006,
      "placeId": "ChIJXSKoNHGY3BQRH24EbrvzHeA",
      "placeName": "Akbank",
      "coordinates": "36.5439537,32.0016644",
      "website": "https://www.akbank.com/",
      "logoId": 206,
      "merchantName": "Akbank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1115936930,
    "accountNumber": "35302464616",
    "date": "2015-06-05",
    "amount": "-958.00",
    "text": "Thomas Cook Airlines Onbo 1.000",
    "alfareferanse": "*1018 01.06 NOK 958.00 THOMAS COOK AIRLINES ONBO Kurs: 1.000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 1007,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116154684,
    "accountNumber": "35302464616",
    "date": "2015-06-05",
    "amount": "-210.00",
    "text": "Thomas Cook Airlines Onbo 1.000",
    "alfareferanse": "*1018 01.06 NOK 210.00 THOMAS COOK AIRLINES ONBO Kurs: 1.000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 1007,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116729070,
    "accountNumber": "35302464616",
    "date": "2015-06-05",
    "amount": "-607.83",
    "text": "Alanya Ata Kzlarpnar",
    "alfareferanse": "*1018 03.06 NOK 607.83 ALANYA ATA KZLARPNAR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1002,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117477743,
    "accountNumber": "35302464616",
    "date": "2015-06-05",
    "amount": "-33.00",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116477154,
    "accountNumber": "35302464616",
    "date": "2015-06-05",
    "amount": "-55.00",
    "text": "Thomas Cook Airlines Onbo",
    "alfareferanse": "*1018 01.06 NOK 55.00 THOMAS COOK AIRLINES ONBO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 1000,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116722204,
    "accountNumber": "35302464616",
    "date": "2015-06-04",
    "amount": "-913.49",
    "text": "Antalya Dis Hatlar 1",
    "alfareferanse": "*1018 01.06 TRY 300.00 ANTALYA DIS HATLAR 1 Kurs: 3.0450",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1008,
      "placeId": "ChIJ44nH03eEwxQR3boznK-ME8E",
      "placeName": "Antalya Havaalanı Dış Hatlar Terminali 1",
      "coordinates": "36.9142058,30.8036056",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1117470127,
    "accountNumber": "35302464616",
    "date": "2015-06-04",
    "amount": "-34.50",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116294362,
    "accountNumber": "35302464616",
    "date": "2015-06-03",
    "amount": "-107.00",
    "text": "Mcdonald's Oasen",
    "alfareferanse": "*1018 30.05 NOK 107.00 MCD 041 OASEN Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1010,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116217966,
    "accountNumber": "35302464616",
    "date": "2015-06-03",
    "amount": "-167.67",
    "text": "E-Visa Turkey",
    "alfareferanse": "*1018 01.06 USD 20.70 E-VISA TURKEY Kurs: 8.1000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Reise",
    "imageId": "",
    "place": {
      "id": 1009,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116217972,
    "accountNumber": "35302464616",
    "date": "2015-06-03",
    "amount": "-167.67",
    "text": "E-Visa Turkey",
    "alfareferanse": "*1018 01.06 USD 20.70 E-VISA TURKEY Kurs: 8.1000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Reise",
    "imageId": "",
    "place": {
      "id": 1009,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1116217968,
    "accountNumber": "35302464616",
    "date": "2015-06-03",
    "amount": "-167.67",
    "text": "E-Visa Turkey",
    "alfareferanse": "*1018 01.06 USD 20.70 E-VISA TURKEY Kurs: 8.1000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Reise",
    "imageId": "",
    "place": {
      "id": 1009,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1112372045,
    "accountNumber": "35302464616",
    "date": "2015-06-02",
    "amount": "-253.00",
    "text": "Duty Free Hovedporten Gardermoen",
    "alfareferanse": "01.06 7102 DUTY FREE  HOVEDPORTEN  GARDERMOEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Taxfree",
    "imageId": "",
    "place": {
      "id": 1306,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100891003,
    "accountNumber": "35302464616",
    "date": "2015-06-01",
    "amount": "-163.90",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "29.05 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101271867,
    "accountNumber": "35302464616",
    "date": "2015-06-01",
    "amount": "-130.70",
    "text": "Rema Øyrane Tor Ådnavegen 63 Indre Arna",
    "alfareferanse": "29.05 REMA ØYRANE TOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1300,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1103371011,
    "accountNumber": "35302464616",
    "date": "2015-06-01",
    "amount": "-17.00",
    "text": "Narvesen 163 Flyplassvn. Gardermoen",
    "alfareferanse": "01.06 NARVESEN 163 FLYPLASSVN.  GARDERMOEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 888,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102312066,
    "accountNumber": "35302464616",
    "date": "2015-06-01",
    "amount": "-70.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "30.05 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1098260830,
    "accountNumber": "35302464616",
    "date": "2015-06-01",
    "amount": "-136.79",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "30.05 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1104996044,
    "accountNumber": "35302464616",
    "date": "2015-05-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1104836725,
    "accountNumber": "35302464616",
    "date": "2015-05-31",
    "amount": "-66.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         33 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101844380,
    "accountNumber": "35302464616",
    "date": "2015-05-28",
    "amount": "-95.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "27.05 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100291265,
    "accountNumber": "35302464616",
    "date": "2015-05-28",
    "amount": "-229.00",
    "text": "Pizza Venezia Folke Bernad Fyllingsdalen",
    "alfareferanse": "27.05 PIZZA VENEZIA FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 970,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100603180,
    "accountNumber": "35302464616",
    "date": "2015-05-28",
    "amount": "-195.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "27.05 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1095572519,
    "accountNumber": "35302464616",
    "date": "2015-05-28",
    "amount": "-1276.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1098239273,
    "accountNumber": "35302464616",
    "date": "2015-05-27",
    "amount": "-201.21",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "27.05 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1104817157,
    "accountNumber": "35302464616",
    "date": "2015-05-27",
    "amount": "-200.00",
    "text": "Årspris Visa Electron",
    "alfareferanse": "Årspris Visa Electron",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1011,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1103483958,
    "accountNumber": "35302464616",
    "date": "2015-05-26",
    "amount": "-2704.00",
    "text": "Norwegian Ai4 1.00",
    "alfareferanse": "*1018 19.05 NOK 2704.00 NORWEGIAN AI3287165809214 Kurs: 1.00",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 1013,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1099857724,
    "accountNumber": "35302464616",
    "date": "2015-05-26",
    "amount": "-298.18",
    "text": "Rema Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "26.05 REMA KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1014,
      "placeId": "ChIJ6QeaEaADPUYRa28x28MDPxY",
      "placeName": "REMA 1000",
      "coordinates": "60.5459402,5.2867653",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100483841,
    "accountNumber": "35302464616",
    "date": "2015-05-26",
    "amount": "-202.99",
    "text": "Rema Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "23.05 REMA KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1014,
      "placeId": "ChIJ6QeaEaADPUYRa28x28MDPxY",
      "placeName": "REMA 1000",
      "coordinates": "60.5459402,5.2867653",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100150372,
    "accountNumber": "35302464616",
    "date": "2015-05-26",
    "amount": "-249.00",
    "text": "Expert Bonus Kvassnesv.23 Isdalstø",
    "alfareferanse": "26.05 EXPERT BONUS KVASSNESV.23 ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 1012,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1103428702,
    "accountNumber": "35302464616",
    "date": "2015-05-26",
    "amount": "-10.50",
    "text": "Hylkje Post I B Hylkjebak.1 Hylkje",
    "alfareferanse": "26.05 HYLKJE POST I B HYLKJEBAK.1  HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 980,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1103800572,
    "accountNumber": "35302464616",
    "date": "2015-05-19",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 17.05 NOK 119.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102141760,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-79.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "18.05 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102140257,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-79.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "18.05 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102141918,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-79.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "18.05 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101151142,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-141.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.05 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1104094517,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-20.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*1018 11.05 NOK 20.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101122909,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-144.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "16.05 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101729514,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-100.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "17.05 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1095477917,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-100.00",
    "text": "Nokas 7-Eleven Engen 5011 Bergen",
    "alfareferanse": "18.05 Nokas 7-Eleven Engen    5011 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1015,
      "placeId": "ChIJfYZ5XwL8PEYRpNxbn_gNe6Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3916858,5.3200612",
      "website": "http://7-eleven.no/butikker",
      "logoId": 207,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101868069,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-93.00",
    "text": "Aura Nighclub Strandgt 3 Bergen",
    "alfareferanse": "17.05 AURA NIGHCLUB STRANDGT 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1016,
      "placeId": "ChIJbdZ-Gqj-PEYRLWsJ79OzThc",
      "placeName": "Aura Nightclub",
      "coordinates": "60.3935826,5.3241524",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102399197,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-65.00",
    "text": "Aura Nighclub Strandgt 3 Bergen",
    "alfareferanse": "17.05 AURA NIGHCLUB STRANDGT 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1016,
      "placeId": "ChIJbdZ-Gqj-PEYRLWsJ79OzThc",
      "placeName": "Aura Nightclub",
      "coordinates": "60.3935826,5.3241524",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102399419,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-65.00",
    "text": "Aura Nighclub Strandgt 3 Bergen",
    "alfareferanse": "17.05 AURA NIGHCLUB STRANDGT 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1016,
      "placeId": "ChIJbdZ-Gqj-PEYRLWsJ79OzThc",
      "placeName": "Aura Nightclub",
      "coordinates": "60.3935826,5.3241524",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1104284089,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-500.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "16.05 SPV Fyllingsdalen Inne  5147 Fyllingsdalen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1099654322,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-334.80",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "15.05 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102082190,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-81.70",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "15.05 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101542445,
    "accountNumber": "35302464616",
    "date": "2015-05-18",
    "amount": "-111.80",
    "text": "Rimi Minde Minde Àlle 4 Bergen",
    "alfareferanse": "15.05 5051 RIMI MINDE MINDE ÀLLE 4 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1307,
      "placeId": "ChIJefQDjEH5PEYR60s5ZNUHJUY",
      "placeName": "Extra Minde",
      "coordinates": "60.360687,5.343119",
      "website": "https://coop.no/butikker/extra/minde-4576/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1098182032,
    "accountNumber": "35302464616",
    "date": "2015-05-15",
    "amount": "-402.93",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "15.05 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1103137447,
    "accountNumber": "35302464616",
    "date": "2015-05-15",
    "amount": "-29.80",
    "text": "Rimi Skareveien 1 Mathopen",
    "alfareferanse": "13.05 4987 RIMI SKAREVEIEN 1 MATHOPEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 979,
      "placeId": "ChIJM76lv-b6PEYRg29QdF3BMSs",
      "placeName": "EXTRA",
      "coordinates": "60.3336941,5.2118852",
      "website": "https://coop.no/butikker/extra/mathopen-4345/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1099930683,
    "accountNumber": "35302464616",
    "date": "2015-05-15",
    "amount": "-284.00",
    "text": "Peppes Pizza Av Fjøsangervei",
    "alfareferanse": "14.05 PEPPES PIZZA AV FJØSANGERVEI",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 891,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101182568,
    "accountNumber": "35302464616",
    "date": "2015-05-15",
    "amount": "-139.00",
    "text": "Shell Shell Var Den Fyllingdalen",
    "alfareferanse": "14.05 Shell SHELL VAR DEN  FYLLINGDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 655,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101479021,
    "accountNumber": "35302464616",
    "date": "2015-05-15",
    "amount": "-116.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "14.05 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1099433249,
    "accountNumber": "35302464616",
    "date": "2015-05-12",
    "amount": "-391.20",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "11.05 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100141562,
    "accountNumber": "35302464616",
    "date": "2015-05-12",
    "amount": "-249.29",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "11.05 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1104101161,
    "accountNumber": "35302464616",
    "date": "2015-05-12",
    "amount": "-19.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 08.05 NOK 19.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101670987,
    "accountNumber": "35302464616",
    "date": "2015-05-12",
    "amount": "-102.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "11.05 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1096842165,
    "accountNumber": "35302464616",
    "date": "2015-05-12",
    "amount": "-600.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 12.05.15",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1096928114,
    "accountNumber": "35302465310",
    "date": "2015-05-12",
    "amount": "-298.00",
    "text": "Canal Digital N",
    "alfareferanse": "Nettgiro til: Canal Digital N Betalt: 12.05.15",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kabel-TV/Satellitt",
    "imageId": "",
    "place": {
      "id": 1427,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101561947,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-110.00",
    "text": "Trg Norge As Åsene Storse Ulset",
    "alfareferanse": "11.05 TRG NORGE AS ÅSENE STORSE ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 910,
      "placeId": "ChIJLc6svPr9PEYRfqz1Eoow7K8",
      "placeName": "H&M",
      "coordinates": "60.4661421,5.3227179",
      "website": "http://www.hm.com/no/",
      "logoId": 187,
      "merchantName": "H&M",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1099906598,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-289.00",
    "text": "Italpizza As Åsane Senter Ulset",
    "alfareferanse": "09.05 ITALPIZZA AS ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1019,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1103059197,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-32.40",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "11.05 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100354060,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-220.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "10.05 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1098209901,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-300.51",
    "text": "Shell Shell Lag Unepark Eraadal",
    "alfareferanse": "10.05 Shell Shell Lag unepark eRaadal",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 652,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101854884,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-94.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "10.05 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102151553,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-78.70",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "10.05 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100648240,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-189.49",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "09.05 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102443471,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-62.40",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "08.05 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101998899,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-86.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "10.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1099571412,
    "accountNumber": "35302464616",
    "date": "2015-05-11",
    "amount": "-352.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "10.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101809218,
    "accountNumber": "35302464616",
    "date": "2015-05-08",
    "amount": "-97.69",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "07.05 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1104312964,
    "accountNumber": "35302464616",
    "date": "2015-05-08",
    "amount": "-200.00",
    "text": "Spv Åsane Senter Park.P 5116 Ulset",
    "alfareferanse": "08.05 SPV Åsane Senter Park.p 5116 Ulset",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1020,
      "placeId": "ChIJ-9E2lvr9PEYRu4cOpuR9fyY",
      "placeName": "Sparebanken Vest Åsane",
      "coordinates": "60.4655007,5.3220355",
      "website": "https://www.spv.no/kontakt-oss/kontorer/aasane",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1098204606,
    "accountNumber": "35302464616",
    "date": "2015-05-06",
    "amount": "-303.85",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "06.05 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102671661,
    "accountNumber": "35302464616",
    "date": "2015-05-05",
    "amount": "-50.00",
    "text": "Dalia Kebab Che Vaskerelven Bergen",
    "alfareferanse": "05.05 DALIA KEBAB CHE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1021,
      "placeId": "ChIJe2lqdgL8PEYRhNNNokHmKlY",
      "placeName": "Dalia Kebab",
      "coordinates": "60.390665,5.322248",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1100598910,
    "accountNumber": "35302464616",
    "date": "2015-05-05",
    "amount": "-195.20",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "04.05 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101915018,
    "accountNumber": "35302464616",
    "date": "2015-05-04",
    "amount": "-90.00",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "03.05 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1102921494,
    "accountNumber": "35302464616",
    "date": "2015-05-04",
    "amount": "-39.50",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "03.05 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101993474,
    "accountNumber": "35302464616",
    "date": "2015-05-04",
    "amount": "-86.50",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "30.04 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101996935,
    "accountNumber": "35302464616",
    "date": "2015-05-04",
    "amount": "-86.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "01.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1101901423,
    "accountNumber": "35302464616",
    "date": "2015-05-04",
    "amount": "-91.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "30.04 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1093097850,
    "accountNumber": "35302464616",
    "date": "2015-04-30",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087263919,
    "accountNumber": "35302464616",
    "date": "2015-04-30",
    "amount": "-250.34",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "29.04 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087893257,
    "accountNumber": "35302464616",
    "date": "2015-04-30",
    "amount": "-538.80",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "30.04 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090888241,
    "accountNumber": "35302464616",
    "date": "2015-04-30",
    "amount": "-56.00",
    "text": "Hjelle Bakeri Åsane Senter Bergen.",
    "alfareferanse": "30.04 HJELLE BAKERI ÅSANE SENTER BERGEN.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 847,
      "placeId": "ChIJMc6UmPr9PEYRSrWtdRTXFc0",
      "placeName": "Hjelle Bakeri",
      "coordinates": "60.4657162,5.3227725",
      "website": "http://www.hjellebakeri.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1092934270,
    "accountNumber": "35302464616",
    "date": "2015-04-30",
    "amount": "-60.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         30 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090119612,
    "accountNumber": "35302464616",
    "date": "2015-04-28",
    "amount": "-102.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "27.04 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088992909,
    "accountNumber": "35302464616",
    "date": "2015-04-28",
    "amount": "-221.72",
    "text": "Rema Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "27.04 REMA KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1014,
      "placeId": "ChIJ6QeaEaADPUYRa28x28MDPxY",
      "placeName": "REMA 1000",
      "coordinates": "60.5459402,5.2867653",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1084955593,
    "accountNumber": "35302464616",
    "date": "2015-04-28",
    "amount": "-1276.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087265137,
    "accountNumber": "35302464616",
    "date": "2015-04-27",
    "amount": "-250.05",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "25.04 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089454887,
    "accountNumber": "35302464616",
    "date": "2015-04-27",
    "amount": "-163.90",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "25.04 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089828302,
    "accountNumber": "35302464616",
    "date": "2015-04-27",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "26.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089005181,
    "accountNumber": "35302464616",
    "date": "2015-04-27",
    "amount": "-220.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "26.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089877560,
    "accountNumber": "35302464616",
    "date": "2015-04-27",
    "amount": "-122.00",
    "text": "Harbour Cafe Torget 9 Bergen",
    "alfareferanse": "26.04 HARBOUR CAFE TORGET 9 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 567,
      "placeId": "ChIJX9mF3Kf-PEYRu_0lGooLSt0",
      "placeName": "Harbour Café",
      "coordinates": "60.3949519,5.3261725",
      "website": "http://harbourcafe.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088135708,
    "accountNumber": "35302464616",
    "date": "2015-04-27",
    "amount": "-420.00",
    "text": "Hylkje Post I B Hylkjebak.1 Hylkje",
    "alfareferanse": "24.04 HYLKJE POST I B HYLKJEBAK.1  HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 980,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089527714,
    "accountNumber": "35302464616",
    "date": "2015-04-24",
    "amount": "-155.40",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "23.04 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089860037,
    "accountNumber": "35302464616",
    "date": "2015-04-24",
    "amount": "-124.00",
    "text": "Cafe Contra Bar Nedre Ole Bu Bergen",
    "alfareferanse": "24.04 CAFE CONTRA BAR NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1428,
      "placeId": "ChIJb4oT06n-PEYRJ7tUtrXh36w",
      "placeName": "Café Contra Bar",
      "coordinates": "60.391554,5.323348",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089955426,
    "accountNumber": "35302464616",
    "date": "2015-04-24",
    "amount": "-116.00",
    "text": "Esso Loddefjord Loddefjord Bergen",
    "alfareferanse": "24.04 ESSO LODDEFJORD LODDEFJORD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 458,
      "placeId": "ChIJMX-SkQz7PEYRNz5raAaRkLw",
      "placeName": "Esso",
      "coordinates": "60.3611204,5.234456",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090118603,
    "accountNumber": "35302464616",
    "date": "2015-04-22",
    "amount": "-102.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "21.04 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088753273,
    "accountNumber": "35302464616",
    "date": "2015-04-22",
    "amount": "-260.30",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "21.04 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1092208676,
    "accountNumber": "35302464616",
    "date": "2015-04-21",
    "amount": "-48.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*1018 19.04 NOK 48.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1092014919,
    "accountNumber": "35302464616",
    "date": "2015-04-21",
    "amount": "-119.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.04 NOK 119.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090254715,
    "accountNumber": "35302464616",
    "date": "2015-04-20",
    "amount": "-95.79",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "19.04 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089846654,
    "accountNumber": "35302464616",
    "date": "2015-04-20",
    "amount": "-125.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "19.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089847088,
    "accountNumber": "35302464616",
    "date": "2015-04-20",
    "amount": "-125.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "19.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090208427,
    "accountNumber": "35302464616",
    "date": "2015-04-20",
    "amount": "-99.00",
    "text": "Bella Pizza As Nesttunvn 11 Nesttun",
    "alfareferanse": "18.04 BELLA PIZZA AS  NESTTUNVN 11 NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1022,
      "placeId": "ChIJDZfXXbn5PEYRl5OCgM5JxN8",
      "placeName": "Bella Napoli Pizzeria",
      "coordinates": "60.3174199,5.3529848",
      "website": "http://www.bellanapoli.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088538310,
    "accountNumber": "35302464616",
    "date": "2015-04-20",
    "amount": "-302.29",
    "text": "Rema Fjøsanger Straumeveien Bergen",
    "alfareferanse": "18.04 REMA FJØSANGER  STRAUMEVEIEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1023,
      "placeId": "ChIJGUyy7275PEYRu5QkMcUITww",
      "placeName": "REMA 1000",
      "coordinates": "60.3474007,5.3355204",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090276378,
    "accountNumber": "35302464616",
    "date": "2015-04-20",
    "amount": "-94.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "19.04 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088712214,
    "accountNumber": "35302464616",
    "date": "2015-04-20",
    "amount": "-269.90",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "17.04 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087244820,
    "accountNumber": "35302464616",
    "date": "2015-04-17",
    "amount": "-303.40",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "16.04 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1091698272,
    "accountNumber": "35302464616",
    "date": "2015-04-16",
    "amount": "-4315.00",
    "text": "Vingno",
    "alfareferanse": "*1018 14.04 NOK 4315.00 VINGNO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Reise",
    "imageId": "",
    "place": {
      "id": 1024,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089862304,
    "accountNumber": "35302464616",
    "date": "2015-04-16",
    "amount": "-123.80",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "15.04 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089136268,
    "accountNumber": "35302464616",
    "date": "2015-04-15",
    "amount": "-200.00",
    "text": "Brun Og Blid Ådnavegen 63 Indre Arna",
    "alfareferanse": "15.04 BRUN OG BLID ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 633,
      "placeId": "ChIJyXVeGn__PEYRxVXCadP7qTg",
      "placeName": "Brown and happy",
      "coordinates": "60.421339,5.4654701",
      "website": "https://brunogblid.no/",
      "logoId": 95,
      "merchantName": "Brun og blid",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089096792,
    "accountNumber": "35302464616",
    "date": "2015-04-14",
    "amount": "-205.00",
    "text": "Italpizza As Åsane Senter Ulset",
    "alfareferanse": "13.04 ITALPIZZA AS ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1019,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088697344,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-273.00",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "11.04 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089494865,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-159.00",
    "text": "Italpizza As Åsane Senter Ulset",
    "alfareferanse": "12.04 ITALPIZZA AS ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1019,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089928383,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-118.40",
    "text": "Extra Danmarksp Solheimsgt33 Bergen",
    "alfareferanse": "10.04 EXTRA DANMARKSP SOLHEIMSGT33 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1025,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088803626,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-250.49",
    "text": "Esso Laksevåg Carl Konowsg Laksevåg",
    "alfareferanse": "11.04 ESSO LAKSEVÅG CARL KONOWSG LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1026,
      "placeId": "ChIJt3OVAAj8PEYR5LMy8A-Du_w",
      "placeName": "Esso",
      "coordinates": "60.384097,5.3030989",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089714062,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-138.00",
    "text": "Rema Laksevåg A Kringsjåvn. Laksevåg",
    "alfareferanse": "11.04 REMA LAKSEVÅG A KRINGSJÅVN.  LAKSEVÅG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1027,
      "placeId": "ChIJ48v9snT8PEYR7c3rrh7to3k",
      "placeName": "REMA 1000",
      "coordinates": "60.3893928,5.2863534",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088440023,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-328.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "12.04 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089943627,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-117.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "11.04 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090313993,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-91.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "11.04 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089511719,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-157.80",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "12.04 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089486364,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-159.99",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "11.04 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088833914,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-248.65",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "11.04 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089827088,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "12.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089420249,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-168.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "12.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089826927,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "11.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089061620,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-210.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "11.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089826884,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "12.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089827201,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-127.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "12.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088788281,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-254.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "11.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089420321,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-168.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "12.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090354757,
    "accountNumber": "35302464616",
    "date": "2015-04-13",
    "amount": "-89.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "10.04 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088785831,
    "accountNumber": "35302464616",
    "date": "2015-04-10",
    "amount": "-254.70",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "09.04 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089454691,
    "accountNumber": "35302464616",
    "date": "2015-04-10",
    "amount": "-163.90",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "10.04 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090752241,
    "accountNumber": "35302464616",
    "date": "2015-04-10",
    "amount": "-64.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "09.04 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087962891,
    "accountNumber": "35302464616",
    "date": "2015-04-10",
    "amount": "-499.95",
    "text": "B.Young Øyrane Torg Bergen",
    "alfareferanse": "10.04 B.YOUNG ØYRANE TORG  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1429,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus Øyrane Torg",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089854109,
    "accountNumber": "35302464616",
    "date": "2015-04-09",
    "amount": "-124.46",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "08.04 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090040435,
    "accountNumber": "35302464616",
    "date": "2015-04-09",
    "amount": "-109.00",
    "text": "Mama Rosa Kvasnessvn. Isdalstø",
    "alfareferanse": "08.04 MAMA ROSA KVASNESSVN.  ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1028,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090010211,
    "accountNumber": "35302464616",
    "date": "2015-04-08",
    "amount": "-111.20",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "07.04 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088299560,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-368.00",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "01.04 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089952081,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-116.00",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "02.04 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089159252,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-199.00",
    "text": "Italpizza As Åsane Senter Ulset",
    "alfareferanse": "06.04 ITALPIZZA AS ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1019,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089159308,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-199.00",
    "text": "Italpizza As Åsane Senter Ulset",
    "alfareferanse": "03.04 ITALPIZZA AS ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1019,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088015582,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-475.78",
    "text": "Rema Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "04.04 REMA KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1014,
      "placeId": "ChIJ6QeaEaADPUYRa28x28MDPxY",
      "placeName": "REMA 1000",
      "coordinates": "60.5459402,5.2867653",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090857364,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-58.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "05.04 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088155632,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-411.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "06.04 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088750197,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-261.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "03.04 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088291251,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-370.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "05.04 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088721140,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-268.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "02.04 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088183241,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-400.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "04.04 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1086184838,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-95.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 07.04.15",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1086170350,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-167.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 07.04.15",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1084877175,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-200.00",
    "text": "Nokas Deli De Luca Ole 5014 Bergen",
    "alfareferanse": "05.04 Nokas Deli de Luca Ole  5014 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1030,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090154259,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-100.00",
    "text": "Kaos Nygårdsgt. 2 Bergen",
    "alfareferanse": "02.04 KAOS NYGÅRDSGT. 2 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 687,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089963290,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-115.00",
    "text": "Lille Vaskerelven Bergen",
    "alfareferanse": "02.04 LILLE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 740,
      "placeId": "ChIJqzY-egL8PEYRudQQ9OqCRXU",
      "placeName": "Lille Bar & Lounge",
      "coordinates": "60.3911359,5.322035",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089050682,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-212.00",
    "text": "Inside Rock Cafe Bergen",
    "alfareferanse": "05.04 INSIDE ROCK CAFE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 761,
      "placeId": "ChIJFXBwfAL8PEYRScMQLJUMq6E",
      "placeName": "Inside Live & Rock cafe",
      "coordinates": "60.391402,5.3218394",
      "website": "http://insiderockcafe.no/",
      "logoId": 140,
      "merchantName": "Inside Live & Rock cafe",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1086133567,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-298.00",
    "text": "Canal Digital N",
    "alfareferanse": "Nettgiro til: Canal Digital N Betalt: 07.04.15",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kabel-TV/Satellitt",
    "imageId": "",
    "place": {
      "id": 1427,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087737522,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-657.00",
    "text": "Lindex 959 Øyra Ådnavegen 63 Indre Arna",
    "alfareferanse": "01.04 LINDEX 959 ØYRA ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1399,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090802032,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-60.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "02.04 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089000326,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-220.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "02.04 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089396474,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-170.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "03.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089649962,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-144.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "03.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089712722,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-138.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "03.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090616447,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-72.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "03.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090616646,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-72.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "03.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090150201,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-100.00",
    "text": "Upstairs As Vestre Torvg Bergen",
    "alfareferanse": "03.04 UPSTAIRS AS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 562,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088907788,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-236.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "06.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088990608,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-222.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "06.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089843559,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-125.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "06.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090011677,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-111.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "06.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088806258,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-250.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "05.04 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1090370198,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-88.00",
    "text": "Kiwi 823 Spelha Krokatjønnv. Fyllingsdalen",
    "alfareferanse": "01.04 KIWI 823 SPELHA KROKATJØNNV. FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 526,
      "placeId": "ChIJA6mH5rf7PEYRacLUrZF-r2g",
      "placeName": "Kiwi Spelhaugen",
      "coordinates": "60.3498878,5.2765162",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Spelhaugen/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087233357,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-374.30",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "06.04 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087261521,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-254.63",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "01.04 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089975845,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-114.00",
    "text": "7 - Eleven 7091 Strandgt. 2 Bergen",
    "alfareferanse": "05.04 7 - ELEVEN 7091 STRANDGT. 2  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 612,
      "placeId": "ChIJXe9d36f-PEYRb0irnzf202s",
      "placeName": "7-Eleven",
      "coordinates": "60.3945016,5.3258814",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1091019832,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-49.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "06.04 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1091114557,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-43.90",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "05.04 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1088453966,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-324.00",
    "text": "Jasmine Restaur Krokatjønnve Fyllingsdalen",
    "alfareferanse": "01.04 JASMINE RESTAUR KROKATJØNNVE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 600,
      "placeId": "ChIJUV_U6Lf7PEYRe4FzZBpjv5g",
      "placeName": "Krystall Restaurant & Take-Away",
      "coordinates": "60.3496856,5.2770907",
      "website": "http://www.krystallrestaurant.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1089571133,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-150.00",
    "text": "Shell Shell Var Den Fyllingdalen",
    "alfareferanse": "04.04 Shell SHELL VAR DEN  FYLLINGDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 655,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1087244274,
    "accountNumber": "35302464616",
    "date": "2015-04-07",
    "amount": "-304.15",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "04.04 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1071419541,
    "accountNumber": "35302464616",
    "date": "2015-04-01",
    "amount": "14077.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "Fra: GJENSIDIGE FORSIKRING ASA Betalt: 01.04.15",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1078793544,
    "accountNumber": "35302464616",
    "date": "2015-03-31",
    "amount": "-129.00",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "30.03 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1080499740,
    "accountNumber": "35302464616",
    "date": "2015-03-31",
    "amount": "-38.50",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "30.03 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1082572957,
    "accountNumber": "35302464616",
    "date": "2015-03-31",
    "amount": "-62.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         31 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1081033497,
    "accountNumber": "35302464616",
    "date": "2015-03-31",
    "amount": "-10.00",
    "text": "Haukeland Sykehus Bergen",
    "alfareferanse": "30.03 HAUKELAND SYKEHUS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1031,
      "placeId": "ChIJU8l1RDb5PEYRfyuoGFexG3U",
      "placeName": "Haukeland University Hospital / Health Bergen",
      "coordinates": "60.3739406,5.359481",
      "website": "https://helse-bergen.no/",
      "logoId": 210,
      "merchantName": "Haukeland University Hospital",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1079682872,
    "accountNumber": "35302464616",
    "date": "2015-03-31",
    "amount": "-77.70",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "31.03 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1079553368,
    "accountNumber": "35302464616",
    "date": "2015-03-30",
    "amount": "-83.80",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "29.03 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1075611241,
    "accountNumber": "35302464616",
    "date": "2015-03-30",
    "amount": "-299.11",
    "text": "Statoil Fylling Krokatjønnve Fyllingsdalen",
    "alfareferanse": "28.03 STATOIL FYLLING KROKATJØNNVE FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1032,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1079944351,
    "accountNumber": "35302464616",
    "date": "2015-03-30",
    "amount": "-64.00",
    "text": "Mcdonald's Brygges Bryggesporen Bergen",
    "alfareferanse": "29.03 MCD 057 BRYGGES BRYGGESPOREN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 728,
      "placeId": "ChIJP1mWxaf-PEYRnQwhU4NL1qk",
      "placeName": "McDonald's",
      "coordinates": "60.3954113,5.3266711",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1080963260,
    "accountNumber": "35302464616",
    "date": "2015-03-30",
    "amount": "-16.00",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "30.03 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077836042,
    "accountNumber": "35302464616",
    "date": "2015-03-30",
    "amount": "-214.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "29.03 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1072740509,
    "accountNumber": "35302464616",
    "date": "2015-03-30",
    "amount": "-1283.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1072125252,
    "accountNumber": "35302464616",
    "date": "2015-03-30",
    "amount": "-115.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "29.03 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1081769206,
    "accountNumber": "35302464616",
    "date": "2015-03-24",
    "amount": "-15.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*1018 21.03 NOK 15.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1079991978,
    "accountNumber": "35302464616",
    "date": "2015-03-24",
    "amount": "-60.70",
    "text": "Bunnpris Nygårdsgt 89 Bergen",
    "alfareferanse": "24.03 BUNNPRIS NYGÅRDSGT 89 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1033,
      "placeId": "ChIJ7_vXiKz-PEYR0bY0ABaG5ZQ",
      "placeName": "Bunnpris",
      "coordinates": "60.3850653,5.3315794",
      "website": "http://bunnpris.no/butikker/bunnpris-nygardsgaten",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076507359,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-494.88",
    "text": "Only Horisont Myrdalsvegen Nyborg",
    "alfareferanse": "20.03 ONLY HORISONT MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1035,
      "placeId": "ChIJ17QdB_r9PEYRdHrcvlNgcno",
      "placeName": "Horisont Shopping",
      "coordinates": "60.4698923,5.322545",
      "website": "http://horisont.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076570209,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-466.38",
    "text": "Kiwi 888 Stølsvn 1 Indre Arna",
    "alfareferanse": "21.03 KIWI 888 STØLSVN 1 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1034,
      "placeId": "ChIJWV2tE4D_PEYRViszQPVr8WI",
      "placeName": "KIWI Indre Arna",
      "coordinates": "60.4245007,5.4706934",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Indre-Arna/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077023622,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-342.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "20.03 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1078731953,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-133.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "21.03 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1080698146,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-29.90",
    "text": "Nille 120 Ådnavegen 63 Indre Arna",
    "alfareferanse": "21.03 NILLE 120 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1430,
      "placeId": "ChIJC3CXGn__PEYReA-pLrPrA0U",
      "placeName": "nille",
      "coordinates": "60.4214664,5.4656163",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076867527,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-378.80",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "20.03 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1078675637,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-138.00",
    "text": "Rema Øyrane Tor Ådnavegen 63 Indre Arna",
    "alfareferanse": "21.03 REMA ØYRANE TOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1300,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077091835,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-326.38",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "20.03 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1075604332,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-300.44",
    "text": "Shell Shell Hau Kaas Ny Bnyborg",
    "alfareferanse": "21.03 Shell Shell Hau kaas Ny bNyborg",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 656,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1078481572,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-151.30",
    "text": "27 Plantasjen Åsamyrane 32 Nyborg",
    "alfareferanse": "21.03 27 PLANTASJEN ÅSAMYRANE 32 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Hagesenter",
    "imageId": "",
    "place": {
      "id": 641,
      "placeId": "ChIJB01zzP8BPUYRoT3m9BfwoII",
      "placeName": "Plantasjen",
      "coordinates": "60.4767896,5.3452213",
      "website": "https://www.plantasjen.no/storelocator/bergen-asane",
      "logoId": 54,
      "merchantName": "Plantasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1079433687,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-90.00",
    "text": "Shell Shell Hau Kaas Ny Bnyborg",
    "alfareferanse": "21.03 Shell Shell Hau kaas Ny bNyborg",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 656,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1078767263,
    "accountNumber": "35302464616",
    "date": "2015-03-23",
    "amount": "-130.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "22.03 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1081518380,
    "accountNumber": "35302464616",
    "date": "2015-03-20",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*1018 18.03 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076498232,
    "accountNumber": "35302464616",
    "date": "2015-03-20",
    "amount": "-498.00",
    "text": "Lindex 959 Øyra Ådnavegen 63 Indre Arna",
    "alfareferanse": "19.03 LINDEX 959 ØYRA ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1399,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1073937492,
    "accountNumber": "35302464616",
    "date": "2015-03-19",
    "amount": "-2685.61",
    "text": "Bkk Kundetjenes",
    "alfareferanse": "Nettgiro til: BKK KUNDETJENES Betalt: 19.03.15",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Elektrisitet",
    "imageId": "",
    "place": {
      "id": 787,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1078606242,
    "accountNumber": "35302464616",
    "date": "2015-03-19",
    "amount": "-143.00",
    "text": "Big Bite 322 Myrdalsvegen 5130 Nyborg",
    "alfareferanse": "18.03 BIG BITE 322 MYRDALSVEGEN 5130 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 914,
      "placeId": "ChIJq3eFEPr9PEYReCP0kl8cLHk",
      "placeName": "Big Bite",
      "coordinates": "60.4699227,5.3225459",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077453021,
    "accountNumber": "35302464616",
    "date": "2015-03-19",
    "amount": "-265.00",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "18.03 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077804407,
    "accountNumber": "35302464616",
    "date": "2015-03-19",
    "amount": "-218.40",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "18.03 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076647827,
    "accountNumber": "35302464616",
    "date": "2015-03-19",
    "amount": "-439.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "18.03 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1075589389,
    "accountNumber": "35302464616",
    "date": "2015-03-19",
    "amount": "-353.89",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "18.03 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1080151648,
    "accountNumber": "35302464616",
    "date": "2015-03-18",
    "amount": "-53.60",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "17.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076856838,
    "accountNumber": "35302464616",
    "date": "2015-03-18",
    "amount": "-380.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "17.03 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076571001,
    "accountNumber": "35302464616",
    "date": "2015-03-18",
    "amount": "-466.00",
    "text": "Kremmerhuset 27 Torgalmennin Bergen",
    "alfareferanse": "17.03 KREMMERHUSET 27 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 733,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1079431230,
    "accountNumber": "35302464616",
    "date": "2015-03-18",
    "amount": "-90.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "17.03 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1078322047,
    "accountNumber": "35302464616",
    "date": "2015-03-18",
    "amount": "-166.90",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "17.03 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077696726,
    "accountNumber": "35302464616",
    "date": "2015-03-17",
    "amount": "-231.40",
    "text": "Nespresso Bouti Strandgaten Bergen",
    "alfareferanse": "17.03 NESPRESSO BOUTI STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 738,
      "placeId": "ChIJJQ2lA6j-PEYRkm-DbV9hQQY",
      "placeName": "Nespresso Boutique Bergen",
      "coordinates": "60.3940462,5.3237397",
      "website": "http://www.nespresso.com/",
      "logoId": 133,
      "merchantName": "Nespresso Boutique",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1082550186,
    "accountNumber": "35302464616",
    "date": "2015-03-13",
    "amount": "-100.00",
    "text": "Rebestilling Av Kort",
    "alfareferanse": "Rebestilling av kort",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1036,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1073994043,
    "accountNumber": "35302464616",
    "date": "2015-03-11",
    "amount": "-1500.00",
    "text": "Tollregion Oslo",
    "alfareferanse": "Nettgiro til: TOLLREGION OSLO Betalt: 11.03.15",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Offentlige avgifter",
    "imageId": "",
    "place": {
      "id": 1037,
      "placeId": "ChIJ6Wr3goluQUYR7b7kL2CICo8",
      "placeName": "Customs Region Oslo and Akershus",
      "coordinates": "59.9109062,10.7594563",
      "website": "http://www.toll.no/no/om-tolletaten/kontakt-oss/tollregion-oslo-og-akershus/",
      "logoId": 212,
      "merchantName": "Toll",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1074098783,
    "accountNumber": "35302464616",
    "date": "2015-03-11",
    "amount": "-575.00",
    "text": "Bergen Bompenge",
    "alfareferanse": "Nettgiro til: BERGEN BOMPENGE Betalt: 11.03.15",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bompenger",
    "imageId": "",
    "place": {
      "id": 1290,
      "placeId": "ChIJheVQtUX5PEYRoLGjxDF1E90",
      "placeName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "coordinates": "60.3677117,5.3417854",
      "website": "http://www.bomringenbergen.no/",
      "logoId": 266,
      "merchantName": "Bergen Bompengeselskap AS / Bomringen i Bergen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1074093062,
    "accountNumber": "35302464616",
    "date": "2015-03-11",
    "amount": "-600.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 11.03.15",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1081262651,
    "accountNumber": "35302464616",
    "date": "2015-03-10",
    "amount": "-289.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 07.03 NOK 289.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077092221,
    "accountNumber": "35302464616",
    "date": "2015-03-09",
    "amount": "-326.20",
    "text": "Apotek 1 Plognv. 6 Oslo",
    "alfareferanse": "07.03 APOTEK 1 PLOGNV. 6 OSLO",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1038,
      "placeId": "ChIJC3pcfX1uQUYR4DOSSCkP2Bw",
      "placeName": "Apotek 1",
      "coordinates": "59.9129897,10.7382068",
      "website": "https://www.apotek1.no/vaare-apotek/oslo/nordstjernen-oslo-630",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1074243592,
    "accountNumber": "35302464616",
    "date": "2015-03-03",
    "amount": "-162.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 03.03.15",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1074194637,
    "accountNumber": "35302465310",
    "date": "2015-03-03",
    "amount": "-298.00",
    "text": "Canal Digital N",
    "alfareferanse": "Nettgiro til: Canal Digital N Betalt: 03.03.15",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kabel-TV/Satellitt",
    "imageId": "",
    "place": {
      "id": 1427,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1080537367,
    "accountNumber": "35302464616",
    "date": "2015-03-03",
    "amount": "-36.00",
    "text": "Q-Park Citypark Markeveien 7 Bergen",
    "alfareferanse": "03.03 Q-PARK CITYPARK MARKEVEIEN 7 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1299,
      "placeId": "ChIJL_dawAL8PEYRLRpe4CxHlAk",
      "placeName": "City Park parking garage",
      "coordinates": "60.393622,5.320199",
      "website": "http://www.q-park.no/no/parker-hos-q-park-/finn-parkering/parkering-per-by-sted/bergen/city-park-p-hus",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1081585549,
    "accountNumber": "35302464616",
    "date": "2015-03-03",
    "amount": "-68.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 01.03 NOK 68.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1072724126,
    "accountNumber": "35302464616",
    "date": "2015-03-02",
    "amount": "-1517.00",
    "text": "Gjensidige Forsikring Asa",
    "alfareferanse": "GJENSIDIGE FORSIKRING ASA",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 462,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1079801360,
    "accountNumber": "35302464616",
    "date": "2015-03-02",
    "amount": "-70.50",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "27.02 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076703066,
    "accountNumber": "35302464616",
    "date": "2015-03-02",
    "amount": "-419.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "28.02 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076622465,
    "accountNumber": "35302464616",
    "date": "2015-03-02",
    "amount": "-448.00",
    "text": "Bikbok J38 Myrdalvn 2 Nyborg",
    "alfareferanse": "28.02 BIKBOK J38 MYRDALVN 2 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1308,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1076092103,
    "accountNumber": "35302464616",
    "date": "2015-03-02",
    "amount": "-752.99",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "28.02 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1077306688,
    "accountNumber": "35302464616",
    "date": "2015-03-02",
    "amount": "-291.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "28.02 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1069961959,
    "accountNumber": "35302464616",
    "date": "2015-02-28",
    "amount": "-108.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         54 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 575,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066146285,
    "accountNumber": "35302464616",
    "date": "2015-02-27",
    "amount": "-208.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "27.02 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064839133,
    "accountNumber": "35302464616",
    "date": "2015-02-27",
    "amount": "-598.80",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "26.02 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065043812,
    "accountNumber": "35302464616",
    "date": "2015-02-27",
    "amount": "-477.00",
    "text": "Hylkje Post I B Hylkjebak.1 Hylkje",
    "alfareferanse": "26.02 HYLKJE POST I B HYLKJEBAK.1  HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 980,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066462923,
    "accountNumber": "35302464616",
    "date": "2015-02-26",
    "amount": "-172.00",
    "text": "Narvesen 778 Åsane Sent. Ulset",
    "alfareferanse": "26.02 NARVESEN 778 ÅSANE SENT.  ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1039,
      "placeId": "ChIJE8vqqPr9PEYRjAAbpqe8rS0",
      "placeName": "Narvesen",
      "coordinates": "60.4650684,5.3217269",
      "website": "http://www.narvesen.no/",
      "logoId": 183,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1061163505,
    "accountNumber": "36241466323",
    "date": "2015-02-26",
    "amount": "39000.00",
    "text": "749",
    "alfareferanse": "150013749",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Boliglån",
    "imageId": "",
    "place": {
      "id": 1480,
      "search": "749",
      "logoId": 0,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1069234662,
    "accountNumber": "35302464616",
    "date": "2015-02-25",
    "amount": "-48.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 23.02 NOK 48.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068264161,
    "accountNumber": "35302464616",
    "date": "2015-02-25",
    "amount": "-40.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.02 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068194696,
    "accountNumber": "35302464616",
    "date": "2015-02-25",
    "amount": "-44.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.02 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1061432176,
    "accountNumber": "35302464616",
    "date": "2015-02-25",
    "amount": "-300.00",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "24.02 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066533612,
    "accountNumber": "35302464616",
    "date": "2015-02-24",
    "amount": "-164.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "23.02 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1069254142,
    "accountNumber": "35302464616",
    "date": "2015-02-24",
    "amount": "-39.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 20.02 NOK 39.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064598080,
    "accountNumber": "35302464616",
    "date": "2015-02-24",
    "amount": "-896.65",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "23.02 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065609355,
    "accountNumber": "35302464616",
    "date": "2015-02-24",
    "amount": "-300.00",
    "text": "Coop Obs Hyperm Myrdalsveien Nyborg",
    "alfareferanse": "23.02 COOP OBS HYPERM MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 890,
      "placeId": "ChIJj9NRBfr9PEYR6iEVzMi-m9I",
      "placeName": "Obs",
      "coordinates": "60.470369,5.3228398",
      "website": "https://coop.no/butikker/obs/horisont-2551/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066509143,
    "accountNumber": "35302464616",
    "date": "2015-02-23",
    "amount": "-166.70",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "21.02 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068095213,
    "accountNumber": "35302464616",
    "date": "2015-02-23",
    "amount": "-49.80",
    "text": "Spar Dale K.Jebsensv. Dalekvam",
    "alfareferanse": "22.02 SPAR DALE K.JEBSENSV.  DALEKVAM",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1040,
      "placeId": "ChIJMZ6cO8yxPUYRyuL8r4CHhr8",
      "placeName": "SPAR Dale",
      "coordinates": "60.5900549,5.8204727",
      "website": "http://spar.no/Finn-butikk/SPAR-Dale/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068630715,
    "accountNumber": "35302464616",
    "date": "2015-02-23",
    "amount": "-19.90",
    "text": "Meny Knarvik Kvernhusmyra Isdalstø",
    "alfareferanse": "20.02 MENY KNARVIK KVERNHUSMYRA ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1041,
      "placeId": "ChIJHb82A6ADPUYRRX_haZjdlf8",
      "placeName": "Meny Knarvik",
      "coordinates": "60.5468007,5.2858158",
      "website": "http://meny.no/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065685651,
    "accountNumber": "35302464616",
    "date": "2015-02-23",
    "amount": "-287.50",
    "text": "Meny Knarvik Kvernhusmyra Isdalstø",
    "alfareferanse": "20.02 MENY KNARVIK KVERNHUSMYRA ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1041,
      "placeId": "ChIJHb82A6ADPUYRRX_haZjdlf8",
      "placeName": "Meny Knarvik",
      "coordinates": "60.5468007,5.2858158",
      "website": "http://meny.no/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066897500,
    "accountNumber": "35302464616",
    "date": "2015-02-23",
    "amount": "-129.00",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "21.02 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064277023,
    "accountNumber": "35302464616",
    "date": "2015-02-23",
    "amount": "-499.05",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "21.02 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066968730,
    "accountNumber": "35302464616",
    "date": "2015-02-23",
    "amount": "-121.89",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "21.02 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066441963,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-175.00",
    "text": "Baker Brun As Damsgårdsvei Bergen",
    "alfareferanse": "19.02 BAKER BRUN AS DAMSGÅRDSVEI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 631,
      "placeId": "ChIJUT-MYv_7PEYRbDxdWnAc-DE",
      "placeName": "Baker Brun Produksjon AS",
      "coordinates": "60.383095,5.3148769",
      "website": "http://www.bakerbrun.no/",
      "logoId": 98,
      "merchantName": "Baker Brun",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067263609,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-100.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "19.02 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068619008,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-20.00",
    "text": "Bygarasjen Kaigaten 5 Bergen",
    "alfareferanse": "19.02 BYGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 485,
      "placeId": "ChIJw-V6JKz-PEYRh-Jz7ilHYtk",
      "placeName": "ByGarasjen",
      "coordinates": "60.387808,5.3334129",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1063144153,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-326.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 19.02.15",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1063150099,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-316.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 19.02.15",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068702049,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-11.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "19.02 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068160633,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-45.70",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "20.02 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1063134198,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-360.64",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 19.02.15",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064932184,
    "accountNumber": "35302464616",
    "date": "2015-02-20",
    "amount": "-531.80",
    "text": "Vinmonopolet Arna Indre Arna",
    "alfareferanse": "20.02 VINMONOPOLET ARNA INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1301,
      "placeId": "ChIJC6INGX__PEYRZrXsulc2wA4",
      "placeName": "Vinmonopolet Bergen, Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.vinmonopolet.no/",
      "logoId": 270,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1062947956,
    "accountNumber": "35302464616",
    "date": "2015-02-19",
    "amount": "-2264.98",
    "text": "Bkk Kundetjenes",
    "alfareferanse": "Nettgiro til: BKK KUNDETJENES Betalt: 19.02.15",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Elektrisitet",
    "imageId": "",
    "place": {
      "id": 787,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066997224,
    "accountNumber": "35302464616",
    "date": "2015-02-19",
    "amount": "-119.90",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "18.02 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064316167,
    "accountNumber": "35302464616",
    "date": "2015-02-19",
    "amount": "-300.01",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "18.02 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065679845,
    "accountNumber": "35302464616",
    "date": "2015-02-19",
    "amount": "-288.78",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "18.02 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067540698,
    "accountNumber": "35302464616",
    "date": "2015-02-19",
    "amount": "-82.70",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "19.02 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066011533,
    "accountNumber": "35302464616",
    "date": "2015-02-18",
    "amount": "-228.56",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "17.02 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065265010,
    "accountNumber": "35302464616",
    "date": "2015-02-17",
    "amount": "-392.00",
    "text": "Kremmerhuset Laguneveien Rådal",
    "alfareferanse": "16.02 KREMMERHUSET LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1042,
      "placeId": "ChIJZcbcveb5PEYRuiE8vVGoYco",
      "placeName": "Kremmerhuset",
      "coordinates": "60.2969378,5.3307471",
      "website": "http://www.kremmerhuset.no/",
      "logoId": 169,
      "merchantName": "Kremmerhuset",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065151675,
    "accountNumber": "35302464616",
    "date": "2015-02-17",
    "amount": "-427.50",
    "text": "H&M 779 Lagunen Krohnsvn.12 Rdal",
    "alfareferanse": "16.02 H&M 779 LAGUNEN KROHNSVN.12  RDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1043,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1069100367,
    "accountNumber": "35302464616",
    "date": "2015-02-17",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.02 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1069131154,
    "accountNumber": "35302464616",
    "date": "2015-02-17",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 14.02 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066129137,
    "accountNumber": "35302464616",
    "date": "2015-02-17",
    "amount": "-210.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "16.02 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066350536,
    "accountNumber": "35302464616",
    "date": "2015-02-16",
    "amount": "-186.00",
    "text": "424Europrislone Lonevåg Aren Lonevåg",
    "alfareferanse": "13.02 424EUROPRISLONE LONEVÅG AREN LONEVÅG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1045,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064272832,
    "accountNumber": "35302464616",
    "date": "2015-02-16",
    "amount": "-502.34",
    "text": "1-2-3 Knarvik 5 Kvassnesvn. Isdalstø",
    "alfareferanse": "14.02 1-2-3 KNARVIK 5 KVASSNESVN.  ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1046,
      "placeId": "ChIJ60FlYp8DPUYRxGs2HOIOWfA",
      "placeName": "Shell",
      "coordinates": "60.5471999,5.2912582",
      "website": "https://find.shell.com/no/fuel/NO_9232-shell-knarvik",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064532713,
    "accountNumber": "35302464616",
    "date": "2015-02-16",
    "amount": "-1051.00",
    "text": "Kicks 675 Åsane Senter Ulset",
    "alfareferanse": "14.02 KICKS 675 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 868,
      "placeId": "ChIJ1RP5sfr9PEYR9RNWaC99jzQ",
      "placeName": "KICKS",
      "coordinates": "60.4654946,5.3214737",
      "website": "https://www.kicks.no/butikker/ulset-asane-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068393741,
    "accountNumber": "35302464616",
    "date": "2015-02-13",
    "amount": "-32.18",
    "text": "Kiwi Knarvik Knarvik Senter",
    "alfareferanse": "12.02 KIWI KNARVIK KNARVIK SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1047,
      "placeId": "ChIJz6Zh658DPUYRCprxm6g5vP0",
      "placeName": "KIWI Knarvik",
      "coordinates": "60.5483609,5.2874532",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Kvassnesvegen-Knarvik/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068694823,
    "accountNumber": "35302464616",
    "date": "2015-02-13",
    "amount": "-12.90",
    "text": "Mekk Knarvik Promenaden Isdalstø",
    "alfareferanse": "12.02 MEKK KNARVIK PROMENADEN ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1048,
      "placeId": "ChIJW62M_p8DPUYRjRIi3Zubkag",
      "placeName": "Mekk Knarvik Senter",
      "coordinates": "60.5475902,5.2855886",
      "website": "http://www.mekk.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064685811,
    "accountNumber": "35302464616",
    "date": "2015-02-13",
    "amount": "-749.00",
    "text": "Vita Kvernhushaug Isdalstø",
    "alfareferanse": "12.02 1613 VITA KVERNHUSHAUG ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1049,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067680358,
    "accountNumber": "35302464616",
    "date": "2015-02-13",
    "amount": "-74.50",
    "text": "Cmyk Knarrvik Knarvik Sent Isdalstø",
    "alfareferanse": "12.02 CMYK KNARRVIK KNARVIK SENT ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1050,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064911669,
    "accountNumber": "35302464616",
    "date": "2015-02-12",
    "amount": "-547.00",
    "text": "Mango Norge Åsane Senter Ulset",
    "alfareferanse": "11.02 MANGO NORGE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 985,
      "placeId": "ChIJGQBJkfr9PEYRw1bmEKKokmE",
      "placeName": "Mango",
      "coordinates": "60.4662392,5.322624",
      "website": "http://shop.mango.com/preHome.faces",
      "logoId": 203,
      "merchantName": "Mango",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067223800,
    "accountNumber": "35302464616",
    "date": "2015-02-12",
    "amount": "-101.80",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "11.02 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067205810,
    "accountNumber": "35302464616",
    "date": "2015-02-12",
    "amount": "-102.90",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "11.02 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068548279,
    "accountNumber": "35302464616",
    "date": "2015-02-12",
    "amount": "-24.40",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "11.02 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067412583,
    "accountNumber": "35302464616",
    "date": "2015-02-11",
    "amount": "-90.40",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "10.02 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066108884,
    "accountNumber": "35302464616",
    "date": "2015-02-11",
    "amount": "-213.59",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "10.02 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068913836,
    "accountNumber": "35302464616",
    "date": "2015-02-10",
    "amount": "-289.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 08.02 NOK 289.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064952718,
    "accountNumber": "35302464616",
    "date": "2015-02-10",
    "amount": "-519.00",
    "text": "Expert Bonus Kvassnesv.23 Isdalstø",
    "alfareferanse": "09.02 EXPERT BONUS KVASSNESV.23 ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 1012,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065743245,
    "accountNumber": "35302464616",
    "date": "2015-02-10",
    "amount": "-274.83",
    "text": "Kiwi Knarvik Knarvik Senter",
    "alfareferanse": "09.02 KIWI KNARVIK KNARVIK SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1047,
      "placeId": "ChIJz6Zh658DPUYRCprxm6g5vP0",
      "placeName": "KIWI Knarvik",
      "coordinates": "60.5483609,5.2874532",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Kvassnesvegen-Knarvik/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064681348,
    "accountNumber": "35302464616",
    "date": "2015-02-09",
    "amount": "-752.19",
    "text": "Rema Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "07.02 REMA KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1014,
      "placeId": "ChIJ6QeaEaADPUYRa28x28MDPxY",
      "placeName": "REMA 1000",
      "coordinates": "60.5459402,5.2867653",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1063200525,
    "accountNumber": "35302464616",
    "date": "2015-02-09",
    "amount": "-155.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 08.02.15",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068730298,
    "accountNumber": "35302464616",
    "date": "2015-02-09",
    "amount": "-6.70",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "07.02 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064272251,
    "accountNumber": "35302464616",
    "date": "2015-02-09",
    "amount": "-503.71",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "07.02 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1068711064,
    "accountNumber": "35302464616",
    "date": "2015-02-05",
    "amount": "-10.00",
    "text": "Bunnpris Møllen Møllendalsba Bergen",
    "alfareferanse": "04.02 BUNNPRIS MØLLEN MØLLENDALSBA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1051,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067634096,
    "accountNumber": "35302464616",
    "date": "2015-02-04",
    "amount": "-77.19",
    "text": "Bunnpris Møllen Møllendalsba Bergen",
    "alfareferanse": "04.02 BUNNPRIS MØLLEN MØLLENDALSBA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1051,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066372480,
    "accountNumber": "35302464616",
    "date": "2015-02-04",
    "amount": "-182.80",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "04.02 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067505147,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-84.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "31.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1061396500,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-516.00",
    "text": "Statoil Helleve Helleveien 3 Bergen",
    "alfareferanse": "31.01 STATOIL HELLEVE HELLEVEIEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 895,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067101927,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-111.00",
    "text": "Trg Norge As Åsene Storse Ulset",
    "alfareferanse": "30.01 TRG NORGE AS ÅSENE STORSE ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 910,
      "placeId": "ChIJLc6svPr9PEYRfqz1Eoow7K8",
      "placeName": "H&M",
      "coordinates": "60.4661421,5.3227179",
      "website": "http://www.hm.com/no/",
      "logoId": 187,
      "merchantName": "H&M",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064641028,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-808.49",
    "text": "Meny Knarvik Kvernhusmyra Isdalstø",
    "alfareferanse": "31.01 MENY KNARVIK KVERNHUSMYRA ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1041,
      "placeId": "ChIJHb82A6ADPUYRRX_haZjdlf8",
      "placeName": "Meny Knarvik",
      "coordinates": "60.5468007,5.2858158",
      "website": "http://meny.no/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064906149,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-549.70",
    "text": "Vinmonopolet Knarvik Isdalst",
    "alfareferanse": "31.01 VINMONOPOLET KNARVIK ISDALST",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 1052,
      "placeId": "ChIJaduJHKADPUYRrKtZKwut9fk",
      "placeName": "Vinmonopolet",
      "coordinates": "60.5469771,5.2865279",
      "website": "http://www.vinmonopolet.no/butikker?butikk_id=156",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067675979,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-74.90",
    "text": "Apotek 1 Kvassnesvn. Isdalstø",
    "alfareferanse": "31.01 APOTEK 1 KVASSNESVN.  ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1053,
      "placeId": "ChIJZZ-C_J8DPUYR8aNTcvu80oA",
      "placeName": "Apotek 1",
      "coordinates": "60.5474954,5.28682",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/promenaden-525",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066120676,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-211.00",
    "text": "Apotek 1 Kvassnesvn. Isdalstø",
    "alfareferanse": "31.01 APOTEK 1 KVASSNESVN.  ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1053,
      "placeId": "ChIJZZ-C_J8DPUYR8aNTcvu80oA",
      "placeName": "Apotek 1",
      "coordinates": "60.5474954,5.28682",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/promenaden-525",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1064637487,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-815.00",
    "text": "Hylkje Post I B Hylkjebak.1 Hylkje",
    "alfareferanse": "31.01 HYLKJE POST I B HYLKJEBAK.1  HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 980,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065045919,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-475.70",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "30.01 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067350582,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-95.30",
    "text": "Nille 96 Sane Sane Senter Ulset",
    "alfareferanse": "30.01 NILLE 96 SANE SANE SENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 846,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065030911,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-483.34",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "30.01 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067416616,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-90.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "01.02 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065486230,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-327.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "01.02 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066155879,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-206.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "01.02 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066194056,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-200.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "01.02 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066155909,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-206.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "01.02 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065595395,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-300.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "01.02 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065936102,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-240.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "01.02 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065093426,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-450.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "01.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067240184,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-100.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "01.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066150293,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-207.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "01.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1066150319,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-207.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "01.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1065283294,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-386.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "01.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067101913,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-111.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "01.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1067415713,
    "accountNumber": "35302464616",
    "date": "2015-02-02",
    "amount": "-90.00",
    "text": "Ricks Silver Veiten 3 Bergen",
    "alfareferanse": "01.02 RICKS SILVER VEITEN 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 521,
      "placeId": "ChIJXWGfkQL8PEYRgq_G5t-MB6w",
      "placeName": "Ricks AS",
      "coordinates": "60.3927185,5.3210843",
      "website": "http://www.ricks.no/",
      "logoId": 62,
      "merchantName": "Ricks",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1059619884,
    "accountNumber": "35302464616",
    "date": "2015-01-31",
    "amount": "-48.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         24 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055853661,
    "accountNumber": "35302464616",
    "date": "2015-01-30",
    "amount": "-210.00",
    "text": "Vitusapotek Kna Kvernhusmyra Isdalstø",
    "alfareferanse": "29.01 VITUSAPOTEK KNA KVERNHUSMYRA ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 997,
      "placeId": "ChIJhy2R9Z8DPUYRitZ8-3_BEUk",
      "placeName": "Vitusapotek Knarvik",
      "coordinates": "60.5474554,5.287272",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Knarvik",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058231790,
    "accountNumber": "35302464616",
    "date": "2015-01-30",
    "amount": "-20.00",
    "text": "Narvesen 179 By Agnes Mowinc Bergen",
    "alfareferanse": "29.01 NARVESEN 179 BY AGNES MOWINC BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1054,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056710016,
    "accountNumber": "35302464616",
    "date": "2015-01-30",
    "amount": "-117.40",
    "text": "Bunnpris Møllen Møllendalsba Bergen",
    "alfareferanse": "30.01 BUNNPRIS MØLLEN MØLLENDALSBA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1051,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054968193,
    "accountNumber": "35302464616",
    "date": "2015-01-30",
    "amount": "-402.47",
    "text": "Kiwi Knarvik Knarvik Senter",
    "alfareferanse": "29.01 KIWI KNARVIK KNARVIK SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1047,
      "placeId": "ChIJz6Zh658DPUYRCprxm6g5vP0",
      "placeName": "KIWI Knarvik",
      "coordinates": "60.5483609,5.2874532",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Kvassnesvegen-Knarvik/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056870325,
    "accountNumber": "35302464616",
    "date": "2015-01-29",
    "amount": "-103.18",
    "text": "Bunnpris Møllen Møllendalsba Bergen",
    "alfareferanse": "29.01 BUNNPRIS MØLLEN MØLLENDALSBA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1051,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054294320,
    "accountNumber": "35302464616",
    "date": "2015-01-29",
    "amount": "-1136.10",
    "text": "Kiwi 879 Rollandslia Ulset",
    "alfareferanse": "28.01 KIWI 879 ROLLANDSLIA  ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1055,
      "placeId": "ChIJRQFgZOP9PEYRUIukZGk4fIw",
      "placeName": "KIWI Rolland",
      "coordinates": "60.4621107,5.3295933",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Rolland/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056694031,
    "accountNumber": "35302464616",
    "date": "2015-01-29",
    "amount": "-119.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "29.01 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057801046,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-44.96",
    "text": "Glitter Åsane Senter Ulset",
    "alfareferanse": "27.01 GLITTER ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 1056,
      "placeId": "ChIJoVxneQL8PEYRMQVwyAebTJ8",
      "placeName": "Glitter",
      "coordinates": "60.4655343,5.3232015",
      "website": "https://www.glitter.no/storelocator/Glitter-asaneStorsenter",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055760002,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-226.00",
    "text": "Princess Arken Arken Senter Ulset",
    "alfareferanse": "27.01 PRINCESS ARKEN  ARKEN SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1057,
      "placeId": "ChIJCfgW5fr9PEYRCczc0YFMr0s",
      "placeName": "Princess avd. Arken",
      "coordinates": "60.466458,5.3248722",
      "website": "https://www.princessbutikken.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056134644,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-177.20",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "27.01 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057805393,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-44.70",
    "text": "Kiwi Åsane Senter Ulset",
    "alfareferanse": "27.01 KIWI ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 750,
      "placeId": "ChIJDcFBlfr9PEYRuAD0WFodumQ",
      "placeName": "KIWI Åsane Storsenter",
      "coordinates": "60.4665013,5.3244853",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Asane-Storsenter/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055008357,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-397.00",
    "text": "Cubus 877 Arken Ulset",
    "alfareferanse": "27.01 CUBUS 877 ARKEN ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 870,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054384800,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-881.40",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "27.01 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058876812,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-39.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 26.01 NOK 39.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055845338,
    "accountNumber": "35302464616",
    "date": "2015-01-28",
    "amount": "-211.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "27.01 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057263000,
    "accountNumber": "35302464616",
    "date": "2015-01-27",
    "amount": "-79.00",
    "text": "Gina Tricot Ber Sane Center Ulset",
    "alfareferanse": "26.01 GINA TRICOT BER SANE CENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 849,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054844955,
    "accountNumber": "35302464616",
    "date": "2015-01-27",
    "amount": "-456.00",
    "text": "Gina Tricot Ber Sane Center Ulset",
    "alfareferanse": "26.01 GINA TRICOT BER SANE CENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 849,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054638733,
    "accountNumber": "35302464616",
    "date": "2015-01-27",
    "amount": "-576.16",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "26.01 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054300732,
    "accountNumber": "35302464616",
    "date": "2015-01-27",
    "amount": "-1104.50",
    "text": "H&M Planned Åsane Senter Ulset",
    "alfareferanse": "26.01 H&M PLANNED ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1058,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055305593,
    "accountNumber": "35302464616",
    "date": "2015-01-27",
    "amount": "-309.60",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "26.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055779421,
    "accountNumber": "35302464616",
    "date": "2015-01-27",
    "amount": "-222.40",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "26.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055994373,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-195.45",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "24.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055046314,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-383.00",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "24.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055668898,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-240.00",
    "text": "Ica Superm Kvasnesveien Isdalstø",
    "alfareferanse": "24.01 4229 ICA SUPERM KVASNESVEIEN ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1059,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057317426,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-75.00",
    "text": "Vitusapotek Bie Solheimsvn. Bergen",
    "alfareferanse": "26.01 VITUSAPOTEK BIE SOLHEIMSVN.  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1060,
      "placeId": "ChIJW-e2tK7-PEYRGP_JiUKGoXs",
      "placeName": "Vitusapotek Nordstjernen - Bergen",
      "coordinates": "60.3893095,5.3325248",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Nordstjernen-Bergen/",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057804643,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-44.73",
    "text": "Extra Danmarksp Solheimsgt33 Bergen",
    "alfareferanse": "26.01 EXTRA DANMARKSP SOLHEIMSGT33 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1025,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055081089,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-370.67",
    "text": "Rema Knarvik Kvassnesvege Isdalstø",
    "alfareferanse": "24.01 REMA KNARVIK KVASSNESVEGE ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1014,
      "placeId": "ChIJ6QeaEaADPUYRa28x28MDPxY",
      "placeName": "REMA 1000",
      "coordinates": "60.5459402,5.2867653",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058875970,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-39.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 22.01 NOK 39.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054104232,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-300.22",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "23.01 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054067656,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-499.99",
    "text": "Uno-X Fyllingsd Alen 03 Fyllingsdale",
    "alfareferanse": "25.01 UNO-X FYLLINGSD ALEN 03 FYLLINGSDALE",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 669,
      "placeId": "ChIJkYrNcsP7PEYRoJ_Ym8RHu2s",
      "placeName": "Uno-X Fyllingsdalen",
      "coordinates": "60.3557261,5.2930183",
      "website": "http://unox.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056878715,
    "accountNumber": "35302464616",
    "date": "2015-01-26",
    "amount": "-102.30",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "23.01 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058745911,
    "accountNumber": "35302464616",
    "date": "2015-01-23",
    "amount": "-90.00",
    "text": "Qpark As",
    "alfareferanse": "*4928 20.01 NOK 90.00 QPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 789,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055127465,
    "accountNumber": "35302464616",
    "date": "2015-01-22",
    "amount": "-355.80",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "21.01 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057339708,
    "accountNumber": "35302464616",
    "date": "2015-01-22",
    "amount": "-73.30",
    "text": "Panduro Hobby Åsane Senter Ulset",
    "alfareferanse": "21.01 PANDURO HOBBY ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1061,
      "placeId": "ChIJ-9E2lvr9PEYRfXMAw8OkAks",
      "placeName": "Panduro hobby",
      "coordinates": "60.4656398,5.3223496",
      "website": "http://panduro.com/nb-no/",
      "logoId": 155,
      "merchantName": "Panduro hobby",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056584139,
    "accountNumber": "35302464616",
    "date": "2015-01-22",
    "amount": "-129.00",
    "text": "H&M Planned Åsane Senter Ulset",
    "alfareferanse": "21.01 H&M PLANNED ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1058,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055349602,
    "accountNumber": "35302464616",
    "date": "2015-01-22",
    "amount": "-300.00",
    "text": "H&M Planned Åsane Senter Ulset",
    "alfareferanse": "21.01 H&M PLANNED ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1058,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055837616,
    "accountNumber": "35302464616",
    "date": "2015-01-22",
    "amount": "-212.54",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "21.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058929999,
    "accountNumber": "35302464616",
    "date": "2015-01-22",
    "amount": "-29.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 20.01 NOK 29.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054832049,
    "accountNumber": "35302464616",
    "date": "2015-01-21",
    "amount": "-462.80",
    "text": "Nespresso Strandgt. 7 Bergen",
    "alfareferanse": "20.01 NESPRESSO STRANDGT. 7  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1062,
      "placeId": "ChIJJQ2lA6j-PEYRkm-DbV9hQQY",
      "placeName": "Nespresso Boutique Bergen",
      "coordinates": "60.3940462,5.3237397",
      "website": "http://www.nespresso.com/",
      "logoId": 133,
      "merchantName": "Nespresso Boutique",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056967163,
    "accountNumber": "35302464616",
    "date": "2015-01-21",
    "amount": "-99.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "20.01 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054996297,
    "accountNumber": "35302464616",
    "date": "2015-01-21",
    "amount": "-399.00",
    "text": "A. Lohne Gull Torgallmenni Bergen",
    "alfareferanse": "20.01 A. LOHNE GULL TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Gullsmed",
    "imageId": "",
    "place": {
      "id": 1311,
      "placeId": "ChIJB7V5Pqj-PEYR0ernnKVPL4o",
      "placeName": "A. Lohne Gullsmedforretning A/S",
      "coordinates": "60.3930587,5.3248159",
      "website": "http://www.a-lohne.no/",
      "logoId": 272,
      "merchantName": "A. Lohne Gullsmedforretning",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055120773,
    "accountNumber": "35302464616",
    "date": "2015-01-21",
    "amount": "-358.00",
    "text": "Lindex 224 Galleriet Bergen",
    "alfareferanse": "20.01 LINDEX 224 GALLERIET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1401,
      "placeId": "ChIJSbvXPaj-PEYRvmB-hu5Dg48",
      "placeName": "Lindex",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://www.lindex.com/",
      "logoId": 295,
      "merchantName": "Lindex",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056342751,
    "accountNumber": "35302464616",
    "date": "2015-01-21",
    "amount": "-151.00",
    "text": "Bit Bergen Torgallmenni Bergen",
    "alfareferanse": "20.01 BIT BERGEN TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1274,
      "placeId": "ChIJdTQeP6j-PEYR4BI9U4nFlrk",
      "placeName": "BIT",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://bit.no/",
      "logoId": 259,
      "merchantName": "BIT",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054741942,
    "accountNumber": "35302464616",
    "date": "2015-01-20",
    "amount": "-504.00",
    "text": "Kinarestaurant Tertnesgrend Tertnes",
    "alfareferanse": "19.01 KINARESTAURANT  TERTNESGREND TERTNES",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1063,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054843322,
    "accountNumber": "35302464616",
    "date": "2015-01-20",
    "amount": "-456.99",
    "text": "Kiwi 814 Tertnesveien Tertnes",
    "alfareferanse": "19.01 KIWI 814 TERTNESVEIEN TERTNES",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 865,
      "placeId": "ChIJb5hcu5X9PEYRDKGffqthGuI",
      "placeName": "KIWI Tertnes",
      "coordinates": "60.4557967,5.2952747",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Tertnes/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058718105,
    "accountNumber": "35302464616",
    "date": "2015-01-19",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.01 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1052512881,
    "accountNumber": "35302464616",
    "date": "2015-01-19",
    "amount": "-1000.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 19.01.15",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058340220,
    "accountNumber": "35302464616",
    "date": "2015-01-19",
    "amount": "-5.20",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "19.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1052504116,
    "accountNumber": "35302464616",
    "date": "2015-01-19",
    "amount": "-1093.18",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 19.01.15",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058750644,
    "accountNumber": "35302464616",
    "date": "2015-01-16",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 14.01 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058043161,
    "accountNumber": "35302464616",
    "date": "2015-01-15",
    "amount": "-30.00",
    "text": "Time Park Vestk Loddefjordve Loddefjord",
    "alfareferanse": "14.01 TIME PARK VESTK LODDEFJORDVE LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 519,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058887858,
    "accountNumber": "35302464616",
    "date": "2015-01-13",
    "amount": "-35.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 09.01 NOK 35.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055952724,
    "accountNumber": "35302464616",
    "date": "2015-01-13",
    "amount": "-199.00",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "12.01 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055880571,
    "accountNumber": "35302464616",
    "date": "2015-01-12",
    "amount": "-205.50",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "12.01 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057060816,
    "accountNumber": "35302464616",
    "date": "2015-01-12",
    "amount": "-91.90",
    "text": "Apotek 1,Arken Åsane Senter Ulset",
    "alfareferanse": "09.01 APOTEK 1,ARKEN  ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 795,
      "placeId": "ChIJRcs1kfr9PEYRb1B_Au3QQX4",
      "placeName": "Apotek 1",
      "coordinates": "60.4657271,5.3244879",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/arken-bergen-697",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057066827,
    "accountNumber": "35302464616",
    "date": "2015-01-12",
    "amount": "-91.00",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "09.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057686511,
    "accountNumber": "35302464616",
    "date": "2015-01-12",
    "amount": "-50.00",
    "text": "Haukeland Sykehus Bergen",
    "alfareferanse": "12.01 HAUKELAND SYKEHUS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1031,
      "placeId": "ChIJU8l1RDb5PEYRfyuoGFexG3U",
      "placeName": "Haukeland University Hospital / Health Bergen",
      "coordinates": "60.3739406,5.359481",
      "website": "https://helse-bergen.no/",
      "logoId": 210,
      "merchantName": "Haukeland University Hospital",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054355004,
    "accountNumber": "35302464616",
    "date": "2015-01-12",
    "amount": "-949.00",
    "text": "Carlings 50E Åsane Senter Ulset",
    "alfareferanse": "12.01 CARLINGS 50E ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1064,
      "placeId": "ChIJZWtA3fr9PEYRrbRBaWC1Bi4",
      "placeName": "Carlings",
      "coordinates": "60.4650958,5.321381",
      "website": "http://carlings.com/",
      "logoId": 214,
      "merchantName": "Carlings",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056702171,
    "accountNumber": "35302464616",
    "date": "2015-01-12",
    "amount": "-118.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "09.01 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055971255,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-198.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "09.01 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054220859,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-1636.00",
    "text": "Clas Ohlson 835 Horisont Nyborg",
    "alfareferanse": "08.01 CLAS OHLSON 835 HORISONT NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1065,
      "placeId": "ChIJZWtA3fr9PEYRBpe1vpwUP0w",
      "placeName": "Clas Ohlson",
      "coordinates": "60.4701267,5.3222108",
      "website": "http://www.clasohlson.com/no/",
      "logoId": 215,
      "merchantName": "Clas Ohlson",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055270706,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-319.00",
    "text": "Jordbærpikene Å Horisont Sen Nyborg",
    "alfareferanse": "08.01 JORDBÆRPIKENE Å HORISONT SEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1066,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055801024,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-219.00",
    "text": "Ark Horisont Myrdalsvn 2 Nyborg",
    "alfareferanse": "08.01 ARK HORISONT MYRDALSVN 2  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1067,
      "placeId": "ChIJ43qZAPr9PEYRTLlLMVtv_sA",
      "placeName": "ARK Horisont Senter",
      "coordinates": "60.4700872,5.3219386",
      "website": "http://www.ark.no/",
      "logoId": 188,
      "merchantName": "Ark Beyer",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1052675434,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-63.23",
    "text": "Cardif Skadefor",
    "alfareferanse": "Nettgiro til: CARDIF SKADEFOR Betalt: 09.01.15",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1068,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057762336,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-47.00",
    "text": "Subway Horisont Breidablikk Hauglandshell",
    "alfareferanse": "08.01 SUBWAY HORISONT BREIDABLIKK  HAUGLANDSHELL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1069,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057864415,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-40.00",
    "text": "Haukeland Sykehus Bergen",
    "alfareferanse": "09.01 HAUKELAND SYKEHUS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1031,
      "placeId": "ChIJU8l1RDb5PEYRfyuoGFexG3U",
      "placeName": "Haukeland University Hospital / Health Bergen",
      "coordinates": "60.3739406,5.359481",
      "website": "https://helse-bergen.no/",
      "logoId": 210,
      "merchantName": "Haukeland University Hospital",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058289845,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-14.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "09.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055967948,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-198.40",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "08.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055803702,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-218.80",
    "text": "Apotek 1 Horiso Myrdalsv.2 Nyborg",
    "alfareferanse": "08.01 APOTEK 1 HORISO MYRDALSV.2 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 909,
      "placeId": "ChIJjfYFde8-TUYReHYol1hFewc",
      "placeName": "Nyborg Apotek",
      "coordinates": "55.3115103,10.7898857",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1052553408,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-600.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 09.01.15",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1052557275,
    "accountNumber": "35302464616",
    "date": "2015-01-09",
    "amount": "-585.00",
    "text": "Canal Digital N",
    "alfareferanse": "Nettgiro til: Canal Digital N Betalt: 09.01.15",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kabel-TV/Satellitt",
    "imageId": "",
    "place": {
      "id": 1427,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1054527546,
    "accountNumber": "35302464616",
    "date": "2015-01-08",
    "amount": "-671.30",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "07.01 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1057788612,
    "accountNumber": "35302464616",
    "date": "2015-01-08",
    "amount": "-45.00",
    "text": "Haukeland Sykehus Bergen",
    "alfareferanse": "07.01 HAUKELAND SYKEHUS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1031,
      "placeId": "ChIJU8l1RDb5PEYRfyuoGFexG3U",
      "placeName": "Haukeland University Hospital / Health Bergen",
      "coordinates": "60.3739406,5.359481",
      "website": "https://helse-bergen.no/",
      "logoId": 210,
      "merchantName": "Haukeland University Hospital",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058948244,
    "accountNumber": "35302464616",
    "date": "2015-01-08",
    "amount": "-20.00",
    "text": "Pelican Rouge",
    "alfareferanse": "*4928 06.01 NOK 20.00 Pelican Rouge Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1070,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058981602,
    "accountNumber": "35302464616",
    "date": "2015-01-08",
    "amount": "-10.00",
    "text": "Pelican Rouge",
    "alfareferanse": "*4928 06.01 NOK 10.00 Pelican Rouge Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1070,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058126102,
    "accountNumber": "35302464616",
    "date": "2015-01-05",
    "amount": "-25.00",
    "text": "Haukeland Sykehus Bergen",
    "alfareferanse": "04.01 HAUKELAND SYKEHUS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1031,
      "placeId": "ChIJU8l1RDb5PEYRfyuoGFexG3U",
      "placeName": "Haukeland University Hospital / Health Bergen",
      "coordinates": "60.3739406,5.359481",
      "website": "https://helse-bergen.no/",
      "logoId": 210,
      "merchantName": "Haukeland University Hospital",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058316543,
    "accountNumber": "35302464616",
    "date": "2015-01-05",
    "amount": "-10.00",
    "text": "Haukeland Sykehus Bergen",
    "alfareferanse": "03.01 HAUKELAND SYKEHUS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1031,
      "placeId": "ChIJU8l1RDb5PEYRfyuoGFexG3U",
      "placeName": "Haukeland University Hospital / Health Bergen",
      "coordinates": "60.3739406,5.359481",
      "website": "https://helse-bergen.no/",
      "logoId": 210,
      "merchantName": "Haukeland University Hospital",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1056718345,
    "accountNumber": "35302464616",
    "date": "2015-01-02",
    "amount": "-116.40",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "31.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1055165383,
    "accountNumber": "35302464616",
    "date": "2015-01-02",
    "amount": "-347.00",
    "text": "Peppes Pizza As Åsmyrene 82 Ulset",
    "alfareferanse": "01.01 PEPPES PIZZA AS ÅSMYRENE 82  ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 852,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1058862968,
    "accountNumber": "35302464616",
    "date": "2015-01-02",
    "amount": "-42.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 30.12 NOK 42.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047827545,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-28.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 29.12 NOK 28.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046346301,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-58.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "31.12 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046887067,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-28.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "31.12 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041603209,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-410.48",
    "text": "Shell Shell Nes Stun Nesttun",
    "alfareferanse": "30.12 Shell SHELL NES STUN  NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 657,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1049234283,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-78.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         39 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1049440265,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045369627,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-113.50",
    "text": "Vinmonopolet Åsane Ulset",
    "alfareferanse": "30.12 VINMONOPOLET ÅSANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 869,
      "placeId": "ChIJ65wnu_r9PEYRP5hRQNIzqhs",
      "placeName": "Vinmonopolet Åsane Senter",
      "coordinates": "60.4648842,5.3223251",
      "website": "http://www.vinmonopolet.no/",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044942147,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-149.00",
    "text": "Kinsarvik Horis Olav Kyrres Bergen",
    "alfareferanse": "30.12 KINSARVIK HORIS OLAV KYRRES  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1071,
      "placeId": "ChIJ8Yn4CQL8PEYRxln6jym_U2g",
      "placeName": "Kinsarvik Fruit Sales Naturkostladen",
      "coordinates": "60.3898921,5.3222186",
      "website": "http://www.kinsarvik.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047080745,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-15.00",
    "text": "Nesttunparkerin Østre Nesttu Nesttun",
    "alfareferanse": "30.12 NESTTUNPARKERIN ØSTRE NESTTU NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1072,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043149904,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-386.59",
    "text": "Coop Extra Østre Nesttu Nesttun",
    "alfareferanse": "30.12 COOP EXTRA ØSTRE NESTTU NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1073,
      "placeId": "ChIJ-2-7fLn5PEYRlKm7ZOTENFA",
      "placeName": "Extra Nesttun",
      "coordinates": "60.3171916,5.3542424",
      "website": "https://coop.no/butikker/extra/nesttun-1450/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045639699,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-99.00",
    "text": "Intersport Nest Østre Nestun Nesttun",
    "alfareferanse": "30.12 INTERSPORT NEST ØSTRE NESTUN NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sportsutstyr",
    "imageId": "",
    "place": {
      "id": 1074,
      "placeId": "ChIJ-2-7fLn5PEYRO_Zp5Tseo2o",
      "placeName": "Intersport Nesttun - Intersport Nesttunsenteret",
      "coordinates": "60.3173128,5.3549279",
      "website": "http://www.intersport.no/",
      "logoId": 216,
      "merchantName": "Intersport",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045305250,
    "accountNumber": "35302464616",
    "date": "2014-12-31",
    "amount": "-119.00",
    "text": "Vitusapotek Kna Kvernhusmyra Isdalstø",
    "alfareferanse": "30.12 VITUSAPOTEK KNA KVERNHUSMYRA ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 997,
      "placeId": "ChIJhy2R9Z8DPUYRitZ8-3_BEUk",
      "placeName": "Vitusapotek Knarvik",
      "coordinates": "60.5474554,5.287272",
      "website": "https://www.vitusapotek.no/Apotek/Hordaland/Vitusapotek-Knarvik",
      "logoId": 49,
      "merchantName": "Vitusapotek",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044183386,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-218.00",
    "text": "Mama Rosa Kvasnessvn. Isdalstø",
    "alfareferanse": "28.12 MAMA ROSA KVASNESSVN.  ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1028,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047017569,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-20.00",
    "text": "Haukeland Sykehus Bergen",
    "alfareferanse": "27.12 HAUKELAND SYKEHUS BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1031,
      "placeId": "ChIJU8l1RDb5PEYRfyuoGFexG3U",
      "placeName": "Haukeland University Hospital / Health Bergen",
      "coordinates": "60.3739406,5.359481",
      "website": "https://helse-bergen.no/",
      "logoId": 210,
      "merchantName": "Haukeland University Hospital",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1039847150,
    "accountNumber": "35302465310",
    "date": "2014-12-29",
    "amount": "-1535.00",
    "text": "Tollregion Oslo",
    "alfareferanse": "Nettgiro til: TOLLREGION OSLO Betalt: 26.12.14",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Offentlige avgifter",
    "imageId": "",
    "place": {
      "id": 1037,
      "placeId": "ChIJ6Wr3goluQUYR7b7kL2CICo8",
      "placeName": "Customs Region Oslo and Akershus",
      "coordinates": "59.9109062,10.7594563",
      "website": "http://www.toll.no/no/om-tolletaten/kontakt-oss/tollregion-oslo-og-akershus/",
      "logoId": 212,
      "merchantName": "Toll",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041620719,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-322.96",
    "text": "Esso Frekhaug . Frekhaug",
    "alfareferanse": "28.12 ESSO FREKHAUG . FREKHAUG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 574,
      "placeId": "ChIJX9dhzdoCPUYRZGRavUuecLk",
      "placeName": "Esso",
      "coordinates": "60.5148598,5.2278602",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046255609,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-62.00",
    "text": "Esso Frekhaug . Frekhaug",
    "alfareferanse": "28.12 ESSO FREKHAUG . FREKHAUG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 574,
      "placeId": "ChIJX9dhzdoCPUYRZGRavUuecLk",
      "placeName": "Esso",
      "coordinates": "60.5148598,5.2278602",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044593481,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-179.00",
    "text": "Arken Zoo Arken Senter Ulset",
    "alfareferanse": "29.12 ARKEN ZOO ARKEN SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 672,
      "placeId": "ChIJAYxFxfr9PEYR5zUdhYyHLaI",
      "placeName": "Buddy Arken Zoo",
      "coordinates": "60.4654634,5.3245503",
      "website": "https://buddy.no/butikker/hordaland/buddy-arken/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041672877,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-68.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "25.12 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043844526,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-260.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "27.12 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046387458,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-55.00",
    "text": "Statoil Service Nordre Brurå Nyborg",
    "alfareferanse": "28.12 STATOIL SERVICE NORDRE BRURÅ NYBORG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 630,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046753279,
    "accountNumber": "35302464616",
    "date": "2014-12-29",
    "amount": "-35.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "29.12 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046539071,
    "accountNumber": "35302464616",
    "date": "2014-12-23",
    "amount": "-47.30",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "22.12 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043062668,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-399.70",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "19.12 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044058012,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-234.00",
    "text": "Cubus 874 Oasen Senter Fyllingsdalen",
    "alfareferanse": "21.12 CUBUS 874 OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 607,
      "placeId": "ChIJoTfziMX7PEYRab_c3wl4tKY",
      "placeName": "Cubus",
      "coordinates": "60.348195,5.2920946",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/874/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043280844,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-353.38",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "19.12 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046780658,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-32.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "21.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046840616,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-30.00",
    "text": "Dolly Dimple's Lagunen Rådal",
    "alfareferanse": "20.12 DOLLY DIMPLE'S  LAGUNEN RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1075,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043863011,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-258.00",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "21.12 H&M 854 OASEN OASEN BERGEN 5147 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044903508,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-149.80",
    "text": "Jula Bergen As Åsmyrane 320 Nyborg",
    "alfareferanse": "19.12 JULA BERGEN AS  ÅSMYRANE 320 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 965,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044696036,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-168.70",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "20.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045976075,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-79.00",
    "text": "Solbrød Oasen Kokstaddalen Kokstad",
    "alfareferanse": "21.12 SOLBRØD OASEN KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 871,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047119055,
    "accountNumber": "35302464616",
    "date": "2014-12-22",
    "amount": "-10.00",
    "text": "Time Park Lagun Laguneveien Rådal",
    "alfareferanse": "20.12 TIME PARK LAGUN LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1303,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046839356,
    "accountNumber": "35302464616",
    "date": "2014-12-19",
    "amount": "-30.00",
    "text": "Time Park Lagun Laguneveien Rådal",
    "alfareferanse": "18.12 TIME PARK LAGUN LAGUNEVEIEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1303,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043207130,
    "accountNumber": "35302464616",
    "date": "2014-12-19",
    "amount": "-371.31",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "18.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045625192,
    "accountNumber": "35302464616",
    "date": "2014-12-19",
    "amount": "-99.00",
    "text": "Dressmann 162 Krohnåsvn 12 Rådal",
    "alfareferanse": "18.12 DRESSMANN 162 KROHNÅSVN 12 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1077,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046838707,
    "accountNumber": "35302464616",
    "date": "2014-12-19",
    "amount": "-30.00",
    "text": "Lie Nielsen As Krohnåsvn. 1 Bergen",
    "alfareferanse": "18.12 LIE NIELSEN AS  KROHNÅSVN. 1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1078,
      "placeId": "ChIJo3Oy7Uj5PEYR4MM4BuTn5KM",
      "placeName": "Lie Nielsen AS",
      "coordinates": "60.3720115,5.3480554",
      "website": "http://www.lienielsen.no/",
      "logoId": 116,
      "merchantName": "Lie-Nielsen konditori",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042752562,
    "accountNumber": "35302464616",
    "date": "2014-12-19",
    "amount": "-498.00",
    "text": "Visit Your Hair Folke Bernad Fyllingsdalen",
    "alfareferanse": "18.12 VISIT YOUR HAIR FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 1079,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043466732,
    "accountNumber": "35302464616",
    "date": "2014-12-19",
    "amount": "-318.00",
    "text": "H&M 779 Lagunen Krohnsvn.12 Rdal",
    "alfareferanse": "18.12 H&M 779 LAGUNEN KROHNSVN.12  RDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1043,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041974656,
    "accountNumber": "35302464616",
    "date": "2014-12-18",
    "amount": "-1098.00",
    "text": "Jula Bergen Oas Folke Barnad Fyllingsdalen",
    "alfareferanse": "17.12 JULA BERGEN OAS FOLKE BARNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 516,
      "placeId": "ChIJp13-g8_7PEYRFzYXBSYJXB8",
      "placeName": "Jula",
      "coordinates": "60.3485852,5.2915106",
      "website": "http://www.jula.no/vare-varehus/bergen-fyllingsdalen/",
      "logoId": 61,
      "merchantName": "Jula",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041600173,
    "accountNumber": "35302464616",
    "date": "2014-12-18",
    "amount": "-432.55",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "18.12 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047582726,
    "accountNumber": "35302464616",
    "date": "2014-12-17",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.12 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047737067,
    "accountNumber": "35302464616",
    "date": "2014-12-17",
    "amount": "-48.00",
    "text": "Qpark As",
    "alfareferanse": "*4928 13.12 NOK 48.00 QPARK AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 789,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047616122,
    "accountNumber": "35302464616",
    "date": "2014-12-16",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 14.12 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045464579,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-106.50",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "14.12 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043816141,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-265.00",
    "text": "Shell Shell Var Den Fyllingdalen",
    "alfareferanse": "15.12 Shell SHELL VAR DEN  FYLLINGDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 655,
      "placeId": "ChIJkQ6GsbX7PEYR-ulkGrPxXgU",
      "placeName": "Shell",
      "coordinates": "60.3423154,5.2732459",
      "website": "https://find.shell.com/no/fuel/NO_9145-shell-varden",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041589974,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-500.06",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "14.12 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046191543,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-66.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "13.12 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046554088,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-46.00",
    "text": "Upstairs As Vestre Torvg Bergen",
    "alfareferanse": "13.12 UPSTAIRS AS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 562,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044661822,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-171.00",
    "text": "Mcdonald's Åsane Åsane Senter",
    "alfareferanse": "13.12 MCD 061 ÅSANE ÅSANE SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 504,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046462330,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-50.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "13.12 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046252473,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-62.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "15.12 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046595180,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-43.90",
    "text": "Apotek 1 Åsane Åsane Senter Åsane",
    "alfareferanse": "14.12 APOTEK 1 ÅSANE  ÅSANE SENTER ÅSANE",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 872,
      "placeId": "ChIJ0UDGlvr9PEYRoNg9dtRvQPo",
      "placeName": "Apotek 1",
      "coordinates": "60.4663314,5.3230661",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/aasane-10",
      "logoId": 63,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046591431,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-44.00",
    "text": "Best Alversund Alversund Alversund",
    "alfareferanse": "13.12 BEST ALVERSUND  ALVERSUND ALVERSUND",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 856,
      "placeId": "ChIJXW0CS1YDPUYROp0_SulXTR0",
      "placeName": "Gabben Bensinstasjon AS",
      "coordinates": "60.5710295,5.2329067",
      "website": "https://beststasjon.no/",
      "logoId": 174,
      "merchantName": "Gabben Bensinstasjon AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043363457,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-339.00",
    "text": "Narvesen 778 Åsane Sent. Ulset",
    "alfareferanse": "14.12 NARVESEN 778 ÅSANE SENT.  ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1039,
      "placeId": "ChIJE8vqqPr9PEYRjAAbpqe8rS0",
      "placeName": "Narvesen",
      "coordinates": "60.4650684,5.3217269",
      "website": "http://www.narvesen.no/",
      "logoId": 183,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043960811,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-248.00",
    "text": "H&M 849 Sane Sane Senter Ulset",
    "alfareferanse": "14.12 H&M 849 SANE SANE SENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1080,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043939594,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-249.00",
    "text": "H&M 849 Sane Sane Senter Ulset",
    "alfareferanse": "14.12 H&M 849 SANE SANE SENTER  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1080,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045862044,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-84.90",
    "text": "Rimi Danma Solheimsgt. Bergen",
    "alfareferanse": "12.12 6699 RIMI DANMA SOLHEIMSGT.  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1081,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044189809,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-217.00",
    "text": "Bergen Zoo As Galleriet Bergen",
    "alfareferanse": "13.12 BERGEN ZOO AS GALLERIET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 1082,
      "placeId": "ChIJSbvXPaj-PEYR2EjBArThtic",
      "placeName": "The center Zoo",
      "coordinates": "60.3929202,5.324611",
      "website": "http://sentrumzoo.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047074595,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-15.00",
    "text": "Ark, Åsane Åsane Senter Ulset",
    "alfareferanse": "14.12 ARK, ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1083,
      "placeId": "ChIJ1RP5sfr9PEYRHPmEGDjt_bg",
      "placeName": "Ark Beyer Åsane",
      "coordinates": "60.4649722,5.3217089",
      "website": "http://www.ark.no/",
      "logoId": 188,
      "merchantName": "Ark Beyer",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043596626,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-299.00",
    "text": "Haaland Åsane Senter .",
    "alfareferanse": "14.12 112001 HAALAND  ÅSANE SENTER .",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1084,
      "placeId": "ChIJjRGEuPr9PEYROiOGi4tXh64",
      "placeName": "Eurosko",
      "coordinates": "60.465574,5.322181",
      "website": "http://www.haalandsko.no/",
      "logoId": 218,
      "merchantName": "Eurosko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042859386,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-456.18",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "13.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046283008,
    "accountNumber": "35302464616",
    "date": "2014-12-15",
    "amount": "-60.00",
    "text": "Metro Nattklubb Nedre Ole Bu Bergen",
    "alfareferanse": "13.12 METRO NATTKLUBB NEDRE OLE BU BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1309,
      "placeId": "ChIJpQJT0qn-PEYRHJTki5wEPFk",
      "placeName": "Metro Nightclub",
      "coordinates": "60.3913136,5.3233392",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045551024,
    "accountNumber": "35302464616",
    "date": "2014-12-12",
    "amount": "-100.00",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "11.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042173172,
    "accountNumber": "35302464616",
    "date": "2014-12-11",
    "amount": "-816.30",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "10.12 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044038031,
    "accountNumber": "35302464616",
    "date": "2014-12-10",
    "amount": "-237.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "09.12 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046110852,
    "accountNumber": "35302464616",
    "date": "2014-12-10",
    "amount": "-70.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "10.12 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1039049750,
    "accountNumber": "35302464616",
    "date": "2014-12-10",
    "amount": "-500.00",
    "text": "Nordea Åsane Mini 03:03",
    "alfareferanse": "10.12 NORDEA ÅSANE MINI 03:03",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1085,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045757977,
    "accountNumber": "35302464616",
    "date": "2014-12-10",
    "amount": "-90.00",
    "text": "Teater Kebab As Engen 28 Bergen",
    "alfareferanse": "09.12 TEATER KEBAB AS ENGEN 28 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1086,
      "placeId": "ChIJM-sihgL8PEYRK4QLJhB2eJM",
      "placeName": "Logen Teater",
      "coordinates": "60.3919449,5.321493",
      "website": "http://hordalandteater.no/billettsal/",
      "logoId": 219,
      "merchantName": "Logen Teater",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044978043,
    "accountNumber": "35302464616",
    "date": "2014-12-10",
    "amount": "-145.00",
    "text": "Bergen Kino Neumannsgate Bergen",
    "alfareferanse": "09.12 BERGEN KINO NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 713,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041939936,
    "accountNumber": "35302464616",
    "date": "2014-12-09",
    "amount": "-1180.00",
    "text": "Bilvarehusene N Sanemyrane Ulset",
    "alfareferanse": "08.12 BILVAREHUSENE N SANEMYRANE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Bil",
    "imageId": "",
    "place": {
      "id": 1087,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1039774873,
    "accountNumber": "35302465310",
    "date": "2014-12-09",
    "amount": "-10860.00",
    "text": "Autoriserte Tra",
    "alfareferanse": "Nettgiro til: AUTORISERTE TRA Betalt: 08.12.14",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Kjøreskole",
    "imageId": "",
    "place": {
      "id": 1088,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046363667,
    "accountNumber": "35302464616",
    "date": "2014-12-09",
    "amount": "-56.20",
    "text": "Rema Kokstad Kokstadvegen Kokstad",
    "alfareferanse": "08.12 REMA KOKSTAD KOKSTADVEGEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1089,
      "placeId": "ChIJ825K1Z_wPEYRvuoM9P_Jd8U",
      "placeName": "REMA 1000 Kokstad",
      "coordinates": "60.2922252,5.2620446",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045378487,
    "accountNumber": "35302464616",
    "date": "2014-12-09",
    "amount": "-112.50",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "08.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046502880,
    "accountNumber": "35302464616",
    "date": "2014-12-09",
    "amount": "-49.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "09.12 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045963255,
    "accountNumber": "35302464616",
    "date": "2014-12-09",
    "amount": "-79.10",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "08.12 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043715658,
    "accountNumber": "35302464616",
    "date": "2014-12-08",
    "amount": "-281.00",
    "text": "Trg Norge As Åsene Storse Ulset",
    "alfareferanse": "07.12 TRG NORGE AS ÅSENE STORSE ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 910,
      "placeId": "ChIJLc6svPr9PEYRfqz1Eoow7K8",
      "placeName": "H&M",
      "coordinates": "60.4661421,5.3227179",
      "website": "http://www.hm.com/no/",
      "logoId": 187,
      "merchantName": "H&M",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044098269,
    "accountNumber": "35302464616",
    "date": "2014-12-08",
    "amount": "-228.85",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "07.12 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042694427,
    "accountNumber": "35302464616",
    "date": "2014-12-08",
    "amount": "-505.00",
    "text": "Panduro Hobby Åsane Senter Ulset",
    "alfareferanse": "07.12 PANDURO HOBBY ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1061,
      "placeId": "ChIJ-9E2lvr9PEYRfXMAw8OkAks",
      "placeName": "Panduro hobby",
      "coordinates": "60.4656398,5.3223496",
      "website": "http://panduro.com/nb-no/",
      "logoId": 155,
      "merchantName": "Panduro hobby",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043759059,
    "accountNumber": "35302464616",
    "date": "2014-12-08",
    "amount": "-275.00",
    "text": "Peppes Pizza As Åsmyrene 82 Ulset",
    "alfareferanse": "07.12 PEPPES PIZZA AS ÅSMYRENE 82  ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 852,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046397830,
    "accountNumber": "35302464616",
    "date": "2014-12-08",
    "amount": "-54.00",
    "text": "Yx Vang I Valdr Es 0067 Vang",
    "alfareferanse": "05.12 YX VANG I VALDR ES 0067 VANG",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1090,
      "placeId": "ChIJgZASeSFhFUYRPlLtr4p0Jow",
      "placeName": "Yx",
      "coordinates": "61.1306182,8.5420745",
      "website": "http://vangautoservice.no/",
      "logoId": 220,
      "merchantName": "Vang Auto-Service",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043890969,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-253.00",
    "text": "Esso Voss Evangervn.4 Voss",
    "alfareferanse": "05.12 ESSO VOSS EVANGERVN.4  VOSS",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1091,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043482777,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-314.50",
    "text": "Biltema Åsane Liamyra 8 Nyborg",
    "alfareferanse": "05.12 BILTEMA ÅSANE LIAMYRA 8 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1092,
      "placeId": "ChIJi4LE-P39PEYRq8TXvQzPY8w",
      "placeName": "Biltema",
      "coordinates": "60.4692168,5.3395653",
      "website": "http://www.biltema.no/",
      "logoId": 221,
      "merchantName": "Biltema",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045820594,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-87.60",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "04.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046587231,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-44.30",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "05.12 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041761436,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-2040.00",
    "text": "Kicks 617 Strandgaten Bergen",
    "alfareferanse": "04.12 KICKS 617 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 953,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041662446,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-152.43",
    "text": "Shell Express S Andvike Nbergen",
    "alfareferanse": "04.12 Shell Express S andvike nBergen",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 658,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044359797,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-199.00",
    "text": "Shell Shell Hau Kaas Ny Bnyborg",
    "alfareferanse": "05.12 Shell Shell Hau kaas Ny bNyborg",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 656,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041545755,
    "accountNumber": "35302464616",
    "date": "2014-12-05",
    "amount": "-1027.75",
    "text": "Esso Nyborg . Bergen",
    "alfareferanse": "05.12 ESSO NYBORG . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 475,
      "placeId": "ChIJeyicmf_9PEYRZunp1cnbohE",
      "placeName": "Esso",
      "coordinates": "60.4742847,5.3370742",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047862070,
    "accountNumber": "35302464616",
    "date": "2014-12-04",
    "amount": "-15.00",
    "text": "Coca-Cola Enterprises Nor",
    "alfareferanse": "*4928 02.12 NOK 15.00 COCA-COLA ENTERPRISES NOR Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 489,
      "placeId": "ChIJm9Lc-DFlQUYRzBGPC-uWeyQ",
      "placeName": "Coca-Cola Norway AS",
      "coordinates": "59.9344227,10.9426316",
      "website": "http://www.coca-cola.no/",
      "logoId": 51,
      "merchantName": "Coca-Cola",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042050209,
    "accountNumber": "35302464616",
    "date": "2014-12-04",
    "amount": "-982.40",
    "text": "27 Plantasjen Åsamyrane 32 Nyborg",
    "alfareferanse": "03.12 27 PLANTASJEN ÅSAMYRANE 32 NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Hagesenter",
    "imageId": "",
    "place": {
      "id": 641,
      "placeId": "ChIJB01zzP8BPUYRoT3m9BfwoII",
      "placeName": "Plantasjen",
      "coordinates": "60.4767896,5.3452213",
      "website": "https://www.plantasjen.no/storelocator/bergen-asane",
      "logoId": 54,
      "merchantName": "Plantasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046351254,
    "accountNumber": "35302464616",
    "date": "2014-12-04",
    "amount": "-57.40",
    "text": "Rema Kokstad Kokstadvegen Kokstad",
    "alfareferanse": "03.12 REMA KOKSTAD KOKSTADVEGEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1089,
      "placeId": "ChIJ825K1Z_wPEYRvuoM9P_Jd8U",
      "placeName": "REMA 1000 Kokstad",
      "coordinates": "60.2922252,5.2620446",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043471084,
    "accountNumber": "35302464616",
    "date": "2014-12-04",
    "amount": "-317.00",
    "text": "Mama Rosa Kvasnessvn. Isdalstø",
    "alfareferanse": "03.12 MAMA ROSA KVASNESSVN.  ISDALSTØ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1028,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046740779,
    "accountNumber": "35302464616",
    "date": "2014-12-04",
    "amount": "-35.00",
    "text": "A & Jc Matglede C. Sundts Gt Bergen",
    "alfareferanse": "04.12 A & JC MATGLEDE C. SUNDTS GT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 794,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046071851,
    "accountNumber": "35302464616",
    "date": "2014-12-04",
    "amount": "-73.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "04.12 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042469915,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-600.00",
    "text": "Egon Åsane Myrdalsveien Nyborg",
    "alfareferanse": "02.12 EGON ÅSANE MYRDALSVEIEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 855,
      "placeId": "ChIJq3eFEPr9PEYR9liiXXYzGAo",
      "placeName": "Egon Åsane",
      "coordinates": "60.4691392,5.3222716",
      "website": "http://www.egon.no/restauranter/asane",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041634787,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-298.70",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "03.12 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046916193,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-25.00",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "03.12 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041724449,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-2763.05",
    "text": "Spar Kjøp Åsane Liamyrene 6 Nyborg",
    "alfareferanse": "02.12 SPAR KJØP ÅSANE LIAMYRENE 6  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 983,
      "placeId": "ChIJ1X5vDf79PEYR1kJCNSChc48",
      "placeName": "Spar Kjøp Åsane",
      "coordinates": "60.470281,5.337713",
      "website": "https://www.sparkjop.no/",
      "logoId": 146,
      "merchantName": "Spar Kjøp",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042205632,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-796.00",
    "text": "Lampehuset Hori Myrdalsvegen Nyborg",
    "alfareferanse": "02.12 LAMPEHUSET HORI MYRDALSVEGEN NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1093,
      "placeId": "ChIJdVyfRHECPUYRN6EkGEStYtI",
      "placeName": "Lampehuset Horisont",
      "coordinates": "60.470295,5.32264",
      "website": "http://www.lampehuset.no/",
      "logoId": 222,
      "merchantName": "Lampehuset Horisont",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1042798863,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-479.70",
    "text": "Nille 066 Linkbygget Isdalst",
    "alfareferanse": "02.12 NILLE 066 LINKBYGGET ISDALST",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1094,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046664908,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-39.90",
    "text": "Bunnpris Møllen Møllendalsba Bergen",
    "alfareferanse": "03.12 BUNNPRIS MØLLEN MØLLENDALSBA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1051,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1045740369,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-91.50",
    "text": "Bunnpris Møllen Møllendalsba Bergen",
    "alfareferanse": "03.12 BUNNPRIS MØLLEN MØLLENDALSBA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1051,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1044306902,
    "accountNumber": "35302464616",
    "date": "2014-12-03",
    "amount": "-200.49",
    "text": "Kiwi Knarvik Knarvik Senter",
    "alfareferanse": "02.12 KIWI KNARVIK KNARVIK SENTER",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1047,
      "placeId": "ChIJz6Zh658DPUYRCprxm6g5vP0",
      "placeName": "KIWI Knarvik",
      "coordinates": "60.5483609,5.2874532",
      "website": "https://kiwi.no/Finn-butikk/Kiwi-Kvassnesvegen-Knarvik/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047160647,
    "accountNumber": "35302464616",
    "date": "2014-12-02",
    "amount": "-2725.95",
    "text": "Blush.No",
    "alfareferanse": "*4928 28.11 NOK 2725.95 BLUSH.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 1095,
      "placeId": "ChIJky3fxDnHRkYR9r7fYCRNeM0",
      "placeName": "Blush.no AS",
      "coordinates": "59.1749085,10.2194018",
      "website": "https://www.blush.no/",
      "logoId": 223,
      "merchantName": "Blush.no",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1043330864,
    "accountNumber": "35302464616",
    "date": "2014-12-02",
    "amount": "-347.00",
    "text": "Hylkje Post I B Hylkjebak.1 Hylkje",
    "alfareferanse": "01.12 HYLKJE POST I B HYLKJEBAK.1  HYLKJE",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 980,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047364091,
    "accountNumber": "35302464616",
    "date": "2014-12-02",
    "amount": "-288.00",
    "text": "Bergen Kino As",
    "alfareferanse": "*4928 27.11 NOK 288.00 BERGEN KINO AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 689,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047374357,
    "accountNumber": "35302464616",
    "date": "2014-12-02",
    "amount": "-268.00",
    "text": "Bergen Kino As",
    "alfareferanse": "*4928 27.11 NOK 268.00 BERGEN KINO AS Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 689,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1047093505,
    "accountNumber": "35302464616",
    "date": "2014-12-02",
    "amount": "-12.90",
    "text": "Rema Spectrum Folke Bernad Fyllingsdalen",
    "alfareferanse": "01.12 REMA SPECTRUM FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 634,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1041732418,
    "accountNumber": "35302464616",
    "date": "2014-12-02",
    "amount": "-2524.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "01.12 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1046589020,
    "accountNumber": "35302464616",
    "date": "2014-12-02",
    "amount": "-44.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "01.12 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030868570,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-551.14",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "29.11 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030070097,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-600.28",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "01.12 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033801923,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-89.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "01.12 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1031237645,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-401.60",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "28.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033959266,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-79.90",
    "text": "Rema Kokstad Kokstadvegen Kokstad",
    "alfareferanse": "29.11 REMA KOKSTAD KOKSTADVEGEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1089,
      "placeId": "ChIJ825K1Z_wPEYRvuoM9P_Jd8U",
      "placeName": "REMA 1000 Kokstad",
      "coordinates": "60.2922252,5.2620446",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034111912,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-71.00",
    "text": "Narvesen 765 Oasen Sent Fyllingsd.",
    "alfareferanse": "28.11 NARVESEN 765 OASEN SENT FYLLINGSD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1096,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033419527,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-109.30",
    "text": "Rema Kokstad Kokstadvegen Kokstad",
    "alfareferanse": "29.11 REMA KOKSTAD KOKSTADVEGEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1089,
      "placeId": "ChIJ825K1Z_wPEYRvuoM9P_Jd8U",
      "placeName": "REMA 1000 Kokstad",
      "coordinates": "60.2922252,5.2620446",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034357558,
    "accountNumber": "35302464616",
    "date": "2014-12-01",
    "amount": "-59.00",
    "text": "Big Bite F.Bernadott Fyllingsdalen",
    "alfareferanse": "01.12 BIG BITE F.BERNADOTT  FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1097,
      "placeId": "ChIJp13-g8_7PEYRsozFVYYiG7w",
      "placeName": "Big Bite",
      "coordinates": "60.3491563,5.2911056",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1036766719,
    "accountNumber": "35302464616",
    "date": "2014-11-30",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1036580393,
    "accountNumber": "35302464616",
    "date": "2014-11-30",
    "amount": "-94.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         47 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 678,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033573805,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-100.00",
    "text": "Klostergarasjen Kaigaten 5 Bergen",
    "alfareferanse": "27.11 KLOSTERGARASJEN KAIGATEN 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 463,
      "placeId": "ChIJNfD5FAP8PEYROvbPXi252Rg",
      "placeName": "KlosterGarasjen",
      "coordinates": "60.3931547,5.3165641",
      "website": "http://bergenparkering.no/",
      "logoId": 38,
      "merchantName": "KlosterGarasjen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032773596,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-161.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "28.11 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1028361214,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-1085.00",
    "text": "Gjensidige Fors",
    "alfareferanse": "Nettgiro til: GJENSIDIGE FORS Betalt: 28.11.14",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1098,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030736458,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-634.00",
    "text": "Egon Bristol Torgallmenn Bergen",
    "alfareferanse": "27.11 EGON BRISTOL TORGALLMENN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1099,
      "placeId": "ChIJuYPZJ6j-PEYRzic3O2MvbPw",
      "placeName": "Egon",
      "coordinates": "60.3925896,5.3228722",
      "website": "http://www.egon.no/restauranter/bristol",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030997763,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-498.00",
    "text": "Bjørklund Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "28.11 BJØRKLUND OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Gullsmed",
    "imageId": "",
    "place": {
      "id": 1100,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030802737,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-596.00",
    "text": "Bik Bok 640 Torgalmening Bergen",
    "alfareferanse": "27.11 BIK BOK 640 TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1283,
      "placeId": "ChIJM-52F6j-PEYRlPWjCquzOgY",
      "placeName": "BIK BOK",
      "coordinates": "60.3922526,5.3239096",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/640/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034563204,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-49.00",
    "text": "Bik Bok 640 Torgalmening Bergen",
    "alfareferanse": "27.11 BIK BOK 640 TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1283,
      "placeId": "ChIJM-52F6j-PEYRlPWjCquzOgY",
      "placeName": "BIK BOK",
      "coordinates": "60.3922526,5.3239096",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/640/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034073356,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-74.00",
    "text": "Bon Appetit Torgalm 14 Bergen",
    "alfareferanse": "27.11 BON APPETIT TORGALM 14 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1313,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033332118,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-116.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "28.11 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1031833285,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-278.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "28.11 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033958870,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-79.90",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "27.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1036023789,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-1000.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "28.11 SPV Fyllingsdalen Inne  5147 Fyllingsdalen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030124827,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-301.60",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "27.11 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030611015,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-744.00",
    "text": "Cubus 879 Sundt City Bergen",
    "alfareferanse": "27.11 CUBUS 879 SUNDT CITY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 724,
      "placeId": "ChIJg4UbMKj-PEYRyOGpKSaozac",
      "placeName": "Cubus",
      "coordinates": "60.3922867,5.3239234",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/879/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032479689,
    "accountNumber": "35302464616",
    "date": "2014-11-28",
    "amount": "-194.00",
    "text": "Bergen Kino Kiosk .",
    "alfareferanse": "27.11 BERGEN KINO KIOSK .",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 842,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032988852,
    "accountNumber": "35302464616",
    "date": "2014-11-27",
    "amount": "-143.79",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "26.11 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1035142917,
    "accountNumber": "35302464616",
    "date": "2014-11-26",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "26.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030133873,
    "accountNumber": "35302464616",
    "date": "2014-11-26",
    "amount": "-299.47",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "25.11 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1026910187,
    "accountNumber": "35302464616",
    "date": "2014-11-26",
    "amount": "-1397.30",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "25.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033814094,
    "accountNumber": "35302464616",
    "date": "2014-11-26",
    "amount": "-88.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "26.11 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033635837,
    "accountNumber": "35302464616",
    "date": "2014-11-25",
    "amount": "-98.10",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "24.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034253207,
    "accountNumber": "35302464616",
    "date": "2014-11-25",
    "amount": "-63.80",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "24.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033956619,
    "accountNumber": "35302464616",
    "date": "2014-11-25",
    "amount": "-79.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "24.11 MENY BERGEN STORSENTER.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034765592,
    "accountNumber": "35302464616",
    "date": "2014-11-24",
    "amount": "-38.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "24.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030577024,
    "accountNumber": "35302464616",
    "date": "2014-11-24",
    "amount": "-783.97",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "22.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033076431,
    "accountNumber": "35302464616",
    "date": "2014-11-24",
    "amount": "-136.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "24.11 BURGER KING. STRØM GT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033435366,
    "accountNumber": "35302464616",
    "date": "2014-11-24",
    "amount": "-108.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "24.11 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032102256,
    "accountNumber": "35302464616",
    "date": "2014-11-24",
    "amount": "-237.00",
    "text": "Åsane Grillkj. Arken Senter Ulset",
    "alfareferanse": "21.11 ÅSANE GRILLKJ.  ARKEN SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1101,
      "placeId": "ChIJZWtA3fr9PEYR_yx2x-hxNzo",
      "placeName": "Orthodontists in Arken AS",
      "coordinates": "60.46505,5.3241326",
      "website": "http://kjevearken.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034691032,
    "accountNumber": "35302464616",
    "date": "2014-11-21",
    "amount": "-41.27",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "21.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034737329,
    "accountNumber": "35302464616",
    "date": "2014-11-21",
    "amount": "-39.90",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "21.11 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034697237,
    "accountNumber": "35302464616",
    "date": "2014-11-20",
    "amount": "-40.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "20.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034350899,
    "accountNumber": "35302464616",
    "date": "2014-11-20",
    "amount": "-59.00",
    "text": "Big Bite F.Bernadott Fyllingsdalen",
    "alfareferanse": "20.11 BIG BITE F.BERNADOTT  FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1097,
      "placeId": "ChIJp13-g8_7PEYRsozFVYYiG7w",
      "placeName": "Big Bite",
      "coordinates": "60.3491563,5.2911056",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034076252,
    "accountNumber": "35302464616",
    "date": "2014-11-19",
    "amount": "-73.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "19.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032164014,
    "accountNumber": "35302464616",
    "date": "2014-11-18",
    "amount": "-228.00",
    "text": "Fersk Kosmetikk Småstrandgt Bergen",
    "alfareferanse": "17.11 FERSK KOSMETIKK SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 680,
      "placeId": "ChIJu3S-Xaj-PEYRdY2KXPeajc8",
      "placeName": "Xhibition Shopping Center",
      "coordinates": "60.392964,5.3260681",
      "website": "https://www.xhibition.no/",
      "logoId": 109,
      "merchantName": "Xhibition Shopping Center",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1035675521,
    "accountNumber": "35302464616",
    "date": "2014-11-18",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 14.11 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1035649693,
    "accountNumber": "35302464616",
    "date": "2014-11-18",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.11 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030573704,
    "accountNumber": "35302464616",
    "date": "2014-11-18",
    "amount": "-789.00",
    "text": "Kicks 617 Strandgaten Bergen",
    "alfareferanse": "17.11 KICKS 617 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 953,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030899247,
    "accountNumber": "35302464616",
    "date": "2014-11-18",
    "amount": "-537.00",
    "text": "Kicks 675 Åsane Senter Ulset",
    "alfareferanse": "17.11 KICKS 675 ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 868,
      "placeId": "ChIJ1RP5sfr9PEYR9RNWaC99jzQ",
      "placeName": "KICKS",
      "coordinates": "60.4654946,5.3214737",
      "website": "https://www.kicks.no/butikker/ulset-asane-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034131149,
    "accountNumber": "35302464616",
    "date": "2014-11-18",
    "amount": "-70.00",
    "text": "Wayne's Coffe Småstrandgat Bergen",
    "alfareferanse": "17.11 WAYNE'S COFFE SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1102,
      "placeId": "ChIJu3S-Xaj-PEYRSPl7a56eksY",
      "placeName": "Wayne’s Coffee",
      "coordinates": "60.3930053,5.3263753",
      "website": "http://www.waynescoffee.no/",
      "logoId": 225,
      "merchantName": "Wayne’s Coffee",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1027806850,
    "accountNumber": "35302464616",
    "date": "2014-11-17",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033587665,
    "accountNumber": "35302464616",
    "date": "2014-11-17",
    "amount": "-99.80",
    "text": "Glitter Bergen Starvhusgt 4 Bergen",
    "alfareferanse": "14.11 GLITTER BERGEN  STARVHUSGT 4 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 810,
      "placeId": "ChIJ__-_XKj-PEYR-7d3oVnZ4vU",
      "placeName": "Glitter",
      "coordinates": "60.3927285,5.3263497",
      "website": "https://www.glitter.no/storelocator/Glitter-Bergen-Xhibition",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1035187809,
    "accountNumber": "35302464616",
    "date": "2014-11-17",
    "amount": "-17.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "14.11 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034403062,
    "accountNumber": "35302464616",
    "date": "2014-11-14",
    "amount": "-55.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032894039,
    "accountNumber": "35302464616",
    "date": "2014-11-14",
    "amount": "-150.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "14.11 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1031202391,
    "accountNumber": "35302464616",
    "date": "2014-11-13",
    "amount": "-415.00",
    "text": "Billettautomat Vestre Strøm Bergen",
    "alfareferanse": "12.11 BILLETTAUTOMAT  VESTRE STRØM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Buss",
    "imageId": "",
    "place": {
      "id": 818,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033130553,
    "accountNumber": "35302464616",
    "date": "2014-11-13",
    "amount": "-130.55",
    "text": "Rimi Strømgt. 8 Bergen",
    "alfareferanse": "12.11 4983 RIMI STRØMGT. 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1103,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1035128701,
    "accountNumber": "35302464616",
    "date": "2014-11-13",
    "amount": "-20.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "13.11 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034402993,
    "accountNumber": "35302464616",
    "date": "2014-11-12",
    "amount": "-55.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "12.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1027515320,
    "accountNumber": "35302464616",
    "date": "2014-11-12",
    "amount": "-200.00",
    "text": "Nordea Bergen Stors. Mini-2 15:21",
    "alfareferanse": "11.11 NORDEA BERGEN STORS. MINI-2 15:21",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1105,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034805627,
    "accountNumber": "35302464616",
    "date": "2014-11-12",
    "amount": "-36.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "12.11 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032227377,
    "accountNumber": "35302464616",
    "date": "2014-11-11",
    "amount": "-219.00",
    "text": "116 Barnas Hus Nordåsdalen Rådal",
    "alfareferanse": "10.11 116 BARNAS HUS  NORDÅSDALEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Barneklær",
    "imageId": "",
    "place": {
      "id": 1106,
      "placeId": "ChIJSVrXO-z5PEYRIjFHyVv_lSo",
      "placeName": "Barnas Hus Nordås",
      "coordinates": "60.3082502,5.3275413",
      "website": "https://www.barnashus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033441788,
    "accountNumber": "35302464616",
    "date": "2014-11-11",
    "amount": "-108.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "10.11 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034214646,
    "accountNumber": "35302464616",
    "date": "2014-11-11",
    "amount": "-65.70",
    "text": "Bunnpris Møllen Møllendalsba Bergen",
    "alfareferanse": "11.11 BUNNPRIS MØLLEN MØLLENDALSBA BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1051,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032111245,
    "accountNumber": "35302464616",
    "date": "2014-11-10",
    "amount": "-235.40",
    "text": "Nespresso Strandgt. 7 Bergen",
    "alfareferanse": "07.11 NESPRESSO STRANDGT. 7  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1062,
      "placeId": "ChIJJQ2lA6j-PEYRkm-DbV9hQQY",
      "placeName": "Nespresso Boutique Bergen",
      "coordinates": "60.3940462,5.3237397",
      "website": "http://www.nespresso.com/",
      "logoId": 133,
      "merchantName": "Nespresso Boutique",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034447449,
    "accountNumber": "35302464616",
    "date": "2014-11-10",
    "amount": "-53.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033974492,
    "accountNumber": "35302464616",
    "date": "2014-11-07",
    "amount": "-79.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "06.11 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032216120,
    "accountNumber": "35302464616",
    "date": "2014-11-07",
    "amount": "-220.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "07.11 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034188886,
    "accountNumber": "35302464616",
    "date": "2014-11-07",
    "amount": "-67.50",
    "text": "Vita Torgalmennin Bergen",
    "alfareferanse": "07.11 2604 VITA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1277,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032977773,
    "accountNumber": "35302464616",
    "date": "2014-11-05",
    "amount": "-144.90",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "05.11 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030321792,
    "accountNumber": "35302464616",
    "date": "2014-11-05",
    "amount": "-1384.00",
    "text": "Jysk Btk 588 Salhusveien Nyborg",
    "alfareferanse": "04.11 JYSK BTK 588 SALHUSVEIEN  NYBORG",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "224",
    "place": {
      "id": 1107,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    },
    "tags": "Hjemmet, Oppussing"
  },
  {
    "transactionId": 1034108577,
    "accountNumber": "35302464616",
    "date": "2014-11-05",
    "amount": "-71.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "05.11 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1031447357,
    "accountNumber": "35302464616",
    "date": "2014-11-05",
    "amount": "-351.20",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "04.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1032140601,
    "accountNumber": "35302464616",
    "date": "2014-11-05",
    "amount": "-230.25",
    "text": "Spar Hylkje Hylkjebakken 5109",
    "alfareferanse": "04.11 SPAR HYLKJE HYLKJEBAKKEN 5109",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 972,
      "placeId": "ChIJm40W8CcCPUYRGOpp6mZbXns",
      "placeName": "Spar Hylkje",
      "coordinates": "60.5091567,5.3476037",
      "website": "https://spar.no/Finn-butikk/SPAR-Hylkje/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034884018,
    "accountNumber": "35302464616",
    "date": "2014-11-05",
    "amount": "-31.71",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "05.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033302058,
    "accountNumber": "35302464616",
    "date": "2014-11-04",
    "amount": "-118.35",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "04.11 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1028530003,
    "accountNumber": "35302465310",
    "date": "2014-11-04",
    "amount": "-63.23",
    "text": "Cardif Skadefor",
    "alfareferanse": "Nettgiro til: CARDIF SKADEFOR Betalt: 04.11.14",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1068,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034470166,
    "accountNumber": "35302464616",
    "date": "2014-11-04",
    "amount": "-51.90",
    "text": "Meny Åsane Åsane Senter Ulset",
    "alfareferanse": "04.11 MENY ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 806,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1028408619,
    "accountNumber": "35302465310",
    "date": "2014-11-04",
    "amount": "-600.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 04.11.14",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033020410,
    "accountNumber": "35302464616",
    "date": "2014-11-03",
    "amount": "-140.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "03.11 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033897672,
    "accountNumber": "35302464616",
    "date": "2014-11-03",
    "amount": "-82.00",
    "text": "Cafe Opus As 24 Strømsgt. 8 Bergen",
    "alfareferanse": "03.11 CAFE OPUS AS 24 STRØMSGT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1108,
      "placeId": "ChIJJ_9Dt67-PEYRjvrSOiE3VcM",
      "placeName": "Cafe Opus",
      "coordinates": "60.389375,5.3320505",
      "website": "http://www.cafeopus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1033261390,
    "accountNumber": "35302464616",
    "date": "2014-11-03",
    "amount": "-120.00",
    "text": "Tgr Norge As Strøm Gaten Bergen",
    "alfareferanse": "03.11 TGR NORGE AS STRØM GATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 765,
      "placeId": "ChIJWXX6OUuYP0YRSXPklBnAo8E",
      "placeName": "Flying Tiger Copenhagen",
      "coordinates": "60.3892604,5.3325568",
      "website": "http://flyingtiger.com/",
      "logoId": 142,
      "merchantName": "Flying Tiger",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1034455280,
    "accountNumber": "35302464616",
    "date": "2014-11-03",
    "amount": "-52.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.11 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030323267,
    "accountNumber": "35302464616",
    "date": "2014-11-03",
    "amount": "-1374.80",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "31.10 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1030216603,
    "accountNumber": "35302464616",
    "date": "2014-11-03",
    "amount": "-3000.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "31.10 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024989622,
    "accountNumber": "35302464616",
    "date": "2014-10-31",
    "amount": "-44.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         22 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1019995228,
    "accountNumber": "35302464616",
    "date": "2014-10-31",
    "amount": "-369.00",
    "text": "128 Boots Apotek Arna",
    "alfareferanse": "30.10 128 BOOTS APOTEK ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 954,
      "placeId": "ChIJC6INGX__PEYRFpsj0c6UoTA",
      "placeName": "Boots apotek Arna",
      "coordinates": "60.4211979,5.4654294",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021026531,
    "accountNumber": "35302464616",
    "date": "2014-10-31",
    "amount": "-189.00",
    "text": "Clas Ohlson 825 Strømgaten 8 Bergen",
    "alfareferanse": "31.10 CLAS OHLSON 825 STRØMGATEN 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1109,
      "placeId": "ChIJW-e2tK7-PEYR6BuSJ3XwmpQ",
      "placeName": "Clas Ohlson",
      "coordinates": "60.3894215,5.3327617",
      "website": "http://www.clasohlson.com/no/",
      "logoId": 215,
      "merchantName": "Clas Ohlson",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1020255240,
    "accountNumber": "35302464616",
    "date": "2014-10-31",
    "amount": "-301.19",
    "text": "Rema Øyrane Tor Ådnavegen 63 Indre Arna",
    "alfareferanse": "30.10 REMA ØYRANE TOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1300,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1019001016,
    "accountNumber": "35302464616",
    "date": "2014-10-31",
    "amount": "-1866.00",
    "text": "Interoptik Øyrane Torg Bergen",
    "alfareferanse": "30.10 INTEROPTIK ØYRANE TORG  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Optiker",
    "imageId": "",
    "place": {
      "id": 1431,
      "placeId": "ChIJC6INGX__PEYRWvzEDk2wO5g",
      "placeName": "Interoptik Bakketeig - Øyrane Torg",
      "coordinates": "60.4213491,5.465495",
      "website": "http://www.interoptik.no/finn-butikk/interoptik-optiker-bakketeig-oyrane-torg/?utm_source=gmb&utm_medium=organic&utm_campaign=googlemybusiness&utm_content=bakketeig_oyrane_torg",
      "logoId": 302,
      "merchantName": "Interoptik Bakketeig",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1019305282,
    "accountNumber": "35302464616",
    "date": "2014-10-31",
    "amount": "-745.00",
    "text": "Arna Zoo Ådnavn. 63 Indre Arna",
    "alfareferanse": "30.10 ARNA ZOO ÅDNAVN. 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 1432,
      "placeId": "ChIJC6INGX__PEYRL5Q5QZ472Ug",
      "placeName": "Arna zoo AS",
      "coordinates": "60.4212848,5.4649739",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1017266687,
    "accountNumber": "35302465310",
    "date": "2014-10-30",
    "amount": "-1003.77",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 30.10.14",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024492103,
    "accountNumber": "35302464616",
    "date": "2014-10-30",
    "amount": "-400.00",
    "text": "Spv Arna 5260 Indre Arna",
    "alfareferanse": "30.10 SPV Arna                5260 Indre Arna",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1110,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024428823,
    "accountNumber": "35302464616",
    "date": "2014-10-30",
    "amount": "-1500.00",
    "text": "Spv Arna 5260 Indre Arna",
    "alfareferanse": "30.10 SPV Arna                5260 Indre Arna",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1110,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1017275688,
    "accountNumber": "35302465310",
    "date": "2014-10-30",
    "amount": "-924.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 30.10.14",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024255955,
    "accountNumber": "35302464616",
    "date": "2014-10-30",
    "amount": "-29.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 29.10 NOK 29.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022594537,
    "accountNumber": "35302464616",
    "date": "2014-10-29",
    "amount": "-66.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "29.10 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024203802,
    "accountNumber": "35302464616",
    "date": "2014-10-28",
    "amount": "-39.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 26.10 NOK 39.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024427661,
    "accountNumber": "35302464616",
    "date": "2014-10-28",
    "amount": "-1500.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "28.10 SPV Fyllingsdalen Inne  5147 Fyllingsdalen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023500401,
    "accountNumber": "35302464616",
    "date": "2014-10-28",
    "amount": "-20.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "27.10 NARVESEN 105 STRØMSGT. 2  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022216875,
    "accountNumber": "35302464616",
    "date": "2014-10-28",
    "amount": "-89.00",
    "text": "Deli De Luca Be Strømsgaten Bergen",
    "alfareferanse": "27.10 DELI DE LUCA BE STRØMSGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1112,
      "placeId": "ChIJ8d6fxK7-PEYRylv30jJD0lo",
      "placeName": "Deli de Luca Jernbanestasjon",
      "coordinates": "60.3903329,5.3332623",
      "website": "http://www.delideluca.no/",
      "logoId": 227,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023409518,
    "accountNumber": "35302464616",
    "date": "2014-10-27",
    "amount": "-25.00",
    "text": "Deli De Luca Be Strømsgaten Bergen",
    "alfareferanse": "24.10 DELI DE LUCA BE STRØMSGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1112,
      "placeId": "ChIJ8d6fxK7-PEYRylv30jJD0lo",
      "placeName": "Deli de Luca Jernbanestasjon",
      "coordinates": "60.3903329,5.3332623",
      "website": "http://www.delideluca.no/",
      "logoId": 227,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023115182,
    "accountNumber": "35302464616",
    "date": "2014-10-27",
    "amount": "-39.76",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "27.10 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023178818,
    "accountNumber": "35302464616",
    "date": "2014-10-24",
    "amount": "-35.92",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "24.10 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021939363,
    "accountNumber": "35302464616",
    "date": "2014-10-24",
    "amount": "-104.00",
    "text": "Søstrene Hageli Strandgt 3 Bergen",
    "alfareferanse": "23.10 SØSTRENE HAGELI STRANDGT 3 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1113,
      "placeId": "ChIJ8UFEBaj-PEYRSAJv8YFTup8",
      "placeName": "Søstrene Hagelin",
      "coordinates": "60.393877,5.3240355",
      "website": "http://www.sostrenehagelin.no/",
      "logoId": 228,
      "merchantName": "Søstrene Hagelin",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021377935,
    "accountNumber": "35302464616",
    "date": "2014-10-24",
    "amount": "-150.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "23.10 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1020848696,
    "accountNumber": "35302464616",
    "date": "2014-10-24",
    "amount": "-205.50",
    "text": "Nespresso Strandgt. 7 Bergen",
    "alfareferanse": "23.10 NESPRESSO STRANDGT. 7  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1062,
      "placeId": "ChIJJQ2lA6j-PEYRkm-DbV9hQQY",
      "placeName": "Nespresso Boutique Bergen",
      "coordinates": "60.3940462,5.3237397",
      "website": "http://www.nespresso.com/",
      "logoId": 133,
      "merchantName": "Nespresso Boutique",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023405159,
    "accountNumber": "35302464616",
    "date": "2014-10-23",
    "amount": "-25.00",
    "text": "Deli De Luca Be Strømsgaten Bergen",
    "alfareferanse": "23.10 DELI DE LUCA BE STRØMSGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1112,
      "placeId": "ChIJ8d6fxK7-PEYRylv30jJD0lo",
      "placeName": "Deli de Luca Jernbanestasjon",
      "coordinates": "60.3903329,5.3332623",
      "website": "http://www.delideluca.no/",
      "logoId": 227,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023234680,
    "accountNumber": "35302464616",
    "date": "2014-10-23",
    "amount": "-32.90",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "22.10 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022577871,
    "accountNumber": "35302464616",
    "date": "2014-10-22",
    "amount": "-68.00",
    "text": "Deli De Luca Be Strømsgaten Bergen",
    "alfareferanse": "21.10 DELI DE LUCA BE STRØMSGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1112,
      "placeId": "ChIJ8d6fxK7-PEYRylv30jJD0lo",
      "placeName": "Deli de Luca Jernbanestasjon",
      "coordinates": "60.3903329,5.3332623",
      "website": "http://www.delideluca.no/",
      "logoId": 227,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022130578,
    "accountNumber": "35302464616",
    "date": "2014-10-22",
    "amount": "-94.00",
    "text": "Sørensen Tobakk Torgallmenn8 Bergen",
    "alfareferanse": "21.10 SØRENSEN TOBAKK TORGALLMENN8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1114,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021445176,
    "accountNumber": "35302464616",
    "date": "2014-10-22",
    "amount": "-145.00",
    "text": "Konfektgallerie Torgallmenni Bergen",
    "alfareferanse": "21.10 KONFEKTGALLERIE TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1315,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022561574,
    "accountNumber": "35302464616",
    "date": "2014-10-22",
    "amount": "-69.00",
    "text": "Kremmerhuset 27 Torgalmennin Bergen",
    "alfareferanse": "22.10 KREMMERHUSET 27 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 733,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1020343695,
    "accountNumber": "35302464616",
    "date": "2014-10-22",
    "amount": "-290.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "21.10 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023048804,
    "accountNumber": "35302464616",
    "date": "2014-10-21",
    "amount": "-42.30",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "20.10 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1019981498,
    "accountNumber": "35302464616",
    "date": "2014-10-21",
    "amount": "-373.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "20.10 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022803291,
    "accountNumber": "35302464616",
    "date": "2014-10-21",
    "amount": "-55.00",
    "text": "Deli De Luca Be Strømsgaten Bergen",
    "alfareferanse": "21.10 DELI DE LUCA BE STRØMSGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1112,
      "placeId": "ChIJ8d6fxK7-PEYRylv30jJD0lo",
      "placeName": "Deli de Luca Jernbanestasjon",
      "coordinates": "60.3903329,5.3332623",
      "website": "http://www.delideluca.no/",
      "logoId": 227,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023401506,
    "accountNumber": "35302464616",
    "date": "2014-10-20",
    "amount": "-25.00",
    "text": "Narvesen 765 Oasen Sent Fyllingsd.",
    "alfareferanse": "17.10 NARVESEN 765 OASEN SENT FYLLINGSD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1096,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1019670922,
    "accountNumber": "35302464616",
    "date": "2014-10-20",
    "amount": "-485.60",
    "text": "Rusta Bergen Åsane Senter Ulset",
    "alfareferanse": "17.10 RUSTA BERGEN ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 619,
      "placeId": "ChIJzaXYTvr9PEYR7UiOr8SGF80",
      "placeName": "Rusta",
      "coordinates": "60.4689863,5.3249195",
      "website": "https://rusta.com/no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022414666,
    "accountNumber": "35302464616",
    "date": "2014-10-20",
    "amount": "-77.60",
    "text": "Rema Åsane Åsane Senter Ulset",
    "alfareferanse": "17.10 REMA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 674,
      "placeId": "ChIJP6TYTvr9PEYRVUuzeYlkYk8",
      "placeName": "Rema 1000 Åsane Senter",
      "coordinates": "60.4680656,5.3248696",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021063267,
    "accountNumber": "35302464616",
    "date": "2014-10-17",
    "amount": "-184.00",
    "text": "Shell 7-Eleven Ådnavegen 67 Indre Arna",
    "alfareferanse": "16.10 SHELL 7-ELEVEN  ÅDNAVEGEN 67 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 651,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021551637,
    "accountNumber": "35302464616",
    "date": "2014-10-17",
    "amount": "-134.98",
    "text": "Søstrene Grene Folke Bernad Fyllingsdalen",
    "alfareferanse": "17.10 SØSTRENE GRENE  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 518,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024037226,
    "accountNumber": "35302464616",
    "date": "2014-10-17",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.10 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022736395,
    "accountNumber": "35302464616",
    "date": "2014-10-17",
    "amount": "-59.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "17.10 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022862621,
    "accountNumber": "35302464616",
    "date": "2014-10-17",
    "amount": "-51.00",
    "text": "Statoil Ulset 5 Hesthaugveie Ulset",
    "alfareferanse": "16.10 STATOIL ULSET 5 HESTHAUGVEIE ULSET",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 901,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022820697,
    "accountNumber": "35302464616",
    "date": "2014-10-17",
    "amount": "-54.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "17.10 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1016728974,
    "accountNumber": "35302464616",
    "date": "2014-10-16",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021755979,
    "accountNumber": "35302464616",
    "date": "2014-10-16",
    "amount": "-118.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "16.10 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1020110416,
    "accountNumber": "35302464616",
    "date": "2014-10-16",
    "amount": "-338.00",
    "text": "Fersk Kosmetikk Småstrandgt Bergen",
    "alfareferanse": "16.10 FERSK KOSMETIKK SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 680,
      "placeId": "ChIJu3S-Xaj-PEYRdY2KXPeajc8",
      "placeName": "Xhibition Shopping Center",
      "coordinates": "60.392964,5.3260681",
      "website": "https://www.xhibition.no/",
      "logoId": 109,
      "merchantName": "Xhibition Shopping Center",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022862341,
    "accountNumber": "35302464616",
    "date": "2014-10-15",
    "amount": "-51.00",
    "text": "Fersk Kosmetikk Småstrandgt Bergen",
    "alfareferanse": "15.10 FERSK KOSMETIKK SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 680,
      "placeId": "ChIJu3S-Xaj-PEYRdY2KXPeajc8",
      "placeName": "Xhibition Shopping Center",
      "coordinates": "60.392964,5.3260681",
      "website": "https://www.xhibition.no/",
      "logoId": 109,
      "merchantName": "Xhibition Shopping Center",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1019890939,
    "accountNumber": "35302464616",
    "date": "2014-10-15",
    "amount": "-399.00",
    "text": "Shoeday Strømgt. 8 Bergen",
    "alfareferanse": "14.10 512025 SHOEDAY  STRØMGT. 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1115,
      "placeId": "ChIJW-e2tK7-PEYRZM33NJHQ51w",
      "placeName": "Mani Bergen Storsenter",
      "coordinates": "60.3893038,5.3325916",
      "website": "http://skomani.no/",
      "logoId": 171,
      "merchantName": "Mani",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023322986,
    "accountNumber": "35302464616",
    "date": "2014-10-15",
    "amount": "-29.08",
    "text": "Rema Øyrane Tor Ådnavegen 63 Indre Arna",
    "alfareferanse": "15.10 REMA ØYRANE TOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1300,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024480536,
    "accountNumber": "35302464616",
    "date": "2014-10-15",
    "amount": "-400.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "15.10 SPV Fyllingsdalen Inne  5147 Fyllingsdalen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021266895,
    "accountNumber": "35302464616",
    "date": "2014-10-14",
    "amount": "-160.00",
    "text": "Lie Nielsen As Chr.Michels 5012 Bergen",
    "alfareferanse": "14.10 LIE NIELSEN AS  CHR.MICHELS  5012 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1116,
      "placeId": "ChIJA0RtlgL8PEYRTv2FVFLfB-4",
      "placeName": "Lie Nielsen konditori AS",
      "coordinates": "60.3931374,5.3220761",
      "website": "http://www.lienielsen.no/",
      "logoId": 116,
      "merchantName": "Lie-Nielsen konditori",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024065521,
    "accountNumber": "35302464616",
    "date": "2014-10-14",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 12.10 NOK 89.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022503331,
    "accountNumber": "35302464616",
    "date": "2014-10-14",
    "amount": "-71.70",
    "text": "Rema 1000 Myrho Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "13.10 REMA 1000 MYRHO MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 524,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1024254511,
    "accountNumber": "35302464616",
    "date": "2014-10-14",
    "amount": "-29.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 12.10 NOK 29.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1023479424,
    "accountNumber": "35302464616",
    "date": "2014-10-10",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.10 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022983026,
    "accountNumber": "35302464616",
    "date": "2014-10-10",
    "amount": "-46.00",
    "text": "Wayne's Coffe Småstrandgat Bergen",
    "alfareferanse": "10.10 WAYNE'S COFFE SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1102,
      "placeId": "ChIJu3S-Xaj-PEYRSPl7a56eksY",
      "placeName": "Wayne’s Coffee",
      "coordinates": "60.3930053,5.3263753",
      "website": "http://www.waynescoffee.no/",
      "logoId": 225,
      "merchantName": "Wayne’s Coffee",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1021987686,
    "accountNumber": "35302464616",
    "date": "2014-10-10",
    "amount": "-100.00",
    "text": "China Palace 2 Torgalmennin Bergen",
    "alfareferanse": "10.10 CHINA PALACE 2  TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1316,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1022617227,
    "accountNumber": "35302464616",
    "date": "2014-10-10",
    "amount": "-65.00",
    "text": "China Palace Torgallmenni Bergen",
    "alfareferanse": "10.10 CHINA PALACE TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1317,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012089980,
    "accountNumber": "35302464616",
    "date": "2014-09-30",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "30.09 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1013994787,
    "accountNumber": "35302464616",
    "date": "2014-09-30",
    "amount": "-74.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         37 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 683,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1013224615,
    "accountNumber": "35302464616",
    "date": "2014-09-30",
    "amount": "-29.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 28.09 NOK 29.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012088616,
    "accountNumber": "35302464616",
    "date": "2014-09-24",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "24.09 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1008051299,
    "accountNumber": "35302464616",
    "date": "2014-09-23",
    "amount": "-1000.00",
    "text": "Expert Bonus Fa Nordåsdalen Rådal",
    "alfareferanse": "22.09 EXPERT BONUS FA NORDÅSDALEN  RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 1118,
      "placeId": "ChIJUdWqM-z5PEYRa4B-QS3gcso",
      "placeName": "POWER Fana",
      "coordinates": "60.3082485,5.3270829",
      "website": "https://www.power.no/",
      "logoId": 186,
      "merchantName": "POWER",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1011142276,
    "accountNumber": "35302464616",
    "date": "2014-09-23",
    "amount": "-86.49",
    "text": "Rema Garnsveien Garnsvegen 7 Indre Arna",
    "alfareferanse": "23.09 REMA GARNSVEIEN GARNSVEGEN 7 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1119,
      "placeId": "ChIJ1zMtw4D_PEYR6pvTIUZ75CQ",
      "placeName": "Rema 1000 Garnesveien",
      "coordinates": "60.4285704,5.4695214",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012086985,
    "accountNumber": "35302464616",
    "date": "2014-09-17",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "16.09 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1013125915,
    "accountNumber": "35302464616",
    "date": "2014-09-17",
    "amount": "-54.77",
    "text": "Norwegian",
    "alfareferanse": "*4928 14.09 SEK 60.00 Norwegian Kurs: 0.9128",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 1120,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1013002068,
    "accountNumber": "35302464616",
    "date": "2014-09-17",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.09 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012824374,
    "accountNumber": "35302464616",
    "date": "2014-09-16",
    "amount": "-237.07",
    "text": "Scandic Hotels Ariadne 80 0.911",
    "alfareferanse": "*4928 12.09 SEK 260.00 SCANDIC HOTELS ARIADNE 80 Kurs: 0.911",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Hotell",
    "imageId": "",
    "place": {
      "id": 1121,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012824376,
    "accountNumber": "35302464616",
    "date": "2014-09-16",
    "amount": "-237.07",
    "text": "Lokomotiv Taxi O Markovic 0.911",
    "alfareferanse": "*4928 13.09 SEK 260.00 LOKOMOTIV TAXI O MARKOVIC Kurs: 0.911",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 1122,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012905538,
    "accountNumber": "35302464616",
    "date": "2014-09-16",
    "amount": "-155.10",
    "text": "Ica Kvantum Vartan",
    "alfareferanse": "*4928 13.09 SEK 170.10 ICA KVANTUM VARTAN Kurs: 0.9118",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1123,
      "placeId": "ChIJ8yWfLMuCX0YRKgGriMuNnk4",
      "placeName": "ICA Kvantum",
      "coordinates": "59.3472229,18.113241",
      "website": "http://www.ica.se/kvantumvartan",
      "logoId": 230,
      "merchantName": "ICA Kvantum",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012941612,
    "accountNumber": "35302464616",
    "date": "2014-09-16",
    "amount": "-124.00",
    "text": "Mcdonald Centralstationen 0.911",
    "alfareferanse": "*4928 14.09 SEK 136.00 MCDONALD CENTRALSTATIONEN Kurs: 0.911",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1124,
      "placeId": "ChIJqU76e2CdX0YR_QHbkvUFOIU",
      "placeName": "McDonald's",
      "coordinates": "59.3297526,18.0586302",
      "website": "http://www.mcdonalds.se/",
      "logoId": 231,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1013125976,
    "accountNumber": "35302464616",
    "date": "2014-09-16",
    "amount": "-54.71",
    "text": "Norwegian",
    "alfareferanse": "*4928 12.09 SEK 60.00 Norwegian Kurs: 0.9118",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Fly",
    "imageId": "",
    "place": {
      "id": 1120,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1005573889,
    "accountNumber": "35302464616",
    "date": "2014-09-16",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1013030519,
    "accountNumber": "35302464616",
    "date": "2014-09-16",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 12.09 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1008963115,
    "accountNumber": "35302464616",
    "date": "2014-09-15",
    "amount": "-348.00",
    "text": "Cubus 874 Oasen Senter Fyllingsdalen",
    "alfareferanse": "12.09 CUBUS 874 OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 607,
      "placeId": "ChIJoTfziMX7PEYRab_c3wl4tKY",
      "placeName": "Cubus",
      "coordinates": "60.348195,5.2920946",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/874/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1009673006,
    "accountNumber": "35302464616",
    "date": "2014-09-15",
    "amount": "-215.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "14.09 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1011639157,
    "accountNumber": "35302464616",
    "date": "2014-09-15",
    "amount": "-59.00",
    "text": "Cafe Opus As Flesland Fly Bergen",
    "alfareferanse": "12.09 CAFE OPUS AS FLESLAND FLY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1125,
      "placeId": "ChIJJ_9Dt67-PEYRjvrSOiE3VcM",
      "placeName": "Cafe Opus",
      "coordinates": "60.389375,5.3320505",
      "website": "http://www.cafeopus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1008299908,
    "accountNumber": "35302464616",
    "date": "2014-09-15",
    "amount": "-647.00",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "12.09 H&M 854 OASEN OASEN BERGEN 5147 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1011660878,
    "accountNumber": "35302464616",
    "date": "2014-09-12",
    "amount": "-58.00",
    "text": "Henry Helgesen Tårnplass 1 Bergen",
    "alfareferanse": "12.09 HENRY HELGESEN  TÅRNPLASS 1  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1126,
      "placeId": "ChIJGVzhpAL8PEYRPyWWiWUbwvk",
      "placeName": "Henry Helgesen cafe & Conditorier AS",
      "coordinates": "60.3936958,5.3221574",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1008371748,
    "accountNumber": "35302464616",
    "date": "2014-09-10",
    "amount": "-595.00",
    "text": "Kicks 673 Folke Bernad Bergen",
    "alfareferanse": "09.09 KICKS 673 FOLKE BERNAD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 966,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1011538635,
    "accountNumber": "35302464616",
    "date": "2014-09-10",
    "amount": "-64.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "09.09 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1009206430,
    "accountNumber": "35302464616",
    "date": "2014-09-09",
    "amount": "-296.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "08.09 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1013080805,
    "accountNumber": "35302464616",
    "date": "2014-09-09",
    "amount": "-77.00",
    "text": "Nsb Tog 3060 94",
    "alfareferanse": "*4928 01.09 NOK 77.00 NSB tog 3060 94 Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1127,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1011829076,
    "accountNumber": "35302464616",
    "date": "2014-09-09",
    "amount": "-49.70",
    "text": "Rema Garnsveien Garnsvegen 7 Indre Arna",
    "alfareferanse": "08.09 REMA GARNSVEIEN GARNSVEGEN 7 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1119,
      "placeId": "ChIJ1zMtw4D_PEYR6pvTIUZ75CQ",
      "placeName": "Rema 1000 Garnesveien",
      "coordinates": "60.4285704,5.4695214",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012291574,
    "accountNumber": "35302464616",
    "date": "2014-09-09",
    "amount": "-27.90",
    "text": "Rema Øyrane Tor Ådnavegen 63 Indre Arna",
    "alfareferanse": "08.09 REMA ØYRANE TOR ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1300,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012084463,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "08.09 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1008777062,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-399.00",
    "text": "Morris 7121 Lagunevn 1 Rådal",
    "alfareferanse": "06.09 MORRIS 7121 LAGUNEVN 1 RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Vesker",
    "imageId": "",
    "place": {
      "id": 1128,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1011636367,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-59.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "08.09 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1010779219,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-107.70",
    "text": "Rimi Lone Hardangerv. Haukeland",
    "alfareferanse": "06.09 5055 RIMI LONE  HARDANGERV.  HAUKELAND",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1318,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1006136486,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-915.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 05.09.14",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1008343929,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-605.00",
    "text": "Kicks 617 Strandgaten Bergen",
    "alfareferanse": "08.09 KICKS 617 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 953,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1006290132,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-38.23",
    "text": "Cardif Skadefor",
    "alfareferanse": "Nettgiro til: CARDIF SKADEFOR Betalt: 05.09.14",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1068,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1006290131,
    "accountNumber": "35302464616",
    "date": "2014-09-08",
    "amount": "-38.23",
    "text": "Cardif Skadefor",
    "alfareferanse": "Nettgiro til: CARDIF SKADEFOR Betalt: 05.09.14",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1068,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1011634481,
    "accountNumber": "35302464616",
    "date": "2014-09-05",
    "amount": "-59.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "05.09 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012084166,
    "accountNumber": "35302464616",
    "date": "2014-09-05",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "05.09 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1010919058,
    "accountNumber": "35302464616",
    "date": "2014-09-04",
    "amount": "-99.00",
    "text": "Wayne's Coffe Småstrandgat Bergen",
    "alfareferanse": "03.09 WAYNE'S COFFE SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1102,
      "placeId": "ChIJu3S-Xaj-PEYRSPl7a56eksY",
      "placeName": "Wayne’s Coffee",
      "coordinates": "60.3930053,5.3263753",
      "website": "http://www.waynescoffee.no/",
      "logoId": 225,
      "merchantName": "Wayne’s Coffee",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1010475993,
    "accountNumber": "35302464616",
    "date": "2014-09-02",
    "amount": "-130.90",
    "text": "91 Kid Interiør Spelhaugen 6 Fyllingsdalen",
    "alfareferanse": "01.09 91 KID INTERIØR SPELHAUGEN 6 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1129,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012277402,
    "accountNumber": "35302464616",
    "date": "2014-09-02",
    "amount": "-28.80",
    "text": "Rimi Løvås . Fyllingsdalen",
    "alfareferanse": "01.09 6672 RIMI LØVÅS . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1130,
      "placeId": "ChIJP5Ghj8P7PEYRuv8OdiZUNFg",
      "placeName": "Extra Løvås",
      "coordinates": "60.35582,5.2897729",
      "website": "https://coop.no/butikker/extra/lovas-4580/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1010601480,
    "accountNumber": "35302464616",
    "date": "2014-09-02",
    "amount": "-120.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "01.09 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1012735884,
    "accountNumber": "35302464616",
    "date": "2014-09-02",
    "amount": "-433.80",
    "text": "Blush.No",
    "alfareferanse": "*4928 28.08 NOK 433.80 BLUSH.NO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 1095,
      "placeId": "ChIJky3fxDnHRkYR9r7fYCRNeM0",
      "placeName": "Blush.no AS",
      "coordinates": "59.1749085,10.2194018",
      "website": "https://www.blush.no/",
      "logoId": 223,
      "merchantName": "Blush.no",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 998895783,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-172.90",
    "text": "Nespresso Strandgt. 7 Bergen",
    "alfareferanse": "30.08 NESPRESSO STRANDGT. 7  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1062,
      "placeId": "ChIJJQ2lA6j-PEYRkm-DbV9hQQY",
      "placeName": "Nespresso Boutique Bergen",
      "coordinates": "60.3940462,5.3237397",
      "website": "http://www.nespresso.com/",
      "logoId": 133,
      "merchantName": "Nespresso Boutique",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 997287232,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-495.00",
    "text": "Kicks 617 Strandgaten Bergen",
    "alfareferanse": "30.08 KICKS 617 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 953,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 998290669,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-246.00",
    "text": "Burger King5003 Strandgt. 5 Bergen",
    "alfareferanse": "30.08 BURGER KING5003 STRANDGT. 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 959,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 999923158,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-92.80",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "30.08 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001151855,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-27.90",
    "text": "Spar Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "30.08 SPAR ØYRANE ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 960,
      "placeId": "ChIJC6INGX__PEYRSmdk7JDduTE",
      "placeName": "Spar",
      "coordinates": "60.4213174,5.4650283",
      "website": "https://spar.no/",
      "logoId": 153,
      "merchantName": "Eurospar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001309651,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-19.80",
    "text": "Glitter Bergen Starvhusgt 4 Bergen",
    "alfareferanse": "30.08 GLITTER BERGEN  STARVHUSGT 4 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 810,
      "placeId": "ChIJ__-_XKj-PEYR-7d3oVnZ4vU",
      "placeName": "Glitter",
      "coordinates": "60.3927285,5.3263497",
      "website": "https://www.glitter.no/storelocator/Glitter-Bergen-Xhibition",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 997772349,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-340.00",
    "text": "Kicks 676 Strømgaten 8 Bergen",
    "alfareferanse": "30.08 KICKS 676 STRØMGATEN 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 858,
      "placeId": "ChIJJ_9Dt67-PEYRu-Al530qmuM",
      "placeName": "KICKS",
      "coordinates": "60.3891768,5.332768",
      "website": "https://www.kicks.no/butikker/bergen-bergen-storsenter/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000131311,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-80.00",
    "text": "Narvesen 709 Øyrane Torg, Bergen",
    "alfareferanse": "30.08 NARVESEN 709 ØYRANE TORG, BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1131,
      "placeId": "ChIJd_9GqH__PEYRVsucM_qjGXI",
      "placeName": "Narvesen",
      "coordinates": "60.4213785,5.4663651",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 999171196,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-148.00",
    "text": "Dromedar Strandgt 81 Bergen",
    "alfareferanse": "30.08 DROMEDAR STRANDGT 81  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1132,
      "placeId": "ChIJ3_A5xBz8PEYRMWI4VL9qtDA",
      "placeName": "Dromedar Kaffebar",
      "coordinates": "60.3957639,5.3175526",
      "website": "http://www.dromedar.no/",
      "logoId": 232,
      "merchantName": "Dromedar Kaffebar",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000965357,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-37.00",
    "text": "Nsb Bergen Tvm Stasjonsvn5 5015 Bergen",
    "alfareferanse": "30.08 NSB BERGEN TVM  STASJONSVN5  5015 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1133,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000965299,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "30.08 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001142043,
    "accountNumber": "35302464616",
    "date": "2014-09-01",
    "amount": "-28.40",
    "text": "Rema Garnsveien Garnsvegen 7 Indre Arna",
    "alfareferanse": "30.08 REMA GARNSVEIEN GARNSVEGEN 7 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1119,
      "placeId": "ChIJ1zMtw4D_PEYR6pvTIUZ75CQ",
      "placeName": "Rema 1000 Garnesveien",
      "coordinates": "60.4285704,5.4695214",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1002795946,
    "accountNumber": "35302464616",
    "date": "2014-08-31",
    "amount": "-52.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         26 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000964958,
    "accountNumber": "35302464616",
    "date": "2014-08-29",
    "amount": "-37.00",
    "text": "Nsb Bergen Tvm Stasjonsvn5 5015 Bergen",
    "alfareferanse": "28.08 NSB BERGEN TVM  STASJONSVN5  5015 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1133,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 998521683,
    "accountNumber": "35302464616",
    "date": "2014-08-29",
    "amount": "-212.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "28.08 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000622488,
    "accountNumber": "35302464616",
    "date": "2014-08-28",
    "amount": "-54.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "28.08 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 998113651,
    "accountNumber": "35302464616",
    "date": "2014-08-28",
    "amount": "-274.00",
    "text": "Lindex 959 Øyra Ådnavegen 63 Indre Arna",
    "alfareferanse": "27.08 LINDEX 959 ØYRA ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1399,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 996928615,
    "accountNumber": "35302464616",
    "date": "2014-08-28",
    "amount": "-714.00",
    "text": "Parfymell Ådnavegen 63 Indre Arna",
    "alfareferanse": "27.08 12228 PARFYMELL ÅDNAVEGEN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 1319,
      "placeId": "ChIJC6INGX__PEYR4WaVQugCpv0",
      "placeName": "Parfymelle",
      "coordinates": "60.4211702,5.4651545",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000964749,
    "accountNumber": "35302464616",
    "date": "2014-08-28",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "28.08 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 999646412,
    "accountNumber": "35302464616",
    "date": "2014-08-28",
    "amount": "-108.10",
    "text": "Kiwi 888 Stølsvn 1 Indre Arna",
    "alfareferanse": "27.08 KIWI 888 STØLSVN 1 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1034,
      "placeId": "ChIJWV2tE4D_PEYRViszQPVr8WI",
      "placeName": "KIWI Indre Arna",
      "coordinates": "60.4245007,5.4706934",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Indre-Arna/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000211795,
    "accountNumber": "35302464616",
    "date": "2014-08-28",
    "amount": "-76.29",
    "text": "Kiwi 888 Stølsvn 1 Indre Arna",
    "alfareferanse": "27.08 KIWI 888 STØLSVN 1 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1034,
      "placeId": "ChIJWV2tE4D_PEYRViszQPVr8WI",
      "placeName": "KIWI Indre Arna",
      "coordinates": "60.4245007,5.4706934",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Indre-Arna/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 999361578,
    "accountNumber": "35302464616",
    "date": "2014-08-27",
    "amount": "-130.00",
    "text": "Jacob Aall Bras Småstrandsgt Bergen",
    "alfareferanse": "26.08 JACOB AALL BRAS SMÅSTRANDSGT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1134,
      "placeId": "ChIJu3S-Xaj-PEYRiV0QNnQKXao",
      "placeName": "Jacob Aall Brasserie & Bar Bergen",
      "coordinates": "60.3928282,5.326276",
      "website": "http://www.jacobaall.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000935221,
    "accountNumber": "35302464616",
    "date": "2014-08-27",
    "amount": "-39.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "26.08 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 998513515,
    "accountNumber": "35302464616",
    "date": "2014-08-26",
    "amount": "-213.39",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "25.08 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 997982685,
    "accountNumber": "35302464616",
    "date": "2014-08-26",
    "amount": "-299.00",
    "text": "Italpizza As Åsane Senter Ulset",
    "alfareferanse": "25.08 ITALPIZZA AS ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1019,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1002001396,
    "accountNumber": "35302464616",
    "date": "2014-08-26",
    "amount": "-35.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 24.08 NOK 35.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001917438,
    "accountNumber": "35302464616",
    "date": "2014-08-25",
    "amount": "-70.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 21.08 NOK 70.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001917399,
    "accountNumber": "35302464616",
    "date": "2014-08-25",
    "amount": "-70.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 21.08 NOK 70.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000963475,
    "accountNumber": "35302464616",
    "date": "2014-08-25",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "25.08 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000963037,
    "accountNumber": "35302464616",
    "date": "2014-08-21",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "21.08 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001831343,
    "accountNumber": "35302464616",
    "date": "2014-08-19",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.08 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000962038,
    "accountNumber": "35302464616",
    "date": "2014-08-18",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "18.08 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000253065,
    "accountNumber": "35302464616",
    "date": "2014-08-18",
    "amount": "-74.10",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "15.08 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 999520658,
    "accountNumber": "35302464616",
    "date": "2014-08-18",
    "amount": "-118.00",
    "text": "Narvesen 709 Øyrane Torg, Bergen",
    "alfareferanse": "17.08 NARVESEN 709 ØYRANE TORG, BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1131,
      "placeId": "ChIJd_9GqH__PEYRVsucM_qjGXI",
      "placeName": "Narvesen",
      "coordinates": "60.4213785,5.4663651",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 994329625,
    "accountNumber": "35302464616",
    "date": "2014-08-18",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000689300,
    "accountNumber": "35302464616",
    "date": "2014-08-18",
    "amount": "-50.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "18.08 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001988526,
    "accountNumber": "35302464616",
    "date": "2014-08-18",
    "amount": "-39.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 14.08 NOK 39.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 998617231,
    "accountNumber": "35302464616",
    "date": "2014-08-18",
    "amount": "-200.00",
    "text": "Peppes Pizza Loddefjordvn Loddefjord",
    "alfareferanse": "15.08 PEPPES PIZZA LODDEFJORDVN LODDEFJORD",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 546,
      "placeId": "ChIJLzGKTFv7PEYR_2jcVu7R7Gs",
      "placeName": "Peppes Pizza - Vannkanten",
      "coordinates": "60.3624792,5.235519",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000961629,
    "accountNumber": "35302464616",
    "date": "2014-08-15",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "15.08 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 999337279,
    "accountNumber": "35302464616",
    "date": "2014-08-15",
    "amount": "-131.99",
    "text": "Rema Garnsveien Garnsvegen 7 Indre Arna",
    "alfareferanse": "14.08 REMA GARNSVEIEN GARNSVEGEN 7 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1119,
      "placeId": "ChIJ1zMtw4D_PEYR6pvTIUZ75CQ",
      "placeName": "Rema 1000 Garnesveien",
      "coordinates": "60.4285704,5.4695214",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 999706619,
    "accountNumber": "35302464616",
    "date": "2014-08-15",
    "amount": "-104.00",
    "text": "Solbrød Oasen Kokstaddalen Kokstad",
    "alfareferanse": "15.08 SOLBRØD OASEN KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 871,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001857515,
    "accountNumber": "35302464616",
    "date": "2014-08-14",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 12.08 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1002038009,
    "accountNumber": "35302464616",
    "date": "2014-08-14",
    "amount": "-29.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 12.08 NOK 29.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 997121784,
    "accountNumber": "35302464616",
    "date": "2014-08-13",
    "amount": "-574.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "12.08 CUBUS 875 BYSTASJONEN  5015 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000855147,
    "accountNumber": "35302464616",
    "date": "2014-08-13",
    "amount": "-42.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "12.08 NARVESEN 105 STRØMSGT. 2  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000872579,
    "accountNumber": "35302464616",
    "date": "2014-08-13",
    "amount": "-40.90",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "12.08 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001235946,
    "accountNumber": "35302464616",
    "date": "2014-08-12",
    "amount": "-22.90",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "11.08 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000468825,
    "accountNumber": "35302464616",
    "date": "2014-08-12",
    "amount": "-61.69",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "11.08 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1001158005,
    "accountNumber": "35302464616",
    "date": "2014-08-11",
    "amount": "-27.00",
    "text": "Narvesen 709 Øyrane Torg, Bergen",
    "alfareferanse": "10.08 NARVESEN 709 ØYRANE TORG, BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1131,
      "placeId": "ChIJd_9GqH__PEYRVsucM_qjGXI",
      "placeName": "Narvesen",
      "coordinates": "60.4213785,5.4663651",
      "website": "http://narvesen.no/",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 1000138178,
    "accountNumber": "35302464616",
    "date": "2014-08-11",
    "amount": "-79.90",
    "text": "Rema Garnsveien Garnsvegen 7 Indre Arna",
    "alfareferanse": "09.08 REMA GARNSVEIEN GARNSVEGEN 7 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1119,
      "placeId": "ChIJ1zMtw4D_PEYR6pvTIUZ75CQ",
      "placeName": "Rema 1000 Garnesveien",
      "coordinates": "60.4285704,5.4695214",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 998471917,
    "accountNumber": "35302464616",
    "date": "2014-08-11",
    "amount": "-219.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "10.08 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 997778365,
    "accountNumber": "35302464616",
    "date": "2014-08-11",
    "amount": "-338.28",
    "text": "Kiwi 888 Stølsvn 1 Indre Arna",
    "alfareferanse": "09.08 KIWI 888 STØLSVN 1 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1034,
      "placeId": "ChIJWV2tE4D_PEYRViszQPVr8WI",
      "placeName": "KIWI Indre Arna",
      "coordinates": "60.4245007,5.4706934",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Indre-Arna/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989429622,
    "accountNumber": "35302464616",
    "date": "2014-07-31",
    "amount": "-37.00",
    "text": "Nsb Arna Tvm Arna Stasjon Indre Arna",
    "alfareferanse": "31.07 NSB ARNA TVM ARNA STASJON INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1117,
      "placeId": "ChIJg6I7Vn7_PEYREF_DnbamwXw",
      "placeName": "Arna",
      "coordinates": "60.4203739,5.4678446",
      "website": "https://www.nsb.no/reisemal/stasjoner/arna-stasjon?id=2342",
      "logoId": 229,
      "merchantName": "NSB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 991587637,
    "accountNumber": "35302464616",
    "date": "2014-07-31",
    "amount": "-34.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         17 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1136,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990676253,
    "accountNumber": "35302464616",
    "date": "2014-07-31",
    "amount": "-29.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 29.07 NOK 29.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989798499,
    "accountNumber": "35302464616",
    "date": "2014-07-31",
    "amount": "-11.50",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "31.07 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989042506,
    "accountNumber": "35302464616",
    "date": "2014-07-30",
    "amount": "-59.00",
    "text": "Big Bite Avd317 Adnavn 63 Indre Arna",
    "alfareferanse": "30.07 BIG BITE AVD317 ADNAVN 63 INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1423,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990827719,
    "accountNumber": "35302464616",
    "date": "2014-07-29",
    "amount": "-949.37",
    "text": "Akbank/Hacet Kavsagi Al",
    "alfareferanse": "*4928 25.07 NOK 949.37 AKBANK/HACET KAVSAGI AL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1137,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 991576360,
    "accountNumber": "35302464616",
    "date": "2014-07-29",
    "amount": "-34.70",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 987898725,
    "accountNumber": "35302464616",
    "date": "2014-07-28",
    "amount": "-129.00",
    "text": "Duty Free Ankom Bergen Lufh. Bergen",
    "alfareferanse": "27.07 DUTY FREE ANKOM BERGEN LUFH. BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Taxfree",
    "imageId": "",
    "place": {
      "id": 1320,
      "placeId": "ChIJzZm963fwPEYRkIe_P4LGDgA",
      "placeName": "Bergen Airport",
      "coordinates": "60.29183,5.2220173",
      "website": "https://avinor.no/flyplass/bergen/",
      "logoId": 274,
      "merchantName": "Bergen Airport",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990823218,
    "accountNumber": "35302464616",
    "date": "2014-07-25",
    "amount": "-1102.94",
    "text": "Akbank/Hacet Kavsagi Al",
    "alfareferanse": "*4928 23.07 NOK 1102.94 AKBANK/HACET KAVSAGI AL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1137,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 991568673,
    "accountNumber": "35302464616",
    "date": "2014-07-25",
    "amount": "-35.50",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 991577874,
    "accountNumber": "35302464616",
    "date": "2014-07-23",
    "amount": "-34.60",
    "text": "Personkort Utland",
    "alfareferanse": "PERSONKORT UTLAND                   1 TRANS(ER) TYPE 715",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1003,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990828839,
    "accountNumber": "35302464616",
    "date": "2014-07-23",
    "amount": "-935.28",
    "text": "Akbank/Hacet Kavsagi Al",
    "alfareferanse": "*4928 21.07 NOK 935.28 AKBANK/HACET KAVSAGI AL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1137,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990360430,
    "accountNumber": "35302464616",
    "date": "2014-07-22",
    "amount": "-119.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 19.07 NOK 119.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990749459,
    "accountNumber": "35302464616",
    "date": "2014-07-22",
    "amount": "-7.00",
    "text": "Itunes.Com/Bill",
    "alfareferanse": "*4928 20.07 NOK 7.00 ITUNES.COM/BILL Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 459,
      "logoId": 277,
      "merchantName": "Apple",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989606430,
    "accountNumber": "35302464616",
    "date": "2014-07-21",
    "amount": "-27.00",
    "text": "Narvesen 749 Nedre Torget Bergen",
    "alfareferanse": "18.07 NARVESEN 749 NEDRE TORGET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 595,
      "placeId": "ChIJJxgNwqf-PEYRsiakWPPE4pg",
      "placeName": "Narvesen",
      "coordinates": "60.3954837,5.3257567",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 988160886,
    "accountNumber": "35302464616",
    "date": "2014-07-21",
    "amount": "-108.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "18.07 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 986831624,
    "accountNumber": "35302464616",
    "date": "2014-07-21",
    "amount": "-235.00",
    "text": "Cafe Opus As Flesland Fly Bergen",
    "alfareferanse": "20.07 CAFE OPUS AS FLESLAND FLY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1125,
      "placeId": "ChIJJ_9Dt67-PEYRjvrSOiE3VcM",
      "placeName": "Cafe Opus",
      "coordinates": "60.389375,5.3320505",
      "website": "http://www.cafeopus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 987528059,
    "accountNumber": "35302464616",
    "date": "2014-07-21",
    "amount": "-159.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "19.07 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989693025,
    "accountNumber": "35302464616",
    "date": "2014-07-21",
    "amount": "-21.00",
    "text": "7 - Eleven 7111 . Bergen Luftha",
    "alfareferanse": "20.07 7 - ELEVEN 7111 . BERGEN LUFTHA",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1433,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989098229,
    "accountNumber": "35302464616",
    "date": "2014-07-21",
    "amount": "-55.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "18.07 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990174225,
    "accountNumber": "35302464616",
    "date": "2014-07-18",
    "amount": "-253.46",
    "text": "E-Visa Turkey",
    "alfareferanse": "*4928 16.07 USD 40.00 E-VISA TURKEY Kurs: 6.3365",
    "mainCategory": "Utgifter",
    "category": "Ferie og reise",
    "subCategory": "Reise",
    "imageId": "",
    "place": {
      "id": 1009,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990422950,
    "accountNumber": "35302464616",
    "date": "2014-07-17",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.07 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 987333672,
    "accountNumber": "35302464616",
    "date": "2014-07-17",
    "amount": "-179.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "16.07 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989681014,
    "accountNumber": "35302464616",
    "date": "2014-07-16",
    "amount": "-22.30",
    "text": "Coop Obs Sartor Sartor Sente Straume",
    "alfareferanse": "16.07 COOP OBS SARTOR SARTOR SENTE STRAUME",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 578,
      "placeId": "ChIJ07Dmae7kPEYRdtQFqnLjn7Y",
      "placeName": "Obs Bygg",
      "coordinates": "60.3589314,5.1249784",
      "website": "https://coop.no/butikker/obs/sartor-2408/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 986908264,
    "accountNumber": "35302464616",
    "date": "2014-07-16",
    "amount": "-224.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "15.07 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 988778025,
    "accountNumber": "35302464616",
    "date": "2014-07-16",
    "amount": "-73.68",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "15.07 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989241879,
    "accountNumber": "35302464616",
    "date": "2014-07-16",
    "amount": "-48.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "15.07 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 982663622,
    "accountNumber": "35302464616",
    "date": "2014-07-16",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 988427919,
    "accountNumber": "35302464616",
    "date": "2014-07-16",
    "amount": "-94.00",
    "text": "Sørensen Tobakk Torgallmenn8 Bergen",
    "alfareferanse": "15.07 SØRENSEN TOBAKK TORGALLMENN8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1114,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 990458854,
    "accountNumber": "35302464616",
    "date": "2014-07-15",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 12.07 NOK 89.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 987285265,
    "accountNumber": "35302464616",
    "date": "2014-07-14",
    "amount": "-184.00",
    "text": "Jula Bergen Oas Folke Barnad Fyllingsdalen",
    "alfareferanse": "12.07 JULA BERGEN OAS FOLKE BARNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 516,
      "placeId": "ChIJp13-g8_7PEYRFzYXBSYJXB8",
      "placeName": "Jula",
      "coordinates": "60.3485852,5.2915106",
      "website": "http://www.jula.no/vare-varehus/bergen-fyllingsdalen/",
      "logoId": 61,
      "merchantName": "Jula",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 988596185,
    "accountNumber": "35302464616",
    "date": "2014-07-14",
    "amount": "-83.00",
    "text": "Big Bite 321 F.Bernadott Fyllingsdalen",
    "alfareferanse": "12.07 BIG BITE 321 F.BERNADOTT  FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1138,
      "placeId": "ChIJp13-g8_7PEYRsozFVYYiG7w",
      "placeName": "Big Bite",
      "coordinates": "60.3491563,5.2911056",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 987506989,
    "accountNumber": "35302464616",
    "date": "2014-07-14",
    "amount": "-160.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "12.07 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 989453790,
    "accountNumber": "35302464616",
    "date": "2014-07-10",
    "amount": "-35.00",
    "text": "Wayne's Coffe Småstrandgat Bergen",
    "alfareferanse": "10.07 WAYNE'S COFFE SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1102,
      "placeId": "ChIJu3S-Xaj-PEYRSPl7a56eksY",
      "placeName": "Wayne’s Coffee",
      "coordinates": "60.3930053,5.3263753",
      "website": "http://www.waynescoffee.no/",
      "logoId": 225,
      "merchantName": "Wayne’s Coffee",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976416532,
    "accountNumber": "35302464616",
    "date": "2014-07-01",
    "amount": "-112.79",
    "text": "Rimi Strømgt. 8 Bergen",
    "alfareferanse": "30.06 4983 RIMI STRØMGT. 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1103,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978550750,
    "accountNumber": "35302464616",
    "date": "2014-07-01",
    "amount": "-280.00",
    "text": "Toscana Pizzeri",
    "alfareferanse": "*4928 29.06 NOK 280.00 TOSCANA PIZZERI Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1140,
      "placeId": "ChIJRaZ3l_qHKhMRM3n-edsz4po",
      "placeName": "Il Giardino Ristorante Pizzeria panoramico",
      "coordinates": "43.896533,10.789558",
      "website": "http://www.caffegiardino.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975985945,
    "accountNumber": "35302464616",
    "date": "2014-07-01",
    "amount": "-147.00",
    "text": "Crinum As Bergen Stors 5015 Bergen",
    "alfareferanse": "30.06 CRINUM AS BERGEN STORS 5015 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 1141,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974253580,
    "accountNumber": "35302464616",
    "date": "2014-07-01",
    "amount": "-388.00",
    "text": "Din Sko 1116 Torgallm.8 Bergen",
    "alfareferanse": "30.06 DIN SKO 1116 TORGALLM.8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1139,
      "placeId": "ChIJSbvXPaj-PEYRjUumXB8BhtY",
      "placeName": "DinSko & ECCO",
      "coordinates": "60.3930466,5.3251284",
      "website": "https://feetfirst.no/sko/dinsko-ecco-galleriet",
      "logoId": 159,
      "merchantName": "DinSko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975302124,
    "accountNumber": "35302464616",
    "date": "2014-07-01",
    "amount": "-208.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "30.06 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974263081,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-384.90",
    "text": "Br-Leker F2328 Strømgaten 8 Bergen",
    "alfareferanse": "28.06 BR-LEKER F2328  STRØMGATEN 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Leketøy",
    "imageId": "",
    "place": {
      "id": 1142,
      "placeId": "ChIJJ_9Dt67-PEYRlC-SlrxtGeY",
      "placeName": "BR",
      "coordinates": "60.3895227,5.3327305",
      "website": "https://www.br.no/store/2328",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976901956,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-86.00",
    "text": "Dolly Dimples Strømgt. 8 Bergen",
    "alfareferanse": "28.06 DOLLY DIMPLES STRØMGT. 8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1143,
      "placeId": "ChIJ7cjd0eD5PEYRuemL6G3TbUg",
      "placeName": "Dolly Dimple's Lagunen",
      "coordinates": "60.2969411,5.3307524",
      "website": "http://www.dolly.no/",
      "logoId": 120,
      "merchantName": "Dolly Dimple's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 979933515,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-32.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         16 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1136,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977167637,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-72.00",
    "text": "Cafe Opus As 24 Strømsgt. 8 Bergen",
    "alfareferanse": "28.06 CAFE OPUS AS 24 STRØMSGT. 8  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1108,
      "placeId": "ChIJJ_9Dt67-PEYRjvrSOiE3VcM",
      "placeName": "Cafe Opus",
      "coordinates": "60.389375,5.3320505",
      "website": "http://www.cafeopus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974033910,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-448.95",
    "text": "Glitter Bergen Starvhusgt 4 Bergen",
    "alfareferanse": "28.06 GLITTER BERGEN  STARVHUSGT 4 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 810,
      "placeId": "ChIJ__-_XKj-PEYR-7d3oVnZ4vU",
      "placeName": "Glitter",
      "coordinates": "60.3927285,5.3263497",
      "website": "https://www.glitter.no/storelocator/Glitter-Bergen-Xhibition",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 979812371,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-250.00",
    "text": "Årspris Visa Classic",
    "alfareferanse": "Årspris Visa Classic",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 679,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974624303,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-303.00",
    "text": "Norgestaxi As Akersbakken Oslo",
    "alfareferanse": "28.06 NORGESTAXI AS AKERSBAKKEN  OSLO",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 614,
      "placeId": "ChIJwU6kv29uQUYRMmJEU73VaKs",
      "placeName": "NorgesTaxi",
      "coordinates": "59.913628,10.789754",
      "website": "http://www.norgestaxi.no/",
      "logoId": 66,
      "merchantName": "NorgesTaxi",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 980061072,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978011525,
    "accountNumber": "35302464616",
    "date": "2014-06-30",
    "amount": "-30.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "27.06 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978321567,
    "accountNumber": "35302464616",
    "date": "2014-06-17",
    "amount": "-2.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "17.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978754918,
    "accountNumber": "35302464616",
    "date": "2014-06-17",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.06 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977703002,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-45.00",
    "text": "Wayne's Coffe Småstrandgat Bergen",
    "alfareferanse": "14.06 WAYNE'S COFFE SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1102,
      "placeId": "ChIJu3S-Xaj-PEYRSPl7a56eksY",
      "placeName": "Wayne’s Coffee",
      "coordinates": "60.3930053,5.3263753",
      "website": "http://www.waynescoffee.no/",
      "logoId": 225,
      "merchantName": "Wayne’s Coffee",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 970526751,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976899140,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-86.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "13.06 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975229420,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-218.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "13.06 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976870568,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-88.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "14.06 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976860920,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-88.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977411431,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-59.30",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978786229,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-89.00",
    "text": "Netflix",
    "alfareferanse": "*4928 12.06 NOK 89.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974163200,
    "accountNumber": "35302464616",
    "date": "2014-06-16",
    "amount": "-403.28",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "14.06 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978240828,
    "accountNumber": "35302464616",
    "date": "2014-06-13",
    "amount": "-17.90",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "12.06 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975152597,
    "accountNumber": "35302464616",
    "date": "2014-06-12",
    "amount": "-228.00",
    "text": "Subway Strandka Strandgt. 1 Bergen",
    "alfareferanse": "11.06 SUBWAY STRANDKA STRANDGT. 1  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1144,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976780939,
    "accountNumber": "35302464616",
    "date": "2014-06-11",
    "amount": "-92.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977193749,
    "accountNumber": "35302464616",
    "date": "2014-06-10",
    "amount": "-70.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "06.06 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976655310,
    "accountNumber": "35302464616",
    "date": "2014-06-10",
    "amount": "-99.00",
    "text": "Din Sko 1116 Torgallm.8 Bergen",
    "alfareferanse": "10.06 DIN SKO 1116 TORGALLM.8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1139,
      "placeId": "ChIJSbvXPaj-PEYRjUumXB8BhtY",
      "placeName": "DinSko & ECCO",
      "coordinates": "60.3930466,5.3251284",
      "website": "https://feetfirst.no/sko/dinsko-ecco-galleriet",
      "logoId": 159,
      "merchantName": "DinSko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975664274,
    "accountNumber": "35302464616",
    "date": "2014-06-10",
    "amount": "-174.65",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "07.06 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975909570,
    "accountNumber": "35302464616",
    "date": "2014-06-10",
    "amount": "-150.00",
    "text": "Bergen Kino Neumannsgate Bergen",
    "alfareferanse": "06.06 BERGEN KINO NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 713,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976597968,
    "accountNumber": "35302464616",
    "date": "2014-06-06",
    "amount": "-100.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "05.06 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977768373,
    "accountNumber": "35302464616",
    "date": "2014-06-06",
    "amount": "-41.00",
    "text": "A & Jc Matglede C. Sundts Gt Bergen",
    "alfareferanse": "05.06 A & JC MATGLEDE C. SUNDTS GT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 794,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976842759,
    "accountNumber": "35302464616",
    "date": "2014-06-06",
    "amount": "-89.00",
    "text": "Bergen Zoo As Galleriet Bergen",
    "alfareferanse": "05.06 BERGEN ZOO AS GALLERIET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 1082,
      "placeId": "ChIJSbvXPaj-PEYR2EjBArThtic",
      "placeName": "The center Zoo",
      "coordinates": "60.3929202,5.324611",
      "website": "http://sentrumzoo.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 969675078,
    "accountNumber": "35302464616",
    "date": "2014-06-06",
    "amount": "-366.59",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "05.06 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977337820,
    "accountNumber": "35302464616",
    "date": "2014-06-06",
    "amount": "-62.83",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "06.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977673889,
    "accountNumber": "35302464616",
    "date": "2014-06-06",
    "amount": "-46.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "05.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976340734,
    "accountNumber": "35302464616",
    "date": "2014-06-05",
    "amount": "-118.73",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "04.06 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 971277029,
    "accountNumber": "35302464616",
    "date": "2014-06-05",
    "amount": "-395.00",
    "text": "Frende S As",
    "alfareferanse": "Nettgiro til: Frende S AS Betalt: 05.06.14",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1145,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 979184336,
    "accountNumber": "35302464616",
    "date": "2014-06-05",
    "amount": "-1100.00",
    "text": "Spv Sletten 5081 Bergen",
    "alfareferanse": "05.06 SPV Sletten             5081 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1146,
      "placeId": "ChIJZ__93hX5PEYRFmhIQFEYUNE",
      "placeName": "Sletten Tannlegesenter",
      "coordinates": "60.3566913,5.3593651",
      "website": "http://www.slettentannlegesenter.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 971233957,
    "accountNumber": "35302464616",
    "date": "2014-06-05",
    "amount": "-600.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 05.06.14",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 971646441,
    "accountNumber": "35302464616",
    "date": "2014-06-05",
    "amount": "-678.00",
    "text": "6008.00",
    "alfareferanse": "Nettgiro til: 6008.05.01210 Betalt: 05.06.14",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1434,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 973563172,
    "accountNumber": "35302464616",
    "date": "2014-06-05",
    "amount": "-680.00",
    "text": "Kicks 617 Strandgaten Bergen",
    "alfareferanse": "04.06 KICKS 617 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 953,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977271899,
    "accountNumber": "35302464616",
    "date": "2014-06-04",
    "amount": "-66.70",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "04.06 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975642510,
    "accountNumber": "35302464616",
    "date": "2014-06-04",
    "amount": "-177.00",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "03.06 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978263321,
    "accountNumber": "35302464616",
    "date": "2014-06-04",
    "amount": "-15.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 978240141,
    "accountNumber": "35302464616",
    "date": "2014-06-04",
    "amount": "-17.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "04.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977759270,
    "accountNumber": "35302464616",
    "date": "2014-06-03",
    "amount": "-41.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977489446,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-55.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 977937775,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-32.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.06 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975453260,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-197.00",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "30.05 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974814268,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-276.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "01.06 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976007782,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-144.80",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "02.06 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975206845,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-220.00",
    "text": "Habo Gastronomi Vaskerelven Bergen",
    "alfareferanse": "30.05 HABO GASTRONOMI VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1147,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975560981,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-185.00",
    "text": "Habo Gastronomi Vaskerelven Bergen",
    "alfareferanse": "30.05 HABO GASTRONOMI VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1147,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975206841,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-220.00",
    "text": "Habo Gastronomi Vaskerelven Bergen",
    "alfareferanse": "30.05 HABO GASTRONOMI VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1147,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 970229873,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-200.00",
    "text": "Nokas 7-Eleven Torget 5014 Bergen",
    "alfareferanse": "01.06 Nokas 7-Eleven Torget   5014 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1148,
      "placeId": "ChIJW7VB36f-PEYRiPmsO1fOA-Y",
      "placeName": "7-Eleven",
      "coordinates": "60.3953766,5.3262766",
      "website": "http://www.7-eleven.no/",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976086133,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-138.00",
    "text": "Luux Bergen As Valkendorfsg Bergen",
    "alfareferanse": "01.06 LUUX BERGEN AS  VALKENDORFSG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1149,
      "placeId": "ChIJl5eioAL8PEYRrqWM8UVAA5s",
      "placeName": "Luux Bergen",
      "coordinates": "60.3934119,5.3229737",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975830434,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-158.00",
    "text": "Luux Bergen As Valkendorfsg Bergen",
    "alfareferanse": "01.06 LUUX BERGEN AS  VALKENDORFSG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1149,
      "placeId": "ChIJl5eioAL8PEYRrqWM8UVAA5s",
      "placeName": "Luux Bergen",
      "coordinates": "60.3934119,5.3229737",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976086169,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-138.00",
    "text": "Luux Bergen As Valkendorfsg Bergen",
    "alfareferanse": "01.06 LUUX BERGEN AS  VALKENDORFSG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1149,
      "placeId": "ChIJl5eioAL8PEYRrqWM8UVAA5s",
      "placeName": "Luux Bergen",
      "coordinates": "60.3934119,5.3229737",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 973277481,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-1023.00",
    "text": "Solheimsviken Post I Butik Bergen",
    "alfareferanse": "31.05 SOLHEIMSVIKEN POST I BUTIK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 1150,
      "placeId": "ChIJK03-ak75PEYRCCsxLr0Zu7Y",
      "placeName": "Solheimsviken Post i Butikk",
      "coordinates": "60.3753929,5.3381713",
      "website": "https://www.posten.no/",
      "logoId": 100,
      "merchantName": "Posten",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 976558289,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-103.00",
    "text": "Burger King 500 Strandgt 5 Bergen",
    "alfareferanse": "31.05 BURGER KING 500 STRANDGT 5 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1151,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974350444,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-360.00",
    "text": "Privaten . Bergen",
    "alfareferanse": "30.05 PRIVATEN . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1435,
      "placeId": "ChIJWX8CfgL8PEYRaUrntrz3EaA",
      "placeName": "Privaten Bar",
      "coordinates": "60.3915869,5.3220019",
      "website": "http://privatenbar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975054967,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-240.00",
    "text": "Privaten . Bergen",
    "alfareferanse": "30.05 PRIVATEN . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1435,
      "placeId": "ChIJWX8CfgL8PEYRaUrntrz3EaA",
      "placeName": "Privaten Bar",
      "coordinates": "60.3915869,5.3220019",
      "website": "http://privatenbar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975360948,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-200.00",
    "text": "Privaten . Bergen",
    "alfareferanse": "30.05 PRIVATEN . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1435,
      "placeId": "ChIJWX8CfgL8PEYRaUrntrz3EaA",
      "placeName": "Privaten Bar",
      "coordinates": "60.3915869,5.3220019",
      "website": "http://privatenbar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 975129598,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-230.00",
    "text": "Bergen Taxi Kokstadvn 8 Kokstad",
    "alfareferanse": "01.06 BERGEN TAXI KOKSTADVN 8  KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Taxi",
    "imageId": "",
    "place": {
      "id": 978,
      "placeId": "ChIJ6c7VjgL8PEYRqbHd_npT-mQ",
      "placeName": "Bergen Taxi",
      "coordinates": "60.2917336,5.2595899",
      "website": "http://www.bergentaxi.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 974885338,
    "accountNumber": "35302464616",
    "date": "2014-06-02",
    "amount": "-263.80",
    "text": "Vinmonopolet Valkendorfsg Bergen",
    "alfareferanse": "31.05 VINMONOPOLET VALKENDORFSG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 718,
      "placeId": "ChIJu_x9vq7-PEYRDJzRB3tvIqY",
      "placeName": "Vinmonopolet Bergen Storsenter",
      "coordinates": "60.3894163,5.3332038",
      "website": "http://www.vinmonopolet.no/butikker?butikk_id=121",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 967454005,
    "accountNumber": "35302464616",
    "date": "2014-05-31",
    "amount": "-52.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         26 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 967548388,
    "accountNumber": "35302464616",
    "date": "2014-05-31",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 967406066,
    "accountNumber": "35302464616",
    "date": "2014-05-28",
    "amount": "-200.00",
    "text": "Årspris Visa Electron",
    "alfareferanse": "Årspris Visa Electron",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1011,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962699739,
    "accountNumber": "35302464616",
    "date": "2014-05-26",
    "amount": "-351.39",
    "text": "Bunnpris Nygårdsgt 89 Bergen",
    "alfareferanse": "23.05 BUNNPRIS NYGÅRDSGT 89 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1033,
      "placeId": "ChIJ7_vXiKz-PEYR0bY0ABaG5ZQ",
      "placeName": "Bunnpris",
      "coordinates": "60.3850653,5.3315794",
      "website": "http://bunnpris.no/butikker/bunnpris-nygardsgaten",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966198258,
    "accountNumber": "35302464616",
    "date": "2014-05-26",
    "amount": "-2.10",
    "text": "Bunnpris Nygårdsgt 89 Bergen",
    "alfareferanse": "23.05 BUNNPRIS NYGÅRDSGT 89 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1033,
      "placeId": "ChIJ7_vXiKz-PEYR0bY0ABaG5ZQ",
      "placeName": "Bunnpris",
      "coordinates": "60.3850653,5.3315794",
      "website": "http://bunnpris.no/butikker/bunnpris-nygardsgaten",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 958539973,
    "accountNumber": "35302464616",
    "date": "2014-05-26",
    "amount": "-425.00",
    "text": "Bunnpris Nygårdsgt 89 Bergen",
    "alfareferanse": "23.05 BUNNPRIS NYGÅRDSGT 89 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1033,
      "placeId": "ChIJ7_vXiKz-PEYR0bY0ABaG5ZQ",
      "placeName": "Bunnpris",
      "coordinates": "60.3850653,5.3315794",
      "website": "http://bunnpris.no/butikker/bunnpris-nygardsgaten",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964018444,
    "accountNumber": "35302464616",
    "date": "2014-05-26",
    "amount": "-150.00",
    "text": "China Palace 2 Torgalmennin Bergen",
    "alfareferanse": "23.05 CHINA PALACE 2  TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1316,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 959046237,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-400.00",
    "text": "Torget Atm 1 Ute 5014 Bergen",
    "alfareferanse": "17.05 Torget ATM 1 Ute        5014 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1321,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962797518,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-328.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "16.05 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966526770,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.05 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962436669,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-431.00",
    "text": "Lille Vaskerelven Bergen",
    "alfareferanse": "16.05 LILLE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 740,
      "placeId": "ChIJqzY-egL8PEYRudQQ9OqCRXU",
      "placeName": "Lille Bar & Lounge",
      "coordinates": "60.3911359,5.322035",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963337146,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-230.00",
    "text": "Lille Vaskerelven Bergen",
    "alfareferanse": "16.05 LILLE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 740,
      "placeId": "ChIJqzY-egL8PEYRudQQ9OqCRXU",
      "placeName": "Lille Bar & Lounge",
      "coordinates": "60.3911359,5.322035",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965697016,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-39.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "16.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962708287,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-350.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "17.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963448526,
    "accountNumber": "35302464616",
    "date": "2014-05-19",
    "amount": "-214.00",
    "text": "Wave Zachariasbry Bergen",
    "alfareferanse": "17.05 WAVE ZACHARIASBRY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 558,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965028021,
    "accountNumber": "35302464616",
    "date": "2014-05-16",
    "amount": "-76.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "16.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 959323899,
    "accountNumber": "35302464616",
    "date": "2014-05-16",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962549273,
    "accountNumber": "35302464616",
    "date": "2014-05-15",
    "amount": "-398.00",
    "text": "Cubus 879 Sundt City Bergen",
    "alfareferanse": "14.05 CUBUS 879 SUNDT CITY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 724,
      "placeId": "ChIJg4UbMKj-PEYRyOGpKSaozac",
      "placeName": "Cubus",
      "coordinates": "60.3922867,5.3239234",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/879/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965399936,
    "accountNumber": "35302464616",
    "date": "2014-05-15",
    "amount": "-55.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "15.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965595641,
    "accountNumber": "35302464616",
    "date": "2014-05-15",
    "amount": "-45.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "14.05 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966574420,
    "accountNumber": "35302464616",
    "date": "2014-05-14",
    "amount": "-79.00",
    "text": "Netflix",
    "alfareferanse": "*4928 12.05 NOK 79.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965399841,
    "accountNumber": "35302464616",
    "date": "2014-05-14",
    "amount": "-55.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966059309,
    "accountNumber": "35302464616",
    "date": "2014-05-14",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "13.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965616552,
    "accountNumber": "35302464616",
    "date": "2014-05-14",
    "amount": "-44.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "13.05 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965910973,
    "accountNumber": "35302464616",
    "date": "2014-05-14",
    "amount": "-29.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "13.05 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964655215,
    "accountNumber": "35302464616",
    "date": "2014-05-14",
    "amount": "-99.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "13.05 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964550496,
    "accountNumber": "35302464616",
    "date": "2014-05-14",
    "amount": "-104.90",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "13.05 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964559093,
    "accountNumber": "35302464616",
    "date": "2014-05-13",
    "amount": "-104.00",
    "text": "Kremmerhuset Xh Småstrandgat Bergen",
    "alfareferanse": "12.05 KREMMERHUSET XH SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1152,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 958998637,
    "accountNumber": "35302464616",
    "date": "2014-05-13",
    "amount": "-1300.00",
    "text": "Atm Galleriet Be Dnb Bank Asa 7183",
    "alfareferanse": "12.05 ATM GALLERIET BE DNB BANK ASA      7183",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 739,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964393724,
    "accountNumber": "35302464616",
    "date": "2014-05-12",
    "amount": "-117.28",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "12.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965172170,
    "accountNumber": "35302464616",
    "date": "2014-05-09",
    "amount": "-68.65",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "08.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962960969,
    "accountNumber": "35302464616",
    "date": "2014-05-09",
    "amount": "-298.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "08.05 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965505628,
    "accountNumber": "35302464616",
    "date": "2014-05-08",
    "amount": "-49.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "08.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965286942,
    "accountNumber": "35302464616",
    "date": "2014-05-07",
    "amount": "-60.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "07.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965345328,
    "accountNumber": "35302464616",
    "date": "2014-05-07",
    "amount": "-58.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "06.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965050224,
    "accountNumber": "35302464616",
    "date": "2014-05-07",
    "amount": "-75.00",
    "text": "Vero Moda/Only Torvalmennin Bergen",
    "alfareferanse": "06.05 VERO MODA/ONLY  TORVALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1322,
      "placeId": "ChIJH9t0PKj-PEYRRB3ILnroPZk",
      "placeName": "VERO MODA",
      "coordinates": "60.3926438,5.3244953",
      "website": "http://www.veromoda.no/",
      "logoId": 275,
      "merchantName": "VERO MODA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966303944,
    "accountNumber": "35302464616",
    "date": "2014-05-07",
    "amount": "-498.00",
    "text": "Nsb.No Mobil",
    "alfareferanse": "*4928 05.05 NOK 498.00 nsb.no mobil Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1153,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966458100,
    "accountNumber": "35302464616",
    "date": "2014-05-06",
    "amount": "-140.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 04.05 NOK 140.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966458263,
    "accountNumber": "35302464616",
    "date": "2014-05-06",
    "amount": "-140.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 03.05 NOK 140.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964367392,
    "accountNumber": "35302464616",
    "date": "2014-05-06",
    "amount": "-119.00",
    "text": "Reindyrka As Strandgt. 6 Bergen",
    "alfareferanse": "05.05 REINDYRKA AS STRANDGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1155,
      "placeId": "ChIJ18ycpgL8PEYRpvUTJMBqmtk",
      "placeName": "Reindyrka AS",
      "coordinates": "60.3937437,5.3230539",
      "website": "https://www.reindyrka.no/",
      "logoId": 236,
      "merchantName": "Reindyrka AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964489313,
    "accountNumber": "35302464616",
    "date": "2014-05-06",
    "amount": "-109.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "06.05 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963625757,
    "accountNumber": "35302464616",
    "date": "2014-05-06",
    "amount": "-195.00",
    "text": "Nespresso Strandgt. 7 Bergen",
    "alfareferanse": "05.05 NESPRESSO STRANDGT. 7  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1062,
      "placeId": "ChIJJQ2lA6j-PEYRkm-DbV9hQQY",
      "placeName": "Nespresso Boutique Bergen",
      "coordinates": "60.3940462,5.3237397",
      "website": "http://www.nespresso.com/",
      "logoId": 133,
      "merchantName": "Nespresso Boutique",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962168410,
    "accountNumber": "35302464616",
    "date": "2014-05-06",
    "amount": "-554.78",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "05.05 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964851423,
    "accountNumber": "35302464616",
    "date": "2014-05-06",
    "amount": "-87.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "06.05 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963678997,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-188.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "03.05 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966116806,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-17.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "03.05 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 961734126,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-1091.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "03.05 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963287409,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-238.16",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "02.05 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965774271,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-35.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 966050004,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965505198,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-49.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "05.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 961855900,
    "accountNumber": "35302464616",
    "date": "2014-05-05",
    "amount": "-841.48",
    "text": "Rema Fjøsanger Straumeveien Bergen",
    "alfareferanse": "03.05 REMA FJØSANGER  STRAUMEVEIEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1023,
      "placeId": "ChIJGUyy7275PEYRu5QkMcUITww",
      "placeName": "REMA 1000",
      "coordinates": "60.3474007,5.3355204",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 959955421,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-525.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 01.05.14",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962825241,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-320.00",
    "text": "God Drikke Berg Vaskerelven Bergen",
    "alfareferanse": "01.05 GOD DRIKKE BERG VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 686,
      "placeId": "ChIJ8XhRegL8PEYRSgdZyHSHbM4",
      "placeName": "God Drikke Bergen AS",
      "coordinates": "60.3911614,5.3217653",
      "website": "http://www.bergensentrum.no/",
      "logoId": 110,
      "merchantName": "God Drikke Bergen AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965519279,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-49.00",
    "text": "Platekompaniet Torgallmenni Bergen",
    "alfareferanse": "30.04 PLATEKOMPANIET  TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Spill/musikk",
    "imageId": "",
    "place": {
      "id": 1323,
      "placeId": "ChIJJ6MGIqj-PEYRuG_EdoMJ0vY",
      "placeName": "Platekompaniet - Torgallmenningen",
      "coordinates": "60.3931885,5.3238325",
      "website": "http://www.platekompaniet.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963300193,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-236.00",
    "text": "Privaten . Bergen",
    "alfareferanse": "02.05 PRIVATEN . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1435,
      "placeId": "ChIJWX8CfgL8PEYRaUrntrz3EaA",
      "placeName": "Privaten Bar",
      "coordinates": "60.3915869,5.3220019",
      "website": "http://privatenbar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 962516842,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-400.00",
    "text": "Privaten . Bergen",
    "alfareferanse": "01.05 PRIVATEN . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1435,
      "placeId": "ChIJWX8CfgL8PEYRaUrntrz3EaA",
      "placeName": "Privaten Bar",
      "coordinates": "60.3915869,5.3220019",
      "website": "http://privatenbar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963678633,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-188.00",
    "text": "Privaten . Bergen",
    "alfareferanse": "01.05 PRIVATEN . BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1435,
      "placeId": "ChIJWX8CfgL8PEYRaUrntrz3EaA",
      "placeName": "Privaten Bar",
      "coordinates": "60.3915869,5.3220019",
      "website": "http://privatenbar.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963659814,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-190.00",
    "text": "Tgi Fridays As Nedre Bulls Bergen",
    "alfareferanse": "01.05 TGI FRIDAYS AS  NEDRE BULLS  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1156,
      "placeId": "ChIJpQJT0qn-PEYRicdiDvFY3AU",
      "placeName": "TGI Friday's",
      "coordinates": "60.3913922,5.3237916",
      "website": "https://fridays.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964644736,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-99.00",
    "text": "H&M 839 Xhibiti Smstrandgt. Bergen",
    "alfareferanse": "30.04 H&M 839 XHIBITI SMSTRANDGT.  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1157,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 963688080,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-187.00",
    "text": "Ole B Scene 1 Ole Bulls Pl Bergen",
    "alfareferanse": "30.04 OLE B SCENE 1 OLE BULLS PL BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1158,
      "placeId": "ChIJ8Y6ThAL8PEYRLg8gDHBmNYg",
      "placeName": "Ole Bull Scene",
      "coordinates": "60.392322,5.3223322",
      "website": "http://www.olebullscene.no/",
      "logoId": 237,
      "merchantName": "Ole Bull Scene",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 960031157,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-226.00",
    "text": "Frende S As",
    "alfareferanse": "Nettgiro til: Frende S AS Betalt: 01.05.14",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1145,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 965773141,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-35.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.05 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964099669,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-143.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "02.05 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 964523721,
    "accountNumber": "35302464616",
    "date": "2014-05-02",
    "amount": "-106.79",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "30.04 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 956536113,
    "accountNumber": "35302464616",
    "date": "2014-04-30",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 956453107,
    "accountNumber": "35302464616",
    "date": "2014-04-30",
    "amount": "-38.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         19 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1136,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953612044,
    "accountNumber": "35302464616",
    "date": "2014-04-30",
    "amount": "-113.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "29.04 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955481330,
    "accountNumber": "35302464616",
    "date": "2014-04-29",
    "amount": "-140.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 26.04 NOK 140.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955772139,
    "accountNumber": "35302464616",
    "date": "2014-04-29",
    "amount": "-14.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 26.04 NOK 14.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955481290,
    "accountNumber": "35302464616",
    "date": "2014-04-29",
    "amount": "-140.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 25.04 NOK 140.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953154308,
    "accountNumber": "35302464616",
    "date": "2014-04-28",
    "amount": "-158.00",
    "text": "Nesttun Kebab & Nesttunveien Nesttun",
    "alfareferanse": "26.04 NESTTUN KEBAB & NESTTUNVEIEN NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1159,
      "placeId": "ChIJ90tz7bv5PEYRqwM3qlBtY8M",
      "placeName": "Nesttun Kebab og Pizza",
      "coordinates": "60.3192282,5.3522274",
      "website": "https://www.facebook.com/pages/Nesttun-Kebab/123574931034176",
      "logoId": 71,
      "merchantName": "BarBarista",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955096734,
    "accountNumber": "35302464616",
    "date": "2014-04-28",
    "amount": "-20.00",
    "text": "Mcdonald's Brygges Bryggesporen Bergen",
    "alfareferanse": "27.04 MCD 057 BRYGGES BRYGGESPOREN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 728,
      "placeId": "ChIJP1mWxaf-PEYRnQwhU4NL1qk",
      "placeName": "McDonald's",
      "coordinates": "60.3954113,5.3266711",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953837969,
    "accountNumber": "35302464616",
    "date": "2014-04-25",
    "amount": "-98.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "24.04 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953401899,
    "accountNumber": "35302464616",
    "date": "2014-04-25",
    "amount": "-132.39",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "24.04 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954626251,
    "accountNumber": "35302464616",
    "date": "2014-04-25",
    "amount": "-48.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "24.04 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953565858,
    "accountNumber": "35302464616",
    "date": "2014-04-25",
    "amount": "-117.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "25.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953564985,
    "accountNumber": "35302464616",
    "date": "2014-04-25",
    "amount": "-117.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "24.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955625456,
    "accountNumber": "35302464616",
    "date": "2014-04-24",
    "amount": "-70.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 22.04 NOK 70.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955624804,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-70.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 20.04 NOK 70.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955624767,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-70.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 20.04 NOK 70.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955450480,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-175.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 18.04 NOK 175.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955624762,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-70.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 18.04 NOK 70.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955624907,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-70.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 18.04 NOK 70.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 950327130,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-100.00",
    "text": "Mobilbank Til: Nordea Finans K",
    "alfareferanse": "Mobilbank til: NORDEA FINANS K Betalt: 23.04.14",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1160,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 950327121,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-100.00",
    "text": "Mobilbank Til: Nordea Finans K",
    "alfareferanse": "Mobilbank til: NORDEA FINANS K Betalt: 23.04.14",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1160,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 950315504,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-332.00",
    "text": "Mobilbank Til: Frende S As",
    "alfareferanse": "Mobilbank til: Frende S AS Betalt: 23.04.14",
    "mainCategory": "Utgifter",
    "category": "Forsikring",
    "subCategory": "Skadeforsikring",
    "imageId": "",
    "place": {
      "id": 1161,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 950305277,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-600.00",
    "text": "Mobilbank Til: Bank Norwegian",
    "alfareferanse": "Mobilbank til: BANK NORWEGIAN Betalt: 23.04.14",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1324,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954410956,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-59.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "23.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954442891,
    "accountNumber": "35302464616",
    "date": "2014-04-23",
    "amount": "-58.20",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "22.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953455354,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-128.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "19.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953671930,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-108.00",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "19.04 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954355710,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-63.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "18.04 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953672210,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-108.00",
    "text": "Akadem.Kvarter Olav Kyrresg Bergen",
    "alfareferanse": "18.04 AKADEM.KVARTER  OLAV KYRRESG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1162,
      "placeId": "ChIJUR9SCAL8PEYRdWFRmjy03ws",
      "placeName": "The Academic Quarter",
      "coordinates": "60.3897013,5.3220125",
      "website": "http://www.kvarteret.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954506419,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-54.00",
    "text": "Akadem.Kvarter Olav Kyrresg Bergen",
    "alfareferanse": "18.04 AKADEM.KVARTER  OLAV KYRRESG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1162,
      "placeId": "ChIJUR9SCAL8PEYRdWFRmjy03ws",
      "placeName": "The Academic Quarter",
      "coordinates": "60.3897013,5.3220125",
      "website": "http://www.kvarteret.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953219689,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-150.00",
    "text": "God Servering Engen 12 Bergen",
    "alfareferanse": "21.04 GOD SERVERING ENGEN 12 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1163,
      "placeId": "ChIJMW1_9wL8PEYRpUWgKe51ZHo",
      "placeName": "Ferdinand på Engen",
      "coordinates": "60.391989,5.3194724",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952535418,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-240.00",
    "text": "Tgi Fridays As Nedre Bulls Bergen",
    "alfareferanse": "21.04 TGI FRIDAYS AS  NEDRE BULLS  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1156,
      "placeId": "ChIJpQJT0qn-PEYRicdiDvFY3AU",
      "placeName": "TGI Friday's",
      "coordinates": "60.3913922,5.3237916",
      "website": "https://fridays.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953379109,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-135.00",
    "text": "Tgi Fridays As Nedre Bulls Bergen",
    "alfareferanse": "21.04 TGI FRIDAYS AS  NEDRE BULLS  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1156,
      "placeId": "ChIJpQJT0qn-PEYRicdiDvFY3AU",
      "placeName": "TGI Friday's",
      "coordinates": "60.3913922,5.3237916",
      "website": "https://fridays.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952535254,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-240.00",
    "text": "Bergen Kino Neumannsgate Bergen",
    "alfareferanse": "21.04 BERGEN KINO NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 713,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952109730,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-325.00",
    "text": "Ny Milano Pizze Stortveitvei Bergen",
    "alfareferanse": "17.04 NY MILANO PIZZE STORTVEITVEI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 691,
      "placeId": "ChIJPTvOD0D5PEYRI6kz-Db50M4",
      "placeName": "Milano Pizzeria",
      "coordinates": "60.359419,5.3500582",
      "website": "http://www.milano-pizzeria.net/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955546760,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.04 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 951446423,
    "accountNumber": "35302464616",
    "date": "2014-04-22",
    "amount": "-600.00",
    "text": "Peppes Pizza 26 Olav Kyrres Bergen",
    "alfareferanse": "21.04 PEPPES PIZZA 26 OLAV KYRRES  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 956,
      "placeId": "ChIJMRfeSaj-PEYRBm0FKEqpfzE",
      "placeName": "Peppes Pizza - Ole Bulls Plass",
      "coordinates": "60.3917406,5.3243031",
      "website": "https://www.peppes.no/",
      "logoId": 69,
      "merchantName": "Peppes Pizza",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 948898456,
    "accountNumber": "35302464616",
    "date": "2014-04-16",
    "amount": "-200.00",
    "text": "Unicef Komiteen I No",
    "alfareferanse": "UNICEF KOMITEEN I NO",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gaver",
    "imageId": "",
    "place": {
      "id": 1314,
      "placeId": "ChIJyehniIhuQUYRKIuWYqZ-BLE",
      "placeName": "UNICEF committee in Norway",
      "coordinates": "59.9096078,10.7416936",
      "website": "https://www.unicef.no/",
      "logoId": 273,
      "merchantName": "UNICEF committee in Norway",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953657089,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-109.00",
    "text": "Konfektgallerie Torgallmenni Bergen",
    "alfareferanse": "12.04 KONFEKTGALLERIE TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Spesialforretning",
    "imageId": "",
    "place": {
      "id": 1315,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954103981,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-79.50",
    "text": "Lindex 224 Galleriet Bergen",
    "alfareferanse": "14.04 LINDEX 224 GALLERIET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1401,
      "placeId": "ChIJSbvXPaj-PEYRvmB-hu5Dg48",
      "placeName": "Lindex",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://www.lindex.com/",
      "logoId": 295,
      "merchantName": "Lindex",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954323675,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-65.00",
    "text": "Big Bite 321 F.Bernadott Fyllingsdalen",
    "alfareferanse": "11.04 BIG BITE 321 F.BERNADOTT  FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1138,
      "placeId": "ChIJp13-g8_7PEYRsozFVYYiG7w",
      "placeName": "Big Bite",
      "coordinates": "60.3491563,5.2911056",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954068612,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-80.05",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "12.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954360691,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-62.50",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954959680,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-28.60",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954567841,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-50.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "11.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955592811,
    "accountNumber": "35302464616",
    "date": "2014-04-14",
    "amount": "-79.00",
    "text": "Netflix",
    "alfareferanse": "*4928 10.04 NOK 79.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952194829,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-303.60",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "10.04 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953763972,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-100.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "11.04 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955081856,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-20.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "11.04 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954182630,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-74.60",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954130571,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-78.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "10.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954788054,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-38.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "10.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954240846,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-70.00",
    "text": "Upstairs Vestre Torvg Bergen",
    "alfareferanse": "10.04 UPSTAIRS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 561,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954309114,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-66.00",
    "text": "Upstairs As Vestre Torvg Bergen",
    "alfareferanse": "11.04 UPSTAIRS AS VESTRE TORVG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 562,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954485941,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-55.00",
    "text": "Deli De Luca Torggaten 5 Bergen",
    "alfareferanse": "10.04 DELI DE LUCA TORGGATEN 5  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 528,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954240194,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-70.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "11.04 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 955008578,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-25.00",
    "text": "Sentrum Kebab Vestre Torgg Bergen",
    "alfareferanse": "10.04 SENTRUM KEBAB VESTRE TORGG BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 483,
      "placeId": "ChIJDZISDQL8PEYR5kX9pp55F_o",
      "placeName": "Sentrum Kebab",
      "coordinates": "60.3901006,5.321377",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 948627366,
    "accountNumber": "35302464616",
    "date": "2014-04-11",
    "amount": "-500.00",
    "text": "Nokas 7-Eleven Torggt. 5014 Bergen",
    "alfareferanse": "11.04 Nokas 7-Eleven Torggt.  5014 Bergen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1164,
      "placeId": "ChIJ0Zo-fwL8PEYRJia7ZqcGLI8",
      "placeName": "7-Eleven",
      "coordinates": "60.3908829,5.3223338",
      "website": "http://7-eleven.no/",
      "logoId": 207,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 951298099,
    "accountNumber": "35302464616",
    "date": "2014-04-10",
    "amount": "-742.00",
    "text": "Solheimsviken Post I Butik Bergen",
    "alfareferanse": "09.04 SOLHEIMSVIKEN POST I BUTIK BERGEN",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Postkontor",
    "imageId": "",
    "place": {
      "id": 1150,
      "placeId": "ChIJK03-ak75PEYRCCsxLr0Zu7Y",
      "placeName": "Solheimsviken Post i Butikk",
      "coordinates": "60.3753929,5.3381713",
      "website": "https://www.posten.no/",
      "logoId": 100,
      "merchantName": "Posten",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952655623,
    "accountNumber": "35302464616",
    "date": "2014-04-09",
    "amount": "-220.00",
    "text": "Bergen Kino Kiosk .",
    "alfareferanse": "08.04 BERGEN KINO KIOSK .",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 842,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953488470,
    "accountNumber": "35302464616",
    "date": "2014-04-09",
    "amount": "-124.30",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "09.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954523048,
    "accountNumber": "35302464616",
    "date": "2014-04-09",
    "amount": "-52.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "08.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 948163225,
    "accountNumber": "35302464616",
    "date": "2014-04-08",
    "amount": "-534.90",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "07.04 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952329221,
    "accountNumber": "35302464616",
    "date": "2014-04-08",
    "amount": "-279.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "08.04 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954592939,
    "accountNumber": "35302464616",
    "date": "2014-04-07",
    "amount": "-49.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "05.04 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953452712,
    "accountNumber": "35302464616",
    "date": "2014-04-07",
    "amount": "-128.00",
    "text": "Tannklinikken E Håkonsgt.1 Bergen",
    "alfareferanse": "07.04 TANNKLINIKKEN E HÅKONSGT.1 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Tannlege",
    "subCategory": "Tannlege",
    "imageId": "",
    "place": {
      "id": 1165,
      "placeId": "ChIJDbmMBKj-PEYRO7ahvABNaeY",
      "placeName": "Tannklinikken 5-3",
      "coordinates": "60.3939818,5.3238403",
      "website": "http://www.5-3.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954599406,
    "accountNumber": "35302464616",
    "date": "2014-04-07",
    "amount": "-49.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "05.04 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 953321091,
    "accountNumber": "35302464616",
    "date": "2014-04-07",
    "amount": "-140.00",
    "text": "Dalia Kebab Che Vaskerelven Bergen",
    "alfareferanse": "06.04 DALIA KEBAB CHE VASKERELVEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1021,
      "placeId": "ChIJe2lqdgL8PEYRhNNNokHmKlY",
      "placeName": "Dalia Kebab",
      "coordinates": "60.390665,5.322248",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952989337,
    "accountNumber": "35302464616",
    "date": "2014-04-04",
    "amount": "-178.00",
    "text": "Fersk Kosmetikk Småstrandgt Bergen",
    "alfareferanse": "03.04 FERSK KOSMETIKK SMÅSTRANDGT  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 680,
      "placeId": "ChIJu3S-Xaj-PEYRdY2KXPeajc8",
      "placeName": "Xhibition Shopping Center",
      "coordinates": "60.392964,5.3260681",
      "website": "https://www.xhibition.no/",
      "logoId": 109,
      "merchantName": "Xhibition Shopping Center",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954382059,
    "accountNumber": "35302464616",
    "date": "2014-04-03",
    "amount": "-60.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954278158,
    "accountNumber": "35302464616",
    "date": "2014-04-02",
    "amount": "-68.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "01.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 954418194,
    "accountNumber": "35302464616",
    "date": "2014-04-02",
    "amount": "-59.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952363174,
    "accountNumber": "35302464616",
    "date": "2014-04-02",
    "amount": "-271.80",
    "text": "Apotek 1Galleri Torgallmenni Bergen",
    "alfareferanse": "02.04 APOTEK 1GALLERI TORGALLMENNI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1275,
      "placeId": "ChIJlXd4Pqj-PEYRGJ59Z0m-IwI",
      "placeName": "Apotek 1",
      "coordinates": "60.3933283,5.3253565",
      "website": "https://www.apotek1.no/vaare-apotek/hordaland/galleriet-536",
      "logoId": 260,
      "merchantName": "Apotek 1",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 952398389,
    "accountNumber": "35302464616",
    "date": "2014-04-02",
    "amount": "-264.00",
    "text": "Lerøy Mat Galle Torgalm.8 Bergen",
    "alfareferanse": "02.04 LERØY MAT GALLE TORGALM.8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1281,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 951941194,
    "accountNumber": "35302464616",
    "date": "2014-04-02",
    "amount": "-373.50",
    "text": "Lindex 224 Galleriet Bergen",
    "alfareferanse": "01.04 LINDEX 224 GALLERIET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1401,
      "placeId": "ChIJSbvXPaj-PEYRvmB-hu5Dg48",
      "placeName": "Lindex",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://www.lindex.com/",
      "logoId": 295,
      "merchantName": "Lindex",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 937348197,
    "accountNumber": "35302464616",
    "date": "2014-04-01",
    "amount": "-700.00",
    "text": "Atm Galleriet 2 Dnb Bank Asa 7183",
    "alfareferanse": "01.04 ATM GALLERIET 2  DNB BANK ASA      7183",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1166,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 940987978,
    "accountNumber": "35302464616",
    "date": "2014-04-01",
    "amount": "-402.74",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "31.03 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943716148,
    "accountNumber": "35302464616",
    "date": "2014-04-01",
    "amount": "-69.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "31.03 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943288024,
    "accountNumber": "35302464616",
    "date": "2014-04-01",
    "amount": "-93.39",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "01.04 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943228570,
    "accountNumber": "35302464616",
    "date": "2014-03-31",
    "amount": "-97.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "31.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944065081,
    "accountNumber": "35302464616",
    "date": "2014-03-31",
    "amount": "-50.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "31.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 946379570,
    "accountNumber": "35302464616",
    "date": "2014-03-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943635371,
    "accountNumber": "35302464616",
    "date": "2014-03-31",
    "amount": "-73.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "28.03 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 946201513,
    "accountNumber": "35302464616",
    "date": "2014-03-31",
    "amount": "-58.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         29 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944215747,
    "accountNumber": "35302464616",
    "date": "2014-03-28",
    "amount": "-42.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "28.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944657542,
    "accountNumber": "35302464616",
    "date": "2014-03-27",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "27.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943172596,
    "accountNumber": "35302464616",
    "date": "2014-03-26",
    "amount": "-99.70",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "26.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944587301,
    "accountNumber": "35302464616",
    "date": "2014-03-26",
    "amount": "-24.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "25.03 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943522812,
    "accountNumber": "35302464616",
    "date": "2014-03-26",
    "amount": "-79.50",
    "text": "Lindex 224 Galleriet Bergen",
    "alfareferanse": "25.03 LINDEX 224 GALLERIET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1401,
      "placeId": "ChIJSbvXPaj-PEYRvmB-hu5Dg48",
      "placeName": "Lindex",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://www.lindex.com/",
      "logoId": 295,
      "merchantName": "Lindex",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 942098481,
    "accountNumber": "35302464616",
    "date": "2014-03-26",
    "amount": "-198.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "25.03 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943089175,
    "accountNumber": "35302464616",
    "date": "2014-03-26",
    "amount": "-104.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "25.03 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 940870317,
    "accountNumber": "35302464616",
    "date": "2014-03-25",
    "amount": "-450.00",
    "text": "China Palace 2 Torgalmennin Bergen",
    "alfareferanse": "24.03 CHINA PALACE 2  TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1316,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 942271312,
    "accountNumber": "35302464616",
    "date": "2014-03-25",
    "amount": "-178.00",
    "text": "Cubus 879 Sundt City Bergen",
    "alfareferanse": "24.03 CUBUS 879 SUNDT CITY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 724,
      "placeId": "ChIJg4UbMKj-PEYRyOGpKSaozac",
      "placeName": "Cubus",
      "coordinates": "60.3922867,5.3239234",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/879/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943607133,
    "accountNumber": "35302464616",
    "date": "2014-03-25",
    "amount": "-74.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "25.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944072199,
    "accountNumber": "35302464616",
    "date": "2014-03-24",
    "amount": "-49.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "21.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943859203,
    "accountNumber": "35302464616",
    "date": "2014-03-24",
    "amount": "-60.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "24.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944071932,
    "accountNumber": "35302464616",
    "date": "2014-03-24",
    "amount": "-49.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "22.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943683448,
    "accountNumber": "35302464616",
    "date": "2014-03-24",
    "amount": "-70.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "22.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 938392248,
    "accountNumber": "35302465310",
    "date": "2014-03-24",
    "amount": "-447.80",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 22.03.14",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 940126239,
    "accountNumber": "35302464616",
    "date": "2014-03-24",
    "amount": "-2226.00",
    "text": "Optikus M.O.Nielsen Galleriet",
    "alfareferanse": "22.03 OPTIKUS M.O.NIELSEN  GALLERIET",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Optiker",
    "imageId": "",
    "place": {
      "id": 1167,
      "placeId": "ChIJSbvXPaj-PEYRyS2cf2XIfE4",
      "placeName": "Optikus Martin O Nielsen - MON",
      "coordinates": "60.3929202,5.324611",
      "website": "http://www.optikusmon.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943073984,
    "accountNumber": "35302464616",
    "date": "2014-03-21",
    "amount": "-105.00",
    "text": "Sørensen Tobakk Torgallmenn8 Bergen",
    "alfareferanse": "20.03 SØRENSEN TOBAKK TORGALLMENN8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1114,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 942586214,
    "accountNumber": "35302464616",
    "date": "2014-03-21",
    "amount": "-146.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "20.03 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943305258,
    "accountNumber": "35302464616",
    "date": "2014-03-21",
    "amount": "-91.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "21.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944288183,
    "accountNumber": "35302464616",
    "date": "2014-03-21",
    "amount": "-39.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "20.03 7 - ELEVEN 7119 OLAV KYRREST BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944055364,
    "accountNumber": "35302464616",
    "date": "2014-03-20",
    "amount": "-50.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "19.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944648913,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "19.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943209808,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-98.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "18.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943148392,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-100.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "19.03 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943619870,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-74.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "18.03 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943073548,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-105.00",
    "text": "Sørensen Tobakk Torgallmenn8 Bergen",
    "alfareferanse": "18.03 SØRENSEN TOBAKK TORGALLMENN8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1114,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 941537282,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-278.00",
    "text": "H&M 839 Xhibiti Smstrandgt. Bergen",
    "alfareferanse": "19.03 H&M 839 XHIBITI SMSTRANDGT.  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1157,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 945085625,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.03 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943791271,
    "accountNumber": "35302464616",
    "date": "2014-03-19",
    "amount": "-64.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "19.03 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 940651291,
    "accountNumber": "35302464616",
    "date": "2014-03-18",
    "amount": "-558.00",
    "text": "Kicks 673 Folke Bernad Bergen",
    "alfareferanse": "17.03 KICKS 673 FOLKE BERNAD BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 966,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 941238814,
    "accountNumber": "35302464616",
    "date": "2014-03-18",
    "amount": "-338.00",
    "text": "Le Cafe Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "17.03 LE CAFE OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1168,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 937370147,
    "accountNumber": "35302464616",
    "date": "2014-03-18",
    "amount": "-400.00",
    "text": "Atm Galleriet 2 Dnb Bank Asa 7183",
    "alfareferanse": "18.03 ATM GALLERIET 2  DNB BANK ASA      7183",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1166,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 940601361,
    "accountNumber": "35302464616",
    "date": "2014-03-17",
    "amount": "-596.00",
    "text": "H&M 839 Xhibiti Smstrandgt. Bergen",
    "alfareferanse": "15.03 H&M 839 XHIBITI SMSTRANDGT.  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1157,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944005044,
    "accountNumber": "35302464616",
    "date": "2014-03-17",
    "amount": "-52.15",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "14.03 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 941911017,
    "accountNumber": "35302464616",
    "date": "2014-03-17",
    "amount": "-218.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "15.03 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 942841848,
    "accountNumber": "35302464616",
    "date": "2014-03-17",
    "amount": "-123.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "17.03 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943824838,
    "accountNumber": "35302464616",
    "date": "2014-03-17",
    "amount": "-61.79",
    "text": "Rema Xhibition Småstrandgat Bergen",
    "alfareferanse": "15.03 REMA XHIBITION  SMÅSTRANDGAT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 650,
      "placeId": "ChIJu3S-Xaj-PEYRXW--a6TR4UA",
      "placeName": "Rema 1000 Xhibition",
      "coordinates": "60.3926938,5.3264242",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943770856,
    "accountNumber": "35302464616",
    "date": "2014-03-17",
    "amount": "-65.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "14.03 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944911509,
    "accountNumber": "35302464616",
    "date": "2014-03-14",
    "amount": "-351.00",
    "text": "Justeatno",
    "alfareferanse": "*4928 12.03 NOK 351.00 JUSTEATNO Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1169,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944212137,
    "accountNumber": "35302464616",
    "date": "2014-03-14",
    "amount": "-42.00",
    "text": "Mon Plaisir Lagunen Stor Bergen",
    "alfareferanse": "13.03 MON PLAISIR LAGUNEN STOR BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1170,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 941961974,
    "accountNumber": "35302464616",
    "date": "2014-03-13",
    "amount": "-210.00",
    "text": "Baker Brun As Damsgårdsvei Bergen",
    "alfareferanse": "13.03 BAKER BRUN AS DAMSGÅRDSVEI BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 631,
      "placeId": "ChIJUT-MYv_7PEYRbDxdWnAc-DE",
      "placeName": "Baker Brun Produksjon AS",
      "coordinates": "60.383095,5.3148769",
      "website": "http://www.bakerbrun.no/",
      "logoId": 98,
      "merchantName": "Baker Brun",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 945124292,
    "accountNumber": "35302464616",
    "date": "2014-03-12",
    "amount": "-79.00",
    "text": "Netflix",
    "alfareferanse": "*4928 10.03 NOK 79.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943704214,
    "accountNumber": "35302464616",
    "date": "2014-03-10",
    "amount": "-69.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "07.03 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943953472,
    "accountNumber": "35302464616",
    "date": "2014-03-07",
    "amount": "-55.00",
    "text": "Peking Restaura Torgalmennin Bergen",
    "alfareferanse": "06.03 PEKING RESTAURA TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 706,
      "placeId": "ChIJwUlRaqj-PEYRNkcZSvOKkq4",
      "placeName": "Peking Restaurant",
      "coordinates": "60.3930875,5.3251237",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943953665,
    "accountNumber": "35302464616",
    "date": "2014-03-07",
    "amount": "-55.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "07.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943953255,
    "accountNumber": "35302464616",
    "date": "2014-03-06",
    "amount": "-55.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "06.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 941201988,
    "accountNumber": "35302464616",
    "date": "2014-03-06",
    "amount": "-348.00",
    "text": "Cubus 879 Sundt City Bergen",
    "alfareferanse": "05.03 CUBUS 879 SUNDT CITY BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 724,
      "placeId": "ChIJg4UbMKj-PEYRyOGpKSaozac",
      "placeName": "Cubus",
      "coordinates": "60.3922867,5.3239234",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/879/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 938468997,
    "accountNumber": "35302464616",
    "date": "2014-03-05",
    "amount": "-200.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 05.03.14",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944205838,
    "accountNumber": "35302464616",
    "date": "2014-03-05",
    "amount": "-42.50",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "05.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944633739,
    "accountNumber": "35302464616",
    "date": "2014-03-05",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "05.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943952618,
    "accountNumber": "35302464616",
    "date": "2014-03-04",
    "amount": "-55.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "04.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 940628322,
    "accountNumber": "35302464616",
    "date": "2014-03-04",
    "amount": "-575.28",
    "text": "Rema Fjøsanger Straumeveien Bergen",
    "alfareferanse": "04.03 REMA FJØSANGER  STRAUMEVEIEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1023,
      "placeId": "ChIJGUyy7275PEYRu5QkMcUITww",
      "placeName": "REMA 1000",
      "coordinates": "60.3474007,5.3355204",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943702892,
    "accountNumber": "35302464616",
    "date": "2014-03-04",
    "amount": "-69.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "03.03 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 945188412,
    "accountNumber": "35302464616",
    "date": "2014-03-04",
    "amount": "-48.00",
    "text": "Q-Park As (Skidata)",
    "alfareferanse": "*4928 27.02 NOK 48.00 Q-PARK AS (SKIDATA) Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1171,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 941636542,
    "accountNumber": "35302464616",
    "date": "2014-03-04",
    "amount": "-258.00",
    "text": "H&M 839 Xhibiti Smstrandgt. Bergen",
    "alfareferanse": "03.03 H&M 839 XHIBITI SMSTRANDGT.  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1157,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 943592174,
    "accountNumber": "35302464616",
    "date": "2014-03-04",
    "amount": "-75.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "03.03 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 941194274,
    "accountNumber": "35302464616",
    "date": "2014-03-03",
    "amount": "-349.00",
    "text": "Din Sko 1116 Torgallm.8 Bergen",
    "alfareferanse": "03.03 DIN SKO 1116 TORGALLM.8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1139,
      "placeId": "ChIJSbvXPaj-PEYRjUumXB8BhtY",
      "placeName": "DinSko & ECCO",
      "coordinates": "60.3930466,5.3251284",
      "website": "https://feetfirst.no/sko/dinsko-ecco-galleriet",
      "logoId": 159,
      "merchantName": "DinSko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 945292257,
    "accountNumber": "35302464616",
    "date": "2014-03-03",
    "amount": "-7.00",
    "text": "Apple Itunes Store-Nok",
    "alfareferanse": "*4928 27.02 NOK 7.00 APPLE ITUNES STORE-NOK Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1154,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 944384371,
    "accountNumber": "35302464616",
    "date": "2014-03-03",
    "amount": "-33.51",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.03 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932866326,
    "accountNumber": "35302464616",
    "date": "2014-02-28",
    "amount": "-51.50",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "27.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933072301,
    "accountNumber": "35302464616",
    "date": "2014-02-28",
    "amount": "-40.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "28.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932907293,
    "accountNumber": "35302464616",
    "date": "2014-02-28",
    "amount": "-50.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "28.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932068433,
    "accountNumber": "35302464616",
    "date": "2014-02-28",
    "amount": "-105.00",
    "text": "Sørensen Tobakk Torgallmenn8 Bergen",
    "alfareferanse": "27.02 SØRENSEN TOBAKK TORGALLMENN8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1114,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933889087,
    "accountNumber": "35302464616",
    "date": "2014-02-28",
    "amount": "-64.00",
    "text": "Q-Park As (Skidata)",
    "alfareferanse": "*4928 25.02 NOK 64.00 Q-PARK AS (SKIDATA) Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1171,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933194613,
    "accountNumber": "35302464616",
    "date": "2014-02-28",
    "amount": "-31.50",
    "text": "Kiwi 818 Bøhmer Bøhmergaten Bergen",
    "alfareferanse": "27.02 KIWI 818 BØHMER BØHMERGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1272,
      "placeId": "ChIJCaEV7VH5PEYRVol--KyoU6E",
      "placeName": "KIWI Bøhmergaten",
      "coordinates": "60.3759,5.3346749",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bohmergaten/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 934714845,
    "accountNumber": "35302464616",
    "date": "2014-02-28",
    "amount": "-52.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         26 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 759,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931927719,
    "accountNumber": "35302464616",
    "date": "2014-02-27",
    "amount": "-118.00",
    "text": "Big Bite 301 Lagunen Rådal",
    "alfareferanse": "27.02 BIG BITE 301 LAGUNEN RÅDAL",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1436,
      "placeId": "ChIJX1fNweb5PEYRtJjyQJk0pe0",
      "placeName": "Big Bite Lagunen",
      "coordinates": "60.2968658,5.3311507",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 930009724,
    "accountNumber": "35302464616",
    "date": "2014-02-27",
    "amount": "-509.80",
    "text": "Vinmonopolet Vestkanten Bergen",
    "alfareferanse": "26.02 VINMONOPOLET VESTKANTEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Alkohol",
    "imageId": "",
    "place": {
      "id": 490,
      "placeId": "ChIJmckxwAz7PEYRMDl26adIBQo",
      "placeName": "Vinmonopolet Vestkanten",
      "coordinates": "60.3618382,5.2340804",
      "website": "https://www.vinmonopolet.no/store/170",
      "logoId": 52,
      "merchantName": "Vinmonopolet",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933332172,
    "accountNumber": "35302464616",
    "date": "2014-02-26",
    "amount": "-24.40",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "26.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932079264,
    "accountNumber": "35302464616",
    "date": "2014-02-26",
    "amount": "-104.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "26.02 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932948735,
    "accountNumber": "35302464616",
    "date": "2014-02-26",
    "amount": "-48.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "26.02 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932849788,
    "accountNumber": "35302464616",
    "date": "2014-02-25",
    "amount": "-52.90",
    "text": "Rema Fjøsanger Straumeveien Bergen",
    "alfareferanse": "25.02 REMA FJØSANGER  STRAUMEVEIEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1023,
      "placeId": "ChIJGUyy7275PEYRu5QkMcUITww",
      "placeName": "REMA 1000",
      "coordinates": "60.3474007,5.3355204",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931257133,
    "accountNumber": "35302464616",
    "date": "2014-02-25",
    "amount": "-192.00",
    "text": "A & Jc Matglede C. Sundts Gt Bergen",
    "alfareferanse": "25.02 A & JC MATGLEDE C. SUNDTS GT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 794,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933954720,
    "accountNumber": "35302464616",
    "date": "2014-02-25",
    "amount": "-32.00",
    "text": "Q-Park As (Skidata)",
    "alfareferanse": "*4928 21.02 NOK 32.00 Q-PARK AS (SKIDATA) Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1171,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932904496,
    "accountNumber": "35302464616",
    "date": "2014-02-25",
    "amount": "-50.00",
    "text": "Mcdonald's Torgalmennin Bergen",
    "alfareferanse": "24.02 MCD 018 TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 616,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933266304,
    "accountNumber": "35302464616",
    "date": "2014-02-24",
    "amount": "-28.60",
    "text": "Kiwi 868 Nesttu Osvn.3 Nesttun",
    "alfareferanse": "22.02 KIWI 868 NESTTU OSVN.3 NESTTUN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1172,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933954654,
    "accountNumber": "35302464616",
    "date": "2014-02-21",
    "amount": "-32.00",
    "text": "Q-Park As (Skidata)",
    "alfareferanse": "*4928 18.02 NOK 32.00 Q-PARK AS (SKIDATA) Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Parkering",
    "imageId": "",
    "place": {
      "id": 1171,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933047819,
    "accountNumber": "35302464616",
    "date": "2014-02-20",
    "amount": "-40.20",
    "text": "Kiwi 818 Bøhmer Bøhmergaten Bergen",
    "alfareferanse": "19.02 KIWI 818 BØHMER BØHMERGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1272,
      "placeId": "ChIJCaEV7VH5PEYRVol--KyoU6E",
      "placeName": "KIWI Bøhmergaten",
      "coordinates": "60.3759,5.3346749",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Bohmergaten/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933188634,
    "accountNumber": "35302464616",
    "date": "2014-02-20",
    "amount": "-32.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "19.02 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933336001,
    "accountNumber": "35302464616",
    "date": "2014-02-20",
    "amount": "-24.00",
    "text": "Ikea Åsane Åsane Senter Ulset",
    "alfareferanse": "19.02 IKEA ÅSANE ÅSANE SENTER ULSET",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 593,
      "placeId": "ChIJl9HIRfr9PEYRZfLhki6wVrs",
      "placeName": "IKEA Åsane",
      "coordinates": "60.4762328,5.3319204",
      "website": "https://m2.ikea.com/no/no/stores/ikea-asane-f3fa0a002a8011e7a49bf3ab0fb513ac",
      "logoId": 80,
      "merchantName": "IKEA Åsane",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932290128,
    "accountNumber": "35302464616",
    "date": "2014-02-19",
    "amount": "-90.00",
    "text": "A & Jc Matglede C. Sundts Gt Bergen",
    "alfareferanse": "18.02 A & JC MATGLEDE C. SUNDTS GT BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 794,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932610027,
    "accountNumber": "35302464616",
    "date": "2014-02-19",
    "amount": "-69.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "18.02 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933813030,
    "accountNumber": "35302464616",
    "date": "2014-02-18",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 15.02 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933388390,
    "accountNumber": "35302464616",
    "date": "2014-02-18",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "18.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933434390,
    "accountNumber": "35302464616",
    "date": "2014-02-17",
    "amount": "-16.90",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "17.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931462122,
    "accountNumber": "35302464616",
    "date": "2014-02-17",
    "amount": "-165.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "15.02 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 927879382,
    "accountNumber": "35302465310",
    "date": "2014-02-17",
    "amount": "-2600.00",
    "text": "Alektum Inkasso",
    "alfareferanse": "Nettgiro til: ALEKTUM INKASSO Betalt: 17.02.14",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Inkasso",
    "imageId": "",
    "place": {
      "id": 1173,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 929737386,
    "accountNumber": "35302464616",
    "date": "2014-02-17",
    "amount": "-759.88",
    "text": "Rema Myrholtet Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "15.02 REMA MYRHOLTET  MYRHOLTET 13 FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1135,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 930820259,
    "accountNumber": "35302464616",
    "date": "2014-02-17",
    "amount": "-256.00",
    "text": "Øyrane Pizzeria Øyrane Torg Indre Arna",
    "alfareferanse": "16.02 ØYRANE PIZZERIA ØYRANE TORG  INDRE ARNA",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1422,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932077364,
    "accountNumber": "35302464616",
    "date": "2014-02-14",
    "amount": "-104.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "13.02 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932184389,
    "accountNumber": "35302464616",
    "date": "2014-02-14",
    "amount": "-98.35",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931833913,
    "accountNumber": "35302464616",
    "date": "2014-02-13",
    "amount": "-126.46",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "13.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932327045,
    "accountNumber": "35302464616",
    "date": "2014-02-13",
    "amount": "-88.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "13.02 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 927852325,
    "accountNumber": "35302465310",
    "date": "2014-02-13",
    "amount": "-4000.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 12.02.14",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932063763,
    "accountNumber": "35302464616",
    "date": "2014-02-12",
    "amount": "-105.00",
    "text": "Sørensen Tobakk Torgallmenn8 Bergen",
    "alfareferanse": "11.02 SØRENSEN TOBAKK TORGALLMENN8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1114,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933146639,
    "accountNumber": "35302464616",
    "date": "2014-02-12",
    "amount": "-35.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "12.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933380602,
    "accountNumber": "35302464616",
    "date": "2014-02-12",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "12.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933853339,
    "accountNumber": "35302464616",
    "date": "2014-02-12",
    "amount": "-79.00",
    "text": "Netflix",
    "alfareferanse": "*4928 10.02 NOK 79.00 WWW.NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931851347,
    "accountNumber": "35302464616",
    "date": "2014-02-11",
    "amount": "-124.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "11.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932814896,
    "accountNumber": "35302464616",
    "date": "2014-02-10",
    "amount": "-55.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932876377,
    "accountNumber": "35302464616",
    "date": "2014-02-10",
    "amount": "-50.60",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "10.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933374449,
    "accountNumber": "35302464616",
    "date": "2014-02-05",
    "amount": "-20.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "05.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931187577,
    "accountNumber": "35302464616",
    "date": "2014-02-05",
    "amount": "-199.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "05.02 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933163621,
    "accountNumber": "35302464616",
    "date": "2014-02-05",
    "amount": "-34.00",
    "text": "Bergen Zoo As Galleriet Bergen",
    "alfareferanse": "04.02 BERGEN ZOO AS GALLERIET BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Dyrebutikk",
    "imageId": "",
    "place": {
      "id": 1082,
      "placeId": "ChIJSbvXPaj-PEYR2EjBArThtic",
      "placeName": "The center Zoo",
      "coordinates": "60.3929202,5.324611",
      "website": "http://sentrumzoo.com/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933797258,
    "accountNumber": "35302464616",
    "date": "2014-02-04",
    "amount": "-99.00",
    "text": "Spotify",
    "alfareferanse": "*4928 02.02 NOK 99.00 SPOTIFY SPOTIFY PREMIU Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 1044,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 930185241,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-426.00",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "31.01 H&M 854 OASEN OASEN BERGEN 5147 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 930211185,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-414.76",
    "text": "Rema Fjøsanger Straumeveien Bergen",
    "alfareferanse": "01.02 REMA FJØSANGER  STRAUMEVEIEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1023,
      "placeId": "ChIJGUyy7275PEYRu5QkMcUITww",
      "placeName": "REMA 1000",
      "coordinates": "60.3474007,5.3355204",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 926626326,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-500.00",
    "text": "Rema Fjøsanger Straumeveien Bergen",
    "alfareferanse": "31.01 REMA FJØSANGER  STRAUMEVEIEN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1023,
      "placeId": "ChIJGUyy7275PEYRu5QkMcUITww",
      "placeName": "REMA 1000",
      "coordinates": "60.3474007,5.3355204",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931043104,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-219.00",
    "text": "Solbrød Oasen Kokstaddalen Kokstad",
    "alfareferanse": "31.01 SOLBRØD OASEN KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 871,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931605893,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-149.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "03.02 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933248980,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-29.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "03.02 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931941685,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-116.10",
    "text": "Cmyk Oasen Sent Folke Bernad Fyllingsdalen",
    "alfareferanse": "31.01 CMYK OASEN SENT FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1174,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932825061,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-54.72",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 933050460,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-40.00",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "03.02 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931088437,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-211.00",
    "text": "Shell Dale Dale Garden Ndalekvam",
    "alfareferanse": "02.02 SHELL DALE DALE GARDEN NDALEKVAM",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 659,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 932481065,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-77.90",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "31.01 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931406071,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-172.00",
    "text": "Mcdonald's Rådalen Laguneveien",
    "alfareferanse": "02.02 MCD 008 RÅDALEN LAGUNEVEIEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 502,
      "placeId": "ChIJCWGKCeT5PEYRe0hnwqruMpo",
      "placeName": "McDonald's",
      "coordinates": "60.2957387,5.3267669",
      "website": "https://www.mcdonalds.com/no/nb-no.html",
      "logoId": 57,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 931364955,
    "accountNumber": "35302464616",
    "date": "2014-02-03",
    "amount": "-178.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "02.02 ESSO OASEN . FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 924827089,
    "accountNumber": "35302464616",
    "date": "2014-01-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 921773897,
    "accountNumber": "35302464616",
    "date": "2014-01-31",
    "amount": "-125.00",
    "text": "Sørensen Tobakk Torgallmenn8 Bergen",
    "alfareferanse": "31.01 SØRENSEN TOBAKK TORGALLMENN8 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1114,
      "placeId": "ChIJSbvXPaj-PEYR6T3h1ppaxKc",
      "placeName": "Sorensen Tobacco AS",
      "coordinates": "60.3932,5.3249279",
      "website": "http://tobakksorensen.no/",
      "logoId": 131,
      "merchantName": "Sorensen Tobacco AS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 924746397,
    "accountNumber": "35302464616",
    "date": "2014-01-31",
    "amount": "-22.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         11 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1136,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 916779606,
    "accountNumber": "35302464616",
    "date": "2014-01-21",
    "amount": "-1800.00",
    "text": "Atm Bergen Postk Dnb Bank Asa",
    "alfareferanse": "20.01 ATM BERGEN POSTK DNB BANK ASA      501131",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1175,
      "placeId": "ChIJiw5wxqf-PEYRtzGUtYr2r_g",
      "placeName": "DnB",
      "coordinates": "60.3954726,5.3264007",
      "website": "http://www.dnb.no/",
      "logoId": 241,
      "merchantName": "DnB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 923047763,
    "accountNumber": "35302464616",
    "date": "2014-01-17",
    "amount": "-37.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "16.01 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922468120,
    "accountNumber": "35302464616",
    "date": "2014-01-15",
    "amount": "-73.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "15.01 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 921862112,
    "accountNumber": "35302464616",
    "date": "2014-01-14",
    "amount": "-116.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "14.01 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 923830772,
    "accountNumber": "35302464616",
    "date": "2014-01-14",
    "amount": "-79.00",
    "text": "Netflix",
    "alfareferanse": "*4928 10.01 NOK 79.00 NETFLIX.COM Kurs: 1.0000",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Internett-tjenester",
    "imageId": "",
    "place": {
      "id": 648,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922421783,
    "accountNumber": "35302464616",
    "date": "2014-01-13",
    "amount": "-76.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "13.01 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922878097,
    "accountNumber": "35302464616",
    "date": "2014-01-13",
    "amount": "-48.00",
    "text": "Bit Bergen Torgalmennin Bergen",
    "alfareferanse": "11.01 BIT BERGEN TORGALMENNIN BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/utested",
    "imageId": "",
    "place": {
      "id": 1312,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 916416246,
    "accountNumber": "35302464616",
    "date": "2014-01-10",
    "amount": "-43.00",
    "text": "Solbrød Oasen Kokstaddalen Kokstad",
    "alfareferanse": "09.01 SOLBRØD OASEN KOKSTADDALEN KOKSTAD",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 871,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 921278465,
    "accountNumber": "35302464616",
    "date": "2014-01-10",
    "amount": "-179.00",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "09.01 H&M 854 OASEN OASEN BERGEN 5147 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922088635,
    "accountNumber": "35302464616",
    "date": "2014-01-10",
    "amount": "-99.00",
    "text": "Cubus 874 Oasen Senter Fyllingsdalen",
    "alfareferanse": "09.01 CUBUS 874 OASEN SENTER FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 607,
      "placeId": "ChIJoTfziMX7PEYRab_c3wl4tKY",
      "placeName": "Cubus",
      "coordinates": "60.348195,5.2920946",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/874/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922761248,
    "accountNumber": "35302464616",
    "date": "2014-01-10",
    "amount": "-54.00",
    "text": "Mcdonald's Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "09.01 MCD 041 OASEN FOLKE BERNAD FYLLINGSDALEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 487,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922729736,
    "accountNumber": "35302464616",
    "date": "2014-01-09",
    "amount": "-56.01",
    "text": "Narvesen 765 Oasen Sent Fyllingsd.",
    "alfareferanse": "09.01 NARVESEN 765 OASEN SENT FYLLINGSD.",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1096,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 924194083,
    "accountNumber": "35302464616",
    "date": "2014-01-09",
    "amount": "-200.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "09.01 SPV Fyllingsdalen Inne  5147 Fyllingsdalen",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 920951732,
    "accountNumber": "35302464616",
    "date": "2014-01-09",
    "amount": "-220.00",
    "text": "Bergen Kino Neumannsgate Bergen",
    "alfareferanse": "08.01 BERGEN KINO NEUMANNSGATE BERGEN",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 713,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 921982288,
    "accountNumber": "35302464616",
    "date": "2014-01-09",
    "amount": "-105.30",
    "text": "Evjen Dagligvar Blekenberg 6 Bergen",
    "alfareferanse": "08.01 EVJEN DAGLIGVAR BLEKENBERG 6 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1437,
      "placeId": "ChIJUZb_qVH5PEYRUZyCmOg25u0",
      "placeName": "Evjen dagligvare AS",
      "coordinates": "60.3742419,5.3321839",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922086980,
    "accountNumber": "35302464616",
    "date": "2014-01-09",
    "amount": "-99.00",
    "text": "Eplehuset Norge Markevn. 6 Bergen",
    "alfareferanse": "08.01 EPLEHUSET NORGE MARKEVN. 6 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Elektronikk",
    "imageId": "",
    "place": {
      "id": 1176,
      "placeId": "ChIJOf3HwQL8PEYRFE_FBu4mbnw",
      "placeName": "Eplehuset Bergen",
      "coordinates": "60.3932508,5.320408",
      "website": "https://eplehuset.no/",
      "logoId": 242,
      "merchantName": "Eplehuset",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 917735347,
    "accountNumber": "35302465310",
    "date": "2014-01-07",
    "amount": "-1000.00",
    "text": "Bank Norwegian",
    "alfareferanse": "Nettgiro til: BANK NORWEGIAN Betalt: 07.01.14",
    "mainCategory": "Utgifter",
    "category": "Kredittkort",
    "subCategory": "Kredittkort",
    "imageId": "",
    "place": {
      "id": 1395,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 923065369,
    "accountNumber": "35302464616",
    "date": "2014-01-06",
    "amount": "-36.00",
    "text": "Nsb Bergen Tvm Stasjonsvn5 5015 Bergen",
    "alfareferanse": "06.01 NSB BERGEN TVM  STASJONSVN5  5015 BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1133,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 923183854,
    "accountNumber": "35302464616",
    "date": "2014-01-06",
    "amount": "-29.00",
    "text": "Billettautomat Vestre Strøm Bergen",
    "alfareferanse": "06.01 BILLETTAUTOMAT  VESTRE STRØM BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Buss",
    "imageId": "",
    "place": {
      "id": 818,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 919306088,
    "accountNumber": "35302464616",
    "date": "2014-01-06",
    "amount": "-150.07",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "06.01 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 921593666,
    "accountNumber": "35302464616",
    "date": "2014-01-06",
    "amount": "-143.00",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "06.01 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922638437,
    "accountNumber": "35302464616",
    "date": "2014-01-03",
    "amount": "-61.00",
    "text": "Big Bite 308 Strandgaten Bergen",
    "alfareferanse": "03.01 BIG BITE 308 STRANDGATEN  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 911,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 922556648,
    "accountNumber": "35302464616",
    "date": "2014-01-02",
    "amount": "-67.80",
    "text": "Lerøy Mat Torgalmening Bergen",
    "alfareferanse": "02.01 LERØY MAT TORGALMENING BERGEN",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 559,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 911954312,
    "accountNumber": "35302464616",
    "date": "2013-12-31",
    "amount": "-27.00",
    "text": "Statoil Viken 5 Vikensgt. 6 Bergen",
    "alfareferanse": "31.12 STATOIL VIKEN 5 VIKENSGT. 6  BERGEN",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 1018,
      "placeId": "ChIJfUMt8lH5PEYRdLQvM0c6l18",
      "placeName": "Circle K Viken",
      "coordinates": "60.3760067,5.335271",
      "website": "http://www.circlek.no/no_NO/pg1334073611431/st1/S%C3%83%C2%B8kstasjon.html?stationid=53782&countryid=no",
      "logoId": 44,
      "merchantName": "Circle K Godvik",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 914297677,
    "accountNumber": "35302464616",
    "date": "2013-12-31",
    "amount": "-28.00",
    "text": "SMS-bank saldoforespørsel",
    "alfareferanse": "SMS-BANK FORESPØRSEL SALDO         14 TRANS(ER) TYPE 469 130",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1136,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703935803,
    "accountNumber": "35302464616",
    "date": "2012-04-17",
    "amount": "-27.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "16.04 ESSO OASEN . FYLLINGSDALEN                                                ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703376550,
    "accountNumber": "35302464616",
    "date": "2012-04-17",
    "amount": "-100.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "16.04 ESSO OASEN . FYLLINGSDALEN                                                ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703502160,
    "accountNumber": "35302464616",
    "date": "2012-04-16",
    "amount": "-83.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "15.04 ESSO OASEN . FYLLINGSDALEN                                                ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703473293,
    "accountNumber": "35302464616",
    "date": "2012-04-16",
    "amount": "-88.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "14.04 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703324106,
    "accountNumber": "35302464616",
    "date": "2012-04-13",
    "amount": "-108.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "13.04 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703024191,
    "accountNumber": "35302464616",
    "date": "2012-04-12",
    "amount": "-169.00",
    "text": "Ramsvik Frisør Ådnavn. 63 Indre Arna",
    "alfareferanse": "12.04 RAMSVIK FRISØR  ÅDNAVN. 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 1177,
      "placeId": "ChIJC6INGX__PEYR8TrvLRacPWA",
      "placeName": "Ramsvik Barber Arna",
      "coordinates": "60.4213176,5.4650282",
      "website": "http://ramsvik.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 702944859,
    "accountNumber": "35302464616",
    "date": "2012-04-12",
    "amount": "-189.00",
    "text": "Xpress Pizza Kringsjåveie Laksevåg",
    "alfareferanse": "11.04 XPRESS PIZZA KRINGSJÅVEIE LAKSEVÅG                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1178,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703863833,
    "accountNumber": "35302464616",
    "date": "2012-04-12",
    "amount": "-36.30",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "11.04 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703932169,
    "accountNumber": "35302464616",
    "date": "2012-04-11",
    "amount": "-27.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "11.04 ESSO OASEN . FYLLINGSDALEN                                                ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703977697,
    "accountNumber": "35302464616",
    "date": "2012-04-11",
    "amount": "-20.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "10.04 7 - ELEVEN 7119 OLAV KYRREST BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 702363565,
    "accountNumber": "35302464616",
    "date": "2012-04-11",
    "amount": "-425.00",
    "text": "Kicks 17 Strandgaten Bergen",
    "alfareferanse": "11.04 KICKS 17 STRANDGATEN  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 1179,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 700805995,
    "accountNumber": "35302464616",
    "date": "2012-04-11",
    "amount": "-361.00",
    "text": "Ice Norge As",
    "alfareferanse": "Nettgiro til: ICE NORGE AS Betalt: 11.04.12                                     ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 1180,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703715945,
    "accountNumber": "35302464616",
    "date": "2012-04-10",
    "amount": "-55.00",
    "text": "Facil Kebab Neumannsgt. Bergen",
    "alfareferanse": "04.04 FACIL KEBAB NEUMANNSGT.  BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1181,
      "placeId": "ChIJubG0UAL8PEYRGHDWy_tFU8c",
      "placeName": "Bergen,s shawarma",
      "coordinates": "60.3914694,5.3191779",
      "website": "https://facil-kebab-pizza.business.site/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 702280675,
    "accountNumber": "35302464616",
    "date": "2012-04-04",
    "amount": "-499.00",
    "text": "Clas Ohlson 825 Strømgaten 8 Bergen",
    "alfareferanse": "03.04 CLAS OHLSON 825 STRØMGATEN 8 BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1109,
      "placeId": "ChIJW-e2tK7-PEYR6BuSJ3XwmpQ",
      "placeName": "Clas Ohlson",
      "coordinates": "60.3894215,5.3327617",
      "website": "http://www.clasohlson.com/no/",
      "logoId": 215,
      "merchantName": "Clas Ohlson",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703502858,
    "accountNumber": "35302464616",
    "date": "2012-04-04",
    "amount": "-82.80",
    "text": "Deli De Luca Torggt 5 Bergen",
    "alfareferanse": "04.04 DELI DE LUCA TORGGT 5 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1439,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 699405207,
    "accountNumber": "35302464616",
    "date": "2012-04-03",
    "amount": "-210.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "02.04 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 702614102,
    "accountNumber": "35302464616",
    "date": "2012-04-03",
    "amount": "-297.00",
    "text": "Princess Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "03.04 PRINCESS ØYRANE ØYRANE TORG  INDRE ARNA                                   ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1182,
      "placeId": "ChIJC6INGX__PEYRZq3zFVrVKKY",
      "placeName": "Princess avd Øyrane Torg",
      "coordinates": "60.4210261,5.4651529",
      "website": "https://www.princessbutikken.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 703108897,
    "accountNumber": "35302464616",
    "date": "2012-04-03",
    "amount": "-149.39",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "03.04 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 702664464,
    "accountNumber": "35302464616",
    "date": "2012-04-02",
    "amount": "-274.00",
    "text": "Bjørklund Berge Strømgt. 8 Bergen",
    "alfareferanse": "31.03 BJØRKLUND BERGE STRØMGT. 8 BERGEN                                         ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Gullsmed",
    "imageId": "",
    "place": {
      "id": 1183,
      "placeId": "ChIJW-e2tK7-PEYRfuHpKeIaEyU",
      "placeName": "Bjørklund Bergen",
      "coordinates": "60.3893065,5.332543",
      "website": "http://bjorklund.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 702880034,
    "accountNumber": "35302464616",
    "date": "2012-04-02",
    "amount": "-200.00",
    "text": "Blaze As Øyrane Torg Tlf 50",
    "alfareferanse": "31.03 BLAZE AS ØYRANE TORG  TLF 55950450                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 981,
      "placeId": "ChIJT48wG3__PEYRGqErJ2soRGQ",
      "placeName": "Blace",
      "coordinates": "60.4212725,5.4656685",
      "website": "http://www.oyrane-torg.no/2011/klaer-sko-parfyme/blace/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 700706293,
    "accountNumber": "35302464616",
    "date": "2012-04-02",
    "amount": "-5000.00",
    "text": "Autoriserte Tra",
    "alfareferanse": "Nettgiro til: AUTORISERTE TRA Betalt: 01.04.12                                  ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Kjøreskole",
    "imageId": "",
    "place": {
      "id": 1088,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 694324362,
    "accountNumber": "35302464616",
    "date": "2012-03-31",
    "amount": "-215.00",
    "text": "Blaze As Øyrane Torg Tlf 50",
    "alfareferanse": "29.03 BLAZE AS ØYRANE TORG  TLF 55950450                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 981,
      "placeId": "ChIJT48wG3__PEYRGqErJ2soRGQ",
      "placeName": "Blace",
      "coordinates": "60.4212725,5.4656685",
      "website": "http://www.oyrane-torg.no/2011/klaer-sko-parfyme/blace/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 694100742,
    "accountNumber": "35302464616",
    "date": "2012-03-31",
    "amount": "-258.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "29.03 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 693610972,
    "accountNumber": "35302464616",
    "date": "2012-03-31",
    "amount": "-398.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "29.03 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696252567,
    "accountNumber": "35302464616",
    "date": "2012-03-31",
    "amount": "-29.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "30.03 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696399788,
    "accountNumber": "35302464616",
    "date": "2012-03-31",
    "amount": "-17.50",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "29.03 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696142921,
    "accountNumber": "35302464616",
    "date": "2012-03-31",
    "amount": "-36.50",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "29.03 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 697683823,
    "accountNumber": "35302464616",
    "date": "2012-03-31",
    "amount": "-21.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           3 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 693443207,
    "accountNumber": "35302464616",
    "date": "2012-03-29",
    "amount": "-474.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "28.03 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 697102178,
    "accountNumber": "35302464616",
    "date": "2012-03-29",
    "amount": "-200.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "29.03 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 690761255,
    "accountNumber": "35302464616",
    "date": "2012-03-29",
    "amount": "-800.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "28.03 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696399625,
    "accountNumber": "35302464616",
    "date": "2012-03-28",
    "amount": "-17.50",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "28.03 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696181230,
    "accountNumber": "35302464616",
    "date": "2012-03-28",
    "amount": "-34.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "27.03 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695819359,
    "accountNumber": "35302464616",
    "date": "2012-03-26",
    "amount": "-59.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "24.03 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 694675085,
    "accountNumber": "35302464616",
    "date": "2012-03-23",
    "amount": "-165.18",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "22.03 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696050633,
    "accountNumber": "35302464616",
    "date": "2012-03-21",
    "amount": "-42.70",
    "text": "Rema 1000 Småstrandgt. Bergen",
    "alfareferanse": "20.03 REMA 1000 SMÅSTRANDGT. BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1188,
      "placeId": "ChIJ2wdZ8z_5PEYRo4PkNhiJwUU",
      "placeName": "REMA 1000",
      "coordinates": "60.3601238,5.3521353",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 694460099,
    "accountNumber": "35302464616",
    "date": "2012-03-20",
    "amount": "-198.00",
    "text": "Dressmann 199 Strømsgaten Bergen",
    "alfareferanse": "20.03 DRESSMANN 199 STRØMSGATEN  BERGEN                                         ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1189,
      "placeId": "ChIJizLIsa7-PEYRXz7z_GY5ows",
      "placeName": "Dressmann Bystasjonen",
      "coordinates": "60.3893065,5.332543",
      "website": "https://dressmann.com/no/",
      "logoId": 247,
      "merchantName": "Dressmann",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 690810198,
    "accountNumber": "35302464616",
    "date": "2012-03-20",
    "amount": "-200.00",
    "text": "Bnp 7-Eleven Torggt. 5014 Bergen",
    "alfareferanse": "19.03 BNP 7-Eleven Torggt.    5014 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1441,
      "placeId": "ChIJ0Zo-fwL8PEYRJia7ZqcGLI8",
      "placeName": "7-Eleven",
      "coordinates": "60.3908829,5.3223338",
      "website": "http://7-eleven.no/",
      "logoId": 207,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 694980393,
    "accountNumber": "35302464616",
    "date": "2012-03-20",
    "amount": "-129.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "19.03 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695938366,
    "accountNumber": "35302464616",
    "date": "2012-03-20",
    "amount": "-50.00",
    "text": "Vero Moda/Only Torvalm. 10 Bergen",
    "alfareferanse": "19.03 VERO MODA/ONLY  TORVALM. 10  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1326,
      "placeId": "ChIJH9t0PKj-PEYRRB3ILnroPZk",
      "placeName": "VERO MODA",
      "coordinates": "60.3926438,5.3244953",
      "website": "http://www.veromoda.no/",
      "logoId": 275,
      "merchantName": "VERO MODA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 693748162,
    "accountNumber": "35302464616",
    "date": "2012-03-19",
    "amount": "-348.00",
    "text": "Vita Strandgata 2 Bergen",
    "alfareferanse": "17.03 1603 VITA STRANDGATA 2 BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1190,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 693989685,
    "accountNumber": "35302464616",
    "date": "2012-03-19",
    "amount": "-285.66",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "16.03 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 693851456,
    "accountNumber": "35302464616",
    "date": "2012-03-19",
    "amount": "-314.58",
    "text": "Rema 1000 Småstrandgt. Bergen",
    "alfareferanse": "17.03 REMA 1000 SMÅSTRANDGT. BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1188,
      "placeId": "ChIJ2wdZ8z_5PEYRo4PkNhiJwUU",
      "placeName": "REMA 1000",
      "coordinates": "60.3601238,5.3521353",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695346904,
    "accountNumber": "35302464616",
    "date": "2012-03-19",
    "amount": "-95.00",
    "text": "Kicks 17 Strandgaten Bergen",
    "alfareferanse": "17.03 KICKS 17 STRANDGATEN  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 1179,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695458219,
    "accountNumber": "35302464616",
    "date": "2012-03-19",
    "amount": "-85.70",
    "text": "Nille 79 Bergen Strmsgata 8 Bergen",
    "alfareferanse": "16.03 NILLE 79 BERGEN STRMSGATA 8  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1192,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695805922,
    "accountNumber": "35302464616",
    "date": "2012-03-16",
    "amount": "-59.50",
    "text": "Lerøy Mat As Torgalm. Bergen",
    "alfareferanse": "16.03 LERØY MAT AS TORGALM. BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1193,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 693481627,
    "accountNumber": "35302464616",
    "date": "2012-03-16",
    "amount": "-450.00",
    "text": "Kicks 17 Strandgaten Bergen",
    "alfareferanse": "16.03 KICKS 17 STRANDGATEN  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kvinnebutikk",
    "imageId": "",
    "place": {
      "id": 1179,
      "placeId": "ChIJGf0PrAL8PEYRu-gDlvNksV4",
      "placeName": "KICKS",
      "coordinates": "60.3942891,5.3226011",
      "website": "https://www.kicks.no/butikker/bergen-kloverhuset/?utm_source=google&utm_medium=organic&utm_campaign=Google+Maps",
      "logoId": 173,
      "merchantName": "KICKS",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 694178035,
    "accountNumber": "35302464616",
    "date": "2012-03-16",
    "amount": "-242.80",
    "text": "Rema 1000 Småstrandgt. Bergen",
    "alfareferanse": "15.03 REMA 1000 SMÅSTRANDGT. BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1188,
      "placeId": "ChIJ2wdZ8z_5PEYRo4PkNhiJwUU",
      "placeName": "REMA 1000",
      "coordinates": "60.3601238,5.3521353",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 691738029,
    "accountNumber": "35302464616",
    "date": "2012-03-15",
    "amount": "-674.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 15.03.12                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696161370,
    "accountNumber": "35302464616",
    "date": "2012-03-14",
    "amount": "-35.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "13.03 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696289742,
    "accountNumber": "35302464616",
    "date": "2012-03-13",
    "amount": "-26.10",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "13.03 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 693814122,
    "accountNumber": "35302464616",
    "date": "2012-03-13",
    "amount": "-326.00",
    "text": "Cafe Opus As 24 Strømsgt. 8 Bergen",
    "alfareferanse": "13.03 CAFE OPUS AS 24 STRØMSGT. 8  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1108,
      "placeId": "ChIJJ_9Dt67-PEYRjvrSOiE3VcM",
      "placeName": "Cafe Opus",
      "coordinates": "60.389375,5.3320505",
      "website": "http://www.cafeopus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 690746688,
    "accountNumber": "35302464616",
    "date": "2012-03-12",
    "amount": "-1000.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "12.03 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696049632,
    "accountNumber": "35302464616",
    "date": "2012-03-12",
    "amount": "-42.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "12.03 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696178299,
    "accountNumber": "35302464616",
    "date": "2012-03-12",
    "amount": "-34.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "10.03 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696337600,
    "accountNumber": "35302464616",
    "date": "2012-03-12",
    "amount": "-22.40",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "12.03 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696297443,
    "accountNumber": "35302464616",
    "date": "2012-03-08",
    "amount": "-25.50",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "08.03 ESSO OASEN . FYLLINGSDALEN                                                ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695513505,
    "accountNumber": "35302464616",
    "date": "2012-03-07",
    "amount": "-80.60",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "06.03 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695370971,
    "accountNumber": "35302464616",
    "date": "2012-03-07",
    "amount": "-92.50",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "06.03 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695810653,
    "accountNumber": "35302464616",
    "date": "2012-03-06",
    "amount": "-59.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "06.03 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695929135,
    "accountNumber": "35302464616",
    "date": "2012-03-06",
    "amount": "-50.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "06.03 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 696158081,
    "accountNumber": "35302464616",
    "date": "2012-03-06",
    "amount": "-35.00",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "06.03 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 690362272,
    "accountNumber": "35302464616",
    "date": "2012-03-05",
    "amount": "-130.00",
    "text": "Shell Boenes Bo Ejanesv Eboenes",
    "alfareferanse": "04.03 SHELL BOENES BO EJANESV EBOENES                                           ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 660,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695539774,
    "accountNumber": "35302464616",
    "date": "2012-03-05",
    "amount": "-79.00",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "02.03 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 695895003,
    "accountNumber": "35302464616",
    "date": "2012-03-05",
    "amount": "-53.15",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "03.03 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 697072940,
    "accountNumber": "35302464616",
    "date": "2012-03-02",
    "amount": "-400.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "02.03 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686436913,
    "accountNumber": "35302464616",
    "date": "2012-03-01",
    "amount": "-80.20",
    "text": "Rema 1000 Fylli Myrholtet 13 Fyllingsdalen",
    "alfareferanse": "01.03 REMA 1000 FYLLI MYRHOLTET 13 FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1194,
      "placeId": "ChIJt73g87b7PEYRH70kJIW8Z-8",
      "placeName": "REMA 1000",
      "coordinates": "60.3457831,5.2715766",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 684362147,
    "accountNumber": "35302464616",
    "date": "2012-03-01",
    "amount": "-482.38",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "29.02 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687275458,
    "accountNumber": "35302464616",
    "date": "2012-02-29",
    "amount": "-17.50",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "29.02 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 688565072,
    "accountNumber": "35302464616",
    "date": "2012-02-29",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 683970919,
    "accountNumber": "35302464616",
    "date": "2012-02-29",
    "amount": "-942.00",
    "text": "Kremmerhuset 23 Strømgt. 8 Bergen",
    "alfareferanse": "28.02 KREMMERHUSET 23 STRØMGT. 8 BERGEN                                         ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1195,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687061918,
    "accountNumber": "35302464616",
    "date": "2012-02-29",
    "amount": "-35.00",
    "text": "Big Bite Bergen Nicolaismanu Bergen",
    "alfareferanse": "28.02 BIG BITE BERGEN NICOLAISMANU BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1196,
      "placeId": "ChIJW-e2tK7-PEYRuFvWjVsw_nY",
      "placeName": "Big Bite",
      "coordinates": "60.3893065,5.332543",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 681791920,
    "accountNumber": "35302464616",
    "date": "2012-02-29",
    "amount": "-500.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "28.02 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686368877,
    "accountNumber": "35302464616",
    "date": "2012-02-27",
    "amount": "-87.10",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "25.02 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686749374,
    "accountNumber": "35302464616",
    "date": "2012-02-21",
    "amount": "-57.40",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "20.02 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685262216,
    "accountNumber": "35302464616",
    "date": "2012-02-20",
    "amount": "-214.33",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "18.02 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687071264,
    "accountNumber": "35302464616",
    "date": "2012-02-20",
    "amount": "-34.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "20.02 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687890260,
    "accountNumber": "35302464616",
    "date": "2012-02-20",
    "amount": "-900.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "18.02 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687274963,
    "accountNumber": "35302464616",
    "date": "2012-02-20",
    "amount": "-17.50",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "20.02 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685957243,
    "accountNumber": "35302464616",
    "date": "2012-02-20",
    "amount": "-123.90",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "18.02 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 684933348,
    "accountNumber": "35302464616",
    "date": "2012-02-20",
    "amount": "-283.00",
    "text": "Kremmerhuset 23 Strømgt. 8 Bergen",
    "alfareferanse": "17.02 KREMMERHUSET 23 STRØMGT. 8 BERGEN                                         ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1195,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687136121,
    "accountNumber": "35302464616",
    "date": "2012-02-20",
    "amount": "-29.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "20.02 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686579501,
    "accountNumber": "35302464616",
    "date": "2012-02-17",
    "amount": "-69.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "17.02 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687257710,
    "accountNumber": "35302464616",
    "date": "2012-02-16",
    "amount": "-19.60",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "16.02 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687006152,
    "accountNumber": "35302464616",
    "date": "2012-02-16",
    "amount": "-39.00",
    "text": "Big Bite Bergen Nicolaismanu Bergen",
    "alfareferanse": "16.02 BIG BITE BERGEN NICOLAISMANU BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1196,
      "placeId": "ChIJW-e2tK7-PEYRuFvWjVsw_nY",
      "placeName": "Big Bite",
      "coordinates": "60.3893065,5.332543",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 684590960,
    "accountNumber": "35302464616",
    "date": "2012-02-16",
    "amount": "-378.50",
    "text": "Princess Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.02 PRINCESS OASEN  FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1197,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685440735,
    "accountNumber": "35302464616",
    "date": "2012-02-16",
    "amount": "-190.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "16.02 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687323825,
    "accountNumber": "35302464616",
    "date": "2012-02-16",
    "amount": "-10.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "16.02 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685375459,
    "accountNumber": "35302464616",
    "date": "2012-02-15",
    "amount": "-199.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "15.02 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686657568,
    "accountNumber": "35302464616",
    "date": "2012-02-15",
    "amount": "-64.00",
    "text": "Big Bite Bergen Nicolaismanu Bergen",
    "alfareferanse": "14.02 BIG BITE BERGEN NICOLAISMANU BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1196,
      "placeId": "ChIJW-e2tK7-PEYRuFvWjVsw_nY",
      "placeName": "Big Bite",
      "coordinates": "60.3893065,5.332543",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686942612,
    "accountNumber": "35302464616",
    "date": "2012-02-15",
    "amount": "-43.40",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "15.02 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685193322,
    "accountNumber": "35302464616",
    "date": "2012-02-14",
    "amount": "-227.00",
    "text": "Princess Øyrane Øyrane Torg Indre Arna",
    "alfareferanse": "14.02 PRINCESS ØYRANE ØYRANE TORG  INDRE ARNA                                   ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1182,
      "placeId": "ChIJC6INGX__PEYRZq3zFVrVKKY",
      "placeName": "Princess avd Øyrane Torg",
      "coordinates": "60.4210261,5.4651529",
      "website": "https://www.princessbutikken.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687961176,
    "accountNumber": "35302464616",
    "date": "2012-02-14",
    "amount": "-100.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "13.02 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 682820136,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-299.00",
    "text": "Ice Norge As",
    "alfareferanse": "Nettgiro til: ICE NORGE AS Betalt: 12.02.12                                     ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 1180,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 682797577,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-361.00",
    "text": "Ice Norge As",
    "alfareferanse": "Nettgiro til: ICE NORGE AS Betalt: 12.02.12                                     ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 1180,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686602889,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-68.60",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "11.02 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 681778165,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-600.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "11.02 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687070337,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-34.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "11.02 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686463728,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-79.00",
    "text": "B.Young Øyrane Torg Bergen",
    "alfareferanse": "11.02 B.YOUNG ØYRANE TORG  BERGEN                                               ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1429,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus Øyrane Torg",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685460105,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-187.00",
    "text": "Parfymell Ådnavegen 63 Indre Arna",
    "alfareferanse": "11.02 12228 PARFYMELL ÅDNAVEGEN 63 INDRE ARNA                                   ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 1319,
      "placeId": "ChIJC6INGX__PEYR4WaVQugCpv0",
      "placeName": "Parfymelle",
      "coordinates": "60.4211702,5.4651545",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 687079785,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-33.00",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "11.02 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 681280759,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-515.50",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "11.02 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686880249,
    "accountNumber": "35302464616",
    "date": "2012-02-13",
    "amount": "-48.50",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "13.02 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686807880,
    "accountNumber": "35302464616",
    "date": "2012-02-10",
    "amount": "-52.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "10.02 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686212875,
    "accountNumber": "35302464616",
    "date": "2012-02-10",
    "amount": "-99.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "10.02 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686893351,
    "accountNumber": "35302464616",
    "date": "2012-02-09",
    "amount": "-47.30",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "08.02 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686824001,
    "accountNumber": "35302464616",
    "date": "2012-02-07",
    "amount": "-51.00",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "07.02 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 684428926,
    "accountNumber": "35302464616",
    "date": "2012-02-06",
    "amount": "-445.12",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "03.02 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685280467,
    "accountNumber": "35302464616",
    "date": "2012-02-06",
    "amount": "-210.20",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "04.02 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 686852070,
    "accountNumber": "35302464616",
    "date": "2012-02-02",
    "amount": "-49.99",
    "text": "B.Young Øyrane Torg Bergen",
    "alfareferanse": "02.02 B.YOUNG ØYRANE TORG  BERGEN                                               ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1429,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus Øyrane Torg",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685858664,
    "accountNumber": "35302464616",
    "date": "2012-02-02",
    "amount": "-135.16",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "01.02 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 685307467,
    "accountNumber": "35302464616",
    "date": "2012-02-02",
    "amount": "-205.85",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "02.02 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677538975,
    "accountNumber": "35302464616",
    "date": "2012-02-01",
    "amount": "-68.90",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "31.01 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 675505927,
    "accountNumber": "35302464616",
    "date": "2012-01-31",
    "amount": "-352.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "30.01 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677824317,
    "accountNumber": "35302464616",
    "date": "2012-01-31",
    "amount": "-49.00",
    "text": "Wallendahl Øyrane Torg Indre Arne",
    "alfareferanse": "30.01 WALLENDAHL ØYRANE TORG  INDRE ARNE                                        ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1442,
      "placeId": "ChIJjX0SGX__PEYRMwt7_zR7KQo",
      "placeName": "Wallendahl Arna",
      "coordinates": "60.4213099,5.465029",
      "website": "http://www.wallendahl.no/",
      "logoId": 248,
      "merchantName": "Wallendahl",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 676346258,
    "accountNumber": "35302464616",
    "date": "2012-01-31",
    "amount": "-188.00",
    "text": "Naturkost As Øyrane Torg Indre Arna",
    "alfareferanse": "30.01 NATURKOST AS ØYRANE TORG  INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1198,
      "placeId": "ChIJC3CXGn__PEYRQLgn5aGQCnI",
      "placeName": "Kinsarvik Naturkost",
      "coordinates": "60.4214664,5.4656163",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 676174907,
    "accountNumber": "35302464616",
    "date": "2012-01-31",
    "amount": "-208.50",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "30.01 H&M 854 OASEN OASEN BERGEN 5147 BERGEN                                    ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 679592661,
    "accountNumber": "35302464616",
    "date": "2012-01-31",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677347625,
    "accountNumber": "35302464616",
    "date": "2012-01-31",
    "amount": "-82.30",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "30.01 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 671924587,
    "accountNumber": "35302464616",
    "date": "2012-01-31",
    "amount": "-37.50",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "30.01 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678973037,
    "accountNumber": "35302464616",
    "date": "2012-01-30",
    "amount": "-200.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "30.01 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678230971,
    "accountNumber": "35302464616",
    "date": "2012-01-23",
    "amount": "-19.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "21.01 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678028293,
    "accountNumber": "35302464616",
    "date": "2012-01-20",
    "amount": "-34.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "20.01 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677020364,
    "accountNumber": "35302464616",
    "date": "2012-01-19",
    "amount": "-108.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "19.01 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 675220778,
    "accountNumber": "35302464616",
    "date": "2012-01-19",
    "amount": "-465.00",
    "text": "Arna Libr/Elitf Ådnavn. 63 Indre Arna",
    "alfareferanse": "19.01 ARNA LIBR/ELITF ÅDNAVN. 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1443,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678959833,
    "accountNumber": "35302464616",
    "date": "2012-01-18",
    "amount": "-300.00",
    "text": "Spv Jernbanen Bergen 5020 Bergen",
    "alfareferanse": "18.01 SPV Jernbanen Bergen    5020 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1199,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 675150399,
    "accountNumber": "35302464616",
    "date": "2012-01-17",
    "amount": "-500.00",
    "text": "Brun Og Blid Ådnavegen 63 Indre Arna",
    "alfareferanse": "17.01 BRUN OG BLID ÅDNAVEGEN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 633,
      "placeId": "ChIJyXVeGn__PEYRxVXCadP7qTg",
      "placeName": "Brown and happy",
      "coordinates": "60.421339,5.4654701",
      "website": "https://brunogblid.no/",
      "logoId": 95,
      "merchantName": "Brun og blid",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677871030,
    "accountNumber": "35302464616",
    "date": "2012-01-13",
    "amount": "-45.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "12.01 7 - ELEVEN 7119 OLAV KYRREST BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 672381046,
    "accountNumber": "35302464616",
    "date": "2012-01-13",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "13.01 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678222780,
    "accountNumber": "35302464616",
    "date": "2012-01-13",
    "amount": "-19.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "13.01 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678009654,
    "accountNumber": "35302464616",
    "date": "2012-01-13",
    "amount": "-35.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "12.01 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 675951215,
    "accountNumber": "35302464616",
    "date": "2012-01-12",
    "amount": "-249.00",
    "text": "Arna Libr/Elitf Ådnavn. 63 Indre Arna",
    "alfareferanse": "12.01 ARNA LIBR/ELITF ÅDNAVN. 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1443,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678091770,
    "accountNumber": "35302464616",
    "date": "2012-01-12",
    "amount": "-29.00",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "12.01 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677785264,
    "accountNumber": "35302464616",
    "date": "2012-01-12",
    "amount": "-50.00",
    "text": "Brun Og Blid Ådnavegen 63 Indre Arna",
    "alfareferanse": "12.01 BRUN OG BLID ÅDNAVEGEN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 633,
      "placeId": "ChIJyXVeGn__PEYRxVXCadP7qTg",
      "placeName": "Brown and happy",
      "coordinates": "60.421339,5.4654701",
      "website": "https://brunogblid.no/",
      "logoId": 95,
      "merchantName": "Brun og blid",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 676281179,
    "accountNumber": "35302464616",
    "date": "2012-01-12",
    "amount": "-198.00",
    "text": "Nille 298 F.Bernad.V52 Fyllingsdalen",
    "alfareferanse": "11.01 NILLE 298 F.BERNAD.V52 FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 639,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677478168,
    "accountNumber": "35302464616",
    "date": "2012-01-12",
    "amount": "-72.42",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "11.01 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677566004,
    "accountNumber": "35302464616",
    "date": "2012-01-10",
    "amount": "-66.40",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "10.01 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677551486,
    "accountNumber": "35302464616",
    "date": "2012-01-10",
    "amount": "-67.98",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "10.01 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 675533907,
    "accountNumber": "35302464616",
    "date": "2012-01-10",
    "amount": "-347.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "10.01 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 672321143,
    "accountNumber": "35302464616",
    "date": "2012-01-04",
    "amount": "-1000.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "03.01 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 677384318,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-79.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "31.12 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 671775137,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-749.00",
    "text": "Clas Ohlson 825 Strømgaten 8 Bergen",
    "alfareferanse": "02.01 CLAS OHLSON 825 STRØMGATEN 8 BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1109,
      "placeId": "ChIJW-e2tK7-PEYR6BuSJ3XwmpQ",
      "placeName": "Clas Ohlson",
      "coordinates": "60.3894215,5.3327617",
      "website": "http://www.clasohlson.com/no/",
      "logoId": 215,
      "merchantName": "Clas Ohlson",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678307581,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-7.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "02.01 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678227902,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-19.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "31.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678307197,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-7.50",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "30.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 675288292,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-431.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "02.01 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 673489203,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-383.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 02.01.12                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 678309893,
    "accountNumber": "35302464616",
    "date": "2012-01-02",
    "amount": "-5.40",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "02.01 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667953025,
    "accountNumber": "35302464616",
    "date": "2011-12-31",
    "amount": "-28.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "29.12 7 - ELEVEN 7119 OLAV KYRREST BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 670067382,
    "accountNumber": "35302464616",
    "date": "2011-12-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666447425,
    "accountNumber": "35302464616",
    "date": "2011-12-29",
    "amount": "-154.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "29.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 668074517,
    "accountNumber": "35302464616",
    "date": "2011-12-29",
    "amount": "-16.90",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "28.12 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667830682,
    "accountNumber": "35302464616",
    "date": "2011-12-29",
    "amount": "-39.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "29.12 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667807477,
    "accountNumber": "35302464616",
    "date": "2011-12-28",
    "amount": "-40.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "28.12 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667873577,
    "accountNumber": "35302464616",
    "date": "2011-12-28",
    "amount": "-35.00",
    "text": "Hjellekonditor Øyrane Torg Indre Arna",
    "alfareferanse": "28.12 HJELLEKONDITOR  ØYRANE TORG  INDRE ARNA                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1444,
      "placeId": "ChIJL_uqG3__PEYR--j1PapIv2M",
      "placeName": "Telenor shop Øyrane Torg",
      "coordinates": "60.4213703,5.4651007",
      "website": "http://www.telenor.no/telenorbutikken",
      "logoId": 262,
      "merchantName": "Telenor shop Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667807676,
    "accountNumber": "35302464616",
    "date": "2011-12-28",
    "amount": "-40.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "27.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667750336,
    "accountNumber": "35302464616",
    "date": "2011-12-27",
    "amount": "-45.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "23.12 7 - ELEVEN 7119 OLAV KYRREST BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 668073965,
    "accountNumber": "35302464616",
    "date": "2011-12-23",
    "amount": "-16.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "23.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665925976,
    "accountNumber": "35302464616",
    "date": "2011-12-23",
    "amount": "-217.80",
    "text": "Nille 120 Ådnavegen 63 Indre Arna",
    "alfareferanse": "23.12 NILLE 120 ÅDNAVEGEN 63 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1430,
      "placeId": "ChIJC3CXGn__PEYReA-pLrPrA0U",
      "placeName": "nille",
      "coordinates": "60.4214664,5.4656163",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 662939042,
    "accountNumber": "35302464616",
    "date": "2011-12-23",
    "amount": "-226.66",
    "text": "Ice Norge As",
    "alfareferanse": "Nettgiro til: ICE NORGE AS Betalt: 23.12.11                                     ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 1180,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 668016161,
    "accountNumber": "35302464616",
    "date": "2011-12-22",
    "amount": "-22.43",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "21.12 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667268419,
    "accountNumber": "35302464616",
    "date": "2011-12-22",
    "amount": "-80.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "21.12 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667734759,
    "accountNumber": "35302464616",
    "date": "2011-12-22",
    "amount": "-45.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "22.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667992878,
    "accountNumber": "35302464616",
    "date": "2011-12-21",
    "amount": "-25.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "21.12 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665358328,
    "accountNumber": "35302464616",
    "date": "2011-12-21",
    "amount": "-316.00",
    "text": "Rema 1000 Dag Hammersk Fyllingsdalen",
    "alfareferanse": "20.12 REMA 1000 DAG HAMMERSK FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1201,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 664963619,
    "accountNumber": "35302464616",
    "date": "2011-12-20",
    "amount": "-415.00",
    "text": "China Restauran Torgallmenni Bergen",
    "alfareferanse": "20.12 CHINA RESTAURAN TORGALLMENNI BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1202,
      "placeId": "ChIJd1tuGqj-PEYRrOMheQejVMk",
      "placeName": "China Palace Restaurant",
      "coordinates": "60.3935131,5.3241582",
      "website": "http://chinapalacerestaurant.no/?utm_source=GMBlisting&utm_medium=organic",
      "logoId": 39,
      "merchantName": "China Palace Restaurant",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667848504,
    "accountNumber": "35302464616",
    "date": "2011-12-20",
    "amount": "-37.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "19.12 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667734699,
    "accountNumber": "35302464616",
    "date": "2011-12-20",
    "amount": "-45.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "19.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667734703,
    "accountNumber": "35302464616",
    "date": "2011-12-20",
    "amount": "-45.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "20.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667679408,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-49.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "19.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665844447,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-230.00",
    "text": "Vita Folkebernado Fyllingsdalen",
    "alfareferanse": "18.12 1606 VITA FOLKEBERNADO FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 565,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665553950,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-283.46",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "16.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 664682053,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-525.01",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "17.12 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666210956,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-184.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "17.12 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666275404,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-177.00",
    "text": "Wallendahl. Strandgt. 17 Bergen",
    "alfareferanse": "16.12 WALLENDAHL. STRANDGT. 17 BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1203,
      "placeId": "ChIJ--btsgL8PEYRjUCJ8kWu8Fs",
      "placeName": "Wallendahl",
      "coordinates": "60.3945761,5.3222859",
      "website": "http://wallendahl.no/",
      "logoId": 248,
      "merchantName": "Wallendahl",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666986693,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-100.00",
    "text": "Mcdonalds Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.12 MCDONALDS OASEN FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1204,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667346244,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-75.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "17.12 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665996123,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-205.19",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "17.12 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 664549991,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-599.00",
    "text": "Cmyk Oasen Sent Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.12 CMYK OASEN SENT FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1174,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665677017,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-258.00",
    "text": "Cmyk Oasen Sent Folke Bernad Fyllingsdalen",
    "alfareferanse": "16.12 CMYK OASEN SENT FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1174,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667825962,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-39.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "18.12 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667760876,
    "accountNumber": "35302464616",
    "date": "2011-12-19",
    "amount": "-44.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "17.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666864112,
    "accountNumber": "35302464616",
    "date": "2011-12-16",
    "amount": "-112.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "15.12 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 664541232,
    "accountNumber": "35302464616",
    "date": "2011-12-16",
    "amount": "-599.90",
    "text": "Playcom Oasen Fyllingsdalen",
    "alfareferanse": "16.12 PLAYCOM OASEN FYLLINGSDALEN                                               ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Spill/musikk",
    "imageId": "",
    "place": {
      "id": 1205,
      "placeId": "ChIJp13-g8_7PEYRa7Z1LDOsHRI",
      "placeName": "Oasen Senter",
      "coordinates": "60.3489856,5.2909136",
      "website": "http://www.oasen-senter.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 668741290,
    "accountNumber": "35302464616",
    "date": "2011-12-16",
    "amount": "-500.00",
    "text": "Spv Fyllingsdalen Ute 5147 Fyllingsdalen",
    "alfareferanse": "16.12 SPV Fyllingsdalen ute   5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1206,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665151699,
    "accountNumber": "35302464616",
    "date": "2011-12-16",
    "amount": "-367.50",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "16.12 H&M 854 OASEN OASEN BERGEN 5147 BERGEN                                    ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666984774,
    "accountNumber": "35302464616",
    "date": "2011-12-16",
    "amount": "-100.00",
    "text": "Solbrød Oasen Kokstaddalen Kokstad",
    "alfareferanse": "16.12 SOLBRØD OASEN KOKSTADDALEN KOKSTAD                                        ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 871,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666022752,
    "accountNumber": "35302464616",
    "date": "2011-12-16",
    "amount": "-200.49",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "15.12 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667153387,
    "accountNumber": "35302464616",
    "date": "2011-12-15",
    "amount": "-89.90",
    "text": "Lerøy Mat As Torgalm. Bergen",
    "alfareferanse": "14.12 LERØY MAT AS TORGALM. BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1193,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666135163,
    "accountNumber": "35302464616",
    "date": "2011-12-15",
    "amount": "-196.00",
    "text": "Wallendahl Øyrane Torg Indre Arne",
    "alfareferanse": "14.12 WALLENDAHL ØYRANE TORG  INDRE ARNE                                        ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1442,
      "placeId": "ChIJjX0SGX__PEYRMwt7_zR7KQo",
      "placeName": "Wallendahl Arna",
      "coordinates": "60.4213099,5.465029",
      "website": "http://www.wallendahl.no/",
      "logoId": 248,
      "merchantName": "Wallendahl",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 664429141,
    "accountNumber": "35302464616",
    "date": "2011-12-15",
    "amount": "-687.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "14.12 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 663969852,
    "accountNumber": "35302464616",
    "date": "2011-12-14",
    "amount": "-1780.00",
    "text": "Arna Frisørsalo Ådnavn. 63 Indre Arna",
    "alfareferanse": "13.12 ARNA FRISØRSALO ÅDNAVN. 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 1207,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 661955423,
    "accountNumber": "35302464616",
    "date": "2011-12-13",
    "amount": "-400.00",
    "text": "Nordea Bergen Bystasjon 2 20:43",
    "alfareferanse": "12.12 NORDEA BERGEN BYSTASJON 2 20:43                                           ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1208,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667823964,
    "accountNumber": "35302464616",
    "date": "2011-12-13",
    "amount": "-39.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "12.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667815895,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-39.50",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "10.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667798139,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-40.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "11.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667441646,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-68.00",
    "text": "Nsb Bergen Tvm Stasjonsvn5 5015 Bergen",
    "alfareferanse": "11.12 NSB BERGEN TVM  STASJONSVN5  5015 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Tog",
    "imageId": "",
    "place": {
      "id": 1133,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667726177,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-46.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "09.12 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666438508,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-156.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "11.12 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667949688,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-28.00",
    "text": "Shell 7-1 Oeyran Indre Arna",
    "alfareferanse": "11.12 SHELL 7-11 7526  OEYRAN INDRE ARNA                                        ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 663,
      "placeId": "ChIJNR8AdX__PEYR4GnjRDgcA1I",
      "placeName": "Shell",
      "coordinates": "60.421005,5.4642392",
      "website": "https://find.shell.com/no/fuel/NO_7932-shell-7-eleven-oyrane-torg",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667624898,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-53.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "12.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667772373,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-42.80",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "10.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667856218,
    "accountNumber": "35302464616",
    "date": "2011-12-12",
    "amount": "-36.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "09.12 7 - ELEVEN 7119 OLAV KYRREST BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666031901,
    "accountNumber": "35302464616",
    "date": "2011-12-09",
    "amount": "-200.00",
    "text": "Brun Og Blid Ådnavegen 63 Indre Arna",
    "alfareferanse": "09.12 BRUN OG BLID ÅDNAVEGEN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Solsenter",
    "imageId": "",
    "place": {
      "id": 633,
      "placeId": "ChIJyXVeGn__PEYRxVXCadP7qTg",
      "placeName": "Brown and happy",
      "coordinates": "60.421339,5.4654701",
      "website": "https://brunogblid.no/",
      "logoId": 95,
      "merchantName": "Brun og blid",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667949421,
    "accountNumber": "35302464616",
    "date": "2011-12-09",
    "amount": "-28.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "09.12 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665222359,
    "accountNumber": "35302464616",
    "date": "2011-12-09",
    "amount": "-349.00",
    "text": "Arna Frisørsalo Ådnavn. 63 Indre Arna",
    "alfareferanse": "09.12 ARNA FRISØRSALO ÅDNAVN. 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 1207,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667277499,
    "accountNumber": "35302464616",
    "date": "2011-12-09",
    "amount": "-79.70",
    "text": "Nille 120 Ådnavegen 63 Indre Arna",
    "alfareferanse": "09.12 NILLE 120 ÅDNAVEGEN 63 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1430,
      "placeId": "ChIJC3CXGn__PEYReA-pLrPrA0U",
      "placeName": "nille",
      "coordinates": "60.4214664,5.4656163",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666928607,
    "accountNumber": "35302464616",
    "date": "2011-12-09",
    "amount": "-105.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "09.12 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 665565072,
    "accountNumber": "35302464616",
    "date": "2011-12-09",
    "amount": "-280.00",
    "text": "Parfymell Ådnavegen 63 Indre Arna",
    "alfareferanse": "09.12 12228 PARFYMELL ÅDNAVEGEN 63 INDRE ARNA                                   ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 1319,
      "placeId": "ChIJC6INGX__PEYR4WaVQugCpv0",
      "placeName": "Parfymelle",
      "coordinates": "60.4211702,5.4651545",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667793123,
    "accountNumber": "35302464616",
    "date": "2011-12-08",
    "amount": "-40.09",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "07.12 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 668773404,
    "accountNumber": "35302464616",
    "date": "2011-12-08",
    "amount": "-200.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "08.12 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667513371,
    "accountNumber": "35302464616",
    "date": "2011-12-08",
    "amount": "-61.70",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "08.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 668066862,
    "accountNumber": "35302464616",
    "date": "2011-12-07",
    "amount": "-17.00",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "06.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667912864,
    "accountNumber": "35302464616",
    "date": "2011-12-07",
    "amount": "-30.50",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "07.12 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666186830,
    "accountNumber": "35302464616",
    "date": "2011-12-07",
    "amount": "-188.00",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "06.12 H&M 854 OASEN OASEN BERGEN 5147 BERGEN                                    ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667319649,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-77.65",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "02.12 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667957748,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-27.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "03.12 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666974110,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-100.00",
    "text": "Mcdonald's Torgalm. 11 Bergen",
    "alfareferanse": "04.12 MCDONALD'S TORGALM. 11  BERGEN                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1209,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667089519,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-95.00",
    "text": "Mcdonalds Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "03.12 MCDONALDS OASEN FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1204,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 661510486,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-170.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "04.12 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667935987,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-29.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "03.12 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 668757441,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-400.00",
    "text": "Spv Jernbanen Bergen 5020 Bergen",
    "alfareferanse": "03.12 SPV Jernbanen Bergen    5020 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1199,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667865465,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-35.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "02.12 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 666861667,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-112.20",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "03.12 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667256055,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-80.60",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "03.12 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667756014,
    "accountNumber": "35302464616",
    "date": "2011-12-05",
    "amount": "-44.40",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "03.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 667943962,
    "accountNumber": "35302464616",
    "date": "2011-12-02",
    "amount": "-28.90",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "02.12 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 652014711,
    "accountNumber": "35302464616",
    "date": "2011-12-01",
    "amount": "-92.80",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "01.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657179119,
    "accountNumber": "35302464616",
    "date": "2011-12-01",
    "amount": "-99.00",
    "text": "Parfymell Ådnavegen 63 Indre Arna",
    "alfareferanse": "01.12 12228 PARFYMELL ÅDNAVEGEN 63 INDRE ARNA                                   ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Parfymeri",
    "imageId": "",
    "place": {
      "id": 1319,
      "placeId": "ChIJC6INGX__PEYR4WaVQugCpv0",
      "placeName": "Parfymelle",
      "coordinates": "60.4211702,5.4651545",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 651850932,
    "accountNumber": "35302464616",
    "date": "2011-12-01",
    "amount": "-887.50",
    "text": "Rema 1000 Dag Hammersk Fyllingsdalen",
    "alfareferanse": "30.11 REMA 1000 DAG HAMMERSK FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1201,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657803350,
    "accountNumber": "35302464616",
    "date": "2011-12-01",
    "amount": "-52.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "01.12 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 655861947,
    "accountNumber": "35302464616",
    "date": "2011-12-01",
    "amount": "-270.75",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "01.12 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 653505392,
    "accountNumber": "35302464616",
    "date": "2011-11-30",
    "amount": "-204.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 30.11.11                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 659677355,
    "accountNumber": "35302464616",
    "date": "2011-11-30",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658324074,
    "accountNumber": "35302464616",
    "date": "2011-11-28",
    "amount": "-14.50",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "26.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658060830,
    "accountNumber": "35302464616",
    "date": "2011-11-28",
    "amount": "-35.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "26.11 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657325659,
    "accountNumber": "35302464616",
    "date": "2011-11-25",
    "amount": "-87.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "25.11 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658122703,
    "accountNumber": "35302464616",
    "date": "2011-11-25",
    "amount": "-30.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "25.11 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658008362,
    "accountNumber": "35302464616",
    "date": "2011-11-25",
    "amount": "-39.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "24.11 7 - ELEVEN 7119 OLAV KYRREST BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658141943,
    "accountNumber": "35302464616",
    "date": "2011-11-24",
    "amount": "-29.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "23.11 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658008097,
    "accountNumber": "35302464616",
    "date": "2011-11-24",
    "amount": "-39.00",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "24.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658146001,
    "accountNumber": "35302464616",
    "date": "2011-11-24",
    "amount": "-28.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "23.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658302521,
    "accountNumber": "35302464616",
    "date": "2011-11-24",
    "amount": "-16.90",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "24.11 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658280516,
    "accountNumber": "35302464616",
    "date": "2011-11-23",
    "amount": "-19.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "23.11 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 655529261,
    "accountNumber": "35302464616",
    "date": "2011-11-23",
    "amount": "-350.00",
    "text": "Fyllingsdalen Kiropraktor .",
    "alfareferanse": "22.11 FYLLINGSDALEN KIROPRAKTOR  .                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1210,
      "placeId": "ChIJFZzeFcP7PEYR1jXeHiphVpw",
      "placeName": "Fyllingsdalen Kiropraktorsenter",
      "coordinates": "60.356995,5.2949091",
      "website": "http://www.fyllingsdalenkiropraktor.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657992978,
    "accountNumber": "35302464616",
    "date": "2011-11-22",
    "amount": "-39.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "21.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658308981,
    "accountNumber": "35302464616",
    "date": "2011-11-22",
    "amount": "-15.93",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "22.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657602811,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-66.40",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "21.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658350685,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-10.00",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "19.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656517085,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-167.69",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "18.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656692277,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-147.10",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "19.11 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 652018283,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-66.50",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "19.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657832002,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-50.00",
    "text": "Bjørklund Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "18.11 BJØRKLUND OASEN FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Gullsmed",
    "imageId": "",
    "place": {
      "id": 1100,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 655201493,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-479.00",
    "text": "Mani Åsane Åsane Senter Ulset",
    "alfareferanse": "18.11 MANI ÅSANE ÅSANE SENTER ULSET                                             ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1211,
      "placeId": "ChIJi_-Apvr9PEYRl_sA5BkUR1Q",
      "placeName": "Mani Åsane Storsenter",
      "coordinates": "60.4653796,5.321591",
      "website": "http://skomani.no/",
      "logoId": 171,
      "merchantName": "Mani",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 655447871,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-378.00",
    "text": "Dressmann 164 Åsane Senter 5116 Ulset",
    "alfareferanse": "18.11 DRESSMANN 164 ÅSANE SENTER 5116 ULSET                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1212,
      "placeId": "ChIJo1D7u_r9PEYRqg9UZm9x-CQ",
      "placeName": "Dressmann Åsane Senter",
      "coordinates": "60.4655259,5.3221087",
      "website": "https://dressmann.com/no/",
      "logoId": 247,
      "merchantName": "Dressmann",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656145172,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-217.00",
    "text": "Kremmerhuset 24 Åsane Senter Bergen",
    "alfareferanse": "18.11 KREMMERHUSET 24 ÅSANE SENTER BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1213,
      "placeId": "ChIJGQBJkfr9PEYRRl07hrNUGCc",
      "placeName": "Kremmerhuset",
      "coordinates": "60.465909,5.3224399",
      "website": "http://www.kremmerhuset.no/",
      "logoId": 169,
      "merchantName": "Kremmerhuset",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657969553,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-40.30",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "19.11 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658166364,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-27.00",
    "text": "Narvesen 778 Åsane Sent. Ulset",
    "alfareferanse": "18.11 NARVESEN 778 ÅSANE SENT.  ULSET                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1039,
      "placeId": "ChIJE8vqqPr9PEYRjAAbpqe8rS0",
      "placeName": "Narvesen",
      "coordinates": "60.4650684,5.3217269",
      "website": "http://www.narvesen.no/",
      "logoId": 183,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656467974,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-174.00",
    "text": "Ark, Åsane Åsane Senter Ulset",
    "alfareferanse": "18.11 ARK, ÅSANE ÅSANE SENTER ULSET                                             ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1083,
      "placeId": "ChIJ1RP5sfr9PEYRHPmEGDjt_bg",
      "placeName": "Ark Beyer Åsane",
      "coordinates": "60.4649722,5.3217089",
      "website": "http://www.ark.no/",
      "logoId": 188,
      "merchantName": "Ark Beyer",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656528686,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-165.50",
    "text": "Vita Åsane Senter Ulset",
    "alfareferanse": "18.11 1601 VITA ÅSANE SENTER ULSET                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 926,
      "placeId": "ChIJm0qxovr9PEYRLWqUX5supVw",
      "placeName": "Vita",
      "coordinates": "60.464841,5.321676",
      "website": "https://www.vita.no/butikker/vita-sane-storsenter",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656185029,
    "accountNumber": "35302464616",
    "date": "2011-11-21",
    "amount": "-209.40",
    "text": "Nille 96 Sane Sane Senter Ulset",
    "alfareferanse": "18.11 NILLE 96 SANE SANE SENTER  ULSET                                          ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 846,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658305640,
    "accountNumber": "35302464616",
    "date": "2011-11-18",
    "amount": "-16.30",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "17.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657526963,
    "accountNumber": "35302464616",
    "date": "2011-11-18",
    "amount": "-71.02",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "18.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658036023,
    "accountNumber": "35302464616",
    "date": "2011-11-18",
    "amount": "-36.76",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "18.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658130359,
    "accountNumber": "35302464616",
    "date": "2011-11-17",
    "amount": "-29.80",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "16.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657458468,
    "accountNumber": "35302464616",
    "date": "2011-11-16",
    "amount": "-77.10",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "15.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658248318,
    "accountNumber": "35302464616",
    "date": "2011-11-16",
    "amount": "-20.40",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "15.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657840484,
    "accountNumber": "35302464616",
    "date": "2011-11-15",
    "amount": "-49.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "15.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658157972,
    "accountNumber": "35302464616",
    "date": "2011-11-15",
    "amount": "-27.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "15.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 652018393,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-65.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "12.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657189480,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-98.00",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "12.11 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657332177,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-86.20",
    "text": "Mega Nyborg Åsmyrana 271 Nyborg",
    "alfareferanse": "12.11 MEGA NYBORG ÅSMYRANA 271 NYBORG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1215,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656453308,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-176.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "13.11 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657886162,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-47.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "13.11 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656302823,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-198.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "12.11 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 651994710,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-153.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "12.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 656452260,
    "accountNumber": "35302464616",
    "date": "2011-11-14",
    "amount": "-176.10",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "11.11 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658358558,
    "accountNumber": "35302464616",
    "date": "2011-11-11",
    "amount": "-8.50",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "10.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 651880330,
    "accountNumber": "35302464616",
    "date": "2011-11-11",
    "amount": "-589.20",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "10.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657693176,
    "accountNumber": "35302464616",
    "date": "2011-11-11",
    "amount": "-59.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "11.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658144661,
    "accountNumber": "35302464616",
    "date": "2011-11-11",
    "amount": "-28.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "11.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658083747,
    "accountNumber": "35302464616",
    "date": "2011-11-11",
    "amount": "-33.00",
    "text": "Henry Helgesen Tårnplass 1 Bergen",
    "alfareferanse": "11.11 HENRY HELGESEN  TÅRNPLASS 1  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1126,
      "placeId": "ChIJGVzhpAL8PEYRPyWWiWUbwvk",
      "placeName": "Henry Helgesen cafe & Conditorier AS",
      "coordinates": "60.3936958,5.3221574",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 652474750,
    "accountNumber": "35302464616",
    "date": "2011-11-10",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "09.11 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658968792,
    "accountNumber": "35302464616",
    "date": "2011-11-10",
    "amount": "-500.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "10.11 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657921824,
    "accountNumber": "35302464616",
    "date": "2011-11-10",
    "amount": "-44.80",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "09.11 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658242985,
    "accountNumber": "35302464616",
    "date": "2011-11-08",
    "amount": "-21.06",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "08.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657692960,
    "accountNumber": "35302464616",
    "date": "2011-11-08",
    "amount": "-59.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "07.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658183605,
    "accountNumber": "35302464616",
    "date": "2011-11-08",
    "amount": "-25.01",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "08.11 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 658277928,
    "accountNumber": "35302464616",
    "date": "2011-11-08",
    "amount": "-19.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "07.11 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 657903608,
    "accountNumber": "35302464616",
    "date": "2011-11-07",
    "amount": "-45.50",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "07.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 652015088,
    "accountNumber": "35302464616",
    "date": "2011-11-04",
    "amount": "-89.00",
    "text": "Narvesen 768 Bystasjonen Bergen",
    "alfareferanse": "04.11 NARVESEN 768 BYSTASJONEN  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 815,
      "placeId": "ChIJ1zU0267-PEYRm0ZIjjZwpbo",
      "placeName": "Narvesen",
      "coordinates": "60.3893065,5.332543",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647810352,
    "accountNumber": "35302464616",
    "date": "2011-11-01",
    "amount": "-78.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "31.10 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648505279,
    "accountNumber": "35302464616",
    "date": "2011-11-01",
    "amount": "-29.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "01.11 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 645879829,
    "accountNumber": "35302464616",
    "date": "2011-11-01",
    "amount": "-350.00",
    "text": "Fyllingsdalen Kiropraktor .",
    "alfareferanse": "01.11 FYLLINGSDALEN KIROPRAKTOR  .                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1210,
      "placeId": "ChIJFZzeFcP7PEYR1jXeHiphVpw",
      "placeName": "Fyllingsdalen Kiropraktorsenter",
      "coordinates": "60.356995,5.2949091",
      "website": "http://www.fyllingsdalenkiropraktor.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648436110,
    "accountNumber": "35302464616",
    "date": "2011-11-01",
    "amount": "-35.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "01.11 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648461138,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-33.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "31.10 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647767505,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-80.00",
    "text": "Mcdonalds Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "30.10 MCDONALDS OASEN FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1204,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648358941,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-40.00",
    "text": "Mcdonalds Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "28.10 MCDONALDS OASEN FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1204,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648463668,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-32.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "29.10 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 649448916,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-100.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "29.10 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647104739,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-138.95",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "31.10 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646812205,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-174.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "28.10 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646306948,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-250.00",
    "text": "Blaze As Øyrane Torg Tlf 50",
    "alfareferanse": "31.10 BLAZE AS ØYRANE TORG  TLF 55950450                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 981,
      "placeId": "ChIJT48wG3__PEYRGqErJ2soRGQ",
      "placeName": "Blace",
      "coordinates": "60.4212725,5.4656685",
      "website": "http://www.oyrane-torg.no/2011/klaer-sko-parfyme/blace/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648649702,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-19.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "31.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 650148079,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648625166,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-20.40",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "28.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646976928,
    "accountNumber": "35302464616",
    "date": "2011-10-31",
    "amount": "-151.22",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "31.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 649366890,
    "accountNumber": "35302464616",
    "date": "2011-10-28",
    "amount": "-700.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "28.10 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 649438391,
    "accountNumber": "35302464616",
    "date": "2011-10-26",
    "amount": "-200.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "26.10 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648203471,
    "accountNumber": "35302464616",
    "date": "2011-10-26",
    "amount": "-50.00",
    "text": "Mcdonalds Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "25.10 MCDONALDS OASEN FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1204,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647439936,
    "accountNumber": "35302464616",
    "date": "2011-10-26",
    "amount": "-103.60",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "25.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648147426,
    "accountNumber": "35302464616",
    "date": "2011-10-26",
    "amount": "-53.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "26.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647531890,
    "accountNumber": "35302464616",
    "date": "2011-10-26",
    "amount": "-98.90",
    "text": "Nille 298 F.Bernad.V52 Fyllingsdalen",
    "alfareferanse": "25.10 NILLE 298 F.BERNAD.V52 FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 639,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648543608,
    "accountNumber": "35302464616",
    "date": "2011-10-25",
    "amount": "-27.00",
    "text": "7 - Eleven 7119 Olav Kyrrest Bergen",
    "alfareferanse": "24.10 7 - ELEVEN 7119 OLAV KYRREST BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 535,
      "placeId": "ChIJJe73R6j-PEYR_qUzzSFGUoE",
      "placeName": "7-Eleven Olav Kyrres gate",
      "coordinates": "60.3923883,5.3248911",
      "website": "http://www.7-eleven.no/#/butikker",
      "logoId": 59,
      "merchantName": "7-Eleven",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648290622,
    "accountNumber": "35302464616",
    "date": "2011-10-25",
    "amount": "-44.91",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "25.10 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648534294,
    "accountNumber": "35302464616",
    "date": "2011-10-25",
    "amount": "-27.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "24.10 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647357468,
    "accountNumber": "35302464616",
    "date": "2011-10-24",
    "amount": "-111.67",
    "text": "Rema 1000 Dag Hammersk Fyllingsdalen",
    "alfareferanse": "22.10 REMA 1000 DAG HAMMERSK FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1201,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646401427,
    "accountNumber": "35302464616",
    "date": "2011-10-24",
    "amount": "-232.60",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "21.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648005541,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-63.60",
    "text": "Oasen Mat Oasen Bydels Fyllingsdalen",
    "alfareferanse": "20.10 OASEN MAT OASEN BYDELS FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 501,
      "placeId": "ChIJoTfziMX7PEYRBKQlT9TLAb8",
      "placeName": "MENY Oasen Bergen",
      "coordinates": "60.3485248,5.2913724",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Oasen-Bergen/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648701809,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-14.50",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "21.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648622344,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-20.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "21.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647274122,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-120.00",
    "text": "Mcdonalds Arken Åsane Senter Ulset",
    "alfareferanse": "20.10 MCDONALDS ARKEN ÅSANE SENTER ULSET                                        ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1216,
      "placeId": "ChIJAYxFxfr9PEYRED6eb15T8gs",
      "placeName": "McDonald's Åsane",
      "coordinates": "60.4651374,5.3238795",
      "website": "https://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648700237,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-14.90",
    "text": "Ica Maxi Åsane Ulset",
    "alfareferanse": "20.10 ICA Maxi Åsane   ULSET                                                    ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1217,
      "placeId": "ChIJ65wnu_r9PEYR25ChN2X24J0",
      "placeName": "Meny Åsane Storsenter",
      "coordinates": "60.4651371,5.322297",
      "website": "https://meny.no/Finn-butikk/Meny-Asane-Storsenter/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 645725351,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-399.50",
    "text": "Bik Bok 64 D Åsane Senter Ulset",
    "alfareferanse": "20.10 BIK BOK 64 D ÅSANE SENTER ULSET                                           ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1218,
      "placeId": "ChIJAYxFxfr9PEYRQzHeottJwMA",
      "placeName": "BIK BOK",
      "coordinates": "60.4648923,5.3240095",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/64d/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647929171,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-69.00",
    "text": "Location Bergen . Bergen",
    "alfareferanse": "21.10 LOCATION BERGEN . BERGEN                                                  ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1445,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 645336638,
    "accountNumber": "35302464616",
    "date": "2011-10-21",
    "amount": "-611.00",
    "text": "Cubus 877 Arken Ulset",
    "alfareferanse": "20.10 CUBUS 877 ARKEN ULSET                                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 870,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648173836,
    "accountNumber": "35302464616",
    "date": "2011-10-20",
    "amount": "-51.80",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "20.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646494390,
    "accountNumber": "35302464616",
    "date": "2011-10-19",
    "amount": "-216.50",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "18.10 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648516231,
    "accountNumber": "35302464616",
    "date": "2011-10-18",
    "amount": "-29.00",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "17.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647863533,
    "accountNumber": "35302464616",
    "date": "2011-10-18",
    "amount": "-73.67",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "18.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646614566,
    "accountNumber": "35302464616",
    "date": "2011-10-18",
    "amount": "-199.50",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "18.10 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648713192,
    "accountNumber": "35302464616",
    "date": "2011-10-18",
    "amount": "-12.73",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "18.10 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647824753,
    "accountNumber": "35302464616",
    "date": "2011-10-17",
    "amount": "-76.20",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "15.10 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648184410,
    "accountNumber": "35302464616",
    "date": "2011-10-17",
    "amount": "-50.70",
    "text": "Meny Sletten Slettebakkvn Bergen",
    "alfareferanse": "15.10 MENY SLETTEN SLETTEBAKKVN BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1219,
      "placeId": "ChIJ____PxH5PEYRvxRcXwKn5QM",
      "placeName": "MENY Sletten",
      "coordinates": "60.3566014,5.3590732",
      "website": "https://meny.no/Finn-butikk/MENY-Sletten/",
      "logoId": 88,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647084140,
    "accountNumber": "35302464616",
    "date": "2011-10-17",
    "amount": "-140.20",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "14.10 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648173688,
    "accountNumber": "35302464616",
    "date": "2011-10-17",
    "amount": "-51.80",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "15.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647551124,
    "accountNumber": "35302464616",
    "date": "2011-10-17",
    "amount": "-97.23",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "15.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647553296,
    "accountNumber": "35302464616",
    "date": "2011-10-14",
    "amount": "-97.00",
    "text": "Location Bergen Normannsgt.3 Bergen",
    "alfareferanse": "13.10 LOCATION BERGEN NORMANNSGT.3 BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1221,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648003051,
    "accountNumber": "35302464616",
    "date": "2011-10-14",
    "amount": "-63.92",
    "text": "Lerøy Mat As Torgalm. Bergen",
    "alfareferanse": "13.10 LERØY MAT AS TORGALM. BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1193,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648655824,
    "accountNumber": "35302464616",
    "date": "2011-10-14",
    "amount": "-19.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "14.10 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 645254790,
    "accountNumber": "35302464616",
    "date": "2011-10-11",
    "amount": "-698.68",
    "text": "Rema 1000 Dag Hammersk Fyllingsdalen",
    "alfareferanse": "11.10 REMA 1000 DAG HAMMERSK FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1201,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 645638398,
    "accountNumber": "35302464616",
    "date": "2011-10-11",
    "amount": "-434.89",
    "text": "Europris Fyllin Spelhaugen 1 Fyllingsdalen",
    "alfareferanse": "11.10 EUROPRIS FYLLIN SPELHAUGEN 1 FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1222,
      "placeId": "ChIJ01vxC7j7PEYRUDjB_D1U4ow",
      "placeName": "Europris Fyllingsdalen",
      "coordinates": "60.350932,5.2777491",
      "website": "https://www.europris.no/vaare-butikker/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646071275,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-299.95",
    "text": "Vero Moda Berge Strømgt 8 Bergen",
    "alfareferanse": "10.10 VERO MODA BERGE STRØMGT 8 BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1223,
      "placeId": "ChIJW-e2tK7-PEYRPFpH9jrUJsc",
      "placeName": "VERO MODA",
      "coordinates": "60.3894438,5.3325016",
      "website": "http://www.veromoda.no/",
      "logoId": 251,
      "merchantName": "VERO MODA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648652387,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-19.40",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "10.10 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647011888,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-149.00",
    "text": "Naturkost As Øyrane Torg Indre Arna",
    "alfareferanse": "10.10 NATURKOST AS ØYRANE TORG  INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1198,
      "placeId": "ChIJC3CXGn__PEYRQLgn5aGQCnI",
      "placeName": "Kinsarvik Naturkost",
      "coordinates": "60.4214664,5.4656163",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 643861113,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-149.50",
    "text": "Ice Norge As",
    "alfareferanse": "Nettgiro til: ICE NORGE AS Betalt: 10.10.11                                     ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 1180,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 649330723,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-1000.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "10.10 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 643736673,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-612.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 10.10.11                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 643851634,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-183.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 10.10.11                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 647574081,
    "accountNumber": "35302464616",
    "date": "2011-10-10",
    "amount": "-95.00",
    "text": "Shell Nesstun N Esttunv Enesttun",
    "alfareferanse": "09.10 SHELL NESSTUN N ESTTUNV ENESTTUN                                          ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 664,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648647241,
    "accountNumber": "35302464616",
    "date": "2011-10-05",
    "amount": "-19.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "05.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648147005,
    "accountNumber": "35302464616",
    "date": "2011-10-04",
    "amount": "-53.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "04.10 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648563466,
    "accountNumber": "35302464616",
    "date": "2011-10-04",
    "amount": "-25.00",
    "text": "Narvesen 105 Strømsgt. 2 Bergen",
    "alfareferanse": "04.10 NARVESEN 105 STRØMSGT. 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1111,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648526341,
    "accountNumber": "35302464616",
    "date": "2011-10-04",
    "amount": "-28.00",
    "text": "Solbrød Bergen Kokstaddalen Kokstad",
    "alfareferanse": "03.10 SOLBRØD BERGEN  KOKSTADDALEN KOKSTAD                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1224,
      "placeId": "ChIJ0Qe873b6PEYR8mIA4Y1ulG0",
      "placeName": "Baker Brun Produksjon AS avdeling Kokstad",
      "coordinates": "60.296334,5.254525",
      "website": "http://www.solbrod.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648038740,
    "accountNumber": "35302464616",
    "date": "2011-10-03",
    "amount": "-60.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "03.10 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 642761548,
    "accountNumber": "35302464616",
    "date": "2011-10-03",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "30.09 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 646238802,
    "accountNumber": "35302464616",
    "date": "2011-10-03",
    "amount": "-264.00",
    "text": "Vita Strømgt. 8 Bergen",
    "alfareferanse": "30.09 1610 VITA STRØMGT. 8 BERGEN                                               ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 754,
      "placeId": "ChIJJ_9Dt67-PEYRlZpJ6_lJ0WE",
      "placeName": "Vita Bergen Storsenter",
      "coordinates": "60.3890421,5.3322011",
      "website": "https://www.vita.no/butikker/vita-bergen-storsenter",
      "logoId": 137,
      "merchantName": "Vita",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 648250466,
    "accountNumber": "35302464616",
    "date": "2011-10-03",
    "amount": "-47.60",
    "text": "Kiwi 886 Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "02.10 KIWI 886 OASEN  FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 577,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 640465747,
    "accountNumber": "35302465310",
    "date": "2011-09-30",
    "amount": "-3.00",
    "text": "Nettgiro M/Meld.Forfall I Dag",
    "alfareferanse": "NETTGIRO M/MELD.FORFALL I DAG       1 TRANS(ER) TYPE 203                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 635,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 640409274,
    "accountNumber": "35302464616",
    "date": "2011-09-30",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 639684394,
    "accountNumber": "35302464616",
    "date": "2011-09-30",
    "amount": "-500.00",
    "text": "Spv Jernbanen Bergen 5020 Bergen",
    "alfareferanse": "30.09 SPV Jernbanen Bergen    5020 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1199,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638912692,
    "accountNumber": "35302464616",
    "date": "2011-09-30",
    "amount": "-25.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "30.09 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638107032,
    "accountNumber": "35302464616",
    "date": "2011-09-21",
    "amount": "-84.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "21.09 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638253335,
    "accountNumber": "35302464616",
    "date": "2011-09-20",
    "amount": "-71.74",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "20.09 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 633222556,
    "accountNumber": "35302464616",
    "date": "2011-09-20",
    "amount": "-119.00",
    "text": "Narvesen 768 Bystasjonen Bergen",
    "alfareferanse": "19.09 NARVESEN 768 BYSTASJONEN  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 815,
      "placeId": "ChIJ1zU0267-PEYRm0ZIjjZwpbo",
      "placeName": "Narvesen",
      "coordinates": "60.3893065,5.332543",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 637560811,
    "accountNumber": "35302464616",
    "date": "2011-09-20",
    "amount": "-138.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "20.09 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 633111774,
    "accountNumber": "35302464616",
    "date": "2011-09-20",
    "amount": "-548.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "20.09 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 634480439,
    "accountNumber": "35302464616",
    "date": "2011-09-19",
    "amount": "-1177.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 19.09.11                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638924300,
    "accountNumber": "35302464616",
    "date": "2011-09-19",
    "amount": "-23.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "19.09 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638389589,
    "accountNumber": "35302464616",
    "date": "2011-09-19",
    "amount": "-61.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "19.09 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638351704,
    "accountNumber": "35302464616",
    "date": "2011-09-19",
    "amount": "-64.80",
    "text": "Rema 1000 Øyran Øyrane Torg Bergen",
    "alfareferanse": "17.09 REMA 1000 ØYRAN ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1325,
      "placeId": "ChIJC6INGX__PEYRAw6WjB_DVxY",
      "placeName": "REMA 1000",
      "coordinates": "60.4213477,5.4649823",
      "website": "https://www.rema.no/",
      "logoId": 261,
      "merchantName": "Rema 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 633067607,
    "accountNumber": "35302464616",
    "date": "2011-09-16",
    "amount": "-1000.00",
    "text": "Wallendahl. Strandgt. 17 Bergen",
    "alfareferanse": "16.09 WALLENDAHL. STRANDGT. 17 BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Jernvare",
    "imageId": "",
    "place": {
      "id": 1203,
      "placeId": "ChIJ--btsgL8PEYRjUCJ8kWu8Fs",
      "placeName": "Wallendahl",
      "coordinates": "60.3945761,5.3222859",
      "website": "http://wallendahl.no/",
      "logoId": 248,
      "merchantName": "Wallendahl",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 639618341,
    "accountNumber": "35302464616",
    "date": "2011-09-15",
    "amount": "-1000.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "15.09 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 636444569,
    "accountNumber": "35302464616",
    "date": "2011-09-14",
    "amount": "-351.30",
    "text": "Bunnpris Smiber Tore Nermans Fyllingsdalen",
    "alfareferanse": "14.09 BUNNPRIS SMIBER TORE NERMANS FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 498,
      "placeId": "ChIJKdPqX8z7PEYRH0ej7vqQ7Gw",
      "placeName": "Bunnpris Smiberget",
      "coordinates": "60.3408681,5.2874596",
      "website": "http://bunnpris.no/butikker/bunnpris-smiberget",
      "logoId": 55,
      "merchantName": "Bunnpris",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638452887,
    "accountNumber": "35302464616",
    "date": "2011-09-08",
    "amount": "-57.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "08.09 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638638697,
    "accountNumber": "35302464616",
    "date": "2011-09-08",
    "amount": "-44.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "08.09 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 639646754,
    "accountNumber": "35302464616",
    "date": "2011-09-06",
    "amount": "-600.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "06.09 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 633654857,
    "accountNumber": "35302464616",
    "date": "2011-09-02",
    "amount": "-400.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "01.09 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 638799253,
    "accountNumber": "35302464616",
    "date": "2011-09-02",
    "amount": "-32.00",
    "text": "Deli De Luca Torggt 5 Bergen",
    "alfareferanse": "01.09 DELI DE LUCA TORGGT 5 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1439,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 639058776,
    "accountNumber": "35302464616",
    "date": "2011-09-02",
    "amount": "-8.00",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "02.09 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629870913,
    "accountNumber": "35302464616",
    "date": "2011-09-01",
    "amount": "-29.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "01.09 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629989266,
    "accountNumber": "35302464616",
    "date": "2011-09-01",
    "amount": "-20.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "01.09 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629989651,
    "accountNumber": "35302464616",
    "date": "2011-09-01",
    "amount": "-20.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "31.08 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 624279901,
    "accountNumber": "35302464616",
    "date": "2011-08-31",
    "amount": "-500.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "30.08 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629653523,
    "accountNumber": "35302464616",
    "date": "2011-08-31",
    "amount": "-44.70",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "31.08 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 625264310,
    "accountNumber": "35302464616",
    "date": "2011-08-31",
    "amount": "-327.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 31.08.11                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 631364713,
    "accountNumber": "35302464616",
    "date": "2011-08-31",
    "amount": "-21.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           3 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 623684481,
    "accountNumber": "35302464616",
    "date": "2011-08-30",
    "amount": "-542.80",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "30.08 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 627463258,
    "accountNumber": "35302464616",
    "date": "2011-08-30",
    "amount": "-299.50",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "30.08 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 624299872,
    "accountNumber": "35302464616",
    "date": "2011-08-23",
    "amount": "-300.00",
    "text": "Atm Bergen Postk Dnb Nor Bank Asa",
    "alfareferanse": "22.08 ATM BERGEN POSTK DNB NOR BANK ASA  501131                                 ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1226,
      "placeId": "ChIJiw5wxqf-PEYRtzGUtYr2r_g",
      "placeName": "DnB",
      "coordinates": "60.3954726,5.3264007",
      "website": "http://www.dnb.no/",
      "logoId": 241,
      "merchantName": "DnB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629223241,
    "accountNumber": "35302464616",
    "date": "2011-08-23",
    "amount": "-74.30",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "22.08 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629476522,
    "accountNumber": "35302464616",
    "date": "2011-08-23",
    "amount": "-56.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "23.08 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629969243,
    "accountNumber": "35302464616",
    "date": "2011-08-23",
    "amount": "-20.50",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "23.08 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 630776083,
    "accountNumber": "35302464616",
    "date": "2011-08-23",
    "amount": "-200.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "23.08 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 623692281,
    "accountNumber": "35302464616",
    "date": "2011-08-22",
    "amount": "-510.00",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "20.08 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629543436,
    "accountNumber": "35302464616",
    "date": "2011-08-22",
    "amount": "-50.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "22.08 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629411270,
    "accountNumber": "35302464616",
    "date": "2011-08-22",
    "amount": "-60.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "22.08 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629982245,
    "accountNumber": "35302464616",
    "date": "2011-08-22",
    "amount": "-20.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "20.08 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 623761288,
    "accountNumber": "35302464616",
    "date": "2011-08-22",
    "amount": "-252.00",
    "text": "Narvesen 765 Oasen Sent Fyllingsd.",
    "alfareferanse": "19.08 NARVESEN 765 OASEN SENT FYLLINGSD.                                        ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1096,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 629899729,
    "accountNumber": "35302464616",
    "date": "2011-08-19",
    "amount": "-26.01",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "19.08 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 628804833,
    "accountNumber": "35302464616",
    "date": "2011-08-18",
    "amount": "-105.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "18.08 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 623634387,
    "accountNumber": "35302464616",
    "date": "2011-08-17",
    "amount": "-1000.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "17.08 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 630679791,
    "accountNumber": "35302464616",
    "date": "2011-08-15",
    "amount": "-1000.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "15.08 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 625188443,
    "accountNumber": "35302464616",
    "date": "2011-08-10",
    "amount": "-719.00",
    "text": "Nordea Finans K",
    "alfareferanse": "Nettgiro til: NORDEA FINANS K Betalt: 10.08.11                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Diverse netthandel",
    "imageId": "",
    "place": {
      "id": 1029,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 623666352,
    "accountNumber": "35302464616",
    "date": "2011-08-10",
    "amount": "-638.50",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "09.08 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 625251777,
    "accountNumber": "35302464616",
    "date": "2011-08-10",
    "amount": "-376.16",
    "text": "Ice Norge As",
    "alfareferanse": "Nettgiro til: ICE NORGE AS Betalt: 10.08.11                                     ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 1180,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 624301599,
    "accountNumber": "35302464616",
    "date": "2011-08-03",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "02.08 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 627492606,
    "accountNumber": "35302464616",
    "date": "2011-08-03",
    "amount": "-297.00",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "02.08 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 626694292,
    "accountNumber": "35302464616",
    "date": "2011-08-03",
    "amount": "-630.00",
    "text": "Ramsvik Frisør Olav Kyrresg Bergen",
    "alfareferanse": "02.08 RAMSVIK FRISØR  OLAV KYRRESG BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Frisør",
    "imageId": "",
    "place": {
      "id": 1228,
      "placeId": "ChIJPwgHDgL8PEYRznEvZJo0nDc",
      "placeName": "Ramsvik Frisør",
      "coordinates": "60.390784,5.3232129",
      "website": "http://www.ramsvik.no/salongene/bergen-sentrum",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 628304722,
    "accountNumber": "35302464616",
    "date": "2011-08-03",
    "amount": "-159.00",
    "text": "Accessorize Strømgt. 8 Bergen",
    "alfareferanse": "02.08 ACCESSORIZE STRØMGT. 8 BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1229,
      "placeId": "ChIJSbvXPaj-PEYR5xTJCLkNDOc",
      "placeName": "Accessorize",
      "coordinates": "60.392857,5.3249708",
      "website": "http://eu.accessorize.com/",
      "logoId": 253,
      "merchantName": "Accessorize",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 628978171,
    "accountNumber": "35302464616",
    "date": "2011-08-03",
    "amount": "-93.29",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "02.08 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614317166,
    "accountNumber": "35302464616",
    "date": "2011-08-01",
    "amount": "-113.00",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "30.07 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619331763,
    "accountNumber": "35302464616",
    "date": "2011-08-01",
    "amount": "-106.35",
    "text": "Nille 120 Yran Arnavgen Indre Arna",
    "alfareferanse": "30.07 NILLE 120 YRAN  ARNAVGEN INDRE ARNA                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1446,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 617464161,
    "accountNumber": "35302464616",
    "date": "2011-08-01",
    "amount": "-460.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "30.07 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 618643715,
    "accountNumber": "35302464616",
    "date": "2011-08-01",
    "amount": "-188.00",
    "text": "Esso Nesstun Fanavn.2 Nesttun",
    "alfareferanse": "31.07 ESSO NESSTUN FANAVN.2 NESTTUN                                             ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 710,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619448368,
    "accountNumber": "35302464616",
    "date": "2011-08-01",
    "amount": "-99.00",
    "text": "Esso Nesstun Fanavn.2 Nesttun",
    "alfareferanse": "31.07 ESSO NESSTUN FANAVN.2 NESTTUN                                             ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 710,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 621902929,
    "accountNumber": "35302464616",
    "date": "2011-07-31",
    "amount": "-21.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           3 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614260078,
    "accountNumber": "35302464616",
    "date": "2011-07-25",
    "amount": "-349.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "23.07 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 620143373,
    "accountNumber": "35302464616",
    "date": "2011-07-25",
    "amount": "-44.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "23.07 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 621260279,
    "accountNumber": "35302464616",
    "date": "2011-07-25",
    "amount": "-300.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "23.07 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619775000,
    "accountNumber": "35302464616",
    "date": "2011-07-19",
    "amount": "-71.40",
    "text": "Kiwi 879 Rollandslia Ulset",
    "alfareferanse": "19.07 KIWI 879 ROLLANDSLIA  ULSET                                               ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1055,
      "placeId": "ChIJRQFgZOP9PEYRUIukZGk4fIw",
      "placeName": "KIWI Rolland",
      "coordinates": "60.4621107,5.3295933",
      "website": "https://kiwi.no/Finn-butikk/KIWI-Rolland/",
      "logoId": 41,
      "merchantName": "KIWI",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614761843,
    "accountNumber": "35302464616",
    "date": "2011-07-18",
    "amount": "-600.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "16.07 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 618557932,
    "accountNumber": "35302464616",
    "date": "2011-07-18",
    "amount": "-199.00",
    "text": "Carlings 54 E Torgalmen.7 Bergen",
    "alfareferanse": "15.07 CARLINGS 54 E TORGALMEN.7  BERGEN                                         ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1230,
      "placeId": "ChIJx7VpIaj-PEYRSV6FmEEa0ps",
      "placeName": "Carlings Torgallmenningen",
      "coordinates": "60.3929099,5.3234536",
      "website": "http://carlings.com/no/Secondary-menu-elements/Stores-with-links-to-each-country/Norway/BERGEN/Carlings-Bergen1/",
      "logoId": 214,
      "merchantName": "Carlings",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619985861,
    "accountNumber": "35302464616",
    "date": "2011-07-18",
    "amount": "-55.60",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "18.07 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619458563,
    "accountNumber": "35302464616",
    "date": "2011-07-15",
    "amount": "-98.00",
    "text": "Kremmerhuset Torgalmennin Bergen",
    "alfareferanse": "15.07 KREMMERHUSET TORGALMENNIN BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1231,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614786519,
    "accountNumber": "35302464616",
    "date": "2011-07-15",
    "amount": "-400.00",
    "text": "Sr-Bank Bergen V/Banken 5020 Bergen",
    "alfareferanse": "15.07 SR-Bank Bergen v/banken 5020 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1232,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619232288,
    "accountNumber": "35302464616",
    "date": "2011-07-15",
    "amount": "-117.70",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "14.07 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614316176,
    "accountNumber": "35302464616",
    "date": "2011-07-15",
    "amount": "-115.40",
    "text": "Lerøy Mat As Torgalm. Bergen",
    "alfareferanse": "15.07 LERØY MAT AS TORGALM. BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1193,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619789289,
    "accountNumber": "35302464616",
    "date": "2011-07-15",
    "amount": "-70.00",
    "text": "Vero Moda/Only Torvalm. 10 Bergen",
    "alfareferanse": "15.07 VERO MODA/ONLY  TORVALM. 10  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1326,
      "placeId": "ChIJH9t0PKj-PEYRRB3ILnroPZk",
      "placeName": "VERO MODA",
      "coordinates": "60.3926438,5.3244953",
      "website": "http://www.veromoda.no/",
      "logoId": 275,
      "merchantName": "VERO MODA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619944022,
    "accountNumber": "35302464616",
    "date": "2011-07-15",
    "amount": "-59.00",
    "text": "Esso Oasen . Fyllingsdalen",
    "alfareferanse": "14.07 ESSO OASEN . FYLLINGSDALEN                                                ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 480,
      "placeId": "ChIJbbqzV8_7PEYR2hEBPHeaG04",
      "placeName": "Esso",
      "coordinates": "60.3494411,5.2877068",
      "website": "http://www.essofuelfinder.no/",
      "logoId": 37,
      "merchantName": "Esso",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 620457560,
    "accountNumber": "35302464616",
    "date": "2011-07-11",
    "amount": "-12.00",
    "text": "Naturkost As Øyrane Torg Indre Arna",
    "alfareferanse": "09.07 NATURKOST AS ØYRANE TORG  INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1198,
      "placeId": "ChIJC3CXGn__PEYRQLgn5aGQCnI",
      "placeName": "Kinsarvik Naturkost",
      "coordinates": "60.4214664,5.4656163",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 621257682,
    "accountNumber": "35302464616",
    "date": "2011-07-11",
    "amount": "-300.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "09.07 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619430575,
    "accountNumber": "35302464616",
    "date": "2011-07-11",
    "amount": "-99.00",
    "text": "Burger King5003 Strandgt. 5 Bergen",
    "alfareferanse": "09.07 BURGER KING5003 STRANDGT. 5  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 959,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619730879,
    "accountNumber": "35302464616",
    "date": "2011-07-07",
    "amount": "-75.00",
    "text": "Narvesen 762 Olav Kyrresg Bergen",
    "alfareferanse": "07.07 NARVESEN 762 OLAV KYRRESG BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1233,
      "placeId": "ChIJi9BrNKj-PEYRWWSgwALKXMc",
      "placeName": "Narvesen",
      "coordinates": "60.3918744,5.3244803",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 621221935,
    "accountNumber": "35302464616",
    "date": "2011-07-07",
    "amount": "-500.00",
    "text": "Spv Sartor Senter 2 5353 Straume",
    "alfareferanse": "07.07 SPV Sartor Senter 2     5353 Straume                                      ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1234,
      "placeId": "ChIJydL-ru_kPEYRaLHj1EIWEvI",
      "placeName": "Sparebanken Vest Straume",
      "coordinates": "60.3588763,5.1251317",
      "website": "https://www.spv.no/kontakt-oss/kontorer/straume",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614800886,
    "accountNumber": "35302464616",
    "date": "2011-07-07",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen V/Banken 5020 Bergen",
    "alfareferanse": "06.07 SR-Bank Bergen v/banken 5020 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1232,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 618939413,
    "accountNumber": "35302464616",
    "date": "2011-07-06",
    "amount": "-149.00",
    "text": "G-Sport Gågaten Strandgt. 59 Bergen",
    "alfareferanse": "06.07 G-SPORT GÅGATEN STRANDGT. 59 BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sportsutstyr",
    "imageId": "",
    "place": {
      "id": 1235,
      "placeId": "ChIJpY9VNR38PEYRMfAxlA1IdEs",
      "placeName": "G-Sport Bergen - Gågaten",
      "coordinates": "60.395356,5.3184664",
      "website": "https://www.gsport.no/butikker/g-sport-gaagaten",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619238884,
    "accountNumber": "35302464616",
    "date": "2011-07-06",
    "amount": "-116.80",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "05.07 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 618604155,
    "accountNumber": "35302464616",
    "date": "2011-07-06",
    "amount": "-194.00",
    "text": "Burger King5003 Strandgt. 5 Bergen",
    "alfareferanse": "06.07 BURGER KING5003 STRANDGT. 5  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 959,
      "placeId": "ChIJDbmMBKj-PEYR-LhSE4f_v6M",
      "placeName": "Burger King",
      "coordinates": "60.3939661,5.3238634",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614199684,
    "accountNumber": "35302464616",
    "date": "2011-07-05",
    "amount": "-615.00",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "04.07 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 614281148,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-259.50",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "01.07 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619385856,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-100.00",
    "text": "Bergen Kino Neumannsgt 3 Bergen",
    "alfareferanse": "03.07 BERGEN KINO NEUMANNSGT 3 BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 1236,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619425573,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-99.00",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "02.07 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 620394646,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-20.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "02.07 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 621256451,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-300.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "02.07 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619100986,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-130.00",
    "text": "Facil Kebab Neumannsgt. Bergen",
    "alfareferanse": "03.07 FACIL KEBAB NEUMANNSGT.  BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1181,
      "placeId": "ChIJubG0UAL8PEYRGHDWy_tFU8c",
      "placeName": "Bergen,s shawarma",
      "coordinates": "60.3914694,5.3191779",
      "website": "https://facil-kebab-pizza.business.site/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 618609069,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-193.00",
    "text": "Location Bergen . Bergen",
    "alfareferanse": "03.07 LOCATION BERGEN . BERGEN                                                  ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1445,
      "placeId": "ChIJd312ZkkNOUYRCAretD6gQp4",
      "placeName": "Bergen",
      "coordinates": "60.3912628,5.3220544",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619621705,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-84.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "02.07 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 619039403,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-138.00",
    "text": "Toscana Pizzeri Øvre Fylling Laksevåg",
    "alfareferanse": "01.07 TOSCANA PIZZERI ØVRE FYLLING LAKSEVÅG                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 881,
      "placeId": "ChIJ8YhaQvr7PEYRUW6QnzyushU",
      "placeName": "Toscana Pizzeria",
      "coordinates": "60.37594,5.3037526",
      "website": "http://www.toscana-pizzeria.net/",
      "logoId": 180,
      "merchantName": "Toscana Pizzeria",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 620065320,
    "accountNumber": "35302464616",
    "date": "2011-07-04",
    "amount": "-49.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "02.07 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 609893658,
    "accountNumber": "35302464616",
    "date": "2011-07-01",
    "amount": "-127.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "01.07 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 608248917,
    "accountNumber": "35302464616",
    "date": "2011-07-01",
    "amount": "-497.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "01.07 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 609270880,
    "accountNumber": "35302464616",
    "date": "2011-07-01",
    "amount": "-205.25",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "01.07 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 611151479,
    "accountNumber": "35302464616",
    "date": "2011-07-01",
    "amount": "-19.00",
    "text": "Big Bite Bergen Nicolaismanu Bergen",
    "alfareferanse": "01.07 BIG BITE BERGEN NICOLAISMANU BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1196,
      "placeId": "ChIJW-e2tK7-PEYRuFvWjVsw_nY",
      "placeName": "Big Bite",
      "coordinates": "60.3893065,5.332543",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 610943342,
    "accountNumber": "35302464616",
    "date": "2011-06-30",
    "amount": "-38.00",
    "text": "Deli De Luca Torggt 5 Bergen",
    "alfareferanse": "29.06 DELI DE LUCA TORGGT 5 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1439,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 612604191,
    "accountNumber": "35302464616",
    "date": "2011-06-30",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 610605671,
    "accountNumber": "35302464616",
    "date": "2011-06-30",
    "amount": "-64.00",
    "text": "271 Boots Apotek Spelhaugen",
    "alfareferanse": "30.06 271 BOOTS APOTEK SPELHAUGEN                                               ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 653,
      "placeId": "ChIJH3-H5rf7PEYRgCTeF2gxbJI",
      "placeName": "Boots apotek Spelhaugen",
      "coordinates": "60.3499212,5.276873",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 608722887,
    "accountNumber": "35302464616",
    "date": "2011-06-27",
    "amount": "-317.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "25.06 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 610328776,
    "accountNumber": "35302464616",
    "date": "2011-06-27",
    "amount": "-86.00",
    "text": "Mcdonald's Torgalm. 11 Bergen",
    "alfareferanse": "26.06 MCDONALD'S TORGALM. 11  BERGEN                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1209,
      "placeId": "ChIJuYPZJ6j-PEYR6a9RXUjSiVY",
      "placeName": "McDonald's",
      "coordinates": "60.3924837,5.3232035",
      "website": "http://www.mcdonalds.no/",
      "logoId": 46,
      "merchantName": "McDonald's",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 605519685,
    "accountNumber": "35302464616",
    "date": "2011-06-27",
    "amount": "-700.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "26.06 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 609348311,
    "accountNumber": "35302464616",
    "date": "2011-06-27",
    "amount": "-199.00",
    "text": "H&M 839 Xhibiti Smstrandgt. Bergen",
    "alfareferanse": "25.06 H&M 839 XHIBITI SMSTRANDGT.  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1157,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 608291961,
    "accountNumber": "35302464616",
    "date": "2011-06-23",
    "amount": "-471.80",
    "text": "Coop Prix Melkeplassen Laksevåg",
    "alfareferanse": "22.06 COOP PRIX MELKEPLASSEN LAKSEVÅG                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1227,
      "placeId": "ChIJ6_RtW_r7PEYRzenLNyLxgZs",
      "placeName": "Coop Prix Melkeplassen",
      "coordinates": "60.3756,5.30427",
      "website": "https://coop.no/butikker/prix/melkeplassen-1465/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 609708995,
    "accountNumber": "35302464616",
    "date": "2011-06-22",
    "amount": "-149.00",
    "text": "Cmyk Oasen Sent Folke Bernad Fyllingsdalen",
    "alfareferanse": "21.06 CMYK OASEN SENT FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1174,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 611039802,
    "accountNumber": "35302464616",
    "date": "2011-06-22",
    "amount": "-29.00",
    "text": "Deli De Luca Torggt 5 Bergen",
    "alfareferanse": "22.06 DELI DE LUCA TORGGT 5 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1439,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 609422353,
    "accountNumber": "35302464616",
    "date": "2011-06-21",
    "amount": "-188.00",
    "text": "Norli Oasen Byd F.Bernadotte Fyllingsdalen",
    "alfareferanse": "21.06 NORLI OASEN BYD F.BERNADOTTE FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1237,
      "placeId": "ChIJoTfziMX7PEYRFW2AoN5LeEE",
      "placeName": "Norli Oasis Bydelssenter",
      "coordinates": "60.3490486,5.2914566",
      "website": "http://www.norli.no/",
      "logoId": 141,
      "merchantName": "Norli",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 610645638,
    "accountNumber": "35302464616",
    "date": "2011-06-21",
    "amount": "-60.00",
    "text": "Bergen Kino Neumannsgt 3 Bergen",
    "alfareferanse": "20.06 BERGEN KINO NEUMANNSGT 3 BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 1236,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 611852096,
    "accountNumber": "35302464616",
    "date": "2011-06-21",
    "amount": "-300.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "21.06 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 610414991,
    "accountNumber": "35302464616",
    "date": "2011-06-14",
    "amount": "-79.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "11.06 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 610308333,
    "accountNumber": "35302464616",
    "date": "2011-06-14",
    "amount": "-88.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "11.06 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 609117057,
    "accountNumber": "35302464616",
    "date": "2011-06-14",
    "amount": "-234.50",
    "text": "Lene V Arna Øyrane Torv Bergen",
    "alfareferanse": "11.06 LENE V ARNA ØYRANE TORV  BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1448,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus Øyrane Torg",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 608804627,
    "accountNumber": "35302464616",
    "date": "2011-06-14",
    "amount": "-299.50",
    "text": "Lene V Arna Øyrane Torv Bergen",
    "alfareferanse": "11.06 LENE V ARNA ØYRANE TORV  BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1448,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus Øyrane Torg",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 610728722,
    "accountNumber": "35302464616",
    "date": "2011-06-10",
    "amount": "-54.00",
    "text": "Norli Bergen Strømgt 8 Bergen",
    "alfareferanse": "10.06 NORLI BERGEN STRØMGT 8 BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1238,
      "placeId": "ChIJW-e2tK7-PEYRqpfTv4Q7o0k",
      "placeName": "Norli Bergen Storsenter",
      "coordinates": "60.3893436,5.3326129",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/norli/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 602901354,
    "accountNumber": "35302464616",
    "date": "2011-05-31",
    "amount": "-100.00",
    "text": "Årsgebyr Visa Electron",
    "alfareferanse": "ÅRSGEBYR VISA ELECTRON                                                          ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1239,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 601739793,
    "accountNumber": "35302464616",
    "date": "2011-05-23",
    "amount": "-11.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "21.05 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 601671542,
    "accountNumber": "35302464616",
    "date": "2011-05-20",
    "amount": "-19.90",
    "text": "Nille 120 Yran Arnavgen Indre Arna",
    "alfareferanse": "20.05 NILLE 120 YRAN  ARNAVGEN INDRE ARNA                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1446,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 601475222,
    "accountNumber": "35302464616",
    "date": "2011-05-20",
    "amount": "-34.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "20.05 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 599844038,
    "accountNumber": "35302464616",
    "date": "2011-05-18",
    "amount": "-176.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "16.05 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 600797530,
    "accountNumber": "35302464616",
    "date": "2011-05-18",
    "amount": "-80.00",
    "text": "Blaze As Øyrane Torg Tlf 50",
    "alfareferanse": "16.05 BLAZE AS ØYRANE TORG  TLF 55950450                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 981,
      "placeId": "ChIJT48wG3__PEYRGqErJ2soRGQ",
      "placeName": "Blace",
      "coordinates": "60.4212725,5.4656685",
      "website": "http://www.oyrane-torg.no/2011/klaer-sko-parfyme/blace/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 601283357,
    "accountNumber": "35302464616",
    "date": "2011-05-16",
    "amount": "-47.20",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "16.05 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 601427189,
    "accountNumber": "35302464616",
    "date": "2011-05-13",
    "amount": "-37.60",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "13.05 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 601187514,
    "accountNumber": "35302464616",
    "date": "2011-05-13",
    "amount": "-53.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "13.05 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 601465059,
    "accountNumber": "35302464616",
    "date": "2011-05-13",
    "amount": "-34.90",
    "text": "Nille 120 Yran Arnavgen Indre Arna",
    "alfareferanse": "13.05 NILLE 120 YRAN  ARNAVGEN INDRE ARNA                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1446,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 600948989,
    "accountNumber": "35302464616",
    "date": "2011-05-13",
    "amount": "-69.40",
    "text": "Nille 120 Yran Arnavgen Indre Arna",
    "alfareferanse": "13.05 NILLE 120 YRAN  ARNAVGEN INDRE ARNA                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Nips",
    "imageId": "",
    "place": {
      "id": 1446,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 599266684,
    "accountNumber": "35302464616",
    "date": "2011-05-13",
    "amount": "-267.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "13.05 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 602379047,
    "accountNumber": "35302464616",
    "date": "2011-05-12",
    "amount": "-300.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "12.05 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 585303527,
    "accountNumber": "35302464616",
    "date": "2011-03-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 584595552,
    "accountNumber": "35302464616",
    "date": "2011-03-16",
    "amount": "-300.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "16.03 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 583556763,
    "accountNumber": "35302464616",
    "date": "2011-03-16",
    "amount": "-49.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "16.03 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 577856813,
    "accountNumber": "35302464616",
    "date": "2011-03-16",
    "amount": "-705.00",
    "text": "Arna Libr/Elitf Ådnavn. 63 Indre Arna",
    "alfareferanse": "16.03 ARNA LIBR/ELITF ÅDNAVN. 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1443,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 583417525,
    "accountNumber": "35302464616",
    "date": "2011-03-16",
    "amount": "-59.00",
    "text": "Cubus Avd 922 Ådnavegen 63 Indre Arna",
    "alfareferanse": "16.03 CUBUS AVD 922 ÅDNAVEGEN 63 INDRE ARNA                                     ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 955,
      "placeId": "ChIJ65gNGX__PEYRhibezMw4KjE",
      "placeName": "Cubus",
      "coordinates": "60.4213039,5.4658068",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/922/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 582976397,
    "accountNumber": "35302464616",
    "date": "2011-03-16",
    "amount": "-95.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "16.03 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 583556096,
    "accountNumber": "35302464616",
    "date": "2011-03-15",
    "amount": "-49.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "15.03 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 584538166,
    "accountNumber": "35302464616",
    "date": "2011-03-15",
    "amount": "-700.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "15.03 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 583659003,
    "accountNumber": "35302464616",
    "date": "2011-03-15",
    "amount": "-40.70",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "15.03 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 578489034,
    "accountNumber": "35302464616",
    "date": "2011-03-07",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "05.03 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 567991373,
    "accountNumber": "35302464616",
    "date": "2011-01-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566151531,
    "accountNumber": "35302464616",
    "date": "2011-01-17",
    "amount": "-70.00",
    "text": "Facil Kebab Neumannsgt. Bergen",
    "alfareferanse": "15.01 FACIL KEBAB NEUMANNSGT.  BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1181,
      "placeId": "ChIJubG0UAL8PEYRGHDWy_tFU8c",
      "placeName": "Bergen,s shawarma",
      "coordinates": "60.3914694,5.3191779",
      "website": "https://facil-kebab-pizza.business.site/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565241159,
    "accountNumber": "35302464616",
    "date": "2011-01-17",
    "amount": "-179.00",
    "text": "Location Bergen Normannsgt.3 Bergen",
    "alfareferanse": "15.01 LOCATION BERGEN NORMANNSGT.3 BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1221,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565102268,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-199.90",
    "text": "Spaceworld 3015 Strømg. 8 Bergen",
    "alfareferanse": "13.01 SPACEWORLD 3015 STRØMG. 8 BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Spill/musikk",
    "imageId": "",
    "place": {
      "id": 1241,
      "placeId": "ChIJW-e2tK7-PEYRiIwfp35mPOg",
      "placeName": "Spaceworld Soundgarden Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.swsg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566176802,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-68.80",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "14.01 Rimi Bergen Sto rsenter Bergen                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 561654592,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-400.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "14.01 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565438650,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-149.00",
    "text": "Din Sko 1116 Torgallm.8 Bergen",
    "alfareferanse": "13.01 DIN SKO 1116 TORGALLM.8 BERGEN                                            ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1139,
      "placeId": "ChIJSbvXPaj-PEYRjUumXB8BhtY",
      "placeName": "DinSko & ECCO",
      "coordinates": "60.3930466,5.3251284",
      "website": "https://feetfirst.no/sko/dinsko-ecco-galleriet",
      "logoId": 159,
      "merchantName": "DinSko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565672681,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-118.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "13.01 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565430758,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-149.50",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "13.01 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565362583,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-159.00",
    "text": "H&M 747 Galleri Torgallmenni 5014 Bergen",
    "alfareferanse": "13.01 H&M 747 GALLERI TORGALLMENNI 5014 BERGEN                                  ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1310,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565115027,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-199.00",
    "text": "Norli Galleriet Galleriet-To Bergen",
    "alfareferanse": "13.01 NORLI GALLERIET GALLERIET-TO BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Bokhandel",
    "imageId": "",
    "place": {
      "id": 1449,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 565629786,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-123.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "13.01 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566717584,
    "accountNumber": "35302464616",
    "date": "2011-01-14",
    "amount": "-20.00",
    "text": "Narvesen 747 Ole Bullspl. Bergen",
    "alfareferanse": "13.01 NARVESEN 747 OLE BULLSPL. BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 486,
      "placeId": "ChIJZakAJqj-PEYRshajp9bMmoQ",
      "placeName": "Narvesen Torgallmenningen",
      "coordinates": "60.3924148,5.3233702",
      "website": "http://narvesen.no/forside.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 561204922,
    "accountNumber": "35302464616",
    "date": "2011-01-13",
    "amount": "-117.00",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "13.01 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566773524,
    "accountNumber": "35302464616",
    "date": "2011-01-13",
    "amount": "-13.90",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "13.01 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 567281707,
    "accountNumber": "35302464616",
    "date": "2011-01-13",
    "amount": "-500.00",
    "text": "Spv Jernbanen Bergen 5020 Bergen",
    "alfareferanse": "13.01 SPV Jernbanen Bergen    5020 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1199,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566709439,
    "accountNumber": "35302464616",
    "date": "2011-01-12",
    "amount": "-20.90",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "12.01 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566750279,
    "accountNumber": "35302464616",
    "date": "2011-01-10",
    "amount": "-16.50",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "10.01 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 561218429,
    "accountNumber": "35302464616",
    "date": "2011-01-06",
    "amount": "-60.00",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "06.01 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566539077,
    "accountNumber": "35302464616",
    "date": "2011-01-05",
    "amount": "-37.40",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "05.01 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 566271136,
    "accountNumber": "35302464616",
    "date": "2011-01-04",
    "amount": "-59.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "04.01 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 559418482,
    "accountNumber": "35302464616",
    "date": "2010-12-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 559327770,
    "accountNumber": "35302464616",
    "date": "2010-12-27",
    "amount": "-25.00",
    "text": "Kontantuttak Utland",
    "alfareferanse": "KONTANTUTTAK UTLAND                 1 TRANS(ER) TYPE 194   4                    ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1242,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 557615647,
    "accountNumber": "35302464616",
    "date": "2010-12-22",
    "amount": "-15.00",
    "text": "Cafe Opus As Flesland Fly Bergen",
    "alfareferanse": "22.12 CAFE OPUS AS FLESLAND FLY BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1125,
      "placeId": "ChIJJ_9Dt67-PEYRjvrSOiE3VcM",
      "placeName": "Cafe Opus",
      "coordinates": "60.389375,5.3320505",
      "website": "http://www.cafeopus.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 554832329,
    "accountNumber": "35302464616",
    "date": "2010-12-22",
    "amount": "-398.00",
    "text": "H&M 854 Oasen Oasen Bergen 5147 Bergen",
    "alfareferanse": "21.12 H&M 854 OASEN OASEN BERGEN 5147 BERGEN                                    ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1076,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 557542394,
    "accountNumber": "35302464616",
    "date": "2010-12-21",
    "amount": "-24.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "21.12 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 555961179,
    "accountNumber": "35302464616",
    "date": "2010-12-20",
    "amount": "-179.90",
    "text": "Playcom Oasen Fyllingsdalen",
    "alfareferanse": "17.12 PLAYCOM OASEN FYLLINGSDALEN                                               ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Spill/musikk",
    "imageId": "",
    "place": {
      "id": 1205,
      "placeId": "ChIJp13-g8_7PEYRa7Z1LDOsHRI",
      "placeName": "Oasen Senter",
      "coordinates": "60.3489856,5.2909136",
      "website": "http://www.oasen-senter.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 556855671,
    "accountNumber": "35302464616",
    "date": "2010-12-20",
    "amount": "-84.00",
    "text": "Mcdonalds Oasen Folke Bernad Fyllingsdalen",
    "alfareferanse": "17.12 MCDONALDS OASEN FOLKE BERNAD FYLLINGSDALEN                                ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1204,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 551763681,
    "accountNumber": "35302464616",
    "date": "2010-12-16",
    "amount": "-1000.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "15.12 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 551321774,
    "accountNumber": "35302464616",
    "date": "2010-12-16",
    "amount": "-329.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "16.12 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 547779704,
    "accountNumber": "35302464616",
    "date": "2010-11-30",
    "amount": "-53.00",
    "text": "Big Bite Øyrane Ådnavn 63 Indre Arna",
    "alfareferanse": "29.11 BIG BITE ØYRANE ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1440,
      "placeId": "ChIJd_9GqH__PEYRqYsIDGeOR4M",
      "placeName": "Big Bite Øyrane Torg",
      "coordinates": "60.4208905,5.4655642",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 549438094,
    "accountNumber": "35302464616",
    "date": "2010-11-30",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 548854456,
    "accountNumber": "35302464616",
    "date": "2010-11-29",
    "amount": "-300.00",
    "text": "Spv Arna 5261 Indre Arna",
    "alfareferanse": "29.11 SPV Arna                5261 Indre Arna                                   ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1185,
      "placeId": "ChIJC6INGX__PEYRJDW5SxPiQmY",
      "placeName": "Sparebanken Vest Indre Arna",
      "coordinates": "60.4212848,5.4649739",
      "website": "https://www.spv.no/kontakt-oss/kontorer/indre-arna",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 546526201,
    "accountNumber": "35302464616",
    "date": "2010-11-26",
    "amount": "-188.50",
    "text": "Coop Marked Sta . Stanghelle",
    "alfareferanse": "25.11 COOP MARKED STA . STANGHELLE                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1243,
      "placeId": "ChIJX5FMnditPUYR2XFSXFoqcWU",
      "placeName": "Coop Marked Stanghelle",
      "coordinates": "60.5516683,5.7386403",
      "website": "https://coop.no/butikker/marked/stanghelle-1370/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 547465062,
    "accountNumber": "35302464616",
    "date": "2010-11-23",
    "amount": "-79.00",
    "text": "Shell Dale Dalekvam",
    "alfareferanse": "22.11 SHELL DALE   DALEKVAM                                                     ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Drivstoff",
    "imageId": "",
    "place": {
      "id": 666,
      "placeId": "ChIJ04goxdixPUYRwb8N4kGWZ4g",
      "placeName": "Shell",
      "coordinates": "60.5834375,5.7953721",
      "website": "https://find.shell.com/no/fuel/NO_4185-shell-dale",
      "logoId": 45,
      "merchantName": "Shell",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 548075279,
    "accountNumber": "35302464616",
    "date": "2010-11-19",
    "amount": "-29.80",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "19.11 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 542391124,
    "accountNumber": "35302464616",
    "date": "2010-11-18",
    "amount": "-210.20",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "17.11 RIMI Bergen Sto rsenter BERGEN                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 546836674,
    "accountNumber": "35302464616",
    "date": "2010-11-18",
    "amount": "-145.00",
    "text": "Burger King. Strøm Gt. 8 Bergen",
    "alfareferanse": "17.11 BURGER KING. STRØM GT. 8  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 904,
      "placeId": "ChIJW-e2tK7-PEYRWzHjuxL7qmU",
      "placeName": "Burger King Bergen Storsenter",
      "coordinates": "60.3893537,5.33254",
      "website": "http://www.burgerking.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 547426284,
    "accountNumber": "35302464616",
    "date": "2010-11-17",
    "amount": "-81.30",
    "text": "Coop Marked Sta . Stanghelle",
    "alfareferanse": "17.11 COOP MARKED STA . STANGHELLE                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1243,
      "placeId": "ChIJX5FMnditPUYR2XFSXFoqcWU",
      "placeName": "Coop Marked Stanghelle",
      "coordinates": "60.5516683,5.7386403",
      "website": "https://coop.no/butikker/marked/stanghelle-1370/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 547404520,
    "accountNumber": "35302464616",
    "date": "2010-11-11",
    "amount": "-84.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "10.11 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 545434582,
    "accountNumber": "35302464616",
    "date": "2010-11-11",
    "amount": "-481.00",
    "text": "Cubus 875 Bystasjonen 5015 Bergen",
    "alfareferanse": "10.11 CUBUS 875 BYSTASJONEN  5015 BERGEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 880,
      "placeId": "ChIJA9dQkq7-PEYR4sH-IrSqfys",
      "placeName": "Cubus",
      "coordinates": "60.3896262,5.3324917",
      "website": "http://cubus.com/no/Bedriftssider/butikkliste/Norway/875/",
      "logoId": 86,
      "merchantName": "Cubus",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 542881189,
    "accountNumber": "35302464616",
    "date": "2010-11-10",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "10.11 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 547527377,
    "accountNumber": "35302464616",
    "date": "2010-11-09",
    "amount": "-74.00",
    "text": "Kaffehuset Bgo Jernbane 5015 Bergen",
    "alfareferanse": "09.11 KAFFEHUSET BGO JERNBANE 5015 BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1244,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 547304288,
    "accountNumber": "35302464616",
    "date": "2010-11-09",
    "amount": "-93.18",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "09.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 547619767,
    "accountNumber": "35302464616",
    "date": "2010-11-08",
    "amount": "-65.70",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "08.11 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 542856442,
    "accountNumber": "35302464616",
    "date": "2010-11-05",
    "amount": "-400.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "05.11 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 540399689,
    "accountNumber": "35302464616",
    "date": "2010-10-31",
    "amount": "-100.00",
    "text": "Årsgebyr Visa Electron",
    "alfareferanse": "ÅRSGEBYR VISA ELECTRON                                                          ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1239,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 540546831,
    "accountNumber": "35302464616",
    "date": "2010-10-31",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 538962328,
    "accountNumber": "35302464616",
    "date": "2010-10-28",
    "amount": "-49.95",
    "text": "Vero Moda Voss Amfi Voss 5700 Voss",
    "alfareferanse": "27.10 VERO MODA VOSS  AMFI VOSS 5700 VOSS                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1245,
      "placeId": "ChIJH0tEc5HaPUYR7c3amcUGaz0",
      "placeName": "AMFI Voss",
      "coordinates": "60.6285926,6.435131",
      "website": "http://www.amfi.no/voss/?utm_source=google&utm_medium=infoboks&utm_campaign=GMB",
      "logoId": 256,
      "merchantName": "AMFI Voss",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 539196100,
    "accountNumber": "35302464616",
    "date": "2010-10-28",
    "amount": "-30.40",
    "text": "Coop Marked . Granvin",
    "alfareferanse": "27.10 COOP MARKED . GRANVIN                                                     ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1327,
      "placeId": "ChIJ6XgtoSB5PkYRYCRN88ENLlE",
      "placeName": "Coop Marked Granvin",
      "coordinates": "60.5245686,6.7208642",
      "website": "https://coop.no/butikker/marked/granvin-1416/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 539966598,
    "accountNumber": "35302464616",
    "date": "2010-10-25",
    "amount": "-200.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "23.10 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 538642099,
    "accountNumber": "35302464616",
    "date": "2010-10-22",
    "amount": "-75.86",
    "text": "Lerøy Mat As Torgalm. Bergen",
    "alfareferanse": "21.10 LERØY MAT AS TORGALM. BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1193,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 538545905,
    "accountNumber": "35302464616",
    "date": "2010-10-22",
    "amount": "-84.00",
    "text": "Øyrane Pizzeria Øyrane Torg Bergen",
    "alfareferanse": "21.10 ØYRANE PIZZERIA ØYRANE TORG  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Kafe/restaurant",
    "imageId": "",
    "place": {
      "id": 1438,
      "placeId": "ChIJC6INGX__PEYRTgh4-6aQOEc",
      "placeName": "Øyrane Pizzeria",
      "coordinates": "60.4217069,5.4652503",
      "website": "http://www.oyrane-torg.no/apningstider/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 538398149,
    "accountNumber": "35302464616",
    "date": "2010-10-20",
    "amount": "-98.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "20.10 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 539248295,
    "accountNumber": "35302464616",
    "date": "2010-10-20",
    "amount": "-26.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "20.10 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 538002854,
    "accountNumber": "35302464616",
    "date": "2010-10-18",
    "amount": "-141.00",
    "text": "Mf Bergensfjord Strandav 1 Florø",
    "alfareferanse": "17.10 MF BERGENSFJORD STRANDAV 1 FLORØ                                          ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 1247,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 534128687,
    "accountNumber": "35302464616",
    "date": "2010-10-18",
    "amount": "-500.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "15.10 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 538533658,
    "accountNumber": "35302464616",
    "date": "2010-10-18",
    "amount": "-85.00",
    "text": "Mf Raunefjord F Strandav 1 Florø",
    "alfareferanse": "15.10 MF RAUNEFJORD F STRANDAV 1 FLORØ                                          ",
    "mainCategory": "Utgifter",
    "category": "Bil og transport",
    "subCategory": "Ferge",
    "imageId": "",
    "place": {
      "id": 992,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 539082725,
    "accountNumber": "35302464616",
    "date": "2010-10-14",
    "amount": "-40.00",
    "text": "Deli De Luca Torggt 5 Bergen",
    "alfareferanse": "14.10 DELI DE LUCA TORGGT 5 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1439,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 535074914,
    "accountNumber": "35302464616",
    "date": "2010-10-13",
    "amount": "-438.00",
    "text": "Netcom Gsm As",
    "alfareferanse": "Nettgiro til: NETCOM GSM AS Betalt: 13.10.10                                    ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Mobiltelefoni",
    "imageId": "",
    "place": {
      "id": 1248,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 534161676,
    "accountNumber": "35302464616",
    "date": "2010-10-04",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "01.10 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 539228725,
    "accountNumber": "35302464616",
    "date": "2010-10-04",
    "amount": "-28.00",
    "text": "Deli De Luca Torggt 5 Bergen",
    "alfareferanse": "01.10 DELI DE LUCA TORGGT 5 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1439,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 538972491,
    "accountNumber": "35302464616",
    "date": "2010-10-04",
    "amount": "-49.00",
    "text": "Deli De Luca Torggt 5 Bergen",
    "alfareferanse": "01.10 DELI DE LUCA TORGGT 5 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1439,
      "placeId": "ChIJZyZsfwL8PEYROP0CkpDnGyQ",
      "placeName": "Deli De Luca - Ole Bulls Plass",
      "coordinates": "60.3914597,5.3225045",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530407697,
    "accountNumber": "35302464616",
    "date": "2010-10-01",
    "amount": "-35.50",
    "text": "Coop Marked Sta . Stanghelle",
    "alfareferanse": "30.09 COOP MARKED STA . STANGHELLE                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1243,
      "placeId": "ChIJX5FMnditPUYR2XFSXFoqcWU",
      "placeName": "Coop Marked Stanghelle",
      "coordinates": "60.5516683,5.7386403",
      "website": "https://coop.no/butikker/marked/stanghelle-1370/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530573042,
    "accountNumber": "35302464616",
    "date": "2010-09-30",
    "amount": "-22.00",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "30.09 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 531871659,
    "accountNumber": "35302464616",
    "date": "2010-09-30",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530465284,
    "accountNumber": "35302464616",
    "date": "2010-09-29",
    "amount": "-30.40",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "29.09 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530617062,
    "accountNumber": "35302464616",
    "date": "2010-09-29",
    "amount": "-17.90",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "28.09 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 528799333,
    "accountNumber": "35302464616",
    "date": "2010-09-27",
    "amount": "-210.00",
    "text": "Bergen Kino Neumannsgt 3 Bergen",
    "alfareferanse": "24.09 BERGEN KINO NEUMANNSGT 3 BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Medier og kommunikasjon",
    "subCategory": "Kino",
    "imageId": "",
    "place": {
      "id": 1236,
      "placeId": "ChIJ03_zUwL8PEYRMAuLxCtL7UE",
      "placeName": "Bergen Kino",
      "coordinates": "60.3912756,5.3185141",
      "website": "http://www.bergenkino.no/",
      "logoId": 112,
      "merchantName": "Bergen Kino",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530339508,
    "accountNumber": "35302464616",
    "date": "2010-09-27",
    "amount": "-40.40",
    "text": "Lerøy Mat As Torgalm. Bergen",
    "alfareferanse": "25.09 LERØY MAT AS TORGALM. BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1193,
      "placeId": "ChIJSbvXPaj-PEYRDk8nvvFmOac",
      "placeName": "Lerøy Mat Galleriet",
      "coordinates": "60.3930466,5.3251284",
      "website": "http://leroymat.no/",
      "logoId": 73,
      "merchantName": "Lerøy Mat",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 525536929,
    "accountNumber": "35302464616",
    "date": "2010-09-27",
    "amount": "-400.00",
    "text": "Galleriet 2 Dnb Nor Bank",
    "alfareferanse": "25.09 GALLERIET 2  DNB NOR BANK                                                 ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1450,
      "placeId": "ChIJ_dzNbqj-PEYRlaoBDkkA8SA",
      "placeName": "DNB",
      "coordinates": "60.3937679,5.3251252",
      "website": "https://www.dnb.no/",
      "logoId": 304,
      "merchantName": "DNB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530407569,
    "accountNumber": "35302464616",
    "date": "2010-09-24",
    "amount": "-35.50",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "23.09 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530333226,
    "accountNumber": "35302464616",
    "date": "2010-09-24",
    "amount": "-41.00",
    "text": "Big Bite 308 Småstrandgt. Bergen",
    "alfareferanse": "24.09 BIG BITE 308 SMÅSTRANDGT. BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1249,
      "placeId": "ChIJu3S-Xaj-PEYRoD5xGgWY3c8",
      "placeName": "Big Bite",
      "coordinates": "60.3927575,5.3260816",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 525516850,
    "accountNumber": "35302464616",
    "date": "2010-09-24",
    "amount": "-500.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "24.09 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530601250,
    "accountNumber": "35302464616",
    "date": "2010-09-23",
    "amount": "-19.90",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "22.09 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530032253,
    "accountNumber": "35302464616",
    "date": "2010-09-23",
    "amount": "-64.58",
    "text": "Kremmerhuset Neumannsg 2 Bergen",
    "alfareferanse": "22.09 KREMMERHUSET NEUMANNSG 2  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Hjem og bolig",
    "subCategory": "Interiør",
    "imageId": "",
    "place": {
      "id": 1250,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530463070,
    "accountNumber": "35302464616",
    "date": "2010-09-23",
    "amount": "-30.80",
    "text": "Coop Marked Tunesvn. 2 Indre Arna",
    "alfareferanse": "23.09 COOP MARKED TUNESVN. 2 INDRE ARNA                                         ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1240,
      "placeId": "ChIJo03nV9v_PEYRdcJCYZD8c7Q",
      "placeName": "Coop Prix Ytre Arna",
      "coordinates": "60.4600003,5.4375217",
      "website": "https://coop.no/butikker/prix/ytre-arna-1461/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 530302473,
    "accountNumber": "35302464616",
    "date": "2010-09-23",
    "amount": "-44.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "22.09 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 523233787,
    "accountNumber": "35302464616",
    "date": "2010-08-31",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 516365646,
    "accountNumber": "35302464616",
    "date": "2010-08-30",
    "amount": "-600.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "27.08 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 521893571,
    "accountNumber": "35302464616",
    "date": "2010-08-12",
    "amount": "-18.90",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "11.08 RIMI Bergen Sto rsenter BERGEN                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 519553635,
    "accountNumber": "35302464616",
    "date": "2010-08-12",
    "amount": "-299.00",
    "text": "Bik Bok 640 Torgalmening Bergen",
    "alfareferanse": "11.08 BIK BOK 640 TORGALMENING BERGEN                                           ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1283,
      "placeId": "ChIJM-52F6j-PEYRlPWjCquzOgY",
      "placeName": "BIK BOK",
      "coordinates": "60.3922526,5.3239096",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/640/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 521317358,
    "accountNumber": "35302464616",
    "date": "2010-08-06",
    "amount": "-65.00",
    "text": "Narvesen 757 Haukelsykeh Bergen",
    "alfareferanse": "06.08 NARVESEN 757 HAUKELSYKEH  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1251,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 521094140,
    "accountNumber": "35302464616",
    "date": "2010-08-03",
    "amount": "-83.10",
    "text": "Rema 1000 Dag Hammersk Fyllingsdalen",
    "alfareferanse": "02.08 REMA 1000 DAG HAMMERSK FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1201,
      "placeId": "ChIJLbLLcsP7PEYRPs9smXXlDr8",
      "placeName": "Rema 1000 Lynghaugparken",
      "coordinates": "60.3564883,5.2939196",
      "website": "https://www.rema.no/",
      "logoId": 42,
      "merchantName": "REMA 1000",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 519547835,
    "accountNumber": "35302464616",
    "date": "2010-08-02",
    "amount": "-299.00",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "31.07 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 516341282,
    "accountNumber": "35302464616",
    "date": "2010-08-02",
    "amount": "-900.00",
    "text": "Nordea Bergen Bystasjon 2 14:25",
    "alfareferanse": "31.07 NORDEA BERGEN BYSTASJON 2 14:25                                           ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1252,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 514100332,
    "accountNumber": "35302464616",
    "date": "2010-07-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 512507054,
    "accountNumber": "35302464616",
    "date": "2010-07-19",
    "amount": "-39.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "16.07 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 511869014,
    "accountNumber": "35302464616",
    "date": "2010-07-14",
    "amount": "-99.00",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "13.07 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 511442852,
    "accountNumber": "35302464616",
    "date": "2010-07-14",
    "amount": "-149.50",
    "text": "Carlings 50C Strømgt 8 Bergen",
    "alfareferanse": "13.07 CARLINGS 50C STRØMGT 8 BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1253,
      "placeId": "ChIJW-e2tK7-PEYRu1wX7iHM9Sk",
      "placeName": "Carlings",
      "coordinates": "60.3894438,5.3325016",
      "website": "http://carlings.com/",
      "logoId": 214,
      "merchantName": "Carlings",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 511768322,
    "accountNumber": "35302464616",
    "date": "2010-07-14",
    "amount": "-108.50",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "13.07 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 511883098,
    "accountNumber": "35302464616",
    "date": "2010-07-13",
    "amount": "-98.40",
    "text": "Rimi Bergen Sto Rsenter Bergen",
    "alfareferanse": "12.07 RIMI Bergen Sto rsenter BERGEN                                            ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1191,
      "placeId": "ChIJW-e2tK7-PEYRG9m4og6xKu4",
      "placeName": "Extra Bergen Storsenter",
      "coordinates": "60.3893065,5.332543",
      "website": "http://www.bergenstorsenter.no/butikker-og-virksomheter/coop-extra/",
      "logoId": 134,
      "merchantName": "Bergen Storsenter",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 507801459,
    "accountNumber": "35302464616",
    "date": "2010-07-13",
    "amount": "-400.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "12.07 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 511785207,
    "accountNumber": "35302464616",
    "date": "2010-07-12",
    "amount": "-106.00",
    "text": "Mix Dale Imbiss Stasjonsvn. Dalekvam",
    "alfareferanse": "11.07 MIX DALE IMBISS STASJONSVN.  DALEKVAM                                     ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1254,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 513405909,
    "accountNumber": "35302464616",
    "date": "2010-07-09",
    "amount": "-500.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "09.07 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 512642753,
    "accountNumber": "35302464616",
    "date": "2010-07-05",
    "amount": "-23.00",
    "text": "Coop Marked Sta . Stanghelle",
    "alfareferanse": "03.07 COOP MARKED STA . STANGHELLE                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1243,
      "placeId": "ChIJX5FMnditPUYR2XFSXFoqcWU",
      "placeName": "Coop Marked Stanghelle",
      "coordinates": "60.5516683,5.7386403",
      "website": "https://coop.no/butikker/marked/stanghelle-1370/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 512655352,
    "accountNumber": "35302464616",
    "date": "2010-07-05",
    "amount": "-20.50",
    "text": "Coop Marked Sta . Stanghelle",
    "alfareferanse": "03.07 COOP MARKED STA . STANGHELLE                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1243,
      "placeId": "ChIJX5FMnditPUYR2XFSXFoqcWU",
      "placeName": "Coop Marked Stanghelle",
      "coordinates": "60.5516683,5.7386403",
      "website": "https://coop.no/butikker/marked/stanghelle-1370/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 512439664,
    "accountNumber": "35302464616",
    "date": "2010-07-05",
    "amount": "-45.00",
    "text": "Deli De Luca Strømsgt. 4 Bergen",
    "alfareferanse": "03.07 DELI DE LUCA STRØMSGT. 4  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1200,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 511630564,
    "accountNumber": "35302464616",
    "date": "2010-07-05",
    "amount": "-125.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "03.07 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 507322416,
    "accountNumber": "35302464616",
    "date": "2010-07-05",
    "amount": "-326.00",
    "text": "Narvesen 768 Bystasjonen Bergen",
    "alfareferanse": "05.07 NARVESEN 768 BYSTASJONEN  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 815,
      "placeId": "ChIJ1zU0267-PEYRm0ZIjjZwpbo",
      "placeName": "Narvesen",
      "coordinates": "60.3893065,5.332543",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 507322648,
    "accountNumber": "35302464616",
    "date": "2010-07-05",
    "amount": "-325.00",
    "text": "Narvesen 768 Bystasjonen Bergen",
    "alfareferanse": "04.07 NARVESEN 768 BYSTASJONEN  BERGEN                                          ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 815,
      "placeId": "ChIJ1zU0267-PEYRm0ZIjjZwpbo",
      "placeName": "Narvesen",
      "coordinates": "60.3893065,5.332543",
      "website": "http://narvesen.no/butikker/alle.aspx",
      "logoId": 50,
      "merchantName": "Narvesen",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 512287016,
    "accountNumber": "35302464616",
    "date": "2010-07-02",
    "amount": "-59.00",
    "text": "Mix Dale Imbiss Stasjonsvn. Dalekvam",
    "alfareferanse": "01.07 MIX DALE IMBISS STASJONSVN.  DALEKVAM                                     ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1254,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 501941208,
    "accountNumber": "35302464616",
    "date": "2010-06-30",
    "amount": "-348.50",
    "text": "Carlings 50C Strømgt 8 Bergen",
    "alfareferanse": "29.06 CARLINGS 50C STRØMGT 8 BERGEN                                             ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1253,
      "placeId": "ChIJW-e2tK7-PEYRu1wX7iHM9Sk",
      "placeName": "Carlings",
      "coordinates": "60.3894438,5.3325016",
      "website": "http://carlings.com/",
      "logoId": 214,
      "merchantName": "Carlings",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 504067875,
    "accountNumber": "35302464616",
    "date": "2010-06-30",
    "amount": "-49.00",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "29.06 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 503223850,
    "accountNumber": "35302464616",
    "date": "2010-06-29",
    "amount": "-123.00",
    "text": "Avd 271 Apotek Term Fyllingsdalen",
    "alfareferanse": "29.06 AVD 271 APOTEK TERM  FYLLINGSDALEN                                        ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helse",
    "imageId": "",
    "place": {
      "id": 1255,
      "placeId": "ChIJH3-H5rf7PEYRgCTeF2gxbJI",
      "placeName": "Boots apotek Spelhaugen",
      "coordinates": "60.3499212,5.276873",
      "website": "https://www.boots.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 502200603,
    "accountNumber": "35302464616",
    "date": "2010-06-29",
    "amount": "-285.00",
    "text": "Vita 1606 Folkebernado Fyllingsdalen",
    "alfareferanse": "29.06 VITA 1606 FOLKEBERNADO FYLLINGSDALEN                                      ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1256,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 504922253,
    "accountNumber": "35302464616",
    "date": "2010-06-29",
    "amount": "-1000.00",
    "text": "Spv Fyllingsdalen Inne 5147 Fyllingsdalen",
    "alfareferanse": "29.06 SPV Fyllingsdalen Inne  5147 Fyllingsdalen                                ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1017,
      "placeId": "ChIJu8VOec_7PEYRjL876kP9RTI",
      "placeName": "Sparebanken Vest Fyllingsdalen",
      "coordinates": "60.3495056,5.2918366",
      "website": "https://www.spv.no/kontakt-oss/kontorer/fyllingsdalen",
      "logoId": 208,
      "merchantName": "Sparebanken Vest",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 505007832,
    "accountNumber": "35302464616",
    "date": "2010-06-18",
    "amount": "-200.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "18.06 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 503945559,
    "accountNumber": "35302464616",
    "date": "2010-06-10",
    "amount": "-58.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "09.06 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 504993587,
    "accountNumber": "35302464616",
    "date": "2010-06-09",
    "amount": "-300.00",
    "text": "Spv Jernbanen Bergen 5020 Bergen",
    "alfareferanse": "09.06 SPV Jernbanen Bergen    5020 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1199,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 504238201,
    "accountNumber": "35302464616",
    "date": "2010-06-07",
    "amount": "-33.50",
    "text": "Mix Dale Imbiss Stasjonsvn. Dalekvam",
    "alfareferanse": "05.06 MIX DALE IMBISS STASJONSVN.  DALEKVAM                                     ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1254,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 504216548,
    "accountNumber": "35302464616",
    "date": "2010-06-04",
    "amount": "-35.00",
    "text": "Big Bite Storse Strømsgata 8 Bergen",
    "alfareferanse": "03.06 BIG BITE STORSE STRØMSGATA 8 BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Hurtigmat",
    "imageId": "",
    "place": {
      "id": 1257,
      "placeId": "ChIJW-e2tK7-PEYRuFvWjVsw_nY",
      "placeName": "Big Bite",
      "coordinates": "60.3893065,5.332543",
      "website": "https://www.bigbite.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 503927113,
    "accountNumber": "35302464616",
    "date": "2010-06-04",
    "amount": "-59.00",
    "text": "Liv Annes As Bakeriutsalg Dalekvam",
    "alfareferanse": "04.06 LIV ANNES AS BAKERIUTSALG DALEKVAM                                        ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Bakervarer",
    "imageId": "",
    "place": {
      "id": 1258,
      "placeId": "ChIJT25CLsyxPUYRarNdO2Ew5B4",
      "placeName": "Liv Anne's AS",
      "coordinates": "60.5898484,5.8196912",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 500990721,
    "accountNumber": "35302464616",
    "date": "2010-06-02",
    "amount": "-1799.00",
    "text": "Telekiosken Øyr Ådnavn 63 Indre Arna",
    "alfareferanse": "01.06 TELEKIOSKEN ØYR ÅDNAVN 63 INDRE ARNA                                      ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1259,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 504940262,
    "accountNumber": "35302464616",
    "date": "2010-06-02",
    "amount": "-500.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "02.06 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 503984231,
    "accountNumber": "35302464616",
    "date": "2010-06-02",
    "amount": "-54.80",
    "text": "Safari Øyrane Torg. Indre Arna.",
    "alfareferanse": "01.06 SAFARI ØYRANE TORG. INDRE ARNA.                                           ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1184,
      "placeId": "ChIJATjsGn__PEYRYothqZNso9c",
      "placeName": "Øyrane Torg",
      "coordinates": "60.4214084,5.4655769",
      "website": "http://www.oyrane-torg.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 495844726,
    "accountNumber": "35302464616",
    "date": "2010-06-01",
    "amount": "-500.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "01.06 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 489623773,
    "accountNumber": "35302464616",
    "date": "2010-05-07",
    "amount": "-216.50",
    "text": "Coop Marked Sta . Stanghelle",
    "alfareferanse": "07.05 COOP MARKED STA . STANGHELLE                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 1243,
      "placeId": "ChIJX5FMnditPUYR2XFSXFoqcWU",
      "placeName": "Coop Marked Stanghelle",
      "coordinates": "60.5516683,5.7386403",
      "website": "https://coop.no/butikker/marked/stanghelle-1370/",
      "logoId": 40,
      "merchantName": "EXTRA",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 487780105,
    "accountNumber": "35302464616",
    "date": "2010-04-30",
    "amount": "-14.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           2 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 486280556,
    "accountNumber": "35302464616",
    "date": "2010-04-28",
    "amount": "-58.00",
    "text": "Narvesen Strømsgt. 2 Bergen",
    "alfareferanse": "27.04 NARVESEN STRØMSGT. 2  BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1225,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 482140330,
    "accountNumber": "35302464616",
    "date": "2010-04-28",
    "amount": "-600.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "27.04 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 487743441,
    "accountNumber": "35302464616",
    "date": "2010-04-27",
    "amount": "-25.00",
    "text": "Kontantuttak Utland",
    "alfareferanse": "KONTANTUTTAK UTLAND                 1 TRANS(ER) TYPE 194   4                    ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1242,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 487743465,
    "accountNumber": "35302464616",
    "date": "2010-04-27",
    "amount": "-25.00",
    "text": "Kontantuttak Utland",
    "alfareferanse": "KONTANTUTTAK UTLAND                 1 TRANS(ER) TYPE 194   4                    ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1242,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 485042067,
    "accountNumber": "35302464616",
    "date": "2010-04-26",
    "amount": "-239.00",
    "text": "Body Shop Lagunen Rådal",
    "alfareferanse": "23.04 BODY SHOP LAGUNEN RÅDAL                                                   ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Helsekost",
    "imageId": "",
    "place": {
      "id": 1260,
      "placeId": "ChIJX1fNweb5PEYR-KCfDR8Vxy8",
      "placeName": "The Body Shop",
      "coordinates": "60.2969378,5.3307471",
      "website": "https://www.thebodyshop.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 482167796,
    "accountNumber": "35302464616",
    "date": "2010-04-22",
    "amount": "-400.00",
    "text": "Nordea Torgalmenning Bergen 16:20",
    "alfareferanse": "21.04 NORDEA TORGALMENNING BERGEN 16:20                                         ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1328,
      "placeId": "ChIJ-2-7fLn5PEYRkBe_pS1lw80",
      "placeName": "Nordea",
      "coordinates": "60.3174631,5.3546334",
      "website": "https://www.nordea.no/",
      "logoId": 276,
      "merchantName": "Nordea",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 486562205,
    "accountNumber": "35302464616",
    "date": "2010-04-22",
    "amount": "-29.90",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "21.04 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 480085532,
    "accountNumber": "35302464616",
    "date": "2010-03-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 479362631,
    "accountNumber": "35302464616",
    "date": "2010-03-26",
    "amount": "-600.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "26.03 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 473952814,
    "accountNumber": "35302464616",
    "date": "2010-03-22",
    "amount": "-400.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "20.03 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 471171515,
    "accountNumber": "35302464616",
    "date": "2010-03-01",
    "amount": "-100.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "01.03 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 471778360,
    "accountNumber": "35302464616",
    "date": "2010-02-28",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 471660949,
    "accountNumber": "35302464616",
    "date": "2010-02-25",
    "amount": "-25.00",
    "text": "Kontantuttak Utland",
    "alfareferanse": "KONTANTUTTAK UTLAND                 1 TRANS(ER) TYPE 194   4                    ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 1242,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 471115523,
    "accountNumber": "35302464616",
    "date": "2010-02-15",
    "amount": "-500.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "15.02 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 461465161,
    "accountNumber": "35302464616",
    "date": "2010-02-01",
    "amount": "-249.00",
    "text": "Din Sko 1116 Torgallm.8 Bergen",
    "alfareferanse": "30.01 DIN SKO 1116 TORGALLM.8 BERGEN                                            ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Sko",
    "imageId": "",
    "place": {
      "id": 1139,
      "placeId": "ChIJSbvXPaj-PEYRjUumXB8BhtY",
      "placeName": "DinSko & ECCO",
      "coordinates": "60.3930466,5.3251284",
      "website": "https://feetfirst.no/sko/dinsko-ecco-galleriet",
      "logoId": 159,
      "merchantName": "DinSko",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 462133591,
    "accountNumber": "35302464616",
    "date": "2010-02-01",
    "amount": "-129.00",
    "text": "Lindex 224 Galleriet Bergen",
    "alfareferanse": "30.01 LINDEX 224 GALLERIET BERGEN                                               ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1401,
      "placeId": "ChIJSbvXPaj-PEYRvmB-hu5Dg48",
      "placeName": "Lindex",
      "coordinates": "60.3928011,5.3248477",
      "website": "http://www.lindex.com/",
      "logoId": 295,
      "merchantName": "Lindex",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 458473861,
    "accountNumber": "35302464616",
    "date": "2010-02-01",
    "amount": "-200.00",
    "text": "Galleriet 2 Dnb Nor Bank",
    "alfareferanse": "30.01 GALLERIET 2  DNB NOR BANK                                                 ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1450,
      "placeId": "ChIJ_dzNbqj-PEYRlaoBDkkA8SA",
      "placeName": "DNB",
      "coordinates": "60.3937679,5.3251252",
      "website": "https://www.dnb.no/",
      "logoId": 304,
      "merchantName": "DNB",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 464260935,
    "accountNumber": "35302464616",
    "date": "2010-01-31",
    "amount": "-7.00",
    "text": "Uttak minibank hos annen bank",
    "alfareferanse": "PRIS MB-UTTAK IKKE SPV MB           1 TRANS(ER) TYPE 151                        ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Gebyr",
    "imageId": "",
    "place": {
      "id": 533,
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 463637790,
    "accountNumber": "35302464616",
    "date": "2010-01-27",
    "amount": "-100.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "27.01 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 463637463,
    "accountNumber": "35302464616",
    "date": "2010-01-26",
    "amount": "-100.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "26.01 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 462656977,
    "accountNumber": "35302464616",
    "date": "2010-01-22",
    "amount": "-67.28",
    "text": "Meny Bergen Storsenter.",
    "alfareferanse": "22.01 MENY BERGEN STORSENTER.                                                   ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Dagligvarer",
    "imageId": "",
    "place": {
      "id": 543,
      "placeId": "ChIJW-e2tK7-PEYR1NrbZn_tsNE",
      "placeName": "Meny Bergen Storsenter",
      "coordinates": "60.3896628,5.3330003",
      "website": "http://www.meny.no/Info/Finn-din-butikk/MENY-Bergen-Storsenter/",
      "logoId": 56,
      "merchantName": "MENY",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 462878551,
    "accountNumber": "35302464616",
    "date": "2010-01-18",
    "amount": "-45.00",
    "text": "Deli De Luca, S Stølegt. 15 Bergen",
    "alfareferanse": "17.01 DELI DE LUCA, S STØLEGT. 15  BERGEN                                       ",
    "mainCategory": "Utgifter",
    "category": "Mat og drikke",
    "subCategory": "Kiosk",
    "imageId": "",
    "place": {
      "id": 1261,
      "placeId": "ChIJdSi6Cqj-PEYRYbQFwBspz90",
      "placeName": "Deli de Luca Torget",
      "coordinates": "60.3945368,5.3257302",
      "website": "http://delideluca.no/",
      "logoId": 64,
      "merchantName": "Deli de Luca",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 458463885,
    "accountNumber": "35302464616",
    "date": "2010-01-18",
    "amount": "-200.00",
    "text": "Sr-Bank Bergen Storsent 5015 Bergen",
    "alfareferanse": "17.01 SR-Bank Bergen Storsent 5015 Bergen                                       ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1186,
      "placeId": "ChIJubjKtwL8PEYRMqo7i6gN4qk",
      "placeName": "SpareBank 1 SR-Bank",
      "coordinates": "60.3909471,5.3225424",
      "website": "https://www.sparebank1.no/nb/sr-bank/privat/kundeservice/kontakt/bergen.html",
      "logoId": 200,
      "merchantName": "SpareBank 1 SR-Bank",
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 463634557,
    "accountNumber": "35302464616",
    "date": "2010-01-15",
    "amount": "-100.00",
    "text": "Spv Dalekvam 5722 Dalekvam",
    "alfareferanse": "15.01 SPV Dalekvam            5722 Dalekvam                                     ",
    "mainCategory": "Utgifter",
    "category": "Diverse",
    "subCategory": "Minibankuttak",
    "imageId": "",
    "place": {
      "id": 1246,
      "placeId": "ChIJM3v1FsyxPUYRDn5-ZoYn45o",
      "placeName": "Jernia Rokne avd. Dalekvam",
      "coordinates": "60.590142,5.821308",
      "website": "http://www.jernia.no/",
      "logoId": -1,
      "inserted": "0001-01-01T00:00:00"
    }
  },
  {
    "transactionId": 462839873,
    "accountNumber": "35302464616",
    "date": "2010-01-12",
    "amount": "-49.00",
    "text": "Bik Bok 634 Strømgt.8 Bergen",
    "alfareferanse": "11.01 BIK BOK 634 STRØMGT.8 BERGEN                                              ",
    "mainCategory": "Utgifter",
    "category": "Levekostnader",
    "subCategory": "Klær",
    "imageId": "",
    "place": {
      "id": 1214,
      "placeId": "ChIJW-e2tK7-PEYRAqXYBaTmTeQ",
      "placeName": "BIK BOK",
      "coordinates": "60.389297,5.3327229",
      "website": "https://bikbok.com/en/corporate/stores-en/stores/norway/634/",
      "logoId": 104,
      "merchantName": "BIK BOK",
      "inserted": "0001-01-01T00:00:00"
    }
  }
];