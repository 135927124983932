import React from 'react';
import PropTypes from 'prop-types';
import LegendWithSaveStatus from 'components/Form/LegendWithSaveStatus';

const forfallPresentationOptions = [
  {
    label: 'Vis sum forfall',
    value: 'SUM_FORFALL',
  },
  {
    label: 'Vis rest/igjen etter forfall',
    value: 'REST_ETTER_FORFALL',
  },
];

const MySettingsForfallPresentationFieldset = ({
  forfallPresentation,
  updateFilter,
}) => {
  const numberOfExistingForfallPresentationSaveInstances =
    forfallPresentation.savingInstances.length;
  const forfallPresentationStatus =
    numberOfExistingForfallPresentationSaveInstances > 0
      ? forfallPresentation.savingInstances[
        numberOfExistingForfallPresentationSaveInstances - 1
      ]
      : '';

  return (
    <fieldset className="fieldset home-settings__presentation-fieldset">
      <LegendWithSaveStatus
        text="Konto-visning:"
        status={forfallPresentationStatus}
      />
      <div className="form-group" role="radiogroup">
        {forfallPresentationOptions.map((option, i) => (
          <span key={`forfall-presentation-${option.value}`}>
            <input
              checked={forfallPresentation.value === option.value}
              type="radio"
              className="radio"
              id={`forfall-presentation-${i}`}
              name="forfall-presentations"
              value={`forfall-presentation-${i}`}
              onChange={() =>
                updateFilter(
                  option.value,
                  numberOfExistingForfallPresentationSaveInstances,
                )
              }
            />
            <label
              className="radio__label"
              htmlFor={`forfall-presentation-${i}`}
            >
              {option.label}
            </label>
          </span>
        ))}
      </div>
    </fieldset>
  );
};

MySettingsForfallPresentationFieldset.propTypes = {
  forfallPresentation: PropTypes.shape({}).isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default MySettingsForfallPresentationFieldset;
