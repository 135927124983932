import avtalegiroavtaler, { avtalegiroAvtalerForslag } from './avtalegiroavtaler.data';
import efakturaavtaler, { efakturaavtalerForslag } from './efakturaavtaler.data';
import kombinertavtaler, { kombinertavtalerForslag } from './kombinertavtaler.data';

export default [
  ...avtalegiroavtaler,
  ...avtalegiroAvtalerForslag,
  ...efakturaavtaler,
  ...efakturaavtalerForslag,
  ...kombinertavtaler,
  ...kombinertavtalerForslag,
];
