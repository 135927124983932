/* eslint-disable max-len */

import React from 'react';

const Aside = () => (
  <div>
    <section className="published-content">
      <div className="published-content__item published-content__frame">
        <h2>Tips</h2>
        <p>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui
          blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
          et quas molestias excepturi sint occaecati cupiditate non provident,
          similique sunt in culpa qui officia deserunt mollitia animi, id est
          laborum et dolorum fuga.&nbsp;
        </p>
      </div>
    </section>
    <section className="published-content">
      <div className="published-content__item published-content__frame">
        <h2>Oppsalg &amp; lenker</h2>
        <div>
          <ul>
            <li>
              <a href="https://www.spv.no/">Sparebanken Vest</a>
            </li>
            <li>
              <a href="https://www.spv.no/lane">Låne</a>
            </li>
            <li>
              <a href="https://www.spv.no/spare">Spare</a>
            </li>
            <li>
              <a href="https://www.spv.no/kort-og-betaling">Kort og betaling</a>
            </li>
            <li>
              <a href="https://www.spv.no/forsikre">Forsikring</a>
            </li>
          </ul>
          <p>
            Et harum quidem rerum facilis est et expedita distinctio. Nam libero
            tempore, cum soluta nobis est eligendi optio cumque nihil impedit
            quo minus id quod maxime placeat facere possimus, omnis voluptas
            assumenda est, omnis dolor repellendus. Temporibus autem quibusdam
            et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et
            voluptates repudiandae sint et molestiae non recusandae. Itaque
            earum rerum hic tenetur a sapiente delectus, ut aut reiciendis
            voluptatibus maiores alias consequatur aut perferendis doloribus
            asperiores repellat.
          </p>
        </div>
      </div>
    </section>
  </div>
);

Aside.propTypes = {};

export default Aside;
