import styled from 'styled-components';
import { breakpoints } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';

export default styled.div`
  .p-faste-oppdrag {
    &__main,
    &__heading {
      ${horizontalLayoutSpacing({ phone: false })};
    }

    &__collection {
      &--empty {
        .info-card {
          border-top: 0;
        }
      }
    }

    .nav-list {
      ${horizontalLayoutSpacing({ type: 'margin' })};
      @media (min-width: ${breakpoints.tablet}px) {
        margin: 0;
      }
    }
  }
`;
