import styled from 'styled-components';
import { breakpoints, colors } from 'digitalbank-core-style';
import { rem } from 'polished';

export default styled.div`
  .scheduled-payments-filter {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}px) {
      padding: ${`${rem(5)} ${rem(20)}`};
    }

    &__toggle {
      /*
      @include disable-highlighting; */
      border: 0;
      background: none;
      appearance: none;
      padding: 0;
      cursor: pointer;
      outline: none;
      height: ${rem(45)};
      text-align: left;
      width: 100%;

      &-content {
        display: flex;
        align-items: flex-start;
        align-self: flex-end;
        justify-content: space-between;
      }

      &-chevron {
        display: flex;
        width: ${rem(12)};
        height: ${rem(12)};
        color: ${colors.dustyGray};
        transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
        transform-style: preserve-3d;
        transform-origin: 50% 50%;
        transform: rotate(0);

        &--opened {
          transform: rotate(180deg);
        }
      }

      &-chevron-text {
        display: none;

        @media (min-width: ${breakpoints.tablet}px) {
          color: ${colors.dustyGray};
          font-size: ${rem(14)};
          line-height: 1;
          display: flex;
          margin-right: ${rem(10)};
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;

      &--opened {
        margin-bottom: ${rem(20)};
      }

      &-summary {
        font-size: ${rem(15)};
        line-height: 1.5;
        display: flex;
        align-self: flex-start;
        justify-content: flex-start;
        width: calc(100% - 40px);

        &__account,
        &__date {
          &-label,
          &-text {
            margin: 0;
            font-size: ${rem(15)};
          }

          &-label {
            color: ${colors.dustyGray};
            line-height: 1;
          }

          &-text {
            line-height: 1.5;
          }
        }

        &__account {
          width: 50%;
          margin-right: ${rem(15)};

          &-text {
            @media (max-width: ${breakpoints.tablet}px) {
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-top: ${rem(20)};
    }

    &__date-dropdown-container {
      /* @include disable-highlighting; */
      width: ${rem(90)};
      display: flex;
      justify-content: flex-end;
    }

    &__date-dropdown {
      /* @include disable-highlighting; */

      @media (min-width: ${breakpoints.tablet}px) {
        margin-left: ${rem(10)};
      }
    }

    &__date-option {
      /* @include disable-highlighting; */
      display: flex;
      justify-content: flex-start;

      @media (max-width: ${breakpoints.tablet}px) {
        justify-content: space-between;
      }
    }

    &__accountlist {
      width: 100%;

      @media (min-width: ${breakpoints.tablet}px) {
        width: ${rem(318)};
      }
    }

    &__date-options-heading {
      color: ${colors.boulder};
      font-size: ${rem(13)};
      margin-top: ${rem(20)};
      margin-bottom: ${rem(1)};
    }

    &__collapse {
      transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
`;
