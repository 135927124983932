import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const TransactionCardMap = withGoogleMap(({ lat, lng }) => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat, lng }}
    clickableIcons={false}
    options={{
      disableDefaultUI: true,
    }}
  >
    <Marker position={{ lat, lng }} />
  </GoogleMap>
));

export default TransactionCardMap;
