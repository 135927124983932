/* eslint-disable no-script-url */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Fields, reduxForm, formValueSelector } from 'redux-form';
import { InputField } from 'digitalbank-form';
import Button from 'digitalbank-buttons';
// import postalCodes from 'norway-postal-codes';
import PhoneField from 'digitalbank-form/lib/es/components/PhoneField/PhoneField';
import {
  all as allCountries,
  findCountries,
  findCountryPhoneCode,
} from 'spv-country-data';
import {
  asYouType as FormatAsYouType,
  format as formatNumber,
} from 'libphonenumber-js';

const BankIDOrderFormContainer = ({
  handleSubmit,
  onSubmitSuccess,
  // postnummer,
  phoneCountryCode,
}) => (
  // const location = postalCodes[postnummer];
  <form
    action="javascript:void(0);"
    onSubmit={handleSubmit(onSubmitSuccess)}
    className="efaktura-fra-alle-form"
  >
    <fieldset className="fieldset">
      {/* <Field name="adresse" component={InputField} label="Adresse" />
        <div className="contact-form__location">
          <Field
            name="postnummer"
            component={InputField}
            type="tel"
            size="10"
            hasAutoWidth
            label="Postnummer"
            maxLength="4"
            rightAddon={() => (
              <div
                className="contact-form__location-name"
                aria-live="polite"
                role="status"
              >
                {location && location}
              </div>
            )}
          />
        </div> */}
      <Field
        name="epost"
        component={InputField}
        label="E-post"
        transformableLabel={false}
        placeholder="Skriv e-postadresse"
      />
    </fieldset>
    <fieldset className="fieldset">
      <legend className="legend-simple">Mobil</legend>
      <Fields
        names={['phone', 'phoneCountryCode']}
        component={PhoneField}
        countriesShortList={findCountries('NO, GB, SE')}
        countries={allCountries}
        numberField={{
          name: 'phone',
          label: 'Mobil',
          ariaLabel: 'Mobil',
          normalize: value =>
            value && new FormatAsYouType(phoneCountryCode).input(value),
        }}
        countryCodeField={{
          name: 'phoneCountryCode',
          label: 'Land',
          ariaLabel: 'Land',
          findCountryPhoneCode,
        }}
      />
    </fieldset>
    <Button variant="primary" style={{ width: '100%' }} type="submit">
      Lagre
    </Button>
  </form>
);

BankIDOrderFormContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  // postnummer: PropTypes.string,
  phoneCountryCode: PropTypes.string,
};

BankIDOrderFormContainer.defaultProps = {
  // postnummer: undefined,
  phoneCountryCode: undefined,
};

const form = reduxForm({
  form: 'bankIDOrderForm',
  destroyOnUnmount: false,
})(BankIDOrderFormContainer);

const mapStateToProps = (state) => {
  const selector = formValueSelector('bankIDOrderForm');
  const { bankIDInformation } = state.user;
  return {
    postnummer: selector(state, 'postnummer'),
    phoneCountryCode: selector(state, 'phoneCountryCode'),
    initialValues: {
      adresse: bankIDInformation.address,
      postnummer: bankIDInformation.zipCode,
      phone: formatNumber(
        {
          country: bankIDInformation.phoneCountryCode,
          phone: bankIDInformation.phone,
        },
        'National',
      ),
      phoneCountryCode: bankIDInformation.phoneCountryCode,
      epost: bankIDInformation.email,
    },
  };
};

export default connect(mapStateToProps)(form);
