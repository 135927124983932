export default ({ kroner, tilKonto, fraKonto }) => {
  const errors = {};
  if (!kroner) {
    errors.kroner = 'Mangler beløp';
  }
  if (!Number(kroner)) {
    errors.kroner = 'Beløpet er ikke gyldig';
  }
  if (!tilKonto) {
    errors.tilKonto = 'Kontonummer mangler';
  }
  if (!fraKonto) {
    errors.fraKonto = 'Kontonummer mangler';
  }
  return errors;
};
