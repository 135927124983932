/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField } from 'digitalbank-form';
import classNames from 'classnames';
import Collapse from 'react-css-collapse';
import { QuestionMark } from 'digitalbank-icons';

const KidField = ({ state, setState }) => (
  <div>
    <Field
      name="kid"
      label="KID-nummer"
      transformableLabel={false}
      component={InputField}
      type="text"
      rightAddon={() => (
        <button
          type="button"
          className="form-control__help-button"
          onClick={() =>
            setState({
              help: { ...state.help, kid: !state.help.kid },
            })
          }
        >
          <QuestionMark
            className="form-control__help-button-icon"
            active={state.help.kid}
          />
        </button>
      )}
    />
    <Collapse isOpen={state.help.kid} className="input-help-animation">
      <div
        className={classNames('input-help input-help--with-triangle', {
          'input-help--is-active': state.help.kid,
        })}
      >
        <div
          className="input-help__content"
          dangerouslySetInnerHTML={{
            __html: '<p>Tips: Du finner KID-nummeret på regningen</p>',
          }}
        />
      </div>
    </Collapse>
  </div>
);

KidField.propTypes = {
  state: PropTypes.shape({}).isRequired,
  setState: PropTypes.func.isRequired,
};

export default KidField;
