import styled from 'styled-components';
import {
  breakpoints,
  colors,
  utilityColors,
  layout,
  spacing,
} from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-scheduled-payments {
    &__main {
      ${horizontalLayoutSpacing({ phone: false })};
    }

    &__heading {
      ${horizontalLayoutSpacing({ tablet: false })};
    }

    &__aside {
      @media (max-width: ${breakpoints.tablet - 1}px) {
        padding: ${rem(spacing.layoutSpacing.md)}
          ${rem(layout.horizontalSpacing.phone)} 0;
      }

      @media (min-width: ${breakpoints.tablet}px) {
        padding: 0 ${rem(layout.horizontalSpacing.phone)};
      }
    }

    &__efaktura-container {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
    }

    &__navigation {
      display: inline-block;
      width: 100%;
      border-top: solid transparent ${rem(1)};
      border-bottom: solid ${`${colors.alto} ${rem(1)}`};
      transition: background-color 250ms linear;
      margin-bottom: ${rem(spacing.layoutSpacing.md)};

      @media (max-width: ${breakpoints.tablet - 1}px) {
        padding: ${rem(10)};
      }

      @media (min-width: ${breakpoints.desktop}px) {
        margin-bottom: ${rem(20)};
      }

      &--opened {
        background-color: #fff;
        border-top: solid ${`${colors.alto} ${rem(1)}`};
        transition: background-color 250ms linear;
      }
    }

    &__collection {
      margin-bottom: ${rem(spacing.layoutSpacing.md)};

      &--empty {
        margin-bottom: 0;

        .info-card {
          border-top: 0;
        }

        .info-card__heading {
          margin: 0;
        }
      }

      ~ .p-scheduled-payments__collection {
        margin-bottom: ${rem(spacing.layoutSpacing.md)};
      }

      .accordion-item {
        &__header {
          background-color: #fff;
          transition: background-color 100ms linear;
        }

        &-header__info {
          font-size: ${rem(14)};
          justify-content: space-between;
          align-items: center;
          /* vi flexer heading i stedet. */
          flex: none;
        }

        &-header__heading {
          margin-right: 0;
          flex: 1;
          min-width: 1px;
          /* hack for at text-overflow: ellipsis skal slå inn */

          &-description {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        &--is-open {
          .accordion-item__header {
            background-color: transparent;
          }
        }
      }

      .accordion-item-header--is-negative {
        .accordion-item-header__heading,
        .accordion-item-header__heading-info,
        .accordion-item-header__info {
          color: ${utilityColors.negative};
        }
      }
    }

    &__account-field,
    &__date-field {
      /* reset default form-group style */
      margin-bottom: 0;
    }

    &__account-field {
      width: 50%;
      padding-right: ${rem(layout.horizontalSpacing.phone)};
      float: left;
      /* we need this because of the date-field */
      position: relative;
      z-index: 1;
    }

    &__date-field {
      width: 50%;
    }

    &__summary {
      color: ${colors.tundora};
      display: flex;
      justify-content: space-between;
      margin-top: ${rem(60)};
      @media (max-width: ${breakpoints.tablet - 1}px) {
        /* sett kun for små skjermer */
        padding-left: ${rem(10)};
        padding-right: ${rem(10)};
      }
    }

    .calendar-field {
      &--active {
        .calendar-field__wrapper {
          padding-bottom: ${rem(85)};
          /* Tabbar space + some padding */
          @media (min-width: ${breakpoints.tablet}px) {
            padding-bottom: ${rem(20)};
          }
        }
      }

      &__wrapper {
        @media (min-width: ${breakpoints.tablet}px) {
          width: auto;
          min-width: 50%;
          right: inherit;
          left: 50%;
        }
      }
    }
  }
`;
