import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { formatAccountNumber } from 'utils/format.util';
import { updateDisplayName } from 'actions/accounts.actions';
import _ from 'lodash';
import StatusIndicator from 'components/StatusIndicator';

export const EditAccountNameElement = ({
  account,
  setDisplayName,
  editAccountNamesStatus,
}) => {
  // Get status
  const indexOfAccount = _.findIndex(
    editAccountNamesStatus,
    x => x.accountId === account.id,
  );
  let status = '';
  let numberOfExistingSavingInstances = 0;

  if (indexOfAccount > -1) {
    const editStatus = editAccountNamesStatus[indexOfAccount];
    numberOfExistingSavingInstances = editStatus.savingInstances.length;
    status = editStatus.savingInstances[numberOfExistingSavingInstances - 1];
  }

  return (
    <li key={`account-names-${account.id}`} className="edit-account-element">
      <div className="edit-account-element__info">
        <span className="edit-account-element__info-heading">
          {account.accountName}
        </span>
        <span className="edit-account-element__info-description">
          {formatAccountNumber(account.accountNumber)}
        </span>
      </div>
      <div className="edit-account-element__input">
        <div className="form-group edit-account-element__input-field">
          <label
            className="label label--hidden"
            htmlFor={`account-name-${account.id}`}
          >
            Kontonavn
          </label>
          <input
            className="form-control"
            type="text"
            name={`account-name-${account.id}`}
            placeholder={account.visningsnavn || 'Eget kontonavn'}
            value={account.visningsnavn}
            onChange={e =>
              setDisplayName(e.target.value, numberOfExistingSavingInstances)
            }
          />
        </div>
        <StatusIndicator status={status} />
      </div>
    </li>
  );
};

EditAccountNameElement.propTypes = {
  account: PropTypes.shape({}).isRequired,
  setDisplayName: PropTypes.func.isRequired,
  editAccountNamesStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

const EditAccountNamesListContainer = ({
  accounts,
  onUpdateDisplayName,
  editAccountNamesStatus,
}) => (
  <section>
    <h2>Gi kontoene egne navn</h2>
    <ul className="edit-account-names-list">
      {accounts.map(account => (
        <EditAccountNameElement
          key={`edit-account-element-${account.id}`}
          account={account}
          setDisplayName={(value, index) =>
            onUpdateDisplayName(account.id, value, index)
          }
          editAccountNamesStatus={editAccountNamesStatus}
        />
      ))}
    </ul>
  </section>
);

EditAccountNamesListContainer.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onUpdateDisplayName: PropTypes.func.isRequired,
  editAccountNamesStatus: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default connect(
  state => ({
    editAccountNamesStatus: state.editAccountNamesStatus,
  }),
  {
    onUpdateDisplayName: updateDisplayName,
  },
)(EditAccountNamesListContainer);
