import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import dataSelector from 'redux-helpers/dataSelector';
import { change } from 'redux-helpers/data.actions';
import { getFormValues, isPristine } from 'redux-form';
import PropTypes from 'prop-types';
import i18n from 'i18n';
import { formatAccountNumber } from 'utils/format.util';
import * as PATHS from 'routes/paths';
import ReauthWrapper from 'components/Reauth/ReauthWrapper';
import wizardPage from 'components/wizardPage';
import { getFrekvensString } from 'utils/frekvens.util';
import { updateTransactionWithFormValues } from 'services/transactions.service';
import { FAST_BETALING_EDIT_FORM_NAME } from '../payment.constants';

const EditFastBetalingReauth = ({
  values,
  history,
  transactions,
  recurringTransactions,
  changeData,
  match: {
    params: { id },
  },
}) => {
  const {
    kroner, oere, tilKonto, frekvens,
  } = values;
  const amount = i18n(i => i.NUMBER_TWO_DIGITS, {
    number: Number(kroner) + Number(oere / 100),
  });
  const account = formatAccountNumber(tilKonto);
  const submit = () => {
    // NOTE: Update both transaction in recurringTransactions and transactions
    updateTransactionWithFormValues(
      'recurringTransactions',
      recurringTransactions,
      changeData,
      id,
      values,
    );
    updateTransactionWithFormValues(
      'transactions',
      transactions,
      changeData,
      id,
      values,
    );
    setTimeout(
      () => history.push(`${PATHS.FAST_BETALING_EDIT_RECEIPT}/${id}`),
      1500,
    );
  };
  return (
    <ReauthWrapper
      description={`Godkjenn fast betaling av ${amount} til ${account} ${getFrekvensString(
        frekvens,
      ).toLowerCase()}`}
      onSubmit={submit}
      goBack={history.goBack}
    />
  );
};

EditFastBetalingReauth.propTypes = {
  values: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  transactions: PropTypes.shape({}).isRequired,
  recurringTransactions: PropTypes.shape({}).isRequired,
  changeData: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  changeData: bindActionCreators(change, dispatch),
});

const mapStateToProps = state => ({
  values: getFormValues(FAST_BETALING_EDIT_FORM_NAME)(state),
  transactions: dataSelector(state, 'transactions'),
  recurringTransactions: dataSelector(state, 'recurringTransactions'),
});

export default wizardPage({
  redirectTest: state => isPristine(FAST_BETALING_EDIT_FORM_NAME)(state),
  redirectPath: PATHS.FASTE_OPPDRAG,
})(connect(mapStateToProps, mapDispatchToProps)(EditFastBetalingReauth));
