import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { InputField } from 'digitalbank-form';
import { showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb } from 'digitalbank-form/lib/es/utils/showErrorCallbacks.util';
import classNames from 'classnames';
import ClearInputFieldButton from 'digitalbank-form/lib/es/components/ClearInputFieldButton';
import i18n from 'i18n';
import { Account } from 'digitalbank-icons';

const recipientButton = toggleReceivers => (
  <button
    type="button"
    className="form-control__button ignore-react-onclickoutside"
    onClick={toggleReceivers}
  >
    <Account className="form-control__button-icon" />
  </button>
);

const RecipientField = ({
  tilKonto,
  clearToAccountField,
  toggleReceivers,
  disabled,
  isSkeleton,
}) => (
  <div
    className={classNames('connected-form-groups recipient-field', {
      skeleton: isSkeleton,
    })}
  >
    <Field
      name="tilKonto"
      disabled={disabled}
      label={i18n(i => i.TIL_KONTO_INPUT_LABEL)}
      component={InputField}
      type="text"
      displayError={false}
      InlineElement={
        <ClearInputFieldButton
          onClick={clearToAccountField}
          ariaLabel="Tøm innhold i til-konto"
        />
      }
      rightAddon={() => {
        if (!disabled) {
          return recipientButton(toggleReceivers);
        }
        return null;
      }}
    />
    <Field
      type="text"
      name="tilKontonavn"
      disabled={disabled}
      label="Mottaker navn"
      transformableLabel={false}
      rightAddon={() => <span className="placeholder" />}
      displayError={false}
      component={InputField}
    />
    <div
      className={classNames('form-error', {
        'form-error--active': tilKonto.meta.error,
      })}
      role="alert"
    >
      {showErrorWhenSubmitFailedOrWhenDirtyAndTouchedCb(tilKonto.meta) && (
        <span>{tilKonto.meta.error}</span>
      )}
    </div>
  </div>
);

RecipientField.propTypes = {
  tilKonto: PropTypes.shape({}).isRequired,
  clearToAccountField: PropTypes.func.isRequired,
  toggleReceivers: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isSkeleton: PropTypes.bool,
};

RecipientField.defaultProps = {
  disabled: false,
  isSkeleton: false,
};

export default RecipientField;
