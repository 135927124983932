import styled from 'styled-components';
import { breakpoints, layout, spacing, colors } from 'digitalbank-core-style';
import {
  horizontalLayoutSpacing,
  verticalLayoutSpacing,
} from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

// const nameContainerWidthSmall = 118;
const dateContainerWidthSmall = 70;
const amountContainerWidthSmall = 80;
const amountContainerWidthLarge = 120;
// const chevronContainerWidthSmall = 32;
// const chevronContainerWidthLarge = 42;
// const alertTextWidthLarge = 120;
// const infoContainerWidthSmall =
//   dateContainerWidthSmall +
//   amountContainerWidthSmall +
//   chevronContainerWidthSmall;
const horizontalPaddingLarge = '20px';

export default styled.div`
  .scheduled-payments-dropdown {
    &__heading-info {
      color: inherit;
      font-size: ${rem(14)};
      line-height: 1.1;

      @media (min-width: ${breakpoints.tablet}) {
        display: none;
      }

      .accordion-item--is-open & {
        font-weight: 500;
      }
    }

    &__alert-text {
      display: none;
      @media (min-width: ${breakpoints.tablet}) {
        padding: 0 0 0 ${horizontalPaddingLarge};
        display: block;
        font-weight: 500;
      }
    }

    &__date {
      padding-left: ${rem(layout.horizontalSpacing.phone)};
      color: ${colors.dustyGray};
      text-align: right;
      min-width: ${rem(dateContainerWidthSmall)};

      .accordion-item-header--is-negative & {
        color: inherit;
      }
    }

    &__amount {
      padding-left: ${rem(layout.horizontalSpacing.phone)};
      color: inherit;
      font-size: ${rem(14)};
      text-align: right;
      min-width: ${rem(amountContainerWidthSmall)};

      @media (min-width: ${breakpoints.tablet}) {
        font-size: ${rem(16)};
        padding-left: 0;
        min-width: ${rem(amountContainerWidthLarge)};
      }
    }

    &__collapse-info {
      ${horizontalLayoutSpacing};
      padding-left: ${rem(layout.horizontalSpacing.phone)};
      font-size: ${rem(14)};
      font-weight: 400;

      &-alert {
        padding-top: ${rem(spacing.layoutSpacing.xs)};
        padding-bottom: ${rem(spacing.layoutSpacing.xs)};
        &-text {
          font-size: ${rem(15)};
          justify-content: center;
        }
      }
    }

    &__collapse-no-actions {
      &-container {
        ${horizontalLayoutSpacing};
        ${verticalLayoutSpacing};
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        box-shadow: inset 0 ${rem(1)} 0 ${colors.alto};
      }

      &-text {
        font-size: ${rem(14)};
      }
    }

    &__estimated-currency-container {
      margin-top: ${rem(10)};

      @media (max-width: ${breakpoints.tablet}) {
        margin-left: ${rem(10)};
      }

      &--compact {
        margin-top: 0;
      }
    }
  }
`;
