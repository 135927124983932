import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-css-collapse';
import Button from 'digitalbank-buttons';

class LoanHistoryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const { button, children, ...other } = this.props;
    const { isOpen } = this.state;
    return (
      <div {...other}>
        <Collapse isOpen={this.state.isOpen} className="collapse-transition">
          {children}
        </Collapse>
        <Button
          variant="link"
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          {!isOpen && button.contentWhenClosed}
          {isOpen && button.contentWhenOpen}
        </Button>
      </div>
    );
  }
}

LoanHistoryDetails.defaultProps = {
  button: {
    contentWhenClosed: 'Vis detaljer',
    contentWhenOpen: 'Vis mindre',
  },
};

LoanHistoryDetails.propTypes = {
  children: PropTypes.node.isRequired,
  button: PropTypes.shape({
    contentWhenClosed: PropTypes.node,
    contentWhenOpen: PropTypes.node,
  }),
};

export default LoanHistoryDetails;
