export default {
  NUMBER_OF_STOPPED_PAYMENTS_PLURAL: {
    'nb-NO': 'Du har { antall } stoppede betalinger',
  },
  NUMBER_OF_STOPPED_PAYMENTS_SINGLE: {
    'nb-NO': 'Du har 1 stoppet betaling',
  },
  NUMBER_OF_NEW_INVOICE_PLURAL: {
    'nb-NO': 'Du har { antall } nye eFakturaer',
  },
  NUMBER_OF_NEW_INVOICE_SINGLE: {
    'nb-NO': 'Du har 1 ny eFaktura',
  },
};
