import React from 'react';
import PropTypes from 'prop-types';
import { BasePage } from 'digitalbank-layout';
import historyShape from 'shapes/history.shape';
import EfakturaFormContainer from './EfakturaFormContainer';
import BetalingsavtaleStyled from '../BetalingsavtaleStyled';

const EfakturaCreatePage = ({ params, history }) => (
  <BetalingsavtaleStyled>
    <BasePage heading="Opprett eFaktura" className="p-betalingsavtale">
      <EfakturaFormContainer
        history={history}
        tilbyderId={params ? params.tilbyderId : undefined}
        submitButtonText="Opprett eFaktura"
      />
    </BasePage>
  </BetalingsavtaleStyled>
);

EfakturaCreatePage.defaultProps = {
  params: undefined,
};

EfakturaCreatePage.propTypes = {
  history: historyShape.isRequired,
  params: PropTypes.shape({
    tilbyderId: PropTypes.string,
  }),
};

export default EfakturaCreatePage;
