import { combineReducers } from 'redux';
import settings from 'reducers/userSettings.reducer';
import {
  REMOVE_SPERRET_EFAKTURA_AVSENDER,
  ADD_SPERRET_EFAKTURA_AVSENDER,
} from 'routes/Avtaleadministrasjon/EfakturaFraAlle/efakturaFraAlle.actions';

const initialInformation = {
  address: 'Tarlebøveien 68',
  addressInfo: '',
  zipCode: '5031',
  phone: '94321234',
  phoneCountryCode: 'NO',
  otherPhone: '',
  otherPhoneCountryCode: 'NO',
  email: 'kari.nordmann@gmail.com',
  otherEmail: '',
  foreignerAddress: '',
  foreignerCountry: '',
};

const initialBankIDInformation = {
  address: initialInformation.address,
  zipCode: initialInformation.zipCode,
  email: initialInformation.email,
  phone: initialInformation.phone,
  phoneCountryCode: initialInformation.phoneCountryCode,
};

const initialEfakturaFraAlle = {
  ...initialBankIDInformation,
  mailNotification: false,
};

// const initialSperredeAvsendere = ['NOR813009862-1', 'NOR976390512-1'];
const initialSperredeAvsendere = [];

const information = (state = initialInformation, action = {}) => {
  switch (action.type) {
    case 'USER_SETTINGS/UPDATE':
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

const efakturaFraAlle = (state = initialEfakturaFraAlle, action = {}) => {
  switch (action.type) {
    case 'USER_EFAKTURA_FRA_ALLE/UPDATE':
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

const bankIDInformation = (state = initialBankIDInformation, action = {}) => {
  switch (action.type) {
    case 'USER_BANKID_INFORMATION/UPDATE':
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

const sperredeAvsendere = (state = initialSperredeAvsendere, action = {}) => {
  switch (action.type) {
    case REMOVE_SPERRET_EFAKTURA_AVSENDER: {
      const indexOfSperretAvsender = state.indexOf(action.id);
      return [
        ...state.slice(0, indexOfSperretAvsender),
        ...state.slice(indexOfSperretAvsender + 1),
      ];
    }
    case ADD_SPERRET_EFAKTURA_AVSENDER: {
      return [...state, action.id];
    }
    default:
      return state;
  }
};

export default combineReducers({
  settings,
  information,
  efakturaFraAlle,
  bankIDInformation,
  sperredeAvsendere,
});
