import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// import 'moment/locale/nb';

/* eslint-disable react/no-array-index-key */

// Component will receive date, locale and localeUtils props
const DatePickerCaption = ({
  date, onChange, fromMonth, toMonth,
}) => {
  const fromYear = moment(fromMonth).year();
  const toYear = moment(toMonth).year();
  const years = [];

  for (let i = fromYear; i <= toYear; i += 1) {
    years.push(i);
  }

  return (
    <div className="DayPicker-Caption">
      <div className="DayPicker-caption-select-wrapper">
        <select
          className="DayPicker-caption-select DayPicker-caption-select--month"
          name="month"
          onChange={(e) => {
            onChange(new Date(date.getFullYear(), e.target.value));
          }}
          value={date.getMonth()}
        >
          {moment.months().map((month, i) => (
            <option key={i} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          className="DayPicker-caption-select DayPicker-caption-select--year"
          name="year"
          onChange={e => onChange(new Date(e.target.value, date.getMonth()))}
          value={date.getFullYear()}
        >
          {years.map(i => (
            <option key={`year-${i}`} value={i}>
              {i}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

DatePickerCaption.propTypes = {
  onChange: PropTypes.func.isRequired,
  date: PropTypes.instanceOf(Date), // Passed down from DayPicker
  fromMonth: PropTypes.instanceOf(Date).isRequired,
  toMonth: PropTypes.instanceOf(Date).isRequired,
};

DatePickerCaption.defaultProps = {
  date: null,
};

export default DatePickerCaption;
