export default (values) => {
  const errors = {};
  if (!values.kroner) {
    errors.kroner = 'Mangler beløp';
  }
  if (!values.tilKonto) {
    errors.tilKonto = 'Kontonummer mangler';
  }
  if (!values.fraKonto) {
    errors.fraKonto = 'Kontonummer mangler';
  }
  return errors;
};
