import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UlWithoutStyle } from 'styled';
import { PaperClip } from 'digitalbank-icons';

const CategorySummaryHeading = styled.h4`
  margin: 0;
  padding: 0 0 16px;
  text-transform: uppercase;
  font-weight: bold;
`;

const CategorySummaryFooter = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  /* color: #444; */
  /* border-bottom: 1px solid #888; */
  /* padding-bottom: 8px; */
`;

export const CategorySummaryStyled = styled.div`
  background: #fff;
  padding: 24px 18px;

  margin-bottom: 18px;
`;

const Transactions = styled(UlWithoutStyle)`
  margin-bottom: 18px;
`;

const Transaction = styled.li`
  border-bottom: 1px solid #ddd;
  padding: 8px 0;

  svg {
    width: 12px;
    /* margin-left: 12px; */
  }
`;

const TransactionText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`;

const TransactionDescription = styled.div`
  display: flex;
  align-items: center;
  color: #777;
  font-size: 14px;

  svg {
    margin-right: 4px;
  }
`;

const CategorySummary = ({ category }) => (
  <CategorySummaryStyled>
    <CategorySummaryHeading>{category.name}</CategorySummaryHeading>
    <Transactions>
      {category.transactions.map((transaction, i) => (
        <Transaction key={`transaction-${transaction.transactionId}`}>
          {/* {transaction.text} - {transaction} */}
          <TransactionText>
            <div>{`${transaction.text}`}</div>
            <div>
              {Number(transaction.amount).toLocaleString('nb-NO', {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </div>
          </TransactionText>
          {Math.floor(Math.random() * 5) > 3 && (
            <TransactionDescription>
              <PaperClip />
              {' Vedlegg X'}
            </TransactionDescription>
          )}
          {/* {JSON.stringify(transaction)} */}
        </Transaction>
      ))}
    </Transactions>
    <CategorySummaryFooter>
      <div>Sum</div>
      <div>
        {Number(category.sum).toLocaleString('nb-NO', {
          style: 'decimal',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </div>
    </CategorySummaryFooter>
  </CategorySummaryStyled>
);

CategorySummary.propTypes = {
  category: PropTypes.shape({}).isRequired,
};
export default CategorySummary;
