import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { QuestionMark } from 'digitalbank-icons';
import { InfoToggleIcon } from 'digitalbank-icons';
import Collapse from 'react-css-collapse';

class TextElementWithTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }
  render() {
    const { isOpen } = this.state;
    const { textElement, tooltipContent } = this.props;
    return (
      <div>
        <div className="text-element-with-tooltip">
          {textElement}
          <button
            type="button"
            className={classNames('text-element-with-tooltip__button', {
              'text-element-with-tooltip__button--is-active': isOpen,
            })}
            onClick={() => this.setState({ isOpen: !isOpen })}
          >
            <InfoToggleIcon
              className="text-element-with-tooltip__button-icon"
              active={isOpen}
            />
          </button>
        </div>
        <Collapse isOpen={isOpen} className="input-help-animation">
          <div
            className={classNames('input-help', {
              'input-help--is-active': isOpen,
            })}
          >
            <div className="input-help__content">{tooltipContent}</div>
          </div>
        </Collapse>
      </div>
    );
  }
}

TextElementWithTooltip.defaultProps = {
  textElement: null,
  tooltipContent: null,
};

TextElementWithTooltip.propTypes = {
  textElement: PropTypes.node,
  tooltipContent: PropTypes.node,
};

export default TextElementWithTooltip;
