import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, isPristine } from 'redux-form';
import PropTypes from 'prop-types';
import i18n from 'i18n';
// import { formatAccountNumber } from 'utils/format.util';
import * as PATHS from 'routes/paths';
import historyShape from 'shapes/history.shape';
import ReauthWrapper from 'components/Reauth/ReauthWrapper';
import wizardPage from 'components/wizardPage';

const Reauth = ({
  values: {
    kroner, oere, recipientName, paymentCurrency,
  },
  history,
}) => {
  const amountNumber = i18n(i => i.NUMBER_TWO_DIGITS, {
    number: Number(kroner) + Number(oere / 100),
  });
  // const account = formatAccountNumber(tilKonto);
  return (
    <ReauthWrapper
      description={i18n(i => i.REAUTH_PAYMENT_DESCRIPTION, {
        amount: `<b>${amountNumber} ${paymentCurrency}</b>`,
        account: `<b>${recipientName}</b>`,
      })}
      onSubmit={() => history.push(PATHS.PAYMENT_GLOBAL_RECEIPT)}
      goBack={history.goBack}
    />
  );
};

Reauth.propTypes = {
  values: PropTypes.shape({}).isRequired,
  history: historyShape.isRequired,
};

const mapStateToProps = state => ({
  values: getFormValues('globalPaymentForm')(state),
});

export default wizardPage({
  redirectTest: state => isPristine('globalPaymentForm')(state),
  redirectPath: PATHS.PAYMENT_GLOBAL,
})(connect(mapStateToProps)(Reauth));
