export default {
  REAUTH_PAYMENT_DESCRIPTION: {
    'nb-NO': 'Godkjenn betaling av {amount} til {account}',
    'nn-NO': 'Godkjenn betaling av {amount} til {account}',
    'en-GB': 'Approve payment of {amount} to {account}',
  },
  REAUT_GO3_CODE_LABEL: {
    'nb-NO': 'Engangskode fra kodebrikke',
    'nn-NO': 'Engangskode fra kodebrikke',
    'en-GB': 'Code from your code generator',
  },
  REAUT_ENCAP_CODE_LABEL: {
    'nb-NO': 'PIN-kode',
    'nn-NO': 'PIN-kode',
    'en-GB': 'PIN-code',
  },
};
