const template = {
  ACTION_EDIT_ITEM: {
    'nb-NO': 'Endre',
    'nn-NO': 'Endre',
    'en-GB': 'Endre',
  },
  ACTION_HISTORY_ITEM: {
    'nb-NO': 'Historikk',
    'nn-NO': 'Historikk',
    'en-GB': 'Historikk',
  },
  ACTION_DELETE_ITEM: {
    'nb-NO': 'Slett',
    'nn-NO': 'Slett',
    'en-GB': 'Slett',
  },
  ACTION_CONFIRM_DELETE_ITEM: {
    'nb-NO': 'Bekreft sletting',
    'nn-NO': 'Bekreft sletting',
    'en-GB': 'Bekreft sletting',
  },
};

export default template;
