import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import i18n from 'i18n';
import classNames from 'classnames';
import {
  ChevronLeft,
  EnvelopeO,
  CogO,
  ChevronDown,
  Lock,
} from 'digitalbank-icons';
import * as PATH from 'routes/paths';
import getLinks from 'services/links.service';
import _ from 'lodash';
import DropdownList from 'components/DropdownList';
import Logo from 'components/Icons/Logo';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleDropdownId: null,
      hasTransition: false, // prevent transition on inital load,
      scrollY: 0,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.updateScrollY = _.debounce(
      () => {
        this.setState({ scrollY: window.scrollY });
      },
      60,
      { leading: true, trailing: true },
    );
    document.addEventListener('scroll', this.updateScrollY);
  }
  componentWillReceiveProps() {
    if (!this.state.hasTransition) {
      this.setState({
        hasTransition: true,
      });
    }
  }
  componentWillUnmount() {
    document.removeEventListener('scroll', this.updateScrollY);
  }
  hideVisibleDropdown() {
    this.setState({ visibleDropdownId: null });
  }
  toggleDropdown(visibleDropdownId) {
    if (this.state.visibleDropdownId === visibleDropdownId) {
      this.hideVisibleDropdown();
    } else {
      this.setState({ visibleDropdownId });
    }
  }
  handleClickOutside() {
    if (this.state.visibleDropdownId) {
      this.setState({ visibleDropdownId: null });
    }
  }
  render() {
    const {
      isLoggedIn,
      title,
      disableLogoLink,
      logoLinkPath,
      hideLinks,
      logoutLinkPath,
      history,
    } = this.props;

    const { scrollY } = this.state;

    const isHomePage = history.location.pathname === PATH.HOME;
    const transparent = isHomePage && scrollY <= 0;
    const hideGoBack = isHomePage;
    const { visibleDropdownId } = this.state;

    const headerClass = classNames('header header--responsive', {
      'header--has-title': title && isLoggedIn,
    });

    let titleElement;
    let backBtn;
    let logo;

    if (disableLogoLink) {
      logo = (
        <div className="header__logo-link">
          <Logo className="header__logo" />
        </div>
      );
    } else {
      logo = (
        <Link to={logoLinkPath} className="header__logo-link">
          <Logo className="header__logo" />
        </Link>
      );
    }

    if (title && isLoggedIn) {
      titleElement = <div className="header__title">{title}</div>;
      let backBtnStyle;
      if (hideGoBack) {
        backBtnStyle = {
          visibility: 'hidden',
        };
      }
      backBtn = (
        <button
          className="header__back-btn"
          onClick={() => window.history.go(-1)}
          style={backBtnStyle}
          aria-label="Tilbake"
        >
          <ChevronLeft className="header__back-btn-icon" />
        </button>
      );
    }
    const headerLinksStyle = hideLinks ? { visibility: 'hidden' } : {};

    return (
      <header className={headerClass}>
        <nav className="header__nav">
          {logo}
          <div className="header__back">{backBtn}</div>
          {titleElement}
          {isLoggedIn && (
            <ul className="header__links">
              <li
                className="header__link header__link--desktop"
                style={headerLinksStyle}
              >
                <button
                  type="button"
                  className="header__anchor ignore-react-onclickoutside"
                  onClick={() => this.toggleDropdown('INBOX')}
                >
                  <EnvelopeO
                    className="header__icon header__icon--left"
                    aria-hidden
                  />
                  {i18n(t => t.ROUTE_TITLE_INBOX)}
                  <ChevronDown
                    className="header__icon header__icon--right header__icon--chevron-down"
                    aria-hidden
                  />
                </button>
                <div
                  className={classNames('dropdown header-dropdown', {
                    'dropdown--active': visibleDropdownId === 'INBOX',
                  })}
                >
                  <DropdownList
                    handleClickOutside={this.handleClickOutside}
                    linkGroups={
                      _.find(
                        getLinks(this.props.notifications),
                        x => x.path === PATH.INBOX,
                      ).subelements
                    }
                    onActionClick={() => this.hideVisibleDropdown()}
                  />
                </div>
              </li>
              <li
                className="header__link header__link--desktop"
                style={headerLinksStyle}
              >
                <button
                  type="button"
                  className="header__anchor ignore-react-onclickoutside"
                  onClick={() => this.toggleDropdown('SETTINGS')}
                >
                  <CogO
                    className="header__icon header__icon--left"
                    aria-hidden
                  />
                  {i18n(t => t.ROUTE_TITLE_SETTINGS)}
                  <ChevronDown
                    className="header__icon header__icon--right header__icon--chevron-down"
                    aria-hidden
                  />
                </button>
                <div
                  className={classNames('dropdown header-dropdown', {
                    'dropdown--active': visibleDropdownId === 'SETTINGS',
                  })}
                >
                  <DropdownList
                    handleClickOutside={this.handleClickOutside}
                    linkGroups={
                      _.find(
                        getLinks(this.props.notifications),
                        x => x.path === PATH.SETTINGS,
                      ).subelements
                    }
                    onActionClick={() => this.hideVisibleDropdown()}
                  />
                </div>
              </li>
              <li className="header__link">
                <Link
                  to={logoutLinkPath}
                  onClick={this.props.handleLogout}
                  className="header__anchor"
                >
                  <Lock
                    className="header__icon header__icon--left header__icon--lock"
                    aria-hidden
                  />
                  {i18n(t => t.LOG_OUT)}
                </Link>
              </li>
            </ul>
          )}
        </nav>
        <div
          className={classNames('header__background', {
            'header__background--has-transition': this.state.hasTransition,
            'header__background--transparent': transparent,
            'header__background--not-transparent': !transparent,
          })}
        />
      </header>
    );
  }
}

Header.defaultProps = {
  title: '',
  disableLogoLink: false,
  hideLinks: false,
  notifications: undefined,
};

Header.propTypes = {
  history: PropTypes.shape({}).isRequired,
  handleLogout: PropTypes.func.isRequired,
  logoutLinkPath: PropTypes.string.isRequired,
  logoLinkPath: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  title: PropTypes.string,
  disableLogoLink: PropTypes.bool,
  hideLinks: PropTypes.bool,
  notifications: PropTypes.shape({}),
};

export default Header;
