import React from 'react';
import { BasePage } from 'digitalbank-layout';
import { Link } from 'react-router-dom';
import Button from 'digitalbank-buttons';
import * as PATHS from 'routes/paths';
// import { ChevronRight } from 'digitalbank-icons';
import EfakturaFraAlleFaq from './EfakturaFraAlleFaq';
import EfakturaFraAlleStyled from './EfakturaFraAlleStyled';

const EfakturaFraAlleSetupInfoPage = () => (
  <EfakturaFraAlleStyled>
    <BasePage
      heading="eFaktura fra alle"
      className="p-efaktura-fra-alle-info"
      asideContent={<EfakturaFraAlleFaq />}
    >
      <article className="p-efaktura-fra-alle-info__article">
        <p className="short-description">
          Med{' '}
          <i>
            <b>eFaktura fra alle</b>
          </i>{' '}
          mottar du regninger automatisk i nettbanken fra godkjente
          fakturaavsendere. Da slipper du å inngå avtale for hver enkelt
          fakturaavsender. Regningene må fortsatt godkjennes av deg.
        </p>
        <p>
          Du kan fortsatt legge inn avtaler manuelt, f.eks. for regninger som er
          registrert i en annens navn.
        </p>
      </article>
      <div className="p-efaktura-fra-alle-info__continue-btn-container">
        <Button
          variant="primary"
          component={Link}
          to={PATHS.EFAKTURA_FRA_ALLE_ACTIVATION_SETUP}
          className="p-efaktura-fra-alle-info__continue-btn"
          // rightIcon={<ChevronRight />}
        >
          Aktiver efaktura fra alle
        </Button>
      </div>
    </BasePage>
  </EfakturaFraAlleStyled>
);

EfakturaFraAlleSetupInfoPage.propTypes = {};

export default EfakturaFraAlleSetupInfoPage;
