import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SettingsCard = ({
  children,
  heading,
  description,
  aside,
  className,
  ...other
}) => (
  <section
    className={classNames('settings-card', className, {
      'settings-card--without-description': !description,
    })}
    {...other}
  >
    <div className="settings-card__content">
      <article className="settings-card__article">
        {heading && <h3 className="settings-card__heading">{heading}</h3>}
        {description && (
          <div className="settings-card__description">{description}</div>
        )}
      </article>
      {aside && <aside className="settings-card__aside">{aside}</aside>}
    </div>
    {children}
  </section>
);

SettingsCard.defaultProps = {
  children: null,
  aside: null,
  heading: null,
  description: null,
  className: null,
};

SettingsCard.propTypes = {
  children: PropTypes.node,
  aside: PropTypes.node,
  heading: PropTypes.string,
  description: PropTypes.node,
  className: PropTypes.string,
};

export default SettingsCard;
