import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

class PrivateRoute extends PureComponent {
  render() {
    const {
      isAuthenticated, loginPath, path, ...other
    } = this.props;
    if (isAuthenticated) return <Route path={path} {...other} />;
    return (
      <Redirect
        to={{
          pathname: loginPath,
          state: { from: path },
        }}
        from={path}
      />
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.user !== undefined,
});

PrivateRoute.defaultProps = {
  loginPath: '/login',
};

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loginPath: PropTypes.string,
  path: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(PrivateRoute);
