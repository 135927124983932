/* eslint-disable no-mixed-operators, no-restricted-syntax */

import moment from 'moment';
import { tint } from 'polished';
import { getHeatmapLocations } from './googleMaps.util';

export { getHeatmapLocations };

export const getTransactionDescription = (transaction) => {
  if (!transaction) return '';
  if (
    transaction.place &&
    (transaction.place.merchantName || transaction.place.placeName)
  ) {
    return transaction.place.merchantName || transaction.place.placeName;
  }
  return transaction.text;
};

export const isMainCategory = name =>
  name === 'Utgifter' || name === 'Inntekter';

export const getCategory = (categories, name) => {
  for (const mainCategory of categories) {
    if (mainCategory.name === name) {
      return mainCategory;
    }
    if (mainCategory.subCategories) {
      for (const category of mainCategory.subCategories) {
        if (category.name === name) {
          return category;
        }
        if (category.subCategories) {
          for (const subCategory of category.subCategories) {
            if (subCategory.name === name) {
              return subCategory;
            }
          }
        }
      }
    }
  }
  return undefined;
};

export const getTransactionDetailsInCategory = ({
  categoryName,
  transactions,
  categoryKey,
}) => {
  if (transactions && transactions.length > 0) {
    return transactions.reduce(
      (total, transaction) => {
        if (transaction[categoryKey] === categoryName) {
          const totalTransactions = total.transactions
            ? total.transactions
            : [];
          return {
            sum: total.sum + Number(transaction.amount),
            numberOfTransactions: total.numberOfTransactions + 1,
            transactions: [...totalTransactions, transaction],
          };
        }
        return total;
      },
      { sum: 0, numberOfTransactions: 0 },
    );
  }
  return 0;
};

export const getTintValue = (percentage, min = 0.5) => {
  if (percentage === 0) return min;
  if (percentage === 100) return 1;
  const decimal = (100 - percentage) / 100;

  return 1 - decimal * min;
};

const colors = {
  hjem: '#C40000',
  dagligvarer: '#9C0C14',
  spiseute: '#670B10',
  handel: '#954F72',
  helse: '#520930',
  transport: '#9DD2D9',
  ferie: '#0293AC',
  fritid: '#F2C4AF',
  diverse: '#EF645F',
  offentlig: '#556777',
  barn: '#E02568',
};

export const getCategoryColor = (name, percentageOfTotal) => {
  const tintValue = percentageOfTotal
    ? getTintValue(percentageOfTotal)
    : undefined;
  if (name === 'Hjem og bolig') {
    return tintValue ? tint(tintValue, colors.hjem) : colors.hjem;
  }

  if (name === 'Mat og drikke') {
    return tintValue ? tint(tintValue, colors.spiseute) : colors.spiseute;
  }

  if (name === 'Bil og transport') {
    return tintValue ? tint(tintValue, colors.transport) : colors.transport;
  }

  if (name === 'Medier og kommunikasjon') {
    return tintValue ? tint(tintValue, colors.handel) : colors.handel;
  }

  if (name === 'Levekostnader') {
    return tintValue ? tint(tintValue, colors.dagligvarer) : colors.dagligvarer;
  }

  if (name === 'Forsikring') {
    return tintValue ? tint(tintValue, colors.offentlig) : colors.offentlig;
  }

  if (name === 'Ferie og reise') {
    return tintValue ? tint(tintValue, colors.ferie) : colors.ferie;
  }
  if (name === 'Treningssenter') {
    return tintValue ? tint(tintValue, colors.helse) : colors.helse;
  }
  return tintValue ? tint(tintValue, colors.diverse) : colors.diverse;
};

export const getCategoryData = ({ categories, transactions, categoryName }) => {
  const category = getCategory(categories, categoryName);
  if (!category) return null;

  const categoryDetails = getTransactionDetailsInCategory({
    categoryName: category.name,
    transactions,
    categoryKey: category.type,
  });

  let subCategories;
  if (category.subCategories) {
    subCategories = category.subCategories
      .map((subCategory) => {
        const details = getTransactionDetailsInCategory({
          categoryName: subCategory.name,
          transactions,
          categoryKey: subCategory.type,
        });
        const sum = Number(details.sum);
        const sumTest = sum < 0 ? sum * -1 : sum;
        const percentageOfTotal =
          sumTest > 0 ? (sum / categoryDetails.sum) * 100 : 0;
        const color = !isMainCategory(categoryName)
          ? getCategoryColor(categoryName, percentageOfTotal)
          : getCategoryColor(subCategory.name);

        return {
          ...subCategory,
          sum,
          transactions: details.transactions,
          numberOfTransactions: details.numberOfTransactions,
          color,
          category: subCategory,
          percentageOfTotal,
        };
      })
      .filter(x => x.sumTransactions !== 0)
      .sort((a, b) => a.sum - b.sum);
  }

  return {
    name: categoryName,
    color: '#f3f3f3',
    sum: categoryDetails.sum,
    type: category.type,
    children: subCategories,
  };
};

export const mapCategoryDataToGraph = data => ({
  ...data,
  sum: data.sum < 0 ? data.sum * -1 : data.sum,
  children: data.children.map(child => ({
    ...child,
    sum: child.sum < 0 ? child.sum * -1 : child.sum,
  })),
});

export const getGraphSumHeadingPeriodText = (dateFilter, mainCategory) => {
  const startText = mainCategory === 'Utgifter' ? 'Brukt' : 'Inntekter';
  if (dateFilter === '1') {
    // TODO: remove subtract when using real data
    const month = moment()
      .subtract(1, 'months')
      .format('MMM');
    return `${startText} hittil i ${month}`;
  } else if (dateFilter === '2') {
    return `${startText} siste 3 måneder`;
  } else if (dateFilter === '3') {
    return `${startText} siste 6 måneder`;
  } else if (dateFilter === '4') {
    return `${startText} i år`;
  }
  return 'Ukjent periode';
};
