import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18n';
import { BasePage } from 'digitalbank-layout';
import ButtonGroup from 'digitalbank-button-group';
import Accordion from 'digitalbank-accordion';
import AvtaleListHeader from 'digitalbank-betaling-web/lib/es/features/betalingsavtaler/avtaler/AvtaleListHeader';
import AvtaleListContent from 'digitalbank-betaling-web/lib/es/features/betalingsavtaler/avtaler/AvtaleListContent';
import AvtaleForslagListContent from 'digitalbank-betaling-web/lib/es/features/betalingsavtaler/avtaleforslag/AvtaleForslagListContent';
import EfakturaFraAlleCard from 'routes/Avtaleadministrasjon/EfakturaFraAlle/EfakturaFraAlleCard';
import * as PATHS from 'routes/paths';
import Alert from 'digitalbank-alert';
import { CogO } from 'digitalbank-icons';
import { AvtaleadministrasjonFilters } from 'routes/Avtaleadministrasjon/avtaleadministrasjon.constants';
import AvtaleAdministrasjonStyled from './AvtaleadministrasjonStyled';

class Avtaleadministrasjon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvtaleId: '',
      openSuggestedBetalingsavtaleTilbyderId: '',
      displayInfoAlert: true,
      sectionFilter: props.filter,
    };
    this.setSectionFilter = this.setSectionFilter.bind(this);
  }
  setSectionFilter(filter) {
    this.setState({
      ...this.state,
      sectionFilter: filter,
    });
  }
  render() {
    const { avtaler, avtaleForslag, automaticEfakturaIsActive } = this.props;
    const { sectionFilter } = this.state;
    const displayBetalingsavtalerSection =
      sectionFilter === AvtaleadministrasjonFilters.BETALINGSAVTALER;
    const displayForslagSection =
      sectionFilter === AvtaleadministrasjonFilters.FORSLAG;
    return (
      <AvtaleAdministrasjonStyled>
        <BasePage
          heading={i18n(t => t.ROUTE_TITLE_PAYMENT_AGREEMENTS)}
          className="p-betalingsavtaler"
        >
          {!automaticEfakturaIsActive && <EfakturaFraAlleCard />}
          <ButtonGroup
            className="p-betalingsavtaler__top"
            items={[
              {
                id: AvtaleadministrasjonFilters.BETALINGSAVTALER,
                text: 'Betalingsavtaler',
                active: displayBetalingsavtalerSection,
                onClick: () =>
                  this.setSectionFilter(
                    AvtaleadministrasjonFilters.BETALINGSAVTALER,
                  ),
              },
              {
                id: AvtaleadministrasjonFilters.FORSLAG,
                text: `Forslag (${avtaleForslag.length})`,
                active: displayForslagSection,
                onClick: () =>
                  this.setSectionFilter(AvtaleadministrasjonFilters.FORSLAG),
              },
            ]}
            onClick={filter => this.setSectionFilter(filter)}
          />

          {displayBetalingsavtalerSection &&
            avtaler.length > 0 && (
              <div>
                {automaticEfakturaIsActive && (
                  <Link
                    className="efaktura-fra-alle-settings-card"
                    to={PATHS.EFAKTURA_FRA_ALLE_SETTINGS}
                  >
                    <div className="efaktura-fra-alle-settings-card__content">
                      <div className="efaktura-fra-alle-settings-card__description">
                        eFaktura fra alle er aktiv
                      </div>
                      <div className="efaktura-fra-alle-settings-card__info">
                        Du mottar nye eFaktura automatisk
                      </div>
                    </div>
                    <CogO className="efaktura-fra-alle-settings-card__icon" />
                  </Link>
                )}
                <Accordion
                  items={avtaler.map(avtale => ({
                    id: `${avtale.avtaletype}-${avtale.id}`,
                    header: <AvtaleListHeader avtale={avtale} />,
                    content: (
                      <AvtaleListContent
                        avtale={avtale}
                        onDeleteAvtale={() => {}}
                        onResetDeleteError={() => {}}
                        RouterLink={Link}
                      />
                    ),
                  }))}
                />
              </div>
            )}
          {displayForslagSection &&
            avtaleForslag.length > 0 && (
              <div>
                {automaticEfakturaIsActive &&
                  this.state.displayInfoAlert && (
                    <div className="p-betalingsavtaler__efaktura-fra-alle-info-alert">
                      <Alert
                        small
                        type="info"
                        hideIcon
                        onCloseClick={() =>
                          this.setState({ displayInfoAlert: false })
                        }
                      >
                        <p style={{ margin: '0' }}>
                          Selv om du har valgt{' '}
                          <i>
                            <b>eFaktura fra alle </b>
                          </i>{' '}
                          kan det komme nye forslag fra bedrifter som ikke
                          støtter dette ennå.
                        </p>
                      </Alert>
                    </div>
                  )}
                <Accordion
                  items={avtaleForslag.map(avtale => ({
                    id: `${avtale.avtaletype}-${avtale.id}`,
                    header: <AvtaleListHeader avtale={avtale} />,
                    content: (
                      <AvtaleForslagListContent
                        avtale={avtale}
                        onDelete={() => {}}
                        onResetDeleteAvtaleforslagError={() => {}}
                        RouterLink={Link}
                      />
                    ),
                  }))}
                />
              </div>
            )}
        </BasePage>
      </AvtaleAdministrasjonStyled>
    );
  }
}

Avtaleadministrasjon.defaultProps = {
  avtaler: undefined,
  avtaleForslag: undefined,
  filter: AvtaleadministrasjonFilters.BETALINGSAVTALER,
};

Avtaleadministrasjon.propTypes = {
  filter: PropTypes.string,
  avtaler: PropTypes.arrayOf(PropTypes.shape({})),
  avtaleForslag: PropTypes.arrayOf(PropTypes.shape({})),
  automaticEfakturaIsActive: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  avtaler: state.betalingsavtaler.avtaler,
  avtaleForslag: state.betalingsavtaler.avtaleForslag,
  automaticEfakturaIsActive: state.user.settings.automaticEfakturaIsActive,
});

export default connect(mapStateToProps)(Avtaleadministrasjon);
