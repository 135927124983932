/* eslint-disable no-mixed-operators  */

import styled from 'styled-components';
import { breakpoints, spacing, layout, colors } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { formMaxWidthValue } from 'digitalbank-core-style/lib/sizes';
import { rem } from 'polished';

export default styled.div`
  .p-efaktura-fra-alle-settings {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__description {
      ${horizontalLayoutSpacing({ tablet: false })};
      margin-bottom: ${rem(spacing.layoutSpacing.md)};

      ul {
        li {
          margin-bottom: ${rem(14)};
        }
      }
    }

    &__main {
      ${horizontalLayoutSpacing({ phone: false })};

      @media (max-width: ${breakpoints.tablet}px) {
        margin-bottom: ${rem(100)};
      }
    }

    &__aside {
      ${horizontalLayoutSpacing({ phone: false })};
    }

    &__modal-card {
      margin: 40px 5px;
      max-width: ${rem(formMaxWidthValue)};

      @media (min - width: ${formMaxWidthValue +
          layout.horizontalSpacing.phone * 2}px) {
        margin: 40px auto;
      }
    }

    &__form {
      margin-bottom: ${rem(spacing.layoutSpacing.md)};
    }

    &__block-icon {
      color: ${colors.dustyGray};
    }

    &__submit-btn-container {
      ${horizontalLayoutSpacing({ tablet: false })};

      .button {
        @media (max-width: ${breakpoints.tablet}px) {
          width: 100%;
        }
      }
    }

    &__section {
      ${horizontalLayoutSpacing({ tablet: false })};

      &-heading {
        ${horizontalLayoutSpacing({ tablet: false })};
      }

      &-contact-info {
        margin-bottom: ${rem(spacing.layoutSpacing.md)};

        .detail-section {
          margin-bottom: 10px;
        }
      }
    }

    .nav-list {
      @media (min-width: ${breakpoints.tablet}px) {
        border-left: 1px solid ${colors.alto};
        border-right: 1px solid ${colors.alto};
      }
    }
  }
`;
