import styled from 'styled-components';
import { breakpoints } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { formMaxWidthValue } from 'digitalbank-core-style/lib/sizes';
import { rem } from 'polished';

export default styled.div`
  .p-transfer {
    &__heading {
      ${horizontalLayoutSpacing};
    }

    &__footer {
      &-content {
        @media (min-width: ${breakpoints.tablet}px) {
          max-width: ${formMaxWidthValue}px;
        }

        &--full-width {
          max-width: none;
        }

        .published-content {
          ${horizontalLayoutSpacing({ type: 'margin' })};

          &:first-of-type {
            margin-top: 20px;
          }
        }
      }
    }

    &__aside {
      ${horizontalLayoutSpacing};
      &--form-align {
        @media (min-width: ${breakpoints.tablet}px) {
          /* Align with form on desktop */
          margin-top: ${rem(24)};
        }
      }
    }

    &__info-alert {
      ${horizontalLayoutSpacing};
      margin-bottom: $vertical-margin-small;
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${formMaxWidthValue}px;
      }

      + .p-transfer__info-alert {
        margin-top: $vertical-margin-medium;
      }
    }

    .account-picker-info {
      margin-bottom: ${rem(8)};
    }

    .action-list,
    .nav-list {
      ${horizontalLayoutSpacing};
      padding-top: ${rem(20)};
      margin-bottom: ${rem(20)};
    }

    .action-list {
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${formMaxWidthValue}px;
      }
    }
  }

  .transfer-form {
    ${horizontalLayoutSpacing};
    margin-bottom: ${rem(20)};

    @media (min-width: ${breakpoints.tablet}px) {
      max-width: ${formMaxWidthValue}px;
    }

    &__remove-btn {
      width: 100%;
      margin-top: 30px;
    }

    &__complete-btn {
      width: 100%;
      margin-top: ${rem(25)};

      &--error {
        opacity: 0.25;
      }
    }
  }
`;
