import styled from 'styled-components';
import { breakpoints, spacing, layout, sizes } from 'digitalbank-core-style';
import { horizontalLayoutSpacing } from 'digitalbank-core-style/lib/mixins';
import { rem } from 'polished';

export default styled.div`
  .p-personal-data-consent {
    &__heading,
    &__main,
    &__footer,
    &__aside {
      ${horizontalLayoutSpacing};
    }

    &__footer {
      margin-top: ${rem(spacing.layoutSpacing.sm)};

      &-content {
        @media (min-width: ${breakpoints.tablet}px) {
          width: 70%;
          max-width: ${rem(sizes.formMaxWidthValue)};
        }
      }
    }

    &__age-alert {
      margin-bottom: rem(20);
    }

    &__aside {
      @media (max-width: ${breakpoints.tablet}px) {
        margin-top: ${rem(spacing.layoutSpacing.sm)};
      }
    }

    &__info,
    &__form {
      ${horizontalLayoutSpacing};
      padding-bottom: ${rem(spacing.layoutSpacing.sm)};
      background: #fff;
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${rem(sizes.formMaxWidthValue)};
      }
    }

    &__info {
      padding-top: ${rem(spacing.layoutSpacing.sm)};

      &--no-bottom-space {
        padding-bottom: 0;
      }
    }

    &__external-links {
      margin: ${rem(layout.horizontalSpacing.phone)}; 0;
      font-style: italic;
    }

    &__save-button {
      width: 100%;
      margin-top: ${rem(16)};

      @media (min-width: ${breakpoints.tablet}px) {
        width: 215px;
      }
    }

    &__error {
      display: none;
      padding: 0;
      margin-top: ${rem(16)};

      &--visible {
        display: flex;
      }

      &-text {
        flex: 1;
        padding: ${rem(10)} 0 ${rem(10)} ${rem(10)};
      }

      .alert__icon {
        margin: auto 0;
        padding-left: ${rem(10)};
        max-width: ${rem(20)};
        min-width: ${rem(20)};
      }
    }

    .radio-group {
      margin-bottom: 0;
      margin-top: ${rem(12)};
    }

    .radio__label {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .accordion {
      @media (min-width: ${breakpoints.tablet}px) {
        max-width: ${rem(sizes.formMaxWidthValue)};
      }
    }

    &__actions {
      display: flex;
    }

    &-periodic {
      &__save-button {
        flex: 1;
        margin-top: ${rem(16)};
        max-width: 170px;
        margin-right: 20px;
      }
    }
  }
`;
