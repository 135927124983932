/* eslint-disable react/no-danger, max-len */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import historyShape from 'shapes/history.shape';
import { bindActionCreators } from 'redux';
import {
  reduxForm,
  formValueSelector,
  Field,
  Fields,
  change,
} from 'redux-form';
import i18n from 'i18n';
import classNames from 'classnames';
import { InputField, Legend } from 'digitalbank-form';
import CountrySelectField from 'digitalbank-form/lib/es/components/CountrySelectField/CountrySelectField';
import { Account, ExclamationCircle, QuestionMark } from 'digitalbank-icons';
import { Modal, ModalCard } from 'digitalbank-modal';
import Alert from 'digitalbank-alert';
import RecipientSelect from 'components/RecipientLists/select/RecipientSelect';
import { setLocale } from 'actions';
import * as PATHS from 'routes/paths';
import { GlobalPaymentInfo, Recipients } from 'data';
import _ from 'lodash';
import Collapse from 'react-css-collapse';
import Button from 'digitalbank-buttons';
import {
  all as allCountries,
  findCountries,
  findCountry,
} from 'spv-country-data';
import {
  getIbanDisplayFilter,
  getToAccountLabel,
  getToAccountHelpText,
  getCountryDefaultCurrency,
  countryShouldDisplayBankIdentifierCodeField,
  countryShouldDisplayBankCodeField,
  countryShouldDisplayBankAddressFields,
  getBankBasedOnAccountNumber,
} from '../paymentGlobal.utils';
import { firstPageValidate } from '../paymentGlobal.validation';
import paymentGlobalFields from '../paymentGlobal.fields';
import ExtraBankCodeFields from './Fields/ExtraBankCodeFields';
import RecipientAddressFields from './Fields/RecipientAddressFields';
import initialValues from './initialValues';

const getBank = (ibanDisplayFilter, tilKonto, bankCode) => {
  if (ibanDisplayFilter === 'MANDATORY') {
    //  Get bank name and code based on IBAN (tilKonto) value
    return getBankBasedOnAccountNumber(tilKonto);
  }
  if (ibanDisplayFilter !== 'MANDATORY') {
    // Get bank name based on bankCode
    return _.find(GlobalPaymentInfo.Banks, b => b.code === bankCode);
  }

  return undefined;
};

class FirstPage extends Component {
  static handleTilKontoChange(
    currentCountry,
    bankCode,
    tilKonto,
    changeFieldValue,
  ) {
    const formAlias = 'globalPaymentForm';

    // check if we can get country from tilKonto value
    const isoCode =
      tilKonto &&
      tilKonto.length >= 2 &&
      /^[a-zA-Z]+$/.test(tilKonto.substr(0, 2))
        ? tilKonto.substr(0, 2).toUpperCase()
        : undefined;
    const country = isoCode ? findCountry(isoCode) : '';
    if (country) {
      changeFieldValue(formAlias, 'recipientCountry', isoCode);
      changeFieldValue(formAlias, 'country', isoCode);
      // Get default currency based on country and update fields
      const defaultCurrency = getCountryDefaultCurrency(isoCode);
      changeFieldValue(formAlias, 'currency', defaultCurrency);
      changeFieldValue(formAlias, 'paymentCurrency', defaultCurrency);
    }

    const ibanDisplayFilter = getIbanDisplayFilter(
      country ? isoCode : currentCountry,
    );
    // get bankName and bankCode based
    const bank = getBank(ibanDisplayFilter, tilKonto, bankCode);
    // Reset bankCode and bankName if we cant find bank
    if (ibanDisplayFilter === 'MANDATORY' && !bank) {
      changeFieldValue(formAlias, 'bankCode', '');
      changeFieldValue(formAlias, 'bankName', '');
    }
    if (bank) {
      changeFieldValue(formAlias, 'bankCode', bank.code);
      changeFieldValue(formAlias, 'bankName', bank.name);
    }
  }
  static updateCountry(changeFieldValue, tilKonto) {
    const formAlias = 'globalPaymentForm';
    // changeFieldValue(formAlias, 'country', tilKonto);

    // We should test if recipientCountry is dirty before changing it
    changeFieldValue(formAlias, 'recipientCountry', tilKonto);

    // Get default currency based on country and update fields
    const defaultCurrency = getCountryDefaultCurrency(tilKonto);
    changeFieldValue(formAlias, 'currency', defaultCurrency);
    changeFieldValue(formAlias, 'paymentCurrency', defaultCurrency);
  }
  static updateRecipient(changeFieldValue, recipient) {
    const formAlias = 'globalPaymentForm';
    changeFieldValue(formAlias, 'tilKonto', recipient.account);
    changeFieldValue(formAlias, 'recipientCountry', recipient.country);
    changeFieldValue(formAlias, 'recipientAddress1', recipient.address1);
    changeFieldValue(formAlias, 'recipientAddress2', recipient.address2);
    changeFieldValue(formAlias, 'recipientAddress3', recipient.address3);
    changeFieldValue(formAlias, 'recipientName', recipient.name);

    changeFieldValue(formAlias, 'country', recipient.bank.country);
    changeFieldValue(formAlias, 'bankAddress1', recipient.bank.address);
    changeFieldValue(formAlias, 'bankCode', recipient.bank.code);
    changeFieldValue(formAlias, 'bankName', recipient.bank.name);

    // Get default currency based on country and update fields
    const defaultCurrency = getCountryDefaultCurrency(recipient.country);
    changeFieldValue(formAlias, 'currency', defaultCurrency);
    changeFieldValue(formAlias, 'paymentCurrency', defaultCurrency);
  }
  static handleBankCodeChange(
    ibanDisplayFilter,
    bankCode,
    tilKonto,
    changeFieldValue,
  ) {
    const formAlias = 'globalPaymentForm';
    // get bankName and bankCode based
    const bank = getBank(ibanDisplayFilter, tilKonto, bankCode);
    changeFieldValue(formAlias, 'bankName', bank ? bank.name : '');
  }
  constructor(props) {
    super(props);
    this.state = {
      displayReceivers: false,
      displayChecklistModal: false,
      help: {
        toAccount: false,
        extraBankCode: false,
      },
    };
    this.toggleReceivers = this.toggleReceivers.bind(this);
    this.toggleChecklistModal = this.toggleChecklistModal.bind(this);
    this.selectBankInfoRadioOption = this.selectBankInfoRadioOption.bind(this);
  }
  toggleReceivers() {
    this.setState({
      ...this.state,
      displayReceivers: !this.state.displayReceivers,
    });
  }
  toggleChecklistModal() {
    this.setState({
      ...this.state,
      displayChecklistModal: !this.state.displayChecklistModal,
    });
  }
  selectBankInfoRadioOption(value) {
    this.setState({
      ...this.state,
      bankInfoRadioOptionsValue: value,
    });
  }

  render() {
    const {
      history,
      country,
      changeFieldValue,
      extraBankCode,
      handleSubmit,
      locale,
    } = this.props;
    let bankIdendifierCodeFields = '';
    const ibanDisplayFilter = getIbanDisplayFilter(country);
    const toAccountLabel = getToAccountLabel(ibanDisplayFilter, i18n);
    const toAccountHelpText = getToAccountHelpText(ibanDisplayFilter, i18n);
    const displayBankAddressFields =
      country !== '' ? countryShouldDisplayBankAddressFields(country) : false;
    const displayBankIdendifierCodeField =
      country !== '' && countryShouldDisplayBankIdentifierCodeField(country);
    const displayBankCodeField = countryShouldDisplayBankCodeField(country);
    const { displayChecklistModal } = this.state;
    bankIdendifierCodeFields = (
      <div>
        <div className="connected-form-groups">
          <Field
            name="bankCode"
            label="SWIFT / BIC"
            component={InputField}
            autoCapitalize="characters"
            type="text"
            disabled={ibanDisplayFilter === 'MANDATORY'}
            displayError={false}
            onFieldChange={e =>
              FirstPage.handleBankCodeChange(
                ibanDisplayFilter,
                e.target.value,
                this.props.tilKonto,
                changeFieldValue,
              )
            }
            rightAddon={() => (
              <button
                type="button"
                className="form-control__help-button"
                onClick={() =>
                  this.setState({
                    help: {
                      ...this.state.help,
                      bankCode: !this.state.help.bankCode,
                    },
                  })
                }
              >
                <QuestionMark
                  className="form-control__help-button-icon"
                  active={this.state.help.bankCode}
                />
              </button>
            )}
          />
          <Field
            name="bankName"
            label={i18n(i => i.PAYMENT_GLOBAL_LABEL_BANK_NAME)}
            component={InputField}
            type="text"
            disabled
            className="form-group--with-help-button-indent"
            transformableLabel={false}
          />
        </div>
        <Collapse
          isOpen={this.state.help.bankCode}
          className="input-help-animation"
        >
          <div
            className={classNames('input-help input-help--with-triangle', {
              'input-help--is-active': this.state.help.bankCode,
            })}
          >
            <div
              className="input-help__content"
              dangerouslySetInnerHTML={{
                __html: i18n(t => t.INPUT_HELP_TEXT_EXTRA_BANK_CODE_SWIFT_BIC),
              }}
            />
          </div>
        </Collapse>
      </div>
    );
    return (
      <form
        className="payment-global-form"
        onSubmit={handleSubmit(() => history.push(PATHS.PAYMENT_GLOBAL_SECOND))}
      >
        <Modal
          toggle={this.toggleChecklistModal}
          visible={displayChecklistModal}
          fullSize
        >
          <ModalCard
            className="modal-card--large"
            toggle={this.toggleChecklistModal}
            visible={displayChecklistModal}
            hasCloseButton
          >
            <span>
              {locale === 'nb-NO' && (
                <article>
                  <h3>Unngå å bli svindlet</h3>
                  <ul>
                    <li>Ikke send penger når du selv selger noe</li>
                    <li>Ikke send penger for å motta arv, gevinst etc.</li>
                    <li>
                      Ikke send penger til personer du ikke kjenner godt, eller
                      ikke kan bekrefte identiteten til (f.eks bekjentskaper
                      gjort på nettet)
                    </li>
                  </ul>
                  <h3>Har du mottatt falsk e-post?</h3>
                  <p>
                    Når du mottar betalingsinformasjon på e-post anbefaler vi
                    deg å dobbeltsjekke pr. telefon at opplysningene stemmer.
                  </p>
                  <p>
                    Vil du vite mer om hvordan du unngår å bli svindlet?{' '}
                    <span className="link">Klikk her</span> (åpner i nytt vindu)
                  </p>
                  <Button
                    variant="cancel"
                    size="small"
                    className="modal-card__cancel-btn"
                    onClick={this.toggleChecklistModal}
                  >
                    Lukk
                  </Button>
                </article>
              )}
              {locale === 'en-GB' && (
                <article>
                  <h3>Security advice</h3>
                  <ul>
                    <li>
                      Never give your bank account details or send any form of
                      payment when you are the seller, unless it can be verified
                    </li>
                    <li>
                      Never give your bank account details or send any form of
                      payment to receive inheritance, winnings etc. unless it
                      can be verified
                    </li>
                    <li>
                      {' '}
                      Never give your bank account details or send any form of
                      payment to people you do not know well, or can not verify
                      the identity of (eg. acquaintances made online)
                    </li>
                  </ul>
                  <h3>Have you received fraudulent email?</h3>
                  <p>
                    When you receive payment information via e-mail, we
                    recommend you to double check that the information is
                    correct pr. telephone.
                  </p>
                  <p>
                    Do you want to know more about how to avoid being victim of
                    fraud? <span className="link">Click here</span> (opens in
                    new window)
                  </p>
                  <button
                    className="button button--cancel button--small modal-card__cancel-btn"
                    type="button"
                    onClick={this.toggleChecklistModal}
                  >
                    Close
                  </button>
                </article>
              )}
            </span>
          </ModalCard>
        </Modal>
        <Modal
          toggle={this.toggleReceivers}
          visible={this.state.displayReceivers}
        >
          <RecipientSelect
            header={i18n(t => t.RECIPIENT_SELECT)}
            closeText={i18n(t => t.CANCEL)}
            close={this.toggleReceivers}
            recipients={_.orderBy(
              _.filter(Recipients, x => x.group === 'utland'),
              x => x.name,
            )}
            onSelect={(recipient) => {
              FirstPage.updateRecipient(changeFieldValue, recipient);
              this.toggleReceivers();
            }}
          />
        </Modal>
        <div className="payment-global-form__info-alert">
          <Alert icon={ExclamationCircle} type="info" small>
            {locale === 'en-GB' && (
              <p>
                Go through{' '}
                <button
                  className="button button--link"
                  type="button"
                  onClick={this.toggleChecklistModal}
                >
                  this checklist
                </button>{' '}
                before making a foreign payment
              </p>
            )}
            {locale === 'nb-NO' && (
              <p>
                Unngå å bli svindlet! Gå gjennom{' '}
                <button
                  className="button button--link"
                  type="button"
                  onClick={this.toggleChecklistModal}
                >
                  denne sjekklisten
                </button>{' '}
                før du foretar en utlandsbetaling
              </p>
            )}
          </Alert>
        </div>
        <fieldset className="fieldset">
          <button
            className="button button--link payment-global-form__language-toggler"
            type="button"
            onClick={() => {
              this.props.setLocale(locale === 'en-GB' ? 'nb-NO' : 'en-GB');
              // TODO: This force a revalidate of our form and translating errors. We should find another way.
              setTimeout(() => {
                changeFieldValue('globalPaymentForm', 'bankCode', '123');
                changeFieldValue(
                  'globalPaymentForm',
                  'bankCode',
                  this.props.bankCode,
                );
              }, 100);
            }}
            style={{ marginBottom: '.625rem' }}
          >
            {locale === 'en-GB' ? 'På norsk' : 'In English'}
          </button>

          <Legend text={i18n(t => t.PAYENT_GLOBAL_LEGEND_RECIPIENT_BANK)} />
          <Field
            component={CountrySelectField}
            name="country"
            defaultValueText={i18n(t => t.COUNTRY_SELECT)}
            label={i18n(t => t.COUNTRY)}
            transformableLabel={false}
            countriesShortList={findCountries('SE, PL, DK, GB, US')}
            countries={allCountries}
            ariaLabel=""
            displayCountryPhoneCode={false}
            className="form-group--with-help-button-indent"
            onFieldChange={e =>
              FirstPage.updateCountry(changeFieldValue, e.target.value)
            }
          />
          <Field
            name="tilKonto"
            normalize={value => value && value.toUpperCase()}
            autoCapitalize="characters"
            spellCheck={false}
            label={toAccountLabel}
            component={InputField}
            type="text"
            onFieldChange={e =>
              FirstPage.handleTilKontoChange(
                country,
                this.props.bankCode,
                e.target.value,
                changeFieldValue,
              )
            }
            rightAddon={() => (
              <span style={{ display: 'flex' }}>
                <button
                  type="button"
                  className="form-control__button ignore-react-onclickoutside"
                  onClick={this.toggleReceivers}
                >
                  <Account className="form-control__button-icon" />
                </button>
                <button
                  type="button"
                  className="form-control__help-button"
                  onClick={() =>
                    this.setState({
                      help: {
                        ...this.state.help,
                        toAccount: !this.state.help.toAccount,
                      },
                    })
                  }
                >
                  <QuestionMark
                    className="form-control__help-button-icon"
                    active={this.state.help.toAccount}
                  />
                </button>
              </span>
            )}
          />
          <Collapse
            isOpen={this.state.help.toAccount}
            className="input-help-animation"
          >
            <div
              className={classNames('input-help input-help--with-triangle', {
                'input-help--is-active': this.state.help.toAccount,
              })}
            >
              <div
                className="input-help__content"
                dangerouslySetInnerHTML={{ __html: toAccountHelpText }}
              />
            </div>
          </Collapse>
          {displayBankIdendifierCodeField && bankIdendifierCodeFields}
          {displayBankCodeField && (
            <ExtraBankCodeFields
              extraBankCode={extraBankCode}
              help={this.state.help}
              toggleHelp={() =>
                this.setState({
                  help: {
                    ...this.state.help,
                    extraBankCode: !this.state.help.extraBankCode,
                  },
                })
              }
            />
          )}
          {displayBankAddressFields && (
            <div className="connected-form-groups">
              <Field
                name="bankAddress1"
                label={i18n(t => t.PAYENT_GLOBAL_LEGEND_RECIPIENT_BANK)}
                displayError={false}
                component={InputField}
                type="text"
                className="form-group--with-help-button-indent"
              />
              <Field
                name="bankAddress2"
                label={i18n(
                  t => t.PAYMENT_GLOBAL_LABEL_RECIPIENT_BANK_ADDRESS_LINE_2,
                )}
                displayError={false}
                transformableLabel={false}
                component={InputField}
                type="text"
                className="form-group--with-help-button-indent"
              />
              <Field
                name="bankAddress3"
                label={i18n(
                  t => t.PAYMENT_GLOBAL_LABEL_RECIPIENT_BANK_ADDRESS_LINE_3,
                )}
                displayError={false}
                transformableLabel={false}
                component={InputField}
                type="text"
                className="form-group--with-help-button-indent"
              />
            </div>
          )}
        </fieldset>
        <fieldset className="fieldset">
          <Legend text={i18n(t => t.PAYMENT_GLOBAL_LEGEND_RECIPIENT)} />
          <Field
            name="recipientName"
            label={i18n(t => t.PAYMENT_GLOBAL_LABEL_RECIPIENT_NAME)}
            component={InputField}
            type="text"
            autoCapitalize="word"
            spellCheck={false}
            autoComplete="name"
            autoCorrect="off"
            className="form-group--with-help-button-indent"
          />
          <Fields
            names={[
              'recipientAddress1',
              'recipientAddress2',
              'recipientAddress3',
            ]}
            component={RecipientAddressFields}
            locale={locale}
          />
          <Field
            component={CountrySelectField}
            name="recipientCountry"
            defaultValueText={i18n(t => t.COUNTRY_SELECT)}
            label={i18n(t => t.COUNTRY)}
            transformableLabel={false}
            countriesShortList={findCountries('SE, PL, DK, GB, US')}
            countries={allCountries}
            ariaLabel=""
            displayCountryPhoneCode={false}
            className="form-group--with-help-button-indent"
          />
        </fieldset>
        <div className="payment-global-form__actions">
          <Button
            variant="primary"
            type="submit"
            className="payment-global-form__next-button"
          >
            {i18n(t => t.NEXT)}
          </Button>
        </div>
      </form>
    );
  }
}

FirstPage.defaultProps = {
  ...initialValues,
  handleSubmit: undefined,
};

FirstPage.propTypes = {
  history: historyShape.isRequired,
  handleSubmit: PropTypes.func,
  bankCode: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  tilKonto: PropTypes.string.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  setLocale: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  extraBankCode: PropTypes.string,
};

const form = reduxForm({
  destroyOnUnmount: false,
  form: 'globalPaymentForm',
  fields: paymentGlobalFields,
  initialValues,
  validate: values => firstPageValidate(values, i18n),
})(FirstPage);

const mapStateToProps = (state) => {
  const selector = formValueSelector('globalPaymentForm');
  return {
    bankCode: selector(state, 'bankCode'),
    bankName: selector(state, 'bankName'),
    country: selector(state, 'country'),
    tilKonto: selector(state, 'tilKonto'),
    recipientName: selector(state, 'recipientName'),
    extraBankCode: selector(state, 'extraBankCode'),
    locale: state.locale,
  };
};

const mapDispatchToProps = dispatch => ({
  changeFieldValue: bindActionCreators(change, dispatch),
  setLocale: bindActionCreators(setLocale, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(form);
