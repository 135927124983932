import { mapTransactionToFormValues } from 'utils/transactions.util';
import { getTransactionFromId } from 'services/transactions.service';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case 'EDIT_FAST_OVERFOERING/LOAD': {
      const transaction = getTransactionFromId(
        action.transactions.data,
        action.transactionId,
      );
      return {
        ...state,
        data: mapTransactionToFormValues(transaction),
      };
    }

    case 'EDIT_FAST_OVERFOERING/UNLOAD': {
      return {};
    }
    default:
      return state;
  }
}
