const template = {
  YES: {
    'nb-NO': 'Ja',
    'nn-NO': 'Ja',
    'en-GB': 'Yes',
  },
  NO: {
    'nb-NO': 'Nei',
    'nn-NO': 'Nei',
    'en-GB': 'No',
  },
  CONFIRMATION: {
    'nb-NO': 'Bekreftelse',
    'nn-NO': 'Bekreftelse',
    'en-GB': 'Confirmation',
  },
  ADDRESS: {
    'nb-NO': 'Adresse',
    'nn-NO': 'Adresse',
    'en-GB': 'Address',
  },
  NEXT: {
    'nb-NO': 'Neste',
    'nn-NO': 'Neste',
    'en-GB': 'Next',
  },
  BACK: {
    'nb-NO': 'Tilbake',
    'nn-NO': 'Tilbake',
    'en-GB': 'Back',
  },
  CANCEL: {
    'nb-NO': 'Avbryt',
    'nn-NO': 'Avbryt',
    'en-GB': 'Cancel',
  },
  COUNTRY: {
    'nb-NO': 'Land',
    'nn-NO': 'Land',
    'en-GB': 'Country',
  },
  COUNTRY_SELECT: {
    'nb-NO': 'Velg land',
    'nn-NO': 'Velg land',
    'en-GB': 'Select country',
  },
  ACCOUNT_NUMBER: {
    'nb-NO': 'Kontonummer',
    'nn-NO': 'Kontonummer',
    'en-GB': 'Account number',
  },
  RECIPIENT: {
    'nb-NO': 'Mottaker',
    'nn-NO': 'Mottakar',
    'en-GB': 'Recipient',
  },
  RECIPIENT_SELECT: {
    'nb-NO': 'Velg mottaker',
    'nn-NO': 'Velg mottaker',
    'en-GB': 'Select recipient',
  },
  FROM: {
    'nb-NO': 'Fra',
    'nn-NO': 'Frå',
    'en-GB': 'From',
  },
  TO: {
    'nb-NO': 'Til',
    'nn-NO': 'Til',
    'en-GB': 'To',
  },
  FROM_ACCOUNT: {
    'nb-NO': 'Fra konto',
    'nn-NO': 'Frå konto',
    'en-GB': 'From account',
  },
  AMOUNT: {
    'nb-NO': 'Beløp',
    'nn-NO': 'Beløp',
    'en-GB': 'Amount',
  },
  DECIMALS: {
    'nb-NO': 'Desimaler',
    'nn-NO': 'Desimaler',
    'en-GB': 'Decimals',
  },
  CURRENCY: {
    'nb-NO': 'Valuta',
    'nn-NO': 'Valuta',
    'en-GB': 'Currency',
  },
  EXCHANGE: {
    'nb-NO': 'Kurs',
    'nn-NO': 'Kurs',
    'en-GB': 'Exchange',
  },
  FEE: {
    'nb-NO': 'Gebyr',
    'nn-NO': 'Gebyr',
    'en-GB': 'Fee',
  },
  FEES: {
    'nb-NO': 'Omkostninger',
    'nn-NO': 'Omkostninger',
    'en-GB': 'Fees',
  },
  MESSAGE: {
    'nb-NO': 'Melding',
    'nn-NO': 'Melding',
    'en-GB': 'Message',
  },
  ADVANCED_OPTIONS: {
    'nb-NO': 'Avanserte valg',
    'nn-NO': 'Avanserte val',
    'en-GB': 'Advanced options',
  },
  IS_REQUIRED: {
    'nb-NO': 'er påkrevd',
    'nn-NO': 'er påkrevd',
    'en-GB': 'is required',
  },
  DISPLAY_DETAILS: {
    'nb-NO': 'Vis detaljer',
    'nn-NO': 'Vis detaljer',
    'en-GB': 'Display details',
  },
  HIDE_DETAILS: {
    'nb-NO': 'Skjul detaljer',
    'nn-NO': 'Skjul detaljer',
    'en-GB': 'Hide details',
  },
  DATE: {
    'nb-NO': 'Dato',
    'nn-NO': 'Dato',
    'en-GB': 'Date',
  },
  MELDING: {
    'nb-NO': 'Melding',
    'nn-NO': 'Melding',
    'en-GB': 'Message',
  },
  FRA: {
    'nb-NO': 'Fra',
    'nn-NO': 'Fra',
    'en-GB': 'From',
  },
  KID: {
    'nb-NO': 'KID',
    'nn-NO': 'KID',
    'en-GB': 'KID',
  },
  FORFALL: {
    'nb-NO': 'Forfall',
    'nn-NO': 'Forfall',
    'en-GB': 'Scheduled',
  },
  MY_INFORMATION: {
    'nb-NO': 'Mine Innstillinger',
    'nn-NO': 'Mine Innstillinger',
    'en-GB': 'My information',
  },
  SIGN_IN: {
    'nb-NO': 'Logg inn',
    'nn-NO': 'Logg inn',
    'en-GB': 'Sign in',
  },
  LOG_OUT: {
    'nb-NO': 'Logg ut',
    'nn-NO': 'Logg ut',
    'en-GB': 'Log out',
  },
  MENU: {
    'nb-NO': 'Meny',
    'nn-NO': 'Meny',
    'en-GB': 'Menu',
  },
  NUMBER_NO_DIGITS: {
    'nb-NO': '{number, number, noDecimals}',
    'nn-NO': '{number, number, noDecimals}',
    'en-GB': '{number, number, noDecimals}',
  },
  NUMBER_TWO_DIGITS: {
    'nb-NO': '{number, number, twoDecimals}',
    'nn-NO': '{number, number, twoDecimals}',
    'en-GB': '{number, number, twoDecimals}',
  },
  SHORT_DATE: {
    'nb-NO': '{dato, date, mediumNoYear}',
    'nn-NO': '{dato, date, mediumNoYear}',
    'en-GB': '{dato, date, mediumNoYear}',
  },
  MEDIUM_DATE: {
    'nb-NO': '{dato, date, medium}',
    'nn-NO': '{dato, date, medium}',
    'en-GB': '{dato, date, medium}',
  },
  FULL_DATE_NO_YEAR: {
    'nb-NO': '{dato, date, full_no_year}',
    'nn-NO': '{dato, date, full_no_year}',
    'en-GB': '{dato, date, full_no_year}',
  },
  FULL_DATE: {
    'nb-NO': '{dato, date, full}',
    'nn-NO': '{dato, date, full}',
    'en-GB': '{dato, date, full}',
  },
  SIGN_OUT: {
    'nb-NO': 'Logg ut',
    'nn-NO': 'Logg ut',
    'en-GB': 'Logg ut',
  },
  SOMETHING_ELSE: {
    'nb-NO': 'noe annet',
    'nn-NO': 'noe annet',
    'en-GB': 'noe annet',
  },
  FETCHING_KONTOER: {
    'nb-NO': 'Henter kontoer...',
    'nn-NO': 'Henter kontoer...',
    'en-GB': 'Henter kontoer...',
  },
  NY_OVERFOERING: {
    'nb-NO': 'Ny overføring',
    'nn-NO': 'Ny overføring',
    'en-GB': 'Ny overføring',
  },
  BETALE: {
    'nb-NO': 'Betale',
    'nn-NO': 'Betale',
    'en-GB': 'Betale',
  },
  CREATE_KONTO: {
    'nb-NO': 'Åpne konto',
    'nn-NO': 'Åpne konto',
    'en-GB': 'Åpne konto',
  },
  FORFALLSOVERSIKT: {
    'nb-NO': 'Forfallsoversikt',
    'nn-NO': 'Forfallsoversikt',
    'en-GB': 'Forfallsoversikt',
  },
  SISTE_BEVEGELSER: {
    'nb-NO': 'Siste bevegelser',
    'nn-NO': 'Siste bevegelser',
    'en-GB': 'Siste bevegelser',
  },
  OVERFOERE_FROM_KREDITTKORT_TITLE: {
    'nb-NO': 'Overføre fra kredittkort',
    'nn-NO': 'Overføre fra kredittkort',
    'en-GB': 'Overføre fra kredittkort',
  },
  OVERFOERE_FROM_KREDITTKORT_TEXT: {
    'nb-NO':
      'Tjenesten koster det samme som for kontantuttak: 30kr + 1% av overført beløp',
    'nn-NO':
      'Tjenesten koster det samme som for kontantuttak: 30kr + 1% av overført beløp',
    'en-GB':
      'Tjenesten koster det samme som for kontantuttak: 30kr + 1% av overført beløp',
  },
  SEARCH: {
    'nb-NO': 'Søk',
    'nn-NO': 'Søk',
    'en-GB': 'Search',
  },
};

export default template;
